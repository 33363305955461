import {Observable} from 'rxjs'

import {Case} from './item_model'
import {Api, FactserverEmptyResponse} from './api'

export interface GetTBOToApproveResponse {
    artkey: number
    case: Case
    originating_spli: {
        artkey: number
        price_per_case: number | null
        price_per_bottle: number
        number_of_bottles_per_case: number
        gift_box_type: string
        customs_status: string
        currency: string
        incoterm: string
        number_of_bottles: number | null
        number_of_cases: number | null
        country_of_origin: string | null
        availability_status: string
        aux_info: string | null
        supplier_price_list: {
            artkey: number
            start_date: string
            end_date: string | null
            supplier_price_list_status: string | null
            supplier_price_list_description: string | null
        }
    }
    delivery_period: number | null
    maximum_quantity: number | null
    minimum_quantity: number | null
    price_per_case: number
    supplier: {
        artkey: number
        name: string
    }
}

interface UpdateTboOfferItemRequest {
    artkey: number
    case_artkey: number
    number_of_bottles: number
    best_before_date: string | null
    gift_box_type: string | null
    tax_label: string | null
    customs_status: string
    item_tags: string[]
    spli_artkey: number
    price_per_case: number | null
    delivery_period: number | null
    quantity: number | null
    minimum_quantity: number | null
}

export class PricelistApi {
    api = new Api()

    delete_tbo_from_pricelist(offer_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('pricelist.delete_tbo_offer_item', {
            key: offer_item_artkey,
        })
    }

    approve_tbo_offer_item(offer_item_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('pricelist.approve_tbo_offer_item', {
            offer_item_artkey: offer_item_artkey,
        })
    }

    update_tbo_offer_item(request: UpdateTboOfferItemRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('pricelist.update_tbo_offer_item', request)
    }
}
