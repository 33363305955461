import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {$s} from '@/app'

export interface BackToLinkAttrs {
    href: string
    text: string
}

export class BackToLinkButton extends MithrilTsxComponent<BackToLinkAttrs> {
    view(vnode: m.Vnode<BackToLinkAttrs>): m.Children {
        return (
            <button className={'btn btn-default'} type={'button'} onclick={() => m.route.set(vnode.attrs.href)}>
                <span className={'glyphicon glyphicon-arrow-left'} /> {vnode.attrs.text}
            </button>
        )
    }
}

export interface BackToListAttrs {
    href: string
}

export class BackToListButton extends MithrilTsxComponent<BackToLinkAttrs> {
    view(vnode: m.Vnode<BackToLinkAttrs>): m.Children {
        return <BackToLinkButton href={vnode.attrs.href} text={'Back to list'} />
    }
}

export interface LinkAttrs {
    href: string
    target?: string
    className?: string
    disabled?: boolean
}

/**
 * Create <a href. Default target = _blank.
 *
 * Usage:
 *  <Link href={`/stock/manage/${item.reference}`}>
 *     <span className={"fas fa-expand-arrows-alt"} />
 *     {item.reference}
 *  </Link>
 */
export class Link extends MithrilTsxComponent<LinkAttrs> {
    view(vnode: m.Vnode<LinkAttrs>): m.Children {
        const target = vnode.attrs.target || '_blank'
        let href = vnode.attrs.href
        if (!href.includes('account=')) {
            if (!href.includes('?')) {
                href = `${href}?account=${$s.session.account.slug}`
            } else {
                href = `${href}&account=${$s.session.account.slug}`
            }
        }
        return m(
            m.route.Link,
            {
                onclick: vnode.attrs.onclick,
                href: encodeURI(href),
                class: `no-click ${vnode.attrs.className || ''}`,
                rel: 'noreferrer noopener',
                disabled: vnode.attrs.disabled,
                target: target,
            },
            vnode.children,
        )
    }
}
