m = require 'mithril'
{or-list} = require 'prelude-ls'

api = require 'api.ls'
{account-icon-by-slug} = require 'accounts.ls'
confirmation = require '@/components/confirmation.ls'
{icon-button} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{icon} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{Modal} = require '@/components/modal/modal.ls'
{a, format-date, format-date-time} = require 'utils.ls'
{AccountSlug} = require '@bitstillery/common/account/account'
{ProcessManageOffer} = require './processes'
{OfferType, Priority} = require '@/models/offers'
{CloneOffer} = require './components/clone_offer.ls'
app = require('@/app')

module.exports = class OfferList
    ->
        @offers = new Collection do
            api_function_name: 'offer.get_offer_records'
            default_sort_by: 'start_date'
            default_sort_order: 'desc'
            filter_function: @is_match
            query_limit: 25
            additional_params: ~> do
                show_expired: @offers.show_expired!
                show_mine_only: @offers.show_mine_only!

        # Initialize filters with their default values, defined as attributes
        # of the collection; this preserves their values when navigating back
        # to this page.
        if typeof @offers.show_expired == 'undefined' then @offers.show_expired = window.prop false
        if typeof @offers.show_mine_only == 'undefined' then @offers.show_mine_only = window.prop false

        @search_input_ctrl = new SearchInput.controller do
            collection: @offers
            placeholder: 'Search for title, remark and/or creator.'

        @source_offer = window.prop null

    oncreate: ~>
        @offers.init!
        app.$m.common.observable.subscribe 'offer_updated', @, @offers.requery

    close_clone_offer_modal: ~>
        # Set the source_offer window.prop to null to stop the clone offer modal from
        # being rendered.
        @source_offer null

    delete_offer: (artkey) ~>
        data = do
            artkey: artkey
        api.call-and-then 'offer.delete_offer', data, do
            success: ~>
                app.$m.common.observable.broadcast 'offer_updated'
                app.notifier.notify 'Successfully deleted the offer.', 'success'
                m.route.set '/offer/offers'

    is_match: (offer, term, additional_params, keyword) ~>
        return or-list [
            offer.title.toLowerCase!indexOf(term) > -1
            offer.remark.toLowerCase!indexOf(term) > -1
            offer.created_by.profile.name.toLowerCase!indexOf(term) > -1
        ]

    toggle_active_only: (value) ~>
        if value?
            @offers.show_expired value
            @offers.requery!
        else
            @offers.show_expired!

    toggle_mine_only: (value) ~>
        if value?
            @offers.show_mine_only value
            @offers.requery!
        else
            @offers.show_mine_only!

    view: -> m '.c-offer-offers view process',
        m ProcessManageOffer, {
            active: 'list',
            context: {},
        }

        m '.step-content',

            m '.c-filter-group',
                SearchInput.view @search_input_ctrl
                inputs.checkbox @toggle_active_only, {label: 'Show expired offers'}
                inputs.checkbox @toggle_mine_only, {label: 'Show my offers only'}

            @offers.show_counter!

            m CollectionTable, do
                collection: @offers
                options:
                    search_table_style: true
                    sticky_header: true
                    with_buttons: false
                    autoscale: true
                    unique_name: 'offer_records'
                    onclick: (record, e) -->
                        m.route.set "/offer/offers/#{record.artkey}/details"

                columns:
                    do
                        width: 10
                        name: 'Title'
                        sort: true
                        field: 'title'
                    do
                        width: 10
                        name: 'Remark'
                        sort: true
                        field: 'remark'
                        ellipsis: true
                    do
                        width: 7
                        name: 'Start Date'
                        sort: true
                        field: 'start_date'
                        transform: format-date
                    do
                        width: 7
                        name: 'End Date'
                        sort: true
                        field: 'end_date'
                        transform: format-date
                    do
                        width: 10
                        name: 'Published on'
                        sort: true
                        field: 'published_timestamp'
                        transform: format-date-time
                    do
                        width: 20
                        name: 'Portal link'
                        field: 'hash'
                        classes: ['no-click']
                        function: (record) ~>
                            if record.published_timestamp
                                [
                                    m PortalOfferLink,
                                        account: AccountSlug.MSP
                                        offer_hash: record.hash
                                    m PortalOfferLink,
                                        account: AccountSlug.ETR
                                        offer_hash: record.hash
                                ]
                            else
                                'offer is not published yet'
                    do
                        width: 10
                        name: 'Creator'
                        field: 'created_by.profile.name'
                        sort: true
                        ellipsis: true
                    do
                        width: 10
                        name: 'Incoterm'
                        field: 'incoterm'
                        sort: true
                        ellipsis: true
                        function: (record) ~>
                            if record.incoterm
                                "#{record.incoterm} - #{record.incoterm_location}"
                            else
                                'default for relation'
                    do
                        width: 8
                        name: 'Offer type'
                        sort: true
                        field: 'offer_type'
                        transform: (value) ~> OfferType.all[value]
                    do
                        width: 8
                        name: 'Priority'
                        sort: true
                        field: 'priority'
                        transform: (value) ~> Priority.all[value]
                    do
                        width: 8
                        name: 'Actions'
                        header: ''
                        function: (record) ~>
                            m '.btn-toolbar.no-click' m '.btn-group',
                                icon-button 'pencil', do
                                    class: 'btn-default no-click'
                                    onclick: ~> m.route.set "/offer/offers/#{record.artkey}/details"
                                    title: 'Edit offer'
                                icon-button 'duplicate', do
                                    class: 'btn-default no-click'
                                    onclick: ~> @source_offer record
                                    title: 'Clone offer'
                                icon-button 'remove', do
                                    class: 'btn-default no-click'
                                    onclick: confirmation.show.bind(@, do
                                        title: 'Delete offer'
                                        message: 'Are you sure you want to delete this offer?'
                                        onconfirm: ~> @delete_offer record.artkey
                                        unique_name: 'offer_delete_confirm'
                                    )
                                    title: 'Delete offer'

            if @source_offer!
                m Modal, do
                    title: 'Clone offer'
                    onclose: @close_clone_offer_modal
                , m CloneOffer, do
                    source_offer: @source_offer
                    done: @close_clone_offer_modal
                    cancel: @close_clone_offer_modal


export class PortalOfferLink
    (vnode) ->
        @account = vnode.attrs.account
        @hash = vnode.attrs.offer_hash

    account_icon: ~>
        if @account == AccountSlug.MSI
            account-icon-by-slug AccountSlug.MSI, app.$m.data.msi_supplier_name
        else if @account == AccountSlug.MSP
            account-icon-by-slug AccountSlug.MSP, app.$m.data.msp_supplier_name
        else if @account == AccountSlug.A2BC
            account-icon-by-slug AccountSlug.A2BC, app.$m.data.a2bc_supplier_name
        else if @account == AccountSlug.ETR
            account-icon-by-slug AccountSlug.ETR, app.$m.data.a2bc_supplier_name

    link: ~>
        if [AccountSlug.MSI, AccountSlug.MSP].includes(@account)
            "#{app.config.portal_base_url}/#!/offers?offer_hash=#{@hash}"
        else if [AccountSlug.A2BC, AccountSlug.ETR].includes(@account)
            "#{app.config.a2bc_portal_base_url}/#!/offers?offer_hash=#{@hash}"

    view: ->
        m '',
            @account_icon!
            m 'span' ' '
            m 'a',
                id: 'msi-link'
                target: "_blank"
                href: @link!
            , @link!
            m 'span' ' '
            m 'span.copy-to-clipboard-icon',
                href: "#"
                onclick: ~> navigator.clipboard.writeText(@link!).then(~>
                    alert("Copied link to clipboard.")
                )
            , icon 'fa-copy'
