m = require 'mithril'
$ = require 'jquery'
{map} = require 'prelude-ls'
{to-string} = require 'utils.ls'
{deref} = require '@bitstillery/common/utils.ls'

export class MultiSelect
    (vnode) ->
        # @options is a list of [key, value] pairs.
        {@selected, @options, @disabled} = vnode.attrs

    onchange: (evt) ~>
        # jQuery .val returns a list of options for a select[multiple]
        selected = $ evt.target .val!
        # It returns null if nothing is selected.
        @selected if not selected then [] else selected

    view: ->
        rendered-options = \
            deref @options
            |> map (entry) ~>
                if typeof! entry != \Array
                then [entry, entry]
                else entry
            |> map ([key, value]) ~>
                m 'option' do
                    value: key
                    # Keys must always be a string, hence the to-string.
                    selected: if (to-string key) in @selected! then \selected
                , value

        m 'select' do
            multiple: \multiple
            oncreate: (vnode) ~>
                $ vnode.dom .multiselect({maxHeight: 200, enableCaseInsensitiveFiltering: true})
                vnode.dom.onchange = (evt) ~> @onchange evt
            onremove: (vnode) -> $ vnode.dom .multiselect 'destroy'
            disabled: @disabled
        , rendered-options
