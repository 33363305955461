import {Observable, of, Subject} from 'rxjs'
import {filter, mergeAll} from 'rxjs/operators'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api} from './api'
import {GetDestinationsResponse} from './fact2server_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from './factserver_generic'

/**
 * Caches the Destinations data for usage in drop down, search auto complete elements etc. This singleton
 * is available during the browser lifespan of the application. It waits on the
 * GetAllForDropDownResponse[]. This value is null if the data is not yet retrieved.
 */
export class DestinationsDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: DestinationsDropDownData | null = null
    /** Cached results, when available */
    private result: GetDestinationsResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetDestinationsResponse[]> = new Subject<GetDestinationsResponse[]>()

    private constructor() {
        this.api.get('discover/stock/locations')
            .subscribe({
                next: (response: GetDestinationsResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): DestinationsDropDownData {
        if (DestinationsDropDownData._instance === null) {
            DestinationsDropDownData._instance = new DestinationsDropDownData()
        }

        return DestinationsDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static destinations(): Observable<GetDestinationsResponse[]> {
        if (DestinationsDropDownData.instance().drop_down_data$.isStopped) {
            return of(DestinationsDropDownData.instance().result)
        }

        return DestinationsDropDownData.instance().drop_down_data$
    }

    /** Destination with artkey. */
    public static get(artkey: number): Observable<GetDestinationsResponse> {
        return DestinationsDropDownData.destinations().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item) => item.artkey === artkey),
        )
    }

    public static loendersloot(): Observable<GetDestinationsResponse> {
        return DestinationsDropDownData.destinations().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((destination) => destination.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY),
        )
    }
}
