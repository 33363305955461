{deref} = require '@bitstillery/common/utils'
{AccountSlug} = require '@bitstillery/common/account/account'

export account-icon = (account) ->
    return account-icon-by-slug(account.slug, account.name)

export account-icon-by-slug = (account_slug, account_name) ->
    account_slug = deref account_slug
    if account_slug == AccountSlug.A2BC
        icon-src = '/theme/img/discover/a2bc-favicon.ico'
    else if account_slug == AccountSlug.ETR
        icon-src = '/theme/img/discover/etr-favicon.ico'
    else if account_slug == AccountSlug.MSI
        icon-src = '/theme/img/discover/msi-favicon.ico'
    else if account_slug == AccountSlug.MSP
        icon-src = '/theme/img/discover/msp-favicon.ico'

    return m 'img', {
        className: 'account-slug-icon',
        src: icon-src,
        title: deref account_name
    }
