m = require 'mithril'
{map, compact} = require 'prelude-ls'
{a, match-term-in, maybe-map, prepend} = require 'utils.ls'
api = require 'api.ls'
app = require('@/app')


{icon-button, button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'


module.exports = class PortalQuotesList
    (vnode) ->
        @entities = new Collection do
            api_function_name: 'portal.quote.get_quotes'
            filter_function: @is-match
            query_limit: 25

        @search_input_ctrl = new SearchInput.controller do
            collection: @entities
            placeholder: 'Search for portal quote...'

    oncreate: ~>
        @entities.init!

    is-match: (quote, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            quote.title
            quote.content
        ]

    onremove: ~>
        @search_input_ctrl.onremove!

    mark_deleted: (artkey) ~>
        api.call 'portal.quote.mark_deleted' {artkey: artkey}, (resp) ~>
            if resp.success
                app.notifier.notify 'Successfully deleted quote', 'success'
                @entities.soft_delete(artkey)
            else
                app.$m.common.generic_error_handler!


    view: -> m '.c-portal-quotes' a do
        m '.btn-toolbar' a do
            button-with-icon 'Create Portal Quote', 'plus', do
                class: 'btn-default'
                onclick: ~> m.route.set '/portal/portal-quotes/create'

        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @entities
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'portal_quotes'
                onclick: (record, e) -->
                    m.route.set "/portal/portal-quotes/#{record.artkey}/edit"
            columns:
                do
                    width: 2
                    name: 'Name'
                    field: 'name'
                    sort: true
                do
                    width: 1
                    name: 'Image name'
                    field: 'image_name'
                    sort: true
                do
                    width: 8
                    name: 'Message'
                    field: 'content'
                    sort: true
                    ellipsis: true
                do
                    width: 2
                    name: 'Author'
                    field: 'creator.profile.name'
                    sort: true
                do
                    width: 1
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            icon-button 'pencil', do
                                class: 'btn-default no-click'
                                onclick: ~> m.route.set "/portal/portal-quotes/#{record.artkey}/edit"
                            icon-button 'remove', do
                                class: 'btn-danger no-click'
                                onclick: ~> @mark_deleted(record.artkey)
