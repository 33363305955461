$ = require 'jquery'
m = require 'mithril'


export class Popover
    oncreate: (vnode) ->
        data = do
            container: 'body'
            html: true
            animation: false
            content: vnode.attrs.content
            title: vnode.attrs.title || ''
            placement: (vnode.attrs.placement || 'top')
            trigger: \manual

        popover = $ vnode.dom .popover data

        if not vnode.attrs.always_show then popover
            ..on 'mouseenter' ->
                $ @ .popover 'show'
                $ '.popover' .css 'max-width', (vnode.attrs.max_width || 200) + 'px'
                $ '.popover' .on 'mouseleave' ~>
                    $ @ .popover 'hide'

            ..on 'mouseleave' ->
                setTimeout ->
                    if not $ '.popover:hover' .length
                        $ vnode.dom .popover 'hide'
                , 100
        else
            $ vnode.dom .popover 'show'

    onupdate: (vnode) ->
        # This is hacky and dependent on Bootstrap v3.
        # Another way to force the popover to update is
        # to destroy it and then re-create it...
        if popover = $ vnode.dom .data 'bs.popover' then
            popover
                ..options
                    ..content = vnode.attrs.content
                    ..title = vnode.attrs.title || ''

            # When the popover is visible, this is needed to
            # force the popover to update it's title and/or content.
            if popover .tip! .has-class 'in'
                $ vnode.dom .popover 'show'

    onremove: (vnode) ->
        $ vnode.dom .popover 'destroy'

    view: (vnode) ->
        vnode.children


export header_with_popover: (popover_content, header_content) ~>
    m 'span', m Popover, {title: '', content: popover_content}
    , header_content
