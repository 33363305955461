m = require 'mithril'
api = require '../../api.ls'
utils = require '../../utils.ls'
{all} = require 'prelude-ls'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

module.exports = class ProductMerge
    ->
        @products = window.prop []
        @product_artkeys = window.prop []
        @loading = window.prop false
        @query_products m.route.param 'p'

    query_products: (product_artkeys) ~>
        @product_artkeys product_artkeys.split(',')
        data = do
            product_artkeys: @product_artkeys!
        api.call 'product_management.get_products_and_bottles', data, @set_products

    merge_products: ~>
        @loading true
        data = do
            product_artkeys: @product_artkeys!
        api.call 'product_management.merge_products', data, @handle_merge

    handle_merge: (result) ~>
        @loading false
        if result.success
            app.$m.data.update_data_products true
            m.route.set '/data/products/' + result.product_artkey + '/merged'
        else
            app.$m.common.generic_error_handler 'Something went wrong'

    set_products: (result) ~>
        @products app.$m.products._create_products result.products

    view: ->
        a = -> [.. for &]
        m '.merge-product' a do
            m '.row#button-bar' m '.col-lg-12.btn-toolbar' a do
                m 'button.btn.btn-link' {type: "button", onclick: ~> m.route.set '/data/products'},
                (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

            m 'h1' 'Merge products'
            m '.row' m '.col-md-6' m '.alert.alert-info' \
                'Would you like to merge the following ', @products!length, ' products?'

            @products!map (product) ~>
                final_product = all (.artkey! >= product.artkey!), @products!
                m '.row' m '.col-md-6' m '.panel.panel-default' a do
                    m '.panel-heading' a do
                        m 'h4' product.name!
                        if final_product
                            m 'em' 'This will be the resulting product.'
                    m '.panel-body' a do
                        m '.col-xs-4' m 'label' 'Alcohol %'
                        m '.col-xs-4' m 'label' 'Size in cl'
                        m '.col-xs-4' m 'label' 'Refill status'
                        for bottle in product.bottles!
                            a do
                                m '.col-xs-4' (+bottle.alcohol_percentage!).toFixed(1)
                                m '.col-xs-4' (+bottle.volume!).toFixed(1)
                                m '.col-xs-4' bottle.refill_status!.toLowerCase!

            m '.row.mb-2' a do
                m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-default' \
                    {onclick: ~> m.route.set '/data/products'} 'Cancel'
                m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-success' \
                    {onclick: @merge_products, disabled: @loading!} \
                    if @loading! then m Spinner else 'Merge'
