m = require 'mithril'
{join, or-list} = require 'prelude-ls'
{filter, toArray, mergeAll} = require "rxjs/operators"
rxjs = require "rxjs"
api = require 'api.ls'
{Amount} = require '@bitstillery/common/components'
{button-with-icon, icon-button, text-button} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
confirmation = require '@/components/confirmation.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{Modal} = require '@/components/modal/modal.ls'
{header_with_popover} = require '@/components/popover/popover.ls'
{rank} = require '@/components/rank.ls'
{a, download_binary_file_from_base64_str, format-date, format-percentage, maybe-map, pluralize} = require 'utils.ls'
{RelationDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{current_account_slug} = require '@bitstillery/common/account/account'
{AddToSalesOrder} = require '../components/add_to_sales_order.ls'
{BulkUpdateField, CustomOfferItem, Offer, OfferType} = require '@/models/offers'
{EditCustomOfferItem} = require './components/edit_custom_offer_item.ls'
{EditPosition} = require './components/edit_position.ls'
{ProcessManageOffer} = require './processes'
{OfferApi} = require '@/factserver_api/offer_api'
{convert_from_currency_to_euro, convert_from_source_to_target, convert_from_euro_to_currency} = require '@/factserver_api/currencies'
{Supplier} = require '@/models/suppliers'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

module.exports = class OfferCustomProducts
    ->
        # Offer details.
        @offer = window.prop new Offer
        @offer_artkey = m.route.param 'artkey'
        @offer_api = new OfferApi()

        # Custom offer items.
        @custom_offer_items = new Collection do
            additional_params: @additional_params
            api_function_name: 'offer.get_custom_offer_items_for_offer'
            default_sort_by: 'position'
            default_sort_order: 'asc'
            query_limit: 25

        @preview_supplier_artkey = window.prop ''
        @preview_supplier = window.prop null
        @preview_currency = window.prop app.$s.currencies.default

        # Initialize filters with their default values. Define them as
        # attributes of the collection; this helps to preserve their values
        # when navigating back to this page.
        if not @custom_offer_items.supplier then @custom_offer_items.supplier = window.prop new Supplier

        # Initialize bulk update parameters.
        @bulk_update_mode = window.prop false
        @bulk_updates = {}
        @reset_bulk_update!

        @edit_position_for = window.prop null

        @email_batch_artkey = m.route.param 'email_batch'

        @price_analysis = window.prop false

        @item_to_add = window.prop null

        @saving = window.prop false
        @is_exporting = window.prop false

    oncreate: ~>
        @query_offer @offer_artkey

        app.$m.common.observable.subscribe 'offer_updated', @, @custom_offer_items.requery
        app.$m.common.observable.subscribe 'custom_offer_item_updated', @, @custom_offer_items.requery

    additional_params: ~>
        return do
            offer_artkey: @offer_artkey
            supplier_artkey: @preview_supplier_artkey!
            price_analysis: @price_analysis!

    always_special: ~>
        @offer!offer_type! == OfferType.SPECIAL

    close_add_to_sales_order: ~>
        @item_to_add null

    delete_custom_offer_item: (record) ~>
        record.is_deleting = true
        data = {record.artkey}
        api.call-and-then 'offer.delete_custom_offer_item', data, do
            success: ~>
                @custom_offer_items.requery!
                record.is_deleting = false
                app.notifier.notify 'Successfully deleted the custom offer item.', 'success'

    export_pricelist: ~>
        @is_exporting true
        @offer_api.export_preview_price_list(
            @additional_params!.supplier_artkey,
            @additional_params!.offer_artkey,
            "Offer #{format-date(new Date)}.xlsx",
        ).subscribe({
            next: ~>
                @is_exporting false
                m.redraw()
            error: ~>
                @is_exporting false
                m.redraw()
        })

    extract_rank: (record) ~>
        do
            rank: record.market_rank
            total: record.market_total

    header_with_popover: (popover_content, header_content) ~>
        m 'span', m Popover, {content: popover_content}, header_content

    margin_color_class: (value) ~>
        if value > 8.8
            '.analysis-good-color'
        else if value < 0
            '.analysis-bad-color'
        else
            ''

    move_custom_offer_item: (record, direction, callback) ~>
        data = do
            artkey: record.artkey
            direction: direction

        api.call-and-then 'offer.move_custom_offer_item', data, do
            success: callback

    query_offer: (artkey) ~>
        data = do
            artkey: artkey
            include_suppliers: true

        api.call-and-then 'offer.get_offer' data, do
            success: (resp) ~>
                @offer new Offer resp.result

                # If the offer is for exactly one supplier: directly set it as the "preview supplier".
                if resp.result.suppliers.length == 1
                    @set_supplier resp.result.suppliers[0].artkey

                # Now that the optional preview supplier is known: query the
                # custom offer items and their list prices.
                @custom_offer_items.init!

            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    set_supplier: (supplier_artkey) ~>
        @preview_supplier_artkey "#{supplier_artkey}"
        if supplier_artkey then
            data = do
                artkey: supplier_artkey
            api.call-and-then 'suppliers.get_supplier' data, do
                success: (resp) ~>
                    @preview_supplier resp.result
                    @preview_currency @preview_supplier!currency
        else
            @preview_supplier null
            @preview_currency app.$s.currencies.default
        @custom_offer_items.requery!

    # Helper function to create a virtual window.prop for form fields in the bulk
    # update mode using currying. Do so by providing its first 3 parameters in
    # the definition, leaving only `value` as parameter for the virtual prop.
    #
    # @param {string} field: One of `BulkUpdateField.all`.
    # @param {int} artkey: Artkey of the form field's custom offer item.
    # @param {*} original_value: The form field's original value when queried.
    # @param {*} value: Optional new value of the field.
    #
    # @return {*}: value for the form field.
    virtual_prop: (field, artkey, original_value, value) ~~>
        key = artkey.toString!

        # Update the virtual prop's value, if provided.
        if value?
            if value == original_value
                if key in Object.keys @bulk_updates[field]
                    delete @bulk_updates[field][key]
            else
                @bulk_updates[field][key] = value

        # Return the virtual prop's value.
        if key in Object.keys @bulk_updates[field]
            return @bulk_updates[field][key]
        else
            return original_value

    reset_bulk_update: ~>
        @bulk_update_mode false
        for field in BulkUpdateField.all
            @bulk_updates[field] = {}

    save_bulk_update: ~>
        api.call-and-then 'offer.bulk_update', @bulk_updates, do
            success: ~>
                @custom_offer_items.requery!
                app.notifier.notify 'Successfully updated the products.', 'success'
            final: ~>
                @reset_bulk_update!

    toggle_price_analysis: ~>
        @price_analysis not @price_analysis!
        @custom_offer_items.requery!

    # Determine the text of the publish button. It depends on whether the
    # offer has already been published or not.
    publish_button_text: ~>
        if @offer!published_timestamp!
            'Offer is published'
        else
            'Publish offer'

    # Determine the text of the email button. It depends on whether it will
    # also publish the offer.
    email_button_text: ~>
        if not @offer!published_timestamp!
            'Publish offer and compose new email message'
        else
            'Compose new email message'

    # This function is called after clicking one of the buttons to publish the
    # offer and optionally continue to the compose new email page.
    #
    # @param {bool} proceed: true to proceed to the next step.
    publish_offer: (proceed) ~>
        if not @offer!published_timestamp! and not @saving!
            @saving true

            data = do
                artkey: @offer!artkey!

            api.call-and-then 'offer.publish', data, do
                success: (resp) ~>
                    app.$m.common.observable.broadcast 'offer_updated'
                    app.notifier.notify "Successfully published offer \"#{@offer!title!}\".", 'success'
                    if proceed
                        m.route.set "/offer/offers/#{@offer_artkey}/email/create"
                    else
                        @offer new Offer resp.result
                        @saving false

                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
                    @saving false
        else if proceed and not @saving!
            # The offer is already published; just proceed to the next step.
            m.route.set "/offer/offers/#{@offer_artkey}/email/create"

    view: ~> m '.c-process-new-offer-step-4 view process',
        if @edit_position_for!
            modal-attrs = do
                title: "Change position for #{@edit_position_for!name}"
                onclose: ~> @edit_position_for null

            m Modal, modal-attrs,
                m EditPosition, do
                    custom_offer_item: @edit_position_for!
                    max: @custom_offer_items.total_number_of_items!
                    onchangedsuccessfully: ~> @edit_position_for null

        if @item_to_add!
            modal-attrs = do
                title: "Add #{@item_to_add!name} to sales order"
                onclose: @close_add_to_sales_order

            m Modal, modal-attrs,
                m AddToSalesOrder, do
                    custom_offer_item: @item_to_add!
                    on_added_item: @close_add_to_sales_order
                    supplier_artkey: @preview_supplier!artkey
                    supplier_currency: @preview_supplier!currency

        m ProcessManageOffer, {
            active: 'custom_products',
            context: {
                offer_artkey: @offer_artkey,
                email_batch_artkey: @email_batch_artkey
            }
        }

        m '.step-content',
            m '.c-filter-group',
                if @bulk_update_mode!
                    m '.btn-group',
                        button-with-icon 'Cancel bulk updates', 'ban-circle', do
                            class: 'btn-default'
                            onclick: @reset_bulk_update

                        button-with-icon 'Save bulk updates', 'fa-save', do
                            class: 'btn-success'
                            onclick: @save_bulk_update
                else
                    button-with-icon 'Open bulk update mode', 'pencil', do
                        class: 'btn-success'
                        onclick: ~> @bulk_update_mode true

                button-with-icon @publish_button_text!, 'glyphicon-globe', do
                    class: if @offer!published_timestamp! then 'btn-normal' else 'btn-success'
                    disabled: @saving! or @offer!published_timestamp!
                    onclick: ~> @publish_offer false
                    title: 'This button instantly publishes the offer, which makes the prices in this offer visible in the portal for the selected relations. It keeps you on this page.'

                button-with-icon @email_button_text!, 'glyphicon-envelope', do
                    class: 'btn-success'
                    disabled: @saving!
                    onclick: ~> @publish_offer true
                    title: 'This button takes you to the next page where you can compose an email message. If the offer is not already published, it will be published for you.'

                button-with-icon 'Show all products', 'glyphicon glyphicon-eye-open', do
                    class: 'btn-default'
                    disabled: @custom_offer_items.show_count % @custom_offer_items.limit !== 0
                    onclick: ~> @custom_offer_items.show_all!

            m '.c-filter-group',
                m '.filter-field vertical',
                    m 'label' 'Optionally select a relation to preview its list prices'
                    if @offer!suppliers
                        get_all_response$ = RelationDropDownData.relations()
                        if @offer!suppliers!length > 0
                            get_all_response$ = get_all_response$.pipe(
                                mergeAll(),
                                filter((relation) ~> @offer!suppliers!map((supplier) -> supplier.artkey).includes(relation.artkey)),
                                toArray(),
                            )

                        m RelationDropDown, do
                            selected_relation_artkey: @preview_supplier_artkey!
                            get_all_for_drop_down_response$: get_all_response$
                            onchange: (supplier_artkey) ~>
                                @set_supplier(supplier_artkey)

            m '.c-filter-group',
                if @is_exporting! then m Spinner else [
                    button-with-icon 'Preview offer for  relation', 'fa-file-excel',
                        class: 'btn-default'
                        disabled: ! @additional_params!.supplier_artkey
                        onclick: @export_pricelist
                ]

                button-with-icon 'Toggle price analysis (slow)', 'sort-by-order', do
                    class: if @price_analysis! then 'btn-info' else 'btn-default'
                    onclick: @toggle_price_analysis

            m '.btn-toolbar',
                button-with-icon 'Add product to offer', 'plus', do
                    class: 'btn-default mb-2'
                    'data-target': '#add_item'
                    'data-toggle': 'collapse'

            m '.collapse#add_item', m EditCustomOfferItem, do
                offer_artkey: @offer_artkey
                always_special: @always_special
                onsaved: (.reset!)
                supplier_artkey: @preview_supplier_artkey

            @custom_offer_items.show_counter!

            m CollectionTable, do
                collection: @custom_offer_items
                options:
                    search_table_style: true
                    sticky_header: true
                    with_buttons: false
                    autoscale: true
                    unique_name: 'offer_custom_offer_items'
                    onclick: (record, e) ~~>
                        if record.details_expanded! then
                            record.details_expanded false
                        else
                            record.details_expanded true
                    row_classes: (record) ~>
                        if record.excluded_for_supplier or record.quantity == 0 then
                            ['is-hidden']
                        else if @preview_supplier!
                            visibility = @preview_supplier!portal_customs_visibility
                            if visibility in app.$m.data.customs_status and record.customs_status != visibility
                                ['is-hidden']

                row_model: (row) ->
                    row with
                        custom_offer_item_instance: new CustomOfferItem row

                view_details: (record) ~>
                    m EditCustomOfferItem, do
                        custom_offer_item_instance: record.custom_offer_item_instance
                        offer_artkey: @offer_artkey
                        always_special: @always_special
                        supplier_artkey: @preview_supplier_artkey
                        onsaved: ->
                            record.details_expanded false

                columns:
                    do
                        width: 8
                        header: ''
                        name: 'Position actions'
                        function: (record) ~>
                            m '.btn-toolbar.no-click',
                                do
                                    icon-button 'triangle-top', do
                                        class: 'btn-default no-click'
                                        disabled: record.position == 1
                                        onclick: ~> @move_custom_offer_item record, 'up', @custom_offer_items.requery
                                        tabindex: if @bulk_update_mode! then -1
                                do
                                    icon-button 'triangle-bottom', do
                                        class: 'btn-default no-click'
                                        disabled: record.position == @custom_offer_items.items!length
                                        onclick: ~> @move_custom_offer_item record, 'down', @custom_offer_items.requery
                                        tabindex: if @bulk_update_mode! then -1
                    do
                        width: 6
                        name: 'Position'
                        sort: true
                        field: 'position'
                        function: (record) ~>
                            m '.btn-toolbar.no-click',
                                text-button record.position, do
                                    class: 'btn-default'
                                    onclick: ~> @edit_position_for record
                                    tabindex: if @bulk_update_mode! then -1
                                    title: 'Change position'
                    do
                        width: 4
                        name: 'Rank'
                        sort: true
                        field: 'market_score'
                        descending: true
                        default_visible: false
                        function: (record) ~>
                            if @price_analysis! and not @custom_offer_items.loading! then
                                rank @extract_rank(record)
                    do
                        width: 4
                        header: header_with_popover(
                            "#{app.$s.currencies.default} above average price"
                            a do
                                m 'span.glyphicon.glyphicon-stats'
                                m 'span' ' €'
                        )
                        name: "Above average (#{app.$s.currencies.default})"
                        sort: true
                        field: 'avg_competitor_diff'
                        classes: ['price']
                        descending: true
                        default_visible: false
                        function: (record) ~>
                            if @price_analysis! and not @custom_offer_items.loading! then
                                m Amount, do
                                    amount: +record.avg_competitor_diff
                                    currency: ~> app.$s.currencies.default
                                    display_currency: app.$s.currencies.default
                    do
                        width: 4
                        header: header_with_popover(
                            "#{app.$s.currencies.default} above average in %"
                            a do
                                m 'span.glyphicon.glyphicon-stats'
                                m 'span' ' %'
                        )
                        name: 'Percent above average price'
                        sort: true
                        field: 'avg_competitor_diff_percentage'
                        classes: ['number']
                        descending: true
                        default_visible: false
                        function: (record) ~>
                            if @price_analysis! and not @custom_offer_items.loading! then
                                "#{(+record.avg_competitor_diff_percentage).toFixed(1)}%"
                    do
                        width: 20
                        name: 'Productㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ' # This is a hack to increase the minimum column width
                        sort: true
                        field: 'name'
                        ellipsis: true
                    do
                        width: 6
                        name: 'Category'
                        sort: true
                        field: 'category'
                        ellipsis: true
                        function: (record) ->
                            if record.category
                                m 'span.text-capitalize' record.category
                            else
                                m 'span' '-'
                    do
                        width: 8
                        name: 'TBO supplier'
                        ellipsis: true
                        function: (record) ->
                            if record.supplier and record.supplier.name then
                                record.supplier.name
                            else
                                '-'
                    do
                        width: 4
                        name: 'Btl / cs'
                        sort: true
                        field: 'number_of_bottles'
                        default_visible: false
                        classes: ['number']
                    do
                        width: 4
                        name: 'Size'
                        sort: true
                        field: 'volume'
                        default_visible: false
                        classes: ['number']
                        function: (record) ->
                            m 'span' (+record.volume).toFixed(1), 'cl'
                    do
                        width: 4
                        name: 'Alc %'
                        sort: true
                        field: 'alcohol_percentage'
                        default_visible: false
                        classes: ['number']
                        function: (record) ->
                            m 'span' (+record.alcohol_percentage).toFixed(1), '%'
                    do
                        width: 3
                        name: 'Ref'
                        sort: true
                        field: 'refill_status'
                        default_visible: false
                    do
                        width: 12
                        name: 'Specs'
                        function: (record) ->
                            specs = []
                            specs.push record.number_of_bottles
                            specs.push (+record.volume).toFixed(1)
                            specs.push (+record.alcohol_percentage).toFixed(1)
                            specs.push record.refill_status
                            join ' / ' specs
                    do
                        width: 4
                        name: 'GB'
                        sort: true
                        field: 'gift_box_type'
                        default_visible: false
                        ellipsis: true
                    do
                        width: 4
                        name: 'Tax Label'
                        sort: true
                        field: 'tax_label'
                        default_visible: false
                        ellipsis: true
                    do
                        width: 5
                        header: 'BBD'
                        name: 'Best before date'
                        sort: true
                        field: 'best_before_date'
                        default_visible: false
                        transform: maybe-map format-date
                    do
                        width: 8
                        name: 'Features'
                        function: (record) ->
                            features = []
                            if record.gift_box_type
                                features.push record.gift_box_type
                            if record.tax_label
                                features.push record.tax_label
                            if record.best_before_date
                                features.push "BBD: #{format-date record.best_before_date}"
                            if record.item_tags
                                features.push "#{join ' / ' app.$m.data.item_tag.get_tag_names record.item_tags}"
                            join ' / ' features
                    do
                        width: 3
                        header: 'Cus.'
                        name: 'Customs status'
                        sort: true
                        field: 'customs_status'
                    do
                        width: 8
                        name: 'Stock value'
                        sort: true
                        field: 'euro_total_stock_value'
                        classes: ['price']
                        default_visible: false
                        function: (record) ->
                            if record.offer_item_type != 'tbo'
                                m Amount, do
                                    amount: +record.euro_total_stock_value
                                    currency: app.$s.currencies.default
                            else
                                '-'
                    do
                        width: 2
                        name: 'Number of cases in stock'
                        header: icon-with-popover do
                            icon-id: 'home'
                            content: 'Number of cases in stock'
                        sort: true
                        field: 'number_of_cases_in_stock'
                        classes: ['number']
                        default_visible: false
                    do
                        width: 2
                        name: 'Number of cases in purchase'
                        header: icon-with-popover do
                            icon-id: 'shopping-cart'
                            content: 'Number of cases in purchase'
                        sort: true
                        field: 'number_of_cases_in_purchase'
                        classes: ['number']
                        default_visible: false
                    do
                        width: 2
                        name: 'Number of cases in sales'
                        header: icon-with-popover do
                            icon-id: 'screenshot'
                            content: 'Number of cases in sales'
                        sort: true
                        field: 'number_of_cases_in_sales'
                        classes: ['number']
                        default_visible: false
                    do
                        width: 2
                        name: 'Number of cases available'
                        header: icon-with-popover do
                            icon-id: 'fa-shield-alt'
                            content: 'Number of cases available'
                        sort: true
                        field: 'number_of_cases_available'
                        classes: ['number']
                        default_visible: false
                    do
                        width: 3
                        name: 'Throughput'
                        header: icon-with-popover do
                            icon-id: 'flash'
                            content: 'Throughput'
                        field: 'item_throughput'
                        sort: true
                        classes: ['number']
                        default_visible: false
                        transform: maybe-map format-percentage
                    do
                        width: 2
                        header: 'MOQ'
                        name: 'Minimum Order Quantity'
                        sort: true
                        field: 'minimum_quantity'
                        classes: ['number']
                        function: (record) ->
                            if record.minimum_quantity? then
                                record.minimum_quantity
                            else
                                '-'
                    do
                        width: 4
                        header: 'List qty'
                        name: 'List quantity'
                        sort: true
                        field: 'quantity'
                        classes: ['number']
                        function: (record) ~>
                            if @bulk_update_mode!
                                inputs.number(
                                    @virtual_prop(
                                        BulkUpdateField.QUANTITY_UPDATES,
                                        record.artkey,
                                        record.quantity,
                                    ),
                                    do
                                        min: 1
                                        required: true
                                        class: if @virtual_prop(
                                            BulkUpdateField.QUANTITY_UPDATES,
                                            record.artkey,
                                            record.quantity,
                                        )! != record.quantity then 'changed'
                                )
                            else if record.quantity?
                                record.quantity
                            else
                                '-'
                    do
                        width: 8
                        name: 'Avg purchase / cs'
                        sort: true
                        field: 'avg_purchase_price'
                        classes: ['price']
                        function: (record) ->
                            if record.avg_purchase_price then
                                m Amount, do
                                    amount: record.avg_purchase_price
                                    currency: app.$s.currencies.default
                            else
                                '-'
                    do
                        width: 8
                        name: 'Original price / cs'
                        sort: true
                        field: 'original_price'
                        classes: ['price']
                        function: (record) ~>
                            if record.original_price then
                                m Amount, do
                                    amount: +record.original_price
                                    currency: app.$s.currencies.default
                            else
                                '-'
                    do
                        width: 8
                        name: 'Current portal price / cs'
                        sort: true
                        field: 'current_portal_price'
                        classes: ['price']
                        function: (record) ~>
                            if record.current_portal_price then
                                m Amount, do
                                    amount: +record.current_portal_price
                                    currency: record.supplier_currency
                                    rate: app.$s.currencies.exchange_rates[record.supplier_currency].portal_rate
                            else
                                '-'
                    do
                        width: 17
                        name: 'Custom price / cs'
                        sort: true
                        field: 'custom_price_per_case'
                        classes: ['price']
                        function: (record) ~>
                            if @bulk_update_mode!
                                # Determine prices.
                                db_price = record.custom_price_per_case
                                input_price_prop = @virtual_prop(
                                    BulkUpdateField.PRICE_UPDATES,
                                    record.artkey,
                                    db_price,  # initialize input with the price in the database
                                )
                                input_price = input_price_prop!

                                # Render price input.
                                m MoneyInput,
                                    value: input_price_prop!
                                    on_value: (price) -> input_price_prop price
                                    currency: record.currency
                                    additional_class:  if (input_price == '' and db_price is not null) \
                                            or (input_price not in ['', null] and db_price is null) \
                                            or +input_price != +db_price \
                                            then 'changed'
                            else if record.custom_price_per_case
                                m Amount, do
                                    amount: +record.custom_price_per_case
                                    currency: record.currency
                                    rate: app.$s.currencies.exchange_rates[record.currency].portal_rate
                            else
                                '-'
                    do
                        width: 6
                        name: 'Margin %'
                        classes: ['price']
                        function: (record) ~>
                            if record.avg_purchase_price
                                # Determine price.
                                if @bulk_update_mode!
                                    # In bulk update mode, the price is either
                                    # the price in the input, or the record's
                                    # original price.
                                    input_price = @virtual_prop(
                                        BulkUpdateField.PRICE_UPDATES,
                                        record.artkey,
                                        record.custom_price_per_case,
                                    )!
                                    if input_price in ['', null]
                                        price = record.original_price
                                    else
                                        price = convert_from_currency_to_euro(
                                            input_price,
                                            record.currency,
                                            app.$s.currencies.exchange_rates[record.currency].portal_rate,
                                        )
                                else if record.custom_price_per_case
                                    # A custom price has been defined; convert
                                    # it to Euro and use it.
                                    price = convert_from_currency_to_euro(
                                        record.custom_price_per_case,
                                        record.currency,
                                        app.$s.currencies.exchange_rates[record.currency].portal_rate,
                                    )
                                else
                                    # Otherwise, use the current portal price.
                                    price = convert_from_currency_to_euro(
                                        record.current_portal_price,
                                        @preview_currency!,
                                        app.$s.currencies.exchange_rates[@preview_currency!].portal_rate,
                                    )

                                # Determine margin.
                                margin_percentage = (
                                    (price - record.avg_purchase_price) / record.avg_purchase_price
                                ) * 100

                                m "span.#{@margin_color_class margin_percentage}" "#{margin_percentage.toFixed(2)}%"
                            else
                                '-'
                    do
                        width: 8
                        name: 'List price / cs'
                        sort: true
                        field: 'list_price'
                        classes: ['price']
                        function: (record) ~>
                            m Amount, do
                                amount: +record.list_price
                                currency: record.supplier_currency
                    do
                        width: 8
                        name: 'List price / btl'
                        classes: ['price']
                        default_visible: false
                        function: (record) ~>
                            m Amount, do
                                amount: +record.list_price / +record.number_of_bottles
                                currency: record.supplier_currency
                                rate: app.$s.currencies.exchange_rates[record.supplier_currency].portal_rate
                    do
                        width: 4
                        name: 'Delivery period'
                        sort: true
                        field: 'delivery_period'
                        classes: ['number']
                        function: (record) ->
                            if record.delivery_period? then
                                record.delivery_period + ' ' + pluralize record.delivery_period, 'week', 'weeks'
                            else
                                '-'
                    do
                        width: 8
                        name: 'Item type'
                        sort: true
                        field: 'offer_item_type'
                        transform: (type) ->
                            | type == 'tbo' => 'TBO'
                            | otherwise => type.capitalizeFirstLetter!
                    do
                        width: 12
                        name: 'Remark'
                        sort: true
                        field: 'remark'
                        ellipsis: true
                    do
                        width: 4
                        name: 'Special'
                        sort: true
                        field: 'is_special'
                        function: (record) ~>
                            if @always_special!
                                m '.disabled-table-column', icon-with-popover do
                                    icon-id: 'ok'
                                    content: 'The entire offer is special.'
                            else if @bulk_update_mode!
                                m 'form-group.no-click' inputs.checkbox(
                                    @virtual_prop(
                                        BulkUpdateField.IS_SPECIAL_UPDATES,
                                        record.artkey,
                                        record.is_special,
                                    ), do
                                        className: if @virtual_prop(
                                            BulkUpdateField.IS_SPECIAL_UPDATES,
                                            record.artkey,
                                            record.is_special,
                                        )! != record.is_special then 'changed'
                                )
                            else if record.is_special
                                icon-with-popover do
                                    icon-id: 'ok'
                                    content: 'This product is a special offer.'
                    do
                        width: 2
                        name: 'Custom product icon'
                        header: icon 'glyphicon-cog'
                        function: (record) ->
                            if not record.offer_item_artkey then
                                icon-with-popover do
                                    icon-id: 'glyphicon-cog'
                                    title: 'Custom product'
                                    content: 'This product has been added manually.'
                    do
                        width: 2
                        name: 'Product photo icon'
                        header: icon 'fa-image'
                        field: 'has_product_photo'
                        function: (record) ->
                            if record.has_product_photo then
                                icon-with-popover do
                                    icon-id: 'fa-image'
                                    title: 'Product photo'
                                    content: 'This product has one or more photos.'
                    do
                        width: 15
                        name: 'Actions'
                        header: ''
                        function: (record) ~>
                            if record.is_deleting
                                m Spinner
                            else
                                m '.btn-toolbar.no-click' m '.btn-group',
                                    icon-button if record.details_expanded! then 'ban-circle' else 'pencil', do
                                        title: if record.details_expanded! then 'Close form' else 'Edit product'
                                        class: 'btn-default no-click'
                                        onclick: ~>
                                            if record.details_expanded! then
                                                record.details_expanded false
                                            else
                                                record.details_expanded true
                                        tabindex: if @bulk_update_mode! then -1
                                    icon-button 'remove', do
                                        title: 'Remove product'
                                        class: 'btn-danger no-click'
                                        onclick: ~> @delete_custom_offer_item record
                                        tabindex: if @bulk_update_mode! then -1
                                    m AddToSalesButton, do
                                        record: record
                                        custom_offer_items: @custom_offer_items
                                        item_to_add: @item_to_add
                                        relation: @preview_supplier


AddToSalesButton = (initial_vnode) ->
    record = initial_vnode.attrs.record
    custom_offer_items = initial_vnode.attrs.custom_offer_items
    item_to_add = initial_vnode.attrs.item_to_add
    relation = initial_vnode.attrs.relation

    get_add_to_sales_button_disabled = ->
        if relation! == null or record.excluded_for_supplier or record.quantity == 0
            'disabled'

    get_add_to_sales_button_title = ->
        if not relation!
            'Select a relation first'
        else if record.excluded_for_supplier
            'This product may not be sold to the selected relation'
        else if record.quantity == 0
            'This product is sold out'
        else
            'Add to sales order'

    return do
        view: (vnode) -> icon-button 'screenshot', do
            title: get_add_to_sales_button_title!
            class: 'btn-default'
            disabled: get_add_to_sales_button_disabled!
            onclick: ~> item_to_add record
