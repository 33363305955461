import {Observable} from 'rxjs'

import {PurchaseFigures} from '../dashboard/widgets/widget'
import {NamedLocation} from '../components/adminlte/leaflet_map'

import {Api, FactserverRequestData} from './api'

import {DashboardStatisticsResponse, SalesOrderTurnoverResponse} from '@/factserver_api/fact2server_api.ts'

export interface SalesFiguresRequest extends FactserverRequestData {
    period_from: string
    period_till: string
    sales_manager_artkey: number | null
}

export interface SalesFigures {
    turnover_in_euro: number
    number_of_cases: number
    average_margin_percentage: number
    total_margin: number
    count: number
}

export interface SalesFiguresSalesOrder {
    reference: string
    supplier_name: string
    euro_turnover: number
    sales_order_status: string
}

export interface SalesFiguresResponse {
    invoiced: SalesFigures
    pending: SalesFigures
    saved: SalesFigures
    confirmed: SalesFigures
    waiting_on_tbo: SalesFigures
    top_sales_orders: SalesFiguresSalesOrder[]
    latest_sales_orders: SalesFiguresSalesOrder[]
}

export interface PurchaseFiguresRequest extends FactserverRequestData {
    period_from?: string
    period_till?: string
    sales_manager_artkey: number | null
}

export interface PurchaseFiguresResponse {
    confirmed: PurchaseFigures
    saved: PurchaseFigures
    overdue: PurchaseFigures
    stock: {
        number_of_cases_available: number
        number_of_cases_in_stock: number
        number_of_cases_in_sales: number
        number_of_cases_in_purchase: number
        number_of_cases_in_transit: number
        value_available: string
        value_in_stock: string
        value_in_sales: string
        value_in_purchase: string
        value_in_transit: string
    }
    open_tbos: number
    confirmed_tbos: number
    to_confirm_tbos: number
    ordered_tbos: number
}

export interface PortalSalesResponse {
    portal_sales: SalesFigures
    non_portal_sales: SalesFigures
}

export interface RelationFiguresRequest extends FactserverRequestData {
    sales_manager_artkey: number | null
    period_from: string
    period_till: string
}

export interface TopRelation {
    name: string
    turnover: number
    margin: number
}

export interface ClientStatus {
    client_status: string
    count: number
}

export interface RelationLocation {
    artkey: number
    is_buyer: boolean
    is_supplier: boolean
    name: string
    longitude: string
    latitude: string
    checked_on: string
}

export interface RelationFiguresResponse {
    new_relations_count: number
    top_relations: TopRelation[]
    client_statuses: ClientStatus[]
    locations: RelationLocation[]
}

export interface PortalSessionPerDay {
    day: string
    count: number
}

export interface PortalUserSessionRequest extends FactserverRequestData {
    period_from: string
    period_till: string
}

export interface PortalUserSessionResponse {
    number_of_sessions: number
    portal_pending: number
    euro_total_portal_orders: number
    euro_total_non_portal_orders: number
    sessions_per_day: PortalSessionPerDay[]
}

export interface MostPopularPage {
    page: string
    hits: number
}

export interface PortalUserActivitiesRequest extends FactserverRequestData {
    period_from: string
    period_till: string
}

export interface PortalUserActivitiesResponse {
    page_views: number
    most_popular: MostPopularPage[]
}

export interface PortalUserSessionLocationsRequest extends FactserverRequestData {
    period_from: string
    period_till: string
}

export interface YearSalesIndexesResponse {
    turnover: {
        target: string
        index: string
        invoiced: string
    }
    margin: {
        target: string
        index: string
        invoiced: string
    }
    latest_month_targets: {
        turnover: string
        gross_margin_percentage: string
    }
    latest_month_confirmed_and_saved: {
        count: number
        turnover_in_euro: string
        number_of_cases: number
        total_margin: string
        average_margin_percentage: string
    }
    latest_month_invoiced: {
        count: number
        turnover_in_euro: string
        number_of_cases: number
        total_margin: string
        average_margin_percentage: string
    }
}

export interface UsersStatusTodayResponse {
    statuses: UserStatusObj[]
}

export interface UserStatusObj {
    user_name: string
    user_artkey: number
    status: string
    standard_schedule: boolean
}

export class DashboardApi {
    api = new Api()

    sales_figures(sales_figures_request: SalesFiguresRequest): Observable<SalesFiguresResponse> {
        return this.api.post_request('dashboard.sales.figures', sales_figures_request)
    }

    sales_statistics(
        user_artkey: number | null, account_slug: string, date_ranges_str: string,
    ): Observable<DashboardStatisticsResponse> {
        let request_url = `discover/dashboard/statistics?relation_type=Sales&date_ranges_str=${date_ranges_str}&account_slug=${account_slug}`

        if (user_artkey) {
            request_url = request_url.concat(`&account_manager_artkey=${user_artkey}`)
        }

        return this.api.get(request_url)
    }

    purchase_statistics(
        user_artkey: number | null, account_slug: string, date_ranges_str: string,
    ): Observable<DashboardStatisticsResponse> {
        let request_url = `discover/dashboard/statistics?relation_type=Purchase&date_ranges_str=${date_ranges_str}&account_slug=${account_slug}`

        if (user_artkey) {
            request_url = request_url.concat(`&account_manager_artkey=${user_artkey}`)
        }

        return this.api.get(request_url)
    }

    year_sales_indexes(): Observable<YearSalesIndexesResponse> {
        return this.api.post_request('dashboard.sales.year_sales_indexes_for_account', {})
    }

    portal_sales_figures(sales_figures_request: SalesFiguresRequest): Observable<PortalSalesResponse> {
        return this.api.post_request('dashboard.portal.portal_sales_figures', sales_figures_request)
    }

    relations_figures(relation_figures_request: RelationFiguresRequest): Observable<RelationFiguresResponse> {
        return this.api.post_request('dashboard.relations.relations_figures', relation_figures_request)
    }

    purchase_figures(purchase_figures_request: PurchaseFiguresRequest): Observable<PurchaseFiguresResponse> {
        return this.api.post_request('dashboard.purchase.figures', purchase_figures_request)
    }

    portal_user_sessions(portal_user_session_request: PortalUserSessionRequest): Observable<PortalUserSessionResponse> {
        return this.api.post_request('dashboard.portal.portal_user_sessions', portal_user_session_request)
    }

    portal_user_activities(request: PortalUserActivitiesRequest): Observable<PortalUserActivitiesResponse> {
        return this.api.post_request('dashboard.portal.portal_user_activities', request)
    }

    portal_user_session_location(request: PortalUserSessionLocationsRequest): Observable<NamedLocation[]> {
        return this.api.post_request('dashboard.portal.portal_user_session_locations', request)
    }

    get_users_status_today(): Observable<UsersStatusTodayResponse> {
        return this.api.post_request('user_schedules.get_users_status_today', {})
    }

    get_sales_order_turnover_data(user_artkey?: number | null): Observable<SalesOrderTurnoverResponse> {
        return this.api.get('discover/dashboard/sales-orders/turnover' + (user_artkey ? `?sales_manager_artkey=${user_artkey}` : ''))
    }
}
