m = require 'mithril'
{map, compact} = require 'prelude-ls'

{account-icon} = require 'accounts.ls'
{match-term-in, maybe-map, format-date-time} = require 'utils.ls'

{blank-link, icon-link, link} = require '@/components/link.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'


module.exports = class PortalSearchLog
    (vnode) ->
        @entities = new Collection do
            api_function_name: 'portal.search.get_logs'
            filter_function: @is-match
            query_limit: 50
            default_sort_by: 'timestamp'
            default_sort_order: 'desc'

        @search_input_ctrl = new SearchInput.controller do
            collection: @entities
            placeholder: 'Search for portal user...'

    oncreate: ~>
        @entities.init!

    is-match: (log, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            log.portal_user.name
            log.portal_user.contact_person.name
            log.portal_user.contact_person.supplier.name
            log.search_terms
        ]

    onremove: ~>
        @search_input_ctrl.onremove!

    terms_to_comma_separated: (record) ~>
        return JSON.parse(record.search_terms).join(',')

    view: -> m '.c-portal-search-log view',
        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @entities
            options:
                sticky_header: true
                autoscale: true
                unique_name: 'portal_search_log'
            columns:
                do
                    width: 4
                    name: 'Username'
                    field: 'portal_user'
                    sort: true
                    transform: (portal_user) ~>
                        link "/portal/portal-users/#{portal_user.artkey}/edit", portal_user.name, {'class': 'no-click'}
                do
                    width: 12
                    name: 'Contact person'
                    sort: false
                    field: 'portal_user.contact_person'
                    transform: (contact_person) ~>
                        link "/crm/relations/#{contact_person.supplier.artkey}/contact-persons/#{contact_person.artkey}/edit", contact_person.name, {'class': 'no-click'}
                do
                    width: 8
                    name: 'Relation'
                    sort: false
                    field: 'portal_user.contact_person.supplier'
                    transform: (supplier) ~> [
                        m 'span.mr-05' account-icon supplier.sales_account
                        link "/crm/relations/#{supplier.artkey}/edit", supplier.name, {'class': 'no-click'}
                    ]
                do
                    width: 5
                    name: 'Search moment'
                    sort: true
                    field: 'timestamp'
                    transform: maybe-map format-date-time
                do
                    width: 12
                    name: 'Search'
                    sort: false
                    field: 'search_terms'
                    function: @terms_to_comma_separated
                do
                    width: 4
                    name: 'Links'
                    sort: false
                    field: 'search_terms'
                    function: (record) ~>
                        final_terms = @terms_to_comma_separated record
                        return m 'span',
                            icon-link "/offer/relation-dashboard?r=#{record.portal_user.contact_person.supplier.artkey}&q=#{final_terms}", "piggy-bank"
                            ' '
                            icon-link "/market/discover?q=#{final_terms}", "search"
                do
                    width: 6
                    name: 'Number of search results'
                    sort: true
                    field: 'number_of_results'
