m = require 'mithril'
{map, odd, head, reject, slice, or-list} = require 'prelude-ls'
utils = require 'utils.ls'
api = require 'api.ls'

{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
confirmation = require '@/components/confirmation.ls'
fixed-table-header = require '@/components/table/fixed-header.ls'
{Spinner} = require '@bitstillery/common/components'

module.exports = class CommentTemplate
    ->
        @comment_templates = new Collection do
            api_function_name: 'comment_templates.get_all'
            filter_function: @is_match
            query_limit: 25
            sort_order: [
               {'name': 'title', 'direction': 'asc'}
            ]
            default_sort_by: 'title'
            default_sort_order: 'asc'

        @search_input_ctrl = new SearchInput.controller do
            collection: @comment_templates
            placeholder: 'Search for title and/or body...'

    oncreate: ~>
        @comment_templates.init!

    is_match: (comment_template, term, additional_params, keyword) ~>
        return or-list [
            comment_template.title.toLowerCase!indexOf(term) > -1,
            comment_template.body.toLowerCase!indexOf(term) > -1
        ]

    select_comment_template: (artkey) ->
        m.route.set '/data/commenttemplates/' + artkey + '/edit'

    delete_comment_template: (artkey) ~>
        data = {artkey}
        api.call 'comment_templates.delete_comment_template', data, (resp) ~>
            @comment_templates.soft_delete artkey

    onremove: ~>
        @search_input_ctrl.onremove!


    view: -> m '.c-comment-templates view',
        m '.btn-toolbar',
            m 'button.btn.btn-default' {type: 'button', onclick: ~> m.route.set '/data/commenttemplates/create'},
                (m 'span.glyphicon.glyphicon-plus'), ' Create Comment template'

        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        fixed-table-header.with-buttons m 'table.table.search-table.clickable',
            m 'thead.thead-default' m 'tr',
                m 'th' {onclick: @comment_templates.sort.bind(@, 'title')} 'Title ',
                    @comment_templates.sort_icon 'title'
                m 'th' {onclick: @comment_templates.sort.bind(@, 'body')} 'Body ',
                    @comment_templates.sort_icon 'body'
                m 'th' ''
            m 'tbody',
                @comment_templates.search_result!map (comment_template, index) ~>
                    m 'tr' { class: if odd index then 'odd' else 'even' },
                        m 'td.col-sm-3' {onclick: ~> @select_comment_template comment_template.artkey, \
                                         style: 'cursor: pointer'} comment_template.title
                        m 'td.col-sm-8' {onclick: ~> @select_comment_template comment_template.artkey, overflow: 'ellipsis'} comment_template.body
                        m 'td.btn-toolbar',
                            m '.btn-group' m 'button.btn.btn-danger' {type: "button", \
                                onclick: confirmation.show.bind(@, do
                                    title: 'Delete comment template'
                                    message: 'Are you sure you want to delete this comment template?'
                                    onconfirm: ~> @delete_comment_template(comment_template.artkey)
                                    unique_name: 'comment_template_item_delete_confirm'
                            )}, m 'span.glyphicon.glyphicon-remove'

                if not @comment_templates.search_result!length and not @comment_templates.loading!
                    m 'tr' m 'td' {colspan: '100%'} 'No results found.'
                if @comment_templates.loading!
                     m 'tr' m 'td' {colspan: '100%'} m Spinner
                if @comment_templates.can_show_more_items! then m 'tr' m 'td' {colspan: '100%'} m 'button.btn.btn-info' \
                    {onclick: @comment_templates.show_more, id: 'show-more'} 'Show more results.'
