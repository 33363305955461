m = require 'mithril'
{map} = require 'prelude-ls'
{a, prevent-default} = require 'utils.ls'
api = require 'api.ls'
app = require('@/app')

{button-with-icon} = require '@/components/buttons.ls'
{PortalTip} = require 'portal/models.ls'
inputs = require '@/components/inputs'


module.exports = class PortalTipsEdit
    ->
        @tip = new PortalTip

        @create = false

        artkey = m.route.param 'artkey'
        if artkey
            @query_tip artkey
        else #we are creating a new one
            @create = true

    query_tip: (artkey) ~>
        api.call 'portal.tip.get_single_tip' {artkey: artkey}, @set_tip

    set_tip: (resp) ~>
        if resp.success
            try
                @tip = new PortalTip resp.result
            catch
                app.$m.common.generic_error_handler e
        else
            app.notifier.notify 'Unknown tip.', 'danger'
            m.route.set '/portal/portal-tips'

    save: (e) ~>
        e.preventDefault!

        data = do
            artkey: @tip.artkey!
            title: @tip.title!
            content: @tip.content!
        api.call 'portal.tip.create_or_update_tip' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            if @create then
                app.notifier.notify "Successfully created new portal tip '#{@tip.title!}'.", 'success'
                m.route.set '/portal/portal-tips/' + result.artkey + '/edit'
            else
                app.notifier.notify 'Successfully updated tip.', 'success'


    view: -> m '.c-tips view' a do
        m '.btn-toolbar' a do
            m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/portal/portal-tips'},
             (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

        m 'form.flex-form' {onsubmit: @save},
            m '.fieldset',
                m '.field' a do
                    m 'label' {for: 'tip_title'} 'Title'
                    m 'input.form-control' {id: 'tip_title', type: 'text', \
                        required: true, \
                        placeholder: 'tip title', value: @tip.title! or '', \
                        oninput: (ev) ~> @tip.title ev.target.value}
                m '.field' a do
                    m 'label' {for: 'tip_content'} 'Message'
                    m 'textarea.form-control' {id: 'tip_content', rows: 15, \
                        placeholder: 'tip message', value: @tip.content! or '', \
                        oninput: (ev) ~> @tip.content ev.target.value}

            m 'button.btn.btn-success.btn-submit' if @create then 'Create Tip' else 'Update Tip'
