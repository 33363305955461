import m from 'mithril'

import {GenericList} from '../generic/generic_list'

interface LoenderslootTag {
    code: string
    description: string
    item_tag: {
        name: string
    }
}

const LoenderslootTagList: m.ClosureComponent<void> = () => {
    const is_match = (loendersloot_tag: LoenderslootTag, term: string): boolean => {
        return [
            loendersloot_tag.code.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            loendersloot_tag.description.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            loendersloot_tag.item_tag.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
        ].some(
            (element) => element,
        )
    }

    return {
        view(): m.Children {
            return (
                <GenericList<LoenderslootTag>
                    readable_entity_name="loendersloot tag"
                    base_url="/data/loendersloot-tags/"
                    list_api_call="loendersloot.tags.get_all_loendersloot_tags"
                    is_match={is_match}
                    nocreate={false}
                    noedit={false}
                    columns={[
                        {key: 'code', label: 'Code'},
                        {key: 'description', label: 'Description'},
                        {key: 'item_tag.name', label: 'Item tag'},
                        {key: 'unapprove_when_added', label: 'Unapprove when added'},
                        {key: 'show_as_feature', label: 'Show as feature'},
                        {key: 'item_tag.is_portal_filter', label: 'Portal filter'},
                        {key: 'forbid_in_stock', label: 'Forbid in stock'},
                        {key: 'is_gift_box', label: 'Is gift box'},
                    ]}
                    not_sortable={true}
                    requery_subscribe_key="loendersloot_tags_updated"
                />
            )
        },
    }
}

export default LoenderslootTagList
