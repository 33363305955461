import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {Button} from '@bitstillery/common/components'
import {
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Icon,
    PanelContext,
    PanelFilters,
    RowActionDelete,
    RowActionEdit,
    RowActionDownload,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {download_base64_file} from '@bitstillery/common/lib/utils'
import {api} from '@bitstillery/common/app'

import {UpsertPricelist} from './components/upsert_pricelist'

import {$s, notifier} from '@/app'
import {
    GetSupplierPriceListStatisticsResponse,
    SupplierPriceListCollectionResponse, SupplierPriceListStatus,
} from '@/factserver_api/fact2server_api.ts'

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Supplier',
        render: (row) => {
            return <div className="td-group">
                <span className="header">
                    {row.supplier_name}
                </span>
                <span className="details wrap">
                    {row.supplier_price_list_description}
                </span>
                <span className="details">
                    <div className="stats mt-05">
                        <div className="stat">
                            <Icon name="checked" type="unset" tip={() => {
                                return `Already ${row.statistics.total_items} items resolved to our products`
                            }}/>
                            {row.statistics.total_items}
                        </div>
                        <div className="stat">
                            <Icon name="question" type="unset" tip={() => {
                                return `${row.statistics.total_unresolved} items could not be resolved to known products`
                            }}/>
                            {row.statistics.total_unresolved}
                        </div>
                    </div>
                </span>
            </div>
        },
    }, {
        name: 'Created on',
        render: (row) => format_iso_to_date(row.created_on),
    },
    {
        name: 'From / To',
        render: (row) => {
            const date_range = [format_iso_to_date(row.start_date)]
            if (row.end_date) {
                date_range.push(format_iso_to_date(row.end_date))
            } else {
                date_range.push('N/A')
            }
            return <span>
                {date_range.join(' - ')}
            </span>
        },
    }, {
        name: 'Pricelist manager',
        render: (row) => row.price_list_manager_name,
    },
]

export class MarketListPricelists extends MithrilTsxComponent<unknown> {

    async oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/supplier-price-lists/collection-view',
            },
        }, undefined, {
            items_queried: async({result}) => {
                if (!result.length) {
                    return result
                }
                const spl_artkeys = result.map((i) => i.artkey)
                const query_param = spl_artkeys.join(',')
                const {result: statistics} = await api.get<GetSupplierPriceListStatisticsResponse[]>(`discover/supplier-price-lists/collection-view/statistics?spl_artkeys=${query_param}`)
                for (const spl of result) {
                    spl.statistics = statistics.find((stat) => stat.spl_artkey === spl.artkey)
                }
                return result
            },
        })
    }

    view(): m.Children {
        return <div className="c-market-list-pricelists view-container">
            <PanelFilters collection={collection} />

            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <Button
                        icon="upload"
                        onclick={() => m.route.set('/market/pricelists/upload')}
                        text="Upload pricelist"
                        type="info"
                        variant="context"
                    />
                    <Button
                        icon="plus"
                        onclick={() => {
                            Object.assign($s.context, {
                                id: null,
                                data: {},
                                name: 'upsert_pricelist',
                                title: 'Create Pricelist',
                            })
                        }}
                        text={' Create pricelist'}
                        type="info"
                        variant="context"
                    />
                </div>
                <CollectionHeader collection={collection} columns={columns} />

                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        m.route.set(`/market/pricelists/${row.artkey}?meta=true&tabId=resolved`)
                    }}
                    row_actions={(row) => {
                        const inactive = row.supplier_price_list_status === 'deleted'
                        return [
                            row.file_name ? <RowActionDownload
                                row={row}
                                row_download={async() => {
                                    const {result, success} = await api.post('offerprocessing.get_supplier_price_list_file', {
                                        spl_artkey: row.artkey,
                                    }) as any
                                    if (!success) {
                                        notifier.notify(result.message, 'warning')
                                    } else {
                                        download_base64_file(result.file_base64_encoded, row.file_name)
                                    }
                                }}
                            /> : null,
                            <RowActionEdit
                                collection={collection}
                                context={{mode: 'panel', name: 'upsert_pricelist', title: 'Edit Pricelist'}}
                                row={row}
                            />,
                            <RowActionDelete
                                icon={inactive ? 'undo' : 'deactivate'}
                                needs_confirmation={false}
                                row={row}
                                row_delete={async() => {
                                    const {status_code} = inactive
                                        ? await api.post(`discover/supplier-price-lists/${row.artkey}/reactivate`, undefined, true)
                                        : await api.delete(`discover/supplier-price-lists/${row.artkey}`)

                                    if (status_code > 299) {
                                        notifier.notify('Could not reactivate pricelist', 'warning')
                                        return
                                    }
                                    notifier.notify(`Pricelist is ${inactive ? 're' : 'de'}activated`, 'info')
                                    row.supplier_price_list_status = inactive ? 'active' : 'deleted'
                                }}
                                tip={()=> {
                                    return row.supplier_price_list_status === 'deleted' ? 'Reactivate pricelist' : 'Deactivate pricelist'
                                }}
                            />,
                        ]
                    }}
                    row_status={(row: SupplierPriceListCollectionResponse) => {
                        let status = {icon: 'checked', tip: 'This supplier price list is active', type: 'success'}
                        if (row.supplier_price_list_status === SupplierPriceListStatus.Expired) {
                            status = {icon: 'clock', tip: 'Pricelist is past the end date', type: 'default'}
                        }
                        if (row.supplier_price_list_status === SupplierPriceListStatus.NotYetActive) {
                            status = {icon: 'clock', tip: 'Pricelist is not yet active', type: 'warning'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.AlwaysActive) {
                            status = {icon: 'pricelist', tip: 'Pricelist has no end date', type: 'success'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.Processing) {
                            status = {icon: 'cog', tip: 'Pricelist is being processed', type: 'success'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.WaitingForAnalyze) {
                            status = {icon: 'cog', tip: 'Pricelist is waiting to be analysed', type: 'warning'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.FailedProcessing) {
                            status = {icon: 'warning', tip: `Processing failed: ${row.failed_processing_message}`, type: 'warning'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.Deleted) {
                            status = {icon: 'trash', tip: 'Pricelist was deleted', type: 'danger'}
                        }
                        else if (row.supplier_price_list_status === SupplierPriceListStatus.Analyzing) {
                            status = {icon: 'chartGraph', tip: 'Pricelist is being analysed', type: 'warning'}
                        }

                        return {
                            render: [
                                <Icon
                                    name={status.icon}
                                    tip={status.tip}
                                    type={status.type}
                                />,
                            ],
                            type: 'default',
                        }
                    }}
                />
            </CollectionView>
            <PanelContext collection={collection}>
                <div className="content">
                    {$s.context.name === 'upsert_pricelist' && <UpsertPricelist collection={collection} />}
                </div>
            </PanelContext>
        </div>
    }
}
