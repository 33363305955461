m = require 'mithril'
api = require '../../api.ls'
inputs = require '@/components/inputs.ls'
{Warehouse} = require '@/models/data'
{download_binary_file_from_base64_str, format-date} = require 'utils.ls'
{MoneyInput} = require '@/components/decimal_input'
{countries} = require '@bitstillery/common/lib/countries'
rxjs = require "rxjs"
rxjs_operators = require "rxjs/operators"
{CountriesSelect, DropDownWithSearch, DropDownOption, empty_option} = require '@/components/html_components'
{AttachmentList, AttachmentHelper, UploadAttachment} = require '@/components/attachment_list'
{AttachmentType} = require '@/factserver_api/attachment_api'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

module.exports = class WarehouseEdit
    ->
        @destination = window.prop null
        @attachment_to_categorize = false
        @attachments = window.prop []
        @user = window.prop []

        @create = window.prop false
        @country_to_region$ = null
        @regions = window.prop []

        @supplier_artkey = m.route.param 'supplier_artkey'
        @destination_artkey = m.route.param 'artkey'

        @display_name = window.prop ''

        if @destination_artkey
            @query_destination @destination_artkey
            @attachment_helper = new AttachmentHelper(@destination_artkey, AttachmentType.WAREHOUSE)
        else #we are creating a new one
            @create true
            @destination new Warehouse
            @attachment_helper = null


    onupdate: ~>
        # Wire country drop down to the region observable datasource -> @regions
        if ! @country_to_region$
            country_element = document.getElementById "country"
            if country_element
                @country_to_region$ = rxjs.fromEvent(country_element, "input").pipe(
                    rxjs_operators.map((event) -> event.target.value),
                    rxjs_operators.switchMap((country_code) ~>
                        app.$m.data.region_drop_down_data.for_country_code(country_code)
                    )
                )
                @country_to_region$.subscribe((regions) ~>
                    @destination!region_code ''
                    @regions regions || []
                    m.redraw!
                )


    redirect_to_deactivate_page: (artkey) ~>
        if @supplier_artkey
            base_url = "/crm/relations/#{@supplier_artkey}/edit/warehouses/"
        else
            base_url = "/data/warehouses/"
        m.route.set "#{base_url}#{artkey}/deactivate"

    redirect_to_edit_page: (artkey) ~>
        if @supplier_artkey
            base_url = "/crm/relations/#{@supplier_artkey}/edit/warehouses/"
        else
            base_url = "/data/warehouses/"
        m.route.set "#{base_url}#{artkey}/edit", {key: 'edit'}

    redirect_to_previous_page: ~>
        if @supplier_artkey
            url = "/crm/relations/#{@supplier_artkey}/edit?tab=destinations"
        else
            url = "/data/warehouses"
        m.route.set url

    query_destination: (artkey) ~>
        api.call-and-then 'destinations.get_destination' {artkey: artkey}, do
            success: (resp) ~>
                @destination app.$m.data.warehouses.create_warehouse resp.result
                app.$m.data.region_drop_down_data.for_country_code(@destination!country_code!).subscribe((regions) ~>
                    @regions regions || []
                    m.redraw!
                )
            failure: (resp) ~>
                app.notifier.notify "Unknown #{@destination!destination_type!}.", 'danger'
                @redirect_to_previous_page!

    save: (e) ~>
        e.preventDefault!

        data = do
            destination_type: @destination!destination_type!
            artkey: @destination!artkey!
            can_handle_our_stock: @destination!can_handle_our_stock!
            name: @destination!name!
            emailaddress: @destination!emailaddress!
            street_address: @destination!street_address!
            zip_code: @destination!zip_code!
            city: @destination!city!
            country_code: @destination!country_code!
            region_code: @destination!region_code!
            warehouse_id: @destination!warehouse_id!
            vat_id: @destination!vat_id!
            excise_id: @destination!excise_id!
            currency: @destination!currency!
            warehouse_base_costs: +@destination!warehouse_base_costs!
            warehouse_costs_per_case: +@destination!warehouse_costs_per_case!
        if @supplier_artkey
            data['supplier_artkey'] = @supplier_artkey
        api.call 'destinations.update_or_create_destination', data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            app.$m.common.observable.broadcast 'warehouses_updated', ''
            if @create!
                app.notifier.notify "Successfully created new #{@destination!destination_type!} #{@destination!name!}.", 'success'
                @redirect_to_edit_page result.artkey
            else
                app.notifier.notify "Successfully updated #{@destination!destination_type!}.", 'success'

    set_street_address: (value) ~>
        @destination!street_address value.replace(/\n/g, '|')

    get_display_name: ~>
        @display_name ''
        if @destination!name!
            @display_name @display_name! + @destination!name!
        if  @destination!name! && @destination!country_code!
            @display_name @display_name! + " - "
        if @destination!country_code!
            @display_name @display_name! + countries[@destination!country_code!]

        @display_name!

    view: ->
        if @destination! == null
            return m Spinner

        m '.c-destination-edit view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: "button", onclick: ~> @redirect_to_previous_page!},
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                if @destination!artkey! then
                    m 'button.btn.btn-danger' {type: 'button', \
                    onclick: ~> @redirect_to_deactivate_page(@destination!artkey!)},
                    (m 'span.glyphicon.glyphicon-trash'), ' Deactivate'

            m 'form.flex-form' {onsubmit: @save},
                if @attachment_helper
                    m '.fieldset large',
                        m UploadAttachment,
                            attachment_helper: @attachment_helper

                        m AttachmentList, do
                            attachment_helper: @attachment_helper

                m '.fieldset-group',
                    m '.fieldset',
                        m '.fieldset-label' 'General Information'

                        inputs.text(@destination!name, {label: 'Name', required: true, placeholder: 'Name'})
                        inputs.select @destination!destination_type,
                            {"warehouse": "warehouse", "airport": "airport", "seaport": "seaport"}
                            label: 'Destination type', required: true, empty_option: false, disabled: not @create!

                        if @destination!destination_type! !== "warehouse" then [
                            inputs.text(@get_display_name, {
                                disabled: true,
                                label: 'Invoice diplay name',
                                placeholder: 'Name displayed on the invoice'
                            })
                        ]

                        inputs.text(@destination!warehouse_id, {label: 'Location ID'})
                        inputs.checkbox @destination!can_handle_our_stock, {
                            disabled: false,
                            help: 'Enable this if this warehouse can handle our stock.',
                            label: 'Warehouse with our stock',
                        }


                    if @destination!destination_type! === "warehouse" then
                        m '.fieldset',
                            m '.fieldset-label' 'Contact'
                            inputs.text(@destination!emailaddress, {label: 'Email', placeholder: 'Email'})
                            m '.field-group',
                                m CountriesSelect,
                                    label: 'Country'
                                    required: true
                                    id: "country"
                                    selected: @destination!country_code!
                                    onchange: (value) ~> @destination!country_code(value)
                                inputs.text(@destination!city, {label: 'City', required: true})

                            if @regions!length > 0
                                m DropDownWithSearch,
                                    label: 'Region'
                                    selected: @destination!region_code!
                                    onchange: (region_code) ~>
                                        @destination!region_code region_code
                                    empty_option: empty_option("Select a region")
                                    @regions!map((region) ->
                                        m DropDownOption,
                                            value: region.code,
                                            "#{region.code} - #{region.name}"
                                    )

                            m '.field-group',
                                inputs.text(@destination!street_address, {
                                    label: 'Address',
                                    required: @destination!destination_type! === "warehouse"
                                })
                                inputs.text(@destination!zip_code, {label: 'Zip code'})

                if @destination!destination_type! === "warehouse" then
                    m '.fieldset',
                        m '.fieldset-label' 'Financial'
                        inputs.text(@destination!excise_id, {label: 'Excise ID'})
                        inputs.text(@destination!vat_id, {label: 'VAT ID'})

                        # These fields are not visible when upserting a destination from a supplier
                        if not @supplier_artkey then [
                            inputs.select(@destination!currency, app.$s.currencies.all, {label: 'Currency'})

                            m '.field-group',
                                m MoneyInput,
                                    label: 'Warehouse base costs'
                                    value: @destination!warehouse_base_costs!
                                    currency: @destination!currency!
                                    on_value: (price) ~> @destination!warehouse_base_costs price

                                m MoneyInput,
                                    label: 'Warehouse costs per case'
                                    value: @destination!warehouse_costs_per_case!
                                    currency: @destination!currency!
                                    on_value: (price) ~> @destination!warehouse_costs_per_case price
                        ]


                m 'button.btn.btn-success.btn-submit' if @create! then 'Create Destination' else 'Update Destination'
