$ = require 'jquery'
m = require "mithril"
utils = require '../../utils.ls'
{slice, and-list} = require 'prelude-ls'
{debounce} = require '@bitstillery/common/ts_utils'


class ViewModel
    (options) ->
        @input_container_id = window.prop options.input_container_id
        @on_submit_suggestion = options.on_submit_suggestion
        @suggestions = prop(options['suggestions'] || [])
        @min_length = prop(options['min_length'] || 3)
        # Only use this to check, not to set. For that, use the show_suggestions function.
        @hide_suggestions = window.prop true
        @found_suggestions = window.prop []
        @highlighted_suggestion = window.prop null
        @search_term = window.prop ''

        @delayed_submit_input = debounce 200, @submit_oninput

    oninput: (value, is_suggestion=false) ~>
        @search_term value
        if not is_suggestion
            @delayed_submit_input!

    submit_oninput: ~>
        # Separate from oninput so that it can be called when the suggestions are
        # loaded afterwards.
        if @search_term!length > @min_length!
            @show_suggestions true
        else
            @show_suggestions false

    show_suggestions: (to_show) ~>
        # Whether the suggestions should be shown. Triggers filtering of suggestions
        # if needed, and empties it to not unnecesarily render data (can slow down a lot
        # with lots of suggestions.
        if to_show
            @filter_suggestions!
            @hide_suggestions false
        else
            # Empty to save rendering unnecessary data in the view.
            @found_suggestions []
            @highlighted_suggestion null
            @hide_suggestions true
        m.redraw!

    onclick: (e) ~>
        if @search_term!length > 0
            @show_suggestions true

    onkeydown: (e) ~>
        @submit_keydown e

    filter_suggestions: ~>
        # Careful: The suggestions list can be huge. Calling this will force the
        # found_suggestions to be set. Make sure you only call it when it is necessary.
        if @search_term!length > 0
            @found_suggestions @suggestions!filter(@is_suggestion_match)
        else
            @found_suggestions @suggestions!
        # It makes no sense to render more than 50 suggestions. If you do, it does slow
        # down a lot though. So, let's not.
        @found_suggestions @found_suggestions!slice 0 50

    is_suggestion_match: (suggestion) ~>
        # Check if a suggestion maps the serach term.
        return and-list [suggestion.toLowerCase!trim!indexOf(term) > -1 \
                         for term in @search_term!toLowerCase!split ' ']

    highlight_suggestion: (index) ~>
        if @highlighted_suggestion! != index
            @highlighted_suggestion index

    highlight_next_suggestion: ~>
        if @found_suggestions!length > 0
            if @highlighted_suggestion! == (@found_suggestions!length - 1) or not @highlighted_suggestion!?
                @highlighted_suggestion 0
            else
                @highlighted_suggestion @highlighted_suggestion! + 1
            document.getElementById('suggestion-' + @highlighted_suggestion!).scrollIntoView false

    highlight_previous_suggestion: ~>
        if @found_suggestions!length > 0
            if not @highlighted_suggestion!?
                @highlighted_suggestion 0
            else if @highlighted_suggestion! == 0
                @highlighted_suggestion @found_suggestions!length - 1
            else
                @highlighted_suggestion @highlighted_suggestion! - 1
            document.getElementById('suggestion-' + @highlighted_suggestion!).scrollIntoView false

    submit_keydown: (e) ~>
        switch e.keyCode
            case 13 # enter
                e.preventDefault!
                if @highlighted_suggestion!?
                    suggestion = @found_suggestions![@highlighted_suggestion!]
                    if suggestion
                        @submit_suggestion suggestion
                @show_suggestions false
            case 40 # arrow down
                @show_suggestions true
                @highlight_next_suggestion!
            case 38 # arrow up
                @show_suggestions true
                @highlight_previous_suggestion!
            case 27 # escape
                @show_suggestions false
            case 9  # tab
                @show_suggestions false
            default
                return

    submit_suggestion: (suggestion) ~>
        @on_submit_suggestion suggestion
        @show_suggestions false

    reset: ~>
        @search_term ''
        @show_suggestions false


export class controller
    (options) ->
        @vm = new ViewModel ...

        @click_handler = (e) ~>
            if not $(e.target).closest(@vm.input_container_id!).length and \
                    not $(e.target).is(@vm.input_container_id!)
                @vm.show_suggestions false

        $ document .on 'click', @click_handler

    onremove: ~>
        $ document .off 'click', @click_handler

    get_found_suggestions: ~>
        @vm.found_suggestions!

    oninput: ~>
        @vm.oninput ...

    onkeydown: (e) ~>
        @vm.onkeydown e

    onclick: (e) ~>
        @vm.onclick e

    set_suggestions: (suggestions) ~>
        @vm.suggestions suggestions
        @vm.submit_oninput!

    reset: ~>
        @vm.reset!

    update_suggestions: ~>
        @vm.search_term ...
        @vm.show_suggestions true


export view = (ctrl) ->
    if ctrl.vm.found_suggestions!length
        m '.autocomplete-suggestions' {hidden: ctrl.vm.hide_suggestions!},
            ctrl.vm.found_suggestions!map (suggestion, index) ->
                m '.autocomplete-suggestion' {id: 'suggestion-' + index, \
                    'class': if index == ctrl.vm.highlighted_suggestion! then 'highlighted' else '', \
                    onclick: (-> ctrl.vm.submit_suggestion suggestion),\
                    onmouseover: -> ctrl.vm.highlight_suggestion index} suggestion
