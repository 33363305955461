import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {randomString} from 'utils.ls'
import {classes} from '@bitstillery/common/lib/utils'
import {countries} from '@bitstillery/common/lib/countries'
import {Modal} from 'components/modal/modal.ls'
import {show_confirmation} from 'components/confirmation.ls'
import {Observable} from 'rxjs'
import {DateTime} from 'luxon'

import {SaveFilterPresetAs} from '../../filter_preset/save_filter_preset_as'
import {DangerButton, DefaultButton, SuccessButton} from '../buttons'
import {
    CheckBox,
    DropDownOption,
    DropDownWithSelect,
    InputDate,
    RadioSelection,
    RangedInput,
} from '../html_components'
import {MaybeObservable} from '../relation'

import {SearchBarControl} from './search_bar'
import {CollectionFetcher, PagedCollectionFetcher, SearchFilter, SearchFilterValue} from './collection_table'

import {FilterApi, FilterPreset} from '@/factserver_api/filter_api'

/**
 * Functions and views related to the sidebar. The sidebar works together with a CollectionFetcher and is a way
 * to display several filters for a collection.
 *
 * A sidebar consist of several ui elements to filter a Collection:
 * - CheckboxGroup - A list of checkboxes of which 0, 1 or more can be selected.
 * - FilterRadioButtonGroup - A button group for that can select only one value.
 * - ApplyFilterButton - An apply button (apply the filters) and a reset button.
 *
 * To cooperate with the CollectionFetcher there are several FilterHelper objects:
 * - ArrayFilterHelper - for filter properties that are sent to the backend as an array.
 * - SingleValueFilterHelper - for filter properties that are sent as a scalar to the backend.
 * - UserFilterHelper - for the users in the system. Sent as artkey to the backend.
 *
 * To remember the state of the filter (when navigating) there is a SidebarLocalStorage.
 */

/** Choices for a Yes/No/All situation. */
export const YesNoAllFilterValues = [
    {title: 'Yes', value: 'true'},
    {title: 'No', value: 'false'},
    {title: 'All', value: ''},
]

/** Choices for a Custom status situation. */
export const CustomStatusFilterValues = [
    {title: 'T1', value: 'T1'},
    {title: 'T2', value: 'T2'},
    {title: 'Both', value: ''},
]

/** Choices for a Seen or Unseen situation. */
export const SeenUnseenFilterValues = [
    {
        title: (
            <span>
                <span className={'glyphicon glyphicon-eye-open'} /> Seen
            </span>
        ),
        value: 'seen',
    },
    {
        title: (
            <span>
                <span className={'glyphicon glyphicon-eye-close'} /> Unseen
            </span>
        ),
        value: 'unseen',
    },
    {title: 'Both', value: ''},
]

/** Choices for a refill status situation. */
export const RefillStatusFilterValues = [
    {title: 'REF', value: 'ref'},
    {title: 'Non REF', value: 'nonref'},
    {title: 'Both', value: ''},
]

/** Choice for the Only Mine situation. */
export const OnlyMineFilterValue = [
    {
        title: (
            <span>
                <span className={'glyphicon glyphicon-user'} /> Only mine
            </span>
        ),
        value: 'mine',
    },
]

/** Choices for the country filter. */
export const CountryCodeFilterValues = Object.keys(countries).map((key: string) => {
    return {
        title: countries[key],
        value: key,
    }
})

function modified_star(helper: FilterHelper): m.Children {
    return helper.has_values_set ? (
        <span>
            {' '}
            <span className={'fas fa-star'} style={'font-size: 6px; vertical-align: super;'} />
        </span>
    ) : (
        <span />
    )
}

export interface CheckboxGroupAttrs {
    title: JSX.Element
    disabled?: boolean
    collapsed?: boolean
    helper: ArrayFilterHelper
}

export class FilterPresetHelper {
    is_loading = false
    fetcher: PagedCollectionFetcher<unknown>
    filter_preset_name: string
    current_applied_preset: FilterPreset | null = null

    presets: FilterPreset[] = []
    filter_api = new FilterApi()

    constructor(fetcher: PagedCollectionFetcher<unknown>, filter_preset_name: string) {
        this.fetcher = fetcher
        this.filter_preset_name = filter_preset_name

        this.load()
    }

    load(select_artkey?: string): void {
        this.is_loading = true
        this.presets = []
        this.current_applied_preset = null
        this.filter_api
            .get_filter_presets({filter_preset_type: this.filter_preset_name})
            .subscribe((response: FilterPreset[]) => {
                this.presets = response
                this.is_loading = false
                if (select_artkey) {
                    this.apply_preset(select_artkey)
                }
                m.redraw()
            })
    }

    apply_preset(artkey: string): void {
        const selected_presets = this.presets.filter((preset) => String(preset.artkey) === String(+artkey))
        if (selected_presets.length !== 1) {
            return
        }
        const selected_preset = selected_presets[0]
        Object.keys(selected_preset.filters).forEach((object_key) => {
            // @ts-ignore
            this.fetcher.filters[object_key] = selected_preset.filters[object_key]
        })
        this.current_applied_preset = selected_preset
        this.fetcher.reset_and_query()
    }

    current_filters(): SearchFilter {
        return this.fetcher.filters
    }

    delete_currently_applied_preset(): void {
        if (!this.current_applied_preset) {
            return
        }
        this.is_loading = true
        this.filter_api.delete_filter_preset({artkey: this.current_applied_preset.artkey}).subscribe({
            next: () => {
                this.load()
            },
        })
    }
}

interface FilterPresetButtonAttrs {
    helper: FilterPresetHelper
}

export class FilterPresetButton extends MithrilTsxComponent<FilterPresetButtonAttrs> {
    show_save_modal = false

    close_save_modal(): void {
        this.show_save_modal = false
    }

    process_save_response(vnode: m.Vnode<FilterPresetButtonAttrs>, artkey: number): void {
        vnode.attrs.helper.load(`${artkey}`)
        this.show_save_modal = false
        m.redraw()
    }

    delete_current_preset(vnode: m.Vnode<FilterPresetButtonAttrs>): void {
        const selected_preset = vnode.attrs.helper.current_applied_preset
        if (!selected_preset) {
            return
        }
        show_confirmation({
            title: 'Delete filter preset',
            message: 'Are you sure you want to delete this filter preset?',
            unique_name: 'delete_filter_preset_confirm',
            onconfirm: () => {
                vnode.attrs.helper.delete_currently_applied_preset()
            },
        })
    }

    view(vnode: m.Vnode<FilterPresetButtonAttrs>): m.Children {
        return (
            <div className="c-filter-preset-button mb-1">
                {this.show_save_modal && (
                    <Modal title={'Save filter preset as'} onclose={() => this.close_save_modal()}>
                        <SaveFilterPresetAs
                            filter_preset_data={{
                                artkey: vnode.attrs.helper.current_applied_preset?.artkey,
                                name: vnode.attrs.helper.current_applied_preset?.name || '',
                                filters: vnode.attrs.helper.current_filters(),
                                filter_preset_type: vnode.attrs.helper.filter_preset_name,
                            }}
                            done={(artkey: number) => this.process_save_response(vnode, artkey)}
                            cancel={() => this.close_save_modal()}
                        />
                    </Modal>
                )}

                <DropDownWithSelect
                    empty_option={<DropDownOption value={''}>{'Select a preset'}</DropDownOption>}
                    selected={vnode.attrs.helper.current_applied_preset?.artkey || ''}
                    onchange={(value: string) => vnode.attrs.helper.apply_preset(value)}
                >
                    {vnode.attrs.helper.presets.map((preset) => (
                        <DropDownOption value={`${preset.artkey}`}>{preset.name}</DropDownOption>
                    ))}
                </DropDownWithSelect>

                <div className={'btn-group mb-1'}>
                    <SuccessButton
                        icon_class={'fas fa-save'}
                        onclick={() => (this.show_save_modal = true)}
                        disabled={vnode.attrs.helper.is_loading}
                        title=" Save"
                    />
                    <DangerButton
                        title={' Delete'}
                        icon_class={'fas fa-trash'}
                        onclick={() => this.delete_current_preset(vnode)}
                        disabled={vnode.attrs.helper.is_loading || vnode.attrs.helper.current_applied_preset === null}
                    />
                </div>
            </div>
        )
    }
}

interface ApplyFilterButtonAttrs {
    fetcher: CollectionFetcher<unknown>
    filter_helpers: FilterHelper[]
    search_bar_control?: SearchBarControl | null
    side_bar_storage?: SidebarLocalStorage
}

/** UI element that renders an apply filter button and a reset filter button. */
export class ApplyFilterButton extends MithrilTsxComponent<ApplyFilterButtonAttrs> {
    view(vnode: m.Vnode<ApplyFilterButtonAttrs>): m.Children {
        const any_changed = vnode.attrs.filter_helpers.find((filter_helper) => filter_helper.is_changed)
        return (
            <div className="btn-group mb-2">
                <DefaultButton
                    onclick={() => {
                        vnode.attrs.filter_helpers.forEach((filter_helper: FilterHelper) =>
                            filter_helper.reset_filter(),
                        )
                        vnode.attrs.fetcher.reset_and_query()
                        if (vnode.attrs.side_bar_storage) {
                            vnode.attrs.side_bar_storage.save()
                        }
                    }}
                >
                    <span className={'fas fa-undo'} /> Reset Filters
                </DefaultButton>
                <SuccessButton
                    title={'Apply Filters'}
                    disabled={!any_changed}
                    onclick={() => {
                        // reset is changed on the helpers, confirm the search text and query.
                        vnode.attrs.filter_helpers.forEach(
                            (filter_helper: FilterHelper) => (filter_helper.is_changed = false),
                        )
                        vnode.attrs.search_bar_control?.submit_search_text()
                        vnode.attrs.fetcher.reset_and_query()
                        if (vnode.attrs.side_bar_storage) {
                            vnode.attrs.side_bar_storage.save()
                        }
                    }}
                >
                    <span className={'glyphicon glyphicon-filter'} />
                </SuccessButton>
            </div>
        )
    }
}

/** UI Element that renders a list of checkboxes. Each checkbox is a CheckboxGroupElement. */
export class CheckboxGroup extends MithrilTsxComponent<CheckboxGroupAttrs> {
    is_collapsed = false

    constructor(vnode: m.Vnode<CheckboxGroupAttrs>) {
        super()

        if (vnode.attrs.collapsed) {
            this.is_collapsed = true
        }
    }

    toggle_collapsed(): void {
        this.is_collapsed = !this.is_collapsed
    }

    view(vnode: m.Vnode<CheckboxGroupAttrs>): m.Children {
        return (
            <div className={classes('c-checkbox-filter', {
                collapsed: this.is_collapsed,
            })}>
                <a className="title" onclick={() => this.toggle_collapsed()}>
                    {this.is_collapsed && <span className={'fas fa-plus-square'} />}
                    {!this.is_collapsed && <span className={'fas fa-minus-square'} />} {vnode.attrs.title}
                    {modified_star(vnode.attrs.helper)}
                </a>
                <div className="items">
                    {!this.is_collapsed && vnode.children}
                </div>
            </div>
        )
    }
}

/** A title / value combination for displaying a choice to the user (title) and communicating with the backend (value). */
export interface FilterValue {
    value: string
    title: JSX.Element | string
}

interface FilterCheckboxGroupElementAttrs {
    filter_values: FilterValue[]
    helper: FilterHelper
}

/** UI Element that has to be in a CheckboxGroup, renders checkboxes for each filter value in filter_values. */
export class FilterCheckboxGroupElement extends MithrilTsxComponent<FilterCheckboxGroupElementAttrs> {
    view(vnode: m.Vnode<FilterCheckboxGroupElementAttrs>): m.Children {
        return vnode.attrs.filter_values.map((filter_value: FilterValue) => {
            return (
                <CheckBox
                    checked={vnode.attrs.helper.is_in_filter(filter_value.value)}
                    id={filter_value.value}
                    onchange={() => vnode.attrs.helper.toggle_filter(filter_value.value)}
                >
                    {filter_value.title}
                </CheckBox>
            )
        })
    }
}

export interface SelectAllCheckboxGroupElementAttrs {
    helper: FilterHelper
}

/** UI Element that has to be in a CheckboxGroup, renders a select-all Checkbox. */
export class SelectAllCheckboxGroupElement extends MithrilTsxComponent<SelectAllCheckboxGroupElementAttrs> {
    view(vnode: m.Vnode<SelectAllCheckboxGroupElementAttrs>): m.Children {
        return (
            <div>
                <CheckBox
                    checked={vnode.attrs.helper.are_all_selected()}
                    id={randomString(8)}
                    onchange={() => vnode.attrs.helper.toggle_all()}
                >
                    {' '}
                    Select all
                </CheckBox>
            </div>
        )
    }
}

interface FilterRadioButtonGroupAttrs {
    title: JSX.Element | string
    filter_values: FilterValue[]
    helper: SingleValueFilterHelper
    onchange?: () => unknown
}

/** UI Element that renders a button group for the filter values. */
export class FilterRadioButtonGroup extends MithrilTsxComponent<FilterRadioButtonGroupAttrs> {
    view(vnode: m.Vnode<FilterRadioButtonGroupAttrs>): m.Children {
        const values = vnode.attrs.filter_values.map((filter_value: FilterValue) => {
            return {
                title: filter_value.title,
                value: filter_value.value,
                description: filter_value.title,
            }
        })

        return (
            <div className="c-radio-filter field">
                {vnode.attrs.title &&
                <label>
                    {vnode.attrs.title} {modified_star(vnode.attrs.helper)}
                </label>}

                <RadioSelection
                    value={vnode.attrs.helper.filter_value_from_fetcher()}
                    onclick={(value: string) => {
                        vnode.attrs.helper.toggle_filter(value)
                        if (vnode.attrs.onchange) {
                            vnode.attrs.onchange()
                        }
                    }}
                    choices={values}
                />
            </div>
        )
    }
}

interface RangedMinMaxFilterAttrs {
    min: number
    max: number
    open_end: boolean
    step?: number
    title: string
    helper: ArrayFilterHelper
}

export class RangedMinMaxFilter extends MithrilTsxComponent<RangedMinMaxFilterAttrs> {
    view(vnode: m.Vnode<RangedMinMaxFilterAttrs>): m.Children {
        return (
            <RangedInput
                title={vnode.attrs.title}
                type={'double'}
                open_end={vnode.attrs.open_end}
                min={vnode.attrs.min}
                max={vnode.attrs.max}
                step={vnode.attrs.step ? vnode.attrs.step : 1}
                value_left={
                    vnode.attrs.helper.filter_value_from_fetcher_at_index(0) === ''
                        ? `${vnode.attrs.min}`
                        : vnode.attrs.helper.filter_value_from_fetcher_at_index(0)
                }
                oninput_left={(value: string) => {
                    const value_as_number = +value
                    vnode.attrs.helper.set_fetcher_filter_value_at_index(
                        value_as_number === vnode.attrs.min ? '' : value,
                        0,
                    )
                }}
                value_right={
                    vnode.attrs.helper.filter_value_from_fetcher_at_index(1) === ''
                        ? `${vnode.attrs.max}`
                        : vnode.attrs.helper.filter_value_from_fetcher_at_index(1)
                }
                oninput_right={(value: string) => {
                    const value_as_number = +value
                    vnode.attrs.helper.set_fetcher_filter_value_at_index(
                        value_as_number === vnode.attrs.max ? '' : value,
                        1,
                    )
                }}
            />
        )
    }
}

interface RangedFilterAttrs {
    min: number
    max: number
    open_end: boolean
    step?: number
    title: JSX.Element | string
    helper: SingleValueFilterHelper
}

export class RangedFilter extends MithrilTsxComponent<RangedFilterAttrs> {
    view(vnode: m.Vnode<RangedFilterAttrs>): m.Children {
        return (
            <RangedInput
                title={vnode.attrs.title}
                type={'single'}
                open_end={vnode.attrs.open_end}
                min={vnode.attrs.min}
                max={vnode.attrs.max}
                step={vnode.attrs.step ? vnode.attrs.step : 1}
                value_left={
                    vnode.attrs.helper.filter_value_from_fetcher() === ''
                        ? `${vnode.attrs.min}`
                        : vnode.attrs.helper.filter_value_from_fetcher()
                }
                oninput_left={(va: string) => {
                    const va_number = +va
                    vnode.attrs.helper.set_fetcher_filter_value(va_number === vnode.attrs.min ? '' : va)
                }}
            />
        )
    }
}

interface DateFilterAttrs {
    helper: SingleValueFilterHelper
    title: JSX.Element | string
}

export class DateFilter extends MithrilTsxComponent<DateFilterAttrs> {
    view(vnode: m.Vnode<DateFilterAttrs>): m.Children {
        return (
            <div className="c-field-date-filter field">
                <label>
                    {vnode.attrs.title} {modified_star(vnode.attrs.helper)}
                </label>
                <InputDate
                    value={vnode.attrs.helper.filter_value_from_fetcher()}
                    onchange={(value: DateTime | null) => {
                        if (value) {
                            vnode.attrs.helper.set_fetcher_filter_value(value.toISODate())
                        } else {
                            vnode.attrs.helper.set_fetcher_filter_value('')
                        }
                    }}
                />
            </div>
        )
    }
}

interface CheckboxGroupElementAttrs {
    key_label$: Observable<{ artkey: number; label: string }[]>
    helper: FilterHelper
}

/** UI Element that renders a list of checkboxes for each artkey / name in the resolved observable users$. */
export class CheckboxGroupElement extends MithrilTsxComponent<CheckboxGroupElementAttrs> {
    key_label_list: [string, string][] = []

    oncreate(vnode: m.Vnode<CheckboxGroupElementAttrs>): void {
        vnode.attrs.key_label$.subscribe(
            (users) => (this.key_label_list = users.map((usr) => [`${usr.artkey}`, usr.label])),
        )
    }

    view(vnode: m.Vnode<CheckboxGroupElementAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.key_label$}>
                {this.key_label_list.map((key_label) => (
                    <div>
                        <CheckBox
                            checked={vnode.attrs.helper.is_in_filter(key_label[0])}
                            id={key_label[0]}
                            onchange={() => vnode.attrs.helper.toggle_filter(key_label[0])}
                        >
                            {key_label[1]}
                        </CheckBox>
                    </div>
                ))}
            </MaybeObservable>
        )
    }
}

interface CheckboxFilterAttrs {
    helper: SingleValueFilterHelper
    title: string | JSX.Element
}

export class CheckboxFilter extends MithrilTsxComponent<CheckboxFilterAttrs> {
    view(vnode: m.Vnode<CheckboxFilterAttrs>): m.Children {
        return (
            <CheckBox
                checked={vnode.attrs.helper.is_in_filter('yes')}
                id={vnode.attrs.helper.filter_name}
                onchange={() =>
                    vnode.attrs.helper.set_fetcher_filter_value(
                        vnode.attrs.helper.filter_value_from_fetcher() === 'yes' ? 'no' : 'yes',
                    )
                }
            >
                {vnode.attrs.title}
            </CheckBox>
        )
    }
}

/**
 * Bridges the UI elements and the CollectionFetcher backend. Each FilterHelper supports the following:
 *
 * - is this value currently set in the CollectionFetcher filter.
 * - are all the values currently set in the CollectionFetcher filter.
 * - toggle this value in or out of the CollectionFetcher filter.
 * - toggle all values (either empty or set all) in the CollectionFetcher filter.
 * - has the FilterHelper changed since the last apply.
 * - Reset the CollectionFetcher filter to the default value.
 **/
export abstract class FilterHelper {
    fetcher: CollectionFetcher<unknown>
    private _is_changed = false
    filter_name: string
    default_filter_value: SearchFilterValue
    onchange: (value: SearchFilterValue) => unknown = () => {
        // empty default function
    }

    protected constructor(
        fetcher: CollectionFetcher<unknown>,
        filter_name: string,
        default_filter_value: SearchFilterValue,
    ) {
        this.filter_name = filter_name
        this.fetcher = fetcher
        this.default_filter_value = default_filter_value
    }

    get is_changed(): boolean {
        return this._is_changed || this.fetcher.is_dirty
    }

    set is_changed(value: boolean) {
        this._is_changed = value
    }

    get has_values_set(): boolean {
        const filter_value = this.filter_value_from_fetcher()
        const is_default_value = this.default_filter_value === this.filter_value_from_fetcher()
        if (typeof filter_value === 'number' || typeof filter_value === 'string') {
            return !is_default_value
        }
        // @ts-ignore
        return this.filter_value_from_fetcher().length > 0
    }

    /** True if all choices are in the CollectionFetcher filter, false otherwise. */
    abstract are_all_selected(): boolean

    /** Add all to CollectionFetcher filter if not all are in the filter, clear the filter otherwise. */
    abstract toggle_all(): void

    /** True if value is in the CollectionFetcher filter, false otherwise. */
    abstract is_in_filter(value: string): boolean

    /** If value is present in the CollectionFetcher filter remove it, otherwise add it. */
    abstract toggle_filter(value: string): void

    /** Reset CollectionFetcher filter to default value. */
    reset_filter(): void {
        if (typeof this.default_filter_value === 'number') {
            this.set_fetcher_filter_value(this.default_filter_value)
        }
        // @ts-ignore
        this.set_fetcher_filter_value(this.default_filter_value.slice())
    }

    /** Set CollectionFetcher filter value  to value. */
    set_fetcher_filter_value(value: SearchFilterValue): void {
        this.fetcher.filters[this.filter_name] = value
        this.is_changed = true

        this.onchange(value)
    }

    filter_value_from_fetcher(): SearchFilterValue {
        return this.fetcher.filters[this.filter_name]
    }
}

/**
 * Implements the FilterHelper that will be sent to the factserver as a single value
 * (ie operates_online: "true" | "false" | "").
 */
export class SingleValueFilterHelper extends FilterHelper {
    constructor(fetcher: CollectionFetcher<unknown>, filter_name: string, default_filter_value: string) {
        super(fetcher, filter_name, default_filter_value)

        this.default_filter_value = default_filter_value
        this.reset_filter()
    }

    private _filter(): string {
        return this.fetcher.filters[this.filter_name] as string
    }

    are_all_selected(): boolean {
        return false // Not implemented
    }

    is_in_filter(value: string): boolean {
        return this._filter() === value
    }

    toggle_all(): void {
        // Not implemented
    }

    toggle_filter(value: string): void {
        this.is_changed = true
        if (this.is_in_filter(value)) {
            this.set_fetcher_filter_value('')
        } else {
            this.set_fetcher_filter_value(value)
        }
    }
}

/**
 * Implements the FilterHelper for an Observable of Type T. The result of the observable will be cached in this class.
 *
 * Type T: The type this ObservableFilterHelper is instantiated for.
 */
export class ObservableFilterHelper<T> extends FilterHelper {
    values: [number, string][] = []

    constructor(
        collection_fetcher: CollectionFetcher<unknown>,
        filter_name: string,
        observable$: Observable<T[]>,
        map_to_key_value_function: (t: T) => [number, string],
        default_filter_value: SearchFilterValue,
    ) {
        super(collection_fetcher, filter_name, default_filter_value)

        if (this.fetcher.filters[this.filter_name] === undefined) {
            this.fetcher.filters[this.filter_name] = []
        }
        observable$.subscribe((categories) => (this.values = categories.map(map_to_key_value_function)))
        this.reset_filter()
    }

    private _filter(): number[] {
        return this.fetcher.filters[this.filter_name] as number[]
    }

    are_all_selected(): boolean {
        return this._filter().length === this.values.length
    }

    toggle_all(): void {
        this.is_changed = true
        if (this.are_all_selected()) {
            this.set_fetcher_filter_value([])
        } else {
            this.set_fetcher_filter_value(this.values.map((category) => category[0]))
        }
    }

    is_in_filter(value: string): boolean {
        return this._filter().includes(+value)
    }

    toggle_filter(value: string): void {
        this.is_changed = true
        if (this.is_in_filter(value)) {
            this.set_fetcher_filter_value(this._filter().filter((key: number) => String(key) !== String(+value)))
        } else {
            this._filter().push(+value)
        }
    }
}

/** Implementation of a FilterHelper for a property that sends its values as an array to the backend. */
export class ArrayFilterHelper extends FilterHelper {
    values: FilterValue[]

    constructor(
        fetcher: CollectionFetcher<unknown>,
        filter_name: string,
        filter_values: FilterValue[],
        default_filter_value: SearchFilterValue,
    ) {
        super(fetcher, filter_name, default_filter_value)
        if (this.fetcher.filters[this.filter_name] === undefined) {
            this.fetcher.filters[this.filter_name] = []
        }
        this.values = filter_values
        this.reset_filter()
    }

    private _filter(): Array<string> {
        return this.fetcher.filters[this.filter_name] as Array<string>
    }

    set_fetcher_filter_value_at_index(value: string, index: number): void {
        this._filter()[index] = value
        this.is_changed = true
    }

    filter_value_from_fetcher_at_index(index: number): string {
        return this._filter()[index]
    }

    is_in_filter(value: string): boolean {
        return this._filter().includes(value)
    }

    toggle_filter(value: string): void {
        this.is_changed = true
        if (this.is_in_filter(value)) {
            this.set_fetcher_filter_value(this._filter().filter((it: string) => it !== value))
        } else {
            this._filter().push(value)
        }
    }

    are_all_selected(): boolean {
        return this._filter().length === this.values.length
    }

    toggle_all(): void {
        this.is_changed = true
        if (this.are_all_selected()) {
            this.set_fetcher_filter_value([])
        } else {
            this.set_fetcher_filter_value(this.values.map((filter_value: FilterValue) => filter_value.value))
        }
    }
}

interface FilterHelperStorage {
    filter_name: string
    filter_value: SearchFilterValue
}

/** Store and load a sidebar state from local storage. */
export class SidebarLocalStorage {
    sidebar_storage_id: string
    filter_helpers: FilterHelper[]

    constructor(unique_id: string, filter_helpers: FilterHelper[]) {
        this.sidebar_storage_id = `sidebar-${unique_id}`
        this.filter_helpers = filter_helpers
    }

    load(): void {
        const storage = localStorage.getItem(this.sidebar_storage_id)
        if (storage) {
            const filter_data = JSON.parse(storage) as FilterHelperStorage[]
            this.filter_helpers.forEach((filter_helper) => {
                const filter_helper_storage = filter_data.find(
                    (filter_data: FilterHelperStorage) => filter_data.filter_name === filter_helper.filter_name,
                )
                if (filter_helper_storage) {
                    filter_helper.set_fetcher_filter_value(filter_helper_storage.filter_value)
                }
            })
        }
    }

    save(): void {
        const filter_data: FilterHelperStorage[] = this.filter_helpers.map((filter_helper: FilterHelper) => {
            return {
                filter_name: filter_helper.filter_name,
                filter_value: filter_helper.filter_value_from_fetcher(),
            }
        })
        localStorage.setItem(this.sidebar_storage_id, JSON.stringify(filter_data))
    }
}
