api = require 'api.ls'
app = require('@/app')
m = require 'mithril'

{map, compact} = require 'prelude-ls'
{a, match-term-in, format-percentage-base100} = require 'utils.ls'
{ProductBottleType} = require '@/models/products'
{icon-button, button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'

module.exports = class ProductCategoryList
    (vnode) ->
        @entities = new Collection do
            api_function_name: 'product_categories.get_product_categories'
            filter_function: @is-match
            default_sort_by: 'name'
            default_sort_order: 'asc'

        @search_input_ctrl = new SearchInput.controller do
            collection: @entities
            placeholder: 'Search for product categories...'

        app.$m.common.observable.subscribe 'deleted_product_category', @, ~> @entities.requery!

    oncreate: ~>
        @entities.init!

    is-match: (product-categories, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            product-categories.name
        ]

    onremove: ~>
        @search_input_ctrl.onremove!

    view: -> m '.c-product-categories view' a do

        m '.btn-toolbar' a do
            button-with-icon 'Create Product Category', 'plus', do
                class: 'btn-default'
                onclick: ~> m.route.set '/data/product-categories/create'

        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @entities
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'product_category'
                onclick: (record, e) -->
                    m.route.set "/data/product-categories/#{record.artkey}/edit"
            columns:
                do
                    width: 5
                    name: 'Name'
                    field: 'name'
                    sort: true
                    function: (record) ~>
                        m 'span.text-capitalize' record.name
                do
                    width: 3
                    name: 'NL VAT rate'
                    field: 'nl_vat_rate'
                    sort: true
                    transform: (nl_vat_rate) ~>
                        format-percentage-base100 nl_vat_rate.percentage
                do
                    width: 3
                    name: 'DE VAT rate'
                    field: 'de_vat_rate'
                    sort: true
                    transform: (de_vat_rate) ~>
                        format-percentage-base100 de_vat_rate.percentage
                do
                    width: 3
                    name: 'BE VAT rate'
                    field: 'be_vat_rate'
                    sort: true
                    transform: (be_vat_rate) ~>
                        format-percentage-base100 be_vat_rate.percentage
                do
                    width: 2
                    name: 'CBS Code'
                    field: 'cbs_code'
                do
                    width: 2
                    name: 'CBS Code >= 200ml'
                    field: 'cbs_code_gte_200'
                do
                    width: 2
                    name: 'CBS Code >= 1000ml'
                    field: 'cbs_code_gte_1000'
                do
                    width: 2
                    name: 'Default bottle type'
                    field: 'default_product_bottle_type'
                    transform: (default_product_bottle_type) ~>
                        ProductBottleType[default_product_bottle_type]
                do
                    width: 1
                    header: ''
                    name: ''
                    function: (record) ~>
                        m ''
