m = require 'mithril'
{Case} = require '@/models/stock'
{Supplier} = require '@/models/suppliers'
app = require('@/app')

export class OfferItem
    (json) ->
        @artkey = window.prop ''
        @offer_item_type = window.prop ''
        @case_artkey = window.prop ''
        @case = window.prop new Case
        @delivery_period = window.prop ''
        @expected_delivery_date = window.prop ''
        @maximum_quantity = window.prop ''
        @minimum_quantity = window.prop ''
        @sales_price_per_case = window.prop ''
        @is_hidden = window.prop false
        @is_seen = window.prop false
        @can_be_ordered = window.prop false
        @supplier = window.prop null
        @silenced_until_timestamp = window.prop null
        @custom_offer_item = window.prop null
        @sales_price_per_case = window.prop ''
        @needs_approval = window.prop ''
        @originating_spli = window.prop null
        @originating_poi = window.prop null
        @bottle_gtin = window.prop ''
        @case_gtin_code = window.prop ''
        @case_gtin_codes = window.prop ''
        @bottle_gtin_codes = window.prop ''
        @items = window.prop []

        for prop of json
            if prop == 'case' and json[prop] then
                @case_artkey json[prop].artkey
                @case new Case json[prop]
            else if prop == 'price_per_case' then
                @sales_price_per_case json[prop]
            else if prop == 'supplier' then
                if json[prop]? then
                    @supplier new Supplier json[prop]
                else
                    @supplier null
            else if prop of @
                @[prop] json[prop]
            else
                console.error "Unknown property #{prop} of OfferItem"

    toJS: ~> do
        artkey: @artkey!
        offer_item_type: @offer_item_type!
        case_artkey: @case_artkey!
        delivery_period: @delivery_period!
        expected_delivery_date: @expected_delivery_date!
        maximum_quantity: @maximum_quantity!
        minimum_quantity: @minimum_quantity!
        sales_price_per_case: @sales_price_per_case!
        is_hidden: @is_hidden!
        is_seen: @is_seen!
