m = require 'mithril'
AutoComplete = require '@/components/collection/autocomplete.ls'
{SalesOrderCreditItem, SalesOrderItem} = require '@/sales_orders/models.ls'
api = require '../../api.ls'
inputs = require '@/components/inputs.ls'
{MoneyInput} = require '@/components/decimal_input'
utils = require '../../utils.ls'
{head, sort-by} = require 'prelude-ls'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

export class AddCreditItem
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @sales_order_changed = vnode.attrs.sales_order_changed
        @creditable_products = window.prop []
        @init!

        @loading_products = window.prop false
        @loading_items = false
        @loading = window.prop false

        @autocomplete_ctrl = new AutoComplete.controller do
            input_container_id: '#search_input_container'
            on_submit_suggestion: @on_submit_suggestion

    init: ~>
        @product = window.prop ''
        @product_name = window.prop ''

        @creditable_sales_order_items = window.prop []
        @sales_order_credit_item = window.prop new SalesOrderCreditItem

    load_creditable_products: ~>
        if @creditable_products!length == 0
            @loading_products true
            data = do
                supplier_artkey: @sales_order!supplier!artkey!
            api.call-and-then 'sales.credit.get_creditable_products_for_supplier' data, do
                success: (resp) ~>
                    @creditable_products resp.result
                    creditable_product_names = [product.name for product in @creditable_products!]
                    @autocomplete_ctrl.set_suggestions creditable_product_names
                final: (resp) ~>
                    @loading_products false

    select_product: (value, is_suggestion=false) ~>
        @product_name value
        @autocomplete_ctrl.oninput value, is_suggestion
        @product head @creditable_products!filter (.name == value)
        @reset_item!

    on_submit_suggestion: (value) ~>
        @select_product value, true
        @loading_items = true
        data = do
            supplier_artkey: @sales_order!supplier!artkey!
            product_artkey: @product!artkey

        api.call-and-then 'sales.credit.get_creditable_sales_order_items_for_supplier_and_product', data, do
            success: (resp) ~>
                @creditable_sales_order_items [new SalesOrderItem item for item in resp.result]
            final: (resp) ~>
                @loading_items = false


    set_item: (item_artkey) ~>
        if item_artkey
            @sales_order_credit_item!sales_order_item head @creditable_sales_order_items!filter (.artkey! == +item_artkey)
        else
            @sales_order_credit_item!sales_order_item new SalesOrderItem

    reset_item: ~>
        @creditable_sales_order_items []
        @sales_order_credit_item!sales_order_item new SalesOrderItem

    add_to_order: (e) ~>
        e.preventDefault!
        @loading true

        data = do
            sales_order_artkey: @sales_order!artkey!
            sales_order_item_artkey: @sales_order_credit_item!sales_order_item!artkey!
            number_of_cases: @sales_order_credit_item!number_of_cases!

        api.call-and-then 'sales.credit.add_credit_item_to_sales_order', data, do
            success: (resp) ~>
                if @sales_order_changed
                    @sales_order_changed!
                else
                    sales_order_credit_item_obj = new SalesOrderCreditItem resp.result, @sales_order!
                    @sales_order!sales_order_credit_items!push sales_order_credit_item_obj
                    app.$m.common.observable.broadcast 'sales_order_updated_'+@sales_order!artkey!, 'Go fetch'
                @init!
                @autocomplete_ctrl.reset!
                app.notifier.notify 'The credit item was added successfully.', 'success'
            final: (resp) ~>
                @loading false


    onremove: ~>
        @autocomplete_ctrl.onremove!

    view: -> m '.mwrap',
        m 'button.btn.btn-default.mb-2',
            {'data-target': '#add_credit_item', 'data-toggle': 'collapse', onclick: @load_creditable_products} \
            (m 'span.glyphicon.glyphicon-plus'), ' Add credit item to order'
        m '.collapse#add_credit_item',
            if @loading_products! then m Spinner else
                m 'form.flex-form' {onsubmit: @add_to_order},
                    m '.fieldset.largest',
                        m '.field-group',
                            m '.field',
                                m 'label' 'Product'
                                m '.control',
                                    m 'input' {\
                                        'autofocus', \
                                        value: @product_name!, \
                                        type: 'text', \
                                        onclick: @autocomplete_ctrl.onclick, \
                                        autocomplete: "off", \
                                        onkeydown: @autocomplete_ctrl.onkeydown, \
                                        oninput: (ev) ~> @select_product ev.target.value}
                                    AutoComplete.view @autocomplete_ctrl
                                    m 'button.btn.btn-default' {tabindex: -1, \
                                        onclick: @init, type: 'button'} m 'span.glyphicon.glyphicon-remove-sign'
                            m '.field',
                                m 'label',
                                    'Item'
                                    m 'small' ' (from last half year)'
                                if @loading_items
                                    m Spinner
                                else
                                    m 'select' {\
                                            required: true, \
                                            onchange: (ev) ~> @set_item +ev.target.value},
                                        m 'option' {selected: true, value: ''} '-'
                                        if @creditable_sales_order_items!
                                            @creditable_sales_order_items!map (sales_order_item, index) ~>
                                                option_text = sales_order_item.sales_order!reference!
                                                option_text += ' - ' + (+sales_order_item.price_per_case!).format-money! + ' ' + sales_order_item.sales_order!was_sold_in!
                                                option_text += ' - ' + sales_order_item.item!bottle!to_specs!
                                                option_text += ' - ' + sales_order_item.item!number_of_bottles_per_case! + ' btl/cs'
                                                if sales_order_item.item!gift_box_type!
                                                    option_text += ' - ' + sales_order_item.item!gift_box_type!
                                                if sales_order_item.item!tax_label!
                                                    option_text += ' - ' + sales_order_item.item!tax_label!
                                                option_text += ' - ' + sales_order_item.number_of_cases! + ' cs'
                                                m 'option', do
                                                    value: +sales_order_item.artkey!
                                                    selected: @sales_order_credit_item!sales_order_item!artkey! == sales_order_item.artkey!
                                                , option_text

                            inputs.number(@sales_order_credit_item!number_of_cases, {
                                label: 'Cases',
                                required: true,
                                min: 1
                            })

                            m MoneyInput,
                                label: 'Total value'
                                value: @sales_order_credit_item!number_of_cases! * @sales_order_credit_item!sales_order_item!price_per_case!
                                on_value: (price) ~> undefined
                                currency: @sales_order_credit_item!sales_order_item!sales_order!was_sold_in!
                                disabled: true

                            m '.field',
                                m 'label' m.trust '&nbsp;'
                                m 'button.btn.btn-success' \
                                    if @loading! then m Spinner else 'Add credit item'
