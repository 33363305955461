m = require 'mithril'
{and-list} = require 'prelude-ls'

api = require 'api.ls'
app = require('@/app')
{prevent-default} = require 'utils.ls'
{button-with-icon, text-button} = require '@/components/buttons.ls'
{icon} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{check_vat_id} = require 'data/vatcheck/actions.ls'
{european_union_countries} = require '@bitstillery/common/lib/countries'
{auto_attach_buy_from_account_supplier_invoices} = require '@/purchase_orders/auto_attach_bfa_attachments'
{Spinner} = require '@bitstillery/common/components'

{SalesOrderStatus} = require '../models.ls'


export class InvoiceOrder
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @close = vnode.attrs.close
        @is_submitting = false

        @confirm_vat = window.prop false
        @confirm_origin = window.prop false
        @confirm_destination = window.prop false
        @confirm_incoterm = window.prop false
        @confirm_incoterm_location = window.prop false
        @confirm_insurance_type = window.prop false

        # Ordered lists of the field names that are confirmed and their props.
        # These are used by the can_invoice, get_class and get_disabled
        # functions.
        @confirmation_fields = [
            'vat'
            'origin'
            'destination'
            'incoterm'
            'incoterm_location'
            'insurance_type'
        ]
        @confirmation_props = [
            @confirm_vat
            @confirm_origin
            @confirm_destination
            @confirm_incoterm
            @confirm_incoterm_location
            @confirm_insurance_type
        ]

        vat_country_code = @sales_order!vat_country_code!
        @requires_vat_check = vat_country_code in european_union_countries
        if @requires_vat_check
            # VAT ID is confirmed by the system.
            @vat_check_loading = window.prop false
            check_vat_id(null, @vat_check_loading, @confirm_vat, vat_country_code, @sales_order!vat_id!)
        else
            @confirm_vat true

    can_invoice: ~>
        return and-list [prop! for prop in @confirmation_props]

    get_class: (field) ~>
        # Use the lists to determine the css class to use for a field.
        index = @confirmation_fields.indexOf(field)
        if @confirmation_props[index]!
            return '.confirmed'
        else if index == 0 or @confirmation_props[index-1]!
            return '.confirming'
        else
            return '.unconfirmed'

    get_disabled: (field) ~>
        # Use the lists to determine if the checkbox is disabled for a field.
        index = @confirmation_fields.indexOf(field)
        if index == 0
            return @confirmation_props[index]!
        else
            # If the field is already confirmed or the previous field has not
            # been confirmed yet -> disabled.
            return @confirmation_props[index]! or not @confirmation_props[index-1]!

    auto_attach_invoice_to_related_purchase_orders: ~>
        auto_attach_buy_from_account_supplier_invoices @sales_order!artkey!

    set_sales_order_status: (status) ~>
        @is_submitting = true
        data = do
            sales_order_artkey: @sales_order!artkey!
            status: SalesOrderStatus.INVOICED
        api.call 'sales.core.set_sales_order_status', data, @handle_set_sales_order_status

    handle_set_sales_order_status: (resp) ~>
        @is_submitting = false
        if resp.success
            if @sales_order!is_buy_from_account_sales_order!
                @auto_attach_invoice_to_related_purchase_orders!
            m.route.set "/sales-orders/manage/#{@sales_order!artkey!}/invoice"
        else
            app.notifier.notify(resp.message, 'danger')

    view: -> m '.c-invoice-order',
        m 'form.flex-form' {onsubmit: prevent-default @set_sales_order_status},
            m '.fieldset',
                if @requires_vat_check
                    m '.field-readonly',
                        m '.key',
                            m "#{@get_class 'vat'}",
                                if @sales_order!is_vat_shifted
                                    'Destination VAT ID (VAT shifted) '
                                else
                                    'Relation VAT ID '

                                if @vat_check_loading!
                                        m Spinner
                                else
                                    if @confirm_vat! then [
                                        icon 'fa-check', do
                                            class: 'text-success text-xl'
                                    ] else
                                        icon 'fa-times', do
                                            class: 'text-danger text-xl'


                        m '.value',
                            @sales_order!vat_id! or '-'

                m '.field-readonly',
                    m '.key',
                        m "#{@get_class 'origin'}" 'Origin warehouse'
                    m '.value',
                        inputs.checkbox @confirm_origin, {
                            disabled: @get_disabled('origin'),
                            label: if origin = @sales_order!origin!name! then origin else '-'
                        },

                m '.field-readonly',
                    m '.key',
                        m "#{@get_class 'destination'}" 'Destination'
                    m '.value',
                        inputs.checkbox @confirm_destination, {
                            disabled: @get_disabled('destination'),
                            label: @sales_order!destination_location! || @sales_order!destination!name!
                        }

                m '.field-readonly',
                    m '.key',
                        m "#{@get_class 'incoterm'}" 'Incoterm'
                    m '.value',
                        inputs.checkbox @confirm_incoterm, {
                            disabled: @get_disabled('incoterm'),
                            label: @sales_order!incoterm!
                        }

                m '.field-readonly',
                    m '.key',
                        m "#{@get_class 'incoterm_location'}" 'Incoterm location'
                    m '.value',
                        inputs.checkbox @confirm_incoterm_location, {
                            disabled: @get_disabled('incoterm_location'),
                            label: @sales_order!incoterm_location!
                        }

                m '.field-readonly',
                    m '.key',
                        m "#{@get_class 'insurance_type'}" 'Insurance type'
                    m '.value',
                        inputs.checkbox @confirm_insurance_type, {
                            disabled: @get_disabled('insurance_type'),
                            label: @sales_order!insurance_type!name!
                        }

            m '.btn-toolbar',
                button-with-icon 'Cancel', 'ban-circle', do
                    class: 'btn-default'
                    type: 'button'
                    onclick: @close
                button-with-icon 'Edit order', 'edit', do
                    class: 'btn-default'
                    type: 'button'
                    onclick: ~> m.route.set '/sales-orders/manage/' + @sales_order!artkey! + '/edit'
                button-with-icon 'Invoice Sales Order', 'book', do
                    class: 'btn-success'
                    disabled: not @can_invoice! || @is_submitting
                    type: 'submit'

