import {Observable, of, Subject} from 'rxjs'

import {Api} from './api'
import {GetIncotermsResponse} from './fact2server_api'

/**
 * Caches the Relation data for usage in drop down, search auto complete elements etc. This singleton
 * is available during the browser lifespan of the application. It waits on the
 * GetAllForDropDownResponse[]. This value is null if the data is not yet retrieved.
 *
 * Usage:
 * RelationDropDownData.get(98765).subscribe((r) => console.log("RDD3 got 98765", r))
 * RelationDropDownData.names().subscribe((r) => console.log("RDD3 name", r))
 * RelationDropDownData.relations().subscribe((res) => console.log("result1", res))
 */
export class IncotermsDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: IncotermsDropDownData | null = null
    /** Cached results, when available */
    private result: GetIncotermsResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetIncotermsResponse[]> = new Subject<GetIncotermsResponse[]>()

    private constructor() {
        this.api.get('discover/data/incoterms')
            .subscribe({
                next: (response: GetIncotermsResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): IncotermsDropDownData {
        if (IncotermsDropDownData._instance === null) {
            IncotermsDropDownData._instance = new IncotermsDropDownData()
        }

        return IncotermsDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static incoterms(): Observable<GetIncotermsResponse[]> {
        if (IncotermsDropDownData.instance().drop_down_data$.isStopped) {
            return of(IncotermsDropDownData.instance().result)
        }

        return IncotermsDropDownData.instance().drop_down_data$
    }
}
