m = require 'mithril'
jwt-decode = require 'jwt-decode'
{json-format} = require 'formats.ls'
{join} = require 'prelude-ls'
api = require '../api.ls'
utils = require '../utils.ls'

export class Profile
    (profile_json) ->
        profile_json = profile_json || {}
        @artkey = window.prop profile_json['artkey']
        @name = window.prop profile_json['name']
        @emailaddress = window.prop profile_json['emailaddress']
        @address = window.prop profile_json['address']
        @telephone_number = window.prop profile_json['telephone_number']


export class User
    (user_json) ->
        user_json = user_json || {}
        @name = window.prop user_json['name']
        @artkey = window.prop user_json['artkey']
        @profile = window.prop new Profile user_json['profile']
        @is_superuser = window.prop user_json['is_superuser']
        @is_sales_target_manager = window.prop user_json['is_sales_target_manager']
        @is_trader = window.prop user_json['is_trader']
        @is_purchaser = window.prop user_json['is_trader']
        @has_userdata_loaded = false
        @decimal_locale = window.prop user_json["decimal_locale"]

    # JWT token, which has the format: see process.py in the Factserver
    # Calling this function returns the token, and sets the data from the token on
    # the user object. It also loads profile data if it wasn't loaded before.
    token: (value) ~>
        if arguments.length
            app.$s.identity.token = value

        token = app.$s.identity.token
        if not token
            app.emit('identity.logout')
            return

        try
            data = jwt-decode token
        catch e
            localStorage.removeItem 'token'
            if (app.telemetry.enabled)
                app.telemetry.set_user(undefined)
            return

        @artkey data.user_artkey
        @name data.name

        # Optionally load some additional userdata from the server, that is not and should not be in the token.
        if not @has_userdata_loaded
            api_data = do
                artkey: @artkey!
            @has_userdata_loaded = true
            api.call-and-then 'users.get_user', api_data, do
                success: (resp) ~>
                    app.emit('identity.login', resp.result)
                    @is_superuser resp.result.is_superuser
                    @is_sales_target_manager resp.result.is_sales_target_manager
                    @decimal_locale resp.result.decimal_locale
                    @profile new Profile resp.result.profile

        return token

    get_setting_path: (key) ->
        join '_' [@name!] ++ key

    get_setting: (key, default_value) ->
        value = localStorage.getItem @get_setting_path key
        if value? then value else default_value

    remove_setting: (key) ->
        localStorage.removeItem @get_setting_path key

    set_setting: (key, value) ->
        localStorage.setItem (@get_setting_path key), value

    make_setting_prop: (key, default_value, format=json-format) ~>
        result = window.prop format.deserialize (@get_setting key, default_value)
        utils.on-set result, (value) ~>
            @set_setting key, format.serialize value


export class UsersDataModel
    ->

    create_users: (users) ~>
        [@create_user user for user in users]

    create_user: (user) ~>
        new User user
