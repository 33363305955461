m = require 'mithril'
{
    compact, filter, sum, odd, map, each, join, pairs-to-obj,
    Obj, reject, values, any, id
} = require 'prelude-ls'
{a}: utils = require 'utils.ls'
{json-format} = require 'formats.ls'
inputs = require './inputs.ls'
dialog = require './dialog.ls'
table-base = require './table/base.ls'
fixed-table-header = require './table/fixed-header.ls'
{join-maybes, on-set} = utils
{deref} = require '@bitstillery/common/utils.ls'
app = require('@/app')
{legacy_user} = require '@/models/users'

# This is the Uber table that will replace the old table (in table.ls).
# The Uber table supports per-user visible column selections.
# If you are looking for a collection table have a look in collection_table.ls.


is_default_visible = (column) ->
    if column.default_visible? then column.default_visible else true


class ViewModel
    (args) ->
        @options = args.options or {}
        @view_details = args.view_details
        @row_model = args.row_model
        @columns = -> compact deref args.columns
        @items = args.items or window.prop []

        # Determine defaults for column visibility.
        @columns_visibility = window.prop @get_default_columns_visibility!

        table_settings_json = legacy_user.get_setting ['table-settings', @options.unique_name]
        if table_settings_json
            try
                table_settings = JSON.parse table_settings_json
                @load_table_settings table_settings
            catch
                console.error "User has invalid settings for table #{@options.unique_name}", e

    load_table_settings: (table_settings) ->
        # TODO Also load/store settings for sorting, page size, column widths and column order.
        visible_columns = table_settings.visible_columns
        if typeof! visible_columns == 'Object'
            for key, value of visible_columns
                dyna_prop = @columns_visibility![key]
                # TODO need better handling of dymamic columns.
                if not dyna_prop then
                    dyna_prop = window.prop false
                    @columns_visibility![key] = dyna_prop
                dyna_prop value == true

    save_table_settings: ~>
        table_settings = do
            visible_columns: @columns_visibility! |> Obj.map (x) -> x!  # unpack properties

        table_settings_json = JSON.stringify table_settings
        legacy_user.set_setting ['table-settings', @options.unique_name], table_settings_json

    get_default_columns_visibility: ->
        @columns!
            |> map (column) -> [column.name, window.prop (is_default_visible column)]
            |> pairs-to-obj

    visible_columns: ->
        is_visible = (column) ~>
            dyna_prop = @columns_visibility![column.name]
            # TODO need better handling of dynamic columns.
            #      If you're improving this please also have a
            #      look at the column selector modal.
            #      Hint: use the new modal.ls component!
            if not dyna_prop then
                dyna_prop = window.prop (is_default_visible column)
                @columns_visibility![column.name] = dyna_prop
            dyna_prop!

        @columns! |> filter is_visible

    total_columns_width: ->
        @visible_columns! |> map (.width) |> sum

    autoscaled_columns: ->
        table-base.auto-scale-columns @visible_columns!


class TableRow
    (vnode) ->
        @table = vnode.attrs.table
        @record = vnode.attrs.record with details_expanded: window.prop false

        # Livescript expands '(f or g) x' to 'f(x) or g(x)'
        # That's why we need the intermediate variable 'transform'.
        transform = @table.row_model or id
        @record = transform @record

    toggle_expand_details: (e) ~>
        # Ignore this click if e.target, or any of it's parents,
        # have .no-click
        if ($ e.target .closest '.no-click' .length) == 0 then
            @record.details_expanded (not @record.details_expanded!)

    view: ->
        {onclick, row_classes} = @table.options

        if onclick? then
            original-onclick = onclick
            onclick = (record) -> (e) ->
                # Ignore this click if e.target, or any of it's parents,
                # have .no-click
                if ($ e.target .closest '.no-click' .length) == 0 then
                    (original-onclick record) e

        if row_classes?
            class_names = row_classes(@record)

        if !class_names
            class_names = []

        if @record.details_expanded! then
            class_names.push('selected')

        row-attrs = do
            onclick: if onclick?
                        then onclick @record
                        else if @table.view_details
                        then @toggle_expand_details

        value_for_column = (column) ~>
            if column.value
                column.value
            else if column.function
                column.function @record
            else
                field = column.show_field or column.field
                value = utils.get_descendant_prop @record, field
                if column.transform
                then column.transform value
                else value

        m 'tbody.table-row' {class: class_names.join(' ')},
            m 'tr' row-attrs, @table.visible_columns!map (column) ->
                props = {}
                classes = column.classes
                if column.ellipsis
                    classes = ['ellipsis', classes].join(' ')
                if classes
                    props['class'] = classes

                m 'td' props,
                    if column.ellipsis then
                        m 'span', value_for_column(column)
                    else
                        value_for_column(column)

            if @record.details_expanded! then
                if details = @table.view_details @record then
                    m 'tr.well.selected' m 'td' {colspan: '100%'} m '.well-container' details


export class Table
    (vnode) ->
        @vm = new ViewModel vnode.attrs

    show_column_selector_dialog: ~>
        # Make a temporary copy of the columns visibility while editing.
        edit_columns_visibility = @vm.columns_visibility!
            |> Obj.map (prop) -> window.prop prop!

        dialog.show do
            unique_name: "#{@vm.options.unique_name}_column_selector_dialog"
            title: 'Select visible columns:'
            body: m '',
                @vm.columns!map (column, index) ~>
                    m 'div' inputs.checkbox(edit_columns_visibility[column.name], {
                        disabled: (index == @vm.columns!length - 1),
                        label: column.name,
                    })

            extra_footer_buttons: [
                do
                    label: 'Reset'
                    onclick: ~>
                        legacy_user.remove_setting ['table-settings', @vm.options.unique_name]
                        # Part of workaround for non-updating checkboxes: do a full
                        # page reload to load the default columns and have all
                        # checkboxes for default visibile columns checked.
                        window.location.reload(true)
            ]

            onclose: ~>
                # Part of workaround for non-updating checkboxes: a full page
                # reload if something has changed when closing the dialog.
                for name, prop of edit_columns_visibility
                    if @vm.columns_visibility![name]! != prop!
                        window.location.reload(true)
                        break

            # Show/hide the columns and persist the new settings.
            onsubmit: ~>
                for name, prop of edit_columns_visibility
                    @vm.columns_visibility![name] prop!
                @vm.save_table_settings!

    get_table_wrapper: ->
        if @vm.options.sticky_header then
            if @vm.options.with_buttons
            then fixed-table-header.with-buttons
            else fixed-table-header.without-buttons
        else
            id

    get_table_classes: ->
        ['c-table', 'table'] ++ \
        if not @vm.options.search_table_style? or @vm.options.search_table_style
            ['search-table', 'clickable']
        else
            ['table-hover']

    view_column_header: (column) ->
        if column.header? then column.header else column.name

    get_body_items: ->
        @vm.items!

    sort_column: ->
        console.error 'sort not implemented'

    view_footer: ->
        void

    view: (vnode) ->
        wrapper = @get_table_wrapper!
        classes = join ' ' @get_table_classes!

        table-attrs = do
            class: classes

        m '.c-collection-table .table-container' wrapper m 'table' table-attrs,
            if @vm.options.autoscale
                # Seems broken; but remain current behaviour intact.
                table-base.colgroup @vm.autoscaled_columns!
            else
                table-base.colgroup @vm.visible_columns!

            # Headers.
            table-base.header @vm.visible_columns!, (cell, column, index) ~>
                attrs = do
                    # 'ellipsis' class on the header breaks the sticky header. As a
                    # workaround, filter out 'ellipsis' from the header classes.
                    class:      if column.classes?
                                then reject (== 'ellipsis'), column.classes
                                else ''
                    # If a column has a sort_function defined, use it on
                    # click. Otherwise, if a column has sort=true, set the
                    # sort_column function on the Table instance.
                    # If a column has 'descending' set, set the default sorting
                    # direction to ascending=false. ascending=true is the sane
                    # default.
                    onclick:    if column.sort_function or column.sort then
                                    if column.sort_function
                                        column.sort_function
                                    else
                                        @sort_column.bind @,
                                            (column.sort_field or column.field),
                                            ascending=(not column.descending),
                                            sort_order=(column.sort_order || []),

                cell attrs,
                    @view_column_header column
                    if !vnode.attrs.hide_column_selector and index == @vm.visible_columns!length - 1
                        m 'span.glyphicon.glyphicon-th-list.fa-fw' do
                            onclick: utils.stop-propagation @show_column_selector_dialog.bind @


            # Body.
            @get_body_items!map (record) ~>
                # The key of the TableRow used to be the record's artkey.
                # However, record is not an prop, and if any of record's columns
                # change, but the key stays the same, Mithril does not recognize
                # any changes. As a consequence, the TableRow isn't updated and
                # keeps showing the old column values.
                # By serializing the entire record and using that as key, the key
                # changes if any column in record changes, and thus TableRow is
                # updated.
                m TableRow, do
                    key: json-format.serialize record
                    table: @vm
                    record: record

            # Optional footer.
            @view_footer!
