import m from 'mithril'
import {AuthLogin as AuthLoginCommon} from '@bitstillery/common/components'
import jwtDecode from 'jwt-decode'
import {$t, api, events, notifier} from '@bitstillery/common/app'

import {$s} from '@/app'
import {DEFAULT_ROUTE} from '@/routes'

export default class AuthLogin extends AuthLoginCommon {

    oninit() {
        $s.page.title = $t('landing.login.title', {vendor: process.env.MSI_TITLE})
        $s.page.subtitle = $t('landing.login.subtitle')
    }

    async login() {
        if (this.data.stepper.selection === 0) {
            const {result: result_preauth, success} = await api.post('users.get_pre_auth_token', {
                username: this.data.$m.email_address,
                password: this.data.$m.password,
            }) as any

            if (success) {
                this.data.pre_auth_token = result_preauth.token
                if (result_preauth.otp_validated) {
                    this.data.stepper.selection = 2
                } else {
                    const {result: result_secret_url} = await api.post('discover/data/users/otp-secret-url', {
                        pre_auth_token: result_preauth.token,
                    }, true) as any

                    this.data.otp_secret = result_secret_url.otp_secret_url
                    this.data.stepper.selection = 1
                }
            } else {
                notifier.notify($t('status.error.login.credentials_invalid'), 'danger', 7000)
            }
        } else {
            const {result, status_code} = await api.post('discover/data/users/otp', {
                otp: this.data.$m.otp_code,
                pre_auth_token: this.data.pre_auth_token,
            }, true)

            if (status_code === 200) {
                const claims:any = jwtDecode(result.token)
                $s.identity = {
                    artkey: claims.user_artkey,
                    exp: claims.exp,
                    token: result.token, // New API can be used from here on
                    user: {},
                }

                events.emit('identity.login')
                notifier.notify($t('page.title.login', {vendor: process.env.MSI_TITLE}), 'success')
                m.route.set(DEFAULT_ROUTE)
            } else if (status_code === 422) {
                this.data.otp_fail = result.detail
                notifier.notify($t('status.error.otp.invalid_notify'), 'danger', 7000)
            }
        }
    }
}
