import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'

import {ProductPhotoList} from '@/components/product_photos/product_photo_list'
import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {GetProductPhotoResponse} from '@/factserver_api/fact2server_api'

interface StockItemPhotoListAttrs {
    item_artkey: number
    item_reference: string
    on_product_photo_count?: (number_of_photos: number) => unknown
}

export class StockItemPhotoList extends MithrilTsxComponent<StockItemPhotoListAttrs> {
    product_photo_api = new ProductPhotoApi()

    product_photos: GetProductPhotoResponse[] = []
    is_fetching = false

    oncreate(vnode: m.Vnode<StockItemPhotoListAttrs>): void {
        this.fetch_product_photos(vnode)
    }

    download_all_files(vnode: m.Vnode<StockItemPhotoListAttrs>, include_internal: boolean): void {
        this.product_photo_api.download_all_for_item(
            vnode.attrs.item_artkey, include_internal, `photos-${vnode.attrs.item_reference}.zip`,
        )
    }

    fetch_product_photos(vnode: m.Vnode<StockItemPhotoListAttrs>): void {
        this.is_fetching = true
        this.product_photo_api.get_all_photos_for_stock_item(vnode.attrs.item_artkey).subscribe({
            next: (result) => {
                this.is_fetching = false
                this.product_photos = result
                if (vnode.attrs.on_product_photo_count) {
                    vnode.attrs.on_product_photo_count(this.product_photos.length)
                }
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
            },
        })
    }

    view(vnode: m.Vnode<StockItemPhotoListAttrs, this>): m.Children {
        return (
            <div>
                <div className={'row'}>
                    {!this.is_fetching && (
                        <ProductPhotoList
                            product_photos={this.product_photos}
                            item_artkey={vnode.attrs.item_artkey}
                            download_all_photos={(include_internal: boolean) =>
                                this.download_all_files(vnode, include_internal)
                            }
                            on_product_photos_changed={() => this.fetch_product_photos(vnode)}
                            on_sort_product_photo_items={(artkeys: number[]) => {
                                artkeys.forEach((artkey, index) => {
                                    const pp = this.product_photos.find((pp) => pp.artkey === artkey)
                                    if (pp) {
                                        pp.rank = index + 1
                                    }
                                })
                            }}
                        />
                    )}
                    {this.is_fetching && <Spinner />}
                </div>
            </div>
        )
    }
}
