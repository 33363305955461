import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from './api'

export interface GetMutationListResponse {
    artkey: number
    reference: string
    created_on: string
    item_mutation_reason: string
    description: string
    stock_value_difference: number
    user_profile_name: string
    status: string
    target_warehouse_name: string
    expected_delivery_date: string
    warehouse_status: string
    warehouse_reference: string
}

export interface ProductPhotoData {
    artkey: number
    rank: number
    item_artkey: number
    is_internal: boolean
    created_on: string
    s3_location: string
    file_name: string
}

export interface GetProductPhotosForReviewResponse {
    artkey: number
    lot: number
    reference: string
    product_photos: ProductPhotoData[]
    purchase_order_item: {
        purchase_order: {
            artkey: number
            reference: string
        }

        case: {
            bottle: {
                product: {
                    name: string
                }
            }
        }

        created_on: string
    }

    // fields only used in the view.
    is_approved: boolean
    is_showing_details: boolean
}

export enum StockSearchMode {
    AVAILABLE_ONLY = 'available_only',
    INSTOCK_ONLY = 'instock_only',
    INPURCHASE_ONLY = 'inpurchase_only',
    INSALE_ONLY = 'insale_only',
    INTRANSIT_ONLY = 'intransit_only',
    AVAILABLE_OR_INSTOCK = 'available_or_instock',
    INSTOCK_AND_NOT_AVAILABLE = 'instock_and_not_available',
    NOT_INVOICED = 'not_invoiced',
    INSALE_OTHER_ACCOUNT = 'insale_other_account',
    ALL = 'all',
}

export interface GetStockRecordsRequest {
    bottle_artkey?: number
    case_artkey?: number
    ignore_ref?: boolean
    product_artkey?: number
    bottle_volume?: number
    bottle_alcohol_percentage?: number
    mode?: StockSearchMode
    limit?: number
    all_accounts?: boolean
}

export interface Profile {
    artkey: number
    name: string
}

export interface User {
    artkey: number
    profile: Profile
}

export enum ItemMutationStatus {
    IN_TRANSIT = 'In transit',
    IN_STOCK = 'In stock',
}

export enum ItemMutationReason {
    MOVE_TO_WAREHOUSE = 'Move to Warehouse',
}

export interface Item {
    artkey: number
    case: {
        artkey: number
        bottle: {
            artkey: number
            product: {
                artkey: number
                name: string
            }
            alcohol_percentage: string
            refill_status: string
            volume: string
            loendersloot_id: string
        }
        customs_status: string
        number_of_bottles: number
        gift_box_type: string
        tax_label: string
        best_before_date: string
        item_tags: string[] | null
        serialized_item_tags: string
    }
    bottle_gtin_code: string
    case_gtin_code: string
    country_of_origin: string
    entry_date: string
    is_in_stock: boolean
    lot: string | null

    cases_per_pallet: number
    remark: string

    number_of_cases: number
    number_of_cases_in_purchase: number | null
    number_of_cases_in_sales: number | null
    number_of_cases_in_stock: number | null
    number_of_shipped_cases: number | null
    number_of_cases_available: number | null
    purchase_order_item: {
        artkey: number
        was_bought_in: string
        purchase_order: {
            artkey: number
            reference: string
        }
    }

    purchase_order_reference: string
    reference: string
    warehouse: {
        artkey: number
        name: string
    }
    was_bought_for: number
    was_bought_for_plus_costs: number
    was_bought_in: string

    // The artkeys are formatted as strings.
    hide_from_pricelist_for_suppliers: {
        artkey: number
        name: string
    }[]
    hide_from_pricelist_for_countries: {
        artkey: number
        country_code: string
    }[]
}

export interface UpdateItemRequest {
    item_artkey: number
    serialized_item_tags: string
    best_before_date: string | null
    cases_per_pallet: number
    remark: string
    country_of_origin: string
    bottle_gtin_code: string
    case_gtin_code: string
}

export type UpdateItemQuickRequest = Pick<UpdateItemRequest, 'item_artkey' | 'bottle_gtin_code' | 'case_gtin_code'>

export interface Attachment {
    artkey: number
    file_name: string
    file_hash: string
    description: string
    creator: User
    category: string
}

export interface GetItemMutationByReferenceResponse {
    artkey: number
    created_on: string
    description: string
    item_mutation_reason: string
    outtake_instruction_comment: string | null
    reference: string
    incoterm: string | null
    incoterm_location: string | null
    number_of_euro_pallets: number | null
    number_of_block_pallets: number | null
    sent_to_loendersloot_on: string | null
    expected_delivery_date: string | null
    planned_unloading_date: string | null
    warehouse_status: string | null
    frontoffice_instruction: string | null
    warehouse_instruction: string | null
    freight_instruction: string | null
    last_warehouse_message: string | null
    stock_value_difference: number
    status: string
    warehouse_reference: string | null
    transport_costs: number
    number_of_edi_messages: number
    user: User
    target_warehouse: {
        artkey: number
        name: string
        street_address: string
        zip_code: string
        city: string
        country_code: string
        warehouse_id: string
        excise_id: string | null
    } | null
    parts: {
        sources: Item[]
        targets: Item[]
        number_of_cases_in_mutation: number | null
    }[]
    sources: Item[]
    targets: Item[]
}

export interface GetStockRecordsResponse {
    total: number
    stock_items: StockRecord[]
}

export interface StockRecord {
    artkey: number
    expected_delivery_date: string
    date: string
    account_slug: string
    account_name: string
    bottle_alcohol_percentage: number
    bottle_artkey: number
    bottle_refill_status: string
    bottle_volume: number
    bottle_excise_nl: number
    case_artkey: number
    case_tax_label: string
    case_gift_box_type: string
    case_number_of_bottles: number
    case_excise_nl: number
    sales_price_per_case: number
    offer_item_list_price: number
    case_article_code: string
    case_customs_status: string
    item_best_before_date: string
    item_item_tags_per_category: string
    item_damages: string
    item_general_tags: string
    item_pack_size: string
    item_packaging: string
    item_country_of_origin: string
    item_entry_date: string
    item_lot: string
    item_number_of_cases_available: number
    item_number_of_cases_in_purchase: number
    item_number_of_cases_in_stock: number
    item_number_of_cases_in_sales: number
    item_reference: string
    item_total_stock_value: number
    item_euro_total_stock_value: number
    item_was_bought_for: number
    item_euro_was_bought_for: number
    item_was_bought_for_plus_costs: number
    item_euro_was_bought_for_plus_costs: number
    case_cases_per_pallet: number
    item_remark: string
    item_needs_approval: boolean
    item_is_approved: boolean
    item_throughput: number
    item_stock_age: number
    item_number_of_sales_order_items: number
    item_number_of_photos: number
    item_mutation_reference: string
    product_category_name: string
    product_name: string
    purchase_order_artkey: number
    purchase_order_bought_against_rate: number
    purchase_order_is_in_stock: boolean
    purchase_order_expected_delivery_date: string
    purchase_order_confirmation_date: string
    purchase_order_reference: string
    purchase_order_was_bought_in: string
    purchase_order_item_artkey: number
    purchase_order_item_remark: string
    suggested_price_per_case: number
    sales_order_credit_item_artkey: number
    supplier_artkey: number
    supplier_name: string
    warehouse_name: string
}

export interface ApproveBulkRequest {
    bulk_items: Array<ApprovalItemData>
}

export interface ApprovalItemData {
    item_artkey: number
    sales_price_per_case: number
    maximum_order_quantity?: number | null
    minimum_order_quantity?: number | null
    case_artkey: number
    offer_item_type?: string
    offer_item_artkey?: number
}

export interface ApproveBulkResponse {
    approved_items: Array<number>
}

export interface UpdateOfferItemRequest {
    item_artkey: number
    case_artkey: string
    sales_price_per_case: string
    offer_item_type?: string
    maximum_quantity?: number
    minimum_quantity?: number
}

export interface UpdateOfferItemResponse {
    artkey: number
}

export class StockApi {
    api = new Api()

    delete_item_mutation(artkey: number): Observable<any> {
        return this.api.post_request('stock.delete_warehouse_move_mutation', {
            item_mutation_artkey: artkey,
        })
    }

    get_item(item_reference: string): Observable<Item> {
        return this.api.post_request('stock.get_item', {item_reference: item_reference})
    }

    get_item_mutation_by_reference(reference: string): Observable<GetItemMutationByReferenceResponse> {
        return this.api.post_request('stock.get_item_mutation', {
            item_mutation_reference: reference,
        })
    }

    get_stock_records(request: GetStockRecordsRequest): Observable<GetStockRecordsResponse> {
        return this.api.post_request('stock.get_stock_records', request)
    }

    get_stock_records_by_specs(request: GetStockRecordsRequest): Observable<GetStockRecordsResponse> {
        return this.api.post_request('stock.get_stock_records', request)
    }

    send_mutation_xml_to_loendersloot(item: GetItemMutationByReferenceResponse): Observable<FactserverEmptyResponse> {
        const expected_delivery_date = item.expected_delivery_date?.includes('T')
            ? item.expected_delivery_date.split('T')[0]
            : item.expected_delivery_date
        return this.api.post_request('loendersloot.messaging.send_mutation_xml_to_loendersloot', {
            item_mutation_reference: item.reference,
            expected_delivery_date: expected_delivery_date,
            number_of_euro_pallets: item.number_of_euro_pallets,
            number_of_block_pallets: item.number_of_block_pallets,
            frontoffice_instruction: item.frontoffice_instruction,
            warehouse_instruction: item.warehouse_instruction,
            freight_instruction: item.freight_instruction,
        })
    }

    update_item(request: UpdateItemRequest): Observable<void> {
        return this.api.post_request('stock.update_item', request)
    }

    update_item_mutation_outtake_instruction_comment(
        artkey: number,
        comment: string,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('stock.update_item_mutation_outtake_instruction_comment', {
            artkey: artkey,
            outtake_instruction_comment: comment,
        })
    }

    update_item_quick(request: UpdateItemQuickRequest): Observable<void> {
        return this.api.post_request('stock.update_item_quick', request)
    }

    approve_item(request: ApprovalItemData): Observable<FactserverEmptyResponse> {
        return this.api.post_request('approval.approve_item', request)
    }

    approve_bulk_items(request: ApproveBulkRequest): Observable<ApproveBulkResponse> {
        return this.api.post_request('approval.approve_bulk_items', request)
    }

    update_offer_item(request: UpdateOfferItemRequest): Observable<UpdateOfferItemResponse> {
        return this.api.post_request('pricelist.create_or_update_offer_item', request)
    }

    get_hidden_information_for_stock_item(stock_item_artkey: number): Observable<any> {
        return this.api.get(`discover/items/${stock_item_artkey}/hidden-information`)
    }

    delete_hidden_country_for_item(item_artkey: number, country_code: string): Observable<any> {
        return this.api.delete(`discover/items/${item_artkey}/hidden-information/countries/${country_code}`)
    }

    delete_hidden_relation_for_item(item_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.delete(`discover/items/${item_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    add_hidden_country_for_item(item_artkey: number, country_code: string): Observable<any> {
        return this.api.post_fact2server_request(`discover/items/${item_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation_for_item(item_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.post_fact2server_request(`discover/items/${item_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }

}
