import m from 'mithril'

import {GenericList} from '../generic/generic_list'

interface BrandHolder {
    name: string
}

const BrandHolderList: m.ClosureComponent<void> = () => {
    const is_match = (brand: BrandHolder, term: string): boolean => {
        return [brand.name.toLowerCase().indexOf(term.toLowerCase()) > -1].some((element) => element === true)
    }

    return {
        view(): m.Children {
            return (
                <GenericList<BrandHolder>
                    readable_entity_name="BrandHolder"
                    base_url="/data/brand-holders/"
                    list_api_call="brand_holder.get_all"
                    is_match={is_match}
                    columns={[{key: 'name', label: 'Name'}]}
                />
            )
        },
    }
}

// This is necessary for pages.js to work correctly in its current setup.
export default BrandHolderList
