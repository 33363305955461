m = require 'mithril'
{sort, unique, join} = require "prelude-ls"
{Panel} = require '@/components/panel'
table = require '@/components/table.ls'
{a}: utils = require '../../utils.ls'
{Amount} = require '@bitstillery/common/components'
{Pager} = require '@/components/pager/pager.tsx'
{to_specs} = require '@bitstillery/common/lib/specs'
{FilterType} = require './models.ls'
{Popover} = require '@/components/popover/popover.ls'
{AmountUnit, Button, ButtonGroup, DataCard, Lot, Icon, Spinner} = require '@bitstillery/common/components'
app = require('@/app')

{$s} = require('@/app')
{accountIconBySlug} = require 'accounts.ls'

export class ShowPurchaseHistory
    (vnode) ->
        @purchase_history = vnode.attrs.purchase_history
        @purchase_history_total = vnode.attrs.purchase_history_total

    view: (vnode) ->
        if vnode.attrs.count! > 0
            vnode.attrs.toolbar = m Pager, do
                page_size: vnode.attrs.limit!
                count: vnode.attrs.count!
                fetch_page: vnode.attrs.fetch_page
                display_page_count: false

        show_product = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.PRODUCT])
        show_relation = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.RELATION])
        show_specs = vnode.attrs.show_specs and not show_product

        m '.c-market-explore-purchase-history' {key: vnode.attrs.unique_name},
            m Panel, do
                title: vnode.attrs.title
                toolbar: vnode.attrs.toolbar
                collapsible: vnode.attrs.collapsible
                collapsed: vnode.attrs.collapsed
                download_callback: if vnode.attrs.count! > 0 then vnode.attrs.download_callback else undefined
            ,
                if vnode.attrs.loading!
                    m Spinner
                else if @purchase_history!length
                    purchase_history = @purchase_history
                    [
                        m DataCard, {
                            model: do
                                data: [
                                    {label: 'Purchase history for', value: vnode.attrs.header()},
                                    {label: 'Total purchase value', value: m Amount, {
                                        amount: +@purchase_history_total!
                                        currency: app.$s.currencies.default
                                    }}
                                ]
                            type: 'dense'
                        }

                        table.table purchase_history, [
                            do
                                field: 'purchase_order_reference'
                                name: 'Purchase Order'
                                width: 13
                                function: (record) ~>
                                    m '.td-group',
                                        m 'span.header',
                                            [
                                                accountIconBySlug(record.account_slug, record.account_name)
                                                m m.route.Link, do
                                                    target: '_blank'
                                                    href: "/purchase-orders/manage/#{record.purchase_order_reference}?account=#{record.account_slug}"
                                                , record.purchase_order_reference
                                            ]
                                        if show_specs then
                                            m 'span.details',
                                                to_specs({
                                                    bottle_volume: record.bottle_volume,
                                                    bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                    bottle_refill_status: record.bottle_refill_status,
                                                    case_customs_status: record.case_customs_status,
                                                    case_gift_box_type: record.case_gift_box_type,
                                                    case_number_of_bottles: record.case_number_of_bottles,
                                                    case_tax_label: record.case_tax_label,
                                                })
                                        if record.aux_info then
                                            m 'span.details', record.aux_info

                            do
                                field: 'purchase_order_status'
                                name: 'Status'
                                function: (record) ~>
                                    m '.td-group',
                                        m 'span' record.purchase_order_status
                                        m 'span.details' utils.format-date record.purchase_order_latest_status_update
                                width: 10


                            if show_relation then do
                                name: 'Supplier'
                                ellipsis: true
                                field: 'supplier_name'
                                width: 20
                            if show_product then do
                                name: 'Product'
                                function: (record) ~>
                                    record.product_name
                            do
                                classes: ['center'],
                                field: 'purchase_order_item_number_of_cases'
                                name: 'Cases'
                            do
                                name: 'Purchase Price'
                                function: (record) ~>
                                    m AmountUnit, {
                                        case_amount: +record.purchase_order_item_was_bought_for,
                                        case_number_of_bottles: record.case_number_of_bottles,
                                        currency: record.purchase_order_was_bought_in,
                                        display_currency: app.$s.currencies.default,
                                        excise: +record.case_excise_nl,
                                        rate: record.purchase_order_bought_against_rate,
                                        origin: if record.purchase_order_was_bought_in != app.$s.currencies.default then {
                                            case_amount: +record.purchase_order_item_was_bought_for,
                                            currency: record.purchase_order_was_bought_in,
                                        } else undefined
                                    }
                            do
                                name: 'Total Price'
                                function: (record) ~>
                                    m Amount, do
                                        amount: +record.purchase_order_item_total_was_bought_for
                                        excise: +record.case_excise_nl * record.purchase_order_item_number_of_cases
                                        currency: record.purchase_order_was_bought_in
                                        rate: record.purchase_order_bought_against_rate
                            do
                                name: 'Warehouse'
                                function: (record) ~>
                                    for lot_number in sort unique record.lots
                                        m Lot, {
                                            lot_number: lot_number,
                                            warehouse_name: record.warehouse_name,
                                        }
                            do
                                classes: ['actions']
                                function: (record) ~>
                                    m '.actions-group no-click',
                                        m ButtonGroup,
                                            if record.references
                                                for reference in sort unique record.references
                                                    m Button, {
                                                        icon: "stock",
                                                        onclick: ~> window.open("#!/stock/manage/#{reference}")
                                                        tip: "Manage stock item #{reference}"
                                                    }

                        ], do
                            search_table_style: false
                            sticky_header: false
                    ]
                else
                    'No results'
