import {Observable} from 'rxjs'

import {Api} from './api'

export interface GenerateCBSExportResponse {
    file_base64_encoded: string
}

export class CBSApi {
    api = new Api()

    generate_cbs_export(month: number, year: number): Observable<GenerateCBSExportResponse> {
        return this.api.post_request('cbs.generate_cbs_export', {
            month: month,
            year: year,
        })
    }
}
