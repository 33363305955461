import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {$t, api, events, notifier} from '@bitstillery/common/app'
import {to_specs} from '@bitstillery/common/lib/specs'
import {CellAvailability, Icon} from '@bitstillery/common/components'
import {
    Button,
    ButtonDataCard,
    ButtonGroup,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    PanelFilters,
    Ranking,
    RowActionEdit,
    RowActionDelete,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {Amount, AmountUnit} from '@bitstillery/common/components'

import {RowDetailPricelistItem} from './row_detail_pricelist_item'

import {$m, $s} from '@/app'
import {create_download_for_blob} from '@/factserver_api/api'
import {PanelSpli} from '@/market/components/panel_spli'
import {
    GetPurchaseOrderForSPLIResult,
    GetSpliThroughputResponse,
    RelationCompanyType,
} from '@/factserver_api/fact2server_api'
import {model} from '@/market/list_pricelist_items'
import {BottleThroughput} from '@/components/market_info/bottle_throughput.tsx'

const RANKING_ENABLED_STATUS = ['always_active', 'active']

export const collection = new CollectionProxy()

const columns = [
    {
        className: 'cell-group',
        name: 'Product',
        render: (row) => {
            const elements: any[] = []
            const country = row.country_of_origin ? row.country_of_origin : row.default_country_code
            if (RANKING_ENABLED_STATUS.includes(model.supplier_pricelist?.status)) {
                elements.push(
                    <Ranking
                        model={{
                            rank: row.rank,
                            tip: () => `Ranking: ${row.rank}/${row.total}`,
                            total:row.total,
                        }}
                    />,
                )
            }
            elements.push(<div className="td-group">
                <span className="header">
                    {country && <Country
                        className="mr-05"
                        country_code={country}
                    />}
                    {row.is_available ? row.product_name : <s>{row.product_name}</s>}
                </span>
                {(() => {
                    return <span>{to_specs({
                        bottle_alcohol_percentage: row.alcohol_percentage,
                        bottle_refill_status: row.refill_status,
                        bottle_volume: row.volume,
                        case_number_of_bottles: row.number_of_bottles_per_case,
                        case_gift_box_type: row.gift_box_type,
                        case_customs_status: row.customs_status,
                    })}</span>
                })()}
            </div>)

            return elements
        },
        width: '2fr',
    }, {
        name: 'Supplier price',
        render: (row) => {
            let price_per_case = row.price_per_case
            if (!price_per_case) {
                price_per_case = row.price_per_bottle * row.number_of_bottles_per_case
            }
            return <div className="td-group">
                <AmountUnit
                    case_amount={price_per_case}
                    case_number_of_bottles={row.number_of_bottles_per_case}
                    currency={row.currency}
                    display_currency={$s.currencies.default}
                />
            </div>
        },
    }, {
        name: 'Availability',
        render: (row) => <div className="td-group">
            {(() => {
                if (row.number_of_cases) return `${row.number_of_cases} cases`
                else if (row.number_of_bottles) return `${row.number_of_bottles} bottles`
                return '-'
            })()}
            {row.availability_status && <span className="details">{row.availability_status}</span>}
            {row.aux_info && <span className="details">{row.aux_info}</span>}
        </div>,
    },
    {
        name: 'Similar stock',
        render: (row) => <div className="td-group">
            <CellAvailability key={row.artkey} row={row}/>
        </div>,
    }, {
        name: 'Oldest stock age',
        render: (row) => row.max_stock_age ? row.max_stock_age : '-',
    }, {
        name: 'Average purchase price',
        render: (row:any) => {
            if (!row.avg_purchase_price) {
                return '-'
            }

            return <Amount
                amount={row.avg_purchase_price}
                currency={'EUR'}
            />
        },
    },
    {
        name: 'Throughput',
        render: (row) => {
            if (row.throughput) {
                return <div className="td-group">
                    <BottleThroughput
                        type={'Short'}
                        prefix={'Sold'}
                        throughput={{
                            last_month: row.throughput.sold_last_month,
                            last_quarter: row.throughput.sold_last_quarter,
                            last_year: row.throughput.sold_last_year,
                        }}
                    />
                    <BottleThroughput
                        type={'Short'}
                        prefix={'Bought'}
                        throughput={{
                            last_month: row.throughput.bought_last_month,
                            last_quarter: row.throughput.bought_last_quarter,
                            last_year: row.throughput.bought_last_year,
                        }}
                    />
                </div>

            }
        },
    },
]

export class SpliResolved extends MithrilTsxComponent<unknown> {
    async oninit() {
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/supplier-price-lists/${m.route.param('artkey')}/items/collection-view`,
            },
        }, undefined, {
            items_queried: async({result}) => {
                if (!result.length) {
                    return result
                }
                const spli_artkeys = result.map((i) => i.artkey)
                for (const item of result) {
                    item.purchase_orders = []
                }

                const api_calls = await Promise.all([
                    api.get<GetPurchaseOrderForSPLIResult[]>(`discover/supplier-price-lists/${m.route.param('artkey')}/items/purchase-orders?spli_artkeys=${spli_artkeys.join(',')}`),
                    api.get<GetSpliThroughputResponse[]>(`discover/supplier-price-lists/${m.route.param('artkey')}/items/throughput?spli_artkeys=${spli_artkeys.join(',')}`),
                ])

                const {result:spli_purchases} = api_calls[0]

                const items_by_artkey = result.reduce((acc, item) => {
                    acc[item.artkey] = item
                    return acc
                }, {})

                for (const spli_purchase of spli_purchases) {
                    items_by_artkey[spli_purchase.spli_artkey].purchase_orders.push(spli_purchase)
                }

                const {result:throughput_info} = api_calls[1]
                for (const throughput of throughput_info) {
                    items_by_artkey[throughput.artkey].throughput = throughput
                }

                return result
            },
        })
        // Required by legacy forms; e.g. add_to_order/add_to_offer
        events.on('spli:refetch', collection.reset_query, collection)
    }

    onremove() {
        events.off('spli:refetch', collection.reset_query, collection)
    }

    view(): m.Children {
        const supplier_pricelist = model.supplier_pricelist as any
        const supplier = supplier_pricelist.supplier
        return <div className="c-spli-resolved view-container">
            <PanelFilters collection={collection} />
            <CollectionView mode="table">
                <div className="btn-toolbar">
                    <ButtonGroup>
                        <ButtonDataCard />
                    </ButtonGroup>
                    <ButtonGroup classname="ml-2">
                        <Button
                            active={$s.context.id === null && $s.context.name === 'edit_spli'}
                            icon="plus"
                            text="Add Pricelist Item"
                            tip="Only applicable on the Resolved & Analysis tabs"
                            onclick={() => {
                                Object.assign($s.context, {
                                    data: $m.pricelists.spli_row_model(),
                                    id: null,
                                    name: 'edit_spli',
                                    supplier_price_list_artkey: +m.route.param('artkey'),
                                    title: 'Add Pricelist Item',
                                })
                            }}
                            type="info"
                            variant="context"
                        />
                        <Button
                            icon="excel"
                            text="Excel Export"
                            onclick={async() => {
                                model.loading.export = true
                                try {
                                    const {result: blob} = await api.get<Blob>(`discover/supplier-price-lists/${supplier_pricelist.artkey}/export`)
                                    create_download_for_blob(blob, `supplier-pricelist-${supplier_pricelist.artkey}.xlsx`)
                                } catch (err) {
                                    notifier.notify('Something went wrong downloading the export of this pricelist', 'danger')
                                } finally {
                                    model.loading.export = false
                                }
                            }}
                            disabled={model.loading.export}
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>
                </div>

                <CollectionHeader collection={collection} columns={columns} />

                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_detail={(row) => {
                        row.spl_header_list = supplier_pricelist.header_as_list
                        return <RowDetailPricelistItem row={row} />
                    }}
                    row_actions={(row) => {
                        return [
                            <RowActionEdit
                                collection={collection}
                                context={{mode: 'panel', name: 'edit_spli', title: 'Edit Pricelist item'}}
                                mode="panel"
                                row={row}
                                type="default"
                            />,
                            <RowActionEdit
                                collection={collection}
                                context={{mode: 'panel', name: 'edit_add_to_order', title: 'Add to Purchase Order'}}
                                disabled={row.is_available}
                                icon="cart"
                                mode="panel"
                                row={row}
                                tip="Add to Purchase Order"
                                type="info"
                            />,
                            <RowActionEdit
                                collection={collection}
                                context={{mode: 'panel', name: 'edit_add_to_custom_offer', title: 'Add to Custom Offer'}}
                                disabled={supplier_pricelist.company_type === RelationCompanyType.Competitor || !row.is_available}
                                icon="copy"
                                row={row}
                                tip={supplier_pricelist.company_type === RelationCompanyType.Competitor ? 'This is a competitor' : 'Add to Custom Offer'}

                            />,
                            <Button
                                disabled={
                                    row.number_of_matching_tbo_items > 0 ||
                                    supplier.company_type === RelationCompanyType.Competitor ||
                                    !row.is_available
                                }
                                onclick={async(e) => {
                                    e.stopPropagation()

                                    await api.post('pricelist.create_tbo_offer_item', {
                                        spli_artkey: row.artkey,
                                        case: {
                                            bottle_artkey: row.bottle_artkey,
                                            number_of_bottles: row.number_of_bottles_per_case,
                                            gift_box_type: row.gift_box_type,
                                            customs_status: row.customs_status,
                                            tax_label: '',
                                            best_before_date: '',
                                            item_tags: [],
                                        },
                                    })
                                    row.number_of_matching_tbo_items += 1
                                }}
                                icon="thumbUp"
                                tip={() => {
                                    let tip
                                    if (row.number_of_matching_tbo_items > 0) {
                                        tip = 'Already on <a href="/#!/pricelist/work" target="_blank">TBO approval list</a>'
                                    } else if (supplier.company_type === RelationCompanyType.Competitor) {
                                        tip = 'Cannot add to TBO approval list; this is a competitor'
                                    } else if (!row.is_available) {
                                        tip = 'Cannot add to TBO approval list; this item is deactivated'
                                    } else {
                                        tip = 'Add to <a href="/#!/pricelist/work" target="_blank">TBO approval list</a>'
                                    }
                                    return tip
                                }}
                                type="default"
                                variant="toggle"
                            />,
                            <Button
                                icon={row.is_available ? 'toggle-switch-off-outline' : 'toggle-switch-outline'}
                                onclick={async(e) => {
                                    e.stopPropagation()
                                    await api.put(`discover/supplier-price-list-items/${row.artkey}/mark-${row.is_available ? 'unavailable' : 'available'}`, {})
                                    row.is_available = !row.is_available
                                    notifier.notify(row.is_available ? 'Pricelist item activated' : 'Pricelist item deactivated', 'warning')
                                }}
                                tip={() => `${row.is_available ? 'Deactivate' : 'Activate'} this pricelist item`}
                                type="warning"
                                variant="toggle"
                            />,
                            <RowActionDelete
                                icon={row.line_content ? 'deactivate' : 'trash'}
                                row={row}
                                row_delete={async() => {
                                    await api.post('offerprocessing.unresolve_spli', {
                                        artkey: row.artkey,
                                    })
                                    collection.soft_delete(row.artkey)
                                    if (row.line_content) {
                                        notifier.notify($t('notifications.spli_unresolved', {name: row.product_name}), 'warning')
                                    } else {
                                        notifier.notify($t('notifications.spli_deleted', {name: row.product_name}), 'warning')
                                    }
                                    events.emit('spl:refetch')
                                }}
                                tip={() => {
                                    if (row.line_content) return 'Unresolve this pricelist item'
                                    return 'Delete this pricelist item'
                                }}
                                variant="deactivate"
                            />,
                        ]
                    }}
                    row_status={(row) => {
                        return {
                            render: [
                                row.purchase_orders.length ? <Icon
                                    name="cart"
                                    tip={() => {
                                        const items = row.purchase_orders.map((i) => {
                                            return `<a href="/#!/purchase-orders/manage/${i.purchase_order_artkey}" target="_blank">P${i.purchase_order_artkey}</a> - ${i.number_of_cases} cases`
                                        })
                                        return `Already in purchase orders:<br/>${items.join('<br/>')}`

                                    }}
                                    type="info"
                                /> : null,
                                !row.is_available ? <Icon
                                    name="toggle-switch-off-outline"
                                    tip="This item is deactivated"
                                    type="warning"
                                /> : null,
                                row.number_of_matching_tbo_items > 0 ? <Icon
                                    name="thumbUp"
                                    tip="On TBO approval list"
                                    type="default"
                                /> : null,
                            ],
                            type: 'default',
                        }
                    }}
                />
            </CollectionView>
            <PanelSpli
                collection={collection}
                icon="list"
            />
        </div>
    }
}
