m = require 'mithril'
{compact, map, sort-with} = require 'prelude-ls'
{compare-on, format-date, to-string} = require 'utils.ls'
{deref} = require '@bitstillery/common/utils.ls'
{Popover} = require '@/components/popover/popover.ls'
app = require('@/app')

# Generates a list of label data for a PurchaseOrderItem or a (stock) Item.
get-label-list = (item) ->
    item_labels = (deref item.item_tags) || []
        |> map app.$m.data.item_tag.get_item_tag
        |> compact
        |> map (tag) -> do
            category: if tag.category!? then tag.category!name! else ''
            name: tag.name!
            abbreviation: if tag.abbreviation! then tag.abbreviation! else tag.name!

    if tax_label = deref item.tax_label then
        item_labels.push do
            category: 'Tax Label'
            name: tax_label
            abbreviation: tax_label

    if best_before_date = deref item.best_before_date then
        item_labels.push do
            category: 'Best Before Date'
            name: format-date best_before_date
            abbreviation: 'BBD'

    if cases_per_pallet = deref item.cases_per_pallet then
        item_labels.push do
            category: 'Cases Per Pallet'
            name: to-string cases_per_pallet
            abbreviation: 'CPP'

    if remark = deref item.remark then
        item_labels.push do
            category: 'Remark'
            name: remark
            abbreviation: 'RE'

    return item_labels


# A mapping of categories and colors.
# Some are double, as they are used in full as well as in abbreviated form.
label-color = (category) ->
    switch category
    | 'Tax Label'        => '#e4d1d1'
    | 'Packaging'        => '#b9b0b0'
    | 'Pack Size'        => '#d9ecd0'
    | 'Damages'          => '#b2c2bf'
    | 'General'          => '#77a8a8'
    | 'Best Before Date' => '#fff2df'
    | 'BBD'              => '#fff2df'
    | 'Cases Per Pallet' => '#d6d4e0'
    | 'CPP'              => '#d6d4e0'
    | otherwise          => '#87bdd8'


# Renders a list of labels.
export labels = (list) ->
    list
    |> sort-with compare-on (.category), (.name)
    |> map (tag) ->
        m 'span.label.tag-label' do
            style:
                'background-color': label-color tag.category
        , "#{tag.category}: #{tag.name}"


# Renders a list of "icons": for each item in the list, a label is rendered
# with an abbreviation as text and a popover with the category and name.
export label-icons = (list) ->
    list
    |> sort-with compare-on (.category), (.name)
    |> map (tag) ->
        m Popover, {title: tag.category or 'Item tag', content: tag.name},
            m 'span.label.tag-label-icon' do
                style:
                    'background-color': label-color tag.category
            , "#{tag.abbreviation}"


# Renders a list of "icons" for a PurchaseOrderItem or a (stock) Item.
export label-icons-for-item = (item) ->
    label-icons get-label-list item
