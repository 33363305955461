{icon} = require './icon.ls'
{m-ext} = require '../utils.ls'


button = (init-attrs) ->
    m-ext 'button.btn', init-attrs


export text-button = (text, options={}, init-attrs={}) ->
    (button init-attrs) options, text


export button-with-icon = (text, icon-id, options={}, init-attrs={}) ->
    (button init-attrs) options, [(icon icon-id), ' ' + text]


export icon-button = (icon-id, options={}, init-attrs={}) ->
    (button init-attrs) options, (icon icon-id)


mk-def-icon-button = (icon-id, cls) ->
    (options={}) ->
        icon-button icon-id, options, {class: cls}


export ok = mk-def-icon-button 'ok', 'btn-success'
export cancel = mk-def-icon-button 'ban-circle', 'btn-info'
export edit = mk-def-icon-button 'pencil', 'btn-default'
export remove = mk-def-icon-button 'remove', 'btn-danger'

