m = require 'mithril'

api = require 'api.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{EmailPreview} = require 'email/components/email_preview.ls'
{EmailBatchType} = require 'email/models.ls'

export class EmailBatchRecipients
    (vnode) ->
        @email_batch = vnode.attrs.email_batch
        @offer_artkey = @email_batch!offer_artkey
        @recipients = new Collection do
            api_function_name: 'email.batch.get_recipients'
            additional_params: ~> {'email_batch_artkey': @email_batch!artkey}
            query_limit: 25
            default_sort_by: 'artkey'
            default_sort_order: 'asc'

    oncreate: ~>
        @recipients.query!

    fetch_preview: (record) ~>
        if record.preview_body != undefined
            return
        data = do
            offer_email_batch_artkey: @email_batch!artkey
            subject: @email_batch!subject
            body: @email_batch!body
            pricelist_type: @email_batch!batch_type
            offer_artkey: @offer_artkey
            supplier_artkey: record.artkey
            from_email_address_artkey: @email_batch!use_sent_from_user_artkey
        record.preview_body = window.prop ''
        api.call-and-then 'pricelistbatch.get_rendered_email_for_supplier' data, do
            success: (resp) ~>
                if resp.result.message !== undefined
                    record.preview_body 'Error while rendering preview: ' + resp.result.message
                else
                    record.preview_body resp.result.body

    view: ~>
        m '.row' m '.col-lg-4' m 'h4' 'Recipients'
        m '.row', m '.col-sm-12', m CollectionTable, do
            collection: @recipients
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'email_batch_recipients'
            view_details: (record) ~>
                @fetch_preview record
                m '.col-sm-8.content-preview' m EmailPreview, email: record.preview_body
            columns:
                do
                    width: 2
                    name: 'Relation'
                    field: 'supplier_name'
                    sort: true
                do
                    width: 3
                    name: 'To email addresses'
                    field: 'contact_person_email_addresses'
                    sort: false
                do
                    width: 3
                    name: 'Recipient name(s)'
                    field: 'contact_person_names'
                    sort: false
