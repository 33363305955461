m = require 'mithril'
{join} = require "prelude-ls"

{should_include_excise} = require 'excise.ls'
{FilterType} = require './models.ls'
{Panel} = require '@/components/panel'
{Table} = require '@/components/table2.ls'
{a}: utils = require '../../utils.ls'
{to_specs} = require '@bitstillery/common/lib/specs'
{AmountUnit, Button, ButtonGroup, DataCard, Icon, Lot, Spinner} = require '@bitstillery/common/components'

{Amount} = require '@bitstillery/common/components'
{icon} = require '@/components/icon.ls'
{Pager} = require '@/components/pager/pager.tsx'
{Popover} = require '@/components/popover/popover.ls'
app = require('@/app')

{accountIconBySlug} = require 'accounts.ls'

export class ShowSalesHistory
    (vnode) ->
        @sales_history = vnode.attrs.sales_history
        @sales_history_margin = vnode.attrs.sales_history_margin
        @sales_history_total = vnode.attrs.sales_history_total

    view: (vnode) ->
        if vnode.attrs.count! > 0
            vnode.attrs.toolbar = m Pager, do
                page_size: vnode.attrs.limit!
                count: vnode.attrs.count!
                fetch_page: vnode.attrs.fetch_page
                display_page_count: false

        show_product = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.PRODUCT])
        show_relation = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.RELATION])
        show_specs = vnode.attrs.show_specs and (not show_product)

        m '.c-market-explore-sales-history', {key: vnode.attrs.unique_name},
            m Panel, do
                title: vnode.attrs.title
                toolbar: vnode.attrs.toolbar
                collapsible: vnode.attrs.collapsible
                collapsed: vnode.attrs.collapsed
                download_callback: if vnode.attrs.count! > 0 then vnode.attrs.download_callback else undefined
            ,
                if vnode.attrs.loading!
                    m Spinner
                else if @sales_history!length
                    sales_history = @sales_history
                    [
                        m DataCard, {
                            model: do
                                data: [
                                    {label: 'Sales history for', value: vnode.attrs.header()},
                                    {label: 'Total sales value', value: m Amount, {
                                        amount: +@sales_history_total!
                                        currency: app.$s.currencies.default
                                    }}
                                    {label: 'Total margin', value: m Amount, {
                                        amount: +@sales_history_margin!
                                        currency: app.$s.currencies.default
                                    }}
                                ]
                            type: 'dense'
                        }

                        m Table, do
                            hide_column_selector: true
                            items: sales_history
                            options:
                                search_table_style: false
                                sticky_header: false
                            columns:
                                do
                                    field: 'sales_order_reference'
                                    function: (record) ~>
                                        m '.td-group',
                                            m 'span.header',
                                                accountIconBySlug(record.account_slug, record.account_name)
                                                m m.route.Link, do
                                                    target: '_blank'
                                                    href: "/sales-orders/manage/#{record.sales_order_reference}"
                                                , record.sales_order_reference
                                            if show_specs then
                                                m 'span.details',
                                                    to_specs({
                                                        bottle_volume: record.bottle_volume,
                                                        bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                        bottle_refill_status: record.bottle_refill_status,
                                                        case_customs_status: record.case_customs_status,
                                                        case_gift_box_type: record.case_gift_box_type,
                                                        case_number_of_bottles: record.case_number_of_bottles,
                                                        case_tax_label: record.case_tax_label,
                                                    })
                                    name: 'Sales Order'
                                    width: 13
                                do
                                    field: 'sales_order_sales_order_status'
                                    function: (record) ~>
                                        m '.td-group',
                                            m '' record.sales_order_sales_order_status
                                            m '.details',
                                                utils.format-date record.sales_order_latest_status_update
                                    name: 'Status'
                                    width: 10
                                do
                                    default_visible: show_relation
                                    field: 'supplier_name'
                                    name: 'Buyer'
                                    width: 20
                                do
                                    default_visible: show_product
                                    field: 'product_name'
                                    name: 'Product'
                                do
                                    classes: ['center']
                                    field: 'sales_order_item_number_of_cases'
                                    name: 'Cases'
                                do
                                    function: (record) ~>
                                        m AmountUnit, {
                                            case_amount: +record.sales_order_item_price_per_case_excl_excise,
                                            case_amount_excise: +record.sales_order_item_price_per_case_incl_excise,
                                            case_number_of_bottles: record.case_number_of_bottles,
                                            currency: record.sales_order_was_sold_in,
                                            rate: record.sales_order_sold_against_rate
                                            should_include_excise: should_include_excise!
                                        }
                                    name: 'Sales Price'
                                do
                                    function: (record) ~>
                                        m AmountUnit, {
                                            case_amount: +record.sales_order_item_total_euro_was_bought_for / record.sales_order_item_number_of_cases,
                                            case_number_of_bottles: record.case_number_of_bottles,
                                            currency: app.$s.currencies.default,
                                            excise: +record.case_excise_nl,
                                            display_currency: app.$s.currencies.default
                                        }
                                    name: 'Purchase Price'
                                do
                                    function: (record) ~>
                                        m Lot, {
                                            lot_number: record.item_lot,
                                            warehouse_name: record.warehouse_name,
                                        }
                                    name: 'Warehouse'
                                do
                                    function: (record) ~> record.sales_manager_name,
                                    name: 'Sales Manger'
                                do
                                    classes: ['actions']
                                    function: (record) ~>
                                        m '.actions-group no-click',
                                            m ButtonGroup,
                                               m Button, {
                                                    onclick: ~> window.open("#!/stock/manage/#{record.item_reference}")
                                                    icon: 'stock',
                                                    tip: "Manage Stock item #{record.item_reference}"
                                               }
                                               m Button, {
                                                    onclick: ~> window.open("#!/purchase-orders/manage/#{record.purchase_order_reference}")
                                                    icon: 'cart',
                                                    tip: "Open related Purchase Order #{record.purchase_order_reference}"
                                               }

                    ]
                else
                    'No results'
