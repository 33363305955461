import m from 'mithril'
import {DateTime} from 'luxon'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Modal} from 'components/modal/modal.ls'
import {formatDate} from 'utils.ls'

import {CreateOffer} from './create_offer'

import {$s} from '@/app'
import {OnOffButton, SaveButton} from '@/components/buttons'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {InputDate} from '@/components/html_components'
import {UserDropDown} from '@/components/users'
import {ProductOfInterest, Reasons} from '@/factserver_api/product_of_interest_api'

export default class ProductOfInterestList extends MithrilTsxComponent<unknown> {
    // (Default) filters.
    search_text = ''
    approved_after: DateTime | null = DateTime.local().plus({day: -1}) // since yesterday
    approved_before: DateTime | null = null
    reasons = [
        Reasons.CATEGORY_FAVOURITED,
        Reasons.CATEGORY_SOLD_BEFORE,
        Reasons.PRODUCT_FAVOURITED,
        Reasons.PRODUCT_SOLD_BEFORE,
    ]

    grouping: string[] = ['relations']
    sales_manager = ''

    product_of_interest_fetcher = new PagedCollectionFetcher<ProductOfInterest>(
        'pricelist.get_products_of_interest',
        'product_names',
    )

    product_of_interest: ProductOfInterest | null = null
    search_bar_controller: SearchBarControl | null = null

    constructor() {
        super()

        // Process querystring.
        if (m.route.param('filter_sales_manager')) {
            this.sales_manager = `${$s.identity.user.artkey}`
        }
        if (m.route.param('approved_after')) {
            this.approved_after = DateTime.fromISO(m.route.param('approved_after'))
        }
        if (m.route.param('approved_before')) {
            this.approved_before = DateTime.fromISO(m.route.param('approved_before'))
        }
        if (m.route.param('product_name')) {
            this.product_of_interest_fetcher.search_terms = m.route.param('product_name').split(' ')
        }

        // Immediately apply the default filters to the collection.
        this.apply_filters()
    }

    apply_filters(): void {
        this.product_of_interest_fetcher.filters['approved_after'] = this.approved_after?.toISODate() || ''
        this.product_of_interest_fetcher.filters['approved_before'] = this.approved_before?.toISODate() || ''
        this.product_of_interest_fetcher.filters['reasons'] = this.reasons
        this.product_of_interest_fetcher.filters['sales_manager'] = this.sales_manager
        this.product_of_interest_fetcher.filters['grouping'] = this.grouping
        this.product_of_interest_fetcher.reset_and_query()
    }

    // Handle clicking on one of the grouping options.
    update_grouping(group_per: string): void {
        if (this.grouping.includes(group_per)) {
            this.grouping = this.grouping.filter((item) => item !== group_per)
        } else {
            this.grouping.push(group_per)
        }
        this.apply_filters()
    }

    // Handle clicking on one of the reason filters.
    update_reasons_filter(reason: Reasons): void {
        if (this.reasons.includes(reason)) {
            this.reasons = this.reasons.filter((item) => item !== reason)
        } else {
            this.reasons.push(reason)
        }
        this.apply_filters()
    }

    // Show an array of records in a vertical list.
    record_list(records: string[]): m.Children {
        return (
            <div>
                {records.map((record) => (
                    <span>
                        {record}
                        <br />
                    </span>
                ))}
            </div>
        )
    }

    search_for_search_text = (search_text: string): void => {
        this.search_text = search_text
        this.product_of_interest_fetcher.set_search_terms(search_text)
    }

    view(): m.Children {
        return (
            <div className="c-pricelist-poi view">
                {this.product_of_interest !== null && (
                    <Modal title={'Create offer'} onclose={() => (this.product_of_interest = null)}>
                        <CreateOffer
                            product_of_interest={this.product_of_interest}
                            done={() => (this.product_of_interest = null)}
                            cancel={() => (this.product_of_interest = null)}
                        />
                    </Modal>
                )}

                <div className="c-filter-group">
                    <SearchBar
                        placeholder={'Search for product name or relation name...'}
                        on_submit={this.search_for_search_text}
                        default_search_text={this.product_of_interest_fetcher.search_text()}
                        search_bar_controller={(controller: SearchBarControl) =>
                            (this.search_bar_controller = controller)
                        }
                    />

                    <UserDropDown
                        onchange={(user_artkey: string) => {
                            this.sales_manager = user_artkey
                            this.apply_filters()
                        }}
                        selected_user_artkey={this.sales_manager}
                    />
                </div>

                <div className="c-filter-group">
                    <div className="filter-field vertical">
                        <label>Approved after</label>
                        <InputDate
                            value={this.approved_after?.toISODate() || ''}
                            onchange={(new_value: DateTime) => {
                                this.approved_after = new_value
                                this.apply_filters()
                            }}
                        />

                    </div>
                    <div className="filter-field vertical">
                        <label>Approved before</label>
                        <InputDate
                            value={this.approved_before?.toISODate() || ''}
                            onchange={(new_value: DateTime) => {
                                this.approved_before = new_value
                                this.apply_filters()
                            }}
                        />
                    </div>

                    <div className="filter-field vertical">
                        <label>Reasons</label>

                        <div className={'btn-group'}>
                            <OnOffButton
                                is_on={() => this.reasons.includes(Reasons.CATEGORY_FAVOURITED)}
                                onclick={() => this.update_reasons_filter(Reasons.CATEGORY_FAVOURITED)}
                            >
                                Cat. favourited
                            </OnOffButton>
                            <OnOffButton
                                is_on={() => this.reasons.includes(Reasons.CATEGORY_SOLD_BEFORE)}
                                onclick={() => this.update_reasons_filter(Reasons.CATEGORY_SOLD_BEFORE)}
                            >
                                Cat. sold
                            </OnOffButton>
                            <OnOffButton
                                is_on={() => this.reasons.includes(Reasons.PRODUCT_FAVOURITED)}
                                onclick={() => this.update_reasons_filter(Reasons.PRODUCT_FAVOURITED)}
                            >
                                Product favourited
                            </OnOffButton>
                            <OnOffButton
                                is_on={() => this.reasons.includes(Reasons.PRODUCT_SOLD_BEFORE)}
                                onclick={() => this.update_reasons_filter(Reasons.PRODUCT_SOLD_BEFORE)}
                            >
                                Product sold
                            </OnOffButton>
                        </div>
                    </div>

                    <div className="filter-field vertical">
                        <label>Group together</label>
                        <div className="btn-group">
                            <OnOffButton
                                is_on={() => this.grouping.includes('products')}
                                onclick={() => this.update_grouping('products')}
                            >
                                Products
                            </OnOffButton>
                            <OnOffButton
                                is_on={() => this.grouping.includes('relations')}
                                onclick={() => this.update_grouping('relations')}
                            >
                                Relations
                            </OnOffButton>
                            <OnOffButton
                                is_on={() => this.grouping.includes('reasons')}
                                onclick={() => this.update_grouping('reasons')}
                            >
                                Reasons
                            </OnOffButton>
                        </div>
                    </div>

                </div>

                <CollectionTable<ProductOfInterest, void> collection_fetcher={this.product_of_interest_fetcher}>
                    <CollectionTableColumn<ProductOfInterest>
                        header_title={() => 'Product(s)'}
                        sort_name={'product_names'}
                        td_class_name={'col-lg-4'}
                        data_field={(row: ProductOfInterest) => this.record_list(row.products)}
                    />
                    <CollectionTableColumn<ProductOfInterest>
                        header_title={() => 'Approved on'}
                        sort_name={'moment_of_approval'}
                        td_class_name={'col-lg-1'}
                        data_field={(row: ProductOfInterest) => {
                            return this.record_list(row.moment_of_approval.map((date: string) => formatDate(date)))
                        }}
                    />
                    <CollectionTableColumn<ProductOfInterest>
                        header_title={() => 'Relation(s)'}
                        sort_name={'relation_names'}
                        td_class_name={'col-lg-5'}
                        data_field={(row: ProductOfInterest) => row.relation_names}
                    />
                    <CollectionTableColumn<ProductOfInterest>
                        header_title={() => 'Reason(s)'}
                        sort_name={'reasons'}
                        td_class_name={'col-lg-1'}
                        data_field={(row: ProductOfInterest) => this.record_list(row.reasons)}
                    />
                    <CollectionTableColumn<ProductOfInterest>
                        header_title={() => 'Actions'}
                        td_class_name={'col-lg-1'}
                        data_field={(row: ProductOfInterest) => {
                            return <SaveButton title="Create offer" onclick={() => (this.product_of_interest = row)} />
                        }}
                    />
                </CollectionTable>
            </div>
        )
    }
}
