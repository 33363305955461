import m from 'mithril'
import {Country} from '@bitstillery/common/components'
import {accountIcon} from 'accounts.ls'
import {deref} from '@bitstillery/common/utils.ls'
import {format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/ts_utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {Amount} from '@bitstillery/common/components'

import item_link from '../components/item_link'

import {$s} from '@/app'
import {InlineEditableText} from '@/components/html_components'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic'

interface PurchaseOrderDetailsAttrs {
    purchase_order: () => any
}

export class PurchaseOrderDetails extends MithrilTsxComponent<PurchaseOrderDetailsAttrs> {
    purchase_api = new PurchaseApi()

    update_remark(vnode: m.Vnode<PurchaseOrderDetailsAttrs>) {
        this.purchase_api.update_remark(vnode.attrs.purchase_order().artkey(), vnode.attrs.purchase_order().remark()).subscribe({
            next: () => {
                m.redraw()
            },
            error: () => {
            },
        })
    }

    view(vnode: m.Vnode<PurchaseOrderDetailsAttrs>): m.Children {
        const purchase_order = deref(vnode.attrs.purchase_order)
        const planned_loading_time = purchase_order.planned_unloading_date()
            ? DateTime.fromISO(purchase_order.planned_unloading_date()).toISOTime()
            : null
        const is_target_loendersloot = purchase_order.target_warehouse().artkey() === LOENDERSLOOT_WAREHOUSE_ARTKEY
        return (
            <div class="details">
                <div class="row">
                    <div class="col-sm-6">
                        <dl class="dl-horizontal">
                            <dt>Reference</dt>
                            <dd>{purchase_order.reference()}</dd>
                            <dt>Supplier reference</dt>
                            <dd>{purchase_order.supplier_reference()}</dd>
                            <dt>For account</dt>
                            <dd>
                                <span class="mr-05">{accountIcon(purchase_order.account())}</span>
                                {purchase_order.account().name()}
                            </dd>
                            <dt>Created on</dt>
                            <dd>{format_iso_to_date_time(purchase_order.created_on())}</dd>
                            <dt>Created by</dt>
                            <dd>{purchase_order.was_handled_by().profile().name()}</dd>
                            <dt>Purchase manager</dt>
                            <dd>{purchase_order.supplier().purchase_manager().profile().name()}</dd>
                            <dt>Status</dt>
                            {purchase_order.status() === PurchaseOrderStatus.IN_STOCK ? (
                                <dd>
                                    <a target="_blank" href={item_link.from_purchase_order(purchase_order)}>
                                        {purchase_order.status()}
                                    </a>
                                </dd>
                            ) : (
                                <dd>{purchase_order.status()}</dd>
                            )}
                            <dt>Confirmation date</dt>
                            <dd>{format_iso_to_date(purchase_order.confirmation_date())}</dd>
                            <dt>Intake date</dt>
                            <dd>{format_iso_to_date(purchase_order.intake_date())}</dd>
                            <dt>Sent to FIS date</dt>
                            <dd>{format_iso_to_date(purchase_order.was_sent_to_financial_information_system_on())}</dd>
                            <dt>Communicated</dt>
                            <dd>
                                {purchase_order.is_communicated_on()
                                    ? `Yes, on ${format_iso_to_date(purchase_order.is_communicated_on())}`
                                    : 'Not yet'}
                            </dd>
                            <dt>Relation</dt>
                            <dd>
                                {m(
                                    m.route.Link,
                                    {
                                        target: '_blank',
                                        href: `/crm/relations/${purchase_order.supplier().artkey()}/edit`,
                                    },
                                    purchase_order.supplier().name(),
                                )}
                            </dd>
                            <dt>Currency</dt>
                            <dd>{purchase_order.was_bought_in()}</dd>
                            {purchase_order.was_bought_in() !== $s.currencies.default && (
                                <span>
                                    <dt>Exchange rate</dt>
                                    <dd>{purchase_order.bought_against_rate().toFixed(4)}</dd>
                                </span>
                            )}
                            <dt>Remarks</dt>
                            <InlineEditableText
                                value={vnode.attrs.purchase_order().remark()}
                                oninput={(value: string) => vnode.attrs.purchase_order().remark(value)}
                                onsave={() => this.update_remark(vnode)}
                                oncancel={(original_text: string) => vnode.attrs.purchase_order().remark(original_text)}
                            />
                        </dl>
                    </div>
                    <div class="col-sm-6">
                        <dl class="dl-horizontal">
                            <dt>Insurance</dt>
                            <dd>{purchase_order.insurance_type().name()}</dd>
                            <dt>Incoterm</dt>
                            <dd>{`${purchase_order.incoterm()} - ${purchase_order.incoterm_location()}`}</dd>
                            <dt>Warehouse</dt>
                            <dd>{purchase_order.target_warehouse()?.name()}</dd>
                            <dt>Warehouse reference</dt>
                            <dd>{purchase_order.warehouse_reference()}</dd>

                            {[PurchaseOrderStatus.IN_STOCK, PurchaseOrderStatus.CONFIRMED].includes(
                                purchase_order.status(),
                            ) ? (
                                    <span>
                                        <dt>Warehouse base costs</dt>
                                        <dd>
                                            <Amount
                                                amount={purchase_order.warehouse_base_costs()}
                                                currency={purchase_order.was_bought_in()}
                                            />
                                        </dd>
                                        <dt>Warehouse costs / case</dt>
                                        <dd>
                                            <Amount
                                                amount={purchase_order.warehouse_costs_per_case()}
                                                currency={purchase_order.was_bought_in()}
                                            />
                                        </dd>
                                        <dt>Order transport costs</dt>
                                        <dd>
                                            <Amount
                                                amount={purchase_order.transport_costs()}
                                                currency={purchase_order.was_bought_in()}
                                            />
                                        </dd>
                                        {purchase_order.supplier().country_code() === 'NL' ? (
                                            <span>
                                                <dt>Waste fund costs</dt>
                                                <dd>
                                                    <Amount
                                                        amount={purchase_order.waste_fund_costs()}
                                                        currency={purchase_order.was_bought_in()}
                                                    />
                                                </dd>
                                            </span>
                                        ) : null}
                                    </span>
                                ) : null}

                            <dt>Number of items</dt>
                            <dd>{purchase_order.purchase_order_items()?.length}</dd>
                            <dt>Number of cases</dt>
                            <dd>{purchase_order.number_of_cases()}</dd>
                            <dt>Total value</dt>
                            <dd>
                                <Amount
                                    amount={purchase_order.was_bought_for()}
                                    currency={purchase_order.was_bought_in()}
                                    rate={purchase_order.bought_against_rate()}
                                />
                            </dd>
                            <dt>Number of euro pallets</dt>
                            <dd>{purchase_order.number_of_euro_pallets()}</dd>
                            <dt>Number of block pallets</dt>
                            <dd>{purchase_order.number_of_block_pallets()}</dd>
                            {is_target_loendersloot && <dt>Sent to loendersloot on</dt>}
                            {is_target_loendersloot && <dd>{format_iso_to_date_time(purchase_order.sent_to_loendersloot_on())}</dd>}
                            <dt>Expected delivery date</dt>
                            <dd>{format_iso_to_date(purchase_order.expected_delivery_date())}</dd>
                            {is_target_loendersloot && <dt>Planned unloading date</dt>}
                            {is_target_loendersloot && planned_loading_time && planned_loading_time.startsWith('00:00:00') && (
                                <dd>{format_iso_to_date(purchase_order.planned_unloading_date())}</dd>
                            )}
                            {is_target_loendersloot && planned_loading_time && !planned_loading_time.startsWith('00:00:00') && (
                                <dd>{format_iso_to_date_time(purchase_order.planned_unloading_date())}</dd>
                            )}
                            {is_target_loendersloot && !planned_loading_time && <dd>-</dd>}
                            {is_target_loendersloot && <dt>Warehouse status</dt>}
                            {is_target_loendersloot && <dd>
                                {titleize(purchase_order.warehouse_status())} -{' '}
                                {format_iso_to_date_time(purchase_order.last_warehouse_message())}
                            </dd>}
                            <dt>Loading info</dt>
                            <dd>
                                {purchase_order.delivery().weight_in_kilos() && (
                                    <div>{`${purchase_order.delivery().weight_in_kilos()} kg`}</div>
                                )}
                                {purchase_order.delivery().number_of_euro_pallets() && (
                                    <div>{`${purchase_order.delivery().number_of_euro_pallets()} euro pallet(s)`}</div>
                                )}
                                {purchase_order.delivery().number_of_block_pallets() && (
                                    <div>{`${purchase_order
                                        .delivery()
                                        .number_of_block_pallets()} block pallet(s)`}</div>
                                )}
                                {!purchase_order.delivery().number_of_block_pallets() &&
                                    purchase_order.number_of_block_pallets() && (
                                    <div>{`${purchase_order.number_of_block_pallets()} block pallet(s)`}</div>
                                )}
                            </dd>
                        </dl>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="dl dl-horizontal">
                            {purchase_order.supplier().hide_from_pricelist_for_countries().length > 0 && (
                                <span>
                                    <div className="dt">Relation is blocked in</div>
                                    <div className="dd">
                                        {purchase_order
                                            .supplier()
                                            .hide_from_pricelist_for_countries()
                                            .map((country) => (
                                                <div><Country country_code={country.country_code} type={'flag_with_country'}/></div>
                                            ))}
                                    </div>
                                </span>
                            )}
                            {purchase_order.supplier().hide_from_pricelist_for_suppliers().length > 0 && (
                                <span>
                                    <div className="dt">Relation is blocked in</div>
                                    <div className="dd">
                                        {purchase_order
                                            .supplier()
                                            .hide_from_pricelist_for_suppliers()
                                            .map((supplier: any) => (
                                                <div><Country country_code={supplier.name} type={'flag_with_country'}/></div>
                                            ))}
                                    </div>
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {purchase_order.supplier().memo() && (
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert alert-info">
                                <span class="glyphicon glyphicon-info-sign" />
                                <span class="sr-only">Memo:</span> {purchase_order.supplier().memo()}
                            </div>
                        </div>
                    </div>
                )}
                {purchase_order.status() === PurchaseOrderStatus.SAVED &&
                    purchase_order.requires_import_statement() &&
                    purchase_order.supplier().has_valid_signed_trademark_declaration() && (
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert alert-info">
                                <span class="glyphicon glyphicon-info-sign" /> This supplier has a valid Trademark
                                    Declaration (valid until{' '}
                                {format_iso_to_date(purchase_order.supplier().signed_trademark_declaration_valid_until())})
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
