m = require 'mithril'
{AdditionalType, SalesOrderAdditional} = require '@/sales_orders/models.ls'
api = require 'api.ls'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{a}: utils = require 'utils.ls'
{head} = require 'prelude-ls'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

export class AddAdditional
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @loading = window.prop false
        @additional = window.prop new SalesOrderAdditional
        @additional!total_value = window.prop ''
        @sales_order_changed = vnode.attrs.sales_order_changed
        @init!
        @active_vouchers = window.prop []
        @active_voucher_codes = window.prop []
        @unit = window.prop ''
        @ppu = window.prop 0

    init: ~>
        @additional!artkey ''
        @additional!description ''
        @additional!sales_order_additional_type ''
        @additional!price_per_unit ''
        @additional!quantity ''
        @additional!sales_order = @sales_order
        @additional!total_value @additional!quantity! * @additional!price_per_unit!
        @additional!value_per_quantity 0

    get_relation_vouchers: ~>
        api.call-and-then 'voucher.get_relation_active_vouchers', {relation_artkey: @sales_order!supplier!artkey!, only_unused: true}, do
            success: (resp) ~>
                @active_vouchers resp.result
                voucher_codes = @active_vouchers!map((voucher) -> voucher.code)
                @active_voucher_codes voucher_codes

    add_to_order: (e) ~>
        e.preventDefault!
        @loading true

        data = do
            sales_order_artkey: @additional!sales_order!artkey!
            sales_order_additional_type: @additional!sales_order_additional_type!
            description: @additional!description!
            quantity: @additional!quantity!
            value_type: @additional!value_type!
            value_per_quantity: @additional!value_per_quantity!
        api.call 'sales.additionals.add_additional_to_sales_order' data, @handle_added_additional

    handle_added_additional: (resp) ~>
        @loading false

        if resp.success
            additional_obj = new SalesOrderAdditional resp.result
            app.$m.common.observable.broadcast 'sales_order_updated_'+@sales_order!artkey!, 'Go fetch'
            @init!
            @get_relation_vouchers()
            app.notifier.notify 'The additional was added successfully.', 'success'
            if @sales_order_changed
                @sales_order_changed!
        else
            if resp.message
                app.notifier.notify resp.message, 'danger'
            else
                app.$m.common.generic_error_handler!

    is_selecting_voucher: ->
        return @additional!sales_order_additional_type! == AdditionalType.VOUCHER

    onchange_voucher: (voucher_code) ~>
        selected_voucher = head @active_vouchers!filter (.code == voucher_code)
        if not selected_voucher
            @additional!description ""
            @additional!quantity 0
            @additional!value_type 'FIXED'
            @additional!value_per_quantity 0
        else
            @additional!description voucher_code
            @additional!quantity 1
            @additional!value_type selected_voucher.value_type
            @additional!value_per_quantity -selected_voucher.value


    view: -> m '.mwrap', a do
        m 'button.btn.btn-default.mb-2',
            {'data-target': '#add_additional', 'data-toggle': 'collapse', onclick: ~> @get_relation_vouchers()} \
            (m 'span.glyphicon.glyphicon-plus'), ' Add additional item to order'
        m '.collapse#add_additional',
            m 'form.flex-form' {onsubmit: @add_to_order},
                m '.fieldset.largest',
                    m '.field-group',
                        inputs.select(@additional!sales_order_additional_type, AdditionalType.CHOICES, {
                            label: 'Type',
                            required: true
                        })
                        if @is_selecting_voucher!
                            if @active_voucher_codes!length > 0
                                inputs.select(@additional!description, @active_voucher_codes!, {
                                    label: 'Voucher',
                                    required: true,
                                    empty_option: true,
                                    onchange: @onchange_voucher
                                })
                            else
                                m '.field',
                                    m 'label' 'No active vouchers found'
                        else
                            inputs.text(@additional!description, {
                                label: 'Description',
                                required: true
                            })
                        if !@is_selecting_voucher!
                            [
                                inputs.number(@additional!quantity, {
                                    label: 'Quantity',
                                    required: true,
                                    disabled: @is_selecting_voucher!,
                                    min: 1
                                })

                                inputs.select(@additional!value_type, ['FIXED', 'PERCENTAGE'], {
                                    label: 'Value type',
                                    required: true,
                                    empty_option: false
                                })

                                if @additional!value_type! == 'PERCENTAGE'
                                    inputs.number(@additional!value_per_quantity, {
                                        label: 'Percentage'
                                        required: true,
                                        max: 100,
                                        min: -100,
                                        disabled: @is_selecting_voucher!
                                    })
                                else
                                    m MoneyInput,
                                        label: if @additional!value_type! == 'FIXED' then 'Price per unit' else 'Value per quantity'
                                        value: @additional!value_per_quantity!
                                        on_value: (price) ~> @additional!value_per_quantity price
                                        currency: @sales_order!was_sold_in!
                                        required: true
                                        disabled: @is_selecting_voucher!
                                        minimum: -999999
                            ]

                        m '.field',
                            m 'label' m.trust '&nbsp;'
                            m 'button.btn.btn-success' \
                                if @loading! then m Spinner else 'Add additional item'
