m = require 'mithril'
{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'


module.exports = class PaymentTermList
    is_match = (payment_term, term) ~>
        return or-list [
            payment_term.description.toLowerCase!indexOf(term) > -1,
        ]

    view: ->
        m GenericList, do
            readable_entity_name: 'payment term'
            base_url: '/data/paymentterms/'
            list_api_call: 'paymentterms.get_all'
            is_match: @is_match
            columns:
                {'key': 'description', 'label': 'Description'}
                {'key': 'exact_payment_term_code', 'label': 'Exact code'}
            requery_subscribe_key: 'paymentterms_updated'
