m = require 'mithril'
utils = require '../utils.ls'
{id, sum, odd, map, each, compact} = require 'prelude-ls'
fixed-table-header = require '@/components/table/fixed-header.ls'
{Spinner} = require '@bitstillery/common/components'
{to-list} = utils

table = {}
module.exports = table

# Search the code for 'table.table(' or 'table.collection_table(' to see how to use the table.
#
# Sorting only works when you use a collection. You also have to implement the sorting server
# side to make it work.


sum_column_widths = (columns) ->
    get_width = (col) -> if col then col.width else 0
    columns |> map get_width |> sum


autoscaled_columns = (columns, options) ->
    total_width = sum_column_widths columns
    if total_width != 100 and options.autoscale
        factor = 100 / total_width
        columns |> map (col) ->
            if col then
                col with width: col.width * factor
    else
        columns


table.table = (items, columns, options) ->
    if not options then options = {}

    wrapper = \
        if options.sticky_header then (
            if options.with_buttons
            then fixed-table-header.with-buttons
            else fixed-table-header.without-buttons)
        else id

    classes = ['table']
    if not options.search_table_style? or options.search_table_style
        classes.push('search-table')
        classes.push('clickable')
    else
        classes.push('table-hover')

    return m '.table-container',
        wrapper m 'table', {class: classes.join(' ')},
            table.colgroup columns, options
            table.header columns, options
            table.body items, columns, options


table.collection_table = (collection, columns, options) ->
    if not options then options = {}

    wrapper = \
        if options.with_buttons
        then fixed-table-header.with-buttons
        else fixed-table-header.without-buttons

    return m '.table-container',
        wrapper m 'table.table.search-table.clickable',
            table.colgroup columns, options
            table.collection_header collection, columns, options
            table.body collection.search_result, columns, options

        table.show_more collection, options


table.colgroup = (columns, options) ->
    return m 'colgroup', [
        for column in (autoscaled_columns ...) when column
            if column.width then
                m 'col' {style: {width: column.width.toFixed(1) + '%'}}
            else
                m 'col'
    ]

table.header = (columns, options) ->
    if not options.autoscale
        total_widths = sum_column_widths columns
        if total_widths != 100
            console.debug "Sum of table columns widths != 100: #{total_widths}"

    return m 'thead.thead-default', m 'tr', [
        for column in columns when column
            props = {}
            if column.classes?
                props['class'] = column.classes
            m 'th', props, column.name
    ]

table.collection_header = (collection, columns, options) ->
    if not options.autoscale
        total_widths = sum_column_widths columns
        if total_widths != 100
            console.debug "Sum of table columns widths != 100: #{total_widths}"

    return m 'thead.thead-default', m 'tr', [
        for column in columns when column
            props = {}
            if column.classes?
                props['class'] = column.classes
            if column.sort
                props['onclick'] = collection.sort.bind(@, column.field)
                m 'th', props, column.name, ' ', collection.sort_icon(column.field)
            else
                m 'th', props, column.name
    ]

table.body = (items, columns, options) ->
    if not options then options = {}

    return items!map((item, index) ->
        attrs = {}
        if options.onclick?
            attrs['onclick'] = options.onclick(item)
        if options.row_class?
            attrs['class'] = options.row_class(item)
        m 'tbody.table-row' {key: item.artkey} compact [
            m('tr', attrs, for column in columns when column
                props = {}
                classes = column.classes
                if column.ellipsis
                    classes = ['ellipsis', classes].join(' ')
                if classes
                    props['class'] = classes
                m 'td', props,
                    if column.ellipsis then
                        m 'span',
                            if column.function then column.function(item)
                            else utils.get_descendant_prop(item, column.field)
                    else
                        if column.function then column.function(item)
                        else utils.get_descendant_prop(item, column.field)
            )

            if options.details
                let details = options.details item
                    if details then
                        (to-list details) |> map (row) ->
                            m 'tr' m 'td' {colspan: '100%'} row
        ]
    )


table.show_more = (collection, options) ->
    m '.table-show-more' [
        if not collection.search_result!length and not collection.loading!
            m 'p' 'No results found. Maybe the filters are too strict?'
        else if collection.loading!
            m Spinner, {className: 'table-spinner'}
        else if collection.can_show_more_items!
            m 'button.btn.btn-info' {onclick: collection.show_more} 'Show more results'
        else
            m ''
    ]
