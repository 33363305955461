import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from '@/factserver_api/api'

export interface GetLoenderslootMessagesResponse {
    created_on: string
    artkey: number
    message_type: string
    status: string
    raw_xml: string
    number_of_retries: number
    last_reason_of_failure: number
    next_retry_moment: string
    is_showing_details: boolean | null
    celery_task_id: string | null
}

export const loendersloot_message_status = {
    RECEIVED: 'Received',
    PROCESSED: 'Processed',
    FAILED: 'Failed',
}

export class CommunicationApi {
    api = new Api()

    delete_loendersloot_message(message_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('communication.delete_loendersloot_message', {artkey: message_artkey})
    }

    resolve_loendersloot_message(message_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('communication.resolve_loendersloot_message', {artkey: message_artkey})
    }

}
