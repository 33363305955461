import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import Sortable from 'sortablejs'
import {product_photo_image_location} from '@bitstillery/common/ts_utils'
import Viewer from 'viewerjs'
import {generate_unique_id} from '@bitstillery/common/ts_utils'
import {config} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {DefaultButton} from '../buttons'
import {CheckBox} from '../html_components'

import {ProductPhotoHelper} from './product_photo_helper'

import {GetProductPhotoResponse, RotateDirection} from '@/factserver_api/fact2server_api'

interface ProductPhotoHorizontalViewItemAttrs {
    product_photo: GetProductPhotoResponse
    on_product_photos_changed: () => unknown
}

class ProductPhotoHorizontalViewItem extends MithrilTsxComponent<ProductPhotoHorizontalViewItemAttrs> {
    image_location: string
    product_photo_helper: ProductPhotoHelper = new ProductPhotoHelper()

    constructor(vnode: m.Vnode<ProductPhotoHorizontalViewItemAttrs>) {
        super()
        this.image_location = product_photo_image_location(config.product_photo_host, {s3_location: vnode.attrs.product_photo.s3_location})
    }

    get is_loading() {
        return this.product_photo_helper.is_loading
    }

    view(vnode: m.Vnode<ProductPhotoHorizontalViewItemAttrs>): m.Children {
        const product_photo = vnode.attrs.product_photo
        return (
            // data-id is required for sortable.
            <div data-id={product_photo.artkey}>
                <img
                    src={this.image_location}
                    className={'horizontal-view-product-photo'}
                    alt={`A photo for ${product_photo.file_name}`}
                />
                {this.is_loading && <Spinner/>}
                {!this.is_loading && (
                    <div>
                        <CheckBox
                            checked={product_photo.is_internal}
                            id={`flip_is_internal-${product_photo.artkey}`}
                            onchange={() => this.product_photo_helper.flip_is_internal(product_photo)}
                        >
                            Internal
                        </CheckBox>
                        <span>{'  '}</span>
                        <DefaultButton icon_class={'fas fa-download'}
                            onclick={() => this.product_photo_helper.download_photo(product_photo)}/>
                        <DefaultButton
                            onclick={() => this.product_photo_helper.rotate(product_photo, RotateDirection.Right, vnode.attrs.on_product_photos_changed)}
                            icon_class={'fas fa-undo'}/>
                        <DefaultButton
                            onclick={() => this.product_photo_helper.rotate(product_photo, RotateDirection.Left, vnode.attrs.on_product_photos_changed)}
                            icon_class={'fas fa-undo fa-flip-horizontal'}
                        />
                        <DefaultButton
                            additional_class={'btn-danger'}
                            onclick={() => this.product_photo_helper.delete(product_photo, vnode.attrs.on_product_photos_changed)}
                            icon_class={'glyphicon glyphicon-trash'}
                        />
                    </div>
                )}
            </div>
        )
    }
}

interface ProductPhotoHorizontalViewAttrs {
    product_photos: GetProductPhotoResponse[]
    on_product_photos_changed: () => unknown
    download_all_photos: (include_internal: boolean) => unknown
}

export class ProductPhotoHorizontalView extends MithrilTsxComponent<ProductPhotoHorizontalViewAttrs> {
    sortable: Sortable | null = null
    is_loading = false
    gallery: Viewer | null = null
    gallery_uuid: string
    photo_list_uuid: string
    product_photo_helper: ProductPhotoHelper = new ProductPhotoHelper()

    constructor() {
        super()
        this.gallery_uuid = generate_unique_id()
        this.photo_list_uuid = generate_unique_id()
    }

    oncreate() {
        const photo_list_element = document.getElementById(this.photo_list_uuid)
        if (photo_list_element === null) {
            // eslint-disable-next-line no-console
            console.error('Sortable element not found!')
        } else {
            this.sortable = Sortable.create(photo_list_element, {
                animation: 150,
                onEnd: () => this.rerank(),
            })
        }

        const gallery_element = document.getElementById(this.gallery_uuid)
        if (gallery_element === null) {
            // eslint-disable-next-line no-console
            console.error('Gallery element not found for viewer.js!')
        } else {
            // Initialise the sortable list
            this.gallery = new Viewer(gallery_element, {
                toolbar: {
                    zoomIn: {
                        show: 1,
                        size: 'large',
                    },
                    zoomOut: {
                        show: 1,
                        size: 'large',
                    },
                    oneToOne: {
                        show: 1,
                        size: 'large',
                    },
                    reset: {
                        show: 1,
                        size: 'large',
                    },
                    prev: {
                        show: 1,
                        size: 'large',
                    },
                    play: false,
                    next: {
                        show: 1,
                        size: 'large',
                    },
                    rotateLeft: false,
                    rotateRight: false,
                    flipHorizontal: false,
                    flipVertical: false,
                },
                url(image) {
                    return image.src.replace('.thumb', '')
                },
            })
        }
    }

    rerank() {
        if (!this.sortable) {
            return
        }
        const artkeys = this.sortable.toArray().map((artkey) => +artkey)
        this.product_photo_helper.sort(artkeys)
    }

    view(vnode: m.Vnode<ProductPhotoHorizontalViewAttrs>): m.Children {
        return (
            <div>
                <DefaultButton
                    icon_class={'glyphicon glyphicon-download'}
                    onclick={() => this.gallery?.show()}
                    title={' Show photos in gallery'}
                />
                <DefaultButton
                    icon_class={'glyphicon glyphicon-download'}
                    onclick={() => vnode.attrs.download_all_photos(false)}
                    title={' Download'}
                />
                <DefaultButton
                    icon_class={'glyphicon glyphicon-download'}
                    onclick={() => vnode.attrs.download_all_photos(true)}
                    title={' With internal photos'}
                />
                <div id={this.gallery_uuid} style={'padding: 10px 0px; width: 97vw; overflow-x: auto'}>
                    <div id={this.photo_list_uuid} style={'display: flex;'}>
                        {vnode.attrs.product_photos.map((product_photo) => (
                            <ProductPhotoHorizontalViewItem product_photo={product_photo} on_product_photos_changed={vnode.attrs.on_product_photos_changed}/>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
