api = require '../../api.ls'
m = require 'mithril'
{Supplier} = require '@/models/suppliers'
app = require('@/app')

module. exports = class SupplierDeactivate
    ->
        @supplier = window.prop new Supplier
        @can_deactivate = window.prop false
        @supplier_price_lists_count = window.prop false
        @purchase_orders_count = window.prop false
        @sales_orders_count = window.prop false

        supplier_artkey = m.route.param 'artkey'
        if supplier_artkey
            @query_supplier supplier_artkey

    query_supplier: (artkey) ~>
        api.call 'suppliers.can_deactivate_supplier' {artkey: artkey}, @set_supplier

    set_supplier: (resp) ~>
        if resp.success
            @can_deactivate resp.result.can_deactivate
            @supplier_price_lists_count resp.result.supplier_price_lists_count
            @purchase_orders_count resp.result.purchase_orders_count
            @sales_orders_count resp.result.sales_order_count
            @supplier new Supplier resp.result.supplier

    deactivate_supplier: ~>
        api.call 'suppliers.deactivate_supplier' {artkey: @supplier!artkey!}, @handle_deactivate_supplier

    handle_deactivate_supplier: (resp) ~>
        if resp.success
            app.$m.common.observable.broadcast 'suppliers_updated', ''
            app.notifier.notify "Successfully deactivated relation #{@supplier!name!}.", 'success'
            m.route.set '/crm/relations'
        else
            app.notifier.notify "Deleting of relation #{@supplier!name!} failed.", 'danger'

    view: -> m '.deactivate-supplier',
        m '.row#button-bar' m '.col-lg-12.btn-toolbar',
            m 'button.btn.btn-link' {type: "button", \
              onclick: ~> m.route.set "/crm/relations/#{@supplier!artkey!}/edit"},
              (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to relation '

        m '.row' m '.col-xs-12' m 'h2' 'Deactivate relation'

        if @can_deactivate!
            m '.row' m '.col-md-6 .col-xs-12' m '.alert.alert-info' \
                'Would you like to deactivate the following relation?'
        else
            m '.row' m '.col-md-6 .col-xs-12' m '.alert.alert-warning',
                'The following relation ', (m 'strong' 'cannot be deactivated'), ' because it has:'
                m 'ul',
                    if @supplier_price_lists_count! > 0
                        m 'li' "#{@supplier_price_lists_count!} price lists"
                    if @purchase_orders_count! > 0
                        m 'li' "#{@purchase_orders_count!} purchase orders"
                    if @sales_orders_count! > 0
                        m 'li' "#{@sales_orders_count!} sales orders"

        m '.row' m '.col-md-6 .col-xs-12' m '.panel.panel-default',
            m '.panel-heading' m 'h4' @supplier!name!
            m '.panel-body' m 'dl.dl-horizontal',
                m 'dt', 'Name'
                m 'dd', @supplier!name!
                m 'dt', 'Email'
                m 'dd', @supplier!emailaddress!
                m 'dt', 'URL'
                m 'dd', @supplier!url!
                m 'dt', 'Address'
                m 'dd' @supplier!street_address!
                m 'dt', 'Zip code'
                m 'dd' @supplier!zip_code!
                m 'dt', 'City'
                m 'dd' @supplier!city!
                m 'dt', 'Country'
                m 'dd' @supplier!country_code!
                m 'dt', 'Company Type'
                m 'dd', @supplier!company_type!
                m 'dt', 'Client Status'
                m 'dd', @supplier!client_status!
                m 'dt', 'Purchase manager'
                m 'dd', @supplier!purchase_manager!profile!name!
                m 'dt', 'Sales manager'
                m 'dd', @supplier!sales_manager!profile!name!
                m 'dt', 'Relation nr'
                m 'dd', @supplier!relation_nr!

        m '.row.mb-2',
            m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-default' \
                {onclick: ~> m.route.set "/crm/relations/#{@supplier!artkey!}/edit"} 'Cancel'
            if @can_deactivate!
                m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-success' \
                    {onclick: @deactivate_supplier, id: "deactivate"} 'Deactivate'
