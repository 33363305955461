export BACKSPACE = 8
export TAB = 9
export ENTER = 13
export ESCAPE = 27
export SPACE = 32
export PAGE_UP = 33
export PAGE_DOWN = 34
export ARROW_LEFT = 37
export ARROW_UP = 38
export ARROW_RIGHT = 39
export ARROW_DOWN = 40
export DELETE = 46
