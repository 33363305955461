import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Support, VersionChecker} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'
import {BarMainDiscover} from '@/components/bar_main'
import {BarCategoriesDiscover} from '@/components/bar_categories'
import {PanelMenuDiscover} from '@/components/panel_menu/panel_menu'

export class LayoutDiscover extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        return [
            <PanelMenuDiscover/>,
            <div className={classes('l-main-col', {
                // Affects the application height
                'header-collapsed': $s.page.header.collapsed,
            })}>
                <BarMainDiscover className={{
                    // Only for the new msp build; as of 01/05/24
                    'legacy-account': process.env.MSI_THEME === 'msp' && ['a2bc', 'msi'].includes($s.session.account.slug),
                }}/>
                <BarCategoriesDiscover/>
                {vnode.children}
            </div>,
            <Support/>,
            <VersionChecker title="Portal Update" reloadButton={$t('system.reload_portal')}>
                <p>
                    {`A new version of the ${process.env.MSI_TITLE} portal has just been published!`}<br/>
                    {'Please reload the portal in order to update to the latest version. '}<br/>
                    {'Don\'t worry; your cart content is securely stored and will still be there. '}
                </p>
            </VersionChecker>,
        ]
    }
}
