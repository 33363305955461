$ = require 'jquery'
m = require 'mithril'
{map, find, reject, filter, id, each, fold, empty} = require 'prelude-ls'
require! 'utils.ls': {
    is-list, stop-propagation, indexed-map, update, contains, clamp,
    if-null, if-empty, asap, copy, maybe-map,
}
{debounce} = require '@bitstillery/common/ts_utils'
{is-function, deref} = require '@bitstillery/common/utils.ls'
{classes} = require '@bitstillery/common/lib/utils'
{prevent-default} = require 'lib/html-utils.ls'
{icon} = require '@/components/icon.ls'
kc = require 'lib/keycodes.ls'


export class MultiInput
    (vnode) ->
        {@value, @choices, @item-show, @placeholder} = vnode.attrs

        @item-key = vnode.attrs.item-key or (.artkey) >> deref
        @item-decorate = vnode.attrs.item-decorate or (-> {})

        @input = null
        @input-focused = false
        @selected = null
        @suggestions = []
        @highlighted-suggestion = null

        @suggestions-on-empty-input = vnode.attrs.suggestions-on-empty-input
        @allow-free-text = vnode.attrs.allow-free-text

        @free-text-lookup = vnode.attrs.free-text-lookup or id
        @free-text-key = vnode.attrs.free-text-key or id

        @input-width = vnode.attrs.input-width || '19rem'

        @debounce-update-suggestions = debounce 100, @update-suggestions

    lookup: (key) ~>
        @choices
        |> deref
        |> find @item-key >> (== key)
        |> if-null ~>
            if @allow-free-text then
                @free-text-lookup key

    find-suggestions: (input) ~>
        @choices
        |> deref
        |> reject @item-key >> (in @value!)
        |> filter @item-show >> contains-ignore-case input

    update-suggestions: ~>
        # This function is called debounced, e.g. delayed.
        # It could happen that we missed the boat and the user has already
        # pressed enter or escape.
        if not @input then return

        @suggestions = @find-suggestions @input

        if @suggestions.length == 0 then
            @highlighted-suggestion = null
        else if @highlighted-suggestion == null then
            if not @allow-free-text then
                @highlighted-suggestion = 0
        else if @highlighted-suggestion >= @suggestions.length then
            @highlighted-suggestion = @suggestions.length - 1

        m.redraw!

    remove: (key) ->
        index = @index-of key
        assert index >= 0
        update @value, reject (== key)

        if index < @value-count! then
            @selected = @key-at index
        else if @value-count! > 0 then
            @selected = @key-at index - 1
        else
            @show-input!

    oninput: (value) ~>
        @input = value
        @debounce-update-suggestions!

    onpaste: (e) ~>
        data = e.clipboard-data.get-data 'text'
        if not @allow-free-text then
            @oninput data
        else
            data.split /[\n\t]+/        # Split on tabs and newlines.
            |> map (.trim!)             # Trim spaces.
            |> filter id                # Filter out empty strings.
            |> map @free-text-key    # Lookup a free text key for the string.
            |> map @add-key-to-value    # Creates a function that appends the key to a list.
            |> fold (>>), id            # Chain all the functions together (f >> g >> h >> etc.)
            |> update @value         # Update the value of the list in one go.

            @show-input!

    index-of: (key) ~>
        @value!indexOf key

    selected-index: ~>
        @index-of @selected

    value-count: ~>
        @value!length

    key-at: (index) ~>
        @value![index]

    show-input: ~>
        @selected = null
        @input = ''
        @input-focused = true
        if @suggestions-on-empty-input then
            @update-suggestions!
        else
            @clear-suggestions!

    clear-suggestions: ~>
        @suggestions = []
        @highlighted-suggestion = null

    hide-input: ~>
        @input = null
        @input-focused = false
        @clear-suggestions!

    select: (key) ~>
        @selected = key
        @hide-input!

    edit: (key) ~>
        index = @index-of key
        assert index >= 0
        update @value, reject (== key)

        @selected = null
        @input = key
        @update-suggestions!

    key-to-add: ~>
        if @highlighted-suggestion != null then
            @item-key @suggestions[@highlighted-suggestion]
        else if @allow-free-text then
            @free-text-key (@input |> maybe-map (.trim!))

    add: ~>
        if key = @key-to-add! then
            update @value, @add-key-to-value key
            @show-input!
            # Ensure we keep focus, even after the redraws.
            asap @show-input

    add-no-focus: ~>
        if key = @key-to-add! then
            update @value, @add-key-to-value key
        @hide-input!

    # Returns a function that will add key to a list.
    add-key-to-value: (key) ->
        # Remove all duplicates and then append the key.
        # (effectively treating @value as a ordered set).
        (++ key) << reject (== key)

    move-left: ~>
        index = @selected-index!
        if index < 0 then
            if @value-count! > 0
            then @selected = @key-at @value-count! - 1
        else if index > 0 then
            @selected = @key-at index - 1

    move-right: ~>
        index = @selected-index!
        if index < 0 then
            @selected = null
        else if index < @value-count! - 1 then
            @selected = @key-at index + 1
        else
            @show-input!

    move-suggestion-highlight: (offset) ~>
        if @allow-free-text then
            if @highlighted-suggestion == null then
                if offset > 0 then
                    @highlighted-suggestion = 0
                return
            else if offset < 0 and @highlighted-suggestion + offset < 0 then
                @highlighted-suggestion = null
                return

        @highlighted-suggestion + offset
        |> clamp 0, @suggestions.length - 1
        |> (new-index) ~> @highlighted-suggestion = new-index

    input-keydown: (e) ~>
        switch e.keyCode
        | kc.ENTER =>
            @add!
        | kc.ESCAPE =>
            @hide-input!
        | kc.ARROW_LEFT, kc.BACKSPACE =>
            if @input == '' and @move-left! then
                @hide-input!
        #XXX gives weird behaviour if you type in a name with spaces quickly.
        # | kc.SPACE =>
        #     if @allow-free-text and @highlighted-suggestion != null then
        #         @input =  @suggestions[@highlighted-suggestion]
        #         @update-suggestions!
        | kc.ARROW_UP =>
            e.prevent-default!
            @move-suggestion-highlight -1
        | kc.ARROW_DOWN =>
            e.prevent-default!
            # If there are no suggestions, try to update them.
            if @suggestions.length == 0 then
                @update-suggestions!
            else
                @move-suggestion-highlight +1
        | kc.PAGE_UP =>
            e.prevent-default!
            @move-suggestion-highlight -10
        | kc.PAGE_DOWN =>
            e.prevent-default!
            @move-suggestion-highlight +10

    label-keydown: (e) ~>
        switch e.keyCode
        | kc.ARROW_LEFT =>
            e.prevent-default!
            @move-left!
        | kc.ARROW_RIGHT =>
            e.prevent-default!
            @move-right!
        | kc.DELETE, kc.BACKSPACE =>
            @remove @selected
        | kc.ENTER =>
            @edit @selected

    view: ->
        m '' {
            class: classes('c-multi-input field', {focus: @input != null})
            onclick: @show-input
        },
            @value!
            |> map (key) ~> [key, @lookup key]
            |> map ([key, entity]) ~> view-item @, key, entity
            |> if-empty ~>
                if @input == null and @placeholder then
                    m '.placeholder' deref @placeholder

            if @input == null then
                m '.fake-input', do
                    tabindex: 0
                    onfocus: ~> @input = ''
            else
                m '.input-container',
                    m 'input.input', do
                        type: 'text'
                        value: @input
                        oninput: (ev) ~> @oninput ev.target.value
                        onkeydown: stop-propagation @input-keydown
                        onpaste: prevent-default @onpaste
                        onblur: if @allow-free-text then @add-no-focus else @hide-input
                        oncreate: set-focus
                        onupdate: (vnode) ~>
                            if @input-focused then
                                set-focus vnode

                    if not empty @suggestions then
                        m '.input-autocomplete' {style: {width: @input-width}}, m 'ul',
                            @suggestions |> indexed-map (entity, index) ~>
                                is-highlighted = ~> @highlighted-suggestion == index
                                m 'li' do
                                    class: if is-highlighted! then 'highlighted' else ''
                                    onupdate: scroll-into-view is-highlighted
                                    onmousedown: @add
                                    onmouseover: ~> @highlighted-suggestion = index
                                    onmouseout: ~>
                                        if @highlighted-suggestion == index then
                                            @highlighted-suggestion = null

                                , @item-show entity


view-item = (ctrl, key, entity) ->
    is-selected = -> ctrl.selected == key

    keep-focused = (vnode) ->
        if is-selected! then
            set-focus vnode

    attrs = copy (ctrl.item-decorate entity) with do
        tabindex: 0
        onclick: stop-propagation -> ctrl.select key
        ondblclick: -> ctrl.edit key
        onfocus: -> ctrl.select key
        onblur: -> if ctrl.selected == key then ctrl.selected = null
        onkeydown: ctrl.label-keydown
        oncreate: keep-focused
        onupdate: keep-focused

    cls = if is-selected! then 'label-primary' else 'label-default'
    m "span.label.#{cls}" attrs,
        if entity then ctrl.item-show entity else 'unresolved'
        icon 'remove', do
            class: 'remove'
            onclick: stop-propagation -> ctrl.remove key


contains-ignore-case = (sub, str) -->
    contains sub.toLowerCase!, str.toLowerCase!


# Create a config that scrolls the element into view when cond! is true.
scroll-into-view = (cond) ->
    (vnode) ->
        if cond! then
            $e = $ vnode.dom
            parent = $e .parents '.input-autocomplete'
            scroll-top = parent.scroll-top!
            parent-height = parent .height!
            scroll-bottom = scroll-top + parent-height
            item-top = vnode.dom .offset-top
            item-bottom = item-top + $e .outer-height!

            if item-top < scroll-top then
                $ parent .scroll-top item-top
            else if item-bottom > scroll-bottom then
                $ parent .scroll-top item-bottom - parent-height


set-focus = (vnode) ->
    if not $ vnode.dom .is ':focus' then
        $ vnode.dom .focus!
