$ = require 'jquery'
m = require 'mithril'
{merge-obj} = require 'utils.ls'

export toggle = (prop, attrs={}) ->

    # If no alternate styling is defined default to red/green switches.
    if !('data-onstyle' of attrs) then
        attrs['data-onstyle'] = 'success'
    if !('data-offstyle' of attrs) then
        attrs['data-offstyle'] = 'danger'

    final-attrs = merge-obj attrs, do
        type: 'checkbox'
        checked: if prop! then 'checked' else null
        id: 'toggle-excise'
        'data-toggle': 'toggle'
        'data-onstyle': onstyle = attrs['data-onstyle']
        'data-offstyle': offstyle = attrs['data-offstyle']
        # Passing the onchange event in these properties does not work since mithril 2. Workaround -> jQuery.
        oncreate: (vnode) ->
            $ vnode.dom .bootstrap-toggle({
                on: attrs.on,
                off: attrs.off
            })
            $('#toggle-excise')[0].onchange = ->
                value =  $(this).prop('checked')
                prop value
                if attrs.onafterupdate then
                    attrs.onafterupdate value

    m 'input.checkbox', final-attrs


export toggle-with-label = (label, prop, attrs={}) ->
    m '.c-toggle-label checkbox' m 'label',
        toggle prop, attrs
        label
