import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'
import {Validator} from '@bitstillery/common/lib/validation'

import {DropDownOption, DropDownWithSelect} from './html_components'
import {MaybeObservable} from './relation'

import {GetInsuranceTypeResponse} from '@/factserver_api/fact2server_api'

interface InsuranceTypesDropDownAttrs extends Validator {
    selected_insurance_type_artkey: string
    onchange: (insurance_type_artkey: string) => unknown
    disabled?: boolean
    required?: boolean
    /**
     * The data source for the drop down options. Fill eg with RelationDropDownData.relations()
     **/
    get_all_for_drop_down_response$: Observable<GetInsuranceTypeResponse[]>
}

/**
 * InsuranceTypes drop down.
 *
 * Usage:
 *        <InsuranceTypesDropDown
 *             selected_insurance_type_artkey={`${this.update_sales_order_request.insurance_type_artkey}`}
 *             disabled={this.is_disabled()}
 *             get_all_for_drop_down_response$={InsuranceTypesDropDownData.insurance_types()}
 *             onchange={(artkey) => this.update_sales_order_request.insurance_type_artkey = artkey}
 *        />
 **/
export class InsuranceTypesDropDown extends MithrilTsxComponent<InsuranceTypesDropDownAttrs> {
    insurance_types: GetInsuranceTypeResponse[] = []

    oncreate(vnode: m.Vnode<InsuranceTypesDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((insurance_types) => (this.insurance_types = insurance_types))
    }

    view(vnode: m.Vnode<InsuranceTypesDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
                <DropDownWithSelect
                    label={vnode.attrs.label}
                    onchange={vnode.attrs.onchange}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected_insurance_type_artkey}
                    required={vnode.attrs.required}
                >
                    {this.insurance_types?.map((insurance_type) => (
                        <DropDownOption value={`${insurance_type.artkey}`}>{insurance_type.name}</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}
