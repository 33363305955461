import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse, FactserverRequestData} from './api'

export interface Notification {
    artkey: number
    message: string
    date: string
    link: string[]
    subject: string
    is_read?: boolean
    is_seen_at: string
}

export interface NotificationsResponse {
    notifications: Notification[]
}

export interface OpenNotificationsResponse {
    count: number
}

export interface FullOpenNotificationsResponse {
    open_notifications_count: number
    open_notifications_crm: number
    open_notifications_email_batches: number
    open_notifications_price_lists: number
    open_notifications_suppliers: number
    open_notifications_portal_users: number
    open_notifications_sales_orders: number
    open_notifications_items: number
    open_notifications_offers: number
    open_notifications_leads: number
    open_notifications_purchase_orders: number
}

export interface MarkNotificationReadRequest extends FactserverRequestData {
    notification_artkeys: number[]
}

export interface BlockNotificationSubjectsRequest extends FactserverRequestData {
    subjects: string[]
}

export class NotificationApi {
    api = new Api()

    get_for_user(): Observable<NotificationsResponse> {
        return this.api.post_request('notifications.get_for_user', {})
    }

    notifications_count(): Observable<FullOpenNotificationsResponse> {
        return this.api.post_request('notifications.notifications_count', {})
    }

    mark_as_seen(request: MarkNotificationReadRequest): Observable<FactserverEmptyResponse | null> {
        return this.api.post_request('notifications.mark_as_seen', request)
    }

    block_subjects(request: BlockNotificationSubjectsRequest): Observable<FactserverEmptyResponse | null> {
        return this.api.post_request('notifications.block_notification_subjects', request)
    }

    unblock_subjects(request: BlockNotificationSubjectsRequest): Observable<FactserverEmptyResponse | null> {
        return this.api.post_request('notifications.unblock_notification_subjects', request)
    }

    recent_notifications_count(): Observable<OpenNotificationsResponse> {
        return this.api.post_request('notifications.recent_notifications_count', {})
    }

    snooze_notifications(notification_artkeys: number[], number_of_days: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('notifications.snooze_notifications', {
            notification_artkeys: notification_artkeys,
            number_of_days: number_of_days,
        })
    }
}
