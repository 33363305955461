import m from 'mithril'
import {format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {accountIconBySlug} from 'accounts.ls'
import {show_confirmation} from 'components/confirmation.ls'
import {Button} from '@bitstillery/common/components'
import {$t, notifier} from '@bitstillery/common/app'

import {CollectionTable, CollectionTableColumn, PagedCollectionFetcherWithGET} from '@/components/collection/collection_table'
import {GetLoenderslootStockReconResponse} from '@/factserver_api/fact2server_api'
import {LoenderslootApi} from '@/factserver_api/loendersloot_api'

export default class LoenderslootStockReconList extends MithrilTsxComponent<unknown> {
    loendersloot_api = new LoenderslootApi()
    is_loading = false
    recon_fetcher = new PagedCollectionFetcherWithGET<GetLoenderslootStockReconResponse>(
        'discover/loendersloot-stock/recons/collection-view',
        'created_on',
        undefined,
        30,
        false,
    )

    view(): m.Children {
        return (
            <div className="c-stock-recon view">
                <div className="btn-toolbar">
                    <Button
                        icon="compare"
                        loading={this.is_loading}
                        text={$t('stock.actions.rerun_differences')}
                        disabled={this.is_loading}
                        onclick={() => show_confirmation({
                            unique_name: 'rerun confirmation',
                            title: 'Schedule rerun',
                            message: 'The stock recon will be started as soon you hit the OK button; please check back in a couple of minutes for the result.',
                            onconfirm: () => {
                                this.loendersloot_api.reschedule_stockrecon().subscribe({
                                    next: () => {
                                        notifier.notify('Rerun scheduled; please wait a couple of minutes', 'success')
                                        this.is_loading = false
                                        m.redraw()
                                    },
                                    error: () => {
                                        this.is_loading = false
                                        m.redraw()
                                    },
                                })
                            },
                        })}
                        type="info"
                        variant="context"
                    />
                </div>
                <CollectionTable<GetLoenderslootStockReconResponse, void>
                    collection_fetcher={this.recon_fetcher}
                    on_row_click={(row: GetLoenderslootStockReconResponse) => m.route.set(`/stock/recon/${row.artkey}`)}
                >
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Created on'}
                        sort_name={'created_on'}
                        data_field={(row: GetLoenderslootStockReconResponse) => format_iso_to_date_time(row.created_on)}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'File name'}
                        data_field={(row: GetLoenderslootStockReconResponse) => [
                            accountIconBySlug(row.account_slug, row.account_name),
                            row.file_name,
                        ]}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Total items'}
                        td_class_name={'col-sm-1'}
                        data_field={(row: GetLoenderslootStockReconResponse) => row.total_items}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Matched Discover with Loendersloot'}
                        data_field={(row: GetLoenderslootStockReconResponse) => row.total_items_found_in_loendersloot}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Unmatched Discover with Loendersloot'}
                        data_field={(row: GetLoenderslootStockReconResponse) => row.total_items_not_found_in_loendersloot}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Matched Loendersloot in Discover'}
                        data_field={(row: GetLoenderslootStockReconResponse) => row.total_items_found_in_discover}
                    />
                    <CollectionTableColumn<GetLoenderslootStockReconResponse>
                        header_title={() => 'Unmatched Loendersloot in Discover'}
                        data_field={(row: GetLoenderslootStockReconResponse) => row.total_items_not_found_in_discover}
                    />
                </CollectionTable>
            </div>
        )
    }
}
