import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {notifier} from '@bitstillery/common/app'

import {AddToOrder} from '@/purchase_orders/components/add_to_order'
import {DefaultButton} from '@/components/buttons'
import {GetPurchaseOrderItemsCollectionViewResponse} from '@/factserver_api/fact2server_api'

interface SplitPurchaseOrderItemAttrs {
    purchase_order_item: GetPurchaseOrderItemsCollectionViewResponse
    cases_in_tbo: number
    done: () => void
    switch_to_tbo: () => void
}

export class SplitPurchaseOrderItem extends MithrilTsxComponent<SplitPurchaseOrderItemAttrs> {
    purchase_order_item: GetPurchaseOrderItemsCollectionViewResponse
    done: () => void
    switch_to_tbo: () => void

    data = (() => {
        const $f = {
            cases_to_split: 0,
        }
        return proxy({
            $f,
        })
    })()

    cases_in_sales: number
    cases_in_tbo: number
    poi_number_of_cases: number

    constructor(vnode: m.Vnode<SplitPurchaseOrderItemAttrs>) {
        super()

        this.purchase_order_item = vnode.attrs.purchase_order_item
        this.done = vnode.attrs.done
        this.switch_to_tbo = vnode.attrs.switch_to_tbo

        this.cases_in_tbo = this._calculate_cases_in_tbo()
        this.cases_in_sales = this._calculate_cases_in_sales()
        this.poi_number_of_cases = this.purchase_order_item.number_of_cases
    }

    process_split(purchase_order_artkey: number) {
        this.poi_number_of_cases -= this.data.$f.cases_to_split
        this.purchase_order_item.number_of_cases = this.poi_number_of_cases
        this.done()
        notifier.notify(
            `Successfully split ${this.data.$f.cases_to_split} ${
                this.data.$f.cases_to_split > 1 ? 'cases' : 'case'
            } to purchase order P${purchase_order_artkey}`,
            'success',
        )

        m.redraw()
    }

    _calculate_cases_in_tbo(): number {
        return this.purchase_order_item.number_of_cases_in_tbo
    }

    _calculate_cases_in_sales(): number {
        return this.purchase_order_item.number_of_cases_in_soi
    }

    view(): m.Children {
        // It is assumed here that a POI can't have cases in a TBO and a SOI at the same time
        const unsplitable_cases = this.cases_in_sales ? this.cases_in_sales : this.cases_in_tbo
        const max_to_split = unsplitable_cases ?
            this.poi_number_of_cases - unsplitable_cases :
            this.purchase_order_item.number_of_cases

        const current_color_class = this.data.$f.cases_to_split <= max_to_split
            ? '' : 'analysis-bad-color'

        return (
            <div className="c-split-poi">
                <div className="context-well">
                    {this.cases_in_tbo > 0 && <div className="field-group">

                        <div className="field-readonly">
                            <div className="key"></div>
                            <div className="value">
                                <DefaultButton
                                    title={' Split TBOs'}
                                    icon_class={'glyphicon glyphicon-flag'}
                                    onclick={() => this.switch_to_tbo()}
                                    additional_class={'pull-right'}
                                />
                            </div>
                        </div>
                        <div className="field-readonly">
                            <div className="key">Cases in TBOs</div>
                            <div className="value">{this.cases_in_tbo}</div>
                        </div>
                    </div>}
                    <div className="field-group">
                        <div className="field-readonly">
                            <div className="key">Cases in POI</div>
                            <div className="value">{this.poi_number_of_cases}</div>
                        </div>

                        {this.cases_in_sales > 0 && <div className="field-readonly">
                            <div className="key">Cases in Sales</div>
                            <div className="value">{this.cases_in_sales}</div>
                        </div>}

                        <div className="field-readonly">
                            <div className="key">Cases in POI after splitting</div>
                            <div className="value">
                                <span className={current_color_class}>{
                                    this.purchase_order_item.number_of_cases - this.data.$f.cases_to_split
                                }</span>
                            </div>
                        </div>
                    </div>
                    <FieldText
                        ref={[this.data.$f, 'cases_to_split']}
                        label="Cases in POI after splitting"
                        min={0}
                        max={max_to_split}
                        placeholder={'# cases to split'}
                        type="number"
                    />
                </div>

                {(!!this.data.$f.cases_to_split && this.data.$f.cases_to_split <= max_to_split && this.data.$f.cases_to_split > 0) && <div className="context-well mt-2">
                    <h4>Split to Purchase Order:</h4>
                    <AddToOrder
                        supplier_artkey={this.purchase_order_item.supplier_artkey}
                        on_added_item={(purchase_order_artkey: number) => this.process_split(purchase_order_artkey)}
                        purchase_order_item={{
                            artkey: () => this.purchase_order_item.artkey,
                            was_bought_for: () => this.purchase_order_item.was_bought_for,
                            suggested_price_per_case: () => this.purchase_order_item.suggested_price_per_case,
                            country_of_origin: () => this.purchase_order_item.country_of_origin,
                            case: () => {
                                return {
                                    artkey: this.purchase_order_item.case_artkey,
                                    number_of_bottles: () => 6,
                                }
                            },
                        }}
                        exclude_purchase_order_artkeys={[this.purchase_order_item.purchase_order_artkey]}
                        cases={this.data.$f.cases_to_split}
                    />
                </div>}
            </div>
        )
    }
}
