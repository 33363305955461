import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse, FactserverRequestData} from './api'

export interface CategorizePurchaseOrderAttachmentRequest extends FactserverRequestData {
    attachment_artkey: number
    description: string | null
    category: string | null
    attachment_type: string
    valid_until: string | null
}

export enum AttachmentType {
    ITEM_MUTATION = 'Item Mutation',
    PURCHASE_ORDER = 'Purchase Order',
    RELATION = 'Relation',
    SALES_ORDER = 'Sales Order',
    WAREHOUSE = 'Warehouse',
    LEAD = 'Lead',
}

export interface GetAttachmentRequest {
    related_artkey: number
    attachment_type: string
}

interface Profile {
    name: string
}

interface User {
    profile: Profile
}

export interface GetAttachmentResponse {
    artkey: number
    file_name: string
    file_hash: string
    description: string | null
    created_on: string
    creator: User
    category: string
    valid_until?: string
}

export interface DeleteAttachmentRequest {
    attachment_artkey: number
    attachment_type: string
}

export interface UploadAttachmentRequest {
    purchase_order_artkey?: number
    sales_order_artkey?: number
    warehouse_artkey?: number
    supplier_artkey?: number
    item_mutation_artkey?: number

    file: string
    file_name: string
    attachment_type: string
}

export interface AttachmentCategory {
    artkey: number
    name: string
    attachment_type: string
    loendersloot_document_types: string | null
}

export interface CreateOrUpdateAttachmentCategoryRequest {
    artkey?: number
    name: string
    attachment_type: string
    loendersloot_document_types: string | null
}

export class AttachmentApi {
    api = new Api()

    categorize_attachment(
        request: CategorizePurchaseOrderAttachmentRequest,
    ): Observable<FactserverEmptyResponse | null> {
        return this.api.post_request('attachment.categorize_attachment', request)
    }

    delete_attachment(request: DeleteAttachmentRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('attachment.delete_attachment', request)
    }

    download_all_item_mutation_attachments(item_mutation_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.download_all_item_mutation_attachments', {
            artkey: item_mutation_artkey,
        })
    }

    download_all_sales_order_attachments(sales_order_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.get_sales_order_attachment_file_batch', {
            sales_order_artkey: sales_order_artkey,
        })
    }

    download_all_purchase_order_attachments(purchase_order_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.get_purchase_order_attachment_file_batch', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    download_all_lead_attachments(lead_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.get_lead_attachment_file_batch', {
            lead_artkey: lead_artkey,
        })
    }

    download_all_warehouse_attachments(warehouse_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.get_warehouse_attachment_file_batch', {
            warehouse_artkey: warehouse_artkey,
        })
    }

    download_all_relation_attachments(relation_artkey: number): Observable<string> {
        return this.api.post_request<unknown, string>('attachment.get_relation_attachment_file_batch', {
            relation_artkey: relation_artkey,
        })
    }

    fetch_sales_order_attachments(sales_order_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_sales_order_attachments', {
            sales_order_artkey: sales_order_artkey,
        })
    }

    fetch_warehouse_attachments(warehouse_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_warehouse_attachments', {
            warehouse_artkey: warehouse_artkey,
        })
    }

    fetch_purchase_order_attachments(purchase_order_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_purchase_order_attachments', {
            purchase_order_artkey: purchase_order_artkey,
        })
    }

    fetch_lead_attachments(lead_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_lead_attachments', {
            lead_artkey: lead_artkey,
        })
    }

    fetch_relation_attachments(relation_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_relation_attachments', {
            relation_artkey: relation_artkey,
        })
    }

    fetch_item_mutation_attachments(item_mutation_artkey: number): Observable<GetAttachmentResponse[]> {
        return this.api.post_request('attachment.get_item_mutation_attachments', {
            item_mutation_artkey: item_mutation_artkey,
        })
    }

    upload_attachment(upload_attachment_request: UploadAttachmentRequest): Observable<GetAttachmentResponse> {
        return this.api.post_request('attachment.create_attachment', upload_attachment_request)
    }

    get_all_attachment_categories(): Observable<AttachmentCategory[]> {
        return this.api.post_request('attachment.get_all_attachment_categories', {})
    }

    get_attachment_category(attachment_category_artkey: number): Observable<AttachmentCategory> {
        return this.api.post_request('attachment.get_attachment_category', {
            artkey: attachment_category_artkey,
        })
    }

    create_or_update_attachment_category(
        create_or_update_attachment_category: CreateOrUpdateAttachmentCategoryRequest,
    ): Observable<AttachmentCategory> {
        return this.api.post_request('attachment.create_or_update_attachment_category', {
            ...create_or_update_attachment_category,
        })
    }

    delete_attachment_category(attachment_category_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('attachment.delete_attachment_category', {
            artkey: attachment_category_artkey,
        })
    }
}
