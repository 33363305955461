m = require 'mithril'
{map, compact, concat, empty} = require 'prelude-ls'
utils = require '../../utils.ls'
{Amount} = require '@bitstillery/common/components'
{icon} = require '@/components/icon.ls'
inputs = require '@/components/inputs.ls'
{labels} = require '@/components/labels.ls'
{to-string} = utils
{Icon, Country} = require '@bitstillery/common/components'
app = require('@/app')
{CaseLabels} = require '@/components/labels'

export items_table = (items, options) ->
    if not options then
        options = do
            include_prices: true

    purchase_order_link = (item) ->
        "/purchase-orders/manage/#{item.purchase_order_item!purchase_order!reference!}"

    a = -> [.. for &]
    m 'table.table' a do
        m 'thead.thead-default',
            m 'tr' a do
                m 'th' '#'
                m 'th' 'Intake'
                m 'th' 'Warehouse'
                m 'th' 'Lot #'
                m 'th' 'Product'
                m 'th' 'Country of origin'
                m 'th.number' 'Btl / cs'
                m 'th.number' 'Size'
                m 'th.number' 'Alc %'
                m 'th' 'Ref'
                m 'th' 'GB'
                if options.include_prices then a do
                    m 'th.price' 'Purchase / cs'
                    m 'th.price' 'Cost / cs'
                m 'th.number' 'Cases'
                m 'th.number' 'Stock'
                m 'th.number' 'P.O.'
                m 'th.number' 'S.O.'
                m 'th.number' 'Avail.'
                m 'th' 'Customs status'
                m 'th' # link to item

        items!map (item, index) -> m 'tbody.table-row' a do
            m 'tr' a do
                m 'td' m 'a' {onclick: ~> m.route.set purchase_order_link item} item.purchase_order_item!purchase_order!reference!
                m 'td' utils.format-date(item.entry_date!) || '-'
                m 'td' if item.warehouse_artkey! then item.warehouse!name!
                m 'td' m 'samp' item.lot!
                m 'td' item.bottle!product!name!
                m 'td', m Country,
                    country_code: item.country_of_origin!
                    type: 'flag_with_country'
                m 'td.number' item.number_of_bottles_per_case!
                m 'td.number' item.bottle!display_volume!
                m 'td.number' item.bottle!display_alcohol_percentage!
                m 'td' item.bottle!refill_status!
                m 'td' item.gift_box_type!

                if options.include_prices then a do
                    m 'td.price' m Amount, do
                        amount: item.was_bought_for!
                        currency: item.was_bought_in!
                    m 'td.price' m Amount, do
                        amount: item.was_bought_for_plus_costs!
                        currency: item.was_bought_in!

                m 'td.number' item.number_of_cases! || 0
                m 'td.number' item.number_of_cases_in_stock! || 0
                m 'td.number' item.number_of_cases_in_purchase! || 0
                m 'td.number' item.number_of_cases_in_sales! || 0
                m 'td.number' item.number_of_cases_available! || 0
                m 'td' item.customs_status!

                m 'td.actions.no-click',
                    m '.actions-group',
                        m '.action-toggles',
                            if m.route.get() != "/stock/manage/#{item.reference!}" then
                                m Icon, {
                                    name: 'search',
                                    onclick: ~> window.open("#!/stock/manage/#{item.reference!}")
                                    tip: "View stock item #{item.reference!}",
                                    type: 'info',
                                }

            if item.case! and item.case!serialized_item_tags
                m 'tr' m 'td' {colspan: '100%'},
                    m CaseLabels,
                        show_as_labels: true,
                        case:
                            serialized_item_tags: item.case!serialized_item_tags!
                            gift_box_type: item.case!gift_box_type!
                            cases_per_pallet: item.cases_per_pallet!
                            remark: item.remark!
                            best_before_date: item.case!best_before_date!
                            tax_label: item.case!tax_label!
