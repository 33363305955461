import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'

import {$s} from '@/app'
import {EditAssist, Field, HorizontalForm} from '@/components/form'
import {BackToListButton} from '@/components/discover'
import {PercentInput} from '@/components/decimal_input'
import {InputDate} from '@/components/html_components'
import {
    CreateMarginThresholdRequest,
    GetMarginThresholdResponse,
    MarginsApi,
    UpdateMarginThresholdRequest,
} from '@/factserver_api/margins_api'

export default class MarginThresholdEdit extends MithrilTsxComponent<unknown> {
    edit_assist: EditAssist
    is_loading = false
    margin_api = new MarginsApi()
    margin_threshold: GetMarginThresholdResponse | null = null
    create_or_update_request: UpdateMarginThresholdRequest | CreateMarginThresholdRequest = {
        artkey: 0,
        end_needs_approval_range: 0,
        end_low_margin_range: 0,
        start_target_margin_range: 0,
        start_date: DateTime.now().toISODate(),
    }

    constructor() {
        super()

        this.edit_assist = new EditAssist(m.route)
    }

    oncreate(): void {
        if (!$s.identity.user.is_sales_target_manager) {
            m.route.set('/data/margin-thresholds')
        }
        this.fetch_margin()
    }

    create_request(): CreateMarginThresholdRequest {
        return this.create_or_update_request as CreateMarginThresholdRequest
    }

    update_request(): UpdateMarginThresholdRequest {
        return this.create_or_update_request as UpdateMarginThresholdRequest
    }

    fetch_margin(): void {
        if (!this.edit_assist.artkey) {
            return
        }
        this.is_loading = true
        const update_request = this.update_request()
        this.margin_api.get_margin_threshold(this.edit_assist.artkey).subscribe({
            next: (response: GetMarginThresholdResponse) => {
                this.margin_threshold = response
                this.is_loading = false
                update_request.artkey = response.artkey
                update_request.start_target_margin_range = response.start_target_margin_range
                    ? +response.start_target_margin_range
                    : 0
                update_request.end_needs_approval_range = response.end_needs_approval_range
                    ? +response.end_needs_approval_range
                    : 0
                update_request.end_low_margin_range = response.end_low_margin_range ? +response.end_low_margin_range : 0
                m.redraw()
            },
            error: () => {
                this.is_loading = false
            },
        })
    }

    update_margin(): boolean {
        if (this.edit_assist.is_creating) {
            const create_request = this.create_request()
            this.margin_api.create_margin_threshold(create_request).subscribe({
                next: () => {
                    notifier.notify('Successfully created the margin', 'success')
                    m.route.set('/data/margin-thresholds')
                },
            })
        } else {
            const update_request = this.update_request()
            this.margin_api.update_margin_threshold(update_request).subscribe({
                next: () => {
                    notifier.notify('Successfully updated the margin', 'success')
                },
            })
        }
        return false
    }

    view(): m.Children {
        return (
            <div className="c-margin-threshold">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/margin-thresholds'} />
                </div>
                {!this.is_loading && (
                    <div className={'row col-sm-6'}>
                        <HorizontalForm onsubmit={() => this.update_margin()} edit_assist={this.edit_assist}>
                            {!this.edit_assist.is_creating && (
                                <Field label={'Date'}>
                                    {`${format_iso_to_date(
                                        this.margin_threshold?.start_date || '',
                                    )} - ${format_iso_to_date(this.margin_threshold?.end_date || '')}`}
                                </Field>
                            )}
                            {this.edit_assist.is_creating && (
                                <Field label={'Start date'}>
                                    <InputDate
                                        value={this.create_request().start_date}
                                        onchange={(value: DateTime) =>
                                            (this.create_request().start_date = value.toISODate())
                                        }
                                    />
                                </Field>
                            )}
                            <Field label={'Needs approval below'}>
                                <PercentInput
                                    value={this.create_or_update_request.end_needs_approval_range}
                                    required={true}
                                    on_value={(value: number) =>
                                        (this.create_or_update_request.end_needs_approval_range = value)
                                    }
                                />
                            </Field>
                            <Field label={'Red zone below'}>
                                <PercentInput
                                    value={this.create_or_update_request.end_low_margin_range}
                                    required={true}
                                    on_value={(value: number) =>
                                        (this.create_or_update_request.end_low_margin_range = value)
                                    }
                                />
                            </Field>
                            <Field label={'Green zone starting from'}>
                                <PercentInput
                                    value={this.create_or_update_request.start_target_margin_range}
                                    required={true}
                                    on_value={(value: number) =>
                                        (this.create_or_update_request.start_target_margin_range = value)
                                    }
                                />
                            </Field>
                        </HorizontalForm>
                    </div>
                )}
            </div>
        )
    }
}
