import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money} from '@bitstillery/common/ts_utils'
import {DateTime} from 'luxon'
import {classes} from '@bitstillery/common/lib/utils'
import {current_account_slug} from '@bitstillery/common/account/account'

import {$s} from '@/app'
import {Chart} from '@/lib/chart'
import {border_colors, default_chart_options_for} from '@/components/adminlte/chart_js_utils'
import {DashboardApi} from '@/factserver_api/dashboard_api'
import {DashboardStatisticsResponse, SupplierStatistic} from '@/factserver_api/fact2server_api.ts'

interface PurchaseStatisticsWidgetAttrs {
    user_artkey: number | null | undefined
    is_collapsed: boolean
    on_collapsed: (value: boolean) => unknown
}

/**
 * Display the purchasing statistics in a graph.
 */
export class PurchaseStatisticsWidget extends MithrilTsxComponent<PurchaseStatisticsWidgetAttrs> {
    is_loading = false
    user_artkey: number | null | undefined

    sales_chart: Chart | null = null
    dashboard_api = new DashboardApi()

    year_1 = DateTime.now().year - 4
    year_2 = DateTime.now().year - 3
    year_3 = DateTime.now().year - 2
    year_4 = DateTime.now().year - 1
    year_5 = DateTime.now().year

    static purchase_chart = 'canvas-purchase-statistics-widget'

    constructor(vnode: m.Vnode<PurchaseStatisticsWidgetAttrs>) {
        super()

        this.user_artkey = vnode.attrs.user_artkey
    }

    oncreate(vnode: m.Vnode<PurchaseStatisticsWidgetAttrs>): void {
        const canvas = document.getElementById(PurchaseStatisticsWidget.purchase_chart) as HTMLCanvasElement
        if (!canvas) {
            throw Error('Programmer error')
        }

        const config = default_chart_options_for('line')
        config.options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    callbacks: {
                        label(context): string | string[] {
                            return `${context.dataset.label}: ${format_money(
                                +(context.raw as string),
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}`
                        },
                    },
                },
                legend: {
                    display: true,
                },
            },
        }
        this.sales_chart = new Chart(canvas, config)
        this.fetch_purchase_statistics(vnode.attrs.user_artkey)
    }

    onupdate(vnode: m.Vnode<PurchaseStatisticsWidgetAttrs>): void {
        if (vnode.attrs.user_artkey !== undefined && vnode.attrs.user_artkey !== this.user_artkey) {
            this.fetch_purchase_statistics(vnode.attrs.user_artkey)
        }
    }

    fetch_purchase_statistics(user_artkey: number | null | undefined): void {
        if (this.is_loading || user_artkey === undefined) {
            return
        }
        if (user_artkey === null) {
            return
        }

        this.is_loading = true

        const labels = [this.year_1, this.year_2, this.year_3, this.year_4, this.year_5]
        const date_range_str = labels.map((year) => `${year}-01-01_${year + 1}-01-01`).join(',')

        this.dashboard_api.purchase_statistics(user_artkey, current_account_slug(), date_range_str).subscribe({
            next: (response: DashboardStatisticsResponse) => {
                const statistics = response.statistics
                this.is_loading = false
                this.user_artkey = user_artkey

                const dataset = statistics.map((value: SupplierStatistic) => {
                    return {
                        label: value.name,
                        data: value.year_values.sort((a, b) => a.year - b.year).map((year_value) => +year_value.value),
                        fill: false,
                        borderColor: border_colors,
                        tension: 0.1,
                    }
                })

                if (this.sales_chart) {
                    this.sales_chart.data = {
                        labels: labels,
                        datasets: dataset,
                    }
                    this.sales_chart.update()
                }

                m.redraw()
            },
            error: () => {
                m.redraw()
                this.is_loading = false
            },
        })
    }

    view() {
        return (
            <div className={classes('c-purchase-statistics dashboard-widget', {
                loading: this.is_loading,
            })}>
                <div className="widget-title">
                    <span className="text">Largest differences between yearly purchase value</span>
                </div>
                <div
                    className="widget-body"
                    onclick={() => {
                        if (this.user_artkey) {
                            window.open(
                                `#!/market/statistics?manager=${this.user_artkey}&year_1=${this.year_1}&year_2=${this.year_2}&year_3=${this.year_3}&type=purchase`,
                                '_blank',
                            )
                        }
                    }}
                >
                    <canvas id={PurchaseStatisticsWidget.purchase_chart} />
                </div>
                <div className="widget-help">
                    The top 10 relation list with the largest differences between adjacent years (except the current year)
                </div>
            </div>
        )
    }
}
