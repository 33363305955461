import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {show_confirmation} from 'components/confirmation.ls'
import {Country, Spinner} from '@bitstillery/common/components'

import {CountriesSelect, DropDownOption, DropDownWithSelect} from '@/components/html_components'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {GetProductsResponse} from '@/factserver_api/fact2server_api'

enum DefaultCountryFilter {
    ALL = 'All',
    SOME = 'Some',
    NONE = 'None',
}

interface GetProductsListResponse extends GetProductsResponse {
    number_of_bottles: number
    brand?: {
        name: String,
        artkey: number,
    }
}

export default class ProductList extends MithrilTsxComponent<unknown> {
    search_text = ''
    product_management_api = new ProductManagementApi()
    products_fetcher = new PagedCollectionFetcher<GetProductsListResponse>(
        'product_management.get_products',
        'name',
        () => (this.selected_product_artkeys = []),
    )

    selected_product_artkeys: number[] = []
    search_bar_controller: SearchBarControl | null = null
    selected_country_code = ''
    updating_countries = false
    created_since_date: DateTime | null = null

    update_new_since = (new_since: DateTime): void => {
        this.created_since_date = new_since
        if (this.created_since_date !== null) {
            this.products_fetcher.filters['created_since_date'] = this.created_since_date.toISODate()
        } else {
            delete this.products_fetcher.filters['created_since_date']
        }
        this.products_fetcher.reset_and_query()
    }

    search_for_search_text = (search_text: string): void => {
        this.search_text = search_text
        this.products_fetcher.set_search_terms(search_text)
    }

    constructor() {
        super()
        this.products_fetcher.filters['default_country_code'] = DefaultCountryFilter.ALL
    }

    clear_countries_for_selected_products(): void {
        this.selected_country_code = ''
        this.update_countries_for_selected_products(false)
    }

    toggle_select_all(): void {
        if (this.selected_product_artkeys.length > 0) {
            this.products_fetcher.page_size = 25
            this.products_fetcher.reset_and_query()
            this.selected_product_artkeys = []
        } else if (this.search_text && this.selected_product_artkeys.length === 0) {
            // search and select-all -> fetch-all
            this.products_fetcher.page_size = null
            this.products_fetcher.reset_and_query(() => {
                this.selected_product_artkeys = this.products_fetcher.fetched_rows.map(
                    (row: GetProductsResponse) => row.artkey,
                )
            })
        } else {
            // just select the ones in view now.
            this.selected_product_artkeys = this.products_fetcher.fetched_rows.map(
                (row: GetProductsResponse) => row.artkey,
            )
        }
    }

    update_countries_for_selected_products(update_stock: boolean): void {
        this.product_management_api
            .update_country_for_product({
                default_country_code: this.selected_country_code,
                product_artkeys: this.selected_product_artkeys,
                update_stock: update_stock,
            })
            .subscribe(() => {
                this.products_fetcher.fetched_rows
                    .filter((product) => this.selected_product_artkeys.includes(product.artkey))
                    .forEach((product) => (product.default_country_code = this.selected_country_code))
                m.redraw()
            })
    }

    update_default_country_filter(value: DefaultCountryFilter): void {
        this.products_fetcher.filters['default_country_code'] = value
        this.selected_product_artkeys = []
        this.products_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <div className="c-products view">
                <div className="c-filter-group">
                    <DefaultButton
                        icon_class={'glyphicon glyphicon-resize-small'}
                        disabled={this.selected_product_artkeys.length < 2}
                        onclick={() => m.route.set(`/data/products/merge?p=${this.selected_product_artkeys.join(',')}`)}
                        title={' Merge'}
                    />
                    <DefaultButton
                        icon_class={'glyphicon glyphicon-th-list'}
                        onclick={() => m.route.set('/data/products/blacklisted')}
                        title={' View blacklisted products'}
                    />

                    <CountriesSelect
                        selected={this.selected_country_code}
                        onchange={(value: string) => (this.selected_country_code = value)}
                    />
                </div>
                <div className="c-filter-group">
                    {this.updating_countries && <Spinner />}
                    {!this.updating_countries && (
                        <span className={'btn-group'}>
                            <DefaultButton
                                icon_class={`flag-icon flag-icon-${this.selected_country_code.toLowerCase()}`}
                                disabled={this.selected_country_code === '' || this.selected_product_artkeys.length === 0}
                                onclick={() => this.update_countries_for_selected_products(false)}
                                title={` Update ${this.selected_product_artkeys.length} selected`}
                            />
                            <DefaultButton
                                icon_class={`flag-icon flag-icon-${this.selected_country_code.toLowerCase()}`}
                                disabled={this.selected_country_code === '' || this.selected_product_artkeys.length === 0}
                                onclick={() => {
                                    show_confirmation({
                                        title: 'Update default country code and stock items',
                                        message:
                                            'This will update all the stock items and set the default. Are you sure?',
                                        unique_name: 'update_default_and_stock',
                                        onconfirm: () => this.update_countries_for_selected_products(true),
                                    })
                                }}
                                title={` Update ${this.selected_product_artkeys.length} selected + update stock`}
                            />
                        </span>
                    )}

                    {!this.updating_countries && (
                        <DangerButton
                            icon_class={'glyphicon glyphicon-trash'}
                            disabled={this.selected_product_artkeys.length === 0}
                            onclick={() => this.clear_countries_for_selected_products()}
                            title={' Clear country code'}
                        />
                    )}
                </div>

                <div className="c-filter-group">
                    <SearchBar
                        label="Search Products"
                        placeholder={'Search for name GTIN and/or category...'}
                        on_submit={this.search_for_search_text}
                        default_search_text={this.products_fetcher.search_text()}
                        search_bar_controller={(controller: SearchBarControl) =>
                            (this.search_bar_controller = controller)
                        }
                        on_get_suggestions$={(filter_text: string) =>
                            this.product_management_api.get_simple_product_names(filter_text)
                        }
                    />

                    <DropDownWithSelect
                        label="Country"
                        selected={this.products_fetcher.filters['default_country_code'] as string}
                        onchange={(value: string) =>
                            this.update_default_country_filter(value as DefaultCountryFilter)
                        }
                        empty_option={
                            <DropDownOption value={DefaultCountryFilter.ALL}>{'Has country'}</DropDownOption>
                        }
                    >
                        {[
                            <DropDownOption value={DefaultCountryFilter.SOME}>{'Default country'}</DropDownOption>,
                            <DropDownOption value={DefaultCountryFilter.NONE}>
                                {'No default country'}
                            </DropDownOption>,
                        ]}
                    </DropDownWithSelect>

                    <div className="field">
                        <label>New since</label>

                        <input
                            value={this.created_since_date?.toISODate()}
                            onblur={function() {
                                if (this.value === '') this.type = 'text'
                            }}
                            onfocus={function() {
                                this.type = 'date'
                            }}
                            onchange={(value: InputEvent) => {
                                void this.update_new_since(DateTime.fromISO((value.target as HTMLInputElement)?.value))
                            }}
                            className="field"
                            type="date"
                        />
                    </div>

                </div>

                <div className={'btn-group'}>
                    {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
                        <button
                            className={
                                this.search_text.includes(`startswith:${letter}`)
                                    ? 'btn btn-primary'
                                    : 'btn btn-default  .alphabet'
                            }
                            type={'button'}
                            onclick={() => {
                                this.selected_product_artkeys = []
                                this.search_bar_controller?.set_and_submit_search_text(`startswith:${letter}`)
                            }}
                        >
                            {letter}
                        </button>
                    ))}
                </div>

                <CollectionTable<GetProductsListResponse, void>
                    collection_fetcher={this.products_fetcher}
                    on_row_click={(row: GetProductsResponse) => m.route.set(`/data/products/${row.artkey}`)}
                >
                    <CollectionTableColumn<GetProductsListResponse>
                        header_title={() => {
                            return [
                                <input
                                    type={'checkbox'}
                                    id={'select_all'}
                                    onchange={() => this.toggle_select_all()}
                                    checked={
                                        this.products_fetcher.fetched_rows.length !== 0 &&
                                        this.products_fetcher.fetched_rows.length ===
                                            this.selected_product_artkeys.length
                                    }
                                />,
                                <label className="select-all" for={'select_all'} />,
                            ]
                        }}
                        td_class_name="size-1"
                        disable_click_handler={true}
                        data_field={(row: GetProductsListResponse) => (
                            <span onclick={(event: Event) => event.stopPropagation()}>
                                <input
                                    type={'checkbox'}
                                    id={row.artkey}
                                    onchange={() => {
                                        this.selected_product_artkeys.includes(row.artkey)
                                            ? (this.selected_product_artkeys = this.selected_product_artkeys.filter(
                                                (artkey) => artkey !== row.artkey,
                                            ))
                                            : this.selected_product_artkeys.push(row.artkey)
                                    }}
                                    checked={this.selected_product_artkeys.includes(row.artkey)}
                                />
                                <label className="select-all" for={row.artkey} />
                            </span>
                        )}
                    />
                    <CollectionTableColumn<GetProductsListResponse>
                        td_class_name="size-6"
                        header_title={() => 'Product name'}
                        sort_name={'name'}
                        data_field={(row: GetProductsListResponse) => row.name}
                    />
                    <CollectionTableColumn<GetProductsListResponse>
                        td_class_name="size-1"
                        header_title={() => 'Country Code'}
                        sort_name={'country_code'}
                        data_field={(row: GetProductsListResponse) =>
                            <Country country_code={row.default_country_code} type={'flag_with_country'}/>
                        }
                    />
                    <CollectionTableColumn<GetProductsListResponse>
                        td_class_name="size-2"
                        header_title={() => 'Bottles'}
                        data_field={(row: GetProductsListResponse) => row.number_of_bottles}
                    />
                    <CollectionTableColumn<GetProductsListResponse>
                        header_title={() => 'Brand'}
                        data_field={(row: GetProductsListResponse) => row.brand?.name}
                    />
                </CollectionTable>
            </div>
        )
    }
}
