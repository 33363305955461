import {Observable, of, Subject} from 'rxjs'
import jwt_decode from 'jwt-decode'

import {Api} from './api'

/**
 * Caches the User data for usage in drop down, search auto complete elements etc. This singleton
 * is available during the browser lifespan of the application. It waits on the
 * UserDropDownDataResponse[]. This value is [] if the data is not yet retrieved.
 */
interface GetUserRequest {
    artkey: number
}

interface Token {
    user_artkey: number
    name: string
}

export interface User {
    artkey: number
    name: string
    is_superuser: boolean
    decimal_local: string
}

/**
 * Retrieves the user information from the backend. The input user artkey is taken from the token and sent to
 * the backend.
 *
 * Usage:
 *         LoggedInUserData.user().subscribe({
 *             next: (value) => {
 *                 this.user = value
 *             },
 *         })
 *
 *         LoggedInUserData.instance().user_artkey
 *         ...
 *
 * and in the view:
 *         { if user?.is_superuser && ...}
 */
export class LoggedInUserData {
    public static instance(): LoggedInUserData {
        if (LoggedInUserData._instance === null) {
            LoggedInUserData._instance = new LoggedInUserData()
        }

        return LoggedInUserData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static user(): Observable<User | null> {
        if (LoggedInUserData.instance().drop_down_data$.isStopped) {
            return of(LoggedInUserData.instance().result)
        }

        return LoggedInUserData.instance().drop_down_data$
    }

    /** Singleton instance of this class. */
    private static _instance: LoggedInUserData | null = null
    api = new Api()

    /** Cached results, when available */
    private result: User | null = null
    user_artkey = -1
    name = ''

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<User> = new Subject<User>()

    private constructor() {
        const token = localStorage.getItem('token')
        if (!token) {
            return
        }
        try {
            const decoded_token: Token = jwt_decode(token)
            this.user_artkey = decoded_token.user_artkey
            this.name = decoded_token.name
            this.api
                .post_request<GetUserRequest, User>('users.get_user', {
                    artkey: decoded_token.user_artkey,
                })
                .subscribe({
                    next: (response: User) => {
                        this.drop_down_data$.next(response)
                        if (response) {
                            this.result = response
                        }
                    },
                    error: (v) => this.drop_down_data$.error(v),
                    complete: () => this.drop_down_data$.complete(),
                })
        } catch {
            // if anything fails, just return and leave this.
            return
        }
    }

}
