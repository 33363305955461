m = require 'mithril'
{ filter, map } = require 'prelude-ls'

class Color
    (red, green, blue)->
        @background = "rgba(#{red}, #{green}, #{blue}, 0.2)"
        @border = "rgba(#{red}, #{green}, #{blue}, 1)"


export class GraphDataManager
    ->
        # initialize empty graph_data, with no datasets. Datasets is a list of dataset objects. A dataset is:
        # - an object with at least a record property, representing the case/product this data set is for. Record contains an artkey.
        @colors_given_away = {}
        @available_colors = [
            new Color(255, 99, 132)
            new Color(54, 162, 235),
            new Color(255, 206, 86),
            new Color(75, 192, 192),
            new Color(153, 102, 255),
            new Color(239, 79, 117),
            new Color(255, 114 ,166),
            new Color(128, 71, 80),
            new Color(215, 105, 82),
            new Color(255, 196, 56),
            new Color(104, 114, 79),
            new Color(141, 236, 120),
            new Color(2, 181, 160),
            new Color(1, 128, 181),
            new Color(133, 105, 128),
            new Color(118, 122, 121),
        ]
        @graph_data = do
            datasets: []


    add_dataset: (dataset) ~>
        @graph_data.datasets.push(dataset)

    has_dataset_for_case_artkey: (case_artkey) ~>
        return case_artkey in (@graph_data.datasets |> map (dataset) -> dataset.record.artkey)

    remove_dataset_for_case_artkey: (case_artkey) ~>
        @graph_data.datasets = @graph_data.datasets |> filter (dataset) -> dataset.record.artkey != case_artkey
        @available_colors.push(@colors_given_away[case_artkey])
        delete @colors_given_away[case_artkey]

    case_artkeys_in_data: ~> @graph_data.datasets |> map (dataset) -> dataset.record.artkey

    data: ~> @graph_data

    number_of_datasets: ~> @graph_data.datasets.length

    fetch_available_color_for_graph: (case_artkey) ~>
        # Pick a predefined color for the graph or generate a black color if nothing left.
        color = if @available_colors.length > 0 then @available_colors[0] else new Color(12, 27, 29)
        @available_colors.shift!
        @colors_given_away[case_artkey] = color
        return color
