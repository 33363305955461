import {ModelsCommon} from '@bitstillery/common/models'

import * as accounts from './accounts'
import * as bottles from './bottles'
import * as common from './common'
import * as data from './data'
import * as identity from './identity'
import * as offers from './offers'
import * as pricelists from './pricelists'
import * as products from './products'
import * as purchase_orders from './purchase_orders'
import * as suppliers from './suppliers'
import * as users from './users'

export interface ModelsDiscover extends ModelsCommon {
    accounts: typeof accounts
    bottles: typeof bottles
    common: typeof common
    data: typeof data
    identity: typeof identity
    offers: typeof offers
    pricelists: typeof pricelists
    products: typeof products
    purchase_orders: typeof purchase_orders
    suppliers: typeof suppliers
    users: typeof users
}

export const models = {
    accounts,
    bottles,
    common,
    data,
    identity,
    offers,
    pricelists,
    products,
    purchase_orders,
    suppliers,
    users,
}
