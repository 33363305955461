m = require 'mithril'
{icon} = require '@/components/icon.ls'
{copy} = require '../utils.ls'


export link = (url, content, options={}) ->
    args = copy <| options with do
        href: url

    m m.route.Link, args, content


export blank-link = (url, content, options={}) ->
    args = copy <| options with do
        href: "#{url}"
        target: '_blank'

    m m.route.Link, args, content


export icon-link = (url, icon-id, options={}) ->
    args = copy <| options with do
        href: url

    m m.route.Link, args, (icon icon-id)


export blank-icon-link = (url, icon-id, options={}) ->
    args = copy <| options with do
        href: "#{url}"
        target: '_blank'

    m m.route.Link, args, (icon icon-id)
