import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'

export interface ButtonProps {
    icon_class?: string
    title?: JSX.Element | string
    tooltip?: string
    disabled?: boolean
    onclick?: (event: Event) => void
    type?: string
    additional_class?: string
}

function button(
    attrs: ButtonProps,
    children: m.Children,
    className = 'btn btn-default no-click',
): m.Vnode<ButtonProps> {
    return (
        <button
            disabled={attrs?.disabled}
            type={attrs?.type || 'button'}
            onclick={attrs.onclick}
            className={className}
            title={attrs?.tooltip}
        >
            {attrs.icon_class !== undefined && <span class={attrs.icon_class} />}
            {attrs.title || children}
        </button>
    )
}

/**
 * Default button with an optional icon.
 */
export class DefaultButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        let class_name
        if (vnode.attrs.additional_class && vnode.attrs.additional_class.includes('btn-')) {
            class_name = 'btn no-click'
        } else {
            class_name = 'btn btn-default no-click'
        }
        if (vnode.attrs.additional_class) {
            class_name = `${class_name} ${vnode.attrs.additional_class}`
        }
        return button(vnode.attrs, vnode.children, class_name)
    }
}

/**
 * Save button with an optional icon.
 */
export class SaveButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        vnode.attrs.title = vnode.attrs?.title || ' Save'
        return button(vnode.attrs, vnode.children, 'btn btn-default btn-success no-click')
    }
}

/**
 * Warning button with an optional icon.
 */
export class WarningButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        let class_name = 'btn btn-warning no-click'
        if (vnode.attrs.additional_class) {
            class_name = `${class_name} ${vnode.attrs.additional_class}`
        }
        return button(vnode.attrs, vnode.children, class_name)
    }
}

export class InfoButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        return button(vnode.attrs, vnode.children, 'btn btn-info no-click')
    }
}

export class PrimaryButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        return button(vnode.attrs, vnode.children, 'btn btn-primary no-click')
    }
}

export class SuccessButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        return button(vnode.attrs, vnode.children, classes('btn btn-success no-click', vnode.attrs.className))
    }
}

/**
 * Danger button with an optional icon.
 */
export class DangerButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        return button(vnode.attrs, vnode.children, 'btn btn-danger no-click')
    }
}

/**
 * Cancel button.
 */
export class CancelButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        vnode.attrs.icon_class = vnode.attrs.icon_class || 'glyphicon glyphicon-ban-circle'
        return button(vnode.attrs, vnode.children, 'btn btn-info no-click')
    }
}

interface OnOffButtonProps extends ButtonProps {
    is_on: () => boolean
}

export class OnOffButton extends MithrilTsxComponent<OnOffButtonProps> {
    view(vnode: m.Vnode<OnOffButtonProps>): m.Children {
        if (vnode.attrs.is_on()) {
            return <PrimaryButton {...vnode.attrs}>{vnode.children}</PrimaryButton>
        }
        return <DefaultButton {...vnode.attrs}>{vnode.children}</DefaultButton>
    }
}

export interface LinkButtonProps extends ButtonProps {
    href: string
}

/**
 * Displays a button (as a button, not as text as in LinkButton) that links to href on click.
 */
export class ButtonWithLink extends MithrilTsxComponent<LinkButtonProps> {
    view(vnode: m.Vnode<LinkButtonProps>): m.Children {
        let class_name = 'btn btn-default no-click'
        if (vnode.attrs.additional_class) {
            class_name = `${class_name} ${vnode.attrs.additional_class}`
        }
        const default_button = button(vnode.attrs, vnode.children, class_name)
        if (vnode.attrs.href) {
            default_button.attrs.onclick = () => {
                m.route.set(vnode.attrs.href)
            }
        }
        return default_button
    }
}
/**
 * Button that is displayed as a link.
 */
export class LinkButton extends MithrilTsxComponent<ButtonProps> {
    view(vnode: m.Vnode<ButtonProps>): m.Children {
        let class_name = 'btn btn-link no-click'
        if (vnode.attrs.additional_class) {
            class_name = `${class_name} ${vnode.attrs.additional_class}`
        }
        return button(vnode.attrs, vnode.children, class_name)
    }
}

// ButtonProps plus make the additional_class field required.
type ClasslessButtonProps = ButtonProps & Pick<Required<ButtonProps>, 'additional_class'>

/**
 * Button without a specific style class.
 */
export class ClasslessButton extends MithrilTsxComponent<ClasslessButtonProps> {
    view(vnode: m.Vnode<ClasslessButtonProps>): m.Children {
        let class_name = 'btn no-click'
        if (vnode.attrs.additional_class) {
            class_name = `${class_name} ${vnode.attrs.additional_class}`
        }
        return button(vnode.attrs, vnode.children, class_name)
    }
}
