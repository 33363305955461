import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {formatDate} from 'utils.ls'
import {Amount} from '@bitstillery/common/components'

import {$m, $s} from '@/app'
import {OfferApi} from '@/factserver_api/offer_api'
import {DefaultButton, SaveButton} from '@/components/buttons'
import {NumberInput} from '@/components/input_numbers'
import {MoneyInput} from '@/components/decimal_input'
import {BottleMarket} from '@/components/market_info/bottle_market'
import {BottlePurchaseOrders} from '@/components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'

interface PurchaseOrderItem {
    artkey: number
    number_of_cases: number
    bottle_artkey: number
    bottle_volume: number
    bottle_alcohol_percentage: number
    bottle_refill_status: number
    case_artkey: number
    case_customs_status: string
    case_gift_box_type: string
    case_number_of_bottles: string
    case_tax_label: string
    case_best_before_date: Date
    case_item_tags: number[]
    product_name: string
    was_bought_for: number
    was_bought_in: string
    bought_against_rate: number
}

interface AddPoiToOfferAttrs {
    purchase_order_item: PurchaseOrderItem
    offer_artkey: number
    offer_title: string
    preview_supplier_artkey?: number
    done: () => void
    cancel: () => void
}

export class AddPoiToOffer extends MithrilTsxComponent<AddPoiToOfferAttrs> {
    purchase_order_item: PurchaseOrderItem
    offer_artkey: number
    offer_title: string
    preview_supplier_artkey?: number
    done: () => void
    cancel: () => void

    offer_api = new OfferApi()

    sales_price_per_case: number | null = null
    maximum_quantity: number | null = null
    delivery_period: number | null = null
    minimum_quantity: number | null = null
    saving = false

    constructor(vnode: m.Vnode<AddPoiToOfferAttrs>) {
        super()

        this.purchase_order_item = vnode.attrs.purchase_order_item
        this.offer_artkey = vnode.attrs.offer_artkey
        this.offer_title = vnode.attrs.offer_title
        this.done = vnode.attrs.done
        this.cancel = vnode.attrs.cancel

        if (vnode.attrs.preview_supplier_artkey) {
            this.preview_supplier_artkey = vnode.attrs.preview_supplier_artkey
        }

        this.maximum_quantity = this.purchase_order_item.number_of_cases
    }

    /**
     * Construct a string representation of the purchase order item"s case.
     *
     * @return {string}: The string representation of the case.
     */
    case_text(): string {
        let case_text = `${this.purchase_order_item.product_name}`
        case_text += ` - ${this.purchase_order_item.case_number_of_bottles} / ${this.purchase_order_item.bottle_volume}`
        case_text += ` / ${this.purchase_order_item.bottle_alcohol_percentage} / ${this.purchase_order_item.bottle_refill_status}`

        if (this.purchase_order_item.case_gift_box_type) {
            case_text += ` / ${this.purchase_order_item.case_gift_box_type}`
        }

        case_text += ` - ${this.purchase_order_item.case_customs_status}`

        if (this.purchase_order_item.case_tax_label) {
            case_text += ` - ${this.purchase_order_item.case_tax_label}`
        }

        if (this.purchase_order_item.case_best_before_date) {
            case_text += ` - ${formatDate(this.purchase_order_item.case_best_before_date)}`
        }

        if (this.purchase_order_item.case_item_tags && this.purchase_order_item.case_item_tags.length) {
            const item_tags_list: string[] = $m.data.item_tag.get_tag_names(this.purchase_order_item.case_item_tags)
            case_text += ` - ${item_tags_list.join(' / ')}`
        }

        return case_text
    }

    /**
     * Check if the form values are valid for submission.
     *
     * @return {boolean}: true for a valid form, false otherwise.
     */
    is_valid(): boolean {
        if (this.sales_price_per_case === null || this.maximum_quantity === null || this.delivery_period === null) {
            return false
        }
        return this.sales_price_per_case > 0 && this.maximum_quantity > 0 && this.delivery_period > 0
    }

    /**
     * Create a custom offer item from the purchase order item and the form data.
     * Called when submitting the form.
     */
    create_custom_offer_item(): void {
        if (!this.is_valid()) {
            return
        }

        this.saving = true

        this.offer_api
            .create_custom_offer_item_from_poi({
                purchase_order_item_artkey: this.purchase_order_item.artkey,
                offer_artkey: this.offer_artkey,
                price_per_case: this.sales_price_per_case as number,
                delivery_period: this.delivery_period as number,
                quantity: this.maximum_quantity as number,
                minimum_quantity: this.minimum_quantity,
            })
            .subscribe({
                next: () => {
                    this.done()
                },
                complete: () => {
                    this.saving = false
                    m.redraw()
                },
            })
    }

    /**
     * Use a color to show how good the margin is. 9.5% is 2021"s target.
     *
     * Note that many similar functions exist in Discover. Along with this
     * function, these are refactored with DNG-862.
     *
     * @param {number} margin_percentage: The calculated margin percentage.
     *
     * @return {string}: css class for the color to show the margin in.
     */
    margin_color_class(margin_percentage: number): string {
        if (margin_percentage >= 9.5) {
            return 'analysis-good-color'
        } else if (margin_percentage < 0) {
            return 'analysis-bad-color'
        } else {
            return ''
        }
    }

    view(): m.Children {
        const purchase_price: number = convert_from_currency_to_euro(
            this.purchase_order_item.was_bought_for,
            this.purchase_order_item.was_bought_in,
        )
        const margin_percentage = this.sales_price_per_case
            ? (this.sales_price_per_case / purchase_price - 1) * 100
            : null

        return (
            <form className={'form-horizontal'} onsubmit={() => this.create_custom_offer_item()}>
                <div className={'row'}>
                    <label className={'col-md-4 control-label'}>Offer to add to</label>
                    <div className={'col-md-8'}>
                        <label className={'control-label'}>{this.offer_title}</label>
                    </div>
                </div>

                <div className={'row'}>
                    <label className={'col-md-4 control-label'}>Case to add</label>
                    <div className={'col-md-8'}>
                        <label className={'control-label'}>{this.case_text()}</label>
                    </div>
                </div>

                <div className={'row'}>
                    <label className={'col-md-4 control-label'}>Quantity in purchase order item</label>
                    <div className={'col-md-8'}>
                        <label className={'control-label'}>{this.purchase_order_item.number_of_cases}</label>
                    </div>
                </div>

                <div className={'row'}>
                    <label className={'col-md-4 control-label'}>Purchase price per case</label>
                    <div className={'col-md-8'}>
                        <label className={'control-label'}>
                            <Amount
                                currency={this.purchase_order_item.was_bought_in}
                                amount={this.purchase_order_item.was_bought_for}
                                display_currency={$s.currencies.default}
                                rate={this.purchase_order_item.bought_against_rate}
                            />
                        </label>
                    </div>
                </div>

                <hr />

                <div className="field">
                    <label className={'col-md-4 control-label'}>Delivery period (weeks)</label>
                    <div className={'col-md-8'}>
                        <NumberInput
                            minimum={1}
                            required={true}
                            value={this.delivery_period}
                            oninput={(value: number | null) => (this.delivery_period = value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className={'col-md-4 control-label'}>Quantity</label>
                    <div className={'col-md-8'}>
                        <NumberInput
                            minimum={1}
                            required={true}
                            value={this.maximum_quantity}
                            oninput={(value: number | null) => (this.maximum_quantity = value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className={'col-md-4 control-label'}>MOQ</label>
                    <div className={'col-md-8'}>
                        <NumberInput
                            minimum={1}
                            value={this.minimum_quantity}
                            oninput={(value: number | null) => (this.minimum_quantity = value)}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className={'col-md-4 control-label'}>Sales price</label>
                    <div className={'col-md-8'}>
                        <MoneyInput
                            required={true}
                            value={this.sales_price_per_case}
                            on_value={(value: number | null) => (this.sales_price_per_case = value)}
                            currency={$s.currencies.default}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className={'col-md-4 control-label'}>Margin percentage</label>
                    <div className={'col-md-8'}>
                        <label className={'control-label'}>
                            {margin_percentage && (
                                <span className={this.margin_color_class(margin_percentage)}>
                                    {margin_percentage.toFixed(2)}%
                                </span>
                            )}
                            {!margin_percentage && <span>--,--%</span>}
                        </label>
                    </div>
                </div>

                <div className="field">
                    <div className={'col-md-offset-4 col-md-8'}>
                        <div className={'btn-toolbar'}>
                            <SaveButton title="Add to offer" disabled={!this.is_valid() || this.saving} type="submit" />
                            <DefaultButton title="Cancel" onclick={() => this.cancel()} />
                        </div>
                    </div>
                </div>

                <hr />

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <BottleMarket
                            bottle_artkey={this.purchase_order_item.bottle_artkey}
                            case_artkey={this.purchase_order_item.case_artkey}
                            customs_status={this.purchase_order_item.case_customs_status}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <BottlePurchaseOrders
                            bottle_artkey={this.purchase_order_item.bottle_artkey}
                            case_artkey={this.purchase_order_item.case_artkey}
                            customs_status={this.purchase_order_item.case_customs_status}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <BottleSalesOrders
                            bottle_artkey={this.purchase_order_item.bottle_artkey}
                            case_artkey={this.purchase_order_item.case_artkey}
                        />
                    </div>
                </div>

                {this.preview_supplier_artkey && (
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <BottleSalesOrders
                                bottle_artkey={this.purchase_order_item.bottle_artkey}
                                supplier_artkey={this.preview_supplier_artkey}
                            />
                        </div>
                    </div>
                )}
            </form>
        )
    }
}
