import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

import {Api, create_download_for_blob, FactserverEmptyResponse, FactserverRequestData} from './api'

export interface CreateCustomOfferItemRequest extends FactserverRequestData {
    purchase_order_item_artkey: number
    offer_artkey: number
    price_per_case: number
    delivery_period: number
    quantity: number
    minimum_quantity: number | null
}

export interface GetOfferHistoryRequest extends FactserverRequestData {
    bottle_artkey: number
    customs_status?: string
    supplier_artkey?: number
    limit?: number
}

export interface GetOfferHistoryResponse {
    count: number
    offer_history_items: Array<OfferHistoryItem>
}

export interface OfferHistoryItem {
    artkey: number
    offer_artkey: number
    offer_title: string
    offer_start_date: string
    offer_end_date: string
    case_number_of_bottles: number
    case_customs_status: string
    case_gift_box_type: string
    case_tax_label: string
    euro_price_per_case: number
    product_name?: string
    bottle_volume?: number
    bottle_alcohol_percentage?: number
    bottle_refill_status?: string
}

export interface GetRelationOfferItemsRequest {
    supplier_artkey: string
    search_terms: string[]
    sort_order: []
    sort_by: string
    ascending: boolean
    hide_suppliers: number[]
    show_categories: number[]
    is_special: boolean
    only_show_type: string[] // stock, tbo, purchase
    hidden_selection?: string // hidden_only
    customs_status_selection: string // T1, T2, both
}

export interface GetRelationOfferItemsResponse {
    artkey: number
    product: string
    offer_item_type: string
    delivery_period: string | null
    bottle_artkey: number
    bottle_volume: string
    bottle_alcohol_percentage: string
    bottle_refill_status: string
    bottle_excise_nl: string
    case_artkey: number
    case_article_code: string
    case_gift_box_type: string
    case_number_of_bottles: number
    case_excise_nl: string
    currency: string
    price_per_case: string
    list_price: string
    supplier_list_price: string
    supplier_excise_per_case: string
    supplier_validity_date: string
    case_tax_label: string
    item_best_before_date: string
    case_customs_status: string
    // item_damages
    // item_general_tags
    // item_pack_size
    // item_packaging
    item_tags_sorted_artkeys: string
    product_category: string
    product_name: string
    list_quantity: number
    minimum_quantity: number | null
    maximum_quantity: number | null
    // entry_date
    stock_age: number
    // newest_stock_age
    // last_sale_date
    avg_purchase_price: string
    number_of_cases_available: number
    number_of_cases_in_purchase: number
    number_of_cases_in_stock: number
    number_of_cases_in_sales: number
    number_of_shipped_cases: number
    euro_total_stock_value: string
    bottle_gtin_codes: string[]
    case_gtin_codes: string[]
    // euro_total_available_value
    item_throughput: number
    supplier_artkey: number | null
    supplier_name: string | null
    market_rank: number
    market_total: number
    market_score: number
    previous_price: number | null
    price_up: boolean
    price_down: boolean
    // is_hidden
    has_product_photo: boolean
}

interface GetCustomTboOfferItemsByCaseRequest {
    case_artkey: number
}

export interface GetCustomTboOfferItemsByCaseOfferItem {
    artkey: number
    offer: {
        artkey: number
        incoterm: string
        incoterm_location: string
        start_date: string
    }
    supplier: {
        artkey: number
        name: string
        company_type: string
    }
    name: string
    volume: number
    alcohol_percentage: number
    refill_status: string
    number_of_bottles: number
    customs_status: string
    gift_box_type: string
    tax_label: string
    best_before_date: string
    item_tags: string
    purchase_price_per_case: number
    purchase_currency: string
    quantity: number
    case_artkey: number

    incoterm?: string
}

export class OfferApi {
    api = new Api()

    create_custom_offer_item_from_poi(request: CreateCustomOfferItemRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('offer.create_custom_offer_item_from_purchase_order_item', request)
    }

    get_offer_history_for_bottle(request: GetOfferHistoryRequest): Observable<GetOfferHistoryResponse> {
        return this.api.post_request('offer.get_offer_history_for_bottle', request)
    }

    export_preview_price_list(relation_artkey: number, offer_artkey: number, file_name: string): Observable<boolean> {
        return this.api.get(`discover/relations/${relation_artkey}/offer/${offer_artkey}/preview-price-list/excel`).pipe(
            map((response: Blob) => create_download_for_blob(response, file_name),
            ),
        )
    }

    get_custom_tbo_offer_items_by_case(
        request: GetCustomTboOfferItemsByCaseRequest,
    ): Observable<GetCustomTboOfferItemsByCaseOfferItem[]> {
        return this.api.post_request('offer.get_custom_tbo_offer_items_by_case', request)
    }
}
