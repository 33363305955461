api = require 'api.ls'


# Perform an API request to check the VAT ID.
# The first two parameters are supposed to be passed in from the caller, to be able to report the results.
export check_vat_id = (result_prop, loading_prop, valid_prop, country_code, vat_id, prod_mode=true) ->
    loading_prop window.prop true
    data = do
        vat_id: vat_id
        country_code: country_code
        prod_mode: prod_mode
    api.call-and-then 'vatid.check', data, do
        success: (resp) ->
            if result_prop
                result_prop resp
                result_prop!vat_id = vat_id
                result_prop!country_code = country_code
            if valid_prop
                valid_prop resp.valid
        final: ->
            loading_prop false
    return loading_prop
