m = require 'mithril'
{Supplier} = require '@/models/suppliers'
app = require('@/app')

{compact, map, group-by} = require 'prelude-ls'

export class BulkUpdateField
    @QUANTITY_UPDATES = 'quantity_updates'
    @PRICE_UPDATES = 'price_updates'
    @IS_SPECIAL_UPDATES = 'is_special_updates'

    @all = [@QUANTITY_UPDATES, @PRICE_UPDATES, @IS_SPECIAL_UPDATES]


export class OfferType
    @NORMAL = 'normal'
    @SPECIAL = 'special'
    @PRICELIST = 'pricelist'

    @all = do
        (@NORMAL): 'Normal offer'
        (@SPECIAL): 'Special offer'
        (@PRICELIST): 'Pricelist'

    # In offer type select inputs, only show normal and special.
    @offer_type_options = do
        (@NORMAL): 'Normal offer'
        (@SPECIAL): 'Special offer'


export class Priority
    @UNIQUE = 1
    @BULK = 2

    @all = do
        (@UNIQUE): 'Unique'
        (@BULK): 'Bulk'


export class Offer
    (json) ->
        @artkey = window.prop ''
        @title = window.prop ''
        @remark = window.prop ''
        @start_date = window.prop null
        @end_date = window.prop null
        @incoterm = window.prop ''
        @incoterm_location = window.prop ''
        @priority = window.prop 1
        @offer_type = window.prop 'special'
        @created_timestamp = window.prop null
        @published_timestamp = window.prop null
        @portal_popup_artkey = window.prop null
        @portal_popup = window.prop new PortalPopup
        @relation_filters = window.prop null

        for prop of json
            if prop == 'portal_popup'
                @portal_popup new PortalPopup json[prop]
                @portal_popup_artkey @portal_popup!artkey!
            else
                @[prop] = window.prop json[prop]

export class CustomOfferItem
    (json) ->
        # CustomOfferItem properties.
        @artkey = window.prop ''
        @offer_artkey = window.prop ''
        @supplier = window.prop new Supplier
        @supplier_artkey = window.prop ''
        @delivery_period = window.prop null
        @is_special = window.prop false
        @remark = window.prop ''
        @position = window.prop null
        @minimum_quantity = window.prop null
        @maximum_quantity = window.prop null
        @offer_item_artkey = window.prop null  # artkey of the pricelist item
        @offer_item_quantity = window.prop null  # quantity of the pricelist item
        @custom_quantity = window.prop null  # quantity to override with
        @quantity = window.prop null  # calculated quantity of the custom offer item
        @original_price = window.prop null  # price of the pricelist item
        @custom_price_per_case = window.prop null  # price per case to override with
        @price_per_case = window.prop null  # calculated price per case of the custom offer item
        @euro_price_per_case = window.prop null  # calculated price per case of the custom offer item in euros
        @currency = window.prop ''
        @purchase_price_per_case = window.prop null
        @purchase_currency = window.prop null

        # Product properties.
        @name = window.prop ''
        @category = window.prop ''

        # Bottle properties.
        @bottle_artkey = window.prop null
        @volume = window.prop null
        @alcohol_percentage = window.prop null
        @refill_status = window.prop 'ref'

        # Case properties.
        @case_artkey = window.prop null
        @number_of_bottles = window.prop null
        @gift_box_type = window.prop ''
        @tax_label = window.prop ''
        @customs_status = window.prop ''
        @best_before_date = window.prop null
        @item_tags = window.prop []
        @item_tags_sorted_artkeys = window.prop null

        for prop of json
            if prop == 'supplier'
                @supplier new Supplier json[prop]
                @supplier_artkey @supplier!artkey!
            else if prop == 'item_tags'
                @item_tags (json[prop] or [])
            else
                @[prop] = window.prop json[prop]

    item_tags_per_category: ->
        @item_tags!
        |> map app.$m.data.item_tag.get_item_tag
        |> compact
        |> group-by (.category_artkey!)

    price_per_bottle: ~>
        @price_per_case! / @number_of_bottles!

    euro_price_per_bottle: ~>
        @euro_price_per_case! / @number_of_bottles!


export class PortalPopup
    (json) ->
        @artkey = window.prop ''
        @title = window.prop ''
        @text = window.prop ''
        @image_s3_key = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]
