export class AgeInDays
    @WEEK = 'Last week'
    @MONTH = 'Last month'
    @YEAR = 'Last year'
    @ALL = 'All'

    @all = [@WEEK, @MONTH, @YEAR, @ALL]
    @choices = {}
    for status in @all
        @choices[status] = status

    @as_days = {}
    @as_days[@WEEK] = 7
    @as_days[@MONTH] = 30
    @as_days[@YEAR] = 360
    @as_days[@ALL] = 9999


export class AggregationLevel
    @DAY = 'day'
    @MONTH = 'month'

    @all = [@DAY, @MONTH]
    @choices = {}
    for status in @all
        @choices[status] = status

export class GraphFunction
    @CUMULATIVE = 'Cumulative'
    @ROLLING_AVERAGE = 'Rolling Average'
    @NUMBER_OF_CASES = "Aggregated per month"

    @all = [@CUMULATIVE, @ROLLING_AVERAGE, @NUMBER_OF_CASES]
    @choices = {}
    for status in @all
        @choices[status] = status


export class StatFunction
    @PREDICTED_STOCK = "Actual stock + prediction"
    @PREDICTED_SALES = "Actual sales + prediction (accumulated)"
    @ACTUAL_STOCK = "Actual stock"

    @all = [@PREDICTED_SALES, @PREDICTED_STOCK, @ACTUAL_STOCK]
    @choices = {}
    for status in @all
        @choices[status] = status
