m = require 'mithril'
{or-list, concat, map, sort-by, join, filter} = require 'prelude-ls'
api = require 'api.ls'
{create_download_for_blob} = require '@/factserver_api/api'
{should_include_excise} = require 'excise.ls'
{a, format-percentage, maybe-map, format-date, download_binary_file_from_base64_str, on-set, update, assoc}: utils = require 'utils.ls'
{Collection} = require '@/components/collection/collection.ls'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
download_files = require 'lib/download_files.ls'
inputs = require '@/components/inputs'
{Amount, SubAmount} = require '@bitstillery/common/components'
discover_link = require '@/market/components/discover_link'
explore_link = require '@/market/components/explore_link'
{BottleSalesOrders} = require '@/components/market_info/bottle_sales_orders'
{ItemSalesOrders} = require '@/components/market_info/item_sales_orders'
{CollectionTable} = require '@/components/collection_table.ls'
{icon-with-popover, icon} = require '@/components/icon.ls'
{to_specs} = require '@bitstillery/common/lib/specs'
{Popover} = require '@/components/popover/popover.ls'
{icon-button, button-with-icon, text-button} = require '@/components/buttons.ls'
{StockItemPhotoList} = require '@/stock/product_photos/stock_item_photo_list'
{QuickItemEditor} = require '@/stock/quick_item_editor'
{Button, ButtonGroup, Country, Icon, Link, Lot} = require '@bitstillery/common/components'
{account-icon-by-slug} = require 'accounts.ls'
{StockSearchMode} = require '@/models/stock'
app = require('@/app')

module.exports = class StockList
    ->
        @stock = new Collection do
            api_function_name: 'stock.get_stock_records'
            query_limit: 25
            sort_order: [
                {'name': 'product_name', 'direction': 'asc'},
                {'name': 'bottle_volume', 'direction': 'asc'},
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'},
                {'name': 'bottle_refill_status', 'direction': 'desc'},
                {'name': 'artkey', 'direction': 'asc'},
            ]
            filter_serverside: true
            default_sort_by: 'product_name'
            default_sort_order: 'asc'
            additional_params: @additional_params
            data_key: 'stock_items'

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

        if not @stock.mode
            @stock.mode = window.prop StockSearchMode.NOT_INVOICED

        @stock_statistics = window.prop {}

        available_only_param = m.route.param 'available_only'
        if available_only_param == 'false'
            @stock.mode StockSearchMode.ALL

        @query_stock_statistics!
        @show_only_without_image = window.prop false

    submit_search: (text) ~>
        @stock.update_search_term text
        @stock.submit_search!

    oncreate: ->
        query = m.route.param 'q'
        if query then
            @stock.update_search_term query.replace(/\+/g, ' ')
            @stock.submit_search!
        else
            @stock.init!

        app.$m.common.observable.subscribe 'stock_updated', @, ~>
            @stock.requery!
            @query_stock_statistics!

    additional_params: ~>
        return {mode: @stock.mode!, show_only_without_image: @show_only_without_image!, all_accounts: true}

    toggle_mode: (value) ~>
        @stock.mode value
        @stock.requery!

    query_stock_statistics: ~>
        api.call 'stock.get_statistics', {}, @set_statistics

    set_statistics: (resp) ~>
        result = resp.result
        result.available_value = (result.in_purchase_value + result.in_stock_value - result.in_sales_value).toFixed(2)
        @stock_statistics resp.result

    export_stock: ~>
        api.call 'stock.export_to_excel', do
            search_terms: @stock.search_terms!
            mode: @stock.mode!
            show_only_without_image: @show_only_without_image!
            all_accounts: true
        , @handle_export_result

    handle_export_result: (resp) ~>
        if resp.success
            utils.download_binary_file_from_base64_str resp.result,
              'Stock ' + format-date(new Date) + '.xlsx'
        else
            app.$m.common.generic_error_handler!

    download_batch: (item) ~>>
        response = await app.api.get "discover/items/#{item.artkey}/product-photos/download?include_internal=false"
        create_download_for_blob(response.result, "#{item.item_reference}-product-photos.zip")

    view: -> m '.c-stock-list view',
        m '.btn-toolbar' a do
            m Button, {
                icon: 'excel',
                onclick: @export_stock,
                text: app.$t('stock.actions.export_excel'),
                type: 'info',
                variant: 'context',
            }

            if @stock_statistics!
                m '.c-totals stats-group' a do
                    m Popover, do
                        content: 'Total value in purchase'
                        placement: 'bottom'
                    , m '.bs-callout.bs-callout-info',
                        (icon 'shopping-cart'),
                        ' €', (+@stock_statistics!in_purchase_value).format-money!

                    icon 'fa-plus'

                    m Popover, do
                        content: 'Total value in stock'
                        placement: 'bottom'
                    , m '.bs-callout.bs-callout-success',
                        (icon 'home'),
                        ' €', (+@stock_statistics!in_stock_value).format-money!

                    icon 'fa-minus'

                    m Popover, do
                        content: 'Total value in sales'
                        placement: 'bottom'
                    , m '.bs-callout.bs-callout-warning',
                        (icon 'screenshot'),
                        ' €', (+@stock_statistics!in_sales_value).format-money!

                    icon 'fa-equals'

                    m Popover, do
                        content: 'Total value available'
                        placement:  'bottom'
                    , m '.bs-callout.bs-callout-primary',
                        (icon 'fa-shield-alt'),
                        ' €', (+@stock_statistics!available_value).format-money!

        m '.c-filter-group' a do
            m SearchBar,
                placeholder: "Search for GTIN, products, specs, order reference, lot, warehouse or supplier..."
                on_submit: @submit_search
                default_search_text: @stock.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

            text-button 'Only without image',
                if @show_only_without_image!
                    class: 'btn-primary'
                    onclick: ~>
                        @show_only_without_image false
                        @stock.requery!
                else
                    class: 'btn-default'
                    onclick: ~>
                        @show_only_without_image true
                        @stock.requery!

            inputs.select @stock.mode,
                StockSearchMode.options, do
                    onchange: @toggle_mode
                    empty_option: false

        @stock.show_counter!

        m CollectionTable, do
            collection: @stock
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'stock_list'
            view_details: (record) ~>
                m '.columns',
                    m '.column',
                        m ItemSalesOrders, {item_artkey: record.artkey}
                        m BottleSalesOrders, {bottle_artkey: record.bottle_artkey}

                        if record.item_is_in_stock && !record.item_is_source_in
                            m QuickItemEditor, do
                                item: record
                                onchange: (update_data) ~>
                                    record.bottle_gtin_code = update_data.bottle_gtin_code
                                    record.case_gtin_code = update_data.case_gtin_code
                                    m.redraw!
                    m '.column',
                        m StockItemPhotoList, do
                            item_artkey: record.artkey
                            item_reference: record.item_reference
                            on_product_photo_count: (number_of_photos) ~>
                                record.item_number_of_photos = number_of_photos


            columns: ~> concat [
                [
                    do
                        width: 3
                        header: '#'
                        name: 'Purchase Order Reference'
                        sort: true
                        field: 'purchase_order_reference'
                        function: (record) -> [
                            m Link, {
                                account: record.account_slug,
                                href: "/purchase-orders/manage/#{record.purchase_order_artkey}",
                                target: '_blank'
                            }, m '.fl-c', [
                                account-icon-by-slug(record.account_slug, record.account_name),
                                record.purchase_order_reference
                            ]
                        ]
                    do
                        field: 'date'
                        width: 5
                        name: 'Warehouse'
                        sort: true
                        function: (row) ->
                            if row.item_entry_date
                                lot_date = utils.format-date row.item_entry_date
                                lot_type = 'default'
                            else if not row.sales_order_credit_item_artkey
                                lot_date = utils.format-date row.expected_delivery_date
                                lot_type = 'warning'

                            m Lot, {
                                lot_date: lot_date,
                                lot_number: row.item_lot,
                                mutation: {
                                    reference: row.item_mutation_reference,
                                    target_in: row.item_is_target_in,
                                },
                                type: lot_type,
                                warehouse_name: row.warehouse_name,
                            }
                    do
                        width: 15
                        name: 'Product'
                        sort: true
                        ellipsis: true
                        field: 'product_name'
                        function: (row) ->
                            m '.td-group', [
                                m 'span.header', row.product_name
                                m 'span',
                                    to_specs({
                                        bottle_alcohol_percentage: row.bottle_alcohol_percentage,
                                        bottle_refill_status: row.bottle_refill_status,
                                        bottle_volume: row.bottle_volume,
                                        case_number_of_bottles: row.case_number_of_bottles,
                                        case_gift_box_type: row.case_gift_box_type,
                                        case_customs_status: row.case_customs_status,
                                        case_tax_label: row.case_tax_label
                                    })

                                if row.bottle_gtin_code then m 'span' row.bottle_gtin_code
                            ]

                    do
                        width: 4
                        name: 'Category'
                        field: 'product_category_name'
                        sort: true
                        ellipsis: true
                        function: (record) ->
                            m 'span.text-capitalize' record.product_category_name
                    do
                        width: 3
                        name: 'Btl / cs'
                        sort: true
                        field: 'case_number_of_bottles'
                        classes: ['number']
                    do
                        width: 4
                        name: 'Size'
                        sort: true
                        field: 'bottle_volume'
                        function: (record) ->
                            m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                        classes: ['number']
                    do
                        width: 4
                        name: 'Alc %'
                        sort: true
                        field: 'bottle_alcohol_percentage'
                        function: (record) ->
                            m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                        classes: ['number']
                    do
                        width: 3
                        name: 'Ref'
                        sort: true
                        field: 'bottle_refill_status'
                    do
                        width: 4
                        name: 'GB'
                        sort: true
                        field: 'case_gift_box_type'
                        ellipsis: true
                    do
                        width: 4
                        name: 'Tax Label'
                        sort: true
                        field: 'case_tax_label'
                        ellipsis: true
                    do
                        width: 5
                        name: 'Excise NL'
                        field: 'bottle_excise_nl'
                        classes: ['price']
                        function: (record) -> [
                            m Amount, do
                                amount: record.bottle_excise_nl
                                currency: app.$s.currencies.default
                                display_currency: app.$s.currencies.default
                            m SubAmount,
                                label: 'Cs'
                                amount: record.case_excise_nl
                                currency: app.$s.currencies.default
                                display_currency: app.$s.currencies.default
                        ]
                    do
                        width: 6
                        name: 'Purchase / cs'
                        sort: true
                        field: 'purchase_order_item_was_bought_for'
                        classes: ['price']
                        function: (record) -> [
                            m Amount, do
                                amount: +record.item_was_bought_for
                                currency: record.purchase_order_was_bought_in
                                display_currency: app.$s.currencies.default
                                rate: record.purchase_order_bought_against_rate
                            m SubAmount, do
                                label: 'Costs'
                                amount: +record.item_was_bought_for_plus_costs
                                currency: record.purchase_order_was_bought_in
                                display_currency: app.$s.currencies.default
                                rate: record.purchase_order_bought_against_rate
                        ]
                    do
                        width: 5
                        name: 'Sales / cs'
                        sort: true
                        field: 'offer_item_list_price'
                        classes: ['price']
                        transform: (list-price) ->
                            if list-price then
                                m Amount, do
                                    amount: list-price
                                    currency: app.$s.currencies.default
                                    display_currency: app.$s.currencies.default
                            else
                                '-'
                    do
                        width: 1
                        header: icon-with-popover do
                            icon-id: 'calendar'
                            content: 'Stock age in days'
                        name: 'Stock age in days'
                        sort: true
                        field: 'stock_age'
                    do
                        width: 6
                        name: 'Stock value'
                        sort: true
                        field: 'item_total_stock_value'
                        classes: ['price']
                        function: (record) ->
                            m Amount, do
                                amount: +record.item_total_stock_value
                                currency: record.purchase_order_was_bought_in
                                display_currency: app.$s.currencies.default
                                rate: record.purchase_order_bought_against_rate
                    do
                        width: 2
                        name: 'Country of origin'
                        sort: false
                        field: 'item_country_of_origin'
                        function: (record) ->
                            m Country,
                                country_code: record.item_country_of_origin
                                type: 'flag_with_country'
                    do
                        width: 2
                        name: 'Number of cases in stock'
                        header: icon-with-popover do
                            icon-id: 'home'
                            content: 'Number of cases in stock'
                        sort: true
                        field: 'item_number_of_cases_in_stock'
                        classes: ['number']
                    do
                        width: 2
                        name: 'Number of cases in purchase'
                        header: icon-with-popover do
                            icon-id: 'shopping-cart'
                            content: 'Number of cases in purchase'
                        sort: true
                        field: 'item_number_of_cases_in_purchase'
                        classes: ['number']
                    do
                        width: 2
                        name: 'Number of cases in sales'
                        header: icon-with-popover do
                            icon-id: 'screenshot'
                            content: 'Number of cases in sales'
                        sort: true
                        field: 'item_number_of_cases_in_sales'
                        classes: ['number']
                    do
                        width: 2
                        name: 'Number of cases available'
                        header: icon-with-popover do
                            icon-id: 'fa-shield-alt'
                            content: 'Number of cases available'
                        sort: true
                        field: 'item_number_of_cases_available'
                        classes: ['number']
                    do
                        width: 2
                        name: 'Throughput'
                        header: icon-with-popover do
                            icon-id: 'flash'
                            content: 'Throughput'
                        field: 'item_throughput'
                        sort: true
                        sort_order: [{'name': 'date', 'direction': 'asc'}]
                        classes: ['number']
                        transform: maybe-map format-percentage
                    do
                        width: 3
                        name: 'Number of sales order (items)'
                        header: '#SO'
                        field: 'item_number_of_sales_order_items'
                        default_visible: false
                        sort: true
                        classes: ['number']
                    do
                        width: 3
                        name: 'Cus.'
                        sort: true
                        field: 'case_customs_status'
                    do
                        width: 4
                        name: 'Article code'
                        field: 'case_article_code'
                        default_visible: false
                    do
                        width: 4
                        name: 'Bottle GTIN'
                        field: 'bottle_gtin_code'
                        sort: true
                        default_visible: false
                    do
                        width: 4
                        name: 'Case GTIN'
                        field: 'case_gtin_code'
                        sort: true
                        default_visible: false
                    do
                        width: 5
                        header: 'BBD'
                        name: 'Best before date'
                        sort: true
                        field: 'item_best_before_date'
                        transform: (value) -> if value then format-date value else ''
                        default_visible: false
                    do
                        width: 3
                        header: 'CPP'
                        name: 'Cases per pallet'
                        sort: true
                        field: 'item_cases_per_pallet'
                        default_visible: false
                    do
                        width: 5
                        header: 'Remark'
                        name: 'Remark'
                        sort: true
                        field: 'item_remark'
                        ellipsis: true
                        default_visible: false
                    do
                        width: 5
                        header: 'Weight (kg)'
                        name: 'Weight'
                        field: 'case_weight'
                        sort: true
                        default_visible: false
                        transform: (value) -> if value then (+value).toFixed(1) else ''
                    do
                        width: 5
                        header: 'Length (cm)'
                        name: 'Length'
                        field: 'case_length'
                        sort: true
                        default_visible: false
                    do
                        width: 5
                        header: 'Width (cm)'
                        name: 'Width'
                        field: 'case_width'
                        sort: true
                        default_visible: false
                    do
                        width: 5
                        header: 'Height (cm)'
                        name: 'Height'
                        field: 'case_height'
                        sort: true
                        default_visible: false
                    do
                        width: 8
                        header: 'Dimensions (l/w/h)'
                        name: 'Dimensions'
                        field: 'case_dimensions'
                        sort: false
                        default_visible: false
                ]

                app.$m.data.item_tag_category.all_categories_except_tax_label!
                |> sort-by (.name!)
                |> map (category) ->
                    width: 4
                    name: category.name!
                    field: "item_tag_category_#{category.name!}"
                    default_visible: false
                    function: (record) ->
                        (record.item_item_tags_per_category || [])
                        |> map (pair) -> pair.split ':'
                        |> filter ([cat, tag]) -> cat == category.name!
                        |> map ([cat, tag]) -> tag
                        |> join ', '

                [
                    do
                        classes: ['actions']
                        width: 2
                        header: ''
                        name: 'Go to item'
                        function: (record) ~>
                            m '.actions-group no-click',
                                m '.action-toggles',
                                    m Icon, {
                                        name: 'search',
                                        onclick: ~>
                                            window.open(discover_link.from_properties(record.product_name, record.bottle_volume, record.bottle_alcohol_percentage))
                                        tip: "Discover #{record.product_name}/#{record.bottle_volume}/#{record.bottle_alcohol_percentage}"
                                        type: 'info',
                                    }
                                    m Icon, {
                                        name: 'compass',
                                        onclick: ~>
                                            case_artkey = record.case_artkey
                                            supplier_artkey = record.supplier_artkey
                                            if supplier_artkey then
                                                url = "#!/market/explore/?case=#{case_artkey}&supplier=#{supplier_artkey}"
                                            else
                                                url = "#!/market/explore/?case=#{case_artkey}"
                                            window.open(url)
                                        tip: "Explore case / supplier"
                                        type: 'info',
                                    }

                                m ButtonGroup,
                                    m Button, {
                                        icon: 'wrench',
                                        onclick: ~> m.route.set("/stock/manage/#{record.item_reference}")
                                        tip: "Modify stock item"
                                    }
                                    record.item_number_of_photos > 0 && (
                                        m Button, {
                                            icon: 'image',
                                            onclick: ~> @download_batch record
                                            tip: "Download photos"
                                        }
                                    )
                ]
            ]
