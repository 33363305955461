import {events} from '@bitstillery/common/app'

import _models from './_products.ls'

import {$m} from '@/app'

export const {Product, ProductCategory, ProductsDataModel} = _models

export enum ProductBottleType {
    LIGHT_GLASS = 'Glass (light)',
    HEAVY_GLASS = 'Glass (heavy)',
    PET = 'PET',
    STONE = 'Stone',
    PREMIX_RTD_GLASS = 'Premix / RTD glass',
    PREMIX_RTD_CAN = 'Premix / RTD can',
    PREMIX_RTD_PET = 'Premix / RTD PET',
}

export function bind() {
    events.on('identity.login', async() => {
        $m.products = new ProductsDataModel()
    })
}
