/**
 * For type narrowing in a filter function. Maps from Observable<T | null | undefined> => Observable<T>
 *
 * Usage
 *  filter(is_not_null_or_undefined_type_guard)
 */

export function is_not_null_or_undefined_type_guard<T extends unknown>(input: null | undefined | T): input is T {
    return input !== null
}
