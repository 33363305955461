m = require 'mithril'
{all, concat, join, or-list} = require 'prelude-ls'
{account-icon} = require 'accounts.ls'
{current_account_slug} = require '@bitstillery/common/account/account'
{RelationDropDownData} = require '@/factserver_api/relation_api'
api = require 'api.ls'
{button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'
confirmation = require '@/components/confirmation.ls'
{continents, countries} = require '@bitstillery/common/lib/countries'
inputs = require '@/components/inputs'
{languages} = require '@/components/languages.ls'
FilterSidebar = require '../components/relation_selection_sidebar.ls'
{Offer} = require '@/models/offers'
{ProcessManageOffer} = require './processes'
app = require('@/app')

module.exports = class OfferRelations
    ->
        # Offer details.
        @offer = window.prop new Offer
        @offer_artkey = m.route.param 'artkey'
        @query_offer @offer_artkey

        # Relation selection.
        @relation_list = []
        @initial_relation_list = []
        @auto_update = window.prop false
        @initial_auto_update = false

        @relations = new Collection do
            api_function_name: 'offer.get_relations_for_selection'
            filter_function: @is_match
            sort_order: [
                {'name': 'name', 'direction': 'asc'}
            ]
            default_sort_by: 'name'
            default_sort_order: 'asc'
            additional_params: @additional_params

        # Initialize relation filters with their default values.
        if typeof @relations.filters == 'undefined'
            @relations.filters = {
                'sales_account': window.prop [current_account_slug!]
                'buyers': window.prop 'true'
                # Preselect 'Client' and 'Prospect' so 'Low Potential' is left out by default.
                'client_status': window.prop ['Client', 'Prospect']
                'company_type': window.prop []
                'currency': window.prop []
                'customs_status_visibility': window.prop []
                'language': window.prop []
                'portal_level': window.prop []
                'price_list_frequency': window.prop []
                'purchase_manager': window.prop []
                'relation_filter': window.prop 'mine_sales'
                'sales_manager': window.prop []
                'suppliers': window.prop ''
                'active_sales_promotions': window.prop []
                'active_vouchers_artkeys': window.prop []
                'operates_online': window.prop ''
                'should_receive_offer_mails': window.prop 'true'
                'should_receive_purchase_mails': window.prop ''
            }

            # For the country filter, add separate filters per continent. These
            # are used for individual checkbox groups in the filter sidebar. In
            # additional_params, we join these separate filters together to
            # provide them as one parameter for the factserver.
            for continent_code in Object.keys continents
                @relations.filters["countries_per_continent_#{continent_code}"] = window.prop []

        @search_input_ctrl = new SearchInput.controller do
            collection: @relations
            placeholder: 'Search for relation, country, sales manager and status'
            autocomplete: true
            suggestions: []

        @saving = window.prop false

        @email_batch_artkey = m.route.param 'email_batch'

        # Determine the next step from this page. The default is select products, but depending on the
        # querystring parameter inserted by the offer template choice earlier, it can be customize products.
        @next_step_text = 'select products'
        @next_step_slug = 'select_products'
        if m.route.param 'skip_select_products'
            @next_step_text = 'customize products'
            @next_step_slug = 'custom_products'

    oncreate: ~>
        @relations.init!
        app.$m.common.observable.subscribe 'suppliers_updated', @, @relations.requery

        # By subscribing on the collection's query, we make sure that checking
        # the auto-update checkbox first:
        # 1 - updates the collection, and then
        # 2 - selects all relations via the callback.
        app.$m.common.observable.subscribe 'collection.offer.get_relations_for_selection.after-call', @, ~>
            if @auto_update!
                @toggle_all_relations true

        RelationDropDownData.names().subscribe((names) ~> @search_input_ctrl.set_suggestions(names))

    additional_params: ~>
        # Join the separate country filters per continent into one parameter
        # for the factserver.
        all_selected_countries = concat [@relations.filters["countries_per_continent_#{code}"]! for code in Object.keys continents]

        return do
            sales_account: @relations.filters['sales_account']!
            buyers: @relations.filters['buyers']!
            client_status: @relations.filters['client_status']!
            company_type: @relations.filters['company_type']!
            country: all_selected_countries
            currency: @relations.filters['currency']!
            customs_status_visibility: @relations.filters['customs_status_visibility']!
            language: @relations.filters['language']!
            portal_level: @relations.filters['portal_level']!
            price_list_frequency: @relations.filters['price_list_frequency']!
            purchase_manager: @relations.filters['purchase_manager']!
            relation_filter: @relations.filters['relation_filter']!
            sales_manager: @relations.filters['sales_manager']!
            suppliers: @relations.filters['suppliers']!
            active_sales_promotions: @relations.filters['active_sales_promotions']!
            active_vouchers_artkeys: @relations.filters['active_vouchers_artkeys']!
            operates_online: @relations.filters['operates_online']!
            should_receive_offer_mails: @relations.filters['should_receive_offer_mails']!
            should_receive_purchase_mails: @relations.filters['should_receive_purchase_mails']!

    check_relation: (supplier_artkey) ~>
        if supplier_artkey not in @relation_list
            @relation_list.push supplier_artkey
        else
            index = @relation_list.indexOf(supplier_artkey)
            if(index > -1)
                @relation_list.splice(index, 1)
        m.redraw!

    is_match: (relation, term) ~>
        return or-list [
            relation.name.toLowerCase!indexOf(term) > -1
            relation.sales_manager.profile.name.toLowerCase!indexOf(term) > -1
            (relation.company_type || '').toLowerCase!indexOf(term) > -1
            (relation.client_status || '').toLowerCase!indexOf(term) > -1
            (countries[relation.country_code] || '').toLowerCase!indexOf(term) > -1
        ]

    query_offer: (artkey) ~>
        api.call-and-then 'offer.get_offer' {artkey: artkey, include_suppliers: true}, do
            success: (resp) ~>
                try
                    @offer new Offer resp.result
                    for supplier in resp.result.suppliers
                        @relation_list.push supplier.artkey
                    # Save a copy of the selected relation artkeys.
                    @initial_relation_list = @relation_list.slice(0)

                    # Initialize the @auto_update checkbox.
                    if @offer!relation_filters!
                        @auto_update true
                        @initial_auto_update = true
                catch
                    app.$m.common.generic_error_handler e
            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    # Returns whether saveable changes have been made.
    selection_changed: ~>
        return not (@relation_list.sort! === @initial_relation_list.sort!) or (@auto_update! != @initial_auto_update)

    # Sorts the list of relations on basis of which relations are checked.
    sort_by_checked: ~>
        # Populate the collection with a 'checked' attribute.
        for item in @relations.items!
            item.checked = item.artkey in @relation_list
        # Tell the collection to sort by this new 'checked' attribute.
        @relations.sort_by 'checked'
        # Reverse the sort direction of the collection.
        @relations.ascending !@relations.ascending!
        # Execute the collection's filter_items function, which actually
        # performs the sorting.
        @relations.filter_items!

    toggle_all_relations: (value) ~>
        if value?
            if value
                # Check all relations in the search result. Note that we don't
                # clear the @relation_list, so that any previously checked
                # relations remain checked.
                for relation in @relations.search_result!
                    if relation.artkey not in @relation_list
                        @relation_list.push relation.artkey

            else
                # Uncheck all relations in the search result. Relations
                # outside the search result keep being checked in the
                # background.
                for relation in @relations.search_result!
                    index = @relation_list.indexOf(relation.artkey)
                    if index > -1
                        @relation_list.splice(index, 1)

        else
            # Check if the 'select all'-checkbox must be checked. If there is
            # no search result, or if (at least) one relation in the search
            # result is not checked, the checkbox must be unchecked.
            if @relations.search_result!length == 0 then return false
            for relation in @relations.search_result!
                if relation.artkey not in @relation_list
                    return false

            # Otherwise, the checkbox must be checked.
            return true

    # Called when the auto_update checkbox is clicked, and either enables or
    # disables auto-updating the relations for the offer.
    toggle_auto_update: (value) ~>
        if value?
            @auto_update value
            if value
                # Requery in case any filters have changed. A `subscribe` on the
                # requery's after-call then takes care of checking all relations.
                @relations.requery!
            else
                @toggle_all_relations false
        else
            @auto_update!

    # Determine the text of the save button. It depends on whether relations
    # have been (de-)selected and/or if relations have been saved already.
    save_button_text: ~>
        if @selection_changed!
            'Save relation selection'
        else if @relation_list.length == 0
            'No relations are selected yet'
        else
            'Relation selection is saved'

    # Determine the text of the proceed. It depends on whether the relation
    # selection has changed and if we skip to the customize products page.
    proceed_button_text: ~>
        if @selection_changed!
            "Save relation selection and proceed to #{@next_step_text}"
        else
            "Proceed to #{@next_step_text}"

    # Save the relation selection and optionally proceed to the select products page.
    #
    # @param {boolean} proceed: true to proceed to the select products page.
    save_selection: (proceed) ~>
        if not @saving!
            @saving true

            data = do
                artkey: @offer!artkey!
                suppliers: @relation_list

            if @auto_update!
                # Convert the dictionary of Mithril properties to a serialized
                # dictionary of filter values.
                serialized_filters = {}
                for parameter_name in Object.keys(@relations.filters)
                    serialized_filters[parameter_name] = @relations.filters[parameter_name]!

                data['relation_filters'] = serialized_filters

            api.call-and-then 'offer.select_relations', data, do
                success: (resp) ~>
                    app.$m.common.observable.broadcast 'offer_updated'
                    app.notifier.notify "Successfully selected relations for offer \"#{@offer!title!}\".", 'success'
                    if proceed
                        @redirect!
                    else
                        @offer new Offer resp.result
                        @saving false

                        # Reset the initial relation list and auto update values.
                        @initial_relation_list = @relation_list.slice(0)
                        @initial_auto_update = @auto_update!

                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
                    @saving false

    # This function is called after clicking one of the two buttons to save and optionally proceed
    # to the next step. If necessary, it shows a confimation modal before saving.
    #
    # @param {bool} proceed: true to proceed to the next step.
    select_relations: (proceed) ~>
        if @selection_changed!
            if @offer!published_timestamp! and not all (in @relation_list), @initial_relation_list
                confirmation.show do
                    title: 'Deselecting relations'
                    message: 'You have deselected relations in an already published offer. This can change prices for those relations. Are you sure you want to continue?'
                    unique_name: 'deselect_confirm'
                    onconfirm: ~> @save_selection proceed
            else
                @save_selection proceed
        else if proceed
            # The selection has not changed. No need to call the factserver, just redirect.
            @redirect!

    # Determine where to redirect to when clicking the proceed button.
    redirect: ~>
        redirect_url = "/offer/offers/#{@offer_artkey}/#{@next_step_slug}"
        if @email_batch_artkey
            redirect_url += "/?email_batch=#{@email_batch_artkey}"
        m.route.set redirect_url

    view: -> m '.c-process-new-offer-step-2 view process',
        m ProcessManageOffer, {
            active: 'relations',
            context: {
                email_batch_artkey: @email_batch_artkey,
                offer_artkey: @offer_artkey,
                offer_title: @offer!title!
            }
        }

        m '.step-content',
            m '.filter-sidebar-wrapper',
                if @offer!artkey!  # before loading the filter sidebar, the offer must be loaded first
                    m FilterSidebar, do
                        relations: @relations
                        offer_relation_filters: @offer!relation_filters!

                m '.filter-result',
                    m 'p' 'When an offer is published, the prices in this offer are updated in the Portal for the selected relations.'

                    m '.c-filter-group',
                        SearchInput.view @search_input_ctrl

                        button-with-icon @save_button_text!, 'fa-briefcase', do
                            class: if @selection_changed! then 'btn-success' else 'btn-normal'
                            disabled: @saving! or !@selection_changed!
                            onclick: ~> @select_relations false
                            title: 'This button saves any changes you made to the relation selection. If the offer is published, prices in this offer then apply to those relations. It also makes it possible to email the offer to these relations. It keeps you on this page.'

                        button-with-icon @proceed_button_text!, 'glyphicon-list', do
                            class: 'btn-success'
                            disabled: @saving!
                            onclick: ~> @select_relations true
                            title: "This button takes you to the next page where you can #{@next_step_text} for this offer. Any changes you made to the relation selection will be saved."

                    m '.c-filter-group',
                        inputs.checkbox @toggle_all_relations, {
                            disabled: @auto_update!,
                            label: 'Select all relations in the filter result'
                        }
                        inputs.checkbox @toggle_auto_update, {
                            label: 'Use current filters to auto-update the relation selection'
                        }

                    m CollectionTable, do
                        collection: @relations
                        options:
                            search_table_style: true
                            sticky_header: true
                            with_buttons: true
                            autoscale: true
                            unique_name: 'offer_relations'
                        columns:
                            do
                                width: 2
                                name: 'Select for offer'
                                field: 'checked'
                                sort_function: @sort_by_checked
                                default_visible: true
                                function: (record) ~>
                                    m '',
                                        m 'input' {
                                            type: 'checkbox'
                                            id: record.artkey
                                            onclick: ~>
                                                @check_relation record.artkey
                                            checked: record.artkey in @relation_list
                                            disabled: @auto_update!
                                        }
                                        m 'label' {
                                            for: record.artkey
                                        }
                            do
                                width: 8
                                name: 'Relation'
                                field: 'name'
                                sort: true
                                ellipsis: true
                                default_visible: true
                                function: (relation) -> [
                                    m 'span.mr-05' account-icon relation.sales_account
                                    relation.name
                                ]
                            do
                                width: 6
                                name: 'Location'
                                field: 'country_code'
                                sort: true
                                default_visible: true
                                function: (record) ~>
                                    m '',
                                        countries[record.country_code] + ', ' + record.city
                            do
                                width: 4
                                name: 'Languages'
                                field: 'contact_languages'
                                sort: true
                                default_visible: true
                                function: (record) ~>
                                    # Determine the set of languages of contacts.
                                    language_list = []
                                    for language in record.contact_languages
                                        if language
                                            language_list.push languages[language]
                                        else
                                            language_list.push '-'

                                    # Determine the text to display.
                                    if language_list
                                        join ', ' language_list.sort!
                                    else
                                        '-'
                            do
                                width: 4
                                name: 'Sales manager'
                                field: 'sales_manager.profile.name'
                                sort: true
                                default_visible: true
                            do
                                width: 2
                                name: 'Portal level'
                                field: 'portal_level'
                                sort: true
                                default_visible: true
                            do
                                width: 1
                                header: ''
                                name: 'Column selector'
                                value: ' '
