export interface SelectionEnabledComponent {
    start_selection: (selection_controller: SelectionController) => void
    end_selection: () => void
}

export interface SelectionEnabledComponentAttrs {
    register_selection_enabled_component: (selection_enabled_component: SelectionEnabledComponent) => void
}

/**
 * This class controls a selection of certain things (identifiable by a number, eq artkey) from a parent control.
 * The parent control instantiates a SelectionController that can control several selections.
 *
 * Usage:
 * In the parent control instantiate a selection controller with a set of keys:
 *
 *        this.selection_controller = new SelectionController(["soi", "tbo"])
 *
 * The children components are instances of SelectionEnabeledComponent. The first thing a child does is to register:
 *
 *       vnode.attrs.register_selection_enabled_component(this)  // method defined in SelectionEnabledComponentAttrs
 *
 * The child implements the methods start_selection and end_selection. These methods are called when the parent
 * starts the selection process by calling `this.selection_controller.start_selecting()`. The selected items are
 * available through methods as `length_for(key)` and `selected_items(key)`.
 */
export class SelectionController {
    selections: Map<string, number[]> = new Map<string, number[]>()
    selection_enabled_components: SelectionEnabledComponent[] = []

    constructor(keys: string[]) {
        keys.forEach((key: string) => this.selections.set(key, []))
    }

    /** Registers a component that is joined by this SelectionController. */
    register_selection_enabled_component(selection_enabled_component: SelectionEnabledComponent): void {
        this.selection_enabled_components.push(selection_enabled_component)
    }

    /** Signal the start of the select operation to the child components. */
    start_selecting(): void {
        this.selection_enabled_components.forEach((component) => component.start_selection(this))
    }

    /** Signal the end of the select operation to the child components. */
    end_selecting(): void {
        this.selection_enabled_components.forEach((component) => component.end_selection())
    }

    _list_for_key(key: string): number[] {
        const list = this.selections.get(key)
        if (!list) {
            throw new Error(
                `Programming error. Key '${key}' not found. Initialise this component with the key to make it usable.`,
            )
        }
        return list
    }

    /** Adds artkey to the current selection. */
    add_to_selection(key: string, artkey: number): void {
        if (!this.is_in_selection(key, artkey)) {
            this._list_for_key(key).push(artkey)
        }
    }

    /** Removes artkey from the current selection. */
    remove_from_selection(key: string, artkey: number): void {
        if (this.is_in_selection(key, artkey)) {
            this.selections.set(
                key,
                this._list_for_key(key).filter((it) => String(it) !== String(artkey)),
            )
        }
    }

    /** True if the artkey is in the current selection. */
    is_in_selection(key: string, artkey: number): boolean {
        return this._list_for_key(key).includes(artkey)
    }

    /** True if all the artkeys are in the current selection. */
    are_in_selection(key: string, artkeys: number[]): boolean {
        const list = this._list_for_key(key)
        return artkeys.length === list.length && artkeys.every((artkey) => this.is_in_selection(key, artkey))
    }

    /** Initialises the selected_items with an empty array, removing the current selection. */
    remove_all(key: string): void {
        this.selections.set(key, [])
    }

    /** Overwrites the current selected items with the artkeys argument. */
    set_all(key: string, artkeys: number[]): void {
        this.selections.set(key, artkeys)
    }

    /** Returns the number of selected items. */
    length_for(key: string): number {
        return this._list_for_key(key).length
    }

    /** Returns the selected items. */
    selected_items(key: string): number[] {
        return this._list_for_key(key)
    }
}
