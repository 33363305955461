import m from 'mithril'
import {BarCategories} from '@bitstillery/common/components'
import {Button, Dropdown} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$t} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'

import {$s} from '@/app'

function match_route(url: string, route: string): boolean {
    const {path} = m.parsePathname(route)
    return url.startsWith(path)
}

export class BarCategoriesDiscover extends BarCategories {

    last_route = null
    menu: any
    nav_level_1
    nav_level_0
    no_nav_bar_for = ['whatsnew', 'user']

    data = proxy({})

    onupdate(): void {
        let route = m.route.get()
        if (this.last_route === route) return

        if (route.indexOf('?') !== -1) {
            route = route.substr(0, route.indexOf('?'))
        }

        if (route.startsWith('/login')) {
            $s.navigation.selected[0] = null
            return
        }

        // Find index in the route. Either match /.../ or /...
        let index = /\/(.*?)\//.exec(route)
        if (index === null) {
            index = /\/([\w|-]*$)/.exec(route)
        }
        if (index === null || index.length < 2) {
            return
        }

        const level0_id = index[1]

        if (this.no_nav_bar_for.includes(level0_id)) {
            $s.navigation.selected[0] = null
            return
        }

        const level0_index = $s.navigation.menu.findIndex((i) => i.id === level0_id)

        $s.navigation.selected[0] = level0_index
        this.menu = $s.navigation.menu[$s.navigation.selected[0]]

        const menu_title = $t(`${this.menu.i18n}.text`)

        Object.assign($s.page, {
            icon: this.menu.icon,
            title: menu_title,
        })

        if (this.menu.items) {
            const level1_index = this.menu.items.findIndex((item) => {
                if (!item.items) return route.startsWith(item.url)

                const sub_item = item.items.find((i) => {
                    return route.startsWith(i.url)
                })
                return sub_item
            })

            if (level1_index >= 0) {
                $s.navigation.selected[1] = level1_index
                this.sub_menu = this.menu.items[level1_index]
                $s.page.subtitle = this.sub_menu.name
            }
        }

        this.last_route = route
    }

    slot() {
        const route = m.route.get()
        const menu = $s.navigation.menu[$s.navigation.selected[0]]
        if (!menu) return

        return menu.children.map((menu_item) => {
            if (menu_item.children && menu_item.children.length > 0) {
                if (!this.data[menu_item.i18n]) {
                    this.data[menu_item.i18n] = {
                        toggle: false,
                    }
                }

                return <Dropdown ref={this.data[menu_item.i18n]} trigger="hover" key={menu_item.i18n}>
                    <Button
                        className={classes('menu', {
                            'active-child': (
                                menu_item.children.some((i) => match_route(route, i.url))
                            ),
                        })}
                        icon="chevronDown"
                        text={$t(`${menu_item.i18n}.text`)}
                        tip={() => $t(`${menu_item.i18n}.tip`)}
                        type='brand'
                        variant='menu'
                    />

                    {(menu_item.children.length > 0) && <div class="dropdown-body">
                        {menu_item.children.map((submenu_item) =>
                            <div
                                className={classes('sub-menu', {
                                    active: match_route(route, submenu_item.url),
                                })}
                                onclick={() => {
                                    m.route.set(submenu_item.url)
                                    this.data[menu_item.i18n].toggle = false
                                }}
                            >
                                {$t(`${submenu_item.i18n}.text`)}
                            </div>,
                        )}
                    </div>}
                </Dropdown>
            } else {
                let {path, params} = m.parsePathname(menu_item.url)
                params.key = Date.now()
                const url_params = new URLSearchParams(params).toString()
                return <Button
                    active={match_route(route, menu_item.url)}
                    className="menu"
                    key={menu_item.i18n}
                    icon={menu_item.icon}
                    link={`${path}?${url_params}`}
                    text={$t(`${menu_item.i18n}.text`)}
                    tip={() => $t(`${menu_item.i18n}.tip`)}
                    type='unset'
                    variant='unset'
                />
            }
        })

    }
}
