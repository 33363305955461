m = require "mithril"
{and-list} = require 'prelude-ls'
{Account} = require '@/models/accounts'
{Delivery, InsuranceType, Warehouse} = require '@/models/data'
{Case, Item} = require '@/models/stock'
{OfferItem} = require '@/models/pricelists'
{User} = require '@/models/users'
{Supplier} = require '@/models/suppliers'
{convert_from_currency_to_euro, convert_from_source_to_target, convert_from_euro_to_currency} = require '@/factserver_api/currencies'
app = require('@/app')

export class ItemTypes
    # Used for the item dropdowns on the sales order view page,
    # which contains both (stock) items and purchase order items.
    @PURCHASE_ORDER_ITEM = 'purchase_order_item'
    @ITEM = 'item'


export class PortalOrderStatus
    @PENDING = 'Pending'
    @FINALIZED = 'Finalized'


export class SalesOrderStatus
    # TODO: Get from server.
    @SAVED = 'Saved'
    @CONFIRMED = 'Confirmed'
    @SAVED_OR_CONFIRMED = 'Saved or confirmed'
    @INVOICED = 'Invoiced'
    @CANCELLED = 'Cancelled'

    @all = [@SAVED, @CONFIRMED, @SAVED_OR_CONFIRMED, @INVOICED, @CANCELLED]
    @choices = {}
    for status in @all
        @choices[status] = status


export class AdditionalType
    @REVENUE = 'revenue'
    @DISCOUNT = 'discount'
    @VOUCHER = 'voucher'
    @TRANSPORT = 'transport'
    @WASTE_FUND = 'waste_fund'
    @PALLET = 'pallet'
    @OTHER = 'other'

    @CHOICES = {}
    @CHOICES[@REVENUE] = 'Revenue'
    @CHOICES[@DISCOUNT] = 'Discount'
    @CHOICES[@VOUCHER] = 'Voucher'
    @CHOICES[@TRANSPORT] = 'Transport'
    @CHOICES[@WASTE_FUND] = 'Waste fund (afvalfonds)'
    @CHOICES[@PALLET] = 'Pallet costs'
    @CHOICES[@OTHER] = 'Other'


export class DestinationType
    @WAREHOUSE = 'warehouse'
    @SEAPORT = 'seaport'
    @AIRPORT = 'airport'
    @OTHER = 'other'

export class RevenueType
    @DIRECT_SALES = 'direct sales'
    @TO_RESELLERS = 'to resellers'


export class SalesOrder
    (json) ->
        @artkey = window.prop ''
        @account = window.prop new Account
        @account_artkey = window.prop ''
        @supplier = window.prop new Supplier
        @supplier_artkey = window.prop ''
        @date = window.prop ''
        @created_on = window.prop ''
        @incoterm = window.prop ''
        @incoterm_location = window.prop ''
        @destination = window.prop new Warehouse
        @origin = window.prop new Warehouse
        @destination_location = window.prop ''
        @reference = window.prop ''
        @was_handled_by_artkey = window.prop ''
        @was_handled_by = window.prop new User
        @was_sold_in = window.prop ''
        @was_sold_for = window.prop ''
        @euro_was_sold_for = window.prop ''
        @euro_turnover = window.prop ''
        @sales_order_status = window.prop ''
        @sales_order_items = window.prop []
        @sales_order_additionals = window.prop []
        @sales_order_credit_items = window.prop []
        @sales_order_tbo_items = window.prop []
        @basket_items = window.prop []
        @sold_against_rate = window.prop ''
        @number_of_cases = window.prop ''
        @insurance_type_artkey = window.prop ''
        @insurance_type = window.prop new InsuranceType
        @was_sent_to_financial_information_system_on = window.prop ''
        @is_invoiced_on = window.prop ''
        @invoice_number = window.prop ''
        @remark = window.prop ''
        @includes_excise = window.prop false
        @requested_delivery_date = window.prop ''
        @warehouse_instruction = window.prop ''
        @freight_instruction = window.prop ''
        @delivery = window.prop new Delivery

        @euro_purchasing_value = window.prop ''
        @margin = window.prop ''
        @margin_percentage = window.prop ''

        @is_complete = window.prop false
        @from_portal = window.prop false
        @portal_status = window.prop ''
        @combined_status = window.prop ''

        @has_attachments = window.prop false
        @estimated_loading_date = window.prop ''
        @sent_to_loendersloot_on = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]
            if prop == 'invoice_number'
                # Invoice number 12663 has been given out twice, to both sales order
                # S25144 and to sales order S25303. We postfix one with an A, and
                # the other with a B.
                if json['artkey'] == 25144
                    @[prop] @[prop]! + 'A'
                else if json['artkey'] == 25303
                    @[prop] @[prop]! + 'B'
            else if prop == 'supplier'
                @supplier new Supplier json[prop]
                @supplier_artkey json[prop].artkey
            else if prop == 'account'
                @account_artkey json[prop].artkey
                @account new Account json[prop]
            else if prop == 'was_handled_by'
                @was_handled_by app.$m.users.create_user json[prop]
                @was_handled_by_artkey @was_handled_by!artkey!
            else if prop == 'delivery'
                @delivery new Delivery json[prop]
            else if prop == 'destination'
                @destination app.$m.data.warehouses.create_warehouse json[prop]
            else if prop == 'origin'
                @origin app.$m.data.warehouses.create_warehouse json[prop]
            else if prop == 'sales_order_additionals'
                @sales_order_additionals [new SalesOrderAdditional additional for additional in json[prop]]
            else if prop == 'sales_order_credit_items'
                @sales_order_credit_items [new SalesOrderCreditItem credit_item, @ for credit_item in json[prop]]
            else if prop == 'sales_order_tbo_items'
                @sales_order_tbo_items [new SalesOrderTBOItem tbo_item, @ for tbo_item in json[prop]]
            else if prop == 'basket_items'
                @basket_items [new SalesOrderBasketItem basket_item, @ for basket_item in json[prop]]
            else if prop == 'insurance_type'
                @insurance_type = window.prop new InsuranceType json[prop]

    credit_items_in_stock: ~>
        return and-list [credit_item.item!lot! and credit_item.item!lot! != '' for credit_item in @sales_order_credit_items!]


export class SalesOrderItem
    (json, sales_order) ->
        @artkey = window.prop ''
        @line_number = window.prop ''
        @price_per_case = window.prop ''
        @price_per_case_excl_excise = window.prop ''
        @excise_per_case = window.prop ''
        @number_of_cases = window.prop ''
        @item_artkey = window.prop ''
        @item = window.prop new Item
        @description = window.prop ''
        @portal_comment = window.prop ''
        @created_from_portal_timestamp = window.prop ''

        @sales_order = window.prop new SalesOrder
        @sales_order_artkey = window.prop ''

        @credited_in_sales_orders = window.prop []
        @was_last_updated_by_discover_user = window.prop {}
        @was_last_updated_by_portal_user = window.prop {}

        for prop of json
            if prop == 'item' and json[prop]
                @item new Item json[prop]
                @item_artkey @item!artkey!
            else if prop == 'sales_order' and json[prop]
                @sales_order new SalesOrder json[prop]
            else if prop == 'was_last_updated_by_discover_user'
                @was_last_updated_by_discover_user app.$m.users.create_user json[prop]
            else if prop == 'was_last_updated_by_portal_user'
                @was_last_updated_by_portal_user app.$m.users.create_user json[prop]
            else if prop == 'credited_in_sales_orders' then
                # If the item has is not credited, this property is null.
                # Correct that by defaulting to an empty list.
                @credited_in_sales_orders (json[prop] || [])
            else
                @[prop] = window.prop json[prop]

        if sales_order?
            @sales_order sales_order
            @sales_order_artkey @sales_order!artkey!

    euro_was_bought_for: ~>
        @item!euro_was_bought_for!

    dynamic_euro_was_sold_for: ~>
        convert_from_currency_to_euro(
            @price_per_case! - @excise_per_case!,
            @sales_order!was_sold_in!,
            @sales_order!sold_against_rate!
        )

    total_value: ~>
        @price_per_case! * @number_of_cases!

    total_excl_excise: ~>
        return @number_of_cases! * @price_per_case_excl_excise!

    margin_per_case: ~>
        @dynamic_euro_was_sold_for! - @euro_was_bought_for!

    total_margin: ~>
        @margin_per_case! * @number_of_cases!

    margin_percent: ~>
        percentage = @margin_per_case! / @dynamic_euro_was_sold_for! * 100
        if @total_margin! < 0 and percentage > 0
            # This has to be done for credit items that were migrated from Fiton.
            percentage = percentage * -1
        return percentage


export class SalesOrderItemsDataModel
    ->

    create_sales_order_items: (sales_order_items, sales_order) ~>
        [@create_sales_order_item soi, sales_order for soi in sales_order_items]

    create_sales_order_item: (sales_order_item, sales_order) ~>
        new SalesOrderItem sales_order_item, sales_order


export sales_order_items_dm = new SalesOrderItemsDataModel

export class SalesOrderDataModel
    ->

    create_sales_order: (sales_order) ~>
        sales_order_object = new SalesOrder sales_order
        if 'sales_order_items' of sales_order
            sales_order_object.sales_order_items sales_order_items_dm.create_sales_order_items \
                sales_order.sales_order_items, sales_order_object
        return sales_order_object

export sales_order_dm = new SalesOrderDataModel


export class SalesOrderAdditional
    (json) ->
        @artkey = window.prop ''
        @description = window.prop ''
        @sales_order_additional_type = window.prop ''
        @price_per_unit = window.prop ''
        @value_type = window.prop ''
        @value_per_quantity = window.prop ''
        @quantity = window.prop ''

        @sales_order = window.prop new SalesOrder
        @sales_order_artkey = window.prop ''

        for prop of json
            if prop == 'sales_order' and json[prop]
                @sales_order new SalesOrder json[prop]
                @sales_order_artkey @sales_order!artkey!
            else
                @[prop] = window.prop json[prop]


export class SalesOrderCreditItem
    (json, sales_order) ->
        @artkey = window.prop ''
        @sales_order_artkey = window.prop ''
        @sales_order = window.prop if sales_order then sales_order else new SalesOrder
        @sales_order_item_artkey = window.prop ''
        @sales_order_item = window.prop new SalesOrderItem
        @number_of_cases = window.prop ''
        @item = window.prop new Item

        for prop of json
            if prop == 'sales_order_item' and json[prop]
                @sales_order_item new SalesOrderItem json[prop]
                @sales_order_item_artkey @sales_order_item!artkey!
            else if prop == 'sales_order' and json[prop]
                if not sales_order
                    @sales_order sales_order_dm.create_sales_order json[prop]
                    @sales_order_artkey @sales_order!artkey!
            else if prop == 'item' and json[prop]
                @item new Item json[prop]
            else
                @[prop] = window.prop json[prop]

    total_value: ~>
        return @number_of_cases! * -@sales_order_item!price_per_case!

    margin_per_case: ~>
        @sales_order_item!euro_was_bought_for! - @sales_order_item!euro_was_sold_for!

    total_margin: ~>
        if ! @sales_order_item!euro_was_sold_for
            return null
        @margin_per_case! * @number_of_cases!

    margin_percent: ~>
        if ! @sales_order_item!euro_was_sold_for
            return null
        percentage = @margin_per_case! / @sales_order_item!euro_was_sold_for! * 100
        if @total_margin! < 0 and percentage > 0
            # This has to be done for credit items that were migrated from Fiton.
            percentage = percentage * -1
        return percentage


export class TboStatus
    @NOT_YET_APPROVED = 'Not yet approved'
    @OPEN = 'Open'
    @ORDERED = 'Ordered'
    @CONFIRMED = 'Confirmed'
    @IN_PROGRESS = 'In Progress'
    @ALL = 'All'


export class SalesOrderTBOItem
    (json, sales_order) ->
        @artkey = window.prop ''
        @sales_order_artkey = window.prop ''
        @sales_order = window.prop if sales_order then sales_order else new SalesOrder
        @sales_order_item_artkey = window.prop ''
        @sales_order_item = window.prop new SalesOrderItem
        @offer_item_artkey = window.prop ''
        @offer_item = window.prop new OfferItem
        @supplier_artkey = window.prop ''
        @supplier = window.prop new Supplier
        @case_artkey = window.prop ''
        @case = window.prop new Case
        @number_of_cases = window.prop ''
        @price_per_case = window.prop ''
        @price_per_case_excl_excise = window.prop ''
        @excise_per_case = window.prop ''
        @created_from_portal_timestamp = window.prop ''
        @delivery_period = window.prop ''
        @purchase_order_account_slug = window.prop ''

        for prop of json
            if prop == 'offer_item' and json[prop] then
                @offer_item new OfferItem json[prop]
                @offer_item_artkey @offer_item!artkey!
            else if prop == 'supplier' and json[prop] then
                @supplier new Supplier json[prop]
                @supplier_artkey @supplier!artkey!
            else if prop == 'case' and json[prop] then
                @case new Case json[prop]
                @case_artkey @case!artkey!
            else if prop == 'sales_order_item' and json[prop] then
                @sales_order_item new SalesOrderItem json[prop]
                @sales_order_item_artkey @sales_order_item!artkey!
            else if prop == 'sales_order' and json[prop] and not sales_order then
                @sales_order sales_order_dm.create_sales_order json[prop]
                @sales_order_artkey @sales_order!artkey!
            else
                @[prop] = window.prop json[prop]

    total_value: ~>
        return @number_of_cases! * @price_per_case!

    total_excl_excise: ~>
        return @number_of_cases! * @price_per_case_excl_excise!


export class SalesOrderBasketItem
    (json, sales_order) ->
        @artkey = window.prop ''
        @sales_order_artkey = window.prop ''
        @sales_order = window.prop if sales_order then sales_order else new SalesOrder
        @price_per_case = window.prop ''
        @excise_per_case = window.prop ''
        @number_of_cases = window.prop ''
        @offer_item = window.prop new OfferItem
        @updated_timestamp = window.prop ''

        for prop of json
            if prop == 'offer_item' and json[prop]
                @offer_item new OfferItem json[prop]
            else if prop == 'sales_order' and json[prop]
                if not sales_order
                    @sales_order sales_order_dm.create_sales_order json[prop]
                    @sales_order_artkey @sales_order!artkey!
            else
                @[prop] = window.prop json[prop]
