m = require 'mithril'
{map, or-list} = require 'prelude-ls'

{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{CollectionTable} = require '@/components/collection_table.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
{label-icons-for-item} = require '@/components/labels.ls'
{Collection} = require '@/components/collection/collection.ls'
{format-date, match-term-in, to-lower} = require 'utils.ls'
{current_account_slug} = require '@bitstillery/common/account/account'
{PurchaseOrderStatus} = require '@/models/purchase_orders'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
app = require('@/app')

module.exports = class PurchaseOrderItems
    ->
        @search_status = window.prop 'Saved or confirmed'

        @purchase_order_items = new Collection do
            api_function_name: 'purchase.core.search_purchase_order_items'
            additional_params: @additional_params
            filter_function: @is_match
            sort_order: [
                {'name': 'purchase_order_created_on', 'direction': 'desc'},
                {'name': 'purchase_order_artkey', 'direction': 'desc'},
                {'name': 'product_name', 'direction': 'asc'},
                {'name': 'bottle_volume', 'direction': 'asc'},
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'},
                {'name': 'bottle_refill_status', 'direction': 'desc'},
            ]
            default_sort_by: 'purchase_order_created_on'
            default_sort_order: 'desc'
            query_limit: 25

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @purchase_order_items.update_search_term text
        @purchase_order_items.submit_search!

    oncreate: ->
        @purchase_order_items.requery!

        q = m.route.param 'q'
        if q
            @submit_search q.replace(/\+/g, ' ')

    is_match: (record, term) ~>
        # Match on the status filter, if selected.
        status_filter = @purchase_order_items.additional_params!status
        if status_filter and record.purchase_order_status not in status_filter then
            return false

        or-list [
            match-term-in term <| map to-lower <| [
                record.product_name
                record.product_category
                record.bottle_refill_status
                record.case_gift_box_type
                record.case_tax_label
                record.target_warehouse_name
                record.supplier_name
            ]
            +record.bottle_volume == +term
            +record.bottle_alcohol_percentage == +term
            +record.purchase_order_artkey == +term
            "p#{record.purchase_order_artkey}" == term
        ]

    additional_params: ~>
        params = {}
        # Append the status filter, if selected.
        if @search_status! == PurchaseOrderStatus.SAVED_OR_CONFIRMED
            params['status'] = [PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED]
        else if @search_status! == PurchaseOrderStatus.STOCKED
            params['status'] = [PurchaseOrderStatus.READY_FOR_FIS, PurchaseOrderStatus.STOCKED_AND_BOOKED]
        else
            params['status'] = [@search_status!]
        params["account"] = current_account_slug!
        return params

    set_search_status: (search_status) ~>
        @search_status search_status
        @purchase_order_items.requery!

    view: -> m '.c-purchase-order-items view',
        # search on product name, specs and relation. Also add a filter on purchase order status.
        m '.c-filter-group',
            m SearchBar,
                placeholder: "Search products..."
                on_submit: @submit_search
                default_search_text: @purchase_order_items.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

            inputs.select @search_status, PurchaseOrderStatus.choices, do
                onchange: @set_search_status
                empty_option: true

        m CollectionTable, do
            collection: @purchase_order_items
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: false
                autoscale: true
                unique_name: 'purchase_order_items'
            columns:
                do
                    width: 4
                    name: 'Purchase Order reference'
                    header: '#'
                    field: 'purchase_order_reference'
                    sort: true
                    function: (record) ->
                        purchase-order-link do
                            artkey: record.purchase_order_artkey
                            reference: record.purchase_order_reference
                do
                    width: 4
                    name: 'Supplier reference'
                    header: '##'
                    field: 'purchase_order_supplier_reference'
                    sort: true
                do
                    width: 9
                    name: 'Product'
                    field: 'product_name'
                    sort: true
                do
                    name: 'Btl / cs'
                    width: 5
                    field: 'case_number_of_bottles'
                    classes: 'number'
                    sort: true
                do
                    name: 'Specs'
                    width: 10
                    function: (record) ->
                        specs = ''
                        if record.bottle_volume
                            specs = specs + record.bottle_volume + 'cl'
                        if record.bottle_alcohol_percentage
                            specs = specs + " / #{record.bottle_alcohol_percentage}%"
                        if record.bottle_refill_status
                            specs = specs + " / #{record.bottle_refill_status}"
                        if record.case_gift_box_type
                            specs = specs + " / #{record.case_gift_box_type}"
                        return specs
                do
                    name: 'Features'
                    width: 10
                    function: (record) -> m '',
                        # if record.case.tax_label
                        #     specs = specs + " / #{record.case.tax_label}"

                        if record.comment then
                            icon-with-popover do
                                icon-id: 'comment'
                                title: 'Comment'
                                content: record.comment

                        label-icons-for-item get-poi-labels record
                do
                    name: 'Cases'
                    width: 6
                    classes: 'number'
                    field: 'number_of_cases'
                    sort: true
                do
                    name: 'Price / cs'
                    width: 7
                    classes: 'price'
                    function: (record) ->
                        # original price per case
                        # TODO /*
                        if record.refers_to_artkey then m '',
                            if record.original_price_per_case then
                                m Amount, do
                                    amount: record.original_price_per_case
                                    currency: record.original_currency
                                    rate: record.bought_against_rate
                            else if record.original_price_per_bottle then
                                m Amount, do
                                    amount: record.original_price_per_bottle * record.case_number_of_bottles
                                    currency: record.original_currency
                                    rate: record.bought_against_rate
                            else
                                '-'
                        else
                            '-'
                do
                    name: 'Net Price / cs'
                    width: 7
                    classes: 'price'
                    field: 'was_bought_for'
                    sort: true
                    function: (record) ->
                        price_per_bottle = record.was_bought_for / record.case_number_of_bottles

                        m '',
                            # Only show icon if the price is different from the spli.
                            if record.refers_to_artkey and not (
                                (record.original_price_per_case and +record.original_price_per_case == +record.was_bought_for) or
                                (record.original_price_per_bottle and +record.original_price_per_bottle == +price_per_bottle)) then
                                icon 'download', {style: {'margin-right': '5px'}}

                            m Amount, do
                                amount: +record.was_bought_for
                                currency: record.was_bought_in
                                display_currency: app.$s.currencies.default
                                rate: record.bought_against_rate

                do
                    name: 'Cost / cs'
                    width: 7
                    classes: 'price'
                    field: 'was_bought_for_plus_costs'
                    function: (record) ->
                        m Amount, do
                            amount: record.was_bought_for_plus_costs
                            currency: record.was_bought_in
                            rate: record.bought_against_rate
                do
                    name: 'Total value'
                    width: 7
                    classes: 'price'
                    field: 'total_price'
                    function: (record) ->
                        m Amount, do
                            amount: record.number_of_cases * record.was_bought_for
                            currency: record.was_bought_in
                            rate: record.bought_against_rate
                do
                    name: 'Cus.'
                    width: 3
                    field: 'case_customs_status'
                    sort: true
                # TODO
                # do
                #     name: 'Lot nrs'
                #     width: 10
                #     field: 'lots'
                do
                    width: 6
                    name: 'Order Date'
                    field: 'purchase_order_created_on'
                    transform: format-date
                    sort: true
                do
                    width: 17
                    name: 'Relation'
                    field: 'supplier_name'
                    ellipsis: true
                    sort: true
                do
                    width: 8
                    name: 'Status'
                    field: 'purchase_order_status'
                    sort: true
                do
                    header: 'Dimensions (l/w/h)'
                    name: 'Dimensions'
                    sort: false
                    default_visible: false
                    function: (record) ->
                        m 'td' do
                            if record.case_length && record.case_width && record.case_height
                                "#{record.case_length} x #{record.case_width} x #{record.case_height} cm"
                do
                    width: 5
                    header: 'Weight'
                    name: 'Weight'
                    field: 'case_weight'
                    sort: false
                    default_visible: false
                    function: (record) ->
                        if record.case_weight
                            "#{record.case_weight} kg"
                do
                    width: 1
                    name: ''
                    function: ->


purchase-order-link = (purchase-order) ->
    m m.route.Link, do
        target: '_blank'
        href: "/purchase-orders/manage/#{purchase-order.artkey}"
        class: "no-click"
    , purchase-order.reference


get-poi-labels = (poi) -> do
    item_tags: poi.case_item_tags || []
    tax_label: poi.case_tax_label
    remark: poi.remark
    cases_per_pallet: poi.cases_per_pallet
    best_before_date: poi.case_best_before_date
