import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface StockReconciliationResult {
    result: string
    result_text: string
    line: string
    lot: string
}

export interface StockReconciliationReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class StockReconciliationReport extends MithrilTsxComponent<StockReconciliationReportAttrs> implements RequeryListener {
    reporting_fetcher = new PagedCollectionFetcher<StockReconciliationResult>(
        'reporting.stock_reconciliation_report.get_all',
        'artkey',
    )

    constructor(vnode: m.Vnode<StockReconciliationReportAttrs>) {
        super()

        vnode.attrs.add_requery_listener(this)
    }

    fire(): void {
        this.reporting_fetcher.reset_and_query()
    }

    onremove(vnode: m.Vnode<StockReconciliationReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    view(): m.Children {
        return (
            <CollectionTable<StockReconciliationResult, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<StockReconciliationResult>
                    header_title={() => 'Lot'}
                    sort_name={'lot'}
                    data_field={(row: StockReconciliationResult) => row.lot}
                />
                <CollectionTableColumn<StockReconciliationResult>
                    header_title={() => 'Result'}
                    data_field={(row: StockReconciliationResult) => row.result_text}
                />
                <CollectionTableColumn<StockReconciliationResult>
                    header_title={() => 'Result code'}
                    data_field={(row: StockReconciliationResult) => row.result}
                />
                <CollectionTableColumn<StockReconciliationResult>
                    header_title={() => 'In line'}
                    data_field={(row: StockReconciliationResult) => <code>{row.line}</code>}
                />
            </CollectionTable>
        )
    }
}
