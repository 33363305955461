import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'
import {map, mergeAll, tap} from 'rxjs/operators'

import {SearchBar, SearchBarControl} from './search_bar'

export interface AutocompleteInputProps<T> {
    /** Optional label for the autocomplete input. */
    label?: string
    /** Optional help text for the autocomplete input. */
    help?: string
    /** Placeholder text for the autocomplete input. */
    placeholder: string
    /** Function to get suggestions based on filter text. */
    on_get_suggestions$: (filter_text: string) => Observable<T[]>
    /** Transforms a suggestion entity into a displayable label. */
    suggestion_as_label: (entity: T) => string
    /** Callback when a suggestion is selected. */
    on_selected: (entity: T | null) => unknown
    /** Callback for when the search text changes. */
    on_new_search_text?: (search_text: string | null) => unknown
    /** Default text to display in the search bar. */
    default_text?: string
    /** Controller for the search bar, allows for external control. */
    search_bar_controller?: (search_bar_control: SearchBarControl) => unknown
    /** If the input is required. */
    required?: boolean
    /** If the input is disabled. */
    disabled?: boolean
    /** Validation rules or function for the input. */
    validation?: any
}

/**
 * Represents a generic autocomplete input component.
 * @template T The type of data to be used for suggestions and selections.
 */
export class AutocompleteInput<T> extends MithrilTsxComponent<AutocompleteInputProps<T>> {
    search_bar_controller: SearchBarControl | null = null
    current_data: T[] = []

    on_get_suggestions$: (filter_text: string) => Observable<T[]>
    suggestion_as_label: (simple_product: T) => string
    on_selected: (entity: T | null) => unknown
    on_new_search_text?: (search_text: string | null) => unknown

    /**
     * Constructs an instance of AutocompleteInput.
     * @param vnode The vnode containing the attributes for the autocomplete input.
     */
    constructor(vnode: m.Vnode<AutocompleteInputProps<T>>) {
        super()
        this.on_get_suggestions$ = vnode.attrs.on_get_suggestions$
        this.suggestion_as_label = vnode.attrs.suggestion_as_label
        this.on_selected = vnode.attrs.on_selected
        this.on_new_search_text = vnode.attrs.on_new_search_text
    }

    /**
     * Searches for entities matching the given search text.
     * @param search_text The text used to search for matching entities.
     */
    search_for_search_text = (search_text: string): void => {
        if (this.on_new_search_text) {
            this.on_new_search_text(search_text)
        }
        if (!search_text) {
            this.on_selected(null)
        }
        const selected = this.current_data.filter((entity) => this.suggestion_as_label(entity) === search_text)
        if (selected.length === 1) {
            this.on_selected(selected[0])
        }
    }

    /**
     * Retrieves the label for suggestions based on the filter text.
     * @param filter_text The text used to filter suggestions.
     * @returns An Observable emitting the label for each suggestion.
     */
    get_suggestion_as_label$(filter_text: string): Observable<string> {
        return this.on_get_suggestions$(filter_text).pipe(
            tap((items) => (this.current_data = items)),
            mergeAll(),
            map((simple_p) => this.suggestion_as_label(simple_p)),
        )
    }

    /**
     * The view representation of the autocomplete input component.
     * @param vnode The vnode containing attributes for the autocomplete input.
     * @returns The constructed SearchBar component.
     */
    view(vnode: m.Vnode<AutocompleteInputProps<T>, this>): m.Children {
        return <SearchBar
            default_search_text={vnode.attrs.default_text}
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            oninput={vnode.attrs.oninput}
            on_get_suggestions$={(filter_text: string) => this.get_suggestion_as_label$(filter_text)}
            on_submit={this.search_for_search_text}
            placeholder={vnode.attrs.placeholder}
            required={vnode.attrs.required}
            search_bar_controller={(controller: SearchBarControl) => {
                this.search_bar_controller = controller
                if (vnode.attrs.search_bar_controller) {
                    vnode.attrs.search_bar_controller(this.search_bar_controller)
                }
            }}
            validation={vnode.attrs.validation}
        />
    }
}
