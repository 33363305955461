import {Observable} from 'rxjs'

import {VoucherPromotion, VoucherValueType} from '../vouchers/voucher_models'

import {Api, FactserverRequestData} from './api'

interface GetVoucherPromotionRequest extends FactserverRequestData {
    artkey: number
}
type DeleteVoucherPromotionRequest = GetVoucherPromotionRequest

export interface CreateOrUpdateVoucherPromotionRequest extends FactserverRequestData {
    artkey?: number
    name: string
    voucher_value_type: VoucherValueType
    value: number
    code: string
    start_date: string
    end_date: string
}

export class VoucherApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    get_voucher_promotion(artkey: number): Observable<VoucherPromotion> {
        const request: GetVoucherPromotionRequest = {artkey: artkey}
        return this.api.post_request('voucher.get_voucher_promotion', request)
    }

    create_or_update_voucher_promotion(request: CreateOrUpdateVoucherPromotionRequest): Observable<VoucherPromotion> {
        return this.api.post_request('voucher.create_or_update_voucher_promotion', request)
    }

    delete_voucher_promotion(artkey: number): Observable<VoucherPromotion> {
        const request: DeleteVoucherPromotionRequest = {artkey: artkey}
        return this.api.post_request('voucher.delete_voucher_promotion', request)
    }

    save_relation_selection(
        voucher_promotion_artkey: number,
        relation_artkeys: Array<number>,
    ): Observable<VoucherPromotion> {
        return this.api.post_request('voucher.save_relation_selection', {
            artkey: voucher_promotion_artkey,
            relation_artkeys: relation_artkeys,
        })
    }

    get_active_relation_vouchers(relation_artkey: number): Observable<VoucherPromotion[]> {
        return this.api.post_request('voucher.get_relation_active_vouchers', {
            relation_artkey: relation_artkey,
            only_unused: true,
        })
    }
}
