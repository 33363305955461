{Amount} = require '@bitstillery/common/components'
{icon-with-popover} = require '@/components/icon.ls'
app = require('@/app')

export offer-price = (item) ->
    if item.price_per_case == item.list_price then
        m '' {style: 'display: flex; justify-content: space-between'},
            if item.price_up
                icon-with-popover {icon-id: 'fa-circle', content: "Raised from #{(+item.previous_price).format-money!} #{app.$s.currencies.default}"}, {style: 'color: red'}
            else if item.price_down
                icon-with-popover {icon-id: 'fa-circle', content: "Reduced from #{(+item.previous_price).format-money!} #{app.$s.currencies.default}"}, {style: 'color: green'}
            else
                m 'span' ' '

            m Amount, do
                amount: item.list_price
                currency: app.$s.currencies.default
    else [
        # This should be a special offer.
        m 'del' m Amount, do
            amount: item.price_per_case
            currency: app.$s.currencies.default
        m 'br'
        m Amount, do
            amount: item.list_price
            currency: app.$s.currencies.default
    ]
