m = require 'mithril'

{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'


module.exports = class EmailI18nList
    ->
        @email_placeholders = new Collection do
            api_function_name: 'email_i18n_placeholders.get_email_placeholders'
            sort_order: [
                {'name': 'placeholder', 'direction': 'asc'}
            ]
            query_limit: 20

    oncreate: ~>
        @email_placeholders.requery!

    view: ->
        m '.c-email-i18n view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: 'button', onclick: ~> m.route.set '/data/email-placeholders/create'},
                    (m 'span.glyphicon.glyphicon-plus'), ' Create Email Placeholder'

            m CollectionTable, do
                collection: @email_placeholders
                options:
                    sticky_header: true
                    unique_name: 'email_placeholders'
                    onclick: (email_placeholder, e) -->
                        e.preventDefault!
                        m.route.set("/data/email-placeholders/#{email_placeholder.artkey}/edit")
                columns:
                    do
                        name: 'Placeholder'
                        width: 4
                        header: 'Placeholder'
                        field: 'placeholder'
                    do
                        name: 'Description'
                        width: 4
                        header: 'Description'
                        field: 'description'
                    do
                        name: 'Column selector'
                        header: ''
                        value: ' '
                        width: 1
