import {events} from '@bitstillery/common/app'

import _models from './_purchase_orders.ls'

import {$m} from '@/app'

export const {
    PurchaseOrder,
    PurchaseOrderItem,
    PurchaseOrdersDataModel,
    PurchaseOrderItemsDataModel,
    PurchaseOrderStatus,
} = _models

export function bind() {
    events.on('identity.login', async() => {
        $m.purchase_orders = new PurchaseOrdersDataModel()
    })
}
