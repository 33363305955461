m = require 'mithril'
{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'

module.exports = class InsuranceTypeList
    is_match: (insurance_type, term) ~>
        return or-list [
            insurance_type.name.toLowerCase!indexOf(term) > -1,
        ]

    view: ->
        m GenericList, do
            readable_entity_name: 'insurance type'
            base_url: '/data/insurancetypes/'
            list_api_call: 'insurancetypes.get_all'
            is_match: @is_match
            columns:
                {'key': 'name', 'label': 'Name'}
                {'key': 'fee', 'label': 'Fee (permillage)'}
            requery_subscribe_key: 'insurancetypes_updated'
