m = require 'mithril'
{find, map} = require 'prelude-ls'
{is-function} = require '@bitstillery/common/utils.ls'
api = require 'api.ls'
{MultiSelect} = require '@/components/inputs/multiselect.ls'
{countries, european_union_countries, required_region_country_codes} = require '@bitstillery/common/lib/countries'
{excise_countries} = require '@bitstillery/common/excise'
inputs = require '@/components/inputs'
{MoneyInput, PercentInput} = require '@/components/decimal_input'
{languages} = require '@/components/languages.ls'
{link} = require '@/components/link.ls'
{Table} = require '@/components/table2.ls'
{Lead} = require 'crm/leads/models.ls'
{a, download_binary_file_from_base64_str, join-maybes, format-date, format-date-time} = require 'utils.ls'
{format_money} = require '@bitstillery/common/lib/format'
{button-with-icon} = require '@/components/buttons.ls'
{icon} = require '@/components/icon.ls'
{AddLogEntry, LogEntries} = require '@/components/logbook/entries.ls'
{proxy} = require '@bitstillery/common/lib/proxy'
{FieldCheckbox, FieldText, FieldVatId} = require '@bitstillery/common/components'
{classes} = require '@bitstillery/common/lib/utils'
# For validation.and, which can't be imported through destructuring in livescript
validators = require '@bitstillery/common/lib/validation'
{region_drop_down_data} = require '@/factserver_api/relation_api'
{RelationApi} = require '@/factserver_api/relation_api'
{CountriesSelect, DropDownWithSearch, DropDownOption, empty_option} = require '@/components/html_components'
{conditional, invalid_fields, invalid_fields_format, password, required, validation, vat_id, website} = require '@bitstillery/common/lib/validation'
{AttachmentList, AttachmentHelper, UploadAttachment} = require '@/components/attachment_list'
{AttachmentType} = require '@/factserver_api/attachment_api'
{UserDropDown} = require '@/components/users'
{IncotermsDropDown} = require '@/components/incoterms'
{IncotermsDropDownData} = require '@/factserver_api/incoterms_api'
{DropDownWithSelect, DropDownOption, empty_option} = require '@/components/html_components'
{current_account_slug, AccountSlug} = require '@bitstillery/common/account/account'
{HiddenInformation, HiddenRelationHelper} = require '@/purchase_orders/view_purchase_order/hidden_information'
{ContactPerson, LedgerType} = require '@/models/data'
{Supplier} = require '@/models/suppliers'
{Spinner, Tippy} = require '@bitstillery/common/components'

app = require('@/app')


field = (label, input) ->
    inputs.field label, input, label_width=4, input_width=8


price_quantity_preference = [
    ['bottle', 'Bottle'],
    ['case', 'Case'],
]

help = {
    buyer_only: 'Only for relations that are buyer',
    has_sales_orders: 'Fixed because relation already has sales orders',
    supplier_only: 'Only for relations that are supplier',
}

module.exports = class SupplierEdit
    ->
        @supplier = window.prop null
        @user = window.prop []

        @success_message = window.prop ''
        @purchase_ledgers = window.prop []
        app.$m.data.ledgers.query_all @purchase_ledgers, LedgerType.PURCHASING
        @sales_ledgers = window.prop []
        app.$m.data.ledgers.query_all @sales_ledgers, LedgerType.SALES
        @payment_terms = window.prop []
        app.$m.data.payment_term.query_all @payment_terms
        @price_list_frequency_select = window.prop ''
        @price_list_frequency_amount = window.prop 1
        @price_list_frequency_unit = window.prop 'days'

        @sales_promotion_november_promotion_active = window.prop false
        @sales_promotion_scooter_giveaway_active = window.prop false

        @contact_persons = window.prop null
        @warehouses = window.prop []
        @primary_warehouse_name = window.prop null

        @create = window.prop false
        @create_from_lead = window.prop false
        @create_contact_person = window.prop false

        @hide_from_pricelist_for_countries_selected = window.prop []
        @hide_from_pricelist_for_suppliers_selected = window.prop []

        @contact_person = window.prop new ContactPerson

        @has_portal_access = window.prop false
        @enable_portal_access = window.prop false
        @password = window.prop ''
        @repeat_password = window.prop ''

        @_custom_password = false
        @_generate_password = false

        const pathname = m.parsePathname(m.route.get())
        const params = Object.fromEntries(new URLSearchParams(pathname.params))

        @generate_password = (value) ~>
            if value? then
                @_generate_password = value
                if value and @_custom_password then
                    @_custom_password = false

            return @_generate_password

        @custom_password = (value) ~>
            if value? then
                @_custom_password = value
                if value and @_generate_password then
                    @_generate_password = false
                else if not value and @create! then
                    @_generate_password = true
            return @_custom_password

        @generate_password true
        @relation_api = new RelationApi()

        @regions = window.prop []
        @country_to_region$ = null
        @region_is_required = window.prop false

        this.loadSupplier()

        this.data = proxy({
            amount_spent: 0,
            $f: {
                tokens_spent: 0,
                promotion_selected: false,
            },
            msi_supplier_artkey: null,
            tab: if params.tab then params.tab else 'relation',
            threshold: 0,
            tokens: {
                draft: 0,
                redeemable: 0,
            },
            turnover: {
                draft: 0,
                redeemable: 0,
                penalty: null,
            },
        })

        this.$v = {
            company_type: validation([this.supplier, 'company_type'], required()),
            country_code: validation([this.supplier, 'country_code'], required()),
            client_status: validation([this.supplier, 'client_status'], required()),
            city: validation([this.supplier, 'city'], required()),
            currency: validation([this.supplier, 'currency'], required()),
            customs_status: validation([this.supplier, 'customs_status'], required()),
            price_list_frequency: validation([this, 'price_list_frequency_select'], conditional((~> @supplier!is_supplier!), required())),
            portal_customs_visibility: validation([this.supplier, 'portal_customs_visibility'], required()),
            purchase_manager_artkey: validation([this.supplier, 'purchase_manager.artkey'], required()),
            price_list_manager_artkey: validation([this.supplier, 'price_list_manager.artkey'], required()),
            price_preference: validation([this.supplier, 'price_preference'], required()),
            purchase_ledger_artkey: validation([this.supplier, 'purchase_ledger_artkey'], required()),
            purchase_payment_term_artkey: validation(
                [this.supplier, 'purchase_payment_term_artkey'],
                conditional((~> @supplier!is_supplier!), required())
            ),
            region_code: validation([this.supplier, 'region_code'], conditional((~> this.region_is_required()), required()))
            relation_name: validation([this.supplier, 'name'], required()),
            sales_ledger_artkey: validation([this.supplier, 'sales_ledger_artkey'], required()),
            sales_manager_artkey: validation([this.supplier, 'sales_manager.artkey'], required()),
            sales_payment_term_artkey: validation([this.supplier, 'sales_payment_term_artkey'], conditional((~> @supplier!is_buyer!), required())),
            street_address: validation([this.supplier, 'street_address'], required()),
            zip_code: validation([this.supplier, 'zip_code'], required()),
            contact_emailaddress: validation([this.contact_person, 'emailaddress'], conditional((~> @create_contact_person! or this.create_from_lead()), required())),
            contact_first_name: validation([this.contact_person, 'first_name'], conditional((~> @create_contact_person! or @create_from_lead!), required())),
            contact_family_name: validation(
                [this.contact_person, 'family_name'],
                conditional((~> @create_contact_person! or @create_from_lead!), required())
            ),
            contact_password: validation(
                [this, 'password'],
                conditional((~> (@create_contact_person! or @create_from_lead!) and (@create! and @custom_password!)), password())
            ),
            contact_password_verify: validation([this, 'repeat_password'], {
                label: '*',
                message: 'Passwords do not match'
                validate: ~>
                    this.label = '**'
                    if this.password() !== this.repeat_password() then
                        return this

                    return false
            }),
            url: validation([this.supplier, 'url'], conditional((~> @supplier!url!), website())),
            vat_id: validation([this.supplier, 'vat_id'], conditional((~>
                country_code = this.supplier!.country_code!
                return european_union_countries.includes(country_code)
            ), validators.and([required(), vat_id([this.supplier, 'country_code'])])))
        }

    loadSupplier: ~>>
        @lead_artkey = ''
        supplier_artkey = m.route.param 'artkey'

        if supplier_artkey
            await @query_supplier supplier_artkey
            await @load_msi_supplier supplier_artkey
            @attachment_helper = new AttachmentHelper(supplier_artkey, AttachmentType.RELATION)
        else
            # We are creating a new one
            @attachment_helper = null
            @create true
            @supplier new Supplier
            @lead_artkey = m.route.param 'lead_artkey'
            if @lead_artkey
                # We are converting a lead; make sure to wait unit
                # the lead data is loaded before updating regions.
                # The supplier country_code needs to load first.
                await @query_lead @lead_artkey
                @create_from_lead true

            this.update_regions(@supplier!country_code!)

    update_regions: (country_code) ~>
        regions = app.$m.data.region_drop_down_data.for_country_code(country_code).subscribe((regions) ~>
            @regions regions
            @region_is_required @supplier!country_code! in required_region_country_codes
            m.redraw!
        )

    shallow_copy_while_preserving_functions: (from, to) ~>
        # Copies the 'value of function' in the from object to the corresponding functions of the to object by invoking.
        # This is required because the render tree has references to the functions declared in the constructor.
        # All except 'make_setting_prop', it is not copyable since it needs args.
        Object.entries(from).filter((attr) -> attr[0] not in ["make_setting_prop"]).map((attr) ~>
            attr_key = attr[0]
            are_functions = to[attr_key] && from[attr_key] && is-function(to[attr_key]) && is-function(from[attr_key])
            if are_functions
                to[attr_key](from[attr_key]())
        )

    query_lead: (artkey) ~>
        return new Promise((resolve, reject) ~>
            api.call-and-then 'crm.leads.get' {artkey: artkey}, do
                success: (resp) ~>
                    lead = new Lead resp.result
                    @shallow_copy_while_preserving_functions lead, @supplier!
                    @supplier!artkey null
                    if lead.manager!
                        @supplier!purchase_manager!artkey lead.manager!artkey!
                        @supplier!sales_manager!artkey lead.manager!artkey!
                    if lead.contact_persons!
                        @shallow_copy_while_preserving_functions lead.contact_persons![0], @contact_person!
                        @contact_person!artkey null

                    resolve()
        )



    query_supplier: (artkey) ~>
        return new Promise((resolve, reject) ~>
            @supplier null
            api.call-and-then 'suppliers.get_supplier', {artkey: artkey}, do
                success: (resp) ~>
                    active_promotion = resp.result.active_promotion
                    @supplier new Supplier resp.result
                    if @supplier!operates_online! == null
                        @supplier!operates_online false

                    if active_promotion then
                        this.data.amount_spent = Number(resp.result.amount_spent)
                        this.data.threshold = active_promotion.additional_json_data.threshold
                        this.data.$f.penalty = resp.result.penalty
                        Object.assign(this.data.$f, {
                            promotion_selected: resp.result.promotion_selected
                            tokens_spent: this.data.amount_spent / this.data.threshold
                        })

                        Object.assign(this.data.turnover, {
                            draft: resp.result.promotion_turnover_draft,
                            redeemable: resp.result.promotion_turnover_redeemable,
                        })

                        Object.assign(this.data.tokens, {
                            draft: Math.floor(resp.result.promotion_turnover_draft / this.data.threshold),
                            redeemable: Math.floor(resp.result.promotion_turnover_redeemable / this.data.threshold),
                        })

                    # construct a list with the selected countries as country_codes
                    @hide_from_pricelist_for_countries_selected @supplier!hide_from_pricelist_for_countries!map((excluded) -> excluded.country_code)
                    @hide_from_pricelist_for_suppliers_selected @supplier!hide_from_pricelist_for_suppliers!map((excluded) -> "" + excluded.artkey)

                    # Set the price_list_frequency_x input field values.
                    price_list_frequency = @supplier!price_list_frequency!
                    if price_list_frequency in Object.keys app.$m.data.price_list_frequencies
                        # If the frequency occurs in the default frequency set, use it.
                        @price_list_frequency_select price_list_frequency
                    else if price_list_frequency
                        # Otherwise, use the custom fields.
                        @price_list_frequency_select 'other'
                        [amount, unit] = price_list_frequency.split ' '
                        @price_list_frequency_amount amount
                        @price_list_frequency_unit unit

                    @update_regions(@supplier!country_code!)
                    # Fetch warehouses after we have the supplier.
                    api.call2 'suppliers.get_all_supplier_warehouses', {supplier_artkey: @supplier!artkey!}, @set_warehouses
                    resolve()

                failure: (resp) ~>
                    m.route.set '/crm/relations'
                    return {}

            data = {supplier_artkey: artkey}
            api.call-and-then 'contact_persons.get_all', data, do
                success: (resp) ~> @contact_persons resp.result
        )

    valid-password: ~>
        @password! == @repeat_password!

    warehouse_names: ~>
        return @warehouses! |> map (.name)

    set_warehouses: (resp) ~>
        @warehouses resp.result
        primary_warehouse = @warehouses! |> find (.artkey == @supplier!primary_warehouse_artkey!)
        if primary_warehouse
            @primary_warehouse_name primary_warehouse.name

    save: ~>
        this.complete_supplier_url()
        if @create_contact_person! then
            (@password! and @valid-password!) or @generate_password!

        # Determine the price_list_frequency value from the price_list_frequency_x
        # input field values.
        if @price_list_frequency_select! == ''
            price_list_frequency = null
        else if @price_list_frequency_select! == 'other'
            price_list_frequency = [@price_list_frequency_amount!, @price_list_frequency_unit!].join(' ')
        else
            price_list_frequency = @price_list_frequency_select!

        data = do
            amount_spent: this.data.$f.tokens_spent * this.data.threshold
            penalty: this.data.$f.penalty
            artkey: @supplier!artkey!
            name: @supplier!name!
            emailaddress: @supplier!emailaddress!
            telephone_number: @supplier!telephone_number!
            url: @supplier!url!
            street_address: @supplier!street_address!
            zip_code: @supplier!zip_code!
            city: @supplier!city!
            country_code: @supplier!country_code!
            region_code: @supplier!region_code!
            company_type: @supplier!company_type!
            client_status: @supplier!client_status!
            operates_online: @supplier!operates_online!
            is_supplier: @supplier!is_supplier!
            is_buyer: @supplier!is_buyer!
            incoterm: @supplier!incoterm!
            should_receive_offer_mails: @supplier!should_receive_offer_mails!
            should_receive_purchase_mails: @supplier!should_receive_purchase_mails!
            purchase_ledger_artkey: @supplier!purchase_ledger_artkey!
            sales_ledger_artkey: @supplier!sales_ledger_artkey!
            low_tariff_sales_ledger_artkey: @supplier!low_tariff_sales_ledger_artkey!
            purchase_manager_artkey: +@supplier!purchase_manager!artkey!
            price_list_manager_artkey: +@supplier!price_list_manager!artkey!
            sales_manager_artkey: +@supplier!sales_manager!artkey!
            vat_id: @supplier!vat_id!
            excise_id: @supplier!excise_id!
            memo: @supplier!memo!
            linkedin: @supplier!linkedin!
            facebook: @supplier!facebook!
            instagram: @supplier!instagram!
            twitter: @supplier!twitter!
            sales_payment_term_artkey: +@supplier!sales_payment_term_artkey!
            purchase_payment_term_artkey: +@supplier!purchase_payment_term_artkey!
            price_list_frequency: price_list_frequency
            portal_level: @supplier!portal_level!
            portal_customs_visibility: @supplier!portal_customs_visibility!
            mix_customs_on_pricelist: @supplier!mix_customs_on_pricelist!
            portal_account_expiry: @supplier!portal_account_expiry!
            currency: @supplier!currency!
            customs_status: @supplier!customs_status!
            transport_costs_payment_method: @supplier!transport_costs_payment_method!
            transport_costs_per_case: @supplier!transport_costs_per_case!
            include_ukds_in_price: @supplier!include_ukds_in_price!
            include_excise_in_price: @supplier!include_excise_in_price!
            show_excise_in_portal: @supplier!show_excise_in_portal!
            minimum_order_amount: @supplier!minimum_order_amount!
            price_markup_percentage: @supplier!price_markup_percentage!
            primary_warehouse_artkey: if @primary_warehouse! then @primary_warehouse!artkey else null
            use_incoterm_on_pricelist: @supplier!use_incoterm_on_pricelist!
            price_preference: @supplier!price_preference!toLowerCase!
            promotion_selected: this.data.$f.promotion_selected
            emailaddress_financial_documents: @supplier!emailaddress_financial_documents!
            emailaddress_rfp_documents: @supplier!emailaddress_rfp_documents!
            lead_artkey: @lead_artkey
            hide_from_pricelist_for_countries: @hide_from_pricelist_for_countries_selected!
            hide_from_pricelist_for_suppliers: @hide_from_pricelist_for_suppliers_selected!map((artkey) -> +artkey)
        if @create_from_lead! or @create_contact_person!
            data.contact_person = do
                artkey: @contact_person!artkey!
                first_name: @contact_person!first_name!
                family_name: @contact_person!family_name!
                telephone_number: @contact_person!telephone_number!
                mobile_telephone_number: @contact_person!mobile_telephone_number!
                emailaddress: @contact_person!emailaddress!
                birthdate: if @contact_person!birthdate! then @contact_person!birthdate!split('T')[0] else null
                position: @contact_person!position!
                salutation: @contact_person!salutation!
                language: @contact_person!language!
                enable_portal_access: @enable_portal_access!
                should_receive_mails: @contact_person!should_receive_mails!
                should_receive_purchase_enquiries: @contact_person!should_receive_purchase_enquiries!
                add_to_hello_dialog: @contact_person!add_to_hello_dialog!
                password: @password!
                generate_password: @generate_password!

        api.call 'suppliers.create_or_update_supplier' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            if @create!
                app.notifier.notify "Successfully created new relation #{@supplier!name!}.", 'success'
                m.route.set '/crm/relations'
            else
                app.notifier.notify 'Successfully updated relation.', 'success'
            app.$m.common.observable.broadcast 'suppliers_updated', ''

    complete_supplier_url: ~>
        if @supplier!url! and not @supplier!url!match(/^https?:/)
            @supplier!url 'http://' + @supplier!url!

    primary_warehouse: ~>
        return @warehouses! |> find (.name == @primary_warehouse_name!)

    base_url: ~>
        "/crm/relations/#{@supplier!artkey!}"

    contact_person_edit_url: (contact_person) ~>
        "#{@base_url!}/contact-persons/#{contact_person.artkey}/edit"

    contact_person_create_url: ~>
        if @create_from_lead! or @create! then
            @create_contact_person true
        else
            m.route.set "#{@base_url!}/contact-persons/create"

    warehouse_edit_url: (warehouse) ~>
        "#{@base_url!}/edit/warehouses/#{warehouse.artkey}/edit"

    warehouse_select_url: ~>
        "#{@base_url!}/edit/warehouses/select"

    clear_cache: ~>
        api.call-and-then 'suppliers.clear_portal_cache_for_supplier', {
            supplier_artkey: @supplier!artkey!
        }, do
            success: (resp) ~>
                app.notifier.notify "Portal cache cleared for #{@supplier!name!}. Price list generation takes about 10 seconds.", 'info'

    setTab: (tabName) ~>
        this.data.tab = tabName
        m.route.set("#{this.base_url()}/edit?tab=#{tabName}")


    load_msi_supplier: (supplier_artkey) ~>
        if current_account_slug() == "msi"
            return
        @relation_api.load_alternative_supplier(supplier_artkey).subscribe(
            next: (response) ~>
                @data.msi_supplier_artkey = response.alternate_relation_artkey
        )

    import_price_lists_from_msi: (import_from) ~>
        @relation_api.import_price_lists(import_from, @data.msi_supplier_artkey).subscribe(
            next: (response) ~>
                app.notifier.notify "Transferred #{response.rowcount} price lists from MSI.", 'info'
                m.redraw!
        )

    view: ->
        if @supplier! == null
            return m Spinner

        invalid = invalid_fields(this.$v)

        turnover = this.data.turnover
        tokens = this.data.tokens

        # Left-hand column for creating/editing the relation.
        supplier_name_disabled = @supplier!has_sales_orders! or @supplier!has_purchase_orders!
        address_hint = ''
        if @supplier!longitude_latitude_checked_on! != null and @supplier!longitude! == null
            address_hint = 'address not verified'
        else if @supplier!longitude_latitude_checked_on! == null and @supplier!longitude! == null
            address_hint =  'address is not yet verified'
        else if @supplier!longitude_latitude_checked_on! != null and @supplier!longitude! != null
            address_hint =  'address verified and found'

        m '.c-crm-relations-edit view',
            m '.btn-toolbar',
                m 'button.btn.btn-default',
                    type: "button"
                    onclick: ~> m.route.set '/crm/relations'
                    m 'span.glyphicon.glyphicon-arrow-left'
                    ' Back to list'

                if @supplier! and @supplier!artkey! then [

                    m 'button.btn.btn-danger',
                        type: 'button'
                        onclick: ~> m.route.set "/crm/relations/#{@supplier!artkey!}/deactivate"
                        m 'span.glyphicon.glyphicon-trash'
                        ' Deactivate Relation'
                    m 'button.btn.btn-warning',
                        type: 'button'
                        onclick: ~> @clear_cache!
                        m 'span.glyphicon.glyphicon-flash'
                        ' Clear cache + Portal price list'
                    if @data.msi_supplier_artkey
                        m Tippy, {
                            content: "This action will: <ul><li>Transfer all the price lists from MSI account to MSP.</li><li>Remove #{@supplier!name!} as a supplier from the MSI account.</li></ul>"
                        },
                            m 'button.btn.btn-warning',
                                type: 'button'
                                onclick: ~> @import_price_lists_from_msi @supplier!artkey!
                                ' Make MSP relation'
                ]

            m '.c-tabs',
                m 'ul.nav.nav-tabs',
                    m 'li' {
                        class: classes('nav-link', {active: this.data.tab === 'relation'})
                        onclick: ~> this.setTab('relation')
                    },
                        m 'a' 'Relation'
                    m 'li', {
                        class: classes('nav-link', {active: this.data.tab === 'contacts', disabled: this.create()})
                        onclick: ~> if !this.create() then this.setTab('contacts')
                    },
                        m 'a' 'Contacts'
                    m 'li', {
                        class: classes('nav-link', {active: this.data.tab === 'hide_information', disabled: this.create()})
                        onclick: ~> if !this.create() then this.setTab('hide_information')
                    },
                        m 'a' 'Visibility'
                    m 'li', {
                        class: classes('nav-link', {active: this.data.tab === 'destinations', disabled: this.create()})
                        onclick: ~> if !this.create() then this.setTab('destinations')
                    },
                        m 'a' 'Destinations'
                    m 'li' {
                        class: classes('nav-link', {active: this.data.tab === 'logbook', disabled: this.create()})
                        onclick: ~> if !this.create() then this.setTab('logbook')
                    },
                        m 'a' 'Logbook'
                    m 'li', {
                        class: classes('nav-link', {active: this.data.tab === 'attachments', disabled: this.create()})
                        onclick: ~> if !this.create() then this.setTab('attachments')
                    },
                        m 'a' 'Attachments'


                m '.c-tab' {class: classes({active: this.data.tab === 'relation'})},
                    m 'form.flex-form' {onsubmit: @save},

                        m '.fieldset-group',
                            m '.fieldset',
                                m '.fieldset-label' 'General Information'
                                if not @create!
                                    inputs.text @supplier!relation_nr, {
                                        disabled: true,
                                        label: 'Relation ID',
                                        help: 'A unique immutable number that identifies this relation'
                                    }

                                inputs.text @supplier!name,
                                    placeholder: "Relation name"
                                    autocomplete: 'off'
                                    disabled: !app.$s.identity.user.is_superuser and supplier_name_disabled
                                    label: 'Relation name'
                                    help:
                                        if app.$s.identity.user.is_superuser then
                                            '(!) This field would normally be disabled; proceed with caution'
                                        else 'Becomes unchangeable after the first sales/purchase order has been made'
                                    validation: this.$v.relation_name,

                                m DropDownWithSelect,
                                    label: 'Company type',
                                    selected: @supplier!company_type!
                                    validation: this.$v.company_type,
                                    empty_option: empty_option("Select Company type...")
                                    onchange: (chosen) ~>
                                        @supplier!company_type chosen
                                ,
                                    app.$m.data.company_types.map((company) ->
                                        m DropDownOption,
                                            value: company
                                            company
                                        )


                                m DropDownWithSelect,
                                    label: 'Client status'
                                    selected: @supplier!client_status!
                                    empty_option: empty_option("Select Client status...")
                                    onchange: (chosen) ~>
                                        @supplier!client_status chosen
                                    validation: this.$v.client_status,
                                ,
                                    app.$m.data.client_statuses.map((status) ->
                                        m DropDownOption,
                                            value: status
                                            status
                                        )

                                m '.field-group',
                                    m UserDropDown,
                                        label: 'Manager (purchase)'
                                        selected_user_artkey: "#{@supplier!purchase_manager!artkey!}"
                                        onchange: (user_artkey) ~>
                                            if user_artkey == ''
                                                @supplier!purchase_manager!artkey user_artkey
                                            else
                                                @supplier!purchase_manager!artkey +user_artkey
                                        validation: this.$v.purchase_manager_artkey,

                                m '.field-group',
                                    m UserDropDown,
                                        label: 'Manager (sales)'
                                        selected_user_artkey: "#{@supplier!sales_manager!artkey!}"
                                        onchange: (user_artkey) ~>
                                            if user_artkey == ''
                                                @supplier!sales_manager!artkey user_artkey
                                            else
                                                @supplier!sales_manager!artkey +user_artkey
                                        validation: this.$v.sales_manager_artkey,

                                m '.field-group',
                                    m UserDropDown,
                                        label: 'Manager (price list)'
                                        selected_user_artkey: "#{@supplier!price_list_manager!artkey!}"
                                        onchange: (user_artkey) ~>
                                            if user_artkey == ''
                                                @supplier!price_list_manager!artkey user_artkey
                                            else
                                                @supplier!price_list_manager!artkey +user_artkey
                                        validation: this.$v.price_list_manager_artkey,

                                m CountriesSelect,
                                    empty_option: empty_option("Select a country...")
                                    selected: @supplier!country_code!
                                    label: 'Country'
                                    onchange: (value) ~>
                                        this.supplier().country_code(value)
                                        this.update_regions(value)
                                    validation: this.$v.country_code,

                                m FieldVatId, {
                                    country_code: @supplier!country_code!,
                                    disabled: !european_union_countries.includes(@supplier!country_code!)
                                    ref: [@supplier, 'vat_id'],
                                    validation: this.$v.vat_id,
                                }

                                m DropDownWithSelect,
                                    help: 'Currency to trade in'
                                    label: 'Currency'
                                    selected: @supplier!currency!
                                    onchange: (chosen) ~>
                                        @supplier!currency chosen
                                    validation: this.$v.currency
                                ,
                                    app.$s.currencies.all.map((currency) ->
                                        m DropDownOption,
                                            value: currency
                                            currency
                                        )

                                inputs.textarea @supplier!memo, {label: 'Relation memo'}

                            m '.fieldset',
                                m '.fieldset-label' 'Address & Media'

                                m DropDownWithSearch,
                                    disabled: @regions!length == 0
                                    help:
                                        if @supplier!region_code! and @regions!filter((regio) ~> regio.code == @supplier!region_code!).some((regio) ~> regio.is_in_eu_tax_alliance)
                                            "This region is in the eu-tax-alliance"
                                    label: 'Region'
                                    selected: @supplier!region_code!
                                    onchange: (region_code) ~>
                                        @supplier!region_code region_code
                                        m.redraw!
                                    empty_option: empty_option("Select a region...")
                                    validation: this.$v.region_code
                                    @regions!map((region, index) ->
                                        m DropDownOption,
                                            value: region.code,
                                            "#{region.code} - #{region.name}"
                                    )

                                inputs.text @supplier!city, {
                                    help: address_hint,
                                    label: 'City',
                                    validation: this.$v.city
                                }

                                m '.field-group',
                                    inputs.text @supplier!street_address, {
                                        label: 'Address',
                                        validation: this.$v.street_address
                                    }

                                    inputs.text @supplier!zip_code, {
                                        label: 'Zip code',
                                        validation: this.$v.zip_code
                                    }

                                inputs.text @supplier!telephone_number, {
                                    label: 'Phone number',
                                    placeholder: '+31501234567',
                                }

                                inputs.text @supplier!url, {
                                    label: 'Website',
                                    onblur: @complete_supplier_url
                                    validation: this.$v.url
                                    placeholder: 'https://company.org',
                                }
                                inputs.checkbox @supplier!operates_online, {
                                    help: 'Relation has a webshop',
                                    label: 'Operates online'
                                }

                                field 'Email address', m 'input.form-control' do
                                    type: 'email'
                                    autocomplete: 'off'
                                    placeholder: 'info@company.org'
                                    value: @supplier!emailaddress! or ''
                                    oninput: (ev) ~> @supplier!emailaddress ev.target.value
                                    onblur: (ev) ~>
                                        if not @supplier!emailaddress_financial_documents!
                                            @supplier!emailaddress_financial_documents ev.target.value

                                inputs.text @supplier!emailaddress_financial_documents, {
                                    label: 'Email address (Finance)',
                                    placeholder: 'finance@company.org'
                                }

                                inputs.text @supplier!emailaddress_rfp_documents, {
                                    label: 'Email address (RFP)'
                                }

                                m '.field-group',
                                    inputs.text @supplier!linkedin, {
                                        icon: 'fab fa-linkedin',
                                        label: 'LinkedIn',
                                        placeholder: 'linkedin.com/company/...',
                                    }
                                    inputs.text @supplier!facebook, {
                                        icon: 'fab fa-facebook',
                                        label: 'Facebook',
                                        placeholder: 'facebook.com/...'
                                    }
                                m '.field-group',
                                    inputs.text @supplier!instagram, {
                                        icon: 'fab fa-instagram',
                                        label: 'Instagram',
                                        placeholder: 'instagram.com/...'
                                    }
                                    inputs.text @supplier!twitter, {
                                        icon: 'fab fa-twitter',
                                        label: 'Twitter',
                                        placeholder: 'twitter.com/...'
                                    }

                        m '.fieldset-group',
                            m '.fieldset',
                                m '.fieldset-label' 'Suppliers'

                                m DropDownWithSelect,
                                    disabled: @supplier!has_purchase_orders!
                                    help:
                                        if @supplier!has_purchase_orders! then 'Supplier already has purchase orders'
                                        else 'Purchase ledger for purchases from this supplier'
                                    label: 'Purchase ledger'

                                    selected: @supplier!purchase_ledger_artkey!
                                    empty_option: empty_option("Select ledger...")
                                    validation: this.$v.purchase_ledger_artkey
                                    onchange: (chosen) ~>
                                        if chosen
                                            @supplier!purchase_ledger_artkey +chosen
                                        else
                                            @supplier!purchase_ledger_artkey null
                                ,
                                    [[ledger.artkey!, ledger.description!] for ledger in @purchase_ledgers!].map((ledger) ->
                                        m DropDownOption,
                                            value: ledger[0]
                                            ledger[1]
                                        )

                                inputs.checkbox @supplier!is_supplier, {
                                    help: 'We may buy products from this relation',
                                    label: 'Relation is supplier'
                                }

                                inputs.checkbox @supplier!should_receive_purchase_mails, {
                                    disabled: !@supplier!is_supplier!,
                                    help: 'Purchase mails are sent to relation contacts',
                                    label: 'Receive purchase mails',
                                }

                                m DropDownWithSelect,
                                    disabled: !@supplier!is_supplier!
                                    help: if @supplier!is_supplier! then 'Payment terms this supplier uses' else help.supplier_only
                                    label: 'Purchase terms'
                                    selected: @supplier!purchase_payment_term_artkey!
                                    empty_option: empty_option("Select payment terms...")
                                    validation: this.$v.purchase_payment_term_artkey
                                    onchange: (chosen) ~>
                                        if chosen
                                            @supplier!purchase_payment_term_artkey +chosen
                                        else
                                            @supplier!purchase_payment_term_artkey null
                                ,
                                    [[term.artkey!, term.description!] for term in @payment_terms!].map((term) ->
                                        m DropDownOption,
                                            value: term[0]
                                            term[1]
                                        )

                                m DropDownWithSelect,
                                    disabled: !@supplier!is_supplier!
                                    help:
                                        if @supplier!is_supplier! then 'Relates to the shelf life of supplier pricelists from this supplier'
                                        else help.supplier_only
                                    label: 'Pricelist refresh rate'
                                    selected: @price_list_frequency_select!
                                    empty_option: empty_option("Select pricelist sustainability...")
                                    onchange: (frequency) ~>
                                        @price_list_frequency_select frequency
                                    validation: this.$v.price_list_frequency
                                ,
                                    app.$m.data.price_list_frequencies_as_table.map((frequency) ->
                                        m DropDownOption,
                                            value: frequency[0]
                                            frequency[1]
                                        )

                                # If the option 'other' is selected in the pricelist frequency
                                # input: render two inputs for a custom pricelist frequency.
                                if @price_list_frequency_select! == 'other' then
                                    m '.field',
                                        m 'label.col-md-4.control-label' ''
                                        m '.col-md-2',
                                            inputs.number @price_list_frequency_amount
                                        m '.col-md-6',
                                            inputs.radio @price_list_frequency_unit, [
                                                {value: 'days', description: 'Days'}
                                                {value: 'weeks', description: 'Weeks'}
                                                {value: 'months', description: 'Months'}
                                                {value: 'years', description: 'Years'}
                                            ]


                            m '.fieldset',
                                m '.fieldset-label' 'Buyers'
                                m DropDownWithSelect,
                                    help:
                                        if @supplier!has_sales_orders! then help.has_sales_orders
                                        else if @supplier!is_buyer! then 'Sales ledger for purchases from this buyer'
                                        else help.buyer_only
                                    label: 'Sales ledger'
                                    selected: @supplier!sales_ledger_artkey!
                                    disabled: @supplier!has_sales_orders!
                                    empty_option: empty_option("Select ledger...")
                                    validation: this.$v.sales_ledger_artkey
                                    onchange: (chosen) ~>
                                        if chosen
                                            @supplier!sales_ledger_artkey +chosen
                                        else
                                            @supplier!sales_ledger_artkey null
                                ,
                                    [[ledger.artkey!, ledger.description!] for ledger in @sales_ledgers!].map((ledger) ->
                                        m DropDownOption,
                                            value: ledger[0]
                                            ledger[1]
                                        )

                                inputs.checkbox @supplier!is_buyer, {
                                    help: 'This relation may buy products from us'
                                    label: 'Relation is buyer',
                                }

                                inputs.checkbox @supplier!should_receive_offer_mails, {
                                    disabled: !@supplier!is_buyer!,
                                    help: 'Offer mails are sent to relation contacts',
                                    label: 'Receive offer mails',
                                }

                                m DropDownWithSelect,
                                    disabled: !@supplier!is_buyer!
                                    help: if @supplier!is_buyer! then 'Payment terms we use for this buyer' else help.buyer_only
                                    label: 'Sales terms'
                                    selected: @supplier!sales_payment_term_artkey!
                                    empty_option: empty_option("Select payment terms...")
                                    validation: this.$v.sales_payment_term_artkey
                                    onchange: (chosen) ~>
                                        if chosen
                                            @supplier!sales_payment_term_artkey +chosen
                                        else
                                            @supplier!sales_payment_term_artkey null
                                ,
                                    [[payment_term.artkey!, payment_term.description!] for payment_term in @payment_terms!].map((name) ->
                                        m DropDownOption,
                                            value: name[0]
                                            name[1]
                                        )

                                m DropDownWithSelect,
                                    help:
                                        if @supplier!has_sales_orders! then help.has_sales_orders
                                        else if @supplier!is_buyer! then 'Sales ledger for low tariff purchases from this buyer'
                                        else help.buyer_only
                                    label: 'Sales ledger (low tariff)'
                                    selected: @supplier!low_tariff_sales_ledger_artkey!
                                    disabled: !@supplier!is_buyer! or @supplier!has_sales_orders!
                                    empty_option: empty_option("Select ledger...")
                                    onchange: (chosen) ~>
                                        if chosen
                                            @supplier!low_tariff_sales_ledger_artkey +chosen
                                        else
                                            @supplier!low_tariff_sales_ledger_artkey null
                                ,
                                    [[ledger.artkey!, ledger.description!] for ledger in @sales_ledgers!].map((ledger) ->
                                        m DropDownOption,
                                            value: ledger[0]
                                            ledger[1]
                                        )

                                m MoneyInput,
                                    help: 'Enables free shipping incentive in the portal'
                                    label: 'Minimum order amount'
                                    value: @supplier!minimum_order_amount!
                                    on_value: (price) ~> @supplier!minimum_order_amount price
                                    currency: @supplier!currency!

                                m PercentInput,
                                    help: 'Percentage added to the relation\'s prices in the portal'
                                    label: 'Price markup'
                                    value: @supplier!price_markup_percentage!
                                    minimum: -10
                                    on_value: (value) ~>
                                        @supplier!price_markup_percentage value

                        m   '.fieldset-group',
                            m '.fieldset',
                                m '.fieldset-label' 'Transport'
                                inputs.text @supplier!excise_id, {
                                    label: 'Excise ID',
                                    help: 'Identifies a registered trader within the excise community',
                                    placeholder: 'e.g. NL00000123ABC',
                                }
                                m '.field-group',
                                    m DropDownWithSelect,
                                        help:
                                            if !@supplier!portal_customs_visibility!
                                                ''
                                            else if (@supplier!portal_customs_visibility! == @supplier!customs_status!) or @supplier!customs_status! == 'T1'
                                                "Portal visibility is set to #{@supplier!portal_customs_visibility!}"
                                            else
                                                "Warning: Portal visibility is set to #{@supplier!portal_customs_visibility!}"
                                        label: 'Customs status'
                                        selected: @supplier!customs_status!
                                        onchange: (chosen) ~>
                                            @supplier!customs_status chosen
                                        validation: this.$v.customs_status
                                    ,
                                        [
                                            m DropDownOption,
                                                value: 'T1'
                                                'T1'
                                            m DropDownOption,
                                                value: 'T2'
                                                'T2'
                                        ]

                                    m DropDownWithSelect,
                                        label: 'Customs Visibility'
                                        help:
                                            if @supplier!portal_customs_visibility! == @supplier!customs_status! or @supplier!customs_status! == 'T1'
                                                "Supplier customs status is set to #{@supplier!customs_status!}"
                                            else
                                                "Warning! Supplier customs status is set to #{@supplier!customs_status!}"
                                        selected: @supplier!portal_customs_visibility!
                                        onchange: (customs_status) ~>
                                            @supplier!portal_customs_visibility customs_status
                                        empty_option: empty_option("Select visibility...")
                                        validation: this.$v.portal_customs_visibility
                                    ,
                                        app.$m.data.supplier_portal_customs_visibility.map((customs_status) ->
                                            m DropDownOption,
                                                value: customs_status[0]
                                                customs_status[1]
                                            )


                                if !this.create() then
                                    m DropDownWithSelect,
                                        label: 'Primary destination'
                                        help: 'Add or modify destinations from the "Destinations" tab'
                                        selected: @primary_warehouse_name!
                                        empty_option: empty_option("Select a destination...")
                                        onchange: (chosen) ~>
                                            @primary_warehouse_name chosen
                                    ,
                                        @warehouse_names!map((name) ->
                                            m DropDownOption,
                                                value: name
                                                name
                                            )

                                m IncotermsDropDown,
                                    empty_option: empty_option("Select Incoterms...")
                                    help:
                                        if not @supplier!incoterm! or @supplier!incoterm! in ['EXW', 'FCA'] then
                                            'No transport costs will be calculated'
                                        else if @supplier!incoterm! and @primary_warehouse!
                                            "Incoterm of warehouse: #{@supplier!incoterm!} - #{@primary_warehouse!city} - #{countries[@primary_warehouse!country_code]} (#{@primary_warehouse!name})."
                                        else
                                            'Incoterm: EXW - Roosendaal - Netherlands (Loendersloot).'
                                    label: 'Incoterms'
                                    selected_incoterm: @supplier!incoterm!
                                    get_all_for_drop_down_response$: IncotermsDropDownData.incoterms()
                                    onchange: (incoterm) ~> @supplier!incoterm incoterm

                                m DropDownWithSelect,
                                    disabled: @supplier!incoterm! not in ['EXW', 'FCA']
                                    label: 'Payment method'
                                    help: if @supplier!incoterm! in ['EXW', 'FCA'] then 'Transport costs payment method' else 'Only with Ex works & Free Carrier Incoterm'
                                    selected: @supplier!transport_costs_payment_method!
                                    empty_option: empty_option("Select payment method...")
                                    onchange: (chosen) ~>
                                        @supplier!transport_costs_payment_method chosen
                                ,
                                    app.$m.data.supplier_transport_costs_payment_methods.map((weare_item) ->
                                        m DropDownOption,
                                            value: weare_item[0]
                                            weare_item[1]
                                        )

                                m MoneyInput,
                                    label: 'Transport costs per case'
                                    help: 'Added to the price on the price list & portal'
                                    value: @supplier!transport_costs_per_case!
                                    on_value: (price) ~> @supplier!transport_costs_per_case price
                                    currency: @supplier!currency!

                                inputs.checkbox @supplier!use_incoterm_on_pricelist, {
                                    disabled: !(@supplier!incoterm! and @primary_warehouse!),
                                    help: if (@supplier!incoterm! and @primary_warehouse!) then 'Please account for potential transport costs!' else 'Only for relations with an incoterm & primary warehouse set',
                                    label: 'Use selected incoterm on pricelist and portal',
                                }

                                if @primary_warehouse! and @supplier!use_incoterm_on_pricelist!
                                    m '.alert alert-danger', a do
                                        m 'span' 'The following incoterms will be applied for this relation:'
                                        m 'br'
                                        m 'span.text-muted' "#{@supplier!incoterm!} - #{@primary_warehouse!city} - #{@primary_warehouse!country_code} (#{@primary_warehouse!name})"



                            m '.fieldset',
                                m '.fieldset-label' 'Portal Settings'
                                m '.field-readonly',
                                    m '.key' 'Price list generated on'
                                    m '.value' format-date-time @supplier!relation_price_list_generated_on!

                                m DropDownWithSelect,
                                    label: 'Portal level'
                                    help: 'Trial & demo accounts expire; normal requires 2fa login'
                                    selected: @supplier!portal_level!
                                    empty_option: empty_option("Select trust level...")
                                    onchange: (chosen) ~>
                                        @supplier!portal_level chosen
                                ,
                                    app.$m.data.supplier_portal_levels.map((weare_item) ->
                                        m DropDownOption,
                                            value: weare_item[0]
                                            weare_item[1]
                                        )

                                inputs.date @supplier!portal_account_expiry, {
                                    disabled: !(@supplier!portal_level! in ['trial', 'demo']),
                                    help:
                                        if @supplier!portal_level! in ['trial', 'demo'] then
                                            'Account becomes inactive after specified date'
                                        else 'Only enabled for trial & demo accounts'
                                    label: 'Account expiry',
                                }

                                m DropDownWithSelect,
                                    label: 'Price/quantity unit'
                                    selected: @supplier!price_preference!
                                    validation: this.$v.price_preference
                                    onchange: (pref) ~>
                                        @supplier!price_preference pref
                                ,
                                    price_quantity_preference.map((pref) ->
                                        m DropDownOption,
                                            value: pref[0]
                                            pref[1]
                                        )

                                inputs.checkbox @supplier!show_excise_in_portal, {
                                    help: 'Show excise prices separately, next to the product prices in the portal',
                                    label: 'Show excise in portal',
                                }
                                inputs.checkbox @supplier!include_excise_in_price, {
                                    disabled: @supplier!country_code! not in excise_countries,
                                    help:
                                        if @supplier!country_code! in excise_countries then "Include excise costs in all shown prices"
                                        else "Only enabled for countries: #{excise_countries.join(', ')}"
                                    label: "Include excise in price"
                                }

                                inputs.checkbox @supplier!include_ukds_in_price, {
                                    disabled: @supplier!currency! != 'GBP'
                                    help:
                                        if @supplier!currency! === 'GBP' then 'Need to add a UKDS sticker on bottles?'
                                        else 'Only in use when trading currency is set to GBP'
                                    label: 'Add UKDS sticker to price',
                                }

                                if @supplier!portal_customs_visibility! == 'both'
                                    inputs.checkbox @supplier!mix_customs_on_pricelist, {
                                        help: 'Relates to pricelists; no separate grey section',
                                        label: 'Mix products from different customs statuses'
                                    }

                                if @supplier!active_promotion! then
                                    m FieldCheckbox, {
                                        label: "Promotion: #{@supplier!active_promotion!name}"
                                        help: "Whether relation takes part in this active promotion"
                                        placeholder: 'No promotion selected...'
                                        ref: [this.data.$f, 'promotion_selected']
                                    }

                                if this.data.$f.promotion_selected then
                                    m '.promotion-stats mt-3',
                                        m '.stat',
                                            m '.key' 'Turnover:'
                                            m '.value' "#{format_money(turnover.redeemable)} redeemable (#{format_money(turnover.draft)} draft)"

                                        m '.stat',
                                             m '.key' 'Tokens Available:'
                                             m '.value' "#{tokens.redeemable - this.data.$f.tokens_spent} (x#{this.data.threshold})"

                                        m FieldText,
                                            label: 'Tokens redeemed'
                                            help: 'Tokens this customer already redeemed during the promotion'
                                            min: 0
                                            max: tokens.redeemable
                                            ref: [this.data.$f, 'tokens_spent']
                                            type: 'number'

                                        m MoneyInput,
                                            help: 'Amount to be subtracted from the total turnover'
                                            label: 'Penalty'
                                            value: this.data.$f.penalty
                                            on_value: (price) ~> this.data.$f.penalty = price
                                            currency: @supplier!currency!

                        # Separate contact person form when creating a new relation from a lead.
                        if @create_contact_person! or @create_from_lead! then
                            m '.fieldset',
                                m '.fieldset-label' 'Create contact person'
                                m 'form.flex-form',
                                    inputs.text @contact_person!first_name, {label: 'First name', validation: this.$v.contact_first_name}
                                    inputs.text @contact_person!family_name, {label: 'Family name', validation: this.$v.contact_family_name}
                                    inputs.text @contact_person!telephone_number, {label: 'Phone number'}
                                    inputs.text @contact_person!mobile_telephone_number, {label: 'Mobile number'}
                                    inputs.text @contact_person!emailaddress, {
                                        label: 'Email address',
                                        validation: this.$v.contact_emailaddress
                                    }
                                    inputs.date @contact_person!birthdate, {label: 'Birthdate', max: new Date(), min: '1900-01-01'}
                                    inputs.text @contact_person!position, {label: 'Position'}
                                    inputs.text @contact_person!salutation, {label: 'Salutation'}
                                    inputs.language @contact_person!language, {label: 'Language'}

                                    inputs.checkbox @contact_person!should_receive_mails, {
                                        checked: @contact_person!should_receive_mails!,
                                        help: 'Includes contact in several commercial mailingtypes',
                                        label: 'Receive commercial mailings'
                                    }

                                    if current_account_slug! == AccountSlug.MSP
                                        inputs.checkbox @contact_person!add_to_hello_dialog, {
                                            checked: @contact_person!add_to_hello_dialog!,
                                            help: 'Only active for Moving Spirits customers',
                                            label: 'Add contact to newsletter'
                                        }

                                    inputs.checkbox @contact_person!should_receive_purchase_enquiries, {
                                        checked: @contact_person!should_receive_purchase_enquiries!,
                                        label: 'Receive purchase enquiries'
                                    }

                                    inputs.checkbox @enable_portal_access, {
                                        checked: @has_portal_access!,
                                        disabled: @has_portal_access!,
                                        help: 'Contact person will get an email with login details for the portal after creation',
                                        label: 'Portal access'
                                    }

                                    if @enable_portal_access! then
                                        inputs.checkbox @custom_password, {
                                            help:
                                                if @generate_password! then
                                                    'By default, the portal user will receive a randomly generated password'
                                                else 'Use a custom password for this portal user'
                                            label: 'Customize password'
                                        }

                                    if @enable_portal_access! and @custom_password! then m '',
                                        inputs.password @password, {
                                            label: 'Password'
                                            help: 'Minimum of 8 characters with one lowercase and one uppercase letter'
                                            validation: this.$v.contact_password
                                        }
                                        inputs.password @repeat_password, {
                                            help: 'Type previous password again for verification purposes'
                                            label: 'Verify password',
                                            placeholder: 'Repeat password...',
                                            validation: this.$v.contact_password_verify
                                        }
                    m '.c-submit',
                        m 'button.btn.btn-success btn-submit' {
                            disabled: invalid.length,
                            onclick: this.save,
                        }, if @create! then 'Create Relation' else 'Update Relation'

                        invalid_fields_format(invalid)

                m '.c-tab' {class: classes({active: this.data.tab === 'destinations'})},
                    m '.btn-toolbar',
                        m 'button.btn.btn-default' do
                            type: 'button'
                            onclick: ~>
                                m.route.set "#{@base_url!}/edit/warehouses/create"
                        , (m 'span.glyphicon.glyphicon-plus'), ' Create Destination'
                        m 'button.btn.btn-default' do
                            type: 'button'
                            onclick: ~> m.route.set @warehouse_select_url!
                        , (m 'span.glyphicon.glyphicon-plus'), ' Select Destinations'

                    if @supplier! and @supplier!artkey! then
                        m Table, do
                            items: @warehouses
                            options:
                                autoscale: true
                                onclick: (w, e) ~~>
                                    m.route.set @warehouse_edit_url w
                            columns:
                                do
                                    name: 'Name'
                                    field: 'name'
                                    width: 15
                                do
                                    name: 'Address'
                                    function: (w) ->
                                        join-maybes ' ', [
                                            w.street_address
                                            w.zip_code
                                            w.city
                                        ]
                                    width: 40
                                do
                                    name: 'Country code'
                                    field: 'country_code'
                                    width: 10
                                do
                                    name: 'Location ID'
                                    field: 'warehouse_id'
                                    default_visible: false
                                    width: 15
                                do
                                    name: 'Excise ID'
                                    field: 'excise_id'
                                    default_visible: false
                                    width: 15
                                do
                                    name: 'Column selector'
                                    header: ' '
                                    value: ' '
                                    width: 1

                m '.c-tab' {class: classes({active: this.data.tab === 'contacts'})},
                    m '.btn-toolbar',
                        m 'button.btn.btn-default', do
                            type: 'button'
                            onclick: ~> @contact_person_create_url!
                        , (m 'span.glyphicon.glyphicon-plus'), ' Create contact'

                    if @contact_persons! != null
                        m Table, do
                            items: @contact_persons
                            options:
                                autoscale: true
                                onclick: (cp, e) ~~>
                                    m.route.set @contact_person_edit_url cp
                            columns:
                                do
                                    name: 'Name'
                                    field: 'name'
                                    width: 20
                                do
                                    name: 'Telephone nr.'
                                    field: 'telephone_number'
                                    width: 15
                                do
                                    name: 'Mobile tel.nr.'
                                    field: 'mobile_telephone_number'
                                    width: 15
                                do
                                    name: 'E-mail'
                                    field: 'emailaddress'
                                    width: 25
                                do
                                    name: 'Portal user'
                                    width: 15
                                    function: (record) ~>
                                        portal_user = find-active-portal-user record.portal_users
                                        if portal_user and !portal_user.is_deleted
                                            return link "/portal/portal-users/#{portal_user.artkey}/edit", portal_user.name, {'class': 'no-click'}
                                        else
                                            return m 'em' 'No portal user'
                                do
                                    name: 'Last seen'
                                    width: 15
                                    function: (record) ~>
                                        portal_user = find-active-portal-user record.portal_users
                                        if portal_user
                                            return format-date-time portal_user.last_seen_timestamp
                                        else
                                            return m 'em' '-'
                                do
                                    name: 'Salutation'
                                    field: 'salutation'
                                    width: 10
                                    default_visible: false
                                do
                                    name: 'Language'
                                    field: 'language'
                                    width: 10
                                    default_visible: false
                                    function: (record) ~>
                                        if record.language
                                            "#{languages[record.language]}"
                                        else
                                            '-'
                                do
                                    name: 'Column selector'
                                    header: ' '
                                    value: ' '
                                    width: 1

                m '.c-tab' {class: classes({active: this.data.tab === 'hide_information'})},
                    if ! @create!
                        m HiddenInformation,
                            hidden_information_helper: new HiddenRelationHelper @supplier!artkey!


                m '.c-tab' {class: classes({active: this.data.tab === 'logbook'})},
                    if not @create! and @supplier! then
                        m '.fieldset',
                            if @supplier! and @supplier!artkey! then [
                                m LogEntries, do
                                    log_entries: @supplier!log_entries!
                                m AddLogEntry, do
                                    artkey: @supplier!artkey
                                    process: 'suppliers.add_log_entry'
                                    on_add_callback: ~> @query_supplier(@supplier!artkey!)
                            ]

                m '.c-tab' {class: classes({active: this.data.tab === 'attachments'})},
                    if @supplier! and @supplier!artkey! then [
                        m AttachmentList, do
                            attachment_helper: @attachment_helper
                        m UploadAttachment, do
                            attachment_helper: @attachment_helper
                    ]

find-active-portal-user = (portal_users) ->
    for portal_user in portal_users
        if not portal_user.is_deleted then return portal_user
