m = require 'mithril'

{Chart} = require '@/lib/chart'


export create_time_line_graph = (graph_data, target) ->
    new Chart(target, do
        type: 'line',
        data: graph_data
        options: do
            scales: do
                x: do
                    type: 'time',
                    ticks: {
                        source: 'auto'
                    }
                y: do
                    beginAtZero: true
    )
