m = require 'mithril'
api = require '../api.ls'
{filter, find, sort-by, map} = require 'prelude-ls'
{Supplier} = require '@/models/suppliers'
app = require('@/app')

export class Delivery
    (json) ->
        @status = window.prop ''
        @external_reference = window.prop ''
        @weight_in_kilos = window.prop ''
        @number_of_euro_pallets = window.prop ''
        @number_of_block_pallets = window.prop ''
        @loading_in_meters = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class Warehouse
    (json) ->
        @destination_type = window.prop ''
        @artkey = window.prop ''
        @name = window.prop ''
        @emailaddress = window.prop ''
        @currency = window.prop ''
        @warehouse_base_costs = window.prop ''
        @warehouse_costs_per_case = window.prop ''
        @street_address = window.prop ''
        @zip_code = window.prop ''
        @city = window.prop ''
        @country_code = window.prop ''
        @region_code = window.prop ''
        @warehouse_id = window.prop ''
        @vat_id = window.prop ''
        @excise_id = window.prop ''
        @can_handle_our_stock = window.prop false

        for prop of json
            @[prop] = window.prop json[prop]


export class DestinationsDataModel

    create_warehouse: (warehouse_json) ~>
        new Warehouse warehouse_json

export class VatType
    (json) ->
        @artkey = window.prop null
        @vat_code = window.prop ''
        @percentage = window.prop ''
        @description = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class VatTypeDataModel
    create: (vat_type_json) ~>
        new VatType vat_type_json


export class VatRate
    (json) ->
        @artkey = window.prop null
        @percentage = window.prop ''
        @country_code = window.prop ''
        @vat_type_artkey = window.prop ''
        @vat_type = window.prop new VatType

        for prop of json
            if prop == 'vat_type'
                @vat_type new VatType json[prop]
                @vat_type_artkey json[prop]['artkey']
            else
                @[prop] = window.prop json[prop]


export class VatRateDataModel
    ->
        @vat_rates = window.prop []
        api.call-and-then 'vatrates.get_all', {}, do
            success: (resp) ~>
                @vat_rates [@create vat_rate_json for vat_rate_json in resp.result]
                app.$m.common.observable.broadcast 'vatrates_loaded', @vat_rates

    create: (json) ~>
        new VatRate json

    get_vat_rates_by_country: (country_code) ~>
        @vat_rates!filter (vat_rate) ->
            vat_rate.country_code! == country_code


export class LedgerType
    @PURCHASING = 'Purchase'
    @SALES = 'Sales'

export class Ledger
    (json) ->
        @artkey = window.prop null
        @ledger_type = window.prop ''
        @description = window.prop ''
        @ledger_code = window.prop ''
        @vat_type_artkey = window.prop ''
        @vat_type = window.prop new VatType

        for prop of json
            @[prop] = window.prop json[prop]
            if prop == 'vat_type'
                @vat_type_artkey json[prop].artkey
                @vat_type app.$m.data.vat_type.create json[prop]

    to_string: ->
        return @ledger_code! + ' - ' + @description!

export class LedgersDataModel
    query_all: (target, type) ~>
        set_ledgers_curried = @set_ledgers target
        api.call 'ledgers.get_all', {type: type}, set_ledgers_curried

    set_ledgers: (target, msg) ~~>
        target [@create_ledger ledger_json for ledger_json in msg.result]

    create_ledger: (ledger_json) ~>
        new Ledger ledger_json


export class InsuranceType
    (json) ->
        @artkey = window.prop ''
        @name = window.prop '1 - No transport insurance arranged by MSI / no insurance involved'
        @fee = window.prop 0.0

        for prop of json
            @[prop] = window.prop json[prop]


export class InsuranceTypeDataModel
    create_insurance_type: (insurance_type_json) ~>
        new InsuranceType insurance_type_json


export class ContactPerson
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @first_name = window.prop ''
        @family_name = window.prop ''
        @birthdate = window.prop ''
        @telephone_number = window.prop ''
        @mobile_telephone_number = window.prop ''
        @emailaddress = window.prop ''
        @position = window.prop ''
        @salutation = window.prop ''
        @language = window.prop ''
        @should_receive_mails = window.prop false
        @should_receive_purchase_enquiries = window.prop false
        @add_to_hello_dialog = window.prop false
        @supplier_artkey = window.prop ''
        @supplier = window.prop new Supplier
        @add_to_hello_dialog = window.prop false

        for prop of json
            if prop == 'supplier'
                @supplier_artkey json[prop].artkey
                @supplier new Supplier json[prop]
            else
                @[prop] = window.prop json[prop]


export class ContactPersonDataModel
    create_contact_person: (json) ->
        new ContactPerson json


export class PaymentTerm
    (json) ->
        @artkey = window.prop ''
        @exact_payment_term_code = window.prop ''
        @description = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class PaymentTermDataModel
    query_all: (target) ~>
        set_payment_terms_curried = @set_payment_terms target
        api.call 'paymentterms.get_all', {}, set_payment_terms_curried

    set_payment_terms: (target, msg) ~~>
        target [@create_payment_term payment_term_json for payment_term_json in msg.result]

    create_payment_term: (payment_term_json) ~>
        new PaymentTerm payment_term_json


export class CommentTemplate
    (json) ->
        @artkey = window.prop null
        @title = window.prop ''
        @body = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class ItemTagCategoryCardinality
    @ONE = 'One'
    @MANY = 'Many'


export class ItemTagCategoryConstants
    @TAX_LABEL = 'Tax Label'


export class TaxLabelConstants
    @NO_TAX_LABEL = 'No Tax Label'


export class ItemTagCategory
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @cardinality = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]

    has_cardinality_one: ~>
        @cardinality! == ItemTagCategoryCardinality.ONE


export class ItemTagCategoryDataModel
    ->
        @categories = window.prop []
        api.call 'product_management.get_all_item_tag_categories', {}, @set_item_tag_categories

    set_item_tag_categories: (msg) ~>
        @categories [@create_category category_json for category_json in msg.result]
        app.$m.common.observable.broadcast 'item_tag_categories_loaded', @categories

    create_category: (category_json) ~>
        new ItemTagCategory category_json

    get_category_by_artkey: (artkey) ~>
        @categories! |> find (.artkey! == artkey)

    get_category_by_name: (name) ~>
        @categories! |> find (.name! == name)

    tax_label_category: ~>
        @get_category_by_name ItemTagCategoryConstants.TAX_LABEL

    all_categories_except_tax_label: ~>
        @categories! |> filter (.name! != ItemTagCategoryConstants.TAX_LABEL)


export class ItemTag
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @abbreviation = window.prop ''
        @category = window.prop new ItemTagCategory
        @category_artkey = window.prop ''

        for prop of json
            if prop == 'item_tag_category'
                @category_artkey json[prop].artkey
                @category app.$m.data.item_tag_category.get_category_by_artkey json[prop].artkey
            else
                @[prop] = window.prop json[prop]


export class ItemTagDataModel
    ->
        @tags = window.prop []
        app.$m.common.observable.subscribe 'item_tags_updated', @, @load_item_tags
        @load_item_tags!

    load_item_tags: ~>
        api.call 'product_management.get_all_item_tags', {}, @set_item_tags

    set_item_tags: (msg) ~>
        msg.result
        |> map @create_item_tag
        |> sort-by (.name!)
        |> @tags

        app.$m.common.observable.broadcast 'item_tags_loaded', @tags

    get_all: (artkeys) ~>
        @tags! |> filter (.artkey! in artkeys)

    create_item_tag: (item_tag_json) ~>
        new ItemTag item_tag_json

    get_item_tag: (artkey) ~>
        @tags! |> find (.artkey! == artkey)

    get_all_from_category: (category) ~>
        @tags! |> filter (.category_artkey! == category.artkey!)

    get_tag_names: (artkeys) ~>
        artkeys |> map @get_item_tag |> map (tag) -> tag.name!


export class EmailPlaceholderTranslation
    (json) ->
        @artkey = window.prop null
        @language_code = window.prop ''
        @translation = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class EmailPlaceholder
    (json) ->
        @artkey = window.prop null
        @placeholder = window.prop ''
        @description = window.prop ''
        @translations = window.prop []

        for prop of json
            @[prop] = window.prop json[prop]


export class EmailRecipientType
    @TO = "TO"
    @FROM = "FROM"
    @BCC = "BCC"
    @CC = "CC"


export class EmailAttachment
    @INVOICE = "INVOICE"
    @PROFORMA = "PROFORMA_INVOICE"
    @OUTTAKE = "OUTTAKE"
    @PDF_CONTENT_TYPE = "application/pdf"


export class BrandHolder
    (json) ->
        @artkey = window.prop null
        @name = window.prop ''
        @requires_import_statement = window.prop false

        for prop of json
            @[prop] = window.prop json[prop]


export class Brand
    (json) ->
        @artkey = window.prop null
        @name = window.prop ''
        @match_text = window.prop ''
        @brand_holder_artkey = window.prop ''
        @brand_holder = window.prop new BrandHolder

        for prop of json
            if prop == 'brand_holder'
                @brand_holder new BrandHolder json[prop]
                @brand_holder_artkey json[prop]['artkey']
            else
                @[prop] = window.prop json[prop]


export class AttachmentCategory
    (json) ->
        @artkey = window.prop null
        @name = window.prop ''
        @attachment_type = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]
