import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'

export interface InputNumberAttrs {
    classNames?: string[]
    label?: string
    help?: string
    required?: boolean
    disabled?: boolean
    minimum: number
    maximum?: number
    placeholder?: string
    /* Note that value has to be a number. The API call returns python decimals as string, so be sure to convert. */
    value: number | null
    onchange?: (value: number) => unknown
    oninput?: (value: number) => unknown
}

/**
 * Mithril component for a number input with some sane defaults.
 *
 * This is a replacement for the `inputs.number` livescript component.
 *
 * @param {boolean} required: The `required` value for the input.
 * @param {number} minimum: The `min` value for the input.
 * @param {number} value: The value for the input.
 * @param {string} placeholder: The `placeholder` value for the input
 * @param {(value: number) => unknown} onchange: Function to call on an onchange event.
 * @param {(value: number) => unknown} oninput: Function to call on an oninput event.
 */
export class NumberInput extends MithrilTsxComponent<InputNumberAttrs> {
    view(vnode: m.Vnode<InputNumberAttrs>): m.Children {
        const element = (
            <input
                required={vnode.attrs.required}
                type="number"
                min={vnode.attrs.minimum}
                max={vnode.attrs.maximum || null}
                step="1"
                disabled={vnode.attrs.disabled || false}
                placeholder={vnode.attrs.placeholder}
                value={vnode.attrs.value || null}
                onchange={(value: InputEvent) => {
                    if (vnode.attrs.onchange) {
                        vnode.attrs.onchange(+(value.target as HTMLInputElement)?.value)
                    }
                }}
                oninput={(value: InputEvent) => {
                    if (vnode.attrs.oninput) {
                        vnode.attrs.oninput(+(value.target as HTMLInputElement)?.value)
                    }
                }}
                className={'number-input no-click'}
            />
        )

        // Legacy format
        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-field-number', 'field', vnode.attrs.classNames)}>
                <label>{vnode.attrs.label}</label>
                {element}
                {vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </div>
        )
    }
}
