/**
 * Contains components relating to money and currency.
 */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'

import {$s} from '@/app'
import {CurrenciesDropDown} from '@/components/currencies'
import {GetAllCurrenciesResponse} from '@/factserver_api/fact2server_api'

interface DecimalInputAttrs {
    label?: string
    required?: boolean
    disabled?: boolean
    minimum?: number
    placeholder?: string
    additional_class?: string
    show_addon?: boolean /* default true */
    number_of_fraction_digits?: number

    /* Current value of the MoneyInput component. */
    value: number | null
    /* The value, if empty, value will be 0 if empty with is_empty true. */
    on_value: (value: number, is_empty: boolean) => unknown
    /* default false - the currency string should be wrapped in an object if you want to change the value in the parent component*/
    show_currency_edit?: boolean
}

class DecimalInputHelper {
    input_value = 0 // cached value as a number
    display_input_value = '' // cached value as a string
    show_addon = true
    show_currency_edit = false

    allowed_input: string[] = []

    minimum_value: number
    decimal_separator: string
    number_of_fraction_digits: number

    /* The percentage, if empty, price will be 0 if empty with is_empty true. */
    on_value: (value: number, is_empty: boolean) => unknown

    constructor(vnode: m.Vnode<DecimalInputAttrs>) {
        this.decimal_separator = $s.identity.user.decimal_locale === 'en' ? '.' : ','
        this.minimum_value = vnode.attrs.minimum || 0
        this.allowed_input = [this.decimal_separator, '-']

        this.number_of_fraction_digits = 2
        if (vnode.attrs.number_of_fraction_digits !== undefined) {
            this.number_of_fraction_digits = vnode.attrs.number_of_fraction_digits
        }

        this.on_value = vnode.attrs.on_value
        this.input_value = vnode.attrs.value || 0
        this.display_input_value = this.format_display_value(this.input_value)

        if (vnode.attrs.show_addon !== undefined) {
            this.show_addon = vnode.attrs.show_addon
        }

        if (vnode.attrs.show_currency_edit !== undefined) {
            this.show_currency_edit = vnode.attrs.show_currency_edit
        }
    }

    /** Format the number value to a displayable value (2 digits, "" if null) */
    format_display_value(value: number | null): string {
        if (!value) {
            return ''
        }
        return (+value).toFixed(this.number_of_fraction_digits).replace('.', this.decimal_separator)
    }

    /** Transform a string (display value) to a number, taking decimal separator into account. */
    transform_display_value_to_number(value: string): number {
        if (!value) {
            return 0
        }
        /* -0 will render as '-' */
        if (value === '-') {
            return -0
        }
        return +value.replaceAll(this.decimal_separator, '.')
    }

    /** Updates the string representation and the number value AND signals the change to the parent. */
    update_cached_values(value: string): void {
        this.display_input_value = value
        this.input_value = this.transform_display_value_to_number(value)

        this.on_value(this.input_value, this.display_input_value === '')
    }

    /** Set parent number value, and set internal store as-is in the input. */
    onupdate(vnode: m.Vnode<MoneyInputAttrs>): void {
        if (vnode.attrs.value !== this.input_value) {
            this.input_value = vnode.attrs.value || 0
            this.display_input_value = this.format_display_value(vnode.attrs.value)
        }
    }

    oninput(input_event: InputEvent): void {
        const target = input_event.target as HTMLInputElement
        const value = target.value

        this.validate(value, target)
        this.update_cached_values(value)
    }

    /** Return true if valid input, false otherwise. */
    validate(value: string, target: HTMLInputElement): boolean {
        target.setCustomValidity('')
        // validate the input values
        if (this.minimum_value !== undefined && +value < this.minimum_value) {
            target.setCustomValidity(`Minimum value is ${this.minimum_value}`)
        }
        return target.checkValidity()
    }

    keypress(keyboard_event: KeyboardEvent): boolean {
        const key_value = keyboard_event.key
        const input_already_contains_decimal_separator = this.display_input_value.includes(this.decimal_separator)
        if (input_already_contains_decimal_separator && key_value === this.decimal_separator) {
            return false
        }
        if (isNaN(+key_value) && !this.allowed_input.includes(key_value)) {
            return false
        }
        return true
    }

    /** Set parent number value, and set internal store to the formatted representation. */
    onchange(input_event: InputEvent): void {
        const value = (input_event.target as HTMLInputElement).value
        this.update_cached_values(value)
    }
}

interface MoneyInputAttrs extends DecimalInputAttrs {
    auto_focus?: boolean
    currency?: string | GetAllCurrenciesResponse
    on_value_currency?: (value: string) => any
    validation?: any
}

/**
 * Mithril component for a money input.
 *
 * This is a replacement for the `inputs.Price` livescript component.
 * This component keeps its internal state even when external `m.redraw`s are
 * issued. This makes it possible to use `oninput` for immediate feedback
 * after typing a character in the input - without this internal state, the
 * cursor would immediately jump to the end.
 *
 * The internal store (this.display_input_value) is a formatted string representation of the number
 * property this.input_value.
 * The parent's value (given with attrs.price) is set with attrs.set_price.
 *
 * Usage:
 * <MoneyInput
 *     value={soi.price_per_case}
 *     currency={sales_order.was_sold_in}
 *     required={true}
 *     on_value={(value: number) => (soi.price_per_case = value)}
 *     on_value_currency={(value: string) => (soi.currency = value)}
 * />
 *
 * @param {number | null} price: Value of the input.
 * @param {(value: number | null) => void} set_price: Function to process the input's
 *     value after an `oninput` or an `onchange`.
 * @param required: If the value is required.
 * @param minimum: Minimum value for the input component (default 0).
 * @param placeholder: Placeholder for the input component.
 * @param currency: The currency to display.
 * @param show_currency: Whether to show the currency addon (default true).
 */
export class MoneyInput extends MithrilTsxComponent<MoneyInputAttrs> {
    decimal_input_helper: DecimalInputHelper

    constructor(vnode: m.Vnode<MoneyInputAttrs>) {
        super()
        this.decimal_input_helper = new DecimalInputHelper(vnode)
    }

    onupdate(vnode: m.Vnode<MoneyInputAttrs>): void {
        this.decimal_input_helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<MoneyInputAttrs>): m.Children {
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false

        return (
            <div className={classes('c-field-money', 'field', 'no-click', vnode.attrs.additional_class, {
                disabled: vnode.attrs.disabled,
                valid: validation && !invalid && validation.dirty,
                invalid: invalid && validation.dirty,
            })}>
                {vnode.attrs.label && (
                    <label>{vnode.attrs.label}
                        {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                        {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <div className='control'>
                    <input
                        required={vnode.attrs.required}
                        disabled={vnode.attrs.disabled}
                        min={vnode.attrs.minimum}
                        type="text"
                        placeholder={vnode.attrs.placeholder}
                        value={this.decimal_input_helper.display_input_value}
                        onkeypress={(keyboard_event: KeyboardEvent) => this.decimal_input_helper.keypress(keyboard_event)}
                        oninput={(input_event: InputEvent) => {
                            if (vnode.attrs.validation) {
                                vnode.attrs.validation.dirty = true
                            }
                            return this.decimal_input_helper.oninput(input_event)
                        }}
                        onchange={(input_event: InputEvent) => {
                            return this.decimal_input_helper.onchange(input_event)
                        }}
                        oncreate={(dom) => {
                            if (vnode.attrs.auto_focus) {
                                const input_dom = dom.dom as HTMLInputElement
                                input_dom.focus()
                            }
                        }}
                    />
                    {(vnode.attrs.currency && !this.decimal_input_helper.show_currency_edit) && (
                        <span class="control-addon">{vnode.attrs.currency}</span>
                    )}
                    { this.decimal_input_helper.show_currency_edit && (
                        <CurrenciesDropDown
                            disabled={vnode.attrs.disabled}
                            selected_currency={vnode.attrs.currency}
                            onchange={(value: GetAllCurrenciesResponse | null) => {
                                if (vnode.attrs.on_value_currency) {
                                    vnode.attrs.on_value_currency(value?.currency || '')
                                }
                            }}
                        />
                    )}
                </div>
                {(() => {
                    if (invalid && validation.dirty) return <div className="help validation">{invalid.message}</div>
                    else if (vnode.attrs.help) return <div className="help">{vnode.attrs.help}</div>
                })()}
            </div>
        )
    }
}

type PercentInputAttrs = DecimalInputAttrs

/**
 * Input a percentage, while dealing with the users decimal separator setting.
 */
export class PercentInput extends MithrilTsxComponent<PercentInputAttrs> {
    decimal_input_helper: DecimalInputHelper

    constructor(vnode: m.Vnode<PercentInputAttrs>) {
        super()

        this.decimal_input_helper = new DecimalInputHelper(vnode)
    }

    onupdate(vnode: m.Vnode<MoneyInputAttrs>): void {
        this.decimal_input_helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<PercentInputAttrs>): m.Children {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        return (
            <div class={classes('c-field-percent field', vnode.attrs.additional_class, {
                invalid: validation && invalid && validation.dirty,
                valid: validation && !invalid && validation.dirty,
            })}>
                {vnode.attrs.label && <label>{vnode.attrs.label}</label>}
                <div className="control">
                    <input
                        required={vnode.attrs.required}
                        disabled={vnode.attrs.disabled}
                        type="text"
                        placeholder={vnode.attrs.placeholder}
                        value={this.decimal_input_helper.display_input_value}
                        onkeypress={(keyboard_event: KeyboardEvent) => this.decimal_input_helper.keypress(keyboard_event)}
                        oninput={(input_event: InputEvent) => {
                            if (vnode.attrs.validation) {
                                vnode.attrs.validation.dirty = true
                            }

                            return this.decimal_input_helper.oninput(input_event)
                        }}
                        onchange={(input_event: InputEvent) => this.decimal_input_helper.onchange(input_event)}
                    />
                    {this.decimal_input_helper.show_addon && <span class="control-addon">%</span>}
                </div>
                {(() => {
                    if (invalid && validation.dirty) return <div className="help validation">{invalid.message}</div>
                    else if (vnode.attrs.help) return <div className="help">{vnode.attrs.help}</div>
                })()}
            </div>
        )
    }
}

/**
 * Input a decimal, while dealing with the users decimal separator setting.
 */
export class DecimalInput extends MithrilTsxComponent<DecimalInputAttrs> {
    decimal_input_helper: DecimalInputHelper

    constructor(vnode: m.Vnode<DecimalInputAttrs>) {
        super()

        this.decimal_input_helper = new DecimalInputHelper(vnode)
    }

    onupdate(vnode: m.Vnode<DecimalInputAttrs>): void {
        this.decimal_input_helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<DecimalInputAttrs>): m.Children {
        const element = (
            <div class="input-group no-click">
                <input
                    required={vnode.attrs.required}
                    disabled={vnode.attrs.disabled}
                    type="text"
                    placeholder={vnode.attrs.placeholder}
                    value={this.decimal_input_helper.display_input_value}
                    onkeypress={(keyboard_event: KeyboardEvent) => this.decimal_input_helper.keypress(keyboard_event)}
                    oninput={(input_event: InputEvent) => this.decimal_input_helper.oninput(input_event)}
                    onchange={(input_event: InputEvent) => this.decimal_input_helper.onchange(input_event)}
                    class={`number-input ${vnode.attrs.additional_class || ''}`}
                />
            </div>
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-decimal-input field', vnode.attrs.className)}>
                {!!vnode.attrs.label && <label>{vnode.attrs.label}</label>}
                {element}
                {!!vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </div>
        )
    }
}
