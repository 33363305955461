import {DataCard, Link, PanelContext} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import m from 'mithril'
import {accountIconBySlug} from 'accounts.ls'
import {api, notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {$m, $s} from '@/app'
import {AddToOffer} from '@/market/components/add_to_offer'
import {AddToOrder} from '@/purchase_orders/components/add_to_order'
import {ResolveSourceLine} from '@/market/components/resolve_source_line'
import {download_binary_file_from_base64_str} from '@/utils.ls'
import {UpsertSpli} from '@/market/components/upsert_spli'
import {model} from '@/market/list_pricelist_items'

export class PanelSpli extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        const spl = model.supplier_pricelist as any
        return <PanelContext collection={vnode.attrs.collection}>
            <div className="content">
                {$s.context.name === 'data_card' && <DataCard
                    model={{
                        data: [
                            {
                                label: 'Relation',
                                value: <Link href={`/crm/relations/${spl.supplier.artkey}/edit`}>
                                    {accountIconBySlug(spl.supplier.sales_account.slug, 'Account')}
                                    {spl.supplier.name}
                                </Link>,
                            },
                            {label: 'Creation date', value: format_iso_to_date(spl.created_on)},
                            {label: 'Start date', value: format_iso_to_date(spl.start_date)},
                            {label: 'End date', value: format_iso_to_date(spl.end_date)},
                            {label: 'Description', value: spl.supplier_price_lisxt_description},
                            {label: 'Status', value: spl.status.replaceAll('_', ' ')},
                            {label: 'File', value: <span className="link-action" onclick={async() => {
                                const {result, success} = await api.post('offerprocessing.get_supplier_price_list_file', {
                                    spl_artkey: spl.artkey,
                                }) as any
                                if (success) {
                                    download_binary_file_from_base64_str(result.file_base64_encoded, spl.file_name)
                                } else {
                                    notifier.notify(result.message, 'danger')
                                }
                            }}>{spl.file_name}</span>},
                            {label: 'Blacklisted items', value: spl.blacklisted_source_lines_count},
                        ]}}
                />}

                {$s.context.name === 'edit_spli' && <UpsertSpli
                    collection={vnode.attrs.collection}
                    source="spli"
                />}

                {$s.context.name === 'edit_add_to_order' && <AddToOrder
                    mode="panel"
                    selected_spli={$m.pricelists.adapter_add_to_order($s.context.data, spl.supplier)}
                    supplier_artkey={spl.supplier.artkey}
                    oncancel={() => {
                        Object.assign($s.context, {id: null, name: null})
                    }}
                    on_added_item={async() => {
                        // Manually update purchase orders for the item
                        const {result: purchase_orders} = await Promise.resolve(api.get(
                            `discover/supplier-price-lists/${spl.artkey}/items/purchase-orders?spli_artkeys=${$s.context.data.artkey}`,
                        ))
                        const spli = vnode.attrs.collection.state.items.filter(
                            (item: any) => item.artkey === $s.context.id,
                        )[0]
                        Object.assign(spli, {purchase_orders: purchase_orders})
                    }}
                    on_close_panel={async() => {
                        Object.assign($s.context, {id: null, name: null})
                    }}
                />}

                {$s.context.name === 'edit_add_to_custom_offer' && <AddToOffer
                    mode="panel"
                    spli={$m.pricelists.adapter_add_to_offer($s.context.data, spl.supplier)}
                    custom_offer={true}
                    done={() => {
                        Object.assign($s.context, {id: null, name: null})
                        notifier.notify('Successfully added item to custom offer', 'success')
                    }}
                    cancel={() => Object.assign($s.context, {id: null, name: null})}
                    oncancel={() => {
                        Object.assign($s.context, {id: null, name: null})
                    }}
                />}

                {$s.context.name === 'resolve_line' && <ResolveSourceLine />}
            </div>
        </PanelContext>
    }
}
