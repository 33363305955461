m = require 'mithril'
{map, filter} = require 'prelude-ls'
{button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{icon-button} = require '@/components/buttons.ls'
api = require 'api.ls'
confirmation = require '@/components/confirmation.ls'
inputs = require '@/components/inputs'
{EmailPlaceholder} = require '@/models/data'
app = require('@/app')

{Country} = require '@bitstillery/common/components'


module.exports = class EmailI18nPlaceholderEdit
    ->
        @artkey = m.route.param 'artkey'
        @create = window.prop false
        @email_placeholder = window.prop new EmailPlaceholder()
        @can_show_delete_translation_button = window.prop false

        @translations = window.prop null

        @required_langs = ['en', 'de', 'fr', 'nl']
        @lang_code_to_flag = {
            en: 'gb',
            de: 'de',
            nl: 'nl',
            fr: 'fr',
        }

        if @artkey
            api.call-and-then 'email_i18n_placeholders.get_email_placeholder', do
                artkey: @artkey
            , do
                success: (result) ~>
                    @email_placeholder!artkey(result.result.artkey)
                    @email_placeholder!description(result.result.description)
                    @email_placeholder!placeholder(result.result.placeholder)
                    @email_placeholder!translations(result.result.translations)
        else
            @create true

        @translations = new Collection do
            api_function_name: 'email_i18n_placeholders.get_translations_for_email_placeholder'
            query_limit: 25
            additional_params: window.prop do
                email_placeholder_artkey: @artkey

        # Can show delete buttons on translations if there are at least 4 uniques
        app.$m.common.observable.subscribe "collection.email_i18n_placeholders.get_translations_for_email_placeholder.after-call", @, ~>
            @can_show_delete_translation_button @has_translations_for_all_languages!

    oncreate: ~>
        @translations.init!

    has_translations_for_all_languages: ~>
        mapped_lang_codes = @email_placeholder!translations! |> map (translation) -> translation.language_code
        return new Set(mapped_lang_codes).size >= @required_langs.length

    add_translation: (e) ~>
        e.preventDefault!
        @email_placeholder!translations!push({
            language_code: @required_langs[0]
            translation: ''
        })
        api.call-and-then 'email_i18n_placeholders.upsert_email_placeholder_with_translations',
            artkey: @email_placeholder!artkey!
            description: @email_placeholder!description!
            placeholder: @email_placeholder!placeholder!
            translations: @email_placeholder!translations!
        , do
            success: (result) ~>
                app.notifier.notify 'An empty translation was saved', 'success'
                @email_placeholder!artkey(result.result.artkey)
                @email_placeholder!description(result.result.description)
                @email_placeholder!placeholder(result.result.placeholder)
                @email_placeholder!translations(result.result.translations)
                @translations.requery!

    delete_email_placeholder: ~>
        confirmation.show do
            title: "Delete email placeholder"
            message: "Are you sure you want to delete this email placeholder?"
            unique_name: "email_placeholder_deactivate_confirm"
            onconfirm: ~>
                api.call-and-then 'email_i18n_placeholders.delete_email_placeholder', do
                    artkey: @artkey
                , do
                    success: (result) ->
                        app.notifier.notify 'Email placeholder deleted', 'success'
                        m.route.set('/data/email-placeholders')

    delete_translation: (artkey) ~>
        @email_placeholder!translations(@email_placeholder!translations! |> filter((translation) -> translation.artkey !== artkey))
        api.call-and-then 'email_i18n_placeholders.upsert_email_placeholder_with_translations',
            artkey: @email_placeholder!artkey!
            description: @email_placeholder!description!
            placeholder: @email_placeholder!placeholder!
            translations: @email_placeholder!translations!
        , do
            success: (result) ~>
                app.notifier.notify 'Translation has been successfully deleted', 'success'
                @email_placeholder!artkey(result.result.artkey)
                @email_placeholder!description(result.result.description)
                @email_placeholder!placeholder(result.result.placeholder)
                @email_placeholder!translations(result.result.translations)
                @translations.requery!

    save_email_placeholder: (e) ~>
        e.preventDefault!
        if not @create! and not @has_translations_for_all_languages!
            app.notifier.notify 'At least one translation is required for each language', 'danger'
            return

        api.call-and-then 'email_i18n_placeholders.upsert_email_placeholder_with_translations',
            artkey: @email_placeholder!artkey!
            description: @email_placeholder!description!
            placeholder: @email_placeholder!placeholder!
            translations: @email_placeholder!translations!
        , do
            success: (result) ~>
                app.notifier.notify 'Email placeholder updated', 'success'
                if @create!
                    m.route.set("/data/email-placeholders/#{result.result.artkey}/edit", {'key': 'edit'})
                else
                    m.route.set('/data/email-placeholders')

    view: -> m '.c-email-placeholder-edit view',
        m '.btn-toolbar',
            m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/data/email-placeholders'},
                (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
            if not @create!
                m 'button.btn.btn-danger.pull-right' do
                    type: 'button'
                    onclick: @delete_email_placeholder
                , (m 'span.glyphicon.glyphicon-trash'), ' Deactivate'

        m 'form' {onsubmit: @save_email_placeholder},
            m   '.fieldset',
                m '.field',
                    m 'label' 'Placeholder'
                    inputs.text @email_placeholder!placeholder, {required: true}
                m '.field',
                    m 'label' {for: 'description'} 'Description'
                    inputs.text @email_placeholder!description, {required: true, id: 'description'}

                m '.field',
                    button-with-icon if @create! then 'Create' else 'Update', 'ok', do
                        class: 'btn-success'
                    if not @create!
                        button-with-icon 'Translation', 'plus', do
                            class: 'btn-default'
                            onclick: (e) ~> @add_translation(e)

            if not @create!
                m CollectionTable, do
                    collection: @translations
                    options:
                        sticky_header: true
                        with_buttons: true
                        unique_name: 'email_placeholder_translations'
                    row_model: (row) ~>
                        row with
                            new_translation:  window.prop row.translation
                            new_language_code: window.prop row.language_code
                    columns:
                        do
                            header: 'Flag'
                            name: 'Flag'
                            width: 0.5
                            function: (record) ~>
                                m Country,
                                    country_code: @lang_code_to_flag[record.new_language_code!]
                        do
                            name: 'Language code'
                            width: 0.5
                            header: 'Language Code'
                            function: (record) ~>
                                inputs.select record.new_language_code, @required_langs, do
                                    after_update: ~>
                                        translation_to_update = @email_placeholder!translations! |> filter((translation) -> translation.artkey == record.artkey)
                                        translation_to_update[0].language_code = record.new_language_code!
                                        record.language_code = record.new_language_code!
                        do
                            name: 'Translation'
                            header: 'Translation'
                            function: (record) ~>
                                inputs.textarea record.new_translation, do
                                    required: true
                                    id: 'translation'
                                    oninput: (ev) ~>
                                        translation_to_update = @email_placeholder!translations! |> filter((translation) -> translation.artkey == record.artkey)
                                        translation_to_update[0].translation = ev.target.value
                        do
                            width: 1
                            name: 'Actions'
                            header: ''
                            function: (record) ~>
                                m '.btn-toolbar.no-click' m '.btn-group',
                                    if not @create! and @can_show_delete_translation_button!
                                        alternatives = @translations.items!.filter((translation) -> translation.language_code == record.language_code)
                                        if alternatives.length > 1
                                            icon-button 'remove', do
                                                class: 'btn-danger no-click'
                                                title: 'Remove this translation'
                                                onclick: (e) ~>
                                                    e.preventDefault!
                                                    @delete_translation(record.artkey)
