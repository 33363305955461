m = require 'mithril'
luxon = require 'luxon'
{filter, toArray, mergeAll} = require "rxjs/operators"
api = require 'api.ls'
{icon-button, text-button} = require '@/components/buttons.ls'
inputs = require '@/components/inputs'
{EmailPreview} = require 'email/components/email_preview.ls'
{Offer} = require '@/models/offers'
{classes} = require '@bitstillery/common/lib/utils'
{RelationDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{current_account_slug} = require '@bitstillery/common/account/account'
{EmailProcessFlow, EmailBatchType, EmailBatch} = require './models'
{UserDropDown} = require '@/components/users'
{Button} = require '@bitstillery/common/components'
{ProcessManageOffer} = require '@/offer/offers/processes'
app = require('@/app')

templates = require('./templates').default

export class ScheduleType
    @ASAP = 'ASAP'
    @SCHEDULED = 'SCHEDULED'
    @ONCE_A_WEEK = 'ONCE_A_WEEK'
    @MONDAY_TILL_FRIDAY = 'MONDAY_TILL_FRIDAY'
    @DAILY = 'DAILY'

    @choices = {
        'ASAP': 'As soon as possible'
        'SCHEDULED': 'On a certain date/time'
        'ONCE_A_WEEK': 'Repeat on a weekday'
        'MONDAY_TILL_FRIDAY': 'Repeat every weekday'
        'DAILY': 'Every day'
    }


export class WeekDays
    @SUN = 'SUN'
    @MON = 'MON'
    @TUE = 'TUE'
    @WED = 'WED'
    @THU = 'THU'
    @FRI = 'FRI'
    @SAT = 'SAT'

    @choices = {
        'SUN': 'Sunday'
        'MON': 'Monday'
        'TUE': 'Tuesday'
        'WED': 'Wednesday'
        'THU': 'Thursday'
        'FRI': 'Friday'
        'SAT': 'Saturday'
    }


module.exports = class SendOfferMail
    ->
        @supplier_artkey = window.prop ''
        @preview_body = window.prop 'Wait while fetching relations ...'

        @email_batch = new EmailBatch
        @email_batch.batch_type EmailBatchType.NOTHING
        @footer = window.prop ''

        @create = false
        @loading = true
        @email_batch.body '#contact.salutation# #contact.first_name#'

        @offer = window.prop new Offer

        @email_batch_artkey = m.route.param 'artkey'
        if @email_batch_artkey
            @query_batch @email_batch_artkey
        else  # we are creating a new one
            @create = true
            @query_footer!

        # Pre-fill pricelist type and offer fields, if provided via the
        # querystring.
        @offer_artkey = m.route.param 'offer'
        if @offer_artkey
            @email_batch.batch_type EmailBatchType.CUSTOM_OFFER
            @email_batch.offer_artkey +@offer_artkey

        @offers = window.prop []
        @query_custom_offers!
        @language = window.prop null

        @email_placeholders = window.prop null
        @selected_placeholder = window.prop ''
        @placeholder_description = []
        api.call-and-then 'email_i18n_placeholders.get_all_email_placeholders', {}, do
            success: (resp) ~>
                @email_placeholders resp.result.map((ph) -> ph.placeholder).sort()
                resp.result.forEach((ph) ~> @placeholder_description[ph.placeholder] = ph.description)
        @schedule_type = window.prop ScheduleType.ASAP
        @selected_weekday = window.prop WeekDays.MON
        @selected_scheduled_date = window.prop null
        @selected_scheduled_time = window.prop null
        @sent_from_sales_manager = window.prop true

    oncreate: (value) ~>
        # If the suppliers are already loaded, set the preview body and
        # optionally query the offer.
        @initialize_preview!

    initialize_preview: ~>
        @preview_body 'Select a client for a preview ...'
        if @offer_artkey
            @query_offer @offer_artkey

    get_process_flow: ~>
        if @offer_artkey
            return EmailProcessFlow.OFFERS
        else
            return EmailProcessFlow.MAILS

    query_footer: ~>
        api.call-and-then 'pricelistbatch.get_footer' {}, do
            success: (resp) ~>
                @loading = false
                @footer resp.result

    query_batch: (artkey) ~>
        api.call-and-then 'email.batch.get' {'offer_email_batch_artkey': artkey}, do
            success: (resp) ~>
                @email_batch.from_js resp.result

                if @email_batch.cron_expression!split(' ')[4] == "1-5"
                    @schedule_type ScheduleType.MONDAY_TILL_FRIDAY
                else if @email_batch.cron_expression!split(' ')[4] != "*"
                    @selected_weekday @email_batch.cron_expression!split(' ')[4]
                    @schedule_type ScheduleType.ONCE_A_WEEK
                else if @email_batch.cron_expression!includes("* * * *")
                    @schedule_type ScheduleType.DAILY
                else
                    @schedule_type ScheduleType.SCHEDULED
                @selected_scheduled_date luxon.DateTime.fromISO(@email_batch.next_run!).toISODate()
                @selected_scheduled_time luxon.DateTime.fromISO(@email_batch.next_run!).toFormat("HH:mm")

                # Now that the email batch has been loaded, query the custom
                # offers to include the email batch' offer in the custom offer dropdown.
                if @email_batch.offer_artkey!
                    @query_custom_offers!
                @loading = false

    query_custom_offers: ~>
        if @email_batch.offer_artkey!
            # We are creating or editing an email batch for a specific offer.
            # Always include this offer in the dropdown, regardless if it is
            # expired or not.
            data = do
                include_offer_artkey: @email_batch.offer_artkey!
        else
            data = {}

        api.call-and-then 'offer.get_offer_records' data, do
            success: (resp) ~>
                @offers resp.result

    query_offer: (artkey) ~>
        data = do
            artkey: artkey
            include_suppliers: true

        api.call-and-then 'offer.get_offer' data, do
            success: (resp) ~>
                @offer new Offer resp.result

                # If the offer is for exactly one supplier: directly set it as
                # the "preview supplier".
                if resp.result.suppliers.length == 1
                    @supplier_artkey "#{resp.result.suppliers[0].artkey}"


                # Now that the optional preview supplier is known: refresh the preview.
                @refresh-preview!
            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    save: (e) ~>
        e.preventDefault!

        body = @email_batch.body!
        scheduled_datetime = luxon.DateTime.fromISO("#{@selected_scheduled_date!}T#{@selected_scheduled_time!}")

        # Empty the offer_artkey field if it is not to be saved.
        if @email_batch.batch_type! != EmailBatchType.CUSTOM_OFFER
            @email_batch.offer_artkey null
        data = do
            offer_email_batch_artkey: @email_batch.artkey! or null
            subject: @email_batch.subject!
            body: body
            pricelist_type: @email_batch.batch_type!
            offer_artkey: @email_batch.offer_artkey!
            sent_from_user_artkey: @email_batch.use_sent_from_user_artkey!
            scheduling_type: @schedule_type!
            scheduled_datetime: scheduled_datetime
            scheduled_weekday: @selected_weekday!

        api.call-and-then 'pricelistbatch.create_or_update' data, do
            success: (result) ~>
                if @create then
                    app.notifier.notify "Successfully created new e-mail batch '#{@email_batch.subject!}'.", 'success'
                    if @get_process_flow! == EmailProcessFlow.OFFERS
                        m.route.set "/offer/offers/#{@offer_artkey}/email/#{result.result.artkey}/recipient-selection"
                    else if @get_process_flow! == EmailProcessFlow.MAILS
                        m.route.set "/crm/email/#{result.result.artkey}/recipient-selection"
                else
                    app.notifier.notify 'Successfully updated e-mail batch.', 'success'
                    if @get_process_flow! == EmailProcessFlow.OFFERS
                        m.route.set "/offer/offers/#{@offer_artkey}/email/#{result.result.artkey}/recipient-selection"
                    else
                        m.route.set '/crm/email/' + @email_batch.artkey!

    insert-content: (value) ~>
        # insert text with jquery, just modifying @email_batch.body does not trigger markup functions in summernote.
        $('#summernote').summernote('insertText', value + ' ')
        @refresh-preview!

    after-update: (value) ~>
        m.redraw!

    insert-template-pricelist: ~>
        @email_batch.subject "\#subject_pricelist\#"
        @email_batch.body templates.pricelist
        @email_batch.batch_type EmailBatchType.ENTIRE_PRICELIST

    insert-template-offer: ~>
        @email_batch.subject "\#subject_offer\#"
        @email_batch.body templates.offer
        @email_batch.batch_type EmailBatchType.CUSTOM_OFFER

    insert-template-spot-offer: ~>
        @email_batch.subject "\#subject_spotoffer\#"
        @email_batch.body templates.spotOffer
        @email_batch.batch_type EmailBatchType.CUSTOM_OFFER

    insert-template-purchase-request: ~>
        @email_batch.subject "\#subject_purchase\#"
        @email_batch.body templates.purchaseRequest
        @email_batch.batch_type EmailBatchType.PURCHASE_ENQUIRY

    refresh-preview: ~>
        if @supplier_artkey!
            if @email_batch.batch_type! != EmailBatchType.CUSTOM_OFFER
                @email_batch.offer_artkey null
            data = do
                offer_email_batch_artkey: @email_batch.artkey! or null
                subject: @email_batch.subject!
                body: @email_batch.body!
                pricelist_type: @email_batch.batch_type!
                offer_artkey: @email_batch.offer_artkey!
                supplier_artkey: @supplier_artkey!
                from_email_address_artkey: @email_batch.use_sent_from_user_artkey!
            @preview_body 'fetching preview...'
            api.call-and-then 'pricelistbatch.get_rendered_email_for_supplier' data, do
                success: (resp) ~>
                    if resp.result.message !== undefined
                        @preview_body 'Error while rendering preview: ' + resp.result.message
                    else
                        @preview_body resp.result.body
                        @language resp.result.country_language

    view: ->
        m classes('.c-process-new-offer-step-5', 'view', {process: @get_process_flow! == EmailProcessFlow.OFFERS}),
            # Offers flow: show process indicator, title and subtitle.
            if @get_process_flow! == EmailProcessFlow.OFFERS
                m ProcessManageOffer, {
                    active: 'email_content',
                    context: {
                        offer_artkey: @email_batch.offer_artkey!
                        email_batch_artkey: @email_batch.artkey!
                    }
                }

            # Mails flow: show back to list-link and title.
            else if @get_process_flow! == EmailProcessFlow.MAILS
                [
                    m '.btn-toolbar',
                        m 'button.btn.btn-default' do
                            type: 'button'
                            onclick: ~>
                                if @email_batch.artkey!
                                    m.route.set "/crm/email/#{@email_batch.artkey!}"
                                else
                                    m.route.set '/crm/email'
                        , (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                ]

            if not @loading
                m '.c-email-edit step-content',
                    m 'form' {onsubmit: @save},
                        m '.fieldset-group large',
                            m '.fieldset',
                                m '.fieldset-label' 'Email Composer'

                                if @email_batch.batch_type! == EmailBatchType.PURCHASE_ENQUIRY
                                    m '.field',
                                        m 'label' 'From address'
                                        m UserDropDown, do
                                            selected_user_artkey: String(@email_batch.use_sent_from_user_artkey!)
                                            onchange: (user_artkey) ~>
                                                if (user_artkey)
                                                    @email_batch.use_sent_from_user_artkey +user_artkey
                                                else
                                                    @email_batch.use_sent_from_user_artkey null

                                else if @sent_from_sales_manager!
                                    inputs.checkbox @sent_from_sales_manager, {
                                        label: 'From sales manager',
                                        help: 'Use sales manager of the relation in from address'
                                    }
                                else
                                    m '.field',
                                        m 'label' 'From address'
                                        m UserDropDown, do
                                            selected_user_artkey: String(@email_batch.use_sent_from_user_artkey!)
                                            onchange: (user_artkey) ~>
                                                if (user_artkey) then
                                                    @email_batch.use_sent_from_user_artkey +user_artkey
                                                    @sent_from_sales_manager false
                                                else if (user_artkey == "" && @email_batch.use_sent_from_user_artkey!)
                                                    @email_batch.use_sent_from_user_artkey null
                                                    @sent_from_sales_manager true
                                        m '.help',
                                            'Note that all the relations will see the mail address of the selected sales manager.'

                                m '.field',
                                    m 'label' 'Email subject'
                                    inputs.text @email_batch.subject, {required: true, id: 'subject'}

                                m '.field-group',
                                    inputs.select @schedule_type, ScheduleType.choices, {
                                        empty_option: false,
                                        label: 'Scheduling type',
                                    }

                                    if @schedule_type! == ScheduleType.ONCE_A_WEEK
                                        inputs.select @selected_weekday, WeekDays.choices, {
                                            empty_option: false,
                                            label: 'Weekdays'
                                        }
                                    else if @schedule_type! == ScheduleType.SCHEDULED then [
                                        inputs.date @selected_scheduled_date, {
                                            label: 'Date',
                                            required: true
                                        }
                                        inputs.time @selected_scheduled_time,
                                            label: 'Time',
                                            required: true
                                    ]

                                m '.field',
                                    m 'label' 'Templates'
                                    m '.btn-group',
                                        if @get_process_flow! == EmailProcessFlow.MAILS
                                            m 'a.btn.btn-default.template',
                                                onclick: ~> @insert-template-pricelist!
                                            , 'Pricelist'
                                        m 'a.btn.btn-default.template',
                                            onclick: ~> @insert-template-offer!
                                        , 'Offer'
                                        m 'a.btn.btn-default.template',
                                            onclick: ~> @insert-template-spot-offer!
                                        , 'Spot offer'
                                        if @get_process_flow! == EmailProcessFlow.MAILS
                                            m 'a.btn.btn-default.template',
                                                onclick: ~> @insert-template-purchase-request!
                                            , 'Purchase inquiry'

                                m '.field',
                                    m inputs.Texteditor,
                                        text: @email_batch.body
                                        required: true
                                        after-update: @after-update


                                # Only show the 'Include' field in the "mails flow".
                                if @get_process_flow! == EmailProcessFlow.MAILS and @email_batch.batch_type! != EmailBatchType.PURCHASE_ENQUIRY
                                    m '.field',
                                        m 'label' 'Include'
                                        inputs.radio  @email_batch.batch_type, [
                                            {value: EmailBatchType.ENTIRE_PRICELIST, description: 'Entire pricelist'}
                                            {value: EmailBatchType.CUSTOM_OFFER, description: 'Custom offer'}
                                            {value: EmailBatchType.NOTHING, description: 'Nothing'}
                                        ]

                                # Only show the 'Custom offer' field when mailing a custom
                                # offer in the "mails flow".
                                if @get_process_flow! == EmailProcessFlow.MAILS and @email_batch.batch_type! == EmailBatchType.CUSTOM_OFFER
                                    inputs.offer @offers, @email_batch.offer_artkey, {
                                        label: 'Custom offer',
                                        required: true
                                    }

                                m '.field',
                                    m 'label' 'Generic variables'
                                    m '.control',
                                        if @email_placeholders!
                                            inputs.select @selected_placeholder, @email_placeholders!, {}

                                        icon-button 'plus', do
                                            class: 'btn-default no-click'
                                            onclick: (e) ~>
                                                e.preventDefault!
                                                @insert-content '#' + @selected_placeholder! + '#'
                                    m '.help'
                                        "Variable for: #{@placeholder_description[@selected_placeholder!]}"

                            m '.fieldset.large',
                                m '.fieldset-label' 'Email Preview'

                                m '.refresh-wrapper',
                                    m '.field',
                                        m 'label' 'Relation'
                                        if @offer_artkey and @offer!suppliers and @offer!suppliers!length > 0
                                            [
                                                m RelationDropDown, do
                                                    selected_relation_artkey: @supplier_artkey!
                                                    get_all_for_drop_down_response$: RelationDropDownData.relations().pipe(
                                                        mergeAll(),
                                                        filter((relation) ~> @offer!suppliers!map((supplier) -> supplier.artkey).includes(relation.artkey)),
                                                        toArray(),
                                                    )
                                                    onchange: (supplier_artkey) ~>
                                                        @supplier_artkey(supplier_artkey)
                                                        @refresh-preview!
                                            ]
                                        else if not @offer_artkey
                                            [
                                                m RelationDropDown, do
                                                    selected_relation_artkey: @supplier_artkey!
                                                    get_all_for_drop_down_response$: RelationDropDownData.relations()
                                                    onchange: (supplier_artkey) ~>
                                                        @supplier_artkey(supplier_artkey)
                                                        @refresh-preview!
                                            ]

                                    m Button, {
                                        onclick: (e) ~>
                                            e.preventDefault()
                                            @refresh-preview!
                                        text: 'Refresh Preview',
                                        type: 'info',
                                    }
                                    m 'div.flag flag-icon flag-icon-' + @language!, ''


                                m '.field',
                                    m '.content-preview',
                                        m EmailPreview, email: @preview_body

                        m '.btn-toolbar',
                            text-button if @create then 'Create Batch' else 'Update Batch', do
                                disabled: !@email_batch.body!  # this prevents errors from an empty Texteditor input
                                type: 'submit'
                                class: 'btn-success btn-submit'
