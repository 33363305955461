import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {api, notifier} from '@bitstillery/common/app'

import {CountriesSelect, InputDate, TextArea, TextInput} from '@/components/html_components'
import {NumberInput} from '@/components/input_numbers'
import {EditAssist, HorizontalForm} from '@/components/form'
import {DamagesDropDown, GeneralDropDown, PackagingLabelDropDown, PackSizeDropDown} from '@/components/case_inputs'
import {GetItemResponseOutput, UpdateItemRequest} from '@/factserver_api/fact2server_api.ts'

export interface ItemEditorAttrs {
    item: GetItemResponseOutput
    edit_assist: EditAssist
}

export class ItemEditor extends MithrilTsxComponent<ItemEditorAttrs> {
    update_item_request: UpdateItemRequest

    constructor(vnode: m.Vnode<ItemEditorAttrs>) {
        super()
        this.update_item_request = {
            best_before_date: vnode.attrs.item.case.best_before_date,
            bottle_gtin: vnode.attrs.item.bottle_gtin_code || null,
            case_gtin: vnode.attrs.item.case_gtin_code || null,
            country_of_origin: vnode.attrs.item.country_of_origin || '',
            bottle_lot: vnode.attrs.item.bottle_lot || null,
            remark: vnode.attrs.item.remark || null,
            cases_per_pallet: vnode.attrs.item.cases_per_pallet || null,
            cases_per_pallet_layer: vnode.attrs.item.cases_per_pallet_layer || null,
            item_tag_artkeys: vnode.attrs.item.case.item_tag_artkeys,
        }
    }

    async update_item(vnode: m.Vnode<ItemEditorAttrs>) {
        const {response, result} = await api.put(`discover/items/${vnode.attrs.item.artkey}`, this.update_item_request)
        if (response.ok) {
            notifier.notify(`Item ${vnode.attrs.item.reference} is updated`)
            m.route.set(`/stock/manage/${vnode.attrs.item.reference}`)
        }

        if (response.status === 400) {
            notifier.notify(`Cannot update item: ${result.detail}`)
        }

    }

    view(vnode: m.Vnode<ItemEditorAttrs>): m.Children {
        return (
            <HorizontalForm
                onsubmit={async() => await this.update_item(vnode)}
                edit_assist={vnode.attrs.edit_assist}
                submit_title={'Update Stock Item'}
            >
                <div className="fieldset-group">
                    <div className="fieldset">
                        <div className="fieldset-label">General</div>

                        <div className={'field-group'}>
                            <CountriesSelect
                                label="Country of Origin"
                                required={true}
                                selected={this.update_item_request.country_of_origin}
                                onchange={(value: string) => (this.update_item_request.country_of_origin = value)}
                            />
                            <InputDate
                                label="Best Before Date"
                                value={this.update_item_request.best_before_date}
                                onchange={(value: DateTime) => {
                                    this.update_item_request.best_before_date = value ? value.toISO() : null
                                }}
                            />
                        </div>

                        <div className="field-group">
                            <NumberInput
                                label="Cases Per Pallet"
                                minimum={this.update_item_request.cases_per_pallet_layer || 0}
                                value={this.update_item_request.cases_per_pallet}
                                oninput={(value: number) => (this.update_item_request.cases_per_pallet = value)}
                            />
                            <NumberInput
                                label="Cases Per Pallet Layer"
                                minimum={0}
                                maximum={this.update_item_request.cases_per_pallet}
                                value={this.update_item_request.cases_per_pallet_layer}
                                oninput={(value: number) => (this.update_item_request.cases_per_pallet_layer = value)}
                            />
                        </div>

                        <TextInput
                            label="Remark"
                            value={this.update_item_request.remark}
                            oninput={(value: string) => (this.update_item_request.remark = value)}
                        />

                        <div className="field-group">
                            <TextInput
                                label="Bottle GTIN"
                                value={this.update_item_request.bottle_gtin}
                                oninput={(value: string) => (this.update_item_request.bottle_gtin = value)}
                            />

                            <TextInput
                                label="Case GTIN"
                                value={this.update_item_request.case_gtin}
                                oninput={(value: string) => (this.update_item_request.case_gtin = value)}
                            />
                        </div>
                        <div className="field-group">
                            <TextArea
                                label="Bottle coding"
                                value={vnode.attrs.item.bottle_coding}
                                disabled={true}
                            />

                            <TextInput
                                label="Bottle lot"
                                value={this.update_item_request.bottle_lot}
                                oninput={(value: string) => (this.update_item_request.bottle_lot = value)}
                            />
                        </div>
                    </div>

                    <div className="fieldset">
                        <div className="fieldset-label">Labels</div>
                        <DamagesDropDown
                            label="Damages"
                            selected={this.update_item_request.item_tag_artkeys.join(',')}
                            onchange={(value: string) => {
                                this.update_item_request.item_tag_artkeys = value.split(',')
                                    .map((artkey) => +artkey)
                                    .filter((artkey) => artkey !== 0)
                                m.redraw()
                            }}
                        />

                        <GeneralDropDown
                            label="General Tags"
                            selected={this.update_item_request.item_tag_artkeys.join(',')}
                            onchange={(value: string) => {
                                this.update_item_request.item_tag_artkeys = value.split(',')
                                    .map((artkey) => +artkey)
                                    .filter((artkey) => artkey !== 0)
                                m.redraw()
                            }}
                        />

                        <PackSizeDropDown
                            label="Pack Size"
                            selected={this.update_item_request.item_tag_artkeys.join(',')}
                            onchange={(value: string) => {
                                this.update_item_request.item_tag_artkeys = value.split(',')
                                    .map((artkey) => +artkey)
                                    .filter((artkey) => artkey !== 0)
                                m.redraw()
                            }}
                        />

                        <PackagingLabelDropDown
                            label="Packaging"
                            selected={this.update_item_request.item_tag_artkeys.join(',')}
                            onchange={(value: string) => {
                                this.update_item_request.item_tag_artkeys = value.split(',')
                                    .map((artkey) => +artkey)
                                    .filter((artkey) => artkey !== 0)
                                m.redraw()
                            }}
                        />
                    </div>
                </div>
            </HorizontalForm>
        )
    }
}
