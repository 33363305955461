m = require 'mithril'

api = require 'api.ls'
inputs = require '@/components/inputs'

{prevent-default} = require 'utils.ls'
ZeroResultsGraph = require './zero_results_graph.ls'
StockPositionGraph = require './stock_position_graph.ls'
ItemSoldGraph = require './item_sold_graph.ls'
ItemStockedGraph = require './item_stocked_graph.ls'


tab = (title, currently_showing, id) ->
    m 'li',
        role: 'presentation'
        class: if currently_showing! == id then 'active' else ''
        m m.route.Link,
            href: m.route.get!
            onclick: ~> preventDefault currently_showing id
        , title


module.exports = class DianaTopicListeners
    SHOWING = do
        ZERO_RESULTS: "ZERO_RESULTS",
        ITEM_SOLD: "ITEM_SOLD",
        ITEM_STOCKED: "ITEM_STOCKED",
        STOCK_POSITION: "STOCK_POSITION",
        MAINTENANCE: "MAINTENANCE"
    ->
        @currently_showing = window.prop SHOWING.ZERO_RESULTS

    view: ->
        m '.c-diana view',
            m '.row',
            m 'ul.nav.nav-tabs.pl-2.mb-2' [
                tab "Zero results", @currently_showing, SHOWING.ZERO_RESULTS
                tab "Cases sold", @currently_showing, SHOWING.ITEM_SOLD
                tab "Cases stocked", @currently_showing, SHOWING.ITEM_STOCKED
                tab "Stock position", @currently_showing, SHOWING.STOCK_POSITION
            ]
            if @currently_showing! == SHOWING.ZERO_RESULTS
                [
                    m ZeroResultsGraph, do
                        canvas_id: "top-missed-searches-canvas"
                ]
            if @currently_showing! == SHOWING.ITEM_SOLD
                [
                    m ItemSoldGraph, do
                        canvas_id: "item-sold-canvas"
                ]
            if @currently_showing! == SHOWING.ITEM_STOCKED
                [
                    m ItemStockedGraph, do
                        canvas_id: "item-stocked-canvas"
                ]
            if @currently_showing! == SHOWING.STOCK_POSITION
                [
                    m StockPositionGraph, do
                        canvas_id: "stock-position-canvas"
                ]
