m = require 'mithril'

export class EmailPreview
    (vnode) ->
        @email = vnode.attrs.email
        @contact_person = vnode.attrs.contact-person

    view: ->
        m '.email-preview',
            if @email!
                m.trust @email!
