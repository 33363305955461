m = require 'mithril'
{map} = require 'prelude-ls'

api = require 'api.ls'

{prevent-default} = require 'utils.ls'

{account-icon} = require 'accounts.ls'
{link} = require '@/components/link.ls'
{FieldSelect} = require '@bitstillery/common/components'
{supported_languages} = require '@bitstillery/common/lib/i18n'
{button-with-icon} = require '@/components/buttons.ls'
{contact_person_link, relation_link} = require '@/components/entity_links.ls'
inputs = require '@/components/inputs'

{PortalUser} = require 'portal/models.ls'
{ContactPersonDropdown} = require '@/components/contact_person'
{ContactPersonApi} = require '@/factserver_api/contact_person_api'
{UserManagementApi} = require '@/factserver_api/user_management_api'
{filter, mergeAll, tap, toArray} = require "rxjs/operators"
app = require('@/app')


module.exports = class PortalUserEdit

    (vnode) ->
        @user = new PortalUser
        @repeat_password = window.prop ''
        @contact_person_api = new ContactPersonApi()

        @_custom_password = false
        @_generate_password = false
        @user_management_api= new UserManagementApi()

        @generate_password = (value) ~>
            if value? then
                @_generate_password = value
                if value and @_custom_password then
                    @_custom_password = false
            return @_generate_password

        @custom_password = (value) ~>
            if value? then
                @_custom_password = value
                if value and @_generate_password then
                    @_generate_password = false
                else if not value and @create! then
                    @_generate_password = true
            return @_custom_password


        @base_url = '/portal/portal-users'

        artkey = m.route.param 'artkey'
        if artkey
            @is-email = false
            @create = window.prop false
            data = {artkey: artkey}
            api.call-and-then 'portal.user_management.get_user', data, do
                success:  (resp) ~>
                    @user = new PortalUser resp.result

                failure:  (resp) ~>
                    app.notifier.notify 'Unknown portal user.', 'danger'
                    m.route.set @base_url
        else
            @is-email = true
            @create = window.prop true
            @get_contact_person_drop_down_data$ = @contact_person_api.get_contact_person_dropdown_data()

            @generate_password true
            @user.contact_person_artkey m.route.param 'contact_person_artkey'

        @reset_otp = window.prop false

        @delete_user = window.prop false
        @generate_invite_code = window.prop false

    valid-password: ~>
        @user.password! == @repeat_password!

    button-text: ~>
        if @create! then
            'Create User'
        else if @delete_user! then
            'Delete User'
        else
            'Update User'

    valid: ~>
        if @create! then
            (@user.password! and @valid-password!) or @generate_password!
        else
            @valid-password! or @generate_password!

    save: (e) ~>
        if @delete_user! then
            data = do
                artkey: @user.artkey!
            api.call 'portal.user_management.delete_user', data, @handle_save
        else
            data = do
                artkey: @user.artkey!
                name: @user.name!
                language: @user.language
                password: @user.password!
                contact_person_artkey: @user.contact_person_artkey!
                reset_otp: @reset_otp!
                generate_password: @generate_password!
                generate_invite_code: @generate_invite_code!

            api.call 'portal.user_management.create_or_update_user', data, @handle_save

    handle_save: (result) ~>
        if not result.success
            if result.message
                app.notifier.notify result.message, 'danger'
            else
                app.$m.common.generic_error_handler!
        else
            if @create!
                app.notifier.notify 'Successfully created new portal user.', 'success'
            else
                if @delete_user! then
                    app.notifier.notify 'Successfully deleted portal user.', 'warning'
                else
                    app.notifier.notify 'Successfully updated portal user.', 'success'
            m.route.set "#{@base_url}"

    send_password_reset_email: ~>
        @user_management_api.request_change_password_email(@user.artkey!).subscribe({
            next: ~>
                app.notifier.notify 'Email sent to the portal user.', 'success'
            error: ~>
                app.notifier.notify 'Email sending failed.', 'warning'
        })


    view: ->
        a = -> [.. for &]
        m '.c-portal-user-edit view' a do
            m '.btn-toolbar',
                button-with-icon 'Back to list', 'arrow-left', do
                    class: 'btn-default'
                    onclick: ~> m.route.set @base_url
                button-with-icon 'Send password reset email', 'envelope', do
                    class: 'btn-default'
                    onclick: ~> @send_password_reset_email!

            m 'form.flex-form' {onsubmit: prevent-default @save},
                m '.fieldset',
                    m '.field',
                        m 'label' if @is-email then 'Username (e-mail)' else 'Username'
                        if @is-email then inputs.email @user.name, {required: true} else inputs.text @user.name, {required: true}

                    if not @create!
                        m '.field',
                            m 'label' 'Relation'
                            m 'div',
                                relation_link @user.contact_person!supplier!

                    m '.field',
                        m 'label' 'Contact person'

                        if @create!
                            m ContactPersonDropdown, do
                                selected_contact_person_artkey: @user.contact_person_artkey!
                                get_contact_person_dropdown_data$:
                                    @get_contact_person_drop_down_data$.pipe(
                                        mergeAll(),
                                        filter((contact_person) -> contact_person.portal_user_artkey == null),
                                        toArray(),
                                    )
                                disabled: !(@create!)
                                onchange: (contact_person_artkey) ~>
                                    @user.contact_person_artkey contact_person_artkey

                        else
                            m 'label' contact_person_link @user.contact_person!

                    m FieldSelect, {
                        label: 'Portal language',
                        options: supported_languages
                        help: 'Language of the portal, specifically for this portal user'
                        ref: [@user, 'language']
                        translate: {prefix: ''}
                    }

                    if not @create! then a do
                        inputs.checkbox @reset_otp, {
                            help: 'On the next login the user must scan the new OTP',
                            label: 'Reset OTP'
                        }

                        inputs.checkbox @delete_user, {
                            help: 'Revoke portal access for this account',
                            label: 'Delete account'
                        }

                    inputs.checkbox @generate_invite_code, {
                        help: 'Generate a new invite code (existing code, if any, will be disabled). The user will not receive an email with this code.',
                        label: 'Generate invite code'
                    }

                    m '.field',
                        let text = @button-text!
                            m 'button.btn',
                                if @delete_user! then
                                        class: 'btn-danger btn-submit'
                                else
                                    class: 'btn-success btn-submit'
                                    disabled: bool-to-disabled (not @valid!)
                            , text
                        if (@create! and not @custom_password!) and @generate_password! then
                            m '.field',
                                "A randomly generated password will be sent to specified contact person."


bool-to-disabled = (value) -> if value then 'disabled' else ''
