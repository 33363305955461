m = require 'mithril'
{or-list} = require 'prelude-ls'
{GenericList} = require '../generic/generic_list'


module.exports = class LedgerList
    is_match: (ledger, term) ~>
        return or-list [
            ledger.ledger_code.toLowerCase!indexOf(term) > -1,
        ]

    view: ->
        m GenericList, do
            readable_entity_name: 'ledger'
            base_url: '/data/ledgers/'
            list_api_call: 'ledgers.get_all'
            is_match: @is_match
            columns:
                {key: 'ledger_type', label: 'Type'},
                {key:'description', label: 'Description'}
                {key: 'ledger_code', label: 'Ledger code'}
            requery_subscribe_key: 'ledgers_updated'
