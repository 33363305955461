import m from 'mithril'
import {Modal} from 'components/modal/modal.ls'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import ChangePassword from './change_password'
import UserDetails from './user_details'
import OtpDialog from './otp_dialog'
import {UserSchedule} from './user_schedule'

import {BackToListButton} from '@/components/discover'
import {EditAssist} from '@/components/form'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {LoggedInUserData, User} from '@/factserver_api/user_api'
import {UpdateUserRequest, UserManagementApi} from '@/factserver_api/user_management_api'

enum ShowModus {
    USER_CHANGE_PW,
    USER_DETAILS,
    USER_SCHEDULE,
}

export default class UserMngEdit extends MithrilTsxComponent<any> {
    api = new UserManagementApi()
    logged_in_user: User | null = null
    update_user_request: UpdateUserRequest = {
        name: '',
        is_trader: false,
        is_purchaser: false,
        is_crm_manager: false,
        is_stock_manager: false,
        is_superuser: false,
        is_compensable: false,
        decimal_locale: 'en',

        is_deleted: false,
        profile: {
            a2bc_emailaddress: null,
            address: '',
            emailaddress: '',
            gitlab_username: '',
            mobile_telephone_number: '',
            name: '',
            telephone_number: '',
        },
        trading_countries: [],
        user_accounts: [],

        schedule_monday: null,
        schedule_tuesday: null,
        schedule_wednesday: null,
        schedule_thursday: null,
        schedule_friday: null,

        daily_travel_distance: 0,
        otp_validated: false,
        get_bank_mail_msi: false,
        get_bank_mail_a2bc: false,
    }

    edit_assist: EditAssist
    currently_showing = ShowModus.USER_SCHEDULE
    is_loading = false
    is_loading_vacations = false
    is_self = false

    LIST_ROUTE = '/data/user-manage'
    PAGE_ROUTE = `${this.LIST_ROUTE}/${LoggedInUserData.instance().user_artkey}/edit`

    header_text: string

    constructor() {
        super()

        this.data = proxy({
            dialog: {
                delete: false,
                otp: false,
            },
        })
        this.edit_assist = new EditAssist(m.route)
        this.header_text = ''
        if (this.edit_assist.artkey) {
            this.fetch_user(this.edit_assist.artkey)
        }
        this.header_text = this.edit_assist.is_creating ? 'Creating user' : 'Editing user'
        // @ts-ignore
        this.currently_showing = ShowModus[m.route.param('tab')] // @ts-ignore
            ? ShowModus[m.route.param('tab')]
            : ShowModus.USER_DETAILS
    }

    check_disabled(allow_if_self = false): boolean {
        // Fields none super_user can edit on their own profile
        if (allow_if_self) {
            return (!this.is_self && !this.logged_in_user?.is_superuser) || this.update_user_request.is_deleted || this.is_loading
        }

        return !this.logged_in_user?.is_superuser || this.update_user_request.is_deleted || this.is_loading
    }

    delete_user(): void {
        if (!this.update_user_request.artkey) {
            notifier.notify('Unable to delete the user: user not found', 'danger')
            return
        }

        if (this.is_self) {
            notifier.notify('Unable to delete the user: you can not delete yourself', 'danger')
            return
        }

        this.api.delete_user(this.update_user_request.artkey).subscribe({
            next: () => {
                this.update_user_request.is_deleted = true
                m.route.set(this.LIST_ROUTE)
                notifier.notify('User successfully deleted', 'success')
            },
            error: () => {
                notifier.notify('Something went wrong trying to delete this user, please reload and try again', 'danger')
                m.redraw()
            },
        })
    }

    fetch_user(artkey: number): void {
        this.is_loading = true
        this.api.get_user(artkey).subscribe({
            next: (response) => {
                this.update_user_request = {
                    ...response,
                    trading_countries: response.trading_countries.map((entry) => entry.country_code),
                    user_accounts: response.user_accounts.map((entry) => entry.account.slug),
                }
                this.is_loading = false
                this.is_self = String(this.update_user_request.artkey) === String(LoggedInUserData.instance().user_artkey)
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                this.is_self = false
                m.redraw()
            },
        })
    }

    oncreate(): void {
        LoggedInUserData.user().subscribe({
            next: (value) => {
                this.logged_in_user = value
            },
        })
    }

    view(): m.Children {
        if (this.is_loading) return <Spinner />
        return (
            <div className="c-user-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={this.LIST_ROUTE} />
                    {!this.edit_assist.is_creating && (
                        <div className={'btn-toolbar'}>
                            {!this.check_disabled() && (
                                <DangerButton
                                    icon_class={'glyphicon glyphicon-trash'}
                                    onclick={() => (this.data.dialog.delete = true)}
                                    title={this.update_user_request.is_deleted ? ' User deleted' : ' Delete user'}
                                    disabled={this.is_self}
                                />
                            )}
                            {this.data.dialog.delete && (
                                <Modal title={'Deleting user'} onclose={() => (this.data.dialog.delete = false)}>
                                    Are you sure that you want to delete this user?
                                    <div className={'btn-toolbar'}>
                                        <DangerButton
                                            title={' Delete user'}
                                            icon_class={'glyphicon glyphicon-trash'}
                                            onclick={() => {
                                                this.delete_user()
                                                this.data.dialog.delete = false
                                            }}
                                        />
                                        <DefaultButton
                                            title={'Cancel'}
                                            onclick={() => (this.data.dialog.delete = false)}
                                        />
                                    </div>
                                </Modal>
                            )}

                            {!this.check_disabled() && this.update_user_request.otp_validated && (
                                <DefaultButton
                                    title={' Reset OTP'}
                                    icon_class={'glyphicon glyphicon-refresh'}
                                    onclick={() => this.data.dialog.otp = true}
                                />
                            )}
                            <OtpDialog
                                show={[this.data.dialog, 'otp']}
                                update_user_request={this.update_user_request}
                            />
                        </div>
                    )}
                </div>

                {!this.edit_assist.is_creating && !this.check_disabled(true) && (
                    <ul className="nav nav-tabs">
                        <li
                            role={'presentation'}
                            className={this.currently_showing === ShowModus.USER_DETAILS ? 'active' : ''}
                        >
                            <a
                                href={'#'}
                                onclick={(event: MouseEvent) => {
                                    this.currently_showing = ShowModus.USER_DETAILS
                                    m.route.set(this.PAGE_ROUTE + '?tab=USER_DETAILS')
                                    event.preventDefault()
                                }}
                            >
                                Details
                            </a>
                        </li>
                        <li
                            role={'presentation'}
                            className={this.currently_showing === ShowModus.USER_SCHEDULE ? 'active' : ''}
                        >
                            <a
                                href={'#'}
                                onclick={(event: MouseEvent) => {
                                    this.currently_showing = ShowModus.USER_SCHEDULE
                                    m.route.set(this.PAGE_ROUTE + '?tab=USER_SCHEDULE')
                                    event.preventDefault()
                                }}
                            >
                                Schedule
                            </a>
                        </li>
                        {this.is_self && (
                            <li
                                role={'presentation'}
                                className={this.currently_showing === ShowModus.USER_CHANGE_PW ? 'active' : ''}
                            >
                                <a
                                    href={'#'}
                                    onclick={(event: MouseEvent) => {
                                        this.currently_showing = ShowModus.USER_CHANGE_PW
                                        m.route.set(this.PAGE_ROUTE + '?tab=USER_CHANGE_PW')
                                        event.preventDefault()
                                    }}
                                >
                                    Change password
                                </a>
                            </li>
                        )}
                    </ul>
                )}

                {this.currently_showing === ShowModus.USER_DETAILS && (
                    <UserDetails
                        admin_view={true}
                        api={this.api}
                        update_user_request={this.update_user_request}
                        edit_assist={this.edit_assist}
                        is_self={this.is_self}
                        check_disabled={(allow_if_self: boolean): boolean =>
                            this.check_disabled(allow_if_self)
                        }
                        LIST_ROUTE={this.LIST_ROUTE}
                    />

                )}
                {this.update_user_request.artkey && !this.check_disabled(true) && this.currently_showing === ShowModus.USER_SCHEDULE && (
                    <UserSchedule
                        api={this.api}
                        update_user_request={this.update_user_request}
                        is_loading_vacations={this.is_loading_vacations}
                        check_disabled={(allow_if_self: boolean): boolean => this.check_disabled(allow_if_self)}
                    />
                )}
                {this.currently_showing === ShowModus.USER_CHANGE_PW && this.is_self && (
                    <ChangePassword />
                )}

            </div>
        )
    }
}
