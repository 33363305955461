import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {formatDate} from 'utils.ls'
import {Icon, Spinner} from '@bitstillery/common/components'

import {DianaApi, SearchItem} from '@/factserver_api/diana_api'

interface ProductSearchMissesAttrs {
    product_name: string
}

export class ProductSearchMisses extends MithrilTsxComponent<ProductSearchMissesAttrs> {
    search_items: Array<SearchItem> = []
    loading = true

    constructor(vnode: m.Vnode<ProductSearchMissesAttrs>) {
        super()

        const diana_api = new DianaApi()
        diana_api
            .missed_hits_for_product({
                product_name: vnode.attrs.product_name,
            })
            .subscribe((response: Array<SearchItem>) => {
                this.search_items = response
                this.loading = false
            })
    }

    view(): m.Children {
        return (
            <div className="c-product-search-misses collection-widget">
                <div className="header">
                    <div className="title">
                        <Icon name="search" type="info" />Earlier missed searches for this product
                    </div>
                </div>
                <div className="content">
                    {this.search_items.length === 0 ? (
                        <div class="c-empty-message">
                            {this.loading ? <Spinner /> : 'There are no missed searches for this product.'}
                        </div>
                    ) : (
                        <table className={'table table-condensed'}>
                            <thead className="thead-default">
                                <tr>
                                    <th>Latest search date</th>
                                    <th>Relation</th>
                                    <th>Search term</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.search_items.map((search_item) => (
                                    <tr>
                                        <td>{formatDate(search_item.latest_search)}</td>
                                        <td>{search_item.supplier_name}</td>
                                        <td>{search_item.search_term}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        )
    }
}
