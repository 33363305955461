import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from './api'

export enum DocumentType {
    PRICELIST = 'PRICELIST',
    INVOICE = 'INVOICE',
    PROFORMA_INVOICE = 'PROFORMA_INVOICE',
    OUTTAKE = 'OUTTAKE',
    RFP = 'RFP',
}

export interface SendDocumentToRequest {
    content_type: string
    content: string
    document_name: string
    document_type: DocumentType
    email_to: string
    sales_order_artkey?: number
    purchase_order_artkey?: number
    from_user_artkey: number
}

export class EmailApi {
    api = new Api()

    send_document_to(sent_document_to_request: SendDocumentToRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('email.document.sent_to', sent_document_to_request)
    }
}
