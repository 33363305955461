m = require 'mithril'
{map} = require 'prelude-ls'
{a, prevent-default} = require 'utils.ls'
api = require 'api.ls'
{button-with-icon} = require '@/components/buttons.ls'
{PortalNewsItem} = require 'portal/models.ls'
inputs = require '@/components/inputs'
app = require('@/app')

module.exports = class PortalNewsEdit
    ->
        @news_item = new PortalNewsItem

        @create = false

        artkey = m.route.param 'artkey'
        if artkey
            @query_news_item artkey
        else #we are creating a new one
            @create = true

    query_news_item: (artkey) ~>
        api.call 'portal.news.get_single_news_item' {artkey: artkey}, @set_news_item

    set_news_item: (resp) ~>
        if resp.success
            try
                @news_item = new PortalNewsItem resp.result
            catch
                app.$m.common.generic_error_handler e
        else
            app.notifier.notify 'Unknown news item.', 'danger'
            m.route.set '/portal/portal-news'

    save: (e) ~>
        e.preventDefault!

        data = do
            artkey: @news_item.artkey!
            title: @news_item.title!
            content: @news_item.content!
        api.call 'portal.news.create_or_update_news_item' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            if @create then
                app.notifier.notify "Successfully created new portal news item '#{@news_item.title!}'.", 'success'
                m.route.set '/portal/portal-news/' + result.artkey + '/edit'
            else
                app.notifier.notify 'Successfully updated news item.', 'success'


    view: -> m '.c-portal-news-edit view' a do
        m '.btn-toolbar' a do
            m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/portal/portal-news'},
             (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

        m 'form.flex-form' {onsubmit: @save} a do
            m '.fieldset',
                m '.field' a do
                    m 'label' {for: 'news_item_title'} 'Title'
                    m 'input.form-control' {id: 'news_item_title', type: 'text', \
                        required: true, \
                        placeholder: 'News item title', value: @news_item.title! or '', \
                        oninput: (ev) ~> @news_item.title ev.target.value}
                m '.field' a do
                    m 'label' {for: 'news_item_content'} 'Message'
                    m 'textarea.form-control' {id: 'news_item_content', rows: 15, \
                        placeholder: 'News item message', value: @news_item.content! or '', \
                        oninput: (ev) ~> @news_item.content ev.target.value}

            m 'button.btn.btn-success.btn-submit' if @create then 'Create News' else 'Update News'
