import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, Country, Icon, ReadMoreLessToggle, Tippy} from '@bitstillery/common/components'
import {accountIcon} from 'accounts.ls'
import {show_confirmation} from '@bitstillery/common/lib/dialog'
import {Modal} from 'components/modal/modal.ls'
import {to_specs} from '@bitstillery/common/models/item'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api, notifier} from '@bitstillery/common/app'
import {Amount, SubAmount} from '@bitstillery/common/components'

import {EditPurchaseOrderItem} from './edit_purchase_order_item'
import {HiddenInformation, HiddenPurchaseOrderItemHelper} from './hidden_information'
import {SplitPurchaseOrderItem} from './split_purchase_order_item'
import {SplitTboItems} from './split_tbo_items'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {CaseLabels} from '@/components/labels'
import {Link} from '@/components/discover'
import {drop_down_menus_for_enum, DropDownWithSelect} from '@/components/html_components'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {LoggedInUserData, User} from '@/factserver_api/user_api'
import {
    ErrorResponse,
    GetHiddenInformationResponse,
    GetPurchaseOrderItemsCollectionViewResponseOutput,
    GetPurchaseOrderResponseOutput,
    GetRelatedSalesOrderItemResponse,
    PurchaseOrderStatus,
    RefillStatus,
} from '@/factserver_api/fact2server_api'
import {NumberInput} from '@/components/input_numbers'
import {MoneyInput} from '@/components/decimal_input'

export interface PurchaseOrderItemsAttrs {
    purchase_order: GetPurchaseOrderResponseOutput
    poi_fetcher: PagedCollectionFetcherWithGET<GetPurchaseOrderItemsCollectionViewResponseOutput>
}

interface InlineEditPurchaseOrderItem {
    number_of_cases: number
    number_of_bottles: number
    was_bought_for: string
    suggested_price_per_case?: number
    alcohol_percentage: number
    volume: number
    refill_status: RefillStatus
}

interface PurchaseOrderItemView extends GetPurchaseOrderItemsCollectionViewResponseOutput {
    is_showing_details?: boolean
    is_soi_loading?: boolean
    is_tbo_loading?: boolean
    is_soi_expanded?: boolean
    is_tbo_expanded?: boolean
    related_soi: GetRelatedSalesOrderItemResponse[] | null
    related_tbo: GetRelatedSalesOrderItemResponse[] | null

    is_hidden_information_loading?: boolean
    is_hidden_information_expanded?: boolean
    hidden_information: GetHiddenInformationResponse

    is_editing?: boolean
    inline_edit_data?: InlineEditPurchaseOrderItem
}

interface PurchaseOrderItemsDetailAttrs {
    row: PurchaseOrderItemView
    additional_args: {
        onsave: (row: GetPurchaseOrderItemsCollectionViewResponseOutput) => unknown
        oncancel: (row: GetPurchaseOrderItemsCollectionViewResponseOutput) => unknown
    }
}

/** The row details component. Shows the Edit, Market and Hide information. */
export class PurchaseOrderItemsDetail extends MithrilTsxComponent<PurchaseOrderItemsDetailAttrs> {
    selected_tab: 'market' | 'edit' | 'hide-information-item' = 'market'
    view(vnode: m.Vnode<PurchaseOrderItemsDetailAttrs>) {
        const children: m.Children = []
        const purchase_order_item = vnode.attrs.row
        if (!purchase_order_item.is_showing_details) {
            return children
        }

        children.push(
            <div className="c-tabs">
                <ul className="nav nav-tabs">
                    <li
                        className={classes('nav-link', {active: this.selected_tab === 'market'})}
                        onclick={() => this.selected_tab = 'market'}
                    >
                        <a>Market</a>
                    </li>
                    {purchase_order_item.lots.length === 0 && <li
                        className={classes('nav-link', {active: this.selected_tab === 'edit'})}
                        onclick={() => this.selected_tab = 'edit'}
                    >
                        <a>Edit</a>
                    </li>}
                    <li
                        className={classes('nav-link', {active: this.selected_tab === 'hide-information-item'})}
                        onclick={() => this.selected_tab = 'hide-information-item'}
                    >
                        <a>Hide information item</a>
                    </li>
                </ul>
            </div>,
        )

        if (this.selected_tab === 'market') {
            children.push(<CaseInfoPanel customs_status={purchase_order_item.customs_status} bottle_artkey={purchase_order_item.bottle_artkey} />)
        }
        else if (this.selected_tab === 'hide-information-item') {
            children.push(
                <HiddenInformation
                    hidden_information_helper={new HiddenPurchaseOrderItemHelper(purchase_order_item.purchase_order_artkey, purchase_order_item.artkey)}
                />)
        }
        else if (this.selected_tab === 'edit') {
            children.push(<EditPurchaseOrderItem
                purchase_order_item={purchase_order_item}
                onsave={(row) => {
                    vnode.attrs.additional_args.onsave(row)
                }}
                oncancel={(row) => {
                    vnode.attrs.additional_args.oncancel(row)
                }}
            />)
        }
        return <tr className="well">
            <td colspan={'99'}>
                <div className="well-container">{children}</div>
            </td>
        </tr>
    }
}

export class PurchaseOrderItems extends MithrilTsxComponent<PurchaseOrderItemsAttrs> {

    data = proxy({
        dialog: {
            loading: false,
            title: 'Remove Purchase Order Item',
            type: 'danger',
        },
    })

    poi_fetcher: PagedCollectionFetcherWithGET<GetPurchaseOrderItemsCollectionViewResponseOutput>
    purchase_api = new PurchaseApi()
    purchase_order: GetPurchaseOrderResponseOutput
    show_modal_split_tbo = false
    show_modal_split_item = false
    modal_split_item: GetPurchaseOrderItemsCollectionViewResponseOutput | null

    user: User | null

    constructor(vnode: m.Vnode<PurchaseOrderItemsAttrs>) {
        super()
        this.purchase_order = vnode.attrs.purchase_order
        this.poi_fetcher = vnode.attrs.poi_fetcher
    }

    oncreate(): any {
        LoggedInUserData.user().subscribe({
            next: (user) => this.user = user,
        })
    }

    async load_related_soi(row: PurchaseOrderItemView) {
        row.is_soi_expanded = !row.is_soi_expanded
        row.is_soi_loading = true
        const {result} = await api.get(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/sales-order-items`)
        row.is_soi_loading = false
        row.related_soi = result as any
        m.redraw()
        return result
    }

    async load_related_tbo(row: PurchaseOrderItemView) {
        row.is_tbo_expanded = !row.is_tbo_expanded
        row.is_tbo_loading = true
        const {result} = await api.get(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/sales-order-tbo-items`)
        row.is_tbo_loading = false
        row.related_tbo = result as any
        m.redraw()
        return result
    }

    async load_hidden_information(row: PurchaseOrderItemView) {
        row.is_hidden_information_expanded = !row.is_hidden_information_expanded
        if (row.is_hidden_information_expanded) {
            row.is_hidden_information_loading = true
            const {result} = api.get(`discover/purchase-orders/${this.purchase_order.artkey}/items/${row.artkey}/hidden-information`)
            row.is_hidden_information_loading = false
            row.hidden_information = result
            m.redraw()
        }
    }

    async remove_purchase_order_item(row: PurchaseOrderItemView) {
        const sales_order_items = await this.load_related_soi(row) as any
        const confirmation = show_confirmation({
            body: () => [
                <span>{$t('poi.remove.dialog.confirm', {
                    alcohol_percentage: row.alcohol_percentage,
                    product_name: row.product_name,
                    volume: row.volume,
                })}</span>,

                row.number_of_cases_in_soi > 0 ? [
                    <br/>,
                    <span>{$t('poi.remove.dialog.soi_list', {count: sales_order_items.length})}</span>,
                    <ul>
                        {sales_order_items.map((i) => <li>
                            <Link href={`/sales-orders/manage/${i.sales_order_artkey}`}>
                                {i.sales_order_reference} - {i.relation_name}
                            </Link>
                        </li>)}
                    </ul>,
                    <span>{$t('poi.remove.dialog.soi_confirm')}</span>,
                ] : null,
            ],
            confirm: {
                action: async() => {
                    this.data.dialog.loading = true
                    await api.post('purchase.purchase_order_item.delete_purchase_order_item_from_purchase_order', {
                        purchase_order_item_artkey: row.artkey,
                    })
                    this.poi_fetcher.fetched_rows = this.poi_fetcher.fetched_rows.filter((it) => it.artkey !== row.artkey)
                    this.data.dialog.loading = false
                    confirmation.close()
                    notifier.notify($t('notifications.poi_removed', {name: row.artkey}), 'info')
                },
                icon: 'trash',
                text: $t('poi.remove.button'),
            },
            model: this.data.dialog,
        })
    }

    async save_purchase_order_item(row: PurchaseOrderItemView) {
        const {result, response, status_code} = await api.put<ErrorResponse>(`discover/purchase-orders/${row.purchase_order_artkey}/items/${row.artkey}`, {
            bottle_artkey: row.bottle_artkey,
            volume: row.volume,
            alcohol_percentage: row.alcohol_percentage,
            suggested_sales_price: row.suggested_price_per_case,
            refill_status: row.refill_status,
            was_bought_for: row.was_bought_for,
            number_of_cases: row.number_of_cases,
            number_of_bottles_per_case: row.number_of_bottles,
            customs_status: row.customs_status,
            tax_label: row.tax_label,
            gift_box_type: row.gift_box_type,
            suggested_price_per_case: row.suggested_price_per_case,
            remark: row.remark,
            bottle_gtin_code: row.bottle_gtin_code,
            case_gtin_code: row.case_gtin_code,
            serialized_item_tags: row.serialized_item_tags.split(',').map(it => +it),
            country_of_origin: row.country_of_origin,
            best_before_date: row.best_before_date,
            cases_per_pallet: row.cases_per_pallet,
            cases_per_pallet_layer: row.cases_per_pallet_layer,
        })
        if (response.ok) {
            row.is_showing_details = false
            row.is_editing = false
            row.inline_edit_data = undefined
            notifier.notify('Successfully updated purchase order item.', 'success')
        } else if (status_code === 400) {
            notifier.notify(`Could not save item: ${result.detail}.`, 'warning')
        } else {
            notifier.notify(`Could not save: ${response.statusText}.`, 'warning')
        }
        m.redraw()
    }

    show_split_modal(row: GetPurchaseOrderItemsCollectionViewResponseOutput): void {
        this.modal_split_item = row
        if (row.number_of_cases > row.number_of_cases_in_tbo) {
            this.show_modal_split_item = true
        } else {
            this.show_modal_split_tbo = true
        }
    }

    close_split_modal(): void {
        this.show_modal_split_item = false
        this.show_modal_split_tbo = false
        this.modal_split_item = null
        this.poi_fetcher.reset_and_query()
    }

    start_editing(row: PurchaseOrderItemView): void {
        row.is_editing = true
        row.inline_edit_data = {
            number_of_bottles: row.number_of_bottles,
            number_of_cases: row.number_of_cases,
            was_bought_for: row.was_bought_for,
            suggested_price_per_case: row.suggested_price_per_case,
            alcohol_percentage: row.alcohol_percentage,
            volume: row.volume,
            refill_status: row.refill_status,
        }
    }

    cancel_editing(row: PurchaseOrderItemView): void {
        row.is_editing = false
        if (row.inline_edit_data) {
            row.volume = row.inline_edit_data.volume
            row.alcohol_percentage = row.inline_edit_data.alcohol_percentage
            row.number_of_cases = row.inline_edit_data.number_of_cases
            row.number_of_bottles = row.inline_edit_data.number_of_bottles
            row.was_bought_for = row.inline_edit_data.was_bought_for
            row.suggested_price_per_case = row.inline_edit_data.suggested_price_per_case
            row.refill_status = row.inline_edit_data.refill_status
        }
        row.inline_edit_data = undefined
    }

    save_editing(row: PurchaseOrderItemView): void {
        this.save_purchase_order_item(row)
    }

    view(): m.Children {
        return (
            <div className="c-purchase-order-items">
                {this.show_modal_split_item &&
                    <Modal title={'Split Purchase Order Item'} onclose={() => this.close_split_modal()}>
                        <SplitPurchaseOrderItem
                            purchase_order_item={this.modal_split_item}
                            done={() => this.close_split_modal()}
                            switch_to_tbo={() => {
                                this.show_modal_split_item = false
                                this.show_modal_split_tbo = true
                            }}
                        />
                    </Modal>}
                {this.show_modal_split_tbo &&
                    <Modal title={'Split TBO item'} onclose={() => this.close_split_modal()}>
                        <SplitTboItems
                            purchase_order_item={this.modal_split_item}
                            purchase_order={this.purchase_order}
                            done={() => this.close_split_modal()}
                            cancel={() => this.close_split_modal()}
                            switch_to_poi={() => {
                                this.show_modal_split_item = true
                                this.show_modal_split_tbo = false
                            }}
                        />
                    </Modal>}

                <CollectionTable<GetPurchaseOrderItemsCollectionViewResponseOutput, void>
                    collection_fetcher={this.poi_fetcher}
                    on_row_click={(row: PurchaseOrderItemView) => (row.is_showing_details = !row.is_showing_details)}
                    on_row_click_component={PurchaseOrderItemsDetail}
                    additional_row_component_args={{
                        onsave: async(row) => this.save_purchase_order_item(row),
                        oncancel: () => this.poi_fetcher.reset_and_query(),
                    }}
                >
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Name'}
                        sort_name={'product_name'}
                        data_field={(row) =>
                            <span>
                                {row.requires_import_statement && <Icon size={'s'} name={'warning'} tip={'This item requires a Signed Import statement'}/>}
                                {row.product_name}
                                <span className={'help-block'}>{row.category_name}</span>
                                {row.bottle_gtin_code && <span className={'help-block'}>{row.bottle_gtin_code}</span>}
                            </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Specs'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <span >

                                    <DropDownWithSelect
                                        label={'Refill status'}
                                        selected={row.refill_status.valueOf()}
                                        onchange={(value) => row.refill_status = Object.values(RefillStatus).find(ref => ref === value) || RefillStatus.Nonref}
                                    >
                                        {drop_down_menus_for_enum(RefillStatus)}
                                    </DropDownWithSelect>
                                    <NumberInput
                                        label={'Alcohol percentage'}
                                        value={row.alcohol_percentage}
                                        oninput={(val) => row.alcohol_percentage = val}
                                    />
                                    <NumberInput
                                        label={'Volume'}
                                        value={row.volume}
                                        oninput={(val) => row.volume = val}
                                    />
                                </span>
                                : <span>
                                    <div key={''}>
                                        {to_specs(row, this.user?.decimal_local || 'en')}
                                    </div>
                                    <div key={''}>
                                        {row.gift_box_type}
                                    </div>
                                    <div key={row.artkey + row.cases_per_pallet + row.cases_per_pallet_layer}>
                                        <CaseLabels show_as_icons={true} case={row}/>
                                    </div>
                                </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Bottles per case'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <NumberInput
                                    value={row.number_of_bottles}
                                    oninput={(val) => row.number_of_bottles = val}
                                />
                                : row.number_of_bottles}
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Cases'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <NumberInput
                                    value={row.number_of_cases}
                                    oninput={(val) => row.number_of_cases = val}
                                />
                                : row.number_of_cases
                        }
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        header_title={() => 'TBO'}
                        data_field={(row: PurchaseOrderItemView) => {
                            if (row.number_of_cases_in_tbo === 0) {
                                return '-'
                            }
                            return <div>
                                <ReadMoreLessToggle
                                    is_expanded={row.is_tbo_expanded}
                                    onclick={() => this.load_related_tbo(row)}
                                    is_loading={row.is_tbo_loading}
                                    summary={`${row.number_of_cases_in_tbo} cs`}
                                >
                                    {row.related_tbo?.map((tbo) =>
                                        <div>
                                            {accountIcon({
                                                slug: tbo.sales_order_account_slug,
                                                name: tbo.sales_order_account_name,
                                            })}
                                            <Tippy
                                                content={`${tbo.relation_name} (${tbo.sales_manager_name})`}
                                                allowHTML={true}
                                            >
                                                <Link href={`/sales-orders/manage/${tbo.sales_order_artkey}`}>
                                                    {`${tbo.sales_order_reference} (${tbo.number_of_cases} cs)`}
                                                </Link>
                                            </Tippy>
                                        </div>,
                                    )}
                                </ReadMoreLessToggle>
                            </div>
                        }
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Sales order item'}
                        data_field={(row: PurchaseOrderItemView) => {
                            if (row.number_of_cases_in_soi === 0) {
                                return '-'
                            }
                            return <span>
                                <ReadMoreLessToggle
                                    is_expanded={row.is_soi_expanded}
                                    onclick={() => this.load_related_soi(row)}
                                    is_loading={row.is_soi_loading}
                                    summary={`${row.number_of_cases_in_soi} cs`}
                                >
                                    {row.related_soi?.map((soi) =>
                                        <div>
                                            {accountIcon({
                                                slug: soi.sales_order_account_slug,
                                                name: soi.sales_order_account_name,
                                            })}
                                            <Link href={`/sales-orders/manage/${soi.sales_order_artkey}`}>
                                                {`${soi.sales_order_reference} (${soi.number_of_cases} cs)`}
                                            </Link>
                                        </div>,
                                    )}
                                </ReadMoreLessToggle>
                            </span>

                        }}
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        header_title={() => 'Price / cs'}
                        data_field={(row: PurchaseOrderItemView) => {
                            const is_bid_price = row.spl_artkey && +row.spli_price_per_case !== +row.was_bought_for
                            if (row.is_editing) {
                                return <MoneyInput
                                    currency={row.was_bought_in}
                                    value={row.was_bought_for}
                                    on_value={(val) => row.was_bought_for = val}
                                />
                            }
                            return <div className={classes('td-group')}>
                                <div className={'header'}>
                                    {is_bid_price && <div>Bid:</div>}
                                    <Amount amount={row.was_bought_for} currency={row.was_bought_in}
                                        rate={row.bought_against_rate}/>
                                </div>
                                <div className={'details'}>
                                    <SubAmount label={'Costs:'} amount={row.was_bought_for_plus_costs}
                                        currency={row.was_bought_in} rate={row.bought_against_rate}/>

                                    {row.spl_artkey &&
                                        <Link
                                            href={`/market/pricelists/${row.spl_artkey}?search_terms=${row.product_name.replaceAll(' ', '+')}`}>
                                            <Amount amount={row.spli_price_per_case} currency={row.was_bought_in}
                                                rate={row.bought_against_rate}/>
                                            <Icon
                                                name={row.same_bottle_as_origin ? 'pricelist' : 'danger'}
                                                size={'s'}
                                                tip={
                                                    row.same_bottle_as_origin ?
                                                        'Open the related pricelist' :
                                                        'Different specs on relation pricelist and PO'
                                                }
                                                type={row.same_bottle_as_origin ? 'info' : 'danger'}
                                            />
                                        </Link>
                                    }
                                </div>
                            </div>

                        }}
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Total value'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) =>
                            <Amount amount={row.total_price} currency={row.was_bought_in} rate={row.bought_against_rate}/>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Sales price / cs'}
                        data_field={(row: PurchaseOrderItemView) =>
                            row.is_editing
                                ? <MoneyInput
                                    currency={row.was_bought_in}
                                    value={row.suggested_price_per_case}
                                    on_value={(val) => row.suggested_price_per_case = val}
                                />

                                : <Amount amount={row.suggested_price_per_case} currency={'EUR'}/>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Cus.'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) => row.customs_status}
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Country of origin'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) =>
                            <Country country_code={row.country_of_origin} type={'flag_with_country'}/>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Hide information'}
                        data_field={(row: PurchaseOrderItemView) =>
                            <ReadMoreLessToggle
                                is_expanded={row.is_hidden_information_expanded}
                                onclick={() => this.load_hidden_information(row)}
                                disabled={(row.hidden_for_supplier_artkeys.length + row.hidden_in_country_codes.length) === 0}
                                is_loading={row.is_hidden_information_loading}
                                summary={`${row.hidden_in_country_codes.length} countries / ${row.hidden_for_supplier_artkeys.length} relations`}
                            >
                                {row.hidden_information && <div>
                                    <label>Countries: </label>
                                    {row.hidden_information.hidden_country_codes.map(
                                        (country_code) => <Country country_code={country_code} type={'flag_with_country'}/>,
                                    )}
                                    <label>Relations: </label>
                                    {row.hidden_information.hidden_relations.map(
                                        (relation) => <div>{relation.name}</div>)}
                                </div>}
                            </ReadMoreLessToggle>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Lot nrs'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) => <span>
                            <Link href={`/stock/manage/?q=P${row.purchase_order_artkey}+${row.product_name}+${row.volume}+${row.alcohol_percentage}&avalailable_only=false`} >
                                <Icon name={'stock'}/>
                            </Link>
                            {row.lots.map((lot) => <Link href={`/stock/manage?q=${lot}`}>{' ' + lot}</Link>)}
                        </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Aux'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) => <span>
                            {row.spli_suppliers_item_code && <div><label>Supplier code:</label>{` ${row.spli_suppliers_item_code}`}</div>}
                            {row.spli_hs_code && <div><label>Supplier HS code:</label>{` ${row.spli_hs_code}`}</div>}
                            {row.spli_case_gross_weight_in_kg && <div><label>Supplier weight:</label>{` ${row.spli_case_gross_weight_in_kg}`}</div>}
                            {row.spli_aux_info && <div><label>AUX:</label>{` ${row.spli_aux_info}`}</div>}
                        </span>
                        }
                    />
                    <CollectionTableColumn<GetPurchaseOrderItemsCollectionViewResponseOutput>
                        header_title={() => 'Dimensions'}
                        data_field={(row: GetPurchaseOrderItemsCollectionViewResponseOutput) => <span>
                            {row.dimensions && <div><label>LxBxH:</label>{` ${row.dimensions}`}</div>}
                            {row.weight && <div><label>Weight:</label>{` ${row.weight} kg`}</div>}
                        </span>}
                    />
                    <CollectionTableColumn<PurchaseOrderItemView>
                        td_class_name="actions"
                        header_title={() => ''}
                        data_field={(row: PurchaseOrderItemView) => {
                            const is_delete_present = [PurchaseOrderStatus.Saved, PurchaseOrderStatus.Confirmed].includes(row.purchase_order_status)
                            return <div className="actions-group no-click">
                                <ButtonGroup>
                                    {!row.is_editing && <Button
                                        icon="edit"
                                        onclick={() => this.start_editing(row)}
                                        tip={'Edit this row'}
                                    />}

                                    {row.is_editing && [
                                        <Button
                                            icon="cancel"
                                            onclick={() => this.cancel_editing(row)}
                                            tip="Cancel the editing operation"
                                        />,
                                        <Button
                                            icon="save"
                                            onclick={() => this.save_editing(row)}
                                            tip="Save the editing operation"
                                            type="info"
                                        />,
                                    ]}

                                    <Button
                                        icon="split"
                                        onclick={() => this.show_split_modal(row)}
                                        tip="Show the split purchase order item dialog"
                                    />

                                    {is_delete_present && <Button
                                        icon="trash"
                                        onclick={() => this.remove_purchase_order_item(row)}
                                        tip={() => {
                                            if (row.number_of_cases_in_soi > 0) return 'Delete this item and convert sales order items'
                                            return 'Delete this item'
                                        }}
                                        type="danger"
                                    />}
                                </ButtonGroup>
                            </div>
                        }}
                    />
                </CollectionTable>
            </div>
        )
    }
}
