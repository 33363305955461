import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money, titleize} from '@bitstillery/common/ts_utils'
import {format_iso_to_date, format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {to_specs} from '@bitstillery/common/models/item'
import {Amount, Spinner, Tippy} from '@bitstillery/common/components'

import {$s} from '@/app'
import {NumberInput} from '@/components/input_numbers'
import {MoneyInput} from '@/components/decimal_input'
import {CancelButton, DangerButton, DefaultButton, SuccessButton} from '@/components/buttons'
import {
    SelectionController,
    SelectionEnabledComponent,
    SelectionEnabledComponentAttrs,
} from '@/components/selection_controller'
import {Link} from '@/components/discover'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {CaseLabels} from '@/components/labels'
import {ClipboardCopy} from '@/components/clipboard'
import {
    calculate_number_of_bottles_tbo,
    calculate_total_ex_duty_tbo,
    calculate_total_value_tbo,
    GetFastSalesOrderWithItemsResponse,
    SalesApi,
    SalesOrderStatus,
    SalesOrderTBOItem,
    SalesOrderTBOItemStatus,
} from '@/factserver_api/sales_api'

export interface SalesOrderTBOItemAttrs extends SelectionEnabledComponentAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_tbo_item: SalesOrderTBOItem
    sales_order_changed: () => void
}

/**
 * Show the TBO item in a table row. Contains support for:
 * - deleting
 * - confirming
 * - cancelling
 * - editing
 * - selecting
 * of the tbo item.
 */
export class SalesOrderTBOItemView
    extends MithrilTsxComponent<SalesOrderTBOItemAttrs>
    implements SelectionEnabledComponent
{
    sales_api = new SalesApi()
    sales_order_item_changed: () => void
    is_performing_api_call = false
    is_editing = false
    is_showing_details = false
    is_selecting = false
    selection_controller: SelectionController | null = null

    constructor(vnode: m.Vnode<SalesOrderTBOItemAttrs>) {
        super()
        this.sales_order_item_changed = vnode.attrs.sales_order_changed
        vnode.attrs.register_selection_enabled_component(this)
    }

    cancel_edit(): void {
        this.is_editing = false
        this.sales_order_item_changed()
    }

    cancel_tbo_item(tbo_item_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.cancel_tbo_item(tbo_item_artkey).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.sales_order_item_changed()
            },
            error: () => {
                this.is_performing_api_call = false
                m.redraw()
            },
        })
    }

    confirm_tbo_item(tbo_item_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.confirm_tbo_item(tbo_item_artkey).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.sales_order_item_changed()
            },
            error: () => {
                this.is_performing_api_call = false
                m.redraw()
            },
        })
    }

    delete_sales_order_tbo_item(sales_order_item_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.delete_sales_order_tbo_item(sales_order_item_artkey).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.sales_order_item_changed()
            },
            error: () => {
                this.is_performing_api_call = false
                m.redraw()
            },
        })
    }

    end_selection(): void {
        this.is_selecting = false
        this.selection_controller = null
    }

    save_edit(tbo: SalesOrderTBOItem): void {
        if (tbo.number_of_cases < 1 || +tbo.price_per_case < 0) {
            return
        }
        this.is_performing_api_call = true
        this.sales_api
            .update_tbo_item(tbo.artkey, +tbo.price_per_case, tbo.number_of_cases, tbo.portal_comment)
            .subscribe({
                next: () => {
                    this.is_performing_api_call = false
                    this.is_editing = false
                    this.sales_order_item_changed()
                },
                error: () => {
                    this.is_performing_api_call = false
                    m.redraw()
                },
            })
    }

    start_selection(selection_controller: SelectionController): void {
        this.is_selecting = true
        this.is_editing = false
        this.selection_controller = selection_controller
    }

    toggle_details(event: Event): void {
        if (event.target) {
            const closest = $(event.target).closest('.no-click')
            if (closest.length === 0) {
                this.is_showing_details = !this.is_showing_details
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderTBOItemAttrs>): m.Children {
        const tbo = vnode.attrs.sales_order_tbo_item
        const sales_order = vnode.attrs.sales_order
        const _case = tbo.case
        const tr_class = this.is_editing ? 'no-click' : ''
        return (
            <tbody className={'table-row'} key={tbo.artkey}>
                <tr className={tr_class} onclick={(event: Event) => this.toggle_details(event)}>
                    <td>
                        {_case.bottle.product.name} <ClipboardCopy text={_case.bottle.product.name} />
                        {tbo.have_case_in_stock &&
                            <Link href={`/stock/manage/?q=${_case.bottle.product.name} ${_case.bottle.volume} ${_case.bottle.alcohol_percentage}`} target={'_blank'}>
                                <Tippy content={'A different item for the same specs and case details is already in stock.'}>
                                    {' '}<span class="glyphicon glyphicon-warning-sign"/>
                                </Tippy>
                            </Link>
                        }
                        <span className={'help-block'}>{_case.bottle.product.category}</span>
                    </td>
                    <td>{_case.number_of_bottles}</td>
                    <td>{to_specs(_case.bottle, $s.identity.user.decimal_locale)}</td>
                    <td>{_case.gift_box_type}</td>
                    <td>
                        <CaseLabels case={_case} show_as_icons={true} />
                    </td>
                    <td>{_case.customs_status}</td>
                    {!this.is_editing && <td className={'number'}>{tbo.number_of_cases}</td>}
                    {this.is_editing && (
                        <td className={'number'}>
                            <NumberInput
                                required={true}
                                minimum={0}
                                value={tbo.number_of_cases}
                                oninput={(val: number) => (tbo.number_of_cases = val)}
                            />
                        </td>
                    )}
                    <td className={'number'}>{calculate_number_of_bottles_tbo(tbo)}</td>
                    {!this.is_editing && (
                        <td className={'price'}>
                            <span
                                data-toggle={'popover'}
                                data-trigger={'hover'}
                                data-title={'Was first sold for'}
                                data-delay={50}
                                data-html={'true'}
                                data-content={`${format_money(
                                    tbo.was_first_sold_for,
                                    sales_order.was_sold_in,
                                    $s.identity.user.decimal_locale,
                                )}`}
                            >
                                <Amount
                                    amount={tbo.price_per_case}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />
                                {sales_order.includes_excise && (
                                    <span className={'help-block'}>
                                        Duty:{' '}
                                        <Amount
                                            amount={tbo.excise_per_case}
                                            currency={sales_order.was_sold_in}
                                            rate={sales_order.sold_against_rate}
                                        />
                                    </span>
                                )}
                            </span>
                        </td>
                    )}
                    {this.is_editing && (
                        <td>
                            <MoneyInput
                                value={tbo.price_per_case}
                                required={true}
                                on_value={(value: number) => (tbo.price_per_case = value)}
                                currency={sales_order.was_sold_in}
                            />
                        </td>
                    )}
                    <td className={'price'}>
                        <Amount
                            amount={calculate_total_value_tbo(tbo)}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                        {sales_order.includes_excise && (
                            <span className={'help-block'}>
                                Ex Duty:{' '}
                                <Amount
                                    amount={calculate_total_ex_duty_tbo(tbo)}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />
                            </span>
                        )}
                    </td>
                    <td>
                        {tbo.confirmed_for_purchase_date && (
                            <span>
                                {tbo.status}{' '}
                                {tbo.status === SalesOrderTBOItemStatus.OPEN && (
                                    <Link href={'/sales-orders/assigned-stock/'} target={'_blank'}>
                                        <span className={'fas fa-heart'} /> Not yet ordered
                                    </Link>
                                )}
                                {tbo.status === SalesOrderTBOItemStatus.ORDERED && (
                                    <Link
                                        href={`/purchase-orders/manage/${tbo.purchase_order_reference}?account=${tbo.purchase_order_account_slug}`}
                                        target={'_blank'}
                                    >
                                        <span className={'fas fa-shopping-cart'} /> {tbo.purchase_order_reference}
                                        <span> {tbo.purchase_order_eta ? format_iso_to_date(tbo.purchase_order_eta) : 'ETA: Unknown'}</span>
                                    </Link>
                                )}
                            </span>
                        )}
                        {!tbo.confirmed_for_purchase_date && SalesOrderTBOItemStatus.NOT_APPROVED}
                    </td>
                    <td>{format_iso_to_date(tbo.purchase_order_date || '')}</td>
                    <td>{format_iso_to_date(tbo.purchase_order_eta || '')}</td>
                    <td>
                        {format_iso_to_date(tbo.created_from_portal_timestamp || '')}
                        <Tippy
                            content={`<strong>History</strong>
                                    <ul>
                                        <li>Created on: ${format_iso_to_date_time(tbo.created_on)}</li>
                                        <li>Created in: ${
            tbo.created_from_portal_timestamp ? 'Portal' : 'Discover'
            }</li>
                                        <li>Last updated on: ${format_iso_to_date_time(tbo.was_last_updated_on)}</li>
                                        <li>Last updated by: ${titleize(
                tbo.was_last_updated_by_discover_user.name || '-',
            )}</li>
                                        <li>Added from portal: ${format_iso_to_date_time(
                tbo.created_from_portal_timestamp || '',
            )}</li>
                                        <li>Last updated in portal by: ${
            tbo.was_last_updated_by_portal_user.name || '-'
            }</li>
                                    </ul>`}
                            allowHTML={true}
                        >
                            {' '}
                            <i class="far fa-clipboard" />
                        </Tippy>
                    </td>
                    {!this.is_performing_api_call && (
                        <td>
                            {!this.is_selecting && (
                                <span>
                                    {!this.is_editing && sales_order.combined_status !== SalesOrderStatus.INVOICED && (
                                        <div className={'pull-right'} style={'display: flex'}>
                                            <DefaultButton
                                                icon_class={'glyphicon glyphicon-pencil'}
                                                onclick={() => (this.is_editing = true)}
                                            />
                                            <DangerButton
                                                icon_class={'glyphicon glyphicon-remove'}
                                                onclick={() => this.delete_sales_order_tbo_item(tbo.artkey)}
                                            />
                                            {!tbo.confirmed_for_purchase_date && (
                                                <SuccessButton
                                                    icon_class={'glyphicon glyphicon-ok'}
                                                    title={' '}
                                                    onclick={() => this.confirm_tbo_item(tbo.artkey)}
                                                />
                                            )}
                                            {[
                                                SalesOrderStatus.SAVED,
                                                SalesOrderStatus.PENDING,
                                                SalesOrderStatus.FINALIZED,
                                                SalesOrderStatus.CONFIRMED,
                                            ].includes(sales_order.combined_status) &&
                                                tbo.status === SalesOrderTBOItemStatus.OPEN && (
                                                <CancelButton onclick={() => this.cancel_tbo_item(tbo.artkey)} />
                                            )}
                                        </div>
                                    )}
                                    {this.is_editing && (
                                        <div className={'pull-right'}>
                                            <CancelButton onclick={() => this.cancel_edit()} />
                                            <SuccessButton
                                                icon_class={'glyphicon glyphicon-ok'}
                                                title={' '}
                                                onclick={() => this.save_edit(tbo)}
                                            />
                                        </div>
                                    )}
                                </span>
                            )}
                        </td>
                    )}
                    {this.is_performing_api_call && (
                        <div style={'width: 81px'}>
                            <Spinner />
                        </div>
                    )}
                </tr>
                {tbo.portal_comment && (
                    <tr>
                        <td colspan={'100%'}>
                            <label className={'col-sm-2'}>
                                Portal comment
                                {!this.is_editing && <span className={'fa fa-comments'} />}
                                {this.is_editing && (
                                    <DangerButton
                                        icon_class={'glyphicon glyphicon-remove'}
                                        onclick={() => (tbo.portal_comment = '')}
                                    />
                                )}
                            </label>

                            <div className={'col-sm-4'}>{tbo.portal_comment}</div>
                        </td>
                    </tr>
                )}
                {this.is_showing_details && (
                    <tr className={'well'}>
                        <td colspan={'100%'}>
                            <CaseInfoPanel
                                bottle_artkey={tbo.case.bottle.artkey}
                                case_customs_status={tbo.case.customs_status}
                            />
                        </td>
                    </tr>
                )}
                {this.is_showing_details && (
                    <tr className={'well'}>
                        <td colspan={'100%'}>
                            <BottleSalesOrders
                                bottle_artkey={tbo.case.bottle.artkey}
                                current_supplier_artkey={sales_order.supplier.artkey}
                                case_artkey={tbo.case.artkey}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        )
    }
}

interface SalesOrderTBOItemsAttrs extends SelectionEnabledComponentAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_changed: () => void
}

/**
 * Shows the sales order tbo items of a sales order
 */
export class SalesOrderTBOItems
    extends MithrilTsxComponent<SalesOrderTBOItemsAttrs>
    implements SelectionEnabledComponent
{
    is_selecting = false
    selection_controller: SelectionController | null = null

    constructor(vnode: m.Vnode<SalesOrderTBOItemsAttrs>) {
        super()

        vnode.attrs.register_selection_enabled_component(this)
    }

    end_selection(): void {
        this.is_selecting = false
    }

    start_selection(selection_controller: SelectionController): void {
        this.is_selecting = true
        this.selection_controller = selection_controller
    }

    view(vnode: m.Vnode<SalesOrderTBOItemsAttrs>): m.Children {
        return (
            <span>
                {vnode.attrs.sales_order.sales_order_tbo_items.length > 0 && (
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <h4>TBO</h4>
                        </div>
                        <div className={'col-sm-12'}>
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>Product</th>
                                        <th>Btl / cs</th>
                                        <th>Specs</th>
                                        <th>GB</th>
                                        <th>Features</th>
                                        <th>Cus.</th>
                                        <th>Cases</th>
                                        <th>Bottles</th>
                                        <th>Price / cs</th>
                                        <th>Total value</th>
                                        <th>Status</th>
                                        <th>Purchase date</th>
                                        <th>ETA</th>
                                        <th>Added from portal</th>
                                        <th />
                                    </tr>
                                </thead>
                                {/* Display the sales_order items.*/}
                                {vnode.attrs.sales_order.sales_order_tbo_items.map((tbo) => (
                                    <SalesOrderTBOItemView
                                        sales_order={vnode.attrs.sales_order}
                                        sales_order_tbo_item={tbo}
                                        sales_order_changed={vnode.attrs.sales_order_changed}
                                        register_selection_enabled_component={
                                            vnode.attrs.register_selection_enabled_component
                                        }
                                    />
                                ))}
                            </table>
                        </div>
                    </div>
                )}
            </span>
        )
    }
}
