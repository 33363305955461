m = require 'mithril'

{icon-button} = require '@/components/buttons.ls'
{deref} = require '@bitstillery/common/utils.ls'


export Box = (initial_vnode) ->
    {cls, title, body, loading, collapsed} = initial_vnode.attrs

    return do
        view: (vnode) ->
            box_cls = if collapsed! then "#{cls}.collapsed-box" else cls
            m ".box#{box_cls}",
                m '.box-header.with-border',
                    m 'h3.box-title' title
                    m '.box-tools.pull-right',
                        icon-button 'fa-minus', do
                            class: 'btn-box-tool'
                            'data-widget': 'collapse'
                m '.box-body', deref body!
                if loading!
                    m '.overlay' m 'i.fa.fa-spinner.fa-spin'
