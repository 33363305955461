import {make_route, redirect_to, require_auth, require_no_auth} from '@bitstillery/common/lib/router'
import {LayoutLanding, AuthForgotPassword, AuthResetPassword} from '@bitstillery/common/components'
import WarehousesList from 'data/warehouses/warehouse_list.ls'
import WarehousesDeactivate from 'data/warehouses/warehouse_deactivate.ls'
import WarehousesUpsert from 'data/warehouses/warehouse_edit.ls'
import PortalDemoUsers from 'portal/portal_demo_users/portal_demo_users.ls'

import {LayoutDiscover} from './components/layout'
// Crm module
import CrmLeadsList from './crm/leads/list'
import CrmLeadsEdit from './crm/leads/edit.ls'
import CrmLeadsView from './crm/leads/view'
import CrmEmailBatchList from './email/batches'
import CrmEmailBatch from './email/details/email_batch.ls'
import CrmRelationsList from './data/suppliers/supplier_list/index'
import CrmUploadsUpload from './crm/upload/upload_crm_file'
import CrmRelationsDeactivate from './data/suppliers/supplier_deactivate.ls'
import CrmRelationsUpsert from './data/suppliers/supplier_edit.ls'
import CrmRelationsContactPersonUpsert from './data/suppliers/contact_person_edit.ls'
import CrmRelationsWarehousesSelect from './data/suppliers/warehouse_select.ls'
// Email module
import EmailRecipientSelection from './email/email_recipient_selection.ls'
import EmailUpsert from './email/email_edit.ls'
// User module
import UserExactAuth from './user/exact_auth'
// Misc modules
import DashboardFigures from './dashboard/figures_dashboard'
import Login from './components/pages/login/login'
// Dashboard module
import DashboardPortal from './dashboard/portal_dashboard'
import DashboardSearchlog from './portal/portal_search_log.ls'
import DashboardDiana from './data/diana/topic_listeners.ls'
import DashboardReports from './data/reports/reports'
// Afvalfonds module
import AfvalfondsOverview from './data/afvalfonds/afvalfonds.tsx'
// Attachment categories
import AttachmentList from './data/attachment_categories/list'
import AttachmentEdit from './data/attachment_categories/edit'
import AttachmentCreate from './data/attachment_categories/edit'
// Brandholders module
import BrandHoldersList from './data/brand_holders/list'
import BrandHoldersUpsert from './data/brand_holders/edit'
// Brands module
import BrandsList from './data/brands/list'
import BrandsUpsert from './data/brands/edit'
// CBS module
import CBS from './data/cbs/cbs_export'
// Commenttemplates module
import CommentTemplatesList from './data/commenttemplates/commenttemplate_list'
import CommentTemplatesUpsert from './data/commenttemplates/commenttemplate_edit'
// Email-placeholders module
import EmailPlaceholdersList from './data/email_placeholders/email_placeholder_list.ls'
import EmailPlaceholdersUpsert from './data/email_placeholders/email_placeholder_edit.ls'
// Excise-categories module
import ExciseCategoriesList from './data/excise_categories/excise_category_list.ls'
import ExciseCategoriesUpsert from './data/excise_categories/excise_category_edit.ls'
// Insurance types module
import InsuranceTypesList from './data/insurancetypes/insurancetype_list.ls'
import InsuranceTypesUpsert from './data/insurancetypes/insurancetype_edit.ls'
// Item-tags module
import ItemTagList from './data/item_tags/list'
import ItemTagEdit from './data/item_tags/edit'
// Ledgers module
import LedgersList from './data/ledgers/ledger_list.ls'
import LedgersUpsert from './data/ledgers/ledger_edit.ls'
// Loendersloot module
import LoenderslootMessages from './email/loendersloot_messages'
import LoenderslootTags from './data/loendersloot_tags/loendersloot_tag_list'
import LoenderslootTagsUpsert from './data/loendersloot_tags/loendersloot_tag_edit'
// Margin-tresholds module
import MarginThresholdsList from './data/margin_threshold/list'
import MarginThresholdsEdit from './data/margin_threshold/edit'
// Paymentterm module
import PaymentTermList from './data/paymentterms/paymentterm_list.ls'
import PaymentTermUpsert from './data/paymentterms/paymentterm_edit.ls'
// Product categories module
import ProductCategoriesList from './data/product_categories/product_category_list.ls'
import ProductCategoriesUpsert from './data/product_categories/product_category_edit.ls'
import ProductsList from './data/products/list'
import ProductsEdit from './data/products/product_edit.ls'
import ProductsBlacklisted from './data/products/blacklisted_product_list.ls'
import ProductsMerge from './data/products/product_merge.ls'
// Discover Settings module
import SystemSettings from './data/system_parameters/system_parameters'
// Users module
import UsersList from './data/user_manage/list'
import UsersUpsert from './data/user_manage/edit'
// Vatcheck module
import VatCheck from './data/vatcheck/vatcheck.ls'
// Vatrates module
import VatRatesList from './data/vatrates/list'
import VatRatesUpsert from './data/vatrates/edit.ls'
// Vattypes module
import VatTypesList from './data/vattypes/vattype_list'
import VatTypesUpsert from './data/vattypes/vattype_edit'
// MyDiscover module
import MyDiscoverDashboard from './my_discover/dashboard/dashboard'
import MyDiscoverProfile from './my_discover/profile'
import MyDiscoverDownloads from './my_discover/downloads'
import MyDiscoverColleagues from './my_discover/colleagues/colleagues'
// Market module
import MarketUploadPricelist from './market/upload_pricelist.ls'
import MarketBestBuys from './market/bestbuys.ls'
import MarketExplore from './market/explore.ls'
import {MarketDiscover} from './market/discover'
import {MarketListPricelists} from './market/list_pricelists'
import {MarketListPricelistItems} from './market/list_pricelist_items'
import {MarketStatistics} from './market/statistics'
// Offer module
import OfferList from './offer/offers/offer_list.ls'
import OfferCustomProducts from './offer/offers/offer_custom_products.ls'
import OfferUpsert from './offer/offers/offer_details.ls'
import OfferRelations from './offer/offers/offer_relations.ls'
import OfferSelectProducts from './offer/offers/offer_select_products.ls'
import OfferRelationDashboard from './offer/relation_dashboard/relation_dashboard'
import {VoucherList} from './vouchers/voucher_list'
import {VoucherUpsert} from './vouchers/voucher_upsert'
import {VoucherRelationSelection} from './vouchers/voucher_relation_selection'
// Portal module
import PortalNewsList from './portal/portal_news/portal_news_list.ls'
import PortalNewsUpsert from './portal/portal_news/portal_news_edit.ls'
import PortalQuotesList from './portal/portal_quotes/portal_quotes_list.ls'
import PortalQuotesUpsert from './portal/portal_quotes/portal_quotes_edit.ls'
import PortalTipsList from './portal/portal_tips/portal_tips_list.ls'
import PortalTipsUpsert from './portal/portal_tips/portal_tips_edit.ls'
import PortalUsersList from './portal/portal_users/portal_user_list.ls'
import PortalUsersUpsert from './portal/portal_users/portal_user_edit.ls'
import PortalUsersActivity from './portal/portal_users/activity.ls'
// Pricelist module
import PricelistList from './pricelist/pricelist.ls'
import PricelistApproval from './pricelist/approval'
import {PricelistOfferitemList} from './pricelist/offer_item_list'
import PricelistProductsOfInterest from './pricelist/products_of_interest/list'
import PricelistSilentStock from './pricelist/silent_stock.ls'
import PricelistSpotlight from './pricelist/spotlight.ls'
import PricelistWork from './pricelist/tbo_to_approve_list'
// PurchaseOrders module
import PurchaseOrdersInstockLoendersloot from './purchase_orders/instock_loendersloot'
import PurchaseOrdersItemsList from './purchase_orders/items/list.ls'
import PurchaseOrdersManageList from './purchase_orders/list'
import PurchaseOrdersManageArrivalNotice from './purchase_orders/arrival_notice'
import PurchaseOrdersManageCancel from './purchase_orders/cancel.ls'
import PurchaseOrdersManageConfirm from './purchase_orders/confirm.ls'
import PurchaseOrdersManageFinancials from './purchase_orders/financials.ls'
import PurchaseOrdersManageInStock from './purchase_orders/instock'
import PurchaseOrdersManageRFP from './purchase_orders/request_for_proposal'
import CreateOrEditPurchaseOrder from './purchase_orders/edit'
import PurchaseOrdersDetail from './purchase_orders/view_purchase_order'
import PurchaseOrdersTBO from './purchase_orders/tbo/tbo.ls'
import PurchaseOrdersUpIsUp from './purchase_orders/up_is_up'
// SalesOrders module
import SalesOrdersList from './sales_orders/list'
import SalesOrdersAssignedStock from './sales_orders/assigned_stock.ls'
import SalesOrdersGreenShipment from './sales_orders/create_green_shipment.ls'
import SalesOrdersUpsert from './sales_orders/edit'
import SalesOrdersInstock from './sales_orders/instock.ls'
import SalesOrdersInvoice from './sales_orders/invoice'
import SalesOrdersMoveItems from './sales_orders/move_sales_order_items'
import SalesOrdersOuttakeInstruction from './sales_orders/outtake_instruction'
import SalesOrdersProforma from './sales_orders/proforma'
import SalesOrdersExact from './sales_orders/exact'
import SalesOrdersDetails from './sales_orders/view'
import SalesOrdersLowMarginApproval from './sales_orders/low_margin_approval'
import SalesOrdersRevenue from './sales_orders/revenue.ls'
import SalesOrdersTargets from './sales_orders/targets'
// Stock module
import StockMoveToWarehouse from './stock/move_to_warehouse.ls'
import StockImageReview from './stock/image_review_list'
import StockList from './stock/stock_list.ls'
import StockItemDetails from './stock/item_view.ls'
import StockItemEdit from './stock/item_edit'
import StockItemCasesUpdate from './stock/mutation_create_update_cases.ls'
import StockItemProductUpsert from './stock/mutation_create_update_product.ls'
import StockMutationList from './stock/mutation_list'
import StockMutationDetails from './stock/mutation_view'
import StockMutationEdit from './stock/move_to_warehouse.ls'
import StockMutationInStock from './stock/mutation_instock.ls'
import StockMutationOuttakeInstruction from './stock/outtake_instruction'
import StockRecon from './stock/loendersloot_stock_recon'
import StockReconDetails from './stock/loendersloot_stock_recon_results'

import ComparePriceListPrices from '@/pricelist/compare_prices'

const without_auth = make_route(require_no_auth, LayoutLanding)
const with_auth = make_route(require_auth, LayoutDiscover)

export const DEFAULT_ROUTE = '/dashboard/figures'

// Object keys are used in order; first match wins.
export const routes = {
    '/login': without_auth(Login),
    '/user/forgot-password': without_auth(AuthForgotPassword),
    '/user/reset-password': without_auth(AuthResetPassword),

    '/dashboard/figures': with_auth(DashboardFigures),
    '/dashboard/portal': with_auth(DashboardPortal),
    '/dashboard/search-log/': with_auth(DashboardSearchlog),
    '/dashboard/diana': with_auth(DashboardDiana),
    '/dashboard/reports': with_auth(DashboardReports),

    '/data/afvalfonds': with_auth(AfvalfondsOverview),
    '/data/attachment-categories': with_auth(AttachmentList),
    '/data/attachment-categories/:artkey/edit': with_auth(AttachmentEdit),
    '/data/attachment-categories/create': with_auth(AttachmentCreate),
    '/data/brand-holders': with_auth(BrandHoldersList),
    '/data/brand-holders/:artkey/edit': with_auth(BrandHoldersUpsert),
    '/data/brand-holders/create': with_auth(BrandHoldersUpsert),
    '/data/brands': with_auth(BrandsList),
    '/data/brands/:artkey/edit': with_auth(BrandsUpsert),
    '/data/brands/create': with_auth(BrandsUpsert),
    '/data/cbs': with_auth(CBS),
    '/data/commenttemplates': with_auth(CommentTemplatesList),
    '/data/commenttemplates/:artkey/edit': with_auth(CommentTemplatesUpsert),
    '/data/commenttemplates/create':  with_auth(CommentTemplatesUpsert),
    '/data/email-placeholders': with_auth(EmailPlaceholdersList),
    '/data/email-placeholders/:artkey/edit': with_auth(EmailPlaceholdersUpsert),
    '/data/email-placeholders/create': with_auth(EmailPlaceholdersUpsert),
    '/data/excise-categories': with_auth(ExciseCategoriesList),
    '/data/excise-categories/:artkey/edit': with_auth(ExciseCategoriesUpsert),
    '/data/excise-categories/create': with_auth(ExciseCategoriesUpsert),
    '/data/insurancetypes': with_auth(InsuranceTypesList),
    '/data/insurancetypes/:artkey/edit': with_auth(InsuranceTypesUpsert),
    '/data/insurancetypes/create': with_auth(InsuranceTypesUpsert),
    '/data/item-tags': with_auth(ItemTagList),
    '/data/item-tags/:artkey/edit': with_auth(ItemTagEdit),
    '/data/item-tags/create': with_auth(ItemTagEdit),
    '/data/loendersloot-tags': with_auth(LoenderslootTags),
    '/data/loendersloot-tags/:artkey/edit': with_auth(LoenderslootTagsUpsert),
    '/data/loendersloot-tags/create': with_auth(LoenderslootTagsUpsert),

    '/data/ledgers': with_auth(LedgersList),
    '/data/ledgers/:artkey/edit': with_auth(LedgersUpsert),
    '/data/ledgers/create': with_auth(LedgersUpsert),
    '/data/loendersloot-messages': with_auth(LoenderslootMessages),
    '/data/margin-thresholds': with_auth(MarginThresholdsList),
    '/data/margin-thresholds/edit': with_auth(MarginThresholdsEdit),
    '/data/paymentterms': with_auth(PaymentTermList),
    '/data/paymentterms/:artkey/edit': with_auth(PaymentTermUpsert),
    '/data/paymentterms/create': with_auth(PaymentTermUpsert),
    '/data/product-categories': with_auth(ProductCategoriesList),
    '/data/product-categories/:artkey/edit': with_auth(ProductCategoriesUpsert),
    '/data/product-categories/create': with_auth(ProductCategoriesUpsert),
    '/data/products': with_auth(ProductsList),
    '/data/products/:artkey/merged': with_auth(ProductsEdit),
    '/data/products/blacklisted': with_auth(ProductsBlacklisted),
    '/data/products/merge': with_auth(ProductsMerge),
    '/data/products/:artkey': with_auth(ProductsEdit),
    '/data/system-parameters': with_auth(SystemSettings),
    '/data': redirect_to('/data/products'),

    '/crm/email/:artkey/edit': with_auth(EmailUpsert),
    '/crm/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/crm/email/create': with_auth(EmailUpsert),
    '/crm/email/:artkey': with_auth(CrmEmailBatch),
    '/crm/email': with_auth(CrmEmailBatchList),
    '/crm/leads': with_auth(CrmLeadsList),
    '/crm/leads/:artkey/edit': with_auth(CrmLeadsEdit),
    '/crm/leads/:artkey': with_auth(CrmLeadsView),
    '/crm/uploads/upload': with_auth(CrmUploadsUpload),
    '/crm/relations': with_auth(CrmRelationsList),
    '/crm/relations/:artkey/deactivate': with_auth(CrmRelationsDeactivate),
    '/crm/relations/:artkey/edit': with_auth(CrmRelationsUpsert),
    '/crm/relations/:supplier_artkey/contact-persons/:artkey/edit': with_auth(CrmRelationsContactPersonUpsert),
    '/crm/relations/:supplier_artkey/contact-persons/create': with_auth(CrmRelationsContactPersonUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/:artkey/deactivate': with_auth(WarehousesDeactivate),
    '/crm/relations/:supplier_artkey/edit/warehouses/:artkey/edit': with_auth(WarehousesUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/create': with_auth(WarehousesUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/select': with_auth(CrmRelationsWarehousesSelect),
    '/crm/relations/create': with_auth(CrmRelationsUpsert),
    '/crm/relations/create/:lead_artkey': with_auth(CrmRelationsUpsert),
    '/crm': redirect_to('/crm/relations'),

    '/data/user-manage': with_auth(UsersList),
    '/data/user-manage/:artkey/edit': with_auth(UsersUpsert),
    '/data/user-manage/create': with_auth(UsersUpsert),

    '/data/vatcheck': with_auth(VatCheck),
    '/data/vatrates': with_auth(VatRatesList),
    '/data/vatrates/:artkey/edit': with_auth(VatRatesUpsert),
    '/data/vatrates/create': with_auth(VatRatesUpsert),

    '/data/vattypes': with_auth(VatTypesList),
    '/data/vattypes/:artkey/edit': with_auth(VatTypesUpsert),
    '/data/vattypes/create': with_auth(VatTypesUpsert),

    '/data/warehouses': with_auth(WarehousesList),
    '/data/warehouses/:artkey/deactivate': with_auth(WarehousesDeactivate),
    '/data/warehouses/:artkey/edit': with_auth(WarehousesUpsert),
    '/data/warehouses/create': with_auth(WarehousesUpsert),

    '/my-discover/dashboard': with_auth(MyDiscoverDashboard),
    '/my-discover/profile': with_auth(MyDiscoverProfile),
    '/my-discover/downloads': with_auth(MyDiscoverDownloads),
    '/my-discover/colleagues': with_auth(MyDiscoverColleagues),

    '/market/best-buys': with_auth(MarketBestBuys),
    '/market/discover': with_auth(MarketDiscover),
    '/market/explore': with_auth(MarketExplore),
    '/market/pricelists': with_auth(MarketListPricelists),
    '/market/pricelists/:artkey/edit': with_auth(MarketListPricelists),
    '/market/pricelists/create': with_auth(MarketListPricelists),
    '/market/pricelists/upload': with_auth(MarketUploadPricelist),
    '/market/pricelists/:artkey': with_auth(MarketListPricelistItems),
    '/market/statistics': with_auth(MarketStatistics),
    '/market': redirect_to('/market/discover'),

    '/offer/email/create': with_auth(EmailUpsert),
    '/offer/email/:artkey/edit': with_auth(EmailUpsert),
    '/offer/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/offer/offers': with_auth(OfferList),
    '/offer/offers/:artkey/custom_products': with_auth(OfferCustomProducts),
    '/offer/offers/:artkey/details': with_auth(OfferUpsert),
    '/offer/offers/:artkey/relations': with_auth(OfferRelations),
    '/offer/offers/:artkey/select_products': with_auth(OfferSelectProducts),
    '/offer/offers/:offer/email/:artkey/edit': with_auth(EmailUpsert),
    '/offer/offers/:offer/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/offer/offers/:offer/email/:artkey/email-send': with_auth(CrmEmailBatch),
    '/offer/offers/:offer/email/create': with_auth(EmailUpsert),

    '/offer/offers/create': with_auth(OfferUpsert),
    '/offer/relation-dashboard': with_auth(OfferRelationDashboard),
    '/offer/vouchers': with_auth(VoucherList),
    '/offer/vouchers/create': with_auth(VoucherUpsert),
    '/offer/vouchers/:artkey/edit': with_auth(VoucherUpsert),
    '/offer/vouchers/:artkey/relation-selection': with_auth(VoucherRelationSelection),
    '/offer': redirect_to('/offer/relation-dashboard'),

    '/portal/portal-demo-users': with_auth(PortalDemoUsers),
    '/portal/portal-news': with_auth(PortalNewsList),
    '/portal/portal-news/:artkey/edit': with_auth(PortalNewsUpsert),
    '/portal/portal-news/create': with_auth(PortalNewsUpsert),
    '/portal/portal-quotes': with_auth(PortalQuotesList),
    '/portal/portal-quotes/:artkey/edit': with_auth(PortalQuotesUpsert),
    '/portal/portal-quotes/create': with_auth(PortalQuotesUpsert),
    '/portal/portal-tips': with_auth(PortalTipsList),
    '/portal/portal-tips/:artkey/edit': with_auth(PortalTipsUpsert),
    '/portal/portal-tips/create': with_auth(PortalTipsUpsert),
    '/portal/portal-users': with_auth(PortalUsersList),
    '/portal/portal-users/:artkey/activity': with_auth(PortalUsersActivity),
    '/portal/portal-users/:artkey/edit':with_auth(PortalUsersUpsert),
    '/portal/portal-users/create': with_auth(PortalUsersUpsert),
    '/portal/portal-users/create/:contact_person_artkey': with_auth(PortalUsersUpsert),
    '/portal': redirect_to('/portal/portal-users'),

    '/pricelist': with_auth(PricelistList),
    '/pricelist/approval': with_auth(PricelistApproval),
    '/pricelist/offer-item-list': with_auth(PricelistOfferitemList),
    '/pricelist/products-of-interest': with_auth(PricelistProductsOfInterest),
    '/pricelist/silent-stock': with_auth(PricelistSilentStock),
    '/pricelist/spotlight': with_auth(PricelistSpotlight),
    '/pricelist/work': with_auth(PricelistWork),
    '/pricelist/compare-prices': with_auth(ComparePriceListPrices),

    '/purchase-orders/manage/:artkey/instock_loendersloot': with_auth(PurchaseOrdersInstockLoendersloot),
    '/purchase-orders/items/list': with_auth(PurchaseOrdersItemsList),
    '/purchase-orders/manage': with_auth(PurchaseOrdersManageList),
    '/purchase-orders/manage/:artkey/arrival-notice': with_auth(PurchaseOrdersManageArrivalNotice),
    '/purchase-orders/manage/:artkey/cancel': with_auth(PurchaseOrdersManageCancel),
    '/purchase-orders/manage/:artkey/confirm': with_auth(PurchaseOrdersManageConfirm),
    '/purchase-orders/manage/:artkey/edit': with_auth(CreateOrEditPurchaseOrder),
    '/purchase-orders/manage/:artkey/financials': with_auth(PurchaseOrdersManageFinancials),
    '/purchase-orders/manage/:artkey/instock': with_auth(PurchaseOrdersManageInStock),
    '/purchase-orders/manage/:artkey/request-for-proposal': with_auth(PurchaseOrdersManageRFP),
    '/purchase-orders/manage/create': with_auth(CreateOrEditPurchaseOrder),
    '/purchase-orders/manage/:artkey': with_auth(PurchaseOrdersDetail),
    '/purchase-orders/tbo': with_auth(PurchaseOrdersTBO),
    '/purchase-orders/up-is-up': with_auth(PurchaseOrdersUpIsUp),
    '/purchase-orders': redirect_to('/purchase-orders/manage'),

    '/sales-orders/assigned-stock': with_auth(SalesOrdersAssignedStock),
    '/sales-orders/manage': with_auth(SalesOrdersList),

    '/sales-orders/manage/:artkey/create-green-shipment': with_auth(SalesOrdersGreenShipment),
    '/sales-orders/manage/:artkey/edit': with_auth(SalesOrdersUpsert),
    '/sales-orders/manage/:artkey/instock': with_auth(SalesOrdersInstock),
    '/sales-orders/manage/:artkey/invoice': with_auth(SalesOrdersInvoice),
    '/sales-orders/manage/:artkey/move-items': with_auth(SalesOrdersMoveItems),
    '/sales-orders/manage/:artkey/outtake-instruction': with_auth(SalesOrdersOuttakeInstruction),
    '/sales-orders/manage/:artkey/proforma': with_auth(SalesOrdersProforma),
    '/sales-orders/manage/create': with_auth(SalesOrdersUpsert),
    '/sales-orders/manage/exact': with_auth(SalesOrdersExact),
    '/sales-orders/manage/:artkey': with_auth(SalesOrdersDetails),
    '/sales-orders/margin-approval': with_auth(SalesOrdersLowMarginApproval),
    '/sales-orders/revenue': with_auth(SalesOrdersRevenue),
    '/sales-orders/targets': with_auth(SalesOrdersTargets),
    '/sales-orders': redirect_to('/sales-orders/manage'),

    '/stock/mutations/move-to-warehouse': with_auth(StockMoveToWarehouse),
    '/stock/image-review': with_auth(StockImageReview),
    '/stock/manage': with_auth(StockList),
    '/stock/manage/:reference': with_auth(StockItemDetails),
    '/stock/manage/:artkey/edit': with_auth(StockItemEdit),
    '/stock/manage/:reference/update-cases': with_auth(StockItemCasesUpdate),
    '/stock/manage/:reference/update-product': with_auth(StockItemProductUpsert),
    '/stock/manage/:reference/update-product/:update_type': with_auth(StockItemProductUpsert),
    '/stock/mutations/manage': with_auth(StockMutationList),
    '/stock/mutations/manage/:reference': with_auth(StockMutationDetails),
    '/stock/mutations/manage/:reference/edit': with_auth(StockMutationEdit),
    '/stock/mutations/manage/:reference/instock': with_auth(StockMutationInStock),
    '/stock/mutations/manage/:reference/outtake-instruction': with_auth(StockMutationOuttakeInstruction),
    '/stock/recon': with_auth(StockRecon),
    '/stock/recon/:artkey': with_auth(StockReconDetails),
    '/stock': redirect_to('/stock/manage'),

    '/user/exact_auth/:from_view...': with_auth(UserExactAuth),
}
