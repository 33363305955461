{
    "attachments": {
        "actions": {
            "category_cancel": "Annuleren",
            "category_confirm": "Categorie bevestigen",
            "download_all": "Alles downloaden"
        },
        "tips": {
            "delete_attachment": "Verwijdert bijlage zonder bevestiging(!)",
            "edit_category": "Wijzig categorie en beschrijving van deze bijlage"
        }
    },
    "bar_categories": {
        "title": "Categorie Opties"
    },
    "crm": {
        "text": "CRM",
        "tip": "Relatiebeheer"
    },
    "crm_email": {
        "text": "E-mail",
        "tip": "E-mails samenstellen en versturen naar groepen klanten"
    },
    "crm_leads": {
        "text": "Leads",
        "tip": "Warme leads komen van registratieverzoeken via het portaal; koude leads worden geüpload vanuit excel"
    },
    "crm_relations": {
        "text": "Relaties",
        "tip": "Relatiebeheer"
    },
    "crm_uploads": {
        "text": "Leads importeren",
        "tip": "Koude leads importeren uit Excel"
    },
    "dashboard": {
        "text": "Analytics",
        "tip": "Business Intelligence voor management"
    },
    "dashboard_diana": {
        "text": "DIANA",
        "tip": "Discover Analytics"
    },
    "dashboard_figures": {
        "text": "Cijfers Dashboard",
        "tip": "Generieke managementindicatoren"
    },
    "dashboard_mine": {
        "text": "Analytics",
        "tip": "Business Intelligence voor management"
    },
    "dashboard_portal": {
        "text": "Portaal Analytics",
        "tip": "Bezoekers & algemene bestelstatistieken van het portaal"
    },
    "dashboard_reports": {
        "text": "Rapporten",
        "tip": "Verschillende Business Intelligence-rapporten"
    },
    "dashboard_search_log": {
        "text": "Zoekopdrachten",
        "tip": "Zien naar welke producten mensen zoeken in de portal"
    },
    "data": {
        "text": "Configuratie",
        "tip": "Beheer Discover"
    },
    "data_afvalfonds": {
        "text": "Afvalfonds"
    },
    "data_attachment_categories": {
        "text": "Bijlage Categorieën"
    },
    "data_brand_holders": {
        "text": "Merkhouders"
    },
    "data_brands": {
        "text": "Merken"
    },
    "data_cbs": {
        "text": "CBS Export"
    },
    "data_commenttemplates": {
        "text": "Sjablonen voor opmerkingen",
        "tip": "Sjablonen om te gebruiken in opmerkingen"
    },
    "data_email_placeholders": {
        "text": "E-mail Sjablonen"
    },
    "data_excise_categories": {
        "text": "Accijns"
    },
    "data_insurancetypes": {
        "text": "Verzekeringen"
    },
    "data_item_tags": {
        "text": "Product tags"
    },
    "data_ledgers": {
        "text": "Grootboeken"
    },
    "data_loendersloot_messages": {
        "text": "Loendersloot Berichten"
    },
    "data_loendersloot_tags": {
        "text": "Loendersloot Tags"
    },
    "data_logistics": {},
    "data_margin_thresholds": {
        "text": "Margedrempels"
    },
    "data_paymentterms": {
        "text": "Betalingsvoorwaarden"
    },
    "data_product_categories": {
        "text": "Product Categorieën"
    },
    "data_products": {
        "text": "Producten",
        "tip": "Producten beheren"
    },
    "data_system_parameters": {
        "text": "Parameters"
    },
    "data_user_manage": {
        "text": "Gebruikers"
    },
    "data_vatcheck": {
        "text": "BTW-check"
    },
    "data_vatrates": {
        "text": "BTW-tarieven"
    },
    "data_vattypes": {
        "text": "BTW-soorten"
    },
    "data_warehouses": {
        "text": "Magazijnen",
        "tip": "Bestemmingen voor producten"
    },
    "finance": {
        "text": "Financiën",
        "tip": "Accijnzen, betalingstermijnen, grootboeken, btw en meer beheren"
    },
    "logistics": {
        "text": "Logistiek",
        "tip": "Transport, verzekering, magazijn en meer"
    },
    "market": {
        "text": "Markt",
        "tip": "Vind de beste en goedkoopste producten op de markt"
    },
    "market_best_buys": {
        "text": "Beste aankopen",
        "tip": "Een selectie van best presterende producten uit de markt"
    },
    "market_discover": {
        "text": "Producten",
        "tip": "Zoek producten van leveranciers"
    },
    "market_explore": {
        "text": "Product Geschiedenis",
        "tip": "Historische aankoop-, verkoop-, voorraad- & prijslijstinformatie over hoe een product in het verleden heeft gepresteerd"
    },
    "market_pricelists": {
        "text": "Prijslijsten",
        "tip": "Prijslijsten van leveranciers maken & uploaden"
    },
    "market_resolve": {
        "text": "Oplosser",
        "tip": "Een lijst met producten die niet automatisch konden worden verwerkt uit prijslijsten"
    },
    "market_statistics": {
        "text": "Marktstatistieken",
        "tip": "Inkoop- en verkoopwaarde tussen relatie/product en relatie/trader"
    },
    "misc": {
        "text": "Diverse",
        "tip": "Diverse instellingen"
    },
    "mutations": {
        "text": "Mutaties"
    },
    "my_discover": {
        "text": "Mijn Discover",
        "tip": "Je persoonlijke Discover omgeving"
    },
    "my_discover_colleagues": {
        "basic": "Vandaag",
        "detailed": "Periode",
        "text": "Collega's",
        "tip": "Toon het werkrooster van je collega's"
    },
    "my_discover_dashboard": {
        "text": "Postvak IN",
        "tip": "Meldingen & berichten"
    },
    "my_discover_downloads": {
        "text": "Downloads",
        "tip": "Downloads die je eerder hebt gestart kun je hier ophalen"
    },
    "my_discover_profile": {
        "text": "Mijn profiel",
        "tip": "Je voorkeuren wijzigen"
    },
    "notifications": {
        "poi_removed": "Inkooporderitem {{name}} is verwijderd",
        "sick_updated": "Gebruikersstatus bijgewerkt naar ziek",
        "spli_unresolved": "Verplaatst prijslijstitem {{name}} terug naar onopgelost"
    },
    "offer": {
        "text": "Aanbiedingen",
        "tip": "Kortingen en vouchers voor klanten beheren"
    },
    "offer_offers": {
        "text": "Aanbiedingen beheren",
        "tip": "Aangepaste prijzen en productlijsten voor klanten"
    },
    "offer_relation_dashboard": {
        "text": "Relatie Dashboard",
        "tip": "Bekijk aanbiedingsgegevens voor een relatie"
    },
    "offer_voucher_promotions": {
        "text": "Vouchers",
        "tip": "Creëer speciale kortingen voor (groepen) klanten"
    },
    "panel": {
        "title": {
            "spli_add_to_offer": "Toevoegen aan Aangepast aanbod",
            "spli_add_to_order": "Toevoegen aan inkooporder",
            "spli_create": "Nieuw Prijslijst-item",
            "spli_data_card": "Prijslijst Datakaart - {{name}}",
            "spli_resolve": "Oplossen - {{name}}",
            "spli_update": "Prijslijst item bijwerken - {{name}}"
        }
    },
    "panel_menu": {
        "crm_relations": {
            "text": "CRM",
            "tip": "Relatiebeheer"
        },
        "dashboard_mine": {
            "text": "Analytics",
            "tip": "Business Intelligence voor management"
        },
        "data_products": {
            "text": "Producten",
            "tip": "Producten beheren"
        },
        "data_user_manage": {
            "text": "Instellingen"
        },
        "data_warehouses": {
            "text": "Magazijnen",
            "tip": "Bestemmingen voor producten"
        },
        "market_discover": {
            "text": "Markt",
            "tip": "Verken de markt"
        },
        "my_discover_dashboard": {
            "text": "Mijn Discover",
            "tip": "Je persoonlijke Discover omgeving"
        },
        "offer_offers": {
            "text": "Aanbiedingen",
            "tip": "Aangepaste prijzen en productlijsten voor klanten"
        },
        "portal_portal_users": {
            "text": "Portaal",
            "tip": "Verkoopportaal voor klanten"
        },
        "pricelist_offer_item_list": {
            "text": "Prijzen",
            "tip": "Bepaal de basisproductkosten voor klanten"
        },
        "purchase_orders_manage": {
            "text": "Inkoop",
            "tip": "Goederen inkopen bij leveranciers"
        },
        "sales_orders_manage": {
            "text": "Verkooporders",
            "tip": "Verkooporders van klanten"
        },
        "stock_manage": {
            "text": "Voorraad",
            "tip": "Voorraad en inventaris beheren"
        },
        "tips": {}
    },
    "pdf": {
        "actions": {
            "download": "Download",
            "refresh": "Vernieuw"
        }
    },
    "poi": {
        "remove": {
            "button": "POI verwijderen",
            "dialog": {
                "confirm": "Weet je zeker dat je inkooporderartikel \"{{product_name}} ({{volume}}cl / {{alcohol_percentage}}%)\" wilt verwijderen?",
                "soi_confirm": "Als je het inkooporderitem verwijdert, worden de gerelateerde verkooporderitems geconverteerd naar TBO.",
                "soi_list_one": "Naar dit inkooporderitem wordt verwezen in {{count}} verkooporder:",
                "soi_list_other": "Naar dit inkooporderitem wordt verwezen in {{count}} verkooporders:"
            }
        }
    },
    "portal": {
        "text": "Portaal",
        "tip": "Instellingen voor het klantenportaal"
    },
    "portal_portal_demo_users": {
        "text": "Demo-gebruikers",
        "tip": "Een manier om klanten in te laten loggen op ons verkoopportaal om veilig rond te kijken, zonder dat ze de optie hebben om iets te bestellen"
    },
    "portal_portal_news": {
        "text": "Nieuws",
        "tip": "Nieuwsberichten in de portal bekijken (ongebruikt)"
    },
    "portal_portal_tips": {
        "text": "Tips",
        "tip": "Deze tips worden willekeurig getoond aan klanten n het dashboard van de portal"
    },
    "portal_portal_users": {
        "text": "Gebruikers",
        "tip": "Relatie contactpersonen beheren met een portal account"
    },
    "pricelist": {
        "text": "Verkoopprijzen",
        "tip": "Verkoopprijzen"
    },
    "pricelist_approval": {
        "text": "Goedkeuring",
        "tip": "Producten en hun prijzen uit prijslijsten van leveranciers goedkeuren"
    },
    "pricelist_approval_group": {
        "text": "Goedkeuring",
        "tip": "Producten en hun prijzen uit prijslijsten van leveranciers goedkeuren"
    },
    "pricelist_compare_prices": {
        "text": "Vergelijk",
        "tip": "Vergelijk onze prijzen met de markt"
    },
    "pricelist_offer_item_list": {
        "text": "Verkoopprijzen",
        "tip": "Bepaal de basisproductkosten voor klanten"
    },
    "pricelist_products_of_interest": {
        "text": "Interessante producten",
        "tip": "Maak aanbiedingen voor relaties met producten die hen zouden kunnen interesseren"
    },
    "pricelist_silent_stock": {
        "text": "Goedkeuring - Ongebruikte voorraad",
        "tip": "Producten die niet goed verkocht worden"
    },
    "pricelist_spotlight": {
        "text": "Spotlight",
        "tip": "Speciale aanbieding die wordt getoond op het dashboard van het verkoopportaal"
    },
    "pricelist_work": {
        "text": "Goedkeuring - TBO",
        "tip": "Producten en hun prijzen uit prijslijsten van leveranciers goedkeuren"
    },
    "processes": {
        "manage_offer": {
            "create_email": "E-mailbericht samenstellen",
            "create_offer": "Nieuwe aanbieding maken",
            "custom_products": "Producten aanpassen",
            "edit_email": "E-mailbericht bewerken",
            "edit_offer": "Bewerk aanbod",
            "email_recipients": "Ontvangers selecteren",
            "email_send": "Mail klanten",
            "list": "Aanbiedingen beheren",
            "relations": "Selecteer relaties",
            "select_products": "Selecteer producten"
        },
        "new_offer": {}
    },
    "products": {
        "text": "Producten",
        "tip": "Productgerelateerde instellingen"
    },
    "profile": {
        "actions": {
            "call_sick": "Ziek melden",
            "cancel": "Annuleren",
            "exact_login": "Exacte aanmelding",
            "exact_logout": "Exact afmelden",
            "reset_otp": "OTP resetten"
        },
        "otp": {
            "description": "De One Time Password-code wordt gebruikt als een extra beveiligingscode bij het inloggen. Weet je zeker dat je deze extra beveiligingscode opnieuw wilt instellen? Je kunt een nieuwe OTP-code installeren in de authenticator wanneer je de volgende keer inlogt."
        },
        "sick": {
            "description": {
                "other": "Als je een collega ziek meldt, blijft de status van de collega elke dag \"ziek\" totdat hij of zij deze handmatig bijwerkt.",
                "self": "Als je je ziek meldt, blijft je status elke dag \"ziek\" totdat je hem handmatig bijwerkt."
            }
        },
        "tabs": {
            "details": "Details",
            "schedule": "Schema",
            "security": "Beveiliging"
        }
    },
    "purchase_orders": {
        "text": "Inkoop",
        "tip": "Inkooporders van leveranciers & producten die besteld moeten worden"
    },
    "purchase_orders_items_list": {
        "text": "Gekochte producten",
        "tip": "Een overzicht van gekochte producten"
    },
    "purchase_orders_manage": {
        "text": "Inkooporders",
        "tip": "Inkooporders van leveranciers beheren"
    },
    "purchase_orders_tbo": {
        "text": "Te bestellen",
        "tip": "Een lijst met producten die zijn aangevraagd door de verkoop, maar die nog niet op voorraad zijn. De bron van het verzoek kan afkomstig zijn van de klant in het verkoopportaal of van een trader die de verkooporder beheert."
    },
    "purchase_orders_up_is_up": {
        "text": "Bijna niet op voorraad",
        "tip": "Producten die naar verwachting binnenkort niet meer op voorraad zijn"
    },
    "sales_orders": {
        "text": "Verkoop",
        "tip": "Verkooporders, marges & doelen"
    },
    "sales_orders_assigned_stock": {
        "text": "Toegewezen voorraad",
        "tip": "Opzoeken van te bestellen artikelen per trader"
    },
    "sales_orders_manage": {
        "text": "Verkooporders",
        "tip": "Verkooporders van klanten"
    },
    "sales_orders_margin_approval": {
        "text": "Marge Goedkeuring",
        "tip": "Marges controleren en verkooporders goedkeuren"
    },
    "sales_orders_revenue": {
        "text": "Verkoopdoelstellingen",
        "tip": "Inkomsten per verkooporder bekijken"
    },
    "sales_orders_targets": {
        "text": "Doelen voor traders",
        "tip": "Jaarlijkse omzetdoelstellingen per trader bepalen"
    },
    "settings": {
        "text": "Instellingen",
        "tip": "Systeeminstellingen beheren voor Discover"
    },
    "stock": {
        "actions": {
            "bring_in_stock": "Breng artikelen op voorraad",
            "export_excel": "Exporteren naar Excel",
            "item_edit": "Item bewerken",
            "move_warehouse": "Verhuizen naar magazijn",
            "mutation_del": "Mutatie verwijderen",
            "mutation_edit": "Mutatie bewerken",
            "outtake_instructions": "Opname-instructies",
            "recalculate_availability": "Herbereken beschikbaarheid",
            "rerun_differences": "Voorraad vergelijken",
            "to_loe": "Stuur Loendersloot",
            "to_non_loe": "Mutatie naar voorraad",
            "update_bottles_per_case": "Flessen / dozen aanpassen",
            "update_cases": "Dozen aanpassen",
            "update_product": "Product wijzigen"
        },
        "text": "Voorraad",
        "tip": "Voorraad beheren",
        "tips": {
            "mutation_actions_disabled": "Alleen ingeschakeld voor mutaties die onderweg zijn, een 'Verplaats naar magazijn' mutatiereden hebben en een referentie."
        }
    },
    "stock_image_review": {
        "text": "Foto overzicht",
        "tip": "Bekijk productfoto's uit het magazijn; deze foto's worden gebruikt in ons verkoopportaal, dus let erop dat je alleen 4:3 (liggend) foto's gebruikt."
    },
    "stock_manage": {
        "text": "Voorraad",
        "tip": "Voorraad en inventaris beheren"
    },
    "stock_mutations_manage": {
        "text": "Mutaties beheren",
        "tip": "Veranderingen in de voorraad beheren"
    },
    "stock_mutations_manage_move_to_warehouse": {},
    "stock_mutations_move_to_warehouse": {
        "text": "Verhuizen naar magazijn"
    },
    "stock_recon": {
        "text": "Verschillen in magazijnen",
        "tip": "Een lijst met gerapporteerde problemen met het magazijn"
    },
    "table": {
        "fetch_more": "Meer items ophalen"
    },
    "templates": {
        "text": "Sjablonen",
        "tip": "Sjablonen beheren"
    },
    "tips": {
        "spli_unresolve": "Verplaats het prijslijstitem terug naar de onopgeloste lijst",
        "spli_unresolve_tbo": "Dit prijslijstitem heeft bijpassende TBO items"
    }
}