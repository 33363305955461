m = require 'mithril'
{filter, map} = require 'prelude-ls'
api = require 'api.ls'
{prevent-default, format-date, format-date-time} = require 'utils.ls'
{Collection} = require '@/components/collection/collection.ls'
{button-with-icon} = require '@/components/buttons.ls'
confirmation = require '@/components/confirmation.ls'
inputs = require '@/components/inputs'
{EmailTable} = require 'email/components/email_table.ls'
{ContactPerson} = require '@/models/data'
{current_account_slug, AccountSlug} = require '@bitstillery/common/account/account'
{Link} = require '@/components/discover'
app = require('@/app')

module.exports = class ContactPersonEdit
    SHOWING = do
        CONTACT_PERSON: "CONTACT_PERSON"
        HELLO_DIALOG: "HELLO_DIALOG"

    ->
        @contact_person = new ContactPerson
        @artkey = m.route.param 'artkey'

        @emails = new Collection do
            api_function_name: 'email.email.get_for_contact_person'
            additional_params: ~> {'contact_person_artkey': @artkey}
            filter_function: @is-match
            query_limit: 25
            default_sort_by: 'sent_on'
            default_sort_order: 'desc'

        @has_portal_access = window.prop false
        @enable_portal_access = window.prop false
        @active_portal_user = window.prop null

        @supplier_artkey = m.route.param 'supplier_artkey'
        @back_url = "/crm/relations/#{@supplier_artkey}/edit?tab=contacts"
        @base_url = "/crm/relations/#{@supplier_artkey}/contact-persons/"

        @hello_dialog_info = null
        @currently_showing = SHOWING.CONTACT_PERSON

        if @artkey
            @hello_dialog_fetch!

        if @artkey
            @emails.query!

            data = {artkey: @artkey}
            api.call 'contact_persons.get', data, (resp) ~>
                if resp.success then
                    @contact_person = app.$m.data.contact_person.create_contact_person resp.result
                    if @contact_person.has_portal_access!
                        @has_portal_access true
                        @enable_portal_access true
                        @active_portal_user @contact_person.portal_users!filter((portal_user) -> !portal_user.is_deleted).at(0)
                else
                    app.notifier.notify 'Unknown contact person.', 'danger'
                    m.route.set @back_url

    oncreate: ~>
        if @artkey then
            @emails.requery!

    create: ~>
        not @contact_person.artkey!

    delete: (e) ~>
        confirmation.show do
            title: 'Delete contact person'
            message: 'Are you sure you want to delete this contact person?'
            unique_name: 'contact_person_delete_confirm'
            onconfirm: ~>
                data = do
                    artkey: @contact_person.artkey!
                api.call 'contact_persons.delete', data, @handle_delete

    save: (e) ~>
        if not /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(@contact_person.emailaddress!)
            app.notifier.notify 'Email address is invalid', 'warning'
            return

        data = do
            artkey: @contact_person.artkey!
            supplier_artkey: @supplier_artkey
            first_name: @contact_person.first_name!
            family_name: @contact_person.family_name!
            telephone_number: @contact_person.telephone_number!
            mobile_telephone_number: @contact_person.mobile_telephone_number!
            emailaddress: @contact_person.emailaddress!
            birthdate: if @contact_person.birthdate! then @contact_person.birthdate!split('T')[0] else null
            position: @contact_person.position!
            salutation: @contact_person.salutation!
            language: @contact_person.language!
            portal_language: @contact_person.portal_language
            enable_portal_access: @enable_portal_access!
            should_receive_mails: @contact_person.should_receive_mails!
            should_receive_purchase_enquiries: @contact_person.should_receive_purchase_enquiries!
            add_to_hello_dialog: @contact_person.add_to_hello_dialog!

        api.call-and-then 'contact_persons.create_or_update' data, do
            success: (resp) ~>
                if @create!
                    app.notifier.notify 'Successfully created new contact person.', 'success'
                else
                    app.notifier.notify 'Successfully updated contact person.', 'success'
                m.route.set @back_url # "#{@base_url}#{result.artkey}/edit"

    handle_delete: (result) ~>
        if not result.success
            if result.message
                app.notifier.notify result.message, 'danger'
            else
                app.$m.common.generic_error_handler!
        else
            app.notifier.notify 'Successfully deleted contact person.', 'warning'
            m.route.set @back_url

    hello_dialog_fetch: ~>
        data = do
            contact_person_artkey: @artkey
        api.call-and-then 'hello_dialog.info' data, do
            success: (resp) ~>
                @hello_dialog_info = resp.result || null

    hello_dialog_sync: ~>
        data = do
            contact_person_artkey: @contact_person.artkey!
        api.call-and-then 'hello_dialog.sync_data' data, do
            success: (resp) ~>
                app.notifier.notify "Hello dialog synced", "info"
                @hello_dialog_info = null
                @hello_dialog_fetch!

    hello_dialog_remove: ~>
        data = do
            contact_person_artkey: @contact_person.artkey!
        api.call-and-then 'hello_dialog.remove' data, do
            success: (resp) ~>
                app.notifier.notify "Hello dialog removed", "info"
                @hello_dialog_info = null
                @hello_dialog_fetch!
                @currently_showing = SHOWING.CONTACT_PERSON

    view: -> m '.c-contact-edit view',
        m '.btn-toolbar',
            button-with-icon 'Back to list', 'arrow-left', do
                class: 'btn-default'
                onclick: ~> m.route.set @back_url

            if not @create!
                [
                    m 'button.btn' do
                        type: 'button'
                        onclick: ~> @hello_dialog_sync!
                    , (m 'span.glyphicon.glyphicon-refresh'), if @hello_dialog_info == null then ' Add to Hello Dialog' else ' Update Hello Dialog'
                    m 'button.btn' do
                        type: 'button'
                        disabled: @hello_dialog_info == null
                        onclick: ~> @hello_dialog_remove!
                    , (m 'span.glyphicon.glyphicon-remove'), ' Remove from Hello Dialog'
                    m 'button.btn.btn-danger.pull-right' do
                        type: 'button'
                        onclick: ~> @delete()
                    , (m 'span.glyphicon.glyphicon-trash'), ' Delete'
                ]

        if @hello_dialog_info then

            m 'ul.nav.nav-tabs',
                m 'li',
                    role: 'presentation'
                    class: if @currently_showing == SHOWING.CONTACT_PERSON then 'active' else ''
                    m m.route.Link,
                        href: m.route.get!
                        onclick: ~>
                            preventDefault @currently_showing = SHOWING.CONTACT_PERSON
                    , "Contact person"
                if @hello_dialog_info
                    m 'li',
                        role: 'presentation'
                        class: if @currently_showing == SHOWING.HELLO_DIALOG then 'active' else ''
                        m m.route.Link,
                            href: m.route.get!
                            onclick: ~>
                                preventDefault @currently_showing = SHOWING.HELLO_DIALOG
                        , m 'span',
                            m 'img',
                                class: 'hello-dialog-logo'
                                disabled: true
                                style: 'height: 20px;width: 20px'
                                src: '/img/logo-hello-dialog.svg'


        if @currently_showing == SHOWING.CONTACT_PERSON
            m 'form.flex-form' {onsubmit: prevent-default @save},
                m '.fieldset',
                    m '.field-group',
                        inputs.text @contact_person.first_name, {label: 'First name', required: true}
                        inputs.text @contact_person.family_name, {label: 'Last name'}
                    inputs.text @contact_person.telephone_number, {label: 'Phone number'}
                    inputs.text @contact_person.mobile_telephone_number, {label: 'Mobile number'}
                    inputs.text @contact_person.emailaddress, {label: 'Email address', required: true}
                    inputs.date @contact_person.birthdate, {label: 'Birthdate', max: new Date(), min: '1900-01-01'}

                    m '.field-group',
                        inputs.text @contact_person.position, {label: 'Position'}
                        inputs.text @contact_person.salutation, {label: 'Salutation'}

                    inputs.language @contact_person.language, {
                        help: 'The native language of this contactperson; not to be confused with the portal user language',
                        label: 'Native Language',
                        required: true
                    }

                m '.fieldset',
                    inputs.checkbox @contact_person.should_receive_mails, {
                        checked: @contact_person.should_receive_mails!
                        label: 'Receive commercial mailings',
                    }

                    inputs.checkbox @contact_person.should_receive_purchase_enquiries, {
                        checked: @contact_person.should_receive_purchase_enquiries!
                        label: 'Receive purchase enquiries',
                    }

                    if @create! and current_account_slug! == AccountSlug.MSP
                        inputs.checkbox @contact_person.add_to_hello_dialog, {
                            checked: @contact_person.add_to_hello_dialog!
                            label: 'Add contact to newsletter',
                        }

                    inputs.checkbox @enable_portal_access, {
                        disabled: @has_portal_access!
                        label: 'Portal Access'
                        help:
                            if @has_portal_access!
                                'Use the portal / users page to edit the portal user.'
                            else
                                'Contact person will get an email with login details for the portal after creation'
                    }
                    if @active_portal_user!
                        m Link,
                            href: "/portal/portal-users/#{@active_portal_user!artkey}/edit"
                        ,
                            "Edit the portal user"

                m 'button.btn',
                    class: 'btn-success btn-submit mb-2'
                ,
                    if @create! then
                        'Create Contact'
                    else
                        'Update Contact'


        if @currently_showing == SHOWING.HELLO_DIALOG
            if @hello_dialog_info
                m '.col-sm-6',
                    m 'img',
                        class: 'hello-dialog-logo'
                        src: '/img/logo-hello-dialog.svg'
                    m 'dl.dl-horizontal',
                        m 'dt', '#Id'
                        m 'dd', @hello_dialog_info.id
                        m 'dt', 'Email'
                        m 'dd', @hello_dialog_info.email
                        m 'dt', 'First name'
                        m 'dd', @hello_dialog_info.contact_first_name
                        m 'dt', 'Last name'
                        m 'dd', @hello_dialog_info.contact_family_name
                        m 'dt', 'Salutation'
                        m 'dd', @hello_dialog_info.contact_salutation
                        m 'dt', 'Language'
                        m 'dd', @hello_dialog_info.contact_language
                        m 'dt', 'Status'
                        m 'dd', @hello_dialog_info.supplier_status
                        m 'dt', 'Relation status'
                        m 'dd', @hello_dialog_info.client_status
                        m 'dt', 'Company type'
                        m 'dd', @hello_dialog_info.company_type
                        m 'dt', 'Relation name'
                        m 'dd', @hello_dialog_info.name
                        m 'dt', 'City'
                        m 'dd', @hello_dialog_info.city
                        m 'dt', 'Country code'
                        m 'dd', @hello_dialog_info.country_code
                        m 'dt', 'NPS'
                        m 'dd', @hello_dialog_info.nps_score

        if @artkey then
            m '.row' m '.col-sm-4' m 'h2',
                'Mailings'

            m EmailTable, do
                email_collection: @emails
