m = require 'mithril'
{filter} = require 'prelude-ls'
{a}: utils = require 'utils.ls'
api = require 'api.ls'
inputs = require '@/components/inputs'
items_table = require './components/items_table.ls'
{ItemForm} = require './components/item_form.ls'
{Button} = require '@bitstillery/common/components'
{Item, ItemMutationReason} = require '@/models/stock'
app = require('@/app')

module.exports = class MutationCreateUpdateProduct
    ->
        item_reference = m.route.param 'reference'
        @update_type = m.route.param 'update_type'

        @item = window.prop new Item
        @items = window.prop []
        @base_item_json = window.prop {}

        @new_items = window.prop []

        @reason = window.prop ''
        @description = window.prop ''

        if @update_type == 'correct-bottles'
            @reason ItemMutationReason.CORRECT_BOTTLES_PER_CASE

        @get_item(item_reference)

    create_base_item: (json) ~>
        @base_item_json json
        @base_item_json!lot = ''
        @base_item_json!number_of_cases_in_stock = ''

    get_item: (item_reference) ~>
        data = do
            item_reference: item_reference

        api.call2 'stock.get_item', data, (resp) ~>
            @item new Item resp.result
            @items [@item!]
            @new_items [prop new Item resp.result]
            @create_base_item resp.result

    create_mutation: (e) ~>
        e.preventDefault!

        if @new_items!length == 0
            app.notifier.notify 'Cannot update product, please create items in updated stock.', 'info'
            return
        empty_items = @new_items! |> filter (item) -> item!country_of_origin! == null || item!country_of_origin! == ''
        if empty_items.length > 0
            app.notifier.notify 'Cannot update product, country of origin is a required field.', 'info'
            return

        new_items = [item!toObject! for item in @new_items!]

        data = do
            user_artkey: app.$s.identity.artkey
            item_mutation_reason: @reason!
            description: @description!
            item_artkey: +@item!artkey!
            new_items: new_items

        api.call2 'stock.create_item_mutation_update_product', data, (resp) ~>
            app.$m.common.observable.broadcast 'stock_updated', ''
            app.notifier.notify 'Created mutation successfully', 'success'
            m.route.set "/stock/mutations/manage/#{resp.result}"

    # Function to determine which fields must be shown in the item forms in
    # which situation.
    fields: ~>
        if @update_type == 'correct-bottles'
            ['number_of_bottles_per_case']
        else
            [
                'customs_status',
                'gift_box_type',
                'lot',
                'number_of_bottles_per_case',
                'product',
                'stock',
            ]

    view: -> m '.c-create-mutation view' a do
        m '.btn-toolbar',
            m Button, {
                active: false,
                icon: 'back',
                onclick: ~> m.route.set "/stock/manage/#{@item!reference!}"
                variant: 'toggle'
            }

        # Depending on the update_type, show a different title.
        if @update_type == 'correct-bottles'
            m 'h2' 'Correct number of bottles per case'
        else
            m 'h2' 'Update product'

        m '.panel.panel-primary' a do
            m '.panel-heading' m '.panel-title' 'Current stock'
            m '.panel-body' a do
                items_table.items_table @items

        m 'form.flex-form full-width' {onsubmit: @create_mutation} a do
            m ItemForm, do
                new_items: @new_items
                base_item_json: @base_item_json
                fields: @fields!
                reason: @reason
                update_type: @update_type

            # Don't show the reason field if we are correction the number of
            # bottles per case; only one reason is possible then.
            if @update_type != 'correct-bottles'
                m '.field' a do
                    m 'label' {for: 'type'} 'Reason'
                    inputs.select @reason, ItemMutationReason.all, {required: true}

            m '.field' a do
                m 'label' {for: 'description'} 'Description'
                inputs.textarea @description, {required: true}

            m '.btn-toolbar',
                m Button, {
                    className: 'btn-submit',
                    onclick: @create_mutation,
                    text: 'Update Product',
                    type: 'success',
                }
