{maybe-map, join-maybes, random-uuid} = require '../../utils.ls'
{Product, TaxLabelConstants} = require '@/models/data'
{Product} = require '@/models/products'
{Case} = require '@/models/stock'
{Supplier} = require '@/models/suppliers'
app = require('@/app')


export class FilterType
    @RELATION = 'Relation'
    @PRODUCT = 'Product'
    @BOTH = 'Both'
    @VARIOUS = 'Various'


export class BottleFilters
    (json = {}) ->
        @volume = window.prop (json.volume || '')
        @alcohol_percentage = window.prop (json.alcohol_percentage || '')
        @refill_status = window.prop (json.refill_status || '')

    # Static method for loading filters from a Bottle
    @from_bottle = (bottle) ->
        new BottleFilters do
            volume: bottle.volume!
            alcohol_percentage: bottle.alcohol_percentage!
            refill_status: bottle.refill_status!

    reset: ->
        @volume ''
        @alcohol_percentage ''
        @refill_status ''

    to_json: -> do
        volume: @volume!
        alcohol_percentage: @alcohol_percentage!
        refill_status: @refill_status!

    is_empty: ->
        @volume! == '' &&
        @alcohol_percentage! == '' &&
        @refill_status! == ''

    show: ->
        join-maybes ' ', [
            @volume! |> maybe-map (+ 'cl')
            @alcohol_percentage! |> maybe-map (+ '%')
            @refill_status!
        ]

    is_full: ->
        @volume! && @alcohol_percentage! && @refill_status!

    is_half: ->
        not (@is_empty! or @is_full!)

    clone: ->
        new BottleFilters @to_json!


export class SearchFilter
    (json = {}) ->
        # Give each search filter a random id, Mithril needs it.
        @artkey = random-uuid!

        @product = window.prop new Product
        @product_name = window.prop ''
        @case = window.prop new Case
        @supplier = window.prop new Supplier
        @selected_relations = window.prop []
        @bottle = window.prop new BottleFilters
        @volume_options = window.prop []
        @alcohol_percentage_options = window.prop []
        @refill_status_options = window.prop []

    to_json: ->
        @supplier!name @selected_relations![0]?name || ''
        @supplier!artkey @selected_relations![0]?artkey
        @supplier!country_code @selected_relations![0]?country_code || ''

        supplier_artkey = @selected_relations![0]?artkey
        product_artkey: @product!artkey!
        supplier_artkey: supplier_artkey
        number_of_bottles: @case!number_of_bottles!
        gift_box_type: @case!gift_box_type!
        bottle_volume: @bottle!volume!
        bottle_alcohol_percentage: @bottle!alcohol_percentage!
        bottle_refill_status: @bottle!refill_status!
        tax_label:
            switch @case!tax_label!
            | ''        => undefined
            | TaxLabelConstants.NO_TAX_LABEL => ''
            | otherwise => that

