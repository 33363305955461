import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Spinner,
    CollectionItems,
    CollectionView,
    RowActionProcessed,
    Tabs,
    Tab,
    TabPanel,
} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {Icon} from '@bitstillery/common/components'
import {logger, notifier} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'
import {CollectionProxy} from '@bitstillery/common/lib/collection'

import {$m, $s, api, events} from '@/app'
import {UpsertSpli} from '@/market/components/upsert_spli'
import {SourcelineData} from '@/models/pricelists'
import {collection as collection_unresolved} from '@/market/components/spli_unresolved'
import {model} from '@/market/list_pricelist_items'

const columns_suggestions = [
    {
        name: 'Product',
        render: (row) => {
            return <div className="td-group">
                <span className="header">
                    {row.product_name}
                </span>
                <span className="details">
                    {to_specs({
                        bottle_alcohol_percentage: row.alcohol_percentage,
                        bottle_refill_status: row.refill_status,
                        bottle_volume: row.volume,
                        case_customs_status: row.customs_status,
                        case_gift_box_type: row.gift_box_type,
                        case_number_of_bottles: row.number_of_bottles_per_case,
                    })}

                </span>
            </div>
        },
    },
    {
        name: 'Category',
        render: (row) => {
            return row.product_category
        },
    },
]

const collection = new CollectionProxy()

export class ResolveSourceLine extends MithrilTsxComponent<any> {
    watchers = [] as any

    context_id = null as any
    data = proxy({
        tabs: {
            active: 'add_product',
        },
    })

    async context_update() {
        $s.context.loading = true
        const sourceline = $s.context.data as SourcelineData
        logger.debug(`[resolve] update product suggestions: ${sourceline.product_name}`)
        const {result} = await api.post('matcher.get_bottle_suggestions', {
            bottle_gtin_code: sourceline.bottle_gtin_code,
            product_name: sourceline.product_name,
            volume: sourceline.volume,
            alcohol_percentage: sourceline.alcohol_percentage,
            refill_status: sourceline.refill_status,
        }, false) as any

        this.context_id = $s.context.id

        collection.state.items.splice(0, collection.state.items.length, ...result)
        $s.context.loading = false
    }

    oninit() {
        this.watchers.push(watch($s.context, 'id', async() => {
            if (this.data.tabs.active === 'suggestions') {
                if (this.context_id !== $s.context.id) {
                    this.context_update()
                }
            }
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    view(_vnode:m.Vnode<any>) {
        return <div className="c-resolve-source-line">
            <Tabs
                data={this.data}
            >
                <Tab
                    icon="compare"
                    id="add_product"
                    text="From Product"
                >
                    <TabPanel className="content">
                        <UpsertSpli
                            collection={collection}
                            source="sourceline"
                        />
                    </TabPanel>
                </Tab>
                <Tab
                    icon="auto_fix"
                    id="suggestions"
                    onactivate={() => {
                        if (this.context_id !== $s.context.id) {
                            this.context_update()
                        }
                    }}
                    text="From Suggestions"
                >
                    <TabPanel>
                        <div className="suggestions content mb-1">
                            <div className="persuade">
                                {$s.context.loading ? <Spinner size="l" type="info" /> : <Icon name="info" size="l" type="info"/>}
                                {(() => {
                                    if ($s.context.loading) {
                                        return <span>
                                            We're looking for the best match for this sourceline.
                                            This may take a moment.
                                        </span>
                                    }
                                    if (collection.state.items.length) {
                                        return <span>
                                            Your expertise is needed!
                                            One of the following products may likely be
                                            the right product to resolve this sourceline
                                            with, but we aren't able to tell from the available data.
                                            Help by selecting the right product when you know it matches.
                                        </span>
                                    } else {
                                        return <span>
                                            No suggestions found for this sourceline.
                                        </span>
                                    }
                                })()}
                            </div>
                            <CollectionView inactive={$s.context.loading} mode="table">
                                <CollectionItems
                                    collection={collection}
                                    columns={columns_suggestions}
                                    row_actions={(row) => {
                                        return <RowActionProcessed
                                            row_process={async() => {
                                                const context = $s.context.data as SourcelineData
                                                // Resolve through a product suggestion
                                                context.product_name = row.product_name
                                                context.product_category = row.product_category
                                                try {
                                                    await $m.pricelists.resolve_sourceline(context, model)
                                                    collection_unresolved.select_next(context.artkey)
                                                    events.emit('spl:refetch')
                                                } catch (err) {
                                                    notifier.notify(`Failed to resolve sourceline: ${err}`, 'danger')
                                                }
                                            }}
                                            row={row}
                                            tip="Resolve sourceline with this product"
                                            type="success"
                                        />
                                    }}
                                />
                            </CollectionView>
                        </div>
                    </TabPanel>
                </Tab>
            </Tabs>
        </div>
    }
}
