import m from 'mithril'

// Builds a link from an url and link text.
const build_link = (url, text) => {
    return m(m.route.Link, {
        target: '_blank',
        href: url,
    }, text)
}

// Returns a link to Explore for a product, a relation and an optional year.
export const for_product = (text, case_artkey, supplier_artkey = null, year = null) => {
    let url
    if (supplier_artkey) {
        url = `/market/explore/?case=${case_artkey}&supplier=${supplier_artkey}`
    } else {
        url = `/market/explore/?case=${case_artkey}`
    }
    if (year) {
        url = url + `&year=${year}`
    }
    return build_link(url, text)
}

// Returns a link to Explore for a relation and an optional year.
export const for_relation = (text, supplier_artkey, year = null) => {
    let url = `/market/explore/?supplier=${supplier_artkey}`
    if (year) {
        url = url + `&year=${year}`
    }
    return build_link(url, text)
}
