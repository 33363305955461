$ = require 'jquery'
m = require 'mithril'
{
    each, head, sum, reject,
    map, and-list, all, filter
} = require 'prelude-ls'

api = require 'api.ls'
{
    prevent-default, a, if-let,
    pluralize, random-uuid, update
}: utils = require 'utils.ls'

{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{PurchaseOrderDetails} = require './purchase_order_details'
{item-tags-editor} = require '@/stock/components/item_tags_editor.ls'
{button, text-button, button-with-icon, icon-button} = require '@/components/buttons.ls'
{Popover} = require '@/components/popover/popover.ls'
fixed-table-header = require '@/components/table/fixed-header.ls'
{Item} = require '@/models/stock'
{Tippy} = require "@bitstillery/common/components"
app = require('@/app')

export class InStockPurchaseOrderItem
    (vnode) ->
        @purchase_order_item = vnode.attrs.purchase_order_item
        @item = vnode.attrs.item
        if @item.loendersloot_inspection_item! and not @item.lot!
            # Use data received from loendersloot to prefill values.
            @item.lot @item.loendersloot_inspection_item!lot
        @item.is_last = true  # Keep track of the splitting, only last record has a save button.
        @on_in_stock = vnode.attrs.on_in_stock
        @hide_from_pricelist_for_suppliers_selected = window.prop @item.hide_from_pricelist_for_suppliers!map((item) -> "" + item.artkey)
        @hide_from_pricelist_for_countries_selected = window.prop @item.hide_from_pricelist_for_countries!map((item) -> item.country_code)

    edit: ~>
        not @item.is_in_stock!

    split_item: ~>
        new_item = new Item @item.toObject!
        new_item.uuid = random-uuid!
        new_item.artkey null
        new_item.lot ''
        new_item.entry_date ''
        new_item.number_of_cases ''
        new_item.gift_box_type @item.gift_box_type!
        new_item.tax_label @item.tax_label!
        new_item.is_last = true

        @purchase_order_item.items!forEach (item) -> item.is_last = false
        @purchase_order_item.items!push new_item

    remove_me: ~>
        update @purchase_order_item.items, reject (i) ~>
            if @item.artkey!
            then i.artkey! == @item.artkey!
            else i.uuid == @item.uuid

    cases_diff: ~>
        @purchase_order_item.total_number_of_cases_in_items! - \
            @purchase_order_item.number_of_cases!

    is_last_item: ~>
        return @item.is_last

    can_delete: ~>
        and-list [
            not @item.artkey!?
            not @item.is_in_stock!
            @purchase_order_item.items!length > 1
            @item.sales_order_items.length == 0
        ]

    can_save: ~>
        can_save_item = (item) -> and-list [
            +item.number_of_cases! > 0
            item.lot! != ''
            !item.lot!includes(' ')
            item.country_of_origin! != null
            item.country_of_origin! != ''
        ]
        item = @purchase_order_item.items![0]

        @cases_diff! == 0 and \
            all can_save_item, @purchase_order_item.items!

    save: ~>
        data = do
            artkey: @purchase_order_item.artkey!
            suggested_price_per_case: @purchase_order_item.suggested_price_per_case!
            items: @purchase_order_item.items!map (item) ~>
                artkey: item.artkey!
                gift_box_type: item.gift_box_type!
                tax_label: item.tax_label!
                number_of_cases: +(item.number_of_cases!)
                lot: item.lot!
                item_tags: item.item_tags!
                best_before_date: item.best_before_date!
                cases_per_pallet: item.cases_per_pallet! or @purchase_order_item.cases_per_pallet!
                cases_per_pallet_layer: item.cases_per_pallet_layer! or @purchase_order_item.cases_per_pallet_layer!
                remark: item.remark!
                country_of_origin: item.country_of_origin!
                hide_from_pricelist_for_suppliers: @hide_from_pricelist_for_suppliers_selected!map((artkey) -> +artkey)
                hide_from_pricelist_for_countries: @hide_from_pricelist_for_countries_selected!

        api.call 'purchase.core.instock_purchase_order_item', data, @handle_save

    handle_save: (resp) ~>
        if not resp.success
            msg = if resp.message then resp.message else 'Failed bringing the purchase order item in stock. Please try again later. If the problem persists, please contact us.'
            app.notifier.notify msg, 'danger'
        else
            @on_in_stock resp.in_stockable
            for item in @purchase_order_item.items!
                item.is_in_stock true
            app.notifier.notify 'The purchase order item is now in stock.', 'success'

    remove_item: ~>
        if @item.artkey!
            # Disabled for now.
            app.notifier.notify 'Item can\'t be removed. ' +
                   'If the problem persists, please contact us.', 'danger'
        else
            @remove_me!

    fix_cases: ~>
        new_cases = @item.number_of_cases! - @cases_diff!
        @item.number_of_cases (if new_cases < 1 then 1 else new_cases)

    view: -> [
        m 'tr' {name: 'search-table-row'},
            m 'td' @purchase_order_item.bottle!product!name!
            m 'td' @purchase_order_item.number_of_bottles_per_case!
            m 'td' @purchase_order_item.bottle!to_specs!
            m 'td',
                if @edit!
                then inputs.gift_box_type @item.gift_box_type
                else @item.gift_box_type!
            m 'td',
                if @edit!
                then inputs.tax_label @item.tax_label
                else @item.tax_label!
            m 'td',
                if @purchase_order_item.number_of_cases! == @purchase_order_item.total_number_of_cases_in_items! then
                    @purchase_order_item.number_of_cases!
                else
                    m Tippy, {
                        content: "An inconsistent number of cases between the purchase order item and all stock items."
                    },
                        m 'div' @purchase_order_item.number_of_cases! + " ",
                        m 'span.glyphicon.glyphicon-alert'
            m 'td',
                if @edit! then
                    m MoneyInput,
                        value: @purchase_order_item.suggested_price_per_case!
                        on_value: (price) ~> @purchase_order_item.suggested_price_per_case price
                        currency: app.$s.currencies.default
                else
                    if @purchase_order_item.suggested_price_per_case! then
                        m Amount, do
                            amount: @purchase_order_item.suggested_price_per_case!
                            currency: app.$s.currencies.default
                    else
                        '-'
            m 'td',
                if @edit! then
                    m '.input-group',
                        if-let @cases_diff!, (diff) ~>
                            m Popover, do
                                title: 'Incorrect number of cases',
                                content: diff-message diff
                                placement: 'bottom'
                                max_width: 200
                                always_show: true
                            , m '.input-group-btn' text-button 'Fix', do
                                class: 'btn-warning'
                                onclick: @fix_cases

                        inputs.number @item.number_of_cases, {min: 1}
                else @item.number_of_cases!
            m 'td',
                if @edit!
                then inputs.text(@item.lot, {
                    # Lot numbers don't have whitespace; strip all from user-input
                    after_update: (ev) ~> @item.lot ev.target.value.replace(/\s/g, '')
                })
                else @item.lot!
            m 'td' m '.btn-toolbar' m '.btn-group',
                if @edit! then a do
                    # Only show save button for the last item of a POI.
                    if @is_last_item! then
                        icon-button 'ok', do
                            class: 'btn-success'
                            type: \button
                            onclick: @save
                            disabled: if not @can_save! then \disabled else ''

                    button-with-icon 'Split', 'plus', do
                        class: 'btn-default'
                        type: \button
                        onclick: @split_item

                if @can_delete! then
                    icon-button 'remove', do
                        class: 'btn-danger'
                        type: \button
                        onclick: @remove_item

        if @edit!
            m 'tr.well' m 'td' {colspan: '100%'},
                m '.panel.panel-body',
                    m '.row',
                        m '.col-md-6',
                            m 'form.form-horizontal',
                                item-tags-editor @item
    ]


diff-message = (diff) ->
    cases = pluralize diff, 'case', 'cases'
    if diff > 0
    then "#{diff} #{cases} too many"
    else "#{-diff} #{cases} too few"
