import L from 'leaflet'

interface Location {
    longitude: string | null
    latitude: string | null
}

export interface NamedLocation extends Location {
    name: string
    href?: string
    color?: string // default "#3388ff"
}

export interface LeafletMapOptions {
    zoom_level?: number
    center?: NamedLocation
}

/**
 * Generates a world map from open street view. The map is drawn on a canvas for performance reasons. Markers
 * are added as a circle.
 *
 * Usage:
 * In the view add a div with an id and pass this to the constructor:
 * `const the_map = new Leaflet_map(id_of_the_div, {})`
 *
 * Pass options to set the zoom_level and the center of the map. Next, add some markers:
 * `the_map.add_markers([])`
 */
export class LeafletMap {
    the_map: L.Map
    markers = new L.FeatureGroup()

    /**
     * Create the leaflet map.
     *
     * @param target_id The id of the div where to draw the map.
     * @param options A LeafletMapOptions struct, optional.
     */
    constructor(target_id: string, options: LeafletMapOptions = {}) {
        const the_center = [options?.center?.longitude || '50.505', options?.center?.latitude || '6.09']
        // @ts-ignore
        this.the_map = L.map(target_id, {preferCanvas: true}).setView(the_center, options?.zoom_level || 5)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.the_map)
        this.the_map.addLayer(this.markers)
    }

    add_markers(locations: NamedLocation[]): void {
        this.the_map.removeLayer(this.markers)
        this.markers = new L.FeatureGroup()
        locations
            .filter((location) => location.latitude !== null && location.longitude !== null)
            .map((location) =>
                // @ts-ignore
                L.circleMarker([+location.latitude, +location.longitude], {
                    color: location.color ? location.color : '#3388ff',
                })
                    .bindPopup(
                        location.href ? `<a href=${location.href} target="_blank">${location.name}</a>` : location.name,
                    )
                    .addTo(this.markers),
            )
        this.the_map.addLayer(this.markers)
    }

    destroy(): void {
        this.the_map.remove()
    }
}
