m = require 'mithril'
{filter, head, sort-by} = require 'prelude-ls'
api = require 'api.ls'
{button-with-icon, icon-button} = require '@/components/buttons.ls'
{continents, countries, countries_per_continent} = require '@bitstillery/common/lib/countries'
{CheckboxGroup} = require '@/components/filter/checkboxgroup.ls'
Radiobutton = require '@/components/filter/radiobutton.ls'
inputs = require '@/components/inputs'
{Modal} = require '@/components/modal/modal.ls'
{languages} = require '@/components/languages.ls'
{a}: utils = require 'utils.ls'
{UserCheckboxGroup} = require '@/components/users'
{FilterPresetHandler, FilterPresetType} = require '../../filter_preset/filter_preset'
{SaveFilterPresetAs} = require '../../filter_preset/save_filter_preset_as'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

CLIENT_STATUSES = [
    'Client'
    'Prospect'
    'Low Potential'
]

COMPANY_TYPES = [
    'MSI'
    'Trader'
    'Retail'
    'Wholesale'
    'Travel Retail / Duty Free'
]


# The sidebar filter for the recipients selection.
module.exports = class FilterSidebar
    (vnode) ~>
        @relations = vnode.attrs.relations
        @offer_relation_filters = vnode.attrs.offer_relation_filters || null

        @show_save_modal = window.prop false

        @loading_accounts = window.prop true
        @loading_languages = window.prop true
        @loading_countries = window.prop true
        @loading_vouchers = window.prop false
        @active_vouchers = window.prop []

        # Define the options for the language filters. These are filled from
        # the database in the oncreate funtion.
        @language_options = window.prop []

        # For the country filters, define separate options for each continent.
        # These are filled from the database in the oncreate function.
        @countries_per_continent_options = {}
        for continent_code in Object.keys continents
            @countries_per_continent_options[continent_code] = window.prop []

        @filter_presets = window.prop []
        @filter_preset_choices = window.prop []
        @selected_filter_preset_artkey = window.prop null
        @loading_filter_presets = window.prop true
        @filter_preset_type = FilterPresetType.SELECT_RELATION
        @filter_preset_handler = new FilterPresetHandler(
            @filter_preset_type,
            @filter_presets,
            @filter_preset_choices,
            @selected_filter_preset_artkey,
            @loading_filter_presets,
        )

    oncreate: ~>
        # Wait with rendering the currency and user-related CheckBoxGroups
        # until their filter options are loaded from the factserver.
        app.$m.common.observable.subscribe 'accounts_loaded', @, ~> @loading_accounts false

        # If currencies and users are already loaded earlier, the broadcasts
        # for the subscribes above are not executed, so check that here.
        if app.$m.accounts.accounts!length > 0 then @loading_accounts false

        @loading_vouchers true
        api.call-and-then 'voucher.get_voucher_promotion_list', {}, do
            success: (resp) ~>
                @loading_vouchers false
                @active_vouchers resp.result

        api.call-and-then 'suppliers.get_used_contact_languages', {}, do
            success: (resp) ~>
                if resp.result then
                    @language_options (
                        [[code, languages[code]] for code in resp.result]
                        |> sort-by ((option) -> option[1])  # sort by language name
                    )
                    @loading_languages false

        api.call-and-then 'suppliers.get_used_countries', {}, do
            success: (resp) ~>
                if resp.result then
                    # For each continent, define separate options to choose
                    # from.
                    for continent_code in Object.keys continents
                        @countries_per_continent_options[continent_code] (
                            [[code, "#{code} - #{countries[code]}"] for code in resp.result]
                            |> filter ([code, name]) -> code in countries_per_continent[continent_code]
                            |> sort-by ((option) -> option[1])  # sort by country name
                        )
                    @loading_countries false

        @filter_preset_handler.get_filter_presets!

        # Load the filter parameters from the offer into the collection.
        if @offer_relation_filters
            for parameter_name in Object.keys(@offer_relation_filters)
                if parameter_name in Object.keys(@relations.filters)
                    @relations.filters[parameter_name] @offer_relation_filters[parameter_name]
                else
                    app.notifier.notify "The offer's filter parameter with name #{parameter_name} is not recognized. Check your filter settings and save the relation selection again to fix this.", 'warning'

            # Apply the filter parameters to the collection.
            @relations.requery!

    # Applies a filter preset to the html components and the collection.
    # This function is called after selecting a preset in the dropdown.
    #
    # @param {int} artkey: The selected filter preset's artkey.
    set_filter_preset: (artkey) ~>
        @selected_filter_preset_artkey +artkey

        if artkey
            # Find the filter preset object for the provided artkey.
            preset = head @filter_presets!filter (.artkey == @selected_filter_preset_artkey!)

            # Load the filter parameters from the object into the collection.
            if preset
                for parameter_name in Object.keys(preset['filters'])
                    if parameter_name in Object.keys(@relations.filters)
                        @relations.filters[parameter_name] preset['filters'][parameter_name]
                    else
                        app.notifier.notify "The preset's filter parameter with name #{parameter_name} is not recognized. Check your filter settings and save the preset again to fix this.", 'warning'

            # Apply the filter parameters to the collection.
            @relations.requery!

    # After saving a filter preset via the save modal, process the changes
    # by updating the 'Select a preset'-dropdown and closing the modal.
    #
    # @param {int} artkey: The artkey of the created or updated filter preset.
    process_save_response: (artkey) ~>
        @filter_preset_handler.get_filter_presets!
        @selected_filter_preset_artkey artkey
        @show_save_modal false

    close_save_modal: ~>
        @show_save_modal false

    # Reset all filters to their default values.
    reset_all_filters: ~>
        # NOTE: It is difficult to uncheck the checkboxes in the CheckBoxGroup
        # components from here, because we need to access the state of those
        # CheckBoxGroup components. Therefore, for now, just reload the page
        # instead.
        window.location.reload true

    view: ~> if typeof @relations.filters != 'undefined' then m '.c-filter-sidebar',
        m '.row' m '.col-xs-12',
            m 'label.control-label' 'Select a preset'

        m '.row' m '.col-xs-12.mb-1',
            if @loading_filter_presets! then m Spinner else
                inputs.select @selected_filter_preset_artkey, @filter_preset_choices!, do
                    onchange: @set_filter_preset
                    disabled: @filter_preset_choices!length < 2  # disabled when there are no options or only the 'empty' option

        m '.row' m '.col-xs-12' m '.btn-group',
            button-with-icon 'Save', 'fa-save', do
                class: 'btn-success'
                onclick: ~> @show_save_modal true
                disabled: @loading_filter_presets!
                title: 'Save filter preset'
            button-with-icon 'Delete', 'remove', do
                class: 'btn-danger'
                onclick: ~> @filter_preset_handler.delete_filter_preset!
                disabled: @loading_filter_presets! or @selected_filter_preset_artkey! == null
                title: 'Delete filter preset'

        m 'hr'

        m '.btn-group', a do
            button-with-icon 'Apply', 'glyphicon-filter', do
                class: 'btn-success'
                onclick: @relations.requery
            button-with-icon 'Reset', 'fa-undo', do
                class: 'btn-default'
                onclick: @reset_all_filters

        m 'hr'

        m '.btn-group',
            button-with-icon 'Only my sales relations' 'glyphicon-user',
                if @relations.filters['relation_filter']! == 'mine_sales'
                    class: 'btn-warning'
                    onclick: ~> @relations.filters['relation_filter'] 'all'
                else
                    class: 'btn-default'
                    onclick: ~> @relations.filters['relation_filter'] 'mine_sales'

        m 'hr'

        m '.btn-group.filter-radio.mb-1', a do
            m 'label.control-label' 'Buyers'
            m '' inputs.radio(@relations.filters['buyers'], [
                {'value': 'true', 'description': 'Yes'},
                {'value': 'false', 'description': 'No'},
                {'value': '', 'description': 'Both'}
            ])

        m '.btn-group.filter-radio.mb-1', a do
            m 'label.control-label' 'Suppliers'
            m '' inputs.radio(@relations.filters['suppliers'], [
                {'value': 'true', 'description': 'Yes'},
                {'value': 'false', 'description': 'No'},
                {'value': '', 'description': 'Both'}
            ])

        m 'hr'

        if @loading_accounts! then m Spinner else
            m CheckboxGroup, do
                filter_function: @relations.filters['sales_account']
                filter_id: 'sales_account'
                filter_name: 'Sales Account'
                filter_options: [[account.slug!, account.name!] for account in app.$m.accounts.accounts!]

        if @loading_languages! then m Spinner else
            m CheckboxGroup, do
                filter_function: @relations.filters['language']
                filter_id: 'language'
                filter_name: 'Language'
                filter_options: @language_options!
                show_no_value_checkbox: true

        if @loading_countries! then m Spinner else a do
            # For each continent, show a CheckBoxGroup with countries.
            for continent_code in Object.keys continents
                # Only show the CheckBoxGroup if there are options to choose
                # from in that continent.
                if @countries_per_continent_options[continent_code]!length > 0
                    m CheckboxGroup, do
                        filter_function: @relations.filters["countries_per_continent_#{continent_code}"]
                        filter_id: "countries_#{continent_code}"
                        filter_name: "Countries in #{continents[continent_code]}"
                        filter_options: @countries_per_continent_options[continent_code]!

        m CheckboxGroup, do
            filter_function: @relations.filters['company_type']
            filter_id: 'company_type'
            filter_name: 'Company type'
            filter_options: [[status, status] for status in COMPANY_TYPES]

        m CheckboxGroup, do
            filter_function: @relations.filters['client_status']
            filter_id: 'client_status'
            filter_name: 'Client status'
            filter_options: [[status, status] for status in CLIENT_STATUSES]

        m UserCheckboxGroup, do
            filter_function: @relations.filters['purchase_manager']
            filter_id: 'purchase_manager'
            filter_name: 'Purchase Manager'

        m UserCheckboxGroup, do
            filter_function: @relations.filters['sales_manager']
            filter_id: 'sales_manager'
            filter_name: 'Sales Manager'

        m CheckboxGroup, do
            filter_function: @relations.filters['price_list_frequency']
            filter_id: 'price_list_frequency'
            filter_name: 'Price list frequency'
            filter_options: [[key, app.$m.data.price_list_frequencies[key]] for key in (Object.keys app.$m.data.price_list_frequencies)]
            show_no_value_checkbox: true

        m CheckboxGroup, do
            filter_function: @relations.filters['portal_level']
            filter_id: 'portal_level'
            filter_name: 'Portal level'
            filter_options: app.$m.data.supplier_portal_levels
            show_no_value_checkbox: true

        m CheckboxGroup, do
            filter_function: @relations.filters['customs_status_visibility']
            filter_id: 'customs_status_visibility'
            filter_name: 'Customs status visibility'
            filter_options: app.$m.data.supplier_portal_customs_visibility
            show_no_value_checkbox: true

        m CheckboxGroup, do
            filter_function: @relations.filters['currency']
            filter_id: 'currency'
            filter_name: 'Currency'
            filter_options: [[currency, currency] for currency in app.$s.currencies.all]

        m CheckboxGroup, do
            filter_function: @relations.filters['active_sales_promotions']
            filter_id: 'sales_promotion_artkeys'
            filter_name: 'Sales Promotions'
            filter_options: app.$m.data.sales_promotions
            show_no_value_checkbox: true

        if @loading_vouchers! then m Spinner else
            m CheckboxGroup, do
                filter_function: @relations.filters['active_vouchers_artkeys']
                filter_id: 'active_vouchers'
                filter_name: 'Voucher'
                filter_options: [[voucher.artkey, voucher.name] for voucher in @active_vouchers!]

        m 'hr'

        m Radiobutton, do
            filter_function: @relations.filters['operates_online']
            filter_name: 'Operates online'
            filter_options: [[true, 'Yes'], [false, 'No'], ['', 'All']]

        m Radiobutton, do
            filter_function: @relations.filters['should_receive_offer_mails']
            filter_name: 'Receives offer mails'
            filter_options: [['true', 'Yes'], ['false', 'No'], ['', 'All']]

        m Radiobutton, do
            filter_function: @relations.filters['should_receive_purchase_mails']
            filter_name: 'Receives purchase mails'
            filter_options: [['true', 'Yes'], ['false', 'No'], ['', 'All']]

        if @show_save_modal! then
            m Modal, do
                title: 'Save filter preset as'
                onclose: @close_save_modal
            , m SaveFilterPresetAs, do
                filter_preset_data: @filter_preset_handler.get_save_request_data @relations.filters
                done: @process_save_response
                cancel: @close_save_modal
