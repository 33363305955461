m = require 'mithril'

api = require 'api.ls'
{a, prevent-default}: utils = require 'utils.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{PurchaseOrderDetails} = require './components/purchase_order_details'
{BottleDetails} = require '@/components/market_info/bottle_details'
fixed-table-header = require '@/components/table/fixed-header.ls'
{PurchaseOrderStatus} = require '@/models/purchase_orders'
app = require('@/app')

module.exports = class ConfirmPurchaseOrder
    ->
        @saving = window.prop false
        @purchase_order = window.prop null
        purchase_order_artkey = +m.route.param 'artkey'
        if purchase_order_artkey
            @load_purchase_order +purchase_order_artkey

    load_purchase_order: (purchase_order_artkey) ->
        data = do
            purchase_order_artkey: purchase_order_artkey
        api.call 'purchase.core.get_purchase_orders_with_items', data, @set_purchase_order

    set_purchase_order: (resp) ~>
        if resp.success
            @purchase_order app.$m.purchase_orders.create_purchase_order resp.result[0]
            if @purchase_order!status! not in [PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED]
                app.notifier.notify 'This order does not have the status saved. Only saved orders can be confirmed.', 'info'
                m.route.set '/purchase_orders/manage/' + @purchase_order!artkey!

            # Set default confirmation date values.
            if not @purchase_order!confirmation_date!
                @purchase_order!confirmation_date utils.format_date_html5(new Date!)

            # Calculate the rate ratio between the currency of the warehouse
            # and the currency of the purchase order.
            warehouse_rate = app.$s.currencies.exchange_rates[@purchase_order!target_warehouse!currency!].rate
            order_rate = +@purchase_order!bought_against_rate!
            warehouse_to_order_rate = order_rate / warehouse_rate

            # Set default warehouse costs values.
            if not @purchase_order!warehouse_base_costs!
                @purchase_order!warehouse_base_costs @purchase_order!target_warehouse!warehouse_base_costs! * warehouse_to_order_rate
            if not @purchase_order!warehouse_costs_per_case!
                @purchase_order!warehouse_costs_per_case @purchase_order!target_warehouse!warehouse_costs_per_case! * warehouse_to_order_rate

    submit: ~>
        if not @purchase_order!confirmation_date!
            app.notifier.notify "Please fill out a valid confirmation date.", 'danger'
            return
        if not @purchase_order!expected_delivery_date!
            app.notifier.notify "Please fill out a valid expected delivery date.", 'danger'
            return
        if not @purchase_order!bought_against_rate!
            app.notifier.notify "Please fill out a valid exchange rate.", 'danger'
            return
        if @purchase_order!warehouse_base_costs! == null or @purchase_order!warehouse_base_costs! == ''
            app.notifier.notify "Please fill out the base costs for the warehouse.", 'danger'
            return
        if @purchase_order!warehouse_costs_per_case! == null or @purchase_order!warehouse_costs_per_case! == ''
            app.notifier.notify "Please fill out the costs per case for the warehouse.", 'danger'
            return
        if @purchase_order!transport_costs! == null or @purchase_order!transport_costs! == ''
            app.notifier.notify "Please fill out the costs for transport.", 'danger'
            return
        if @purchase_order!supplier!country_code! == 'NL' and (@purchase_order!waste_fund_costs! == '' or @purchase_order!waste_fund_costs! == null)
            app.notifier.notify "Please fill out the costs for the waste fund.", 'danger'
            return

        @saving true

        suggested_prices = {}
        for item in @purchase_order!purchase_order_items!
            if +item.suggested_price_per_case!
                suggested_prices[+item.artkey!] = +item.suggested_price_per_case!

        data = do
            purchase_order_artkey: +@purchase_order!artkey!
            bought_against_rate: +@purchase_order!bought_against_rate!
            confirmation_date: @purchase_order!confirmation_date!
            expected_delivery_date: @purchase_order!expected_delivery_date!
            warehouse_base_costs: @purchase_order!warehouse_base_costs!
            warehouse_costs_per_case: @purchase_order!warehouse_costs_per_case!
            transport_costs: @purchase_order!transport_costs!
            pallet_costs: @purchase_order!pallet_costs!
            other_costs: @purchase_order!other_costs!
            waste_fund_costs: @purchase_order!waste_fund_costs!
            suggested_sales_prices: suggested_prices
        api.call-and-then 'purchase.core.confirm_purchase_order', data, do
            success: (resp) ~>
                app.notifier.notify 'This order was confirmed successfully.', 'success'
                # Broadcast the change, so the offer item list and
                # stock lists are requeried.
                app.$m.common.observable.broadcast 'stock_updated'
                m.route.set '/purchase-orders/manage/' + @purchase_order!artkey!
            final: (resp) ~>
                @saving false

    view: -> m '.c-purchase-order-confirm view',
        m '.btn-toolbar',
            m 'button.btn.btn-default' {type: "button", \
                                    onclick: ~> m.route.set '/purchase-orders/manage/' + @purchase_order!artkey!},\
                (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to order'
        m 'h2' 'Confirm purchase order'
        if @purchase_order! then
            m PurchaseOrderDetails, do
                purchase_order: @purchase_order

        if @purchase_order! and @purchase_order!artkey! then
            m 'form',
                m '.col-sm-6',
                    m '.field',
                        m 'label' {for: 'confirmation_date'} 'Order confirmation date by supplier *'
                        inputs.date(@purchase_order!confirmation_date, {required: true})
                    m '.field',
                        m 'label' {for: 'warehouse_base_costs'} 'Warehouse base costs *'
                        m MoneyInput, do
                            value: @purchase_order!warehouse_base_costs!
                            on_value: (price) ~> @purchase_order!warehouse_base_costs price
                            currency: @purchase_order!was_bought_in!
                            required: true
                    m '.field',
                        m 'label' {for: 'warehouse_costs_per_case'} 'Warehouse costs per case *'
                        m MoneyInput, do
                            value: @purchase_order!warehouse_costs_per_case!
                            on_value: (price) ~> @purchase_order!warehouse_costs_per_case price
                            currency: @purchase_order!was_bought_in!
                            required: true
                m '.col-sm-6',
                    m '.field',
                        m 'label' {for: 'expected_delivery_date'} 'Expected delivery date *'
                        inputs.date(@purchase_order!expected_delivery_date, {required: true})
                    m '.field-group',
                        m MoneyInput, do
                            label: 'Order transport costs'
                            value: @purchase_order!transport_costs!
                            on_value: (price) ~> @purchase_order!transport_costs price
                            currency: @purchase_order!was_bought_in!
                            required: true
                        m MoneyInput, do
                            label: 'Order pallet costs'
                            value: @purchase_order!pallet_costs!
                            on_value: (price) ~> @purchase_order!pallet_costs price
                            currency: @purchase_order!was_bought_in!
                            required: true
                        m MoneyInput, do
                            label: 'Order other costs'
                            value: @purchase_order!other_costs!
                            on_value: (price) ~> @purchase_order!other_costs price
                            currency: @purchase_order!was_bought_in!
                            required: true
                    m '.field' {class: if @purchase_order!was_bought_in! === app.$s.currencies.default then 'hidden'},
                        m 'label' {for: 'bought_against_rate'} 'Exchange rate *'
                        inputs.number(@purchase_order!bought_against_rate, {required: true, step: '0.0001'})
                    if @purchase_order!supplier!country_code! == 'NL'
                        m '.field',
                            m 'label' {for: 'waste_fund_costs'} 'Waste fund costs *'
                            m MoneyInput, do
                                value: @purchase_order!waste_fund_costs!
                                on_value: (price) ~> @purchase_order!waste_fund_costs price
                                currency: @purchase_order!was_bought_in!
                                required: true

        if @purchase_order! then
            fixed-table-header.with-buttons m 'table.table.search-table',
                m 'thead.thead-default',
                    m 'tr', a do
                        m 'th' 'Product'
                        m 'th' 'Btl / cs'
                        m 'th' 'Specs'
                        m 'th' 'GB'
                        m 'th' 'Cases'
                        m 'th' 'Net price'
                        m 'th' 'Gross price'
                        m 'th' 'Net price (€)'
                        m 'th' 'Gross price (€)'
                        m 'th' 'Total value'
                        m 'th' 'Suggested sales price / cs'
                @purchase_order!purchase_order_items!map (item, index) ~>
                    m ConfirmPurchaseOrderItem, {item: item, index: index, purchase_order: @purchase_order}

        m 'button.btn.btn-default', {disabled: true, class: if not @saving! then 'hidden' else ''}, 'Saving...'
        m 'button.btn.btn-primary' {class: if @saving! then 'hidden' else '', onclick: @submit} (m 'span.glyphicon.glyphicon-road'), ' Confirm order'


class ConfirmPurchaseOrderItem
    (vnode) ->
        @item = window.prop vnode.attrs.item
        @index = window.prop vnode.attrs.index
        @show_market = window.prop false
        @purchase_order = vnode.attrs.purchase_order

    was_bought_for_plus_costs: ~>
        @total_costs_per_case = (+@purchase_order!warehouse_base_costs! + +@purchase_order!transport_costs!) / +@purchase_order!number_of_cases! + +@purchase_order!warehouse_costs_per_case!
        +@item!was_bought_for! + +@total_costs_per_case

    toggle_market: ~>
        @show_market !@show_market!

    view: -> m 'tbody.table-row',
        m 'tr.clickable',
            m 'td' {onclick: @toggle_market}, @item!bottle!product!name!
            m 'td' {onclick: @toggle_market}, @item!number_of_bottles_per_case!
            m 'td' {onclick: @toggle_market},
                (+@item!bottle!volume!).toFixed(1), 'cl', ' / ',
                (+@item!bottle!alcohol_percentage!).toFixed(1), '%', ' / '
                @item!bottle!refill_status!
            m 'td' {onclick: @toggle_market}, @item!gift_box_type!
            m 'td' {onclick: @toggle_market}, @item!number_of_cases!
            # Purchase price
            m 'td' {onclick: @toggle_market},
                m Amount, {amount: +@item!was_bought_for!, currency: @item!purchase_order!was_bought_in!, rate: @item!purchase_order!bought_against_rate!}
            # Gross purchase price
            m 'td' {onclick: @toggle_market},
                m Amount, do
                    amount: @was_bought_for_plus_costs!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
            # Purchase price (EUR)
            m 'td' {onclick: @toggle_market},
                m Amount, do
                    amount: +@item!was_bought_for!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
                    display_currency: app.$s.currencies.default
            # Gross purchase price (EUR)
            m 'td' {onclick: @toggle_market},
                m Amount,
                    amount: @was_bought_for_plus_costs!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
                    display_currency: app.$s.currencies.default
            m 'td' {onclick: @toggle_market, display_currency: app.$s.currencies.default},
                m Amount,
                    amount: +@item!number_of_cases! * +@item!was_bought_for!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
            # Target price per case.
            m 'td' {onclick: @toggle_market},
                m MoneyInput, do
                    value: @item!suggested_price_per_case!
                    on_value: (price) ~> @item!suggested_price_per_case price
                    currency: app.$s.currencies.default

        if @show_market!
            m 'tr.unclickable' m 'td.details-row' {colspan: '100%'},
                m BottleDetails, {bottle_artkey: @item!bottle_artkey!}
