import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {CaseInfoPanelAttrs} from './case_info_panel'
import {BottleStock} from './bottle_stock'
import {BottlePurchaseOrders} from './bottle_purchase_orders'
import {BottleMarket, BottleMarketAttrs} from './bottle_market'
import {BottleSalesOrders} from './bottle_sales_orders'

/**
 * An alternative for CaseInfoPanel, to only show bottle details and restrict input.
 *
 * The CaseInfoPanel is used more often (and by only passing in a bottle_artkey you can get the same effect) so please
 * use that one before this one.
 */
export class BottleDetails extends MithrilTsxComponent<BottleMarketAttrs> {
    view(vnode: m.Vnode<CaseInfoPanelAttrs>): m.Children {
        return [
            <div className="row">
                <div className="col-xs-12">
                    <BottleMarket {...vnode.attrs} />
                </div>
            </div>,
            <div className="row">
                <div className="col-xs-12">
                    <BottleStock bottle_artkey={vnode.attrs.bottle_artkey} />
                </div>
            </div>,
            <div className="row">
                <div className="col-xs-6">
                    <BottlePurchaseOrders bottle_artkey={vnode.attrs.bottle_artkey} />
                </div>
                <div className="col-xs-6">
                    <BottleSalesOrders bottle_artkey={vnode.attrs.bottle_artkey} />
                </div>
            </div>,
        ]
    }
}
