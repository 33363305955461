import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Badge} from '@bitstillery/common/badge'
import {classes} from '@bitstillery/common/lib/utils'

import {NotificationsFilter} from './filter'
import {NotificationsTable} from './table'

import {PagedCollectionFetcher} from '@/components/collection/collection_table'
import {FullOpenNotificationsResponse, Notification, NotificationApi} from '@/factserver_api/notification_api'

export enum DisplayMode {
    NOTIFICATIONS = 'NOTIFICATIONS',
    SEEN_NOTIFICATIONS = 'SEEN_NOTIFICATIONS',
    BLOCKED_NOTIFICATIONS = 'BLOCKED_NOTIFICATIONS',
    SNOOZED_NOTIFICATIONS = 'SNOOZED_NOTIFICATIONS',
}

export enum NotificationsTypeFilter {
    SALES_ORDERS = 'Sales Orders',
    PURCHASE_ORDERS = 'Purchase Orders',
    PRICE_LISTS = 'Price Lists',
    EMAIL_BATCHES = 'Email Batches',
    PORTAL_USERS = 'Portal Users',
    SUPPLIERS = 'Relations',
    ITEMS = 'Stock items',
    OFFERS = 'Offers',
    LEADS = 'Leads',
    CONTACT_PERSONS = 'Contacts',
}

interface NotificationPanelAttrs {
    is_collapsed: boolean
    on_collapsed: (is_collapsed: boolean) => unknown
}

/**
 * Show notifications in from the api call get_for_user. The notifications can be marked as seen.
 *
 * Starts a timeout function to refresh the open notifications count.
 */
export default class NotificationsPanel extends MithrilTsxComponent<NotificationPanelAttrs> {
    notifications_fetcher = new PagedCollectionFetcher<Notification>(
        'notifications.notifications_for_user',
        'date',
        undefined,
        50,
        false,
    )

    open_notifications: FullOpenNotificationsResponse | null = null
    timeout_id: number | null = null

    selected_display_mode = DisplayMode.NOTIFICATIONS

    notification_api = new NotificationApi()

    constructor() {
        super()

        this.notifications_fetcher.filters['display_mode'] = DisplayMode.NOTIFICATIONS
        this.notifications_fetcher.filters['notification_types'] = ''
    }

    fetch_notifications_count(): void {
        this.notification_api.notifications_count().subscribe({
            next: (response) => {
                this.open_notifications = response
                m.redraw()
            },
            complete: () => (this.timeout_id = window.setTimeout((): void => this.fetch_notifications_count(), 10000)),
        })
    }

    oncreate(): void {
        this.fetch_notifications_count()
    }

    onremove(): void {
        if (this.timeout_id) {
            clearTimeout(this.timeout_id)
        }
    }

    switch_display_mode_to(new_display_mode: DisplayMode): void {
        if (this.selected_display_mode === new_display_mode) {
            return
        }
        this.selected_display_mode = new_display_mode
        this.notifications_fetcher.filters['display_mode'] = this.selected_display_mode
        this.notifications_fetcher.reset_and_query()
    }

    view() {
        return (
            <div className={classes('c-widget-notifications dashboard-widget')}>
                <div className="widget-title">
                    Notifications <Badge type={'info'}>{this.open_notifications?.open_notifications_count}</Badge>
                </div>
                <div className="notifications-wrapper">
                    <NotificationsFilter
                        display_mode={this.selected_display_mode}
                        open_notifications_count={this.open_notifications}
                        switch_display_mode_to={(new_display_mode: DisplayMode) =>
                            this.switch_display_mode_to(new_display_mode)
                        }
                        notifications_fetcher={this.notifications_fetcher}
                    />

                    <NotificationsTable
                        notifications_fetcher={this.notifications_fetcher}
                        selected_display_mode={this.selected_display_mode}
                    />
                </div>
            </div>
        )
    }
}
