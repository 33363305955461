export class ExciseDutyApplications
    @HL_PRODUCT = 'hl/product'
    @HL_DEGREE_ALCOHOL = 'hl/degree alcohol'


export class ExciseCategory
    (json) ->
        @artkey = window.prop null
        @currency = window.prop ''
        @name = window.prop ''
        @country_code = window.prop ''
        @duty_rate = window.prop ''
        @duty_application = window.prop ''
        @from_alcohol_percentage = window.prop null
        @to_alcohol_percentage = window.prop null

        for prop of json
            @[prop] = window.prop json[prop]
