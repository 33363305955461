import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'

import {Link} from '@/components/discover'
import {GetPurchaseOrderResponseOutput, PurchaseOrderStatus} from '@/factserver_api/fact2server_api'

interface SignedPurchaseRFPAttrs {
    purchase_order: GetPurchaseOrderResponseOutput
}

export class SignedPurchaseRFP extends MithrilTsxComponent<SignedPurchaseRFPAttrs> {
    view(vnode: m.Vnode<SignedPurchaseRFPAttrs>): m.Children {
        const purchase_order = vnode.attrs.purchase_order
        const is_saved = purchase_order.status === PurchaseOrderStatus.Saved
        if (!is_saved) {
            return
        }
        const has_import_statement = purchase_order.has_import_statement
        const supplier_has_signed_trademark_declaration = purchase_order.supplier.has_signed_trademark_declaration
        const supplier_has_valid_lexis_nexis = purchase_order.supplier.has_valid_lexis_nexis
        const supplier_is_verified = purchase_order.supplier.is_verified
        const signed_trademark_declaration_valid_until = purchase_order.supplier.signed_trademark_declaration_valid_until
        let supplier_trademark_declaration_expired: boolean = false
        if (signed_trademark_declaration_valid_until) {
            supplier_trademark_declaration_expired = new Date(signed_trademark_declaration_valid_until) < new Date()
        }

        /**
         * The signed rfp (Trademark Declaration) can be
         * - uploaded as an attachment.
         * - be present as an attachment at the supplier.
         */
        /* The signed RFP is present and valid for the supplier */
        if (supplier_is_verified) {
            return <div class="alert alert-info">
                <span class="glyphicon glyphicon-info-sign" />
                {' '}
                This supplier is verified and has a valid signed Trademark Declaration
                {
                    signed_trademark_declaration_valid_until
                        ? ` (valid until ${format_iso_to_date(signed_trademark_declaration_valid_until)})`
                        : ''
                }
            </div>
        }

        /* The signed RFP is uploaded as an attachment with this purchase order. */
        if (has_import_statement) {
            return <div class="alert alert-info">
                <span class="glyphicon glyphicon-info-sign" />
                {' '}
                Signed RFP present for this purchase order.
            </div>
        }

        /* The signed RFP is present but no longer valid for the supplier */
        if (supplier_has_valid_lexis_nexis && supplier_has_signed_trademark_declaration && supplier_trademark_declaration_expired) {
            return <div class="alert alert-warning">
                <span class="glyphicon glyphicon-info-sign" />
                {' '}
                This supplier has a signed Trademark Declaration but it has been expired since {format_iso_to_date(signed_trademark_declaration_valid_until)}.
                Update it at <Link href={`/crm/relations/${purchase_order.supplier.artkey}/edit?tab=attachments`}>{purchase_order.supplier.name}</Link>
                {' '}
                {purchase_order.requires_import_statement ? 'or upload a signed RFP to this PO to be able to confirm it.' : ''}
            </div>
        }

        if (purchase_order.requires_import_statement) {
            /* The signed RFP is not present, not at all. */
            return <div class="alert alert-warning">
                <span class="glyphicon glyphicon-warning-sign"/>
                {' '}
                All purchase orders with T2 products require a verified relation or a signed RFP before you can confirm them.
                Either verify the relation
                {' '}
                <Link href={`/crm/relations/${purchase_order.supplier.artkey}/edit?tab=attachments`}>
                    {purchase_order.supplier.name}
                </Link>
                {' '}
                or upload a signed RFP as an attachment.
            </div>
        }

        if (purchase_order.import_statement_override) {
            return <div class="alert alert-info">
                <span class="glyphicon glyphicon-info-sign"/>
                {' '}
                This purchase order is flagged as not requiring a signed RFP.
                {purchase_order.override_reason && (
                    <span>{` The reason given is: "${purchase_order.override_reason.trim()}"`}</span>
                )}
            </div>
        }

        return (
            <div class="alert alert-warning">
                <span class="glyphicon glyphicon-warning-sign"/>
                {' '}
                This purchase order is missing a signed RFP and the supplier is not verified.
            </div>
        )

    }
}
