import {Observable} from 'rxjs'

import {Api, FactserverRequestData} from './api'

interface AfvalfondsDownloadExportRequest extends FactserverRequestData {
    year: number
}
interface AfvalfondsDownloadExportResponse {
    base64_file: string
}

export class AfvalfondsApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    download_export(request: AfvalfondsDownloadExportRequest): Observable<AfvalfondsDownloadExportResponse | null> {
        return this.api.post_fact2server_request('discover/afvalfonds', request)
    }
}
