import {format_bytes, format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'
import {Tippy} from '@bitstillery/common/components'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {GetUserDownloadsResponse, UserDownloadStatus} from '@/factserver_api/fact2server_api'
import {UserManagementApi} from '@/factserver_api/user_management_api'
import {DefaultButton} from '@/components/buttons'

const DOWNLOAD_REFRESH_TIMEOUT = 15000 // 15 seconds

interface DownloadResponseWithLink extends GetUserDownloadsResponse {
    link: string | null
    link_valid_until: string | null
}

export default class MyDownloads {

    download_fetcher: PagedCollectionFetcherWithGET<GetUserDownloadsResponse>
    user_management_api = new UserManagementApi()
    timeout_handle: number

    constructor() {
        this.download_fetcher = new PagedCollectionFetcherWithGET('discover/users/downloads', 'created_on')
        this.download_fetcher.sort_ascending = false
        this.timeout_handle = window.setTimeout(() => this.refresh_downloads(), DOWNLOAD_REFRESH_TIMEOUT)
    }

    onremove() {
        if (this.timeout_handle) {
            clearTimeout(this.timeout_handle)
        }
    }

    delete_download(artkey: number): void {
        this.user_management_api.delete_download(artkey).subscribe({
            next: () => {
                this.download_fetcher.reset_and_query()
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    download_file(row: DownloadResponseWithLink): void {
        this.user_management_api.request_download_link(row.artkey).subscribe({
            next: (response) => {
                window.location.href = response.link
                m.redraw()
            },
            error: () => {
                notifier.notify('Something went wrong when downloading', 'danger')
                m.redraw()
            },
        })
    }

    refresh_downloads(): void {
        this.download_fetcher.reset_and_query()
        this.timeout_handle = window.setTimeout(() => this.refresh_downloads(), DOWNLOAD_REFRESH_TIMEOUT)
    }

    status_to_icon(row: DownloadResponseWithLink): m.Children {
        if (row.status === UserDownloadStatus.PENDING) {
            return <Tippy content={'The file is not yet ready'} >
                <span className={'fas fa-pause'} />
            </Tippy>
        }
        if (row.status === UserDownloadStatus.FAILED) {
            return <Tippy content={row.failure_reason} >
                <span className={'fas fa-exclamation-circle'} />
            </Tippy>
        }
        if (row.status === UserDownloadStatus.DONE && !row.warning_message) {
            return <Tippy content={'This file is complete and is available for download'} >
                <span className={'fas fa-check'} />
            </Tippy>
        }
        if (row.status === UserDownloadStatus.DONE && row.warning_message) {
            return <Tippy content={`This file is complete but has warnings: <br/> ${row.warning_message}`} >
                <span className={'fas fa-exclamation'} />
            </Tippy>
        }
        return <span />
    }

    view(): m.Children {
        return <div className="c-my-discover-downloads view">
            <CollectionTable<GetUserDownloadsResponse, void>
                collection_fetcher={this.download_fetcher}>
                <CollectionTableColumn<GetUserDownloadsResponse>
                    header_title={() => 'File name'}
                    td_class_name={'col-sm-4'}
                    sort_name={'name'}
                    data_field={(row: DownloadResponseWithLink) => <span key={row.status}>
                        {this.status_to_icon(row)}
                        {' '}
                        {row.file_name}
                    </span>
                    }
                />
                <CollectionTableColumn<GetUserDownloadsResponse>
                    header_title={() => 'Created on'}
                    sort_name={'created_on'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: GetUserDownloadsResponse) => format_iso_to_date_time(row.created_on)}
                />
                <CollectionTableColumn<GetUserDownloadsResponse>
                    header_title={() => 'Size'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: GetUserDownloadsResponse) => format_bytes(row.file_size)}
                />
                <CollectionTableColumn<DownloadResponseWithLink>
                    header_title={() => ''}
                    td_class_name={'col-sm-1'}
                    data_field={(row: DownloadResponseWithLink) => [
                        <DefaultButton
                            icon_class={'fas fa-download'}
                            disabled={row.status !== UserDownloadStatus.DONE}
                            onclick={() => this.download_file(row)}
                        />,
                        <DefaultButton
                            additional_class={'btn-danger'}
                            icon_class={'fas fa-trash'}
                            disabled={row.status === UserDownloadStatus.PENDING}
                            onclick={() => this.delete_download(row.artkey)}
                        />,
                    ]}
                />
            </CollectionTable>
        </div>
    }
}
