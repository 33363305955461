import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {format_money_with_symbol} from '@bitstillery/common/ts_utils'
import {notifier} from '@bitstillery/common/app'

import {SalesOrderTurnoverResponse, TurnoverFigures} from '@/factserver_api/fact2server_api.ts'
import {DashboardApi} from '@/factserver_api/dashboard_api.ts'

export interface SalesOrderOverviewWidgetAttrs {
    user_artkey: number | null | undefined
}

export class SalesOrderTurnoverWidget extends MithrilTsxComponent<void> {
    dashboard_api = new DashboardApi()
    sales_order_turnover_data: SalesOrderTurnoverResponse
    loading_figures = true
    user_artkey: number | null | undefined
    row_count = 0

    constructor(vnode: m.Vnode<SalesOrderOverviewWidgetAttrs>) {
        super()
        this.fetch_sales_order_turnover_data(vnode.attrs.user_artkey)
    }

    onupdate(vnode: m.Vnode<SalesOrderOverviewWidgetAttrs>): any {
        if (this.user_artkey !== vnode.attrs.user_artkey) {
            this.fetch_sales_order_turnover_data(vnode.attrs.user_artkey)
        }
    }

    fetch_sales_order_turnover_data(user_artkey?: number | null): void {
        this.user_artkey = user_artkey
        this.loading_figures = true

        this.dashboard_api.get_sales_order_turnover_data(this.user_artkey).subscribe({
            next: (response) => {
                this.sales_order_turnover_data = response
                this.loading_figures = false
            },
            error: (_) => {
                notifier.notify('Failed to fetch sales order turnover data', 'danger')
            },
        })
    }

    sales_order_turnover_row(
        title: string,
        data: TurnoverFigures,
        filters: object,
    ): m.Children {
        this.row_count += 1

        if (this.user_artkey) {
            filters['sales_manager_artkey'] = this.user_artkey.toString()
        }

        const filter_params = new URLSearchParams(filters as any).toString()
        return (
            <tr
                className={this.row_count % 2 === 0 ? 'even onclick-row' : 'odd onclick-row'}
                onclick={() => {
                    window.open('#!/sales-orders/manage?' + filter_params.toString(), '_blank')
                }}
            >
                <td>{title}</td>
                <td>{data.number_of_orders}</td>
                <td>{data.number_of_cases}</td>
                <td>{format_money_with_symbol(data.total_turnover, 'EUR')}</td>
            </tr>
        )
    }

    view(): m.Children {
        return (
            <div className="c-widget-individual-sales dashboard-widget">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Number of orders</th>
                            <th>Number of cases</th>
                            <th>Total turnover</th>
                        </tr>
                    </thead>
                    {this.loading_figures && <Spinner className="table-spinner"/>}
                    {!this.loading_figures && (
                        <tbody>
                            {this.sales_order_turnover_row(
                                'Saved (New)',
                                this.sales_order_turnover_data.saved_turnover_figures,
                                {status: 'Saved', overdue_orders: false},
                            )}
                            {this.sales_order_turnover_row(
                                'Saved (overdue)',
                                this.sales_order_turnover_data.overdue_turnover_figures,
                                {status: 'Saved', overdue_orders: true},
                            )}
                            {this.sales_order_turnover_row(
                                'Confirmed',
                                this.sales_order_turnover_data.confirmed_turnover_figures,
                                {status: 'Confirmed', in_progress_orders: false},
                            )}
                            {this.sales_order_turnover_row(
                                'In progress',
                                this.sales_order_turnover_data.in_progress_turnover_figures,
                                {status: 'Confirmed', in_progress_orders: true},
                            )}
                            {this.sales_order_turnover_row(
                                'Total',
                                this.sales_order_turnover_data.total_turnover_figures,
                                {status: 'Saved,Confirmed'},
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        )
    }
}
