m = require 'mithril'
utils = require 'utils.ls'
{Popover} = require '@/components/popover/popover.ls'
{classes} = require '@bitstillery/common/lib/utils'

panel = (title, body, footer, options) ->
    if not options then options = {}

    if options.collapsible and not options.unique_name?
        console.error 'Please provide a unique_name if you want your panel to be collapsible'

    id = options.unique_name
    _classes = []

    if options.collapsible
        _classes.push 'panel-collapse'
        _classes.push 'collapse'
    if not options.collapsed
        _classes.push 'in'

    description = if options.description then [
        ' '
        m Popover, do
            title: 'Description'
            content: options.description
        , m 'span.glyphicon.glyphicon-info-sign'
    ]

    m '.c-panel', {class: classes({
        collapsible: options.collapsible
    })},
        if title
            m '.panel-heading',
                if options.collapsible
                    m '.panel-title', {
                        'data-toggle': 'collapse',
                        'data-target': "#" + id,
                    },
                        title
                        description
                        if options.download_callback
                            m 'a' {onclick: utils.stop-propagation options.download_callback} options.download_content

                        if options.toolbar
                            m '.toolbar' {onclick: utils.stop-propagation!} options.toolbar
                else
                    m '.panel-title',
                        title
                        description
                        if options.download_callback
                            m '' {onclick: options.download_callback} options.download_content

                        if options.toolbar
                            m '.toolbar' {onclick: utils.stop-propagation!} options.toolbar

        m '' {class: _classes.join(' '), id: id},
            m '.panel-body' body
            if footer
                m '.panel-footer' footer


module.exports = panel
