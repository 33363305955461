api = require 'api.ls'

{deref} = require '@bitstillery/common/utils.ls'

{Lead, LeadSource, LeadStatus} = require './models.ls'

export readable_lead_source = (lead) ->
    lead_data_file = deref lead.lead_data_file

    if deref(lead.source) == LeadSource.REGISTRATION_FORM
        'Registration form'
    else if lead_data_file
        "Lead data file: #{deref(lead_data_file.file_name)} (#{deref(lead_data_file.description)})"

/**
Query a lead by artkey and set it to the provided function.
*/
export query_and_set_lead: (artkey, lead, loading) ->
    loading true
    api.call-and-then 'crm.leads.get' {artkey: artkey}, do
        success: (resp) ~>
            lead new Lead resp.result
        final: ~>
            loading false
