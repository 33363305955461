import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Button} from '@bitstillery/common/components'

interface FieldAttrs {
    label: string
}

/** Used in HorizontalForm, @see HorizontalForm. */
export class Field extends MithrilTsxComponent<FieldAttrs> {
    view(vnode: m.Vnode<FieldAttrs>): m.Children {
        return (
            <div className="field">
                <label>{vnode.attrs.label}</label>
                {vnode.children}
            </div>
        )
    }
}

/** Used in HorizontalForm, @see HorizontalForm. */
export class VerticalField extends MithrilTsxComponent<FieldAttrs> {
    view(vnode: m.Vnode<FieldAttrs>): m.Children {
        return (
            <div>
                <label>{vnode.attrs.label}</label>
                {vnode.children}
            </div>
        )
    }
}

interface FormAttrs {
    onsubmit: (() => boolean) | null
    edit_assist: EditAssist
    submit_disabled: boolean
    submit_title?: string
}

export class HorizontalForm extends MithrilTsxComponent<FormAttrs> {
    view(vnode: m.Vnode<FormAttrs>): m.Children {
        return (
            <form className={classes('flex-form', vnode.attrs.classNames)} onsubmit={(ev) => {
                ev.preventDefault()
                if (vnode.attrs.onsubmit) {
                    return vnode.attrs.onsubmit()
                }
                return false
            }
            }>
                {vnode.children}

                {vnode.attrs.onsubmit && <div className="btn-toolbar">
                    <Button
                        icon="send"
                        type="success"
                        text={
                            vnode.attrs.submit_title
                                ? vnode.attrs.submit_title
                                : vnode.attrs.edit_assist.is_creating
                                    ? 'Create'
                                    : 'Save'
                        }
                        disabled={vnode.attrs.submit_disabled}
                    />
                </div>}
            </form>
        )
    }
}

export interface Form2Attrs {
    disabled?: boolean
}

export class Form extends MithrilTsxComponent<Form2Attrs> {
    view(vnode) {
        let disabled = false
        if (vnode.attrs.disabled) {
            disabled = vnode.attrs.disabled
        } else if (vnode.attrs.validations) {
            disabled = Object.values(vnode.attrs.validations).filter((i) => i._invalid).length > 0
        }
        return (
            <div className="c-form flex-form">
                {vnode.children}
                <div className="btn-toolbar">
                    <button
                        className="btn btn-success btn-submit"
                        disabled={disabled}
                        onclick={vnode.attrs.onSubmit}
                    >
                        {vnode.attrs.submitText}
                    </button>
                </div>
            </div>
        )
    }
}

/**
 * This class assists for routes that contain an optional artkey. If the artkey is present, the form
 * is updating, if it is not present, the form is creating.
 *
 * Usage: `new EditAssist(m.route)`
 * Note: The route should optionally contain the param artkey.
 */
export class EditAssist {
    is_creating = false
    artkey: number | null = null
    reference: string | null = null

    constructor(route: m.Route, override_route_is_creating?: boolean) {
        if (override_route_is_creating) {
            this.artkey = null
            this.is_creating = true
        } else if (route.param('artkey')) {
            this.artkey = +route.param('artkey')
            this.is_creating = false
        } else if (route.param('reference')) {
            this.reference = route.param('reference')
            this.is_creating = false
        } else {
            this.artkey = null
            this.is_creating = true
        }
    }
}
