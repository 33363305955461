import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {to_specs} from '@bitstillery/common/models/item'
import {Amount} from '@bitstillery/common/components'

import {$s} from '@/app'
import {CaseLabels} from '@/components/labels'
import {GetFastSalesOrderWithItemsResponse, SalesApi, SalesOrderBasketItem} from '@/factserver_api/sales_api'

export interface SalesOrderBasketItemsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_changed: () => void
}

/**
 * Shows the sales order basket items of a sales order
 */
export class SalesOrderBasketItems extends MithrilTsxComponent<SalesOrderBasketItemsAttrs> {
    view(vnode: m.Vnode<SalesOrderBasketItemsAttrs>): m.Children {
        return (
            <span>
                {vnode.attrs.sales_order.basket_items.length > 0 && [
                    <h4>Basket items (unconfirmed)</h4>,
                    <table className={'table search-table clickable'}>
                        <thead className={'thead-default'}>
                            <tr>
                                <th>Product</th>
                                <th>Btl / cs</th>
                                <th>Specs</th>
                                <th>GB</th>
                                <th>Features</th>
                                <th>Cus.</th>
                                <th className={'number'}>Cases</th>
                                <th className={'number'}>Bottles</th>
                                <th className={'price'}>Price / cs</th>
                                <th className={'price'}>Total value</th>
                                <th>Last updated on</th>
                                <th>Weight</th>
                                <th>Dimensions (l/w/h)</th>
                            </tr>
                        </thead>
                        {/* Display the basket items.*/}
                        {vnode.attrs.sales_order.basket_items.map((basket_item) => (
                            <SalesOrderBasketItemView
                                sales_order={vnode.attrs.sales_order}
                                sales_order_basket_item={basket_item}
                                sales_order_changed={vnode.attrs.sales_order_changed}
                            />
                        ))}
                    </table>,
                ]}
            </span>
        )
    }
}

interface SalesOrderBasketItemAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_basket_item: SalesOrderBasketItem
    sales_order_changed: () => void
}

/**
 * Display a sales order basket item.
 */
class SalesOrderBasketItemView extends MithrilTsxComponent<SalesOrderBasketItemAttrs> {
    sales_api = new SalesApi()
    sales_order_item_changed: () => void
    supplier_artkey: number

    constructor(vnode: m.Vnode<SalesOrderBasketItemAttrs>) {
        super()
        this.sales_order_item_changed = vnode.attrs.sales_order_changed
        this.supplier_artkey = vnode.attrs.sales_order.supplier.artkey
    }

    view(vnode: m.Vnode<SalesOrderBasketItemAttrs>): m.Children {
        const basket_item = vnode.attrs.sales_order_basket_item
        const sales_order = vnode.attrs.sales_order
        const _case = vnode.attrs.sales_order_basket_item.offer_item.case
        return (
            <tbody className={'table-row'} key={`${basket_item.artkey}`}>
                <tr>
                    <td>
                        <span>
                            {_case.bottle.product.name}
                            <span className={'help-block'}>{_case.bottle.product.category}</span>
                        </span>
                    </td>
                    <td>{_case.number_of_bottles}</td>
                    <td>{to_specs(_case.bottle, $s.identity.user.decimal_locale)}</td>
                    <td>{_case.gift_box_type}</td>
                    <td>
                        <CaseLabels case={_case} show_as_icons={true} />
                    </td>
                    <td>{_case.customs_status}</td>
                    <td>{basket_item.number_of_cases}</td>
                    <td>{_case.number_of_bottles}</td>
                    <td className={'price'}>
                        <span>
                            <Amount
                                amount={basket_item.price_per_case}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                            {sales_order.includes_excise && (
                                <span className={'help-block'}>
                                    Duty:{' '}
                                    <Amount
                                        amount={basket_item.excise_per_case}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                </span>
                            )}
                        </span>
                    </td>
                    <td className={'price'}>
                        <Amount
                            amount={basket_item.total_value}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                    </td>
                    <td>{format_iso_to_date_time(basket_item.updated_timestamp)}</td>
                    <td>
                        <span>{_case.weight} kg</span>
                    </td>
                    <td>
                        {_case.length && _case.width && _case.height && (
                            <span>{`${_case.length} x ${_case.width} x ${_case.height}`}</span>
                        )}
                    </td>
                </tr>

                {basket_item.portal_comment && (
                    <tr>
                        <td colspan={'100%'}>
                            <label className={'col-sm-2'}>Portal comment</label>
                            <div className={'col-sm-4'}>{basket_item.portal_comment}</div>
                        </td>
                    </tr>
                )}
            </tbody>
        )
    }
}
