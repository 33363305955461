import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {OfferItems} from 'pricelist/components/items.ls'
import {Modal} from 'components/modal/modal.ls'
import {AddToSalesOrder} from 'offer/components/add_to_sales_order.ls'
import {type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {displayable_float} from '@bitstillery/common/ts_utils'
import {Amount, CellAvailability, Tippy} from '@bitstillery/common/components'

import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {Link} from '@/components/discover'
import {CaseLabels} from '@/components/labels'
import {OfferPrice} from '@/offer/components/offer_price'
import {TabViewControl} from '@/offer/relation_dashboard/relation_dashboard'
import {DefaultButton} from '@/components/buttons'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {MarginPercentage} from '@/components/html_components'
import {RelationDashboardData, RelationDashboardFilter} from '@/offer/relation_dashboard/relation_dashboard_model'
import {GetPriceListCollectionViewResponseOutput} from '@/factserver_api/fact2server_api'

interface RelationOfferItemsAttrs {
    register_tab_view_control: (tab_view: TabViewControl) => void
    relation_dashboard_data: RelationDashboardData
    relation_dashboard_filters: RelationDashboardFilter
    relation_exchange_rate: number
}

interface RelationOfferRow extends GetPriceListCollectionViewResponseOutput {
    is_showing_details: boolean
}

export class RelationOfferItems extends MithrilTsxComponent<RelationOfferItemsAttrs> implements TabViewControl {
    offer_items_fetcher: PagedCollectionFetcherWithGET<GetPriceListCollectionViewResponseOutput>
    show_add_to_sales_order = false
    add_to_sales_order_row: RelationOfferRow | null = null
    watches: type_remove_watch_function[] = []

    constructor(vnode: m.Vnode<RelationOfferItemsAttrs>) {
        super()
        vnode.attrs.register_tab_view_control(this)
        this.offer_items_fetcher = new PagedCollectionFetcherWithGET<GetPriceListCollectionViewResponseOutput>(
            `discover/relations/${vnode.attrs.relation_dashboard_data.selected_relation?.artkey}/price-list/collection-view`,
            'product_name',
        )
    }

    oncreate(vnode: m.Vnode<RelationOfferItemsAttrs>) {
        if (!vnode.attrs.relation_dashboard_data.selected_relation) {
            return
        }

        this.offer_items_fetcher.filters.supplier_artkey = vnode.attrs.relation_dashboard_data.selected_relation.artkey
        this.offer_items_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin
        this.offer_items_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
        this.offer_items_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys

        this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
        this.update_only_show_type(vnode.attrs.relation_dashboard_filters)

        this.watches = [
            watch(
                vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys,
                () => {
                    this.offer_items_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't1_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't2_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_stock',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_tbo',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_purchase',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'product_name',
                () => {
                    this.offer_items_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'customs_status',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'gtin',
                () => {
                    this.offer_items_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin
                    this.offer_items_fetcher.reset_and_query()
                },
            ),
        ]
    }

    onremove(): any {
        this.watches.forEach((unsubscribe) => unsubscribe())
    }

    update_only_show_type(filter: RelationDashboardFilter) {
        const show_types: string[] = []
        if (filter.show_tbo) {
            show_types.push('tbo')
        }
        if (filter.show_purchase) {
            show_types.push('purchase')
        }
        if (filter.show_stock) {
            show_types.push('stock')
        }
        this.offer_items_fetcher.filters.only_show_type = show_types
    }

    update_customs_status_filter(filter: RelationDashboardFilter) {
        if (filter.t1_filter !== filter.t2_filter) {
            if (filter.t1_filter) {
                this.offer_items_fetcher.filters.customs_status = 'T1'
            } else {
                this.offer_items_fetcher.filters.customs_status = 'T2'
            }
        } else {
            this.offer_items_fetcher.filters.customs_status = ''
        }
    }

    margin_calculation(list_price: number, excise: number, was_bought_for: number): string {
        const margin_percentage = (list_price - excise - was_bought_for) / was_bought_for
        return `
<pre>
Price:       ${displayable_float(list_price)}
Excise:      -/- ${displayable_float(excise)}
Bought for:  -/- ${displayable_float(was_bought_for)}
             -----------
Margin perc: ${displayable_float(margin_percentage * 100)}%
</pre>
`
    }

    is_loading(): boolean {
        return this.offer_items_fetcher.is_fetching
    }

    view(vnode: m.Vnode<RelationOfferItemsAttrs>): m.Vnode {
        return (
            <span>
                {this.show_add_to_sales_order && this.add_to_sales_order_row && (
                    <Modal title={'Add to sales order'} onclose={() => (this.show_add_to_sales_order = false)}>
                        <AddToSalesOrder
                            supplier_artkey={vnode.attrs.relation_dashboard_data.selected_relation.artkey}
                            supplier_currency={vnode.attrs.relation_dashboard_data.selected_relation.currency}
                            on_added_item={() => (this.show_add_to_sales_order = false)}
                            offer_item={{
                                artkey: this.add_to_sales_order_row.artkey,
                                minimum_quantity: this.add_to_sales_order_row.minimum_quantity,
                                maximum_quantity: this.add_to_sales_order_row.maximum_quantity,
                                offer_item_type: this.add_to_sales_order_row.offer_item_type,
                                case_artkey: this.add_to_sales_order_row.case_artkey,
                                product_name: this.add_to_sales_order_row.product_name,
                                avg_purchase_price: this.add_to_sales_order_row.avg_purchase_price,
                                supplier_list_price: this.add_to_sales_order_row.supplier_list_price,
                                list_price: this.add_to_sales_order_row.list_price,
                                price_per_case: this.add_to_sales_order_row.price_per_case,
                            }}
                        />
                    </Modal>
                )}
                <CollectionTable<RelationOfferRow, void>
                    collection_fetcher={this.offer_items_fetcher}
                    on_row_click_component={RelationOfferItemDetails}
                    on_row_click={(row: RelationOfferRow) => (row.is_showing_details = !row.is_showing_details)}
                >

                    <CollectionTableColumn
                        header_title={() => 'Product'}
                        sort_name={'product_name'}
                        data_field={(row) => {
                            return <div className="td-group">
                                <span className="header">
                                    {row.product_name}
                                </span>
                                <span>{to_specs(row)}</span>
                                {row.case_article_code && <span className="details">{row.case_article_code}</span>}
                                {row.bottle_gtins && <span className="details">
                                    {row.bottle_gtins.split(';').map((bottle_gtin_code) => <div>{bottle_gtin_code}</div>)}
                                </span>}
                            </div>}
                        }
                    />

                    <CollectionTableColumn<GetPriceListCollectionViewResponseOutput>
                        header_title={() => 'Lots'}
                        sort_name={'bottle_gtin_codes'}
                        data_field={(row: GetPriceListCollectionViewResponseOutput) =>
                            row.lots &&
                            row.lots.split(';').map((lot) => <div>
                                <Link href={`/stock/manage?q=${lot}`}>{lot}</Link>
                            </div>)
                        }
                    />
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => <Tippy content={'Age of the stock in days'}>Stock age</Tippy>}
                        sort_name={'stock_age'}
                        data_field={(row: RelationOfferRow) => row.stock_age}
                    />

                    <CollectionTableColumn<GetPriceListCollectionViewResponseOutput>
                        header_title={() => 'TBO supplier'}
                        sort_name={'supplier_name'}
                        data_field={(row: GetPriceListCollectionViewResponseOutput) => (
                            <span>
                                {row.tbo_supplier_artkey && (
                                    <Link href={`crm/relations/${row.tbo_supplier_artkey}/edit`}>{row.tbo_supplier_name}</Link>
                                )}
                                {' - '}
                                {row.originating_spl_artkey && <Link href={`market/pricelists/${row.originating_spl_artkey}`}>{row.originating_spl_name}</Link>}
                            </span>
                        )}
                    />

                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => 'Features'}
                        data_field={(row: RelationOfferRow) => (
                            <CaseLabels
                                case={{
                                    best_before_date: row.case_best_before_date,
                                    serialized_item_tags: row.case_serialized_item_tags,
                                    tax_label: row.case_tax_label,
                                    remark: row.remark,
                                    cases_per_pallet: row.cases_per_pallet,
                                    gift_box_type: row.case_gift_box_type,
                                }}
                                show_as_icons={true}
                            />
                        )}
                    />
                    <CollectionTableColumn<GetPriceListCollectionViewResponseOutput>
                        header_title={() => 'Stock value'}
                        data_field={(row: GetPriceListCollectionViewResponseOutput) => (
                            <Amount amount={+row.euro_total_stock_value} currency={'EUR'} />
                        )}
                    />
                    CellAvailability
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => (
                            <Tippy content={'<div>Number of cases in stock</div>'} allowHTML={true}>
                                <span className={'glyphicon glyphicon-home'} />
                            </Tippy>
                        )}
                        data_field={(row: RelationOfferRow) => <CellAvailability row={row} />}
                    />

                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => 'Pricing / cs'}
                        data_field={(row: RelationOfferRow) => (
                            [
                                <OfferPrice
                                    list_price={row.list_price}
                                    original_price_per_case={row.original_price_per_case}
                                    offer_type={row.offer_type}
                                    includes_excise_in_price={row.includes_excise_in_price}
                                    excise_per_case={row.excise_per_case}
                                    currency={row.currency}
                                />,
                                <div className={'help-block'}>
                                    {'Avg Purchase: '}
                                    <Amount amount={row.avg_was_bought_for} currency={'EUR'} />
                                </div>,
                            ]
                        )}
                    />
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => 'Margin %'}
                        data_field={(row: RelationOfferRow) => {
                            const excise = row.includes_excise_in_price ? +(row.excise_per_case || 0) : 0
                            const was_bought_for = +(row.avg_was_bought_for || 0)
                            const list_price = +row.list_price
                            return (
                                <Tippy
                                    allowHTML={true}
                                    content={this.margin_calculation(list_price, excise, was_bought_for)}>
                                    <MarginPercentage
                                        value={
                                            ((list_price - excise - was_bought_for) / was_bought_for)
                                        }
                                    />
                                </Tippy>
                            )
                        }}
                    />
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => 'Delivery period'}
                        data_field={(row: RelationOfferRow) => row.delivery_period || '-'}
                    />
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => 'Photos'}
                        data_field={(row: RelationOfferRow) => (
                            <span>{row.has_product_photos && <span className={'fas fa-image'} />}</span>
                        )}
                    />
                    <CollectionTableColumn<RelationOfferRow>
                        header_title={() => ''}
                        data_field={(row: RelationOfferRow) => (
                            <DefaultButton
                                icon_class={'glyphicon glyphicon-screenshot'}
                                onclick={() => {
                                    this.show_add_to_sales_order = true
                                    this.add_to_sales_order_row = row
                                }}
                            />
                        )}
                    />
                </CollectionTable>
            </span>
        )
    }
}

class RelationOfferItemDetails extends MithrilTsxComponent<
    CollectionTableRowComponentProps<RelationOfferRow, unknown>
> {
    view(vnode: m.Vnode<CollectionTableRowComponentProps<RelationOfferRow, unknown>>): m.Children | null {
        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }
        const row = vnode.attrs.row
        return (
            <tr className={'well'}>
                <td colspan={'100%'}>
                    <div className={'row'}>
                        <div className={'col-xs-6'}>
                            <OfferItems offer_item_artkey={row.artkey} />
                        </div>
                        <div className={'col-xs-6'}>
                            <OfferHistory
                                bottle_artkey={row.bottle_artkey}
                                customs_status={row.case_customs_status}
                                supplier_artkey={row.relation_artkey}
                            />
                        </div>
                    </div>
                    <CaseInfoPanel
                        bottle_artkey={row.bottle_artkey}
                        case_artkey={row.case_artkey}
                        case_customs_status={row.case_customs_status}
                    />
                </td>
            </tr>
        )
    }
}
