m = require 'mithril'
{join, min} = require 'prelude-ls'

{should_include_excise} = require 'excise.ls'
{
    capitalize, format-date, pluralize
} = require 'utils.ls'
{Amount, SubAmount} = require '@bitstillery/common/components'
{account-icon-by-slug} = require 'accounts.ls'
{button-with-icon, icon-button, text-button} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
{blank-link} = require '@/components/link.ls'
{Modal} = require '@/components/modal/modal.ls'
{pricelist_link} = require 'data/components/pricelist_link.ls'
{AddToSalesOrder} = require 'offer/components/add_to_sales_order.ls'
{CaseInfoPanel} = require '@/components/market_info/case_info_panel'
{offer-price} = require 'offer/components/offer-price.ls'
{PurchaseOrderStatus} = require '@/models/purchase_orders'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
app = require('@/app')

class ItemSearchMode
    @OFFER = 'offer'
    @PURCHASE = 'purchase'
    @MARKET = 'market'


BASE_COLLECTION_OPTIONS = do
    query_limit: 8
    filter_serverside: true
    paged: true
    dont-reuse: true
    only_query_on_search: true


export class AddItemToSalesOrder
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @sales_order_item_added = vnode.attrs.sales_order_item_added

        @record_to_add = null
        @offset = window.prop 0
        @search_mode = ItemSearchMode.OFFER

        @offer_search_mode!
        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @items.update_search_term text
        @items.submit_search!

    oncreate: ~>
        @init!

    init: ~>
        @items.init!

    offer_search_mode: ~>
        @search_mode = ItemSearchMode.OFFER
        search_term = if @items then @items.search_term! else null

        @items = new Collection(Object.assign {}, BASE_COLLECTION_OPTIONS, do
            api_function_name: 'pricelist.get_offer_item_records'
            sort_order: [
                {'name': 'case_number_of_bottles', 'direction': 'asc'}
                {'name': 'bottle_volume', 'direction': 'asc'}
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'}
                {'name': 'bottle_refill_status', 'direction': 'desc'}
            ]
            default_sort_by: 'stock_age'
            default_sort_order: 'desc'
            additional_params: window.prop do
                supplier_artkey: @sales_order!supplier!artkey!
                only_show_type: ['purchase', 'stock', 'tbo']
                hidden_selection: 'visible_only'
                customs_status_selection: @sales_order!supplier!portal_customs_visibility!
        )

        @items.init!

    purchase_search_mode: ~>
        @search_mode = ItemSearchMode.PURCHASE
        search_term = if @items then @items.search_term! else null

        @items = new Collection(Object.assign {}, BASE_COLLECTION_OPTIONS, do
            api_function_name: 'purchase.core.search_purchase_order_items'
            sort_order: [
                {'name': 'number_of_cases_available', 'direction': 'desc'},
                {'name': 'purchase_order_date', 'direction': 'desc'},
                {'name': 'purchase_order_reference', 'direction': 'desc'},
                {'name': 'product_name', 'direction': 'asc'},
                {'name': 'bottle_volume', 'direction': 'asc'},
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'},
                {'name': 'bottle_refill_status', 'direction': 'desc'},
            ]
            default_sort_by: 'number_of_cases_available'
            default_sort_order: 'desc'
            additional_params: window.prop do
                status: [PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED, PurchaseOrderStatus.READY_FOR_FIS, PurchaseOrderStatus.STOCKED_AND_BOOKED]
                customs_status_selection: @sales_order!supplier!portal_customs_visibility!
        )

        @items.update_search_term search_term
        @init!

    market_search_mode: ~>
        @search_mode = ItemSearchMode.MARKET
        search_term = if @items then @items.search_term! else null

        @items = new Collection(Object.assign {}, BASE_COLLECTION_OPTIONS, do
            api_function_name: 'marketanalysis.get_supplier_price_list_items'
            sort_order: [
                {'name': 'artkey', 'direction': 'asc'}
            ]
            default_sort_by: 'score'
            default_sort_order: 'asc'
            additional_params: window.prop do
                customs_status_selection: @sales_order!supplier!portal_customs_visibility!
        )

        @items.update_search_term search_term
        @init!

    on_item_add: ~>
        @record_to_add = null
        app.$m.common.observable.broadcast 'sales_order_updated_'+@sales_order!artkey!
        $('input#search_add').focus!
        @items.update_search_term ''
        if @sales_order_item_added
            @sales_order_item_added!
        @init!

    color_class: (value) ~>
        if value > 8.8
            '.analysis-good-color'
        else if value < 0
            '.analysis-bad-color'
        else
            ''

    view: -> m '.mwrap',
        button-with-icon 'Add product to order', 'plus', do
            class: 'btn btn-default mb-2'
            'data-target': '#add_item'
            'data-toggle': 'collapse'

        if @record_to_add then
            if @search_mode == ItemSearchMode.MARKET
                product_name = @record_to_add.bottle.product.name
            else
                product_name = @record_to_add.product_name
            modal-attrs = do
                title: "Add #{product_name} to sales order"
                onclose: ~> @record_to_add = null

            m Modal, modal-attrs,
                if @search_mode == ItemSearchMode.OFFER
                    m AddToSalesOrder, do
                        offer_item: @record_to_add
                        on_added_item: @on_item_add
                        supplier_artkey: @sales_order!supplier!artkey!
                        supplier_currency: @sales_order!supplier!currency!
                        sales_order_artkey: @sales_order!artkey!
                        sales_order_currency: @sales_order!was_sold_in!
                        key: @record_to_add.artkey
                else if @search_mode == ItemSearchMode.PURCHASE
                    m AddToSalesOrder, do
                        purchase_order_item: @record_to_add
                        on_added_item: @on_item_add
                        supplier_artkey: @sales_order!supplier!artkey!
                        supplier_currency: @sales_order!supplier!currency!
                        sales_order_artkey: @sales_order!artkey!
                        sales_order_currency: @sales_order!was_sold_in!
                        key: @record_to_add.artkey
                else if @search_mode == ItemSearchMode.MARKET
                    m AddToSalesOrder, do
                        spli: @record_to_add
                        on_added_item: @on_item_add
                        supplier_artkey: @sales_order!supplier!artkey!
                        supplier_currency: @sales_order!supplier!currency!
                        sales_order_artkey: @sales_order!artkey!
                        sales_order_currency: @sales_order!was_sold_in!
                        key: @record_to_add.artkey

        m '.collapse#add_item' m '.panel.panel-default',
            m '.panel-heading' m '.panel-title' 'Find products to add'
            m '.panel-body',
                m '.c-filter-group' {key: @search_mode},
                    m SearchBar,
                        placeholder: "Search for products, specs, TBO supplier, tax label or other item tags..."
                        on_submit: @submit_search
                        default_search_text: @items.search_term!
                        search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                        on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

                    m '.btn-group',
                        text-button 'Offer items',
                            if @search_mode == ItemSearchMode.OFFER
                                class: 'btn-primary'
                            else
                                class: 'btn-default'
                                onclick: ~>
                                    @offer_search_mode!
                        text-button 'Stock and Purchase',
                            if @search_mode == ItemSearchMode.PURCHASE
                                class: 'btn-primary'
                            else
                                class: 'btn-default'
                                onclick: ~>
                                    @purchase_search_mode!
                        text-button 'Market',
                            if @search_mode == ItemSearchMode.MARKET
                                class: 'btn-primary'
                            else
                                class: 'btn-default'
                                onclick: ~>
                                    @market_search_mode!

                if @search_mode == ItemSearchMode.OFFER
                    m CollectionTable, do
                        key: @search_mode
                        collection: @items
                        options:
                            search_table_style: false
                            sticky_header: false
                            with_buttons: true
                            autoscale: true
                            unique_name: 'offer_item_list'
                            row_classes: (record) ->
                                if record.is_hidden then ['offer-item__is-hidden', 'clickable'] else ['clickable']
                        view_details: (record) ->
                            m CaseInfoPanel, do
                                bottle_artkey: record.bottle_artkey
                        columns:
                            do
                                width: 4
                                name: 'Article code'
                                field: 'case_article_code'
                                sort: true
                            do
                                width: 12
                                name: 'Product'
                                sort: true
                                field: 'product_name'
                                ellipsis: true
                            do
                                width: 5
                                name: 'Category'
                                sort: true
                                field: 'product_category'
                                ellipsis: true
                                transform: capitalize
                            do
                                width: 5
                                name: 'Supplier'
                                sort: true
                                field: 'supplier_name'
                                ellipsis: true
                            do
                                width: 10
                                name: 'Specs'
                                function: (record) ->
                                    specs = []
                                    specs.push record.case_number_of_bottles
                                    specs.push (+record.bottle_volume).toFixed(1)
                                    specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                                    specs.push record.bottle_refill_status
                                    join ' / ' specs
                            do
                                width: 8
                                name: 'Features'
                                function: (record) ->
                                    features = []
                                    if record.case_gift_box_type
                                        features.push record.case_gift_box_type
                                    if record.case_tax_label
                                        features.push record.case_tax_label
                                    if record.item_best_before_date
                                        features.push "BBD: #{format-date record.item_best_before_date}"
                                    if record.item_damages
                                        features.push record.item_damages.replace(', ', ' / ')
                                    if record.item_general_tags
                                        features.push record.item_general_tags.replace(', ', ' / ')
                                    if record.item_pack_size
                                        features.push record.item_pack_size
                                    if record.item_packaging
                                        features.push record.item_packaging
                                    join ' / ' features
                            do
                                width: 3
                                header: 'Cus.'
                                name: 'Customs status'
                                sort: true
                                field: 'case_customs_status'
                            do
                                width: 1
                                header: icon-with-popover do
                                    icon-id: 'calendar'
                                    content: 'Stock age in days'
                                name: 'Stock age in days'
                                sort: true
                                field: 'stock_age'
                            do
                                width: 6
                                name: 'Stock value'
                                sort: true
                                field: 'euro_total_stock_value'
                                classes: ['price']
                                function: (record) ->
                                    if record.offer_item_type != 'tbo'
                                        m Amount, do
                                            amount: +record.euro_total_stock_value
                                            currency: app.$s.currencies.default
                                    else
                                        '-'
                            do
                                width: 2
                                name: 'Number of cases in stock'
                                header: icon-with-popover do
                                    icon-id: 'home'
                                    content: 'Number of cases in stock'
                                sort: true
                                field: 'number_of_cases_in_stock'
                                classes: ['number']
                            do
                                width: 2
                                name: 'Number of cases in purchase'
                                header: icon-with-popover do
                                    icon-id: 'shopping-cart'
                                    content: 'Number of cases in purchase'
                                sort: true
                                field: 'number_of_cases_in_purchase'
                                classes: ['number']
                            do
                                width: 2
                                name: 'Number of cases in sales'
                                header: icon-with-popover do
                                    icon-id: 'screenshot'
                                    content: 'Number of cases in sales'
                                sort: true
                                field: 'number_of_cases_in_sales'
                                classes: ['number']
                            do
                                width: 2
                                name: 'Number of cases available'
                                header: icon-with-popover do
                                    icon-id: 'fa-shield-alt'
                                    content: 'Number of cases available'
                                sort: true
                                field: 'number_of_cases_available'
                                classes: ['number']
                            do
                                width: 7
                                header: 'Max Qty'
                                name: 'Maximum Quantity'
                                field: 'maximum_quantity'
                                classes: ['number']
                            do
                                width: 7
                                header: 'MOQ'
                                name: 'Minimum Order Quantity'
                                field: 'minimum_quantity'
                                classes: ['number']
                            do
                                width: 5
                                header: 'List Qty'
                                name: 'List Quantity'
                                field: 'list_quantity'
                                classes: ['number']
                                function: (record) ~>
                                    if record.maximum_quantity?  # Use ? so 0 is also considered.
                                        min record.maximum_quantity, record.number_of_cases_available
                                    else
                                        record.number_of_cases_available
                            do
                                width: 11
                                name: 'Avg purchase / cs'
                                sort: true
                                field: 'avg_purchase_price'
                                classes: ['price']
                                function: (record) ~>
                                    if record.avg_purchase_price then
                                        if should_include_excise! then [
                                            m Amount, do
                                                amount: +record.avg_purchase_price + +record.case_excise_nl
                                                currency: app.$s.currencies.default

                                            m SubAmount, do
                                                label: 'Ex Duty'
                                                amount: record.avg_purchase_price
                                                currency: app.$s.currencies.default
                                        ]
                                        else
                                            m Amount, do
                                                amount: record.avg_purchase_price
                                                currency: app.$s.currencies.default
                                    else
                                        '-'
                            do
                                width: 12
                                name: 'Base sales / cs'
                                sort: true
                                classes: ['price']
                                field: 'list_price'
                                function: (record) ->
                                    offer-price(record)
                            do
                                width: 12
                                name: 'Relation sales / cs'
                                sort: true
                                field: 'supplier_list_price'
                                classes: ['price']
                                function: (record) ->
                                    m Amount, do
                                        amount: +record.supplier_list_price
                                        currency: app.$s.currencies.default
                            do
                                width: 6
                                name: 'Margin %'
                                sort: true
                                field: 'margin_percentage'
                                classes: ['price']
                                function: (record) ~>
                                    if record.margin_percentage
                                        margin_percentage = +record.margin_percentage
                                        m "span.#{@color_class margin_percentage}" "#{margin_percentage.toFixed(2)}%"
                                    else
                                        '-'
                            do
                                width: 4
                                name: 'Delivery period'
                                sort: true
                                field: 'delivery_period'
                                classes: ['number']
                                transform: (delivery_period) ->
                                    if delivery_period? then
                                        delivery_period + ' ' + pluralize delivery_period, 'week', 'weeks'
                                    else
                                        '-'
                            do
                                width: 3
                                name: 'Item type'
                                field: 'offer_item_type'
                                transform: (type) ~>
                                    | type == 'tbo' => 'TBO'
                                    | otherwise => type.capitalizeFirstLetter!
                            do
                                width: 16
                                header: ''
                                name: 'Actions'
                                function: (record) ~>
                                    m '.btn-toolbar.no-click' m '.btn-group',
                                        icon-button 'screenshot', do
                                            class: 'btn-default'
                                            onclick: ~> @record_to_add = record
                else if @search_mode == ItemSearchMode.PURCHASE
                    m CollectionTable, do
                        key: @search_mode
                        collection: @items
                        options:
                            search_table_style: false
                            sticky_header: false
                            with_buttons: true
                            autoscale: true
                            unique_name: 'offer_item_list'
                            row_classes: (record) ->
                                ['clickable']
                        view_details: (record) ->
                            m CaseInfoPanel, do
                                bottle_artkey: record.bottle_artkey
                        columns:
                            do
                                width: 4
                                name: 'Article code'
                                field: 'case_article_code'
                                sort: true
                            do
                                width: 8
                                name: 'Product'
                                sort: true
                                field: 'product_name'
                                ellipsis: true
                            do
                                width: 5
                                name: 'Category'
                                sort: true
                                field: 'product_category'
                                ellipsis: true
                                transform: capitalize
                            do
                                width: 7
                                name: 'Supplier'
                                sort: true
                                field: 'supplier_name'
                                ellipsis: true
                            do
                                width: 5
                                name: 'P.O. #'
                                sort: true
                                field: 'purchase_order_reference'
                                ellipsis: true
                                transform: (purchase_order_reference) ->
                                    m m.route.Link, {target: '_blank', \
                                        href: "/purchase-orders/manage/#{purchase_order_reference}"},
                                        purchase_order_reference
                            do
                                width: 10
                                name: 'Specs'
                                function: (record) ->
                                    specs = []
                                    specs.push record.case_number_of_bottles
                                    specs.push (+record.bottle_volume).toFixed(1)
                                    specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                                    specs.push record.bottle_refill_status
                                    join ' / ' specs
                            do
                                width: 8
                                name: 'Features'
                                function: (record) ->
                                    features = []
                                    if record.case_gift_box_type
                                        features.push record.case_gift_box_type
                                    if record.case_tax_label
                                        features.push record.case_tax_label
                                    if record.cases_per_pallet
                                        features.push "CPP: #{record.cases_per_pallet}"
                                    if record.item_best_before_date
                                        features.push "BBD: #{format-date record.item_best_before_date}"
                                    if record.item_damages
                                        features.push record.item_damages.replace(', ', ' / ')
                                    if record.item_general_tags
                                        features.push record.item_general_tags.replace(', ', ' / ')
                                    if record.item_pack_size
                                        features.push record.item_pack_size
                                    if record.item_packaging
                                        features.push record.item_packaging
                                    join ' / ' features
                            do
                                width: 3
                                header: 'Cus.'
                                name: 'Customs status'
                                sort: true
                                field: 'case_customs_status'
                            do
                                width: 2
                                name: 'Number of cases in purchase'
                                header: icon-with-popover do
                                    icon-id: 'shopping-cart'
                                    content: 'Number of cases in purchase'
                                sort: true
                                field: 'number_of_cases'
                                classes: ['number']
                            do
                                width: 2
                                name: 'Number of cases available'
                                header: icon-with-popover do
                                    icon-id: 'fa-shield-alt'
                                    content: 'Number of cases available'
                                sort: true
                                field: 'number_of_cases_available'
                                classes: ['number']
                            do
                                width: 9
                                name: 'Purchase / cs'
                                sort: true
                                field: 'was_bought_for'
                                classes: ['price']
                                function: (record) ~>
                                    m Amount, do
                                        amount: +record.was_bought_for
                                        currency: record.was_bought_in
                            do
                                width: 9
                                name: 'Sales / cs'
                                sort: true
                                field: 'suggested_price_per_case'
                                classes: ['price']
                                function: (record) ~>
                                    m Amount, do
                                        amount: +record.suggested_price_per_case
                                        currency: @sales_order!supplier!currency!
                                        rate: app.$s.currencies.exchange_rates[@sales_order!supplier!currency!].portal_rate
                            do
                                width: 6
                                name: 'Margin %'
                                sort: true
                                field: 'margin_percentage'
                                classes: ['price']
                                function: (record) ~>
                                    if record.suggested_price_per_case
                                        margin = +record.suggested_price_per_case - +record.was_bought_for
                                        margin_percentage = margin / +record.was_bought_for * 100
                                        m "span.#{@color_class margin_percentage}" "#{margin_percentage.toFixed(2)}%"
                                    else
                                        '-'
                            do
                                width: 6
                                name: 'ETA'
                                sort: true
                                field: 'purchase_order_expected_delivery_date'
                                transform: format-date
                            do
                                width: 4
                                name: 'Purchase status'
                                field: 'purchase_order_status'
                                function: (record) ~>
                                    m m.route.Link, do
                                        href: "/purchase-orders/manage/#{record.purchase_order_reference}?account=#{record.purchase_order_account_slug}"
                                        target: '_blank'
                                    , [
                                        account-icon-by-slug record.purchase_order_account_slug, ''
                                        ' '
                                        record.purchase_order_status
                                    ]
                            do
                                width: 16
                                header: ''
                                name: 'Actions'
                                function: (record) ~>
                                    m '.btn-toolbar.no-click' m '.btn-group',
                                        icon-button 'screenshot', do
                                            class: 'btn-default'
                                            onclick: ~> @record_to_add = record
                else if @search_mode == ItemSearchMode.MARKET
                    m CollectionTable, do
                        key: @search_mode
                        collection: @items
                        options:
                            search_table_style: false
                            sticky_header: false
                            with_buttons: true
                            autoscale: true
                            unique_name: 'offer_item_list'
                            row_classes: (record) ->
                                ['clickable']
                        view_details: (record) ->
                            m CaseInfoPanel, do
                                bottle_artkey: record.bottle.artkey
                        columns:
                            do
                                width: 3
                                sort: true
                                field: 'score'
                                name: 'Rank'
                                function: ((spli) -> spli.rank + ' / ' + spli.total)
                            do
                                width: 12
                                name: 'Product'
                                sort: true
                                field: 'bottle.product.name'
                                ellipsis: true
                            do
                                width: 6
                                sort: true
                                field: 'bottle.product.category'
                                name: 'Category'
                                ellipsis: true
                                transform: capitalize
                            do
                                width: 14
                                name: 'Supplier'
                                sort: true
                                field: 'supplier_price_list.supplier'
                                ellipsis: true
                                function: (record) ->
                                    m '' [
                                        blank-link pricelist_link.from_bottle_json(record.supplier_price_list.artkey, record.bottle), icon 'new-window'
                                        ' '
                                        record.supplier_price_list.supplier.name
                                    ]
                            do
                                width: 10
                                name: 'Incoterm'
                                field: 'incoterm'
                                ellipsis: true
                            do
                                width: 10
                                name: 'Specs'
                                function: (record) ->
                                    specs = []
                                    specs.push record.number_of_bottles_per_case || '-'
                                    specs.push (+record.bottle.volume).toFixed(1)
                                    specs.push (+record.bottle.alcohol_percentage).toFixed(1)
                                    specs.push record.bottle.refill_status
                                    join ' / ' specs
                            do
                                width: 6
                                name: 'GB'
                                function: (record) ->
                                    features = []
                                    if record.case_gift_box_type
                                        features.push record.gift_box_type
                                    join ' / ' features
                            do
                                width: 10
                                name: 'Aux.'
                                field: 'aux_info'
                            do
                                width: 3
                                header: 'Cus.'
                                name: 'Customs status'
                                sort: true
                                field: 'customs_status'
                            do
                                width: 2
                                name: 'Number of bottles available'
                                header: icon-with-popover do
                                    icon-id: 'fa-wine-bottle'
                                    content: 'Number of bottles available'
                                sort: true
                                field: 'number_of_cases'
                                classes: ['number']
                            do
                                width: 2
                                name: 'Number of cases available'
                                header: icon-with-popover do
                                    icon-id: 'fa-shield-alt'
                                    content: 'Number of cases available'
                                sort: true
                                field: 'number_of_cases'
                                classes: ['number']
                            do
                                width: 6
                                name: 'Price / btl'
                                sort: true
                                field: 'price_per_bottle'
                                classes: ['price']
                                function: (record) ~>
                                    m Amount, do
                                        amount: +record.price_per_bottle
                                        currency: record.currency
                            do
                                width: 6
                                name: 'Price / cs'
                                sort: true
                                field: 'price_per_case'
                                classes: ['price']
                                function: (record) ~>
                                    if record.price_per_case
                                        m Amount, do
                                            amount: +record.price_per_case
                                            currency: record.currency
                                    else
                                        '-'
                            do
                                width: 6
                                name: 'Availability'
                                sort: true
                                field: 'availability_date'
                                transform: format-date
                            do
                                width: 12
                                name: 'Avail. status'
                                sort: true
                                field: 'availability_status'
                            do
                                width: 16
                                header: ''
                                name: 'Actions'
                                function: (record) ~>
                                    m '.btn-toolbar.no-click' m '.btn-group',
                                        icon-button 'screenshot', do
                                            class: 'btn-default'
                                            onclick: ~> @record_to_add = record
