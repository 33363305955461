{map, join} = require 'prelude-ls'
{a}: utils = require 'utils.ls'
{is-function, deref} = require '@bitstillery/common/utils.ls'


export pager = (args) ->
    count = +args.count!
    page_size = +(deref args.page_size)
    selected = 1 + (deref args.offset) / page_size
    pages = Math.ceil count / page_size
    loading = args.loading or window.prop false

    goto = (page) ->
        offset = (page - 1) * page_size
        if (deref args.offset) != offset
            if is-function args.offset
                args.offset offset
            else
                args.offset = offset

            if args.onpagechange
                args.onpagechange page

            base_url = window.location.href
            query_string = ""
            # Check if there is a '?' behind the '#', then we have a query string.
            if base_url.indexOf('?', base_url.indexOf('#')) >= 0
                url_parts = base_url.split('?')
                # Parse out the query string part (behind the last '?').
                query_string = url_parts[url_parts.length - 1]
                # Get the URL part before the last '?'.
                base_url = url_parts.slice(0, -1).join('?')

            # Overwrite (or set) the page query parameter.
            params = new URLSearchParams(query_string)
            params.set('page', page)

            # Append the parameters back to the URL.
            window.history.pushState(null, null, "#{base_url}?#{params.toString!}");


    # Show at most 5 page links centered around the currently selected page.
    max_links = 5
    half_links = Math.floor max_links / 2
    page_links =
        if pages <= max_links
            [1 to pages]
        else if selected <= half_links
            [1 to max_links]
        else if selected >= pages - half_links
            [pages - max_links to pages]
        else
            [selected - half_links to selected + half_links]

    classes = ['pagination']
    element = if args.small then 'small' else 'span'

    m ".c-pager",
        if loading!
            ""
        else if args.count! == 0
            'No results'
        else if args.count! > 0 then [
            if pages > 1 then [
                m 'nav' {'aria-label': 'Page navigation'},
                    m 'ul' {class: join ' ' classes} a do
                        m 'li' m 'a' do
                            onclick: ~> goto 1
                        , m 'span' {'aria-hidden': 'true'} m.trust '&laquo;'

                        m 'li' {class: if selected <= 1 then 'disabled'} m 'a' do
                            'aria-label': 'Previous'
                            onclick: ~> if selected > 1 then goto (selected - 1)
                        , m 'span' {'aria-hidden': 'true'} m.trust '&lsaquo;'

                        page_links |> map (page) ->
                            clazz = if page === selected then 'active'
                            m 'li' {class: clazz} m 'a' do
                                onclick: ~> goto page
                            , page

                        m 'li' {class: if selected >= pages then 'disabled'} m 'a' do
                            'aria-label': 'Next'
                            onclick: ~> if selected < pages then goto (selected + 1)
                        , m 'span' {'aria-hidden': 'true'} m.trust '&rsaquo;'

                        m 'li' m 'a' do
                            onclick: ~> goto pages
                        , m 'span' {'aria-hidden': 'true'} m.trust '&raquo;'
            ]

            m '.result-count' "#{count} result#{if count > 1 then 's' else ''}"
        ]
