import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DataCard, DataCardModel} from '@bitstillery/common/components'
import {Spinner} from '@bitstillery/common/components'

import {Panel} from '@/components/panel.tsx'
import {OfferHistoryItem} from '@/factserver_api/offer_api.ts'
import {OfferHistoryTable} from '@/components/market_info/offer_history.tsx'
import {Pager} from '@/components/pager/pager.tsx'

interface ShowOfferHistoryAttrs {
    offer_history_items: Array<OfferHistoryItem>
    loading: boolean
    description?: string
    limit?: number
    count?: number
    fetch_page: (page_index: number) => void
    collapsible?: boolean
    collapsed?: boolean
    header?: string
}

export class ShowOfferHistory extends MithrilTsxComponent<ShowOfferHistoryAttrs> {
    data_card_model: DataCardModel = {
        data: [{label: 'Offers for', value: '-'}],
    }

    onupdate(vn: m.Vnode<ShowOfferHistoryAttrs>) {
        this.data_card_model.data[0]['value'] = vn.attrs.header
    }

    view(vn: m.Vnode<ShowOfferHistoryAttrs>) {
        let toolbar: JSX.Element | undefined

        if (vn.attrs.count) {
            toolbar = <Pager
                fetch_page={(page_index: number) => {vn.attrs.fetch_page(page_index)}}
                count={vn.attrs.count}
                page_size={vn.attrs.limit}
                display_page_count={false}
            />
        }

        return (
            <div className={'c-market-explore'}>
                <Panel
                    title={'Offer History'}
                    toolbar={toolbar}
                    description={vn.attrs.description}
                    collapsible={vn.attrs.collapsible}
                    collapsed={vn.attrs.collapsed}
                >
                    {vn.attrs.offer_history_items.length === 0 ? (
                        <div class="c-empty-message">
                            {vn.attrs.loading ? <Spinner /> : 'No results'}
                        </div>
                    ) : ([
                        <DataCard model={this.data_card_model}/>,
                        <OfferHistoryTable
                            offer_history_items={vn.attrs.offer_history_items}
                            show_specs={true}
                            show_product_name={true}
                        />,
                    ])}
                </Panel>
            </div>
        )
    }
}
