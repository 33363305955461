{sort, unique, concat, compact, map, empty} = require 'prelude-ls'
{to-string}: utils = require 'utils.ls'

panel = require '@/components/panel.ls'
table = require '@/components/table.ls'
{Amount} = require '@bitstillery/common/components'
{labels} = require '@/components/labels.ls'
{Popover} = require '@/components/popover/popover.ls'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

get-item-labels = (item) ->
    concat compact [
        (item.item_tags || [])
            |> map app.$m.data.item_tag.get_item_tag
            |> compact
            |> map (tag) -> do
                category: tag.category!name!
                name: tag.name!

        if item.case.tax_label != ''
            * category: 'Tax Label'
              name: item.case.tax_label

        if item.best_before_date
            * category: 'BBD'
              name: utils.format-date item.best_before_date

        if item.cases_per_pallet
            * category: 'CPP'
              name: to-string item.case.cases_per_pallet
    ]


export class PurchaseOrderItems
    view: (vnode) -> panel(vnode.attrs.title,
        if vnode.attrs.loading!
            m Spinner
        else
            table.table(vnode.attrs.data, [
                do
                    width: 3
                    field: 'purchase_order.reference'
                    name: '#'
                    function: ((purchase_order_item) ->
                        m(m.route.Link, {href: "/purchase-orders/manage/#{purchase_order_item.purchase_order.reference}"},
                        purchase_order_item.purchase_order.reference)
                    )
                do
                    width: 5
                    field: 'purchase_order.created_on'
                    name: 'Date'
                    function: ((purchase_order_item) ->
                        utils.format-date(purchase_order_item.purchase_order.created_on)
                    )
                do
                    width: 8
                    field: 'purchase_order.supplier.name'
                    name: 'Relation'
                    ellipsis: true
                do
                    width: 8
                    field: 'case.bottle.product.name'
                    name: 'Product'
                    ellipsis: true
                do
                    width: 3
                    field: 'case.number_of_bottles'
                    name: 'Btl / cs'
                    classes: 'number'
                do
                    width: 4
                    field: 'case.bottle.volume'
                    name: 'Size'
                    classes: 'number'
                    function: ((purchase_order_item) -> (+purchase_order_item.case.bottle.volume).toFixed(1) + ' cl')
                do
                    width: 4
                    field: 'case.bottle.alcohol_percentage'
                    name: 'Alc %'
                    classes: 'number'
                    function: ((purchase_order_item) -> (+purchase_order_item.case.bottle.alcohol_percentage).toFixed(1) + '%')
                do
                    width: 3
                    field: 'case.bottle.refill_status'
                    name: 'Ref'
                do
                    width: 5
                    field: 'case.gift_box_type'
                    name: 'GB'
                do
                    width: 3
                    field: 'customs_status'
                    name: 'Cus.'
                do
                    width: 3
                    field: 'number_of_cases'
                    name: 'Cases'
                    classes: 'number'
                do
                    width: 6
                    field: 'was_bought_for'
                    name: 'Price / cs'
                    classes: 'price'
                    function: ((purchase_order_item) ->
                        m(Amount, do
                            amount: purchase_order_item.was_bought_for
                            currency: purchase_order_item.purchase_order.was_bought_in
                            rate: purchase_order_item.purchase_order.bought_against_rate
                        )
                    )
                do
                    width: 6
                    field: 'euro_was_bought_for'
                    name: '€ / cs'
                    classes: 'price'
                    function: ((purchase_order_item) ->
                        m(Amount, do
                            amount: purchase_order_item.was_bought_for
                            currency: purchase_order_item.purchase_order.was_bought_in
                            rate: purchase_order_item.purchase_order.bought_against_rate
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 7
                    field: 'total_price'
                    name: 'Total price'
                    classes: 'price'
                    function: ((purchase_order_item) ->
                        m(Amount, do
                            amount: purchase_order_item.purchase_order.was_bought_for
                            currency: purchase_order_item.purchase_order.was_bought_in
                            rate: purchase_order_item.purchase_order.bought_against_rate
                        )
                    )
                do
                    width: 5
                    field: 'purchase_order.status'
                    name: 'Status'
                do
                    width: 8
                    field: 'purchase_order.supplier.purchase_manager.profile.name'
                    name: 'Purchase manager'
                    function: ((purchase_order_item) ->
                        if purchase_order_item.purchase_order.supplier.purchase_manager.profile.name != purchase_order_item.purchase_order.was_handled_by.profile.name
                        then
                            m Popover, do
                                title: 'Created by'
                                content: purchase_order_item.purchase_order.was_handled_by.profile.name
                            , m '', [
                                purchase_order_item.purchase_order.supplier.purchase_manager.profile.name, ' ', m 'span.glyphicon.glyphicon-user'
                            ]
                        else
                            purchase_order_item.purchase_order.supplier.purchase_manager.profile.name
                    )
                do
                    width: 6
                    field: 'purchase_order.target_warehouse.name'
                    name: 'Warehouse'
                    ellipsis: true
                do
                    width: 9
                    field: 'lots'
                    name: 'Lot(s)'
                    function: ((purchase_order_item) ->
                        for lot in sort unique purchase_order_item.lots
                            m 'div' m m.route.Link, do
                                target: "_blank", \
                                href: "/stock/manage/?q=#{lot}&available_only=false".replace(/ /g, '+')
                            lot
                    )
            ], do
                search_table_style: false
                sticky_header: false
                details: (item) ->
                    compact [
                        let item-labels = get-item-labels item
                            if not empty item-labels
                                labels item-labels

                        if item.remark then
                            m '' [
                                m 'strong' 'Remark: '
                                m 'span' item.remark
                            ]
                    ]
            )
    )
