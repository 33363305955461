m = require 'mithril'
{odd, and-list} = require 'prelude-ls'
api = require 'api.ls'
{a}: utils = require 'utils.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{PurchaseOrderDetails} = require './components/purchase_order_details'
fixed-table-header = require '@/components/table/fixed-header.ls'
{PurchaseOrder} = require '@/models/purchase_orders'
app = require('@/app')

module.exports = class PurchaseOrderFinancials
    ->
        @purchase_order = window.prop new PurchaseOrder
        @invoice_amount = window.prop ''
        @readonly = window.prop true
        @transport_costs_ledger = window.prop ''
        purchase_order_artkey = +m.route.param 'artkey'
        @load_purchase_order +purchase_order_artkey

    transport_ledger_map = {
        '7000': '7130'
        '7001': '7131'
        '7002': '7131'
        '7003': '7132'
        '7004': '7133'
    }

    load_purchase_order: (purchase_order_artkey) ->
        data = do
            purchase_order_artkey: purchase_order_artkey
        api.call 'purchase.core.get_purchase_orders_with_items', data, @set_purchase_order

    set_purchase_order: (resp) ~>
        if resp.success
            @purchase_order app.$m.purchase_orders.create_purchase_order resp.result[0]
            if @purchase_order!was_sent_to_financial_information_system_on!
                @readonly true
            else
                @readonly false
                # Set to 0 if there is no value for convenience.
                if not @purchase_order!invoiced_other_costs!
                    @purchase_order!invoiced_other_costs @purchase_order!other_costs!
                if not @purchase_order!invoiced_transport_costs!
                    @purchase_order!invoiced_transport_costs @purchase_order!transport_costs!
                if not @purchase_order!invoiced_pallet_costs!
                    @purchase_order!invoiced_pallet_costs @purchase_order!pallet_costs!
                if not @purchase_order!invoiced_waste_fund_costs!
                    @purchase_order!invoiced_waste_fund_costs @purchase_order!waste_fund_costs!
                if not @purchase_order!invoice_price_difference!
                    @purchase_order!invoice_price_difference @purchase_order!other_costs!

                # Preselect a transport ledger based on the purchase ledger.
                @transport_costs_ledger transport_ledger_map[@purchase_order!supplier!purchase_ledger!ledger_code!]

            # Initialize the invoice date field with the value of the intake
            # date.
            if @purchase_order!intake_date! and not @purchase_order!is_invoiced_on!
                @purchase_order!is_invoiced_on @purchase_order!intake_date!

        else
            app.$m.common.generic_error_handler!

    submit: (event) ~>
        event.preventDefault!
        @save_data!

    save_data: (callback) ~>
        if not callback
            callback = @handle_save_response
        data = do
            purchase_order_artkey: +@purchase_order!artkey!
            is_invoiced_on: @purchase_order!is_invoiced_on!
            supplier_invoice_reference: @purchase_order!supplier_invoice_reference!
            invoiced_transport_costs: +@purchase_order!invoiced_transport_costs!
            invoiced_pallet_costs: +@purchase_order!invoiced_pallet_costs!
            invoiced_other_costs: +@purchase_order!invoiced_other_costs!
            invoiced_waste_fund_costs: +@purchase_order!invoiced_waste_fund_costs!
            invoice_price_difference: +@purchase_order!invoice_price_difference!
            invoiced_amount: +@purchase_order!invoiced_amount!
        api.call 'purchase.core.update_financial_data', data, callback

    handle_save_response: (resp) ~>
        if not resp.success
            if resp.message
                app.notifier.notify resp.message, 'danger'
            else
                app.$m.common.generic_error_handler!
        else
            app.notifier.notify "The order was updated successfully.", 'success'

    export_to_exact: (event) ~>
        event.preventDefault!
        if @purchase_order!invoiced_transport_costs! > 0 and @transport_costs_ledger! == ''
            app.notifier.notify 'Please select a transport ledger.', 'danger'
            return

        @save_data((resp) ~>
            if not resp.success
                if resp.message
                    app.notifier.notify resp.message, 'danger'
                else
                    app.$m.common.generic_error_handler!
                return

            data = app.$m.data.exact_keys.get_tokens!
            data['purchase_order_artkey'] = +@purchase_order!artkey!
            data['transport_costs_ledger'] = @transport_costs_ledger!

            api.call-and-then 'purchase.exact.export_purchase_order_to_exact', data, do
                success: (resp) ~>
                    app.notifier.notify 'Order successfully exported to Exact!', 'success'
                    m.route.set '/purchase-orders/manage/' + @purchase_order!artkey!
        )

    total_costs_po: ~>
        return +@purchase_order!invoiced_other_costs! + +@purchase_order!invoiced_transport_costs! + +@purchase_order!invoiced_pallet_costs! + +@purchase_order!waste_fund_costs!

    view: ->
        m '.c-purchase-financials view',

            m '.btn-toolbar' {role: "group"},
                m 'button.btn.btn-default' {type: "button", \
                                        onclick: ~> m.route.set '/purchase-orders/manage/' + @purchase_order!artkey!},\
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to order'

            m 'h2' 'Financial administration'

            if @purchase_order! and @purchase_order!artkey! then
                a do
                    m PurchaseOrderDetails, {purchase_order: @purchase_order}

                    fixed-table-header.with-buttons m 'table.table.search-table' {style: 'width: 100%'},
                        m 'thead.thead-default',
                            m 'tr',
                                m 'th' 'Product'
                                m 'th' 'Bottles / case'
                                m 'th' 'Specs'
                                m 'th' 'Giftbox'
                                m 'th' 'Cases ordered'
                                m 'th' 'Price per case'
                                m 'th' 'Total cost'
                        @purchase_order!purchase_order_items!map (item, index) ->
                            m FinancialsPurchaseOrderItem, {item: item, index: index}

                    m '.fieldset',
                        inputs.text(prop(@purchase_order!supplier!purchase_ledger!ledger_code! + ' - ' + @purchase_order!supplier!purchase_ledger!description!), {
                            disabled: true,
                            label: 'Purchase ledger'
                        })

                        m '.field-group',
                            inputs.date(@purchase_order!intake_date, {
                                disabled: true,
                                label: 'Intake date',
                                required: true,
                            })

                            inputs.date(@purchase_order!is_invoiced_on, {
                                disabled: true,
                                label: 'Invoice date',
                            })

                        m '.field-group',
                            inputs.text(@purchase_order!supplier_invoice_reference, {
                                disabled: @readonly!,
                                label: 'Supplier invoice reference',
                                required: true,
                            })

                            m MoneyInput, {
                                label: 'Total invoiced amount',
                                value: @purchase_order!invoiced_amount!
                                on_value: (price) ~> @purchase_order!invoiced_amount price
                                currency: @purchase_order!was_bought_in!
                                additional_class: 'no-spinner'
                                disabled: @readonly!
                            }

                        inputs.select(@transport_costs_ledger, do
                            '7130': '7130 - Inkoopkosten (transport NL 0%)'
                            '7131': '7131 - Inkoopkosten (transport NL hoog%)'
                            '7132': '7132 - Inkoopkosten (transport NL binnen EU)'
                            '7133': '7133 - Inkoopkosten (transport NL buiten EU)'
                        , {
                            disabled: @readonly!,
                            empty_option: true,
                            label: 'Transport ledger'
                        })

                        m '.field-group',
                            m MoneyInput, {
                                label: 'Invoiced transport costs',
                                value: @purchase_order!invoiced_transport_costs!
                                on_value: (price) ~> @purchase_order!invoiced_transport_costs price
                                currency: @purchase_order!was_bought_in!
                                additional_class: 'no-spinner'
                                disabled: @readonly!
                            }

                            m MoneyInput, {
                                label: 'Invoiced pallet costs',
                                value: @purchase_order!invoiced_pallet_costs!
                                on_value: (price) ~> @purchase_order!invoiced_pallet_costs price
                                currency: @purchase_order!was_bought_in!
                                additional_class: 'no-spinner'
                                disabled: @readonly!
                            }

                            m MoneyInput, {
                                label: 'Invoiced other costs',
                                value: @purchase_order!invoiced_other_costs!
                                on_value: (price) ~> @purchase_order!invoiced_other_costs price
                                currency: @purchase_order!was_bought_in!
                                additional_class: 'no-spinner'
                                disabled: @readonly!
                            }

                        if @purchase_order!supplier!country_code! == 'NL'
                            m MoneyInput, {
                                label: 'Invoiced waste fund (afvalfonds) costs',
                                help: 'Netherlands only',
                                value: @purchase_order!invoiced_waste_fund_costs!
                                on_value: (price) ~> @purchase_order!invoiced_waste_fund_costs price
                                currency: @purchase_order!was_bought_in!
                                additional_class: 'no-spinner'
                                disabled: @readonly!
                            }

                        m MoneyInput, {
                            label: 'Price differences',
                            value: @purchase_order!invoice_price_difference!
                            on_value: (price) ~> @purchase_order!invoice_price_difference price
                            currency: @purchase_order!was_bought_in!
                            additional_class: 'no-spinner'
                            disabled: @readonly!
                            minimum: -10000
                        }
                        let result = +((+@purchase_order!was_bought_for!) - (+@purchase_order!invoiced_amount! - @total_costs_po! + +@purchase_order!invoice_price_difference!)).toFixed(2)
                            m 'p' {class: if result != 0 then 'text-danger' else 'text-success'} 'Result: ' + result.format-money!

                        if not @purchase_order!was_sent_to_financial_information_system_on!
                            m '.btn-toolbar', a do
                                m 'button.btn.btn-primary' {type: 'submit', onclick: @export_to_exact} (m 'span.glyphicon.glyphicon-export'), ' Save and export to Exact'
                                m 'button.btn.btn-info' {type: 'submit', onclick: @submit} (m 'span.glyphicon.glyphicon-save'), ' Save but do not export'


class FinancialsPurchaseOrderItem
    (vnode) ->
        @item = window.prop vnode.attrs.item
        @index = window.prop vnode.attrs.index

    view: -> m 'tbody.table-row',
        m 'tr', a do
            m 'td', @item!bottle!product!name!
            m 'td', @item!number_of_bottles_per_case!
            m 'td',
                (+@item!bottle!volume!).toFixed(1), 'cl', ' / ',
                (+@item!bottle!alcohol_percentage!).toFixed(1), '%', ' / '
                @item!bottle!refill_status!
            m 'td', @item!gift_box_type!
            m 'td', @item!number_of_cases!
            m 'td',
                m Amount,
                    amount: +@item!was_bought_for!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
            m 'td',
                m Amount,
                    amount: +@item!number_of_cases! * +@item!was_bought_for!
                    currency: @item!purchase_order!was_bought_in!
                    rate: @item!purchase_order!bought_against_rate!
