import {Observable, of, Subject} from 'rxjs'
import {filter, mergeAll} from 'rxjs/operators'
import {DateTime} from 'luxon'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmptyResponse, FactserverEmtpyRequest} from './api'

export interface GetMarginThresholdResponse {
    artkey: number
    end_needs_approval_range: string | null
    end_low_margin_range: string | null
    start_target_margin_range: string | null
    start_date: string
    end_date?: string
}

export interface UpdateMarginThresholdRequest {
    artkey: number
    end_needs_approval_range: number
    end_low_margin_range: number
    start_target_margin_range: number
}

export interface CreateMarginThresholdRequest {
    end_needs_approval_range: number
    end_low_margin_range: number
    start_target_margin_range: number
    start_date: string
}

export interface SalesOrderLowMarginResponse {
    artkey: number
    reference: string
    is_invoiced_on: string
    margin_percentage: string
    euro_turnover: string
    margin: string
    relation_name: string
    relation_artkey: number
    handled_by: string
    low_margin_approval_reason: string | null
    low_margin_is_approved: boolean
}

export class MarginsApi {
    api = new Api()

    delete_margin_threshold(artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('margin_threshold.delete', {
            artkey: artkey,
        })
    }

    get_margin_threshold(artkey: number): Observable<GetMarginThresholdResponse> {
        return this.api.post_request('margin_threshold.get', {
            artkey: artkey,
        })
    }

    update_margin_threshold(
        update_threshold_request: UpdateMarginThresholdRequest,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('margin_threshold.update', update_threshold_request)
    }

    create_margin_threshold(create_request: CreateMarginThresholdRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('margin_threshold.create', create_request)
    }

    low_margin_sales_order_approval(
        artkey: number,
        approval_reason: string | null,
        is_approved: boolean,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('margin_threshold.low_margin_sales_order_approval', {
            artkey: artkey,
            approval_reason: approval_reason,
            is_approved: is_approved,
        })
    }
}

export class AllMarginThresholds {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: AllMarginThresholds | null = null
    /** Cached results, when available */
    private result: GetMarginThresholdResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetMarginThresholdResponse[]> = new Subject<GetMarginThresholdResponse[]>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, GetMarginThresholdResponse[]>('margin_threshold.get_all', {})
            .subscribe({
                next: (response: GetMarginThresholdResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): AllMarginThresholds {
        if (AllMarginThresholds._instance === null) {
            AllMarginThresholds._instance = new AllMarginThresholds()
        }

        return AllMarginThresholds._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static all(): Observable<GetMarginThresholdResponse[]> {
        if (AllMarginThresholds.instance().drop_down_data$.isStopped) {
            return of(AllMarginThresholds.instance().result)
        }

        return AllMarginThresholds.instance().drop_down_data$
    }

    /** Get for date. */
    public static get(date_time: DateTime): Observable<GetMarginThresholdResponse> {
        return AllMarginThresholds.all().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item) => {
                const item_start_date_as_date_time = DateTime.fromISO(item.start_date)
                const item_end_date_as_date_time = item.end_date ? DateTime.fromISO(item.end_date) : null
                return (
                    item_start_date_as_date_time < date_time &&
                    (item_end_date_as_date_time === null || item_end_date_as_date_time > date_time)
                )
            }),
        )
    }
}
