m = require 'mithril'

api = require 'api.ls'
inputs = require '@/components/inputs'

{button-with-icon} = require '@/components/buttons.ls'
{Spinner} = require '@bitstillery/common/components'

{check_vat_id} = require 'data/vatcheck/actions.ls'
{CountriesSelect, empty_option} = require '@/components/html_components'

module.exports = (initial_vnode) ->
    vat_id = window.prop ''
    country_code = window.prop ''
    prod_mode = window.prop true
    result = window.prop null
    loading = window.prop false

    return do
        view: (vnode) ->
            m '.c-vat-check view',
                m 'form' {onsubmit: -> check_vat_id(result, loading, null, country_code!,vat_id!, prod_mode!)},
                    m '.fieldset',
                        m 'p' [
                            'Verify a VAT number with the '
                            m 'a[href=https://ec.europa.eu/taxation_customs/vies/][target=_blank]' 'European VIES API'
                            '.'
                        ]
                        inputs.field 'VAT ID', inputs.text vat_id
                        inputs.field 'Country',
                            m CountriesSelect,
                                selected: country_code!
                                only_eu: true
                                onchange: (value) ~> country_code value

                        inputs.checkbox prod_mode, {
                            help: 'This should be left enabled for regular VAT checks.',
                            label: 'Use real VIES API',
                        }

                        inputs.field '', button-with-icon 'Check VAT ID', 'fa-check-double', do
                            class: 'btn-success btn-submit'

                if loading!
                    m Spinner
                else if result! then [
                    m 'h3' 'Check result'
                    m '.fieldset',
                        m '.field-readonly',
                            m '.key' 'VAT ID'
                            m '.value' result!vat_id
                        m '.field-readonly',
                            m '.key' 'Country'
                            m '.value' result!country_code
                        m '.field-readonly',
                            m '.key' 'Result'
                            m '.value',
                                if result!valid
                                    'Valid'
                                else
                                    'Invalid'
                        m '.field-readonly',
                            m '.key' 'Trader'
                            m '.value' result!trader_name
                ]

