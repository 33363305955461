import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'

import {$s} from '@/app'
import {Decimal, Percent} from '@/components/html_components'
import {FuelApi, FuelPriceSurchargeResponse} from '@/factserver_api/fuel_api'
import {CurrenciesApi} from '@/factserver_api/currencies'

export class DieselAndCurrencyWidget {
    is_diesel_loading = false
    surcharge: number | null = null

    updated_at = ''

    fuel_api = new FuelApi()
    currency_api = new CurrenciesApi()

    async oncreate() {
        this.fetch_diesel_surcharge()
        await this.currency_api.get_all_currencies()
    }

    fetch_diesel_surcharge(): void {
        this.is_diesel_loading = true
        this.fuel_api.get_diesel_surcharge().subscribe((response: FuelPriceSurchargeResponse) => {
            this.surcharge = response.fuel_price_data.surcharge / 100
            this.is_diesel_loading = false
        })
    }

    view(): m.Children {
        return (
            <div className={classes('c-widget-diesel-currency dashboard-widget', {
                loading: this.is_diesel_loading,
            })}
            >
                <div className="widget-title">
                    Diesel and Currency (Updated {this.updated_at})
                </div>

                <div className="widget-body">
                    <div className="item">
                        <i className="fa fa-euro-sign"/>
                        <Decimal value={$s.currencies.exchange_rates.EUR.rate} number_of_fraction_digits={5} />
                    </div>
                    <div className="item">
                        <i className="fa fa-pound-sign"/>
                        <Decimal value={$s.currencies.exchange_rates.GBP.rate} number_of_fraction_digits={5} />
                    </div>
                    <div className="item">
                        <i className="fa fa-dollar-sign"/>
                        <Decimal value={$s.currencies.exchange_rates.USD.rate} number_of_fraction_digits={5} />
                    </div>
                    <div className="item">
                        <i className="fa fa-yen-sign"/>
                        <Decimal value={$s.currencies.exchange_rates.JPY.rate} number_of_fraction_digits={5} />
                    </div>
                    {this.surcharge && <div className="item">
                        <i className="fas fa-truck"/>
                        <Percent value={this.surcharge} number_of_fraction_digits={2} />
                    </div>}
                </div>
            </div>
        )
    }
}
