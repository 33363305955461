import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {UserDropDown} from '../components/users'

import {DieselAndCurrencyWidget} from './widgets/diesel_and_currency_widget'
import {MinePurchaseWidget} from './widgets/mine_purchase'
import {YearSalesIndexesWidget} from './widgets/year_sales_indexes_widget'
import {ThisMonthSalesStatusWidget} from './widgets/mine_sales_widget'
import {SalesStatisticsWidget} from './widgets/sales_statistics_widget'
import {PurchaseStatisticsWidget} from './widgets/purchase_statistics_widget'
import {RelationsWidget} from './widgets/relations_widget'
import {SalesOrderTurnoverWidget} from './widgets/sales_order_turnover_widget'

import {empty_option} from '@/components/html_components'
import {DEFAULT_ROUTE} from '@/routes'
import {$m} from '@/app'
import {StockValueWidget} from '@/dashboard/widgets/stock_value_widget'

export default class FiguresDashboard extends MithrilTsxComponent<unknown> {
    selected_user_artkey: number | null = null
    user_is_selected = false

    async oncreate() {
        const search_text = window.location.search
        if (search_text) {
            const code_param = /\?code=(.*)/.exec(search_text)
            if (code_param) {
                if (code_param.length !== 2) {
                    notifier.notify('Authentication code has changed at exact. Programming error!', 'warning')
                    return
                }
                const auth_code = code_param[1]
                // Remove the one-time code querystring from exact from the url. Only
                // replacing with a hashbang doesn't trigger the navigation bar, so
                // it is done twice.
                history.replaceState({}, '', '/')
                history.replaceState({}, '', `#!${DEFAULT_ROUTE}`)

                $m.data.exact_keys.login_oauth(auth_code)
            }
        }
    }

    view(): m.Children {
        return (
            <div className="c-dashboard-figures view">
                <div className="columns">
                    <div className="column">
                        <YearSalesIndexesWidget />
                    </div>

                    <div className="column">
                        <DieselAndCurrencyWidget />
                        <StockValueWidget />
                    </div>
                </div>

                <div className="management-panel">
                    <div className="c-filter-group">
                        <UserDropDown
                            local_storage_name={'mine-dashboard-user'}
                            empty_option={empty_option('For account')}
                            onchange={(user_artkey: string) => {
                                this.selected_user_artkey = user_artkey ? +user_artkey : null
                                this.user_is_selected = true
                            }}
                        />
                    </div>
                    {this.user_is_selected && <div className="columns">
                        <div className="column">
                            <ThisMonthSalesStatusWidget
                                user_artkey={this.selected_user_artkey}
                            />
                            <SalesOrderTurnoverWidget
                                user_artkey={this.selected_user_artkey}
                            />
                            <RelationsWidget
                                user_artkey={this.selected_user_artkey}
                            />
                        </div>
                        <div className="column">
                            <MinePurchaseWidget
                                user_artkey={this.selected_user_artkey}
                            />
                            <SalesStatisticsWidget
                                user_artkey={this.selected_user_artkey}
                            />
                            <PurchaseStatisticsWidget
                                user_artkey={this.selected_user_artkey}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
