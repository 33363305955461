import {Observable} from 'rxjs'

import {Api} from './api'

export interface LoenderslootTag {
    artkey?: number
    code: string
    description: string
    unapprove_when_added: boolean
    show_as_feature: boolean
    is_portal_filter: boolean
    forbid_in_stock: boolean
    is_gift_box: boolean
    item_tag_artkey: number
}

export class LoenderslootTagApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    get(artkey: number): Observable<LoenderslootTag> {
        return this.api.get(`discover/system/loendersloot-tags/${artkey}`)
    }

    create(create_tag_request: LoenderslootTag): Observable<any> {
        return this.api.post_fact2server_request('discover/system/loendersloot-tags/', create_tag_request)
    }

    update(update_tag_request: LoenderslootTag): Observable<any> {
        return this.api.put(`discover/system/loendersloot-tags/${update_tag_request.artkey}`, update_tag_request)
    }

    delete(artkey: number): Observable<any> {
        return this.api.delete(`discover/system/loendersloot-tags/${artkey}`)
    }
}
