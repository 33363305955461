$ = require 'jquery'
m = require 'mithril'

# TODO: rewrite this to use the Modal component.
class Dialog
    (vnode) ->
        {
            @unique_name,
            @title,
            @body,
            @no_cancel,
            @onclose,
            @onsubmit,
            @extra_footer_buttons
        } = vnode.attrs

    hide: ~>
        $ @unique_name .modal 'hide'

    close: ~>
        @hide!
        if @onclose? then
            @onclose!

    submit: ~>
        @hide!
        if @onsubmit? then
            @onsubmit!

    show_modal: (vnode) ~>
        $ vnode.dom .modal!

    view: ->
        modal-attrs = do
            id: @unique_name
            tabindex: '-1'
            role: 'dialog'
            'aria-labelledby': 'confirmationLabel'
            oncreate: @show_modal
            onupdate: @show_modal

        m '.modal.fade' modal-attrs,
            m '.modal-dialog' {role: 'document'},
                m '.modal-content',
                    m '.modal-header',
                        m 'button.close' do
                            type: 'button'
                            'data-dismiss': 'modal'
                            'aria-label': 'Close'
                            onclick: @close
                        , '×'
                        m 'h4.modal-title#confirmationLabel' @title

                    m '.modal-body' @body

                    m '.modal-footer',
                        if not @no_cancel then
                            m 'button.btn.btn-default' do
                                type: 'button'
                                'data-dismiss': 'modal'
                                onclick: @close
                            , 'Cancel'

                        if @extra_footer_buttons then
                            for button in @extra_footer_buttons
                                m 'button.btn.btn-default' do
                                    type: 'button'
                                    'data-dismiss': 'modal'
                                    onclick: button.onclick
                                , button.label

                        m 'button.btn.btn-primary' do
                            type: 'button'
                            'data-dismiss': 'modal'
                            onclick: @submit
                        , 'OK'


export show = (options) ->
    if not options.unique_name
        console.error 'Provide a unique name for your dialog, otherwise names might collide!'

    element = document.getElementById 'confirmationLocation'
    m.render element, (m Dialog, options)
