m = require 'mithril'
{map, filter} = require 'prelude-ls'

api = require 'api.ls'
app = require('@/app')

{Spinner} = require '@bitstillery/common/components'
{capitalize} = require 'utils.ls'

{Badge} = require '@bitstillery/common/badge'
{text-button, icon-button} = require '@/components/buttons.ls'
{countries} = require '@bitstillery/common/lib/countries'
inputs = require '@/components/inputs'
{MoneyInput, PercentInput} = require '@/components/decimal_input'

{ExciseCategory, ExciseDutyApplications} = require './models.ls'
{CountriesSelect, empty_option} = require '@/components/html_components'


module.exports = class ExciseCategoryEdit
    ->
        @excise = window.prop new ExciseCategory

        @create = window.prop true
        @artkey = m.route.param 'artkey'
        @product_categories = window.prop []
        @excise_product_categories = []

        @loading = true

        @application_types = [
            ExciseDutyApplications.HL_PRODUCT
            ExciseDutyApplications.HL_DEGREE_ALCOHOL
        ]

    oncreate: ~>
        if @artkey then  # we are not creating a new one
            @create false

        @query_all_product_categories!

    query_excise: (artkey) ~>
        api.call-and-then 'excise.get_excise_category' {artkey: artkey}, do
            success: (resp) ~>
                categories = window.prop []
                @excise new ExciseCategory resp.result
                @loading = false

                # Product categories linked to the excise category should be removed from the @product_categories list.
                for category in @excise!product_categories!
                    @excise_product_categories.push(category.name)
                    index = @product_categories!indexOf(category.name)
                    @product_categories!splice(index, 1)
            failure: ~>
                @loading = false

    query_all_product_categories: ~>
        api.call-and-then 'product_categories.get_product_categories' {}, do
            success: (resp) ~>
                resp.result
                |> filter (item) -> item.cbs_code != "UNKNOWN"
                |> map ({name}) -> name
                |> @product_categories

                # Only after all product categories are retrieved we query for
                # the excise rule. This order is required because we want to
                # remove the product categories linked to the excise cate gory
                # from the product categories array we instantiate above.
                if @artkey
                    @query_excise @artkey
                else
                    @loading = false

    save: (e) ~>
        e.preventDefault!

        if +@excise!from_alcohol_percentage! < +@excise!to_alcohol_percentage! then
            data = do
                artkey: @excise!artkey!
                name: @excise!name!
                country_code: @excise!country_code!
                duty_rate: @excise!duty_rate!
                duty_application: @excise!duty_application!
                from_alcohol_percentage: @excise!from_alcohol_percentage!
                to_alcohol_percentage: @excise!to_alcohol_percentage!
                product_categories: @excise_product_categories

            api.call-and-then 'excise.create_or_update_excise_category' data, do
                success: (resp) ~>
                    if @create! then
                        app.notifier.notify "Successfully created new excise category \"#{@excise!name!}\".", 'success'
                        m.route.set '/data/excise-categories'
                    else
                        app.notifier.notify 'Successfully updated excise category.', 'success'
                        m.route.set '/data/excise-categories'
                    app.$m.common.observable.broadcast 'refresh_excise_list'
                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
        else
            app.notifier.notify "Lower limit can't be set to the same or a higher percentage than upper limit.", 'danger'

    delete: (artkey) ~>
        api.call-and-then 'excise.delete_excise_category' {artkey: artkey}, do
            success: ~>
                app.notifier.notify "Successfully deleted excise category \"#{@excise!name!}\".", 'success'
                app.$m.common.observable.broadcast 'refresh_excise_list'
                m.route.set '/data/excise-categories'

    view: ->
        if @loading then
            return m Spinner
        currency = 'EUR'
        if @excise!country_code! == 'GB'
            currency = 'GBP'

        m '.c-excise-category-edit view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: 'button', onclick: ~> m.route.set '/data/excise-categories'},
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                if not @create! then m 'button.btn.btn-danger' {type: 'button', \
                    onclick: ~> @delete(@artkey)},
                    (m 'span.glyphicon.glyphicon-trash'), 'Delete'

            m 'form.columns' {onsubmit: @save},
                m '.column',
                    m '.fieldset',
                        inputs.text @excise!name, {
                            label: 'Excise category name'
                            required: true
                            placeholder: 'Excise category name'
                            oninput: (ev) ~> @excise!name ev.target.value
                        }

                        m CountriesSelect,
                            label: 'Country'
                            required: true
                            disabled: not @create!
                            selected: @excise!country_code!
                            onchange: (value) ~> @excise!country_code(value)

                        inputs.select @excise!duty_application, @application_types, {
                            help: 'The unit to which the duty should be applied'
                            label: 'Duty Application'
                            required: true
                            empty_option: false
                        }

                        m MoneyInput, {
                            label: 'Duty rate',
                            currency: currency
                            value: @excise!duty_rate!,
                            on_value: (price) ~> @excise!duty_rate price
                        }

                        m '.field-group',
                            m PercentInput,
                                help: 'From, excluding (>)'
                                label: 'Lower limit',
                                required: true,
                                placeholder: 'From'
                                value: @excise!from_alcohol_percentage!
                                on_value: (value) ~> @excise!from_alcohol_percentage value

                            m PercentInput,
                                help: 'Up to and including (<=).',
                                label: 'Upper limit',
                                required: true
                                placeholder: 'To'
                                value: @excise!to_alcohol_percentage!
                                on_value: (value) ~> @excise!to_alcohol_percentage value


                    text-button "#{if @create! then 'Create Excise Category' else 'Update Excise Category'}", do
                        class: 'btn-success'

                m '.column',
                    m '.field',
                        m 'label' {for: 'product_categories'} 'Product categories'
                        m '.product-categories',
                            @product_categories!sort!map (col) ~>
                                m Badge, do
                                    type: 'primary'
                                    additional_classes: 'mr-05 clickable'
                                    onclick: (e) ~>
                                        value = e.target.innerText
                                        @excise_product_categories.push(value)
                                        index = @product_categories!indexOf(value)
                                        @product_categories!splice(index, 1)
                                , col

                        m 'table.table.search-table.product-category-table',
                            m 'thead.thead-default',
                                m 'tr',
                                    m 'th' 'Product category'
                                    m 'th' ''
                            @excise_product_categories.sort!map (category, index) ~>
                                m 'tbody.table-row',
                                    m 'tr.',
                                        m 'td' capitalize category
                                        m 'td' icon-button 'trash', do
                                            class: 'btn-danger float-right'
                                            onclick: (e) ~>
                                                e.preventDefault!
                                                @product_categories!push(capitalize(category))
                                                @excise_product_categories.splice(index, 1)
