import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {required, validation} from '@bitstillery/common/lib/validation'
import {api, notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {$m} from '@/app'
import {BackToListButton} from '@/components/discover'
import {DropDownOption, DropDownWithSelect, FieldText} from '@/components/html_components'
import {EditAssist, Form} from '@/components/form'
import {CreateOrUpdateItemTagRequest, ItemTagResponse} from '@/factserver_api/fact2server_api.ts'

export default class ItemTagEdit extends MithrilTsxComponent<unknown> {
    edit_assist: EditAssist

    is_loading = false
    data = proxy({
        update_request: {
            name: '',
            abbreviation: '',
            item_tag_category_artkey: 0,
        } as CreateOrUpdateItemTagRequest,
    })

    validation = {
        name: validation([this.data.update_request, 'name'], required()),
        abbreviation: validation([this.data.update_request, 'abbreviation'], required()),
    }

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
    }

    async oninit() {
        if (this.edit_assist.is_creating) {
            this.data.update_request.item_tag_category_artkey = $m.data.item_tag_category.categories()[0].artkey()
        } else {
            const {result} = await api.get<ItemTagResponse>(`discover/data/item-tags/${this.edit_assist.artkey}`)

            this.data.update_request.item_tag_category_artkey = result.item_tag_category_artkey
            this.data.update_request.name = result.name
            this.data.update_request.abbreviation = result.abbreviation
        }
    }

    async create_or_update_item_tag() {
        if (this.edit_assist.is_creating) {
            await api.post('discover/data/item-tags', this.data.update_request, true)
            notifier.notify(`Item tag ${this.data.update_request.name} created`)
            m.route.set('/data/item-tags')
        } else {
            await api.put(`discover/data/item-tags/${this.edit_assist.artkey}`, this.data.update_request)
            notifier.notify(`Item tag ${this.data.update_request.name} updated`)
            m.route.set('/data/item-tags')
        }
    }

    view(): m.Children {
        if (this.is_loading) {
            return <Spinner/>
        }
        return (
            <div className="c-item-tags-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/item-tags'} />
                </div>

                <div className="c-tabs">
                    <Form
                        onSubmit={async() => this.create_or_update_item_tag()}
                        submitText={this.edit_assist.is_creating ? 'Create Item Tag' : 'Update Item Tag'}
                        validations={this.validation}
                    >
                        <div className="fieldset">
                            <FieldText
                                label="Name"
                                placeholder={'Tag name'}
                                value={this.data.update_request.name}
                                validation={this.validation.name}
                                oninput={(value: string) => (this.data.update_request.name = value)}
                            />

                            <FieldText
                                label="Abbreviation"
                                placeholder={'Short'}
                                value={this.data.update_request.abbreviation}
                                validation={this.validation.abbreviation}
                                oninput={(value: string) => { this.data.update_request.abbreviation = value }}
                            />

                            <DropDownWithSelect
                                label='Category'
                                selected={`${this.data.update_request.item_tag_category_artkey || ''}`}
                                required={true}
                                onchange={(value: string) =>
                                    (this.data.update_request.item_tag_category_artkey = value ? +value : null)
                                }
                            >
                                {$m.data.item_tag_category.categories().map((category) => {
                                    return (
                                        <DropDownOption value={`${category.artkey()}`}>
                                            {category.name()}
                                        </DropDownOption>
                                    )
                                })}
                            </DropDownWithSelect>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}
