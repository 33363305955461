import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {SubAmount, Amount, Tippy} from '@bitstillery/common/components'

import {OfferType} from '@/factserver_api/fact2server_api'

interface OfferPriceAttrs {
    list_price: string
    original_price_per_case: string
    offer_type: OfferType
    currency: string
    includes_excise_in_price: boolean
    excise_per_case?: string
}

export class OfferPrice extends MithrilTsxComponent<OfferPriceAttrs> {
    view(vnode: m.Vnode<OfferPriceAttrs>): m.Children {
        const is_special = vnode.attrs.offer_type === OfferType.Offer || vnode.attrs.offer_type === OfferType.Spotlight
        const price_up = +vnode.attrs.list_price > +vnode.attrs.original_price_per_case

        return (
            <div className={'c-offer-price'}>
                {vnode.attrs.list_price === vnode.attrs.original_price_per_case && (
                    <div>
                        <Amount amount={vnode.attrs.list_price} currency={vnode.attrs.currency} />
                    </div>
                )}
                {vnode.attrs.list_price !== vnode.attrs.original_price_per_case && (
                    <div className={'changed-price'}>
                        <div className={'original-price'}>
                            <Amount amount={vnode.attrs.original_price_per_case} currency={vnode.attrs.currency} />
                        </div>
                        <div className={'list-price'}>
                            {is_special && <Tippy
                                content={'Is in an offer'}
                                allowHTML={true}
                            >
                                <span className={'glyphicon glyphicon-duplicate'}/>{' '}
                            </Tippy>}
                            {!is_special && price_up && <Tippy
                                content={'Price went up'}
                                allowHTML={true}
                            >
                                <span className={'glyphicon glyphicon-arrow-up price-up-icon'} />
                            </Tippy>}
                            {!is_special && !price_up && <Tippy
                                content={'Price went down'}
                                allowHTML={true}
                            >
                                <span className={'glyphicon glyphicon-arrow-down price-down-icon'} />
                            </Tippy>}
                            <Amount amount={vnode.attrs.list_price} currency={vnode.attrs.currency} />
                        </div>
                    </div>
                )}
                {vnode.attrs.includes_excise_in_price &&
                    <div className={'duty'}>
                        <SubAmount label={'Duty'} currency={vnode.attrs.currency} amount={vnode.attrs.excise_per_case}/>
                    </div>
                }
            </div>
        )
    }
}
