import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, notifier} from '@bitstillery/common/app'

import {VoucherValueType} from './voucher_models'
import {ProcessVouchers} from './processes'

import {drop_down_menus_for_enum, DropDownWithSelect, FormGroup} from '@/components/html_components'

export class VoucherUpsert extends MithrilTsxComponent<unknown> {
    data = proxy({
        loading: false,
        voucher:  {
            voucher_value_type: 'FIXED',
        },
    })

    async oncreate() {
        this.data.voucher_artkey = +m.route.param('artkey')
        if (this.data.voucher_artkey) {
            this.data.loading = true
            const {result} = await api.post('voucher.get_voucher_promotion', {artkey: this.data.voucher_artkey})
            this.data.voucher = result
            this.data.voucher.start_date = result.start_date.split('T')[0]
            this.data.voucher.end_date = result.end_date.split('T')[0]
            this.data.loading = false
        }
    }

    get create(): boolean {
        return this.data.voucher_artkey === null
    }

    async update_voucher_promotion() {
        if (
            this.data.voucher.name &&
            this.data.voucher.start_date &&
            this.data.voucher.end_date &&
            this.data.voucher.code &&
            this.data.voucher.voucher_value_type &&
            this.data.voucher.value !== null &&
            this.data.voucher.value !== undefined
        ) {
            await api.post('voucher.create_or_update_voucher_promotion', {
                artkey: this.data.voucher_artkey,
                name: this.data.voucher.name,
                voucher_value_type: this.data.voucher.voucher_value_type,
                value: this.data.voucher.value,
                code: this.data.voucher.code,
                start_date: this.data.voucher.start_date,
                end_date: this.data.voucher.end_date,
            })
            notifier.notify('Voucher promotion successfully saved!', 'success')
            m.route.set('/offer/vouchers')
        } else {
            notifier.notify('Please fill out all required fields.', 'info')
        }
    }

    view(): m.Children {
        if (this.data.loading) return ''
        return (
            <div className="c-offer-voucher view">
                <ProcessVouchers
                    active='upsert'
                    context={{
                        artkey: this.data.voucher_artkey,
                    }}
                />

                <div className="fieldset">
                    <div className="field">
                        <label>Name</label>
                        <input
                            autocomplete="off"
                            type="text"
                            required={true}
                            value={this.data.voucher.name}
                            oninput={(e) => {
                                this.data.voucher.name = e.target.value
                            }}
                            placeholder="Name"
                            className={'form-control'}
                        />
                    </div>

                    <div className="field-group">
                        <DropDownWithSelect
                            help="Fixed discount amount or a percentage of the order"
                            label="Voucher Type"
                            selected={this.data.voucher.voucher_value_type?.toString() || ''}
                            onchange={(value: string) => {
                                this.data.voucher.voucher_value_type =
                                    VoucherValueType[value as keyof typeof VoucherValueType]
                            }}
                        >
                            {drop_down_menus_for_enum(VoucherValueType)}
                        </DropDownWithSelect>

                        <div className="field">
                            <label>Value</label>
                            <div class="control">
                                <input
                                    autocomplete="off"
                                    required={true}
                                    type="number"
                                    value={this.data.voucher.value}
                                    oninput={(value: InputEvent) => {
                                        this.data.voucher.value = +(value.target as HTMLInputElement)
                                            ?.value
                                    }}
                                    placeholder="Value"
                                    className={'form-control'}
                                />
                                <div class="control-addon">
                                    {this.data.voucher.voucher_value_type === VoucherValueType.FIXED &&
                                        'EUR'}
                                    {this.data.voucher.voucher_value_type === VoucherValueType.PERCENTAGE &&
                                        '%'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fieldset">
                    <FormGroup label="Code">
                        <input
                            autocomplete="off"
                            type="text"
                            required={true}
                            value={this.data.voucher.code}
                            oninput={(value: InputEvent) => {
                                this.data.voucher.code = (value.target as HTMLInputElement)?.value
                            }}
                            placeholder="Code"
                        />
                    </FormGroup>
                    <FormGroup label="Start date">
                        <input
                            type="date"
                            required={true}
                            value={this.data.voucher.start_date}
                            oninput={(value: InputEvent) => {
                                this.data.voucher.start_date = (
                                    value.target as HTMLInputElement
                                )?.value
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="End date"
                        help_text="Note: The last day of the promotion is the day before this date."
                    >
                        <input
                            type="date"
                            required={true}
                            value={this.data.voucher.end_date}
                            oninput={(value: InputEvent) => {
                                this.data.voucher.end_date = (value.target as HTMLInputElement)?.value
                            }}
                        />
                    </FormGroup>
                </div>
                <Button
                    className="btn-login btn-submit"
                    onclick={() => {
                        this.update_voucher_promotion()
                    }}
                    text={this.data.voucher.artkey ? 'Update Promotion' : 'Create Promotion'}
                    type="success"
                />

            </div>

        )
    }
}
