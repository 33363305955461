m = require 'mithril'
utils = require 'utils.ls'

panel = require '@/components/panel.ls'
table = require '@/components/table.ls'
{Amount} = require '@bitstillery/common/components'
{Popover} = require '@/components/popover/popover.ls'
{Spinner} = require '@bitstillery/common/components'


export class CreditItems
    view: (vnode) -> panel(vnode.attrs.title,
        if vnode.attrs.loading!
            m Spinner
        else if vnode.attrs.data!length > 0
            table.table(vnode.attrs.data, [
                do
                    width: 7
                    field: 'sales_order.reference'
                    name: '#'
                    function: ((credit_item) ->
                        m(m.route.Link, {href: "/sales-orders/manage/#{credit_item.sales_order.reference}"},
                        credit_item.sales_order.reference)
                    )
                do
                    width: 5
                    field: 'sales_order.created_on'
                    name: 'Date'
                    function: ((credit_item) ->
                        utils.format-date(credit_item.sales_order.created_on)
                    )
                do
                    width: 9
                    field: 'sales_order.supplier.name'
                    name: 'Relation'
                    ellipsis: true
                do
                    width: 8
                    field: 'item.case.bottle.product.name'
                    name: 'Product'
                do
                    width: 6
                    field: 'item.case.number_of_bottles'
                    name: 'Btl / cs'
                    classes: 'number'
                do
                    width: 4
                    field: 'item.case.bottle.volume'
                    name: 'Size'
                    classes: 'number'
                    function: ((credit_item) -> (+credit_item.item.case.bottle.volume).toFixed(1) + ' cl')
                do
                    width: 4
                    field: 'item.case.bottle.alcohol_percentage'
                    name: 'Alc %'
                    classes: 'number'
                    function: ((credit_item) -> (+credit_item.item.case.bottle.alcohol_percentage).toFixed(1) + '%')
                do
                    width: 3
                    field: 'item.case.bottle.refill_status'
                    name: 'Ref'
                do
                    width: 6
                    field: 'item.case.gift_box_type'
                    name: 'GB'
                do
                    width: 5
                    field: 'item.case.tax_label'
                    name: 'Tax label'
                    ellipsis: true
                do
                    width: 3
                    field: 'item.case.customs_status'
                    name: 'Cus.'
                do
                    width: 6
                    field: 'price_per_case'
                    name: 'Price / cs'
                    classes: 'price'
                    function: ((credit_item) ->
                        m(Amount, do
                            amount: -1 * credit_item.item.was_bought_for
                            currency: credit_item.sales_order.was_sold_in
                            rate: credit_item.sales_order.sold_against_rate
                        )
                    )
                do
                    width: 7
                    field: 'no_field'
                    name: 'Total price'
                    classes: 'price'
                    function: ((credit_item) ->
                        m(Amount, do
                            amount: -1 * credit_item.item.was_bought_for * credit_item.number_of_cases
                            currency: credit_item.sales_order.was_sold_in
                            rate: credit_item.sales_order.sold_against_rate
                        )
                    )
                do
                    width: 7
                    field: 'sales_order.incoterm_and_location'
                    name: 'Incoterm'
                    ellipsis: true
                do
                    width: 7
                    field: 'sales_order.destination_warehouse.name'
                    name: 'Destination'
                    ellipsis: true
                    function: ((credit_item) -> if credit_item.sales_order.destination
                        then credit_item.sales_order.destination.name
                        else credit_item.sales_order.destination_location
                    )
                do
                    width: 5
                    field: 'sales_order.sales_order_status'
                    name: 'Status'
                do
                    width: 8
                    field: 'sales_order.supplier.sales_manager.profile.name'
                    name: 'Sales manager'
                    function: ((credit_item) ->
                        if credit_item.sales_order.supplier.sales_manager.profile.name != credit_item.sales_order.was_handled_by.profile.name
                        then
                            m Popover, do
                                title: 'Created by'
                                content: credit_item.sales_order.was_handled_by.profile.name
                            , m '', [
                                credit_item.sales_order.supplier.sales_manager.profile.name, ' ', m 'span.glyphicon.glyphicon-user'
                            ]
                        else
                            credit_item.sales_order.supplier.sales_manager.profile.name
                    )
            ], do
                search_table_style: false
                sticky_header: false
            )
        else
            'This item is not a result of a credit order.'
    )
