m = require "mithril"

{account-icon} = require 'accounts.ls'
{AccountSlug} = require '@bitstillery/common/account/account'
{Amount} = require '@bitstillery/common/components'
{icon, icon-with-popover} = require '@/components/icon.ls'
{a, maybe-map, with-default}: utils = require 'utils.ls'

{SalesOrderStatus} = require '@/sales_orders/models.ls'
app = require('@/app')

export class SalesOrderDetails
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        base_url = if @sales_order!account!slug! == AccountSlug.ETR then app.config.a2bc_portal_base_url else app.config.portal_base_url
        @portal_link = "#{base_url}/#!/orders/#{@sales_order!artkey!}"

        @memo-is-active = false

    toggle-memo-class: ~>
        @memo-is-active = !@memo-is-active

    view: ->
        m '.details',
            m '.row',
                m '.col-sm-6' a do
                    m 'dl.dl-horizontal', a do
                        m 'dt', 'Reference'
                        m 'dd', @sales_order!reference!
                        m 'dt', 'Created on'
                        m 'dd', utils.format-date-time @sales_order!date!
                        m 'dt', 'Created by'
                        m 'dd', @sales_order!was_handled_by!profile!name!
                        m 'dt', 'Sales manager'
                        m 'dd', @sales_order!supplier!sales_manager!profile!name!
                        m 'dt', 'Status'
                        m 'dd', @sales_order!combined_status!
                        m 'dt', 'Relation'
                        m 'dd',
                            m 'span.mr-05' account-icon @sales_order!account!
                            m m.route.Link, do
                                target: "_blank"
                                href: '/crm/relations/' + @sales_order!supplier!artkey! + '/edit'
                            , @sales_order!supplier!name!
                        if @sales_order!sales_order_status! == SalesOrderStatus.INVOICED
                            a do
                                m 'dt', 'Invoiced on'
                                m 'dd', utils.format-date-time @sales_order!is_invoiced_on!
                                m 'dt', 'Invoice number'
                                m 'dd', @sales_order!invoice_number!
                                m 'dt', 'Sent to FIS date'
                                m 'dd', utils.format-date @sales_order!was_sent_to_financial_information_system_on!
                        m 'dt', 'Currency'
                        m 'dd', @sales_order!was_sold_in!
                        if @sales_order!was_sold_in! != app.$s.currencies.default
                            a do
                                m 'dt', 'Exchange rate'
                                m 'dd', @sales_order!sold_against_rate!
                        m 'dt', 'Origin'
                        m 'dd', if origin = @sales_order!origin!name! then origin else '-'
                        m 'dt', 'Destination'
                        m 'dd', @sales_order!destination_location! || @sales_order!destination!name!
                        m 'dt', 'Incoterm'
                        m 'dd', @sales_order!incoterm! + ' - ' + @sales_order!incoterm_location!
                        m 'dt', 'Req. delivery date'
                        m 'dd',
                            if @sales_order!requested_delivery_date! then
                                utils.format-date @sales_order!requested_delivery_date!
                            else
                                'As soon as possible'
                            '  '
                            icon-with-popover do
                                icon-id: 'info-sign'
                                title: 'Requested delivery date'
                                content: 'This is the delivery date as requested by the client in the portal.'
                        m 'dt', 'Green Transport'
                        m 'dd',
                            if not @sales_order!delivery!external_reference!
                                '-'
                            else if @sales_order!delivery!status! == "CREATED"
                                @sales_order!delivery!external_reference! + ' - Created'
                            else if @sales_order!delivery!status! == "CONFIRMED"
                                @sales_order!delivery!external_reference! + ' - Confirmed'
                        m 'dt', 'Remarks'
                        m 'dd', utils.multiline @sales_order!remark!
                m '.col-sm-6' a do
                    m 'dl.dl-horizontal', a do
                        m 'dt', 'Insurance'
                        m 'dd', @sales_order!insurance_type!name!
                        m 'dt', 'Number of cases'
                        m 'dd', @sales_order!number_of_cases!
                        m 'dt', 'Total purchased value'
                        m 'dd', m Amount, do
                            amount: @sales_order!euro_purchasing_value!
                            key: @sales_order!euro_purchasing_value!
                            currency: app.$s.currencies.default
                        m 'dt', 'Turnover'
                        m 'dd', m Amount, do
                            amount: @sales_order!was_sold_for_excl_excise!
                            currency: @sales_order!was_sold_in!
                            key: @sales_order!was_sold_for_excl_excise!
                            rate: @sales_order!sold_against_rate!
                        m 'dt', 'Total margin'
                        m 'dd', m Amount, do
                            amount: @sales_order!margin!
                            currency: app.$s.currencies.default
                            key: @sales_order!margin!
                        m 'dt', 'Margin (%)'
                        m 'dd', if @sales_order!margin_percentage! then (+@sales_order!margin_percentage!).toFixed(2) + '%' else '-'
                        m 'dt', 'Excise'
                        m 'dd', [
                            m Amount, do
                                amount: @sales_order!excise_total!
                                currency: @sales_order!was_sold_in!
                                rate: @sales_order!sold_against_rate!
                                key: @sales_order!excise_total!
                        ]
                        m 'dt', 'Turnover incl. Excise'
                        m 'dd', [
                            m Amount, do
                                amount: @sales_order!was_sold_for!
                                currency: @sales_order!was_sold_in!
                                rate: @sales_order!sold_against_rate!
                                key: @sales_order!was_sold_for!
                        ]
                        m 'dt', 'VAT'
                        m 'dd', m Amount, do
                            amount: @sales_order!vat_total!
                            currency: @sales_order!was_sold_in!
                            key: @sales_order!vat_total!
                        m 'dt', 'Grand Total'
                        m 'dd', [
                            m Amount, do
                                amount: @sales_order!was_sold_for_incl_vat!
                                currency: @sales_order!was_sold_in!
                                rate: @sales_order!sold_against_rate!
                                key: @sales_order!was_sold_for_incl_vat!
                        ]
                        m 'dt', 'Portal link'
                        m 'dd', m '',
                            m 'a' do
                                id: 'portal-link'
                                target: "_blank"
                                href: @portal_link
                            , @portal_link
                            m 'span' ' '
                            m 'span.copy-to-clipboard-icon' do
                                href: "#"
                                onclick: ~> navigator.clipboard.writeText(@portal_link).then(~>
                                    alert("Copied link to clipboard.")
                                )
                            , icon 'fa-copy'
                        m 'dt', 'Loading info'
                        m 'dd', m '',
                            [
                                if @sales_order!delivery!weight_in_kilos!
                                    m '' "#{@sales_order!delivery!weight_in_kilos!} kg"
                                if @sales_order!delivery!number_of_euro_pallets!
                                    m '' "#{@sales_order!delivery!number_of_euro_pallets!} euro pallet(s)"
                                if @sales_order!delivery!number_of_block_pallets!
                                    m '' "#{@sales_order!delivery!number_of_block_pallets!} block pallet(s)"
                            ]

            if @sales_order!supplier!memo!
                m '.row', a do
                    m '.col-sm-12' a do
                        m '.alert alert-info' do
                            class: if @memo-is-active then 'sales-order-memo--active' else 'sales-order-memo'
                            onclick: ~> @toggle-memo-class!
                        , a do
                            m 'span.glyphicon.glyphicon-info-sign'
                            m 'span' ' Memo: '
                            if @memo-is-active then
                                m 'br'
                            m 'span' @sales_order!supplier!memo!
