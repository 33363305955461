import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {DashboardApi, UserStatusObj} from '@/factserver_api/dashboard_api'

export class StatusBasic extends MithrilTsxComponent<unknown> {
    dashboard_api = new DashboardApi()
    show_statuses_modal = false
    is_loading = true
    user_statuses: UserStatusObj[] = []

    status_counts: { [status: string]: number } = {}

    constructor() {
        super()
        this.fetch_user_statuses()
    }

    fetch_user_statuses(): void {
        this.dashboard_api.get_users_status_today().subscribe({
            next: (response) => {
                this.user_statuses = response.statuses
                this.is_loading = false
                this.status_counts = {}
                this.user_statuses.forEach((user_status_obj) => {
                    if (user_status_obj.status !== null) {
                        const status = user_status_obj.status.toLowerCase()

                        if (this.status_counts[status] === undefined) this.status_counts[status] = 0
                        this.status_counts[status] += 1
                    }
                })
            },
            error: () => {
                notifier.notify(
                    'Something went wrong trying to load the current user statuses, reload the page to try again',
                    'danger',
                )
            },
        })
    }

    view(): m.Children {
        if (this.is_loading) return <Spinner/>
        return (
            <div className="c-users-status-widget dashboard-widget">
                {(() => {
                    if (this.user_statuses.length === 0) return <div>No user statuses today</div>
                    const aggr_names_by_status: { [status: string]: string[] } = {}
                    const table_rows: JSX.Element[] = []

                    this.user_statuses.forEach((user_status) => {
                        if (aggr_names_by_status[user_status.status] === undefined) aggr_names_by_status[user_status.status] = []
                        aggr_names_by_status[user_status.status].push(user_status.user_name)
                    })

                    const statuses = Object.keys(aggr_names_by_status)
                    const number_of_rows = Math.max(...Object.values(this.status_counts))
                    for (let row_index = 0; row_index < number_of_rows; row_index++) {
                        table_rows.push(
                            <tr>
                                {statuses.map((status, index) => {
                                    const even_odd_str = (index + 1) % 2 === 0 ? 'even' : 'odd'
                                    return <td className={`td ${even_odd_str}`}>{aggr_names_by_status[status][row_index]}</td>
                                })}
                            </tr>,
                        )
                    }

                    const widget_table = (
                        <table>
                            <thead>
                                {Object.keys(this.status_counts).map((status, index) => {
                                    const even_odd_str = (index + 1) % 2 === 0 ? 'even' : 'odd'
                                    return (
                                        <th className={`th ${status} ${even_odd_str} text-capitalize`}>
                                            <a href={'#!/my-discover/colleagues'}>
                                                {status} ({this.status_counts[status]})
                                            </a>
                                        </th>
                                    )
                                })}
                            </thead>
                            <tbody>{...table_rows}</tbody>
                        </table>
                    )
                    return widget_table
                })()}
            </div>
        )
    }
}
