import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {Tippy} from '@bitstillery/common/components'

import {MaybeObservable} from './relation'

import {GetItemTagResponse, ItemTagsDropDownData} from '@/factserver_api/item_tags'

interface CaseLabelAttrs {
    show_as_icons?: boolean
    show_as_labels?: boolean
    case: {
        serialized_item_tags?: string
        gift_box_type?: string
        cases_per_pallet?: string
        cases_per_pallet_layer?: string
        remark?: string | null
        best_before_date?: string
        tax_label?: string
    }
}

export class CaseLabels extends MithrilTsxComponent<CaseLabelAttrs> {
    item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags$()

    constructor() {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => {
                this.item_tags = response
                m.redraw()
            },
        })
    }

    // TODO: Refactor category to an enum value.
    style_for_category(category: string | undefined): string {
        switch (category) {
        case 'Packaging':
            return 'background-color: #b9b0b0'
        case 'Pack Size':
            return 'background-color: #d9ecd0'
        case 'Damages':
            return 'background-color: #b2c2bf'
        case 'General':
            return 'background-color: #77a8a8'
        }
        return 'background-color: #87bdd8'
    }

    static style_best_before_date = 'background-color: #fff2df'
    static style_cases_per_pallet = 'background-color: #d6d4e0'
    static style_remark = 'background-color: #87bdd8'
    static style_tax_label = 'background-color: #e4d1d1'
    static style_gift_box_label = 'background-color: #1282c1'

    view_as_label_icons(vnode: m.Vnode<CaseLabelAttrs>): m.Children {
        return (
            <span>
                {vnode.attrs.case.gift_box_type && (
                    <Tippy
                        content={`<strong>Gift box type</strong>
                                  <div>${vnode.attrs.case.gift_box_type}</div>`}
                        allowHTML={true}
                    >
                        <span style={CaseLabels.style_gift_box_label} className={'label tag-label'}>
                            {vnode.attrs.case.gift_box_type}
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.best_before_date && (
                    <Tippy
                        content={`<strong>Best before date</strong>
                                  <div>${format_iso_to_date(vnode.attrs.case.best_before_date)}</div>`}
                        allowHTML={true}
                    >
                        <span style={CaseLabels.style_best_before_date} className={'label tag-label'}>
                            BBD
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.cases_per_pallet && (
                    <Tippy
                        content={`<strong>Cases per pallet</strong>
                                  <div>${vnode.attrs.case.cases_per_pallet}</div>`}
                        allowHTML={true}
                    >
                        <span className={'label tag-label'} style={CaseLabels.style_cases_per_pallet}>
                            CPP
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.cases_per_pallet_layer && (
                    <Tippy
                        content={`<strong>Cases per pallet layer</strong><div>${vnode.attrs.case.cases_per_pallet_layer}</div>`}
                        allowHTML={true}
                    >
                        <span className={'label tag-label'} style={CaseLabels.style_cases_per_pallet}>
                            CPL
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.remark && (
                    <Tippy
                        content={`<strong>Remark</strong><div>${vnode.attrs.case.remark}</div>`}
                        allowHTML={true}
                    >
                        <span className={'label tag-label'} style={CaseLabels.style_remark}>
                            RE
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.tax_label && (
                    <Tippy
                        content={`<strong>Tax label</strong><div>${vnode.attrs.case.tax_label}</div>`}
                        allowHTML={true}
                    >
                        <span style={CaseLabels.style_tax_label} className={'label tag-label'}>
                            UKDS
                        </span>
                    </Tippy>
                )}
                {vnode.attrs.case.serialized_item_tags &&
                    vnode.attrs.case.serialized_item_tags
                        .split(',')
                        .filter((tag_artkey) => tag_artkey !== '')
                        .map((tag_artkey) => {
                            const item_tag = ItemTagsDropDownData.get_item_tag_for_artkey(tag_artkey)
                            return (
                                <Tippy
                                    content={`<strong>${item_tag?.item_tag_category.name || '?'}</strong>
                                              <div>${item_tag?.name || ''}</div>`}
                                    allowHTML={true}
                                >
                                    <span
                                        style={this.style_for_category(item_tag?.item_tag_category.name)}
                                        className={'label tag-label'}
                                    >
                                        {item_tag?.abbreviation}
                                    </span>
                                </Tippy>
                            )
                        })}
            </span>
        )
    }

    view_as_labels(vnode: m.Vnode<CaseLabelAttrs>): m.Children {
        return (
            <span>
                {vnode.attrs.case.best_before_date && (
                    <span className={'label tag-label'} style={CaseLabels.style_best_before_date}>
                        <b>BBD: </b> {format_iso_to_date(vnode.attrs.case.best_before_date)}
                    </span>
                )}
                {vnode.attrs.case.cases_per_pallet && (
                    <span className={'label tag-label'} style={CaseLabels.style_cases_per_pallet}>
                        <b>CPP: </b> {vnode.attrs.case.cases_per_pallet}
                    </span>
                )}
                {vnode.attrs.case.remark && (
                    <span className={'label tag-label'} style={CaseLabels.style_remark}>
                        <b>REMARK: </b> {vnode.attrs.case.remark}
                    </span>
                )}
                {vnode.attrs.case.tax_label && (
                    <span className={'label tag-label'} style={CaseLabels.style_tax_label}>
                        <b>TAX: </b> {vnode.attrs.case.tax_label}
                    </span>
                )}
                {vnode.attrs.case.serialized_item_tags &&
                    vnode.attrs.case.serialized_item_tags
                        .split(',')
                        .filter((tag_artkey) => tag_artkey !== '')
                        .map((tag_artkey) => {
                            const item_tag = ItemTagsDropDownData.get_item_tag_for_artkey(tag_artkey)
                            return (
                                <span
                                    className={'label tag-label'}
                                    style={this.style_for_category(item_tag?.item_tag_category.name)}
                                >
                                    <b>{item_tag?.item_tag_category.name}: </b>
                                    {item_tag?.name} ({item_tag?.abbreviation})
                                </span>
                            )
                        })}
            </span>
        )
    }

    view(vnode: m.Vnode<CaseLabelAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                {vnode.attrs.show_as_labels && this.view_as_labels(vnode)}
                {vnode.attrs.show_as_icons && this.view_as_label_icons(vnode)}
            </MaybeObservable>
        )
    }
}
