m = require 'mithril'
{group-by, sum, reject} = require 'prelude-ls'
api = require 'api.ls'
{format-date, format-percentage} = require 'utils.ls'
{Spinner} = require '@bitstillery/common/components'
{Icon} = require '@bitstillery/common/components'
{Amount} = require '@bitstillery/common/components'
{icon-with-popover} = require '@/components/icon.ls'
{icon-button} = require '@/components/buttons.ls'


export class OfferItems
    (vnode) ->
        {@offer_item_artkey} = vnode.attrs
        @loading = false
        @items = []
        @query-items!

    query-items: ~>
        if !@offer_item_artkey
            @items = []
            return
        @loading = true
        data = {offer_item_artkey: @offer_item_artkey}
        api.call 'stock.get_stock_records', data, (resp) ~>
            @loading = false
            if resp.success then
                @items = resp.result.stock_items

    unapprove-item: (artkey) ~>
        # TODO confirm are you sure?
        data = {item_artkey: artkey}
        api.call 'approval.unapprove_item', data, (resp) ~>
            if resp.success then
                @items = @items |> reject (.artkey == artkey)
            else
                app.$m.common.generic_error_handler!

    view: -> m '.c-offer-items collection-widget',
        m '.header',
            m '.title', [
                m Icon, {name: 'specialOffer', type: 'info'}
                'Offered items'
            ]
        m '.content',
            if @loading then m Spinner else
                if @items.length > 0
                    m 'table.table.table-condensed',
                        m 'thead.thead-default',
                            m 'tr',
                                m 'th' 'Warehouse'
                                m 'th' 'Entry date'
                                m 'th.number', icon-with-popover do
                                    icon-id: 'home'
                                    content: 'Number of cases in stock'
                                m 'th.number', icon-with-popover do
                                    icon-id: 'shopping-cart'
                                    content: 'Number of cases in purchase'
                                m 'th.number', icon-with-popover do
                                    icon-id: 'screenshot'
                                    content: 'Number of cases in sales'
                                m 'th.number', icon-with-popover do
                                    icon-id: 'fa-shield-alt'
                                    content: 'Number of cases available'
                                m 'th.number', icon-with-popover do
                                    icon-id: 'flash'
                                    content: 'Throughput'
                                m 'th.number' 'Btl / cs'
                                m 'th' 'Gift box'
                                m 'th' 'Tax label'
                                m 'th' 'Customs'
                                m 'th.price' 'Bought for (per case)'
                                m 'th' 'Lot'
                                m 'th' '#'
                        m 'tbody',
                            @items.map (stock, index) ~>
                                m 'tr',
                                    m 'td' stock.warehouse_name
                                    m 'td' format-date(stock.item_entry_date) || \
                                        'ETA: ' + format-date stock.expected_delivery_date
                                    m 'td.number' stock.item_number_of_cases_in_stock || '-'
                                    m 'td.number' stock.item_number_of_cases_in_purchase
                                    m 'td.number' stock.item_number_of_cases_in_sales
                                    m 'td.number' stock.item_number_of_cases_available
                                    m 'td.number',
                                        if stock.item_throughput?
                                        then format-percentage stock.item_throughput
                                        else '- %'
                                    m 'td.number' stock.case_number_of_bottles
                                    m 'td' stock.case_gift_box_type
                                    m 'td' stock.case_tax_label
                                    m 'td' stock.case_customs_status
                                    m 'td.price' m Amount, do
                                        amount: +stock.item_was_bought_for
                                        excise: stock.case_excise_nl
                                        currency: stock.purchase_order_was_bought_in
                                        rate: stock.purchase_order_bought_against_rate
                                    m 'td' stock.item_lot
                                    m 'td',
                                        icon-button 'refresh', do
                                            class: 'btn-default btn-xs no-click'
                                            onclick: ~> @unapprove-item stock.artkey
                                        ' '
                                        icon-button 'fa-expand-arrows-alt', do
                                            class: 'btn-default btn-xs no-click'
                                            onclick: ~> m.route.set "/stock/manage/#{stock.item_reference}"
                else
                    m 'p' 'Not in stock.'
