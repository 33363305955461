import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {Amount} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface LedgerPerPurchaseOrder {
    artkey: number
    ledger_code: number
    ledger_type: number
    ledger_description: number
    purchase_order_reference: string
    amount_in_euro: number
    is_invoiced_on: string
    was_sent_to_financial_information_system_on: string
    supplier_name: string
}

export interface LedgerPurchaseOrderAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class LedgerPurchaseOrderReport
    extends MithrilTsxComponent<LedgerPurchaseOrderAttrs>
    implements RequeryListener
{
    reporting_fetcher = new PagedCollectionFetcher<LedgerPerPurchaseOrder>(
        'reporting.ledger_purchase_order_report.get_all',
        'ledger_code',
    )

    constructor(vnode: m.Vnode<LedgerPurchaseOrderAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<LedgerPurchaseOrderAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <CollectionTable<LedgerPerPurchaseOrder, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Code'}
                    sort_name={'ledger_code'}
                    data_field={(row: LedgerPerPurchaseOrder) => row.ledger_code}
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Description'}
                    data_field={(row: LedgerPerPurchaseOrder) => row.ledger_description}
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Purchase Order'}
                    sort_name={'purchase_order_artkey'}
                    data_field={(row: LedgerPerPurchaseOrder) => (
                        <a href={`#!/purchase-orders/manage/${row.artkey}`}>{row.purchase_order_reference}</a>
                    )}
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Relation'}
                    sort_name={'supplier_name'}
                    data_field={(row: LedgerPerPurchaseOrder) => row.supplier_name}
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Amount'}
                    data_field={(row: LedgerPerPurchaseOrder) => (
                        <Amount amount={row.amount_in_euro} currency={'EUR'} />
                    )}
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Sent to FIS'}
                    sort_name={'was_sent_to_financial_information_system_on'}
                    data_field={(row: LedgerPerPurchaseOrder) =>
                        format_iso_to_date(row.was_sent_to_financial_information_system_on)
                    }
                />
                <CollectionTableColumn<LedgerPerPurchaseOrder>
                    header_title={() => 'Invoiced on'}
                    data_field={(row: LedgerPerPurchaseOrder) => format_iso_to_date(row.is_invoiced_on)}
                />
            </CollectionTable>
        )
    }
}
