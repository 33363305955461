m = require 'mithril'
api = require 'api.ls'
{a}: utils = require 'utils.ls'
{button-with-icon} = require '@/components/buttons.ls'
items_table = require './components/items_table.ls'
mutation_panel = require './components/mutation_panel.ls'
{Button, ButtonGroup} = require '@bitstillery/common/components'
{SalesOrderItems} = require './components/sales_order_items.ls'
{PurchaseOrderItems} = require './components/purchase_order_items.ls'
{CreditItems} = require './components/credit_items.ls'
{Item, ItemMutation} = require '@/models/stock'
app = require('@/app')
{create_download_for_blob} = require '@/factserver_api/api'

module.exports = class ItemView
    ->
        @loading = window.prop false
        @loading_purchase = window.prop false
        @loading_sales = window.prop false
        @loading_credit = window.prop false

        @item_reference = m.route.param 'reference'
        @item = window.prop new Item
        @items = window.prop []
        @purchase_order_item = window.prop null
        @purchase_order_items = window.prop []
        @source_mutation = window.prop new ItemMutation
        @target_mutation = window.prop new ItemMutation
        @sales_order_items = window.prop []
        @credit_items = window.prop []

        @get_item(@item_reference)

    add_source_mutation: (resp) ~>
        if resp.success
            @source_mutation new ItemMutation resp.result

    add_target_mutation: (resp) ~>
        if resp.success
            @target_mutation new ItemMutation resp.result

    download_transactions: ~>>
        file_name = "Item #{@item!lot!} transactions #{utils.format-date(new Date)}.xlsx"

        const {result: excel_blob} = await app.api.get("discover/items/#{@item!artkey!}/sales-order-items/export")
        create_download_for_blob(excel_blob, file_name)

    get_credit_items: (item_artkey) ~>
        @loading_credit true
        api.call 'sales.credit.get_credit_items_by_item' {item_artkey: item_artkey}, @set_credit_items

    get_item: (item_reference) ~>
        @loading_purchase true
        @loading_sales true
        @loading_credit true
        data = do
            item_reference: item_reference
        api.call 'stock.get_item', data, @set_item

    get_lots: (purchase_order_item_artkey) ~>
        @loading_purchase true
        api.call 'stock.get_lots' {purchase_order_item_artkey: purchase_order_item_artkey}, @set_lots

    get_mutation: (item_mutation_artkey, callback) ~>
        data = do
            item_mutation_artkey: item_mutation_artkey
        api.call 'stock.get_item_mutation', data, callback

    get_sales_order_items: (item_artkey) ~>>
        @loading_sales true
        const {result: sales_order_items} = await app.api.get("discover/items/#{item_artkey}/sales-order-items")

        @loading_sales false
        if sales_order_items
            @sales_order_items sales_order_items
            m.redraw()

    set_credit_items: (resp) ~>
        @loading_credit false
        if resp.success
            @credit_items resp.result

    set_item: (resp) ~>
        if resp.success
            @item new Item resp.result
            @items [@item!]
            @purchase_order_item resp.result.purchase_order_item

            @get_lots(resp.result.purchase_order_item.artkey)
            @get_sales_order_items(@item!artkey!)
            @get_credit_items(@item!artkey!)

            if @item!is_target_in!
                @get_mutation(@item!is_target_in!artkey!, @add_target_mutation)

            if @item!is_source_in!
                @get_mutation(@item!is_source_in!artkey!, @add_source_mutation)

    set_lots: (resp) ~>
        @loading_purchase false
        if resp.success
            # By now, the property @purchase_order_item has always been set.
            # Append the lots to it and put them in the @purchase_order_items
            # property, which is used for the purchase_order_items view.
            purchase_order_item = @purchase_order_item!
            purchase_order_item.lots = [item.lot for item in resp.result]
            @purchase_order_items [purchase_order_item]

    recalculate_availabilities: ~>
        api.call-and-then 'stock.recalculate_availabilities' {item_artkey: @item!artkey!}, do
            success: (resp) ~>
                app.notifier.notify 'Updated cached availabilities.', 'success'

    view: -> m '.c-view-item view' a do
        m '.btn-toolbar',
            m ButtonGroup, [
                m Button, {
                    disabled: !@item!can_be_mutated!
                    icon: 'edit',
                    onclick: ~> m.route.set "/stock/manage/#{@item!artkey!}/edit",
                    text: app.$t('stock.actions.item_edit'),
                    type: 'info',
                    variant: 'context'
                }
                m Button, {
                    disabled: !@item!can_be_mutated!,
                    icon: 'wrench',
                    onclick: ~> m.route.set "/stock/manage/#{@item!reference!}/update-cases",
                    text: app.$t('stock.actions.update_cases'),
                    type: 'info',
                    variant: 'context'
                }
                m Button, {
                    disabled: !@item!can_be_mutated!,
                    icon: 'wrench',
                    onclick: ~> m.route.set "/stock/manage/#{@item!reference!}/update-product",
                    text: app.$t('stock.actions.update_product'),
                    type: 'info',
                    variant: 'context'
                }
                m Button, {
                    disabled: !@item!can_be_mutated!,
                    icon: 'wrench',
                    onclick: ~> m.route.set "/stock/manage/#{@item!reference!}/update-product/correct-bottles",
                    text: app.$t('stock.actions.update_bottles_per_case'),
                    type: 'info',
                    variant: 'context'
                }
            ]

            m ButtonGroup,
                m Button, {
                    icon: 'cog',
                    disabled: !@item!artkey!
                    onclick: @recalculate_availabilities
                    text: app.$t('stock.actions.recalculate_availability'),
                    type: 'warning',
                    variant: 'context'
                }

            m Button, {
                active: false,
                className: 'btn-back',
                icon: 'back',
                onclick: ~> m.route.set '/stock/manage'
                variant: 'toggle'
            }

        if @source_mutation!artkey! then [
            m 'h4' 'Replaced in:'
            mutation_panel.mutation_panel @source_mutation
        ]

        m '.panel.panel-primary',
            m '.panel-heading' m '.panel-title' 'Stock'
            m '.panel-body' items_table.items_table @items, do
                show_sales: true
                show_available: true
                include_prices: true

        m PurchaseOrderItems, do
            data: @purchase_order_items
            title: 'Purchase order'
            loading: @loading_purchase

        m SalesOrderItems, do
            data: @sales_order_items
            title: 'Transactions'
            loading: @loading_sales
            download_callback: @download_transactions

        m CreditItems, do
            data: @credit_items
            title: 'Credit order'
            loading: @loading_credit

        if @target_mutation!artkey! then [
            m 'h4' 'Created in:'
            mutation_panel.mutation_panel @target_mutation
        ]
