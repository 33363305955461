/**
 * Contains components and utilities relating to the users entity.
 */
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {CheckboxGroup} from 'components/filter/checkboxgroup.ls'
import {proxy} from '@bitstillery/common/lib/proxy.ts'

import {DropDownOption, DropDownWithSelect, empty_option} from './html_components'

import {api} from '@/app.ts'
import {GetAllUsersResponse} from '@/factserver_api/fact2server_api'

interface UsersDropDownAttrs {
    selected_user_artkey?: string // override local storage option
    local_storage_name?: string
    onchange: (user_artkey: string) => unknown

    only_active_traders?: boolean

    disabled?: boolean
    required?: boolean
    empty_option?: JSX.Element
}

/**
 * A drop down with the msi-users. If the component has a local_storage_name, the last selected
 * user is stored in the local storage. This causes this component to survive browser reloads.
 *
 * Another option is to use the option selected_user_artkey. The local-storage will not be used,
 * the caller can do whatever it pleases with this selected artkey.
 */
export class UserDropDown extends MithrilTsxComponent<UsersDropDownAttrs> {

    users = proxy({
        all_users:  [] as GetAllUsersResponse[],
        selected_user_artkey: '',
    })

    constructor(vnode: m.Vnode<UsersDropDownAttrs>) {
        super()

        this.users.selected_user_artkey = vnode.attrs.selected_user_artkey || ''
        if (vnode.attrs.local_storage_name && !this.users.selected_user_artkey) {
            this.users.selected_user_artkey = localStorage.getItem(vnode.attrs.local_storage_name) || ''
        }
    }

    async oncreate(vnode: m.Vnode<UsersDropDownAttrs>) {
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
        if (status_code > 299) {
            return
        }

        this.users.all_users = result

        if (vnode.attrs.selected_user_artkey !== this.users.selected_user_artkey) {
            vnode.attrs.onchange(this.users.selected_user_artkey)
        }
    }

    onupdate(vnode: m.VnodeDOM<UsersDropDownAttrs>): void {
        if (!vnode.attrs.local_storage_name && this.users.selected_user_artkey !== vnode.attrs.selected_user_artkey) {
            this.users.selected_user_artkey = vnode.attrs.selected_user_artkey || ''
        }
    }

    onchange(vnode: m.Vnode<UsersDropDownAttrs>, selected_artkey: string): void {
        if (vnode.attrs.local_storage_name) {
            localStorage.setItem(vnode.attrs.local_storage_name, selected_artkey)
        }

        this.users.selected_user_artkey = selected_artkey
        vnode.attrs.onchange(selected_artkey)
    }

    view(vnode: m.Vnode<UsersDropDownAttrs>): m.Children {
        return (
            <DropDownWithSelect
                onchange={(selected_artkey: string) => this.onchange(vnode, selected_artkey)}
                empty_option={vnode.attrs.empty_option || empty_option('Select a user...')}
                selected={this.users.selected_user_artkey}
                disabled={vnode.attrs.disabled}
                required={vnode.attrs.required}
                label={vnode.attrs.label}
                validation={vnode.attrs.validation}
            >
                {this.users.all_users.map((user) => (
                    <DropDownOption value={`${user.artkey}`}>{user.name}</DropDownOption>
                ))}
            </DropDownWithSelect>
        )
    }
}

interface UserCheckboxAttrs {
    onchange: (user_artkey: string | null) => unknown

    filter_function: () => any
    filter_id: string
    filter_name: string

    only_active_traders: boolean
}

/**
 * Display the users in a checkbox group. Suitable for 'quickly' selecting some users.
 */
export class UserCheckboxGroup extends MithrilTsxComponent<UserCheckboxAttrs> {
    users: [number, string][] = []

    async oncreate() {
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
        if (status_code > 299) {
            return
        }

        result.map((usr) => [usr.artkey, usr.name]).forEach((usr) => this.users.push(usr))
    }

    view(vnode: m.Vnode<UserCheckboxAttrs>): m.Children {
        return (
            <CheckboxGroup
                filter_function={vnode.attrs.filter_function}
                key={this.users}
                filter_id={vnode.attrs.filter_id}
                filter_name={vnode.attrs.filter_name}
                filter_options={this.users}
            />
        )
    }
}
