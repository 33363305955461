m = require 'mithril'
{ map } = require 'prelude-ls'

api = require 'api.ls'
inputs = require '@/components/inputs'

{after-update} = require 'utils.ls'
{Box} = require '@/components/adminlte/box.ls'
{Chart} = require '@/lib/chart'
{AgeInDays} = require './models.ls'

{GraphDataManager} = require './components/graph_data_manager.ls'

module.exports = class ZeroResultsGraph
    (vnode) ->
        @canvas_id = vnode.attrs.canvas_id

        @age_in_days = window.prop AgeInDays.MONTH
        @zero_result_hits = window.prop null
        @x_axis = window.prop []
        @y_axis = window.prop []

        @bar_chart = window.prop null
        @background_colors = new GraphDataManager().available_colors |> map (color) -> color.background
        @border_colors = new GraphDataManager().available_colors |> map (color) -> color.border


    oncreate: ~>
        @fetch_missed_hits!

    onupdate: (vnode) ~>
        if @bar_chart!
            @bar_chart!data.labels = @x_axis!
            @bar_chart!data.datasets[0].data = @y_axis!
            @bar_chart!update!


    fetch_missed_hits: ~>
        age_in_days = AgeInDays.as_days[@age_in_days!]
        data = do
            age_in_days: age_in_days,
            number_of_hits: 15
        api.call-and-then 'diana.missed_hits_per_period', data, do
            success: (resp) ~>
                @zero_result_hits resp.result
                @x_axis (@zero_result_hits! |> map (term) ->  term.search_term)
                @y_axis (@zero_result_hits! |> map (term) ->  term.count)

    set_age_in_days: (val) ~>
        @age_in_days val
        @fetch_missed_hits!

    oncreate_barchart: ~>
        target = document.getElementById(@canvas_id)

        dataset_1 = do
            label: '# of searches for term',
            data: @y_axis!,
            backgroundColor: @background_colors,
            borderColor: @border_colors,
            borderWidth: 1

        @bar_chart new Chart(target, do
            type: 'bar',
            data: do
                labels: @x_axis!,
                datasets: [ dataset_1]
            options: do
                scales: do
                    y: do
                        beginAtZero: true
        )
        return @bar_chart!


    view: -> m '',
        m Box, do
            cls: ".box-info.bg-gray-light"
            title: 'Top searches without a hit'
            body: ~>
                m '',
                    m '.col-sm-3',
                        inputs.select @age_in_days, AgeInDays.choices, do
                            onchange: @set_age_in_days
                            empty_option: false
                    m '.row',
                        if @zero_result_hits! != null
                            m "canvas##{@canvas_id}", {height: 130px, oncreate: ~> @oncreate_barchart!}
            loading: ~> @zero_result_hits! == null
            collapsed: ~> false
