import {MithrilTsxComponent} from 'mithril-tsx-component'

import {default_chart_options_for, tooltip_label} from './chart_js_utils'

import {Chart} from '@/lib/chart'

interface PieChartAttrs {
    data: number[]
}

/**
 * Draws a pie chart on a given canvas.
 *
 * Usage:
 * Declare a canvas element and pass its id to the constructor:
 * `const the_pie = new PieChart(id_of_canvas_element)`
 *
 * Add some labels:
 * `the_pie_chart.labels(["Portal", "Discover"])`
 *
 * Next, add the data, in the same sequence as the labels:
 * `the_pie_chart?.data([some_number_1, some_number_2])`
 */
export class PieChart extends MithrilTsxComponent<PieChartAttrs> {
    canvas: HTMLCanvasElement
    chart: Chart

    /**
     * Constructs a PieChart.
     *
     * @param canvas_id The id to render the canvas to.
     * @param format_value_as_money If true the value number will be formatted as money (EUR).
     */
    constructor(canvas_id: string, format_value_as_money = false) {
        super()
        const target = document.getElementById(canvas_id) as HTMLCanvasElement
        if (!target) {
            throw Error('Programmer error')
        }
        this.canvas = target
        const config = default_chart_options_for('pie')
        config.options = {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label(context): string | string[] {
                            return tooltip_label(context, format_value_as_money)
                        },
                    },
                },
                legend: {
                    display: true,
                },
            },
        }
        this.chart = new Chart(this.canvas, config)
    }

    data(the_data: number[]): void {
        this.chart.data.datasets.forEach((ds) => (ds.data = the_data))
        this.chart.update()
    }

    onclick(handler: (value: number, label: string) => unknown): void {
        this.chart.options.onClick = (event: any, elements, chart: Chart) => {
            if (elements.length > 0) {
                const dataset_index = elements[0].datasetIndex
                const element_index = elements[0].index
                if (chart.data.datasets && chart.data.datasets.length > dataset_index && chart.data.labels) {
                    const value = chart.data.datasets[dataset_index].data[elements[0].index] as number
                    const label = chart.data.labels[element_index] as string
                    handler(value, label)
                }
            }
        }
    }

    labels(the_data: string[]): void {
        this.chart.data.labels = the_data
        this.chart.update()
    }

    title(the_title: string | string[]): void {
        if (this.chart.options.plugins?.title) {
            this.chart.options.plugins.title.text = the_title
            this.chart.options.plugins.title.display = true
        }
    }

    legend_position(the_position): void {
        if (this.chart.options.plugins) {
            this.chart.options.plugins.legend = {
                position: the_position,
                display: true,
            }
        }
    }

    view(): null {
        return null // the graph writes directly to the canvas.
    }
}
