m = require 'mithril'
{ map } = require 'prelude-ls'

api = require 'api.ls'
inputs = require '@/components/inputs'

{after-update} = require 'utils.ls'
{Box} = require '@/components/adminlte/box.ls'
{Chart} = require '@/lib/chart'
models = require './models.ls'
{create_time_line_graph} = require './components/graph_utils.ls'

{GraphProductTable} = require './components/graph_product_table.ls'
{GraphDataManager} = require './components/graph_data_manager.ls'

module.exports = class ItemStockedGraph
    (vnode) ->
        @canvas_id = vnode.attrs.canvas_id

        @accumulate_graph_data = window.prop false |> after-update @set_accumulate_graph_data
        @graph_function = window.prop models.GraphFunction.NUMBER_OF_CASES
        @line_chart = window.prop null
        @graph_data = new GraphDataManager()

    set_aggregation_level: (val) ~>
        @aggregation_level val
        @requery_datasets_in_graph!

    set_graph_function: (val) ~>
        @graph_function val
        @update_graph_data_with_graph_function!

    update_graph_data_with_graph_function: ~>
        for dataset in @graph_data.data!datasets
            for data in dataset.data
                if @graph_function! == models.GraphFunction.ROLLING_AVERAGE
                    data.y = data.rolling_average
                else if @graph_function! == models.GraphFunction.CUMULATIVE
                    data.y = data.cumulative
                else if @graph_function! == models.GraphFunction.NUMBER_OF_CASES
                    data.y = data.number_of_cases
        @line_chart!update!

    # Remove all the datasets and requery the values.
    requery_datasets_in_graph: ~>
        # Which ones are now in the graph for recalculation.
        cases_to_recalculate = @graph_data.datasets |> map (dataset) -> {
            case_artkey: dataset.item_stocked_record.artkey,
            record: dataset.item_stocked_record
        }

        @graph_data_manager = new GraphDataManager()
        for case_to_recalculate in cases_to_recalculate
            @query_data_for_case(case_to_recalculate.case_artkey, case_to_recalculate.record)
        @line_chart!update!


    # Remove from or add a dataset to the graph. Adding is done by querying the case_artkey datapoints.
    on_update_is_in_graph: (case_artkey, record, value) ~~>
        if value
            @query_data_for_case case_artkey, record
        else
            @graph_data.remove_dataset_for_case_artkey case_artkey
            @line_chart!update!

    query_data_for_case: (case_artkey, record) ~>
        data = do
            case_artkey: case_artkey

        api.call-and-then 'diana.item_stocked.datapoints_in_period', data, do
            success: (resp) ~>
                color = @graph_data.fetch_available_color_for_graph case_artkey
                dataset = {  # Chart.js object
                    record: record,
                    label: "#{record.product_name} #{record.volume} #{record.alcohol_percentage} #{record.number_of_bottles_per_case}/#{record.customs_status}/#{record.refill}/#{record.gift_box_type}/#{record.tax_label}",
                    backgroundColor: color.background,
                    borderColor: color.border,
                    lineTension: 0,
                    spanGaps: true,
                    data: resp.result |> map (data_point) -> {
                        x: data_point.timestamp.split('T')[0],
                        y: data_point.cumulative
                        cumulative: data_point.cumulative,
                        rolling_average: data_point.rolling_average,    # Depending on @graph_function this will be put in the y value, see @update_graph_data_with_graph_function.
                        number_of_cases: data_point.number_of_cases,
                    }
                }
                @graph_data.add_dataset dataset
                @update_graph_data_with_graph_function!
                @line_chart!update!

    oncreate_line_chart: ~>
        target = document.getElementById(@canvas_id)

        @line_chart create_time_line_graph @graph_data.data!, target
        return @line_chart!

    view: -> m '', [
        m Box, do
            cls: ".box-info.bg-gray-light"
            title: 'Stocked items per month in case units'
            body: ~>
                if @graph_data.data! != null
                    m "canvas##{@canvas_id}", {height: 50px, oncreate: ~> @oncreate_line_chart!}
            loading: ~> @graph_data.data! == null
            collapsed: ~> false

        m '.field', do
            m '.field-label', 'Graph function'
            m '.field-value',
                inputs.select @graph_function, models.GraphFunction.choices, do
                    onchange: @set_graph_function
                    empty_option: false

        m GraphProductTable, do
            api_name: 'diana.item_stocked.list_cases_stocked'
            on_update_graph_with_record_artkey_and_record: @on_update_is_in_graph
            artkeys_in_graph: @graph_data.case_artkeys_in_data
    ]
