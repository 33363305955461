m = require 'mithril'
{map} = require 'prelude-ls'
{a, prevent-default} = require 'utils.ls'
api = require 'api.ls'
{button-with-icon} = require '@/components/buttons.ls'
{PortalQuote} = require 'portal/models.ls'
inputs = require '@/components/inputs'
app = require('@/app')

module.exports = class PortalQuotesEdit
    ->
        @quote = new PortalQuote

        @create = false

        artkey = m.route.param 'artkey'
        if artkey
            @query_quote artkey
        else #we are creating a new one
            @create = true

    query_quote: (artkey) ~>
        api.call 'portal.quote.get_single_quote' {artkey: artkey}, @set_quote

    set_quote: (resp) ~>
        if resp.success
            try
                @quote = new PortalQuote resp.result
            catch
                app.$m.common.generic_error_handler e
        else
            app.notifier.notify 'Unknown quote.', 'danger'
            m.route.set '/portal/portal-quotes'

    save: (e) ~>
        e.preventDefault!

        data = do
            artkey: @quote.artkey!
            name: @quote.name!
            content: @quote.content!
            image_name: @quote.image_name!
        api.call 'portal.quote.create_or_update_quote' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            if @create then
                app.notifier.notify "Successfully created new portal quote.", 'success'
                m.route.set '/portal/portal-quotes/' + result.artkey + '/edit'
            else
                app.notifier.notify 'Successfully updated quote.', 'success'


    view: -> m '.c-portal-quotes-edit' a do
        m '.btn-toolbar' a do
            m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/portal/portal-quotes/'},
             (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

        m 'form.flex-form' {onsubmit: @save},
            m '.fieldset',
                m '.field' a do
                    m 'label' {for: 'quote_name'} 'Name'
                    m 'input.form-control' {id: 'quote_name', type: 'text', \
                        required: true, \
                        placeholder: 'quote name', value: @quote.name! or '', \
                        oninput: (ev) ~> @quote.name ev.target.value}
                m '.field' a do
                    m 'label' {for: 'quote_image_name'} 'Image name'
                    m 'input.form-control' {id: 'quote_image_name', type: 'text', \
                        required: true, \
                        placeholder: 'quote image name', value: @quote.image_name! or '', \
                        oninput: (ev) ~> @quote.image_name ev.target.value}
                m '.field' a do
                    m 'label' {for: 'quote_content'} 'Message'
                    m 'textarea.form-control' {id: 'quote_content', rows: 15, \
                        placeholder: 'quote message', value: @quote.content! or '', \
                        oninput: (ev) ~> @quote.content ev.target.value}

            m 'button.btn.btn-success.btn-submit' if @create then 'Create Quote' else 'Update Quote'
