import {Observable, of, Subject} from 'rxjs'
import {filter, mergeAll} from 'rxjs/operators'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmtpyRequest} from './api'

export interface CommentTemplateResponse {
    artkey: number
    title: string
    body: string
}

export class CommentTemplateDropDownData {
    api = new Api()
    /** Singleton instance of this class. */
    private static _instance: CommentTemplateDropDownData | null = null
    /** Cached results, when available */
    private result: CommentTemplateResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<CommentTemplateResponse[]> = new Subject<CommentTemplateResponse[]>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, CommentTemplateResponse[]>('comment_templates.get_all', {})
            .subscribe({
                next: (response: CommentTemplateResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): CommentTemplateDropDownData {
        if (CommentTemplateDropDownData._instance === null) {
            CommentTemplateDropDownData._instance = new CommentTemplateDropDownData()
        }

        return CommentTemplateDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static comment_templates(): Observable<CommentTemplateResponse[]> {
        if (CommentTemplateDropDownData.instance().drop_down_data$.isStopped) {
            return of(CommentTemplateDropDownData.instance().result)
        }

        return CommentTemplateDropDownData.instance().drop_down_data$
    }

    public static comment_template_for_artkey(artkey: number): Observable<CommentTemplateResponse> {
        return CommentTemplateDropDownData.comment_templates().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item) => Number(item.artkey) === artkey),
        )
    }
}
