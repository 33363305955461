import {DateTime} from 'luxon'
import {$s, api, store} from '@bitstillery/common/app'

import {GetAllCurrenciesResponseOutput} from '@/factserver_api/fact2server_api.ts'

export class CurrenciesApi {

    /** Get the latest currencies and store it in $s. Contains a cache mechanism.*/
    async get_all_currencies() {
        return await this._optionally_refresh_store()
    }

    async _optionally_refresh_store() {
        if (!this._needs_update_from_api()) return

        const {result: currency_exchange_rates} = await api.get<GetAllCurrenciesResponseOutput[]>('discover/data/currencies/latest')
        $s.currencies.last_api_call_on = new Date().toISOString()
        $s.currencies.all.forEach((currency) => {
            const cer_eur = currency_exchange_rates.find((cer) => cer.currency === currency)
            if (cer_eur) {
                $s.currencies.exchange_rates[currency].rate = cer_eur.exchange_rate
                $s.currencies.exchange_rates[currency].portal_rate = cer_eur.portal_exchange_rate
                $s.currencies.exchange_rates[currency].refreshed_on = cer_eur.date
            }

        })
        store.save()
        return $s.currencies
    }

    _needs_update_from_api(): boolean {
        const now = DateTime.now()
        const refresh_moment = now.minus({hours: 4})
        const latest = $s.currencies.last_api_call_on
        return !latest || DateTime.fromISO(latest) < refresh_moment
    }
}

export function convert_from_source_to_target(
    amount_to_convert: number,
    source_currency: string,
    target_currency: string,
    original_rate?: number | null,
    target_rate?: number | null,
): number {
    if (source_currency === target_currency) {
        return amount_to_convert
    }

    const original = original_rate || $s.currencies.exchange_rates[source_currency].rate
    const target = target_rate || $s.currencies.exchange_rates[target_currency].rate
    return amount_to_convert * target / original
}

/**
 * Convert the amount_to_convert from source_currency to the default EUR currency. If the source is EUR
 * or the rate is 1, the amount_to_convert is returned.
 */
export function convert_from_currency_to_euro(amount_to_convert: number, source_currency: string, use_rate?: number | null): number {
    let converted_amount = amount_to_convert
    if (source_currency === $s.currencies.default || use_rate === 1) {
        return converted_amount
    }
    let rate = use_rate || $s.currencies.exchange_rates[source_currency].rate
    converted_amount = converted_amount / rate
    return converted_amount
}

export function convert_from_euro_to_currency(amount_to_convert: number, target_currency: string, use_rate?: number | null): number {
    let converted_amount = amount_to_convert
    if (target_currency === $s.currencies.default || use_rate === 1) {
        return converted_amount
    }
    let rate = use_rate || $s.currencies.exchange_rates[target_currency].rate
    converted_amount = converted_amount * rate
    return converted_amount

}
