import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {
    DropDownMultiSelect,
    DropDownOption,
    DropDownWithSelect,
    DropDownWithSelectAttrs,
    empty_option,
} from './html_components'
import {MaybeObservable} from './relation'

import {$m} from '@/app'
import {
    DAMAGES_CATEGORY,
    GENERAL_CATEGORY,
    GetItemTagResponse,
    ItemTagsDropDownData,
    PACK_SIZE_CATEGORY,
    PACKAGING_CATEGORY,
} from '@/factserver_api/item_tags'

export class TaxLabelDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.tax_label_item_tags$()

    constructor() {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.item_tags = response),
        })
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                <DropDownWithSelect
                    onchange={vnode.attrs.onchange}
                    empty_option={empty_option('Tax label')}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected}
                    label={vnode.attrs.label}
                >
                    {this.item_tags.map((item_tag: GetItemTagResponse) => (
                        <DropDownOption value={item_tag.name}>{item_tag.name}</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}

export class CustomsStatusDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    customs_statuses: string[] = ['T1', 'T2']

    constructor() {
        super()
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <DropDownWithSelect
                onchange={vnode.attrs.onchange}
                disabled={vnode.attrs.disabled}
                selected={vnode.attrs.selected}
                label={vnode.attrs.label}
            >
                {this.customs_statuses.map((custom_status: string) => (
                    <DropDownOption value={custom_status}>{custom_status}</DropDownOption>
                ))}
            </DropDownWithSelect>
        )
    }
}

/**
 * DropDown for the serialized item tags in a case. Pass the serialized item tags in the value
 * and receive the modified item tags in the onchange handler:
 *     <PackagingLabelDropDown
          selected={row.case.serialized_item_tags}
          onchange={(value: string) => row.case.serialized_item_tags = value}}
        />
 */
export class PackagingLabelDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    packaging_item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags_for_category$(PACKAGING_CATEGORY)

    constructor() {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.packaging_item_tags = response),
        })
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                <DropDownWithSelect
                    label={vnode.attrs.label}
                    onchange={(selected: string) => {
                        const current_tags = vnode.attrs.selected.split(',')
                        const artkeys_with_out_packaging = current_tags
                            .filter(
                                (tag_artkey) =>
                                    !ItemTagsDropDownData.is_artkey_in_category(tag_artkey, PACKAGING_CATEGORY),
                            )
                            .filter((tag_artkey) => tag_artkey !== '')
                        vnode.attrs.onchange(artkeys_with_out_packaging
                            .concat(selected)
                            .filter((artkey) => artkey !== '')
                            .join(','))
                    }}
                    empty_option={empty_option('Packaging label')}
                    disabled={vnode.attrs.disabled}
                    selected={
                        vnode.attrs.selected
                            .split(',')
                            .find((artkey) => ItemTagsDropDownData.is_artkey_in_category(artkey, PACKAGING_CATEGORY)) ||
                        ''
                    }
                >
                    {this.packaging_item_tags.map((item_tag: GetItemTagResponse) => (
                        <DropDownOption value={`${item_tag.artkey}`}>{item_tag.name}</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}

/**
 * DropDown for the serialized item tags in a case. Pass the serialized item tags in the value
 * and receive the modified item tags in the onchange handler:
 *   <PackSizeDropDown
 *      selected={case.serialized_item_tags}
 *      onchange={(value: string) => case.serialized_item_tags = value}}
 *    />
 */
export class PackSizeDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    packaging_item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags_for_category$(PACK_SIZE_CATEGORY)

    constructor() {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.packaging_item_tags = response),
        })
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                <DropDownWithSelect
                    label={vnode.attrs.label}
                    onchange={(selected: string) => {
                        const current_tags = vnode.attrs.selected.split(',')
                        const artkeys_with_out_packaging = current_tags
                            .filter(
                                (tag_artkey) =>
                                    !ItemTagsDropDownData.is_artkey_in_category(tag_artkey, PACK_SIZE_CATEGORY),
                            )
                            .filter((tag_artkey) => tag_artkey !== '')
                        vnode.attrs.onchange(artkeys_with_out_packaging
                            .concat(selected)
                            .filter((artkey) => artkey !== '')
                            .join(','))
                    }}
                    empty_option={empty_option('Pack size')}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected
                        .split(',')
                        .find((artkey) => ItemTagsDropDownData.is_artkey_in_category(artkey, PACK_SIZE_CATEGORY))}
                >
                    {this.packaging_item_tags.map((item_tag: GetItemTagResponse) => (
                        <DropDownOption value={`${item_tag.artkey}`}>{item_tag.name}</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}

/**
 * DropDown for the serialized item tags in a case. Pass the serialized item tags in the value
 * and receive the modified item tags in the onchange handler.
 * <DamagesDropDown
 *    selected={row.case.serialized_item_tags}
 *    onchange={(value: string) => case.serialized_item_tags = value}}
 * />
 */
export class DamagesDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    packaging_item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags_for_category$(DAMAGES_CATEGORY)
    // need to cache the serialized_item_tags since these are not updated in the onview
    serialized_item_tags: string

    constructor(vnode: m.Vnode<DropDownWithSelectAttrs>) {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.packaging_item_tags = response),
        })

        this.serialized_item_tags = vnode.attrs.selected
    }

    onupdate(vnode: m.Vnode<DropDownWithSelectAttrs, this>): void {
        this.serialized_item_tags = vnode.attrs.selected
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                <DropDownMultiSelect
                    label={vnode.attrs.label}
                    onchange={(selected: string[]) => {
                        const current_tags = this.serialized_item_tags.split(',')
                        const artkeys_with_out_packaging = current_tags
                            .filter(
                                (tag_artkey) =>
                                    !ItemTagsDropDownData.is_artkey_in_category(tag_artkey, DAMAGES_CATEGORY),
                            )
                            .filter((tag_artkey) => tag_artkey !== '')
                        vnode.attrs.onchange(artkeys_with_out_packaging
                            .concat(selected)
                            .filter((artkey) => artkey !== '')
                            .join(','))
                    }}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected
                        .split(',')
                        .filter((artkey) => ItemTagsDropDownData.is_artkey_in_category(artkey, DAMAGES_CATEGORY))}
                >
                    {this.packaging_item_tags.map((item_tag: GetItemTagResponse) => (
                        <DropDownOption value={`${item_tag.artkey}`}>{item_tag.name}</DropDownOption>
                    ))}
                </DropDownMultiSelect>
            </MaybeObservable>
        )
    }
}

/**
 * DropDown for the serialized item tags in a case. Pass the serialized item tags in the value
 * and receive the modified item tags in the onchange handler.
 *
 *<GeneralDropDown
 *     selected={row.case.serialized_item_tags}
 *     onchange={(value: string) => {
 *         row.case.serialized_item_tags = value
 *     }}
 * />
 */
export class GeneralDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    packaging_item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags_for_category$(GENERAL_CATEGORY)
    // need to cache the serialized_item_tags, since it does not update in the view().
    serialized_item_tags: string

    constructor(vnode: m.Vnode<DropDownWithSelectAttrs>) {
        super()
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.packaging_item_tags = response),
        })
        this.serialized_item_tags = vnode.attrs.selected
    }

    onupdate(vnode: m.Vnode<DropDownWithSelectAttrs>): void {
        this.serialized_item_tags = vnode.attrs.selected
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <MaybeObservable observed={this.get_item_tag_response$}>
                <DropDownMultiSelect
                    label={vnode.attrs.label}
                    onchange={(selected: string[]) => {
                        const current_tags = this.serialized_item_tags.split(',')
                        const artkeys_with_out_packaging = current_tags
                            .filter(
                                (tag_artkey) =>
                                    !ItemTagsDropDownData.is_artkey_in_category(tag_artkey, GENERAL_CATEGORY),
                            )
                            .filter((tag_artkey) => tag_artkey !== '')
                        vnode.attrs.onchange(artkeys_with_out_packaging
                            .concat(selected)
                            .join(','))
                    }}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected
                        .split(',')
                        .filter((artkey) => ItemTagsDropDownData.is_artkey_in_category(artkey, GENERAL_CATEGORY))}
                >
                    {this.packaging_item_tags.map((item_tag: GetItemTagResponse) => (
                        <DropDownOption value={`${item_tag.artkey}`}>{item_tag.name}</DropDownOption>
                    ))}
                </DropDownMultiSelect>
            </MaybeObservable>
        )
    }
}

export class GiftBoxTypeDropDown extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        return (
            <DropDownWithSelect {...vnode.attrs} empty_option={vnode.attrs.empty_option ?? empty_option('Gift box')}>
                {$m.data.gift_box_types.map((type) => (
                    <DropDownOption value={type}>{type}</DropDownOption>
                ))}
            </DropDownWithSelect>
        )
    }
}
