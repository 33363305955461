m = require 'mithril'
{throttle} = require '@bitstillery/common/ts_utils'


# Expects a 'table' element as the only direct child.
export class FixedTableHeader
    (vnode) ->
        @handler = null
        @app_element = null


    oncreate: (vnode) ->
        @handler = throttle 100, ~> @scroll-fixed vnode.dom
        @app_element = document.getElementById("app")
        @app_element.addEventListener('scroll', @handler)
        @app_element.addEventListener('resize', @handler)


    onremove: ->
        if @handler then
            @app_element.removeEventListener('scroll', @handler)
            @app_element.removeEventListener('resize', @handler)

    scroll-fixed: (element) ~>
        table = $ element
        thead = table .find 'thead' .first!

        app_element_top = @app_element.getBoundingClientRect!y
        table_top = table[0].getBoundingClientRect!y
        thead_height = thead[0].getBoundingClientRect!height

        if (table_top + thead_height) > app_element_top
            table .find '.thead-copy-remove' .remove!
            thead.remove-class 'sticky'
            thead.remove-attr 'top'
        else if (table_top + thead_height) < app_element_top and not thead.has-class 'sticky' then
            thead_copy = thead.clone!
            thead_copy.insert-after thead
            thead_copy.add-class 'thead-copy-remove'
            thead.add-class 'sticky'
            thead.css do
                top: app_element_top
                width: thead_copy.width!

            # Make the size of the sticky header columns the same of the original copy.
            thead.find 'th' .each (index, th) ->
                $ th .width thead_copy.find('th').eq(index).width!

    view: (vnode) ->
        vnode.children


fix-table-header = (table) -->
    m FixedTableHeader, table


export without-buttons = fix-table-header
export with-buttons = fix-table-header
