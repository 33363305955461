import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Icon} from '@bitstillery/common/components'

import {BottleThroughputResponse} from '@/factserver_api/fact2server_api.ts'

interface BottleThroughputAttrs {
    type: 'Long' | 'Short'
    prefix: string
    throughput: BottleThroughputResponse
}

export class BottleThroughput extends MithrilTsxComponent<BottleThroughputAttrs> {
    view(vnode: m.Vnode<BottleThroughputAttrs>): m.Children {
        if (vnode.attrs.type === 'Long') {
            const bottle_throughput = `
            ${vnode.attrs.prefix}:
            Month - ${vnode.attrs.throughput.last_month},
            Quarter - ${vnode.attrs.throughput.last_quarter},
            Year - ${vnode.attrs.throughput.last_year}
            `
            return <i className={'bottle-throughput'}>
                <Icon name='case' size='s' tip={`${vnode.attrs.type} number of cases`}/>
                {bottle_throughput}
            </i>
        }

        return <i className={'c-bottle-throughput'}>
            <Icon name='case' size='s' tip={`${vnode.attrs.type} number of cases per Month / Quarter / Year`}/>
            {`${vnode.attrs.prefix} ${vnode.attrs.throughput.last_month}/${vnode.attrs.throughput.last_quarter}/${vnode.attrs.throughput.last_year}`}
        </i>
    }
}
