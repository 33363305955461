import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'

import {InputDate, TextInput} from '@/components/html_components'
import {NumberInput} from '@/components/input_numbers'
import {CancelButton, SaveButton} from '@/components/buttons'
import {GetPurchaseOrderResponse} from '@/factserver_api/fact2server_api'
import {PurchaseApi} from '@/factserver_api/purchase_api'

export interface SendPurchaseOrderToLoenderslootAttrs {
    purchase_order: GetPurchaseOrderResponse
    oncancel: () => any
    onsend_completed: () => any
}

export default class SendPurchaseOrderToLoendersloot extends MithrilTsxComponent<SendPurchaseOrderToLoenderslootAttrs> {

    purchase_api = new PurchaseApi()

    send_purchase_order_to_loendersloot(vnode: m.Vnode<SendPurchaseOrderToLoenderslootAttrs>): void {
        const purchase_order = vnode.attrs.purchase_order
        if (!purchase_order.expected_delivery_date) {
            return
        }
        if (purchase_order.expected_delivery_date?.includes('T')) {
            purchase_order.expected_delivery_date = purchase_order.expected_delivery_date?.split('T')[0]
        }
        this.purchase_api.send_purchase_order_to_loendersloot({
            purchase_order_artkey: purchase_order.artkey,
            expected_delivery_date: purchase_order.expected_delivery_date || '',
            warehouse_instruction: purchase_order.warehouse_instruction,
            freight_instruction: purchase_order.freight_instruction,
            frontoffice_instruction: purchase_order.frontoffice_instruction,
            number_of_block_pallets: purchase_order.number_of_block_pallets,
            number_of_euro_pallets: purchase_order.number_of_euro_pallets,
        }).subscribe({
            next: () => {
                notifier.notify('Sent the purchase order to Loendersloot', 'success')
                vnode.attrs.onsend_completed()
            },
        })
    }

    view(vnode: m.Vnode<SendPurchaseOrderToLoenderslootAttrs>) : m.Children {
        const purchase_order = vnode.attrs.purchase_order
        return (
            <div className={'c-send-purchase-order-to-loendersloot'}>
                <form className={'flex-form'} onsubmit={() => this.send_purchase_order_to_loendersloot(vnode)}>
                    <div className={'fieldset'}>
                        <div className={'field'}>
                            <label>Expected delivery date</label>
                            <InputDate
                                value={purchase_order.expected_delivery_date}
                                required={true}
                                onchange={(value: DateTime | null) =>
                                    (purchase_order.expected_delivery_date = value ? value.toISODate() : undefined)}
                            />
                        </div>
                        <div className={'field'}>
                            <label>Number of euro pallets</label>
                            <NumberInput
                                value={purchase_order.number_of_euro_pallets}
                                oninput={(value: number) => purchase_order.number_of_euro_pallets = value}
                            />
                        </div>
                        <div className={'field'}>
                            <label>Number of block pallets</label>
                            <NumberInput
                                value={purchase_order.number_of_block_pallets}
                                oninput={(value: number) => purchase_order.number_of_block_pallets = value}
                            />
                        </div>
                        <div className={'field'}>
                            <label>Frontoffice instruction</label>
                            <TextInput
                                value={purchase_order.frontoffice_instruction}
                                oninput={(value: string | null) => purchase_order.frontoffice_instruction = value || undefined}
                            />
                        </div>
                        <div className={'field'}>
                            <label>Warehouse instruction</label>
                            <TextInput
                                value={purchase_order.warehouse_instruction}
                                oninput={(value: string | null) => purchase_order.warehouse_instruction = value || undefined}
                            />
                        </div>
                        <div className={'field'}>
                            <label>Freight instruction</label>
                            <TextInput
                                value={purchase_order.freight_instruction}
                                oninput={(value: string | null) => purchase_order.freight_instruction = value || undefined}
                            />
                        </div>
                        <div className={'btn-toolbar'}>
                            <SaveButton type={'submit'} title={'Send'} icon_class={'glyphicon glyphicon-export'}/>
                            <CancelButton title={'Cancel'} onclick={vnode.attrs.oncancel} />
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}
