import m from 'mithril'
import {odd} from 'prelude-ls'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {get_descendant_prop} from '@bitstillery/common/lib/utils'

// @ts-ignore
import {Collection} from '@/components/collection/collection.ls'
// @ts-ignore
import SearchInput from '@/components/collection/search_input.ls'
// @ts-ignore
import fixedTableHeader from '@/components/table/fixed-header.ls'

export interface GenericListColumn {
    key: string
    label: string
    function?: (entity: any) => m.Children
}

export interface GenericListAttrs<T> {
    list_api_call: string
    is_match: (entity: T, term: string) => boolean
    additional_params?: Record<string, unknown>
    not_sortable?: boolean
    nocreate?: boolean
    nodetail?: boolean
    noedit?: boolean
    parent_url?: string
    parent_name?: string
    requery_subscribe_key?: string
    base_url?: string
    readable_entity_name?: string
    readable_entity_plural?: string
    columns: Array<GenericListColumn>
}

export class GenericList<T> extends MithrilTsxComponent<GenericListAttrs<T>> {
    entities: Collection
    search_input_ctrl: any
    nocreate?: boolean
    nodetail?: boolean
    noedit?: boolean
    parent_url?: string
    parent_name: string
    not_sortable: boolean
    base_url?: string
    readable_entity_name?: string
    columns: Array<GenericListColumn>
    readable_entity_plural: string

    constructor(vnode: m.Vnode<GenericListAttrs<T>>) {
        super()
        this.entities = new Collection({
            api_function_name: vnode.attrs.list_api_call,
            filter_function: vnode.attrs.is_match,
            additional_params: vnode.attrs.additional_params,
            query_limit: 25,
            default_sort_by: vnode.attrs.not_sortable ? '' : 'name',
            default_sort_order: 'asc',
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.entities,
            placeholder: `Search for ${vnode.attrs.readable_entity_name}...`,
        })

        this.nocreate = vnode.attrs.nocreate
        this.nodetail = vnode.attrs.nodetail
        this.noedit = vnode.attrs.noedit
        this.parent_url = vnode.attrs.parent_url
        this.parent_name = vnode.attrs.parent_name || 'parent'
        this.not_sortable = vnode.attrs.not_sortable || false

        this.base_url = vnode.attrs.base_url
        this.readable_entity_name = vnode.attrs.readable_entity_name
        this.columns = vnode.attrs.columns

        if (vnode.attrs.readable_entity_plural) {
            this.readable_entity_plural = vnode.attrs.readable_entity_plural
        } else {
            this.readable_entity_plural = `${this.readable_entity_name}s`
        }
    }

    oncreate() {
        this.entities.init()
    }

    onclick(e: Event, artkey: string) {
        if (
            // @ts-ignore
            !((e.target as HTMLElement).tagName.toLowerCase() === 'a') &&
            !this.nodetail
        ) {
            m.route.set(this.base_url + artkey + '/edit')
        }
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    view(): void | m.Children {
        return (
            <div class="c-generic-list view">
                {(!this.nocreate || this.parent_url) && (
                    <div class="btn-toolbar">
                        {this.parent_url && (
                            <button
                                class="btn btn-default"
                                type="button"
                                onclick={() => m.route.set(this.parent_url as string)}
                            >
                                <span class="glyphicon glyphicon-arrow-left"></span>
                                {' '}
                                Back to {this.parent_name}
                            </button>
                        )}
                        {!this.nocreate && (
                            <button
                                class="btn btn-default"
                                type="button"
                                onclick={() => m.route.set(`${this.base_url}create`)}
                            >
                                <span class="glyphicon glyphicon-plus"></span>
                                {' '}
                                Create {this.readable_entity_name}
                            </button>
                        )}
                    </div>
                )}
                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                </div>
                {fixedTableHeader.withButtons(
                    <table class="table search-table clickable">
                        <thead class="thead-default">
                            <tr>
                                {this.columns.map((field) => {
                                    if (this.not_sortable) {
                                        return <th>{field.label}</th>
                                    } else {
                                        return (
                                            <th
                                                class="sortable"
                                                onclick={this.entities.sort.bind(
                                                    this,
                                                    field.key,
                                                )}
                                            >
                                                {field.label}{' '}
                                                {this.entities.sort_icon(field.key)}
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.entities.search_result() && this.entities.search_result().map((entity, index) => (
                                <tr
                                    class={odd(index) ? 'odd' : 'even'}
                                    onclick={(e: Event) => {
                                        if (!this.noedit) {
                                            this.onclick(e, entity.artkey)
                                        }
                                    }}
                                >
                                    {this.columns.map((column) => {
                                        const value = column.function
                                            ? column.function(entity)
                                            : get_descendant_prop(
                                                entity,
                                                column.key,
                                            )
                                        let contents
                                        if (value === true) {
                                            contents = <i className="fas fa-check-circle"/>
                                        } else if (value === false) {
                                            contents = <i className="fas fa-times-circle"/>
                                        } else {
                                            contents = value
                                        }
                                        return <td class="col-sm-2">{contents}</td>
                                    })}
                                </tr>
                            ))}
                            {!this.entities.search_result().length && !this.entities.loading() && (
                                <tr>
                                    <td colspan="100%">No results found</td>
                                </tr>
                            )}
                            {this.entities.loading() && (
                                <tr>
                                    <td colspan="100%"><Spinner/></td>
                                </tr>
                            )}
                            {this.entities.can_show_more_items() && (
                                <tr>
                                    <td class="footer" colspan="100%">
                                        <button
                                            class="btn btn-info"
                                            onclick={this.entities.show_more}
                                            id="show-more"
                                        >
                                        Show more results
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>,
                )}
            </div>
        )
    }
}
