import m from 'mithril'

import {GenericList} from '../generic/generic_list'

interface AttachmentCategory {
    name: string
    attachment_type: string
}

const AttachmentCategoryList: m.ClosureComponent<void> = () => {
    const is_match = (attachment_category: AttachmentCategory, term: string): boolean => {
        return [attachment_category.name.toLowerCase().indexOf(term.toLowerCase()) > -1].some(
            (element) => element === true,
        )
    }

    return {
        view(): m.Children {
            return (
                <GenericList<AttachmentCategory>
                    readable_entity_name="Attachment Category"
                    readable_entity_plural="Attachment Categories"
                    base_url="/data/attachment-categories/"
                    list_api_call="attachment.get_all_attachment_categories"
                    is_match={is_match}
                    columns={[
                        {key: 'name', label: 'Name'},
                        {key: 'attachment_type', label: 'Attachment type'},
                        {key: 'loendersloot_document_types', label: 'Loendersloot document types'},
                    ]}
                />
            )
        },
    }
}

export default AttachmentCategoryList
