m = require 'mithril'
{Warehouse} = require '@/models/data'
{compact, filter, group-by, head, map} = require 'prelude-ls'

{Account} = require '@/models/accounts'
{User} = require '@/models/users'
app = require('@/app')

export class ItemMutationReason
    @STOCK_DIFFERENCE = 'Stock Difference'
    @DAMAGES = 'Damages'
    @MOVE_TO_WAREHOUSE = 'Move to Warehouse'
    @CORRECT_BOTTLES_PER_CASE = 'Correct bottles per case'
    @CHANGE_LOT_NR = 'Change lot number'
    @OTHER = 'Other'

    # All is not actually 'all reasons', but a subset which is used in
    # inputs.
    @all = do
        (@STOCK_DIFFERENCE): 'Stock difference'
        (@DAMAGES): 'Damages'
        (@CHANGE_LOT_NR): 'Change lot number'
        (@OTHER): 'Other'
    @update_cases_options = do
        (@STOCK_DIFFERENCE): 'Stock difference'
        (@DAMAGES): 'Damages'
        (@OTHER): 'Other'

    @options = do
        (@STOCK_DIFFERENCE): 'Stock difference'
        (@DAMAGES): 'Damages'
        (@MOVE_TO_WAREHOUSE): 'Move to warehouse'
        (@CORRECT_BOTTLES_PER_CASE): 'Correct bottles per case'
        (@CHANGE_LOT_NR): 'Change lot number'
        (@OTHER): 'Other'


export class ItemMutationStatus
    @INSTOCK = 'In stock'
    @INTRANSIT = 'In transit'


export class Item
    (json) ->
        {sales_order_items_dm} = require '@/sales_orders/models.ls'
        {PurchaseOrderItem} = require '@/models/purchase_orders'

        @artkey = window.prop ''
        @account = window.prop new Account
        @account_artkey = window.prop ''
        @reference = window.prop ''
        @purchase_order_item_artkey = window.prop ''
        @purchase_order_item = window.prop new PurchaseOrderItem
        @sales_order_items = window.prop []
        @lot = window.prop ''
        @entry_date = window.prop ''
        @number_of_bottles_per_case = window.prop ''
        @number_of_cases = window.prop ''
        @item_description = window.prop ''
        @case_artkey = window.prop ''
        @case = window.prop new Case
        @warehouse_artkey = window.prop ''
        @warehouse = window.prop new Warehouse
        @is_source_in_artkey = window.prop ''
        @is_source_in = window.prop new ItemMutation
        @is_part_source_in_artkey = window.prop ''
        @is_part_source_in = window.prop new ItemMutationPart
        @is_target_in_artkey = window.prop ''
        @is_target_in = window.prop new ItemMutation
        @is_part_target_in_artkey = window.prop ''
        @is_part_target_in = window.prop new ItemMutationPart
        @is_in_stock = window.prop ''
        @number_of_cases_in_stock = window.prop ''
        @number_of_cases_in_purchase = window.prop ''
        @number_of_shipped_cases = window.prop ''
        @number_of_cases_in_sales = window.prop ''
        @number_of_cases_available = window.prop ''
        @was_bought_for = window.prop ''
        @was_bought_for_plus_costs = window.prop ''
        @euro_was_bought_for = window.prop ''
        @euro_was_bought_for_plus_costs = window.prop ''
        @creation_date = window.prop ''
        @neutral_case = window.prop ''
        @bottle_gtin_code = window.prop ''
        @case_gtin_code = window.prop ''
        @list_price_per_case = window.prop null
        @loendersloot_inspection_item = window.prop {}

        @hide_from_pricelist_for_suppliers = window.prop []
        @hide_from_pricelist_for_countries = window.prop []

        @cases_per_pallet = window.prop null
        @cases_per_pallet_layer = window.prop null
        @remark = window.prop ''
        @country_of_origin = window.prop ''

        # Shortcuts to purchase order.
        @was_bought_in = window.prop ''
        @bought_against_rate = window.prop ''
        @purchase_order_artkey = window.prop ''
        @purchase_order_reference = window.prop ''
        @purchase_order_account_slug = window.prop ''

        for prop of json
            if prop == 'purchase_order_item' and json[prop]
                @purchase_order_item_artkey json[prop].artkey
                @purchase_order_item new PurchaseOrderItem json[prop]
            else if prop == 'case'
                @case_artkey json[prop].artkey
                @case new Case json[prop]
            else if prop == 'account'
                @account_artkey json[prop].artkey
                @account new Account json[prop]
            else if prop == 'warehouse' and json[prop]
                @warehouse_artkey json[prop].artkey
            else if prop == 'is_source_in' and json[prop]
                @is_source_in new ItemMutation json[prop]
                @is_source_in_artkey json[prop].artkey
            else if prop == 'is_target_in' and json[prop]
                @is_target_in new ItemMutation json[prop]
                @is_target_in_artkey json[prop].artkey
            else if prop == 'is_part_source_in' and json[prop]
                @is_part_source_in new ItemMutationPart json[prop]
                @is_part_source_in_artkey json[prop].artkey
            else if prop == 'is_part_target_in' and json[prop]
                @is_part_target_in new ItemMutationPart json[prop]
                @is_part_target_in_artkey json[prop].artkey
            else if prop == 'sales_order_items'
                @sales_order_items sales_order_items_dm.create_sales_order_items json[prop]
            else
                @[prop] = window.prop json[prop]

        @bottle_artkey = @case!bottle_artkey
        @bottle = @case!bottle
        @number_of_bottles_per_case = @case!number_of_bottles
        @gift_box_type = @case!gift_box_type
        @tax_label = @case!tax_label
        @customs_status = @case!customs_status
        @best_before_date = @case!best_before_date
        @item_tags = @case!item_tags

    can_be_mutated: ~>
        # It cannot be mutated, if it has been mutated already.
        has_been_mutated = !!@is_source_in_artkey!
        if has_been_mutated
            return false

        # If the item is the result of a mutation, and the mutation is in transit, and it has a lot, then it is
        # an item which is a leftover from a Warehouse Move Mutation. These leftover items can be edited.
        is_result_of_mutation = !@is_target_in_artkey!
        if is_result_of_mutation
            if @is_target_in! and @is_target_in!status! === ItemMutationStatus.INTRANSIT
                if @lot! != ''
                    return true

        # It cannot be mutated when it is not yet in stock. Then the purchase order item should be edited.
        if not @is_in_stock!
            return false
        return true

    item_tags_per_category: ->
        @item_tags!
        |> map app.$m.data.item_tag.get_item_tag
        |> compact
        |> group-by (.category_artkey!)

    toObject: ~>
        return do
            artkey: +@artkey!
            purchase_order_item_artkey: +@purchase_order_item_artkey!
            lot: @lot!
            entry_date: @entry_date!
            number_of_cases: +@number_of_cases!
            number_of_bottles_per_case: +@number_of_bottles_per_case!
            bottle_artkey: +@bottle_artkey!
            customs_status: @customs_status!
            country_of_origin: @country_of_origin!
            gift_box_type: @gift_box_type!
            warehouse_artkey: +@warehouse_artkey!
            number_of_cases_in_stock: +@number_of_cases_in_stock!
            tax_label: @tax_label!
            best_before_date: @best_before_date!
            cases_per_pallet: if @cases_per_pallet! then +@cases_per_pallet! else null
            remark: @remark!
            item_tags: @item_tags!


export class ItemMutation
    (json) ->
        @artkey = window.prop ''
        @reference = window.prop ''
        @created_on = window.prop new Date
        @item_mutation_reason = window.prop ''
        @user_artkey = window.prop ''
        @user = window.prop new User
        @description = window.prop ''
        @number_of_cases = window.prop ''
        @expected_delivery_date = window.prop ''
        @target_warehouse_artkey = window.prop ''
        @target_warehouse = window.prop new Warehouse
        @stock_value_difference = window.prop ''
        @transport_costs = window.prop ''
        @sources = window.prop []
        @targets = window.prop []
        @status = window.prop ''
        @parts = window.prop []
        @incoterm = window.prop ''
        @incoterm_location = window.prop ''
        @number_of_euro_pallets = window.prop ''
        @number_of_block_pallets = window.prop ''


        for prop of json
            @[prop] = window.prop json[prop]
            if prop == 'sources' and json[prop]
                @sources [new Item item for item in json[prop]]
            else if prop == 'targets' and json[prop]
                @targets [new Item item for item in json[prop]]
            else if prop == 'target_warehouse' and json[prop]
                @target_warehouse app.$m.data.warehouses.get_warehouse json[prop].artkey
                @target_warehouse_artkey json[prop].artkey
            else if prop == 'user'
                @user app.$m.users.create_user json[prop]
                @user_artkey @user!artkey!

        @parts @parts!map (part) ~>
            part.item_mutation_artkey = @artkey!
            part.sources = @sources!filter (.is_part_source_in_artkey! == part.artkey)
            part.targets = @targets!filter (.is_part_target_in_artkey! == part.artkey)
            new ItemMutationPart part


export class ItemMutationPart
    (json) ->
        @artkey = window.prop ''
        @item_mutation = window.prop new ItemMutation
        @item_mutation_artkey = window.prop ''
        @sources = window.prop []
        @targets = window.prop []

        for prop of json
            if prop == 'item_mutation' and json[prop]
                @item_mutation new ItemMutation json[prop]
                @item_mutation_artkey json[prop].artkey
            else
                @[prop] = window.prop json[prop]

    source_item: ~>
        @sources![0]

    target_item: ~>
        @targets! |> filter (.lot! == '') |> head

    leftover_item: ~>
        @targets! |> filter (.lot! != '') |> head


export class Case
    (json) ->
        {Bottle} = require '@/models/bottles'
        @artkey = window.prop ''
        @bottle_artkey = window.prop ''
        @bottle = window.prop new Bottle
        @number_of_bottles = window.prop ''
        @gift_box_type = window.prop ''
        @tax_label = window.prop ''
        @customs_status = window.prop ''
        @best_before_date = window.prop ''
        @item_tags = window.prop []
        @no_eu_address = window.prop false
        @cases_per_pallet = window.prop ''
        @cases_per_pallet_layer = window.prop ''

        for prop of json
            if prop == 'bottle'
                @bottle_artkey json[prop].artkey
                @bottle app.$m.bottles.create_bottle json[prop], app.$m.products.create_product json[prop]['product']
            else if prop == 'item_tags'
                @item_tags (json[prop] or [])
            else
                @[prop] = window.prop json[prop]

    item_tags_per_category: ->
        @item_tags!
        |> map app.$m.data.item_tag.get_item_tag
        |> compact
        |> group-by (.category_artkey!)

    toJS: ~> do
        artkey: +@artkey!
        bottle_artkey: +@bottle_artkey!
        number_of_bottles: +@number_of_bottles!
        gift_box_type: @gift_box_type!
        tax_label: @tax_label!
        customs_status: @customs_status!
        best_before_date: @best_before_date!
        item_tags: @item_tags!
        no_eu_address: @no_eu_address!
        cases_per_pallet: +@cases_per_pallet!
        cases_per_pallet_layer: +@cases_per_pallet_layer!


export class StockSearchMode
    @NOT_INVOICED = 'not_invoiced'
    @AVAILABLE_ONLY = 'available_only'
    @INSTOCK_ONLY = 'instock_only'
    @INPURCHASE_ONLY = 'inpurchase_only'
    @INSALE_ONLY = 'insale_only'
    @INTRANSIT_ONLY = 'intransit_only'
    @AVAILABLE_OR_INSTOCK = 'available_or_instock'
    @INSTOCK_AND_NOT_AVAILABLE = 'instock_and_not_available'
    @INSALE_OTHER_ACCOUNT = 'insale_other_account'
    @NO_GTIN_PHOTOS_IN_STOCK = 'no_gtin_photos_in_stock'
    @NO_GTIN_PHOTOS = 'no_gtin_photos_all'
    @NO_BOTTLE_LOT = 'no_bottle_lot'
    @ALL = 'all'

    @options = do
        (@NOT_INVOICED): 'Show items that are not yet invoiced'
        (@AVAILABLE_ONLY): 'Only show available items'
        (@INSTOCK_ONLY): 'Only show items that are in stock'
        (@INPURCHASE_ONLY): 'Only show items that are in purchase orders'
        (@INSALE_ONLY): 'Only show items that are in sale orders'
        (@INTRANSIT_ONLY): 'Only show items that are in transit'
        (@AVAILABLE_OR_INSTOCK): 'Show items that are available or in stock'
        (@INSTOCK_AND_NOT_AVAILABLE): 'Show items that are in stock and not available'
        (@INSALE_OTHER_ACCOUNT): 'Show items that are in a sales order in another account'
        (@NO_GTIN_PHOTOS): 'Show items that have photos, no GTIN'
        (@NO_GTIN_PHOTOS_IN_STOCK): 'Show items that have photos, in stock, no GTIN'
        (@NO_BOTTLE_LOT): 'Show available items without a bottle lot'
        (@ALL): 'Show all items'
