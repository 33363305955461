import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {DefaultButton} from '@/components/buttons'
import {ChangePasswordRequest, UserManagementApi} from '@/factserver_api/user_management_api'

export default class ChangePassword extends MithrilTsxComponent<unknown> {
    api = new UserManagementApi()

    change_password_request: ChangePasswordRequest = {
        current_password: '',
        new_password: '',
    }

    repeat_new_password = ''

    change_password(): void {
        if (this.change_password_request.current_password === '') {
            notifier.notify('Unable to change password: Please fill in your current password', 'danger')
            return
        }

        if (this.change_password_request.new_password === '') {
            notifier.notify('Unable to change password: Please fill in a new password', 'danger')
            return
        }

        if (this.change_password_request.new_password !== this.repeat_new_password) {
            notifier.notify(
                'Unable to change password: Please make sure the new password and the repeated password are the same',
                'danger',
            )
            return
        }

        if (this.change_password_request.new_password.length <= 7) {
            notifier.notify('Unable to change password: Your new password is not strong enough', 'danger')
            return
        }

        if (this.change_password_request.new_password === this.change_password_request.current_password) {
            notifier.notify('Unable to change password: Your new and current password cannot be the same', 'danger')
            return
        }

        this.api.change_password(this.change_password_request).subscribe({
            next: () => {
                localStorage.removeItem('token')
                m.route.set('/login')
                notifier.notify('Successfully changed password. Please log in again.', 'success')
            },
        })
    }

    view(): m.Children {
        return (
            <div>
                <form className="flex-form">
                    <div className="fieldset">
                        <div className="fieldset-label">Update Password</div>
                        <div className="field">
                            <label>Current password</label>
                            <input
                                placeholder="Your current password"
                                type={'password'}
                                required={true}
                                oninput={(event: InputEvent) =>
                                    (this.change_password_request.current_password = event.target.value)
                                }
                            />
                        </div>
                        <div className="field">
                            <label>New password</label>
                            <input
                                placeholder="Your new password"
                                type={'password'}
                                required={true}
                                oninput={(event: InputEvent) =>
                                    (this.change_password_request.new_password = event.target.value)
                                }
                            />
                        </div>

                        <div className="field">
                            <label>Repeat new password</label>
                            <input
                                placeholder="Verify your new password"
                                type={'password'}
                                required={true}
                                oninput={(event: InputEvent) => (this.repeat_new_password = event.target.value)}
                            />
                        </div>

                        <DefaultButton
                            icon_class={'glyphicon glyphicon-log-out'}
                            additional_class={'btn-success'}
                            type={'submit'}
                            onclick={(e) => {
                                e.preventDefault()
                                this.change_password()
                            }}
                            title={' Change password'}
                        />
                    </div>
                </form>
            </div>
        )
    }
}
