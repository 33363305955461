import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface BadgeAttrs {
    type?: string // 'primary' (default), 'success', 'danger', 'info'
    additional_classes?: string
    onclick?: (event: MouseEvent) => unknown
}

export class Badge extends MithrilTsxComponent<BadgeAttrs> {
    view(vnode: m.Vnode<BadgeAttrs>) {
        const type = vnode.attrs.type || 'primary'
        return <span
            onclick={vnode.attrs.onclick}
            className={`badge badge-${type} ${vnode.attrs.additional_classes || ''}`}>
            {vnode.children}
        </span>
    }
}
