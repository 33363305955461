import m from 'mithril'

export function set_url_params<ParamModel>(params: ParamModel): void {
    const base_route = m.route.get().split('?')[0]
    params = JSON.parse(JSON.stringify(params)) // remove undefined values
    m.route.set(base_route, params)
}

export function get_url_params<ParamModel>(): ParamModel {
    if (m.route.get().split('?').length < 2) {
        return {} as ParamModel
    }
    return m.parseQueryString(m.route.get().split('?')[1]) as ParamModel
}

export function clear_url_params(): void {
    m.route.set(m.route.get().split('?')[0])
}
