export validate_bottle_gtin: (gtin) ~>
    if gtin.match(/^[0-9]+$/) == null
        return "Please enter a bottle GTIN that does not contain none numeric characters"

    if gtin.length not in [12, 13]
        return "Please enter a bottle GTIN that contains either 12 or 13 digits"

    if not @validate_check_digit(gtin)
        return "Please check if you have entered the bottle GTIN correctly as it does not seem to be valid"

export validate_check_digit: (gtin) ~>
    gtin_min_check = gtin.substring(0, gtin.length-1).split('').map(Number).reverse()

    sum = 0
    i = 1
    for digit in gtin_min_check
        if i % 2 == 0
            sum += digit
        else
            sum += digit*3
        i++

    check_digit = (10-(sum)%10)%10
    same_digit_bool = check_digit.toString() == gtin.charAt(gtin.length-1)
    return same_digit_bool
