import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {sum} from '@bitstillery/common/ts_utils'
import {format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {notifier} from '@bitstillery/common/app'

import {AddToOrder, AddToOrderTboItem} from '@/purchase_orders/components/add_to_order'
import {DefaultButton, SaveButton} from '@/components/buttons'
import {NumberInput} from '@/components/input_numbers'
import {CheckBox} from '@/components/html_components'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {
    GetPurchaseOrderItemsCollectionViewResponse,
    GetPurchaseOrderResponse,
    GetRelatedSalesOrderTBOItemResponse,
} from '@/factserver_api/fact2server_api'

interface SplitTboItemsAttrs {
    purchase_order_item: GetPurchaseOrderItemsCollectionViewResponse
    purchase_order: GetPurchaseOrderResponse
    done: () => void
    cancel: () => void
    switch_to_poi: () => void
}

export class SplitTboItems extends MithrilTsxComponent<SplitTboItemsAttrs> {
    purchase_order_item: GetPurchaseOrderItemsCollectionViewResponse
    purchase_order: GetPurchaseOrderResponse
    done: () => void
    cancel: () => void
    switch_to_poi: () => void

    tbo_items: GetRelatedSalesOrderTBOItemResponse[] = []

    total_cases_in_tbo: number
    current_leftover_cases_in_tbo: number
    pending_leftover_cases_in_tbo: number

    split_cases: number[] = []
    lower_number_of_cases_in_poi: boolean[] = []
    poi_number_of_cases: number
    new_poi_number_of_cases: number
    tbo_item_to_purchase: number | null
    new_tbo_item_data: AddToOrderTboItem | null

    purchase_api = new PurchaseApi()

    saving = false

    constructor(vnode: m.Vnode<SplitTboItemsAttrs>) {
        super()

        this.purchase_order_item = vnode.attrs.purchase_order_item
        this.purchase_order = vnode.attrs.purchase_order
        this.done = vnode.attrs.done
        this.cancel = vnode.attrs.cancel
        this.switch_to_poi = vnode.attrs.switch_to_poi

        // Sort the TBO items by "created_on, descending" and put them in an attribute so we can use it easily.

        this.total_cases_in_tbo = this.purchase_order_item.number_of_cases_in_tbo
        this.current_leftover_cases_in_tbo = this.total_cases_in_tbo
        this.pending_leftover_cases_in_tbo = this.total_cases_in_tbo

        this.poi_number_of_cases = this.purchase_order_item.number_of_cases
        this.new_poi_number_of_cases = this.poi_number_of_cases
        this.tbo_item_to_purchase = null
        this.new_tbo_item_data = null
    }

    oncreate(): void {
        this.load_related_tbo_items()
    }

    load_related_tbo_items(): void {
        this.purchase_api.purchase_order_item_related_tbo(this.purchase_order_item.purchase_order_artkey, this.purchase_order_item.artkey)
            .subscribe({
                next: (response) => {
                    this.tbo_items = response.sort((a, b) => (a.created_on < b.created_on ? 1 : -1))
                    this.split_cases = new Array<number>(this.tbo_items.length).fill(0)
                    this.lower_number_of_cases_in_poi = new Array<boolean>(this.tbo_items.length).fill(false)
                    m.redraw()
                },
            })
    }

    /**
     * Splits a TBO item in two.
     *
     * @param {number} tbo_item_index: the index of the TBO item in `this.tbo_items` to split.
     */
    split_to_tbo(tbo_item_index: number): void {
        this.saving = true
        const split_number_of_cases = this.split_cases[tbo_item_index]
        const lower_number_of_cases_in_poi = this.lower_number_of_cases_in_poi[tbo_item_index]
        const tbo_item: GetRelatedSalesOrderTBOItemResponse = this.tbo_items[tbo_item_index]

        this.purchase_api
            .split_tbo_to_new_tbo({
                tbo_item_artkey: tbo_item.tbo_artkey,
                number_of_cases: split_number_of_cases,
                lower_number_of_cases_in_poi: lower_number_of_cases_in_poi,
            })
            .subscribe(() => {
                notifier.notify(
                    `Successfully split the item in ${this.tbo_items[tbo_item_index].sales_order_reference} into a new item with ${split_number_of_cases} cases.`,
                    'success',
                )

                // Update local state.
                this.tbo_items[tbo_item_index].number_of_cases -= split_number_of_cases
                this.current_leftover_cases_in_tbo -= split_number_of_cases
                this.split_cases[tbo_item_index] = 0
                if (this.tbo_items[tbo_item_index].number_of_cases === 0) {
                    // If the leftover TBO item has 0 cases left, remove it.
                    this.tbo_items.splice(tbo_item_index, 1)
                }
                if (lower_number_of_cases_in_poi) {
                    this.poi_number_of_cases -= split_number_of_cases
                    this.purchase_order_item.number_of_cases = this.poi_number_of_cases
                }
                this.saving = false

                // Re-render the input, and the new total number of cases.
                m.redraw()
            })
    }

    /**
     * Initiates the process of splitting a TBO item to a new POI.
     *
     * @param {number} tbo_item_index: the index of the TBO item in `this.tbo_items` to split.
     */
    split_to_poi(tbo_item_index: number): void {
        const tbo_item: GetRelatedSalesOrderTBOItemResponse = this.tbo_items[tbo_item_index]

        this.saving = true
        this.tbo_item_to_purchase = tbo_item_index

        // Construct the data needed to represent a TBO item in the AddToOrder
        // component, conforming to the AddToOrderTboItem interface.
        // Setting this attribute opens the AddToOrder component in the view.
        this.new_tbo_item_data = {
            sales_order: {
                artkey: tbo_item.sales_order_artkey,
                buyer: {
                    name: tbo_item.relation_name,
                },
            },
            artkey: tbo_item.tbo_artkey,
            case_artkey: tbo_item.case_artkey,
            number_of_cases: this.split_cases[tbo_item_index],
        }
    }

    /**
     * Updates the local state after splitting a TBO item to a new POI.
     */
    process_added_poi(): void {
        if (this.tbo_item_to_purchase !== null) {
            // Update local state.
            const split_number_of_cases = this.split_cases[this.tbo_item_to_purchase]
            this.tbo_items[this.tbo_item_to_purchase].number_of_cases -= split_number_of_cases
            this.current_leftover_cases_in_tbo -= split_number_of_cases
            this.split_cases[this.tbo_item_to_purchase] = 0
            if (this.lower_number_of_cases_in_poi[this.tbo_item_to_purchase]) {
                this.poi_number_of_cases -= split_number_of_cases
                this.purchase_order_item.number_of_cases = this.poi_number_of_cases
            }
            this.saving = false

            notifier.notify(
                `Successfully split the item in ${
                    this.tbo_items[this.tbo_item_to_purchase].sales_order_reference
                } into a new POI with ${split_number_of_cases} cases.`,
                'success',
            )

            this.tbo_item_to_purchase = null
            this.new_tbo_item_data = null
        }
    }

    /**
     * Calculates what the new number of cases of the POI would be after splitting.
     */
    calculate_new_poi_cases(): void {
        const lowered_cases = this.tbo_items.map(
            (tbo_item, index) => this.split_cases[index] * (this.lower_number_of_cases_in_poi[index] ? 1 : 0),
        )
        this.new_poi_number_of_cases = this.poi_number_of_cases - sum(lowered_cases)
    }

    view(): m.Children {
        const split_is_complete = this.current_leftover_cases_in_tbo <= this.poi_number_of_cases
        const current_color_class = split_is_complete ? 'analysis-good-color' : 'analysis-bad-color'
        const pending_color_class =
            this.pending_leftover_cases_in_tbo <= this.new_poi_number_of_cases
                ? 'analysis-good-color'
                : 'analysis-bad-color'

        return (
            <div>
                {this.new_tbo_item_data !== null && (
                    <AddToOrder
                        supplier_artkey={this.purchase_order_item.supplier_artkey}
                        on_added_item={() => this.process_added_poi()}
                        tbo_item={this.new_tbo_item_data}
                        purchase_order_item={{
                            artkey: () => this.purchase_order_item.artkey,
                            was_bought_for: () => this.purchase_order_item.was_bought_for,
                            suggested_price_per_case: () => this.purchase_order_item.suggested_price_per_case,
                            country_of_origin: () => this.purchase_order_item.country_of_origin,
                            number_of_cases: () => this.purchase_order_item.number_of_cases,
                            purchase_order: () => { return {
                                incoterm: () => this.purchase_order.incoterm,
                                incoterm_location: () => this.purchase_order.incoterm_location,
                                supplier: () => {
                                    return {
                                        name: () => this.purchase_order.supplier.name,
                                    }
                                },
                            }},
                            case: () => { return {
                                artkey: this.purchase_order_item.case_artkey,
                                bottle: () => { return {
                                    volume: () => this.purchase_order_item.volume,
                                    alcohol_percentage: () => this.purchase_order_item.alcohol_percentage,
                                    refill_status: () => this.purchase_order_item.refill_status,
                                    product: () => { return {
                                        name: () => this.purchase_order_item.product_name,
                                    }},
                                }},
                                customs_status: () => this.purchase_order_item.customs_status,
                                gift_box_type: () => this.purchase_order_item.gift_box_type,
                                tax_label: () => this.purchase_order_item.tax_label,
                                best_before_date: () => this.purchase_order_item.best_before_date,
                                item_tags: () => [], // TODO: item tag artkeys for get_tag_names in add_to_order
                                number_of_bottles: () => this.purchase_order_item.number_of_bottles,
                            }},
                        }}
                        exclude_purchase_order_artkeys={[this.purchase_order_item.purchase_order_artkey]}
                        lower_number_of_cases_in_poi={
                            this.tbo_item_to_purchase !== null
                                ? this.lower_number_of_cases_in_poi[this.tbo_item_to_purchase]
                                : null
                        }
                    />
                )}

                {this.new_tbo_item_data === null && (
                    <div>
                        {this.poi_number_of_cases > this.total_cases_in_tbo &&
                            <div className={'row'}>
                                <div className={'col-md-10'}/>
                                <div className={'col-md-2'}>
                                    <DefaultButton
                                        title={' Split POI'}
                                        icon_class={'glyphicon glyphicon-resize-horizontal'}
                                        onclick={() => this.switch_to_poi()}
                                        additional_class={'pull-right'}
                                    />
                                </div>
                            </div>
                        }

                        <div className={'row'}>
                            <label className={'col-md-6 control-label'}>Current number of cases in POI</label>
                            <div className={'col-md-6'}>
                                <label className={'control-label'}>{this.poi_number_of_cases}</label>
                            </div>
                        </div>

                        <div className={'row'}>
                            <label className={'col-md-6 control-label'}>Number of cases in POI after splitting</label>
                            <div className={'col-md-6'}>
                                <label className={'control-label'}>{this.new_poi_number_of_cases}</label>
                            </div>
                        </div>

                        <div className={'row'}>
                            <label className={'col-md-6 control-label'}>Current total number of cases in TBOs</label>
                            <div className={'col-md-6'}>
                                <label className={'control-label'}>
                                    <span className={current_color_class}>{this.current_leftover_cases_in_tbo}</span>
                                </label>
                            </div>
                        </div>

                        <div className={'row'}>
                            <label className={'col-md-6 control-label'}>
                                Total number of cases in TBOs after splitting
                            </label>
                            <div className={'col-md-6'}>
                                <label className={'control-label'}>
                                    <span className={pending_color_class}>{this.pending_leftover_cases_in_tbo}</span>
                                </label>
                            </div>
                        </div>

                        <hr />

                        <div className={'row'}>
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>SO</th>
                                        <th>Relation</th>
                                        <th>TBO created on</th>
                                        <th>Orig. cs.</th>
                                        <th>New cs.</th>
                                        <th>Cases to split</th>
                                        <th>Also lower cases in POI</th>
                                        <th>Split to</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.tbo_items.map((tbo_item, index) => (
                                        <tr>
                                            <td>
                                                {m(
                                                    m.route.Link,
                                                    {
                                                        target: '_blank',
                                                        href: `/sales-orders/manage/${tbo_item.sales_order_reference}?account=${tbo_item.sales_order_account_slug}`,
                                                    },
                                                    tbo_item.sales_order_reference,
                                                )}
                                            </td>
                                            <td>
                                                {m(
                                                    m.route.Link,
                                                    {
                                                        target: '_blank',
                                                        href: `/crm/relations/${tbo_item.relation_name}/edit`,
                                                    },
                                                    tbo_item.relation_name,
                                                )}
                                            </td>
                                            <td>
                                                <span>{format_iso_to_date_time(tbo_item.created_on)}</span>
                                            </td>
                                            <td>
                                                <span>{tbo_item.number_of_cases}</span>
                                            </td>
                                            <td>
                                                <span className="strong">
                                                    {tbo_item.number_of_cases - this.split_cases[index]}
                                                </span>
                                            </td>
                                            <td>
                                                <NumberInput
                                                    minimum={0}
                                                    maximum={tbo_item.number_of_cases}
                                                    value={this.split_cases[index]}
                                                    oninput={(value: number) => {
                                                        this.split_cases[index] = value
                                                        this.pending_leftover_cases_in_tbo =
                                                            this.current_leftover_cases_in_tbo - sum(this.split_cases)
                                                        this.calculate_new_poi_cases()
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <CheckBox
                                                    value={this.lower_number_of_cases_in_poi[index]}
                                                    onchange={() => {
                                                        this.lower_number_of_cases_in_poi[index] =
                                                            !this.lower_number_of_cases_in_poi[index]
                                                        this.calculate_new_poi_cases()
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <SaveButton
                                                        title="TBO"
                                                        tooltip={`Create a new TBO item in ${tbo_item.sales_order_reference} for ${this.split_cases[index]} case(s)`}
                                                        disabled={
                                                            this.saving ||
                                                            this.split_cases[index] <= 0 ||
                                                            this.split_cases[index] > tbo_item.number_of_cases
                                                        }
                                                        onclick={() => this.split_to_tbo(index)}
                                                    />
                                                    <SaveButton
                                                        title="POI"
                                                        tooltip={`Create a new POI in another PO for ${this.split_cases[index]} case(s)`}
                                                        disabled={
                                                            this.saving ||
                                                            this.split_cases[index] <= 0 ||
                                                            this.split_cases[index] > tbo_item.number_of_cases
                                                        }
                                                        onclick={() => this.split_to_poi(index)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {split_is_complete && (
                            <div className={'row'}>
                                <div className={'btn-toolbar'}>
                                    <SaveButton title="All done!" onclick={() => this.done()} />
                                </div>
                            </div>
                        )}

                        {!split_is_complete && (
                            <div className={'row'}>
                                <div className={'btn-toolbar'}>
                                    <DefaultButton title="Close" onclick={() => this.cancel()} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
