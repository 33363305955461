m = require "mithril"
{map, compact, filter} = require 'prelude-ls'
{Offer} = require '@/models/offers'
{User} = require '@/models/users'
app = require('@/app')


export class EmailBatchStatus
    @UNDER_CONSTRUCTION = 'under_construction'
    @PREPARING = 'preparing'
    @SENDING = 'sending'
    @SENT = 'sent'
    @IN_PROGRESS = 'in_progress'
    @WAITING_FOR_NEXT_RUN = 'waiting_for_next_run'

    @choices = {
        'under_construction': 'Under construction'
        'preparing': 'Preparing'
        'sending': 'Sending'
        'sent': 'Sent'
        'in_progress': 'In Progress'
        'waiting_for_next_run': 'Waiting for next run'
    }


export class EmailBatchType
    @ENTIRE_PRICELIST = "ENTIRE_PRICELIST"
    @SPECIAL_ONLY = "SPECIAL_ONLY"
    @CUSTOM_OFFER = "CUSTOM_OFFER"
    @NOTHING = "NOTHING"
    @PURCHASE_ENQUIRY = "PURCHASE_ENQUIRY"

    @choices = {
        'ENTIRE_PRICELIST': "Entire pricelist"
        'SPECIAL_ONLY': "Special offer"
        'CUSTOM_OFFER': "Custom offer"
        'NOTHING':  "No pricelist"
        'PURCHASE_ENQUIRY':  "Purchase Enquiry"
    }

export class EmailProcessFlow
    @MAILS = 'mails'
    @OFFERS = 'offers'

export class EmailBatch
    (json) ->
        @artkey = window.prop ''
        @created_on = window.prop ''
        @subject = window.prop ''
        @pricelist_type = window.prop EmailBatchType.NOTHING
        @body = window.prop ''
        @dispatched_on = window.prop ''
        @created_by = window.prop new User
        @created_by_artkey = window.prop ''
        @offer_artkey = window.prop ''
        @batch_type = window.prop ''
        @cron_expression = window.prop null
        @use_sent_from_user_artkey = window.prop null

        if json
            @from_js(json)

    from_js: (json) ~>
        for prop of json
            if prop == 'created_by' then
                @created_by_artkey json[prop].artkey
                @created_by json[prop]
            else if prop == 'offer' then
                @offer new Offer json[prop]
                @offer_artkey @offer!artkey!
            else if prop of @
                @[prop] json[prop]
            else
                @[prop] = window.prop json[prop]
