import {Observable} from 'rxjs'

import {Api, FactserverRequestData} from './api'

import {SearchFilter} from '@/components/collection/collection_table'

export interface FilterPreset {
    artkey: number
    name: string
    filters: SearchFilter
    filter_preset_type: string
}

export interface GetFilterPresetsRequest extends FactserverRequestData {
    filter_preset_type: string
}

export interface SaveFilterPresetRequest extends FactserverRequestData {
    artkey?: number | null
    name: string | null
    filters: Record<string, unknown>
    filter_preset_type: string
}

export interface SaveFilterPresetResponse {
    artkey: number
    name: string
}

export interface DeleteFilterPresetRequest extends FactserverRequestData {
    artkey: number
}

export interface DeleteFilterPresetResponse {
    name: string
}

export class FilterApi {
    api = new Api()

    get_filter_presets(request: GetFilterPresetsRequest): Observable<Array<FilterPreset>> {
        return this.api.post_request('filter.get_filter_presets', request)
    }

    save_filter_preset(request: SaveFilterPresetRequest): Observable<SaveFilterPresetResponse> {
        return this.api.post_request('filter.save_filter_preset', request)
    }

    delete_filter_preset(request: DeleteFilterPresetRequest): Observable<DeleteFilterPresetResponse> {
        return this.api.post_request('filter.delete_filter_preset', request)
    }
}
