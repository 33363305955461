m = require 'mithril'
{concat, filter, head, map, obj-to-pairs, sort-by, filter} = require 'prelude-ls'
api = require 'api.ls'
{button-with-icon, text-button} = require '@/components/buttons.ls'
confirmation = require '@/components/confirmation.ls'
{CheckboxGroup} = require '@/components/filter/checkboxgroup.ls'
{Slider} = require '@/components/filter/slider.ls'
{icon} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{Modal} = require '@/components/modal/modal.ls'
{remove-from-array-prop} = require 'utils.ls'
{Spinner} = require '@bitstillery/common/components'
{FilterPresetHandler, FilterPresetType} = require '@/filter_preset/filter_preset'
{SaveFilterPresetAs} = require '@/filter_preset/save_filter_preset_as'
app = require('@/app')

export class ProductSearchMode
    @OFFER = 'offer'
    @PURCHASE = 'purchase'
    @MARKET = 'market'


# The filter sidebar for product selection.
export class ProductFilterSidebar
    (vnode) ~>
        @collection = vnode.attrs.collection
        @mode = vnode.attrs.mode || ProductSearchMode.OFFER
        @show_save_modal = window.prop false

        @product_categories = window.prop []
        @brands = window.prop []
        @loading_categories = window.prop true
        @loading_brands = window.prop true

        @filter_presets = window.prop []
        @filter_preset_choices = window.prop []
        @selected_filter_preset_artkey = window.prop null
        @loading_filter_presets = window.prop true

        if @mode == ProductSearchMode.OFFER
            @filter_preset_type = FilterPresetType.SELECT_OFFER_ITEM
        else if @mode == ProductSearchMode.PURCHASE
            @filter_preset_type = FilterPresetType.SELECT_PURCHASE_ORDER_ITEM
        else if @mode == ProductSearchMode.MARKET
            @filter_preset_type = FilterPresetType.SELECT_MARKET_ITEM

        @filter_preset_handler = new FilterPresetHandler(
            @filter_preset_type,
            @filter_presets,
            @filter_preset_choices,
            @selected_filter_preset_artkey,
            @loading_filter_presets,
        )

    oncreate: ~>
        @get_categories!
        @get_brands!
        @filter_preset_handler.get_filter_presets!

    get_categories: ~>
        api.call-and-then 'product_categories.get_product_categories', {}, do
            success: (resp) ~>
                @loading_categories false
                resp.result
                    |> filter (item) -> item.cbs_code != "UNKNOWN"
                    |> map ({artkey, name}) -> [artkey, (name.charAt(0)toUpperCase! + name.slice(1))]
                    |> @product_categories

    get_brands: ~>
        api.call-and-then 'brands.get_all', {}, do
            success: (resp) ~>
                @loading_brands false
                resp.result
                    |> sort-by (.name)
                    |> map ({artkey, name, brand_holder}) -> [artkey, name, brand_holder['name']]
                    |> @brands

                # Add brand holder name to duplicates:
                # 1) First identify the duplicates.
                all_brand_names = []
                duplicate_brand_names = []
                for brand in @brands!
                    if brand[1] in all_brand_names
                        duplicate_brand_names.push(brand[1])
                    else
                        all_brand_names.push(brand[1])

                # 2) Then add the brand holder name to the duplicates.
                for brand in @brands!
                    if brand[1] in duplicate_brand_names
                        brand[1] = "#{brand[1]} (#{brand[2]})"
                    # Finally, remove the brand holder name element from each tuple in @brands.
                    brand.pop()

    # Applies a filter preset to the html components and the collection.
    # This function is called after selecting a preset in the dropdown.
    #
    # @param {int} artkey: The selected filter preset's artkey.
    set_filter_preset: (artkey) ~>
        @selected_filter_preset_artkey +artkey

        if artkey
            # Find the filter preset object for the provided artkey.
            preset = head @filter_presets!filter (.artkey == @selected_filter_preset_artkey!)

            # Load the filter parameters from the object into the collection.
            if preset
                for parameter_name in Object.keys(preset['filters'])
                    if parameter_name in Object.keys(@collection.filters)
                        @collection.filters[parameter_name] preset['filters'][parameter_name]
                    else
                        app.notifier.notify "The filter parameter with name #{parameter_name} is not recognized. Check your filter settings and save the preset again to fix this.", 'warning'

            # Apply the filter parameters to the collection.
            @collection.requery!

    # After saving a filter preset via the save modal, process the changes
    # by updating the 'Select a preset'-dropdown and closing the modal.
    #
    # @param {int} artkey: The artkey of the created or updated filter preset.
    process_save_response: (artkey) ~>
        @filter_preset_handler.get_filter_presets!
        @selected_filter_preset_artkey artkey
        @show_save_modal false

    close_save_modal: ~>
        @show_save_modal false

    # Reset all filters to their default values.
    reset_all_filters: ~>
        # NOTE: It is difficult to uncheck the checkboxes in the CheckBoxGroup
        # components from here, because we need to access the state of those
        # CheckBoxGroup components. Therefore, for now, just reload the page
        # instead.
        window.location.reload true

    view: ~> if typeof @collection.filters != 'undefined' then m '.c-filter-sidebar',
        m '.row' m '.col-xs-12',
            m 'label.control-label' 'Select a preset'

        m '.row' m '.col-xs-12.mb-1',
            if @loading_filter_presets! then m Spinner else
                inputs.select @selected_filter_preset_artkey, @filter_preset_choices!, do
                    onchange: @set_filter_preset
                    disabled: @filter_preset_choices!length < 2  # disabled when there are no options or only the 'empty' option

        m '.row' m '.col-xs-12' m '.btn-group',
            button-with-icon 'Save', 'fa-save', do
                class: 'btn-success'
                onclick: ~> @show_save_modal true
                disabled: @loading_filter_presets!
                title: 'Save filter preset'
            button-with-icon 'Delete', 'remove', do
                class: 'btn-danger'
                onclick: ~> @filter_preset_handler.delete_filter_preset!
                disabled: @loading_filter_presets! or @selected_filter_preset_artkey! == null
                title: 'Delete filter preset'

        m 'hr'

        m '.btn-group',
            button-with-icon 'Apply', 'glyphicon-filter', do
                class: 'btn-success'
                onclick: @collection.requery
                disabled: @collection.loading!
            button-with-icon 'Reset', 'fa-undo', do
                class: 'btn-default'
                onclick: @reset_all_filters

        m 'hr'

        if @mode == ProductSearchMode.OFFER then [
            m 'label.control-label' 'Offer item type'
            m '' m '.btn-group.mb-1',
                text-button 'Purchase',
                    if 'purchase' in @collection.filters['offer_item_types']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['offer_item_types'], 'purchase'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['offer_item_types']!push 'purchase'

                text-button 'Stock',
                    if 'stock' in @collection.filters['offer_item_types']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['offer_item_types'], 'stock'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['offer_item_types']!push 'stock'

                text-button 'TBO',
                    if 'tbo' in @collection.filters['offer_item_types']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['offer_item_types'], 'tbo'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['offer_item_types']!push 'tbo'
        ]

        if @mode == ProductSearchMode.OFFER then [
            m 'label.control-label' 'Account'
            m '' m '.btn-group.mb-1',
                text-button 'MSI',
                    if 'msi' in @collection.filters['account_slugs']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['account_slugs'], 'msi'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['account_slugs']!push 'msi'

                text-button 'MSP',
                    if 'msp' in @collection.filters['account_slugs']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['account_slugs'], 'msp'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['account_slugs']!push 'msp'


                text-button 'A2BC',
                    if 'a2bc' in @collection.filters['account_slugs']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['account_slugs'], 'a2bc'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['account_slugs']!push 'a2bc'

                text-button 'ETR',
                    if 'etr' in @collection.filters['account_slugs']!
                        class: 'btn-primary'
                        onclick: ~> remove-from-array-prop @collection.filters['account_slugs'], 'etr'
                    else
                        class: 'btn-default'
                        onclick: ~> @collection.filters['account_slugs']!push 'etr'
        ]

        m '.btn-group.filter-radio.mb-1',
            m 'label.control-label' 'Customs status'
            m '' inputs.radio(@collection.filters['customs_status'], [
                {'value': 'T1', 'description': 'T1'},
                {'value': 'T2', 'description': 'T2'},
                {'value': '', 'description': 'Both'}
            ])

        m '.btn-group.filter-radio.mb-1',
            m 'label.control-label' 'Refill status'
            m '' inputs.radio(@collection.filters['refill_status'], [
                {'value': 'ref', 'description': 'Ref'},
                {'value': 'nonref', 'description': 'Nonref'},
                {'value': '', 'description': 'Both'}
            ])

        if @mode in [ProductSearchMode.OFFER, ProductSearchMode.PURCHASE] then
            m '.btn-group.filter-radio.mb-1',
                m 'label.control-label' 'Tax label'
                m '' inputs.radio(@collection.filters['tax_label'], [
                    {'value': 'UKDS', 'description': 'UKDS'},
                    {'value': '', 'description': 'None'},
                    {'value': 'any', 'description': 'Any'}
                ])

        if @mode == ProductSearchMode.OFFER then [
            m '.btn-group.filter-radio.mb-1',
                m 'label.control-label' 'Show hidden items'
                m '' inputs.radio(@collection.filters['hidden_selection'], [
                    {'value': 'visible_only', 'description': icon 'eye-open'},
                    {'value': 'hidden_only', 'description': icon 'eye-close'},
                    {'value': 'all', 'description': 'All'}
                ])

            m '.btn-group.filter-radio.mb-1',
                m 'label.control-label' 'Arriving soon'
                m '' inputs.radio(@collection.filters['arriving_soon'], [
                    {'value': true, 'description': icon 'road'},
                    {'value': false, 'description': 'All'},
                ])
        ]

        m 'hr'

        if @mode == ProductSearchMode.OFFER then [
            m 'label.control-label' 'Stock age'
            m '.mb-1' m Slider, do
                prop: @collection.filters['stock_age_range']
                options: do
                    range: do
                        min: 0
                        '50%': 31
                        '70%': 100
                        max: 1000
                    infinity: true

            m 'label.control-label' 'Last price change age'
            m '.mb-1' m Slider, do
                prop: @collection.filters['last_price_change_age_days_range']
                options: do
                    range: do
                        min: 0
                        '50%': 31
                        '70%': 100
                        max: 1000
                    infinity: true
        ]

        m 'label.control-label' 'Bottles per case'
        m '.mb-1' m Slider, do
            prop: @collection.filters['bottles_per_case_range']
            options: do
                range: do
                    min: 0
                    '60%': 25
                    '80%': 100
                    '90%': 250
                    max: 1000
                infinity: true

        m 'label.control-label' 'Volume'
        m '.mb-1' m Slider, do
            prop: @collection.filters['volume_range']
            options: do
                range: do
                    min: 0
                    '40%': 70
                    '60%': 100
                    '80%': 1000
                    max: 10000
                infinity: true

        m 'label.control-label' 'Alcohol percentage'
        m '.mb-1' m Slider, do
            prop: @collection.filters['alcohol_percentage_range']
            options: do
                range: do
                    min: 0
                    '80%': 60
                    max: 100

        if @mode == ProductSearchMode.OFFER then [
            m 'label.control-label' 'List quantity'
            m '.mb-1' m Slider, do
                prop: @collection.filters['list_quantity_range']
                options: do
                    range: do
                        min: 0
                        '20%': 10
                        '50%': 50
                        '70%': 100
                        '80%': 200
                        '90%': 1000
                        max: 10000
                    infinity: true

            m 'label.control-label' 'Base price'
            m '.mb-1' m Slider, do
                prop: @collection.filters['base_price_range']
                options: do
                    range: do
                        min: 0
                        '50%': 100
                        '70%': 200
                        '90%': 1000
                        max: 10000
                    infinity: true
        ]

        m 'hr'

        if @loading_categories! then m Spinner else
            m CheckboxGroup, do
                filter_function: @collection.filters['categories']
                filter_id: 'category'
                filter_name: 'Category'
                filter_options: @product_categories!

        if @loading_brands! then m Spinner else
            m CheckboxGroup, do
                filter_function: @collection.filters['brands']
                filter_id: 'brand'
                filter_name: 'Brand'
                filter_options: @brands!
                show_no_value_checkbox: true

        m CheckboxGroup, do
            filter_function: @collection.filters['gift_box_types']
            filter_id: 'gift_box_type'
            filter_name: 'Gift box type'
            filter_options: [[gb, gb] for gb in app.$m.data.gift_box_types]
            show_no_value_checkbox: true

        if @show_save_modal! then
            m Modal, do
                title: 'Save filter preset as'
                onclose: @close_save_modal
            , m SaveFilterPresetAs, do
                filter_preset_data: @filter_preset_handler.get_save_request_data @collection.filters
                done: @process_save_response
                cancel: @close_save_modal
