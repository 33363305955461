m = require "mithril"
{odd, difference, unique, join, head, map} = require 'prelude-ls'
{filter, toArray, mergeAll} = require "rxjs/operators"
api = require '../api.ls'
{a, format-date, on-set, format_date_html5}: utils = require 'utils.ls'
{Badge} = require '@bitstillery/common/badge'
inputs = require '../components/inputs.ls'
{text-button, button-with-icon} = require '../components/buttons.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
{RelationDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{IncotermsDropDown} = require '@/components/incoterms'
{IncotermsDropDownData} = require '@/factserver_api/incoterms_api'
{current_account_slug} = require '@bitstillery/common/account/account'
{Supplier, SupplierPriceList} = require '@/models/suppliers'
{Button, Dialog, FieldCheckbox, Icon, Spinner} = require '@bitstillery/common/components'
{proxy} = require '@bitstillery/common/lib/proxy'
app = require('@/app')

module.exports = class MarketUploadPricelist
    ->
        @supplier_price_list = window.prop new SupplierPriceList
        @supplier = window.prop new Supplier
        @incoterm = window.prop 'EXW'
        @location = window.prop ''
        @customs_status = window.prop ''
        @description = window.prop ''
        @currency = window.prop ''
        @start_date = window.prop format_date_html5(new Date)
        @end_date = window.prop ''
        @file = window.prop ''
        @file_name = window.prop ''

        @header = window.prop []
        @saving = window.prop false
        @selected_columns_mapping = window.prop {}
        @custom_columns = window.prop {}
        @removed_columns = window.prop []
        @step2 = window.prop false
        @step2_dialog = window.prop false
        @preprocess_resp = window.prop null

        @error_messages = window.prop []

        # Enable overriding the header row.
        @overridden_header_idx = window.prop null

        this.data = proxy({
            store_cpp_on_case: false,
            store_cpl_on_case: false,
            override_header_row: false,
        })

    upload: (event) ~>
        @step2 false
        # Put a base64 encoded file in @file
        file_reader = new FileReader!
        file_reader.onload = (event) ~>
            @file event.target.result
            if @supplier!artkey! and @start_date!
                @submit_step1!
        file_reader.readAsDataURL event.target.files[0]
        @file_name event.target.files[0].name

    submit_step1: (event) ~>
        # Reset values to pre-step1 values.
        if event
            event.preventDefault!
        @error_messages []
        @preprocess_resp null
        @header null
        @saving true
        @selected_columns_mapping {}
        @custom_columns {}
        @removed_columns []
        @step2 false

        if not @check_can_save_step_1!
            @saving false
            return

        data = do
            supplier_artkey: @supplier!artkey!
            start_date: @start_date!
            end_date: @end_date!
            file: @file!
            overridden_header_idx: if this.data.override_header_row then @overridden_header_idx! - 1 else null

        api.call-and-then 'matcher.preprocess_pricelist', data, do
            success: (resp) ~>
                @saving false
                @error_messages []

                if resp.success == false
                    @error_messages!push resp.message
                    return

                if @overridden_header_idx! > 0
                    @overridden_header_idx resp.header_idx + 1
                @step2 true
                # Remember the full resp for the table component.
                @preprocess_resp resp
                @header resp.header
            failure: ~>
                @saving false


    confirm_step2: (event) ~>
        event.preventDefault!
        @error_messages []
        @saving true
        if not @check_can_save_step_2!
            @saving false
            return

        @step2_dialog true

    submit_step2: (event) ~>
        # Everything in custom_columns is a prop. Serialize.
        custom_columns_instance = Object.keys(@custom_columns!)
            .reduce ((result, key) ~>
                result[parseInt(key)] = @custom_columns![key]!
                return result
            ), {}

        data = do
            supplier_artkey: @supplier!artkey!
            start_date: @start_date!
            end_date: @end_date!
            header: @header!
            incoterm: @incoterm!
            incoterm_location: @location!
            customs_status: @customs_status!
            description: @description!
            currency: @currency!
            store_cpp: this.data.store_cpp_on_case
            store_cpl: this.data.store_cpl_on_case
            selected_columns_mapping: @selected_columns_mapping!
            custom_columns: custom_columns_instance
            removed_columns: @removed_columns!
            file: @file!
            file_name: @file_name!
            overridden_header_idx: if this.data.override_header_row then @overridden_header_idx! - 1 else null

        api.call-and-then 'matcher.parse_pricelist', data, do
            success: (resp) ->
                app.notifier.notify 'Started processing pricelist! You will receive a notification when it is complete.', 'success'
                m.route.set "/market/pricelists/"
            failure: (resp) ~>
                @saving false
                @error_messages []
                @error_messages!push resp.message

    check_can_save_step_1: ~>
        @error_messages []
        if not @file!
            @error_messages!push "Please select a file."
        if not @supplier!artkey!
            @error_messages!push "Please select a supplier."
        if not @start_date!
            @error_messages!push "Please select a valid start date."
        return @error_messages!length == 0

    check_can_save_step_2: ~>
        if not @check_can_save_step_1!
            return false
        @error_messages []
        if not @location! or not @incoterm!
            @error_messages!push "Please select an incoterm and location."
        if not @customs_status!
            @error_messages!push "Please select a customs status."
        if not @currency!
            @error_messages!push "Please select a currency."
        columns_not_unique = @check_columns_unique!
        if columns_not_unique.length > 0
            @error_messages!push "Duplicate columns found: #{join ', ', columns_not_unique}."
        return @error_messages!length == 0

    select_supplier: (supplier_artkey) ~>
        if supplier_artkey
            api.call-and-then 'suppliers.get_supplier' {artkey: supplier_artkey}, do
                success: (response) ~>
                    @supplier new Supplier response.result
                    @currency @supplier!currency!
                    @step2 false
        else
            @supplier new Supplier

    check_columns_unique: ~>
        selected_columns = [value for key, value of @selected_columns_mapping!]
        diffs = []
        for column in unique(selected_columns)
            if selected_columns.filter((col) ~> col == column).length > 1
                diffs.push column
        return diffs

    has_cpp: ~>
        ['Cases per pallet', 'Bottles per pallet'].some (column) ~> Object.values(@selected_columns_mapping!).includes column

    has_cpl: ~>
        ['Cases per pallet layer', 'Bottles per pallet layer'].some (column) ~> Object.values(@selected_columns_mapping!).includes column

    view: ~>
        m '.c-market-upload-pricelist view',
            m Dialog, {
                title: 'Processing pricelist'
                className: if @step2_dialog! then '' else 'hidden'
                onclose: ~>
                    @step2_dialog false
                    @saving false
            },
                m 'p', 'Did you select all relevant columns? Data in unselected columns will be ignored.'

                if this.data.store_cpp_on_case or this.data.store_cpl_on_case
                    m ".alert alert-danger",
                        m 'span',
                            m ".glyphicon glyphicon-warning-sign"
                            m 'span', " Saving CPP/CPL will overwrite any existing values for the cases in this pricelist."
                            m 'div', " These values are used throughout the purchase side of Discover."
                            m 'div', " This action cannot be undone."
                m '.footer',
                    m '.c-button-group',
                        m Button, {
                            onclick: @submit_step2
                            icon: 'checked'
                            type: if this.data.store_cpp_on_case or this.data.store_cpl_on_case then 'danger' else 'success'
                        }, 'Confirm'

            m '.btn-toolbar',
                m Button, {
                    onclick: ~> m.route.set '/market/pricelists'
                    icon: 'back',
                    variant: 'toggle',
                }

            m '.pricelist-wrapper',
                m 'form.flex-form',
                    m '.fieldset',
                        m '.field',
                            m 'label' {for: 'supplier'} 'Supplier'
                            m RelationDropDown, do
                                selected_relation_artkey: @supplier!artkey!
                                get_all_for_drop_down_response$: RelationDropDownData.relations().pipe(
                                    mergeAll(),
                                    filter((relation) -> relation.sales_account.slug == current_account_slug!),
                                    toArray()
                                )
                                onchange: (supplier_artkey) ~>
                                    @select_supplier supplier_artkey

                        m '.field',
                            m 'label' {for: 'file'} 'Pricelist'
                            m 'input' do
                                type: 'file'
                                required: true
                                name: 'file'
                                onchange: @upload
                                accept: 'application/xml,.xls,.xlsx'

                        m '.field-group',
                            m '.field',
                                m 'label' {for: 'start-date'} 'Start date'
                                inputs.date @start_date, do
                                    required: true

                            m '.field',
                                m 'label' {for: 'end-date'} 'End date'
                                inputs.date @end_date

                        m '.field',
                            m 'label' 'Default incoterm'
                            m IncotermsDropDown,
                                selected_incoterm: @incoterm!
                                required: true
                                get_all_for_drop_down_response$: IncotermsDropDownData.incoterms()
                                onchange: (incoterm) ~> @incoterm incoterm
                            m '.help' 'The default incoterm is a fallback for when no incoterm can be found in the price list.'
                        m '.field',
                            m 'label' 'Incoterm location'
                            inputs.text @location

                        m '.field',
                            m 'label' 'Default customs status'
                            m 'select' {required: true, name: 'customs-status', \
                                oninput: (ev) ~> @customs_status ev.target.value},
                                    m 'option', do
                                        value: null
                                        disabled: true
                                        selected: true
                                    ''
                                    app.$m.data.customs_status.map (t1_2) ~>
                                        m 'option' {value: t1_2, selected: t1_2 == @customs_status!} t1_2
                            m '.help' 'The default customs status is a fallback for when no customs status can be found in the price list.'

                        m '.field',
                            m 'label' {for: 'currency'} 'Currency'
                            inputs.select @currency, app.$s.currencies.all

                        if @has_cpp! or @has_cpl! then
                            m '.field',
                                m '.field-group',
                                    if @has_cpp! then
                                        m FieldCheckbox, {
                                            label: "Save CPP"
                                            help: "Save cases per pallet on the product."
                                            ref: [this.data, 'store_cpp_on_case']
                                        }

                                    if @has_cpl! then
                                        m FieldCheckbox, {
                                            label: "Save CPL"
                                            help: "Save cases per pallet layer on the product."
                                            ref: [this.data, 'store_cpl_on_case']
                                        }

                                if this.data.store_cpp_on_case or this.data.store_cpl_on_case
                                    m ".alert alert-danger",
                                        m 'span',
                                            m ".glyphicon glyphicon-warning-sign"
                                            m 'span', " Saving CPP/CPL will overwrite any existing values for the cases in this pricelist."
                                            m 'div', " These values are used throughout the purchase side of Discover."
                                            m 'div', " This action cannot be undone."

                        m '.field',
                            m FieldCheckbox, {
                                label: 'Modify header row'
                                ref: [this.data, 'override_header_row']
                            }

                            if this.data.override_header_row then
                                inputs.text @overridden_header_idx, {
                                    help: 'This field will be set automatically; only adjust in case the header is not properly recognized. Set to -1 if no header exists.'
                                    label: 'Header row'
                                }

                        m '.field',
                            m 'label', 'Description'
                            m 'textarea', {rows: 10, oninput: (ev) ~> @description ev.target.value}, @description!

                    if @step2! then [
                        button-with-icon 'Preprocess pricelist again', 'upload', do
                            class: 'btn-primary'
                            onclick: @submit_step1
                            disabled: @saving!

                        if @saving!
                            m 'p' 'Processing... You will be redirected when processing is done.'
                        else
                            m 'button.btn.btn-success' \
                                {onclick: @confirm_step2} (m 'span.glyphicon.glyphicon-play-circle'), ' Process pricelist'
                    ]
                    else
                        button-with-icon 'Preprocess pricelist', 'upload', do
                            class: 'btn-primary btn-submit'
                            onclick: @submit_step1
                            disabled: @saving!

                    if @error_messages!length > 0
                        m '.error-messages.alert.alert-danger.animated.fadeInUp',
                            m 'ul',
                                @error_messages!map (message) ~>
                                    m 'li' message

                if @step2! then a do
                    m '.column-selector',
                        m UploadSupplierPriceListSelectColumns, do
                            selected_columns_mapping: @selected_columns_mapping
                            custom_columns: @custom_columns
                            removed_columns: @removed_columns
                            supplier: @supplier
                            preprocess_resp: @preprocess_resp


class UploadSupplierPriceListSelectColumns
    (vnode) ->
        @selected_columns_mapping = vnode.attrs.selected_columns_mapping
        @custom_columns = vnode.attrs.custom_columns
        @removed_columns = vnode.attrs.removed_columns
        @supplier = vnode.attrs.supplier

        resp = vnode.attrs.preprocess_resp!
        @other_columns = (resp.other_columns or [])
        @columns = (resp.columns or [])
        @column_mapping = resp.column_mapping
        @header = resp.header

        @candidate_rows = resp.candidate_rows
        # Convert candidate rows to a list of objects, indexed by column name.
        @candidate_products = @candidate_rows.map((prod) ~>
            @header.reduce((result, col_name, idx) ->
                result[col_name] = prod[idx]
                return result
            , {})
        )

        @selected_columns_mapping resp.column_mapping
        @removed_columns resp.removed_columns
        @matched_candidates = null
        @matching_candidates = false

        for k, v of @column_mapping
            @select_column +k, v

        for k, v of resp.custom_columns
            @custom_columns![k] = window.prop v

    select_column: (index, value) ->
        if value != ""
            @selected_columns_mapping![index] = value
            if value == 'custom'
                @custom_columns![index] = window.prop ''
            else
                delete @custom_columns![index]
        else
            delete @selected_columns_mapping![index]
        m.redraw!

    to_select_columns: ~>
        difference(@columns, [value for key, value of @selected_columns_mapping!])

    toggle_column: (column) ~>
        idx = @removed_columns!indexOf(column)
        if idx > -1
            @removed_columns!splice(idx, 1)
        else
            @removed_columns!push(column)

    column_names: ~>
        @header.filter((col_name) ~> not @removed_columns!includes(col_name))

    parse_candidates: (event) ~>
        event.preventDefault!
        @matching_candidates = true

        # Everything in custom_columns is a prop. Serialize.
        custom_columns_instance = Object.keys(@custom_columns!)
            .reduce ((result, key) ~>
                result[parseInt(key)] = @custom_columns![key]!
                return result
            ), {}

        data = do
            header: @header
            candidate_rows: @candidate_rows
            column_mapping: @selected_columns_mapping!
            custom_columns: custom_columns_instance

        api.call-and-then 'matcher.parse_candidates' data, do
            success: (resp) ~>
                @matched_candidates = resp.matched_candidates
            final: (resp) ~>
                @matching_candidates = false

    match_to_string: (mymatch) ~>
        bottle = mymatch['bottle']
        product_name = bottle.product_name or mymatch.product_name
        return "
            Product: #{product_name}<br/>
            Alc: #{bottle.alcohol_percentage}%<br/>
            Vol: #{bottle.volume}cl<br/>
            Ref: #{bottle.refill_status}<br/>
            GB: #{mymatch.gift_box_type or 'Unknown'}<br/>
            Btls/cs: #{mymatch.number_of_bottles_per_case or 'Unknown'}<br/>
            Score: #{mymatch.matching_score or 'Unknown'}
        "

    no_match_to_string: (candidate) ~>
        return "
            Key: #{candidate.product_map_key}<br/>
            Alc: #{if candidate.alcohol_percentage then "#{candidate.alcohol_percentage}%" else 'Unknown'}<br/>
            Vol: #{if candidate.volume then "#{candidate.volume}cl" else 'Unknown'}<br/>
            Ref: #{candidate.refill_status or 'Unknown'}<br/>
            GB: #{candidate.gift_box_type or 'Unknown'}<br/>
            Btls/cs: #{candidate.number_of_bottles_per_case or 'Unknown'}<br/>
            Score: #{candidate.matching_score or 'Unknown'}
        "

    view: ~> a do
        m '.fieldset.full-width',
            m '.field-group',
                m '.field-readonly' a do
                    m '.key' 'Expected columns'
                    m '.value',
                    if @to_select_columns!length > 0
                        @to_select_columns!map (col) ~>
                            m Badge, do
                                type: 'primary'
                                additional_classes: 'mr-05'
                            , col
                    else
                        m 'p' 'All expected columns selected.'
                m '.field-readonly' a do
                    m '.key' 'Removed columns'
                    m '.value',
                        @removed_columns!map (col) ~>
                            m Badge, do
                                type: 'danger'
                                additional_classes: if @header.includes(col) then 'clickable mr-05' else 'mr-05'
                                onclick: (event) ~>
                                    if !@header.includes(col)
                                        return
                                    @removed_columns @removed_columns!filter((it) ~> it != col && @header.includes(col))
                            , col

            button-with-icon 'Match candidates', 'resize-small' do
                class: 'btn-info'
                onclick: @parse_candidates
            if @matching_candidates
                m 'span',
                    ' '
                    m Spinner
        m '.c-column-selector',
            m 'table.table.search-table',
                m 'thead' m 'tr',
                    if @matched_candidates
                        m 'th'
                    @header.map (col_name, index) ~>
                        if @column_names!includes(col_name)
                            # Iterate over header to maintain the index, to use with selected column mapping.
                            m 'th',
                                m 'select.column-mapping' {
                                    oninput: (ev) ~> @select_column(index, ev.target.value)
                                    class: if index of @selected_columns_mapping! then '' else 'nothing-selected'
                                    } a do
                                    m 'option' {value: ''}
                                    @columns.map (col) ~>
                                        m 'option' {value: col, \
                                        selected: col == @selected_columns_mapping![index]} col
                                    m 'option' {value: ''} '--- Other columns ---'
                                    @other_columns.map (col) ->
                                        m 'option' {value: col} col
                                    m 'option' {value: 'custom', \
                                    selected: 'custom' == @selected_columns_mapping![index]} 'FIT-ON'

                                if 'custom' == @selected_columns_mapping![index] then
                                    m '.pull-right',
                                        icon-with-popover do
                                            icon-id: 'question-sign'
                                            title: 'Help for custom columns'
                                            content: '
                                                {{IGNORE}}: Ignore single word.<br/>
                                                {{PRODUCT}}: Product name.<br/>
                                                {{BTLS_CS}}: Nr of bottles per case.<br/>
                                                {{SIZE_CL}}: Size in cl.<br/>
                                                {{SIZE_L}}: Size in cl.<br/>
                                                {{ALC}}: Alcohol percentage (in percentages).<br/>
                                                {{GB}}: Optional designation for giftbox.
                                            '

                                if @selected_columns_mapping![index] == 'custom' then
                                    inputs.text @custom_columns![index], {required: true}

                                m 'p',
                                    col_name
                                    icon 'remove', do
                                        class: 'btn-danger btn-xs clickable ml-05'
                                        onclick: ~>
                                            @toggle_column(col_name, index)
                                            @select_column(index, "")
                m 'tbody', @candidate_products.map (prod, index) ~>
                    m 'tr' {class: if odd index then 'odd' else 'even'},
                        if @matched_candidates then m 'td',
                            if @matched_candidates[index] and @matched_candidates[index]['bottle']
                                icon-with-popover do
                                    icon-id: 'ok'
                                    title: 'Matched! Data found:'
                                    content: @match_to_string(@matched_candidates[index])
                            else if @matched_candidates[index]
                                icon-with-popover do
                                    icon-id: 'remove'
                                    title: 'No match. Data found:'
                                    content: @no_match_to_string(@matched_candidates[index])
                            else
                                icon-with-popover do
                                    icon-id: 'remove'
                                    title: 'No match.'
                                    content: 'No data found. Did you select the right product column?'
                        else
                            ''
                        for col_name in @column_names!
                            m 'td.ellipsis' prod[col_name]

