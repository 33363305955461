import {Observable, of, Subject} from 'rxjs'
import {filter, mergeAll, toArray} from 'rxjs/operators'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmtpyRequest} from './api'

export interface GetItemTagResponse {
    artkey: number
    name: string
    abbreviation: string
    item_tag_category: {
        artkey: number
        name: string
    }
}

export const TAX_LABEL_CATEGORY = 'Tax Label'
export const PACKAGING_CATEGORY = 'Packaging'
export const PACK_SIZE_CATEGORY = 'Pack Size'
export const DAMAGES_CATEGORY = 'Damages'
export const GENERAL_CATEGORY = 'General'

export class ItemTagsDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: ItemTagsDropDownData | null = null
    /** Cached results, when available */
    private result: GetItemTagResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetItemTagResponse[]> = new Subject<GetItemTagResponse[]>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, GetItemTagResponse[]>('product_management.get_all_item_tags', {})
            .subscribe({
                next: (response: GetItemTagResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): ItemTagsDropDownData {
        if (ItemTagsDropDownData._instance === null) {
            ItemTagsDropDownData._instance = new ItemTagsDropDownData()
        }

        return ItemTagsDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static item_tags$(): Observable<GetItemTagResponse[]> {
        if (ItemTagsDropDownData.instance().drop_down_data$.isStopped) {
            return of(ItemTagsDropDownData.instance().result)
        }

        return ItemTagsDropDownData.instance().drop_down_data$
    }

    /** Returns the observable for the item tags of a specific category. */
    public static item_tags_for_category$(category: string): Observable<GetItemTagResponse[]> {
        return ItemTagsDropDownData.item_tags$().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item_tag: GetItemTagResponse) => item_tag.item_tag_category.name === category),
            toArray(),
        )
    }

    public static artkeys_for_category(category: string): string[] {
        return (
            ItemTagsDropDownData.instance()
                .result.filter((tag) => tag.item_tag_category.name === category)
                .map((item) => `${item.artkey}`) || []
        )
    }

    public static is_artkey_in_category(artkey: string, category: string): boolean {
        const artkeys_for_category = ItemTagsDropDownData.artkeys_for_category(category)
        return artkeys_for_category.includes(artkey)
    }

    public static get_item_tag_for_artkey(artkey: string): GetItemTagResponse | undefined {
        return ItemTagsDropDownData.instance().result.find((tag) => tag.artkey === +artkey)
    }

    /* ----------------------------------------------------------------------
    Tax label access functions
    ------------------------------------------------------------------------- */
    public static tax_label_item_tags$(): Observable<GetItemTagResponse[]> {
        return ItemTagsDropDownData.item_tags_for_category$(TAX_LABEL_CATEGORY)
    }
}
