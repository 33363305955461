m = require 'mithril'
api = require 'api.ls'
{Account} = require '@/models/accounts'
{Delivery, InsuranceType, Warehouse} = require '@/models/data'
{Case, Item} = require '@/models/stock'
{Supplier, SupplierPriceListItem} = require '@/models/suppliers'
{User} = require '@/models/users'
{compact, map, group-by} = require 'prelude-ls'
app = require('@/app')

export class PurchaseOrderStatus
    @SAVED = 'Saved'
    @CONFIRMED = 'Confirmed'
    @ON_HOLD = 'On Hold'
    @CANCELLED = 'Cancelled'
    @SAVED_OR_CONFIRMED = 'Saved or confirmed'
    @STOCKED = 'In stock'
    @READY_FOR_FIS = 'Ready for FIS'
    @STOCKED_AND_BOOKED = "Stocked and Booked"

    @all = [@SAVED, @CONFIRMED, @CANCELLED, @SAVED_OR_CONFIRMED, @STOCKED, @READY_FOR_FIS, @STOCKED_AND_BOOKED]
    @choices = {}
    for status in @all
        @choices[status] = status


export class PurchaseOrder
    (json) ->
        @artkey = window.prop ''
        @account = window.prop new Account
        @account_artkey = window.prop ''
        @supplier = window.prop new Supplier
        @supplier_artkey = window.prop ''
        @date = window.prop ''
        @incoterm = window.prop ''
        @incoterm_location = window.prop ''
        @reference = window.prop ''
        @supplier_reference = window.prop ''
        @target_warehouse = window.prop new Warehouse
        @target_warehouse_artkey = window.prop ''
        @warehouse_reference = window.prop ''
        @was_handled_by_artkey = window.prop ''
        @was_handled_by = window.prop new User
        @was_bought_in = window.prop 'EUR'
        @was_bought_for = window.prop '1'
        @number_of_cases = window.prop ''
        @status = window.prop ''
        @purchase_order_items = window.prop []
        @bought_against_rate = window.prop ''
        @confirmation_date = window.prop ''
        @is_cancelled = window.prop false
        @warehouse_base_costs = window.prop 0
        @warehouse_costs_per_case = window.prop 0
        @transport_costs = window.prop 0
        @pallet_costs = window.prop 0
        @other_costs = window.prop 0
        @waste_fund_costs = window.prop 0
        @insurance_type_artkey = window.prop ''
        @insurance_type = window.prop new InsuranceType
        @was_sent_to_financial_information_system_on = window.prop ''
        @remark = window.prop ''
        @bottle_gtin_code = window.prop ''
        @case_gtin_code = window.prop ''
        @has_attachments = window.prop false
        @requires_import_statement = window.prop false
        @has_import_statement = window.prop false
        @is_communicated_on = window.prop ''
        @number_of_euro_pallets = window.prop ''
        @number_of_block_pallets = window.prop ''
        @sent_to_loendersloot_on = window.prop ''
        @expected_delivery_date = window.prop ''
        @warehouse_instruction = window.prop ''
        @freight_instruction = window.prop ''
        @frontoffice_instruction = window.prop ''
        @number_of_edi_messages = window.prop ''

        @delivery = window.prop new Delivery
        @buy_from_account_sales_order = window.prop {}

        # Invoice data
        @is_invoiced_on = window.prop ''
        @intake_date = window.prop ''
        @supplier_invoice_reference = window.prop ''
        @invoiced_transport_costs = window.prop 0
        @invoiced_pallet_costs = window.prop 0
        @invoiced_other_costs = window.prop 0
        @invoice_price_difference = window.prop 0
        @invoiced_amount = window.prop 0


        for prop of json
            if prop == 'supplier'
                @supplier new Supplier json[prop]
                @supplier_artkey @supplier!artkey!
            else if prop == 'account'
                @account_artkey json[prop].artkey
                @account new Account json[prop]
            else if prop == 'delivery'
                @delivery new Delivery json[prop]
            else if prop == 'target_warehouse'
                @target_warehouse new Warehouse json[prop]
                @target_warehouse_artkey json[prop].artkey
            else if prop == 'insurance_type'
                @insurance_type app.$m.data.insurance_type.create_insurance_type json[prop]
                @insurance_type_artkey @insurance_type!artkey!
            else if prop == 'was_handled_by'
                @was_handled_by new User json[prop]
                @was_handled_by_artkey @was_handled_by!artkey!
            else if prop == 'buy_from_account_sales_order'
                if json[prop]
                    @buy_from_account_sales_order {
                        artkey: window.prop json[prop]["artkey"]
                        reference: window.prop json[prop]["reference"]
                        account_slug: window.prop json[prop]["account"]["slug"]
                        account_name: window.prop json[prop]["account"]["name"]
                    }
                else
                    @buy_from_account_sales_order {
                        artkey: window.prop null
                        reference: window.prop null
                        account_slug: window.prop null
                        account_name: window.prop null
                    }
            else
                @[prop] = window.prop json[prop]

    total_costs: ~>
        return +@warehouse_base_costs! + +@transport_costs! + +@warehouse_costs_per_case! * +@number_of_cases!

    costs_per_case: ~>
        return (+@warehouse_base_costs! + +@transport_costs!) / +@number_of_cases! + +@warehouse_costs_per_case!

    is_confirmed: ~>
        @status! == PurchaseOrderStatus.CONFIRMED

    is_in_stock: ~>
        @status! == PurchaseOrderStatus.IN_STOCK


export class PurchaseOrderItem
    (json, purchase_order) ->
        @artkey = window.prop ''
        @case_artkey = window.prop ''
        @case = window.prop new Case
        @creation_date = window.prop ''
        @bought_against_rate = window.prop ''
        @refers_to_artkey = window.prop ''
        @items = window.prop []
        @was_bought_for = window.prop ''
        @suggested_price_per_case = window.prop ''
        @euro_was_bought_for = window.prop ''
        @euro_was_bought_for_plus_costs = window.prop ''
        @currency = window.prop ''
        @availability_date = window.prop ''
        @number_of_cases = window.prop ''
        @is_in_stock = window.prop false
        @purchase_order = window.prop (purchase_order || new PurchaseOrder)
        @purchase_order_artkey = window.prop if purchase_order then purchase_order.artkey! else ''
        @comment = window.prop ''
        @cases_per_pallet = window.prop null
        @cases_per_pallet_layer = window.prop null
        @remark = window.prop null
        @bottle_gtin_code = window.prop ''
        @case_gtin_code = window.prop ''
        @suggested_price_per_case = window.prop null
        @country_of_origin = window.prop null
        @hide_from_pricelist_for_suppliers = window.prop []
        @hide_from_pricelist_for_countries = window.prop []

        for prop of json
            if prop == 'case'
                @case_artkey json[prop].artkey
                @case new Case json[prop]
            else if prop == 'items'
                @items [new Item item for item in json[prop]]
            else if not purchase_order and prop == 'purchase_order'
                # Don't create a new object if it was already provided.
                @purchase_order app.$m.purchase_orders.create_purchase_order json[prop]
                @purchase_order_artkey @purchase_order!artkey!
            else
                @[prop] = window.prop json[prop]

        @bottle_artkey = @case!bottle_artkey
        @bottle = @case!bottle
        @number_of_bottles_per_case = @case!number_of_bottles
        @gift_box_type = @case!gift_box_type
        @tax_label = @case!tax_label
        @customs_status = @case!customs_status
        @item_tags = @case!item_tags
        @best_before_date = @case!best_before_date

    item_tags_per_category: ->
        @item_tags!
        |> map app.$m.data.item_tag.get_item_tag
        |> compact
        |> group-by (.category_artkey!)

    was_bought_for_plus_costs: ~>
        return +@was_bought_for! + +@purchase_order!costs_per_case!

    total_price: ~>
        return +@number_of_cases! * +@was_bought_for!

    specs: ~>
        size = (+@bottle!volume!).toFixed(1)
        alc = (+@bottle!alcohol_percentage!).toFixed(1)
        ref = @bottle!refill_status!
        "#{size}cl / #{alc}% / #{ref}"

    total_number_of_cases_in_items: ~>
        total_number_of_cases = 0
        for item in @items!
            if item.number_of_cases!
                total_number_of_cases += +item.number_of_cases!
        return total_number_of_cases

export class PurchaseOrderItemsDataModel
    ->

    create_purchase_order_items: (purchase_order_items, purchase_order) ~>
        [@create_purchase_order_item poi, purchase_order for poi in purchase_order_items]

    create_purchase_order_item: (purchase_order_item, purchase_order) ~>
        new PurchaseOrderItem purchase_order_item, purchase_order

purchase_orderitems = new PurchaseOrderItemsDataModel


export class PurchaseOrdersDataModel
    ->

    create_purchase_order: (purchase_order) ~>
        purchase_order_object = new PurchaseOrder purchase_order
        if 'purchase_order_items' of purchase_order
            purchase_order_object.purchase_order_items purchase_orderitems.create_purchase_order_items \
                purchase_order.purchase_order_items, purchase_order_object
        return purchase_order_object
