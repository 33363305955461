m = require 'mithril'

api = require 'api.ls'
{filter, map} = require 'prelude-ls'
{button-with-icon} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{EmailPreview} = require 'email/components/email_preview.ls'
{prevent-default, format-date, format-date-time, download_binary_file_from_base64_str_with_type} = require 'utils.ls'
{EmailRecipientType} = require '@/models/data'

export class EmailTable
    (vnode) ->
        @email_collection = vnode.attrs.email_collection

    export_attachment: (artkey, s3_key) ~>
        data = do
            artkey: artkey
            s3_key: s3_key
        api.call-and-then "email.attachment.get", data, do
            success: (resp) ~>
                download_binary_file_from_base64_str_with_type resp.content, resp.file_name, resp.content_type

    view: ->
        m '.row', m '.col-sm-12', m CollectionTable, do
            collection: @email_collection
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'email_batch_emails_sent'
            view_details: (record) ~>
                m '.col-sm-8.content-preview' m EmailPreview, email: -> record.body
            columns:
                do
                    width: 2
                    name: 'Sent on'
                    field: 'sent_on'
                    sort: true
                    transform: format-date-time
                do
                    width: 3
                    name: 'Subject'
                    field: 'subject'
                    sort: true
                    ellipsis: true
                do
                    width: 3
                    name: 'From'
                    field: 'email_recipient_from'
                    sort: true
                    ellipsis: true
                    function: (record) ~>
                        record.recipients
                            |> filter (.type == EmailRecipientType.FROM)
                            |> map (record) ~>
                                m '' record.emailaddress
                do
                    width: 3
                    name: 'To'
                    field: 'email_recipient_to'
                    sort: true
                    ellipsis: true
                    function: (record) ~>
                        record.recipients
                            |> filter (.type == EmailRecipientType.TO)
                            |> map (record) ~>
                                m '' record.emailaddress
                do
                    width: 3
                    name: 'Cc'
                    sort: true
                    ellipsis: true
                    function: (record) ~>
                        record.recipients
                            |> filter (.type == EmailRecipientType.CC)
                            |> map (record) ~>
                                m '' record.emailaddress
                do
                    width: 3
                    name: 'Bcc'
                    sort: true
                    ellipsis: true
                    function: (record) ~>
                        record.recipients
                            |> filter (.type == EmailRecipientType.BCC)
                            |> map (record) ~>
                                m '' record.emailaddress
                do
                    width: 3
                    name: 'State'
                    field: 'sent_state'
                    sort: true
                    ellipsis: true
                do
                    width: 3
                    name: 'Attachments'
                    sort: false
                    ellipsis: true
                    function: (record) ~>
                        email_artkey = record.artkey
                        record.attachments |> map (record) ~>
                            button-with-icon 'Download attachment', 'fa-download', do
                                class: 'btn-default'
                                onclick: (e) ~>
                                    e.stopPropagation!
                                    @export_attachment(email_artkey, record.s3_key)
                                    return false
