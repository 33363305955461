import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {GetFastSalesOrderWithItemsResponse} from '@/factserver_api/sales_api'

export interface RelationMemoAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
}

/**
 * Shows the memo field of the supplier.
 */
export class RelationMemo extends MithrilTsxComponent<RelationMemoAttrs> {
    memo_expanded = false

    view(vnode: m.Vnode<RelationMemoAttrs>): m.Children {
        const sales_order = vnode.attrs.sales_order
        const eu_address_alert = sales_order.supplier.requires_eu_address && sales_order.has_items_missing_eu_address
        return (
            <div>
                {eu_address_alert && (
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <div className={'alert alert-warning'}>
                                <span className={'glyphicon glyphicon-warning-sign'} />
                                <span>
                                    {' '}
                                    This relation is operating from a country that requires an EU address on the label.
                                    This sales order contains items that are missing an EU address on the label.
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {sales_order.supplier.memo && (
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <div
                                className={
                                    this.memo_expanded
                                        ? 'alert alert-info sales-order-memo--active'
                                        : 'alert alert-info sales-order-memo'
                                }
                                onclick={() => (this.memo_expanded = !this.memo_expanded)}
                            >
                                <span className={'glyphicon glyphicon-info-sign'} />

                                <span> Memo: </span>
                                {this.memo_expanded && <br />}
                                <span>{sales_order.supplier.memo}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
