{Account} = require '@/models/accounts'
{User} = require '@/models/users'
app = require('@/app')

export class LeadStatus
    @UPLOADED = 'uploaded'
    @REGISTERED = 'registered'
    @ARCHIVED = 'archived'
    @CONVERTED = 'converted'

    @choices = [
        [@UPLOADED, 'Uploaded'],
        [@REGISTERED, 'Registered'],
        [@ARCHIVED, 'Archived'],
        [@CONVERTED, 'Converted'],
    ]


export class LeadContactStatus
    @NOT_YET_APPROACHED = "NOT_YET_APPROACHED"
    @APPROACHED = "APPROACHED"
    @FINALIZED = "FINALIZED"

    @choices = [
        [@NOT_YET_APPROACHED, 'Not yet approached'],
        [@APPROACHED, 'Approached'],
        [@FINALIZED, 'Finalized'],
    ]


export class LeadSource
    @REGISTRATION_FORM = "REGISTRATION_FORM"
    @DATA_FILE = "DATA_FILE"


export class LeadLogType
    @CONTACT_UPDATE = "CONTACT_UPDATE"
    @MESSAGE = "MESSAGE"


export class Lead
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @company_type = window.prop ''
        @operates_online = window.prop false
        @url = window.prop ''
        @status = window.prop ''

        @created_on = window.prop ''
        @was_last_updated_on = window.prop ''

        @contact_status = window.prop ''

        @street_address = window.prop ''
        @zip_code = window.prop ''
        @city = window.prop ''
        @country_code = window.prop ''
        @emailaddress = window.prop ''
        @telephone_number = window.prop ''

        @manager_artkey = window.prop ''
        @manager = window.prop new User

        @vat_id = window.prop ''

        @memo = window.prop ''
        @message = window.prop ''

        @account_artkey = window.prop ''
        @account = window.prop new Account

        @converted_to_relation_artkey = window.prop ''

        @contact_persons = window.prop []
        @warehouses = window.prop []

        @redeem_code = window.prop ''
        @redeem_code_valid_until = window.prop ''

        for prop of json
            if prop == 'manager'
                @manager app.$m.users.create_user json[prop]
            else if prop == 'contact_persons'
                for contact_person in json[prop]
                    @contact_persons!push(new LeadContactPerson contact_person)
            else if prop == 'warehouses'
                for warehouse in json[prop]
                    @warehouses!push(new LeadWarehouse warehouse)
            else
                @[prop] = window.prop json[prop]


export class LeadContactPerson
    (json) ->
        for prop of json
            @[prop] = window.prop json[prop]


export class LeadWarehouse
    (json) ->
        @country_code = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]
