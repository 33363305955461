m = require 'mithril'

api = require 'api.ls'
{countries} = require '@bitstillery/common/lib/countries'
{Warehouse} = require '@/models/data'
app = require('@/app')


module.exports = class WarehouseDeactivate
    ->
        @destination = window.prop new Warehouse
        @can_deactivate = window.prop false
        @purchase_orders_count = window.prop false
        @sales_orders_count = window.prop false

        warehouse_artkey = m.route.param 'artkey'
        if warehouse_artkey
            @query_warehouse warehouse_artkey

        @supplier_artkey = m.route.param 'supplier_artkey'

    query_warehouse: (artkey) ~>
        api.call 'destinations.can_deactivate_destination' {artkey: artkey}, @set_warehouse

    set_warehouse: (resp) ~>
        if resp.success
            @can_deactivate resp.result.can_deactivate
            @purchase_orders_count resp.result.purchase_orders_count
            @sales_orders_count resp.result.sales_order_count
            @destination app.$m.data.warehouses.create_warehouse resp.result.warehouse
        else
            msg = if resp.message then resp.message else 'Something went wrong while retrieving the warehouse. Please try again later.'
            app.notifier.notify msg, 'danger'
            m.route.set '/data/warehouses'

    deactivate_destination: ~>
        if @supplier_artkey
            api.call 'destinations.unlink_supplier_warehouse' {warehouse_artkey: @destination!artkey!, supplier_artkey: @supplier_artkey}, @handle_deactivate_destination
        else
            api.call 'destinations.deactivate_destination' {artkey: @destination!artkey!}, @handle_deactivate_destination

    handle_deactivate_destination: (resp) ~>
        if resp.success
            app.$m.common.observable.broadcast 'warehouses_updated', ''
            app.notifier.notify "Successfully deactivated #{@destination!destination_type!} #{@destination!name!}.", 'success'
            if @supplier_artkey
                m.route.set "/crm/relations/#{@supplier_artkey}/edit/"
            else
                m.route.set '/data/warehouses'
        else
            app.notifier.notify "Deactivation of warehouse #{@destination!name!} failed.", 'danger'

    redirect_after_cancel: ~>
        if @supplier_artkey
            m.route.set "/crm/relations/#{@supplier_artkey}/edit/warehouses/#{@destination!artkey!}/edit"
        else
            m.route.set "/data/warehouses/#{@destination!artkey!}/edit"

    view: -> m '.deactivate-warehouse',
        m '.row#button-bar' m '.col-lg-12.btn-toolbar',
            m 'button.btn.btn-link' {type: "button", \
              onclick: ~> m.route.set "/data/warehouses/#{@destination!artkey!}/edit"},
              (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to warehouse '

        m '.row' m '.col-xs-12' m 'h2' 'Deactivate warehouse'

        if @can_deactivate!
            m '.row' m '.col-md-6 .col-xs-12' m '.alert.alert-info' \
                'Would you like to deactivate the following warehouse?'
        else
            m '.row' m '.col-md-6 .col-xs-12' m '.alert.alert-warning',
                'The following warehouse ', (m 'strong' 'cannot be deactivated'), ' because it has:'
                m 'ul',
                    if @purchase_orders_count! > 0
                        m 'li' "#{@purchase_orders_count!} purchase orders"
                    if @sales_orders_count! > 0
                        m 'li' "#{@sales_orders_count!} sales orders"

        m '.row' m '.col-md-6 .col-xs-12' m '.panel.panel-default',
            m '.panel-heading' m 'h4' @destination!name!
            m '.panel-body' m 'dl.dl-horizontal',
                m 'dt', 'Name'
                m 'dd', @destination!name!
                m 'dt', 'Email'
                m 'dd', @destination!emailaddress!
                m 'dt', 'Address'
                m 'dd', @destination!street_address!
                m 'dt', 'Zip code'
                m 'dd', @destination!zip_code!
                m 'dt', 'City'
                m 'dd', @destination!city!
                m 'dt', 'Country'
                m 'dd', countries[@destination!country_code!]

        m '.row.mb-2',
            m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-default' \
                {onclick: ~> @redirect_after_cancel!} 'Cancel'
            if @can_deactivate!
                m '.col-md-3 col-xs-6' m 'button.form-control.btn.btn-success' \
                    {onclick: @deactivate_destination} 'Deactivate'
