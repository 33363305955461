import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {product_photo_image_location} from '@bitstillery/common/ts_utils'
import Sortable from 'sortablejs'
import {Button, ButtonGroup, Dialog} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {config, notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {Link} from '../components/discover'

import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {GetProductPhotosForReviewResponse, ProductPhotoData} from '@/factserver_api/stock_api'
import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {RotateDirection} from '@/factserver_api/fact2server_api'
import {CheckBox} from '@/components/html_components'

class ItemImageView extends MithrilTsxComponent<
    CollectionTableRowComponentProps<GetProductPhotosForReviewResponse, void>
> {
    sortable: Sortable | null = null
    item_photo_data: ProductPhotoData[] = []
    product_photo_api = new ProductPhotoApi()
    is_loading = false

    data = proxy({
        photo: {
            detail: null,
        },
    })

    delete_photo(product_photo: ProductPhotoData, row: GetProductPhotosForReviewResponse) {
        this.product_photo_api.delete(product_photo.artkey).subscribe({
            next: () => {
                row.product_photos = row.product_photos.filter((photo) => String(photo.artkey) !== String(product_photo.artkey))
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    oncreate(vnode: m.Vnode<CollectionTableRowComponentProps<GetProductPhotosForReviewResponse, void>>): void {
        this.item_photo_data = vnode.attrs.row.product_photos
        // Initialise the sortable list
        const photo_list_element = document.getElementById(`photo-list-${vnode.attrs.row.artkey}`)
        if (!photo_list_element) {
            throw new Error(`Programmer error, cannot locate element photo-list-${vnode.attrs.row.artkey}`)
        }
        this.sortable = Sortable.create(photo_list_element, {
            animation: 150,
            onEnd: () => this.rerank(),
        })
    }

    rerank() {
        if (!this.sortable) {
            return
        }
        const artkeys = this.sortable.toArray().map((artkey) => +artkey)

        let rank = 1
        artkeys.forEach((artkey) => {
            const photo = this.item_photo_data.filter((photo_data) => {
                return photo_data['artkey'] === artkey
            })[0]
            photo.rank = rank
            rank++
        })
        m.redraw()
    }

    rotate_photo(product_photo: ProductPhotoData, rotate_direction: RotateDirection) {
        this.is_loading = true
        this.product_photo_api.rotate(product_photo.artkey, rotate_direction).subscribe({
            next: (result) => {
                product_photo.s3_location = result.s3_location
                // product_photo.s3_location_thumbnail = result.s3_location_thumbnail
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<CollectionTableRowComponentProps<GetProductPhotosForReviewResponse, void>>): m.Children {
        return (
            <td className="c-item-image-view">
                {!vnode.attrs.row.is_showing_details && <span />}

                {this.data.photo.detail && <Dialog
                    onclose={() => {
                        this.data.photo.detail = null
                    }}
                    title={(() => {
                        const photo = this.data.photo.detail
                        let title = `Rank: ${photo.rank} ${photo.is_internal ? '(internal)' : ''} - ${photo.s3_location}`
                        return title
                    })()}
                >
                    <img src={product_photo_image_location(config.product_photo_host, {
                        s3_location: this.data.photo.detail.s3_location,
                        s3_location_thumbnail: null,
                    })}/>
                </Dialog>}

                {!vnode.attrs.row.is_showing_details && (
                    <div className="photos" id={`photo-list-${vnode.attrs.row.artkey}`}>
                        {vnode.attrs.row.product_photos.map((photo) => (
                            <div
                                className="product-photo"
                                id={`movable-photo-${photo.artkey}`}
                                key={photo.artkey}
                                data-id={photo.artkey}
                            >
                                <div className="image">
                                    <img
                                        src={product_photo_image_location(config.product_photo_host, {
                                            s3_location: photo.s3_location,
                                            s3_location_thumbnail: null,
                                        })}
                                        alt={`Cases for ${vnode.attrs.row.artkey}`}
                                    />
                                </div>

                                <div className="controls">
                                    <ButtonGroup>
                                        <CheckBox
                                            disabled={this.is_loading}
                                            checked={photo.is_internal}
                                            label="Internal"
                                            id={`flip_is_internal-${photo.artkey}`}
                                            onchange={() => (photo.is_internal = !photo.is_internal)}
                                        />
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            disabled={this.is_loading}
                                            icon="search"
                                            onclick={() => {
                                                this.data.photo.detail = photo
                                            }}
                                            size="s"
                                        />
                                        <Button
                                            disabled={this.is_loading}
                                            onclick={() => this.rotate_photo(photo, RotateDirection.Left)}
                                            icon="rotateLeft"
                                            size="s"
                                        />
                                        <Button
                                            disabled={this.is_loading}
                                            icon="rotateRight"
                                            onclick={() => this.rotate_photo(photo, RotateDirection.Right)}
                                            size="s"
                                        />

                                        <Button
                                            disabled={this.is_loading}
                                            onclick={() => this.delete_photo(photo, vnode.attrs.row)}
                                            icon="trash"
                                            size="s"
                                            type="danger"
                                        />
                                    </ButtonGroup>
                                </div>

                            </div>
                        ))}
                    </div>
                )}
            </td>
        )
    }
}

export default class ImageReview extends MithrilTsxComponent<unknown> {
    product_photo_api = new ProductPhotoApi()
    product_photo_fetcher = new PagedCollectionFetcher<GetProductPhotosForReviewResponse>(
        'stock.get_stock_product_photos_for_review',
        'rank',
        undefined,
        10,
        false,
    )

    total_number_to_review: number | null = null
    number_reviewed = 0
    is_loading_count = true

    get_total_count(): void {
        this.product_photo_api.get_total_number_to_review().subscribe(
            {
                next: (response) => {
                    this.total_number_to_review = response
                    this.is_loading_count = false
                    m.redraw()
                },
            },
        )
    }

    oncreate(): void {
        this.get_total_count()
    }

    update_item_photos(item: GetProductPhotosForReviewResponse): void {
        this.product_photo_api.review_item_photos(item).subscribe({
            next: () => {
                notifier.notify(`Photos for ${item.purchase_order_item.case.bottle.product.name} reviewed`, 'success')
                item.is_approved = true
                item.is_showing_details = true
                if (this.total_number_to_review) {
                    this.total_number_to_review = this.total_number_to_review - 1
                }
                this.number_reviewed += 1
                m.redraw()
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-stock-image-review view">
                <div className="btn-toolbar">
                    <Button
                        icon={this.product_photo_fetcher.sort_ascending ? 'sortDescending' : 'sortAscending'}
                        text={`${this.product_photo_fetcher.sort_ascending ? 'Latest' : 'Oldest'} first`}
                        disabled={this.product_photo_fetcher.is_fetching}
                        onclick={() => {
                            this.product_photo_fetcher.sort_ascending = !this.product_photo_fetcher.sort_ascending
                            this.product_photo_fetcher.reset_and_query()
                        }}
                    />
                </div>

                <CollectionTable<GetProductPhotosForReviewResponse, void>
                    collection_fetcher={this.product_photo_fetcher}
                    on_row_click={(row: GetProductPhotosForReviewResponse) =>
                        (row.is_showing_details = !row.is_showing_details)
                    }
                    on_row_click_component={ItemImageView}
                >
                    <CollectionTableColumn
                        header_title={() => <span>
                            {!this.is_loading_count && (
                                <div>Showing {this.product_photo_fetcher.count_fetched_rows() - this.number_reviewed} of {this.total_number_to_review} items to review</div>
                            )}
                            {this.is_loading_count && (
                                <Spinner />
                            )}
                        </span>}
                        data_field={(row: GetProductPhotosForReviewResponse) => <div className="item-header">
                            <ButtonGroup>
                                <Button
                                    icon="thumbUp"
                                    size="s"
                                    type="info"
                                    tooltip={'Approve'}
                                    disabled={row.is_approved}
                                    onclick={() => this.update_item_photos(row)}
                                />
                                <Button
                                    link={`/stock/manage/${row.reference}`}
                                    icon="search"
                                    size="s"
                                />
                            </ButtonGroup>
                            <span>
                                <Link href={`/purchase-orders/manage/${row.purchase_order_item.purchase_order.artkey}`}>
                                    {row.purchase_order_item.purchase_order.reference}
                                </Link>
                                {' - '}
                                {row.purchase_order_item.case.bottle.product.name}
                                {' - '}
                                <Link href={`/stock/manage?q=${row.reference}`}>
                                    {row.lot} ({row.reference})
                                </Link>{' '}

                            </span>
                        </div>}
                    />
                </CollectionTable>
            </div>
        )
    }
}
