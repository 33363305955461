app = require('@/app')
Sentry = require '@sentry/browser'


const GENERIC_MESSAGE =
    '''Something went wrong while processing your request.
       Please try again later. If the problem persists, please contact us.'''


export generic_handler = (error) ->
    message = (error and error.message) || error || GENERIC_MESSAGE
    Sentry.captureException message
    console.error 'Error: ' + message
    app.notifier.notify message, 'danger'

export generic_handler_no_sentry = (error) ->
    app.notifier.notify error || GENERIC_MESSAGE, 'danger'
