m = require 'mithril'
{Popover} = require '@/components/popover/popover.ls'


export icon = (id, attrs={}) ->
    return m "span.#{icon-class(id)}" attrs


export icon-class = (id) ->
    if id.startsWith 'fab' then
        return "#{id}"
    else if id.startsWith 'fa' then
        if id.startsWith 'fa-' then
            return "fas #{id}"
        else
            return "#{id}"
    # Flag icons: icon 'flag-icon'nl'
    else if id.startsWith 'flag-' then
        return "flag-icon.#{id}"
    else if id.startsWith 'glyphicon-' then
        return "glyphicon #{id}"
    else
        return "glyphicon glyphicon-#{id}"



export icon-with-popover = ({icon-id, title, content}, attrs={}) ->
    m Popover, do
        title: title
        content: content
    , icon(icon-id, attrs)
