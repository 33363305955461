import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {DropDownOption, DropDownWithSelect} from './html_components'

import {$s} from '@/app'
import {CurrenciesApi} from '@/factserver_api/currencies'

interface CurrenciesDropDownAttrs {
    selected_currency: string
    onchange: (currency: {
        currency: string,
        exchange_rate: number,
    } | null) => unknown
    disabled?: boolean
    required?: boolean
}

/**
 * Currencies drop down.
 *
 * Usage:
 *  <CurrenciesDropDown
 *      disabled={!this.selected_relation}
 *      required={true}
 *      selected_currency={this.currency}
 *      onchange={(currency) => {
 *          this.currency = currency.currency
 *          this.exchange_rate = currency.exchange_rate
 *      }}
 *  />
 **/
export class CurrenciesDropDown extends MithrilTsxComponent<CurrenciesDropDownAttrs> {
    currencies_api = new CurrenciesApi()

    async oncreate() {
        await this.currencies_api.get_all_currencies()
    }

    view(vnode: m.Vnode<CurrenciesDropDownAttrs>): m.Children {
        return (
            <DropDownWithSelect
                onchange={(selected_currency: string) => {
                    vnode.attrs.onchange({
                        currency: selected_currency,
                        exchange_rate: $s.currencies.exchange_rates[selected_currency].rate,
                    })
                }
                }
                disabled={vnode.attrs.disabled}
                selected={vnode.attrs.selected_currency}
                required={vnode.attrs.required}
            >
                {$s.currencies.all.map((currency) => (
                    <DropDownOption value={`${currency}`}>{currency}</DropDownOption>
                ))}
            </DropDownWithSelect>
        )
    }
}
