import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'

import {drop_down_menus_for_enum, DropDownWithSelect} from '@/components/html_components'

export interface WidgetSelectorProps {
    selection_changed: (widget_selection_data: WidgetSelectionData) => void
    initial_period: Period
    show_sales_managers: boolean
}

export interface WidgetSelectionData {
    from_period: DateTime
    till_period: DateTime
}

export enum Period {
    WEEK = 'This week',
    MONTH = 'This month',
    THIRTY_DAYS = 'Past 30 days',
    SEVEN_DAYS = 'Past 7 days',
    YEAR = 'This year',
    THREE_SIXTY_DAYS = 'Past 360 days',
    ONE_EIGHTY_DAYS = 'Past 180 days',
}

export class WidgetSelector extends MithrilTsxComponent<WidgetSelectorProps> {

    selected_period = Period.MONTH
    widget_selection_data: WidgetSelectionData = {
        from_period: DateTime.local(),
        till_period: DateTime.local(),
    }

    selection_changed_callback: (widget_selection_data: WidgetSelectionData) => void = () => {
        return // default implementation noop.
    }

    constructor() {
        super()

        this.widget_selection_data.till_period = DateTime.local()
        this.widget_selection_data.from_period = this.calculate_from_period(this.selected_period)
    }

    oncreate(vnode: m.VnodeDOM<WidgetSelectorProps, this>): void {
        this.selection_changed_callback = vnode.attrs.selection_changed
        this.selected_period = vnode.attrs.initial_period
        this.widget_selection_data.till_period = DateTime.local()
        this.widget_selection_data.from_period = this.calculate_from_period(this.selected_period)
        this.selection_changed_callback(this.widget_selection_data)
    }

    on_change_selected_period(period: Period): void {
        this.widget_selection_data.till_period = DateTime.local().plus({days: 1})
        this.selected_period = Object.values(Period).find((p) => p === period) || Period.MONTH
        this.widget_selection_data.from_period = this.calculate_from_period(this.selected_period)

        this.selection_changed_callback(this.widget_selection_data)
    }

    calculate_from_period(period: Period, till: DateTime = this.widget_selection_data.till_period): DateTime {
        if (period === Period.WEEK) {
            return till.startOf('week')
        } else if (period === Period.MONTH) {
            return till.startOf('month')
        } else if (period === Period.SEVEN_DAYS) {
            return till.minus({days: 7})
        } else if (period === Period.THIRTY_DAYS) {
            return till.minus({days: 30})
        } else if (period === Period.THREE_SIXTY_DAYS) {
            return till.minus({days: 360})
        } else if (period === Period.ONE_EIGHTY_DAYS) {
            return till.minus({days: 180})
        } else if (period === Period.YEAR) {
            return till.startOf('year')
        }
        throw new Error('Unsupported period.')
    }

    view(): m.Children {
        return (<DropDownWithSelect
            selected={this.selected_period.valueOf()}
            onchange={(value: string) =>
                this.on_change_selected_period(
                    Object.values(Period).find((period) => period === value) || Period.MONTH,
                )
            }
        >
            {drop_down_menus_for_enum(Period)}
        </DropDownWithSelect>)
    }
}
