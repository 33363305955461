import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {ReportDateRangePicker} from './report_and_date_picker'
import {LedgerSalesOrderReport} from './ledger_sales_order_report'
import {ReportMetaInformation} from './report_meta_information'
import {LedgerPurchaseOrderReport} from './ledger_purchase_order_report'
import {InsurancePurchaseOrderReport} from './insurance_purchase_order_report'
import {CountriesTradeReport} from './countries_trade_report'
import {InsuranceSalesOrderReport} from './insurance_sales_order_report'
import {ModifiedProductsReport} from './modified_products_report'
import {StockAgeReport} from './stock_age_report'
import {StockHistoryReport} from './stock_history_report'
import {BrandTransactionsReport} from './brand_transactions_report'
import {RelationsLogisticsReport} from './relations_logistics_report'
import {StockReconciliationReport} from './stock_reconciliation_report'

import {Report, ReportArguments} from '@/factserver_api/reporting_api'

export interface RequeryListener {
    fire: (report_arguments: ReportArguments) => unknown
}

export default class ReportsPage extends MithrilTsxComponent<unknown> {
    report_arguments: ReportArguments
    // additional_report_args: Record<string, unknown> = {}
    requery_components: RequeryListener[] = []

    constructor() {
        super()
        this.report_arguments = {
            report: null,
            start_date: null,
            end_date: null,
            additional_args: {},
        }
    }

    update_selected_report(report_arguments: ReportArguments): void {
        this.report_arguments = report_arguments
        this.requery_components.forEach((component) => component.fire(report_arguments))
    }

    add_requery_listener = (component: RequeryListener): void => {
        this.requery_components.push(component)
    }

    remove_requery_listener = (component: RequeryListener): void => {
        this.requery_components = this.requery_components.filter((item) => item !== component)
    }

    view(): m.Children {
        return (
            <div className="c-reports view">
                <ReportDateRangePicker
                    report_arguments={this.report_arguments}
                    on_get_report={(report_arguments: ReportArguments) =>
                        this.update_selected_report(report_arguments)
                    }
                    on_update_report={(selected_report: string) => {
                        this.report_arguments.report = selected_report
                        this.update_selected_report(this.report_arguments)
                    }}
                />

                {this.report_arguments.report && (
                    <ReportMetaInformation
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.LEDGER_SALES_ORDER_REPORT && (
                    <LedgerSalesOrderReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.LEDGER_PURCHASE_ORDER_REPORT && (
                    <LedgerPurchaseOrderReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.INSURANCE_PURCHASE_ORDERS_REPORT && (
                    <InsurancePurchaseOrderReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}
                {this.report_arguments.report === Report.INSURANCE_SALES_ORDERS_REPORT && (
                    <InsuranceSalesOrderReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.MODIFIED_PRODUCTS_REPORT && (
                    <ModifiedProductsReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.STOCK_AGE_REPORT && (
                    <StockAgeReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.DUPLICATE_STOCK_ARTICLES && <p>This report is export-only</p>}

                {this.report_arguments.report === Report.STOCK_HISTORY_REPORT && (
                    <StockHistoryReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.LIS_WMS_ARTICLES && <p>This report is export-only</p>}

                {this.report_arguments.report === Report.BRAND_TRANSACTIONS_REPORT && (
                    <BrandTransactionsReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.COUNTRIES_TRADE_REPORT && (
                    <CountriesTradeReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.RELATIONS_LOGISTICS_REPORT && (
                    <RelationsLogisticsReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}

                {this.report_arguments.report === Report.STOCK_RECONCILIATION_REPORT && (
                    <StockReconciliationReport
                        report_arguments={this.report_arguments}
                        add_requery_listener={this.add_requery_listener}
                        remove_requery_listener={this.remove_requery_listener}
                    />
                )}
            </div>
        )
    }
}
