m = require 'mithril'
{group-by, Obj} = require 'prelude-ls'

{classes} = require '@bitstillery/common/lib/utils'
api = require 'api.ls'
app = require('@/app')
utils = require 'utils.ls'
{Spinner} = require '@bitstillery/common/components'
{Amount} = require '@bitstillery/common/components'
{pricelist_link} = require '../components/pricelist_link.ls'
{Bottle} = require '@/models/bottles'
{Case} = require '@/models/stock'

class BottleGTINModel
    (json) ->
        @artkey = window.prop ''
        @bottle_artkey = window.prop ''
        @bottle = window.prop new Bottle
        @gtin = window.prop ''

        for prop of json
            if prop == 'bottle'
                @bottle_artkey json[prop].artkey
                @bottle app.$m.bottles.create_bottle json[prop], app.$m.products.create_product json[prop]['product']
            else
                @[prop] = window.prop json[prop]

    toJS: ~> do
        artkey: +@artkey!
        bottle_artkey: +@bottle_artkey!
        gtin: +@gtin!

module.exports = class BottleOnlyForm
    (vnode) ->
        @bottle = window.prop vnode.attrs.bottle
        @on_check = vnode.attrs.on_check
        @loading_source_lines = window.prop false
        @show_cases = window.prop false
        @cases = window.prop []
        @loading_cases = window.prop false
        @show_bottle_gtins = window.prop false
        @bottle_gtins = window.prop []
        @loading_bottle_gtins = window.prop false
        @deleting_bottle_gtin = window.prop false
        @new_bottle_gtin = window.prop ''
        @case_to_edit = window.prop null

    toggle_bottle_cases: ~>
        if @show_cases!
            @show_cases false
            @cases []
        else
            @show_cases true
            @load_bottle_cases!

    load_bottle_cases: ~>
        @loading_cases true
        data = do
            bottle_artkey: @bottle!artkey!
        api.call 'product_management.get_cases_for_bottle', data, @handle_bottle_cases

    handle_bottle_cases: (result) ~>
        if result.success
            @cases [new Case json_data for json_data in result['result']]
        else
            app.$m.common.generic_error_handler!
        @loading_cases false

    toggle_bottle_gtins: ~>
        if @show_bottle_gtins!
            @show_bottle_gtins false
            @bottle_gtins []
        else
            @show_bottle_gtins true
            @load_bottle_gtins!

    load_bottle_gtins: ~>
        @loading_bottle_gtins true
        data = do
            bottle_artkey: @bottle!artkey!
        api.call 'product_management.get_bottle_gtins_for_bottle', data, @handle_bottle_gtins

    handle_bottle_gtins: (result) ~>
        if result.success
            @bottle_gtins [new BottleGTINModel json_data for json_data in result['result']]
        else
             app.$m.common.generic_error_handler!
        @loading_bottle_gtins false

    handle_bottle_gtin_delete: (result) ~>
        if result.success
            app.notifier.notify 'Successfully deleted the bottle gtin.', 'success'
            @load_bottle_gtins!
        else
             app.$m.common.generic_error_handler!
        @deleting_bottle_gtin false

    add_bottle_gtin_to_spec: ~>
        if not @new_bottle_gtin!
            return app.notifier.notify 'Unable to add GTIN: No GTIN was entered', 'danger'

        for bottle_gtin in @bottle_gtins!
            if @new_bottle_gtin! == bottle_gtin.gtin!
                return app.notifier.notify 'Unable to add GTIN: This GTIN is already saved on this bottle', 'danger'

        data = do
            bottle_artkey: @bottle!artkey!
            bottle_gtin_code: @new_bottle_gtin!
        api.call 'product_management.add_bottle_gtin_to_spec', data, @handle_add_bottle_gtin


    handle_add_bottle_gtin: (response) ~>
        if not response.message
            app.notifier.notify 'Successfully added the bottle gtin: '+response.result, 'success'
            @new_bottle_gtin ''
            @load_bottle_gtins!
        else
             app.notifier.notify response.message, 'danger'

    start_edit_case: (caze) ~>
        @case_to_edit window.prop null
        @case_to_edit!artkey = window.prop caze.artkey!
        @case_to_edit!cases_per_pallet = window.prop caze.cases_per_pallet!
        @case_to_edit!cases_per_pallet_layer = window.prop caze.cases_per_pallet_layer!

    cancel_edit_case: ->
        @case_to_edit null

    save_case: (caze) ~>>
        data = do
            cases_per_pallet: @case_to_edit!cases_per_pallet!
            cases_per_pallet_layer: @case_to_edit!cases_per_pallet_layer!
        response = await app.api.put 'discover/cases/'+@case_to_edit!artkey!, data

        if response.success
            app.notifier.notify 'Successfully updated the case.', 'success'
            caze.cases_per_pallet data.cases_per_pallet
            caze.cases_per_pallet_layer data.cases_per_pallet_layer
        else
            app.$m.common.generic_error_handler!

        @case_to_edit null

    view: ->
        a = -> [.. for &]
        m '.c-bottle-only-form' a do

            m '.fieldset.large',
                m '.field-group' a do
                    m '.field',
                        m 'label' {for: 'bottle-cb' + @bottle!artkey!} m.trust '&nbsp;'
                        m 'input' {
                            type: 'checkbox',
                            id: 'bottle-cb' + @bottle!artkey!,
                            onchange: @on_check,
                            value: @bottle!artkey!
                        }
                    m '.field',
                        m 'label' 'Size in cl'
                        m 'input' {
                            type: 'number',
                            required: true,
                            step: 0.1,
                            min: 0.1,
                            value: (+@bottle!volume!).toFixed(1),
                            onchange: (ev) ~> @bottle!volume ev.target.value
                        }
                    m '.field',
                        m 'label' 'Alcohol %'
                        m 'input' {
                            type: 'number',
                            required: true,
                            step: 0.1,
                            min: 0,
                            value: (+@bottle!alcohol_percentage!).toFixed(1),
                            onchange: (ev) ~>
                                @bottle!alcohol_percentage ev.target.value
                        }
                    m '.field',
                        m 'label' 'Refill status'
                        m 'select', {
                            required: true,
                            value: @bottle!refill_status!toLowerCase!,
                            onchange: (ev) ~> @bottle!refill_status ev.target.value,
                        },
                            app.$m.data.bottle_refill_statusses.map (refill_status) ->
                                m 'option' refill_status

                    m '.field',
                        m 'label' 'Dutch excise'
                        m Amount, do
                            amount: @bottle!excise_nl!
                            currency: app.$s.currencies.default

                m '.btn-group',
                    m 'button.btn.btn-default' {class: classes({active: @show_cases!}), onclick: @toggle_bottle_cases},
                        m 'span.fas.fa-cubes'
                    m 'button.btn.btn-default' {class: classes({active: @show_bottle_gtins!}), onclick: @toggle_bottle_gtins},
                        m 'span.glyphicon.glyphicon-barcode'
                    if @bottle!dirty! then \
                        m 'button.btn.btn-default' {onclick: @bottle!reset} 'Reset'

            if @show_cases!
                m '.well large',
                    if @loading_cases!
                        m Spinner
                    else if Obj.empty @cases!
                        m 'span' 'No cases found for this spec.'
                    else
                        m 'table.table.search-table' a do
                            m 'thead.thead-default' a do
                                m 'tr' a do
                                    m 'th' 'Number of bottles'
                                    m 'th' 'Gift box type'
                                    m 'th' 'Tax label'
                                    m 'th' 'Weight'
                                    m 'th' 'Dimensions (l/w/h)'
                                    m 'th' 'CPP'
                                    m 'th' 'CPL'
                                    m 'th' ''
                            @cases!map (caze) ~>
                                m 'tr' a do
                                    m 'td' caze.number_of_bottles!
                                    m 'td' caze.gift_box_type!
                                    m 'td' caze.tax_label!
                                    m 'td' caze.weight!
                                    m 'td' do
                                        if caze.length! && caze.width! && caze.height!
                                            "#{caze.length!} x #{caze.width!} x #{caze.height!}"
                                    m 'td' do
                                        if @case_to_edit! and @case_to_edit!artkey! == caze.artkey!
                                            m 'input', {
                                                type: 'number',
                                                value: @case_to_edit!cases_per_pallet!,
                                                onchange: (ev) ~> @case_to_edit!cases_per_pallet ev.target.value
                                            }
                                        else
                                            caze.cases_per_pallet!
                                    m 'td' do
                                        if @case_to_edit! and @case_to_edit!artkey! == caze.artkey!
                                            m 'input', {
                                                type: 'number',
                                                value: @case_to_edit!cases_per_pallet_layer!,
                                                onchange: (ev) ~> @case_to_edit!cases_per_pallet_layer ev.target.value
                                            }
                                        else
                                            caze.cases_per_pallet_layer!
                                    m 'td' a do
                                        if @case_to_edit! and @case_to_edit!artkey! == caze.artkey!
                                            m '.btn-group',
                                                m 'button.btn.btn-success' {onclick: (event) ~> @save_case caze},
                                                    m 'span.glyphicon.glyphicon-floppy-disk'
                                                m 'button.btn.btn-default' {onclick: (event) ~> @cancel_edit_case!},
                                                    m 'span.glyphicon.glyphicon-ban-circle'
                                        else
                                            m 'button.btn.btn-default' {
                                                disabled: @case_to_edit!,
                                                onclick: (event) ~> @start_edit_case caze
                                            },
                                                m 'span.glyphicon.glyphicon-pencil'

            if @show_bottle_gtins!
                m '.well large',
                    if @loading_bottle_gtins!
                        m Spinner
                    else if Obj.empty @bottle_gtins!
                        m '.panel-body',
                            m 'field-group',
                                m 'label' 'GTIN code'
                                m '.field',
                                    m 'input', do
                                        placeholder: 'GTIN code (12/13 characters)'
                                        type: 'text'
                                        value: @new_bottle_gtin!
                                        onchange: (ev) ~> @new_bottle_gtin ev.target.value
                                    m '.help' 'No bottle gtins found on this spec yet. You can create a new bottle gtin on this spec:'
                                m 'button.btn.btn-info' {onclick: @add_bottle_gtin_to_spec} 'Add GTIN ',
                                    m 'span.glyphicon.glyphicon-plus',
                    else
                        m 'table.table.search-table' a do
                            m 'thead.thead-default' a do
                                m 'tr' a do
                                    m 'th' 'Bottle GTIN'
                                    m 'th' 'Actions'
                            @bottle_gtins!map (gtin) ~>
                                m 'tr' a do
                                    m 'td' gtin.gtin!
                                    m 'td' a do
                                        m 'button.btn.btn-default' {
                                        value: gtin.gtin!,
                                        disabled: @deleting_bottle_gtin!,
                                        onclick: (event) ~>
                                            if confirm("Are you sure that you want to delete the bottle gtin: #{gtin.gtin!}")
                                                @deleting_bottle_gtin true
                                                data = do
                                                    bottle_gtin: gtin.gtin!
                                                api.call 'product_management.delete_bottle_gtin', data, @handle_bottle_gtin_delete
                                        },
                                            m 'span.glyphicon.glyphicon-trash'
                            m 'tr'
                                m 'td',
                                    m 'input',
                                        placeholder: 'Enter bottle GTIN'
                                        type: 'text'
                                        value: @new_bottle_gtin!
                                        onchange: (ev) ~> @new_bottle_gtin ev.target.value
                                m 'td' a do
                                    m 'button.btn.btn-default' {onclick: @add_bottle_gtin_to_spec},
                                        m 'span.glyphicon.glyphicon-plus'
