m = require 'mithril'
GenericEdit = require '../generic/generic_edit.ls'
{EnumField, Field} = require '../generic/generic_field.ls'
api = require '../../api.ls'
app = require('@/app')

module.exports = class VatRateEdit
    ->
        @vat_types = window.prop []
        api.call 'vattypes.get_all' {}, (resp) ~>
            @vat_types [app.$m.data.vat_type.create item for item in resp.result]

        @fields = [
            new Field 'Country code', 'country_code', 'input', 'text'
            new Field 'Percentage', 'percentage', 'input', 'number', {min: 0, step: 0.01}
            new EnumField 'VAT Type', 'vat_type_artkey',
                @vat_types,
                {required: true},
                get-key=(.artkey!),
                get-value=(vat_type) -> "#{vat_type.vat_code!} - #{vat_type.description!}"
        ]

    view: ->
        m GenericEdit, do
            readable_entity_name: 'VAT rate'
            base_url: '/data/vatrates/'
            query_api_call: 'vatrates.get'
            update_or_create_api_call: 'vatrates.update_or_create'
            fields: @fields
            create_entity_func: app.$m.data.vat_rate.create
            broadcast_on_update: 'vatrates_updated'
