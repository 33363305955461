m = require 'mithril'

api = require 'api.ls'
app = require('@/app')
{map} = require 'prelude-ls'
{a, capitalize} = require 'utils.ls'

{Amount} = require '@bitstillery/common/components'
{Percent} = require '@/components/html_components'
{Badge} = require '@bitstillery/common/badge'
{button-with-icon, icon-button} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{countries} = require '@bitstillery/common/lib/countries'
{icon} = require '@/components/icon.ls'
{select} = require '@/components/inputs'
{format_money} = require '@bitstillery/common/ts_utils'


module.exports = class ExciseCategoryList
    (vnode) ->
        @selected_country = window.prop ''
        @used_countries = window.prop []
        @excise_product_categories = window.prop []
        @unassigned_product_categories_country = window.prop []

        @expand_details = false

        @excise_categories = new Collection do
            api_function_name: 'excise.get_excise_categories_for_country'
            query_limit: 25
            additional_params: window.prop do
                country: @selected_country!

    set_country: (country_code) ~>
        @selected_country country_code

        @excise_categories.additional_params @excise_categories.additional_params! with do
            country: country_code
        @excise_categories.requery!

        api.call-and-then 'excise.get_unassigned_product_categories_for_country', {country: @selected_country!}, do
            success: (resp) ~>
                @unassigned_product_categories_country resp.result

    oncreate: ~>
        app.$m.common.observable.subscribe 'refresh_excise_list', @, ~> @excise_categories.requery!
        api.call-and-then 'excise.get_excise_category_used_countries', {}, do
            success: (resp) ~>
                @used_countries resp.result

                if @used_countries!length > 0 then
                    @set_country @used_countries![0]


    view: -> m '.c-excise-categories view' a do
        m '.btn-toolbar' a do
            button-with-icon 'Create Excise Category', 'plus', do
                class: 'btn-default'
                onclick: ~> m.route.set '/data/excise-categories/create'

        m '.c-filter-group',
            select @selected_country, @used_countries!, do
                label: 'Filter Country',
                onchange: (value) ~>
                    @set_country value
                disabled: @used_countries!length <= 0

        if @unassigned_product_categories_country!length > 0
            m '.unassigned-product-categories',
                m '.title' 'Unassigned product categories'
                @unassigned_product_categories_country!map (col) ~>
                    m Badge, do
                        type: 'primary'
                        additional_classes: 'mr-05'
                    , col
        else
            m 'p',
                'All product categories assigned! '
                icon 'fa-check'

        m CollectionTable, do
            collection: @excise_categories
            options:
                sticky_header: true
                with_buttons: true
                unique_name: 'excise_categories'
            view_details: (record) ~>
                [
                    if record.product_categories.length > 0 then
                        m 'span' 'Product categories:'
                    else
                        m 'span' 'No product categories are linked to this excise category.'

                    m 'ul',
                        for product_category in record.product_categories
                            m 'li' capitalize product_category.name
                ]

            columns:
                do
                    name: 'Category Name'
                    width: 4
                    header: 'Category name'
                    field: 'name'
                do
                    name: 'Country'
                    width: 2
                    header: 'Country'
                    field: 'country_code'
                    function: (record) ~>
                        m 'span' countries[record.country_code]
                do
                    name: 'Duty rate'
                    width: 2
                    header: 'Duty rate'
                    field: 'duty_rate'
                    function: (record) ->
                        # Do not use Amount, since the duty_rate is a fixed number in the currency of the country
                        currency = 'EUR'
                        if record.country_code == 'GB'
                            currency = 'GBP'
                        m 'span',
                            format_money(record.duty_rate, currency)
                do
                    name: 'Duty application'
                    width: 2
                    header: 'Duty application'
                    field: 'duty_application'
                do
                    name: 'From Alc %'
                    width: 1
                    header: 'From Alc %'
                    field: 'from_alcohol_percentage'
                    function: (record) ~>
                        m(Percent, do
                            value: +record.from_alcohol_percentage / 100
                        )
                do
                    name : 'To Alc %'
                    width: 1
                    header: 'To Alc %'
                    field: 'to_alcohol_percentage'
                    function: (record) ~>
                        m(Percent, do
                            value: +record.to_alcohol_percentage / 100
                        )
                do
                    name: 'Actions'
                    width: 1
                    header: ''
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            icon-button 'pencil', do
                                class: 'btn-default'
                                onclick: ~> m.route.set "/data/excise-categories/#{record.artkey}/edit"
