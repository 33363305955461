import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {accountIconBySlug} from 'accounts.ls'
import {config} from '@bitstillery/common/app'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface StockAge {
    artkey: string
    entry_date: string
    reference: string
    purchase_order_reference: string
    purchase_order_artkey: number
    product_name: string
    product_artkey: number
    lot: string
    volume: number
    alcohol_percentage: number
    gift_box_type: string
    customs_status: string
    bottles_per_case: number
}

export interface StockAgeReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class StockAgeReport extends MithrilTsxComponent<StockAgeReportAttrs> implements RequeryListener {
    reporting_fetcher = new PagedCollectionFetcher<StockAge>('reporting.stock_age_report.get_all', 'entry_date')

    constructor(vnode: m.Vnode<StockAgeReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<StockAgeReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <CollectionTable<StockAge, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Product name'}
                    sort_name={'product_name'}
                    td_class_name={'col-sm-3'}
                    data_field={(row: StockAge) => (
                        <span>
                            <a
                                target="_blank"
                                href={`#!/pricelist/offer-item-list?q=${row.product_name} ${row.volume} ${row.alcohol_percentage}`}
                            >
                                {row.product_name}
                            </a>
                            &nbsp;
                            <span className={'pull-right'}>
                                <a
                                    target="_blank"
                                    href={`${config.portal_base_url}/#!/offers?search=${row.product_name}&volume=${row.volume}&alcohol=${row.alcohol_percentage}`}
                                >
                                    {accountIconBySlug('msp', 'View on portal MSP')}
                                </a>
                                &nbsp;
                                <a
                                    target="_blank"
                                    href={`${config.a2bc_portal_base_url}/#!/offers?search=${row.product_name}&volume=${row.volume}&alcohol=${row.alcohol_percentage}`}
                                >
                                    {accountIconBySlug('etr', 'View on portal ETR')}
                                </a>
                            </span>
                        </span>
                    )}
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Specs'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) =>
                        `${row.bottles_per_case} ${row.volume} / ${row.alcohol_percentage}% ${row.gift_box_type}`
                    }
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Customs'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) => row.customs_status}
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Item'}
                    sort_name={'artkey'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) => (
                        <a target="_blank" href={`#!/stock/manage/${row.reference}`}>
                            {row.reference}
                        </a>
                    )}
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Purchase order'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) => (
                        <a target="_blank" href={`#!/purchase-orders/manage/${row.purchase_order_artkey}`}>
                            {row.purchase_order_reference}
                        </a>
                    )}
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Entry date'}
                    sort_name={'entry_date'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) => format_iso_to_date(row.entry_date)}
                />
                <CollectionTableColumn<StockAge>
                    header_title={() => 'Lot'}
                    sort_name={'lot'}
                    td_class_name={'col-sm-1'}
                    data_field={(row: StockAge) => (
                        <a
                            target="_blank"
                            href={`#!/stock/manage/?q=${row.lot.replaceAll(' ', '+')}&available_only=false`}
                        >
                            {row.lot}
                        </a>
                    )}
                />
            </CollectionTable>
        )
    }
}
