m = require "mithril"
{odd, Obj, head, sum} = require 'prelude-ls'
api = require '../api.ls'
{a}: utils = require '../utils.ls'
{Button, Country} = require '@bitstillery/common/components'
inputs = require '../components/inputs.ls'
{sales_order_dm, SalesOrderStatus} = require '@/sales_orders/models.ls'
{SalesOrderDetails} = require './components/sales_order_details.ls'
fixed-table-header = require '@/components/table/fixed-header.ls'
{DestinationsDropDown} = require '@/components/destinations'
{DestinationsDropDownData} = require '@/factserver_api/destinations_api'
app = require('@/app')

module.exports = class InStock
    ->
        @sales_order = window.prop null
        @warehouse_artkey = window.prop ''
        sales_order_artkey = +m.route.param 'artkey'
        app.$s.page.title = 'Bring sales order credit items in stock'
        if sales_order_artkey
            @load_sales_order +sales_order_artkey

    load_sales_order: (sales_order_artkey) ->
        data = do
            sales_order_artkey: sales_order_artkey
        api.call 'sales.core.get_fast_sales_order_with_items', data, @set_sales_order

    set_sales_order: (resp) ~>
        if resp.success
            @sales_order sales_order_dm.create_sales_order resp.result
            if @sales_order!sales_order_status! not in [SalesOrderStatus.INVOICED]
                app.notifier.notify 'This order does not have the status invoiced. Only invoiced orders can be brought in stock.', 'danger'
                m.route.set '/sales-orders/manage/' + @sales_order!artkey!
            else if @sales_order!credit_items_in_stock!
                app.notifier.notify 'This order was already brought in stock.', 'info'
                m.route.set '/sales-orders/manage/' + @sales_order!artkey!

    submit: (event) ~>
        event.preventDefault!

        if not @warehouse_artkey!
            app.notifier.notify 'Please select a destination warehouse.', 'info'
            return

        sales_order_credit_item_to_lot = {}
        for sales_order_credit_item in @sales_order!sales_order_credit_items!
            lot = sales_order_credit_item.item!lot!
            if lot then
                sales_order_credit_item_to_lot[+sales_order_credit_item.artkey!] = sales_order_credit_item.item!lot!
            else
                app.notifier.notify 'Please fill out all lot numbers.', 'info'
                return

        data = do
            sales_order_artkey: +@sales_order!artkey!
            sales_order_credit_item_to_lot: sales_order_credit_item_to_lot
            warehouse_artkey: @warehouse_artkey!
        api.call 'sales.credit.in_stock_sales_order_credit_items', data, @handle_save_response

    handle_save_response: (resp) ~>
        if not resp.success
            msg = if resp.message then resp.message else "Failed bringing the sales order credit items in stock. Please try again later. If the problem persists, please contact us."
            app.notifier.notify msg, 'danger'
        else
            app.notifier.notify "The sales order credit items were brought in stock successfully.", 'success'
            m.route.set '/sales-orders/manage/' + @sales_order!artkey!

    view: ->
        m '.c-soci-in-stock view',
            m '.btn-toolbar',
                m Button, {
                    class: 'btn-back',
                    onclick: ~> m.route.set '/sales-orders/manage/' + @sales_order!artkey!,
                    icon: 'back',
                    variant: 'toggle'
                }

            if @sales_order!
                a do
                    m SalesOrderDetails, {sales_order: @sales_order}
                    m 'form.form-horizontal', {role: 'form', onsubmit: @submit},
                        m '.field',
                            style: "display: flex",
                            m 'label.control-label col-sm-2', 'Destination warehouse'
                            m DestinationsDropDown,
                                selected_destination_artkey: @warehouse_artkey!
                                onchange: (artkey) ~> @warehouse_artkey artkey
                                get_all_for_drop_down_response$: DestinationsDropDownData.destinations()

                    fixed-table-header.with-buttons m 'table.table.search-table' {style: 'width: 100%'},
                        m 'thead.thead-default',
                            m 'tr',
                                m 'th' 'Product'
                                m 'th' 'Btl / cs'
                                m 'th' 'Specs'
                                m 'th' 'GB'
                                m 'th' 'Tax label'
                                m 'th' 'Country of origin'
                                m 'th' 'Cases'
                                m 'th' m '.col-xs-12' 'Lot'
                        m 'tbody',
                            @sales_order!sales_order_credit_items!map (sales_order_credit_item, index) ->
                                #if not sales_order_credit_item.is_in_stock!
                                m SalesOrderCreditItem,
                                    {sales_order_credit_item: sales_order_credit_item, index: index}

            m 'button.btn.btn-primary' {type: 'submit', onclick: @submit} (m 'span.glyphicon.glyphicon-play-home'), ' Bring credit items in stock'


class SalesOrderCreditItem
    (vnode) ->
        @sales_order_credit_item = window.prop vnode.attrs.sales_order_credit_item
        @index = vnode.attrs.index

    view: ->
        m 'tr' {class: if odd @index then 'odd' else 'even'}, a do
            m 'td', @sales_order_credit_item!sales_order_item!item!bottle!product!name!
            m 'td', @sales_order_credit_item!sales_order_item!item!number_of_bottles_per_case!
            m 'td', @sales_order_credit_item!sales_order_item!item!bottle!to_specs!
            m 'td', @sales_order_credit_item!sales_order_item!item!gift_box_type!
            m 'td', @sales_order_credit_item!sales_order_item!item!tax_label!
            m 'td', m Country,
                country_code: @sales_order_credit_item!sales_order_item!item!country_of_origin!
                type: 'flag_with_country'
            m 'td', @sales_order_credit_item!number_of_cases!
            m 'td', inputs.text(@sales_order_credit_item!item!lot)
