m = require 'mithril'
{find} = require 'prelude-ls'
api = require 'api.ls'
{AccountSlug, current_account_slug} = require '@bitstillery/common/account/account'
app = require('@/app')

export class Account
    (json) ->
        @artkey = window.prop null
        @name = window.prop ''
        @informal_name = window.prop ''
        @slug = window.prop ''

        for prop of json
            @[prop] = window.prop json[prop]


export class AccountsDataModel
    ->
        @accounts = window.prop []
        api.call-and-then 'accounts.get_all', {}, do
            success: (resp) ~>
                @accounts [new Account account_json for account_json in resp.result]
                app.$m.common.observable.broadcast 'accounts_loaded', @accounts

    get_account: (artkey) ~>
        @accounts! |> find (.artkey! == artkey)

    get_account_by_slug: (slug) ~>
        @accounts! |> find (.slug! == slug)

    # Return the currently active account.
    current_account: ~>
        return @get_account_by_slug current_account_slug!

