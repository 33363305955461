import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'
import {Validator} from '@bitstillery/common/lib/validation'
import {object_to_query_string} from '@bitstillery/common/lib/utils'
import {Country} from '@bitstillery/common/components/ui/country/country.tsx'
import {Button, Icon} from '@bitstillery/common/components'
import {api} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {DropDownMultiSelect, DropDownOption, DropDownWithSearch, empty_option} from './html_components'
import {SearchBar, SearchBarControl} from './collection/search_bar'

import {GetRelationsResponse} from '@/factserver_api/fact2server_api'
import {GetAllForDropDownResponse} from '@/factserver_api/relation_api'
import {Link} from '@/components/discover.tsx'

interface RelationDropDownAttrs extends Validator {
    selected_relation_artkey: string
    onchange: (relation_artkey: string) => unknown
    disabled?: boolean
    placeholder?: string
    required?: boolean
    /**
     * The data source for the drop down options. Fill eg with RelationDropDownData.relations()
     **/
    get_all_for_drop_down_response$: Observable<GetAllForDropDownResponse[]>
}

interface MaybeObservableAttrs {
    observed: Observable<unknown>
}

/**
 * A generic class that displays a spinner if the observable is not completed or if the observable
 * is completed the children if completed.
 */
export class MaybeObservable extends MithrilTsxComponent<MaybeObservableAttrs> {
    is_completed = false

    constructor(vnode: m.Vnode<MaybeObservableAttrs>) {
        super()
        vnode.attrs.observed.subscribe({
            complete: () => {
                this.is_completed = true
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<MaybeObservableAttrs>): m.Children {
        return (
            <span>
                {this.is_completed && vnode.children}
                {!this.is_completed && <Spinner className="table-spinner" />}
            </span>
        )
    }
}

export interface RelationsSearchAttrs extends Validator {
    required?: boolean
    disabled?: boolean
    only_suppliers?: boolean
    only_buyers?: boolean
    placeholder?: string
    selected_relation_name?: string
    selected_relations: GetRelationsResponse[]
    is_single_select?: boolean
    search_bar_controller: (controller: SearchBarControl) => any
    keep_results_in_search_bar?: boolean
}

/**
 * Relation search field.
 */
export class RelationsSearch extends MithrilTsxComponent<RelationsSearchAttrs> {

    found_relations: GetRelationsResponse[] = []
    relations_search_bar_controller: SearchBarControl | null = null

    search_for_relation(vnode: m.Vnode<RelationsSearchAttrs>, search_text: string) {
        const found_relation = this.found_relations.find((relation) => relation.name === search_text)
        if (vnode.attrs.is_single_select) {
            vnode.attrs.selected_relations.splice(0, vnode.attrs.selected_relations.length)
        }
        if (found_relation) {
            // do not add the found relation twice.
            if (!vnode.attrs.selected_relations.find((relation) => relation.name === found_relation.name)) {
                vnode.attrs.selected_relations.push(found_relation)
            }

            // keep sorted on name
            vnode.attrs.selected_relations.sort((a, b) => a.name.localeCompare(b.name))
            if (!vnode.attrs.keep_results_in_search_bar) {
                this.relations_search_bar_controller?.clear_search_text()
            }
        }
    }

    view(vnode: m.Vnode<RelationsSearchAttrs>): m.Children {
        return (
            <SearchBar
                label={vnode.attrs.label}
                placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : 'Search on relation name, contact person'}
                on_submit={(search_text: string) => this.search_for_relation(vnode, search_text)}
                default_search_text={vnode.attrs.selected_relation_name}
                required={vnode.attrs.required}
                disabled={vnode.attrs.disabled}
                search_bar_controller={(controller: SearchBarControl) => {
                    this.relations_search_bar_controller = controller
                    vnode.attrs.search_bar_controller(controller)
                }}
                on_get_suggestions={async(filter_text: string) => {
                    const url_params = object_to_query_string({
                        search_terms: filter_text,
                        only_suppliers: !!vnode.attrs.only_suppliers,
                        only_buyers: !!vnode.attrs.only_buyers,
                    })
                    const {result} = await api.get<GetRelationsResponse[]>(`discover/relations?${url_params}`)
                    this.found_relations = result
                    return result.map((relation) => relation.name)
                }}
            >{vnode.children}</SearchBar>
        )
    }
}

export interface EditableRelationListAttrs {
    selected_relations: GetRelationsResponse[]
    disabled?: boolean
}

export class EditableRelationList extends MithrilTsxComponent<EditableRelationListAttrs> {

    remove_item(vnode: m.Vnode<EditableRelationListAttrs>, relation: GetRelationsResponse) {
        vnode.attrs.selected_relations.splice(vnode.attrs.selected_relations.findIndex((rel) => rel.artkey === relation.artkey), 1)
    }

    view(vnode: m.Vnode<EditableRelationListAttrs>): m.Children {
        return <div className={'c-editable-relations-list'}>
            {vnode.attrs.selected_relations.map((relation) => (<div className={'relation-details'}>
                {relation.is_verified && <Icon name='checkFilled' type='success' tip='Verified' size='s'/>}
                <Link href={`/crm/relations/${relation.artkey}/edit`}>
                    {` ${relation.name} - ${relation.city}`}
                </Link>
                <Country country_code={relation.country_code.toLowerCase()}/>
                <Button
                    icon="close"
                    onclick={() => this.remove_item(vnode, relation)}
                    size="xs"
                    disabled={vnode.attrs.disabled}
                    type="info"
                    variant="toggle"
                />

            </div>),
            )}
        </div>
    }
}

/**
 * Relation dropdown with search.
 *
 * Usage:
 * <RelationDropDown
 *       selected_relation_artkey={this.selected_relation_artkey}
 *       get_all_for_drop_down_response$={RelationDropDownData.relations()}
 *       onchange={(relation_artkey) => this.on_relation_selected(relation_artkey)}
 * />
 * @deprecated Use RelationSearch
 **/
export class RelationDropDown extends MithrilTsxComponent<RelationDropDownAttrs> {
    relations: GetAllForDropDownResponse[] = []

    oncreate(vnode: m.Vnode<RelationDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((relations) => (this.relations = relations))
    }

    view(vnode: m.Vnode<RelationDropDownAttrs>): m.Children {
        return (
            <DropDownWithSearch
                label={vnode.attrs.label}
                onchange={vnode.attrs.onchange}
                empty_option={empty_option('Select a relation...')}
                disabled={vnode.attrs.disabled}
                selected={vnode.attrs.selected_relation_artkey}
                required={vnode.attrs.required}
            >
                {this.relations?.map((relation) => (
                    <DropDownOption value={`${relation.artkey}`}>{relation.name}</DropDownOption>
                ))}
            </DropDownWithSearch>
        )
    }
}

interface RelationMultiSelectDropDownAttrs {
    selected_relation_artkeys: string[]
    onchange: (relation_artkey: string[]) => unknown
    disabled?: boolean
    required?: boolean
    /**
     * The data source for the drop down options. Fill eg with RelationDropDownData.relations()
     **/
    get_all_for_drop_down_response$: Observable<GetAllForDropDownResponse[]>
}

/**
 * Multiselect drop down with search.
 * Usage (showing example for only relations that are supplier):
 *    ...
 *    selected_supplier_artkeys: number[] = []
 *    ...
 *
 *      <RelationMultiSelectDropDown
 *        selected_relation_artkeys={this.selected_supplier_artkeys.map(
 *            (artkey: number) => `${artkey}`
 *        )}
 *        get_all_for_drop_down_response$={RelationDropDownData.relations().pipe(
 *            mergeAll(),
 *            filter((relation) => relation.is_supplier),
 *            toArray()
 *        )}
 *        onchange={(artkeys: string[]) =>
 *            (this.selected_supplier_artkeys = artkeys.map((artkey: string) => +artkey))
 *        }
 *    />
 *
 * @deprecated Use the search relation bar: RelationsSearch.
 * */
export class RelationMultiSelectDropDown extends MithrilTsxComponent<RelationMultiSelectDropDownAttrs> {
    relations: GetAllForDropDownResponse[] = []

    oncreate(vnode: m.Vnode<RelationDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((relations) => (this.relations = relations))
    }

    view(vnode: m.Vnode<RelationMultiSelectDropDownAttrs>): m.Children {
        return (
            <DropDownMultiSelect
                onchange={vnode.attrs.onchange}
                empty_option={empty_option('Select a relation...')}
                disabled={vnode.attrs.disabled}
                help={vnode.attrs.help}
                label={vnode.attrs.label}
                required={vnode.attrs.required}
                selected={vnode.attrs.selected_relation_artkeys}
            >
                {this.relations?.map((relation) => {
                    return (
                        <DropDownOption key={`${relation.artkey}`} value={`${relation.artkey}`}>
                            {relation.name}
                        </DropDownOption>
                    )
                })}
            </DropDownMultiSelect>
        )
    }
}
