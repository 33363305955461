m = require 'mithril'
{join} = require 'prelude-ls'
utils = require 'utils.ls'

{Amount} = require '@bitstillery/common/components'
{icon} = require '@/components/icon.ls'
panel = require '@/components/panel.ls'
{Popover} = require '@/components/popover/popover.ls'
table = require '@/components/table.ls'
{Spinner} = require '@bitstillery/common/components'

export class SalesOrderItems
    view: (vnode) -> panel(vnode.attrs.title,
        if vnode.attrs.loading!
            m Spinner
        else if vnode.attrs.data!length > 0
            table.table(vnode.attrs.data, [
                do
                    width: 3
                    field: 'sales_order_reference'
                    name: '#'
                    function: ((sales_order_item) ->
                        m(
                            m.route.Link, {href: "/sales-orders/manage/#{sales_order_item.sales_order_artkey}"},
                            sales_order_item.sales_order_reference
                        )
                    )
                do
                    width: 5
                    field: 'sales_order_created_on'
                    name: 'Date'
                    function: ((sales_order_item) ->
                        utils.format-date(sales_order_item.sales_order_created_on)
                    )
                do
                    width: 9
                    field: 'supplier_name'
                    name: 'Relation'
                    ellipsis: true
                do
                    width: 8
                    field: 'product_name'
                    name: 'Product'
                do
                    width: 6
                    field: 'case_number_of_bottles'
                    name: 'Btl / cs'
                    classes: 'number'
                do
                    width: 4
                    field: 'bottle_volume'
                    name: 'Size'
                    classes: 'number'
                    function: ((sales_order_item) -> (+sales_order_item.bottle_volume).toFixed(1) + ' cl')
                do
                    width: 4
                    field: 'alcohol_percentage'
                    name: 'Alc %'
                    classes: 'number'
                    function: ((sales_order_item) -> (+sales_order_item.bottle_alcohol_percentage).toFixed(1) + '%')
                do
                    width: 3
                    field: 'bottle_refill_status'
                    name: 'Ref'
                do
                    width: 6
                    field: 'case_gift_box_type'
                    name: 'GB'
                do
                    width: 3
                    field: 'case_customs_status'
                    name: 'Cus.'
                do
                    width: 3
                    field: 'number_of_cases'
                    name: 'Cases'
                    classes: 'number'
                do
                    width: 6
                    field: 'price_per_case'
                    name: 'Price / cs'
                    classes: 'price'
                    function: ((sales_order_item) ->
                        m(Amount, do
                            amount: sales_order_item.price_per_case_excl_excise
                            currency: sales_order_item.sales_order_was_sold_in
                            rate: sales_order_item.sales_order_sold_against_rate
                        )
                    )
                do
                    width: 7
                    field: 'no_field'
                    name: 'Total price'
                    classes: 'price'
                    function: ((sales_order_item) ->
                        m(Amount, do
                            amount: sales_order_item.price_per_case_excl_excise * sales_order_item.number_of_cases
                            currency: sales_order_item.sales_order_was_sold_in
                            rate: sales_order_item.sales_order_sold_against_rate
                        )
                    )
                do
                    width: 7
                    field: 'sales_order_incoterm'
                    name: 'Incoterm'
                    ellipsis: true
                do
                    width: 7
                    field: 'destination_name'
                    name: 'Destination'
                    ellipsis: true
                do
                    width: 5
                    field: 'sales_order_status'
                    name: 'Status'
                do
                    width: 8
                    field: 'was_handled_by_name'
                    name: 'Handled by'
            ], do
                search_table_style: false
                sticky_header: false
            )
        else
            'No transactions'
        , '',
        do
            download_content: m 'a.fa.fa-file-excel'
            download_callback: vnode.attrs.download_callback
    )
