import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from './api'

import {GetLoenderslootMessagesResponse} from '@/email/communication_api'

export class LoenderslootApi {
    api = new Api()

    send_sales_order_to_loendersloot(
        sales_order_artkey: number,
        estimated_loading_date: string,
        warehouse_instruction: string | null,
        freight_instruction: string | null,
        frontoffice_instruction: string | null,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.send_sales_order_xml_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
            estimated_loading_date: estimated_loading_date,
            warehouse_instruction: warehouse_instruction,
            freight_instruction: freight_instruction,
            frontoffice_instruction: frontoffice_instruction,
        })
    }

    send_outbound_release_to_loendersloot(
        sales_order_artkey: number,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.send_outbound_release_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
        })
    }

    reschedule_stockrecon(): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request('discover/loendersloot-stock/recons/reschedule', {})
    }

    send_pre_advice_to_loendersloot(
        sales_order_artkey: number,
        estimated_loading_date: string | null,
        warehouse_instruction: string | null,
        freight_instruction: string | null,
        frontoffice_instruction: string | null,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.start_pre_advice_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
            estimated_loading_date: estimated_loading_date,
            warehouse_instruction: warehouse_instruction,
            freight_instruction: freight_instruction,
            frontoffice_instruction: frontoffice_instruction,
        })
    }

    cancel_pre_advice(sales_order_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
            action: 'cancel',
        })
    }

    confirm_pre_advice_with_outbound(sales_order_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
            action: 'outbound',
        })
    }

    confirm_pre_advice_with_release(sales_order_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
            sales_order_artkey: sales_order_artkey,
            action: 'release',
        })
    }

    get_loendersloot_message(loendersloot_message_artkey: number): Observable<GetLoenderslootMessagesResponse> {
        return this.api.get(`discover/loendersloot-messages/${loendersloot_message_artkey}`)
    }
}
