/* eslint-disable @typescript-eslint/no-unsafe-call */
import m, {ChildArray} from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {countries, european_union_countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {randomString} from 'utils.ls'
import {Observable} from 'rxjs'
import {displayable_float, format_percentage, random_string} from '@bitstillery/common/ts_utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Validator} from '@bitstillery/common/lib/validation'
import {Icon} from '@bitstillery/common/components'

import {MaybeObservable} from './relation'
import {SearchBar} from './collection/search_bar'
import {CancelButton, SaveButton} from './buttons'

import {$s} from '@/app'
import {AllMarginThresholds, GetMarginThresholdResponse} from '@/factserver_api/margins_api'
import {CommentTemplateResponse} from '@/factserver_api/comment_templates'

export interface DropDownOptionAttrs {
    value: string
    __selected?: boolean /* For internal drop-down usage. Used for selecting the selected option. */
}

interface ElementWithMultiselect extends JQuery<HTMLElement> {
    multiselect: any
}

interface ValidatableElement {
    validation?: Validator
}

interface LabeledElement {
    label?: string
    help?: string
}

interface StylableElement {
    classNames?: string[]
}

/**
 * Indicates that a context can be set on the element. The context may be displayed as a Tippy or as an additional
 * help block.
 */
interface ContextElement {
    context?: string
}

export interface DropDownWithSelectAttrs extends ValidatableElement, LabeledElement, StylableElement, ContextElement {
    /**
     * Will fire when an option is selected, the value sent is the value of the selected option.
     * @param value: the value of the selected option.
     */
    onchange: (value: string) => unknown
    empty_option?: JSX.Element
    selected: string /* The value of the option that has to be in selected state. */
    required?: boolean
    disabled?: boolean
    id?: string
}

type EnumType = {
    [s in string | number]: string
}

/**
 * Creates DropDownMenuOption objects for an enum value. The title and the key is the enum value.
 *
 * Usage:
 * <DropDownWithSelect
 *     selected={this.selected_period.valueOf()}
 *     onchange={(value) => this.on_change_selected_period(Object.values(Period).find(period => period === value) || Period.MONTH)}
 *
 * >{drop_down_menus_for_enum(Period)}</DropDownWithSelect>
 *
 * for an enum like:
 * enum Period {
 *     MONTH = "Monthly",
 *     WEEK = "Weekly",
 * }
 */
export function drop_down_menus_for_enum(enum_object: EnumType, key_as_value = false): JSX.Element[] {
    return (Object.keys(enum_object) as Array<keyof typeof enum_object>).map((enum_key) => {
        const enum_value = enum_object[enum_key]
        const key = key_as_value ? enum_key : enum_value
        return <DropDownOption value={key}>{enum_value}</DropDownOption>
    })
}

/**
 * Generates drop down option for usage in the empty option tag.
 * @param title The title to display (for instance "Select..." or "Pick an option..."
 */
export function empty_option(title: JSX.Element | string): JSX.Element {
    return <DropDownOption value={''}>{title}</DropDownOption>
}

/**
 * Inner 'options' for a drop down select. The title (representation) of the option is taken
 * from the vnode.children. The value is in vnode.attrs.value attribute.
 *
 * Usage:
 *      <DropDownOption value="13">Option number 13</DropDownOption>
 */
export class DropDownOption extends MithrilTsxComponent<DropDownOptionAttrs> {
    view(vnode: m.Vnode<DropDownOptionAttrs>): m.Children {
        return (
            <option value={vnode.attrs.value} selected={vnode.attrs.__selected}>
                {vnode.children}
            </option>
        )
    }
}

export class DropDownWithSelect extends MithrilTsxComponent<DropDownWithSelectAttrs> {

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false

        const select_dom = (
            <select
                required={vnode.attrs.required}
                class={classes('no-click', vnode.attrs.classNames)}
                disabled={vnode.attrs.disabled}
                id={vnode.attrs.id}
                onchange={(event: Event) => {
                    if (validation) {
                        validation.dirty = true
                    }
                    vnode.attrs.onchange((event.target as HTMLSelectElement).value)
                }}
            >
                {vnode.attrs.empty_option}

                {(vnode.children as m.ChildArray)
                    ?.filter((child) => child)
                    .map((opt: m.Child | m.ChildArray) => {
                        // @ts-ignore
                        const attrs = opt?.attrs as DropDownOptionAttrs
                        if (vnode.attrs.selected === attrs.value) {
                            attrs.__selected = true
                        }
                        return opt
                    })}
            </select>
        )

        if (!vnode.attrs.label) {
            // The default returned html structure is legacy; replace each legacy
            // DropDownWithSelect by adding a label attribute. Afterwards, remove
            // this legacy codepath.
            return select_dom
        }

        return (
            <div className={classes('c-dropdown-select', 'field', vnode.attrs.classNames, {
                disabled: vnode.attrs.disabled,
                valid: !validation || !invalid,
                invalid: invalid && validation?.dirty,
            })}>
                <label>
                    {vnode.attrs.label}
                    {validation && (
                        <span className="validation">
                            {validation.label}
                        </span>
                    )}

                    {vnode.attrs.context && <Icon name="question" tip={vnode.attrs.context} size="s" type="info" />}
                </label>
                {select_dom}
                {(() => {
                    if (invalid && validation?.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div className="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )

    }
}

/** Focus on the text-input search box. */
function bootstrap_multiselect_ondropdown(jq_event: JQuery.SelectEvent): void {
    $(jq_event.target).parent().find('.multiselect-search').trigger('focus')
}

function bootstrap_set_disabled(component: ElementWithMultiselect, disabled?: boolean) {
    if (disabled) {
        component.multiselect('disable')
    } else {
        component.multiselect('enable')
    }
}

function bootstrap_set_selected(component: ElementWithMultiselect, selected: string | string[]): void {
    component.multiselect('select', selected, false)
}

/** Set up the bootstrap multiselect component. */
function bootstrap_multiselect_oncreate(
    dom: HTMLSelectElement,
    onchange_handler: (event: Event) => unknown,
    selected: string | string[],
    disabled: boolean | undefined,
): void {
    const jquery_dom = $(dom as HTMLElement) as ElementWithMultiselect
    jquery_dom.multiselect({
        enableCaseInsensitiveFiltering: true,
        includeFilterClearBtn: false,
        nonSelectedText: 'Select an option...',
        onDropdownShown: (event: JQuery.SelectEvent) => bootstrap_multiselect_ondropdown(event),
    })
    dom.onchange = onchange_handler

    bootstrap_set_disabled(jquery_dom, disabled)
    bootstrap_set_selected(jquery_dom, selected)
    m.redraw()
}

function bootstrap_multiselect_onremove(dom_vnode: m.VnodeDOM<unknown>): void {
    const element_with_multi_select = $(dom_vnode.dom) as ElementWithMultiselect
    element_with_multi_select.multiselect('destroy')
}

/**
 * Renders a bootstrap-multiselect component. This specific one does not multiselect but shows:
 * - Search box.
 * - Maximum height.
 * - Validation (see the note).
 *
 * @See https://github.com/davidstutz/bootstrap-multiselect
 *
 * NOTE:
 * In order to make validation work a second component is rendered. This is an input component with the contents of the
 * DropDown value (aka selected). The input component is rendered below the drop down. If a validation error occurs it
 * (almost) just looks like the drop down caused a validation.
 */
export class DropDownWithSearch extends MithrilTsxComponent<DropDownWithSelectAttrs> {
    selected: string
    disabled?: boolean
    onchange_handler: (key: string) => void
    children_length = 0

    constructor(vnode: m.Vnode<DropDownWithSelectAttrs>) {
        super()
        this.selected = vnode.attrs.selected
        this.onchange_handler = vnode.attrs.onchange
    }

    onchange(event: Event, vnode): void {
        if (vnode.attrs.validation) {
            vnode.attrs.validation.dirty = true
        }
        const selected = (event.target as HTMLSelectElement)?.value
        this.selected = selected
        this.onchange_handler(selected)
        m.redraw()
    }

    view(vnode: m.Vnode<DropDownWithSelectAttrs>): m.Children {
        const vnode_children_length = (vnode.children as m.ChildArray)?.length || 0
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false

        const select_dom = (
            <span className={'c-field-dropdown-search multiselect no-click'}>
                <select
                    disabled={vnode.attrs.disabled}
                    oncreate={(dom_vnode) => {
                        this._select_dom = $(dom_vnode.dom)
                        this.disabled = vnode.attrs.disabled
                        this.selected = vnode.attrs.selected
                        this.children_length = vnode_children_length

                        bootstrap_multiselect_oncreate(
                            dom_vnode.dom as HTMLSelectElement,
                            (event) => this.onchange(event, vnode),
                            this.selected,
                            this.disabled,
                        )
                    }}
                    onremove={(vnode) => bootstrap_multiselect_onremove(vnode)}
                    onupdate={(dom_vnode) => {
                        this._$select = $(dom_vnode.dom)
                        const children = vnode.children as ChildArray
                        if (this.disabled !== vnode.attrs.disabled) {
                            this.disabled = vnode.attrs.disabled
                            bootstrap_set_disabled(this._$select, this.disabled)
                        }
                        if (this.children_length !== children.length) {
                            this.children_length = vnode_children_length
                            this._$select.multiselect('rebuild')
                            bootstrap_set_disabled(this._$select, this.disabled)
                            bootstrap_set_selected(this._$select, this.selected)
                        }
                        if (this.selected !== vnode.attrs.selected) {
                            this.selected = vnode.attrs.selected
                            this._$select.multiselect('deselectAll', false)
                            bootstrap_set_selected(this._$select, this.selected)
                            this._$select.multiselect('updateButtonText')
                        }
                    }}
                >
                    {vnode.attrs.empty_option}
                    {(vnode.children as m.ChildArray)
                        ?.filter((child) => child !== null)
                        .map((opt: m.Child | m.ChildArray) => {
                            // @ts-ignore
                            const attrs = opt?.attrs as DropDownOptionAttrs
                            if (vnode.attrs.selected === attrs.value) {
                                attrs.__selected = true
                            }
                            return opt
                        })}
                </select>
                <button
                    className={classes('btn-reset btn btn-default', {
                        disabled: this.selected === '',
                    })}
                    onclick={(e) => {
                        e.preventDefault()
                        this.selected = ''
                        this.onchange_handler(this.selected)
                        bootstrap_set_selected(this._$select, this.selected)
                        this._$select.multiselect('rebuild')
                    }}
                >
                    <i className="glyphicon glyphicon-remove-sign"/>
                </button>
                {/* See Note on the DropDownSelect class. */}
                <input
                    className={'form-control sneaky-validator'}
                    required={vnode.attrs.required}
                    name={'the-validation-nobody-wants-to-see'}
                    value={vnode.attrs.selected}
                />
            </span>
        )

        if (!vnode.attrs.label) {
            // The default returned html structure is legacy; replace each legacy
            // DropDownWithSelect by adding a label attribute. Afterwards, remove
            // this legacy codepath.
            return select_dom
        }

        return (
            <div className={classes('c-dropdown-search', 'field', {
                disabled: vnode.attrs.disabled,
                valid: !validation || !invalid,
                invalid: invalid && validation?.dirty,
            })}>
                <label>
                    {vnode.attrs.label}
                    {validation && <span className="validation">{validation.label}</span>}
                </label>
                {select_dom}
                {(() => {
                    if (invalid && validation?.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div className="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}

interface CheckBoxAttrs {
    id?: string
    checked: boolean
    disabled?: boolean
    onchange: (event: Event) => unknown
    icon?: JSX.Element
}

export class CheckBox extends MithrilTsxComponent<CheckBoxAttrs> {
    view(vnode: m.Vnode<CheckBoxAttrs>): m.Children {
        const check_box_id = vnode.attrs.id || random_string(12, false)
        return (
            <span className={classes('c-field-checkbox field', vnode.attrs.className, {
                disabled: vnode.attrs.disabled,
            })}>
                <div className="control">
                    <input
                        id={check_box_id}
                        type="checkbox"
                        disabled={vnode.attrs.disabled || false}
                        onchange={vnode.attrs.onchange}
                        checked={vnode.attrs.checked}
                    />
                    {(() => {
                        if (vnode.children.length > 0) {
                            return <label for={check_box_id}>{vnode.children}</label>
                        }
                        if (vnode.attrs.label) {
                            return <label for={check_box_id}>
                                {vnode.attrs.label}{vnode.attrs.icon && vnode.attrs.icon}
                                {vnode.attrs.context && <Icon name="question" tip={vnode.attrs.context} size="s" type="info" />}
                            </label>
                        }
                    })()}
                </div>
                {vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </span>
        )
    }
}

/** @deprecated*/
export class DropDownMultiSelect {
    selected: string[]
    disabled?: boolean
    onchange_handler: (keys: string[]) => void
    children_length = 0

    constructor(vnode) {
        this.selected = vnode.attrs.selected
        this.onchange_handler = vnode.attrs.onchange
    }

    onchange(event: Event, dom_vnode: m.VnodeDOM<unknown, unknown>): void {
        // Make sure the placeholder is not selectable (filter out the empty artkey).
        const element_with_multiselect = $(dom_vnode.dom) as ElementWithMultiselect
        element_with_multiselect.multiselect('deselect', '')
        // @ts-ignore
        this.selected = ($(event.target).val() as string[]).filter((item) => item !== '')
        this.onchange_handler(this.selected)
        m.redraw()
    }

    view(vnode) {
        const vnode_children_length = (vnode.children as m.ChildArray)?.length || 0
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false

        return (
            <div className={classes('c-field-dropdown-multi-search field', {
                disabled: vnode.attrs.disabled,
                valid: !validation || !invalid,
                invalid: invalid && validation.dirty,
            })}>
                <label>
                    {vnode.attrs.label}
                    {validation && (
                        <span className="validation">
                            {validation.label}
                        </span>
                    )}
                </label>
                <span className={classes('c-field-dropdown-search-multi multiselect no-click', {
                    disabled: vnode.attrs.disabled,
                })}>
                    <select
                        multiple={true}
                        disabled={vnode.attrs.disabled}
                        oncreate={(dom_vnode) => {
                            this._$select = $(dom_vnode.dom)
                            this.disabled = vnode.attrs.disabled
                            this.selected = vnode.attrs.selected
                            this.children_length = vnode_children_length

                            bootstrap_multiselect_oncreate(
                                dom_vnode.dom as HTMLSelectElement,
                                (event) => this.onchange(event, dom_vnode),
                                this.selected,
                                this.disabled,
                            )
                        }}
                        onremove={(vnode) => bootstrap_multiselect_onremove(vnode)}
                        onupdate={(dom_vnode) => {
                            this._$select = $(dom_vnode.dom)
                            const dom = $(dom_vnode.dom) as ElementWithMultiselect
                            if (this.disabled !== vnode.attrs.disabled) {
                                this.disabled = vnode.attrs.disabled
                                bootstrap_set_disabled(dom, this.disabled)
                            }
                            if (this.children_length !== vnode.children.length) {
                                this.children_length = vnode_children_length
                                dom.multiselect('rebuild')
                                bootstrap_set_disabled(dom, this.disabled)
                                bootstrap_set_selected(dom, this.selected)
                            }
                        }}
                    >
                        {vnode.attrs.empty_option}
                        {(vnode.children as m.ChildArray | null)
                            ?.filter((child) => child !== null)
                            .map((opt: m.Child | m.ChildArray) => {
                                // @ts-ignore
                                const attrs = opt?.attrs as DropDownOptionAttrs
                                if (vnode.attrs.selected.includes(attrs.value)) {
                                    attrs.__selected = true
                                }
                                return opt
                            })}
                    </select>
                    <button
                        className={classes('btn-reset btn btn-default', {
                            disabled: !vnode.attrs.selected.length,
                        })}
                        onclick={(e) => {
                            e.preventDefault()
                            vnode.attrs.selected.splice(0, vnode.attrs.selected.length)
                            this.onchange_handler(vnode.attrs.selected)
                            this._$select.multiselect('deselectAll', false)
                            bootstrap_set_selected(this._$select, vnode.attrs.selected)
                        }}
                    >
                        <i className="glyphicon glyphicon-remove-sign"/>
                    </button>
                    {/* See Note on the DropDownSelect class. */}
                    <input
                        className={'form-control sneaky-validator'}
                        required={vnode.attrs.required}
                        name={'the-validation-nobody-wants-to-see'}
                        value={vnode.attrs.selected}
                    />
                </span>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div className="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}

interface CountriesSelectAttrs extends DropDownWithSelectAttrs {
    only_eu?: boolean
}

function _country_representation_for_drop_down(country_code: string): string {
    if (countries[country_code]) {
        return `${country_code} - ${countries[country_code]}`
    }
    return country_code
}

/** Dropdown for selecting a country. */
export class CountriesSelect extends MithrilTsxComponent<CountriesSelectAttrs> {
    view(vnode: m.Vnode<CountriesSelectAttrs>): m.Children {
        return (
            <DropDownWithSelect
                onchange={vnode.attrs.onchange}
                selected={vnode.attrs.selected}
                empty_option={vnode.attrs.empty_option || empty_option(vnode.attrs.placeholder) || empty_option('Select a country')}
                help={vnode.attrs.help}
                required={vnode.attrs.required}
                disabled={vnode.attrs.disabled}
                label={vnode.attrs.label}
                validation={vnode.attrs.validation}
                id={vnode.attrs.id}
            >
                {Object.keys(countries)
                    .filter(
                        (country_code) =>
                            !vnode.attrs.only_eu ||
                            (vnode.attrs.only_eu && european_union_countries.includes(country_code)),
                    )
                    .map((country_code: string) => (
                        <DropDownOption value={country_code}>
                            {_country_representation_for_drop_down(country_code)}
                        </DropDownOption>
                    ))}
            </DropDownWithSelect>
        )
    }
}

interface CountriesMultiSelectAttrs {
    selected_country_codes: string[]
    onchange: (country_code: string[]) => unknown
    disabled?: boolean
}

export class CountriesMultiSelect extends MithrilTsxComponent<CountriesMultiSelectAttrs> {
    view(vnode: m.Vnode<CountriesMultiSelectAttrs>): m.Children {
        return (
            <DropDownMultiSelect
                onchange={vnode.attrs.onchange}
                empty_option={empty_option('Select a country')}
                disabled={vnode.attrs.disabled}
                label={vnode.attrs.label}
                help={vnode.attrs.help}
                selected={vnode.attrs.selected_country_codes}
            >
                {Object.keys(countries).map((country_code: string) => (
                    <DropDownOption value={country_code}>
                        {_country_representation_for_drop_down(country_code)}
                    </DropDownOption>
                ))}
            </DropDownMultiSelect>
        )
    }
}

interface FormGroupAttrs {
    label?: string
    help_text?: string
}

export class FormGroup extends MithrilTsxComponent<FormGroupAttrs> {

    view(vnode: m.Vnode<FormGroupAttrs>): m.Children {
        return (
            <div className="field">
                <label>{vnode.attrs.label && vnode.attrs.label}</label>
                {vnode.children}
                {vnode.attrs.help_text && <div className={'help'}>{vnode.attrs.help_text}</div>}
            </div>
        )
    }
}

interface InputDateAttrs extends Validator {
    min?: string
    max?: string
    value: string | undefined
    onchange: (new_value: DateTime | null) => unknown
    disabled?: boolean
    required?: boolean
}

export class InputDate extends MithrilTsxComponent<InputDateAttrs> {
    view(vnode: m.Vnode<InputDateAttrs>): m.Children {
        const value =
            vnode.attrs.value && vnode.attrs.value.includes('T')
                ? DateTime.fromISO(vnode.attrs.value).toISODate()
                : vnode.attrs.value
        const element = (
            <input
                type="date"
                min={vnode.attrs.min}
                max={vnode.attrs.max}
                value={value}
                required={vnode.attrs.required}
                disabled={vnode.attrs.disabled}
                onchange={(event: InputEvent) => {
                    const event_value = (event.target as HTMLInputElement).value
                    vnode.attrs.onchange(event_value ? DateTime.fromISO(event_value) : null)
                }}
                className="no-click"
            />
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-field-date field', {
                disabled: vnode.attrs.disabled,
            })}>
                <label>{vnode.attrs.label}</label>
                {element}
            </div>
        )
    }
}

interface TextInputAttrs extends ValidatableElement, LabeledElement {
    required?: boolean
    placeholder?: string
    value: string
    oninput: (value: string) => unknown
    onchange?: (value: string) => unknown
    onkeydown?: (value: string) => unknown
    onfocusout?: (value: string) => unknown
    disabled?: boolean
}

export class TextInput extends MithrilTsxComponent<TextInputAttrs> {
    view(vnode: m.Vnode<TextInputAttrs>): m.Children {
        const element = (
            <input
                required={vnode.attrs.required}
                type="text"
                placeholder={vnode.attrs.placeholder}
                value={vnode.attrs.value}
                onkeydown={(value: InputEvent) => {
                    if (vnode.attrs.onkeydown) {
                        vnode.attrs.onkeydown(value)
                    }
                }}
                oninput={(value: InputEvent) => {
                    if (vnode.attrs.oninput) {
                        vnode.attrs.oninput((value.target as HTMLInputElement).value)
                    }
                }}
                onfocusout={(value: InputEvent) => {
                    if (vnode.attrs.onfocusout) {
                        vnode.attrs.onfocusout((value.target as HTMLInputElement).value)
                    }
                }}
                disabled={vnode.attrs.disabled}
            />
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-field-text field', {
                disabled: vnode.attrs.disabled,
            })}>
                <label>{vnode.attrs.label}</label>
                {element}
                {vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </div>
        )
    }
}

/**
 * A TextInput with validation options.
 */
export class FieldText extends MithrilTsxComponent<TextInputAttrs> {
    view(vnode: m.Vnode<TextInputAttrs>): m.Children {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        return (
            <div className={classes('c-field-text field', {
                valid: !validation || !invalid,
                invalid: invalid && validation && validation.dirty,
            })}>
                {(vnode.attrs.label || (validation && validation.label)) && (
                    <label>
                        {vnode.attrs.label}
                        {validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <input
                    type="text"
                    placeholder={vnode.attrs.placeholder}
                    value={vnode.attrs.value}
                    oninput={(value: InputEvent) => {
                        if (validation) {
                            validation.dirty = true
                        }
                        if (vnode.attrs.oninput) {
                            vnode.attrs.oninput((value.target as HTMLInputElement).value)
                        }
                    }}
                    onfocusout={(value: InputEvent) => {
                        if (vnode.attrs.onfocusout) {
                            vnode.attrs.onfocusout((value.target as HTMLInputElement).value)
                        }
                    }}
                    disabled={vnode.attrs.disabled}
                />
                {(() => {
                    if (invalid && validation?.dirty) return <div className="help validation">{invalid.message}</div>
                    else if (vnode.attrs.help) return <div className="help">{vnode.attrs.help}</div>
                })()}
            </div>
        )
    }
}

export interface TextAreaInputAttrs extends TextInputAttrs {
    rows: number | undefined
}

export class TextArea extends MithrilTsxComponent<TextAreaInputAttrs> {

    view(vnode: m.Vnode<TextAreaInputAttrs>): m.Children {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        const element = (
            <textarea
                required={vnode.attrs.required}
                placeholder={vnode.attrs.placeholder}
                value={vnode.attrs.value}
                rows={vnode.attrs.rows}
                oninput={(value: InputEvent) => {
                    if (vnode.attrs.oninput) {
                        vnode.attrs.oninput((value.target as HTMLInputElement).value)
                    }
                }}
                onfocusout={(value: InputEvent) => {
                    if (vnode.attrs.onfocusout) {
                        vnode.attrs.onfocusout((value.target as HTMLInputElement).value)
                    }
                }}
                className={'form-control text-input'}
                disabled={vnode.attrs.disabled}
            />
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-field-textarea field', {
                disabled: vnode.attrs.disabled,
                valid: !validation || !invalid,
                invalid: invalid && validation?.dirty,
            })}>
                <label>{vnode.attrs.label}</label>
                {element}
                {vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </div>
        )
    }
}

export interface RadioSelectionChoice {
    description: string
    value: string
    title?: string
}

interface RadioSelectionAttrs {
    value: string
    onclick: (value: string) => void
    choices: Array<RadioSelectionChoice>
    options?: { name?: string }
}

export class RadioSelection extends MithrilTsxComponent<RadioSelectionAttrs> {
    view(vnode: m.Vnode<RadioSelectionAttrs>): m.Children {
        const options = vnode.attrs.options

        return (
            <div data-toggle="buttons" class="btn-group">
                {vnode.attrs.choices.map((choice: RadioSelectionChoice) => {
                    const name = options?.name || randomString(8)
                    return (
                        <label
                            className={vnode.attrs.value === choice.value ? 'btn btn-info' : 'btn btn-default'}
                            onclick={vnode.attrs.onclick.bind(null, choice.value)}
                            title={choice.title}
                        >
                            <input type="radio" name={name} autocomplete="off" />
                            {choice.description}
                        </label>
                    )
                })}
            </div>
        )
    }
}

/**
 * A SearchBar variant with autosuggest, that allows multiple items
 * to be selected at the bottom of the field.
 */
export class MultiResultSearchBar extends MithrilTsxComponent<any> {
    data = proxy({
        model: '',
        show_suggestions: false,
    })

    view(vn): m.Children {
        return (
            <div className="c-multi-result-searchbar">
                <SearchBar
                    placeholder={vn.attrs.placeholder}
                    label={vn.attrs.label}
                    default_search_text={''}
                    disabled={vn.attrs.disabled}
                    matched_suggestions={
                        vn.attrs.suggestions
                            .filter((i) => i.name.toLowerCase().includes(this.data.model.toLowerCase()))
                            .map((category) => category.name)
                    }
                    model={[this.data, 'model']}
                    show_suggestions={this.data.show_suggestions}
                    onclear={() => {
                        vn.attrs.selection.splice(0, vn.attrs.selection.length)
                    }}
                    oninput={() => {
                        if (!this.data.show_suggestions) {
                            this.data.show_suggestions = true
                        }
                    }}
                    on_submit={(searchText) => {
                        this.data.show_suggestions = false
                        this.data.model = ''
                        const searchterm = searchText.toLowerCase()
                        if (!vn.attrs.suggestions.length) return
                        const matching_suggestion = vn.attrs.suggestions.find((i) => i.name.toLowerCase().includes(searchterm))
                        if (!matching_suggestion) return
                        vn.attrs.selection.push(matching_suggestion.artkey)
                    }}
                />
                <div className="selected-terms">
                    {vn.attrs.suggestions.filter((i) => vn.attrs.selection.includes(i.artkey)).map((suggestion) => (
                        <span
                            tabindex="0"
                            className="label label-default"
                            onclick={() => {
                                const index = vn.attrs.selection.findIndex((i) => i === suggestion.artkey)
                                if (index >= 0) {
                                    vn.attrs.selection.splice(index, 1)
                                }
                            }}
                        >
                            {suggestion.name}
                            <span className="glyphicon glyphicon-remove remove" />
                        </span>
                    ))}
                </div>
            </div>
        )
    }
}

interface DecimalAttrs {
    number_of_fraction_digits?: number // default 2
    value: number
}

/** Display a decimal value with formatting as specified by the user settings. */
export class Decimal extends MithrilTsxComponent<DecimalAttrs> {
    decimal_locale: string
    constructor() {
        super()
        this.decimal_locale = $s.identity.user.decimal_locale
    }

    view(vnode: m.Vnode<DecimalAttrs>): m.Children {
        return (
            <span>
                {displayable_float(vnode.attrs.value, vnode.attrs.number_of_fraction_digits, this.decimal_locale)}
            </span>
        )
    }
}

/** Display a decimal value with formatting as a percent as specified by the user settings. */
export class Percent extends MithrilTsxComponent<DecimalAttrs> {
    decimal_locale: string
    constructor() {
        super()
        this.decimal_locale = $s.identity.user.decimal_locale
    }

    view(vnode: m.Vnode<DecimalAttrs>): m.Children {
        return <span>{format_percentage(vnode.attrs.value, this.decimal_locale)}</span>
    }
}

interface CommentTemplateDropDownAttrs {
    selected_comment_template_artkey: string
    onchange: (comment_template_artkey: string) => unknown
    disabled?: boolean
    required?: boolean
    /**
     * The data source for the drop down options. Fill eg with CommentTemplateDropDownData.comment_templates()
     **/
    get_all_for_drop_down_response$: Observable<CommentTemplateResponse[]>
}

/**
 * Comment template drop down.
 *
 * Usage:
 * <CommentTemplateDropDow
 *       selected_comment_template_artkey={this.selected_relation_artkey}
 *       get_all_for_drop_down_response$={RelationDropDownData.relations()}
 *       onchange={(comment_template_artkey) => this.on_comment_template_selected(comment_template_artkey)}
 * />
 **/
export class CommentTemplateDropDown extends MithrilTsxComponent<CommentTemplateDropDownAttrs> {
    comment_templates: CommentTemplateResponse[] = []

    oncreate(vnode: m.Vnode<CommentTemplateDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe(
            (comment_templates) => (this.comment_templates = comment_templates),
        )
    }

    view(vnode: m.Vnode<CommentTemplateDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
                <DropDownWithSelect
                    onchange={vnode.attrs.onchange}
                    empty_option={empty_option('Select a template')}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected_comment_template_artkey}
                    required={vnode.attrs.required}
                >
                    {this.comment_templates?.map((comment_template) => (
                        <DropDownOption value={`${comment_template.artkey}`}>{comment_template.title}</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}

interface MarginPercentageAttrs {
    value: string | null // Margin percentage as a fraction.
}

export class MarginPercentage extends MithrilTsxComponent<MarginPercentageAttrs> {
    val: GetMarginThresholdResponse | null = null
    low_margin_range = 8
    start_target_margin_range = 8

    oncreate(): void {
        AllMarginThresholds.get(DateTime.now()).subscribe({
            next: (value) => {
                this.low_margin_range = +(value.end_low_margin_range || '0')
                this.start_target_margin_range = +(value.start_target_margin_range || '0')
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<MarginPercentageAttrs>): m.Children {
        const margin_percentage = vnode.attrs.value !== null ? +vnode.attrs.value * 100 : Infinity
        let color_class = 'analysis-mwah-color'
        if (margin_percentage < this.low_margin_range) {
            color_class = 'analysis-bad-color'
        } else if (margin_percentage > this.start_target_margin_range) {
            color_class = 'analysis-good-color'
        }

        return (
            <span className={color_class}>
                <Percent value={margin_percentage / 100} number_of_fraction_digits={1} />
            </span>
        )
    }
}

interface RadioOptionAttrs {
    className: string
    day: string
    title: string
    value: string
    onclick: (value: string) => void
}

export class RadioButtonOption extends MithrilTsxComponent<RadioOptionAttrs> {
    view(vnode: m.Vnode<RadioOptionAttrs>): m.Children {
        return (
            <button
                value={vnode.attrs.value}
                className={vnode.attrs.className}
                onclick={(value: InputEvent) => {
                    vnode.attrs.onclick((value.target as HTMLButtonElement).value)
                }}
            >
                {vnode.attrs.title}
            </button>
        )
    }
}

interface FavouriteStarAttrs {
    is_favourite: boolean
    onclick: (is_favourite: boolean) => unknown
}

export class FavouriteStar extends MithrilTsxComponent<FavouriteStarAttrs> {
    view(vnode: m.Vnode<FavouriteStarAttrs>): m.Children {
        return (
            <div className={'btn'}
                onclick={() => vnode.attrs.onclick(!vnode.attrs.is_favourite)}
                title={'Favourite'}
            >
                {vnode.attrs.is_favourite && <span className={'glyphicon glyphicon-star'} style={'color: gold'} />}
                {!vnode.attrs.is_favourite && (
                    <span className={'glyphicon glyphicon-star-empty'} style={'color: gray'} />
                )}
            </div>
        )
    }
}

interface RangedInputAttrs {
    title?: JSX.Element | string
    type: 'single' | 'double'
    open_end: boolean // whether the max-value is a do everything above the max value.
    min: number
    max: number
    step: number

    value_left: string
    oninput_left: (value: string) => unknown
    value_right?: string
    oninput_right?: (value: string) => unknown
}

/**
 * Ranged input component, renders a slider with:
 * - type = single => single value, aka a volume slider (volume as in audio).
 * - type = double => renders a range (min-max value) slider with two values.
 *
 * If the RangedInput has a value_left and a value_right (in the case of type = double).
 */
export class RangedInput extends MithrilTsxComponent<RangedInputAttrs> {
    oninput_left(ev: InputEvent, vnode: m.Vnode<RangedInputAttrs>): boolean {
        const new_value_left = (ev.target as HTMLInputElement).value
        if (
            vnode.attrs.type === 'double' &&
            +new_value_left >= +(vnode.attrs.value_right || 0) &&
            vnode.attrs.oninput_right
        ) {
            vnode.attrs.oninput_right(`${+new_value_left + 1}`)
        }
        if (vnode.attrs.oninput_left) {
            vnode.attrs.oninput_left(new_value_left)
        }
        return true
    }

    oninput_right(ev: InputEvent, vnode: m.Vnode<RangedInputAttrs>): boolean {
        const new_value_right = (ev.target as HTMLInputElement).value
        if (+new_value_right <= +vnode.attrs.value_left) {
            vnode.attrs.oninput_left(`${+new_value_right - 1}`)
            return true
        }
        if (vnode.attrs.oninput_right) {
            vnode.attrs.oninput_right(new_value_right)
        }
        return true
    }

    view(vnode: m.Vnode<RangedInputAttrs>): m.Children {
        return (
            <span className="c-ranged-input">
                <label>
                    {vnode.attrs.title && `${vnode.attrs.title}: `}
                    {vnode.attrs.type === 'single' && !vnode.attrs.open_end && <span>{vnode.attrs.value_left}</span>}
                    {vnode.attrs.type === 'single' && vnode.attrs.open_end && (
                        <span>
                            {+vnode.attrs.value_left === vnode.attrs.max
                                ? `>${vnode.attrs.value_left}`
                                : vnode.attrs.value_left}
                        </span>
                    )}
                    {vnode.attrs.type === 'double' && !vnode.attrs.open_end && (
                        <span>
                            {vnode.attrs.value_left} - {vnode.attrs.value_right}
                        </span>
                    )}
                    {vnode.attrs.type === 'double' && vnode.attrs.open_end && (
                        <span>
                            {+(vnode.attrs.value_right || '0') === vnode.attrs.max
                                ? `${vnode.attrs.value_left} - ∞`
                                : `${vnode.attrs.value_left} - ${vnode.attrs.value_right}`}
                        </span>
                    )}
                </label>
                <div>
                    <div className={'ranges'}>
                        <input
                            type={'range'}
                            className={vnode.attrs.type === 'double' ? 'first' : 'only'}
                            min={vnode.attrs.min}
                            max={vnode.attrs.max}
                            step={vnode.attrs.step}
                            value={vnode.attrs.value_left}
                            oninput={(ev: InputEvent) => this.oninput_left(ev, vnode)}
                        />
                        {vnode.attrs.type === 'double' && (
                            <input
                                type={'range'}
                                className={'second'}
                                min={vnode.attrs.min}
                                max={vnode.attrs.max}
                                step={vnode.attrs.step}
                                value={vnode.attrs.value_right}
                                oninput={(ev: InputEvent) => this.oninput_right(ev, vnode)}
                            />
                        )}
                    </div>
                </div>
            </span>
        )
    }
}

interface InlineEditableTextAttrs {
    oninput: (value: string) => unknown
    value: string
    onsave: () => unknown
    oncancel: (original_text: string) => unknown
}

/**
 */
export class InlineEditableText extends MithrilTsxComponent<InlineEditableTextAttrs> {

    is_editing = false
    popped_text = ''

    start_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = true
        this.popped_text = vnode.attrs.value
    }

    cancel_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = false
        vnode.attrs.oncancel(this.popped_text)
    }

    save_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = false
        vnode.attrs.onsave()
    }

    view(vnode: m.Vnode<InlineEditableTextAttrs>): m.Children {
        const children: m.Children = [
            !this.is_editing && <div className={'read-only-text'} onclick={() => this.start_editing(vnode)}>
                {vnode.attrs.value}
                <div className={'fas fa-edit'} style={'font-size: small; cursor: pointer;margin-left: 6px;'}/>
            </div>,
            this.is_editing && <div className={'edit-text'}>
                <TextArea
                    value={vnode.attrs.value}
                    oninput={vnode.attrs.oninput}
                    rows={5}
                />
                <CancelButton onclick={() => this.cancel_editing(vnode)}/>
                <SaveButton onclick={() => this.save_editing(vnode)}/>
            </div>,
        ]

        return <div className={'c-inline-edit'}>{children}</div>
    }
}
