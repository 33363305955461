m = require 'mithril'
{join} = require 'prelude-ls'

{should_include_excise} = require 'excise.ls'
{a}: utils = require '../../utils.ls'
{FilterType} = require './models.ls'

{Panel} = require '@/components/panel'
table = require '@/components/table.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs.ls'
{icon} = require '@/components/icon.ls'
discover_link = require '../components/discover_link'
{Pager} = require '@/components/pager/pager.tsx'
{Popover} = require '@/components/popover/popover.ls'
{AmountUnit, Button, ButtonGroup, DataCard, Lot, Icon, Spinner} = require '@bitstillery/common/components'
{to_specs} = require '@bitstillery/common/lib/specs'
app = require('@/app')
{Icon} = require '@bitstillery/common/components'


{accountIconBySlug} = require 'accounts.ls'


export class ShowStock
    (vnode) ->
        @stock = vnode.attrs.stock

    view: (vnode) ->
        if vnode.attrs.count! > 0
            vnode.attrs.toolbar = m Pager, do
                page_size: vnode.attrs.limit!
                count: vnode.attrs.count!
                fetch_page: vnode.attrs.fetch_page
                display_page_count: false

        show_product = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.PRODUCT])
        show_relation = not (vnode.attrs.filter_type! in [FilterType.BOTH, FilterType.RELATION])
        show_specs = vnode.attrs.show_specs and not show_product

        m '.c-market-explore-stock' {key: vnode.attrs.unique_name},
            m Panel, do
                title: vnode.attrs.title
                description: vnode.attrs.description
                collapsible: vnode.attrs.collapsible
                collapsed: vnode.attrs.collapsed
                toolbar: vnode.attrs.toolbar
                footer: vnode.attrs.footer
            ,
                if vnode.attrs.loading!
                    m Spinner
                else if @stock!length
                    stock = @stock
                    [
                        m DataCard, {
                            model: do
                                data: [
                                    {label: 'Stock for', value: vnode.attrs.header()},
                                    {
                                        label: 'Number of cases',
                                        value: m 'div',
                                                m Icon, {size: 'xs', name: 'warehouse', tip: 'In stock'}
                                                m 'span', vnode.attrs.stock_totals['in_stock']+' '
                                                m Icon, {size: 'xs', name: 'cart', tip: 'In purchase'}
                                                m 'span', vnode.attrs.stock_totals['in_purchase']+' '
                                                m Icon, {size: 'xs', name: 'sales', tip: 'In sales'}
                                                m 'span', vnode.attrs.stock_totals['in_sales']+' '
                                                m Icon, {size: 'xs', name: 'shieldHalfFull', tip: 'Available'}
                                                m 'span', vnode.attrs.stock_totals['available']
                                    },
                                ]
                            type: 'dense'
                        }

                        table.table stock, [
                            do
                                field: 'purchase_order_reference'
                                name: 'Purchase Order'
                                function: (record) ~>
                                    m '.td-group',
                                        m 'span.header',
                                            accountIconBySlug(record.account_slug, record.account_name)
                                            m m.route.Link, do
                                                target: '_blank'
                                                href: "/purchase-orders/manage/#{record.purchase_order_reference}"
                                            , record.purchase_order_reference

                                        if show_specs then
                                            m 'span.details',
                                                to_specs({
                                                    bottle_volume: record.bottle_volume,
                                                    bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                    bottle_refill_status: record.bottle_refill_status,
                                                    case_customs_status: record.case_customs_status,
                                                    case_gift_box_type: record.case_gift_box_type,
                                                    case_number_of_bottles: record.case_number_of_bottles,
                                                    case_tax_label: record.case_tax_label,
                                                })
                                width: 13
                            do
                                name: 'Intake'
                                function: (record) ~>
                                    lot_date = null
                                    lot_tip = null
                                    if utils.format-date(record.item_entry_date)
                                        lot_date = utils.format-date(record.item_entry_date)
                                        lot_type = 'default'
                                    else if not record.sales_order_credit_item_artkey
                                        lot_date = utils.format-date(record.expected_delivery_date)
                                        lot_type = 'warning'

                                    if !record.item_lot
                                        if record.item_is_target_in
                                            lot_tip = "Part of <a href='#!/stock/mutations/manage/#{record.item_mutation_reference}' target='_blank'>mutation #{record.item_mutation_reference}</a>"
                                        else
                                            lot_tip = 'This is a purchase order item'


                                    m Lot, {
                                        lot_date: lot_date,
                                        lot_number: record.item_lot,
                                        tip: lot_tip
                                        type: lot_type,
                                        warehouse_name: record.warehouse_name,
                                    }

                                width: 10
                            if show_relation then do
                                width: 20
                                name: 'Supplier'
                                ellipsis: true
                                field: 'supplier_name'
                            if show_product then do
                                name: 'Product'
                                ellipsis: true
                                function: (record) ~>
                                    record.product_name
                            do
                                field: 'item_was_bought_for'
                                name: 'Purchase Price'
                                function: (record) ~>
                                    m AmountUnit, {
                                        case_amount: +record.item_was_bought_for,
                                        case_number_of_bottles: record.case_number_of_bottles,
                                        currency: record.purchase_order_was_bought_in
                                        display_currency: app.$s.currencies.default
                                        excise: record.case_excise_nl,
                                        rate: record.purchase_order_bought_against_rate
                                    }
                            do
                                field: 'item_was_bought_for_plus_costs'
                                name: 'Cost Price'
                                function: (record) ~>
                                    m AmountUnit, {
                                        case_amount: +record.item_was_bought_for_plus_costs,
                                        case_number_of_bottles: record.case_number_of_bottles,
                                        currency: record.purchase_order_was_bought_in
                                        display_currency: app.$s.currencies.default
                                        excise: record.case_excise_nl,
                                        rate: record.purchase_order_bought_against_rate
                                    }

                            do
                                field: 'item_total_stock_value'
                                name: 'Stock value'
                                function: (record) ~>
                                    m Amount, do
                                        amount: +record.item_total_stock_value
                                        excise: +record.case_excise_nl * +record.item_number_of_cases_in_stock
                                        currency: record.purchase_order_was_bought_in
                                        display_currency: app.$s.currencies.default
                                        rate: record.purchase_order_bought_against_rate
                            do
                                width: 1
                                field: 'item_number_of_cases_in_stock'
                                name: m Popover, {title: '', content: 'Number of cases in stock'},
                                    m 'span.glyphicon.glyphicon-home'
                            do
                                width: 1
                                field: 'item_number_of_cases_in_purchase'
                                name: m Popover, {title: '', content: 'Number of cases in purchase'},
                                          m 'span.glyphicon.glyphicon-shopping-cart'
                            do
                                width: 1
                                field: 'item_number_of_cases_in_sales'
                                name: m Popover, {title: '',content: 'Number of cases in sales'},
                                        m 'span.glyphicon.glyphicon-screenshot'
                            do
                                width: 1
                                field: 'item_number_of_cases_available'
                                name: m Popover, {title: '', content: 'Number of cases available'},
                                        icon 'fa-shield-alt'
                            do
                                classes: ['actions'],
                                function: (record) ~>
                                    m '.actions-group no-click',
                                        m ButtonGroup,
                                            m Button, {
                                                icon: "search"
                                                onclick: ~> window.open(discover_link.from_properties record.product_name, record.bottle_volume, record.bottle_alcohol_percentage)
                                                tip: "Manage Stock item #{record.item_reference}"
                                                type: "info"
                                            }
                                            m Button, {
                                                icon: "stock"
                                                onclick: ~> window.open("#!/stock/manage/#{record.item_reference}")
                                                tip: "Manage Stock item #{record.item_reference}"
                                            }
                        ], do
                            search_table_style: false
                            sticky_header: false
                    ]
                else
                    'No results'
