import {DateTime} from 'luxon'
import {copy_object} from '@bitstillery/common/lib/utils'
import {api, config, store} from '@bitstillery/common/app'

import {$s} from '@/app'

export class ExactKeys {
    // Set a refresh token cycle if refresh tokens is still valid.
    timeout_handle = this.set_timeout_for_access_expiry()

    logout(): void {
        Object.assign($s.exact, {
            access_token: '',
            refresh_token: '',
            access_expiry: '',
            frontend_host: '',
        })
        if (this.timeout_handle) {
            clearTimeout(this.timeout_handle)
            this.timeout_handle = null
        }
        store.save()
    }

    /**
     * Calls refresh tokens on the exact-online (through the factserver) for new refresh tokens.
     */
    async refresh_tokens() {
        const {result, status_code} = await api.post('exact.authentication.refresh_tokens', {
            frontend_host: config.frontend_host,
            refresh_token: $s.exact.refresh_token,
        }) as any
        if (status_code > 299) {
            return
        }
        Object.assign($s.exact, {
            access_expiry: result.access_expiry,
            access_token: result.access_token,
            frontend_host: config.frontend_host,
            refresh_token: result.refresh_token,
        })
        store.save()
        this.timeout_handle = this.set_timeout_for_access_expiry()
    }

    /**
     * Logins the user with the authorization code from a Exact redirect result.
     * The actual login is done with the exact-online python api. This should be called
     * once per login session. It will start a refresh token cycle (set_timeout_for_access_expiry).
     */
    async login_oauth(auth_code: string) {
        const {result, status_code} = await api.post('exact.authentication.retrieve_tokens', {
            auth_code: auth_code,
            frontend_host: config.frontend_host,
        }) as any
        if (status_code > 299) {
            return
        }
        Object.assign($s.exact, {
            access_expiry: result.access_expiry,
            access_token: result.access_token,
            frontend_host: config.frontend_host,
            refresh_token: result.refresh_token,
        })
        store.save()
        this.timeout_handle = this.set_timeout_for_access_expiry()
    }

    get_tokens() {
        return copy_object($s.exact)
    }

    is_authenticated() {
        return !!($s.exact.refresh_token && +$s.exact.access_expiry > DateTime.now().toSeconds())
    }

    /**
     * Sets a timeout to refresh_tokens() if the refresh_token and
     * the access_expiry are set in the store.
     */
    private set_timeout_for_access_expiry(): null | ReturnType<typeof setTimeout> {
        if (!$s.exact.access_expiry || !$s.exact.refresh_token) {
            return null
        }
        const expiry_in_ms = (($s.exact.access_expiry * 1000) - new Date().getTime()) - 20000
        if (expiry_in_ms < 0) {
            return null
        }
        return setTimeout(() => Promise.resolve(this.refresh_tokens()), expiry_in_ms)
    }

}
