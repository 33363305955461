export default {
    offer: `
        <p>#contact.salutation# #contact.first_name#,</p>
        <p>#intro.general#</p>
        <p>#weekly_offer.body.general.1st_line#</p>
        <p>#finalize.general#</p>
    `,
    pricelist: `
        <p>#contact.salutation# #contact.first_name#,</p>
        <p>#intro.general#</p>
        <p>#pricelist.body.general.1st_line# #pricelist.body.go_to_portal#</p>
        <p>#body.looking_for_other_items#</p>
        <p>#finalize.general#</p>
    `,
    purchaseRequest: `
        <p>#contact.salutation# #contact.first_name#,</p>
        <p>#intro.general#</p>
        <p>#purchase.body.1st.line#</p>
        <p><br /><br /></p>
        <p>#purchase.body.2nd.line#</p>
        <p>#finalize.general.purchase#</p>
    `,
    spotOffer: `
        <p>#contact.salutation# #contact.first_name#,</p>
        <p>#spot_intro#</p>
        <p>#pricelist_table#</p>
        <p>#Incoterm=exw_Loe# #subject_unsold#</p>
        <p>#spot.finalize#</p>
    `,
}
