import {Observable} from 'rxjs'
import {map, mergeAll} from 'rxjs/operators'

import {Api, FactserverEmptyResponse, FactserverRequestData} from './api'
import {GetProductsResponse, ProductBottle} from './fact2server_api'

export interface Brand {
    artkey: number
    name: string
    match_text: string
    brand_holder: BrandHolder
    brand_holder_artkey: number
    hide_from_pricelist_for_countries: {
        artkey: number
        country_code: string
    }[]
    hide_from_pricelist_for_suppliers: {
        artkey: number
        name: string
    }[]
}

export type BrandResponse = Brand

export interface BrandHolder {
    name: string
    artkey: number
    requires_import_statement: boolean
    hide_from_pricelist_for_countries: {
        artkey: number
        country_code: string
    }[]
    hide_from_pricelist_for_suppliers: {
        artkey: number
        name: string
    }[]
}

export interface UpdateBrandHolderRequest extends Pick<BrandHolder, 'name' | 'requires_import_statement'> {
    artkey?: number
    hide_from_pricelist_for_countries: string[]
    hide_from_pricelist_for_suppliers: string[]
}

export interface UpdateBrandRequest extends Pick<Brand, 'name' | 'match_text'> {
    artkey?: number
    hide_from_pricelist_for_countries: string[]
    hide_from_pricelist_for_suppliers: string[]
    brand_holder_artkey: number | null
}

export interface UpdateCountryForProductsRequest extends FactserverRequestData {
    product_artkeys: number[]
    default_country_code: string
    update_stock: boolean
}

export class ProductManagementApi {
    api = new Api()

    get_all_brand_holders(): Observable<BrandHolder[]> {
        return this.api.post_request('brand_holder.get_all', {})
    }

    get_brand(brand_artkey: number): Observable<BrandResponse> {
        return this.api.post_request('brands.get', {
            artkey: brand_artkey,
        })
    }

    get_brand_holder(brand_holder_artkey: number): Observable<BrandHolder> {
        return this.api.post_request('brand_holder.get', {
            artkey: brand_holder_artkey,
        })
    }

    get_simple_product_names(filter_text: string): Observable<string> {
        return this.get_simple_products(filter_text).pipe(
            mergeAll(),
            map((simple_product: GetProductsResponse) => simple_product.name),
        )
    }

    get_simple_products(filter_text: string): Observable<GetProductsResponse[]> {
        return this.api.get(`discover/products?search_terms=${filter_text}`)
    }

    get_bottles_for_product(artkey: number): Observable<ProductBottle[]> {
        return this.api.get(`discover/products/${artkey}/bottles`)
    }

    get_bottle_by_gtin(gtin: string): Observable<unknown> {
        return this.api.get(`discover/bottle-gtin/${gtin}/bottle`)
    }

    update_country_for_product(request: UpdateCountryForProductsRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('product_management.update_default_country_for_products', request)
    }

    update_or_create_brand(update_brand_request: UpdateBrandRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('brands.update_or_create', {
            ...update_brand_request,
            hide_from_pricelist_for_suppliers: update_brand_request.hide_from_pricelist_for_suppliers.map(
                (entry) => +entry,
            ),
        })
    }

    update_or_create_brand_holder(
        update_brand_holder_request: UpdateBrandHolderRequest,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('brand_holder.update_or_create', {
            ...update_brand_holder_request,
            hide_from_pricelist_for_suppliers: update_brand_holder_request.hide_from_pricelist_for_suppliers.map(
                (entry) => +entry,
            ),
        })
    }

    get_hidden_information_for_product(product_artkey: number): Observable<any> {
        return this.api.get(`discover/products/${product_artkey}/hidden-information`)
    }

    delete_hidden_country_for_product(product_artkey: number, country_code: string): Observable<any> {
        return this.api.delete(`discover/products/${product_artkey}/hidden-information/countries/${country_code}`)
    }

    delete_hidden_relation_for_product(product_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.delete(`discover/products/${product_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    add_hidden_country_for_product(product_artkey: number, country_code: string): Observable<any> {
        return this.api.post_fact2server_request(`discover/products/${product_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation_for_product(product_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.post_fact2server_request(`discover/products/${product_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }

    get_hidden_information_for_brand(brand_artkey: number): Observable<any> {
        return this.api.get(`discover/brands/${brand_artkey}/hidden-information`)
    }

    delete_hidden_country_for_brand(brand_artkey: number, country_code: string): Observable<any> {
        return this.api.delete(`discover/brands/${brand_artkey}/hidden-information/countries/${country_code}`)
    }

    delete_hidden_relation_for_brand(brand_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.delete(`discover/brands/${brand_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    add_hidden_country_for_brand(brand_artkey: number, country_code: string): Observable<any> {
        return this.api.post_fact2server_request(`discover/brands/${brand_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation_for_brand(brand_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.post_fact2server_request(`discover/brands/${brand_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }

    get_hidden_information_for_brand_holder(brand_holder_artkey: number): Observable<any> {
        return this.api.get(`discover/brand_holders/${brand_holder_artkey}/hidden-information`)
    }

    delete_hidden_country_for_brand_holder(brand_holder_artkey: number, country_code: string): Observable<any> {
        return this.api.delete(`discover/brand_holders/${brand_holder_artkey}/hidden-information/countries/${country_code}`)
    }

    delete_hidden_relation_for_brand_holder(brand_holder_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.delete(`discover/brand_holders/${brand_holder_artkey}/hidden-information/relations/${relation_artkey}`)
    }

    add_hidden_country_for_brand_holder(brand_holder_artkey: number, country_code: string): Observable<any> {
        return this.api.post_fact2server_request(`discover/brand_holders/${brand_holder_artkey}/hidden-information/countries`, {
            country_code: country_code,
        })
    }

    add_hidden_relation_for_brand_holder(brand_holder_artkey: number, relation_artkey: number): Observable<any> {
        return this.api.post_fact2server_request(`discover/brand_holders/${brand_holder_artkey}/hidden-information/relations`, {
            relation_artkey: relation_artkey,
        })
    }
}
