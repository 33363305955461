import _models from './_offers.ls'

export const {
    BulkUpdateField,
    CustomOfferItem,
    Offer,
    OfferType,
    PortalPopup,
    Priority,
} = _models
