m = require 'mithril'
{map, compact} = require 'prelude-ls'

{match-term-in, maybe-map, format-date-time, prepend} = require 'utils.ls'
app = require('@/app')

{account-icon} = require 'accounts.ls'
{AccountSlug} = require '@bitstillery/common/account/account'
{icon-button, button-with-icon} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'
{contact_person_link, relation_link} = require '@/components/entity_links.ls'
{link} = require '@/components/link.ls'
SearchInput = require '@/components/collection/search_input.ls'


module.exports = class PortalUserList
    (vnode) ->
        @portal_users = new Collection do
            api_function_name: 'portal.user_management.get_all_users'
            query_limit: 25
            default_sort_by: 'last_activity_timestamp'
            default_sort_order: 'desc'
            filter_serverside: true
            paged: true
            additional_params: -> {}

        @search_input_ctrl = new SearchInput.controller do
            collection: @portal_users
            placeholder: 'Search for portal user...'

    oncreate: ~>
        @portal_users.init!

    onremove: ~>
        @search_input_ctrl.onremove!

    view: ->
        a = -> [.. for &]
        m '.c-portal-user-list view' a do
            m '.btn-toolbar' a do
                button-with-icon 'Create Portal User', 'plus', do
                    class: 'btn-default'
                    onclick: -> m.route.set '/portal/portal-users/create'

            m '.c-filter-group',
                SearchInput.view @search_input_ctrl

            @portal_users.pager!

            m CollectionTable, do
                collection: @portal_users
                options:
                    search_table_style: true
                    sticky_header: true
                    with_buttons: true
                    autoscale: true
                    unique_name: 'portal_users'
                    onclick: (record) ->
                        -> m.route.set "/portal/portal-users/#{record.artkey}/activity"
                columns:
                    do
                        width: 4
                        name: 'Username'
                        field: 'name'
                        sort: true
                    do
                        width: 12
                        name: 'Contact person'
                        field: 'contact_person.name'
                        function: (record) ~>
                            contact_person = record.contact_person
                            contact_person_link contact_person, do
                                onclick: (event) -> event.stopPropagation()
                    do
                        width: 8
                        name: 'Relation'
                        field: 'contact_person.supplier'
                        transform: relation_link
                    do
                        width: 5
                        name: 'Last seen'
                        sort: true
                        descending: true
                        field: 'last_seen_timestamp'
                        transform: maybe-map format-date-time
                    do
                        width: 5
                        name: 'Times seen'
                        sort: true
                        descending: true
                        field: 'number_of_sessions'
                    do
                        width: 8
                        name: 'Invite URL'
                        field: 'invite_code'
                        classes: ['no-click']
                        function: (user) ->
                            if not user.invite_code
                                return ''
                            account_slug = user.contact_person.supplier.sales_account.slug
                            portal_base_url = if account_slug == AccountSlug.ETR then app.config.a2bc_portal_base_url  else app.config.portal_base_url
                            return [
                                m '.btn-toolbar.no-click',
                                    button-with-icon "#{portal_base_url}/#!/invite/#{user.invite_code}" 'fa-copy', do
                                        onclick: -> navigator.clipboard.writeText("#{portal_base_url}/#!/invite/#{user.invite_code}").
                                            then(-> alert("Copied link to clipboard.")).
                                            catch(-> console.log("Cannot copy"))

                            ]

                    do
                        width: 5
                        header: ''
                        name: 'Actions'
                        function: (record) ->
                            m '.btn-toolbar.no-click' m '.btn-group',
                                icon-button 'pencil', do
                                    class: 'btn-default no-click'
                                    onclick: -> m.route.set "/portal/portal-users/#{record.artkey}/edit"
                                # Snowboarding is an activity!
                                icon-button 'fa-snowboarding', do
                                    class: 'btn-default no-click'
                                    onclick: -> m.route.set "/portal/portal-users/#{record.artkey}/activity"
