api = require 'api.ls'
app = require('@/app')
m = require 'mithril'

# http://www.stacktracejs.com/#!/docs/stacktrace-js
# https://developer.mozilla.org/en/docs/Web/API/GlobalEventHandlers/onerror
# https://developers.google.com/web/tools/chrome-devtools/debug/console/track-exceptions?hl=en#handle-runtime-exceptions-using-windowonerror
error_handler = (message, file, line, col, error) ->
    if app.config.env == 'development'
        # disable redrawing now, otherwise we will keep redrawing and throwing errors
        data = do
            url: m.route.get!
            user: app.$s.identity.user.name
            message: message
            file: file
            line: line
            col: col
            error: error
            stack: if error? then error['stack']

        api.call 'error.handler' data

        console.error(message, file, line, col)
        # The stack is not always populated :( the error parameter is often undefined
        if data['stack']
            console.error(data['stack'])

    return true

module.exports = error_handler
