import errors from 'errors.ls'
import utils from 'utils.ls'
import error_handler from 'error_handler.ls'
import {config} from '@bitstillery/common/app'

export const observable = utils.eventsMixin({})

export function generic_error_handler(error) {
    return errors.generic_handler(error)
}

export function bind() {
    if (config.env !== 'development') {
        window.onerror = error_handler
    }
}
