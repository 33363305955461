m = require 'mithril'
{map, compact} = require 'prelude-ls'
{a, match-term-in, maybe-map, format-date-time, prepend} = require 'utils.ls'
{icon-button, button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'
api = require 'api.ls'
app = require('@/app')


module.exports = class PortalNewsList
    (vnode) ->
        @entities = new Collection do
            api_function_name: 'portal.news.get_news'
            filter_function: @is-match
            query_limit: 25
            default_sort_by: 'date'
            default_sort_order: 'desc'

        @search_input_ctrl = new SearchInput.controller do
            collection: @entities
            placeholder: 'Search for portal news item...'

    oncreate: ~>
        @entities.init!

    is-match: (news, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            news.title
            news.content
        ]

    onremove: ~>
        @search_input_ctrl.onremove!

    mark_deleted: (artkey) ~>
        api.call 'portal.news.mark_deleted' {artkey: artkey}, (resp) ~>
            if resp.success
                app.notifier.notify 'Successfully deleted news item', 'success'
                @entities.soft_delete(artkey)
            else
                app.$m.common.generic_error_handler!


    view: -> m '.c-portal-news view' a do
        m '.btn-toolbar' a do
            button-with-icon 'Create Portal News', 'plus', do
                class: 'btn-default'
                onclick: ~> m.route.set '/portal/portal-news/create'

        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @entities
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'portal_news'
                onclick: (record, e) -->
                    m.route.set "/portal/portal-news/#{record.artkey}/edit"
            columns:
                do
                    width: 1
                    name: 'Date'
                    field: 'date'
                    sort: true
                    transform: format-date-time
                do
                    width: 3
                    name: 'Title'
                    field: 'title'
                    sort: true
                do
                    width: 10
                    name: 'Message'
                    field: 'content'
                    sort: true
                    ellipsis: true
                do
                    width: 2
                    name: 'Author'
                    field: 'creator.profile.name'
                    sort: true
                do
                    width: 1
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            icon-button 'pencil', do
                                class: 'btn-default no-click'
                                onclick: ~> m.route.set "/portal/portal-news/#{record.artkey}/edit"
                            icon-button 'remove', do
                                class: 'btn-danger no-click'
                                onclick: ~> @mark_deleted(record.artkey)
