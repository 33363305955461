import {Observable} from 'rxjs'

import {Api, FactserverRequestData} from './api'

interface GetMarketByBottleRequest extends FactserverRequestData {
    bottle_artkey?: number
    supplier_artkey?: number
    supplier_price_list_artkey?: number
    customs_status?: string
    preferred_competitors?: string
    exclude_company_type?: string[]
}

export interface GetSupplierBottleTransactionsRequest extends FactserverRequestData {
    bottle_artkey: number
    supplier_artkey?: number
}

export interface GetSupplierBottleTransactionsResponsePurchaseOrderItem {
    artkey: number
    euro_was_bought_for: number
    number_of_cases: number
    number_of_bottles_per_case: number
    purchase_order_reference: string
    in_stock_on: string
    supplier_name: string
}

export interface GetSupplierBottleTransactionsResponseSalesOrderItem {
    artkey: number
    euro_was_sold_for: number
    margin_percentage: number
    number_of_cases: number
    number_of_bottles_per_case: number
    sales_order_artkey: number
    sales_order_reference: string
    sales_order_status: string
    created_on: string
    is_invoiced_on: string
    client_name: string
    purchase_order_item_artkey: string
    purchase_order_reference: string
}

export interface GetSupplierBottleTransactionsResponse extends FactserverRequestData {
    purchase_order_items: Array<GetSupplierBottleTransactionsResponsePurchaseOrderItem>
    sales_order_items: Array<GetSupplierBottleTransactionsResponseSalesOrderItem>
}

export enum SupplierPriceListItemClassification {
    // Was purchased from this supplier and sold well.
    STAR = 'STAR',
    // Was purchased from this supplier and did not sell well.
    WRECK = 'WRECK',
    // Was purchased from another supplier and sold well.
    RISING_STAR = 'RISING_STAR',
    // Was purchased from another supplier and did not sell well.
    FALLING_STAR = 'FALLING_STAR',
    // Not yet purchased before.
    QUESTION_MARK = 'QUESTION_MARK',
}

export interface SupplierPriceListItem {
    artkey: number

    supplier_price_list: {
        artkey: number
        start_date: string
        end_date?: string
        status: string
    }

    supplier: {
        artkey: number
        name: string
        company_type: string
        account: {
            artkey: number
            name: string
            slug: string
        }
    }

    bottle: {
        artkey: number
        volume: string
        refill_status: string
        alcohol_percentage: string
        excise_nl: number
        product: {
            artkey: number
            name: string
            default_country_code: string
        }
    }

    currency: string
    price_per_case: number
    price_per_bottle: number
    euro_price_per_bottle: number
    case_excise_nl: number
    aux_info: string
    number_of_bottles_per_case: number
    customs_status: string
    gift_box_type: string
    incoterm: string
    is_available: boolean | null
    availability_status: string

}

export class MarketAnalysisApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    get_market_by_bottle(request: GetMarketByBottleRequest): Observable<SupplierPriceListItem[]> {
        return this.api.post_request('marketanalysis.get_market_by_bottle', request)
    }

    get_supplier_bottle_transactions(
        request: GetSupplierBottleTransactionsRequest,
    ): Observable<GetSupplierBottleTransactionsResponse> {
        return this.api.post_request('marketanalysis.get_supplier_bottle_transactions', request)
    }
}
