import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {notifier} from '@bitstillery/common/app'
import {Amount, Tippy} from '@bitstillery/common/components'

import {Link} from '../components/discover'
import {MoneyInput} from '../components/decimal_input'
import {BottleMarket} from '../components/market_info/bottle_market'
import {BottleStock} from '../components/market_info/bottle_stock'
import {BottlePurchaseOrders} from '../components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '../components/market_info/bottle_sales_orders'
import {OfferHistory} from '../components/market_info/offer_history'

import {$s} from '@/app'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    UpdateOfferItemRequest,
    StockApi,
    StockRecord,
    ApproveBulkRequest,
    ApprovalItemData,
} from '@/factserver_api/stock_api'
import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {DefaultButton, SuccessButton, CancelButton} from '@/components/buttons'
import {MarginPercentage} from '@/components/html_components'
import {ClipboardCopy} from '@/components/clipboard.tsx'

export interface NeedsApprovalResponse extends StockRecord {
    edit: boolean
    is_showing_details: boolean
    suggested_offer_item_artkey?: number
    suggested_offer_item_type?: string
    offer_item_maximum_quantity?: number
    offer_item_minimum_quantity?: number
    unsaved_maximum_quantity?: number
    unsaved_minimum_quantity?: number
    unsaved_sales_price_per_case?: number
}

class ApprovalInfoPanel extends MithrilTsxComponent<
    CollectionTableRowComponentProps<NeedsApprovalResponse, unknown>
> {
    view(vnode: m.Vnode<CollectionTableRowComponentProps<NeedsApprovalResponse, unknown>>): m.Children {
        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }
        const row = vnode.attrs.row
        const info_panel_attrs = {
            bottle_artkey: row.bottle_artkey,
            case_customs_status: row.case_customs_status,
            ignore_ref: true,
        }
        return (
            <tr className={'well'}>
                <td colspan={'100%'}>
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <BottleMarket {...info_panel_attrs} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <BottleStock {...info_panel_attrs} />
                        </div>
                        <div className="col-sm-6">
                            <OfferHistory {...info_panel_attrs}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <BottlePurchaseOrders {...info_panel_attrs} />
                        </div>
                        <div className="col-sm-6">
                            <BottleSalesOrders {...info_panel_attrs} />
                        </div>
                    </div>
                </td>
            </tr>
        )
    }
}

export default class OfferApproval extends MithrilTsxComponent<unknown> {
    api = new StockApi()
    product_management_api = new ProductManagementApi()
    search_bar_controller: SearchBarControl | null = null
    items = new PagedCollectionFetcher<NeedsApprovalResponse>(
        'approval.get_needs_approval',
        'sales_price_per_case',
        null,
        25,
        false,
    )

    bulk_edit = false
    bulk_offer_items: Set<NeedsApprovalResponse> = new Set()

    save_offer_item(row: NeedsApprovalResponse): void {
        if (!row.unsaved_sales_price_per_case) {
            notifier.notify('Unable to save offer item: please set a price before saving', 'danger')
            return
        }

        row.sales_price_per_case = row.unsaved_sales_price_per_case
        row.offer_item_maximum_quantity = row.unsaved_maximum_quantity
        row.offer_item_minimum_quantity = row.unsaved_minimum_quantity

        const data: UpdateOfferItemRequest = {
            item_artkey: row.artkey,
            case_artkey: String(row.case_artkey),
            sales_price_per_case: String(row.sales_price_per_case),
            offer_item_type: row.suggested_offer_item_type,
            maximum_quantity: row.offer_item_maximum_quantity,
            minimum_quantity: row.offer_item_minimum_quantity,
        }

        this.api.update_offer_item(data).subscribe({
            next: (resp) => {
                row.suggested_offer_item_artkey = resp.artkey
                row.edit = false
                notifier.notify(`Product ${row.product_name} updated`, 'info')
                m.redraw()
            },
        })
    }

    approve_item(row: NeedsApprovalResponse): void {
        const data: ApprovalItemData = this._create_approval_request(row)

        this.api.approve_item(data).subscribe({
            next: () => {
                notifier.notify(`Product ${row.product_name} approved`, 'info')
                this._remove_item_from_list(row.artkey)
                m.redraw()
            },
        })
    }

    save_and_approve_bulk() {
        const bulk_data = Array.from(this.bulk_offer_items)
            .filter((item) => item.unsaved_sales_price_per_case)
            .map((item) => {
                // @ts-ignore
                item.sales_price_per_case = item.unsaved_sales_price_per_case
                item.offer_item_maximum_quantity = item.unsaved_maximum_quantity
                item.offer_item_minimum_quantity = item.unsaved_minimum_quantity

                return this._create_approval_request(item)
            })

        const request: ApproveBulkRequest = {
            bulk_items: bulk_data,
        }

        this.api.approve_bulk_items(request).subscribe({
            next: (response) => {
                for (const item_artkey of response.approved_items.values()) {
                    this._remove_item_from_list(+item_artkey)
                }

                if (response.approved_items.length !== this.bulk_offer_items.size) {
                    notifier.notify('Not all items were able to be approved, these items are still present on the page', 'warning')
                }
                notifier.notify(`Approved ${bulk_data.length} products`, 'info')
                m.redraw()
                this._reset_bulk_edit()
            },
        })
    }

    _create_approval_request(row: NeedsApprovalResponse): ApprovalItemData {
        return {
            item_artkey: row.artkey,
            sales_price_per_case: row.sales_price_per_case,
            // If these are undefined these keys are excluded from the object, so we set them to null if they are falsely
            maximum_order_quantity: row.offer_item_maximum_quantity || null,
            minimum_order_quantity: row.offer_item_minimum_quantity || null,
            case_artkey: row.case_artkey,
            offer_item_artkey: row.suggested_offer_item_artkey,
            offer_item_type: row.suggested_offer_item_type,
        }
    }

    cancel_bulk() {
        this.bulk_offer_items.forEach((item) => {
            this._clear_temp_values(item)
        })
        this._reset_bulk_edit()
    }

    _remove_item_from_list(artkey: number): void {
        this.items.fetched_rows = this.items.fetched_rows.filter((item) => item.artkey !== artkey)
    }

    _row_features_to_string(row: NeedsApprovalResponse): string {
        let features: Array<string> = []
        features.push(row.case_gift_box_type)
        if (row.case_tax_label) {
            features.push(row.case_tax_label)
        }
        if (row.item_best_before_date) {
            features.push(`BBD: ${format_iso_to_date(row.item_best_before_date)}`)
        }
        if (row.item_damages) {
            features.push(row.item_damages.replace(', ', ' / '))
        }
        if (row.item_general_tags) {
            features.push(row.item_general_tags.replace(', ', ' / '))
        }
        if (row.item_pack_size) {
            features.push(row.item_pack_size)
        }
        if (row.item_packaging) {
            features.push(row.item_packaging)
        }
        return features.join(' / ')
    }

    _stock_status_icon(row: NeedsApprovalResponse) {
        let icon = 'glyphicon glyphicon-'
        let title: string
        if (row.item_lot && row.purchase_order_is_in_stock) {
            icon += 'home'
            title = 'In stock'
        }
        else if (!row.purchase_order_is_in_stock && !row.item_mutation_reference) {
            icon += 'shopping-cart'
            title = 'Uncompleted purchase order'
        }
        else if (row.item_mutation_reference) {
            icon += 'plane'
            title = 'Being moved'
        }
        else {
            icon += 'shopping-cart'
            title = 'In purchase'
        }
        return <span class={icon} title={title}/>
    }

    _calc_margin_percentage_for_row(row: NeedsApprovalResponse): JSX.Element | undefined {
        if (!+row.sales_price_per_case && !row.unsaved_sales_price_per_case) {
            return
        }
        const ppc = row.unsaved_sales_price_per_case || row.sales_price_per_case
        const gross = ppc - row.item_euro_was_bought_for_plus_costs
        const margin = gross / ppc
        return <MarginPercentage value={margin}/>
    }

    _reset_bulk_edit(): void {
        this.bulk_edit = false
        this.bulk_offer_items = new Set()
    }

    _clear_temp_values(row: NeedsApprovalResponse): void {
        row.unsaved_sales_price_per_case = undefined
        row.unsaved_maximum_quantity = undefined
        row.unsaved_minimum_quantity = undefined
    }

    _set_temp_values_to_saved(row: NeedsApprovalResponse): void {
        row.unsaved_maximum_quantity = row.offer_item_maximum_quantity
        row.unsaved_minimum_quantity = row.offer_item_minimum_quantity
        row.unsaved_sales_price_per_case = row.sales_price_per_case
    }

    _add_or_remove_bulk_row(row: NeedsApprovalResponse) {
        if (!row.unsaved_sales_price_per_case) {
            this.bulk_offer_items.delete(row)
        } else {
            this.bulk_offer_items.add(row)
        }
    }

    view(): m.Children {
        return (
            <div className="c-pricelist-approval view">
                <div className="c-filter-group">
                    <SearchBar
                        placeholder={'Search for product name, supplier or purchase order reference...'}
                        on_submit={(search_text: string) => this.items.set_search_terms(search_text)}
                        default_search_text={this.items.search_text()}
                        on_get_suggestions$={(filter_text: string) =>
                            this.product_management_api.get_simple_product_names(filter_text)
                        }
                        search_bar_controller={(controller: SearchBarControl) =>
                            (this.search_bar_controller = controller)
                        }
                    />
                    <span className={'c-btn-group-end'}>
                        {!this.bulk_edit && (
                            <DefaultButton
                                icon_class={'glyphicon glyphicon-pencil'}
                                title={'Bulk Edit'}
                                onclick={() => {
                                    this.items.fetched_rows.forEach((row) => {
                                        row.unsaved_maximum_quantity = row.offer_item_maximum_quantity
                                        row.unsaved_minimum_quantity = row.offer_item_minimum_quantity
                                        row.edit = false
                                    })
                                    this.bulk_edit = true
                                }}
                            />
                        )}
                        {this.bulk_edit && [
                            <CancelButton
                                title="Leave Bulk Edit"
                                onclick={() => {this.cancel_bulk()}}
                            />,
                            <SuccessButton
                                icon_class={'glyphicon glyphicon-thumbs-up'}
                                title="Approve Products"
                                disabled={this.bulk_offer_items.size === 0}
                                tooltip={'Save and approve edited items'}
                                onclick={() => this.save_and_approve_bulk()}
                            />,
                        ]}
                    </span>
                </div>
                <CollectionTable<NeedsApprovalResponse, void>
                    collection_fetcher={this.items}
                    on_row_click={(row: NeedsApprovalResponse) => {
                        row.is_showing_details = !row.is_showing_details
                    }}
                    on_row_click_component={ApprovalInfoPanel}
                >
                    <CollectionTableColumn
                        header_title={() => '#'}
                        sort_name={'purchase_order_reference'}
                        data_field={(row: NeedsApprovalResponse) =>
                            <Link href={`/purchase-orders/manage/${row.purchase_order_artkey}`}>{row.purchase_order_reference}</Link>
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Product'}
                        sort_name={'product_name'}
                        data_field={(row: NeedsApprovalResponse) =>
                            <span>
                                {row.product_name}
                                <ClipboardCopy text={row.product_name} />
                            </span>
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Supplier'}
                        sort_name={'supplier_name'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.supplier_name
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Date confirmed'}
                        sort_name={'purchase_order_confirmation_date'}
                        data_field={(row: NeedsApprovalResponse) =>
                            format_iso_to_date(row.purchase_order_confirmation_date)
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Specs'}
                        data_field={(row: NeedsApprovalResponse) => {
                            let specs: Array<number | string> = []
                            specs.push(+row.case_number_of_bottles)
                            specs.push((+row.bottle_volume).toFixed(1))
                            specs.push((+row.bottle_alcohol_percentage).toFixed(1))
                            specs.push(row.bottle_refill_status)
                            return specs.join(' / ')
                        }

                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Features'}
                        data_field={(row: NeedsApprovalResponse) =>
                            this._row_features_to_string(row)
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Cus.'}
                        sort_name={'case_customs_status'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.case_customs_status
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => <span class={'glyphicon glyphicon-home'} title={'Number of cases in stock'}/>}
                        sort_name={'item_number_of_cases_in_stock'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.item_number_of_cases_in_stock
                        }
                    />
                    <CollectionTableColumn
                        header_title={() =>
                            <span class={'glyphicon glyphicon-shopping-cart'} title={'Number of cases in purchase'} />
                        }
                        sort_name={'item_number_of_cases_in_purchase'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.item_number_of_cases_in_purchase
                        }
                    />
                    <CollectionTableColumn
                        header_title={() =>
                            <span class={'glyphicon glyphicon-screenshot'} title={'Number of cases in sales'}/>
                        }
                        sort_name={'item_number_of_cases_in_sales'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.item_number_of_cases_in_sales
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => <span class={'fas fa-shield-alt'} title={'Number of cases available'}/>}
                        sort_name={'item_number_of_cases_available'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.item_number_of_cases_available
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Intake'}
                        sort_name={'item_entry_date'}
                        data_field={(row: NeedsApprovalResponse) =>{
                            if (row.item_entry_date) {
                                return format_iso_to_date(row.item_entry_date)
                            }
                            if (!row.sales_order_credit_item_artkey) {
                                let eta_str = format_iso_to_date(row.expected_delivery_date)
                                if (new Date(row.expected_delivery_date) < new Date()) {
                                    return <div>{eta_str} <span class={'glyphicon glyphicon-warning-sign'} title={'Overdue'}/></div>
                                }
                                return eta_str
                            }
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => 'In stock'}
                        sort_name={'item_lot'}
                        data_field={(row: NeedsApprovalResponse) =>
                            <span>
                                {this._stock_status_icon(row)}
                                {' '}
                                <Link href={`/stock/manage/${row.item_reference}`}>{row.item_reference} {row.item_lot}</Link>
                            </span>

                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Remark'}
                        sort_name={'item_remark'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.item_remark
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Cost / cs'}
                        sort_name={'item_was_bought_for_plus_costs'}
                        td_class_name={'price'}
                        data_field={(row: NeedsApprovalResponse) =>
                            <Amount
                                amount={+row.item_was_bought_for_plus_costs}
                                currency={row.purchase_order_was_bought_in}
                                display_currency={$s.currencies.default}
                                rate={row.purchase_order_bought_against_rate}
                            />
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Offer type'}
                        sort_name={'suggested_offer_item_type'}
                        data_field={(row: NeedsApprovalResponse) =>
                            row.suggested_offer_item_type
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Margin'}
                        sort_name={'margin'}
                        data_field={(row: NeedsApprovalResponse) =>
                            <div style={'min-width: 100px'}>
                                {this._calc_margin_percentage_for_row(row)}
                            </div>
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Sugg. sales / cs'}
                        sort_name={'suggested_price_per_case'}
                        td_class_name={'price'}
                        data_field={(row: NeedsApprovalResponse) => {
                            if (+row.suggested_price_per_case) {
                                return (
                                    <Amount
                                        amount={+row.suggested_price_per_case}
                                        currency={$s.currencies.default}
                                    />
                                )
                            }
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => 'Intended sales / cs'}
                        sort_name={'sales_price_per_case'}
                        td_class_name={'price'}
                        data_field={(row: NeedsApprovalResponse) => {
                            if (row.edit || this.bulk_edit) {
                                return (
                                    <div class={'btn-group'}>
                                        {!row.unsaved_sales_price_per_case && row.sales_price_per_case && (
                                            <SuccessButton
                                                icon_class={'fas fa-check'}
                                                tooltip={'Add saved price to bulk edit'}
                                                onclick={() => {
                                                    row.unsaved_sales_price_per_case = row.sales_price_per_case
                                                    this.bulk_offer_items.add(row)
                                                }}
                                            />
                                        )}
                                        <MoneyInput
                                            value={row.unsaved_sales_price_per_case ?? row.sales_price_per_case}
                                            on_value={(value) => {
                                                if (value === 0) {
                                                    row.unsaved_sales_price_per_case = undefined
                                                } else {
                                                    row.unsaved_sales_price_per_case = value
                                                }

                                                if (this.bulk_edit) {
                                                    this._add_or_remove_bulk_row(row)
                                                }
                                            }}
                                            required={true}
                                            minimum={0}
                                            currency={$s.currencies.default}
                                        />
                                    </div>
                                )
                            }
                            else if (+row.sales_price_per_case) {
                                return <Tippy content={'Intended price after approval.'}>
                                    <Amount className='base-sales-price' amount={row.sales_price_per_case} currency={$s.currencies.default}/>
                                </Tippy>
                            }
                            return '-'
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => 'Max Qty'}
                        data_field={(row: NeedsApprovalResponse) => {
                            if (row.edit || this.bulk_edit) {
                                return <input
                                    type={'number'}
                                    className={'no-click'}
                                    value={row.unsaved_maximum_quantity}
                                    min={0}
                                    oninput={
                                        (e) => {
                                            if (!e.target.value) {
                                                row.unsaved_maximum_quantity = undefined
                                            } else {
                                                row.unsaved_maximum_quantity = +e.target.value
                                            }
                                            if (this.bulk_edit) {
                                                this._add_or_remove_bulk_row(row)
                                            }
                                        }
                                    }
                                    style={'width:70px'}
                                />
                            }
                            if (row.offer_item_maximum_quantity) {
                                return row.offer_item_maximum_quantity
                            }
                            return '-'
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => 'MOQ'}
                        data_field={(row: NeedsApprovalResponse) => {
                            if (row.edit || this.bulk_edit) {
                                return <input
                                    type={'number'}
                                    className={'no-click'}
                                    value={row.unsaved_minimum_quantity}
                                    min={0}
                                    oninput={
                                        (e) => {
                                            if (!e.target.value) {
                                                row.unsaved_minimum_quantity = undefined
                                            }
                                            else {
                                                row.unsaved_minimum_quantity = +e.target.value
                                            }

                                            if (this.bulk_edit) {
                                                this._add_or_remove_bulk_row(row)
                                            }
                                        }
                                    }
                                    style={'width:70px'}
                                />
                            }
                            if (row.offer_item_minimum_quantity) {
                                return row.offer_item_minimum_quantity
                            }
                            return '-'
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => ''}
                        data_field={(row: NeedsApprovalResponse) =>
                            <div>
                                {!this.bulk_edit && (
                                    <div className={'.btn-toolbar.no-click'}>
                                        {row.edit && (
                                            <div className={'btn-group'}>
                                                <SuccessButton
                                                    icon_class={'glyphicon glyphicon-ok'}
                                                    disabled={!(row.unsaved_sales_price_per_case)}
                                                    onclick={() => {
                                                        this.save_offer_item(row)
                                                        this._clear_temp_values(row)
                                                    }}
                                                />
                                                <CancelButton onclick={() => {
                                                    row.edit = false
                                                    this._clear_temp_values(row)
                                                }}/>
                                            </div>
                                        )}
                                        {!row.edit && (
                                            <div className={'btn-group'}>
                                                <DefaultButton
                                                    icon_class={'glyphicon glyphicon-pencil'}
                                                    onclick={() => {
                                                        this._set_temp_values_to_saved(row)
                                                        row.edit = true
                                                    }}
                                                />
                                                <SuccessButton
                                                    icon_class={'glyphicon glyphicon-thumbs-up'}
                                                    disabled={!+row.sales_price_per_case}
                                                    onclick={() => this.approve_item(row)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        }
                    />
                </CollectionTable>
            </div>
        )
    }
}
