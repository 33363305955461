m = require 'mithril'

api = require 'api.ls'
{Collection} = require '@/components/collection/collection.ls'
{EmailTable} = require 'email/components/email_table.ls'


export class EmailBatchSentEmails
    (vnode) ->
        @email_batch = vnode.attrs.email_batch
        @emails = new Collection do
            api_function_name: 'email.email.get'
            additional_params: ~> {'email_batch_artkey': @email_batch!artkey}
            query_limit: 25
            dont-reuse: true
            default_sort_by: 'created_on'
            default_sort_order: 'desc'

    oncreate: ~>
        @emails.requery!

    view: ~>
        m '.row' m '.col-lg-4' m 'h4' 'Emails sent'
        m EmailTable, do
            email_collection: @emails
