import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

import {StatusBasic} from './status_basic'
import {StatusDetailed} from './status_detailed'

export default class MyDiscoverColleagues {

    data = proxy({
        tab: 'basic',
    })

    set_tab(tabName) {
        this.data.tab = tabName
        m.route.set(`/my-discover/colleagues?tab=${tabName}`)
    }

    view(_vn:m.Vnode<any>) {
        return <div className="c-my-discover-colleagues view">
            <div className="c-tabs">
                <ul className="nav nav-tabs">
                    <li className={classes('nav-link', {active: this.data.tab === 'basic'})}>
                        <a onclick={() => this.set_tab('basic')}>{$t('my_discover_colleagues.basic')}</a>
                    </li>
                    <li className={classes('nav-link', {active: this.data.tab === 'detailed'})}>
                        <a onclick={() => this.set_tab('detailed')}>{$t('my_discover_colleagues.detailed')}</a>
                    </li>
                </ul>

                <div className={classes('c-tab', {active: this.data.tab === 'basic'})}>
                    <StatusBasic/>
                </div>

                <div className={classes('c-tab', {active: this.data.tab === 'detailed'})}>
                    <StatusDetailed />
                </div>
            </div>

        </div>
    }
}
