export enum VoucherValueType {
    FIXED = 'FIXED',
    PERCENTAGE = 'PERCENTAGE',
}

export interface RelationVoucher {
    relation_artkey: number
}

export interface VoucherPromotion {
    artkey: number
    created_on: string
    was_last_updated_by_discover_user: {
        name: string
    }
    name: string
    start_date: string
    end_date: string
    voucher_value_type: VoucherValueType
    value: number
    code: string
    relation_vouchers: Array<RelationVoucher>
}
