import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money} from '@bitstillery/common/ts_utils'

import {default_chart_options_for, tooltip_label} from './chart_js_utils'

import {$s} from '@/app'
import {Chart} from '@/lib/chart'

interface HorizontalBarChartAttrs {
    data: number[]
}

/**
 * Draws a horizontal bar chart.
 */
export class HorizontalBarChart extends MithrilTsxComponent<HorizontalBarChartAttrs> {
    canvas: HTMLCanvasElement
    chart: Chart
    tooltip_as_money = false

    constructor(canvas_id: string, format_value_as_money: boolean, legend_visible: boolean) {
        super()
        const target = document.getElementById(canvas_id) as HTMLCanvasElement
        if (!target) {
            throw Error('Programmer error')
        }
        this.canvas = target
        this.tooltip_as_money = format_value_as_money
        const config = default_chart_options_for('bar')
        config.options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y',
            plugins: {
                tooltip: {
                    callbacks: {
                        label(context): string | string[] {
                            return tooltip_label(context, format_value_as_money)
                        },
                    },
                },
                legend: {
                    display: legend_visible,
                },
            },
            scales: {
                x: {
                    ticks: {
                        callback: (value: number | string) => {
                            return format_money(+value, 'EUR', $s.identity.user.decimal_locale)
                        },
                    },
                },
            },
        }
        this.chart = new Chart(this.canvas, config)
    }

    data(the_data: number[]): void {
        this.chart.data.datasets?.forEach((ds: any) => (ds.data = the_data))
        this.chart.update()
    }

    labels(the_data: string[]): void {
        this.chart.data.labels = the_data
        this.chart.update()
    }

    title(the_title: string | string[]): void {
        if (this.chart.options.plugins?.title) {
            this.chart.options.plugins.title.text = the_title
            this.chart.options.plugins.title.display = true
            this.chart.update()
        }
    }

    view(): null {
        return null // the graph writes directly to the canvas.
    }
}
