import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Gauge} from 'gaugeJS'
import {displayable_float, format_money_with_symbol} from '@bitstillery/common/ts_utils'
import {current_account_slug} from '@bitstillery/common/account/account'
import {classes} from '@bitstillery/common/lib/utils'

import {$s} from '@/app'
import {FigureWidget} from '@/components/box'
import {DashboardApi, YearSalesIndexesResponse} from '@/factserver_api/dashboard_api'

interface YearSalesIndexesAttrs {
    is_collapsed: boolean
    on_collapsed: (value: boolean) => unknown
}

export class YearSalesIndexesWidget extends MithrilTsxComponent<YearSalesIndexesAttrs> {
    static turnover_canvas_id = 'new-turnover-gauge'
    static margin_canvas_id = 'new-margin-gauge'
    dashboard_api = new DashboardApi()
    is_loading = false

    turnover_gauge: Gauge | null = null
    margin_gauge: Gauge | null = null
    indexes: YearSalesIndexesResponse | null = null

    oncreate(): void {
        this.fetch_indexes()
    }

    fetch_indexes(): void {
        if (this.is_loading) {
            return
        }
        this.is_loading = true
        this.dashboard_api.year_sales_indexes().subscribe({
            next: (response: YearSalesIndexesResponse) => {
                this.is_loading = false
                this.indexes = response
                if (this.turnover_gauge) {
                    this.turnover_gauge.set(+response.turnover.index)
                }
                if (this.margin_gauge) {
                    this.margin_gauge.set(+response.margin.index)
                }
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    default_gauge(element: HTMLCanvasElement): Gauge {
        const gauge = new Gauge(element)
        gauge.setOptions({
            angle: 0,
            lineWidth: 0.15,
            radiusScale: 1,
            pointer: {
                length: 0.5,
                strokeWidth: 0.035,
                color: '#000000',
            },
            limitMax: false,
            limitMin: false,
            strokeColor: '#E0E0E0',
            generateGradient: true,
            highDpiSupport: true,
            colorStart: '#6FADCF',
            colorStop: '#C0C0CB',
            percentColors: [
                [0.0, '#DD0000'],
                [0.4, '#DD0000'],
                [0.5, '#FFB50A'],
                [0.68, '#FFE603'],
                [0.78, '#a9d70b'],
                [0.82, '#4CBB17'],
            ],
            staticLabels: {
                font: '10px sans-serif',
                labels: [100],
                color: '#000000',
                fractionDigits: 0,
            },
        })
        gauge.setMinValue(0)
        gauge.maxValue = 125
        gauge.animationSpeed = 24
        gauge.update()
        return gauge
    }

    create_turnover_gauge(): void {
        const canvas = document.getElementById(YearSalesIndexesWidget.turnover_canvas_id) as HTMLCanvasElement
        this.turnover_gauge = this.default_gauge(canvas)
        if (this.indexes) {
            this.turnover_gauge.set(+this.indexes.turnover.index)
        }
    }

    create_margin_gauge(): void {
        const canvas = document.getElementById(YearSalesIndexesWidget.margin_canvas_id) as HTMLCanvasElement
        this.margin_gauge = this.default_gauge(canvas)
        if (this.indexes) {
            this.margin_gauge.set(+this.indexes.margin.index)
        }
    }

    view(): m.Children {
        return (
            <div className={classes('c-widget-year-sales dashboard-widget', {
                loading: this.is_loading,
            })}>
                <div className="widget-title">
                    <span className="text">{`${current_account_slug().toUpperCase()} Sales Indexes`}</span>
                </div>
                <div className="widget-body">
                    <div
                        onclick={() => {
                            m.route.set('/sales-orders/revenue')
                        }}
                        className="c-widget-turnover-margin"
                    >
                        <div className="gauge">
                            <canvas
                                id={YearSalesIndexesWidget.turnover_canvas_id}
                                oncreate={() => this.create_turnover_gauge()}
                            />
                            <div className={'title sales'}>
                                Turnover:{' '}
                                {displayable_float(this.indexes?.turnover.index, 2, $s.identity.user.decimal_locale)}
                            </div>
                        </div>
                        <div className="gauge">
                            <canvas
                                id={YearSalesIndexesWidget.margin_canvas_id}
                                oncreate={() => this.create_margin_gauge()}
                            />
                            <div className={'title sales'}>
                                Margin:{' '}
                                {displayable_float(this.indexes?.margin.index, 2, $s.identity.user.decimal_locale)}
                            </div>
                        </div>
                    </div>

                    <div className="figures">
                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-screenshot'}
                            title={'this month target'}
                            link={'/sales-orders/targets}'}
                            figure={format_money_with_symbol(
                                this.indexes?.latest_month_targets.turnover || '0',
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`Target ${displayable_float(
                                this.indexes?.latest_month_targets.gross_margin_percentage,
                                1,
                                $s.identity.user.decimal_locale,
                            )}%`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-screenshot'}
                            title={'confirmed and saved'}
                            link={'/sales-orders/manage?sales_order_status=Saved or confirmed&user_artkey=&order_by=is_confirmed_on'}
                            figure={format_money_with_symbol(
                                this.indexes?.latest_month_confirmed_and_saved?.turnover_in_euro || '0',
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`Average: ${displayable_float(
                                this.indexes?.latest_month_confirmed_and_saved?.average_margin_percentage || '0',
                                1,
                                $s.identity.user.decimal_locale,
                            )}%`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-screenshot'}
                            title={'invoiced'}
                            link={'/sales-orders/manage?sales_order_status=Invoiced&user_artkey=&order_by=is_invoiced_on'}
                            figure={format_money_with_symbol(
                                this.indexes?.latest_month_invoiced?.turnover_in_euro || '0',
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`Average: ${displayable_float(
                                this.indexes?.latest_month_invoiced?.average_margin_percentage || '0',
                                1,
                                $s.identity.user.decimal_locale,
                            )}%`}
                        />
                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-screenshot'}
                            title={'margin'}
                            link={'/sales-orders/manage?sales_order_status=Invoiced&user_artkey=&order_by=is_invoiced_on'}
                            figure={format_money_with_symbol(
                                this.indexes?.latest_month_invoiced?.total_margin || '0',
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`Orders invoiced: ${this.indexes?.latest_month_invoiced?.count || 0}`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
