import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {ItemTagCollectionResponse} from '@/factserver_api/fact2server_api'

export default class ItemTagList extends MithrilTsxComponent<unknown> {
    item_tag_fetcher: PagedCollectionFetcherWithGET<ItemTagCollectionResponse>

    constructor() {
        super()
        this.item_tag_fetcher = new PagedCollectionFetcherWithGET('discover/data/item-tags/collection-view', 'name')
    }

    oncreate(): void {
        this.item_tag_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <div className="c-data-brands view">
                <button
                    class="btn btn-default"
                    type="button"
                    onclick={() => m.route.set('/data/item-tags/create')}
                >
                    <span class="glyphicon glyphicon-plus"></span>
                    {' '}
                    Create Item Tag
                </button>

                <CollectionTable<ItemTagCollectionResponse, void>
                    on_row_click={(row: ItemTagCollectionResponse) => m.route.set(`/data/item-tags/${row.artkey}/edit`)}
                    collection_fetcher={this.item_tag_fetcher}>
                    <CollectionTableColumn<ItemTagCollectionResponse>
                        header_title={() => 'Name'}
                        sort_name={'name'}
                        data_field={(row: ItemTagCollectionResponse) => row.name}
                    />
                    <CollectionTableColumn<ItemTagCollectionResponse>
                        header_title={() => 'Category'}
                        sort_name={'category_name'}
                        data_field={(row: ItemTagCollectionResponse) => row.category_name}
                    />
                    <CollectionTableColumn<ItemTagCollectionResponse>
                        header_title={() => 'Abbreviation'}
                        sort_name={'abbreviation'}
                        data_field={(row: ItemTagCollectionResponse) => row.abbreviation}
                    />
                </CollectionTable>
            </div>
        )
    }
}
