m = require 'mithril'
{filter} = require 'prelude-ls'

inputs = require '@/components/inputs'


module.exports = class Radiobutton
    (vnode) ~>
        @filter_function = vnode.attrs.filter_function
        @filter_name = vnode.attrs.filter_name
        @filter_options = vnode.attrs.filter_options

    view: ~>
        m '.c-filter-radiobutton field',
            m 'label' @filter_name
            inputs.radio((@filter_function), @filter_options.map((options) ~>
                {'value': options[0], 'description': options[1]}
            ))
