###
# NOTE: Deprecated for item_editor.
###
m = require 'mithril'
{map, sort-by, difference, pairs-to-obj, first} = require 'prelude-ls'
{a, with-default, update, maybe-map, to-int, to-string} = require 'utils.ls'
{deref} = require '@bitstillery/common/utils.ls'
{Country} = require '@bitstillery/common/components'
{MultiSelect} = require '@/components/inputs/multiselect.ls'
{RelationMultiSelectDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{CountriesMultiSelect, CountriesSelect, empty_option} = require '@/components/html_components'
inputs = require '@/components/inputs'
app = require('@/app')

# Args:
# item - the item to edit. Caution. This can be a Item (stock) or a purchase order item.
# options may contain:
# - hide_from_pricelist_for_countries_selected - list of selected country codes.
# - hide_from_pricelist_for_suppliers_selected - list of selected supplier artkeys.
# - not_editable_country_of_origin - country of origin field is not editable.
# - not_editable_hide_from_pricelist_for_countries
# - not_editable_hide_from_pricelist_for_countries
# - only_tags - show only tags.

# NOTE: Deprecated for item_editor.

export item-tags-editor = (item, options={}) ->
    item = deref item

    m '.c-item-tags-editor',
        app.$m.data.item_tag_category.all_categories_except_tax_label!
            |> sort-by (.name!)
            |> map (category) ->
                all_tags = app.$m.data.item_tag.get_all_from_category category
                all_tags_artkeys = all_tags |> map (.artkey!)

                # Compute an object of tags artkey -> name in the current category.
                all_tags_options = all_tags
                    |> sort-by (.name!)
                    |> map (tag) -> [tag.artkey!, tag.name!]

                if category.has_cardinality_one!
                    vprop = (new_value) ->
                        if new_value?
                            update item.item_tags, (tags) ->
                                # Remove all tags from the category and add the new ones.
                                new_tags = difference tags, all_tags_artkeys
                                if new_value then new_tags ++ (+new_value) else new_tags

                        item.item_tags_per_category!
                        |> (cats) -> cats[category.artkey!]
                        |> maybe-map first
                        |> maybe-map (.artkey!)

                    m '.field' a do
                        m 'label' category.name!
                        inputs.select vprop, all_tags_options, do
                            empty_option: true
                            disabled: options.disabled
                else
                    vprop = (new_values) ->
                        if new_values?
                            new_int_values = new_values |> map to-int

                            update item.item_tags, (tags) ->
                                # Remove all tags from the category and add the new ones.
                                (difference tags, all_tags_artkeys) ++ new_int_values

                        item.item_tags_per_category!
                        |> (cats) -> cats[category.artkey!]
                        |> with-default []
                        |> map (.artkey!)
                        |> map to-string

                    m '.field' a do
                        m 'label' category.name!
                        m MultiSelect, do
                            options: -> all_tags_options
                            selected: vprop
                            disabled: options.disabled


        if options.not_editable_hide_from_pricelist_for_countries and options.hide_from_pricelist_for_countries_selected
            m '' ''
        else if options.hide_from_pricelist_for_countries_selected
            m CountriesMultiSelect, {
                label: 'Hide on pricelist in',
                selected_country_codes: options.hide_from_pricelist_for_countries_selected!,
                onchange: (value) ~> options.hide_from_pricelist_for_countries_selected value
            }

        if options.not_editable_hide_from_pricelist_for_suppliers and options.hide_from_pricelist_for_suppliers_selected
            m '' ''
        else if options.hide_from_pricelist_for_suppliers_selected
            m RelationMultiSelectDropDown, {
                label: 'Hide on pricelist from',
                selected_relation_artkeys: options.hide_from_pricelist_for_suppliers_selected!
                onchange: (supplier_artkey) ~>
                    options.hide_from_pricelist_for_suppliers_selected supplier_artkey
                get_all_for_drop_down_response$: RelationDropDownData.relations()
            }

        if item.country_of_origin and options.not_editable_country_of_origin
            m '.field' a do
                m 'label' 'Country of origin'
                m Country,
                    country_code: deref item.country_of_origin
                    type: 'flag_with_country'

        else if item.country_of_origin
            m CountriesSelect, {
                label: 'Country of origin',
                required: true
                selected: item.country_of_origin!
                onchange: (value) -> item.country_of_origin(value)
            }
        if not options.only_tags then a do
            inputs.date item.best_before_date, {
                disabled: options.disabled,
                label: 'Best Before Date'
            }

            inputs.text item.remark, {
                disabled: options.disabled,
                label: 'Remark'
            }

        if options.edit_gtin then [
            inputs.text item.bottle_gtin_code, {
                disabled: options.disabled,
                label: 'Bottle GTIN'
            }

            inputs.text item.case_gtin_code, do
                disabled: options.disabled,
                label: 'Case GTIN'
        ]
