import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {download_binary_file_from_base64_str} from 'utils.ls'
import {current_account_slug} from '@bitstillery/common/account/account'
import {DateTime} from 'luxon'
import {Spinner} from '@bitstillery/common/components'

import {DropDownOption, DropDownWithSelect} from '@/components/html_components'
import {PrimaryButton} from '@/components/buttons'
import {NumberInput} from '@/components/input_numbers'
import {CBSApi} from '@/factserver_api/cbs_api'

const MONTHS = [
    {key: '1', name: 'January'},
    {key: '2', name: 'February'},
    {key: '3', name: 'March'},
    {key: '4', name: 'April'},
    {key: '5', name: 'May'},
    {key: '6', name: 'June'},
    {key: '7', name: 'July'},
    {key: '8', name: 'August'},
    {key: '9', name: 'September'},
    {key: '10', name: 'October'},
    {key: '11', name: 'November'},
    {key: '12', name: 'December'},
]

export default class CBSExport extends MithrilTsxComponent<unknown> {
    cbs_api = new CBSApi()
    last_month = DateTime.now().minus({month: 1})
    month = this.last_month.month
    year = this.last_month.year
    is_loading = false

    generate_export(): void {
        this.is_loading = true
        this.cbs_api.generate_cbs_export(+this.month, this.year).subscribe({
            next: (response) => {
                this.is_loading = false
                const filename = `CBS export ${current_account_slug().toUpperCase()} ${this.month}-${this.year}.txt`
                download_binary_file_from_base64_str(response.file_base64_encoded, filename)
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-cbs view">
                <form className="flex-form" onsubmit={() => this.generate_export()}>
                    <div className="fieldset">
                        <div className="field">
                            <label>Month</label>
                            <DropDownWithSelect
                                selected={this.month}
                                onchange={(value: string) => {
                                    this.month = +value
                                }}
                            >
                                {MONTHS.map((month) => (
                                    <DropDownOption value={month.key}>{month.name}</DropDownOption>
                                ))}
                            </DropDownWithSelect>
                        </div>

                        <div className="field">
                            <label>Year</label>
                            <NumberInput
                                value={this.year}
                                oninput={(value: number) => (this.year = value)}
                                minimum={2000}
                                maximum={2100}
                            />
                        </div>

                        <PrimaryButton type={'submit'}>
                            {this.is_loading && <span><Spinner /> Generating CBS export</span>}
                            {!this.is_loading && <span>Generate CBS export</span>}
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        )
    }
}
