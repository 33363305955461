import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {accountIconBySlug} from 'accounts.ls'
import {Amount, Button, Icon, Spinner} from '@bitstillery/common/components'
import {api} from '@bitstillery/common/app'
import {classes, object_to_query_string, strip_empty_values_nested_obj} from '@bitstillery/common/lib/utils.ts'

import {Link} from '../discover'

import {CaseInfoPanelAttrs} from './case_info_panel'

import {SalesApi, SalesOrderStatus} from '@/factserver_api/sales_api'
import {SalesOrderItemByBottle, BottleThroughputResponse} from '@/factserver_api/fact2server_api.ts'
import {BottleThroughput} from '@/components/market_info/bottle_throughput.tsx'

type BottleSalesOrdersAttrs = Pick<
    CaseInfoPanelAttrs,
    | 'bottle_artkey'
    | 'ignore_ref'
    | 'case_artkey'
    | 'up_is_up'
    | 'current_client_artkey'
    | 'case_customs_status'
    | 'with_excise'
    | 'bottle_mode'
>

export class BottleSalesOrders extends MithrilTsxComponent<BottleSalesOrdersAttrs> {
    sales_api = new SalesApi()
    is_loading = true
    sales_order_items: SalesOrderItemByBottle[] = []
    throughput: object | null = null
    total = 0
    offset = 0
    limit = 5
    attrs: BottleSalesOrdersAttrs

    constructor(vnode: m.Vnode<BottleSalesOrdersAttrs>) {
        super()
        this.attrs = vnode.attrs
        this.show_more()
    }

    get_title(): string {
        if (this.attrs.current_client_artkey) {
            return 'this client'
        }
        if (this.attrs.case_artkey) {
            return 'this case'
        }
        if (this.attrs.bottle_artkey) {
            if (this.attrs.ignore_ref) {
                return 'these specs (ignoring ref/non-ref)'
            }
            return 'these specs (size, alc %, ref)'
        }
        return 'this offer item'
    }

    show_more(): void {
        this._fetch_sales_order_items()
        this.offset += 5
    }

    async _fetch_sales_order_items() {
        this.is_loading = true
        const bottle_artkey = this.attrs.bottle_artkey
        const case_artkey = this.attrs.case_artkey
        const query_str = object_to_query_string(strip_empty_values_nested_obj({
            page_size: this.limit,
            offset: this.offset,
            sort_by: 'sales_order_created_on',
            filters: JSON.stringify({
                case_artkey: this.attrs.case_artkey,
                supplier_artkey: this.attrs.current_client_artkey,
                ignore_ref: this.attrs.ignore_ref,
            }),
        }))

        const quantity_sold_url = `discover/bottles/${bottle_artkey}/quantity-sold?ignore_ref=${this.attrs.ignore_ref ?? false}`
        const response = await Promise.all([
            api.get(`discover/bottles/${bottle_artkey}/sales-order-items/collection-view?${query_str}`),
            api.get(`${quantity_sold_url}${case_artkey ? `&case_artkey=${case_artkey}` : ''}`),
        ])

        const soi_response = response[0]
        const quantity_sold = response[1].result as BottleThroughputResponse

        this.total = soi_response.total ?? 0
        this.sales_order_items = this.sales_order_items.concat(soi_response.result as SalesOrderItemByBottle[])
        this.throughput = quantity_sold
        this.is_loading = false
        m.redraw()
    }

    _sales_order_status_display(sales_order_item: SalesOrderItemByBottle): m.Child {
        const sales_order_status = sales_order_item.sales_order_status
        if (sales_order_status === SalesOrderStatus.CONFIRMED) {
            return (
                <abbr title={format_iso_to_date(sales_order_item.sales_order_is_confirmed_on)}>
                    {sales_order_status}
                </abbr>
            )
        }
        if (sales_order_status === SalesOrderStatus.INVOICED) {
            return (
                <abbr title={format_iso_to_date(sales_order_item.sales_order_is_invoiced_on)}>
                    {sales_order_status}
                </abbr>
            )
        }

        return (
            <abbr title={format_iso_to_date(sales_order_item.sales_order_created_on)}>
                {sales_order_status}
            </abbr>
        )
    }

    view(): m.Children {
        const title = this.get_title()
        return (
            <div className="c-bottle-sales-orders collection-widget">
                <div className="header">
                    <div className="title">
                        <div className={'header'}>
                            <Icon name="cart" type="info" />
                            Latest sales orders for {title}
                        </div>
                        {this.throughput && (<BottleThroughput prefix='Sold' type="Long" throughput={this.throughput} />)}
                    </div>
                </div>
                <div className="content">
                    {this.sales_order_items.length === 0 ? (
                        <div class="c-empty-message">{this.is_loading ? '' : 'Not in any sales order.'}</div>
                    ) : (
                        <div>
                            <table className={'table table-condensed'}>
                                <thead className="thead-default">
                                    <tr>
                                        <th>#</th>
                                        <th>Ref</th>
                                        <th>GB</th>
                                        <th>Tax</th>
                                        <th className={'price'}>
                                        € / cs
                                            {this.attrs.with_excise && <small> incl. excise</small>}
                                        </th>
                                        {this.attrs.bottle_mode && (
                                            <th className={'price'}>
                                            € / btl
                                                {this.attrs.with_excise && <small> incl. excise</small>}
                                            </th>
                                        )}
                                        <th>Status</th>
                                        <th>Last update</th>
                                        <th>Handled by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.sales_order_items.map((sales_order_item) => (
                                        <tr>
                                            <td>
                                                <div className={classes('td-group')}>
                                                    <span className={'header'}>
                                                        {accountIconBySlug(
                                                            sales_order_item.account_slug,
                                                            sales_order_item.account_name,
                                                        )}
                                                        <Link
                                                            target="_blank"
                                                            href={`/sales-orders/manage/${sales_order_item.sales_order_artkey}?account=${sales_order_item.account_slug}`}
                                                        >
                                                            {sales_order_item.sales_order_reference}
                                                        </Link>

                                                    </span>
                                                    <span className={'details'}>
                                                        <div>
                                                            {sales_order_item.supplier_name}
                                                        </div>

                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={classes('td-group')}>
                                                    <span className={'header'}>
                                                        {sales_order_item.number_of_cases} cs
                                                    </span>
                                                    <span className={'details'}>
                                                        {sales_order_item.case_number_of_bottles} / {sales_order_item.bottle_refill_status} / {sales_order_item.case_customs_status}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="ellipsis">{sales_order_item.case_gift_box_type}</td>
                                            <td>{sales_order_item.case_tax_label}</td>
                                            <td className={'price'}>
                                                <Amount
                                                    amount={sales_order_item.price_per_case_excl_excise}
                                                    excise={
                                                        sales_order_item.excise_per_case ||
                                                        sales_order_item.case_excise_nl
                                                    }
                                                    currency={sales_order_item.sales_order_was_sold_in}
                                                    rate={+sales_order_item.sales_order_sold_against_rate}
                                                    display_currency={sales_order_item.sales_order_was_sold_in}
                                                    force_excise={this.attrs.with_excise}
                                                />
                                            </td>
                                            {this.attrs.bottle_mode && (
                                                <td className={'price'}>
                                                    <Amount
                                                        amount={sales_order_item.price_per_bottle_excl_excise}
                                                        excise={
                                                            sales_order_item.excise_per_bottle ||
                                                            sales_order_item.bottle_excise_nl
                                                        }
                                                        currency={sales_order_item.sales_order_was_sold_in}
                                                        rate={+sales_order_item.sales_order_sold_against_rate}
                                                        display_currency={sales_order_item.sales_order_was_sold_in}
                                                        force_excise={this.attrs.with_excise}
                                                    />
                                                </td>
                                            )}
                                            <td>{sales_order_item.sales_order_status}</td>
                                            <td>{format_iso_to_date(sales_order_item.sales_order_latest_status_update)}</td>
                                            <td>{sales_order_item.profile_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {this.sales_order_items.length < this.total && !this.is_loading && (
                                <Button
                                    className="btn-show-more"
                                    icon="chevronDown"
                                    onclick={() => this.show_more()}
                                    size="s"
                                    text="Show more results"
                                />
                            )}
                        </div>
                    )}
                    {this.is_loading && <Spinner />}
                </div>
            </div>
        )
    }
}
