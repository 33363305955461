import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {ProcessStepper} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

export class ProcessManageOffer extends MithrilTsxComponent<unknown> {

    constructor(vn) {
        super()
        this.data = proxy({
            active: vn.attrs.active,
            current: 0,
        })
    }

    data = proxy({
        current: 0,
    })

    path(vn, slug) {
        if (slug === 'email_recipients') {
            return `/offer/offers/${vn.attrs.context.offer_artkey}/email/${vn.attrs.context.email_batch_artkey}/recipient-selection`
        } else if (slug === 'email_send') {
            return `/offer/offers/${vn.attrs.context.offer_artkey}/email/${vn.attrs.context.email_batch_artkey}/email-send`
        } else if (slug === 'email_content') {
            if (vn.attrs.context.email_batch_artkey) {
                return `/offer/offers/${vn.attrs.context.offer_artkey}/email/${vn.attrs.context.email_batch_artkey}/edit`
            }
            return `/offer/offers/${vn.attrs.context.offer_artkey}/email/create`
        }

        let path = `/offer/offers/${vn.attrs.context.offer_artkey}/${slug}`
        if (vn.attrs.context.email_batch_artkey) {
            path += `/?email_batch=${vn.attrs.context.email_batch_artkey}`
        }
        return path
    }

    view(vn:m.Vnode<any>): m.Children {
        return <ProcessStepper
            data={this.data}
            process={[
                {
                    completed: this.data.current > 0,
                    id: 'list',
                    path: '/offer/offers',
                    text: $t('processes.manage_offer.list'),
                },
                {
                    completed: this.data.current > 1,
                    id: 'details',
                    path: vn.attrs.context.offer_artkey ? `/offer/offers/${vn.attrs.context.offer_artkey}/details` : '/offer/offers/create',
                    text: vn.attrs.context.offer_artkey ? $t('processes.manage_offer.edit_offer', {title: vn.attrs.context.offer_title}) : $t('processes.manage_offer.create_offer'),
                },
                {
                    completed: this.data.current > 2,
                    disabled: !vn.attrs.context.offer_artkey,
                    id: 'relations',
                    path: this.path(vn, 'relations'),
                    text: $t('processes.manage_offer.relations'),
                },
                {
                    completed: this.data.current > 3,
                    disabled: !vn.attrs.context.offer_artkey,
                    id: 'select_products',
                    path: this.path(vn, 'select_products'),
                    text: $t('processes.manage_offer.select_products'),
                },
                {
                    completed: this.data.current > 4,
                    disabled: !vn.attrs.context.offer_artkey,
                    id: 'custom_products',
                    path: this.path(vn, 'custom_products'),
                    text: $t('processes.manage_offer.custom_products'),
                },
                {
                    completed: this.data.current > 5,
                    disabled: !vn.attrs.context.offer_artkey,
                    id: 'email_content',
                    path: this.path(vn, 'email_content'),
                    text: vn.attrs.context.email_batch_artkey ? $t('processes.manage_offer.edit_email') : $t('processes.manage_offer.create_email'),
                },
                {
                    completed: this.data.current > 6,
                    disabled: !vn.attrs.context.offer_artkey || !vn.attrs.context.email_batch_artkey,
                    id: 'email_recipients',
                    path: this.path(vn, 'email_recipients'),
                    text: $t('processes.manage_offer.email_recipients'),
                },
                {
                    completed: m.route.param('key'),
                    disabled: !vn.attrs.context.offer_artkey || !vn.attrs.context.email_batch_artkey,
                    id: 'email_send',
                    path: this.path(vn, 'email_send'),
                    text: $t('processes.manage_offer.email_send'),
                },
            ]}
            variant="horizontal"
        />
    }
}
