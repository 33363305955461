import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Modal} from 'components/modal/modal.ls'
import {download_file} from 'lib/download_files.ls'
import {Observable} from 'rxjs'
import {DateTime} from 'luxon'
import {download_binary_file_from_base64_str} from 'utils.ls'
import {format_iso_to_date, format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {Button, ButtonGroup, Tippy} from '@bitstillery/common/components'
import {$t, notifier} from '@bitstillery/common/app'

import {DropDownOption, DropDownWithSelect, InputDate, TextInput} from './html_components'
import {FileDropArea} from './file_drop_area'

import {
    AttachmentApi,
    AttachmentCategory,
    AttachmentType,
    CategorizePurchaseOrderAttachmentRequest,
    GetAttachmentResponse,
} from '@/factserver_api/attachment_api'

interface Attachment {
    artkey: number
    category: string
    file_name: string
    description: string | null
    valid_until: string | null
}

interface CategorizeAttachmentAttrs {
    attachment_helper: AttachmentHelper
    attachment: Attachment
    onclose: () => void
}

/** Popup to update an attachment.*/
class CategorizeAttachment extends MithrilTsxComponent<CategorizeAttachmentAttrs> {
    attachment_api = new AttachmentApi()
    attachment_helper: AttachmentHelper

    description: string | null
    category: string | null
    valid_until: string | null

    constructor(vnode: m.Vnode<CategorizeAttachmentAttrs>) {
        super()
        this.description = vnode.attrs.attachment.description
        this.category = vnode.attrs.attachment.category
        this.valid_until = vnode.attrs.attachment.valid_until
            ? DateTime.fromISO(vnode.attrs.attachment.valid_until).toISODate()
            : ''

        this.attachment_helper = vnode.attrs.attachment_helper
    }

    category_options(): string[] {
        const options = this.attachment_helper.categories
        if (this.category !== null && !options.includes(this.category)) {
            options.push(this.category)
        }
        return options
    }

    submit_category(vnode: m.Vnode<CategorizeAttachmentAttrs>): void {
        this.attachment_api
            .categorize_attachment({
                attachment_artkey: vnode.attrs.attachment.artkey,
                description: this.description,
                category: this.category,
                attachment_type: this.attachment_helper.attachment_type,
                valid_until: this.valid_until,
            })
            .subscribe(() => {
                notifier.notify('Successfully categorized attachment.', 'success')
                vnode.attrs.attachment.description = this.description
                vnode.attrs.attachment.category = this.category || ''
                vnode.attrs.attachment.valid_until = this.valid_until
                vnode.attrs.onclose()
            })
    }

    view(vnode: m.Vnode<CategorizeAttachmentAttrs>): m.Children {
        return [
            <form>
                <div className="fieldset">
                    <div className="field-readonly">
                        <div className="key">File</div>
                        <div className="value">{vnode.attrs.attachment.file_name}</div>
                    </div>

                    <DropDownWithSelect
                        label="Category"
                        onchange={(value: string) => {
                            this.category = value
                        }}
                        empty_option={<DropDownOption value={''}>{''}</DropDownOption>}
                        selected={this.category}
                    >
                        {this.category_options().map((category) => (
                            <DropDownOption value={category}>{category}</DropDownOption>
                        ))}
                    </DropDownWithSelect>

                    <TextInput
                        label="Description"
                        placeholder={'Description...'}
                        value={this.description}
                        oninput={(value: string) => (this.description = value)}
                    />

                    {this.attachment_helper.attachment_type === AttachmentType.RELATION && (
                        <InputDate
                            label="Valid until"
                            value={this.valid_until}
                            onchange={(val: DateTime) => (this.valid_until = val ? val.toISODate() : null)}
                        />
                    )}
                </div>
            </form>,
            <div className="btn-toolbar">
                <ButtonGroup>
                    <Button
                        icon="cancel"
                        onclick={vnode.attrs.onclose}
                        text={$t('attachments.actions.category_cancel')}
                    />
                    <Button
                        icon="save"
                        onclick={() => this.submit_category(vnode)}
                        text={$t('attachments.actions.category_confirm')}
                        type="success"
                    />
                </ButtonGroup>
            </div>,
        ]
    }
}

export class AttachmentHelper {
    attachment_api = new AttachmentApi()
    attachment_type: AttachmentType
    artkey: number
    categories: string[]
    attachments: GetAttachmentResponse[] = []

    constructor(artkey: number, attachment_type: AttachmentType) {
        this.attachment_type = attachment_type
        this.artkey = artkey
        this.categories = []

        this.load_categories()
        this.load_attachments()
    }

    load_attachments(): void {
        let fetcher: Observable<GetAttachmentResponse[]>
        if (this.attachment_type === AttachmentType.WAREHOUSE) {
            fetcher = this.attachment_api.fetch_warehouse_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.SALES_ORDER) {
            fetcher = this.attachment_api.fetch_sales_order_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.ITEM_MUTATION) {
            fetcher = this.attachment_api.fetch_item_mutation_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.RELATION) {
            fetcher = this.attachment_api.fetch_relation_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.PURCHASE_ORDER) {
            fetcher = this.attachment_api.fetch_purchase_order_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.LEAD) {
            fetcher = this.attachment_api.fetch_lead_attachments(this.artkey)
        } else {
            throw Error('Unsupported attachment type')
        }

        fetcher.subscribe({
            next: (result: GetAttachmentResponse[]) => {
                this.attachments = result
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    load_categories(): void {
        this.attachment_api.get_all_attachment_categories().subscribe((response: AttachmentCategory[]) => {
            for (const category of response) {
                if (category.attachment_type === this.attachment_type || (category.attachment_type === AttachmentType.RELATION && this.attachment_type === AttachmentType.LEAD)) {
                    // Select categories that match the type, and for leads also select relation types.
                    this.categories.push(category.name)
                }
            }
        })
    }

    delete_attachment(attachment_artkey: number): void {
        this.attachment_api
            .delete_attachment({
                attachment_artkey: attachment_artkey,
                attachment_type: this.attachment_type,
            })
            .subscribe(() => this.load_attachments())
    }

    download_all_attachments(): void {
        let fetcher: Observable<string>
        if (this.attachment_type === AttachmentType.WAREHOUSE) {
            fetcher = this.attachment_api.download_all_warehouse_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.SALES_ORDER) {
            fetcher = this.attachment_api.download_all_sales_order_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.ITEM_MUTATION) {
            fetcher = this.attachment_api.download_all_item_mutation_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.RELATION) {
            fetcher = this.attachment_api.download_all_relation_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.PURCHASE_ORDER) {
            fetcher = this.attachment_api.download_all_purchase_order_attachments(this.artkey)
        } else if (this.attachment_type === AttachmentType.LEAD) {
            fetcher = this.attachment_api.download_all_lead_attachments(this.artkey)
        } else {
            throw Error('Unsupported attachment type')
        }

        fetcher.subscribe((response: string) => {
            const date = DateTime.now().toISODate()
            download_binary_file_from_base64_str(response, `files-${this.artkey}-${date}.zip`)
        })
    }

    upload_attachment(base64_data: string, file_name: string): Observable<GetAttachmentResponse> {
        let initial_data = {}
        if (this.attachment_type === AttachmentType.WAREHOUSE) {
            initial_data = {
                warehouse_artkey: this.artkey,
            }
        } else if (this.attachment_type === AttachmentType.SALES_ORDER) {
            initial_data = {
                sales_order_artkey: this.artkey,
            }
        } else if (this.attachment_type === AttachmentType.ITEM_MUTATION) {
            initial_data = {
                item_mutation_artkey: this.artkey,
            }
        } else if (this.attachment_type === AttachmentType.RELATION) {
            initial_data = {
                supplier_artkey: this.artkey,
            }
        } else if (this.attachment_type === AttachmentType.PURCHASE_ORDER) {
            initial_data = {
                purchase_order_artkey: this.artkey,
            }
        } else if (this.attachment_type === AttachmentType.LEAD) {
            initial_data = {
                lead_artkey: this.artkey,
            }
        } else {
            throw Error('Unsupported attachment type')
        }

        return this.attachment_api.upload_attachment({
            ...initial_data,
            file: base64_data,
            file_name: file_name,
            attachment_type: this.attachment_type,
        })
    }

    categorize_attachment(request: CategorizePurchaseOrderAttachmentRequest) {
        return this.attachment_api.categorize_attachment(request)
    }
}

export interface AttachmentListProps {
    attachment_helper: AttachmentHelper
}

/**
 * Show an attachment list for a certain entity.
 */
export class AttachmentList extends MithrilTsxComponent<AttachmentListProps> {
    attachment_to_categorize: GetAttachmentResponse | null = null
    attachment_helper: AttachmentHelper

    constructor(vnode: m.Vnode<AttachmentListProps>) {
        super()
        this.attachment_helper = vnode.attrs.attachment_helper
    }

    close_category_modal(): void {
        this.attachment_to_categorize = null
        this.attachment_helper.load_attachments()
    }

    view(): m.Children {
        return (
            <span className={'c-attachment-list'}>
                {this.attachment_to_categorize && (
                    <Modal title={'Categorize attachment'} onclose={() => this.close_category_modal()}>
                        <CategorizeAttachment
                            attachment_helper={this.attachment_helper}
                            attachment={this.attachment_to_categorize}
                            onclose={() => this.close_category_modal()}
                        />
                    </Modal>
                )}
                <div className={'btn-toolbar'}>
                    <Button
                        disabled={!this.attachment_helper.attachments.length}
                        icon="download"
                        onclick={() => this.attachment_helper.download_all_attachments()}
                        text={$t('attachments.actions.download_all')}
                        type="info"
                    /></div>
                <table className={'table'}>
                    <thead className={'thead-default'}>
                        <tr>
                            <th>Filename</th>
                            <th>Created on</th>
                            {this.attachment_helper.attachment_type === AttachmentType.RELATION && <th>Valid until</th>}
                            <th>Uploaded by</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody class={'table-row'}>
                        {this.attachment_helper.attachments.map((row) => (
                            <tr>
                                <td>
                                    <button
                                        className={'btn btn-default'}
                                        type={'button'}
                                        onclick={() => download_file(row, 'attachment')}
                                    >
                                        <i className="fa fa-download"/>
                                        {row.file_name}
                                    </button>
                                </td>
                                <td>{format_iso_to_date_time(row.created_on)}</td>
                                {this.attachment_helper.attachment_type === AttachmentType.RELATION &&
                                    <td>
                                        {row.valid_until && (
                                            <div>
                                                {format_iso_to_date(row.valid_until) + ' '}
                                                {DateTime.now() > DateTime.fromISO(row.valid_until) && (
                                                    <Tippy content={'Attachment longer valid'}>
                                                        <span
                                                            className={'glyphicon glyphicon-warning-sign'}
                                                            style={'color: red'}
                                                        />
                                                    </Tippy>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                }
                                <td>{row.creator.profile.name}</td>
                                <td>{row.description}</td>
                                <td>
                                    {row.category}
                                </td>
                                <td className="actions">
                                    <div className="actions-group">
                                        <ButtonGroup>
                                            <Button
                                                icon="edit"
                                                onclick={() => (this.attachment_to_categorize = row)}
                                                tip={$t('attachments.tips.edit_category')}
                                            />
                                            <Button
                                                onclick={() => this.attachment_helper.delete_attachment(row.artkey)}
                                                icon="trash"
                                                tip={$t('attachments.tips.delete_attachment')}
                                                type="danger"
                                            />
                                        </ButtonGroup>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.attachment_helper.attachments.length === 0 && (
                    <span className={'glyphicon glyphicon-folder-open'} />
                )}
            </span>
        )
    }
}

export class UploadAttachment extends MithrilTsxComponent<AttachmentListProps> {
    attachment_helper: AttachmentHelper
    attachment_to_categorize: GetAttachmentResponse[] = []

    constructor(vnode: m.Vnode<AttachmentListProps>) {
        super()
        this.attachment_helper = vnode.attrs.attachment_helper
    }

    upload_file(file: File, contents: string): void {
        this.attachment_helper.upload_attachment(contents, file.name).subscribe({
            next: (response: GetAttachmentResponse) => {
                notifier.notify(`Successfully uploaded ${file.name}`, 'success')
                this.attachment_to_categorize.push(response)
                m.redraw()
            },
            error: () => m.redraw(),
        })
    }

    close_category_modal(): void {
        this.attachment_to_categorize = this.attachment_to_categorize.slice(1)
        this.attachment_helper.load_attachments()
    }

    view(): m.Children {
        return [
            <FileDropArea onupload={(file: File, contents: string) => this.upload_file(file, contents)} />,
            this.attachment_to_categorize.length > 0 ? <Modal title={'Categorize attachment'} onclose={() => this.close_category_modal()}>
                <CategorizeAttachment
                    attachment_helper={this.attachment_helper}
                    attachment={this.attachment_to_categorize[0]}
                    onclose={() => this.close_category_modal()}
                />
            </Modal> : null,
        ]
    }
}
