{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'


module.exports = class BlacklistedProductList
    ->
        is_match = (product_map, term) ~>
            return or-list [product_map.key.toLowerCase!indexOf(term) > -1]
        columns = [{'key': 'key', 'label': 'Name'}]
        @args = {
            readable_entity_name: 'Blacklisted product'
            base_url: '/data/product/blacklisted/'
            list_api_call: 'product_management.get_blacklisted_products'
            is_match: is_match
            columns: columns
            nocreate: true
            nodetail: true
            parent_name: 'Products',
            parent_url: '/data/products/',
        }

    view: ->
        m GenericList, @args
