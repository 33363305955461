import {Observable} from 'rxjs'

import {Api} from './api'

export interface FuelPrice {
    surcharge: number
}

export interface FuelPriceSurchargeResponse {
    warning_message: string
    fuel_price_data: FuelPrice
}

export class FuelApi {
    api = new Api()

    get_diesel_surcharge(): Observable<FuelPriceSurchargeResponse> {
        return this.api.post_request('fuel.get_diesel_surcharge', {})
    }
}
