import m from 'mithril'
import {format_iso_to_date, format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Tabs, Tab, TabPanel, Tippy} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'

import {UserDropDown} from '../components/users'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {EmailBatchStatus, EmailBatchType, GetEmailBatchCollectionViewResponse} from '@/factserver_api/fact2server_api'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {DefaultButton} from '@/components/buttons'
import {DropDownOption, DropDownWithSelect} from '@/components/html_components'
import {empty_option} from '@/components/html_components'
import {EmailBounces} from '@/email/bounces'

export default class EmailBatchList extends MithrilTsxComponent<unknown> {
    search_bar_controller: SearchBarControl | null = null
    is_loading = false
    email_batches_fetcher = new PagedCollectionFetcherWithGET<GetEmailBatchCollectionViewResponse>(
        'discover/email/batches/collection-view',
        'created_on',
        undefined,
        30,
        false,
    )

    data = (() => {
        return proxy({
            tabs: {
                active: m.route.param('tabId') ? m.route.param('tabId') : 'mailings',
            },
        })
    })()

    view(): m.Children {
        return <div className="c-email-batches">
            <Tabs
                data={this.data}
                type="nested-view"
            >
                <Tab
                    className="c-email-batches"
                    icon="account-group-outline"
                    id="mailings"
                    link={'/crm/email?tabId=mailings'}
                    text="Mailings"
                >
                    <TabPanel className="view">
                        <div className="btn-toolbar">
                            <DefaultButton
                                title={'Create new batch'}
                                icon_class={'fas fa-plus'}
                                onclick={() => m.route.set('/crm/email/create')}
                            />
                        </div>
                        <div className="c-filter-group">
                            <SearchBar
                                label={'Search'}
                                placeholder={'Search for email batches...'}
                                on_submit={(text) => this.email_batches_fetcher.set_search_terms(text)}
                                default_search_text={this.email_batches_fetcher.search_text()}
                                search_bar_controller={(controller: SearchBarControl) =>
                                    (this.search_bar_controller = controller)
                                }
                            />
                            <DropDownWithSelect
                                label="Type"
                                selected={this.email_batches_fetcher.filters['email_batch_type'] as string}
                                onchange={(value: string) => {
                                    this.email_batches_fetcher.filters['email_batch_type'] = value
                                    this.email_batches_fetcher.reset_and_query()
                                }}
                                empty_option={empty_option('All')}
                            >
                                <DropDownOption value={EmailBatchType.ENTIRE_PRICELIST}>Entire pricelist</DropDownOption>
                                <DropDownOption value={EmailBatchType.SPECIAL_ONLY}>Special only</DropDownOption>
                                <DropDownOption value={EmailBatchType.CUSTOM_OFFER}>Custom offer</DropDownOption>
                                <DropDownOption value={EmailBatchType.NOTHING}>Nothing</DropDownOption>
                                <DropDownOption value={EmailBatchType.PURCHASE_ENQUIRY}>Purchase inquiry</DropDownOption>
                            </DropDownWithSelect>
                            <DropDownWithSelect
                                label="Status"
                                selected={this.email_batches_fetcher.filters['status'] as string}
                                onchange={(value: string) => {
                                    this.email_batches_fetcher.filters['status'] = value
                                    this.email_batches_fetcher.reset_and_query()
                                }}
                                empty_option={empty_option('All')}
                            >
                                <DropDownOption value={EmailBatchStatus.UnderConstruction}>Under construction</DropDownOption>
                                <DropDownOption value={EmailBatchStatus.WaitingForNextRun}>Waiting for next run</DropDownOption>
                                <DropDownOption value={EmailBatchStatus.Preparing}>Preparing</DropDownOption>
                                <DropDownOption value={EmailBatchStatus.Sending}>Sending</DropDownOption>
                                <DropDownOption value={EmailBatchStatus.Sent}>Sent</DropDownOption>
                            </DropDownWithSelect>
                            <UserDropDown
                                label={'Created by'}
                                onchange={(user_artkey: string) => {
                                    this.email_batches_fetcher.filters['created_by_artkey'] = user_artkey
                                    this.email_batches_fetcher.reset_and_query()
                                }}
                                selected_user_artkey={this.email_batches_fetcher.filters['created_by_artkey']}
                            />
                        </div>

                        <CollectionTable<GetEmailBatchCollectionViewResponse, void>
                            on_row_click={(row: GetEmailBatchCollectionViewResponse) => m.route.set(`/crm/email/${row.artkey}`)}
                            collection_fetcher={this.email_batches_fetcher}
                        >
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Created on'}
                                sort_name={'created_on'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => format_iso_to_date(row.created_on)}
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Subject'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => row.subject}
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Type'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => {
                                    if (row.email_batch_type === EmailBatchType.CUSTOM_OFFER) {
                                        return 'Custom offer'
                                    }
                                    else if (row.email_batch_type === EmailBatchType.NOTHING) {
                                        return 'Nothing'
                                    }
                                    else if (row.email_batch_type === EmailBatchType.ENTIRE_PRICELIST) {
                                        return 'Entire pricelist'
                                    }
                                    else if (row.email_batch_type === EmailBatchType.SPECIAL_ONLY) {
                                        return 'Specials only'
                                    }
                                    else if (row.email_batch_type === EmailBatchType.PURCHASE_ENQUIRY) {
                                        return 'Purchase enquiry'
                                    }
                                }
                                }
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Created by'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => row.created_by_name}
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Next run'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => format_iso_to_date_time(row.next_run)}
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Dispatched on'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => format_iso_to_date_time(row.dispatched_on)}
                            />
                            <CollectionTableColumn<GetEmailBatchCollectionViewResponse>
                                header_title={() => 'Status'}
                                data_field={(row: GetEmailBatchCollectionViewResponse) => {
                                    const fields: m.Children = []
                                    fields.push(<div className={'help-block'}>
                                        <div>Total {row.number_of_mails} emails of which {row.number_of_unsent_mails} left to sent</div>
                                        <div>Scheduled: {row.is_pending ? 'Yes' : 'No'}</div>
                                    </div>)
                                    if (row.status === EmailBatchStatus.Preparing) {
                                        fields.push(<Tippy content={'Emails are created by the system'}>
                                            <span className={'glyphicon glyphicon-option-horizontal'} />
                                        </Tippy>)
                                    }
                                    else if (row.is_pending) {
                                        fields.push(<Tippy content={'The system is waiting for the next run'}>
                                            <span className={'glyphicon glyphicon-flash'} />
                                        </Tippy>)
                                    }
                                    else if (row.status === EmailBatchStatus.Sent) {
                                        fields.push(<Tippy content={'All emails are sent'}>
                                            <span className={'glyphicon glyphicon-ok-circle'} />
                                        </Tippy>)
                                    }
                                    else if (row.status === EmailBatchStatus.Sending) {
                                        fields.push(<Tippy content={'Emails are being sent'}>
                                            <span className={'glyphicon glyphicon-refresh'} />
                                        </Tippy>)
                                    } else {
                                        fields.push(<Tippy content={'The email is under construction'}>
                                            <span className={'glyphicon glyphicon-asterisk'} />
                                        </Tippy>)
                                    }
                                    return fields
                                }
                                }
                            />
                        </CollectionTable>
                    </TabPanel>
                </Tab>

                <Tab
                    icon="warning"
                    id="bounces"
                    link={'/crm/email?tabId=bounces'}
                    text="Bounces"
                >
                    <TabPanel>
                        <EmailBounces />
                    </TabPanel>
                </Tab>
            </Tabs>
        </div>
    }
}
