
m = require 'mithril'
{map} = require 'prelude-ls'
{classes} = require '@bitstillery/common/lib/utils'
{prevent-default} = require 'utils.ls'
confirmation = require '@/components/confirmation.ls'
api = require 'api.ls'
app = require('@/app')

module.exports = class GenericEdit
    (vnode) ->
        @entity = window.prop vnode.attrs.create_entity_func!
        @user = window.prop []

        @unique_name = vnode.attrs.unique_name
        @create_entity_func = vnode.attrs.create_entity_func
        @readable_entity_name = window.prop vnode.attrs.readable_entity_name
        @base_url = window.prop vnode.attrs.base_url
        @back_url = window.prop (vnode.attrs.back_url or vnode.attrs.base_url)
        @broadcast_on_update = window.prop vnode.attrs.broadcast_on_update or ''
        @update_or_create_api_call = window.prop vnode.attrs.update_or_create_api_call
        @fields = window.prop vnode.attrs.fields
        @additional_params = vnode.attrs.additional_params or (prop {})
        @can_deactivate = window.prop (vnode.attrs.can_deactivate or vnode.attrs.deactivate_api_call?)
        @deactivate_api_call = window.prop vnode.attrs.deactivate_api_call

        @noedit = vnode.attrs.noedit
        @create = window.prop false

        artkey = m.route.param 'artkey'
        if artkey
            @query_entity artkey, vnode.attrs.query_api_call
        else # We are creating a new one
            @create true

    query_entity: (artkey, query_api_call) ~>
        data = @additional_params!
        data.artkey = artkey
        api.call query_api_call, data, @set_entity

    set_entity: (resp) ~>
        if resp.success
            @entity @create_entity_func resp.result
        else
            app.notifier.notify "Unknown #{@readable_entity_name!}.", 'danger'
            m.route.set @base_url!

    save: ~>
        data = @additional_params!
        data.artkey = @entity!artkey!

        for field in @fields!
            value = @entity![field.label]!
            if field.type == 'number'
                value = +value
            data[field.label] = value

        api.call @update_or_create_api_call!, data, @handle_save

    handle_save: (result) ~>
        if not result.success
            if result.message
                app.notifier.notify result.message, 'danger'
            else
                app.$m.common.generic_error_handler!
        else
            if @broadcast_on_update!
                app.$m.common.observable.broadcast @broadcast_on_update!, ''
            if @create!
                app.notifier.notify "Successfully created new #{@readable_entity_name!}.", 'success'
                if not @noedit
                then m.route.set "#{@base_url!}#{result.artkey}/edit", {key: 'edit'}
                else m.route.set "#{@base_url!}"
            else
                app.notifier.notify "Successfully updated #{@readable_entity_name!}.", 'success'

    deactivate: ~>
        if @deactivate_api_call!?
            confirmation.show do
                title: "Deactivate #{@readable_entity_name!}"
                message: "Are you sure you want to delete this #{@readable_entity_name!}?"
                unique_name: "#{@unique_name}_deactivate_confirm"
                onconfirm: ~>
                    data = @additional_params!
                    data.artkey = @entity!artkey!
                    api.call @deactivate_api_call!, data, @handle_deactivate
        else
            m.route.set "#{@base_url!}/#{@entity!artkey!}/deactivate"

    handle_deactivate: (result) ~>
        if not result.success
            if result.message
                app.notifier.notify result.message, 'danger'
            else
                app.$m.common.generic_error_handler!
        else
            app.notifier.notify "Successfully deleted #{@readable_entity_name!}.", 'success'
            if @broadcast_on_update!
                app.$m.common.observable.broadcast @broadcast_on_update!, ''
            m.route.set @back_url!

    button_text: ->
        if this.readable_entity_name then
            if @create! then
                return "Create #{this.readable_entity_name!}"
            else
                return "Update #{this.readable_entity_name!}"
        else
            if @create! then
                return "Create"
            else
                return "Update"

    view: (vnode) ->
        m classes('.c-generic-edit view', vnode.attrs.className),
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set @back_url!},
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                if @can_deactivate! and not @create!
                    m 'button.btn.btn-danger' do
                        type: 'button'
                        onclick: @deactivate.bind(@)
                    , (m 'span.glyphicon.glyphicon-trash'), ' Deactivate'

            m 'form.flex-form' {onsubmit: prevent-default @save},
                m '.fieldset',
                    @fields! |> map (field) ~>
                        field.view @entity!

                m 'button.btn.btn-success.btn-submit' this.button_text()

