import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {Country} from '@bitstillery/common/components/ui/country/country'

import {NumberInput} from '@/components/input_numbers'
import {
    DamagesDropDown,
    CustomsStatusDropDown,
    GeneralDropDown,
    GiftBoxTypeDropDown, PackagingLabelDropDown,
    PackSizeDropDown,
    TaxLabelDropDown,
} from '@/components/case_inputs'
import {MoneyInput} from '@/components/decimal_input'
import {CountriesSelect, InputDate, TextArea, TextInput} from '@/components/html_components'
import {CancelButton, SaveButton} from '@/components/buttons'
import {GetPurchaseOrderItemsCollectionViewResponse} from '@/factserver_api/fact2server_api'

interface EditPurchaseOrderItemAttrs {
    purchase_order_item: GetPurchaseOrderItemsCollectionViewResponse
    onsave: (row: GetPurchaseOrderItemsCollectionViewResponse) => unknown
    oncancel: (row: GetPurchaseOrderItemsCollectionViewResponse) => unknown
}

export class EditPurchaseOrderItem extends MithrilTsxComponent<EditPurchaseOrderItemAttrs> {

    view(vnode: m.Vnode<EditPurchaseOrderItemAttrs>): m.Children {
        const purchase_order_item = vnode.attrs.purchase_order_item
        return (<div className={'c-purchase-order-item-edit'}>
            <h3>Edit {purchase_order_item.product_name} {to_specs(purchase_order_item, 'en')}</h3>
            <form className={'flex-form'}>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <div className={'fieldset-label'}>Purchase order item</div>
                        <NumberInput
                            label={'Bottles per case'}
                            value={purchase_order_item.number_of_bottles}
                            oninput={(val) => purchase_order_item.number_of_bottles = val}
                        />
                        <NumberInput
                            label={'Number of cases'}
                            value={purchase_order_item.number_of_cases}
                            oninput={(val) => purchase_order_item.number_of_cases = val}
                        />
                        <CustomsStatusDropDown
                            label={'Customs status'}
                            selected={purchase_order_item.customs_status}
                            onchange={(val) => purchase_order_item.customs_status = val}
                        />
                        <GiftBoxTypeDropDown
                            label={'Gift box'}
                            onchange={(val) => purchase_order_item.gift_box_type = val}
                            selected={purchase_order_item.gift_box_type}
                        />
                        <MoneyInput
                            label={'Net price per case'}
                            currency={purchase_order_item.was_bought_in}
                            value={purchase_order_item.was_bought_for}
                            on_value={(val) => purchase_order_item.was_bought_for = val}
                        />
                        <MoneyInput
                            label={'Suggested price per case'}
                            currency={purchase_order_item.was_bought_in}
                            value={purchase_order_item.suggested_price_per_case}
                            placeholder={'Suggested price per case'}
                            on_value={(val) => purchase_order_item.suggested_price_per_case = val}
                        />
                        <TextArea
                            value={purchase_order_item.remark}
                            oninput={(val) => purchase_order_item.remark = val}
                            label={'Remark'}
                        />
                        <TextInput
                            value={purchase_order_item.bottle_gtin_code}
                            oninput={(val) => purchase_order_item.bottle_gtin_code = val}
                            label={'Bottle GTIN'}
                        />
                        <TextInput
                            value={purchase_order_item.case_gtin_code}
                            oninput={(val) => purchase_order_item.case_gtin_code = val}
                            label={'Case GTIN'}
                        />
                    </div>
                </div>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <div className={'fieldset-label'}>Tags</div>
                        <TaxLabelDropDown
                            selected={purchase_order_item.tax_label}
                            label={'Tax label'}
                            onchange={(val) => purchase_order_item.tax_label = val}
                        />
                        <DamagesDropDown
                            selected={purchase_order_item.serialized_item_tags}
                            label={'Damages'}
                            onchange={(value: string) => purchase_order_item.serialized_item_tags = value}
                        />
                        <GeneralDropDown
                            selected={purchase_order_item.serialized_item_tags}
                            label={'General'}
                            onchange={(value: string) => purchase_order_item.serialized_item_tags = value}
                        />
                        <PackSizeDropDown
                            selected={purchase_order_item.serialized_item_tags}
                            label={'Pack size'}
                            onchange={(value: string) => purchase_order_item.serialized_item_tags = value}
                        />
                        <PackagingLabelDropDown
                            selected={purchase_order_item.serialized_item_tags}
                            label={'Packaging'}
                            onchange={(value: string) => purchase_order_item.serialized_item_tags = value}
                        />
                        <CountriesSelect
                            label={
                                <div>
                                    {purchase_order_item.country_of_origin &&
                                        <Country country_code={purchase_order_item.country_of_origin} size={'s'}>Country of origin</Country>}
                                    {!purchase_order_item.country_of_origin && 'Country of origin'}
                                </div>}
                            selected={purchase_order_item.country_of_origin}
                            onchange={(val) => purchase_order_item.country_of_origin = val}
                        />
                        <InputDate
                            label="Best Before Date"
                            value={purchase_order_item.best_before_date}
                            onchange={(value: DateTime) => {
                                purchase_order_item.best_before_date = value ? value.toISODate() : undefined
                            }}
                        />
                        <NumberInput
                            label={'Cases per pallet'}
                            value={purchase_order_item.cases_per_pallet}
                            oninput={(value: number) => purchase_order_item.cases_per_pallet = value}
                        />
                        <NumberInput
                            label={'Cases per pallet layer'}
                            value={purchase_order_item.cases_per_pallet_layer}
                            oninput={(value: number) => purchase_order_item.cases_per_pallet_layer = value}
                        />
                    </div>
                </div>
                <div className={'btn-group'}>
                    <SaveButton title={'Save'} onclick={() => vnode.attrs.onsave(vnode.attrs.purchase_order_item)}/>
                    <CancelButton title={'Cancel'} onclick={() => vnode.attrs.oncancel(vnode.attrs.purchase_order_item)}/>
                </div>
            </form>
        </div>)
    }
}
