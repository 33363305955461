m = require 'mithril'
{compact, join, map, min, Obj, or-list} = require 'prelude-ls'
require! 'utils.ls': {
    a, map-obj, format-date, format-date-time, format-percentage, match-term-in, maybe-map, on-set, assoc,
    dissoc, update, pluralize, download_binary_file_from_base64_str,
}
api = require 'api.ls'
{should_include_excise} = require 'excise.ls'
{Amount, SubAmount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{icon, icon-with-popover} = require '@/components/icon.ls'
{icon-button, button-with-icon, text-button} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Modal} = require '@/components/modal/modal.ls'

{AddToSalesOrder} = require 'offer/components/add_to_sales_order.ls'
{CaseInfoPanel} = require '@/components/market_info/case_info_panel'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
app = require('@/app')

module.exports = class OfferSpotlight
    ->
        @offer = new Collection do
            api_function_name: 'pricelist.get_offer_item_records'
            query_limit: 25
            sort_order: [
                {'name': 'case_number_of_bottles', 'direction': 'asc'}
                {'name': 'bottle_volume', 'direction': 'asc'}
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'}
                {'name': 'bottle_refill_status', 'direction': 'desc'}
            ]
            default_sort_by: 'spotlight_rank'
            default_sort_order: 'asc'
            filter_serverside: true
            additional_params: window.prop do
                only_show_type: ['purchase', 'stock', 'tbo']
                only_spotlight: true
            dont-reuse: true

        @add_order = window.prop null

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @offer.update_search_term text
        @offer.submit_search!

    oncreate: ->
        query = m.route.param 'q'
        if query then
            @search_input_ctrl.submit_search query.replace(/\+/g, ' ')
        else
            @offer.init!

        q = m.route.param 'q'
        if q
            @search_input_ctrl.submit_search q.replace(/\+/g, ' ')

        app.$m.common.observable.subscribe 'stock_updated', @, ~>@offer.requery!

    color_class: (value) ~>
        if value > 8.8
            '.analysis-good-color'
        else if value < 0
            '.analysis-bad-color'
        else
            ''

    handle_pricelist_result: (resp) ~>
        if resp.success
            download_binary_file_from_base64_str resp.result,
              'Offer ' + format-date(new Date) + '.xlsx'
        else
            app.$m.common.generic_error_handler!

    save_offer_item: (record, callback) ~>
        data = do
            artkey: record.artkey
            maximum_quantity: record.maximum_quantity!
            minimum_quantity: record.minimum_quantity!
            sales_price_per_case: record.price_per_case!
            delivery_period: record.delivery_period!
            is_hidden: record.is_hidden
            spot_price_per_case: record.spot_price!
            spotlight_valid_hours: record.spotlight_valid_hours!

        api.call2 'pricelist.update_offer_item', data, (resp) ->
            callback resp.spotlight_expiry_timestamp

    move_spotlight_item: (record, direction, callback) ~>
        data = do
            artkey: record.artkey
            direction: direction

        api.call 'spotlight.move_spotlight_item', data, (resp) ~>
            if resp.success then
                callback(resp.rank)
            else
                app.$m.common.generic_error_handler!

    delete_spotlight_item: (record, callback) ~>
        data = do
            artkey: record.artkey

        api.call 'spotlight.delete_spotlight_item', data, (resp) ~>
            if resp.success then
                callback!
            else
                app.$m.common.generic_error_handler!

    add_to_sales_order: (record) ~>
        @add_order record

    close-add-to-order: ~>
        @add_order null

    refresh-and-close-add-to-order: ~>
        @close-add-to-order!

    view: -> m '.c-pricelist-spotlight view offer',
        if @add_order! then
            modal_attrs = do
                title: "Add #{@add_order!product_name} to sales order"
                onclose: @close-add-to-order

            m Modal, modal_attrs,
                m AddToSalesOrder, do
                    offer_item: @add_order!
                    on_added_item: @refresh-and-close-add-to-order

        m '.c-filter-group' a do
            m SearchBar,
                placeholder: "Search products..."
                on_submit: @submit_search
                default_search_text: @offer.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

        @offer.show_counter!

        m CollectionTable, do
            collection: @offer
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: false
                autoscale: true
                unique_name: 'offer_item_list'
                row_classes: (record) ->
                    if record.is_hidden then ['offer-item__is-hidden']

            row_model: (record) ->
                # Extend the record row model with memory of the old
                # maximum quantity, minimum_quantity, delivery period and
                # target price. Also convert them to props, so we can use
                # them for inputs.
                record with
                    edit: window.prop false

                    maximum_quantity: window.prop record.maximum_quantity
                    minimum_quantity: window.prop record.minimum_quantity
                    delivery_period: window.prop record.delivery_period
                    price_per_case: window.prop record.price_per_case
                    spot_price: window.prop record.spot_price
                    spotlight_valid_hours: window.prop record.spotlight_valid_hours
                    spotlight_expiry_timestamp: window.prop record.spotlight_expiry_timestamp

                    old_maximum_quantity: record.maximum_quantity
                    old_minimum_quantity: record.minimum_quantity
                    old_delivery_period: record.delivery_period
                    old_price_per_case: record.price_per_case

            view_details: (record) ->
                m CaseInfoPanel, do
                    case_artkey: record.case_artkey
                    case_customs_status: record.case_customs_status
                    item_best_before_date: record.item_best_before_date
                    item_tags_sorted_artkeys: record.item_tags_sorted_artkeys
                    bottle_artkey: record.bottle_artkey
                    current_supplier_artkey: if record.offer_item_type == 'tbo' then record.supplier_artkey else null

            columns:
                do
                    width: 8
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click',
                            do
                                icon-button 'triangle-top', do
                                    class: 'btn-default no-click'
                                    onclick: ~>
                                        @move_spotlight_item record, "up", ~>
                                            @offer.requery!


                            do
                                icon-button 'triangle-bottom', do
                                    class: 'btn-default no-click'
                                    onclick: ~>
                                        @move_spotlight_item record, "down", ~>
                                            @offer.requery!
                do
                    width: 1
                    name: 'Spotlight rank'
                    field: 'spotlight_rank'
                    sort: true
                do
                    width: 8
                    name: 'Valid hours'
                    field: 'spotlight_valid_hours'
                    sort: true
                    classes: ['number']
                    function: (record) ->
                            if record.edit! then
                                inputs.number record.spotlight_valid_hours, {min: 1}
                            else if record.spotlight_valid_hours!? then
                                record.spotlight_valid_hours! + ' ' + pluralize record.spotlight_valid_hours!, 'hour', 'hours'
                            else
                                '-'
                do
                    width: 2
                    name: 'End time'
                    field: 'spotlight_expiry_timestamp'
                    sort: true
                    transform: (spotlight_expiry_timestamp) ->
                        if spotlight_expiry_timestamp! then
                            format-date-time spotlight_expiry_timestamp!
                        else
                            '-'
                do
                    width: 4
                    name: 'Article code'
                    field: 'case_article_code'
                    sort: true
                do
                    width: 12
                    name: 'Product'
                    sort: true
                    field: 'product_name'
                    ellipsis: true
                do
                    width: 5
                    name: 'Category'
                    sort: true
                    field: 'product_category_name'
                    ellipsis: true
                    function: (record) ->
                        m 'span.text-capitalize' record.product_category_name
                do
                    width: 5
                    name: 'TBO supplier'
                    sort: true
                    field: 'supplier_name'
                    ellipsis: true
                do
                    width: 3
                    name: 'Btl / cs'
                    sort: true
                    field: 'case_number_of_bottles'
                    default_visible: false
                    classes: ['number']
                do
                    width: 4
                    name: 'Size'
                    sort: true
                    field: 'bottle_volume'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                    classes: ['number']
                do
                    width: 4
                    name: 'Alc %'
                    sort: true
                    field: 'bottle_alcohol_percentage'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                    classes: ['number']
                do
                    width: 3
                    name: 'Ref'
                    sort: true
                    default_visible: false
                    field: 'bottle_refill_status'
                do
                    width: 10
                    name: 'Specs'
                    sort: false
                    function: (record) ->
                        specs = []
                        specs.push record.case_number_of_bottles
                        specs.push (+record.bottle_volume).toFixed(1)
                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                        specs.push record.bottle_refill_status
                        join ' / ' specs
                do
                    width: 4
                    name: 'GB'
                    sort: true
                    field: 'case_gift_box_type'
                    default_visible: false
                    ellipsis: true
                do
                    width: 4
                    name: 'Tax Label'
                    sort: true
                    field: 'case_tax_label'
                    default_visible: false
                    ellipsis: true
                do
                    width: 5
                    header: 'BBD'
                    name: 'Best before date'
                    sort: true
                    field: 'item_best_before_date'
                    default_visible: false
                    transform: maybe-map format-date
                do
                    width: 10
                    name: 'Features'
                    function: (record) ->
                        features = []
                        if record.case_gift_box_type
                            features.push record.case_gift_box_type
                        if record.case_tax_label
                            features.push record.case_tax_label
                        if record.item_best_before_date
                            features.push "BBD: #{format-date record.item_best_before_date}"
                        if record.item_damages
                            features.push record.item_damages.replace(', ', ' / ')
                        if record.item_general_tags
                            features.push record.item_general_tags.replace(', ', ' / ')
                        if record.item_pack_size
                            features.push record.item_pack_size
                        if record.item_packaging
                            features.push record.item_packaging
                        join ' / ' features
                do
                    width: 3
                    header: 'Cus.'
                    name: 'Customs status'
                    sort: true
                    field: 'case_customs_status'
                do
                    width: 6
                    name: 'Stock value'
                    sort: true
                    field: 'euro_total_stock_value'
                    classes: ['price']
                    function: (record) ->
                        if record.offer_item_type != 'tbo'
                            m Amount, do
                                amount: +record.euro_total_stock_value
                                currency: app.$s.currencies.default
                        else
                            '-'
                do
                    width: 2
                    name: 'Number of cases in stock'
                    header: icon-with-popover do
                        icon-id: 'home'
                        content: 'Number of cases in stock'
                    sort: true
                    field: 'number_of_cases_in_stock'
                    classes: ['number']
                do
                    width: 2
                    name: 'Number of cases in sales'
                    header: icon-with-popover do
                        icon-id: 'screenshot'
                        content: 'Number of cases in sales'
                    sort: true
                    field: 'number_of_cases_in_sales'
                    classes: ['number']
                    default_visible: false
                do
                    width: 2
                    name: 'Number of cases available'
                    header: icon-with-popover do
                        icon-id: 'fa-shield-alt'
                        content: 'Number of cases available'
                    sort: true
                    field: 'number_of_cases_available'
                    classes: ['number']
                do
                    width: 3
                    name: 'Throughput'
                    header: icon-with-popover do
                        icon-id: 'flash'
                        content: 'Throughput'
                    field: 'item_throughput'
                    sort: true
                    classes: ['number']
                    transform: maybe-map format-percentage
                    default_visible: false
                do
                    width: 7
                    header: 'Max Qty'
                    name: 'Maximum Quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.edit! then
                            inputs.number record.maximum_quantity, {min: 0}
                        else if record.maximum_quantity!? then
                            record.maximum_quantity!
                        else
                            '-'
                do
                    width: 7
                    header: 'MOQ'
                    name: 'Minimum Order Quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.edit! then
                            inputs.number record.minimum_quantity, {min: 0}
                        else if record.minimum_quantity!? then
                            record.minimum_quantity!
                        else
                            '-'
                do
                    width: 5
                    header: 'List Qty'
                    name: 'List Quantity'
                    field: 'list_quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.maximum_quantity!?  # Use ? so 0 is also considered.
                            min record.maximum_quantity!, record.number_of_cases_available
                        else
                            record.number_of_cases_available
                do
                    width: 10
                    name: 'Avg purchase / cs'
                    sort: true
                    field: 'avg_purchase_price'
                    classes: ['price']
                    function: (record) ->
                        if record.avg_purchase_price then
                            if should_include_excise! then [
                                m Amount, do
                                    amount: +record.avg_purchase_price + +record.case_excise_nl
                                    currency: app.$s.currencies.default

                                m SubAmount, do
                                    label: 'Ex Duty'
                                    amount: record.avg_purchase_price
                                    currency: app.$s.currencies.default
                            ]
                            else
                                m Amount, do
                                    amount: record.avg_purchase_price
                                    currency: app.$s.currencies.default
                        else
                            '-'
                do
                    width: 12
                    name: 'Sales / cs'
                    sort: true
                    field: 'price_per_case'
                    classes: ['price']
                    function: (record) ->
                        if record.edit! then
                            m MoneyInput, do
                                value: record.price_per_case!
                                on_value: (price) ~> record.price_per_case price
                                currency: app.$s.currencies.default
                        else
                            if +record.price_per_case!
                                m Amount, do
                                    amount: +record.price_per_case!
                                    currency: app.$s.currencies.default
                            else
                                '-'
                do
                    width: 12
                    name: 'Sales NL / cs'
                    sort: true
                    field: 'price_per_case_incl_excise'
                    default_visible: should_include_excise!
                    classes: ['price']
                    function: (record) ->
                        if +record.price_per_case_incl_excise then [
                            m Amount, do
                                amount: +record.price_per_case_incl_excise
                                currency: app.$s.currencies.default

                            m SubAmount, do
                                label: 'Duty'
                                amount: record.case_excise_nl
                                currency: app.$s.currencies.default
                        ]
                        else
                            '-'
                do
                    width: 12
                    name: 'Spot price / cs'
                    sort: true
                    classes: ['price']
                    field: 'spot_price'
                    function: (record) ->
                        if record.edit! then
                            m MoneyInput, do
                                value: record.spot_price!
                                on_value: (price) ~> record.spot_price price
                                currency: app.$s.currencies.default
                        else
                            m Amount,
                                amount: if record.spot_price!
                                        then +record.spot_price!
                                        else null
                                currency: app.$s.currencies.default
                do
                    width: 12
                    name: 'Spot price NL / cs'
                    sort: true
                    field: 'spot_price_incl_excise'
                    default_visible: should_include_excise!
                    classes: ['price']
                    function: (record) ->
                        if +record.spot_price_incl_excise then [
                            m Amount, do
                                amount: +record.spot_price_incl_excise
                                currency: app.$s.currencies.default

                            m SubAmount, do
                                label: 'Duty'
                                amount: record.case_excise_nl
                                currency: app.$s.currencies.default
                        ]
                        else
                            '-'
                do
                    width: 6
                    name: 'Margin %'
                    sort: true
                    field: 'margin_percentage'
                    classes: ['price']
                    function: (record) ~>
                        if record.edit! then
                            margin_percentage = (record.price_per_case! - record.avg_purchase_price) / record.avg_purchase_price * 100
                        else
                            margin_percentage = +record.margin_percentage
                        m "span.#{@color_class margin_percentage}" "#{margin_percentage.toFixed(2)}%"
                do
                    width: 6
                    name: 'List price/btl'
                    sort: true
                    field: 'list_price_per_bottle'
                    classes: ['price']
                    default_visible: false
                    function: (record) ->
                        m Amount, do
                            amount: -> +record.price_per_case! / +record.case_number_of_bottles
                            currency: app.$s.currencies.default
                do
                    width: 6
                    name: 'Delivery period'
                    sort: true
                    field: 'delivery_period'
                    classes: ['number']
                    function: (record) ->
                            if record.edit! then
                                inputs.number record.delivery_period, {min: 1}
                            else if record.delivery_period!? then
                                record.delivery_period! + ' ' + pluralize record.delivery_period!, 'week', 'weeks'
                            else
                                '-'
                do
                    width: 3
                    name: 'Item type'
                    sort: true
                    field: 'offer_item_type'
                    transform: (type) ->
                        | type == 'tbo' => 'TBO'
                        | otherwise => type.capitalizeFirstLetter!
                do
                    width: 2
                    name: 'Product photo icon'
                    header: icon 'fa-image'
                    field: 'has_product_photo'
                    function: (record) ->
                        if record.has_product_photo then
                            icon-with-popover do
                                icon-id: 'fa-image'
                                title: 'Product photo'
                                content: 'This product has one or more photos.'
                do
                    width: 14
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            if record.edit! then a do
                                icon-button 'ok', do
                                    class: 'btn-success no-click'
                                    onclick: ~>
                                        @save_offer_item record, (timestamp) ~>
                                            if record.old_price_per_case != record.price_per_case!
                                                # Broadcast the change, so the offer item list
                                                # and stock lists are requeried.
                                                app.$m.common.observable.broadcast 'stock_updated'

                                            record.spotlight_expiry_timestamp timestamp
                                            record.old_price_per_case = record.price_per_case!
                                            record.old_delivery_period = record.delivery_period!
                                            record.old_maximum_quantity = record.maximum_quantity!
                                            record.old_minimum_quantity = record.minimum_quantity!
                                            record.edit false
                                            app.notifier.notify 'Successfully updated the offer item.', 'success'

                                icon-button 'ban-circle', do
                                    class: 'btn-info no-click'
                                    onclick: ~>
                                        record.price_per_case record.old_price_per_case
                                        record.delivery_period record.old_delivery_period
                                        record.maximum_quantity record.old_maximum_quantity
                                        record.minimum_quantity record.old_minimum_quantity
                                        record.edit false
                            else a do
                                icon-button 'pencil', do
                                    class: 'btn-default no-click'
                                    disabled: if record.is_hidden then 'disabled' else ''
                                    onclick: ~> record.edit true

                                icon-button 'trash', do
                                    class: 'btn-danger no-click'
                                    onclick: ~> @delete_spotlight_item record, ~> @offer.requery!
                                #else
                                icon-button 'shopping-cart', do
                                    class: 'btn-default'
                                    onclick: ~> @add_to_sales_order record
