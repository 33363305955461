import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

import {Api, create_download_for_blob} from '@/factserver_api/api'
import {SearchStatisticsResponse} from '@/factserver_api/fact2server_api.ts'
import {RelationType} from '@/market/statistics.tsx'

export interface StatisticsRequest {
    type: RelationType
    date_ranges: string[]
    account_slug: string
    relation_artkey?: number
    account_manager_artkey?: string
}

export interface ProductStatisticsResponse {
    product_name: string
    number_of_bottles: number
    volume: number
    refill_status: string
    alcohol_percentage: number
    gift_box_type: string
    tax_label: string
}

export interface RelationStatisticsDataResponse {
    relation_name: string
    relation_nr: number
    relation_account_slug: string
    relation_account_name: string
}

interface StatisticDateRange {
    total_value: number
    number_of_cases: number
    total_margin?: number
}

export interface StatisticsDateRangeResponse {
    [date_range_key: string]: StatisticDateRange
}

export type StatisticsDataResponse =
    | ProductStatisticsResponse & StatisticsDateRangeResponse
    | RelationStatisticsDataResponse & StatisticsDateRangeResponse

export class StatisticsApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    get_statistics(request: StatisticsRequest): Observable<SearchStatisticsResponse> {
        let request_url = `discover/statistics?relation_type=${request.type}&date_ranges_str=${request.date_ranges.join(',')}&account_slug=${request.account_slug}`

        if (request.relation_artkey) {
            request_url = request_url.concat(`&relation_artkey=${request.relation_artkey}`)
        } else if (request.account_manager_artkey) {
            request_url = request_url.concat(`&account_manager_artkey=${request.account_manager_artkey}`)
        }

        return this.api.get(request_url)
    }

    export_sales_statistics(request: StatisticsRequest, file_name: string = 'export'): Observable<unknown> {
        let request_url = `discover/statistics/export?relation_type=${request.type}&date_ranges_str=${request.date_ranges.join(',')}&account_slug=${request.account_slug}`

        if (request.relation_artkey) {
            request_url = request_url.concat(`&relation_artkey=${request.relation_artkey}`)
        } else if (request.account_manager_artkey) {
            request_url = request_url.concat(`&account_manager_artkey=${request.account_manager_artkey}`)
        }

        return this.api.get(request_url)
            .pipe(map((response: Blob) => create_download_for_blob(response, file_name)))
    }
}
