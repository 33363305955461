import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {BrandCollectionResponse} from '@/factserver_api/fact2server_api'

export default class BrandList extends MithrilTsxComponent<unknown> {
    brand_fetcher: PagedCollectionFetcherWithGET<BrandCollectionResponse>

    constructor() {
        super()
        this.brand_fetcher = new PagedCollectionFetcherWithGET('discover/data/brands/collection-view', 'name')
    }

    oncreate(): void {
        this.brand_fetcher.fetch_next()
    }

    view(): m.Children {
        return (
            <div className="c-data-brands view">
                <CollectionTable<BrandCollectionResponse, void>
                    on_row_click={(row: BrandCollectionResponse) => m.route.set(`/data/brands/${row.artkey}/edit`)}
                    collection_fetcher={this.brand_fetcher}>
                    <CollectionTableColumn<BrandCollectionResponse>
                        header_title={() => 'Name'}
                        sort_name={'name'}
                        data_field={(row: BrandCollectionResponse) => row.name}
                    />
                    <CollectionTableColumn<BrandCollectionResponse>
                        header_title={() => 'Brand holder'}
                        sort_name={'brand_holder_name'}
                        data_field={(row: BrandCollectionResponse) => row.brand_holder_name}
                    />
                    <CollectionTableColumn<BrandCollectionResponse>
                        header_title={() => 'match_text'}
                        data_field={(row: BrandCollectionResponse) => row.match_text}
                    />
                </CollectionTable>
            </div>
        )
    }
}
