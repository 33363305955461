import {events} from '@bitstillery/common/app'

import _models from './_users.ls'

import {$m} from '@/app'

export const {Profile, User} = _models
// The old global.user; here because of 'make_setting_prop'
// Fine to initialize on root scope; as long it doesn't do
// an api call.
export let legacy_user = new User()
export class UsersDataModel {
    create_users(users) {
        return users.map((user) => this.create_user(user))
    }

    create_user(user) {
        return new User(user)
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.users = new UsersDataModel() as any
    })
}
