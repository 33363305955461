import {Observable, of, Subject} from 'rxjs'

import {Api} from './api'
import {GetInsuranceTypeResponse} from './fact2server_api'

/**
 * Caches the data for usage in drop down, search auto complete elements etc. This singleton
 * is available during the browser lifespan of the application. It waits on the
 * GetAllForDropDownResponse[]. This value is null if the data is not yet retrieved.
 */
export class InsuranceTypesDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: InsuranceTypesDropDownData | null = null
    /** Cached results, when available */
    private result: GetInsuranceTypeResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetInsuranceTypeResponse[]> = new Subject<GetInsuranceTypeResponse[]>()

    private constructor() {
        this.api.get('discover/data/insurance-types')
            .subscribe({
                next: (response: GetInsuranceTypeResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): InsuranceTypesDropDownData {
        if (InsuranceTypesDropDownData._instance === null) {
            InsuranceTypesDropDownData._instance = new InsuranceTypesDropDownData()
        }

        return InsuranceTypesDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static insurance_types(): Observable<GetInsuranceTypeResponse[]> {
        if (InsuranceTypesDropDownData.instance().drop_down_data$.isStopped) {
            return of(InsuranceTypesDropDownData.instance().result)
        }

        return InsuranceTypesDropDownData.instance().drop_down_data$
    }
}
