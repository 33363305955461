m = require 'mithril'
utils = require '../../utils.ls'
items_table = require './items_table.ls'
{ItemMutationReason} = require '@/models/stock'

mutation_panel = {}
module.exports = mutation_panel

mutation_panel.mutation_panel = (mutation, body) ->
    a = -> [.. for &]
    m '.panel.panel-info' a do
        m '.panel-heading' m '.panel-title' a do
            "Mutation "
            m 'a' {onclick: ~> m.route.set "/stock/mutations/manage/#{mutation!reference!}"} mutation!reference!
        m '.panel-body' a do
            m '.col-sm-12.col-lg-6' m 'dl.dl-horizontal' a do
                m 'dt', 'Creation Date'
                m 'dd', utils.format-date(mutation!created_on!)
                m 'dt', 'Reason'
                m 'dd', mutation!item_mutation_reason!
                m 'dt', 'Description'
                m 'dd', mutation!description!
                m 'dt', 'User'
                m 'dd', mutation!user!profile!name!
                if mutation!item_mutation_reason! != ItemMutationReason.MOVE_TO_WAREHOUSE and mutation!stock_value_difference!
                    a do
                        m 'dt' 'Stock value difference'
                        m 'dd' m('span.colored-number', {'data-value': (+mutation!stock_value_difference!).format-money! + ' EUR'})

            if mutation!target_warehouse_artkey!
                m '.col-sm-12.col-lg-6' m 'dl.dl-horizontal' a do
                    m 'dt', 'Target Warehouse'
                    m 'dd', mutation!target_warehouse!name!
                    m 'dt', 'Expected Delivery Date'
                    m 'dd', utils.format-date(mutation!expected_delivery_date!)
                    m 'dt', 'Status'
                    m 'dd', mutation!status!

            if body
                body
            else
                a do
                    m '.col-sm-12' m '.panel.panel-default' a do
                        m '.panel-heading' m '.panel-title' 'After mutation'
                        m '.panel-body' items_table.items_table(mutation!targets)
                    m '.col-sm-12' m '.panel.panel-default' a do
                        m '.panel-heading' m '.panel-title' 'Before mutation'
                        m '.panel-body' items_table.items_table(mutation!sources)
