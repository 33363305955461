$ = require 'jquery'
m = require 'mithril'
{random-uuid} = require 'utils.ls'


class Confirmation
    (vnode) ->
        {
            @unique_name,
            @title,
            @message,
            @no_cancel,
            @onconfirm,
        } = vnode.attrs

    hide: ~>
        $ ('#' + @unique_name) .modal 'hide'

    close: ~>
        @hide!

    confirm: ~>
        @hide!
        if @onconfirm? then
            @onconfirm!

    view: ->
        modal-attrs = do
            id: @unique_name
            tabindex: '-1'
            role: 'dialog'
            'aria-labelledby': 'confirmationLabel'
            oncreate: (vnode) -> $ vnode.dom .modal!

        m '.c-dialog-confirmation .modal.fade' modal-attrs,
            m '.modal-dialog' {role: 'document'},
                m '.modal-content',
                    m '.modal-header',
                        m 'button.close' do
                            type: 'button'
                            'data-dismiss': 'modal'
                            'aria-label': 'Close'
                        , '×'
                        m 'h4.modal-title#confirmationLabel' @title

                    m '.modal-body' @message

                    m '.modal-footer',
                        if not @no_cancel then
                            m 'button.btn.btn-default' do
                                type: 'button'
                                'data-dismiss': 'modal'
                                onclick: @close
                            , 'Cancel'
                        m 'button.btn.btn-primary' do
                            type: 'button'
                            'data-dismiss': 'modal'
                            onclick: @confirm
                        , 'OK'


export show_confirmation = (properties) ->
    /**
    * Show a confirmation
    *
    * Example of properties:
    * properties = {
    *     title: 'I am a title',
    *     message: 'I am the question you want to ask',
    *     onconfirm: -> console.log 'The function to execute on confirm'
    *     unique_name: 'unique_name_to_prevent_clashes'
    * }
    **/
    if not properties.unique_name then
        console.error 'Provide a unique name for your confirmation, otherwise names might collide!'

    properties.key = random-uuid!

    element = document.getElementById 'confirmationLocation'
    m.render element, (m Confirmation, properties)

# Backwards compatible.
export show = show_confirmation
