import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {AddAdditional, SalesOrderAttr} from 'sales_orders/components/add_additional.ls'
import {format_money} from '@bitstillery/common/ts_utils'
import {Amount, Spinner, Tippy} from '@bitstillery/common/components'

import {VoucherPromotion, VoucherValueType} from '../../vouchers/voucher_models'

import {$s} from '@/app'
import {SelectionEnabledComponent} from '@/components/selection_controller'
import {drop_down_menus_for_enum, DropDownOption, DropDownWithSelect, TextInput} from '@/components/html_components'
import {NumberInput} from '@/components/input_numbers'
import {CancelButton, DangerButton, DefaultButton, SuccessButton} from '@/components/buttons'
import {MoneyInput, PercentInput} from '@/components/decimal_input'
import {VoucherApi} from '@/factserver_api/voucher_api'
import {
    GetFastSalesOrderWithItemsResponse,
    SalesApi,
    SalesOrderAdditionalItem,
    SalesOrderAdditionalType,
    SalesOrderAdditionalValueType,
    SalesOrderStatus,
} from '@/factserver_api/sales_api'

export interface SalesOrderAdditionalItemsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
}

export interface SalesOrderAdditionalItemAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_additional_item: SalesOrderAdditionalItem
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
}

/**
 * Shows the sales order additional item of a sales order
 */
export class SalesOrderAdditionalItemView
    extends MithrilTsxComponent<SalesOrderAdditionalItemAttrs>
    implements SelectionEnabledComponent
{
    sales_api = new SalesApi()
    voucher_api = new VoucherApi()

    sales_order_changed: () => void
    is_editing = false
    is_selecting = false
    is_performing_api_call = false

    available_vouchers: Pick<VoucherPromotion, 'code' | 'value' | 'voucher_value_type'>[] = []
    is_selecting_vouchers = false
    is_fetching_vouchers = false
    selected_voucher_code = ''

    // Workaround for displaying enums to a different text in the drop down.
    additional_type_drop_down_data: { key: SalesOrderAdditionalType; display_name: string }[] = [
        {key: SalesOrderAdditionalType.REVENUE, display_name: 'Revenue'},
        {key: SalesOrderAdditionalType.DISCOUNT, display_name: 'Discount'},
        {key: SalesOrderAdditionalType.TRANSPORT, display_name: 'Transport'},
        {key: SalesOrderAdditionalType.VOUCHER, display_name: 'Voucher'},
        {key: SalesOrderAdditionalType.PALLET, display_name: 'Pallet'},
        {key: SalesOrderAdditionalType.WASTE_FUND, display_name: 'Waste fund (afvalfonds)'},
        {key: SalesOrderAdditionalType.OTHER, display_name: 'Other'},
    ]

    constructor(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>) {
        super()

        this.sales_order_changed = vnode.attrs.sales_order_changed
        vnode.attrs.register_selection_enabled_component(this)
    }

    oncreate(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>): void {
        this.fetch_available_vouchers(vnode.attrs.sales_order_additional_item, vnode.attrs.sales_order.supplier.artkey)
    }

    start_selection(): void {
        this.is_selecting = true
    }

    end_selection(): void {
        this.is_selecting = false
    }

    delete_sales_order_additional_item(additional_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.delete_sales_order_additional_item(additional_artkey).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.sales_order_changed()
            },
            error: () => {
                this.is_performing_api_call = false
            },
        })
    }

    cancel_edit(): void {
        this.is_editing = false
        this.is_selecting_vouchers = false
        this.sales_order_changed()
    }

    start_edit(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>): void {
        const additional = vnode.attrs.sales_order_additional_item
        this.is_editing = true
        this.is_selecting_vouchers = additional.sales_order_additional_type === SalesOrderAdditionalType.VOUCHER
        if (this.is_selecting_vouchers) {
            this.selected_voucher_code = additional.description || ''
        }
    }

    save_edit(additional: SalesOrderAdditionalItem): void {
        if (
            additional.quantity < 1 ||
            additional.value_type === null ||
            additional.value_per_quantity === null ||
            !additional.sales_order_additional_type
        ) {
            return
        }
        this.is_performing_api_call = true
        this.sales_api
            .update_additional_item(
                additional.artkey,
                additional.sales_order_additional_type,
                additional.description,
                additional.quantity,
                additional.value_type,
                +additional.value_per_quantity,
            )
            .subscribe({
                next: () => {
                    this.is_editing = false
                    this.is_performing_api_call = false
                    this.sales_order_changed()
                },
                error: () => {
                    this.is_performing_api_call = false
                    m.redraw()
                },
            })
    }

    fetch_available_vouchers(additional: SalesOrderAdditionalItem, relation_artkey: number): void {
        if (additional.sales_order_additional_type === SalesOrderAdditionalType.VOUCHER) {
            this.available_vouchers = [
                {
                    value: Math.abs(+additional.value_per_quantity),
                    voucher_value_type: additional.value_type as unknown as VoucherValueType,
                    code: additional.description || '',
                },
            ]
        } else {
            this.available_vouchers = []
        }
        this.is_fetching_vouchers = true

        this.voucher_api.get_active_relation_vouchers(relation_artkey).subscribe({
            next: (response) => {
                this.available_vouchers = this.available_vouchers.concat(response)
                this.is_fetching_vouchers = false
                m.redraw()
            },
            error: () => {
                this.is_fetching_vouchers = false
                m.redraw()
            },
        })
    }

    edit_additional_type(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>, new_value: SalesOrderAdditionalType): void {
        const additional = vnode.attrs.sales_order_additional_item
        const current_type = additional.sales_order_additional_type

        /* Fetch any vouchers or reset fields if previous type was a voucher */
        if (new_value === SalesOrderAdditionalType.VOUCHER) {
            this.is_selecting_vouchers = true
            const just_a_voucher = this.available_vouchers.find(() => true)
            this.selected_voucher_code = just_a_voucher?.code || ''
            additional.quantity = 1
            additional.value_per_quantity = `${(just_a_voucher?.value || 0) * -1}`
            additional.description = just_a_voucher?.code || ''
        } else if (current_type === SalesOrderAdditionalType.VOUCHER) {
            this.is_selecting_vouchers = false
            additional.quantity = 0
            additional.price_per_unit = '0'
            additional.description = ''
        }

        additional.sales_order_additional_type = new_value
    }

    edit_voucher(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>, new_voucher_code: string): void {
        const additional = vnode.attrs.sales_order_additional_item
        this.selected_voucher_code = new_voucher_code
        if (new_voucher_code) {
            const voucher_promotion = this.available_vouchers.find((voucher) => voucher.code === new_voucher_code)
            if (voucher_promotion) {
                additional.quantity = 1
                additional.value_type = voucher_promotion.voucher_value_type as unknown as SalesOrderAdditionalValueType
                additional.value_per_quantity = `${voucher_promotion.value * -1}`
                additional.description = voucher_promotion.code
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderAdditionalItemAttrs>): m.Children {
        const sales_order = vnode.attrs.sales_order
        const editable: boolean = ![SalesOrderStatus.CANCELLED, SalesOrderStatus.INVOICED].includes(sales_order.combined_status)
        return (
            <tbody className={'table-row'} key={vnode.attrs.sales_order_additional_item.artkey}>
                <tr>
                    {this.is_editing && (
                        <td>
                            <DropDownWithSelect
                                selected={vnode.attrs.sales_order_additional_item.sales_order_additional_type}
                                onchange={(value: string) =>
                                    this.edit_additional_type(vnode, value as SalesOrderAdditionalType)
                                }
                                required={true}
                            >
                                {this.additional_type_drop_down_data
                                    .filter(
                                        (data) =>
                                            !(
                                                this.available_vouchers.length === 0 &&
                                                data.key === SalesOrderAdditionalType.VOUCHER
                                            ),
                                    )
                                    .map((data) => (
                                        <DropDownOption value={data.key}>{data.display_name}</DropDownOption>
                                    ))}
                            </DropDownWithSelect>
                        </td>
                    )}
                    {!this.is_editing && <td>{vnode.attrs.sales_order_additional_item.sales_order_additional_type}</td>}

                    {!this.is_editing && <td>{vnode.attrs.sales_order_additional_item.description}</td>}
                    {this.is_editing && !this.is_selecting_vouchers && (
                        <td>
                            <TextInput
                                value={vnode.attrs.sales_order_additional_item.description}
                                oninput={(value: string | null) => {
                                    vnode.attrs.sales_order_additional_item.description = value
                                }}
                            />
                        </td>
                    )}
                    {this.is_editing && this.is_selecting_vouchers && (
                        <td>
                            {this.available_vouchers.length > 0 && (
                                <DropDownWithSelect
                                    selected={this.selected_voucher_code}
                                    onchange={(value: string) => this.edit_voucher(vnode, value)}
                                >
                                    {this.available_vouchers.map((voucher) => (
                                        <DropDownOption value={voucher.code}>
                                            {voucher.code} -{' '}
                                            {format_money(Math.abs(voucher.value), 'EUR', $s.identity.user.decimal_locale)}
                                        </DropDownOption>
                                    ))}
                                </DropDownWithSelect>
                            )}
                            {this.available_vouchers.length === 0 && <span>No vouchers available</span>}
                        </td>
                    )}
                    <td className="number">
                        {vnode.attrs.sales_order_additional_item.vat_percentage !== null
                            ? `${vnode.attrs.sales_order_additional_item.vat_percentage}%`
                            : 'Not applicable'}
                    </td>

                    {!this.is_editing && <td className="number">{vnode.attrs.sales_order_additional_item.quantity}</td>}
                    {this.is_editing && (
                        <td>
                            <NumberInput
                                value={vnode.attrs.sales_order_additional_item.quantity}
                                minimum={0}
                                required={true}
                                disabled={this.is_selecting_vouchers}
                                oninput={(value: number) => (vnode.attrs.sales_order_additional_item.quantity = value)}
                            />
                        </td>
                    )}

                    {!this.is_editing && <td>{vnode.attrs.sales_order_additional_item.value_type}</td>}
                    {this.is_editing && (
                        <td>
                            <DropDownWithSelect
                                selected={vnode.attrs.sales_order_additional_item.value_type}
                                onchange={(value_type: keyof typeof SalesOrderAdditionalValueType) =>
                                    (vnode.attrs.sales_order_additional_item.value_type =
                                        SalesOrderAdditionalValueType[value_type])
                                }
                                disabled={this.is_selecting_vouchers}
                            >
                                {drop_down_menus_for_enum(SalesOrderAdditionalValueType)}
                            </DropDownWithSelect>
                        </td>
                    )}
                    {!this.is_editing && (
                        <td className="price">
                            <Amount
                                amount={vnode.attrs.sales_order_additional_item.price_per_unit}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                            {vnode.attrs.sales_order_additional_item.value_type ===
                                SalesOrderAdditionalValueType.PERCENTAGE &&
                                ` (${vnode.attrs.sales_order_additional_item.value_per_quantity}%)`}
                        </td>
                    )}
                    {this.is_editing && (
                        <td>
                            {vnode.attrs.sales_order_additional_item.value_type ===
                            SalesOrderAdditionalValueType.PERCENTAGE ? (
                                    <PercentInput
                                        value={+vnode.attrs.sales_order_additional_item.value_per_quantity}
                                        required={true}
                                        disabled={this.is_selecting_vouchers}
                                        minimum={-100}
                                        maximum={100}
                                        on_value={(value: number | null) =>
                                            (vnode.attrs.sales_order_additional_item.value_per_quantity = `${value}` || '0')
                                        }
                                    />
                                ) : (
                                    <MoneyInput
                                        value={vnode.attrs.sales_order_additional_item.value_per_quantity}
                                        minimum={Number.MIN_VALUE}
                                        maximum={Number.MAX_VALUE}
                                        disabled={this.is_selecting_vouchers}
                                        on_value={(value: number | null) =>
                                            (vnode.attrs.sales_order_additional_item.value_per_quantity = `${value}` || '0')
                                        }
                                    />
                                )}
                        </td>
                    )}
                    <td className="price">
                        <Amount
                            amount={vnode.attrs.sales_order_additional_item.total_value}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                    </td>
                    {!this.is_performing_api_call && !this.is_fetching_vouchers && (
                        <td>
                            {!this.is_selecting && (
                                <span>
                                    {!this.is_editing && editable && (
                                        <div className={'pull-right'}>
                                            <DefaultButton
                                                icon_class={'glyphicon glyphicon-pencil'}
                                                onclick={() => this.start_edit(vnode)}
                                            />
                                            <DangerButton
                                                icon_class={'glyphicon glyphicon-remove'}
                                                onclick={() =>
                                                    this.delete_sales_order_additional_item(
                                                        vnode.attrs.sales_order_additional_item.artkey,
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    {this.is_editing && (
                                        <div className={'pull-right'}>
                                            <CancelButton onclick={() => this.cancel_edit()} />
                                            <SuccessButton
                                                icon_class={'glyphicon glyphicon-ok'}
                                                title={' '}
                                                onclick={() => this.save_edit(vnode.attrs.sales_order_additional_item)}
                                            />
                                        </div>
                                    )}
                                </span>
                            )}
                        </td>
                    )}
                    {(this.is_performing_api_call || this.is_fetching_vouchers) && (
                        <td>
                            <div style={'width: 81px'}>
                                <Spinner />
                            </div>
                        </td>
                    )}
                </tr>
            </tbody>
        )
    }
}

/**
 * Shows the sales order additional items of a sales order
 */
export class SalesOrderAdditionalItems extends MithrilTsxComponent<SalesOrderAdditionalItemsAttrs> {
    sales_order_as_function_view(vnode: m.Vnode<SalesOrderAdditionalItemsAttrs>): () => SalesOrderAttr {
        const sales_order = vnode.attrs.sales_order
        return () => {
            return {
                artkey: () => sales_order.artkey,
                includes_excise: () => sales_order.includes_excise,
                was_sold_in: () => sales_order.was_sold_in,
                supplier: () => {
                    return {
                        artkey: () => sales_order.supplier.artkey,
                    }
                },
                sales_order_additionals: () => [],
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderAdditionalItemsAttrs>): m.Children {
        const disable_add: boolean = [SalesOrderStatus.CANCELLED, SalesOrderStatus.INVOICED].includes(vnode.attrs.sales_order.combined_status)
        return (
            <span>
                {!vnode.attrs.sales_order.is_part_of_buy_from_account && !disable_add && (
                    <AddAdditional
                        sales_order_changed={vnode.attrs.sales_order_changed}
                        sales_order={this.sales_order_as_function_view(vnode)}
                    />
                )}
                {vnode.attrs.sales_order.sales_order_additionals.length > 0 && (
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <h4>Additionals</h4>
                        </div>
                        <div className={'col-sm-12'}>
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>Type</th>
                                        <th>Description</th>
                                        <th className="number">
                                            VAT %{' '}
                                            <Tippy content="This percentage comes from the sales ledger of the relation. If type of additional is percentage, no VAT percentage can be applied.">
                                                <i class="glyphicon glyphicon-question-sign" />
                                            </Tippy>
                                        </th>
                                        <th className="number">Quantity</th>
                                        <th>Value type</th>
                                        <th className="price">Price</th>
                                        <th className="price">Total</th>
                                        <th />
                                    </tr>
                                </thead>
                                {vnode.attrs.sales_order.sales_order_additionals.map((soa) => (
                                    <SalesOrderAdditionalItemView
                                        sales_order={vnode.attrs.sales_order}
                                        sales_order_additional_item={soa}
                                        register_selection_enabled_component={
                                            vnode.attrs.register_selection_enabled_component
                                        }
                                        sales_order_changed={vnode.attrs.sales_order_changed}
                                    />
                                ))}
                            </table>
                        </div>
                    </div>
                )}
            </span>
        )
    }
}
