m = require 'mithril'
{deref} = require '@bitstillery/common/utils.ls'


# Returns a conditionally colored ranking.
export rank = (item) ~>
    rank = deref item.rank
    total = deref item.total

    if rank == 1 and total > 1
        # The item is ranked first of multiple items.
        color-class = 'analysis-good-color'
    else if rank > 1 and rank == total
        # The item is ranked last of multiple items.
        color-class = 'analysis-bad-color'
    else
        # The item is ranked somewhere in the middle or is a single item.
        color-class = 'analysis-neutral-color'

    m 'span.rank' {class: color-class} "#{rank} / #{total}"
