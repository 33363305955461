{deref} = require '@bitstillery/common/utils.ls'
{account-icon} = require 'accounts.ls'
{link} = require '@/components/link.ls'


export contact_person_link: (contact_person, options={}) ->
    supplier = deref contact_person.supplier
    link "/crm/relations/#{deref(supplier.artkey)}/contact-persons/#{deref(contact_person.artkey)}/edit", deref(contact_person.name), options


export relation_link: (relation) -> [
    m 'span.mr-05' account-icon deref(relation.sales_account)
    link "/crm/relations/#{deref(relation.artkey)}/edit", deref(relation.name)
]
