import {
    proforma_or_invoice_renderer,
    ProformaInvoice,
    ProformaInvoiceRendererAttrs,
} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {notifier} from '@bitstillery/common/app'

import {GetFastSalesOrderWithItemsResponse, SalesApi} from '@/factserver_api/sales_api'
import {
    AttachmentType,
    CategorizePurchaseOrderAttachmentRequest,
    GetAttachmentResponse,
} from '@/factserver_api/attachment_api'
import {PDFHelper} from '@/components/pdf_helper'
import {
    GetBuyFromAccountPurchaseOrderResponse, PurchaseOrderStatus,
} from '@/factserver_api/fact2server_api'
import {AttachmentHelper} from '@/components/attachment_list'
import {PurchaseApi} from '@/factserver_api/purchase_api'

export function auto_attach_buy_from_account_supplier_invoices(sales_order_artkey: number) {
    const purchase_api: PurchaseApi = new PurchaseApi()
    const sales_api = new SalesApi()

    sales_api.get_buy_from_account_purchase_orders(sales_order_artkey).then((purchase_orders: GetBuyFromAccountPurchaseOrderResponse[]) => {
        const promises: Promise<void>[] = purchase_orders.filter((purchase_order: GetBuyFromAccountPurchaseOrderResponse) => {
            return !purchase_order.has_supplier_invoice
        }).map((purchase_order: GetBuyFromAccountPurchaseOrderResponse) => {
            if (purchase_order.status === PurchaseOrderStatus.Cancelled) {
                return Promise.resolve()
            }

            return create_and_attach_buy_from_account_supplier_invoice_to_purchase_order(sales_api, purchase_api, purchase_order)
        })
        Promise.all(promises).then(() => {
            notifier.notify('Buy from account supplier invoices attached automatically to all related purchase orders', 'success')
        })
    }, (_) => {
        notifier.notify('Something went wrong while trying to get the related purchase orders.', 'danger')
    })
}

async function create_and_attach_buy_from_account_supplier_invoice_to_purchase_order(
    sales_api: SalesApi, purchase_api: PurchaseApi, purchase_order: GetBuyFromAccountPurchaseOrderResponse,
) {
    const attachment_helper: AttachmentHelper = new AttachmentHelper(purchase_order.artkey, AttachmentType.PURCHASE_ORDER)

    get_buy_from_account_related_sales_order(sales_api, purchase_api, purchase_order).then((related_sales_order) => {
        const base64_encoded_invoice = create_supplier_invoice_pdf(related_sales_order)

        const attachment_name = `Invoice - ${related_sales_order.invoice_number} - ${related_sales_order.account.name}.pdf`
        upload_buy_from_account_supplier_invoice_to_purchase_order(attachment_helper, base64_encoded_invoice, attachment_name, purchase_order.artkey)
    }, (_) => {
        notifier.notify(
            `Something went wrong while trying to generate the buy from account supplier invoice for P${purchase_order.artkey}, please attach the supplier invoice manually in attachments.`,
            'danger',
        )
    })
}

async function get_buy_from_account_related_sales_order(
    sales_api: SalesApi, purchase_api: PurchaseApi, purchase_order: GetBuyFromAccountPurchaseOrderResponse,
): Promise<GetFastSalesOrderWithItemsResponse> {
    const bfa_origin_sales_order = await purchase_api.get_buy_from_account_origin_sales_order(purchase_order.artkey).toPromise()
    return sales_api.get_fast_sales_order_with_items(bfa_origin_sales_order.artkey).toPromise()
}

function create_supplier_invoice_pdf(sales_order: GetFastSalesOrderWithItemsResponse): string {
    const pdf_helper = new PDFHelper<ProformaInvoiceRendererAttrs>(proforma_or_invoice_renderer)

    pdf_helper.render_base64_encoded({
        // @ts-ignore - insignificant difference between GetFastSalesOrderWithItemsResponse and SalesOrder
        sales_order: sales_order,
        account: pdf_helper.account_by_slug(sales_order.account.slug),
        proforma_or_invoice: ProformaInvoice.INVOICE,
        cases_or_bottles: CasesOrBottles.cases,
        decimal_locale: 'en',
        show_article_code: false,
        show_country_of_origin: false,
        show_cbs_code: false,
        show_liters_of_alcohol: false,
        group_similar_items: false,
    })

    if (!pdf_helper.pdf) {
        throw new Error('PDF could not be generated')
    }

    const output = pdf_helper.pdf.output('dataurlstring')
    return output.substring(output.indexOf(';') + 1)
}

function upload_buy_from_account_supplier_invoice_to_purchase_order(
    attachment_helper: AttachmentHelper, base64_encoded_invoice: string, attachment_name: string, purchase_order_artkey: number,
) {
    attachment_helper.upload_attachment(base64_encoded_invoice, attachment_name).subscribe({
        next: (response: GetAttachmentResponse) => {
            const categorize_request: CategorizePurchaseOrderAttachmentRequest = {
                attachment_artkey: response.artkey,
                description: 'Automatically attached supplier invoice',
                category: 'Supplier invoice',
                attachment_type: AttachmentType.PURCHASE_ORDER,
                valid_until: null,
            }

            attachment_helper.categorize_attachment(categorize_request).subscribe({
                next: () => {
                    attachment_helper.load_attachments()
                },
                error: () => {
                    notifier.notify(
                        `Something went wrong while trying to categorize the buy from account supplier invoice in P${purchase_order_artkey}, please categorize the supplier invoice manually in attachments.`,
                        'danger',
                    )
                },
            })
        },
        error: () => {
            notifier.notify(
                `Something went wrong while trying to upload the buy from account supplier invoice to the purchase order P${purchase_order_artkey}, please attach the supplier invoice manually in attachments.`,
                'danger',
            )
        },
    })
}
