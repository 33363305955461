import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Observable} from 'rxjs'

import {DropDownOption, DropDownWithSelect} from './html_components'
import {MaybeObservable} from './relation'

import {GetDestinationsResponse} from '@/factserver_api/fact2server_api'

interface WarehousesDropDownAttrs {
    selected_destination_artkey: string
    onchange: (incoterm_artkey: string) => unknown
    disabled?: boolean
    required?: boolean
    /**
     * The data source for the drop down options.
     **/
    get_all_for_drop_down_response$: Observable<GetDestinationsResponse[]>
}

/**
 * Destinations drop down.
 *
 * Usage:
 *       <DestinationsDropDown
 *            selected_destination_artkey={`${this.selected_origin?.artkey}`}
 *            disabled={!this.selected_relation || !this.loendersloot_destination || this.is_using_loendersloot_release || this.is_origin_loendersloot}
 *            onchange={this.update_origin_warehouse}
 *            get_all_for_drop_down_response$={DestinationsDropDownData.destinations()}
 *        />
 **/
export class DestinationsDropDown extends MithrilTsxComponent<WarehousesDropDownAttrs> {
    destinations: GetDestinationsResponse[] = []

    oncreate(vnode: m.Vnode<WarehousesDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((destinations) => (this.destinations = destinations))
    }

    view(vnode: m.Vnode<WarehousesDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
                <DropDownWithSelect
                    label={vnode.attrs.label}
                    onchange={vnode.attrs.onchange}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected_destination_artkey}
                    empty_option={<DropDownOption value={''}>{''}</DropDownOption>}
                    required={vnode.attrs.required}
                >
                    {this.destinations?.map((destination) => (
                        <DropDownOption value={`${destination.artkey}`}>{destination.name} - {destination.city} ({destination.country_code})</DropDownOption>
                    ))}
                </DropDownWithSelect>
            </MaybeObservable>
        )
    }
}

export function icon_for_destination_type(destination_type: string): m.Children {
    if (destination_type === 'warehouse') {
        return <span className={'fas fa-home'} />
    } else if (destination_type === 'airport') {
        return <span className={'fas fa-plane'} />
    } else if (destination_type === 'seaport') {
        return <span className={'fas fa-anchor'} />
    }

}
