import api from 'api.ls'
import {CollectionTable} from 'components/collection_table.ls'
import {MultiSelect} from 'components/inputs/multiselect.ls'
import {header_with_popover} from 'components/popover/popover.ls'
import {icon} from 'components/icon.ls'
import m from 'mithril'
import {afterUpdate} from 'utils.ls'
import {notifier} from '@bitstillery/common/app'

import {$m} from '@/app'
import {checkbox, select} from '@/components/inputs'

export class CommunicationView {
    constructor(vnode) {
        this.collection = vnode.attrs.collection
        this.is_updating = prop(false)

        const defaults = {disabled: this.is_updating()}

        this.columns = [
            {field: 'name', name: 'Name', sort: true, width: 6},
            {
                field: 'is_supplier',
                function: (record) => checkbox(record.is_supplier, '', defaults),
                name: 'Supplier',
                sort: true,
                width: 2,
            },
            {
                field: 'is_buyer',
                function: (record) => checkbox(record.is_buyer, '', defaults),
                name: 'Buyer',
                sort: true,
                width: 2,
            },
            {
                field: 'should_receive_offer_mails',
                function: (record) => checkbox(record.should_receive_offer_mails, '', defaults),
                header: header_with_popover(
                    'Only relations with this setting enabled will receive offer emails.',
                    m('span', 'Offer mails ', icon('fa-question-circle')),
                ),
                name: 'Offer mails',
                sort: true,
                width: 2,
            },
            {
                field: 'should_receive_purchase_mails',
                function: (record) => checkbox(record.should_receive_purchase_mails, '', defaults),
                header: header_with_popover(
                    'Only relations with this setting enabled will receive purchase enquiries.',
                    m('span', 'Purchase mails ', icon('fa-question-circle')),
                ),
                name: 'Purchase mails',
                sort: true,
                width: 2,
            },
            {
                function: (record) =>
                    m(MultiSelect, {
                        options: record.persons_mailing_enabled_options,
                        selected: record.persons_mailing_enabled,
                        ...defaults,
                    }),
                name: 'Offer contacts',
                sort: true,
                width: 7,
            },
            {
                function: (record) =>
                    m(MultiSelect, {
                        options: record.persons_purchase_enquiries_enabled_options,
                        selected: record.persons_purchase_enquiries_enabled,
                        ...defaults,
                    }),
                name: 'Purchase contacts',
                sort: true,
                width: 7,
            },
            {
                field: 'client_status',
                function: (record) =>
                    select(
                        record.client_status,
                        $m.data.client_statuses.map((i) => [i, i]),
                        {empty_option: true, ...defaults},
                    ),
                name: 'Client Status',
                sort: true,
                width: 4,
            },
            {
                field: 'portal_level',
                function: (record) =>
                    select(record.portal_level, $m.data.supplier_portal_levels, {empty_option: true, ...defaults}),
                name: 'Portal Level',
                sort: true,
                width: 4,
            },
            {
                field: 'company_type',
                function: (record) =>
                    select(
                        record.company_type,
                        $m.data.company_types.map((i) => [i, i]),
                        {empty_option: true, ...defaults},
                    ),
                name: 'Company Type',
                sort: true,
                width: 4,
            },
            {
                field: 'operates_online',
                function: (record) => checkbox(record.operates_online, '', defaults),
                name: 'Operates Online',
                sort: true,
                width: 4,
            },
        ]
    }

    change_prop(artkey, property_name, value) {
        this.is_updating(true)
        const data = {artkey}
        data[property_name] = value

        api.callAndThen('suppliers.update_relation_communication_settings', data, {
            success: () => {
                notifier.notify('Updated!', 'success', 1500)
                this.is_updating(false)
                // Sync up underlying relations collection. This is done client side and not be fetching from server.
                // For the flattened properties extra effort has to be done to sync the collection.
                const relation = this.collection.items().filter((rel) => String(rel.artkey) === String(artkey))[0]
                if (property_name === 'persons_mailing_enabled') {
                    relation.contact_persons.forEach((i) => (i.should_receive_mails = false))
                    relation.contact_persons
                        .filter((i) => i.artkey in value)
                        .forEach((i) => (i.should_receive_mails = true))
                } else if (property_name === 'persons_purchase_enquiries_enabled') {
                    relation.contact_persons.forEach((i) => (i.should_receive_purchase_enquiries = false))
                    relation.contact_persons
                        .filter((i) => i.artkey in value)
                        .forEach((i) => (i.should_receive_purchase_enquiries = true))
                } else if (!['persons_mailing_enabled', 'persons_purchase_enquiries_enabled'].includes(property_name)) {
                    this.collection.update_item_property(artkey, property_name, value)
                }
            },
        })
    }

    on_prop_change(record, prop_name, method) {
        return afterUpdate(method.bind(this, record.artkey, prop_name), prop(record[prop_name]))
    }

    row_model(r) {
        const persons_mailing_enabled = r.contact_persons
            .filter((i) => i.should_receive_mails && i.is_deleted === false)
            .map((i) => String(i.artkey))
        r.persons_mailing_enabled = persons_mailing_enabled
        r.persons_mailing_enabled = this.on_prop_change(r, 'persons_mailing_enabled', this.change_prop)
        r.persons_mailing_enabled_options = r.contact_persons
            .filter((i) => i.is_deleted === false)
            .map((i) => [String(i.artkey), i.emailaddress])

        const persons_purchase_enquiries_enabled = r.contact_persons
            .filter((i) => i.should_receive_purchase_enquiries && i.is_deleted === false)
            .map((i) => String(i.artkey))
        r.persons_purchase_enquiries_enabled = persons_purchase_enquiries_enabled
        r.persons_purchase_enquiries_enabled = this.on_prop_change(
            r,
            'persons_purchase_enquiries_enabled',
            this.change_prop,
        )
        r.persons_purchase_enquiries_enabled_options = r.contact_persons
            .filter((i) => i.is_deleted === false)
            .map((i) => [String(i.artkey), i.emailaddress])

        r.is_supplier = this.on_prop_change(r, 'is_supplier', this.change_prop)
        r.is_buyer = this.on_prop_change(r, 'is_buyer', this.change_prop)
        r.should_receive_offer_mails = this.on_prop_change(r, 'should_receive_offer_mails', this.change_prop)
        r.should_receive_purchase_mails = this.on_prop_change(r, 'should_receive_purchase_mails', this.change_prop)
        r.client_status = this.on_prop_change(r, 'client_status', this.change_prop)
        r.company_type = this.on_prop_change(r, 'company_type', this.change_prop)
        r.operates_online = this.on_prop_change(r, 'operates_online', this.change_prop)
        r.portal_level = this.on_prop_change(r, 'portal_level', this.change_prop)

        return r
    }

    view() {
        return (
            <CollectionTable
                key="relation_communication"
                collection={this.collection}
                options={{
                    autoscale: true,
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                }}
                row_model={this.row_model.bind(this)}
                columns={this.columns}
            />
        )
    }
}
