import {accountIcon} from 'accounts.ls'
import {Collection} from 'components/collection/collection.ls'
import {countries} from '@bitstillery/common/lib/countries'
import {icon} from 'components/icon.ls'
import {Link, Spinner} from '@bitstillery/common/components'

export class DefaultView {
    collection: Collection

    constructor(vnode) {
        this.collection = vnode.attrs.collection
    }

    footer() {
        return (
            <tr>
                {!this.collection.search_result().length && !this.collection.loading() && (
                    <td className="table-footer" colspan="100%">
                        <div className="no-results">No results found</div>
                    </td>
                )}

                {!this.collection.loading() && this.collection.can_show_more_items() && (
                    <td className="table-footer" colspan="100%">
                        <button
                            disabled={this.collection.loading()}
                            onclick={this.collection.show_more}
                            className="btn btn btn-link show-more"
                        >
                            Show more results
                        </button>
                    </td>
                )}

                {this.collection.loading() && (
                    <td className="table-footer" colspan="100%">
                        <Spinner className="table-spinner" />
                    </td>
                )}
            </tr>
        )
    }

    icon_online(relation) {
        if (relation.operates_online) {
            return icon('fa-check')
        } else if (relation.operates_line === false) {
            return icon('fa-times')
        } else {
            return icon('fa-question')
        }
    }

    icon_verified(relation) {
        if (relation.is_verified) {
            return icon('fa-check')
        } else {
            return ''
        }
    }

    view() {
        return (
            <tbody className="c-supplier-list">
                {this.collection.search_result().map((relation, index) => (
                    <tr className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td className="col-sm-2 ellipsis">
                            <Link
                                account={relation.sales_account.slug}
                                href={`/crm/relations/${relation.artkey}/edit`}
                            >   <span className="mr-05">{accountIcon(relation.sales_account)}</span>
                                <span>{`${relation.name}`}</span>
                            </Link>

                        </td>
                        <td className="col-sm-1 ellipsis">{relation.street_address}</td>
                        <td className="col-sm-1 ellipsis">{relation.zip_code}</td>
                        <td className="col-sm-1 ellipsis">{relation.city}</td>
                        <td className="col-sm-1">
                            <span>{countries[relation.country_code]}</span>
                        </td>
                        <td className="col-sm-1">{relation.company_type}</td>
                        <td className="col-sm-1">{this.icon_online(relation)}</td>
                        <td className="col-sm-1">{relation.client_status}</td>
                        <td className="col-sm-1">{this.icon_verified(relation)}</td>
                        <td className="col-sm-1">{relation.purchase_manager.profile.name}</td>
                        <td className="col-sm-1">{relation.sales_manager.profile.name}</td>
                        <td className="col-sm-1">{relation.relation_nr}</td>
                        <td className="col-sm-2">
                            {relation.contact_persons
                                .filter((cp) => cp.is_deleted === false)
                                .slice(0, 4)
                                .map((i) => i.name)
                                .join(', ')}
                            {relation.contact_persons.length > 5 ? '...' : ''}
                        </td>
                    </tr>
                ))}

                {this.footer()}
            </tbody>
        )
    }
}
