import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface SupplierMemoAttrs {
    supplier_memo?: string
}

export class SupplierMemo extends MithrilTsxComponent<SupplierMemoAttrs> {
    view(vnode: m.Vnode<SupplierMemoAttrs>): m.Children {
        const memo = vnode.attrs.supplier_memo
        const children: m.Children = []
        if (memo) {
            children.push(<div class="alert alert-info">
                <span class="glyphicon glyphicon-info-sign" />
                <span class="sr-only">Memo:</span> {memo}
            </div>)
        }
        return children
    }
}
