m = require 'mithril'
luxon = require 'luxon'
{map} = require 'prelude-ls'

api = require 'api.ls'
app = require('@/app')
{button-with-icon, text-button} = require '@/components/buttons.ls'
{Button} = require '@bitstillery/common/components'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{contact_person_link, relation_link} = require '@/components/entity_links.ls'
inputs = require '@/components/inputs'

{PortalUser} = require 'portal/models.ls'
{format-date-time} = require 'utils.ls'
{Spinner} = require '@bitstillery/common/components'

module.exports = PortalUserSessions = (initial_vnode) ->
    user = new PortalUser
    to_date = window.prop luxon.DateTime.local!toISODate()
    from_date = window.prop luxon.DateTime.local!minus({ days: 7}).toISODate()

    artkey = m.route.param 'artkey'
    api.call-and-then 'portal.user_management.get_user', {artkey: artkey}, do
        success: (resp) ->
            user.load resp.result
        failure: (resp) ->
            app.notifier.notify 'Unknown portal user.', 'danger'
            m.route.set '/portal/portal-users'

    params = ->
        do
            artkey: artkey
            from_date: from_date!
            to_date: to_date!

    sessions = new Collection do
        api_function_name: 'portal.user_management.get_user_sessions'
        query_limit: 25
        sort_order: [
            {'name': 'created_on', 'direction': 'desc'}
            {'name': 'was_last_updated_on', 'direction': 'desc'}
            {'name': 'duration', 'direction': 'desc'}
            {'name': 'number_of_activities', 'direction': 'desc'}
        ]
        default_sort_by: 'created_on'
        default_sort_order: 'desc'
        filter_serverside: true
        paged: true
        additional_params: params

    return do
        oncreate: ->
            sessions.init!

        view: (vnode) -> [
            m '.btn-toolbar',
                button-with-icon 'Back to list', 'arrow-left', do
                    class: 'btn-default'
                    onclick: ~> m.route.set '/portal/portal-users'

            m 'h2' 'Portal user activity'

            m 'dl.dl-horizontal',
                m 'dt', 'Username'
                m 'dd', user.name!
                m 'dt', 'Contact person'
                m 'dd', contact_person_link user.contact_person!
                m 'dt', 'Relation'
                m 'dd', relation_link user.contact_person!supplier!
                m 'dt', 'Last seen'
                m 'dd', format-date-time user.last_seen_timestamp!
                m 'dt', 'Times seen'
                m 'dd', user.number_of_sessions!

            m '.c-filter-group',
                inputs.date from_date, {
                    label: 'From',
                    required: true
                }
                inputs.date to_date, {
                    label: 'To',
                    required: true
                }

            m Button, {
                className: 'mb-2',
                onclick: (e) ->
                    sessions.requery!
                text: 'Lookup Activity',
                type: 'success',
            }

            m CollectionTable, do
                collection: sessions
                options:
                    search_table_style: true
                    sticky_header: true
                    with_buttons: true
                    autoscale: true

                view_details: (record) ->
                    m PortalUserSessionActivity, {portal_user_session: record}

                columns:
                    do
                        width: 4
                        name: 'Started'
                        field: 'created_on'
                        sort: true
                        descending: true
                        transform: format-date-time
                    do
                        width: 4
                        name: 'Ended'
                        field: 'was_last_updated_on'
                        sort: true
                        descending: true
                        transform: format-date-time
                    do
                        width: 4
                        name: 'Duration (minutes)'
                        field: 'duration'
                        sort: true
                        descending: true
                    do
                        width: 4
                        name: 'Page views'
                        field: 'number_of_activities'
                        sort: true
                        descending: true
        ]


PortalUserSessionActivity = (initial_vnode) ->
    portal_user_session = initial_vnode.attrs.portal_user_session
    activities = window.prop []
    loading = window.prop true

    return do
        oncreate: ->
            api.call-and-then 'portal.user_management.get_session_activity', {artkey: portal_user_session.artkey}, do
                success: (resp) ->
                    activities resp.result
                    loading false

        view: (vnode) -> m 'span',
            m '.panel.panel-default',
                m '.panel-heading' m '.panel-title' 'Session data'
                m '.panel-body',
                if loading! then m Spinner else
                    m 'dl.dl-horizontal',
                        m 'dt', 'IP address'
                        m 'dd', portal_user_session.remote_ip_address
                        m 'dt', 'Country'
                        m 'dd', portal_user_session.country_name
                        m 'dt', 'Region'
                        m 'dd', portal_user_session.region_name
                        m 'dt', 'Location'
                        m 'dd',
                            if portal_user_session.longitude and portal_user_session.latitude then [
                                portal_user_session.latitude
                                ', '
                                portal_user_session.longitude
                            ]
            m '.panel.panel-default',
                m '.panel-heading' m '.panel-title' 'Page views'
                m '.panel-body',
                if loading! then m Spinner else
                    m 'table.table.table-condensed',
                        m 'thead.thead-default' do
                            m 'tr',
                                m 'th', 'Moment'
                                m 'th', 'Page'
                        m 'tbody',
                            activities!map (activity) ->
                                m 'tr',
                                    m 'td' format-date-time activity.created_on
                                    m 'td' activity.page
