m = require 'mithril'
{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'


module.exports = class WarehouseList
    is_match: (warehouse, term) ~>
        list = [
            warehouse.name.toLowerCase!indexOf(term) > -1,
        ]

        if warehouse.street_address
            list.push warehouse.street_address.toLowerCase!indexOf(term) > -1
        if warehouse.zip_code
            list.push warehouse.zip_code.toLowerCase!indexOf(term) > -1
        if warehouse.city
            list.push warehouse.city.toLowerCase!indexOf(term) > -1
        if warehouse.street_address
            list.push warehouse.emailaddress.toLowerCase!indexOf(term) > -1

        return or-list list

    view: ->
        m GenericList, do
            readable_entity_name: 'destination'
            base_url: '/data/warehouses/'
            list_api_call: 'destinations.get_all_destinations'
            is_match: @is_match
            columns:
                {key: 'name', label: 'Name'}
                {key: 'emailaddress', label: 'Email address'}
                {key: 'street_address', label: 'Street'}
                {key: 'zip_code', label: 'Zip code'}
                {key: 'city', label: 'City'}
                {key: 'country_code', label: 'Country code'}
                {key: 'destination_type', label: 'Type'}
            requery_subscribe_key: 'warehouses_updated'
