import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'

import {EditAssist, HorizontalForm} from '../components/form'

import {StockApi, UpdateItemQuickRequest} from '@/factserver_api/stock_api'
import {TextInput} from '@/components/html_components'

interface QuickItemEditorAttrs {
    item: {
        artkey: number
        bottle_gtin_code: string
        case_gtin_code: string
    }
    onchange: (item: UpdateItemQuickRequest) => unknown
}

export class QuickItemEditor extends MithrilTsxComponent<QuickItemEditorAttrs> {
    api = new StockApi()
    edit_assist: EditAssist
    update_item_request: UpdateItemQuickRequest

    constructor(vnode: m.Vnode<QuickItemEditorAttrs>) {
        super()
        this.edit_assist = new EditAssist(m.route)
        // We don't have an artkey in the route so set some overrides.
        this.edit_assist.artkey = vnode.attrs.item.artkey
        this.edit_assist.is_creating = false
        this.update_item_request = {
            item_artkey: vnode.attrs.item.artkey,
            bottle_gtin_code: vnode.attrs.item.bottle_gtin_code,
            case_gtin_code: vnode.attrs.item.case_gtin_code,
        }
    }

    save_item(update_item_request: UpdateItemQuickRequest, vnode: m.Vnode<QuickItemEditorAttrs>): void {
        this.api.update_item_quick(update_item_request).subscribe({
            next: () => {
                vnode.attrs.onchange(update_item_request)
                notifier.notify('Updated item successfully.', 'success')
            },
        })
    }

    view(vnode: m.Vnode<QuickItemEditorAttrs>): m.Children {
        return (

            <HorizontalForm
                edit_assist={this.edit_assist}
                onsubmit={() => this.save_item(this.update_item_request, vnode)}
                submit_title="Save Product"
            >
                <div className="fieldset">
                    <div className="field-group">
                        <TextInput
                            label="Bottle GTIN"
                            value={this.update_item_request.bottle_gtin_code}
                            oninput={(value: string) => (this.update_item_request.bottle_gtin_code = value)}
                            placeholder="12 or 13 digit code"
                        />

                        <TextInput
                            label="Case GTIN"
                            value={this.update_item_request.case_gtin_code}
                            oninput={(value: string) => (this.update_item_request.case_gtin_code = value)}
                            placeholder="12 or 13 digit code"
                        />
                    </div>
                </div>

            </HorizontalForm>

        )
    }
}
