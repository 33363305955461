m = require 'mithril'
api = require 'api.ls'
{a, preventDefault} = require 'utils.ls'
{Spinner} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
app = require('@/app')

module.exports = class SalesOrderView
    (vnode) ->
        @sales_order_artkey = +(m.route.param!artkey)
        @sales_order = window.prop null
        @pickup_warehouse = window.prop null
        @estimated_number_of_pallets = window.prop null
        @pickup_timeslot = do
            from_date: window.prop ''
            from_time: window.prop ''
            till_time: window.prop ''
        @drop_off_timeslot = do
            from_date: window.prop ''
            from_time: window.prop ''
            till_time: window.prop ''
        @default_unit_type = window.prop null
        @number_of_kilos = window.prop null
        @note = window.prop null
        @loading_reference = window.prop null

    oncreate: ~>
        data = do
            sales_order_artkey: @sales_order_artkey
        api.call-and-then 'sales.core.get_fast_sales_order_with_items', data, do
            success: (resp) ~>
                @sales_order resp.result
                @fetch_pickup_warehouse!
                if @sales_order!origin.artkey == 1  # Top logistics
                    @pickup_timeslot.from_time "08:00"
                    @pickup_timeslot.till_time "15:30"

        api.call-and-then 'sales.green.fetch_green_unit_types', {}, do
            success: (resp) ~>
                @default_unit_type resp.default_unit_type

    fetch_pickup_warehouse: ~>
        data = do
            artkey: @sales_order!origin.artkey
        api.call-and-then 'destinations.get_destination', data, do
            success: (resp) ~>
                @pickup_warehouse resp.result

    as_date_or_datetime: (date, time) ->
        return if time! then "#{date!}T#{time!}" else date!

    save: ~>
        pickup_from =  @as_date_or_datetime @pickup_timeslot.from_date, @pickup_timeslot.from_time
        pickup_till = @as_date_or_datetime @pickup_timeslot.from_date, @pickup_timeslot.till_time
        drop_off_from = @as_date_or_datetime @drop_off_timeslot.from_date, @drop_off_timeslot.from_time
        drop_off_till = @as_date_or_datetime @drop_off_timeslot.from_date, @drop_off_timeslot.till_time

        if drop_off_from > drop_off_till or pickup_from > pickup_till
            app.notifier.notify "Timeslot from must be before till timeslot", "warning"
            return

        data = do
            artkey: @sales_order_artkey
            unit_type_id: @default_unit_type!id
            weight_in_kilos: @number_of_kilos!
            quantity: @sales_order!number_of_cases
            estimated_number_of_pallets: @estimated_number_of_pallets!
            note: @note!
            loading_reference: @loading_reference!

        if @pickup_timeslot.from_date! != ''
            data.pickup_timeslot = do
                from: pickup_from
                till: pickup_till
        if @drop_off_timeslot.from_date! != ''
            data.drop_off_timeslot = do
                from: drop_off_from
                till: drop_off_till
        api.call-and-then 'sales.green.create_shipment_at_green', data, do
            success: (resp) ~>
                app.notifier.notify "Created a shipment request at green with reference #{resp.reference}, green: #{resp.green_reference}", 'info'
                m.route.set '/sales-orders/manage/' + @sales_order_artkey

    view: ~>
        if !@sales_order! or !@pickup_warehouse! or !@default_unit_type!
            return m Spinner

        m '.c-green-shipment',
            m '.btn-toolbar' {role: "group"},
                m 'button.btn.btn-default' {type: "button", \
                                        onclick: ~> m.route.set '/sales-orders/manage/' + @sales_order_artkey},\
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to order'

            m 'h2' "Create shipping request for #{@sales_order!reference}"

            m 'form.flex-form', {role: 'form', onsubmit: prevent-default @save},
                m '.fieldset-group',
                    m '.fieldset',
                        m '.field-readonly',
                            m '.key', 'Pickup'
                            m '.value',
                                m '' @sales_order!origin.name
                                m '' @pickup_warehouse!street_address
                                m '' "#{@pickup_warehouse!zip_code} #{@pickup_warehouse!city}"
                                m '' @pickup_warehouse!country_code

                        m '.field-group',
                            inputs.date @pickup_timeslot.from_date, {
                                label: 'Pickup from'
                            }


                        m '.field-group',
                            inputs.time @pickup_timeslot.from_time, {
                                label: 'Pickup from Timeslot'
                            }

                            inputs.time @pickup_timeslot.till_time, {
                                label: 'Pickup till Timeslot'
                            }


                    m '.fieldset',
                        m '.field-readonly',
                            m '.key', 'Drop off'
                            m '.value',
                                m '' @sales_order!destination.name
                                m '' @sales_order!destination.street_address
                                m '' "#{@sales_order!destination.zip_code} #{@sales_order!destination.city}"
                                m '' @sales_order!destination.country_code

                        inputs.date @drop_off_timeslot.from_date, {
                            label: 'Dropoff from'
                        }

                        m '.field-group',
                            inputs.time @drop_off_timeslot.from_time, {
                                label: 'Dropoff from Timeslot'
                            }
                            inputs.time @drop_off_timeslot.till_time, {
                                label: 'Dropoff till Timeslot'
                            }
                m '.fieldset',
                    if @sales_order!number_of_cases == 0
                        m '.field',
                            m '.illegal-situation', 'The order does not contain any cases! Transport is not possible.'
                    else if @sales_order!destination_location != null
                        m '.field', a do
                            m '.illegal-situation', 'The destination must be a warehouse. Free format destination is not supported.'
                    else
                        [
                            m '.field-readonly', a do
                                m '.key', 'Number of cases'
                                m '.value', @sales_order!number_of_cases + " " + @default_unit_type!name
                            m '.field', a do
                                m 'label', 'Loading reference'
                                inputs.text @loading_reference, do
                                    required: true
                            m '.field', a do
                                m 'label', 'Number of pallets'
                                inputs.number @estimated_number_of_pallets, do
                                    required: true
                            m '.field', a do
                                m 'label', 'Weight (kg)'
                                inputs.number @number_of_kilos, do
                                    required: true
                                    min: 0
                            m '.field',
                                m 'label', 'Note'
                                inputs.textarea(@note)
                        ]

                m 'button.btn.btn-success.btn-submit', {type: 'submit'}, 'Send to green'
