import {Observable} from 'rxjs'

import {Api} from './api'

export interface SearchSalesOrdersResponse {
    artkey: number
    reference: string
    relation_name: string
    combined_status: string
    was_last_updated_on: string
}

export interface SearchPurchaseOrdersResponse {
    artkey: number
    reference: string
    relation_name: string
    status: string
    was_last_updated_on: string
}

export interface SearchRelationsResponse {
    artkey: number
    name: string
    sales_manager_name: string
    country_code: string
    was_last_updated_on: string
}

export interface SearchContactPersonsResponse {
    artkey: number
    relation_name: string
    relation_artkey: number
    name: string
}

export interface SearchProductsResponse {
    artkey: number
    name: string
}

export interface SearchAvailableItemsResponse {
    artkey: number
    reference: string
    lot: string
    product_name: string
    number_of_cases_available: number
}

export interface SearchResult<T> {
    count: number
    search_results: T[]
}

export class SearchApi {
    api = new Api()

    search_sales_orders(search_terms: string): Observable<SearchResult<SearchSalesOrdersResponse>> {
        return this.api.post_request('search.search_sales_orders', {
            search_terms: search_terms,
        })
    }

    search_purchase_orders(search_terms: string): Observable<SearchResult<SearchPurchaseOrdersResponse>> {
        return this.api.post_request('search.search_purchase_orders', {
            search_terms: search_terms,
        })
    }

    search_relations(search_terms: string): Observable<SearchResult<SearchRelationsResponse>> {
        return this.api.post_request('search.search_relations', {
            search_terms: search_terms,
        })
    }

    search_contact_persons(search_terms: string): Observable<SearchResult<SearchContactPersonsResponse>> {
        return this.api.post_request('search.search_contact_persons', {
            search_terms: search_terms,
        })
    }

    search_products(search_terms: string): Observable<SearchResult<SearchProductsResponse>> {
        return this.api.post_request('search.search_products', {
            search_terms: search_terms,
        })
    }

    search_available_items(search_terms: string): Observable<SearchResult<SearchAvailableItemsResponse>> {
        return this.api.post_request('search.search_available_items', {
            search_terms: search_terms,
        })
    }
}
