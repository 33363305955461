import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Icon} from '@bitstillery/common/components/ui/icon/icon'

export interface PagerAttrs {
    fetch_page: (page_index: number) => void
    count: number
    page_size?: number
    max_links?: number
    display_page_count?: boolean
}

export class Pager extends MithrilTsxComponent<PagerAttrs> {
    fetch_page: (page_index: number) => void
    max_links: number
    page_size: number
    count: number
    display_page_count: boolean
    half_links: number
    number_of_pages: number
    selected: number
    page_links: number[]

    number_array = (start: number, end: number, length: number = end - start + 1) => {
        return Array.from({length}, (_, i) => start + i)
    }

    constructor(vn: m.Vnode<PagerAttrs>) {
        super()
        this.fetch_page = vn.attrs.fetch_page
        this.page_size = vn.attrs.page_size ?? 50
        this.max_links = vn.attrs.max_links ?? 5
        this.count = vn.attrs.count
        this.display_page_count = vn.attrs.display_page_count ?? true

        this.half_links = Math.floor(this.max_links / 2)
        this.number_of_pages = Math.ceil(this.count / this.page_size)
        const page_index = m.route.param('page')
        if (page_index && Number(page_index) !== 1) {
            this._correct_page_index(Number(page_index))
        } else {
            // If the page param is missing from the route we are on page one
            this.selected = 1
        }

        this.set_pager_links()
    }

    set_pager_links() {
        if (this.number_of_pages < this.max_links) {
            // If there are fewer pages than the max links, show all pages
            this.page_links = this.number_array(1, this.number_of_pages)
        } else if (this.selected <= this.half_links) {
            // If the selected page number is smaller than the half of the max links, show the first till the max_links
            this.page_links = this.number_array(1, this.max_links)
        } else if (this.selected >= this.number_of_pages - this.half_links) {
            // If we are on the last pages, show the last page - the max links to the last page
            this.page_links = this.number_array(Math.max(this.number_of_pages - this.max_links + 1, 1), this.number_of_pages)
        } else {
            // Else show half number of links before and after the selected page
            this.page_links = this.number_array(this.selected - this.half_links, this.selected + this.half_links)
        }
    }

    change_page(page_index: number): void {
        this.selected = page_index
        this.set_pager_links()

        this.fetch_page(page_index)
    }

    _correct_page_index(page_index: number): void {
        if (isNaN(page_index)) {
            this.change_page(1)
        } else if (this.number_of_pages && page_index > this.number_of_pages) {
            this.change_page(this.number_of_pages)
        } else {
            this.selected = page_index
        }
    }

    view(): m.Children {
        let result_count = `${this.count} result${this.count !== 1 ? 's ' : ' '}`
        if (this.display_page_count) {
            result_count += ` ${this.number_of_pages} page${this.number_of_pages !== 1 ? 's ' : ' '}`
        }

        return <span className={'c-pager'}>
            {this.number_of_pages > 1 && (
                <nav aria-label={'Page navigation'}>
                    <ul class={'pagination'}>
                        {this.selected > 1 && [
                            <li>
                                <a onclick={() => this.change_page(1)}>
                                    <Icon name={'chevronLeftDouble'} />
                                </a>
                            </li>,
                            <li>
                                <a onclick={() => this.change_page(this.selected - 1)}>
                                    <Icon name={'chevronLeft'} />
                                </a>
                            </li>,
                        ]}
                        {this.page_links.map((page_index: number) => {
                            return <li class={this.selected === page_index ? 'active' : undefined}>
                                <a onclick={() => this.change_page(page_index)}>
                                    {page_index}
                                </a>
                            </li>
                        })}
                        {this.selected < this.number_of_pages && [
                            <li>
                                <a onclick={() => this.change_page(this.selected + 1)}>
                                    <Icon name={'chevronRight'} />
                                </a>
                            </li>,
                            <li>
                                <a onclick={() => this.change_page(this.number_of_pages)}>
                                    <Icon name={'chevronRightDouble'} />
                                </a>
                            </li>,
                        ]}
                    </ul>
                </nav>
            )}
            {this.count === 0 && <span>No results</span>}
            {this.count > 0 && <span className={'result-count'}>{result_count}</span>}
        </span>
    }
}
