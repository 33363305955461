m = require 'mithril'
{filter, find, sort-by, map} = require 'prelude-ls'
api = require '../api.ls'
{ContactPerson} = require '@/models/data'


export class PortalUser
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @password = window.prop ''
        @last_seen_timestamp = window.prop ''
        @contact_person_artkey = window.prop ''
        @contact_person = window.prop new ContactPerson
        @number_of_sessions = window.prop ''

        @load(json)

    load: (json) ->
        for prop of json
            if prop == 'contact_person' then
                @contact_person_artkey json[prop].artkey
                @contact_person new ContactPerson json[prop]
            else if prop == 'language' then
                @[prop] = json[prop]
            else
                @[prop] = window.prop json[prop]


export class PortalNewsItem
    (json) ->
        @artkey = window.prop ''
        @title = window.prop ''
        @date = window.prop ''
        @content = window.prop ''
        @creator = window.prop {}
        @creator_artkey = window.prop ''

        for prop of json
            if prop == 'creator' then
                @creator_artkey json[prop].artkey
                @creator json[prop]
            else
                @[prop] = window.prop json[prop]


export  class PortalTip
    (json) ->
        @artkey = window.prop ''
        @title = window.prop ''
        @content = window.prop ''
        @creator = window.prop {}
        @creator_artkey = window.prop ''

        for prop of json
            if prop == 'creator' then
                @creator_artkey json[prop].artkey
                @creator json[prop]
            else
                @[prop] = window.prop json[prop]


export  class PortalQuote
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @content = window.prop ''
        @creator = window.prop {}
        @creator_artkey = window.prop ''
        @image_name = window.prop ''

        for prop of json
            if prop == 'creator' then
                @creator_artkey json[prop].artkey
                @creator json[prop]
            else
                @[prop] = window.prop json[prop]
