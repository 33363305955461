m = require 'mithril'
api = require 'api.ls'
{a, prevent-default} = require 'utils.ls'
mutation_panel = require './components/mutation_panel.ls'
inputs = require '@/components/inputs'
{Button} = require '@bitstillery/common/components'
{ItemMutation, ItemMutationStatus} = require '@/models/stock'
app = require('@/app')

module.exports = class MutationInStock
    (vnode) ->
        @item_mutation_reference = m.route.param 'reference'
        @mutation = window.prop new ItemMutation
        @get_mutation @item_mutation_reference

    get_mutation: (item_mutation_reference) ~>
        data = do
            item_mutation_reference: item_mutation_reference
            status: ItemMutationStatus.INTRANSIT
        api.call2 'stock.get_item_mutation', data, (resp) ~>
            @mutation new ItemMutation resp.result
            @mutation!targets!map (item) ~>
                if item.loendersloot_inspection_item!
                    if not item.lot!
                        item.lot item.loendersloot_inspection_item!lot
                    if not item.bottle_gtin_code!
                        item.bottle_gtin_code item.loendersloot_inspection_item!bottle_gtin

    bring_in_stock: ~>
        lots = []
        unfilled_lot_number = false

        for item in @mutation!targets!
            if item.lot!
                lots.push {
                    reference: item.reference!,
                    lot: item.lot!,
                    bottle_gtin_code: item.bottle_gtin_code! or null,
                }
            else
                unfilled_lot_number = true

        if unfilled_lot_number
            if !confirm "Not all lot numbers have been filled. Click OK if you still want to bring the items with filled lot numbers in stock"
                return

        data = do
            item_mutation_reference: @item_mutation_reference
            lots: lots
        api.call2 'stock.instock_item_mutation', data, (resp) ~>
            app.$m.common.observable.broadcast 'stock_updated', ''
            app.notifier.notify 'The items were brought in stock successfully.', 'success'
            m.route.set "/stock/mutations/manage/#{@item_mutation_reference}"


    view: -> m '.c-stock-mutation-non-loe view',
        m '.btn-toolbar',
            m Button, {
                active: false,
                className: 'btn-back',
                icon: 'back',
                onclick: ~>
                    m.route.set "/stock/mutations/manage/#{@mutation!reference!}"
                variant: 'toggle'
            }

        if @mutation! then
            mutation_panel.mutation_panel @mutation,
                m 'form.form-horizontal' {onsubmit: prevent-default @bring_in_stock},
                    m '.panel.panel-default' a do
                        m '.panel-heading' m '.panel-title' 'Enter lot numbers'
                        m '.panel-body' m 'table.table' a do
                            m 'thead.thead-default', m 'tr' a do
                                m 'th' 'P.O. #'
                                m 'th' 'Warehouse'
                                m 'th' 'Lot #'
                                m 'th' 'Bottle GTIN'
                                m 'th' 'Product'
                                m 'th.number' 'Btl / cs'
                                m 'th.number' 'Size'
                                m 'th.number' 'Alc %'
                                m 'th' 'Ref'
                                m 'th' 'GB'
                                m 'th' 'Tax label'
                                m 'th.number' 'Cases'
                                m 'th' 'Customs status'
                            m 'tbody' @mutation!targets!map (item) ~>
                                if item.is_in_transit!
                                    m 'tr' a do
                                        m 'td' m 'a' {onclick: ~> m.route.set "/purchase-orders/manage/#{item.purchase_order_item!purchase_order!reference!}"} item.purchase_order_item!purchase_order!reference!
                                        m 'td' @mutation!target_warehouse!name!
                                        m 'td' inputs.text(item.lot, {required: false})
                                        m 'td' inputs.text(item.bottle_gtin_code, {required: false})
                                        m 'td' item.bottle!product!name!
                                        m 'td.number' item.number_of_bottles_per_case!
                                        m 'td.number' item.bottle!display_volume!
                                        m 'td.number' item.bottle!display_alcohol_percentage!
                                        m 'td' item.bottle!refill_status!
                                        m 'td' item.gift_box_type!
                                        m 'td' item.tax_label!
                                        m 'td.price' item.number_of_cases!
                                        m 'td' item.customs_status!
                    m Button, {
                        icon: 'stock',
                        text: app.$t('stock.actions.bring_in_stock'),
                        type: 'info',
                        onclick: ~> @bring_in_stock()
                    }
