m = require 'mithril'
GenericEdit = require '../generic/generic_edit.ls'
{Field} = require '../generic/generic_field.ls'
app = require('@/app')

module.exports = class InsuranceTypeEdit
    ->
        @fields = [
            new Field 'Name', 'name', 'input', 'text'
            new Field 'Fee (permillage)', 'fee', 'input', 'number', {min: 0, step: 0.0001}
        ]

    view: ->
        m GenericEdit, do
            readable_entity_name: 'Insurance type'
            base_url: '/data/insurancetypes/'
            query_api_call: 'insurancetypes.get_insurancetype'
            update_or_create_api_call: 'insurancetypes.update_or_create_insurancetype'
            fields: @fields
            create_entity_func: app.$m.data.insurance_type.create_insurance_type
            broadcast_on_update: 'insurancetypes_updated'
