import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'

interface DetailRowAttrs {
    label: string | JSX.Element
    value: string | JSX.Element
    visible?: boolean
    hide_on_no_value?: boolean
    value_class?: string
}

export class DetailRow extends MithrilTsxComponent<DetailRowAttrs> {
    view(vnode: m.Vnode<DetailRowAttrs, this>): m.Children {
        if (vnode.attrs.visible !== undefined && !vnode.attrs.visible) {
            return []
        }
        if (vnode.attrs.hide_on_no_value && !vnode.attrs.value) {
            return []
        }
        return <div className={'c-detail-label'}>
            <div className={'detail-label'}>{vnode.attrs.label}</div>
            <div className={classes('detail-value', vnode.attrs.value_class)}>{vnode.attrs.value}</div>
        </div>
    }
}
