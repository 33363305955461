m = require 'mithril'
{map, head, filter, or-list, find} = require 'prelude-ls'
{a, prevent-default}: utils = require 'utils.ls'
api = require 'api.ls'
{Popover} = require '@/components/popover/popover.ls'
inputs = require '@/components/inputs'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
discover_link = require '@/market/components/discover_link'
confirmation = require '@/components/confirmation.ls'
fixed-table-header = require '@/components/table/fixed-header.ls'
{IncotermsDropDown} = require '@/components/incoterms'
{IncotermsDropDownData} = require '@/factserver_api/incoterms_api'
{DestinationsDropDown} = require '@/components/destinations'
{DestinationsDropDownData} = require '@/factserver_api/destinations_api'
{Button, ButtonGroup, DataCard, Icon} = require '@bitstillery/common/components'
{Item, ItemMutation, ItemMutationPart, StockSearchMode} = require '@/models/stock'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

module.exports = class MoveToWarehouse
    ->
        @mutation = window.prop new ItemMutation
        @mutation!incoterm 'EXW'
        @edit = window.prop false
        @parts_to_delete = window.prop []
        @is_loading = window.prop false
        @is_loading_item_mutation = window.prop false

        @target_warehouse_artkey = window.prop ''

        @item_mutation_reference = m.route.param 'reference'

        if @item_mutation_reference
            @edit true
            @get_mutation(@item_mutation_reference)

        @stock = new Collection do
            api_function_name: 'stock.get_stock_records'
            filter_function: @is_match
            query_limit: 50
            sort_order: [
                {'name': 'bottle_volume', 'direction': 'desc'},
                {'name': 'bottle_alcohol_percentage', 'direction': 'desc'},
                {'name': 'bottle_refill_status', 'direction': 'desc'},
                {'name': 'artkey', 'direction': 'asc'},
            ]
            default_sort_by: 'purchase_order_reference'
            default_sort_order: 'desc'
            additional_params: @additional_params
            data_key: "stock_items"

        if not @stock.mode then @stock.mode = window.prop StockSearchMode.INSTOCK_ONLY

        @search_input_ctrl = new SearchInput.controller do
            collection: @stock
            placeholder: 'Search for products, specs, order reference, lot or warehouse...'

    oncreate: ->
        @stock.init!

    update_target_warehouse: (artkey) ~>
        @target_warehouse_artkey artkey
        @mutation!target_warehouse_artkey artkey
        if @target_warehouse_artkey!
            DestinationsDropDownData.get(+@target_warehouse_artkey!).subscribe(
            (response) ~>
                @mutation!target_warehouse!name response.name
            )

    get_mutation: (item_mutation_reference) ~>
        @is_loading_item_mutation true
        data = do
            item_mutation_reference: item_mutation_reference
        api.call-and-then 'stock.get_item_mutation' data, do
            success: (resp) ~>
                @mutation new ItemMutation resp.result
            final: (resp) ~>
                @is_loading_item_mutation false

    source_warehouse_artkey: ~>
        if @mutation!sources!length == 0 then
            ''
        else
            @mutation!sources![0].warehouse_artkey!

    toggle_mode: (value) ~>
        @stock.mode value
        @stock.requery!

    additional_params: ~>
        return {mode: @stock.mode!}

    is_match: (stock, term, additional_params) ~>
        if additional_params.mode == StockSearchMode.AVAILABLE_ONLY and stock.number_of_cases_available <= 0
            return false
        else if additional_params.mode == StockSearchMode.INSTOCK_ONLY and stock.number_of_cases_in_stock <= 0
            return false
        else if additional_params.mode == StockSearchMode.AVAILABLE_OR_INSTOCK and (stock.number_of_cases_in_stock <= 0 and stock.number_of_cases_available <= 0)
            return false
        return or-list [
            stock.product_name.toLowerCase!indexOf(term) > -1,
            stock.product_category_name.toLowerCase!indexOf(term) > -1,
            +stock.bottle_volume == +term,
            +stock.bottle_alcohol_percentage == +term,
            stock.bottle_refill_status.toLowerCase! == term,
            stock.case_gift_box_type.toLowerCase!indexOf(term) > -1,
            stock.case_tax_label.toLowerCase!indexOf(term) > -1,
            stock.warehouse_name.toLowerCase!indexOf(term) > -1,
            stock.purchase_order_artkey == +term,
            stock.purchase_order_reference.toLowerCase! == term,
            stock.item_lot.toLowerCase! == term,
            stock.item_reference == term
        ]

    in_items_to_move: (item_reference) ~>
        result = @mutation!parts! |>
            find (part) ->
                part.sources! |> find (.reference! == item_reference)

        result?

    add_item: (item_reference) ~>
        if @in_items_to_move(item_reference)
            app.notifier.notify 'Item already on list of items to move.', 'info'
            return

        data = do
            item_reference: item_reference

        api.call2 'stock.get_item', data, (resp) ~>
            source_item = new Item resp.result

            if @source_warehouse_artkey! != '' and @source_warehouse_artkey! != source_item.warehouse_artkey!
                app.notifier.notify 'Item is not from the same warehouse as the rest', 'warning'
                return

            number_of_cases_in_stock = resp.result.number_of_cases_in_stock
            resp.result.number_of_cases_in_stock = 0
            leftover_item = new Item resp.result

            resp.result.warehouse = {artkey: @mutation!target_warehouse_artkey!}
            resp.result.lot = ''
            resp.result.entry_date = ''
            resp.result.number_of_cases = number_of_cases_in_stock
            resp.result.number_of_shipped_cases = 0
            target_item = new Item resp.result

            part = new ItemMutationPart {}
            part.item_mutation @mutation!
            part.item_mutation_artkey @mutation!artkey
            part.sources [source_item]
            part.targets [leftover_item, target_item]

            @mutation!parts!push part

    create_or_update_warehouse_move: ~>
        if @mutation!parts!length == 0
            if @mutation!artkey! == ''
                app.notifier.notify 'No items added yet.' 'danger'
                return
            else
                confirmation.show(do
                    title: 'Delete mutation'
                    message: 'No items are left, do you want to delete this mutation?'
                    onconfirm: ~>
                        data = do
                            item_mutation_artkey: @mutation!artkey!
                        api.call 'stock.delete_warehouse_move_mutation', data, (resp) ~>
                            if resp.success
                                app.notifier.notify 'Successfully deleted mutation.' 'success'
                                app.$m.common.observable.broadcast 'mutations_updated'
                                m.route.set '/stock/mutations/manage'
                            else
                                if resp.message
                                    app.notifier.notify result.message, 'danger'
                                else
                                    app.$m.common.generic_error_handler!
                    unique_name: 'warehouse_move_mutation_delete_confirmation'
                )
                return

        items = @mutation!parts! |> filter ((part) -> part.target_item!) |> map (part) -> do
            source_item_artkey: part.source_item!.artkey!
            cases_to_move: part.target_item!.number_of_cases!

        @is_loading true
        data = do
            user_artkey: app.$s.identity.artkey
            item_mutation_artkey: @mutation!artkey!
            target_warehouse_artkey: @mutation!target_warehouse_artkey!
            expected_delivery_date: @mutation!expected_delivery_date!
            description: @mutation!description!
            parts_to_delete: @parts_to_delete!
            items: items
            incoterm: @mutation!incoterm!
            incoterm_location: @mutation!incoterm_location!
            number_of_block_pallets: @mutation!number_of_block_pallets!
            number_of_euro_pallets: @mutation!number_of_euro_pallets!

        api.call-and-then 'stock.create_or_update_warehouse_move_mutation', data, do
            success: (resp) ~>
                app.$m.common.observable.broadcast('stock_updated')
                is_created = @mutation!artkey! == ''

                @parts_to_delete []
                @mutation new ItemMutation resp.result

                created_or_updated = if is_created then 'created' else 'updated'
                app.notifier.notify "Successfully #{created_or_updated} warehouse move mutation." 'success'
                app.$m.common.observable.broadcast 'mutations_updated'

                if is_created
                    reference = @mutation!reference!
                    m.route.set "/stock/mutations/manage/#{reference}"
            failure: (resp) ~>
                app.notifier.notify resp.message, 'danger'
            final: (resp) ~>
                @is_loading false

    delete_part: (part) ~>
        if part.artkey!
            @parts_to_delete!push part.artkey!
        @mutation!parts <| @mutation!parts!filter (!= part)

    view: -> m '.c-move-to-warehouse view', a do
        m '.btn-toolbar',
            m Button, {
                active: false,
                icon: 'back',
                onclick: ~> m.route.set '/stock/mutations/manage/' + @mutation!reference!,
                variant: 'toggle',
            }

        if @edit!
            m DataCard, {
                className: 'box-small mb-2',
                model: do
                    data: [
                        {label: 'Reference', value: @mutation!reference!},
                        {label: 'Creation Date', value: utils.format-date(@mutation!created_on!)}
                        {label: 'Created by', value: @mutation!user!profile!name!},
                    ]
                type: 'dense'
            }

        m 'form',
            m '.fieldset-group',
                m '.fieldset',
                    m '.field', a do
                        m 'label', 'Target Warehouse'
                        m DestinationsDropDown,
                            selected_destination_artkey: @target_warehouse_artkey
                            onchange: (artkey) ~> @update_target_warehouse artkey
                            required: true
                            get_all_for_drop_down_response$: DestinationsDropDownData.destinations()

                    m '.field',
                        m 'label', {for: 'incoterm'}, 'Incoterm'
                        m IncotermsDropDown,
                            selected_incoterm: @mutation!incoterm!
                            required: true
                            get_all_for_drop_down_response$: IncotermsDropDownData.incoterms()
                            onchange: (incoterm) ~> @mutation!incoterm incoterm
                    m '.field',
                        m 'label', {for: 'incoterm_location'}, 'Incoterm location'
                        m 'input#incoterm_location.form-control', do
                            type: 'text'
                            value: @mutation!incoterm_location!
                            oninput: (ev) ~> @mutation!incoterm_location ev.target.value


                m '.fieldset',
                    m '.field-group',
                        m '.field', a do
                            m 'label', 'Number of euro pallets'
                            inputs.number(@mutation!number_of_euro_pallets)
                        m '.field', a do
                            m 'label', 'Number of block pallets'
                            inputs.number(@mutation!number_of_block_pallets)
                    m '.field', a do
                        m 'label', 'Expected delivery date'
                        inputs.date(@mutation!expected_delivery_date)
                    m '.field', a do
                        m 'label', 'Description'
                        inputs.textarea(@mutation!description)

            m '.panel.panel-success' a do
                m '.panel-heading' m '.panel-title' if @edit! then 'Add extra items' else 'Items to move'
                m '.panel-body',
                    if @is_loading_item_mutation!
                        m Spinner
                    else if @mutation!parts!length > 0
                        m 'table.table' a do
                            m 'thead.thead-default.stock-table'
                                m 'tr' a do
                                    m 'th' '#'
                                    m 'th' 'Source warehouse'
                                    m 'th' 'Lot #'
                                    m 'th' 'Product'
                                    m 'th.number' 'Btl / cs'
                                    m 'th.number' 'Size / Alc % / Ref'
                                    m 'th.number' 'Cases'
                                    m 'th.number' 'Stock'
                                    m 'th' 'Target warehouse'
                                    m 'th' 'Cases to move'
                                    m 'th' 'Leftover cases'
                                    m 'th' # buttons
                            @mutation!parts!filter( (part) -> part.target_item!).map (part) ~>
                                number_of_cases_in_stock = part.source_item!number_of_cases_in_stock!
                                    # If the item is already mutated (e.g. this is an update) we need to compute the
                                # number_of_cases_in_stock by subtracting the number of shipped cases from the
                                # leftover item from the total number of cases of the source item.
                                if part.artkey! != ''
                                    number_of_cases_in_stock = part.source_item!number_of_cases! - \
                                        if part.leftover_item! then part.leftover_item!number_of_shipped_cases! else 0

                                purchase_order = part.source_item!purchase_order_item!purchase_order!
                                m 'tbody.table-row' m 'tr' a do
                                    m 'td' m m.route.Link, {target: '_blank', \
                                        href: "/purchase-orders/manage/#{purchase_order.artkey!}"},
                                        purchase_order.reference!
                                    m 'td' if part.source_item!warehouse_artkey! then part.source_item!warehouse!name!
                                    m 'td' m 'samp' part.source_item!lot!
                                    m 'td' part.source_item!bottle!product!name!
                                    m 'td.number' part.source_item!number_of_bottles_per_case!
                                    m 'td',
                                        [ part.source_item!bottle!display_volume!
                                        , part.source_item!bottle!display_alcohol_percentage!
                                        , part.source_item!bottle!refill_status!
                                        ] * ' '
                                    m 'td.number' part.source_item!number_of_cases!
                                    m 'td.number' number_of_cases_in_stock
                                    m 'td' if typeof @mutation!target_warehouse!name === 'function' then @mutation!target_warehouse!name()
                                    m 'td' inputs.number part.target_item!number_of_cases, do
                                        min: 1
                                        max: number_of_cases_in_stock
                                        required: true
                                    m 'td', number_of_cases_in_stock - part.target_item!number_of_cases!
                                    m 'td.actions',
                                        m '.actions-group',
                                            m ButtonGroup,
                                                m Button, {
                                                    icon: 'trash'
                                                    onclick: @delete_part.bind(@, part),
                                                    type: 'danger'
                                                }

                    else 'No items added yet.'


            m Button, {
                className: 'mb-2',
                disabled: @is_loading!,
                onclick: ~> @create_or_update_warehouse_move()
                text: if @edit! then 'Save Mutation' else 'Create Mutation',
                type: 'success'
            }


        m '.c-filter-group',
            SearchInput.view @search_input_ctrl
            inputs.select(@stock.mode, StockSearchMode.options, {onchange: @toggle_mode, empty_option: false})

        fixed-table-header.without-buttons m 'table.table.search-table.clickable',
            m 'colgroup',
                m 'col' {style: "width: 4%"} # purchase_order_reference
                m 'col' {style: "width: 5%"} # purchase_order_item_intake_date
                m 'col' {style: "width: 6%"} # warehouse_name
                m 'col' {style: "width: 9%"} # item_lot
                m 'col' {style: "width: 2%"} # discover link
                m 'col' {style: "width: 10%"} # product_name
                m 'col' {style: "width: 3%"} # item/poi number_of_bottles_per_case (see factserver)
                m 'col' {style: "width: 4%"} # bottle_volume
                m 'col' {style: "width: 4%"} # bottle_alcohol_percentage
                m 'col' {style: "width: 3%"} # bottle_refill_status
                m 'col' {style: "width: 5%"} # case_gift_box_type
                m 'col' {style: "width: 5%"} # case_tax_label
                m 'col' {style: "width: 3%"} # item_number_of_cases_in_stock
                m 'col' {style: "width: 3%"} # item_number_of_cases_in_purchase
                m 'col' {style: "width: 3%"} # item/purchase_order_item number_of_cases_in_sales (see factserver)
                m 'col' {style: "width: 3%"} # item/purchase_order_item number_of_cases_available (see factserver)
                m 'col' {style: "width: 3%"}


            m 'thead.thead-default.stock-table' m 'tr', a do
                m 'th' {onclick: @stock.sort.bind(@, 'purchase_order_reference')} '# ',
                    @stock.sort_icon 'purchase_order_reference'
                m 'th' {onclick: @stock.sort.bind(@, 'date')} 'Intake ',
                    @stock.sort_icon 'date'
                m 'th' {onclick: @stock.sort.bind(@, 'warehouse_name')} 'Warehouse ',
                    @stock.sort_icon 'warehouse_name'
                m 'th' {onclick: @stock.sort.bind(@, 'item_lot')} 'Lot # ',
                    @stock.sort_icon 'item_lot'
                m 'th' {onclick: @stock.sort.bind(@, 'product_name')} 'Product ',
                    @stock.sort_icon 'product_name'
                m 'th.number' {onclick: @stock.sort.bind(@, 'item_number_of_bottles_per_case')} 'Btl / cs ',
                    @stock.sort_icon 'item_number_of_bottles_per_case'
                m 'th.number' {onclick: @stock.sort.bind(@, 'bottle_volume')} 'Size ',
                    @stock.sort_icon 'bottle_volume'
                m 'th.number' {onclick: @stock.sort.bind(@, 'bottle_alcohol_percentage')} 'Alc % ',
                    @stock.sort_icon 'bottle_alcohol_percentage'
                m 'th' {onclick: @stock.sort.bind(@, 'bottle_refill_status')} 'Ref ',
                    @stock.sort_icon 'bottle_refill_status'
                m 'th' {onclick: @stock.sort.bind(@, 'case_gift_box_type')} 'GB ',
                    @stock.sort_icon 'item_gift_box_type'
                m 'th' {onclick: @stock.sort.bind(@, 'case_tax_label')} 'Tax label ',
                    @stock.sort_icon 'item_tax_label'
                m 'th.number' {onclick: @stock.sort.bind(@, 'item_number_of_cases_in_purchase')} 'P.O. ',
                    @stock.sort_icon 'item_number_of_cases_in_purchase'
                m 'th.number' {onclick: @stock.sort.bind(@, 'item_number_of_cases_in_stock')} 'Stock ',
                    @stock.sort_icon 'item_number_of_cases_in_stock'
                m 'th.number' {onclick: @stock.sort.bind(@, 'item_number_of_cases_in_sales')} 'S.O. ',
                    @stock.sort_icon 'item_number_of_cases_in_sales'
                m 'th.number' {onclick: @stock.sort.bind(@, 'item_number_of_cases_available')} 'Avail. ',
                    @stock.sort_icon 'item_number_of_cases_available'
                m 'th' {onclick: @stock.sort.bind(@, 'case_customs_status')} 'Cus. ',
                    @stock.sort_icon 'case_customs_status'
                m 'th' ''

            @stock.search_result!map (stock_record, index) ~>
                m 'tbody.table-row' m 'tr' {onclick: @toggle_view_details}, a do
                    m 'td' m m.route.Link, {target: '_blank', \
                        href: "/purchase-orders/manage/#{stock_record.purchase_order_artkey}"},
                        stock_record.purchase_order_reference
                    m 'td' utils.format-date(stock_record.item_entry_date) || '-'
                    m 'td.ellipsis' m 'span' stock_record.warehouse_name
                    m 'td' if stock_record.item_lot then m 'samp' stock_record.item_lot else
                        if stock_record.item_is_target_in then
                            m Popover, do
                                title: 'Warehouse move'
                                content: "Part of <a href='#!/stock/mutations/manage/#{stock_record.item_mutation_reference}' target='_blank'>mutation #{stock_record.item_mutation_reference}</a>"
                            , m 'span.glyphicon.glyphicon-plane'
                        else
                            m Popover, do
                                title: 'Purchase order'
                                content: 'This is a purchase order item'
                            , m 'span.glyphicon.glyphicon-shopping-cart'

                    m 'td.ellipsis' m 'span' stock_record.product_name
                    m 'td.number' stock_record.item_number_of_bottles_per_case
                    m 'td.number' (+stock_record.bottle_volume).toFixed(1), 'cl'
                    m 'td.number' (+stock_record.bottle_alcohol_percentage).toFixed(1), '%'
                    m 'td' stock_record.bottle_refill_status
                    m 'td' stock_record.case_gift_box_type
                    m 'td' stock_record.case_tax_label
                    m 'td.number' stock_record.item_number_of_cases_in_purchase
                    m 'td.number' stock_record.item_number_of_cases_in_stock || '-'
                    m 'td.number' stock_record.item_number_of_cases_in_sales
                    m 'td.number' stock_record.item_number_of_cases_available
                    m 'td' stock_record.case_customs_status
                    m 'td.actions',
                        m '.actions-group',
                            m ButtonGroup,
                                m Button, {
                                    icon: if @in_items_to_move(stock_record.item_reference) then 'save' else 'plus',
                                    tip: 'Add to "Items to move list"'
                                    type: if @in_items_to_move(stock_record.item_reference) then 'info' else 'default',
                                    onclick: ~> @add_item stock_record.item_reference,
                                }

                                m Button, {
                                    icon: 'search',
                                    onclick: ~> window.open(discover_link.from_properties(
                                        stock_record.product_name,
                                        stock_record.bottle_volume,
                                        stock_record.bottle_alcohol_percentage
                                    ))
                                    type: 'info'
                                }

        if not @stock.search_result!length and not @stock.loading!
            'No results found.'
        if @stock.loading!
            m  Spinner
        else if @stock.can_show_more_items!
            m Button, {
                icon: 'chevronRightDouble',
                onclick: @stock.show_more
                text: app.$t('table.fetch_more'),
                type: 'info'
            }
