m = require 'mithril'
{filter} = require 'prelude-ls'
{classes} = require '@bitstillery/common/lib/utils'


# A group of checkboxes for a single filter.
export class CheckboxGroup
    (vnode) ~>
        @filter_function = vnode.attrs.filter_function
        @filter_id = vnode.attrs.filter_id
        @filter_name = vnode.attrs.filter_name
        @filter_options = vnode.attrs.filter_options
        @show_no_value_checkbox = vnode.attrs.show_no_value_checkbox || false

        # Define ids for checkboxes. These are used to fetch the checkboxes in
        # the toggle_all function - see the comment there.
        @select_all_id = "#{@filter_id}_select_all"
        @checkbox_ids = ["#{@filter_id}_#{option_value}" for [option_value, option_name] in @filter_options]
        if @show_no_value_checkbox
            @checkbox_ids = @checkbox_ids.concat(["#{@filter_id}_"])

        # Show the checkbox group collapsed (true) or not collapsed (false).
        # Initially, collapsed is true if no checkboxes are checked.
        @collapsed = @filter_function!length == 0

        # List of values to filter on, corresponding to the checked
        # checkboxes. Initialized on the already selected filter values.
        @to_filter = @filter_function!

    onupdate: ~>
        # If the result of @filter_function changes (e.g. when loading a
        # filter preset), update the checkbox group to reflect the new value
        # of @filter_function.
        if @to_filter != @filter_function!
            # Set the checkboxes' checked parameters to the new value.
            check_select_all = true
            for checkbox_id in @checkbox_ids
                checkbox = document.getElementById(checkbox_id)
                checked = (checkbox.value in @filter_function!)
                checkbox.checked = checked
                if not checked then check_select_all = false

            # Also (un)check the select all checkbox, if needed.
            document.getElementById(@select_all_id).checked = check_select_all

            # (De-)collapse the checkbox group.
            @collapsed = @filter_function!length == 0

            # Finally, synchronize the checkbox group's internal state.
            @to_filter = @filter_function!

    # Helper function to add a checkbox to the CheckboxGroup.
    add_checkbox: (option_value, option_name) ~>
        m '.c-field-checkbox field',
            m '.control',
                m 'input', @checkbox_attrs option_value
                m 'label' {for: "#{@filter_id}_#{option_value}"} " #{option_name}"

    # Helper function to determine the attrs for a checkbox in the
    # CheckboxGroup.
    checkbox_attrs: (option_value) ~>
        attrs = do
            type: 'checkbox'
            value: option_value
            id: "#{@filter_id}_#{option_value}"
            onclick: (e) ~>
                @update_filter e.target

        # Check the checkbox if it is one of the already selected filter values.
        if option_value in @filter_function! then
            attrs.checked = true

        return attrs

    # Determine the initial css class for a CheckboxGroup.
    collapse_css_class: ~>
        if @collapsed
            return ".collapse"
        else
            return ".collapse.show"

    # Select all or deselect all checkboxes.
    toggle_all: (select_all_checkbox) ~>
        for checkbox_id in @checkbox_ids
            # NOTE: we use document.getElementById to fetch the checkbox
            # elements of this CheckboxGroup. It is not pretty and I am open
            # to a better alternative.
            checkbox = document.getElementById(checkbox_id)
            checkbox.checked = select_all_checkbox.checked
            @update_checkbox checkbox

        # Update the filter.
        @filter_function @to_filter

    # Update a checkbox' value in the to_filter list.
    update_checkbox: (checkbox) ~>
        if checkbox.checked
            # Push checkbox value to to_filter.
            if checkbox.value not in @to_filter
                @to_filter.push checkbox.value
        else
            # Remove checkbox value from to_filter.
            @to_filter = filter (!= checkbox.value), @to_filter

    # Update the filter after checking a checkbox.
    update_filter: (checkbox) ~>
        @update_checkbox checkbox
        @filter_function @to_filter

    view: ~>
        m '', {class: classes('c-checkbox-filter', {collapsed: @collapsed})},

            m 'a.title', do
                href: "##{@filter_id}"
                'data-toggle': 'collapse'
                onclick: (e) ~>
                    @collapsed = !@collapsed
            ,
                if @collapsed
                    m 'span.fas.fa-plus-square'
                else
                    m 'span.fas.fa-minus-square'
                " #{@filter_name}" +
                    if (@to_filter and @to_filter.length > 0) then ' *' else ''

            m ".items .#{@filter_id}#{@collapse_css_class!}",
                # Render "Select all"-checkbox.
                m '.c-field-checkbox field',
                    m '.control',
                        m 'input', do
                            type: 'checkbox'
                            value: ''
                            id: @select_all_id
                            onclick: (e) ~>
                                @toggle_all e.target
                        m 'label' {for: @select_all_id} ' Select all'

                # Render option checkboxes.
                for [option_value, option_name] in @filter_options
                    @add_checkbox(option_value, option_name)

                # Render "No value"-checkbox.
                if @show_no_value_checkbox
                    @add_checkbox('', " No #{@filter_name}")
