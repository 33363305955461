import m from 'mithril'
import {countries} from '@bitstillery/common/lib/countries'

import {GenericList} from '../generic/generic_list'

import {User, UserManagementApi} from '@/factserver_api/user_management_api'

const UserManage: m.ClosureComponent<void> = () => {
    const api = new UserManagementApi()
    const is_match = (user: User, term: string): boolean => {
        // Filter deleted users
        if (user.is_deleted) {
            return false
        }

        // Filter portal profiles
        if (user.is_system_account) {
            return false
        }

        const country_codes = user.trading_countries.map((country) => countries[country.country_code])

        user.trading_countries_string = country_codes.join(', ')

        api.get_user_status_today(user.artkey).subscribe({
            next: (response) => {
                let class_name = 'badge '
                if (response.status) {
                    class_name += response.status.toLowerCase()
                }
                // @ts-ignore
                user.last_log_today = <div className={class_name}>{response.status}</div>
                m.redraw()
            },
        })

        return [user.name.toLowerCase().indexOf(term.toLowerCase()) > -1].some((element) => element)
    }

    return {
        view(): m.Children {
            return (
                <GenericList<User>
                    readable_entity_name="User"
                    base_url="/data/user-manage/"
                    list_api_call="users.get_all"
                    is_match={is_match}
                    columns={[
                        {key: 'profile.name', label: 'Name'},
                        {key: 'profile.mobile_telephone_number', label: 'Mob. phone #'},
                        {key: 'profile.emailaddress', label: 'Email'},
                        {key: 'trading_countries_string', label: 'Trading countries'},
                        {key: 'last_log_today', label: 'User status'},
                    ]}
                />
            )
        },
    }
}

export default UserManage
