{Brand, VatRate} = require '@/models/data'
app = require('@/app')

export class Product
    (artkey, name, category, default_country_code, product_bottle_type, requires_import_statement, hide_from_pricelist_for_countries, hide_from_pricelist_for_suppliers) ->
        @artkey = window.prop artkey
        @name = window.prop name
        @category = window.prop category
        @bottles = window.prop []
        @default_country_code = window.prop default_country_code
        @product_bottle_type = window.prop product_bottle_type
        @requires_import_statement = window.prop requires_import_statement
        @product_category = window.prop new ProductCategory
        @brand = window.prop new Brand
        @hide_from_pricelist_for_countries = window.prop hide_from_pricelist_for_countries
        @hide_from_pricelist_for_suppliers = window.prop hide_from_pricelist_for_suppliers

    to_string: ->
        @name!


export class ProductCategory
    (json) ->
        @artkey = window.prop ''
        @name = window.prop ''
        @default_product_bottle_type = window.prop ''
        @cbs_code = window.prop ''
        @cbs_code_gte_200 = window.prop ''
        @cbs_code_gte_1000 = window.prop ''
        @nl_vat_rate = window.prop new VatRate
        @de_vat_rate = window.prop new VatRate
        @be_vat_rate = window.prop new VatRate
        @priority = window.prop ''
        @parent = {
            artkey: window.prop ''
            name: window.prop ''
        }


        for prop of json
            if prop == 'nl_vat_rate'
                @nl_vat_rate new VatRate json[prop]
            else if prop == 'de_vat_rate'
                @de_vat_rate new VatRate json[prop]
            else if prop == 'be_vat_rate'
                @be_vat_rate new VatRate json[prop]
            else if prop == 'parent'
                if json[prop]
                    @parent.artkey json[prop].artkey
                    @parent.name json[prop].name

            else
                @[prop] = window.prop json[prop]


export class ProductsDataModel
    ->
        app.$m.common.observable.subscribe 'bottle_added', @, @load_products_and_bottles
        app.$m.common.observable.subscribe 'bottles_updated', @, @load_products_and_bottles

    _create_products: (products_and_bottles) ~>
        temp_products = {}

        for product_and_bottle in products_and_bottles
            product_object = temp_products[product_and_bottle.product_artkey]
            if not product_object
                product_object = @create_product(do
                    artkey: product_and_bottle.product_artkey
                    name: product_and_bottle.product_name
                    category: product_and_bottle.product_category
                    default_country_code: product_and_bottle.product_default_country_code
                    requires_import_statement: product_and_bottle.product_requires_import_statement
                )
            bottle = app.$m.bottles.create_bottle((do
                  artkey: product_and_bottle.bottle_artkey
                  volume: product_and_bottle.bottle_volume
                  alcohol_percentage: product_and_bottle.bottle_alcohol_percentage
                  refill_status: product_and_bottle.bottle_refill_status
                  cbs_code: product_and_bottle.bottle_cbs_code
            ), product_object)
            product_object.bottles!push(bottle)
            temp_products[product_and_bottle.product_artkey] = product_object
        return Object.values temp_products

    create_products: (products) ->
        [@create_product product for product in products]

    create_product: (product) ->
        product_object = new Product(
            product.artkey,
            product.name,
            product.category,
            product.default_country_code,
            product.product_bottle_type,
            product.requires_import_statement,
            product.hide_from_pricelist_for_countries,
            product.hide_from_pricelist_for_suppliers
        )

        if 'bottles' of product
            product_object.bottles app.$m.bottles.create_bottles product.bottles, product_object
        if 'product_category' of product
            product_object.product_category new ProductCategory product.product_category
        if 'brand' of product
            product_object.brand new Brand product.brand
        return product_object

    create_and_add_product: (product_data) ~>
        product_object = @get_product_by_artkey product_data.artkey
        if not product_object
            product_object = @create_product product_data
            @add_product product_object
        else if 'bottles' of product_data
            product_object.bottles app.$m.bottles.create_bottles(product_data.bottles, product_object)
        app.$m.common.observable.broadcast 'products_loaded', 'Go!'
