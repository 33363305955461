m = require 'mithril'
{head, join} = require 'prelude-ls'
api = require 'api.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{a, format-date, prevent-default} = require 'utils.ls'
{FieldText, Spinner} = require '@bitstillery/common/components'
{deref} = require '@bitstillery/common/utils.ls'
{SalesOrder, SalesOrderStatus} = require '@/sales_orders/models.ls'
{Case} = require '@/models/stock'
{CreateOrEditSalesOrderComponent} = require '@/sales_orders/edit_component'
{convert_from_source_to_target, convert_from_euro_to_currency} = require '@/factserver_api/currencies'
app = require('@/app')

class AddMode
    @OFFER = 'offer'
    @PURCHASE = 'purchase'
    @MARKET = 'market'
    @CUSTOM = 'custom'


export class AddToSalesOrder
    (vnode) ->
        @supplier_artkey = vnode.attrs.supplier_artkey
        @supplier_currency = vnode.attrs.supplier_currency or app.$s.currencies.default
        @sales_order_artkey = vnode.attrs.sales_order_artkey
        @sales_order_currency = window.prop (vnode.attrs.sales_order_currency or @supplier_currency)

        @on_added_item = vnode.attrs.on_added_item

        @new_sales_order = window.prop false
        @sales_orders = window.prop []

        @empty_sales_order = new SalesOrder
        @empty_sales_order.was_handled_by_artkey app.$s.identity.artkey
        @empty_sales_order.supplier_artkey @supplier_artkey
        @selected_sales_order = window.prop @empty_sales_order
        @selected_sales_order!artkey @sales_order_artkey

        @stock_items = window.prop []
        @selected_stock_item = window.prop null

        @quantity = window.prop 1
        @price_per_case = window.prop null

        @case_artkey = window.prop null
        @cases = []

        @adding_tbo = false
        @adding_from_stock = false
        @avg_purchase_price = 0
        @list_price = 0
        @list_price_is_offer = false
        @offer_item_artkey = null
        @product_name = ''

        # This component can be used in three modes, namely for (custom) offer
        # item, purchase order item and spli (market item). Depending on which
        # entity is passed in, a different mode is chosen.
        @offer_item = vnode.attrs.offer_item
        @custom_offer_item = vnode.attrs.custom_offer_item
        @purchase_order_item = vnode.attrs.purchase_order_item
        @spli = vnode.attrs.spli

        @loading = false

        if @offer_item or @custom_offer_item
            @mode = AddMode.OFFER

            if @offer_item  # fetch values from offer item
                # The minimum_quantity and maximum_quantity values can be either
                # functions (in case of the offer item list, when they are editable),
                # or not (when they are just part of a collection record). Deref to
                # handle either case.
                @minimum_quantity = (deref @offer_item.minimum_quantity) or 1
                @maximum_quantity = deref @offer_item.maximum_quantity

                @adding_tbo = @offer_item.offer_item_type == 'tbo'
                @case_artkey @offer_item.case_artkey
                @offer_item_artkey = @offer_item.artkey
                @product_name = @offer_item.product_name
                @avg_purchase_price = @offer_item.avg_purchase_price
                if @offer_item.supplier_list_price
                    @list_price = +@offer_item.supplier_list_price
                else
                    @list_price = +@offer_item.list_price
                @list_price_is_offer = (@list_price < +@offer_item.price_per_case)

            else  # fetch values from custom offer item
                @minimum_quantity = @custom_offer_item.minimum_quantity or 1
                @maximum_quantity = @custom_offer_item.quantity
                @adding_tbo = @custom_offer_item.offer_item_type == 'tbo'
                @case_artkey @custom_offer_item.case_artkey
                @offer_item_artkey = @custom_offer_item.linked_offer_item_artkey
                @product_name = @custom_offer_item.name
                @avg_purchase_price = @custom_offer_item.avg_purchase_price
                @list_price = +@custom_offer_item.list_price
                @list_price_is_offer = (@list_price < +@custom_offer_item.original_price)

            @query_case @case_artkey!
            @price_per_case @suggested_offer_price!

            if @adding_tbo and @minimum_quantity is not null
                @quantity +@minimum_quantity

            @adding_from_stock = not @adding_tbo

        else if @purchase_order_item
            @mode = AddMode.PURCHASE
            @minimum_quantity = 1
            @maximum_quantity = @purchase_order_item.number_of_cases_available
            if @purchase_order_item.suggested_price_per_case
                @price_per_case convert_from_euro_to_currency(
                    @purchase_order_item.suggested_price_per_case,
                    @sales_order_currency!,
                    app.$s.currencies.exchange_rates[@sales_order_currency!].portal_rate
                )

            @case_artkey @purchase_order_item.case_artkey
            @query_case @case_artkey!
        else if @spli
            @mode = AddMode.MARKET
            @minimum_quantity = 1
            @maximum_quantity = null

            data = do
                bottle_artkey: @spli.bottle.artkey
                search_exact_case: true
                number_of_bottles: @spli.number_of_bottles_per_case
                customs_status: @spli.customs_status

            api.call-and-then 'product_management.get_cases_for_bottle', data, do
                success: (resp) ~>
                    @cases = resp.result.map((cs) -> new Case cs)
                    if @cases.length
                        @case_artkey @cases[0].artkey!
                    else
                        app.notifier.notify 'No existing case found for this supplier price list item. It unfortunately cannot be added in this way for now.', 'danger'
                        @on_added_item!
        @query_sales_orders!

    query_sales_order: (sales_order_artkey) ~>
        @loading = true
        data = {'sales_order_artkey': sales_order_artkey}
        api.call-and-then 'sales.core.get_sales_order', data, do
            success: (resp) ~>
                @loading = false
                @sales_orders [new SalesOrder resp.result]
                @select_sales_order @sales_order_artkey
                @update_currency!


    query_case: (case_artkey) ~>
        api.call-and-then 'product_management.get_case', {'artkey': case_artkey}, do
            success: (resp) ~>
                resp_case = new Case resp.result
                @cases = [resp_case]

                @query_stock_items do
                    case_artkey: case_artkey

    query_sales_orders: (item_artkey) ~>
        data = do
            status: [SalesOrderStatus.SAVED, SalesOrderStatus.CONFIRMED]
            sort_by: 'created_on'
            ascending: false

        if @supplier_artkey
            data['supplier_artkey'] = @supplier_artkey


        @loading = true
        api.call-and-then 'sales.core.get_sales_orders', data, do
            success: (resp) ~>
                @loading = false
                @sales_orders resp.result.map (sales_order) -> new SalesOrder sales_order
                if @sales_order_artkey
                    @select_sales_order @sales_order_artkey
                    @update_currency!

    query_stock_items: (item_data) ~>
        data = do
            sort_by: 'stock_age'
            ascending: false
            all_accounts: true

        Object.assign data, item_data

        api.call-and-then 'stock.get_stock_records', data, do
            success: (resp) ~>
                @stock_items resp.result.stock_items
                if @stock_items!length == 1
                    @select_stock_item @stock_items![0].artkey
                else
                    @stock_items!sort((first, second) -> if first.stock_age > second.stock_age then -1 else 1)
                    @select_stock_item!

    select_sales_order: (artkey) ~>
        if artkey
            @selected_sales_order head @sales_orders!filter (.artkey! == +artkey)
            @update_currency!
        else
            @selected_sales_order @empty_sales_order
            # Convert the input's price back to the supplier's currency, using
            # the portal rate.
            @price_per_case convert_from_source_to_target(
                +@price_per_case!,
                @sales_order_currency!,
                @supplier_currency,
                app.$s.currencies.exchange_rates[@sales_order_currency!].portal_rate,
                app.$s.currencies.exchange_rates[@supplier_currency!].portal_rate,
            )
            @sales_order_currency @supplier_currency

    update_currency: ~>
        # Convert the input's price to the newly selected sales order's
        # currency, using the portal rate.
        @price_per_case convert_from_source_to_target(
            +@price_per_case!,
            @sales_order_currency!,
            @selected_sales_order!was_sold_in!,
            app.$s.currencies.exchange_rates[@sales_order_currency!].portal_rate,
            app.$s.currencies.exchange_rates[@selected_sales_order!was_sold_in!].portal_rate,
        )
        @sales_order_currency @selected_sales_order!was_sold_in!

    create_new_sales_order: ~>
        @selected_sales_order @empty_sales_order
        @new_sales_order true

    select_stock_item: (artkey) ~>
        if artkey
            @selected_stock_item head @stock_items!filter (.artkey == +artkey)
        else
            @selected_stock_item null

    add_to_sales_order: ~>
        @loading = true
        if @adding_from_stock
            data = do
                sales_order_artkey: @selected_sales_order!artkey!
                item_artkey: @selected_stock_item!artkey
                price_per_case: +@price_per_case!
                number_of_cases: @quantity!

            api.call-and-then 'sales.items.add_item_to_sales_order', data, do
                success: (resp) ~>
                    app.notifier.notify "Added #{@selected_stock_item!product_name} to #{@selected_sales_order!reference!} of #{@selected_sales_order!supplier!name!}.", 'success'
                    @loading = false
                    @on_added_item!

        else if @adding_tbo
            data = do
                sales_order_artkey: @selected_sales_order!artkey!
                item_artkey: @offer_item_artkey
                price_per_case: @price_per_case!
                number_of_cases: @quantity!

            api.call-and-then 'sales.items.add_tbo_to_sales_order', data, do
                success: (resp) ~>
                    app.notifier.notify "Added #{@product_name} to #{@selected_sales_order!reference!} of #{@selected_sales_order!supplier!name!}.", 'success'
                    @loading = false
                    @on_added_item!

        else
            # Adding a case.
            data = do
                sales_order_artkey: @selected_sales_order!artkey!
                case_artkey: @case_artkey!
                purchase_order_item_artkey: if @purchase_order_item then @purchase_order_item.artkey else null
                supplier_artkey: if @spli then @spli.supplier_price_list.supplier.artkey else null
                price_per_case: @price_per_case!
                number_of_cases: @quantity!

            api.call-and-then 'sales.items.add_case_to_sales_order', data, do
                success: (resp) ~>
                    app.notifier.notify "Added case to #{@selected_sales_order!reference!} of #{@selected_sales_order!supplier!name!}.", 'success'
                    @loading = false
                    @on_added_item!

    suggested_offer_price: ~>
        # @list_price's result is in the currency of the supplier. Convert it
        # to the currency of the sales order using the portal rate.
        return convert_from_source_to_target(
            @list_price,
            @supplier_currency,
            @sales_order_currency!,
            app.$s.currencies.exchange_rates[@supplier_currency].portal_rate,
            app.$s.currencies.exchange_rates[@sales_order_currency!].portal_rate,
        )

    stock_item_text: (item) ~>
        # Show the purchase price in the sales order's currency using the
        # current rate.
        was_bought_for = convert_from_euro_to_currency(
            +item.item_euro_was_bought_for,
            @sales_order_currency!,
        )

        stock_item_text = ''
        if item.item_lot then
            stock_item_text += "#{item.item_lot} - "
        else
            stock_item_text += "#{item.purchase_order_reference} - "
        stock_item_text += "In: #{was_bought_for.format-money!} #{@sales_order_currency!}"

        if @list_price_is_offer
            stock_item_text += " - Offer: "
        else
            stock_item_text += " - Target: "

        stock_item_text += "#{(@suggested_offer_price!).format-money!} #{@sales_order_currency!}"
        stock_item_text += " - #{item.case_number_of_bottles} btl/cs"
        stock_item_text += " - #{item.item_number_of_cases_available} available"
        stock_item_text += " - #{item.case_customs_status}"
        if item.case_gift_box_type
            stock_item_text += " - #{item.case_gift_box_type}"
        if item.case_tax_label
            stock_item_text += " - #{item.case_tax_label}"

        features = []
        if item.item_cases_per_pallet
            features.push "CPP: #{item.item_cases_per_pallet}"
        if item.item_cases_per_pallet_layer
            features.push "CPL: #{item.item_cases_per_pallet_layer}"
        if item.item_best_before_date
            features.push "BBD: #{format-date item.item_best_before_date}"
        if item.item_damages
            features.push item.item_damages.replace(', ', ' / ')
        if item.item_general_tags
            features.push item.item_general_tags.replace(', ', ' / ')
        if item.item_pack_size
            features.push item.item_pack_size
        if item.item_packaging
            features.push item.item_packaging
        if features.length
            stock_item_text += " - #{join ' / ' features}"
        if item.case_no_eu_address
            stock_item_text += " - No EU address"

        return stock_item_text

    case_text: (cs) ~>
        case_text = "#{cs.bottle!product!name!}"
        case_text += " - #{cs.number_of_bottles!} / #{cs.bottle!volume!} / #{cs.bottle!alcohol_percentage!} / #{cs.bottle!refill_status!}"
        if cs.gift_box_type!
            case_text += " / #{cs.gift_box_type!}"

        case_text += " - #{cs.customs_status!}"

        # Show features, so the user can see the difference between cases for
        # which only the features differ.
        if cs.tax_label!
            case_text += " - #{cs.tax_label!}"
        if cs.best_before_date!
            case_text += " - #{format-date cs.best_before_date!}"
        if cs.item_tags!length
            case_text += " - #{join ' / ' app.$m.data.item_tag.get_tag_names cs.item_tags!}"

        # This is properly queried, however this function is called with cases from purchase_order_items,
        # which can be different from the item.case that is added, if either is retroactively edited.
        if cs.no_eu_address!
            case_text += " - No EU address"

        return case_text

    view: ->
        if @new_sales_order! then
            return m CreateOrEditSalesOrderComponent,
                relation_artkey: @supplier_artkey
                override_is_new: true
                onsaved: (artkey) ~>
                    @new_sales_order false
                    @sales_order_artkey = artkey  # set the new artkey
                    @query_sales_order artkey

        m 'form.flex-form' {onsubmit: prevent-default @add_to_sales_order},
            # Render sales order select input and button to create a new one.
            m '.fieldset.large',
                if @sales_order_artkey
                    m '.field-readonly',
                        m '.key' 'Sales order to add to'
                        m '.value',
                            if @loading
                                m Spinner
                            else
                                "#{@selected_sales_order!reference!} - #{format-date @selected_sales_order!created_on!} - #{@selected_sales_order!supplier!name!}"

                else
                    m '.field',
                        [
                            if @loading
                                m Spinner
                            else
                                m 'select' do
                                    onchange: (ev) ~> @select_sales_order +ev.target.value
                                , a do
                                    m 'option' {value: ''} ''
                                    @sales_orders!map (so) ~>
                                        m 'option' do
                                            value: +so.artkey!
                                            selected: +so.artkey! == +@selected_sales_order!artkey!
                                        , "#{so.reference!} - #{format-date so.created_on!} - #{so.supplier!name!} (#{so.portal_status!} - #{so.sales_order_status!})"
                            m '.btn-toolbar' m 'button.btn.btn-default' do
                                type: \button
                                onclick: @create_new_sales_order
                            , (m 'span.glyphicon.glyphicon-plus'), ' New Sales Order'
                        ]

                if @adding_from_stock
                    # Render stock item select input or label if there is only 1 item.
                    if @selected_sales_order!artkey!
                        if @stock_items!length == 1
                            m '.field-readonly',
                                m '.key' 'Stock Item'
                                m '.value' @stock_item_text @stock_items![0]
                        else
                            m '.field',
                                m 'label' 'Stock Item'
                                m 'select' do
                                    required: true,
                                    onchange: (ev) ~> @select_stock_item +ev.target.value
                                ,
                                    m 'option' {value: '', selected: true} '-'
                                    @stock_items!map (item) ~>
                                        m 'option' do
                                            value: +item.artkey
                                            selected: +item.artkey == +@selected_stock_item!?artkey
                                        , @stock_item_text item
                    else
                        m '.field-readonly',
                            m '.key' 'Please select a sales order first.'
                else
                    # Render case selection.
                    m '.field',
                        m 'label' 'Case'
                        if @cases
                            if @cases.length == 1
                                @case_text @cases[0]
                            else
                                m 'select.form-control' do
                                    required: true,
                                    onchange: (ev) ~> @case_artkey +ev.target.value
                                ,
                                    @cases.map (cs) ~>
                                        m 'option' do
                                            value: +cs.artkey!
                                            selected: +cs.artkey! == +@case_artkey!
                                        , @case_text cs
                        else
                            m Spinner

                # Render price suggestion.
                m '.field-readonly',
                    if @mode == AddMode.OFFER then [
                        m '.key' 'Relation sales / cs'
                        m '.value', [
                            # Show the suggested sales price in the sales order's
                            # currency using the portal rate. Note the keys in
                            # both the AmountWidget and the span; these are
                            # necessary for auto-updating both the price and the
                            # margin percentage when the user selects another
                            # sales order.
                            m Amount, do
                                amount: @suggested_offer_price!
                                currency: @sales_order_currency!
                                rate: app.$s.currencies.exchange_rates[@sales_order_currency!].portal_rate
                                key: "#{@suggested_offer_price!}#{@sales_order_currency!}"
                            m 'span' do
                                key: "#{@suggested_offer_price!}#{@sales_order_currency!}"
                            , if not @adding_tbo
                                # Calculate the average purchase price in the
                                # sales order's currency using the current rate.
                                purchase_price = convert_from_euro_to_currency(
                                    @avg_purchase_price,
                                    @sales_order_currency!
                                )
                                margin_percentage = ((@suggested_offer_price! / purchase_price) - 1) * 100
                                " (margin with average purchase price: #{margin_percentage.toFixed(2)}%)"
                        ]
                    ]
                    else if @mode == AddMode.PURCHASE then [
                        m '.key' 'Purchase price / cs'
                        m '.value',
                            m Amount, do
                                amount: @purchase_order_item.was_bought_for
                                currency: @purchase_order_item.was_bought_in
                    ]
                    else if @mode == AddMode.MARKET then [
                        m '.key' 'Market price / cs'
                        m '.value',
                            m Amount, do
                                amount: @spli.price_per_case
                                currency: app.$s.currencies.default
                    ]

                m '.field-group',
                    # Render quantity input.
                    if @adding_from_stock
                        inputs.number @quantity, {
                            label: 'Quantity (cases)'
                            required: true
                            min: 1
                            max: if @selected_stock_item! then @selected_stock_item!item_number_of_cases_available
                        }
                    else
                        inputs.number @quantity, {
                            help:
                                if @minimum_quantity and @maximum_quantity
                                    "Minimum: #{@minimum_quantity}, Maximum: #{@maximum_quantity}."
                                else if @minimum_quantity
                                    "Minimum: #{@minimum_quantity}."
                                else if @maximum_quantity
                                    "Maximum: #{@maximum_quantity}."

                            label: 'Quantity (cases)',
                            required: true
                            min: 1
                        }

                    m MoneyInput, {
                        currency: @sales_order_currency!
                        label: 'Price per case'
                        value: @price_per_case!
                        on_value: (price) ~> @price_per_case price
                        required: true
                    }

                m 'button.btn.btn-primary' do
                    type: 'submit'
                    disabled: ![
                        !@loading
                        not @selected_sales_order!artkey!
                        @quantity! < @minimum_quantity
                        @maximum_quantity is not null and @quantity! > @maximum_quantity
                        @adding_from_stock and (not @selected_stock_item! or @quantity! > @selected_stock_item!item_number_of_cases_available)
                    ].some((x) ~> x)
                , 'Add to Order'




