{any, filter, map, all, head} = require 'prelude-ls'
m = require 'mithril'
api = require '../api.ls'
{a}: utils = require '../utils.ls'
{FilterType, BottleFilters, SearchFilter} = require './explore/models.ls'
{SearchFields} = require './explore/search_fields.ls'
{ShowPurchaseHistory} = require './explore/show_purchase_history.ls'
{ShowSalesHistory} = require './explore/show_sales_history.ls'
{ShowStock} = require './explore/show_stock.ls'
{ShowSupplierPriceListItems} = require './explore/show_supplier_price_list_items.ls'
{ShowOfferHistory} = require './explore/show_offer_history.tsx'
{object_to_query_string, strip_empty_values_nested_obj} = require '@bitstillery/common/lib/utils'
{proxy, watch} = require '@bitstillery/common/lib/proxy'
{all-equal, on-set, join-maybes} = utils
{Case} = require '@/models/stock'
{Supplier} = require '@/models/suppliers'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
app = require('@/app')


module.exports = class MarketExplore
    ->
        @totals = window.prop {
            purchase_history: {
                total: ''
                count: 0
            }
            sales_history: {
                total: ''
                margin: ''
                count: 0
            }
            offer_history: {count: 0}
            stock: {
                count: 0
                in_stock: 0
                in_purchase: 0
                in_sales: 0
                available: 0
            }
            pricelist: {count: 0}
            pricelist_history: {count: 0}
        }

        @loading_price_list_items = window.prop false
        @loading_historic_price_list_items = window.prop false
        @loading_offer_history = window.prop false
        @loading_purchase_history = window.prop false
        @loading_sales_history = window.prop false
        @loading_stock_items = window.prop false
        @price_list_items = window.prop []
        @historic_price_list_items = window.prop []
        @offer_history = window.prop []
        @purchase_history = window.prop []
        @sales_history = window.prop []
        @stock_items = window.prop []

        @limit = window.prop 10
        @search_filters = window.prop [new SearchFilter]

        # Date selection properties.
        @start_date = window.prop ''
        @end_date = window.prop ''
        @from_date = window.prop ''
        @to_date = window.prop ''
        @year_date = window.prop ''
        @month_date = window.prop ''
        @_year_input_value = window.prop ''
        @_month_input_value = window.prop ''
        @date_selection_value = window.prop 'all'
        @account_selection_value = window.prop ''

        @selected_header = window.prop ''
        @selected_filter_type = window.prop ''
        @selected_show_specs = window.prop false

        @product_management_api = new ProductManagementApi()

        # Define properties for in the price list panels. Do not reuse
        # the 'normal' product and supplier properties, as the panels must
        # only be updated when the update function is executed, and not when
        # another bottle, product or supplier is being selected.
        @selected_product_name = window.prop ''
        @selected_supplier_name = window.prop ''
        @selected_bottle_filters = window.prop new BottleFilters

        @watchers = []
        @historic_sort_collection = proxy({
            state: do
                ready: false,
                sort: do
                    options: [['start_date', 'Start date'], ['end_date', 'End date']],
                    by: 'end_date',
                    order: 'desc'
        })

        @watchers.push(
            watch(@historic_sort_collection.state.sort, 'by', ~> @get_historic_price_list_items!)
            watch(@historic_sort_collection.state.sort, 'order', ~> @get_historic_price_list_items!)
        )
        @year(new Date!getFullYear!)
        @month(new Date!)

    oncreate: ~>>
        # Read querystring.
        q_case = m.route.param 'case'
        q_supplier = m.route.param 'supplier'
        q_year = m.route.param 'year'

        # Set year.
        if q_year
            @year q_year
            @date_selection_value 'year'

        # Set case and/or supplier and simulate a 'Go click'.
        if q_case or q_supplier
            data = do
                case_artkey: q_case
                supplier_artkey: q_supplier

            query_str = object_to_query_string strip_empty_values_nested_obj data

            resp = await app.api.get 'discover/explore/get-case-and-supplier?' + query_str

            if resp.success
                @set_case_and_supplier resp.result
                search_filter = @search_filters![0]
                @product_management_api.get_bottles_for_product(search_filter.product!artkey!).subscribe({
                    next: (bottles) ~>
                        search_filter.volume_options [...new Set(bottles.map((bottle) -> +bottle.volume))].sort((a, b) -> a - b)
                        search_filter.alcohol_percentage_options [...new Set(bottles.map((bottle) -> +bottle.alcohol_percentage))].sort((a, b) -> a - b)
                        search_filter.refill_status_options [...new Set(bottles.map((bottle) -> bottle.refill_status))].sort()
                })
            else
                app.$m.common.generic_error_handler!

    onremove: ~>>
        @watchers.map (watcher) -> watcher()

    year: (value) ~>
        # Setter and getter in one. Value is in '2016'-format. When setter,
        # value is converted to a Date object for the year_date property.
        if value
            @_year_input_value value
            @year_date new Date(value, 0, 1)
        else
            @_year_input_value!

    month: (value) ~>
        # Setter and getter in one. When setter, value will be a Date object.
        # When getter, the html5 input expects: '2016-09' format.
        if value
            @_month_input_value value.getMonthInputFormat!
            @month_date value
        else
            @_month_input_value!

    set_case_and_supplier: (result) ~>
        search_filter = new SearchFilter

        if result.supplier
            search_filter.supplier!artkey result.supplier.artkey
            search_filter.supplier!name result.supplier.name
            search_filter.selected_relations!push(result.supplier)

        if result.case
            search_filter.case!number_of_bottles result.case.case_number_of_bottles
            search_filter.case!tax_label result.case.tax_label
            search_filter.case!gift_box_type result.case.gift_box_type
            search_filter.bottle!volume +result.case.volume
            search_filter.bottle!alcohol_percentage +result.case.alcohol_percentage
            search_filter.bottle!refill_status result.case.refill_status
            search_filter.product_name result.case.name
            search_filter.product!artkey result.case.product_artkey

        @search_filters [search_filter]

        # Simulate a 'Go' click.
        @update!

    date_selection: (value) ~>
        if value?
            @date_selection_value value
        else
            return @date_selection_value!

    account_selection: (value) ~>
        if value?
            @account_selection_value value
        else
            return @account_selection_value!

    set_year_dates: ~>
        date = @year_date!
        @start_date new Date(date.getFullYear(), 0, 1)
        @end_date new Date(date.getFullYear() + 1, 0, 0)

    set_month_dates: ~>
        date = @month_date!
        @start_date new Date(date.getFullYear(), date.getMonth(), 1)
        @end_date new Date(date.getFullYear(), date.getMonth() + 1, 0)

    set_start_and_end_date: ~>
        switch @date_selection_value!
        case 'all'
            @start_date ''
            @end_date ''
        case 'year'
            @set_year_dates!
        case 'month'
            @set_month_dates!
        case 'range'
            @start_date @from_date!
            @end_date @to_date!

    _build_query_string: (page_index) ->
        filters = {
            product_filters: @search_filters! |> map (.to_json!)
            start_date: utils.format_date_html5(@start_date!)
            end_date: utils.format_date_html5(@end_date!)
            account_slug: @account_selection_value!
            limit: @limit!
            offset: if page_index then @limit! * (page_index - 1)
        }

        filters = strip_empty_values_nested_obj filters, ['tax_label']
        return object_to_query_string filters

    get_query: ~> do
        start_date: utils.format_date_html5(@start_date!)
        end_date: utils.format_date_html5(@end_date!)
        account_slug: @account_selection_value!
        filters: @search_filters! |> map (.to_json!)

    # The filter is valid if at least one search filter has either a product
    # or a supplier.
    is_valid_filter: (search_filters) ->
        search_filters |> any (f) ->
            f.product!artkey! || f.supplier!artkey! || f.selected_relations!length

    all_equal_and_not_empty: (list) ->
        all-equal list and head list

    have_constant_product: ~>
        @search_filters! |> map (.product!artkey!) |> @all_equal_and_not_empty

    have_constant_supplier: ~>
        @search_filters! |> map (.selected_relations![0]?artkey) |> @all_equal_and_not_empty

    have_constant_specs: ~>
        @search_filters! |> map (.bottle!to_json!) |> all-equal

    have_incomplete_specs: ~>
        not (@search_filters! |> all (.bottle!is_full!))

    get_filter_type: ~>
        has_supplier = @search_filters![0]?selected_relations![0]?artkey

        if @have_constant_product! and has_supplier
            FilterType.BOTH
        else if @have_constant_product!
            FilterType.PRODUCT
        else if has_supplier
            FilterType.RELATION
        else
            FilterType.VARIOUS

    get_header: (filter_type, search_filters) ->
        supplier = search_filters[0].supplier!name!
        product = join-maybes ' ', [
            search_filters[0].product!name!
            search_filters[0].bottle!show!
            search_filters[0].product!category!
        ]

        if filter_type == FilterType.BOTH
            "#{product} from #{supplier}"
        else if filter_type == FilterType.RELATION
            supplier
        else if filter_type == FilterType.PRODUCT
            product
        else
            'Various products and/or relations'

    get_table_totals: (table_name, filters) ~>>
        const {result: totals} = await app.api.get(
            'discover/explore/'+table_name.replace('_', '-')+'/totals?'+object_to_query_string filters
        )
        @totals![table_name] = totals
        m.redraw!

    get_all_totals: ~>>
        filters = {
            product_filters: @search_filters! |> map (.to_json!)
            start_date: utils.format_date_html5(@start_date!)
            end_date: utils.format_date_html5(@end_date!)
            account_slug: @account_selection_value!
        }

        filters = strip_empty_values_nested_obj filters, ['tax_label']

        await Promise.all [
            @get_table_totals 'purchase_history', filters
            @get_table_totals 'sales_history', filters
            @get_table_totals 'offer_history', filters
            @get_table_totals 'stock', filters
            @get_table_totals 'pricelist', filters
            @get_table_totals 'pricelist_history', filters
        ]

    get_purchase_history: (page_index ) ~>>
        @loading_purchase_history true

        query_str = @_build_query_string page_index
        const {result: purchase_history} = await app.api.get 'discover/explore/purchase-history?'+query_str
        @purchase_history(purchase_history or [])

        @loading_purchase_history false
        m.redraw!

    get_sales_history: (page_index) ~>>
        @loading_sales_history true

        query_str = @_build_query_string page_index
        const {result: sales_history} = await app.api.get 'discover/explore/sales-history?'+query_str
        @sales_history(sales_history or [])

        @loading_sales_history false
        m.redraw!


    get_stock_items: (page_index) ~>>
        @loading_stock_items true

        query_str = @_build_query_string page_index
        const {result: stock} = await app.api.get 'discover/explore/stock?'+query_str
        @stock_items(stock or [])

        @loading_stock_items false
        m.redraw!

    get_price_list_items: (page_index) ~>>
        @loading_price_list_items true

        query_str = @_build_query_string page_index
        const {result: price_list_items} = await app.api.get 'discover/explore/pricelist?'+query_str
        @price_list_items(price_list_items or [])

        @loading_price_list_items false
        m.redraw!

    get_historic_price_list_items: (page_index) ~>>
        @loading_price_list_items true

        query_str = @_build_query_string page_index
        query_str += '&sort_by=' + @historic_sort_collection.state.sort.by + '&sort_order=' + @historic_sort_collection.state.sort.order
        const {result: historic_price_list_items} = await app.api.get 'discover/explore/pricelist-history?'+query_str
        @historic_price_list_items(historic_price_list_items or [])

        @historic_sort_collection.state.ready = true
        @loading_price_list_items false
        m.redraw!

    get_offer_history: (page_index) ~>>
        @loading_offer_history true
        @offer_history []

        query_str = @_build_query_string page_index
        const {result: offer_history} = await app.api.get 'discover/explore/offer-history?'+query_str
        @offer_history(offer_history or [])

        @loading_offer_history false
        m.redraw!

    update: ~>>
        if not (@is_valid_filter @search_filters!)
             app.notifier.notify 'Please select at least a product or a relation.', 'danger'
             return

        @selected_show_specs (not @have_constant_specs!) or @have_incomplete_specs!
        @selected_header (@get_header filter_type, @search_filters!)
        @clear_data!


        if @have_constant_supplier!
            @selected_supplier_name @search_filters![0].supplier!name!
        else
            @selected_supplier_name undefined

        if @have_constant_product!
            @selected_product_name @search_filters![0].product!name!
        else
            @selected_product_name undefined

        if @have_constant_specs! then
            @selected_bottle_filters @search_filters![0].bottle!clone!
        else
            @selected_bottle_filters new BottleFilters

        # Set the start_date and end_date values, so they can be used in the
        # API call.
        @set_start_and_end_date!


        await Promise.all [
            @get_all_totals!
            @get_purchase_history!
            @get_sales_history!
            @get_offer_history!
            @get_stock_items!
            @get_price_list_items!
            @get_historic_price_list_items!
        ]

        filter_type = @get_filter_type!
        @selected_filter_type filter_type
        @selected_header (@get_header filter_type, @search_filters!)
        m.redraw!

    reset: ~>
        @search_filters [new SearchFilter]

        @start_date undefined
        @end_date undefined
        @date_selection_value 'all'
        @account_selection_value undefined
        @clear_data!
        m.redraw!


    clear_data: ~>
        @price_list_items []
        @historic_price_list_items []
        @offer_history []
        @purchase_history []
        @sales_history []
        @stock_items []
        @totals {
            purchase_history: {
                total: ''
                count: 0
            }
            sales_history: {
                total: ''
                margin: ''
                count: 0
            }
            offer_history: {count: 0}
            stock: {
                count: 0
                in_stock: 0
                in_purchase: 0
                in_sales: 0
                available: 0
            }
            pricelist: {count: 0}
            pricelist_history: {count: 0}
        }

    download_sales_history_excel: ~>
        data = do
            query: @get_query!
        api.call 'explore.export_sales_history_to_excel', data, @handle_sales_history_excel

    handle_sales_history_excel: (resp) ~>
        if resp.success
            utils.download_binary_file_from_base64_str resp.result,
              'Explore - Sales History ' + utils.format-date(new Date) + '.xlsx'
        else
            app.$m.common.generic_error_handler!

    download_purchase_history_excel: ~>
        data = do
            query: @get_query!
        api.call 'explore.export_purchase_history_to_excel', data, @handle_purchase_history_excel

    handle_purchase_history_excel: (resp) ~>
        if resp.success
            utils.download_binary_file_from_base64_str resp.result,
              'Explore - Purchase History ' + utils.format-date(new Date) + '.xlsx'
        else
            app.$m.common.generic_error_handler!

    panel_header: ~>
        bottle_filters: @bottle_filters


    view: -> m '.c-market-explore view' a do
        m SearchFields, do
            search_filters: @search_filters
            date_selection: @date_selection
            account_selection: @account_selection
            month: @month
            year: @year
            from_date: @from_date
            to_date: @to_date
            limit: @limit
            reset: @reset
            update: @update

        m ShowPurchaseHistory, do
            title: 'Purchase history'
            unique_name: 'purchase_history'
            purchase_history: @purchase_history
            purchase_history_total: ~> @totals!['purchase_history']['total']
            download_callback: @download_purchase_history_excel.bind(@)
            limit: @limit
            count: ~> @totals!['purchase_history']['count']
            fetch_page: @get_purchase_history
            filter_type: @selected_filter_type
            header: @selected_header
            show_specs: @selected_show_specs
            loading: @loading_purchase_history
            collapsible: true
            collapsed: false

        m ShowSalesHistory, do
            title: 'Sales history'
            unique_name: 'sales_history'
            sales_history: @sales_history
            sales_history_margin: ~> @totals!['sales_history']['margin']
            sales_history_total: ~> @totals!['sales_history']['total']
            download_callback: @download_sales_history_excel.bind(@)
            limit: @limit
            count: ~> @totals!['sales_history']['count']
            fetch_page: @get_sales_history
            filter_type: @selected_filter_type
            header: @selected_header
            show_specs: @selected_show_specs
            loading: @loading_sales_history
            collapsible: true
            collapsed: false

        m ShowStock, do
            title: 'Stock'
            unique_name: 'stock'
            description: 'Ignores the selected date.'
            stock: @stock_items
            limit: @limit
            stock_totals: @totals!['stock']
            count: ~> @totals!['stock']['count']
            fetch_page: @get_stock_items
            filter_type: @selected_filter_type
            header: @selected_header
            show_specs: @selected_show_specs
            loading: @loading_stock_items
            collapsible: true
            collapsed: false

        m ShowSupplierPriceListItems, do
            title: 'Pricelists (active)'
            unique_name: 'price_list_items'
            description: 'Ignores the selected date and tax label.'
            supplier_price_list_items: @price_list_items
            product_name: @selected_product_name
            supplier_name: @selected_supplier_name
            bottle_filters: @selected_bottle_filters
            limit: @limit
            count: ~> @totals!['pricelist']['count']
            fetch_page: @get_price_list_items
            filter_type: @selected_filter_type
            header: @selected_header
            show_specs: @selected_show_specs
            loading: @loading_price_list_items
            collapsible: true
            collapsed: false

        m ShowOfferHistory, do
            offer_history_items: @offer_history!
            fetch_page: @get_offer_history
            loading: @loading_offer_history!
            description: 'Ignores the selected account slug.'
            count: @totals!['offer_history']['count']
            limit: @limit!
            collapsible: true
            collapsed: false
            header: @selected_header!

        m ShowSupplierPriceListItems, do
            title: 'Pricelists (historic)'
            unique_name: 'historic_price_list_items'
            description: 'Products on pricelists of suppliers who do not have an active pricelist, ' +
                    'but who used to have an active pricelist with the selected product. ' +
                    'Ignores the selected tax label.'
            supplier_price_list_items: @historic_price_list_items
            product_name: @selected_product_name
            supplier_name: @selected_supplier_name
            bottle_filters: @selected_bottle_filters
            limit: @limit
            count: ~> @totals!['pricelist_history']['count']
            fetch_page: @get_historic_price_list_items
            filter_type: @selected_filter_type
            header: @selected_header
            show_specs: @selected_show_specs
            loading: @loading_historic_price_list_items
            collapsible: true
            collapsed: true
            sort_collection: @historic_sort_collection

