import m from 'mithril'
import {debounce} from '@bitstillery/common/lib/utils'
import {$s, $t, api, events, logger, notifier, store, telemetry} from '@bitstillery/common/app'

export function bind() {
    events.on('identity.login', async(user_data = null) => {
        if (!user_data) {
            user_data = await get_user()
        }
        const legacy_token = localStorage.getItem('token')
        if (!legacy_token) {
            localStorage.setItem('token', $s.identity.token)
        }

        $s.identity.user = user_data
        telemetry.set_user(user_data)

        store.save()
        events.emit('app.route')
    })

    events.on('identity.logout', async() => {
        localStorage.removeItem('token')
        m.route.set('/login')
    })
}

export async function get_user() {
    const res = await api.post('users.get_user', {
        artkey: $s.identity.artkey,
    })
    return res.result
}

export function logout() {
    logger.info('[identity] logging out')

    $s.identity = {
        profile: null,
        token: null,
        user: {},
    }
    store.save()
    m.route.set('/login')
    events.emit('identity.logout')
}

export const logout_debounced = debounce(100, () => {
    logout()
    notifier.notify($t('landing.force_logout', 'warning'))
})
