import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {SaveButton} from '@/components/buttons'
import {AfvalfondsApi} from '@/factserver_api/afvalfonds_api'

export default class AfvalfondsOverview extends MithrilTsxComponent<unknown> {
    afvalfonds_api = new AfvalfondsApi()
    is_downloading = false
    year = new Date().getFullYear() - 1

    download_export(): void {
        this.is_downloading = true
        this.afvalfonds_api.download_export({year: this.year}).subscribe({
            next: (response) => {
                if (response) {
                    notifier.notify('Started generating report. When it is complete, it can be downloaded from the download center.', 'success')
                } else {
                    notifier.notify('No response received. Please try again later.', 'danger')
                }
                // Do not allow the is_downloading button again to prevent doubleclicking.
            },
            error: () => {
                this.is_downloading = false
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-afvalfonds view">
                <form className="flex-form">
                    <div className="fieldset">
                        <p>Please select 'Download export' to download the afvalfonds export.</p>
                        <div className="field">
                            <label>Year</label>
                            <input
                                required={true}
                                type="number"
                                min={2000}
                                value={this.year} // value has to be a number.
                                onchange={(value: InputEvent) => {
                                    this.year = +(value.target as HTMLInputElement)?.value
                                }}
                                class="form-control number-input"
                            />
                        </div>
                        <SaveButton
                            icon_class={'glyphicon glyphicon-download'}
                            onclick={() => this.download_export()}
                            title={' Download export'}
                            disabled={this.is_downloading}
                        />
                    </div>
                </form>
            </div>
        )
    }
}
