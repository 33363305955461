m = require "mithril"
table = require '../components/table.ls'
{Collection} = require '@/components/collection/collection.ls'
discover_link = require './components/discover_link'
{Amount} = require '@bitstillery/common/components'
{a}: utils = require '../utils.ls'
{or-list} = require 'prelude-ls'
{pricelist_link} = require '../data/components/pricelist_link.ls'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
app = require('@/app')


BEST_BUY_DAYS = 7


module.exports = class Bestbuys
    (vnode) ->
        @splis = new Collection do
            api_function_name: 'marketanalysis.get_supplier_price_list_items'
            filter_function: @is_match
            query_limit: 20
            additional_params: window.prop do
                only_splis_since_days: BEST_BUY_DAYS
            sort_order: [
                {'name': 'artkey', 'direction': 'asc'}
            ]
            default_sort_by: 'score'
            default_sort_order: 'asc'

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @splis.update_search_term text
        @splis.submit_search!

    oncreate: ~>
        @splis.init!

        q = m.route.param 'q'
        if q
            @submit_search q.replace(/\+/g, ' ')

    is_match : (spli, term) ~>
        return or-list [
            spli.bottle.product.name.toLowerCase!indexOf(term) > -1,
            +spli.bottle.volume == +term,
            +spli.bottle.alcohol_percentage == +term,
            spli.aux_info.toLowerCase!indexOf(term) > -1,
            spli.gift_box_type.toLowerCase!indexOf(term) > -1
        ]

    view : ->
        real_discover_link = (bottle_json) ->
            m 'a.discover-link' {target: '_blank', href: discover_link.from_json(bottle_json)}, m 'span.glyphicon.glyphicon-search'

        m '.c-market-best-buys view' a do

            m '.c-filter-group',
                m SearchBar,
                    placeholder: "Search products..."
                    on_submit: @submit_search
                    default_search_text: @splis.search_term!
                    search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                    on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

            @splis.show_counter!

            table.collection_table(@splis, [
                do  # spli link
                    width: 3
                    function: ((spli) ->
                        m m.route.Link, {href: pricelist_link.from_bottle_json(spli.supplier_price_list.artkey, spli.bottle)} m 'span.glyphicon.glyphicon-new-window'
                    )
                do
                    width: 8
                    field: 'supplier_price_list.supplier.name'
                    name: 'Relation'
                    ellipsis: true
                do
                    width: 5
                    name: 'Start date'
                    function: ((spli) ->
                        utils.format-date(spli.supplier_price_list.start_date)
                    )
                    sort: true
                    field: 'supplier_price_list.start_date'
                do
                    width: 5
                    name: 'Created'
                    function: ((spli) -> utils.format-date(spli.supplier_price_list.creation_date))
                    sort: true
                    field: 'supplier_price_list.creation_date'
                do
                    width: 3
                    sort: true
                    field: 'score'
                    name: 'Rank'
                    function: ((spli) -> spli.rank + ' / ' + spli.total)
                    classes: 'text-center'
                do  # Discover link
                    width: 3
                    function: ((spli) -> real_discover_link(spli.bottle))
                do
                    width: 10
                    sort: true
                    field: 'bottle.product.name'
                    name: 'Product'
                    ellipsis: true
                do
                    width: 4
                    sort: true
                    field: 'number_of_bottles_per_case'
                    name: 'Btl / cs'
                    classes: 'number'
                do
                    width: 5
                    sort: true
                    field: 'bottle.volume'
                    name: 'Size'
                    classes: 'number'
                    function: ((spli) -> (+spli.bottle.volume).toFixed(1))
                do
                    width: 5
                    sort: true
                    field: 'bottle.alcohol_percentage'
                    name: 'Alc %'
                    classes: 'number'
                    function: ((spli) -> (+spli.bottle.alcohol_percentage).toFixed(1))
                do
                    width: 3
                    sort: true
                    field: 'bottle.refill_status'
                    name: 'Ref'
                do
                    width: 3
                    sort: true
                    field: 'gift_box_type'
                    name: 'GB'
                    ellipsis: true
                do
                    width: 7
                    sort: true
                    field: 'aux_info'
                    name: 'Aux info'
                    ellipsis: true
                do
                    width: 7
                    sort: true
                    field: 'euro_price_per_case'
                    name: '€ / cs'
                    classes: 'price'
                    function: ((spli) ->
                        m(Amount, do
                            amount: +spli.price_per_case
                            currency: spli.currency
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 7
                    sort: true
                    field: 'euro_price_per_bottle'
                    name: '€ / btl'
                    classes: 'price'
                    function: ((spli) ->
                        m(Amount, do
                            amount: +spli.price_per_bottle
                            currency: spli.currency
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 4
                    sort: true
                    field: 'customs_status'
                    name: 'Customs'
                do
                    width: 6
                    sort: true
                    field: 'bottle.product.category'
                    name: 'Category'
                    ellipsis: true
                    function: ((spli) ->
                        m 'span.text-capitalize' spli.bottle.product.category
                    )
                do
                    width: 7
                    sort: true
                    field: 'incoterm'
                    name: 'Incoterm'
                    ellipsis: true
                do
                    width: 5
                    sort: true
                    field: 'availability_status'
                    name: 'Availability'
            ])
