m = require 'mithril'
$ = require 'jquery'
{map, union, slice, sort-with, minimum, find} = require 'prelude-ls'
api = require 'api.ls'
{copy, maybe-map} = require 'utils.ls'
utils = require 'utils.ls'
{pager} = require '@/components/pager/pager.ls'
{debounce} = require '@bitstillery/common/ts_utils'
app = require('@/app')

export class Collection
    (options) ->
        if (not options.dont-reuse) and m.route.get! of app.$m.data.collection_states
            cached_collection = app.$m.data.collection_states[m.route.get!]
            # These parameters usually relate to the currently active Mithril component
            # (in other words, we pass these in with a @, for example
            # additional_params: @additional_params).
            # Not passing these through to the cached collection will result in the cached
            # collection still referring to the old Mithril component. This can cause
            # confusion about what @ is bound to.
            cached_collection.additional_params = options.additional_params || window.prop {}
            cached_collection.filter_function = options.filter_function || window.prop true
            return cached_collection

        # required arguments
        @limit = options.query_limit
        @api_function_name = options.api_function_name

        # optional arguments
        @proxy_collection = options.proxy_collection
        @on_query_start = options.on_query_start
        @on_query_end = options.on_query_end
        @filter_function = options.filter_function || window.prop true
        @filter_serverside = window.prop (options.filter_serverside || false)
        @additional_params = options.additional_params || window.prop {}
        @search_term = options.search_term || window.prop ''
        @default_sort_order = options.sort_order || []
        @sort_order = window.prop @default_sort_order
        @sort_by = window.prop (options.default_sort_by || '')
        @ascending = window.prop (options.default_sort_order == 'asc')
        @paged = options.paged or false
        # Do we want to show more items?
        @hide_show_more_items = options.hide_show_more_items or false
        # Only query if we have search terms.
        @only_query_on_search = options.only_query_on_search or false
        # Find the data under this key in the response.
        @data_key = options.data_key or null

        # Variables which can be accessed from the outside
        @items = window.prop [] # all queried items
        @search_result = window.prop [] # the search results
        @can_show_more_items = window.prop false # can we show more items
        @loading = window.prop false # are we querying or loading
        @queried = window.prop false
        @total_number_of_items = window.prop null
        @search_terms = window.prop []
        @query_data = window.prop {}

        # Aggregate search results. Some API requests not only return results,
        # but also aggregates over the results. This is not required though.
        @aggs = {}

        # Internal variables
        @offset = 0
        @show_count = @limit
        @_sort_order = window.prop []
        @route = m.route.get!

        if not @paged
            @scroll_handler = debounce 150, @onscroll
            $ window .on 'scroll', @scroll_handler

        if (not options.dont-reuse) then
            app.$m.data.collection_states[m.route.get!] = @

    onremove: ~>
        if not @paged
            $ window .off 'scroll', @scroll_handler

    onscroll: ~>
        if @route != m.route.get!
            return
        if m.route.get! of app.$m.data.scroll_states
            app.$m.data.scroll_states[m.route.get!] = $(window).scrollTop!
            if not @loading! and $(window).scrollTop! >= $(document).height! - $(window).height! - 1000
                if @can_show_more_items!
                    @show_more!
        else
            app.$m.data.scroll_states[m.route.get!] = 0

    init: ~>
        page = parseInt (m.route.param 'page')
        if page
            @init_page page
        else
            @query!

    onpagechange: (page) !~>
        # Function to call when changing page from an existing page.
        new_offset = (page - 1) * @limit
        @load_until new_offset + @limit
        @offset = new_offset

    init_page: (page) !~>
        # Initialize the collection to start on the specified page.
        @show_count = page * @limit
        @offset = 0
        @query {}, @offset, @show_count
        @offset = @show_count - @limit

    update: ~>
        @query!

    requery: ~>
        @items []
        if @filter_serverside!
            @search_result []
        if @show_count < @limit
            @show_count = @limit
        @query {}, 0, @show_count + 1

    requeryCondition: ~>
        return true

    query: (params, offset, limit) ~>
        if @only_query_on_search and not @search_terms!length
            # We shouldn't do a search. Reset the state.
            @items []
            @search_result []
        else
            if @on_query_start then
                @on_query_start()
            @queried true
            @loading true
            m.redraw!

            # Collect the data for querying.
            data = do
                offset: if offset? then offset else @offset
                limit: if limit? then limit + 1 else @limit + 1
                search_terms: @search_terms!
                sort_order: @sort_order!
                sort_by: @sort_by!
                ascending: @ascending!
            for key, value of @additional_params!
                data[key] = value

            @query_data data

            app.$m.common.observable.broadcast "collection.#{@api_function_name}.before-call"

            api.call @api_function_name, data, @handle_query

    handle_query: (resp) ~>
        if not resp.success
            app.$m.common.generic_error_handler 'Query was not successful'
            return

        if not resp.result
            app.$m.common.generic_error_handler 'Provide the result of the query as \'{\'result\': [...]}\''
            return

        result = resp.result

        if @data_key
            data = result[@data_key]
        else
            data = result

        # Merge the received items with items we already have. Make sure to always select the
        # new items, to reflect any potential changes on the server, but also make sure to append
        # the server results to the end to not mess up the ordering.
        updated_artkeys = [r.artkey for r in data]
        @items union @items!filter((.artkey not in updated_artkeys)), data

        if 'total' of resp or 'total_count' of resp or 'total' of result or 'total_count' of result
            @total_number_of_items (resp.total || resp.total_count || result.total || result.total_count || 0)
            @show_count = @limit_show_count @show_count

            if @proxy_collection then
                @proxy_collection.total = @total_number_of_items!

        # Update the aggregates: make a property for each key in the resp.aggs map.
        # Or update the value if the property was already created.
        if 'aggs' of resp and Object.keys(resp.aggs).length
            for k, v of resp.aggs
                if @aggs[k]?
                    @aggs[k] v
                else
                    @aggs[k] = window.prop v
        else
            @aggs = {}

        if @proxy_collection then
            @proxy_collection.items.splice(0, @proxy_collection.items.length, ...result)

        # Now that we have queried the items, filter them.
        @filter_items!
        if @on_query_end then
            @on_query_end()


    sort_func: (x, y) ~>
        for sort in @_sort_order!
            x_prop = get_prop_or_string(x, sort.name)
            y_prop = get_prop_or_string(y, sort.name)
            if x_prop > y_prop
                return if sort.direction == 'asc' then 1 else -1
            else if x_prop < y_prop
                return if sort.direction == 'asc' then -1 else 1
        return 0

    filter_items: ~>
        # First filter the items based on the match function
        if not @filter_serverside! then
            filtered = @items!filter(@is_search_match)
            if @sort_by!
                @_sort_order @sort_order!filter((a) ~> a.name != @sort_by!)
                @_sort_order!unshift({'name': @sort_by!, 'direction': if @ascending! then 'asc' else 'desc'})
            else
                @_sort_order @sort_order!

            if @_sort_order!length
                # Remove current sort and prepend it.
                sorted = sort-with (@sort_func), filtered
            else
                sorted = filtered
        else
            sorted = @items!

        # If the number of filtered items is more then we show, then we can show more
        @can_show_more_items (@show_count < @total_number_of_items!)

        # After sorting, load the search results with the number of items we want to show
        @search_result slice(0, @show_count, sorted)

        @loading false

        app.$m.common.observable.broadcast("collection.#{@api_function_name}.after-call", {})

    is_search_match: (item) ~>
        # filter the items based on the search terms
        # Union to make sure the function is always evaluated, even if no terms are provided.
        for term in union @search_terms!filter((search_term) -> search_term != '\\'), ['']
            keyword = null
            if term.indexOf(':') > 0
                components = term.split(':')
                keyword = components.shift!
                term = components.join(':')
            return false if not @filter_function item, term, @additional_params!, keyword
        true

    update_search_term: (value) ~>
        # set the new search term, but don't search yet
        @search_term value
        @search_terms if @search_term!length > 0 \
                      then [token.trim! for token in @search_term!toLowerCase!split ' ']
                      else []

    submit_search_event: (e) ~>
        # When hitting enter, filter the items and query the database
        if e.keyCode == 13 # enter
            @submit_search!

    submit_search: ~>
        @show_count = @limit
        @offset = 0
        if @filter_serverside! then
            @requery!
        else
            @filter_items!
            @query!

    reset_search: ~>
        @show_count = @limit
        @offset = 0
        @queried false
        @update_search_term ''
        @requery!

    sort: (column, ascending=true, sort_order=[]) ~>
        if @sort_by! != column
            @ascending ascending
        else
            @ascending !@ascending!

        # Direction in sort_order if direction is not default.
        if ascending != @ascending! then
            flip-direction = ({name, direction}) -> do
                name: name
                direction: if direction == 'asc' then 'desc' else 'asc'

            sort_order = sort_order |> map (copy >> flip-direction)

        @sort_by column
        @sort_order (sort_order ++ @default_sort_order)
        @requery!

    sort_icon: (column) ~>
        if column == @sort_by!
            m 'span.glyphicon' {class: if @ascending! \
                                    then 'glyphicon-triangle-top' \
                                    else 'glyphicon-triangle-bottom'}

    show_more: ~>
        @show_count = @limit_show_count @limit + @show_count
        @offset += @limit
        @query!

    load_until: (count) ~>
        new_show_count = @limit_show_count count
        num_to_load = new_show_count - @show_count
        if num_to_load > 0
            @offset = @show_count
            @show_count = new_show_count
            @query null, @offset, num_to_load

    showing_all: ~>
        return @show_count >= @total_number_of_items!

    limit_show_count: (count) ~>
        if @total_number_of_items!? and count > @total_number_of_items!
        then @total_number_of_items!
        else count

    show_all: ~>
        @show_count = @total_number_of_items!
        @query null, 0, @total_number_of_items!

    show_counter: ~>
        # To show the counter, add a key 'total' to your query result with as value the total number of results
        show_count = minimum [@show_count, @total_number_of_items!]
        m '.collection-counter' [
            if !@loading!
                [
                    'Showing ', show_count, ' of ', @total_number_of_items!, ' results '
                ]
        ]

    pager: ~>
        return pager do
            page_size: @limit
            count: @total_number_of_items
            offset: @offset
            loading: @loading
            onpagechange: @onpagechange

    no_results: ~>
        @queried! and not @search_result!length and not @loading!

    soft_delete: (artkey) ~>
        # Remove a item from the collection, does not delete it from the backend.
        @items @items!filter((.artkey != artkey))
        @filter_items!

    update_item_property: (artkey, property_name, value) ~>
        # Update an item property with the provided value.
        @update artkey, (item) -> item[property_name] = value

    update: (artkey, fn) ~>
        @items!
        |> find (.artkey == artkey)
        |> maybe-map fn

    # Helper function to create a filter function for a
    # certain property using currying.
    requeryFilter: (property, value) ~~>
        if value?
            property value
            if @requeryCondition()
                @requery!
        else
            return property!



# A function for getting a property
get_prop_or_string = (obj, desc) ->
    descendant_prop = utils.get_descendant_prop obj, desc
    if not prop?
        descendant_prop = ''

    # Check if it's a number
    number = +descendant_prop
    if number
      return number

    # If it's a string, lowercase it
    if typeof(descendant_prop) == "string"
        descendant_prop = descendant_prop.toLowerCase!replace(/[ .*+?^${}()}|[\]\\]/g, '')
    return descendant_prop
