pricelist_link = {}
msi_pricelist_link = {}
module.exports = {pricelist_link: pricelist_link, msi_pricelist_link: msi_pricelist_link}

pricelist_link.from_bottle = (pricelist_artkey, bottle) ->
    return pricelist_link.create(
        pricelist_artkey,
        bottle.product!name!,
        +bottle.volume!,
        +bottle.alcohol_percentage!
    )

pricelist_link.from_bottle_json = (pricelist_artkey, bottle) ->
    return pricelist_link.create(
        pricelist_artkey,
        bottle.product.name,
        +bottle.volume,
        +bottle.alcohol_percentage
    )

pricelist_link.create = (pricelist_artkey, product_name, volume, alcohol_percentage) ->
    if pricelist_artkey == null
        # Then it's an offer item in disguise.
        return msi_pricelist_link.create(product_name, volume, alcohol_percentage)

    return "/market/pricelists/#{pricelist_artkey}?search=
      #{product_name} #{volume.toFixed(1)} #{alcohol_percentage.toFixed(1)}"

msi_pricelist_link.create = (product_name, volume, alcohol_percentage) ->
    url = "/pricelist/offer-item-list?q=
      #{product_name} #{volume.toFixed(1)} #{alcohol_percentage.toFixed(1)}"
    return url.replace(/ /g, '+')
