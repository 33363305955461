# Higher order function that returns an event handler that will stop the
# propagation of the event and then call fn. By default the fn argument
# is an empty function, thus you can use this function as follows:
#
#     {onclick: stop-propagation!}
#
export stop-propagation = (fn = (->)) -> (e) ->
    e.stopPropagation!
    fn e


export prevent-default = (fn) -> (e) ->
    e.preventDefault!
    fn e
