m = require 'mithril'
GenericEdit = require '../generic/generic_edit.ls'
{Field} = require '../generic/generic_field.ls'
app = require('@/app')

module.exports = class VatTypeEdit
    ->
        @fields = [
            new Field 'Code', 'vat_code', 'input', 'text'
            new Field 'Percentage', 'percentage', 'input', 'number', {min: 0, step: 0.01}
            new Field 'Description', 'description', 'textarea', 'text', {rows: 4, required: false}
        ]

    view: ->
        m GenericEdit, do
            readable_entity_name: 'VAT type'
            base_url: '/data/vattypes/'
            query_api_call: 'vattypes.get_vat_type'
            update_or_create_api_call: 'vattypes.update_or_create_vat_type'
            fields: @fields
            create_entity_func: app.$m.data.vat_type.create
            broadcast_on_update: 'vattypes_updated'
