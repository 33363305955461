import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'

import {TabViewControl} from '../relation_dashboard'

import {Link} from '@/components/discover'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {RelationDashboardData, RelationDashboardFilter} from '@/offer/relation-dashboard/relation_dashboard_model'
import {GetSpecialsPriceListCollectionViewResponseOutput} from '@/factserver_api/fact2server_api'

interface SpecialOfferItemsAttrs {
    register_tab_view_control: (tab_view: TabViewControl) => void
    relation_dashboard_data: RelationDashboardData
    relation_dashboard_filters: RelationDashboardFilter
}

export class SpecialOfferItems extends MithrilTsxComponent<SpecialOfferItemsAttrs> implements TabViewControl {
    specials_fetcher: PagedCollectionFetcherWithGET<GetSpecialsPriceListCollectionViewResponseOutput>
    watches: type_remove_watch_function[] = []

    constructor(vnode: m.Vnode<SpecialOfferItemsAttrs>) {
        super()
        vnode.attrs.register_tab_view_control(this)
        this.specials_fetcher = new PagedCollectionFetcherWithGET<GetSpecialsPriceListCollectionViewResponseOutput>(
            `discover/relations/${vnode.attrs.relation_dashboard_data.selected_relation?.artkey}/specials-price-list/collection-view`,
            'product_name',
        )
    }

    oncreate(vnode: m.VnodeDOM<SpecialOfferItemsAttrs, this>): any {
        if (!vnode.attrs.relation_dashboard_data.selected_relation) {
            return
        }

        this.specials_fetcher.filters.supplier_artkey = vnode.attrs.relation_dashboard_data.selected_relation.artkey
        this.specials_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
        this.specials_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys
        this.specials_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin

        this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
        this.update_only_show_type(vnode.attrs.relation_dashboard_filters)

        this.watches = [
            watch(
                vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys,
                () => {
                    this.specials_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys || []
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't1_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't2_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_stock',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_tbo',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_purchase',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'product_name',
                () => {
                    this.specials_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'customs_status',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.specials_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'gtin',
                () => {
                    this.specials_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin
                    this.specials_fetcher.reset_and_query()
                },
            ),
        ]
    }

    onremove(): any {
        this.watches.forEach((unsubscribe) => unsubscribe())
    }

    update_only_show_type(filter: RelationDashboardFilter) {
        const show_types: string[] = []
        if (filter.show_tbo) {
            show_types.push('tbo')
        }
        if (filter.show_purchase) {
            show_types.push('purchase')
        }
        if (filter.show_stock) {
            show_types.push('stock')
        }
        this.specials_fetcher.filters.only_show_type = show_types
    }

    update_customs_status_filter(filter: RelationDashboardFilter) {
        if (filter.t1_filter !== filter.t2_filter) {
            if (filter.t1_filter) {
                this.specials_fetcher.filters.customs_status = 'T1'
            } else {
                this.specials_fetcher.filters.customs_status = 'T2'
            }
        } else {
            this.specials_fetcher.filters.customs_status = ''
        }
    }

    is_loading(): boolean {
        return this.specials_fetcher.is_fetching
    }

    view(): m.Children {
        return (
            <span>
                <CollectionTable<GetSpecialsPriceListCollectionViewResponseOutput, void>
                    collection_fetcher={this.specials_fetcher}
                >
                    <CollectionTableColumn<any>
                        header_title={() => 'Product'}
                        sort_name={'product_name'}
                        data_field={(row) => {
                            return <div className="td-group">
                                <span className="header">
                                    {row.product_name}
                                </span>
                                <span>{to_specs(row)}</span>
                                {row.case_article_code && <span className="details">{row.case_article_code}</span>}
                            </div>}
                        }
                    />

                    <CollectionTableColumn<GetSpecialsPriceListCollectionViewResponseOutput>
                        header_title={() => 'Offer'}
                        data_field={(row: GetSpecialsPriceListCollectionViewResponseOutput) =>
                            <Link href={`/offer/offers/${row.offer_artkey}/details`}>{row.offer_title}</Link>
                        }
                    />

                    <CollectionTableColumn<GetSpecialsPriceListCollectionViewResponseOutput>
                        header_title={() => 'Validity'}
                        data_field={(row: GetSpecialsPriceListCollectionViewResponseOutput) =>
                            `${format_iso_to_date(row.offer_start_date)} - ${format_iso_to_date(row.offer_end_date)}`
                        }
                    />

                </CollectionTable>
            </span>
        )
    }
}
