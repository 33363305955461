import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {country_for_country_code} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {Country, FieldCheckbox, Spinner, Icon} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'

import {$s} from '@/app'
import {EditableRelationList, RelationsSearch} from '@/components/relation.tsx'
import {SearchBarControl} from '@/components/collection/search_bar.tsx'
import {EditAssist, HorizontalForm} from '@/components/form'
import {
    CreateEntityResponse,
    GetLocationResponseOutput,
    GetPurchaseOrderResponseOutput,
    GetRelationLocationResponseOutput,
    GetRelationResponse, GetRelationsResponse, PurchaseOrderStatus,
    UpdateOrCreatePurchaseOrderRequest,
} from '@/factserver_api/fact2server_api.ts'
import {
    DropDownOption,
    DropDownWithSelect,
    InputDate,
    TextArea,
    TextInput,
} from '@/components/html_components.tsx'
import {Api} from '@/factserver_api/api.ts'
import {CurrenciesDropDown} from '@/components/currencies.tsx'
import {InsuranceTypesDropDown} from '@/components/insurance_types.tsx'
import {InsuranceTypesDropDownData} from '@/factserver_api/insurance_types_api.ts'
import {icon_for_destination_type} from '@/components/destinations.tsx'
import {IncotermsDropDown} from '@/components/incoterms.tsx'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api.ts'
import {NumberInput} from '@/components/input_numbers'
import {MoneyInput} from '@/components/decimal_input'

interface CreateOrEditPurchaseOrderComponentAttrs {
    onsaved: (number) => unknown // called if is_used_as_inline_form is true, after save of the new purchase order.
    relation_artkey?: number
    is_used_as_inline_form?: boolean // If this is set, this behaves as a 'fieldset'. No redirects etc not onsaved. Only for create.
}

interface EditableUpdatePurchaseOrderRequest extends Pick<
    UpdateOrCreatePurchaseOrderRequest,
    'relation_artkey' | 'incoterm' | 'incoterm_location' | 'currency' | 'exchange_rate' | 'remark' |
    'insurance_type_artkey' | 'supplier_reference' |
    'number_of_euro_pallets' | 'number_of_block_pallets' | 'expected_delivery_date' |
    'warehouse_costs_per_case' | 'warehouse_base_costs' | 'waste_fund_costs' |
    'other_costs' | 'transport_costs' | 'pallet_costs' | 'import_statement_override' | 'override_reason'
> {
    updated_exchange_rate: string
    origin_warehouse_artkey?: number
    target_warehouse_artkey?: number
}

export class CreateOrEditPurchaseOrderComponent extends MithrilTsxComponent<CreateOrEditPurchaseOrderComponentAttrs> {
    api: Api = new Api()

    data = proxy({
        selected_relations: [] as GetRelationsResponse[],
        selected_relation_artkey: null as number | null,
        selected_relation: null as GetRelationsResponse | null,
    })

    watches: type_remove_watch_function[] = []

    is_loading = false
    edit_assist: EditAssist
    search_bar_control: SearchBarControl | null = null

    relation_locations: GetRelationLocationResponseOutput[] = []
    target_locations: GetRelationLocationResponseOutput[] = []

    selected_origin_location: GetRelationLocationResponseOutput | undefined = undefined
    selected_target_location: GetLocationResponseOutput | undefined = undefined

    purchase_order_status = 'Saved'
    update_request: EditableUpdatePurchaseOrderRequest = proxy({
        order_other_costs: 0,
        order_pallet_costs: 0,
        order_transport_costs: 0,
        warehouse_base_costs: 0,
        warehouse_costs_per_case: 0,
        waste_fund_costs: 0,
        insurance_type_artkey: 0,
        supplier_reference: '',
        relation_artkey: 0,
        incoterm: 'EXW',
        incoterm_location: '',
        currency: 'EUR',
        exchange_rate: 1,
        updated_exchange_rate: '1',
        remark: '',
        target_warehouse_artkey: 0,
        origin_warehouse_artkey: 0,
        number_of_euro_pallets: 0,
        number_of_block_pallets: 0,
        expected_delivery_date: '',
        import_statement_override: false,
    })

    constructor(vnode: m.Vnode<CreateOrEditPurchaseOrderComponentAttrs>) {
        super()

        this.edit_assist = new EditAssist(m.route, vnode.attrs.is_used_as_inline_form)
    }

    async oncreate(vnode: m.Vnode<CreateOrEditPurchaseOrderComponentAttrs>) {
        if (vnode.attrs.relation_artkey) {
            this.data.selected_relation_artkey = vnode.attrs.relation_artkey
            await this.fetch_relation()
        }
        else {
            this.data.selected_relation = null
            this.update_origin_warehouse()
        }

        await this.fetch_target_warehouses()

        if (!this.edit_assist.is_creating) {
            await this.fetch_purchase_order()
        }
        else {
            this.update_target_warehouse('2')
        }

        this.init_watches()
    }

    init_watches() {
        this.watches.push(
            watch(this.data.selected_relations, async() => {
                this.data.selected_relation = this.data.selected_relations[0]
                await this.fetch_origin_warehouses(this.data.selected_relations[0]?.artkey)

                if (!this.data.selected_relation) {
                    this.data.selected_relation = null
                    this.relation_locations = []
                    this.data.selected_relations.splice(0, this.data.selected_relations.length)
                    return
                }
            }),
        )
        this.watches.push(watch(this.data, 'selected_relation_artkey', async() => {
            if (this.data.selected_relation_artkey) {
                await this.fetch_relation()
            }
            else {
                this.data.selected_relation = null
                this.update_origin_warehouse()
            }
        }))

    }

    onremove() {
        this.watches.forEach((watch) => watch())
    }

    async fetch_origin_warehouses(relation_artkey?: number) {
        if (!relation_artkey) {
            this.relation_locations = []
            return
        }
        this.relation_locations = await this.api.get_async(`discover/relations/${relation_artkey}/destinations`)
        if (this.relation_locations.length === 1) {
            this.update_origin_warehouse(`${this.relation_locations[0].artkey}`)
        }
        m.redraw()
    }

    async fetch_target_warehouses() {
        this.target_locations = await this.api.get_async('discover/stock/locations')
    }

    async fetch_purchase_order() {
        const purchase_order = await this.api.get_async<GetPurchaseOrderResponseOutput>(`discover/purchase-orders/${this.edit_assist.artkey}`)
        this.data.selected_relation_artkey = purchase_order.supplier.artkey

        this.update_request.updated_exchange_rate = purchase_order.bought_against_rate
        this.update_request.exchange_rate = purchase_order.bought_against_rate
        this.update_request.currency = purchase_order.was_bought_in
        this.update_request.insurance_type_artkey = purchase_order.insurance_type.artkey
        this.update_request.supplier_reference = purchase_order.supplier_reference
        this.update_request.remark = purchase_order.remark
        this.update_request.incoterm = purchase_order.incoterm
        this.update_request.incoterm_location = purchase_order.incoterm_location
        this.update_request.number_of_euro_pallets = purchase_order.number_of_euro_pallets
        this.update_request.number_of_block_pallets = purchase_order.number_of_block_pallets
        this.update_request.expected_delivery_date = purchase_order.expected_delivery_date
        this.update_request.warehouse_base_costs = purchase_order.warehouse_base_costs
        this.update_request.warehouse_costs_per_case = purchase_order.warehouse_costs_per_case
        this.update_request.pallet_costs = purchase_order.pallet_costs
        this.update_request.transport_costs = purchase_order.transport_costs
        this.update_request.other_costs = purchase_order.other_costs
        this.update_request.import_statement_override = purchase_order.import_statement_override
        this.update_request.override_reason = purchase_order.override_reason
        this.update_request.relation_artkey = purchase_order.supplier.artkey

        await this.fetch_relation()

        this.update_target_warehouse(`${purchase_order.target_warehouse.artkey}`)
        this.update_origin_warehouse(`${purchase_order.origin_warehouse?.artkey}`)

        this.purchase_order_status = purchase_order.status
    }

    async fetch_relation() {
        if (!this.data.selected_relation_artkey) {
            this.data.selected_relation = null
            this.relation_locations = []
            this.data.selected_relations.splice(0, this.data.selected_relations.length)
            return
        }
        const api_calls = [
            this.fetch_relation_from_api(),
            this.fetch_origin_warehouses(this.data.selected_relation_artkey),
        ]
        await Promise.all(api_calls)
    }

    async fetch_relation_from_api() {
        const {response , result} = await api.get<GetRelationResponse>(`discover/relations/${this.data.selected_relation_artkey}`)
        if (!response.ok) {
            notifier.notify('Cannot load the supplier. Please check the account drop down on the top?', 'warning')
            return
        }
        this.data.selected_relation = result

        if (this.data.selected_relations.length === 0) {
            this.data.selected_relations.push(this.data.selected_relation)
        }
        this.search_bar_control?.set_and_submit_search_text(this.data.selected_relation?.name || '', true)

    }

    async create_or_update_purchase_order(vnode: m.Vnode<CreateOrEditPurchaseOrderComponentAttrs>) {
        if (!this.data.selected_relation) {
            return
        }
        const request_body = {
            ...this.update_request,
            exchange_rate: this.update_request.updated_exchange_rate,
            relation_artkey: this.data.selected_relation.artkey,
            expected_delivery_date: this.update_request.expected_delivery_date
                ? DateTime.fromISO(this.update_request.expected_delivery_date).toISO()
                : null,
        }

        if (vnode.attrs.is_used_as_inline_form || this.edit_assist.is_creating) {
            const result = await this.api.post_async<EditableUpdatePurchaseOrderRequest, CreateEntityResponse>(
                'discover/purchase-orders', request_body,
            )
            if (vnode.attrs.is_used_as_inline_form) {
                vnode.attrs.onsaved(result.artkey)
            } else {
                m.route.set(`/purchase-orders/manage/${result.artkey}`)
            }
        }
        else {
            await this.api.put_async(`discover/purchase-orders/${this.edit_assist.artkey}`, request_body)
            notifier.notify('Successfully updated Purchase Order', 'info')
            m.route.set(`/purchase-orders/manage/${this.edit_assist.artkey}`)
        }

    }

    update_target_warehouse = (destination_artkey?: string): void => {
        this.selected_target_location = undefined
        if (!destination_artkey) {
            return
        }
        this.selected_target_location = this.target_locations.find(
            (destination) => destination.artkey === +destination_artkey,
        )
        this.update_request.target_warehouse_artkey = this.selected_target_location?.artkey
        if (this.selected_target_location) {
            this.update_request.warehouse_base_costs = this.selected_target_location.warehouse_base_costs
            this.update_request.warehouse_costs_per_case = this.selected_target_location.warehouse_costs_per_case
        }
        this.update_incoterm(this.update_request.incoterm)
    }

    update_origin_warehouse = (origin_artkey?: string): void => {
        this.selected_origin_location = undefined
        if (!this.data.selected_relation || !origin_artkey) {
            return
        }
        this.selected_origin_location = this.relation_locations.find(
            (destination) => destination.artkey === +origin_artkey,
        )
        this.update_request.origin_warehouse_artkey = this.selected_origin_location?.artkey
        this.update_incoterm(this.update_request.incoterm)
    }

    update_incoterm(selected_incoterm: string): void {
        this.update_request.incoterm = selected_incoterm
        let incoterm_location = ''
        if (['EXW', 'FCA'].includes(selected_incoterm)) {
            if (this.selected_origin_location) {
                if (this.selected_origin_location.city && this.selected_origin_location.country_code) {
                    const country = country_for_country_code(this.selected_origin_location?.country_code || '') || ''
                    incoterm_location = `${this.selected_origin_location?.city || ''} - ${country}`
                }
                else if (this.selected_origin_location.city) {
                    incoterm_location = this.selected_origin_location?.city
                }
            }
        } else if (['DAP', 'DDP', 'CFR', 'CIF'].includes(selected_incoterm)) {
            if (this.selected_target_location) {
                const country = country_for_country_code(this.selected_target_location?.country_code || '') || ''
                incoterm_location = `${this.selected_target_location?.city || ''} - ${country}`
            }
        }
        // Only auto update if we can generate a sensible value.
        if (incoterm_location) {
            this.update_request.incoterm_location = incoterm_location
        }
    }

    /** PurchaseOrder fields are disabled if status = INVOICED or there is no supplier selected.*/
    is_disabled(): boolean {
        return !this.data.selected_relation || this.purchase_order_status === '???' || this.purchase_order_status === 'Cancelled' // TODO
    }

    view(vnode: m.Vnode<CreateOrEditPurchaseOrderComponentAttrs>): m.Children {
        if (this.is_loading) return <Spinner/>

        return (
            <HorizontalForm
                classNames="c-purchase-order-edit"
                onsubmit={() => this.create_or_update_purchase_order(vnode)}
                submit_title={this.edit_assist.is_creating ? 'Create Purchase Order' : 'Update Purchase Order'}
                edit_assist={this.edit_assist}>

                <div className="fieldset-group">

                    <div className="fieldset">
                        <div className="field">
                            <RelationsSearch
                                label={'Relation'}
                                only_suppliers={true}
                                selected_relations={this.data.selected_relations}
                                search_bar_controller={(controller: SearchBarControl) =>
                                    (this.search_bar_control = controller)
                                }
                                disabled={vnode.attrs.is_used_as_inline_form || this.data.selected_relation}
                            />
                            <EditableRelationList
                                disabled={vnode.attrs.is_used_as_inline_form}
                                selected_relations={this.data.selected_relations}/>
                        </div>
                        <DropDownWithSelect
                            label="Origin warehouse"
                            context="Origins can be added or changed on the edit page of the relation."
                            selected={`${this.selected_origin_location?.artkey}`}
                            onchange={this.update_origin_warehouse}
                            empty_option={<DropDownOption value={''}>{''}</DropDownOption>}
                            disabled={this.is_disabled()}
                            required={true}
                        >
                            {this.relation_locations.map((warehouse) => (
                                <DropDownOption value={`${warehouse.artkey}`}>
                                    {warehouse.name} - {warehouse.city} ({warehouse.country_code})
                                </DropDownOption>
                            ))}
                        </DropDownWithSelect>

                        <DropDownWithSelect
                            label="Target"
                            context="Destinations can be added or changed on the data/warehouse page for warehouses that handle our stock."
                            onchange={this.update_target_warehouse}
                            selected={`${this.selected_target_location?.artkey}`}
                            empty_option={<DropDownOption value={''}>{''}</DropDownOption>}
                            disabled={this.is_disabled()}
                            required={true}
                        >
                            {this.target_locations.map((warehouse) => (
                                <DropDownOption value={`${warehouse.artkey}`}>
                                    {warehouse.name} - {warehouse.city} ({warehouse.country_code}) {warehouse.is_primary && '(Primary)'}
                                </DropDownOption>
                            ))}
                        </DropDownWithSelect>
                        <div className="field">
                            <label>Currency</label>
                            <CurrenciesDropDown
                                disabled={this.is_disabled()}
                                required={true}
                                selected_currency={this.update_request.currency}
                                onchange={(currency) => {
                                    this.update_request.currency = currency.currency
                                    this.update_request.updated_exchange_rate = $s.currencies.exchange_rates[currency.currency].rate
                                }}
                            />
                            {this.update_request.currency !== 'EUR' && <TextInput
                                label="Exchange rate"
                                required={true}
                                value={this.update_request.updated_exchange_rate}
                                oninput={(value: string) => this.update_request.updated_exchange_rate = value}
                            />}

                        </div>
                        <div className="field">
                            <InsuranceTypesDropDown
                                label="Insurance type"
                                selected_insurance_type_artkey={`${this.update_request.insurance_type_artkey}`}
                                disabled={this.is_disabled()}
                                get_all_for_drop_down_response$={InsuranceTypesDropDownData.insurance_types()}
                                onchange={(artkey) => this.update_request.insurance_type_artkey = artkey}
                            />

                        </div>
                        <div className="field">
                            <TextInput
                                label="Supplier ref"
                                disabled={this.is_disabled()}
                                value={this.update_request.supplier_reference}
                                oninput={(value: string) => this.update_request.supplier_reference = value}
                            />

                        </div>
                        <div className="field">
                            <TextArea
                                label="Remarks"
                                disabled={this.is_disabled()}
                                value={this.update_request.remark}
                                oninput={(value: string) => this.update_request.remark = value}
                            />

                        </div>
                        {$s.identity.user?.is_superuser && this.data.selected_relation && !this.data.selected_relation.is_verified && (
                            <div className="field">
                                <FieldCheckbox
                                    label="Import statement override"
                                    ref={[this.update_request, 'import_statement_override']}
                                    disabled={this.is_disabled() || this.purchase_order_status !== PurchaseOrderStatus.Saved}
                                />
                                {this.update_request.import_statement_override && (
                                    <TextArea
                                        label={'Override reason'}
                                        disabled={this.is_disabled() || this.purchase_order_status !== PurchaseOrderStatus.Saved}
                                        value={this.update_request.override_reason}
                                        oninput={(value: string) => this.update_request.override_reason = value}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="fieldset">
                        <div className="field">
                            {/* Summary of the IncoTerm */}
                            <div className="transport-summary mb-2">
                                {this.selected_origin_location &&
                                    <div className="transport">
                                        {icon_for_destination_type(this.selected_origin_location.destination_type)}
                                        <div className="details">
                                            <div>{this.selected_origin_location.name}</div>
                                            <div>{this.selected_origin_location.street_address}</div>
                                            <div>{this.selected_origin_location.city}{' '}
                                                <Country country_code={this.selected_origin_location.country_code}/>
                                            </div>
                                        </div>
                                    </div>}

                                <div className="transport">
                                    <span className={'glyphicon glyphicon-arrow-right'}/>
                                    <div className="details">
                                        <div>{this.update_request.incoterm} - {this.update_request.incoterm_location}</div>
                                    </div>
                                </div>

                                {this.selected_target_location &&
                                    <div className="transport">
                                        {icon_for_destination_type(this.selected_target_location.destination_type)}
                                        <div className="details">
                                            <div>{this.selected_target_location.name}</div>
                                            <div>{this.selected_target_location.street_address}</div>
                                            <div>{this.selected_target_location.city}{' '}
                                                <Country
                                                    country_code={this.selected_target_location.country_code}/>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        <div className="field">
                            <IncotermsDropDown
                                label="Incoterm"
                                selected_incoterm={this.update_request.incoterm}
                                disabled={this.is_disabled()}
                                get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                                onchange={(selected_incoterm) => this.update_incoterm(selected_incoterm)}
                            />
                        </div>
                        <div className="field">
                            <TextInput
                                label="Incoterm location"
                                required={true}
                                disabled={this.is_disabled()}
                                value={this.update_request.incoterm_location || ''}
                                oninput={(value) => this.update_request.incoterm_location = value}
                            />
                        </div>
                        <div className="field-group">
                            <div className="field">
                                <NumberInput
                                    label={'Euro pallets'}
                                    value={this.update_request.number_of_euro_pallets}
                                    oninput={(value) => this.update_request.number_of_euro_pallets = value}
                                    disabled={this.is_disabled()}
                                    required={false}
                                />
                            </div>
                            <div className="field">
                                <NumberInput
                                    label={'Block pallets'}
                                    value={this.update_request.number_of_block_pallets}
                                    oninput={(value) => this.update_request.number_of_block_pallets = value}
                                    disabled={this.is_disabled()}
                                    required={false}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <InputDate
                                label="Estimated delivery date"
                                disabled={this.is_disabled()}
                                value={this.update_request.expected_delivery_date || ''}
                                onchange={(val: DateTime) =>
                                    val ? this.update_request.expected_delivery_date = val.toISODate() : null
                                }
                            />
                        </div>
                        <div className="field-group">
                            <div className="field">
                                <MoneyInput
                                    label={'Warehouse base costs'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.warehouse_base_costs}
                                    on_value={(value) => this.update_request.warehouse_base_costs = value}
                                />
                            </div>
                            <div className="field">
                                <MoneyInput
                                    label={'Warehouse costs per case'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.warehouse_costs_per_case}
                                    on_value={(value) => this.update_request.warehouse_costs_per_case = value}
                                />
                            </div>
                            {this.selected_relation?.country_code === 'NL' && <div className="field">
                                <MoneyInput
                                    label={'Waste fund'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.waste_fund_costs}
                                    on_value={(value) => this.update_request.waste_fund_costs = value}
                                />
                            </div>}
                        </div>
                        <div className="field-group">
                            <div className="field">
                                <MoneyInput
                                    label={'Pallet costs'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.pallet_costs}
                                    on_value={(value) => this.update_request.pallet_costs = value}
                                />
                            </div>
                            <div className="field">
                                <MoneyInput
                                    label={'Transport costs'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.transport_costs}
                                    on_value={(value) => this.update_request.transport_costs = value}
                                />
                            </div>
                            <div className="field">
                                <MoneyInput
                                    label={'Other costs'}
                                    currency={'EUR'}
                                    disabled={this.is_disabled()}
                                    value={this.update_request.other_costs}
                                    on_value={(value) => this.update_request.other_costs = value}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!this.update_request.import_statement_override && this.data.selected_relation && !this.data.selected_relation.is_verified && (
                    <div class="alert alert-warning">
                        <Icon name='warning' type='warning' size='s'/>
                        {' '}
                        This relation is yet to be verified.
                        {' '}
                        If you want to confirm this purchase order, please verify the relation or upload a signed RFP to this purchase order.
                    </div>
                )}
            </HorizontalForm>
        )
    }
}
