m = require 'mithril'

errors = require 'errors.ls'

{current_account_slug} = require '@bitstillery/common/account/account'
{generate_transaction_id} = require '@bitstillery/common/ts_utils'
{random-uuid} = require 'utils.ls'
{debounce} = require '@bitstillery/common/ts_utils'
app = require('@/app')

class NotLoggedInException

class RequestException
class RequestCancelledException

UNSECURED_PROCESSES = ['users.get_pre_auth_token', 'auth.forgot_password', 'auth.reset_password']


handle_not_logged_in = debounce 250, ->
    app.$m.identity.logout(true)

handle_request_exception = debounce 250, ->
    errors.generic_handler!


call-via-http = (function_name, data, callback) ->
    if function_name not in UNSECURED_PROCESSES and not app.$s.identity.token
        return

    options = {}
    options.url = app.config.api_host + '/' + function_name
    options.method = "POST"

    data._account_slug = current_account_slug!
    # The background parameter affects redrawing. By default, Mithril waits for web service
    # requests to complete before attempting a redraw. This ensures that data being accessed
    # in the view isn't nullable as a result of asynchronous data not being available yet.
    # Setting the background option to true prevents a request from affecting redrawing.
    # This means it's possible for a view to attempt to use data before it is available.
    options.background = true
    options.body = do
        data: data

    options.extract = clear-id-on-unauthorized

    options.config = (xhr) ->
        # Basic auth for the first line of defense
        authorization = 'Basic ' + btoa("#{app.config.api_user}:#{app.config.api_password}")
        xhr.set-request-header 'Authorization', authorization

        # JWT for authentication
        if app.$s.identity.token
            xhr.set-request-header 'X-Auth-Token', app.$s.identity.token

        xhr.set-request-header 'X-Requested-With', 'XMLHttpRequest'
        xhr.set-request-header 'X-transaction-id', "disco-" + generate_transaction_id!

    request = m.request options

    redraw_callback = (resp) ~>
        callback(resp)
        m.redraw!

    handle_error = (error) ->
        if error instanceof NotLoggedInException then
            app.$m.identity.logout_debounced()
        else if error instanceof RequestException then
            handle_request_exception!
        else if error instanceof RequestCancelledException then
            console.log("Cancelled request.")
        else
            errors.generic_handler (error.message || error.title || error)

    if callback
        request.then redraw_callback, handle_error
    else
        return request


export call = call-via-http

# vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
# New definitions imported from Portal.
# The above call will be deprecated in the future.
# vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

export call2 = (process, data, success-cb, failure-cb=null, final-cb=null, redraw=true) ->
    if process not in UNSECURED_PROCESSES and not app.$s.identity.token
        return

    # Stores a reference to the XHR
    transport = window.prop null

    data._account_slug = current_account_slug!

    req = m.request do
        url: app.config.api_host + '/' + process
        method: 'POST'
        background: true  # don't affect redrawing.
        body: {data: data}
        config: get-request-headers >> transport
        extract: clear-id-on-unauthorized

    handle-response = (resp) ->
        if resp.success then
            success-cb resp
        else if failure-cb then
            failure-cb resp
        else
            errors.generic_handler_no_sentry resp.message

        if final-cb then
            final-cb resp

        if redraw then m.redraw!

    handle-error = (error) ->
        if error instanceof NotLoggedInException then
            handle_not_logged_in!
        else if error instanceof RequestException then
            handle_request_exception!
        else if error instanceof RequestCancelledException then
            handle_request_exception!
        else
            errors.generic_handler (error.message || error.title || error)

    do
        promise: req.then handle-response, handle-error
        abort: ->
            # Detach mithril's handlers before we abort the request.
            # If we don't do this, big red errors will show up in the console..
            transport!onreadystatechange = null
            transport!abort!


export call-and-then = (process, data, {success, failure, final, redraw}) ->
    if redraw == undefined then redraw = true
    call2 process, data, success, failure, final, redraw

get-request-headers = (xhr) ->
    # Add basic auth for first line of defence.
    xhr.set-request-header 'Authorization', basic-auth-header!
    xhr.set-request-header 'X-Requested-With', 'XMLHttpRequest'
    xhr.set-request-header 'X-transaction-id', "disco-" + generate_transaction_id!
    token = app.$s.identity.token
    if token then
        xhr.set-request-header 'X-Auth-Token', token

    xhr


basic-auth-header = ->
    'Basic ' + btoa "#{app.config.api_user}:#{app.config.api_password}"


clear-id-on-unauthorized = (xhr) ->
    if xhr.status == 401 then
        app.$s.identity.token = null
        throw new NotLoggedInException
    if xhr.status > 499
        throw new RequestException
    if xhr.status == 0
        throw new RequestCancelledException

    return JSON.parse xhr.responseText
