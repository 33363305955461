m = require 'mithril'
api = require 'api.ls'
inputs = require '@/components/inputs'
{prevent-default} = require 'utils.ls'
app = require('@/app')

# A component to change the position of a custom offer item in an offer.
export class EditPosition
    (vnode) ->
        @custom_offer_item = vnode.attrs.custom_offer_item
        @max = vnode.attrs.max
        @onchangedsuccessfully = vnode.attrs.onchangedsuccessfully

        @new_position = window.prop null
        @saving = window.prop false

    change_position: ~>
        @saving true
        data = do
            artkey: @custom_offer_item.artkey
            new_position: @new_position!

        api.call-and-then 'offer.change_custom_offer_item_position', data, do
            success: (resp) ~>
                app.$m.common.observable.broadcast 'custom_offer_item_updated'
                app.notifier.notify "Changed position of #{@custom_offer_item.name} from #{@custom_offer_item.position} to #{@new_position!}.", 'success'
                @onchangedsuccessfully!
            failure: (resp) ~>
                app.notifier.notify resp.message, 'danger'
                @saving false

    view: ->
        m 'form.form-horizontal', {onsubmit: prevent-default @change_position},
            m '.field',
                m 'label.col-sm-2.control-label' 'Current position'
                m '.col-sm-4' inputs.number (prop @custom_offer_item.position), do
                    disabled: true

            m '.field',
                m 'label.col-sm-2.control-label' 'New position'
                m '.col-sm-4' inputs.number @new_position, do
                    focus: true
                    min: 1
                    max: @max
                    required: true

            m 'form-group',
                m '.col-sm-offset-2',
                    m 'button.btn.btn-primary', do
                        type: 'submit'
                        disabled: @saving!
                    , 'Save new position'
