m = require 'mithril'
{Table} = require './table2.ls'
{Button, Spinner} = require '@bitstillery/common/components'
{slice} = require 'prelude-ls'
app = require('@/app')

export class CollectionTable extends Table
    (vnode) ->
        super ...
        @collection = vnode.attrs.collection

    view_column_header: (column) ->
        header = super ...
        if column.sort or column.sort_function then
            [header, ' ', @collection.sort_icon column.field]
        else
            header

    get_body_items: ->
        # If the collection is paged, only return one page of search results.
        if @collection.paged
            @collection.search_result! |> slice @collection.offset, (+@collection.offset) + (+@collection.limit)
        # Otherwise, return all search results.
        else
            @collection.search_result!

    sort_column: (field, ascending=true, sort_order=[]) ->
        @collection.sort field, ascending, sort_order

    view_footer: ->
        content =
            if @collection.loading!
                m Spinner, {className: 'table-spinner'}
            else if not @collection.search_result!length
                m 'div.no-results' 'No results found.'
            else if @collection.paged
                @collection.pager!
            else if (not @collection.paged) and (@collection.can_show_more_items!) and (!@collection.hide_show_more_items)
                m Button, {
                    className: 'mt-2',
                    icon: 'chevronRightDouble',
                    onclick: @collection.show_more
                    text: app.$t('table.fetch_more'),
                    type: 'info'
                }

        if content?
            m 'tbody' m 'tr' m 'td.table-footer' {colspan: '100%'} content
