m = require 'mithril'
{compact, join, map, min, Obj, or-list} = require 'prelude-ls'

{account-icon} = require 'accounts.ls'
api = require 'api.ls'
require! 'utils.ls': {
    map-obj, format-date, match-term-in, maybe-map, on-set, assoc,
    dissoc, update, pluralize, capitalize
}

{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{icon-button, button-with-icon} = require '@/components/buttons.ls'
{icon, icon-with-popover} = require '@/components/icon.ls'
{link} = require '@/components/link.ls'
{CaseInfoPanel} = require '@/components/market_info/case_info_panel'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
discover_link = require '@/market/components/discover_link'
explore_link = require '@/market/components/explore_link'

{TboStatus} = require '@/sales_orders/models.ls'
{UserDropDown} = require '@/components/users'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
app = require('@/app')

const TBO_STATUS_SELECTION = [
    [TboStatus.IN_PROGRESS, 'Show all in progress TBO items']
    [TboStatus.NOT_YET_APPROVED, 'Show not yet approved TBO items']
    [TboStatus.OPEN, 'Show open TBO items']
    [TboStatus.ORDERED, 'Show ordered TBO items']
    [TboStatus.CONFIRMED, 'Show confirmed TBO items']
    [TboStatus.ALL, 'Show all TBO items']
]


module.exports = class AssignedStock
    ->
        @tbo-items = new Collection do
            api_function_name: 'sales.tbo.get_tbo_items'
            additional_params: @additional_params
            query_limit: 25
            sort_order: []
            default_sort_by: 'sales_order.date'
            default_sort_order: 'asc'
            filter_serverside: true
            dont-reuse: true

        @sales_manager_artkey = \
            let prop = window.prop app.$s.identity.artkey
                on-set prop, (new-value) ~>
                    @tbo-items.requery!

        @search_status = window.prop ''

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @tbo-items.update_search_term text
        @tbo-items.submit_search!

    oncreate: ~>
        @tbo-items.init!

        q = m.route.param 'q'
        if q
            @submit_search q.replace(/\+/g, ' ')

    additional_params: ~>
        params = do
            sales_manager_artkey: @sales_manager_artkey!
        # Append the status filter, if selected.
        if @search_status! == TboStatus.ALL
            params['status'] = [TboStatus.OPEN, TboStatus.NOT_YET_APPROVED, TboStatus.ORDERED, TboStatus.CONFIRMED]
        else if @search_status! == TboStatus.IN_PROGRESS
            params['status'] = [TboStatus.OPEN, TboStatus.NOT_YET_APPROVED, TboStatus.ORDERED]
        else if @search_status!
            params['status'] = [@search_status!]
        else
            params['status'] = [TboStatus.OPEN]
        return params

    set_search_status: (search_status) ~>
        @search_status search_status
        @tbo-items.requery!

    view: -> m '.c-sales-assigned-stock view',
        m '.c-filter-group',
            m SearchBar,
                placeholder: "Search for products, specs, TBO supplier, tax label or other item tags..."
                on_submit: @submit_search
                default_search_text: @tbo-items.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

            inputs.select @search_status, TBO_STATUS_SELECTION,
                {onchange: @set_search_status}

            m UserDropDown, do
                selected_user_artkey: @sales_manager_artkey!
                onchange: (user_artkey) ~>
                    if user_artkey then
                        @sales_manager_artkey(user_artkey)
                    else @sales_manager_artkey(null)

        @tbo-items.show_counter!

        m CollectionTable, do
            collection: @tbo-items
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: false
                autoscale: true
                unique_name: 'assigned_stock_table'

            view_details: (record) ~>
                if not record.purchase_order.artkey then
                    market-data = do
                        bottle_artkey: record.bottle_artkey
                        current_supplier_artkey: record.supplier.artkey
                        customs_status: record.case_customs_status
                    m CaseInfoPanel, market-data

            columns:
                do
                    width: 4
                    name: 'Sales Order'
                    field: 'sales_order.artkey'
                    transform: (artkey) ->
                        link "/sales-orders/manage/#{artkey}", 'S' + artkey
                    sort: true
                do
                    width: 4
                    name: 'Order Date'
                    field: 'sales_order.date'
                    transform: format-date
                    sort: true
                do
                    width: 4
                    name: 'Purchase Date'
                    field: 'purchase_order.date'
                    transform: format-date
                    sort: true
                do
                    width: 4
                    name: 'ETA'
                    field: 'purchase_order.expected_delivery_date'
                    transform: format-date
                    sort: true
                do
                    width: 6
                    name: 'Delivery period'
                    sort: true
                    field: 'delivery_period'
                    classes: ['number']
                    transform: (delivery-period) ->
                        if delivery-period
                            delivery-period + ' ' + pluralize delivery-period, 'week', 'weeks'
                        else
                            '-'
                do
                    width: 10
                    name: 'Buyer'
                    field: 'sales_order.buyer.name'
                    sort: true
                    ellipsis: true
                    function: (record) -> [
                        m 'span.mr-05' account-icon record.sales_order.account
                        record.sales_order.buyer.name
                    ]
                do
                    width: 5
                    name: 'Status'
                    sort: true
                    field: 'status'
                do
                    width: 5
                    name: 'PO#'
                    field: 'purchase_order.artkey'
                    transform: maybe-map purchase-order-link
                do
                    width: 5
                    name: 'Sales manager'
                    field: 'sales_order.buyer.sales_manager.name'
                do
                    width: 1
                    header: ''
                    name: 'Discover link'
                    function: (record) ->
                        m 'a.discover-link.no-click' do
                            target: '_blank',
                            href: discover_link.from_properties record.product_name, record.bottle_volume,
                            record.bottle_alcohol_percentage
                        , icon 'search.no-click'
                do
                    width: 1
                    header: ''
                    name: 'Explore link'
                    function: (record) ->
                        m 'td' explore_link.for_product do
                            icon 'fa-compass.fa-lg.no-click'
                            record.case_artkey
                            record.supplier_artkey
                do
                    width: 12
                    name: 'Product'
                    sort: true
                    field: 'product_name'
                    ellipsis: true
                do
                    width: 5
                    name: 'Category'
                    sort: true
                    field: 'product_category'
                    ellipsis: true
                    transform: capitalize
                do
                    width: 3
                    name: 'Btl / cs'
                    sort: true
                    field: 'case_number_of_bottles'
                    default_visible: false
                    classes: ['number']
                do
                    width: 4
                    name: 'Size'
                    sort: true
                    field: 'bottle_volume'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                    classes: ['number']
                do
                    width: 4
                    name: 'Alc %'
                    sort: true
                    field: 'bottle_alcohol_percentage'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                    classes: ['number']
                do
                    width: 3
                    name: 'Ref'
                    sort: true
                    default_visible: false
                    field: 'bottle_refill_status'
                do
                    width: 10
                    name: 'Specs'
                    sort: false
                    function: (record) ->
                        specs = []
                        specs.push record.case_number_of_bottles
                        specs.push (+record.bottle_volume).toFixed(1)
                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                        specs.push record.bottle_refill_status
                        join ' / ' specs
                do
                    width: 4
                    name: 'GB'
                    sort: true
                    field: 'case_gift_box_type'
                    default_visible: false
                    ellipsis: true
                do
                    width: 4
                    name: 'Tax Label'
                    sort: true
                    field: 'case_tax_label'
                    default_visible: false
                    ellipsis: true
                do
                    width: 5
                    header: 'BBD'
                    name: 'Best before date'
                    sort: true
                    field: 'case_best_before_date'
                    default_visible: false
                    transform: maybe-map format-date
                do
                    width: 10
                    name: 'Features'
                    function: (record) ->
                        features = []
                        if record.case_gift_box_type
                            features.push record.case_gift_box_type
                        if record.case_tax_label
                            features.push record.case_tax_label
                        if record.case_best_before_date
                            features.push "BBD: #{format-date record.case_best_before_date}"
                        if record.case_damages
                            features.push record.case_damages.replace(', ', ' / ')
                        if record.case_general_tags
                            features.push record.case_general_tags.replace(', ', ' / ')
                        if record.case_pack_size
                            features.push record.case_pack_size
                        if record.case_packaging
                            features.push record.case_packaging
                        join ' / ' features
                do
                    width: 10
                    name: 'Purchase / cs'
                    sort: true
                    field: 'price_per_case'
                    classes: ['price']
                    function: (record) ->
                        m Amount, do
                            amount: +record.price_per_case
                            excise: +record.excise_per_case
                            currency: app.$s.currencies.default
                do
                    width: 10
                    name: 'Sales / cs'
                    sort: true
                    field: 'price_per_case'
                    classes: ['price']
                    function: (record) ->
                        m Amount, do
                            amount: +record.price_per_case
                            excise: +record.excise_per_case
                            currency: app.$s.currencies.default
                do
                    width: 2
                    name: 'Number of cases in purchase'
                    header: icon-with-popover do
                        icon-id: 'shopping-cart'
                        content: 'Number of cases in purchase'
                    sort: true
                    field: 'purchase_order_item.number_of_cases'
                    classes: ['number']
                do
                    width: 2
                    name: 'Number of cases in sales'
                    header: icon-with-popover do
                        icon-id: 'screenshot'
                        content: 'Number of cases in sales'
                    sort: true
                    field: 'number_of_cases'
                    classes: ['number']
                do
                    width: 3
                    header: 'Cus.'
                    name: 'Customs status'
                    sort: true
                    field: 'case_customs_status'


purchase-order-link = (artkey) ->
    m m.route.Link, do
        href: "/purchase-orders/manage/P#{artkey}"
    , "P#{artkey}"
