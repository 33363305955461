import m from 'mithril'
import {format_money_with_symbol} from '@bitstillery/common/ts_utils.ts'
import {classes} from '@bitstillery/common/lib/utils'
import {Spinner} from '@bitstillery/common/components'
import {api} from '@bitstillery/common/app'

import {StockValueResponseOutput} from '@/factserver_api/fact2server_api'
import {Percent} from '@/components/html_components'

export class StockValueWidget {

    stock_value_ranges: StockValueResponseOutput | null = null

    async oncreate() {
        const {response, result} = await api.get<StockValueResponseOutput>(
            'discover/dashboard/stock/value-in-date-ranges',
        )
        if (response.ok) {
            this.stock_value_ranges = result
            m.redraw()
        }
    }

    view(): m.Children {
        return (
            <div className={classes('c-widget-stock-value dashboard-widget', {
                loading: !this.stock_value_ranges,
            })}
            >
                <div className="widget-title">
                    Stock value
                </div>
                {!this.stock_value_ranges && <div className="widget-body">
                    <Spinner />
                </div>
                }
                {this.stock_value_ranges && <div className="widget-body">
                    <div className="item">
                        <div>Total stock value</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges.total_stock_value.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.total_stock_value.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.total_stock_value.in_percent / 100} number_of_fraction_digits={2} />
                        </div>
                    </div>
                    <div className="item">
                        <div>0 - 90 days</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges?.range_0_90_days.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.range_0_90_days.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.range_0_90_days.in_percent / 100}
                                number_of_fraction_digits={2}/>
                        </div>
                    </div>
                    <div className="item">
                        <div>91 - 180 days</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges.range_91_180_days.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.range_91_180_days.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.range_91_180_days.in_percent / 100}
                                number_of_fraction_digits={2}/>
                        </div>
                    </div>
                    <div className="item">
                        <div>181-365 days</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges.range_181_365_days.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.range_181_365_days.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.range_181_365_days.in_percent / 100}
                                number_of_fraction_digits={2}/>
                        </div>
                    </div>
                    <div className="item">
                        <div>1 - 2 year</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges.range_366_730_days.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.range_366_730_days.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.range_366_730_days.in_percent / 100}
                                number_of_fraction_digits={2}/>
                        </div>
                    </div>
                    <div className="item">
                        <div>&gt; 2 year</div>
                        <div>
                            {format_money_with_symbol(this.stock_value_ranges.range_more_than_730_days.value, 'EUR')}
                        </div>
                        <div>
                            {this.stock_value_ranges.range_more_than_730_days.number_of_cases} cases
                        </div>
                        <div>
                            <Percent value={+this.stock_value_ranges.range_more_than_730_days.in_percent / 100}
                                number_of_fraction_digits={2}/>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}
