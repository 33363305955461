import {Observable} from 'rxjs'

import {Api} from './api'

export interface SearchItem {
    latest_search: Date
    search_term: string
    supplier_name: string
    supplier_artkey: number
}

export interface MissedHitsForProductRequest {
    product_name: string
}

export class DianaApi {
    api: Api

    constructor() {
        this.api = new Api()
    }

    missed_hits_for_product(request: MissedHitsForProductRequest): Observable<Array<SearchItem>> {
        return this.api.post_request('diana.missed_hits_for_product', request)
    }
}
