m = require 'mithril'
AutoComplete = require './autocomplete.ls'
app = require('@/app')


class ViewModel
    (options) ->
        @collection_instance = options.collection
        @placeholder = options.placeholder || ''
        @autocomplete = options['autocomplete'] || false
        @input_id = options.input_id || ''
        @disabled = options.disabled || -> false

        if @autocomplete
            @autocomplete_ctrl = new AutoComplete.controller do
                input_container_id: '#search-input-container'
                on_submit_suggestion: (suggestion) ~>
                    @oninput suggestion, true
                    @collection_instance.submit_search!
                suggestions: options.suggestions

    init: ~>
        if not @collection_instance
            app.$m.common.generic_error_handler 'No collection provided for SearchInput'
            return

    oninput_event: (event) ~>
        @oninput(event.target.value)

    oninput: (search_term, is_suggestion=false) ~>
        @collection_instance.update_search_term search_term
        if @autocomplete
            @autocomplete_ctrl.oninput search_term, is_suggestion

    reset_search: ~>
        @collection_instance.reset_search!

    get_input_value: ~>
        @collection_instance.search_term!

    onclick: (e) ~>
        if @autocomplete
            @autocomplete_ctrl.onclick e

    onkeydown: (e) ~>
        if @autocomplete
            @autocomplete_ctrl.onkeydown e
        @collection_instance.submit_search_event e

export class controller
    (options) ->
        @vm = new ViewModel ...

        if options.autocomplete
            @set_suggestions = (suggestions) ~>
                @vm.autocomplete_ctrl.set_suggestions suggestions

    reset: ~>
        @vm.reset_search!

    submit_search: (search_term) ~>
        @vm.oninput search_term
        @vm.collection_instance.submit_search!

    onremove: ~>
        if @autocomplete then @vm.autocomplete_ctrl.onremove!


export view = (ctrl) ->
    m '.c-search-input#search-input-container',
        m "input##{ctrl.vm.input_id}" do
            'autofocus': true
            autocomplete: "off"
            value: ctrl.vm.get_input_value!
            type: 'text'
            id: 'search-input'
            disabled: ctrl.vm.disabled!
            placeholder: ctrl.vm.placeholder
            onclick: ctrl.vm.onclick
            onkeydown: ctrl.vm.onkeydown
            oninput: ctrl.vm.oninput_event
        if ctrl.vm.autocomplete
            AutoComplete.view ctrl.vm.autocomplete_ctrl
        m 'button.btn.btn-default' {
            tabindex: -1,
            onclick: ctrl.reset,
            type: 'button',
            disabled: ctrl.vm.disabled!
        } m 'span.glyphicon.glyphicon-remove-sign'
