import {events} from '@bitstillery/common/app'

import _models from './_accounts.ls'

import {$m} from '@/app'

export const {
    Account,
    AccountsDataModel,
} = _models

export function bind() {
    events.on('identity.login', async() => {
        $m.accounts = new AccountsDataModel()
    })
}
