import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {toggleWithLabel} from 'components/toggle.ls'
import {BarMain, Button, Dropdown, Icon} from '@bitstillery/common/components'
import {onSet} from 'utils.ls'
import {classes} from '@bitstillery/common/lib/utils'
import {set_account_styles} from '@bitstillery/common/account/account'
import {$t, store} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {DropDownOption, DropDownWithSelect} from '@/components/html_components'
import {Search} from '@/components/search/search'

export class BarMainDiscover extends BarMain {
    data = proxy({
        profile: {
            toggle: false,
        },
    })

    include_excise = (function(property) {
        return onSet(property, function() {
            let include = sessionStorage.getItem('include_excise') === 'true'
            return sessionStorage.setItem('include_excise', !include)
        })
    }.call(this, window.prop(sessionStorage.getItem('include_excise') === 'true')))

    slot_actions() {
        return [
            <Dropdown
                className='dropdown-my-portal right'
                ref={this.data.profile}
                trigger='click'
            >
                <div className={classes('btn-dropdown')}>
                    <Icon
                        active={m.route.get().startsWith('/my-portal') || this.data.profile.toggle}
                        name={$m.data.exact_keys.is_authenticated() ? 'accountFinance' : 'profile'}
                        size="l"
                        tip={$t('my_portal.tip')}
                        className={classes({finance: $m.data.exact_keys.is_authenticated()}, `signed-${$s.exact.access_token ? 'in' : 'out'}`)}
                    />
                </div>

                <div className='dropdown-body'>
                    <header>{$t('my_discover.text')}</header>
                    <Button
                        icon="profile"
                        link="/my-discover/profile"
                        text={$t('my_discover.text')}
                        variant="menu"
                    />
                    {!$m.data.exact_keys.is_authenticated() &&
                    <Button
                        icon="exact"
                        onclick={() => {
                            m.route.set('/user/exact_auth/user')
                        }}
                        text={$t('profile.actions.exact_login')}
                        variant="menu"
                    />}
                    <Button
                        icon="logout"
                        onclick={() => {
                            if ($m.data.exact_keys.is_authenticated()) {
                                $m.data.exact_keys.logout()
                            }

                            this.data.profile.toggle = false
                            $m.identity.logout()
                        }}
                        text={$t('my_portal.logout')}
                        variant="menu"
                    />
                </div>
            </Dropdown>,
        ]
    }

    slot_search() {
        return [
            <DropDownWithSelect
                classNames="vendor-select"
                selected={$s.session.account.slug}
                onchange={(slug) => {
                    $s.session.account.slug = slug
                    set_account_styles(slug)
                    store.save()
                    const route = m.parsePathname(m.route.get())
                    m.route.set(route.path, {
                        ...route.params,
                        key: Date.now(),
                    })

                }}
            >
                <DropDownOption value={'msp'}>MSP</DropDownOption>
                <DropDownOption value={'etr'}>ETR</DropDownOption>
                <DropDownOption value={'msi'}>MSI</DropDownOption>
                <DropDownOption value={'a2bc'}>A2BC</DropDownOption>
            </DropDownWithSelect>,
            toggleWithLabel('', this.include_excise, {
                'data-size': 'small',
                off: 'Excise(NL)',
                on: 'Excise(NL)',
            }),
            <Search />,
        ]
    }
}
