import {Observable} from 'rxjs'

import {Api, FactserverRequestData} from './api'

export enum Reasons {
    CATEGORY_FAVOURITED = 'Category favourited',
    CATEGORY_SOLD_BEFORE = 'Category sold before',
    PRODUCT_FAVOURITED = 'Product favourited',
    PRODUCT_SOLD_BEFORE = 'Product sold before',
}

export interface ProductOfInterest {
    offer_item_artkeys: number[]
    products: string[]
    product_names: string
    moment_of_approval: string[]
    relation_names: string
    relation_artkeys: number[]
    reasons: Reasons[]
}

export interface CreateOfferRequest extends FactserverRequestData {
    title: string
    remark: string
    offer_item_artkeys: number[]
    relation_artkeys: number[]
}

export class ProductOfInterestApi {
    api = new Api()

    create_offer(request: CreateOfferRequest): Observable<number> {
        return this.api.post_request('pricelist.create_offer_for_products', request)
    }
}
