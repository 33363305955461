import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import NotificationsPanel from '../notifications/notifications'

/**
 * The mine-dashboard page. Entry point of application discover. Displays several fixed widgets.
 *
 * Note: The graphs have a fixed height. This works nice on zoom level 0, but not so if zoomed in or out.
 *      The height is set on the canvas, so graph.js can draw and in the scss on the div.
 */
export default class MineDashboard extends MithrilTsxComponent<unknown> {
    static local_storage_settings_name = 'mine-dashboard-settings'
    /**
     * sales_manager.user_artkey:
     * - undefined means not set yet.
     * - null means show all sales-managers.
     * - otherwise sales_manager_artkey.
     */
    selected_user_artkey: number | null | undefined = undefined

    onremove(): void {
        this.save_to_local_storage()
    }

    save_to_local_storage(): void {
        localStorage.setItem(MineDashboard.local_storage_settings_name, JSON.stringify(this.local_storage_document))
    }

    view(): m.Children {
        return (
            <div className="c-my-discover-notifications view">
                <div className="columns">
                    <div className="column is-8">
                        <NotificationsPanel />
                    </div>
                </div>
            </div>
        )
    }
}
