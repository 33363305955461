{map} = require 'prelude-ls'
inputs = require '@/components/inputs.ls'
panel = require '@/components/panel.ls'
{SearchFilter} = require './models.ls'
{after-update} = require '../../utils.ls'
{TaxLabelConstants} = require '@/models/data'
{a}: utils = require 'utils.ls'
{EditableRelationList, RelationsSearch} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{AutocompleteInput} = require '@/components/collection/autocomplete_input'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
{Button, ButtonGroup} = require '@bitstillery/common/components'

page_sizes = [5, 10, 30, 50, 100]


export class SearchFields
    (vnode) ->
        @search_filters = vnode.attrs.search_filters
        @product_name = window.prop @search_filters![0].product_name!
        @product_management_api = new ProductManagementApi()

        @add_filter = (index) ~>
            @search_filters!splice (index + 1), 0, new SearchFilter

        @remove_filter = (index) ~>
            @search_filters!splice index, 1
            if @search_filters!length == 0
                @search_filters [new SearchFilter]

    on_selected_product: (selected_product, search_filter) ~>
        bottles = []
        if !selected_product
            search_filter.product_name ""
            search_filter.product!artkey ""
            search_filter.bottle!volume ""
            search_filter.bottle!alcohol_percentage ""
            search_filter.bottle!refill_status ""
        else
            @product_management_api.get_bottles_for_product(selected_product.artkey).subscribe({
                next: (bottles) ~>
                    search_filter.product!name selected_product.name
                    search_filter.product!artkey selected_product.artkey
                    # Initialize the drop downs with all possible (uniq and sorted) values
                    search_filter.volume_options [...new Set(bottles.map((bottle) -> +bottle.volume))].sort((a, b) -> a - b)
                    search_filter.alcohol_percentage_options [...new Set(bottles.map((bottle) -> +bottle.alcohol_percentage))].sort((a, b) -> a - b)
                    search_filter.refill_status_options [...new Set(bottles.map((bottle) -> bottle.refill_status))].sort()
                    m.redraw!
            })



    view: (vnode) ->
        update_number_of_bottles = (value) ~>
            if value.length
                vnode.attrs.case!number_of_bottles(+value)
            else
                vnode.attrs.case!number_of_bottles(null)

        m '.c-explore-search-fields', {key: vnode.attrs.unique_name},
            m '.explore-search-filters',

                vnode.attrs.search_filters!map (search_filter, index) ~>
                    m '.c-filter-group product-filter-group' {key: search_filter.artkey},
                        m '.filter-block fl-3',
                            m AutocompleteInput, {
                                label: 'Product',
                                placeholder: "Select a product..."
                                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_products(filter_text)
                                suggestion_as_label: (simple_product) -> simple_product.name
                                on_selected: (simple_product) ~> @on_selected_product(simple_product, search_filter)
                                default_text: vnode.attrs.search_filters![index]product_name!
                            }


                        m '.filter-block fl-2',
                            inputs.select search_filter.bottle!volume, search_filter.volume_options!map((key) -> [key, "#{key.toFixed(1)} cl"]), {
                                label: 'Volume',
                                empty_option: true
                                selected: search_filter.bottle!volume!
                            }

                            inputs.select search_filter.bottle!alcohol_percentage, search_filter.alcohol_percentage_options!map((key) -> [key, "#{key.toFixed(1)} %"]), {
                                empty_option: true,
                                label: 'Alcohol %',
                            }

                            inputs.select search_filter.bottle!refill_status, search_filter.refill_status_options!, {
                                empty_option: true,
                                label: 'Refillable',
                            }

                            inputs.number search_filter.case!number_of_bottles, {
                                label: 'Bottles / case',
                            }

                            inputs.gift_box_type search_filter.case!gift_box_type, {
                                include_no_gift_box_type: true,
                                label: 'Giftbox'
                            }

                            inputs.tax_label search_filter.case!tax_label, {
                                label: 'Tax Label',
                                no_option: TaxLabelConstants.NO_TAX_LABEL
                            }

                        m '.filter-block fl-3',
                            m 'relations',
                                m RelationsSearch, {
                                    label: 'Relation',
                                    selected_relations: vnode.attrs.search_filters![index]selected_relations!
                                    is_single_select: true
                                    selected_relation_name: vnode.attrs.search_filters![index]supplier!name!
                                    search_bar_controller: (controller) ~>
                                        return  # SBC goes unused
                                }
                                m EditableRelationList,
                                    selected_relations: vnode.attrs.search_filters![index]selected_relations!

                        m '.filter-block fl-1',
                            m '.field',
                                m 'label' ''
                                m '.actions-group',
                                    m ButtonGroup,
                                        m Button, {
                                            disabled: vnode.attrs.search_filters().length === 1,
                                            icon: 'minus',
                                            onclick: @remove_filter.bind(@, index)

                                        }
                                        m Button, {
                                            icon: 'plus',
                                            onclick: @add_filter.bind(@, index)
                                            type: 'info',
                                        },

            m 'form#userform.c-filter-group',
                m '.field' a do
                    m 'label' 'Date selection'
                    inputs.radio(vnode.attrs.date_selection, a do
                        {'value': 'all', 'description': 'All'},
                        {'value': 'year', 'description': 'Year'}
                        {'value': 'month', 'description': 'Month'},
                        {'value': 'range', 'description': 'Range'},
                    )
                if vnode.attrs.date_selection! == 'range'
                    a do
                        m '.field',
                            m 'label' 'From'
                            inputs.date vnode.attrs.from_date, {required: true}
                        m '.field',
                            m 'label' 'To'
                            inputs.date vnode.attrs.to_date, {required: true}
                else if vnode.attrs.date_selection! == 'month'
                    m '.field',
                        m 'label' 'Select month'
                        inputs.date_month vnode.attrs.month, {required: true}
                else if vnode.attrs.date_selection! == 'year'
                    m '.field',
                        m 'label' 'Select year'
                        inputs.number vnode.attrs.year, {required: true}

                m '.field' a do
                    m 'label' 'Account selection'
                    inputs.radio(vnode.attrs.account_selection, a do
                        {'value': '', 'description': 'All'},
                        {'value': 'msi', 'description': 'MSI'}
                        {'value': 'msp', 'description': 'MSP'}
                        {'value': 'a2bc', 'description': 'A2BC'}
                        {'value': 'etr', 'description': 'ETR'}
                    )

                m '.field',
                    m 'label' 'Limit'
                    m 'select', do
                        required: true
                        onchange: (ev) ~> vnode.attrs.limit +ev.target.value
                    , page_sizes.map (value) ~>
                        m 'option' do
                            value: value
                            selected: value == vnode.attrs.limit!
                        , value

            m '.btn-toolbar',
                m ButtonGroup,
                    m Button, {
                        disabled: !vnode.attrs.search_filters().some((i) ~> (i.product!name! || i.supplier().artkey())) or vnode.attrs.search_filters().length > 1
                        icon: 'filterRemove',
                        onclick: vnode.attrs.reset,
                        text: 'Reset Filters'
                    }
                    m Button, {
                        disabled: !vnode.attrs.search_filters().every((i) ~> (i.product!name! || i.selected_relations().length))
                        icon: 'search',
                        onclick: vnode.attrs.update,
                        text: 'Explore Products'
                        type: 'info'
                    }

