m = require 'mithril'

api = require 'api.ls'
app = require('@/app')

utils = require 'utils.ls'

{button-with-icon} = require '@/components/buttons.ls'
{icon} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{deref} = require '@bitstillery/common/utils.ls'

{LogType} = require './models.ls'


const log_type_choices = [
    [LogType.MESSAGE, 'Message'],
    [LogType.CONTACT_UPDATE, 'Contact update'],
]

export LogEntries = (initial_vnode) ->
    {log_entries} = initial_vnode.attrs

    return do
        view: (vnode) -> [
            if log_entries.length > 0
                m '.logbook', m 'ul.timeline',
                    log_entries.map (log_entry) ~>
                        m 'li',
                            if log_entry.log_type == LogType.CONTACT_UPDATE
                                icon 'fa-user timeline-icon'
                            else
                                icon 'fa-envelope timeline-icon'
                            m 'div.timeline-item',
                                m 'span.time' utils.format-date-time log_entry.created_on
                                m 'h3.timeline-header' log_entry.created_by.profile.name
                                m '.timeline-body' log_entry.message
                    m 'li',
                        icon 'far.fa-clock timeline-icon'
        ]


export AddLogEntry = (initial_vnode) ->
    {artkey, process, on_add_callback} = initial_vnode.attrs

    message = window.prop ''
    log_type = window.prop ''
    saving = window.prop false

    submit = ->
        saving true
        data = do
            artkey: deref(artkey)
            log_type: log_type!
            message: message!

        api.call-and-then process, data, do
            success: (resp) ->
                app.notifier.notify 'Log entry added successfully.', 'success'
                on_add_callback!
            final: ->
                saving false

    return do
        view: (vnode) -> [
            m 'form.form-horizontal' {onsubmit: utils.prevent-default submit},
                inputs.field 'Type', inputs.select log_type, log_type_choices
                inputs.field 'Message', inputs.textarea message
                inputs.field '', button-with-icon 'Add Log Entry', 'fa-pen', do
                    class: 'btn-primary'
                    disabled: saving!
        ]
