m = require 'mithril'
{join, sum, map, is-type, compact} = require 'prelude-ls'
{m-ext, join-classes} = require '../../utils.ls'


# Scale the width's of the given columns so that their total
# is equal to 100 (%).
#
# Each column is expected to have a numeric width attribute.
# This function makes a copy of the data with livescripts
# 'with' operator. Beware that if you convert this data to
# JSON, it may not work correctly ('with' uses prototypes
# for cloning / structural sharing).
#
# example:
#     >>> auto-scale-columns [{name: 'A', width: 1}, {name: 'B', width: 3}]
#     [{name: 'A', width: 25}, {name: 'B', width: 75}]
export auto-scale-columns = (columns) ->
    total-width = columns |> map (.width) |> sum
    factor = 100 / total-width
    columns |> map (col) ->
        col with width: col.width * factor


# Make a colgroup tag given the list of columns.
#
# Each column must have a numeric width attribute.
export colgroup = (columns) ->
    return m 'colgroup', [
        for column in columns
            if column.width then
                if typeof column.width === 'string' then
                    m 'col' {style: {width: column.width}}
                else
                    m 'col' {style: {width: column.width.toFixed(1) + '%'}}
            else
                m 'col'
    ]


# Make a table tag.
export table = m-ext 'table'


# Create a table header given the set of columns.
#
# view-column is a function that is responsible for filling each header cell.
# The first argument (cell in the example below) is a m-ext mithril tag.
# You are expected to call this function and return it's output.
#
# example:
#     view-column = (cell, column, index) -> cell column.title
#     header [{title: 'A', width: 1}], view-column
export header = (columns, view-column) ->
    m 'thead.thead-default' m 'tr' columns.map (col, index) ->
        view-column (m-ext 'th', {class: join-classes col.classes}), col, index
