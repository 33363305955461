m = require 'mithril'
api = require '../../api.ls'
inputs = require '@/components/inputs'
bottle_only_form = require './bottle_only_form.ls'
{filter, head, reject, sort-with, map, pairs-to-obj, sort} = require 'prelude-ls'
{countries} = require '@bitstillery/common/lib/countries'
{DropDownOption, CountriesSelect, CountriesMultiSelect} = require '@/components/html_components'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{a, format-date, with-default, prevent-default} = require 'utils.ls'
{proxy} = require '@bitstillery/common/lib/proxy'
{classes} = require '@bitstillery/common/lib/utils'
{ProductBottleType} = require '@/models/products'
{HiddenInformation, HiddenProductHelper} = require '@/purchase_orders/view_purchase_order/hidden_information'
app = require('@/app')


module.exports = class ProductEdit
    ->
        @product = window.prop null
        @checked_bottles = window.prop []
        @saving = window.prop false
        @error_messages = window.prop []
        @merged = window.prop false
        @query_product m.route.param 'artkey'
        @product_categories = window.prop []
        @brands = window.prop []
        @product_bottle_types = window.prop []
        @selected_category_artkey = window.prop ''
        @countries = window.prop countries
        const pathname = m.parsePathname(m.route.get())
        const params = Object.fromEntries(new URLSearchParams(pathname.params))
        @data = proxy({
            tab: if params.tab then params.tab else 'edit-product',
        })

        if m.route.get!endsWith 'merged'
            @merged true

    oncreate: ~>
        @get_categories!
        @get_brands!

    get_categories: ~>
        api.call-and-then 'product_categories.get_product_categories', {}, do
            success: (resp) ~>
                resp.result
                    |> filter (item) -> item.cbs_code != "UNKNOWN"
                    |> map ({artkey, name}) -> [artkey, (name.charAt(0)toUpperCase! + name.slice(1))]
                    |> @product_categories

    get_brands: ~>
        api.call-and-then 'brands.get_all', {}, do
            success: (resp) ~>
                resp.result
                    |> map ({artkey, name}) -> [artkey, name]
                    |> @brands

    check_bottle: (event) ~>
        artkey = +event.target.value
        if event.target.checked
            bottle = head @product!bottles!filter (.artkey! == artkey)
            @checked_bottles!push bottle
        else
            @checked_bottles reject (.artkey! == artkey), @checked_bottles!

    query_product: (product_artkey) ~>
        data = do
            product_artkey: product_artkey
        api.call-and-then 'product_management.get_product_and_bottles' data, do
            success: (result) ~>
                @product app.$m.products.create_product result.product
                @product!hide_from_pricelist_for_countries @product!hide_from_pricelist_for_countries!map((cc) -> cc.country_code)
                @product!hide_from_pricelist_for_suppliers @product!hide_from_pricelist_for_suppliers!map((sup) -> "#{sup.artkey}")

    setTab: (tabName) ~>
        this.data.tab = tabName
        m.route.set("/data/products/#{@product!artkey!}?tab=#{tabName}")
        m.redraw!

    save: ~>
        @saving true
        @merged false

        # maybe check whether things have changed?
        if not @check_can_save!
            @saving false
            return

        bottles = [{artkey: +bottle.artkey!, \
            volume: +bottle.volume!, \
            alcohol_percentage: +bottle.alcohol_percentage!, \
            refill_status: bottle.refill_status!} for bottle in @product!bottles! \
            when bottle.dirty!]

        product_data = do
            artkey: @product!artkey!
            name: @product!name!
            default_country_code: @product!default_country_code!
            product_bottle_type: @product!product_bottle_type!
            requires_import_statement: @product!requires_import_statement!
            category_artkey: @product!product_category!artkey!
            brand_artkey: @product!brand!artkey!

        data = do
            bottles: bottles
            product: product_data
        api.call 'product_management.update_product_and_bottles' data, @handle_save

    check_can_save: ~>
        @error_messages []
        if not @product!name!
            @error_messages!push 'Please enter a product name.'
        if (filter (.alcohol_percentage! < 0), @product!bottles!).length > 0
            @error_messages!push 'Please enter an alcohol percentage for each row.'
        if (filter (.volume! <= 0), @product!bottles!).length > 0
            @error_messages!push 'Please enter a volume for each row.'
        return @error_messages!length == 0

    reset_alerts: ~>
        @error_messages []
        m.redraw!

    handle_save: (result) ~>
        @saving false
        if result.success
            app.$m.data.update_data_products true
            app.notifier.notify 'Product saved successfully.', 'success'
            for bottle in @product!bottles!
                bottle.apply_update!
        else
            @error_messages!push result.message
        setTimeout @reset_alerts, 5000

    merge_bottles: ~>
        @saving true
        data = do
            bottle_artkeys: [bottle.artkey! for bottle in @checked_bottles!]
        api.call 'product_management.merge_bottles', data, @handle_merge_result

    handle_merge_result: (result) ~>
        if result.success
            try
                app.$m.data.update_data_products true
                app.$m.common.observable.broadcast 'bottles_updated'
                # Remove all bottles that were merged away.
                merged_bottle_artkeys = [b.artkey! for b in @checked_bottles!filter (.artkey! != result.bottle_artkey)]
                @product!bottles reject (.artkey! in merged_bottle_artkeys), @product!bottles!
                final_bottle = head @product!bottles!filter (.artkey! == result.bottle_artkey)
                # The browser keeps the item selected, so let's say it still is.
                @checked_bottles [final_bottle]
                @saving false
                app.notifier.notify 'Bottles merged successfully.', 'success'
            catch
                app.$m.common.generic_error_handler e.message
        else
            app.$m.common.generic_error_handler result.message
            @saving false
        setTimeout @reset_alerts, 5000

    view: ->
        m '.c-product-edit view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/data/products'},
                (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                m 'button.btn.btn-default' {disabled: @checked_bottles!length < 2, \
                    type: "button", onclick: @merge_bottles} \
                    (m 'span.glyphicon.glyphicon-resize-small'), ' Merge bottles'

            if @merged!
                m '.alert.alert-success.alert-dismissable' \
                    (m 'button.close' {'data-dismiss': 'alert', \
                        onclick: ~> @merged false} m 'span' '×'),
                    (m 'strong' 'Success!'), \
                    ' The products have been merged, this is the resulting product.'

            if @product! !== null then [
                m '.c-tabs',
                    m 'ul.nav.nav-tabs',
                        m 'li' {
                            class: classes('nav-link', {active: this.data.tab === 'edit-product'})
                            onclick: ~> this.setTab('edit-product')
                        },
                            m 'a' 'Edit product'
                        m 'li' {
                            class: classes('nav-link', {active: this.data.tab === 'hidden-information'})
                            onclick: ~> this.setTab('hidden-information')
                        },
                            m 'a' 'Hidden information'

                if this.data.tab === 'hidden-information'
                    m '.c-tab' {class: classes({active: this.data.tab === 'hidden-information'})},
                        m HiddenInformation,
                            hidden_information_helper: new HiddenProductHelper @product!artkey!


                if this.data.tab === 'edit-product'
                    m '.c-tab' {class: classes({active: this.data.tab === 'edit-product'})},
                        m '.fieldset-group',
                            m '.fieldset',
                                m '.field',
                                    m 'label' {for: 'name'} 'Name *'
                                    m 'input.form-control' {type: 'text', required: true,\
                                        name: 'name', value: @product!name!,\
                                        oninput: (ev) ~> @product!name ev.target.value }

                                m '.field',
                                    m 'label', {for: 'brand'}, 'Brand'
                                    inputs.select @product!brand!artkey, @brands!,
                                        required: false
                                        empty_option: true

                                m '.field',
                                    m 'label', {for: 'category'}, 'Category *'
                                    inputs.select @product!product_category!artkey, @product_categories!,
                                        required: true

                            m '.fieldset',
                                m '.field',
                                    m 'label', {for: 'product_bottle_type'}, 'Product bottle type *'
                                    inputs.select @product!product_bottle_type, ProductBottleType,
                                        required: true
                                m '.field',
                                    m 'label', {for: 'default_country_code'}, 'Default country'
                                    if @product!default_country_code! !== undefined
                                        m CountriesSelect,
                                            selected: @product!default_country_code!
                                            onchange: (value) ~> @product!default_country_code value
                                            empty_option:
                                                m DropDownOption,
                                                    value: ""
                                                    ""
                                inputs.checkbox @product!requires_import_statement, {label: 'Requires supplier import statement'}

                        if @product!bottles!length > 0
                            @product!bottles! |> sort-with (sort_bottles) |> map (bottle) ~>
                                m bottle_only_form, {on_check: @check_bottle, bottle: bottle, key: bottle.artkey!}
                        else
                            m 'p' 'No specs'

                        m 'button.btn.btn-success.btn-submit' \
                            {onclick: @save, disabled: @saving!} 'Update Product'
            ]

            if @error_messages!length > 0
                m '.error-messages.alert.alert-danger.animated.fadeInUp',
                    m 'ul',
                        @error_messages!map (message) ->
                            m 'li' message

sort_bottles = (x, y) ->
    | +(x.volume!) > +(y.volume!) => 1
    | +(x.volume!) < +(y.volume!) => -1
    # When we are here, the volumes are the same, so we should sort on alcohol_percentage
    | +(x.alcohol_percentage!) > +(y.alcohol_percentage!) => 1
    | +(x.alcohol_percentage!) < +(y.alcohol_percentage!) => -1
    # The alcohol_percentage is the same, now it depends on ref. If one of them is nonref,
    # it should come later.
    | (x.refill_status!toLowerCase! == 'nonref') => 1
    | otherwise => 0
