import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'

import {DropDownOption, DropDownWithSelect} from './html_components'

import {GetIncotermsResponse} from '@/factserver_api/fact2server_api'

interface IncotermsDropDownAttrs {
    selected_incoterm: string
    onchange: (incoterm_artkey: string) => unknown
    disabled?: boolean
    required?: boolean
    empty_option?: string | m.Children
    label?: string | m.Children
    help?: string | m.Children
    /**
     * The data source for the drop-down options. Fill eg with RelationDropDownData.relations()
     **/
    get_all_for_drop_down_response$: Observable<GetIncotermsResponse[]>
}

export class IncotermsDropDown extends MithrilTsxComponent<IncotermsDropDownAttrs> {
    incoterms: GetIncotermsResponse[] = []

    oncreate(vnode: m.Vnode<IncotermsDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe(
            (incoterms) => (this.incoterms = incoterms),
        )
    }

    view(vnode: m.Vnode<IncotermsDropDownAttrs>): m.Children {
        return <DropDownWithSelect
            empty_option={vnode.attrs.empty_option}
            onchange={vnode.attrs.onchange}
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label}
            selected={vnode.attrs.selected_incoterm}
            required={vnode.attrs.required}
        >
            {this.incoterms?.map((incoterm) => (
                <DropDownOption value={`${incoterm.code}`}>{incoterm.code} - {incoterm.description}</DropDownOption>
            ))}
        </DropDownWithSelect>
    }
}
