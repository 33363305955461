m = require 'mithril'

{a}: utils = require 'utils.ls'
api = require 'api.ls'
app = require('@/app')

{PurchaseOrderDetails} = require './components/purchase_order_details'

module.exports = class PurchaseOrderCancel
    ->
        @date_ordered = window.prop ''
        @purchase_order = window.prop {}
        @purchase_order_artkey = +m.route.param 'artkey'
        @load_purchase_order @purchase_order_artkey
        @saving = window.prop false

    load_purchase_order: (purchase_order_artkey) ->
        data = do
            purchase_order_artkey: purchase_order_artkey
        api.call 'purchase.core.get_purchase_orders_with_items', data, @set_purchase_order

    set_purchase_order: (resp) ~>
        if resp
            @purchase_order app.$m.purchase_orders.create_purchase_order resp.result[0]

    submit: (event) ~>
        event.preventDefault!
        @saving true
        data = do
            artkey: +@purchase_order_artkey
        api.call 'purchase.core.cancel_purchase_order', data, @handle_cancel_response

    handle_cancel_response: (resp) ~>
        if not resp.success
            app.notifier.notify resp.message, 'danger'
            @saving false
        else
            app.notifier.notify "This order was cancelled successfully.", 'success'
            m.route.set '/purchase-orders/manage/'

    view: -> m '',

        m '.row#button-bar',
            m '.col-lg-12.btn-toolbar' {role: "group"},
                m 'button.btn.btn-link' {type: "button", \
                                        onclick: ~> m.route.set '/purchase-orders/manage/' + @purchase_order!artkey!},\
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to order'

        m '.row' m '.col-xs-12' m 'h2' 'Cancelling purchase order'
        m 'p' 'Are you sure you want to cancel this order? This cannot be undone!'

        if @purchase_order!artkey?
            m PurchaseOrderDetails, do
                purchase_order: @purchase_order

        m 'form', a do
            if @saving!
                m '.alert.alert-danger.alert-dismissible', 'Cancelling the purchase order. This takes a while (*+/- 10 seconds per item).'
            else
                m 'button.btn.btn-danger' {type: 'submit', onclick: @submit} (m 'span.glyphicon.glyphicon-remove'), ' Cancel order'
