import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon} from '@bitstillery/common/components'
import m from 'mithril'

import {BottleMarket} from '@/components/market_info/bottle_market'
import {BottleStock} from '@/components/market_info/bottle_stock'
import {BottlePurchaseOrders} from '@/components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {OfferHistory} from '@/components/market_info/offer_history'

export class RowDetailPricelistItem extends MithrilTsxComponent<any> {
    view(vnode: m.Vnode<any>): m.Children {
        const row = vnode.attrs.row
        const info_panel_attrs = {
            bottle_artkey: row.bottle_artkey,
            case_customs_status: row.case_customs_status,
            ignore_ref: true,
        }
        return [
            <div className="columns">
                <div className="column is-12">
                    <BottleMarket {...info_panel_attrs} />
                </div>
            </div>,
            <div className="columns">
                <div className="column is-12">
                    <BottleStock {...info_panel_attrs} />
                </div>
            </div>,
            <div className="columns">
                <div className="column is-12">
                    <OfferHistory {...info_panel_attrs}/>
                </div>
            </div>,
            <div className="columns">
                <div className="column is-6">
                    <BottlePurchaseOrders {...info_panel_attrs} />
                </div>
                <div className="column is-6">
                    <BottleSalesOrders {...info_panel_attrs} />
                </div>
            </div>,
            <div className="columns">
                <div className="column is-12">
                    {vnode.attrs.row.line_content && vnode.attrs.row.spl_header_list && <SpliResolvedFrom
                        header_list={vnode.attrs.row.spl_header_list}
                        line_content={vnode.attrs.row.line_content}
                    />}
                </div>
            </div>,
        ]
    }
}

interface SpliResolvedFromAttrs {
    header_list: string[]
    line_content: string
}

class SpliResolvedFrom extends MithrilTsxComponent<SpliResolvedFromAttrs> {
    view(vn: m.Vnode<SpliResolvedFromAttrs>): m.Children {
        return (<div className="c-spli-resolved-from collection-widget column is-12">
            <div className="header">
                <div className="title">
                    <Icon name="search" type="info" />Resolved from
                </div>
            </div>
            <div className="content">
                <table className={'table table-condensed'}>
                    <thead className="thead-default">
                        <tr>
                            {vn.attrs.header_list.map((header) => {
                                return <th>{header}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {vn.attrs.line_content.split(';').map((value) => {
                                return <td>{value}</td>
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>)
    }
}
