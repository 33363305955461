{
    id, empty, join, all, head, filter,
    Obj, obj-to-pairs, pairs-to-obj,
    flip, apply, map, any, split-at,
    compact, is-type, split-at, drop
    tail, first, group-by, sum, maximum,
    sort-with,
} = require 'prelude-ls'


# Dereference a prop followed by a path of optional selectors.
# This function is useful if you don't know if the prop you have
# is a window.prop (function) or just a plain value.
#
# example:
#     >>> purchase-order = prop {reference: prop 'P123'}
#     >>> deref purchase-order, (.reference)
#     'P123'
#
#     # the following inputs give the same answer:
#     # purchase-order = window.prop {reference: 'P123'}
#     # purchase-order = {reference: window.prop 'P123'}
#     # purchase-order = {reference: 'P123'}
#
export deref = (prop, ...selectors) ->
    | not empty selectors =>
        [fn, ...rest] = selectors
        deref (fn deref prop), ...rest
    | is-function prop => prop!
    | otherwise => prop

export is-function = (value) ->
    typeof value === "function"

# Capitalize the first letter of all words in a string.
export titleize = (str) ->
    return str.replace(/\b[a-z]/g, (.toUpperCase!))
