import {Observable, of, Subject} from 'rxjs'
import {filter, map, mergeAll, toArray} from 'rxjs/operators'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmtpyRequest} from './api'

export interface ProductCategory {
    artkey: number
    cbs_code: string
    cbs_code_gte_200: string | null
    cbs_code_gte_1000: string | null
    default_product_bottle_type: string | null
    name: string
}

export type GetAllProductCategoriesResponse = ProductCategory[]

/**
 * Caches the product categories.
 */
export class ProductCategoriesDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: ProductCategoriesDropDownData | null = null
    /** Cached results, when available */
    private result: GetAllProductCategoriesResponse = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetAllProductCategoriesResponse> = new Subject<GetAllProductCategoriesResponse>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, GetAllProductCategoriesResponse>(
                'product_categories.get_product_categories',
                {},
            )
            .subscribe({
                next: (response: GetAllProductCategoriesResponse) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): ProductCategoriesDropDownData {
        if (ProductCategoriesDropDownData._instance === null) {
            ProductCategoriesDropDownData._instance = new ProductCategoriesDropDownData()
        }

        return ProductCategoriesDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static product_categories(): Observable<GetAllProductCategoriesResponse> {
        if (ProductCategoriesDropDownData.instance().drop_down_data$.isStopped) {
            return of(ProductCategoriesDropDownData.instance().result)
        }

        return ProductCategoriesDropDownData.instance().drop_down_data$
    }

    /** List of names. */
    public static names(): Observable<string[]> {
        return ProductCategoriesDropDownData.product_categories().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            map((it) => it.name),
            toArray(),
        )
    }
}
