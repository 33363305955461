m = require 'mithril'
{compact, join, map, min, Obj, or-list} = require 'prelude-ls'
require! 'utils.ls': {
    a, map-obj, format-date, format-percentage, match-term-in, maybe-map, on-set, assoc,
    dissoc, update, pluralize, download_binary_file_from_base64_str,
}
api = require 'api.ls'
{Amount} = require '@bitstillery/common/components'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{icon-with-popover} = require '@/components/icon.ls'
{icon-button, button-with-icon, text-button} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{rank} = require '@/components/rank.ls'

{CaseInfoPanel} = require '@/components/market_info/case_info_panel'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
app = require('@/app')

module.exports = class OfferSilentStock
    ->
        @silent_stock = new Collection do
            api_function_name: 'pricelist.get_offer_item_records'
            # filter_function: @is_match
            query_limit: 25
            sort_order: [
                {'name': 'case_number_of_bottles', 'direction': 'asc'}
                {'name': 'bottle_volume', 'direction': 'asc'}
                {'name': 'bottle_alcohol_percentage', 'direction': 'asc'}
                {'name': 'bottle_refill_status', 'direction': 'desc'}
            ]
            default_sort_by: 'product_name'
            default_sort_order: 'asc'
            filter_serverside: true
            additional_params: -> do
                only_show_type: ['purchase', 'stock']
                silent_stock: true
            dont-reuse: true
        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @silent_stock.update_search_term text
        @silent_stock.submit_search!

    oncreate: ->
        query = m.route.param 'q'
        if query then
            @search_input_ctrl.submit_search query.replace(/\+/g, ' ')
        else
            @silent_stock.init!

        q = m.route.param 'q'
        if q
            @search_input_ctrl.submit_search q.replace(/\+/g, ' ')

    save_offer_item: (record, callback) ~>
        data = do
            artkey: record.artkey
            maximum_quantity: record.maximum_quantity!
            minimum_quantity: record.minimum_quantity!
            sales_price_per_case: record.price_per_case!
            delivery_period: record.delivery_period!
            is_hidden: record.is_hidden
            silence: true

        api.call2 'pricelist.update_offer_item', data, callback

    color_class: (value) ~>
        if value > 8.8
            '.analysis-good-color'
        else if value < 0
            '.analysis-bad-color'
        else
            ''

    view: -> m '.c-pricelist-silent-stock view',

        m '.alert.alert-info',
            ' This screen shows all offer items that have:'
            m 'ul',
                m 'li' 'throughput < 10%'
                m 'li' 'no sales in the last month'
                m 'li' 'come into stock more than 2 months ago'
            'You can change the price here or ignore the item for a month by clicking on the thumbs up icon.'

        m '.c-filter-group',
            m SearchBar,
                placeholder: "Search products..."
                on_submit: @submit_search
                default_search_text: @silent_stock.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

        @silent_stock.show_counter!

        m CollectionTable, do
            collection: @silent_stock
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: false
                autoscale: true
                unique_name: 'silent_stock_list'

            row_model: (record) ->
                # Extend the record row model with memory of the old
                # maximum quantity, minimum_quantity, delivery period and
                # target price. Also convert them to props, so we can use
                # them for inputs.
                record with
                    edit: window.prop false

                    maximum_quantity: window.prop record.maximum_quantity
                    minimum_quantity: window.prop record.minimum_quantity
                    delivery_period: window.prop record.delivery_period
                    price_per_case: window.prop record.price_per_case

                    old_maximum_quantity: record.maximum_quantity
                    old_minimum_quantity: record.minimum_quantity
                    old_delivery_period: record.delivery_period
                    old_price_per_case: record.price_per_case

            view_details: (record) ->
                m CaseInfoPanel, do
                    case_artkey: record.case_artkey
                    case_customs_status: record.case_customs_status
                    item_best_before_date: record.item_best_before_date
                    item_tags_sorted_artkeys: record.item_tags_sorted_artkeys
                    bottle_artkey: record.bottle_artkey
                    current_supplier_artkey: if record.offer_item_type == 'tbo' then record.supplier_artkey else null

            columns:
                do
                    width: 6
                    name: 'Rank'
                    sort: true
                    field: 'market_score'
                    descending: true
                    function: extract-rank >> rank
                do
                    width: 4
                    name: 'Article code'
                    field: 'case_article_code'
                    sort: true
                do
                    width: 12
                    name: 'Product'
                    sort: true
                    field: 'product_name'
                    ellipsis: true
                do
                    width: 5
                    name: 'Category'
                    sort: true
                    field: 'product_category_name'
                    ellipsis: true
                    function: (record) ->
                        m 'span.text-capitalize' record.product_category_name
                do
                    width: 3
                    name: 'Btl / cs'
                    sort: true
                    field: 'case_number_of_bottles'
                    default_visible: false
                    classes: ['number']
                do
                    width: 4
                    name: 'Size'
                    sort: true
                    field: 'bottle_volume'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                    classes: ['number']
                do
                    width: 4
                    name: 'Alc %'
                    sort: true
                    field: 'bottle_alcohol_percentage'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                    classes: ['number']
                do
                    width: 3
                    name: 'Ref'
                    sort: true
                    default_visible: false
                    field: 'bottle_refill_status'
                do
                    width: 10
                    name: 'Specs'
                    sort: false
                    function: (record) ->
                        specs = []
                        specs.push record.case_number_of_bottles
                        specs.push (+record.bottle_volume).toFixed(1)
                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                        specs.push record.bottle_refill_status
                        join ' / ' specs
                do
                    width: 4
                    name: 'GB'
                    sort: true
                    field: 'case_gift_box_type'
                    default_visible: false
                    ellipsis: true
                do
                    width: 4
                    name: 'Tax Label'
                    sort: true
                    field: 'case_tax_label'
                    default_visible: false
                    ellipsis: true
                do
                    width: 5
                    header: 'BBD'
                    name: 'Best before date'
                    sort: true
                    field: 'item_best_before_date'
                    default_visible: false
                    transform: maybe-map format-date
                do
                    width: 10
                    name: 'Features'
                    function: (record) ->
                        features = []
                        if record.case_gift_box_type
                            features.push record.case_gift_box_type
                        if record.case_tax_label
                            features.push record.case_tax_label
                        if record.item_best_before_date
                            features.push "BBD: #{format-date record.item_best_before_date}"
                        if record.item_damages
                            features.push record.item_damages.replace(', ', ' / ')
                        if record.item_general_tags
                            features.push record.item_general_tags.replace(', ', ' / ')
                        if record.item_pack_size
                            features.push record.item_pack_size
                        if record.item_packaging
                            features.push record.item_packaging
                        join ' / ' features
                do
                    width: 3
                    header: 'Cus.'
                    name: 'Customs status'
                    sort: true
                    field: 'case_customs_status'
                do
                    width: 6
                    name: 'Stock value'
                    sort: true
                    field: 'euro_total_stock_value'
                    classes: ['price']
                    function: (record) ->
                        if record.offer_item_type != 'tbo'
                            m Amount, do
                                amount: +record.euro_total_stock_value
                                currency: app.$s.currencies.default
                        else
                            '-'
                do
                    width: 2
                    name: 'Number of cases in stock'
                    header: icon-with-popover do
                        icon-id: 'home'
                        content: 'Number of cases in stock'
                    sort: true
                    field: 'number_of_cases_in_stock'
                    classes: ['number']
                do
                    width: 2
                    name: 'Number of cases in purchase'
                    header: icon-with-popover do
                        icon-id: 'shopping-cart'
                        content: 'Number of cases in purchase'
                    sort: true
                    field: 'number_of_cases_in_purchase'
                    classes: ['number']
                do
                    width: 2
                    name: 'Number of cases in sales'
                    header: icon-with-popover do
                        icon-id: 'screenshot'
                        content: 'Number of cases in sales'
                    sort: true
                    field: 'number_of_cases_in_sales'
                    classes: ['number']
                do
                    width: 2
                    name: 'Number of cases available'
                    header: icon-with-popover do
                        icon-id: 'fa-shield-alt'
                        content: 'Number of cases available'
                    sort: true
                    field: 'number_of_cases_available'
                    classes: ['number']
                do
                    width: 3
                    name: 'Throughput'
                    header: icon-with-popover do
                        icon-id: 'flash'
                        content: 'Throughput'
                    field: 'item_throughput'
                    sort: true
                    classes: ['number']
                    transform: maybe-map format-percentage
                do
                    width: 7
                    header: 'Max Qty'
                    name: 'Maximum Quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.edit! then
                            inputs.number record.maximum_quantity, {min: 0}
                        else if record.maximum_quantity!? then
                            record.maximum_quantity!
                        else
                            '-'
                do
                    width: 7
                    header: 'MOQ'
                    name: 'Minimum Order Quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.edit! then
                            inputs.number record.minimum_quantity, {min: 0}
                        else if record.minimum_quantity!? then
                            record.minimum_quantity!
                        else
                            '-'
                do
                    width: 5
                    header: 'List Qty'
                    name: 'List Quantity'
                    field: 'list_quantity'
                    classes: ['number']
                    function: (record) ->
                        if record.maximum_quantity!?  # Use ? so 0 is also considered.
                            min record.maximum_quantity!, record.number_of_cases_available
                        else
                            record.number_of_cases_available
                do
                    width: 10
                    name: 'Avg purchase / cs'
                    sort: true
                    field: 'avg_purchase_price'
                    classes: ['price']
                    function: (record) ->
                        if record.avg_purchase_price then
                            m Amount, do
                                amount: record.avg_purchase_price
                                currency: app.$s.currencies.default
                        else
                            '-'
                do
                    width: 15
                    name: 'Sales / cs'
                    sort: true
                    field: 'price_per_case'
                    classes: ['price']
                    function: (record) ->
                        if record.edit! then
                            m MoneyInput, do
                                value: record.price_per_case!
                                on_value: (price) ~> record.price_per_case price
                                currency: app.$s.currencies.default
                        else
                            if +record.price_per_case!
                                m Amount, do
                                    amount: +record.price_per_case!
                                    currency: app.$s.currencies.default
                            else
                                '-'
                do
                    width: 6
                    name: 'Margin %'
                    sort: true
                    field: 'margin_percentage'
                    classes: ['price']
                    function: (record) ~>
                        if record.edit! then
                            margin_percentage = (record.price_per_case! - record.avg_purchase_price) / record.avg_purchase_price * 100
                        else
                            margin_percentage = +record.margin_percentage
                        m "span.#{@color_class margin_percentage}" "#{margin_percentage.toFixed(2)}%"
                do
                    width: 6
                    name: 'List price/btl'
                    sort: true
                    field: 'list_price_per_bottle'
                    classes: ['price']
                    default_visible: false
                    function: (record) ->
                        m Amount, do
                            amount: -> +record.price_per_case! / +record.case_number_of_bottles
                            currency: app.$s.currencies.default
                do
                    width: 4
                    name: 'Delivery period'
                    sort: true
                    field: 'delivery_period'
                    classes: ['number']
                    function: (record) ->
                            if record.edit! then
                                inputs.number record.delivery_period, {min: 1}
                            else if record.delivery_period!? then
                                record.delivery_period! + ' ' + pluralize record.delivery_period!, 'week', 'weeks'
                            else
                                '-'
                do
                    width: 3
                    name: 'Item type'
                    sort: true
                    field: 'offer_item_type'
                    transform: (type) ->
                        | type == 'tbo' => 'TBO'
                        | otherwise => type.capitalizeFirstLetter!
                do
                    width: 18
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            if record.edit! then
                                icon-button 'ban-circle', do
                                    class: 'btn-info no-click'
                                    onclick: ~>
                                        record.price_per_case record.old_price_per_case
                                        record.delivery_period record.old_delivery_period
                                        record.maximum_quantity record.old_maximum_quantity
                                        record.minimum_quantity record.old_minimum_quantity
                                        record.edit false
                            else
                                icon-button 'pencil', do
                                    class: 'btn-default no-click'
                                    onclick: ~> record.edit true

                            icon-button 'thumbs-up', do
                                class: 'btn-success no-click'
                                onclick: ~>
                                    @save_offer_item record, ~>
                                        if record.old_price_per_case != record.price_per_case!
                                            # Broadcast the change, so the offer item list and
                                            # stock lists are requeried.
                                            app.$m.common.observable.broadcast 'stock_updated'

                                        app.notifier.notify 'Silenced item for one month.', 'success'
                                        @silent_stock.soft_delete record.artkey


extract-rank = (record) -> do
    rank: record.market_rank
    total: record.market_total
