import {DateTime} from 'luxon'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {download_binary_file_from_base64_str} from 'utils.ls'

import {drop_down_menus_for_enum, DropDownWithSelect, empty_option, InputDate} from '@/components/html_components'
import {DefaultButton, SaveButton} from '@/components/buttons'
import {DownloadExcelResponse, Report, ReportArguments, ReportingApi} from '@/factserver_api/reporting_api'

enum RelativeDate {
    ALL = 'All',
    THIS_YEAR = 'This year',
    THIS_QUARTER = 'This quarter',
    THIS_MONTH = 'This month',
    LAST_YEAR = 'Last year',
    LAST_QUARTER = 'Last quarter',
    LAST_MONTH = 'Last month',
    CUSTOM = 'Custom...',
}

const hide_date_picker_for = [
    Report.STOCK_AGE_REPORT.valueOf(),
    Report.LIS_WMS_ARTICLES.valueOf(),
    Report.DUPLICATE_STOCK_ARTICLES.valueOf(),
    Report.STOCK_RECONCILIATION_REPORT.valueOf(),
]
const hide_get_report_for = [Report.LIS_WMS_ARTICLES.valueOf(), Report.DUPLICATE_STOCK_ARTICLES.valueOf()]

interface ReportDateRangePickerAttrs {
    report_arguments: ReportArguments
    on_get_report: (selected_report_and_dates: ReportArguments) => unknown
    on_update_report: (selected_report: string) => unknown
}

export class ReportDateRangePicker extends MithrilTsxComponent<ReportDateRangePickerAttrs> {
    selected_relative = RelativeDate.LAST_QUARTER.valueOf()

    selected_report = ''
    show_date_pickers = true
    is_downloading_excel = false
    show_get_report = true
    end_date: DateTime | null
    start_date: DateTime | null
    on_update_report: (selected_report: string) => unknown
    report_arguments: ReportArguments

    reporting_api = new ReportingApi()

    constructor(vnode: m.Vnode<ReportDateRangePickerAttrs>) {
        super()

        this.end_date = this.calculate_end_date(this.selected_relative)
        this.start_date = this.calculate_start_date(this.selected_relative)
        this.on_update_report = vnode.attrs.on_update_report
        this.report_arguments = vnode.attrs.report_arguments
    }

    calculate_end_date(relative_date: string): DateTime | null {
        const now = DateTime.now()
        if (relative_date === RelativeDate.CUSTOM) {
            return this.end_date
        } else if (relative_date === RelativeDate.ALL) {
            return null
        } else if (
            [
                RelativeDate.THIS_MONTH.valueOf(),
                RelativeDate.THIS_YEAR.valueOf(),
                RelativeDate.THIS_QUARTER.valueOf(),
            ].indexOf(relative_date) !== -1
        ) {
            return now.plus({days: 1})
        } else if (relative_date === RelativeDate.LAST_MONTH) {
            return now.set({day: 1})
        } else if (relative_date === RelativeDate.LAST_YEAR) {
            return now.set({day: 1, month: 1})
        } else if (relative_date === RelativeDate.LAST_QUARTER) {
            return now.set({day: 1, month: 1 + (now.quarter - 1) * 3})
        } else {
            throw new Error(`Not implemented: ${relative_date}`)
        }
    }

    calculate_start_date(relative_date: string): DateTime | null {
        const now = DateTime.now()
        if (relative_date === RelativeDate.CUSTOM) {
            return this.start_date
        } else if (relative_date === RelativeDate.ALL) {
            return null
        } else if (relative_date === RelativeDate.THIS_YEAR) {
            return now.set({day: 1, month: 1})
        } else if (relative_date === RelativeDate.THIS_MONTH) {
            return now.set({day: 1})
        } else if (relative_date === RelativeDate.THIS_QUARTER) {
            const first_month_of_this_quarter = 1 + (now.quarter - 1) * 3
            return now.set({day: 1, month: first_month_of_this_quarter})
        } else if (relative_date === RelativeDate.LAST_YEAR) {
            return now.set({day: 1, month: 1, year: now.year - 1})
        } else if (relative_date === RelativeDate.LAST_MONTH) {
            return now.set({day: 1}).minus({day: 1}).set({day: 1})
        } else if (relative_date === RelativeDate.LAST_QUARTER) {
            if (now.quarter === 1) {
                return now.set({day: 1, month: 10, year: now.year - 1})
            } else {
                return now.set({day: 1, month: 1 + (now.quarter - 2) * 3})
            }
        } else {
            throw new Error(`not implemented ${relative_date}`)
        }
    }

    set_dates_from_relative(relative_date: string): void {
        this.selected_relative = relative_date
        this.end_date = this.calculate_end_date(this.selected_relative)
        this.start_date = this.calculate_start_date(this.selected_relative)
    }

    set_selected_report(new_report: string): void {
        this.selected_report = new_report
        this.show_date_pickers = !hide_date_picker_for.includes(this.selected_report)
        this.show_get_report = !hide_get_report_for.includes(this.selected_report)
        this.on_update_report(new_report)

    }

    is_valid(): boolean {
        const report_selected = this.selected_report !== ''
        const dates_valid = this.start_date === null || this.end_date === null || this.start_date < this.end_date

        return report_selected && dates_valid
    }

    download_as_excel_file(): void {
        const file_name = `${this.selected_report} ${this.start_date?.toISODate() || ''} - ${
            this.end_date?.toISODate() || ''
        }.xls`
        this.is_downloading_excel = true
        this.reporting_api
            .download_excel_for({
                report: this.selected_report,
                start_date: this.start_date,
                end_date: this.end_date,
                additional_args: this.report_arguments.additional_args,
            })
            .subscribe({next: (result: DownloadExcelResponse) => {
                this.is_downloading_excel = false
                m.redraw()
                download_binary_file_from_base64_str(result.base64_encoded_file, file_name)
            },
            error: () => {
                this.is_downloading_excel = false
                m.redraw()
            },
            })
    }

    view(vnode: m.Vnode<ReportDateRangePickerAttrs>): m.Children {
        return [
            <div className="c-filter-group">
                <DropDownWithSelect
                    selected={this.selected_report}
                    empty_option={empty_option('Select a report ...')}
                    onchange={(selected: string) => this.set_selected_report(selected)}
                >
                    {drop_down_menus_for_enum(Report)}
                </DropDownWithSelect>

                {this.show_date_pickers && [
                    <DropDownWithSelect
                        selected={this.selected_relative}
                        onchange={(selected: string) => this.set_dates_from_relative(selected)}
                    >
                        {drop_down_menus_for_enum(RelativeDate)}
                    </DropDownWithSelect>,
                    <InputDate
                        value={this.start_date?.toISODate() || ''}
                        onchange={(new_value: DateTime) => (this.start_date = new_value)}
                    />,
                    <InputDate
                        value={this.end_date?.toISODate() || ''}
                        onchange={(new_value: DateTime) => (this.end_date = new_value)}
                    />,

                ]}
            </div>,
            <div className="c-filter-group">
                <span className={'btn-group'}>
                    {this.show_get_report && (
                        <SaveButton
                            icon_class={'glyphicon glyphicon-arrow-down'}
                            onclick={() =>
                                vnode.attrs.on_get_report({
                                    end_date: this.end_date,
                                    start_date: this.start_date,
                                    report: this.selected_report,
                                    additional_args: this.report_arguments.additional_args,
                                })
                            }
                            disabled={!this.is_valid()}
                            title={' Get report'}
                        />
                    )}

                    <DefaultButton
                        icon_class={'far fa-file-excel'}
                        disabled={!this.is_valid() || this.is_downloading_excel}
                        onclick={() => this.download_as_excel_file()}
                        title={' Download'}
                    />
                </span>
            </div>,
        ]
    }
}
