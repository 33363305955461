m = require 'mithril'

api = require 'api.ls'
{filter, map} = require 'prelude-ls'
{button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{EmailTable} = require 'email/components/email_table.ls'

export class SalesOrderEmailCorrespondence
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @artkey = window.prop @sales_order!artkey!
        @emails = new Collection do
            api_function_name: 'email.email.get'
            additional_params: ~> {'sales_order_artkey': @artkey!}
            query_limit: 25
            default_sort_by: 'created_on'
            default_sort_order: 'desc'
        @emails.query!

    oncreate: ~>
        @emails.requery!

    view: -> m EmailTable, do
        email_collection: @emails
