import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {notifier} from '@bitstillery/common/app'

import {$s} from '@/app'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {Percent} from '@/components/html_components'
import {GetProductsResponse} from '@/factserver_api/product_management_api'
import {GetMarginThresholdResponse, MarginsApi} from '@/factserver_api/margins_api'

export default class MarginThresholdList extends MithrilTsxComponent<unknown> {
    margins_fetcher = new PagedCollectionFetcher<GetMarginThresholdResponse>('margin_threshold.get_all', 'start_date')
    margin_api = new MarginsApi()

    delete_margin(artkey: number): void {
        this.margin_api.delete_margin_threshold(artkey).subscribe({
            next: () => {
                notifier.notify('Margin deleted.', 'success')
                this.margins_fetcher.reset_and_query()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-margins view">
                <div className="btn-toolbar">
                    <DefaultButton
                        icon_class={'glyphicon glyphicon-plus'}
                        onclick={() => m.route.set('/data/margin-thresholds/edit')}
                        disabled={!$s.identity.user.is_sales_target_manager}
                        title={' Create new margin'}
                    />
                </div>
                <div className={'alert alert-info'}>
                    <ul>
                        <li>A margin below "Needs approval" - Needs approval and accounting. </li>
                        <li>
                            A margin below <span style={'color: red'}>"End low margin range"</span>: Displayed as red in
                            Discover.
                        </li>
                        <li>
                            A margin between
                            <span style={'color: orange'}>
                                {' '}
                                "End low margin range" and "Start target margin range"{' '}
                            </span>
                            : Displayed as orange in Discover.
                        </li>
                        <li>
                            A margin higher than <span style={'color: green'}> "Start target margin range" </span>:
                            Displayed as green in Discover.
                        </li>
                    </ul>
                </div>

                <CollectionTable<GetProductsResponse, void>
                    collection_fetcher={this.margins_fetcher}
                    on_row_click={(row: GetProductsResponse) => {
                        if ($s.identity.user.is_sales_target_manager) {
                            m.route.set(`/data/margin-thresholds/edit?artkey=${row.artkey}`)
                        }
                    }}
                >
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => 'Start date'}
                        sort_name={'start_date'}
                        td_class_name={'col-sm-2'}
                        data_field={(row: GetMarginThresholdResponse) => format_iso_to_date(row.start_date)}
                    />
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => 'End date'}
                        sort_name={'end_date'}
                        td_class_name={'col-sm-2'}
                        data_field={(row: GetMarginThresholdResponse) => format_iso_to_date(row.end_date || '')}
                    />
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => 'Needs approval'}
                        td_class_name={'col-sm-1'}
                        data_field={(row: GetMarginThresholdResponse) => {
                            return row.end_needs_approval_range ? (
                                <Percent value={+row.end_needs_approval_range / 100} />
                            ) : (
                                '-'
                            )
                        }}
                    />
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => 'End low margin range'}
                        td_class_name={'col-sm-1'}
                        data_field={(row: GetMarginThresholdResponse) => {
                            return row.end_low_margin_range ? <Percent value={+row.end_low_margin_range / 100} /> : '-'
                        }}
                    />
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => 'Start target margin range'}
                        td_class_name={'col-sm-1'}
                        data_field={(row: GetMarginThresholdResponse) => {
                            return row.start_target_margin_range ? (
                                <Percent value={+row.start_target_margin_range / 100} />
                            ) : (
                                '-'
                            )
                        }}
                    />
                    <CollectionTableColumn<GetMarginThresholdResponse>
                        header_title={() => ''}
                        td_class_name={'col-sm-1'}
                        data_field={(row: GetMarginThresholdResponse) => {
                            return (
                                <DangerButton
                                    icon_class={'glyphicon glyphicon-trash'}
                                    disabled={!$s.identity.user.is_sales_target_manager}
                                    onclick={() => this.delete_margin(row.artkey)}
                                />
                            )
                        }}
                    />
                </CollectionTable>
            </div>
        )
    }
}
