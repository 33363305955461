m = require 'mithril'

api = require 'api.ls'
app = require('@/app')
{button-with-icon} = require '@/components/buttons.ls'
confirmation = require '@/components/confirmation.ls'

{Spinner} = require '@bitstillery/common/components'
{a, format-date-time, preventDefault} = require 'utils.ls'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{EmailBatchRecipients} = require 'email/details/email_batch_recipients.ls'
{EmailBatchSentEmails} = require 'email/details/email_batch_sent_emails.ls'
{EmailBatchStatus, EmailBatchType} = require 'email/models.ls'
{EmailProcessFlow} = require '../models'
{ProcessManageOffer} = require '@/offer/offers/processes'

module.exports = class EmailBatch

    SHOWING = do
        RECIPIENTS: "RECIPIENTS"
        SENT_MAIL: "SENT_MAIL"

    (vnode) ->
        @artkey = window.prop m.route.param 'artkey'
        @offer_artkey = m.route.param 'offer'
        @email_batch = window.prop null
        @is_offer = window.prop false

        @currently_showing = SHOWING.RECIPIENTS

    get_process_flow: ~>
        if @offer_artkey
            return EmailProcessFlow.OFFERS
        return EmailProcessFlow.MAILS

    oncreate: ~>
        @fetch_email_batch!

    fetch_email_batch: ~>
        api.call-and-then 'email.batch.get' {offer_email_batch_artkey: @artkey!}, do
            success: (resp) ~>
                @email_batch resp.result
                if @email_batch!offer_artkey
                    @is_offer true
                @number_of_recipients = resp.number_of_recipients
                @number_of_emails = resp.number_of_emails

    view: ~>
        if @email_batch! == null then
            return m Spinner

        m '.c-communication-batches view process',

            if @get_process_flow! == EmailProcessFlow.OFFERS
                m ProcessManageOffer, {
                    active: 'email_send',
                    context: {
                        offer_artkey: @email_batch!.offer_artkey
                        email_batch_artkey: @email_batch!.artkey
                    }
                }

            m '.step-content',
                m '.btn-toolbar',
                    if @get_process_flow! == EmailProcessFlow.MAILS
                        m 'button.btn.btn-default', {type: 'button', onclick: ~> m.route.set '/crm/email'},
                            (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

                    if @email_batch! and @email_batch!status in [EmailBatchStatus.UNDER_CONSTRUCTION, EmailBatchStatus.WAITING_FOR_NEXT_RUN]
                        [
                            if @get_process_flow! == EmailProcessFlow.MAILS
                                [
                                    button-with-icon 'Edit email', 'edit', do
                                        class: 'btn-default'
                                        onclick: ~>
                                            if @is_offer!
                                                m.route.set "/offer/offers/#{@email_batch!offer_artkey}/email/#{@artkey!}/edit"
                                            else
                                                m.route.set "/crm/email/#{@artkey!}/edit"
                                    button-with-icon 'Select recipients', 'user', do
                                        class: 'btn-default'
                                        onclick: ~>
                                            if @is_offer!
                                                m.route.set "/offer/offers/#{@email_batch!offer_artkey}/email/#{@artkey!}/recipient-selection"
                                            else
                                                m.route.set "/crm/email/#{@artkey!}/recipient-selection"

                                    button-with-icon 'Delete batch', 'remove', do
                                        class: 'btn-danger pull-right'
                                        onclick: confirmation.show.bind @, do
                                            title: 'Confirm email batch delete'
                                            message: 'Are you sure you want to delete this email batch?'
                                            unique_name: 'delete_email_batch'
                                            onconfirm: ~>
                                                api.call-and-then 'pricelistbatch.delete' {offer_email_batch_artkey: @artkey!}, do
                                                    success: (resp) ~>
                                                        app.notifier.notify 'Successfully deleted offer email batch.', 'success'
                                                        if @is_offer!
                                                            m.route.set "/offer/offers/#{@email_batch!offer_artkey}/email/create"
                                                        else
                                                            m.route.set '/crm/email'
                                ]
                            if not @email_batch!is_pending
                                button-with-icon 'Send email to all recipients', 'send', do
                                    class: 'btn-success'
                                    onclick: confirmation.show.bind @, do
                                        title: 'Confirm email dispatch'
                                        message: "Are you ready to email #{@number_of_recipients} recipients?"
                                        unique_name: 'dispatch'
                                        onconfirm: ~>
                                            api.call-and-then 'email.batch.confirm_for_dispatch' {email_batch_artkey: @email_batch!artkey}, do
                                                success: (resp) ~>
                                                    app.notifier.notify 'Successfully dispatched offer email batch!', 'success'
                                                    if @is_offer!
                                                        m.route.set "/offer/offers/#{@email_batch!offer_artkey}/email/#{@artkey!}/email-send?key=#{Date.now()}"
                                                    else
                                                        m.route.set '/crm/email'

                        ]


                m '.field',
                    if @email_batch!use_sent_from_user
                        m '.row',
                            m 'label.control-label .col-sm-2', 'From email address'
                            m '.col-sm-10', @email_batch!use_sent_from_user.profile.emailaddress
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Subject'
                        m '.col-sm-10', @email_batch!subject
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Created on'
                        m '.col-sm-10', format-date-time @email_batch!created_on
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Created by'
                        m '.col-sm-10', @email_batch!created_by.profile.name
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Status'
                        m '.col-sm-10', EmailBatchStatus.choices[@email_batch!status]
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Type'
                        m '.col-sm-10', EmailBatchType.choices[@email_batch!batch_type]
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Related offer'
                        m '.col-sm-10.', if @is_offer! then 'Yes' else 'No'
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Scheduled to be sent?'
                        m '.col-sm-10',
                            if @email_batch!is_pending then
                                'Yes'
                            else if @email_batch!status == EmailBatchStatus.UNDER_CONSTRUCTION
                                "No, click 'Send email to all recipients' to enable"
                            else
                                'Has been sent'
                    m '.row',
                        m 'label.control-label .col-sm-2', 'Scheduled for'
                        m '.col-sm-10', format-date-time @email_batch!next_run



                m 'ul.nav.nav-tabs.pl-2.mb-2 .row' [
                    m 'li',
                        role: 'presentation'
                        class: if @currently_showing == SHOWING.RECIPIENTS then 'active' else ''
                        m m.route.Link,
                            href: m.route.get!
                            onclick: ~>
                                preventDefault @currently_showing = SHOWING.RECIPIENTS
                                @fetch_email_batch!
                        , "Recipients (#{@number_of_recipients})"
                    m 'li',
                        role: 'presentation'
                        class: if @currently_showing == SHOWING.SENT_MAIL then 'active' else ''
                        m m.route.Link,
                            href: m.route.get!
                            onclick: ~>
                                preventDefault @currently_showing = SHOWING.SENT_MAIL
                                @fetch_email_batch!
                        , "Sent email (#{@number_of_emails})"
                ]

                m '.collectionpanel',
                    if @currently_showing == SHOWING.RECIPIENTS
                        [
                            if @email_batch!
                                m EmailBatchRecipients, do
                                    email_batch: @email_batch
                        ]
                    else
                        [
                            if @email_batch!
                                m EmailBatchSentEmails, do
                                    email_batch: @email_batch
                        ]
