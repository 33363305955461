m = require 'mithril'
GenericEdit = require '../generic/generic_edit.ls'
{Field} = require '../generic/generic_field.ls'
{CommentTemplate} = require '@/models/data'

module.exports = class CommentTemplateEdit
    view: ->
        m GenericEdit, do
            readable_entity_name: 'Comment template'
            base_url: '/data/commenttemplates/'
            query_api_call: 'comment_templates.get_comment_template'
            update_or_create_api_call: 'comment_templates.update_or_create_comment_template'
            fields:
                new Field 'Title', 'title','input', 'text'
                new Field 'Body', 'body', 'textarea', 'text', {rows: 4, required: true}
            create_entity_func: (json) -> new CommentTemplate(json)
            broadcast_on_update: 'commenttemplates_updated'
