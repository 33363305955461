import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {DateTime} from 'luxon'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {titleize} from '@bitstillery/common/ts_utils'
import {notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {DefaultButton, SuccessButton} from '@/components/buttons'
import {
    DropDownOption,
    DropDownWithSelect,
    InputDate,
    RadioButtonOption,
} from '@/components/html_components'
import {
    ManualStatusLogRequest,
    ScheduledRange,
    ScheduleRangeRequest,
    UpdateUserRequest,
    UpdateWeekRequest,
    user_schedule_options,
    UserManagementApi,
} from '@/factserver_api/user_management_api'

export interface UserScheduleAttrs {
    api: UserManagementApi
    update_user_request: UpdateUserRequest
    is_loading_vacations: boolean
    check_disabled: (allow_if_self?: boolean) => boolean
}

export class UserSchedule extends MithrilTsxComponent<UserScheduleAttrs> {
    is_loading_vacations = false
    is_loading_trips = false
    is_loading_status = false

    exclude_from_standard = ['SICK', 'VACATION', 'ABROAD']
    scheduled_vacations: ScheduledRange[] = []
    scheduled_business_trips: ScheduledRange[] = []
    schedule_vacation_request: ScheduleRangeRequest = {
        user_artkey: 0,
        start_date: '',
    }

    schedule_business_trip_request: ScheduleRangeRequest = {
        user_artkey: 0,
        start_date: '',
    }

    current_status = ''

    delete_range_function(range: ScheduledRange, vnode: m.Vnode<UserScheduleAttrs>, api_call: string): void {
        if (!vnode.attrs.update_user_request.artkey) {
            return notifier.notify('Unable to delete the: user not found', 'danger')
        }

        // @ts-ignore - function needs to be called using the api, because just saving the function and calling it changes the scope
        vnode.attrs.api[api_call]({
            user_artkey: vnode.attrs.update_user_request.artkey,
            artkey: range.artkey,
        }).subscribe({
            next: () => {
                if (range.start_date === range.end_date) {
                    notifier.notify(`Status on ${format_iso_to_date(range.start_date)} successfully deleted`, 'success')
                } else {
                    notifier.notify(
                        `Status between the dates ${format_iso_to_date(range.start_date)} and ${format_iso_to_date(
                            range.end_date,
                        )} successfully deleted`,
                        'success',
                    )
                }
                this.fetch_all(vnode)
            },
        })
    }

    fetch_all(vnode: m.Vnode<UserScheduleAttrs>): void {
        this.fetch_vacations(vnode)
        this.fetch_business_trips(vnode)
        this.fetch_current_status(vnode)
    }

    fetch_business_trips(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify(
                'Unable to fetch vacations for the user, please reload if you want to see the scheduled vacations',
                'danger',
            )
            return
        }
        this.is_loading_trips = true

        vnode.attrs.api.get_scheduled_business_trips(vnode.attrs.update_user_request.artkey).subscribe({
            next: (response) => {
                this.scheduled_business_trips = response.ranges
                this.is_loading_trips = false
                m.redraw()
            },
        })
    }

    fetch_current_status(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify(
                'Unable to fetch current status for the user, please reload if you want to see the scheduled vacations',
                'danger',
            )
            return
        }
        this.is_loading_status = true

        vnode.attrs.api.get_user_status_today(vnode.attrs.update_user_request.artkey).subscribe((response) => {
            this.current_status = response.status
            this.is_loading_status = false
            m.redraw()
        })
    }

    fetch_vacations(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify(
                'Unable to fetch vacations for the user, please reload if you want to see the scheduled vacations',
                'danger',
            )
            return
        }
        this.is_loading_vacations = true

        vnode.attrs.api.get_scheduled_vacations(vnode.attrs.update_user_request.artkey).subscribe({
            next: (response) => {
                this.scheduled_vacations = response.ranges
                this.is_loading_vacations = false
                m.redraw()
            },
        })
    }

    gen_range_table(range_type: string, vnode: m.Vnode<UserScheduleAttrs>): m.Children {
        let ranges: ScheduledRange[]
        let api_call: string

        if (range_type === 'Vacation') {
            ranges = this.scheduled_vacations
            api_call = 'delete_scheduled_vacation'
        } else if (range_type === 'Business trip') {
            ranges = this.scheduled_business_trips
            api_call = 'delete_scheduled_business_trip'
        } else return <div></div>

        return (
            <table className={'table'}>
                <thead className={'thead-default'}>
                    <tr>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>{ranges.map((status_range) => this.range_to_table_row(status_range, vnode, api_call))}</tbody>
            </table>
        )
    }

    manual_log(vnode: m.Vnode<UserScheduleAttrs>, status: string): void {
        if (!status) {
            return
        }

        const request: ManualStatusLogRequest = {
            // @ts-ignore
            user_artkey: vnode.attrs.update_user_request.artkey,
            status: status,
        }
        vnode.attrs.api.manual_schedule_status_log(request).subscribe((response) => {
            this.current_status = response.status
            m.redraw()
        })
    }

    oncreate(vnode: m.Vnode<UserScheduleAttrs>): void {
        this.fetch_all(vnode)
    }

    range_to_table_row(status_range: ScheduledRange, vnode: m.Vnode<UserScheduleAttrs>, api_call: string): m.Children {
        return (
            <tr>
                <td id={'start_date'} style={'vertical-align: middle'}>
                    {format_iso_to_date(status_range.start_date)}
                </td>
                <td id={'end_date'} style={'vertical-align: middle'}>
                    {status_range.end_date === status_range.start_date
                        ? 'N/A'
                        : format_iso_to_date(status_range.end_date)}
                </td>
                {!vnode.attrs.check_disabled(true) && (
                    <td>
                        <DefaultButton
                            icon_class={'glyphicon glyphicon-trash'}
                            additional_class={'btn-danger'}
                            onclick={() => this.delete_range_function(status_range, vnode, api_call)}
                        />
                    </td>
                )}
            </tr>
        )
    }

    schedule_option_classname(value: string, day: string, update_user_request: UpdateUserRequest): string {
        // @ts-ignore
        const update_value = update_user_request[`schedule_${day}`]
        if (value === update_value) {
            return 'btn btn-default ' + value.toLowerCase()
        }
        return 'btn btn-default'
    }

    schedule_radio_view(day: string, exclude: string[] = [], update_user_request: UpdateUserRequest): m.Children {
        const view: Array<JSX.Element> = []
        for (const option in user_schedule_options) {
            if (exclude.includes(option)) {
                continue
            }

            view.push(
                <RadioButtonOption
                    value={option}
                    className={(() => {
                        const update_value = update_user_request[`schedule_${day}`]
                        if (option === update_value) {
                            return 'btn btn-info'
                        }
                        return 'btn btn-default'
                    })()}
                    title={user_schedule_options[option].title}
                    // @ts-ignore
                    onclick={(value: string) => (update_user_request[`schedule_${day}`] = value)}
                />,
            )
        }
        return (
            <div className="field">
                <label>{titleize(day)}</label>
                <div className={'btn-group'}>{view}</div>
            </div>
        )
    }

    schedule_user_business_trip(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify('Unable to schedule business trip: Something went wrong please reload this page', 'danger')
            return
        }

        if (!this.schedule_business_trip_request.start_date || this.schedule_business_trip_request.start_date === '') {
            notifier.notify('Unable to schedule business trip: Please select a start date', 'danger')
            return
        }

        this.schedule_business_trip_request.user_artkey = vnode.attrs.update_user_request.artkey
        vnode.attrs.api.schedule_business_trip(this.schedule_business_trip_request).subscribe({
            next: () => {
                notifier.notify('Business trip scheduled', 'success')
                this.schedule_business_trip_request.start_date = ''
                this.schedule_business_trip_request.end_date = null
                this.fetch_all(vnode)
            },
        })
    }

    schedule_user_vacation(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify('Unable to schedule vacation: Something went wrong please reload this page', 'danger')
            return
        }

        if (!this.schedule_vacation_request.start_date || this.schedule_vacation_request.start_date === '') {
            notifier.notify('Unable to schedule vacation: Please select a start date', 'danger')
            return
        }

        this.schedule_vacation_request.user_artkey = vnode.attrs.update_user_request.artkey
        vnode.attrs.api.schedule_vacation(this.schedule_vacation_request).subscribe({
            next: () => {
                notifier.notify('Vacation scheduled', 'success')
                this.schedule_vacation_request.start_date = ''
                this.schedule_vacation_request.end_date = null
                this.fetch_all(vnode)
            },
        })
    }

    update_standard_week_schedule(vnode: m.Vnode<UserScheduleAttrs>): void {
        if (!vnode.attrs.update_user_request.artkey) {
            notifier.notify('Unable to save schedule: Something went wrong please reload this page', 'danger')
            return
        }

        const request: UpdateWeekRequest = {
            user_artkey: vnode.attrs.update_user_request.artkey,
            schedule_monday: vnode.attrs.update_user_request.schedule_monday,
            schedule_tuesday: vnode.attrs.update_user_request.schedule_tuesday,
            schedule_wednesday: vnode.attrs.update_user_request.schedule_wednesday,
            schedule_thursday: vnode.attrs.update_user_request.schedule_thursday,
            schedule_friday: vnode.attrs.update_user_request.schedule_friday,
        }

        vnode.attrs.api.update_standard_week_schedule(request).subscribe({
            next: () => {
                notifier.notify('Week schedule updated', 'success')
            },
        })
    }

    view(vnode: m.Vnode<UserScheduleAttrs>): m.Children {
        if (this.is_loading_status) return <Spinner />
        return (
            <div className="c-user-schedule">
                <div className="fieldset-group">
                    <div className="fieldset">
                        <div className="fieldset-label">Week Schedule</div>
                        <DropDownWithSelect
                            label="Override current"
                            selected={this.current_status}
                            onchange={(value: string) => this.manual_log(vnode, value)}
                            empty_option={<DropDownOption value={''}>Select a status...</DropDownOption>}
                            disabled={this.current_status === 'VACATION' || this.current_status === 'ABROAD'}
                        >
                            <DropDownOption value={'OFF'}>Off</DropDownOption>
                            <DropDownOption value={'HOME'}>Home</DropDownOption>
                            <DropDownOption value={'OFFICE'}>Office</DropDownOption>
                        </DropDownWithSelect>

                        <div className="field-group vertical">
                            <label>Standard Schedule</label>
                            {this.schedule_radio_view('monday', this.exclude_from_standard, vnode.attrs.update_user_request)}
                            {this.schedule_radio_view('tuesday', this.exclude_from_standard, vnode.attrs.update_user_request)}
                            {this.schedule_radio_view('wednesday', this.exclude_from_standard, vnode.attrs.update_user_request)}
                            {this.schedule_radio_view('thursday', this.exclude_from_standard, vnode.attrs.update_user_request)}
                            {this.schedule_radio_view('friday', this.exclude_from_standard, vnode.attrs.update_user_request)}
                        </div>

                        <SuccessButton
                            title={'Save schedule'}
                            onclick={() => this.update_standard_week_schedule(vnode)}
                        />
                    </div>
                    <div className="fieldset">
                        <div className="fieldset-label">Vacation</div>

                        <InputDate
                            label="Start date"
                            value={this.schedule_vacation_request.start_date}
                            min={DateTime.now().startOf('quarter').toISODate()}
                            max={this.schedule_vacation_request.end_date}
                            onchange={(val: DateTime) =>
                                (this.schedule_vacation_request.start_date = val ? val.toISODate() : '')
                            }
                        />

                        <InputDate
                            label="End date"
                            value={this.schedule_vacation_request.end_date}
                            min={
                                this.schedule_vacation_request.start_date
                                    ? this.schedule_vacation_request.start_date
                                    : DateTime.now().startOf('quarter').toISODate()
                            }
                            onchange={(val: DateTime) =>
                                (this.schedule_vacation_request.end_date = val ? val.toISODate() : null)
                            }
                        />

                        <SuccessButton
                            title={'Schedule vacation'}
                            onclick={() => this.schedule_user_vacation(vnode)}
                        />
                        {!vnode.attrs.is_loading_vacations && this.scheduled_vacations.length > 0 && this.gen_range_table('Vacation', vnode)}
                    </div>
                    <div className="fieldset">
                        <div className="fieldset-label">Business Trips</div>

                        <InputDate
                            label="Start date"
                            value={this.schedule_business_trip_request.start_date || ''}
                            min={DateTime.now().startOf('quarter').toISODate()}
                            max={this.schedule_business_trip_request.end_date}
                            onchange={(val: DateTime) =>
                                (this.schedule_business_trip_request.start_date = val ? val.toISODate() : '')
                            }
                        />

                        <InputDate
                            label="End date"
                            value={this.schedule_business_trip_request.end_date || ''}
                            min={
                                this.schedule_business_trip_request.start_date
                                    ? this.schedule_business_trip_request.start_date
                                    : DateTime.now().startOf('quarter').toISODate()
                            }
                            onchange={(val: DateTime) =>
                                (this.schedule_business_trip_request.end_date = val ? val.toISODate() : null)
                            }
                        />

                        <SuccessButton
                            title="Schedule business trip"
                            onclick={() => this.schedule_user_business_trip(vnode)}
                        />

                        {!vnode.attrs.is_loading_vacations && this.scheduled_business_trips.length > 0 && this.gen_range_table('Business trip', vnode)}
                    </div>

                </div>

            </div>
        )
    }
}
