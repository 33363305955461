import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {format_money_with_symbol, format_percentage} from '@bitstillery/common/ts_utils'
import {classes} from '@bitstillery/common/lib/utils'

import {$s} from '@/app'
import {PieChart} from '@/components/adminlte/pie_chart'
import {FigureWidget} from '@/components/box'
import {DashboardApi, SalesFiguresResponse} from '@/factserver_api/dashboard_api'

interface ThisMonthSalesStatusWidgetAttrs {
    user_artkey: number | null | undefined
    is_collapsed: boolean
    on_collapsed: (is_collapsed: boolean) => unknown
}

/**
 * Display widgets regarding the sales of the sales-manager in this period (this month).
 */
export class ThisMonthSalesStatusWidget extends MithrilTsxComponent<ThisMonthSalesStatusWidgetAttrs> {
    user_artkey: number | null | undefined
    is_loading = false

    start_of_month: DateTime
    period_until: DateTime
    empty_sales_figure = {
        count: 0,
        turnover_in_euro: 0,
        total_margin: 0,
        average_margin_percentage: 0,
        number_of_cases: 0,
    }

    sales_figures: SalesFiguresResponse = {
        confirmed: this.empty_sales_figure,
        invoiced: this.empty_sales_figure,
        latest_sales_orders: [],
        pending: this.empty_sales_figure,
        saved: this.empty_sales_figure,
        top_sales_orders: [],
        waiting_on_tbo: this.empty_sales_figure,
    }

    quantity_pie_chart: PieChart | null = null
    turnover_pie_chart: PieChart | null = null

    dashboard_api = new DashboardApi()

    constructor(vnode: m.Vnode<ThisMonthSalesStatusWidgetAttrs>) {
        super()

        this.user_artkey = vnode.attrs.user_artkey
        const now = DateTime.local()
        this.start_of_month = DateTime.local(now.year, now.month, 1, 0, 0, 0)
        this.period_until = DateTime.local().plus({day: 1})
    }

    oncreate(vnode: m.Vnode<ThisMonthSalesStatusWidgetAttrs>): void {
        this.fetch_sales_status(vnode.attrs.user_artkey)

        this.quantity_pie_chart = new PieChart('monthly-quantity-portal-sales-ratio')
        this.quantity_pie_chart.title(['New orders - amount'])
        this.quantity_pie_chart.legend_position('bottom')

        this.turnover_pie_chart = new PieChart('monthly-turonver-portal-sales-ratio', true)
        this.turnover_pie_chart.title(['New orders - value'])
        this.turnover_pie_chart.legend_position('bottom')
    }

    onupdate(vnode: m.Vnode<ThisMonthSalesStatusWidgetAttrs>): void {
        if (vnode.attrs.user_artkey !== undefined && vnode.attrs.user_artkey !== this.user_artkey) {
            this.fetch_sales_status(vnode.attrs.user_artkey)
        }
    }

    fetch_sales_status(user_artkey: number | null | undefined): void {
        if (this.is_loading || user_artkey === undefined) {
            return
        }
        this.is_loading = true

        this.dashboard_api
            .portal_sales_figures({
                period_from: this.start_of_month.toISODate(),
                period_till: this.period_until.toISODate(),
                sales_manager_artkey: user_artkey,
            })
            .subscribe({
                next: (response) => {
                    this.quantity_pie_chart?.labels(['Portal', 'Discover'])
                    this.turnover_pie_chart?.labels(['Portal', 'Discover'])
                    this.turnover_pie_chart?.data([
                        response.portal_sales.turnover_in_euro,
                        response.non_portal_sales.turnover_in_euro,
                    ])
                    this.quantity_pie_chart?.data([response.portal_sales.count, response.non_portal_sales.count])
                    this.user_artkey = user_artkey
                },
                complete: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })

        this.dashboard_api
            .sales_figures({
                period_from: this.start_of_month.toISODate(),
                period_till: this.period_until.toISODate(),
                sales_manager_artkey: user_artkey,
            })
            .subscribe({
                next: (response) => {
                    this.sales_figures = response
                    this.user_artkey = user_artkey
                },
                complete: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })
    }

    view() {
        return (
            <div className={classes('c-widget-individual-sales dashboard-widget', {
                loading: this.is_loading,
            })}>
                <div className="widget-title">
                    <span className="text">Sales this month</span>
                </div>
                <div className="widget-body">
                    <div className="figures-wrapper">
                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-backward'}
                            title="Pending - portal"
                            link={`/sales-orders/manage?sales_order_status=Saved or confirmed&user_artkey=${
                                this.user_artkey || ''
                            }`}
                            figure={format_money_with_symbol(
                                this.sales_figures.pending.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.pending.count.toLocaleString()} orders & ${this.sales_figures.pending.number_of_cases.toLocaleString()} cases`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-screenshot'}
                            title="Saved"
                            link={`/sales-orders/manage?sales_order_status=Saved&user_artkey=${this.user_artkey || ''}`}
                            figure={format_money_with_symbol(
                                this.sales_figures.saved.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.saved.count} orders & ${this.sales_figures.saved.number_of_cases.toLocaleString()} cases`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-ok-sign'}
                            title="Confirmed"
                            link={`/sales-orders/manage?sales_order_status=Confirmed&user_artkey=${
                                this.user_artkey || ''
                            }&order_by=is_confirmed_on`}
                            figure={format_money_with_symbol(
                                this.sales_figures.confirmed.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.confirmed.count} orders & ${this.sales_figures.confirmed.number_of_cases.toLocaleString()} cases`}
                        />
                    </div>

                    <div className="portal-charts">
                        <div className="chart-wrapper">
                            <canvas id='monthly-quantity-portal-sales-ratio' />
                        </div>
                        <div className="chart-wrapper">
                            <canvas id='monthly-turonver-portal-sales-ratio' />
                        </div>
                    </div>

                    <div className="figures-wrapper">
                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-file'}
                            title="Invoiced"
                            link={`/sales-orders/manage?sales_order_status=Invoiced&user_artkey=${
                                this.user_artkey || ''
                            }&order_by=is_invoiced_on`}
                            figure={format_money_with_symbol(
                                this.sales_figures.invoiced.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.invoiced.count} orders & ${this.sales_figures.invoiced.number_of_cases.toLocaleString()} cases`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'fa fa fa-chart-line'}
                            title="Invoiced margin"
                            link={`/sales-orders/manage?sales_order_status=Invoiced&user_artkey=${
                                this.user_artkey || ''
                            }`}
                            figure={format_money_with_symbol(
                                this.sales_figures.invoiced.total_margin,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`Average ${format_percentage(
                                this.sales_figures.invoiced.average_margin_percentage / 100,
                            )}`}
                        />

                        <FigureWidget
                            is_loading={this.is_loading}
                            color={'sales'}
                            icon={'glyphicon glyphicon-flag'}
                            title="Waiting on TBO"
                            link={'/purchase-orders/tbo?only_mine=true&show=Open'}
                            figure={format_money_with_symbol(
                                this.sales_figures.waiting_on_tbo.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.waiting_on_tbo.count} orders awaiting TBO`}
                        />
                    </div>

                </div>
            </div>
        )
    }
}
