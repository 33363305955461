m = require 'mithril'
api = require 'api.ls'
luxon = require 'luxon'
{map, compact} = require 'prelude-ls'
{excise_countries} = require '@bitstillery/common/excise'
{a, download_binary_file_from_base64_str, prevent-default, format-date-time, format-date, format-percentage, prepend} = require 'utils.ls'
{button-with-icon} = require '@/components/buttons.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Collection} = require '@/components/collection/collection.ls'
{link} = require '@/components/link.ls'
inputs = require '@/components/inputs'
{MoneyInput, PercentInput} = require '@/components/decimal_input'
{UserDropDown} = require '@/components/users'
panel = require '@/components/panel.ls'
{Tippy} = require '@bitstillery/common/components'
app = require('@/app')

module.exports = class PortalDemoUsers
    (vnode) ->
        @portal_demo_users = new Collection do
            api_function_name: 'portal.demo_user.get_all'
            query_limit: 25
            sort_order: [
                {'name': 'artkey', 'direction': 'desc'}
            ]
            default_sort_by: 'artkey'
            default_sort_order: 'desc'
            filter_serverside: true
            paged: false
            additional_params: -> {}

        @is_collapsed = window.prop true
        @sales_manager_artkey = window.prop ""
        if not @sales_manager_artkey?
            @sales_manager_artkey = app.$m.users.legacy_user.make_setting_prop \
                ['demo_portal_user', 'show-user'], app.$s.identity.artkey

        @currency_code = window.prop ''
        @include_ukds_in_price = window.prop false
        @country_code = window.prop ''
        @show_excise_in_portal = window.prop false
        @include_excise_in_price = window.prop false
        @number_of_accounts = window.prop ''
        @expires_on = window.prop ''
        @name = window.prop ''
        @portal_customs_visibility = window.prop 'both'
        @price_preference = window.prop 'case'
        @transport_costs_per_case = window.prop ''
        @minimum_order_amount = window.prop ''
        @price_markup_percentage = window.prop ''


    oncreate: ~>
        @portal_demo_users.init!
        @input_fields_to_default!

    deactivate_account: (artkey) ~>
        data = do
            artkey: artkey
        api.call-and-then 'portal.demo_user.delete_account', data, do
            success: (resp) ~>
                @portal_demo_users.requery!

    cleanup_old_accounts: ~>
        api.call-and-then 'portal.demo_user.clean_up_expired_accounts', {}, do
            success: (resp) ~>
                app.notifier.notify "A total of #{resp.number_of_deleted_accounts} demo accounts were deleted", 'info'
                @portal_demo_users.requery!

    input_fields_to_default: ~>
        @currency_code 'EUR'
        @include_ukds_in_price false
        @country_code 'NL'
        @show_excise_in_portal false
        @include_excise_in_price false
        @number_of_accounts 1
        @expires_on luxon.DateTime.local!.plus({days: 30})
        @name ''
        @portal_customs_visibility 'both'
        @price_preference 'case'
        @transport_costs_per_case ''
        @minimum_order_amount ''
        @price_markup_percentage ''

    fill_create_fields_with: (record) ~>
        @currency_code record.currency_code
        @include_ukds_in_price record.include_ukds_in_price
        @country_code record.country_code
        @show_excise_in_portal record.show_excise_in_portal
        @include_excise_in_price record.include_excise_in_price
        @number_of_accounts 1
        @expires_on ''
        @name record.name
        @portal_customs_visibility record.portal_customs_visibility
        @price_preference record.price_preference
        @transport_costs_per_case record.transport_costs_per_case
        @minimum_order_amount record.minimum_order_amount
        @price_markup_percentage record.price_markup_percentage
        @sales_manager_artkey record.sales_manager_artkey
        @expires_on record.expires_on
        @is_collapsed false

    save: ~>
        data = do
            currency_code: @currency_code!
            include_ukds_in_price: @include_ukds_in_price!
            include_excise_in_price: @include_excise_in_price!
            show_excise_in_portal: @show_excise_in_portal!
            number_of_accounts: @number_of_accounts!
            name: @name!
            portal_customs_visibility: @portal_customs_visibility!
            price_preference: @price_preference!
            country_code: @country_code!
            transport_costs_per_case: @transport_costs_per_case!
            minimum_order_amount: @minimum_order_amount!
            price_markup_percentage: @price_markup_percentage!
            expires_on: @expires_on!
            sales_manager_artkey: +@sales_manager_artkey!
        api.call-and-then 'portal.demo_user.create_accounts', data, do
            success: (resp) ~>
                @portal_demo_users.requery!
                @input_fields_to_default!
                download_binary_file_from_base64_str resp.result,
                    "invite-urls.xlsx"

    view: ->
        m '.c-portal-demo-users view',
            m '.btn-toolbar',
                button-with-icon 'Cleanup old accounts', 'remove', do
                    class: 'btn-danger pull-right'
                    onclick: ~> @cleanup_old_accounts!


            panel('Generate invite codes',
                m 'form.flex-form', {onsubmit: prevent-default @save},
                    m '.fieldset',
                        inputs.text @name, {
                            label: 'Name',
                            required: true
                        }

                        m UserDropDown, {
                            label: 'Sales manager',
                            selected_user_artkey: @sales_manager_artkey!
                            onchange: (user_artkey) ~>
                                @sales_manager_artkey if user_artkey then user_artkey else ""
                        }
                        m '.field-group',
                            inputs.number @number_of_accounts, {
                                label: 'Number of accounts',
                                required: true
                            }
                            inputs.date @expires_on, {
                                label: 'Expires on',
                                required: true
                            }

                        inputs.select @price_preference, ['Case', 'Bottle'], {
                            empty_option: false,
                            label: 'Price unit',
                            required: true
                        }

                        inputs.select @portal_customs_visibility, ['T1', 'T2', 'both'], {
                            empty_option: false,
                            label: 'Customs Status visibility',
                            required: true
                        }

                        inputs.text @country_code, {
                            label: 'Country code',
                            required: true
                            after_update: (ev) ~>
                                if ev.target.value != "NL"
                                    @include_excise_in_price false
                        }

                        inputs.checkbox @include_excise_in_price, {
                            disabled: @country_code! not in excise_countries
                            help: "#{excise_countries.join(', ')} only"
                            label: "Include excise in price",
                        }

                        inputs.select @currency_code, app.$s.currencies.all, {
                            empty_option: false,
                            label:  'Currency'
                        }

                        if @currency_code! == 'GBP' then
                            inputs.checkbox @include_ukds_in_price, {
                                disabled: @currency_code! != 'GBP',
                                label: 'Include UKDS in price'
                            }

                        inputs.checkbox @show_excise_in_portal, {
                            label: 'Show excise in portal'
                        }

                        m MoneyInput, {
                            label: 'Transports costs per case'
                            value: @transport_costs_per_case!
                            on_value: (price) ~> @transport_costs_per_case price
                            currency: @currency_code!
                        }

                        m MoneyInput, {
                            label: 'Minimum order quantity',
                            value: @minimum_order_amount!
                            on_value: (price) ~> @minimum_order_amount price
                            currency: @currency_code!
                        }

                        m PercentInput, {
                            label: 'Price markup percentage',
                            value: @price_markup_percentage!
                            on_value: (value) ~> @price_markup_percentage value
                        }


                    m 'button.btn.btn-success.btn-submit' 'Create Demo Users'
            , null, {
                collapsible: true,
                collapsed: @is_collapsed(),
                unique_name: 'i-am-unique'
            })

            m CollectionTable, do
                collection: @portal_demo_users
                options:
                    search_table_style: true
                    sticky_header: true
                    with_buttons: true
                    autoscale: true
                    unique_name: 'portal_demo_users'
                columns:
                    do
                        width: 1
                        name: 'artkey'
                        sort: true
                        field: 'artkey'
                    do
                        width: 2
                        name: 'Created on'
                        field: 'created_on'
                        sort: true
                        transform: format-date
                    do
                        width: 2
                        name: 'Name'
                        field: 'name'
                    do
                        width: 2
                        name: 'Sales manager'
                        field: 'sales_manager_profile_name'
                    do
                        width: 2
                        name: 'Invite code used'
                        function: (record) ->
                            if record.invite_code_used
                                m '.glyphicon.glyphicon-check' ''
                    do
                        width: 3
                        name: 'Summary'
                        function: (record) ~>
                            excise = if record.show_excise_in_portal then "Yes" else "No"
                            include_excise = if record.include_excise_in_price then "Yes" else "No"
                            show_ukds = if record.include_ukds_in_price then "Yes" else "No"

                            m '',
                                "Currency: #{record.currency_code}, "
                                "Country: #{record.country_code}, "
                                "Show excise: #{excise}, Include excise in price: #{include_excise}, "
                                "Include UKDS: #{show_ukds}, "
                                "Minimum order amount: #{if record.minimum_order_amount then (+record.minimum_order_amount).format-money! else 0.format-money!}, "
                                "Transport costs: #{if record.transport_costs_per_case then (+record.transport_costs_per_case).format-money! else 0.format-money!}, "
                                "Price markup: #{format-percentage record.price_markup_percentage}"
                    do
                        width: 2
                        name: 'Expires on'
                        field: 'expires_on'
                        sort: true
                        transform: format-date-time
                    do
                        width: 2
                        name: 'Actions'
                        sort: false
                        function: (record) ~>
                            [
                                button-with-icon '', 'fa-trash', do
                                    class: 'btn-default'
                                    onclick: (e) ~>
                                        e.stopPropagation!
                                        @deactivate_account(record.artkey)
                                        return false
                                if record.invite_url
                                    m Tippy, {content: 'Copy invite URL to clipboard'},
                                        button-with-icon '', 'fa-copy', {
                                            class: 'btn-default'
                                            onclick: (e) ~>
                                                e.stopPropagation!
                                                navigator.clipboard.writeText(record.invite_url).then ->
                                                    app.notifier.notify 'Invite URL copied to clipboard', 'info'
                                                , ->
                                                    app.notifier.notify 'Cannot copy to the clipboard.', 'danger'
                                                return false
                                        }

                                m Tippy, {content: 'Regenerate invite code'},
                                    button-with-icon '', 'glyphicon-refresh', {
                                        class: 'btn-default'
                                        onclick: (e) ~>
                                            e.stopPropagation!
                                            data = do
                                                artkey: record.artkey
                                            api.call-and-then 'portal.demo_user.generate_invite', data, do
                                                success: (resp) ~>
                                                    @portal_demo_users.requery!
                                                    # navigator.clipboard.writeText(resp.invite_url).then ->
                                                    #     app.notifier.notify 'Invite URL copied to clipboard', 'info'
                                                    # , ->
                                                    #     app.notifier.notify 'Cannot copy to the clipboard.', 'danger'
                                            return false
                                    }
                                m Tippy, {content: 'Register as in use'},
                                    button-with-icon '', 'glyphicon-check', {
                                        class: 'btn-default'
                                        onclick: (e) ~>
                                            e.stopPropagation!
                                            data = do
                                                artkey: record.artkey
                                            api.call-and-then 'portal.demo_user.register_invite_code_as_used', data, do
                                                success: (resp) ~> @portal_demo_users.requery!
                                            return false
                                    }
                                m Tippy, {content: 'Use these values in the create new'},
                                    button-with-icon '', 'glyphicon-pencil', {
                                        class: 'btn-default'
                                        onclick: (e) ~>
                                            e.stopPropagation!
                                            @fill_create_fields_with record
                                            return false
                                    }
                            ]
