m = require 'mithril'
api = require 'api.ls'
{button-with-icon} = require '@/components/buttons.ls'
confirmation = require '@/components/confirmation.ls'
inputs = require '@/components/inputs'
{format_date_html5} = require 'utils.ls'
{Offer, OfferType, Priority} = require '@/models/offers'
{ProcessManageOffer} = require './processes'
{FileDropArea} = require '@/components/file_drop_area'
{IncotermsDropDown} = require '@/components/incoterms'
{IncotermsDropDownData} = require '@/factserver_api/incoterms_api'
app = require('@/app')

DEFAULT_NEW_ARRIVALS_PERIOD = 7


class OfferTemplate
    @NORMAL = ''
    @NEW_ARRIVALS = 'new_arrivals'
    @NEW_ARRIVALS_DRANKGIGANT = 'new_arrivals_drankgigant'

    @all = do
        (@NORMAL): 'Normal'
        (@NEW_ARRIVALS): 'New arrivals'
        (@NEW_ARRIVALS_DRANKGIGANT): 'Drankgigant.nl BV'


# A helper class to define which strings to use for which offer template.
class OfferTemplateSettings
    @OFFER_REMARK = 'Created with template'

    @help_text = do
        (OfferTemplate.NORMAL): 'Select this for creating a custom offer as usual.'
        (OfferTemplate.NEW_ARRIVALS): 'Creates an offer with products in stock that are either new had a recent price change.'
        (OfferTemplate.NEW_ARRIVALS_DRANKGIGANT): 'Creates an offer for Drankgigant.nl BV, with products in stock that are either new or had a recent price change.'

    @redirect_to = do
        (OfferTemplate.NORMAL): 'relations'
        (OfferTemplate.NEW_ARRIVALS): 'relations'
        (OfferTemplate.NEW_ARRIVALS_DRANKGIGANT): 'email/create'

    @save_button_text = do
        (OfferTemplate.NORMAL): 'Create offer & select relations'
        (OfferTemplate.NEW_ARRIVALS): 'Create and proceed to select relations'
        (OfferTemplate.NEW_ARRIVALS_DRANKGIGANT): 'Create and directly proceed to compose email'

    @skip_select_products = do
        (OfferTemplate.NORMAL): false
        (OfferTemplate.NEW_ARRIVALS): true
        (OfferTemplate.NEW_ARRIVALS_DRANKGIGANT): true

    @title = do
        (OfferTemplate.NORMAL): ''
        (OfferTemplate.NEW_ARRIVALS): 'New arrivals'
        (OfferTemplate.NEW_ARRIVALS_DRANKGIGANT): 'New arrivals for Drankgigant.nl BV'


module.exports = class OfferDetails
    ->
        @create = window.prop true
        @saving = window.prop false

        @offer = window.prop new Offer
        @offer!incoterm 'EXW'
        @published = window.prop false
        @enable_portal_popup = window.prop false
        @portal_popup_image_data = null
        @portal_popup_image_filename = null
        @use_default_incoterm = window.prop true

        @offer_template = window.prop OfferTemplate.NORMAL
        @new_arrivals_period = window.prop DEFAULT_NEW_ARRIVALS_PERIOD

        @offer_artkey = m.route.param 'artkey'
        if @offer_artkey
            @query_offer @offer_artkey
            @create false

        @email_batch_artkey = m.route.param 'email_batch'

    offer_type_help_text: (offer_type) ~>
        if offer_type == OfferType.NORMAL
            'Select this for offers where not every product is a special offer, such as the weekly offer.'
        else if offer_type == OfferType.SPECIAL
            'Select this for (short) offers in which every product is a special offer.'

    priority_help_text: (priority) ~>
        if priority == Priority.UNIQUE
            'Select this for the most important offers. Unique offers always override bulk offers.'
        else if priority == Priority.BULK
            'Select this for less important offers. Bulk offers are always overridden by unique offers.'

    query_offer: (artkey) ~>
        api.call-and-then 'offer.get_offer' {artkey: artkey}, do
            success: (resp) ~>
                @offer new Offer resp.result
                if @offer!published_timestamp!
                    @published true
                if @offer!incoterm!
                    @use_default_incoterm false
                @enable_portal_popup @offer!portal_popup_artkey! != ''
            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    save: (e) ~>
        e.preventDefault!

        if @offer!published_timestamp! and not @published!
            # We are unpublishing the offer. Show a confirmation dialog to
            # double check if the user wants to carry on.
            confirmation.show do
                title: 'Unpublishing offer'
                message: 'You are unpublishing an already published offer. This can change prices for all relations that were selected for this offer. Are you sure you want to continue?'
                unique_name: 'unpublish_confirm'
                onconfirm: ~>
                    @save_offer!
        else
            @save_offer!

    save_offer: ~>
        # Basic form validation.
        today = new Date!
        start_date = new Date(@offer!start_date!)
        end_date = new Date(@offer!end_date!)
        if @create! and (end_date < today.setDate(today.getDate() - 1))
            app.notifier.notify 'Could not save the offer; the end date may not be in the past.', 'danger'
            return

        if start_date > end_date
            app.notifier.notify 'Could not save the offer; the start date may not be later than the end date.', 'danger'
            return

        if @use_default_incoterm!
            @offer!incoterm ''
            @offer!incoterm_location ''

        if @offer!incoterm! and not @offer!incoterm_location!
            app.notifier.notify 'Could not save the offer; when entering an incoterm, also enter an incoterm location.', 'danger'
            return

        if @offer!incoterm_location! and not @offer!incoterm!
            app.notifier.notify 'Could not save the offer; when entering an incoterm location, also enter an incoterm.', 'danger'
            return

        if not @saving!
            # Everything is OK so far; save the offer.
            @saving true
            data = do
                artkey: @offer!artkey!
                title: @offer!title!
                remark: @offer!remark!
                start_date: @offer!start_date!
                end_date: @offer!end_date!
                incoterm: @offer!incoterm!
                incoterm_location: @offer!incoterm_location!
                offer_type: @offer!offer_type!
                priority: @offer!priority!
                published: @published!
                enable_portal_popup: @enable_portal_popup!
                portal_popup_title: @offer!portal_popup!title!
                portal_popup_text: @offer!portal_popup!text!
                portal_popup_image: @portal_popup_image_data
                portal_popup_image_filename: @portal_popup_image_filename

            if @create!
                data.template = @offer_template!
                if @offer_template! in [OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]
                    data.new_arrivals_period = @new_arrivals_period!

            api.call-and-then 'offer.create_or_update_offer', data, do
                success: (resp) ~>
                    app.$m.common.observable.broadcast 'offer_updated'
                    if @create!
                        app.notifier.notify "Successfully created new offer \"#{resp.result.title}\".", 'success'
                    else
                        app.notifier.notify "Successfully updated offer \"#{resp.result.title}\".", 'success'

                    redirect_url = "/offer/offers/#{resp.result.artkey}/#{OfferTemplateSettings.redirect_to[@offer_template!]}"
                    if @email_batch_artkey
                        redirect_url += "/?email_batch=#{@email_batch_artkey}"
                    if OfferTemplateSettings.skip_select_products[@offer_template!]
                        if @email_batch_artkey
                            redirect_url += "&"
                        else
                            redirect_url += "/?"
                        redirect_url += "skip_select_products=true"
                    m.route.set redirect_url
                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
                    @saving false

    save_button_text: ~>
        if @create!
            OfferTemplateSettings.save_button_text[@offer_template!]
        else
            'Save and proceed to select relations'

    select_offer_template: (value) ~>
        if value?
            @offer_template value

            # Handle template selection.
            if value == OfferTemplate.NORMAL
                @offer new Offer
            else if @offer_template! in [OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]
                @offer!title OfferTemplateSettings.title[value]
                @offer!remark OfferTemplateSettings.OFFER_REMARK
                @offer!offer_type OfferType.NORMAL
                @set_dates!
        else
            @offer_template!

    set_dates: ~>
        today = new Date!
        end_of_period = new Date!setDate(today.getDate() + @new_arrivals_period!)
        @offer!start_date (format_date_html5 today)
        @offer!end_date (format_date_html5 end_of_period)

    set_new_arrivals_period: (value) ~>
        if value?
            @new_arrivals_period value
            @set_dates!
        else
            @new_arrivals_period!

    handle_filedrop: (file, contents) ~>
        @portal_popup_image_filename = file.name
        @portal_popup_image_data = contents
        m.redraw!

    view: -> m '.c-process-new-offer-step-1 view process',
        m ProcessManageOffer, {
            active: 'details',
            context: {
                email_batch_artkey: @email_batch_artkey,
                offer_title: @offer!title!,
                offer_artkey: @offer_artkey,
            }
        }

        m '.step-content',
            m 'form.flex-form' {onsubmit: @save},
                m '.fieldset',
                    if @create!
                        m '.field',
                            m 'label' 'Offer template'
                            inputs.radio @select_offer_template, \
                                [{value: value, description: description, title: OfferTemplateSettings.help_text[value]} for value, description of OfferTemplate.all]

                    if @create! and @offer_template! in [OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]
                        m '.field',
                            m 'label' 'New arrivals period'
                            inputs.number @set_new_arrivals_period, {required: true}
                            m '.help' 'The number for days for the stock age and the latest price change of this offer\'s products.'


                    inputs.text @offer!title, do
                        label: 'Title'
                        required: true
                        placeholder: 'Title'

                    inputs.text @offer!remark, {
                        label: 'Remark'
                        placeholder: 'Remark'
                    }

                    m '.field-group',
                        inputs.date @offer!start_date, {
                            label: 'Start date',
                            required: true
                        }

                        inputs.date @offer!end_date, {
                            label: 'End date',
                            required: true
                        }

                    inputs.checkbox @published, {
                        help: 'When an offer is published, the prices in this offer are updated in the Portal for the selected relations.',
                        label: 'Published'
                    }

                    m '.field',
                        m 'label' 'Incoterm to use'
                        inputs.radio @use_default_incoterm, [
                            do
                                value: true
                                description: 'Default'
                                title: 'Select this to use the default incoterm that is configured for each relation that you select in this offer.'
                            do
                                value: false
                                description: 'Override'
                                title: 'Select this to define a custom incoterm that applies to all relations that you select in this offer.'
                        ]

                    if not @use_default_incoterm!
                        m '.field',
                            m 'label' 'Incoterm'
                            m IncotermsDropDown,
                                selected_incoterm: @offer!incoterm!
                                get_all_for_drop_down_response$: IncotermsDropDownData.incoterms()
                                onchange: (incoterm) ~> @offer!incoterm incoterm

                    if not @use_default_incoterm!
                        m '.field',
                            m 'label' 'Incoterm location'
                            inputs.text @offer!incoterm_location, do
                                placeholder: 'Incoterm location'

                    m '.field',
                        m 'label' 'Offer type'
                        inputs.radio @offer!offer_type, \
                            [{value: value, description: description, title: @offer_type_help_text value} for value, description of OfferType.offer_type_options]

                    m '.field',
                        m 'label' 'Priority'
                        inputs.radio @offer!priority, \
                            [{value: +value, description: description, title: @priority_help_text +value} for value, description of Priority.all]

                    m '.field',
                        inputs.checkbox @enable_portal_popup, {
                            label: 'Enable portal popup'
                        }


                    if @enable_portal_popup! then [
                        m '.field',
                            m 'label' 'Popup title'
                            inputs.text @offer!portal_popup!title, do
                                required: true
                                placeholder: 'Popup title'

                        m '.field',
                            m 'label' 'Popup text'
                            inputs.textarea @offer!portal_popup!text, do
                                placeholder: 'Popup text'

                        m '.field',
                            m 'label' 'Popup image'
                            [
                                m FileDropArea,
                                    onupload: (file, contents) ~> @handle_filedrop(file,contents)

                                if @offer!portal_popup!image_s3_key! and not @portal_popup_image_filename
                                    m 'img', do
                                        src: "#{app.config.product_photo_host}/#{@offer!portal_popup!image_s3_key!}"
                                        width: 200
                                else if @offer!portal_popup!image_s3_key! and @portal_popup_image_filename
                                    [
                                        m 'label' 'Replace old image'
                                        m 'img', do
                                            src: "#{app.config.product_photo_host}/#{@offer!portal_popup!image_s3_key!}"
                                            width: 200
                                        m 'label.col-sm-2' 'With new image'
                                        m 'img', do
                                            src: @portal_popup_image_data
                                            width: 200
                                    ]
                                else if @portal_popup_image_filename
                                    [
                                        m 'label' 'New image'
                                        m 'img', do
                                            src: @portal_popup_image_data
                                            width: 200
                                    ]
                            ]
                    ]

                button-with-icon @save_button_text!, 'arrow-right', do
                    class: 'btn-success btn-submit'
                    disabled: @saving!
