import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {DefaultButton} from '@/components/buttons'
import {GetFastSalesOrderWithItemsResponse, SalesOrderStatus} from '@/factserver_api/sales_api'

export interface StatusButtonsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    update_status_sales_order: (new_status: SalesOrderStatus) => void
}

/**
 * Shows the status buttons for the sales order.
 * State transitions:
 *    Pending -> Finalized -> Confirmed -> Invoiced
 *                   Saved -> Confirmed -> Invoiced
 */
export class StatusButtons extends MithrilTsxComponent<StatusButtonsAttrs> {
    view(vnode: m.Vnode<StatusButtonsAttrs>): m.Children {
        const elements: JSX.Element[] = []

        if (vnode.attrs.sales_order.combined_status === SalesOrderStatus.PENDING) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-ok-sign'}
                onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.FINALIZED)}
                title={' Finalize'}
            />)
        }

        if (vnode.attrs.sales_order.combined_status === SalesOrderStatus.FINALIZED) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-backward'}
                onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.PENDING)}
                title={' To pending'}
            />)
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-ok-sign'}
                onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.CONFIRMED)}
                title={' Confirm'}
            />)
        }

        if (vnode.attrs.sales_order.combined_status === SalesOrderStatus.SAVED) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-ok-sign'}
                onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.CONFIRMED)}
                title={' Confirm'}
            />)
        }

        if (vnode.attrs.sales_order.combined_status === SalesOrderStatus.CONFIRMED) {
            if (!vnode.attrs.sales_order.pre_advice_reference) {
                elements.push(<DefaultButton
                    icon_class={'glyphicon glyphicon-backward'}
                    onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.FINALIZED)}
                    title={' To finalized'}
                />)

                elements.push(<DefaultButton
                    icon_class={'glyphicon glyphicon-backward'}
                    onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.SAVED)}
                    title={' To saved'}
                />)
            }

            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-book'}
                onclick={() => vnode.attrs.update_status_sales_order(SalesOrderStatus.INVOICED)}
                title={' Invoiced'}
            />)
        }

        return elements
    }
}
