import {MithrilTsxComponent} from 'mithril-tsx-component'
import {next_tick, proxy} from '@bitstillery/common/lib/proxy'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {Button, ButtonGroup, FieldSelect, FieldText, Icon, Spinner} from '@bitstillery/common/components'
import {countries} from '@bitstillery/common/lib/countries'
import {
    bottle_gtin,
    conditional,
    invalid_fields,
    invalid_fields_format,
    required,
    reset_validation,
    validation,
} from '@bitstillery/common/lib/validation'
import {api, events, logger, notifier} from '@bitstillery/common/app'
import {classes, object_to_query_string} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'

import {model} from '@/market/list_pricelist_items'
import {$m, $s} from '@/app'
import {empty_option} from '@/components/html_components'
import {GiftBoxTypeDropDown} from '@/components/case_inputs'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {SpliData} from '@/models/pricelists'
import {PalletLayoutResponse, SupplierPricelistItemRequest} from '@/factserver_api/fact2server_api'
import {MoneyInput} from '@/components/decimal_input.tsx'

interface UpsertSpliAttrs {
    collection: any
    /** Either create a spli from a sourceline or update an existing spli */
    source: 'spli' | 'sourceline'
}

export class UpsertSpli extends MithrilTsxComponent<UpsertSpliAttrs> {

    $v: any = {}
    data = (() => {
        return proxy({
            bottles: [],
            linked: {
                gtin: false,
                product: false,
                specs: false,
            },
            previous_number_of_bottles_per_case: 6,
            per_case: true,
        })
    })()

    watchers = [] as any

    async context_update(vnode) {
        const context = $s.context.data as SpliData
        // The incoterm (e.g. DAP) and location are one field and must be splitted
        // for editing and rejoined when saving.
        if (context && 'incoterm' in context) {
            const [incoterm, ...location] = context.incoterm.split('-').map((i) => i.trim())
            context.incoterm = incoterm
            context.incoterm_location = location.join(' ')
        }

        reset_validation(this.$v)
        Object.assign(this.$v, {
            alcohol_percentage: validation([context, 'alcohol_percentage'], conditional(() => context.bottle_artkey === undefined, required())), // specs
            bottle_gtin_code: validation([context, 'bottle_gtin_code'], bottle_gtin()),
            country_of_origin: validation([context, 'country_of_origin'], required()),
            customs_status: validation([context, 'customs_status'], required()),
            number_of_bottles_per_case: validation([context, 'number_of_bottles_per_case'], required()),
            price_per_bottle: validation([context, 'price_per_bottle'], conditional(() => !this.data.per_case, required())),
            price_per_case: validation([context, 'price_per_case'], conditional(() => this.data.per_case, required())),
            product_name: validation([context, 'product_name'], required()),
            product_category_artkey: validation([context, 'product_category_artkey'], required()),
            refill_status: validation([context, 'refill_status'], conditional(() => context.bottle_artkey === undefined, required())), // specs
            volume: validation([context, 'volume'], conditional(() => context.bottle_artkey === undefined, required())), // specs
        })

        if (context.number_of_bottles) {
            this.data.per_case = false
            context.price_per_case = null
            context.number_of_cases = null
        } else {
            this.data.per_case = true
            context.price_per_bottle = null
            context.number_of_bottles = null
        }

        // Default to 6 bottles per case if not set.
        if (!context.number_of_bottles_per_case) {
            context.number_of_bottles_per_case = 6
        }
        this.data.previous_number_of_bottles_per_case = context.number_of_bottles_per_case

        if (vnode.attrs.source === 'spli' && context.bottle_gtin_code) {
            // In SPLI mode, the Product/Specs/Gtin combination is locked
            // when the GTIN matches a bottle. In sourceline mode we don't
            // lock, because there is an (yet unknown) reason why the
            // product/specs are not known yet. This is something we'll
            // work on later.
            await this.context_from_gtin(vnode, context)
        } else {
            this.data.linked.gtin = false
            this.data.linked.product = false
            if (context.product_name.length >= 3) {
                $s.context.loading = true
                const {result:products} = await api.get(`discover/products?search_terms=${context.product_name}`) as any
                const matched_product = products.find((i) => {
                    if (context.product_artkey) {
                        return context.product_artkey === i.artkey
                    }
                    return i.name === context.product_name
                })

                if (matched_product) {
                    await this.context_from_product(vnode, matched_product)
                }
                $s.context.loading = false
            }
        }

        // These references to the variables change every time the context changes, so we need to rebind them in every context update.
        this.watchers.push(
            watch($s.context.data, 'bottle_artkey', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'number_of_bottles_per_case', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'gift_box_type', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'customs_status', () => {this.get_pallet_layout()}),
        )
    }

    async oninit(vnode) {
        this.watchers.push(watch($s.context, 'id', () => {
            this.context_update(vnode)
        }))

        await this.context_update(vnode)
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async context_from_gtin(vnode: m.Vnode<UpsertSpliAttrs>, context:SpliData) {
        $s.context.loading = true

        const {result: bottle, status_code} = await api.get(`discover/bottle-gtin/${context.bottle_gtin_code}/bottle`) as any
        if (status_code === 404) {
            notifier.notify('Sorry, we don\'t know about this bottle GTIN yet', 'warning')
            $s.context.loading = false
            return
        }

        this.data.bottles.splice(0, this.data.bottles.length, bottle)
        context.bottle_artkey = bottle.artkey
        context.product_name = bottle.product_name
        logger.debug(`[market] update context from bottle gtin "${context.bottle_gtin_code}"`)

        // Mix with existing context, because we don't have all fields here.
        await this.context_from_product(vnode, {
            artkey: bottle.product_artkey,
            country_of_origin: context.country_of_origin,
            default_country_code: bottle.default_country_code,
            bottles: this.data.bottles,
            name: bottle.product_name,
            product_category: {
                artkey: bottle.product_category_artkey,
                name: bottle.product_category_name,
            },
        })

        Object.assign(this.data.linked, {
            gtin: true,
            product: true,
            specs: this.data.bottles.length > 0,
        })
        $s.context.loading = false
    }

    async context_from_product(_vnode: any, suggestion: any) {
        logger.debug(`[market] update spli context for product "${suggestion.name}"`)
        const context = $s.context.data
        const product = {
            product_artkey: suggestion.artkey,
            product_category_artkey: suggestion.product_category.artkey,
            product_category: suggestion.product_category.name,
            product_name: suggestion.name,
        }
        if (!suggestion.country_of_origin) {
            product.country_of_origin = suggestion.default_country_code
        }
        Object.assign(context, product)

        if (suggestion.bottles) {
            this.data.bottles = suggestion.bottles
            this.data.bottles.sort((a, b) => { return +b.volume - +a.volume })
        }

        this.data.linked.product = true
    }

    async get_pallet_layout() {
        const spli = $s.context.data as SpliData
        if (!spli.bottle_artkey || !spli.number_of_bottles_per_case) return

        const request = {
            bottle_artkey: spli.bottle_artkey,
            number_of_bottles: spli.number_of_bottles_per_case,
            gift_box_type: spli.gift_box_type,
        }

        if (spli.customs_status && spli.customs_status !== '') {
            request['customs_status'] = spli.customs_status
        }

        const {result, success} = await api.get<PalletLayoutResponse>(
            `discover/cases/find-pallet-layout?${object_to_query_string(request)}`,
        )

        if (success) {
            Object.assign(spli, {
                cases_per_pallet: result.cases_per_pallet,
                cases_per_pallet_layer: result.cases_per_pallet_layer,
            })
        }
    }

    async save_data(vnode: m.Vnode<UpsertSpliAttrs>) {
        const context = $s.context.data as SpliData

        if (vnode.attrs.source === 'sourceline') {
            try {
                // There is no GTIN endpoint validation (yet) as in SPLI mode;
                // assume everything will work out just fine.
                const {result, success} = await $m.pricelists.resolve_sourceline(context, model)

                if (success) {
                    notifier.notify(`Successfully resolved sourceline: ${context.product_name}`, 'success')
                } else {
                    notifier.notify(`Something went wrong while resolving: ${result}`, 'danger')
                }
                vnode.attrs.collection.select_next($s.context.id)
            } catch (e) {
                notifier.notify(`Failed to resolve sourceline: ${e}`, 'danger')
            }
        } else if (vnode.attrs.source === 'spli') {
            const spl_artkey = model.supplier_pricelist.artkey
            const endpoint_prefix = `discover/supplier-price-lists/${spl_artkey}/supplier-price-list-item`
            const spli_data: SupplierPricelistItemRequest = {
                aux_info: context.aux_info,
                availability_status: context.availability_status,
                bottle: {
                    alcohol_percentage: context.alcohol_percentage,
                    artkey: context.bottle_artkey || null,
                    product: {
                        artkey: context.product_artkey,
                        category: {
                            artkey: context.product_category_artkey,
                            name: context.product_category,
                        },
                        name: context.product_name,
                    },
                    refill_status: context.refill_status,
                    volume: context.volume,
                },
                bottle_artkey: context.bottle_artkey || null,
                bottle_gtin_code: context.bottle_gtin_code,
                country_of_origin: context.country_of_origin,
                case_gtin_code: context.case_gtin_code,
                currency: context.currency,
                customs_status: context.customs_status,
                gift_box_type: context.gift_box_type,
                incoterm: context.incoterm_location ? `${context.incoterm} - ${context.incoterm_location}` : context.incoterm,
                number_of_bottles: context.number_of_bottles,
                number_of_bottles_per_case: context.number_of_bottles_per_case,
                number_of_cases: context.number_of_cases,
                cases_per_pallet: context.cases_per_pallet,
                price_per_bottle: context.price_per_bottle,
                price_per_case: context.price_per_case,
                supplier_price_list_artkey: spl_artkey,
            }

            let success, result
            try {
                if (context.artkey) {
                    // Update an existing spli item.
                    spli_data.artkey = context.artkey;
                    ({result, success} = await api.put<SupplierPricelistItemRequest>(`${endpoint_prefix}/${context.artkey}`, spli_data) as any)
                    success && notifier.notify(`Successfully updated pricelist item: ${context.product_name}`, 'success')

                } else {
                    // Create a new spli item.
                    ({result, success} = await api.post(endpoint_prefix, spli_data, true))

                    if (success) {
                        notifier.notify(`Successfully created pricelist item: ${context.product_name}`, 'success')
                        $s.context.data = $m.pricelists.spli_row_model() // Clear the form.
                        await this.context_update(vnode)
                    }
                }
            } catch (err) {
                notifier.notify(`Something went wrong while resolving: ${err}`, 'danger')
            }

            if (success) {
                await vnode.attrs.collection.update_context()
            } else {
                notifier.notify(`Sorry, the pricelist item could not be applied: ${result.detail}`, 'warning')
                return
            }
        }
        // Refetch the pricelist to update the UI
        events.emit('spl:refetch')
    }

    view(vnode: m.Vnode<UpsertSpliAttrs, {}>): m.Children {
        const context = $s.context.data as SpliData
        let invalid = invalid_fields(this.$v)

        if (context.bottle_artkey) {
            this.data.linked.specs = true
        } else {
            this.data.linked.specs = false
        }

        const product_status = {
            icon: this.data.linked.product ? 'link' : 'linkOff',
            text: (() => {
                const linked = [] as any
                if ($s.context.loading) return 'Loading...'
                if (this.data.linked.product) linked.push('Product')
                if (this.data.linked.specs) linked.push('Specs')
                if (this.data.linked.gtin) linked.push('GTIN')

                if (linked.length) return `Linked: ${linked.join(', ')}`
                return 'Unlinked Product'
            })(),
            type: (() => {
                if ($s.context.loading) return 'default'
                if (this.data.linked.product && this.data.linked.specs && this.data.linked.gtin) return 'success'
                if (this.data.linked.product && this.data.linked.specs) return 'success'
                if (this.data.linked.product) return 'info'
                return 'default'
            })(),
        }

        return <div className="c-upsert-spli">

            <div className={classes('spli-status', product_status.type)}>
                {$s.context.loading ? <Spinner/> : <Icon
                    name={product_status.icon}
                    type={product_status.type}
                />}
                <span>{product_status.text}</span>
            </div>

            <div className="context-well">

                <div className="field-group">
                    <FieldText
                        disabled={this.data.linked.gtin || $s.context.loading}
                        help={(() => {
                            if (this.data.linked.gtin) {
                                return 'Product name is determined from GTIN'
                            } else if (this.data.linked.product) {
                                return 'This is a known product'
                            }
                            return 'This product is unknown to us'
                        })()}
                        label="Product name"
                        submit={(e, suggestion) => {
                            if (suggestion) {
                                this.context_from_product(vnode, suggestion)
                            } else {
                                // Nothing found; unset any linkage.
                                this.data.bottles.length = 0
                                context.bottle_artkey = undefined
                                Object.assign(this.data.linked, {
                                    gtin: false,
                                    product: false,
                                    specs: false,
                                })
                            }
                        }}
                        placeholder="Product name"
                        ref={[context, ['product_name']]}
                        suggestions={async(filter_text) => {
                            if (filter_text.length <= 3) return []
                            const {result} = await api.get(`discover/products?search_terms=${filter_text}`) as any
                            return result
                        }}
                        validation={this.$v.product_name}
                    />

                    <div className="field gtin-field">
                        <FieldText
                            disabled={this.data.linked.gtin || $s.context.loading}
                            help="Determine product & specs from GTIN"
                            label="GTIN (Bottle)"
                            ref={[context, 'bottle_gtin_code']}
                            validation={this.$v.bottle_gtin_code}
                        >
                            <Button
                                disabled={!this.data.linked.gtin && !context.bottle_gtin_code || this.$v.bottle_gtin_code._invalid}
                                icon={this.data.linked.gtin ? 'linkOff' : 'link'}
                                onclick={() => {
                                    if (this.data.linked.gtin) {
                                        context.bottle_gtin_code = null
                                        this.data.linked.gtin = false
                                    } else if (context.bottle_gtin_code) {
                                        this.context_from_gtin(vnode, context)
                                    }
                                }}
                                tip={() => this.data.linked.gtin ? 'Clear bottle GTIN' : 'Match product & specs from bottle GTIN'}
                                type={this.data.linked.gtin ? 'danger' : 'info'}
                            />
                        </FieldText>
                    </div>
                </div>

                <div className="field-group">
                    <FieldSelect
                        disabled={this.data.linked.product || $s.context.loading}
                        help={this.data.linked.product ? 'Category is determined from existing product' : 'Select a product category'}
                        label="Category"
                        options={model.product_categories.map((category: any) => [category.artkey, category.name])}
                        placeholder={'Product category...'}
                        ref={[context, 'product_category_artkey']}
                        validation={this.$v.product_category_artkey}
                    />
                    <FieldSelect
                        disabled={this.data.linked.gtin || $s.context.loading}
                        help={this.data.linked.specs ? 'Good! Use existing specs where possible' : 'Do you really need to create new specs?'}
                        label="Specs"
                        options={this.data.bottles.map((bottle: any) => [bottle.artkey, to_specs(bottle, $s.identity.user.decimal_locale)])}
                        placeholder="New Specs..."
                        ref={[context, 'bottle_artkey']}
                    />
                </div>

                {/* Not an existing bottle; add specs manually */}
                {!context.bottle_artkey && <div className="field-group">
                    <FieldText
                        disabled={this.data.linked.gtin || $s.context.loading}
                        label="Size cl"
                        min={0}
                        ref={[context, 'volume']}
                        step="0.1"
                        type="number"
                        validation={this.$v.volume}
                    />

                    <FieldText
                        disabled={this.data.linked.gtin || $s.context.loading}
                        label="Alcohol %"
                        max={100}
                        min={0}
                        ref={[context, 'alcohol_percentage']}
                        step="0.1"
                        type="number"
                        validation={this.$v.alcohol_percentage}
                    />

                    <FieldSelect
                        disabled={this.data.linked.gtin || $s.context.loading}
                        label="Refill status"
                        options={$m.data.bottle_refill_statusses.map((key: string) => [key, key])}
                        placeholder="select..."
                        ref={[context, 'refill_status']}
                        validation={this.$v.refill_status}
                    />
                </div>}

            </div>

            <div className="fieldset">
                <div className="field-group">

                    <div className="field-merge" key="">
                        <Button
                            className="mt-3"
                            icon={this.data.per_case ? 'case' : 'bottle'}
                            onclick={async() => {
                                this.data.per_case = !this.data.per_case

                                if (this.data.per_case) {
                                    context.number_of_cases = Math.ceil(context.number_of_bottles / context.number_of_bottles_per_case)
                                    context.number_of_bottles = undefined
                                    if (context.price_per_bottle) {
                                        context.price_per_case = Number(context.price_per_bottle) * context.number_of_bottles_per_case
                                    }
                                    context.price_per_bottle = null
                                } else {
                                    context.number_of_bottles = context.number_of_cases * context.number_of_bottles_per_case
                                    context.number_of_cases = undefined
                                    if (context.price_per_case) {
                                        context.price_per_bottle = Number(context.price_per_case) / context.number_of_bottles_per_case
                                    }
                                    context.price_per_case = null
                                }

                                await next_tick()
                                await next_tick()
                                m.redraw()
                            }}
                            tip={() => {
                                return `Use ${this.data.per_case ? 'cases' : 'bottles'} for the unit price`
                            }}
                            type="info"
                        />
                        <FieldText
                            className="mw-120"
                            disabled={$s.context.loading}
                            label={`Quantity (${this.data.per_case ? 'case' : 'bottle'})`}
                            min={0}
                            placeholder="..."
                            ref={this.data.per_case ? [context, 'number_of_cases'] : [context, 'number_of_bottles']}
                            type="number"
                        />
                    </div>

                    <FieldText
                        key=""
                        disabled={$s.context.loading}
                        label="Bottles per case"
                        min={1}
                        ref={[context, 'number_of_bottles_per_case']}
                        onafterupdate={(value: number) => {
                            if (value) {
                                // Adjust the case price when the number of bottles per case changes.
                                if (this.data.per_case && context.price_per_case) {
                                    context.price_per_case = +(
                                        +context.price_per_case / this.data.previous_number_of_bottles_per_case * value
                                    ).toFixed(2)
                                }
                                this.data.previous_number_of_bottles_per_case = value
                            }
                        }}
                        type="number"
                        validation={this.$v.number_of_bottles_per_case}
                    />

                    <MoneyInput
                        disabled={$s.context.loading}
                        label={`Price (${context.currency})`}
                        key={`${context.artkey}-${context.bottle_artkey}-${context.volume}`}
                        value={this.data.per_case ? context.price_per_case : context.price_per_bottle}
                        on_value={(value: number) => {
                            if (this.data.per_case) {
                                context.price_per_case = value
                            } else {
                                context.price_per_bottle = value
                            }
                        }}
                        required={true}
                        minimum={0}
                        currency={context.currency}
                        on_value_currency={(currency: string) => context.currency = currency}
                        show_currency_edit={true}
                    />
                </div>

                <div className="field-group">
                    <FieldSelect
                        disabled={$s.context.loading}
                        label="Country of Origin"
                        options={Object.entries(countries)}
                        placeholder="Select..."
                        ref={[context, 'country_of_origin']}
                        validation={this.$v.country_of_origin}
                    />

                    <FieldSelect
                        disabled={$s.context.loading}
                        label="Customs status"
                        options={[['T1', 'T1'], ['T2', 'T2']]}
                        placeholder={'Select...'}
                        ref={[context, 'customs_status']}
                        validation={this.$v.customs_status}
                    />

                </div>

                <div className="field-group">

                    <GiftBoxTypeDropDown
                        disabled={$s.context.loading}
                        label="Giftbox Type"
                        empty_option={empty_option('Select...')}
                        selected={context.gift_box_type}
                        onchange={(value: string) => (context.gift_box_type = value)}
                    />

                    <FieldText
                        disabled={$s.context.loading}
                        label="GTIN (Case)"
                        ref={[context, 'case_gtin_code']}
                    />
                </div>

                <div className="field-group">
                    <IncotermsDropDown
                        disabled={$s.context.loading}
                        get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                        label="Incoterm"
                        onchange={(value: any) => context.incoterm = value}
                        selected_incoterm={context.incoterm}
                    />
                    <FieldText
                        disabled={$s.context.loading}
                        label="Location"
                        ref={[context, 'incoterm_location']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        disabled={true}
                        label="Cases per pallet"
                        ref={[context, 'cases_per_pallet']}
                    />
                    <FieldText
                        disabled={true}
                        label="Cases per pallet layer"
                        ref={[context, 'cases_per_pallet_layer']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        disabled={$s.context.loading}
                        label="Availability status"
                        ref={[context, 'availability_status']}
                    />
                    <FieldText
                        disabled={$s.context.loading}
                        label="Aux info"
                        ref={[context, 'aux_info']}
                        oninput={(value: string | undefined) => context.aux_info = value}
                    />
                </div>

                <ButtonGroup>
                    <Button
                        disabled={invalid.length}
                        icon="save"
                        onclick={() => this.save_data(vnode)}
                        text={(() => {
                            if (vnode.attrs.source === 'sourceline') {
                                return 'Resolve Sourceline'
                            } else {
                                if (context.artkey) {
                                    return 'Update Pricelist Item'
                                }
                                return 'Add Pricelist Item'
                            }
                        })()}
                        tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                        type="success"
                    />
                </ButtonGroup>
            </div>
        </div>
    }
}
