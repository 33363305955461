m = require 'mithril'
{map, id} = require 'prelude-ls'
{a} = require 'utils.ls'
{is-function} = require '@bitstillery/common/utils.ls'
inputs = require '@/components/inputs'


export class Field
    (name, label, element, type, attrs, help_text) ->
        @name = name
        @label = label
        @element = element
        @type = type
        @attrs = attrs or {}
        @help_text = help_text

    # Note: overriding by inheritance only works on -> not on ~>
    view_element: (entity) ->
        attrs = do
            id: @label
            placeholder: @name
            required: true
            autocomplete: \off
            value: entity[@label]! or ''
            oninput: (ev) ~> entity[@label] ev.target.value

        if @element == 'input' then
            attrs.type = @type

        for key, value of @attrs
            attrs[key] = value

        m "#{@element}.form-control" attrs

    view: (entity) ->
        m '.field',
            m 'label' {for: @label} @name
            @view_element.call @, entity
            if @help_text then
                m '.help' @help_text


export class EnumField extends Field
    (name, label, options, attrs, get-key, get-value) ->
        super name, label, 'select', '', attrs
        @options = if is-function options then options else window.prop options
        @get-key = get-key or id
        @get-value = get-value or id

    view_element: (entity) ->
        attrs = do
            id: @label
            placeholder: @name
            required: true
            autocomplete: \off
            onchange: (e) ~>
                let key = e.target.options[e.target.selectedIndex].value
                    entity[@label] key

        for key, value of @attrs
            attrs[key] = value

        m 'select.form-control' attrs,
            m 'option' {value: ''} ''
            @options! |> map (option) ~>
                let key = @get-key option
                    m 'option' do
                        value: key
                        selected: +entity[@label]! == key
                    , @get-value option


export class CheckboxField extends Field
    view: (entity) ->
        inputs.checkbox entity[@label], {label: @name}
