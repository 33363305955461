import m from 'mithril'
import {show_confirmation} from 'components/confirmation.ls'
import {notifier} from '@bitstillery/common/app'

import {
    DeleteFilterPresetRequest,
    DeleteFilterPresetResponse,
    FilterApi,
    FilterPreset,
    GetFilterPresetsRequest,
    SaveFilterPresetRequest,
} from '@/factserver_api/filter_api'

export class FilterPresetType {
    static readonly SELECT_OFFER_ITEM = 'select offer item'
    static readonly SELECT_PURCHASE_ORDER_ITEM = 'select purchase order item'
    static readonly SELECT_MARKET_ITEM = 'select market item'
    static readonly SELECT_RELATION = 'select relation'
    static readonly MARKET_DISCOVER = 'market discover'
}

/*
 * A generic class to handle fetching, saving and deleting of filter presets for a filter sidebar.
 * It works by passing mithril properties to the constructor, which contain the local state.
 */
export class FilterPresetHandler {
    // Properties for the local state.
    filter_preset_type: string
    filter_presets: (value?: Array<FilterPreset>) => Array<FilterPreset> | void
    filter_preset_choices: (value?: Array<Array<string | number | null>>) => Array<Array<string | number | null>> | void
    selected_filter_preset_artkey: (value?: number | null) => number | null | void
    loading_filter_presets: (value?: boolean) => boolean | void

    filter_api = new FilterApi()

    constructor(
        filter_preset_type: string,
        filter_presets: (value?: Array<FilterPreset>) => Array<FilterPreset> | void,
        filter_preset_choices: (
            value?: Array<Array<string | number | null>>
        ) => Array<Array<string | number | null>> | void,
        selected_filter_preset_artkey: (value?: number | null) => number | null | void,
        loading_filter_presets: (value?: boolean) => boolean | void,
    ) {
        this.filter_preset_type = filter_preset_type
        this.filter_presets = filter_presets
        this.filter_preset_choices = filter_preset_choices
        this.selected_filter_preset_artkey = selected_filter_preset_artkey
        this.loading_filter_presets = loading_filter_presets
    }

    /*
     * Fetch a user's filter presets for a certain filter preset type, and put
     * them in the 'Select a preset'-dropdown.
     */
    get_filter_presets(): void {
        const request_data: GetFilterPresetsRequest = {filter_preset_type: this.filter_preset_type}

        this.filter_api.get_filter_presets(request_data).subscribe((response: Array<FilterPreset>) => {
            this.filter_presets(response)

            // Construct the filter preset dropdown choices.
            let choices: Array<Array<string | number | null>> = [[null, '']]
            response.forEach((filter_preset) => {
                choices = choices.concat([[filter_preset.artkey, filter_preset.name]])
            })
            this.filter_preset_choices(choices)

            this.loading_filter_presets(false)
            m.redraw() // render the preset choices in the dropdown
        })
    }

    /*
     * Get the data required to save a filter preset via `filter.save_filter_preset`.
     *
     * @param filters: the filter functions.
     *
     * @return {Obj}: The process input data.
     */
    get_save_request_data(filters: Record<string, () => unknown>): SaveFilterPresetRequest {
        // Convert the dictionary of Mithril properties to a serialized
        // dictionary with filter values.
        const serialized_filters: Record<string, unknown> = {}
        Object.keys(filters).forEach((parameter_name) => {
            serialized_filters[parameter_name] = filters[parameter_name]()
        })

        const request_data: SaveFilterPresetRequest = {
            name: '', // initialize as empty string
            filters: serialized_filters,
            filter_preset_type: this.filter_preset_type,
        }

        // If we have an artkey, add it to the request data along with the existing name.
        const artkey = this.selected_filter_preset_artkey()
        if (artkey) {
            request_data.artkey = artkey

            // Find the filter preset for the provided artkey so we can fetch its name.
            const filter_presets = this.filter_presets()
            if (filter_presets) {
                const filter_preset: FilterPreset = filter_presets.filter((preset) => String(preset.artkey) === String(artkey))[0]
                request_data.name = filter_preset.name
            }
        }

        return request_data
    }

    /*
     * Delete a filter preset from the database.
     */
    delete_filter_preset(): void {
        const artkey = this.selected_filter_preset_artkey()
        if (typeof artkey === 'number') {
            const request_data: DeleteFilterPresetRequest = {artkey: artkey}

            show_confirmation({
                title: 'Delete filter preset',
                message: 'Are you sure you want to delete this filter preset?',
                unique_name: 'delete_filter_preset_confirm',
                onconfirm: () => {
                    this.filter_api
                        .delete_filter_preset(request_data)
                        .subscribe((response: DeleteFilterPresetResponse) => {
                            // Reload and reset the filter presets to apply the changes to the local state.
                            this.selected_filter_preset_artkey(null)
                            this.get_filter_presets()

                            notifier.notify(`Filter preset '${response.name}' has been deleted successfully.`, 'success')
                        })
                },
            })
        }
    }
}
