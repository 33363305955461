m = require 'mithril'
GenericEdit = require '../generic/generic_edit.ls'
{Field} = require '../generic/generic_field.ls'
app = require('@/app')

module.exports = class PaymentTermEdit
    ->
        @fields = [
            new Field 'Description', 'description', 'input', 'text'
            new Field 'Exact code', 'exact_payment_term_code', 'input', 'text', {},
                "The Exact code should match the code for 'Betalingscondities' in Exact."
        ]

    view: ->
        m GenericEdit, do
            readable_entity_name: 'Payment term'
            base_url: '/data/paymentterms/'
            query_api_call: 'paymentterms.get_paymentterm'
            update_or_create_api_call: 'paymentterms.update_or_create_paymentterm'
            fields: @fields
            create_entity_func: app.$m.data.payment_term.create_payment_term
            broadcast_on_update: 'paymentterms_updated'
