m = require 'mithril'
{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'


module.exports = class VatTypeList
    is_match = (vat_type, term) ~>
        return or-list [
            vat_type.vat_code.toLowerCase!indexOf(term) > -1
        ]

    view: ->
        m GenericList, do
            readable_entity_name: 'VAT type'
            base_url: '/data/vattypes/'
            list_api_call: 'vattypes.get_all'
            is_match: @is_match
            columns:
                {key: 'description', label: 'Description'}
                {key: 'percentage', label: 'Percentage'}
                {key: 'vat_code', label: 'VAT code'}
            requery_subscribe_key: 'vattypes_updated'
