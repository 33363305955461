api = require '../../api.ls'
{Ledger, LedgerType} = require '@/models/data'
m = require 'mithril'
app = require('@/app')

module.exports = class LedgerEdit
    ->
        @ledger = window.prop new Ledger
        @user = window.prop []

        @create = window.prop false

        @vat_types = window.prop []
        api.call 'vattypes.get_all' {}, @set_vat_types

        ledger_artkey = m.route.param 'artkey'
        if ledger_artkey
            @query_ledger ledger_artkey
        else #we are creating a new one
            @create true

    set_vat_types: (resp) ~>
        if resp.success
            @vat_types [app.$m.data.vat_type.create item for item in resp.result]
        else
            app.$m.common.generic_error_handler!

    query_ledger: (artkey) ~>
        api.call 'ledgers.get_ledger' {artkey: artkey}, @set_ledger

    set_ledger: (resp) ~>
        if resp.success
            try
                @ledger app.$m.data.ledgers.create_ledger resp.result
            catch
                app.$m.common.generic_error_handler e
        else
            app.notifier.notify 'Unknown ledger.', 'danger'
            m.route.set '/data/ledgers'

    save: (e) ~>
        e.preventDefault!

        data = do
            artkey: @ledger!artkey!
            ledger_type: @ledger!ledger_type!
            description: @ledger!description!
            vat_type_artkey: @ledger!vat_type_artkey!
            ledger_code: @ledger!ledger_code!
        api.call 'ledgers.update_or_create_ledger' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            if @create!
                app.notifier.notify "Successfully created new ledger #{@ledger!ledger_code!}.", 'success'
                m.route.set '/data/ledgers/' + result.artkey + '/edit'
            else
                app.notifier.notify 'Successfully updated ledger.', 'success'
            app.$m.common.observable.broadcast 'ledgers_updated', ''

    set_address: (value) ~>
        @ledger!address value.replace(/\n/g, '|')

    view: -> m '.c-edit-ledger view',
        m '.btn-toolbar',
            m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/data/ledgers'},
             (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

        m 'form.flex-form' {onsubmit: @save},
            m '.fieldset',
                m '.field',
                    m 'label' {for: 'ledger_type'} 'Ledger type'
                    m 'select.form-control' {id: 'ledger_type', \
                        required: true, \
                        onchange: (ev) ~> @ledger!ledger_type ev.target.value},
                            m 'option' {value: ''} ''
                            m 'option' {value: LedgerType.PURCHASING, selected: if @ledger!ledger_type! == LedgerType.PURCHASING then true else false} LedgerType.PURCHASING.capitalizeFirstLetter!
                            m 'option' {value: LedgerType.SALES, selected: if @ledger!ledger_type! == LedgerType.SALES then true else false} LedgerType.SALES.capitalizeFirstLetter!
                m '.field',
                    m 'label' {for: 'description'} 'Description'
                    m 'textarea.form-control' {id: 'description', rows: 4, \
                        autocomplete: "off", \
                        value: @ledger!description! or '', \
                        oninput: (ev) ~> @ledger!description ev.target.value}
                m '.field',
                    m 'label' {for: 'ledger_code'} 'Ledger code'
                    m 'input.form-control' {id: 'ledger_code', type: 'number', \
                        required: true, \
                        placeholder: 'Ledger code', value: @ledger!ledger_code! or '', \
                        oninput: (ev) ~> @ledger!ledger_code ev.target.value}
                m '.field',
                    m 'label' {for: 'vattype'} 'VAT type'
                    m 'select.form-control' {id: 'vattype', \
                        required: true, \
                        onchange: (ev) ~> @ledger!vat_type_artkey +ev.target.value},
                            m 'option' {value: ''} ''
                            @vat_types!map (vat_type) ~>
                                m 'option', do
                                    value: vat_type.artkey!
                                    selected: if vat_type.artkey! == @ledger!vat_type_artkey! then true else false
                                , "#{vat_type.vat_code!} - #{vat_type.description!}"

            m 'button.btn.btn-success.btn-submit' if @create! then 'Create Ledger' else 'Save Ledger'
