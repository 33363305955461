item_link = {}
module.exports = item_link


item_link.from_purchase_order_item = (purchase_order_item, lot) ->
    bottle = purchase_order_item.bottle!
    url = "#!/stock/manage/?q="
    if lot
        url += " #{lot}"
    else
        url += "#{purchase_order_item.purchase_order!reference!} " +
        "#{bottle.product!name!} " +
        "#{(+bottle.volume!).toFixed(1)} " +
        "#{(+bottle.alcohol_percentage!).toFixed(1)}"
    url += "&available_only=false"
    return url.replace(/ /g, '+')

item_link.from_purchase_order = (purchase_order) ->
    url = "#!/stock/manage/?q=" +
        "#{purchase_order.reference!}" +
        "&available_only=false"
    return url.replace(/ /g, '+')

item_link.from_sales_order_credit_item = (sales_order_credit_item, lot) ->
    purchase_order_item = sales_order_credit_item.item!purchase_order_item!
    return item_link.from_purchase_order_item(purchase_order_item, lot)
