m = require 'mithril'
{GenericList} = require '../generic/generic_list'
{or-list} = require 'prelude-ls'


module.exports = class VatRateList
    is_match = (vat_rate, term) ~>
        return or-list [
            vat_rate.country_code.toLowerCase!indexOf(term.toLowerCase!) > -1
        ]

    view: ->
        m GenericList, do
            readable_entity_name: 'VAT rate'
            base_url: '/data/vatrates/'
            list_api_call: 'vatrates.get_all'
            is_match: @is_match
            columns:
                {key: 'country_code', label: 'Country'}
                {key: 'percentage', label: 'Percentage'}
                {key: 'vat_type.code', label: 'VAT Type', function: (vat_rate) -> "#{vat_rate.vat_type.vat_code} - #{vat_rate.vat_type.description}"}
            requery_subscribe_key: 'vatrates_updated'
