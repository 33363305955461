m = require 'mithril'
nouislider = require 'nouislider'
wNumb = require 'wnumb'


# A slider filter component with support for infinity as maximum value.
export class Slider
    (vnode) ~>
        @slider = null
        @prop = vnode.attrs.prop
        @onchange = vnode.attrs.onchange
        @options = vnode.attrs.options
        @range = do
            min: 0
            max: 10000

    onupdate: ~>
        # If the @prop changes (e.g. when loading a filter preset): set the
        # slider handles to the prop's value.
        options = do
            start: @get_handle_positions @prop
        @slider.updateOptions(options, true)

    create_slider: (vnode, prop, {range, step, decimals, infinity}) ~>
        # Set sane defaults.
        if range
            @range = range
        if not step
            step = 1
        if not decimals
            decimals = 0

        connect = [true, false]
        tooltips = [true]
        if prop!constructor === Array
            # connect the slider's middle part - its effect is the 'blue bar'
            connect.unshift(false)
            # enable tooltips for both slider handles
            tooltips.unshift(true)

        # Create slider.
        @slider = nouislider.create vnode.dom, do
            range: @range
            start: @get_handle_positions prop
            step: step
            connect: connect
            tooltips: tooltips
            format: wNumb do
                decimals: decimals  # set number of decimals
                edit: (val) ~>
                    # If the value equals range.max, display the infinity symbol.
                    if infinity and +val == @range.max then '∞' else val

        @slider.on 'change', (evt) ~>
            # Set the value of the prop to the values of the sliders. Convert
            # "infinity" to null (the infinity symbol is not a number).
            if isNaN evt[0] then val_min = @range.min else val_min = +evt[0]
            if isNaN evt[1] then val_max = @range.max else val_max = +evt[1]
            if val_max
                value = [val_min, val_max]
            else
                value = val_min

            if @onchange
                @onchange(value)
            else
                prop value

    # Get the positions for the left (min) and right (max) handles of the slider.
    #
    # @param {*} prop: Mithril property containing an array with min and max values.
    #
    # @return {array}: An array with min and max values.
    get_handle_positions: (prop) ~>
        if prop!length == 2
            # Initialize the slider handles on the prop's current values.
            # If a value is null, use range.max, as that represents infinity.
            start_min = prop![0]
            start_max = prop![1]
            if start_min == null then start_min = @range.max
            if start_max == null then start_max = @range.max
            return [start_min, start_max]
        else if prop!constructor === Array
            return [@range.min, @range.max]
        else
            return [prop!]

    view: ~>
        m '', do
            oncreate: (vnode) ~>
                @create_slider vnode, @prop, @options
