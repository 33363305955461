import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {DefaultButton, SuccessButton} from '@/components/buttons'
import {GetFastSalesOrderWithItemsResponse, SalesOrderStatus} from '@/factserver_api/sales_api'

export interface DownloadbuttonsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    export_to_excel: () => void
}

/**
 * Shows the status buttons for the sales order.
 * State transitions:
 *    Pending -> Finalized -> Confirmed -> Invoiced
 *                   Saved -> Confirmed -> Invoiced
 */
export class DownloadButtons extends MithrilTsxComponent<DownloadbuttonsAttrs> {
    view(vnode: m.Vnode<DownloadbuttonsAttrs>): m.Children {
        const elements: m.Children = []

        if ([SalesOrderStatus.INVOICED].some((status) => status === vnode.attrs.sales_order?.combined_status)) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-file'}
                onclick={() =>
                    m.route.set(`/sales-orders/manage/${vnode.attrs.sales_order?.artkey || ''}/invoice`)
                }
                title={' Invoice'}
            />)
        }

        if ([SalesOrderStatus.SAVED, SalesOrderStatus.FINALIZED, SalesOrderStatus.PENDING].some(
            (status) => status === vnode.attrs.sales_order?.combined_status,
        )) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-file'}
                onclick={() =>
                    m.route.set(`/sales-orders/manage/${vnode.attrs.sales_order?.artkey || ''}/proforma`)
                }
                title={' Proforma invoice'}
            />)
        }

        if (vnode.attrs.sales_order?.combined_status === SalesOrderStatus.CONFIRMED) {
            elements.push(<SuccessButton
                icon_class={'glyphicon glyphicon-file'}
                onclick={() =>
                    m.route.set(`/sales-orders/manage/${vnode.attrs.sales_order?.artkey || ''}/proforma`)
                }
                title={' Proforma invoice'}
            />)
        }

        elements.push(<DefaultButton
            icon_class={'glyphicon glyphicon-file'}
            onclick={() =>
                m.route.set(`/sales-orders/manage/${vnode.attrs.sales_order?.artkey || ''}/outtake-instruction`)
            }
            title={' Outtake instructions'}
        />)

        elements.push(<DefaultButton
            icon_class={'fas fa-file-excel'}
            onclick={vnode.attrs.export_to_excel}
            title={' Export to excel'}
        />)

        return elements
    }
}
