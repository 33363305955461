m = require 'mithril'
api = require 'api.ls'
app = require('@/app')
{or-list} = require 'prelude-ls'
{remove-from-array, add-unique-to-array} = require 'utils.ls'


{button-with-icon} = require '@/components/buttons.ls'
inputs = require '@/components/inputs'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
SearchInput = require '@/components/collection/search_input.ls'


module.exports = class WarehouseList
    (vnode) ->
        @supplier_artkey = m.route.param 'supplier_artkey'

        @warehouses = new Collection do
            api_function_name: 'destinations.get_all_destinations'
            filter_function: @is_match
            sort_order: [
                {'name': 'name', 'direction': 'asc'}
            ]
            default_sort_by: 'name'
            default_sort_order: 'asc'

        @search_input_ctrl = new SearchInput.controller do
            collection: @warehouses
            placeholder: 'Search for warehouses'

        @supplier_warehouses = []
        api.call2 'suppliers.get_all_supplier_warehouses', {supplier_artkey: @supplier_artkey, third_party_only: true}, @set_warehouses

    save: ~>
        data = do
            supplier_artkey: @supplier_artkey
            warehouse_artkeys: @supplier_warehouses
        api.call2 'suppliers.activate_third_party_warehouses' data, ~>
            app.notifier.notify 'Successfully updated the third party warehouses for this relation.', 'success'
            m.route.set "/crm/relations/#{@supplier_artkey}/edit?tab=destinations"

    set_warehouses: (resp) ~>
        for warehouse in resp.result
            add-unique-to-array(@supplier_warehouses, warehouse.artkey)

    warehouse_selected: (warehouse, value) ~~>
        if value != void
            # Checking or unchecking
            if value
                add-unique-to-array(@supplier_warehouses, warehouse.artkey)
            else
                remove-from-array(@supplier_warehouses, warehouse.artkey)
        return warehouse.artkey in @supplier_warehouses

    oncreate: ~>
        @warehouses.init!

    is_match: (warehouse, term) ~>
        list = [
            warehouse.name.toLowerCase!indexOf(term) > -1,
        ]

        if warehouse.street_address
            list.push warehouse.street_address.toLowerCase!indexOf(term) > -1
        if warehouse.zip_code
            list.push warehouse.zip_code.toLowerCase!indexOf(term) > -1
        if warehouse.city
            list.push warehouse.city.toLowerCase!indexOf(term) > -1
        if warehouse.street_address
            list.push warehouse.emailaddress.toLowerCase!indexOf(term) > -1

        return or-list list

    view: ->
        m '.c-warehouse-select view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set "/crm/relations/#{@supplier_artkey}/edit?tab=destinations"},
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'

                button-with-icon 'Save selection', 'save', do
                    class: 'btn-success'
                    onclick: ~> @save!

            m '.c-filter-group',
                SearchInput.view @search_input_ctrl

            m '.mail.recipients',
                m CollectionTable, do
                    collection: @warehouses
                    options:
                        search_table_style: true
                        sticky_header: true
                        with_buttons: true
                        autoscale: true
                        unique_name: 'supplier_warehouse_select'
                    columns:
                        do
                            width: 1
                            name: 'Activate'
                            sort: false
                            default_visible: true
                            function: (record) ~>
                                curried_warehouse_selected = @warehouse_selected record
                                inputs.checkbox(curried_warehouse_selected)
                        do
                            width: 3
                            name: 'Name'
                            field: 'name'
                            sort: true
                            ellipsis: true
                            default_visible: true
                        do
                            width: 1
                            name: 'Street address'
                            field: 'street_address'
                            sort: true
                            default_visible: true
                        do
                            width: 3
                            name: 'Zip code'
                            field: 'zip_code'
                            sort: true
                            default_visible: true
                        do
                            width: 1
                            name: 'City'
                            field: 'city'
                            sort: true
                            default_visible: true
                        do
                            width: 1
                            name: 'Country code'
                            field: 'country_code'
                            sort: true
                            default_visible: true
