m = require 'mithril'
{map, sort-by} = require 'prelude-ls'
{filter, toArray, mergeAll} = require "rxjs/operators"
prelude = require 'prelude-ls'
api = require 'api.ls'
{icon-button, text-button} = require '@/components/buttons.ls'
inputs = require '@/components/inputs'
{MoneyInput} = require '@/components/decimal_input'
{BottleMarket} = require '@/components/market_info/bottle_market'
{BottlePurchaseOrders} = require '@/components/market_info/bottle_purchase_orders'
{BottleSalesOrders} = require '@/components/market_info/bottle_sales_orders'
{BottleStock} = require '@/components/market_info/bottle_stock'
{OfferHistory} = require '@/components/market_info/offer_history'
{Product} = require '@/models/products'
{OfferItems} = require 'pricelist/components/items.ls'
{item-tags-editor} = require '@/stock/components/item_tags_editor.ls'
{a, unique-id}: utils = require 'utils.ls'
{RelationDropDown} = require '@/components/relation'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{current_account_slug} = require '@bitstillery/common/account/account'
{AutocompleteInput} = require '@/components/collection/autocomplete_input'
{ProductManagementApi} = require '@/factserver_api/product_management_api'
{classes} = require '@bitstillery/common/lib/utils'
{CustomOfferItem} = require '@/models/offers'
{Bottle} = require '@/models/bottles'
app = require('@/app')

export class EditCustomOfferItem
    (vnode) ->
        @product_categories = window.prop []

        # Attributes provided by the offer_products page.
        @custom_offer_item_instance = vnode.attrs.custom_offer_item_instance
        @selected_tbo_supplier_artkey = window.prop ''
        @offer_artkey = vnode.attrs.offer_artkey
        @always_special = vnode.attrs.always_special
        @supplier_artkey = vnode.attrs.supplier_artkey
        @onsaved = vnode.attrs.onsaved || (->)

        # Props for the state.
        @bottle = window.prop new Bottle
        @custom_offer_item = window.prop new CustomOfferItem

        @creating = window.prop true
        @error_messages = window.prop []
        @focus_product_input = window.prop true
        @new_spec = window.prop true
        @override_quantity = window.prop false
        @override_price = window.prop false
        @saving = window.prop false

        @product_management_api = new ProductManagementApi()
        @current_selected_simple_product = null
        @current_search_text = null
        @search_bar_controller = null

        # Generate a unique key for the price input component that exists
        # until the form is reset.
        @price_per_case_input_key = unique-id!

        if @custom_offer_item_instance
            # If @custom_offer_item_instance is provided, we are editing an
            # existing custom offer item, which means we need to give a number
            # of properties a value.
            @creating = window.prop false
            @custom_offer_item @custom_offer_item_instance

            # Fill the empty bottle window.prop with data from @custom_offer_item, so
            # the corresponding form fields get their initial values.
            @bottle!product!name @custom_offer_item!name!
            @bottle!product!category @custom_offer_item!category!
            @bottle!volume @custom_offer_item!volume!
            @bottle!alcohol_percentage @custom_offer_item!alcohol_percentage!
            @bottle!refill_status @custom_offer_item!refill_status!to-lower-case!

            # If the custom offer item's quantity column has a value, then the
            # quantity is overridden (assuming the custom offer item is based
            # on an offer item).
            if @custom_offer_item!custom_quantity! != null
                @override_quantity true

            # If the custom offer item's price column has a value, then the
            # price is overridden (assuming the custom offer item is based
            # on an offer item).
            if @custom_offer_item!custom_price_per_case! != null
                @override_price true
                @custom_offer_item!price_per_case @custom_offer_item!custom_price_per_case!
        else
            # For new custom offer items, set the default value for its
            # currency.
            @custom_offer_item!currency app.$s.currencies.default
            @custom_offer_item!purchase_currency app.$s.currencies.default

    oncreate: ~>
        @get_categories!

    get_categories: ~>
        api.call-and-then 'product_categories.get_product_categories', {}, do
            success: (resp) ~>
                resp.result
                    |> prelude.filter (item) -> item.cbs_code != "UNKNOWN"
                    |> map ({name}) -> name.charAt(0)toUpperCase! + name.slice(1)
                    |> @product_categories

    # Basic client side form validation to verify if required fields are
    # entered and/or within the allowed ranges.
    #
    # @return {boolean}: true if there are no validation errors, false
    #     otherwise.
    check_can_save: ~>
        @error_messages []

        if not @bottle!product!artkey!
            if not @bottle!product!name!
                @error_messages!push 'Please enter a product name.'
            if not @bottle!product!category!
                @error_messages!push 'Please enter a category.'

        if @new_spec!
            if not (+@bottle!volume! >= 1)
                @error_messages!push 'Please enter a size of 1 or greater.'
            if @bottle!alcohol_percentage! == '' or not (0 <= +@bottle!alcohol_percentage! <= 100)
                @error_messages!push 'Please enter an alcohol percentage between 0 and 100.'
        else
            if not @bottle!artkey!
                @error_messages!push 'Please select specs or create new specs.'

        if not +@custom_offer_item!number_of_bottles! >= 1
            @error_messages!push 'Please enter a number of bottles per case (Btl / cs) of 1 or greater.'

        # Only validate the price value if:
        # - the custom offer item is manually added, or
        # - we choose to override it.
        if (@is_manual! or @override_price!) and not @custom_offer_item!price_per_case!
            @error_messages!push 'Please enter a sales price.'

        # Validate that the delivery period and supplier are selected for:
        # - Manual custom offer items (which may only be TBOs).
        # - TBO offer items.
        if @is_manual!
            if (@custom_offer_item!delivery_period! == null or not (+@custom_offer_item!delivery_period! >= 1))
                @error_messages!push 'Please enter a delivery period (weeks) of 1 or greater. Manually created custom offer items may only be TBOs.'
            if not @custom_offer_item!supplier!artkey!
                @error_messages!push 'Please select a TBO supplier. Manually created custom offer items may only be TBOs.'
        else if @custom_offer_item!offer_item_type! == 'tbo'
            if (@custom_offer_item!delivery_period! == null or not (+@custom_offer_item!delivery_period! >= 1))
                @error_messages!push 'Please enter a delivery period (weeks) of 1 or greater. This is a TBO offer item.'
            if not @custom_offer_item!supplier!artkey!
                @error_messages!push 'Please select a TBO supplier. This is a TBO offer item.'

        # Only validate the quantity value if:
        # - the custom offer item is manually added, or
        # - we choose to override it.
        if (@is_manual! or @override_quantity!) and not +@custom_offer_item!quantity! >= 1
            @error_messages!push 'Please enter a quantity (Qty (cs)) of 1 or greater.'

        if not @custom_offer_item!customs_status!
            @error_messages!push 'Please select a customs status.'

        if @is_manual! and not (@custom_offer_item!purchase_currency! and @custom_offer_item!purchase_price_per_case!)
            @error_messages!push 'Please enter a purchase price.'

        return @error_messages!length == 0

    create_new_specs: ~>
        @new_spec true
        current_product = @bottle!product!
        @bottle new Bottle
        @bottle!product current_product

    # Checks if a product name can be matched to a product in the database.
    #
    # @return {boolean}: true if it can be matched, false otherwise.
    known_product: ~>
        @current_selected_simple_product != null

    is_editing: ~>
        @custom_offer_item!artkey! != ''

    # Manually added custom offer items have no link to a pricelist item. A
    # lot of the logic in this file depends on this to be true or false, so
    # create a variable for it.
    #
    # @return {boolean}: true for manually added custom offer items, false otherwise.
    is_manual: ~>
        (not @custom_offer_item!offer_item_artkey!)

    # Checks if a custom offer item is created from a stock offer item.
    # This includes purchase items, which are items-to-be-in-stock.
    #
    # @return {boolean}: true for stock/purchase offer items, false otherwise.
    is_stock_item_from_pricelist: ~>
        (not @is_manual!) and @custom_offer_item!offer_item_type and @custom_offer_item!offer_item_type! in ['stock', 'purchase']

    # Returns parameters for BottleMarket.
    bottle_market_data: ~>
        data = do
            bottle_artkey: @custom_offer_item!bottle_artkey!
            custom_offer_item: @custom_offer_item

        if @custom_offer_item!offer_item_type! == 'tbo'
            data['current_supplier_artkey'] = @custom_offer_item!supplier!artkey!

        return data

    # Returns parameters for BottleStock, BottlePurchaseOrders and BottleSalesOrders.
    match_data: ~>
        data = do
            bottle_artkey: @custom_offer_item!bottle_artkey!
            case_artkey: @custom_offer_item!case_artkey!
            case_customs_status: @custom_offer_item!customs_status!
            item_best_before_date: @custom_offer_item!best_before_date!

        # Only include item tags for non-tbo items. This is only possible for these items, because
        # here they have been copied from the pricelist item and cannot be edited.
        if @custom_offer_item!offer_item_type! != 'tbo'
            data['item_tags_sorted_artkeys'] = @custom_offer_item!item_tags_sorted_artkeys!

        return data

    after_update_product: (simple_product) ~>>
        @current_selected_simple_product = simple_product
        if simple_product
            const {result: bottles} = await app.api.get("discover/products/#{simple_product.artkey}/bottles")
            simple_product.bottles = bottles

            @bottle!product!artkey simple_product.artkey
            @bottle!product!name simple_product.name
            @bottle!product!category simple_product.product_category.name
            @bottle!product!bottles simple_product.bottles.map((bottle) ~>
                bottle = new Bottle(
                    bottle.artkey,
                    bottle.volume,
                    bottle.alcohol_percentage,
                    bottle.refill_status,
                    simple_product.artkey,
                )
                bottle.product = window.prop do
                    name: window.prop simple_product.name
                    artkey: window.prop simple_product.artkey
                    category: window.prop simple_product.product_category.name
                    bottles: window.prop []
                return bottle
            )

            @new_spec false
            @set_bottle(bottles[0].artkey)
        else
            @bottle!product new Product
            @bottle!product!name @current_search_text
            @new_spec true

        m.redraw()

    # This function effectively clears the form. It is used via the
    # offer_products page, where this function is the value of the `onsaved`
    # parameter when adding a custom offer item.
    reset: ~>
        @bottle new Bottle
        @custom_offer_item new CustomOfferItem
        @custom_offer_item!currency app.$s.currencies.default
        @custom_offer_item!purchase_currency app.$s.currencies.default

        @search_bar_controller.clear_search_text!
        @error_messages []
        @focus_product_input true
        @new_spec true

        # Generate new keys for the price input components.
        @price_per_case_input_key = unique-id!
        @purchase_price_per_case_input_key = unique-id!

    save: ~>
        @saving true
        if not @check_can_save!
            @saving false
            return

        data = do
            artkey: @custom_offer_item!artkey!
            offer_artkey: @offer_artkey
            remark: @custom_offer_item!remark!
            currency: app.$s.currencies.default  # currency is conditionally overridden below
            minimum_quantity: @custom_offer_item!minimum_quantity!
            maximum_quantity: @custom_offer_item!maximum_quantity!
            is_special: @custom_offer_item!is_special!
            purchase_price_per_case: @custom_offer_item!purchase_price_per_case!
            purchase_currency: @custom_offer_item!purchase_currency!

        # Some fields may not be changed for stock items from the pricelist,
        # because they would make it a different product which we may not have
        # in stock or have a different quantity of. Disabled inputs already
        # prevent that, but to be safe, these fields are sent to the
        # factserver only for TBO items.
        if not @is_stock_item_from_pricelist!
            data['supplier_artkey'] = @custom_offer_item!supplier!artkey!
            data['delivery_period'] = @custom_offer_item!delivery_period!
            data['name'] = @bottle!product!name!
            data['category'] = @bottle!product!category!
            data['volume'] = @bottle!volume!
            data['alcohol_percentage'] = @bottle!alcohol_percentage!
            data['refill_status'] = @bottle!refill_status!
            data['number_of_bottles'] = @custom_offer_item!number_of_bottles!
            data['gift_box_type'] = @custom_offer_item!gift_box_type!
            data['tax_label'] = @custom_offer_item!tax_label!
            data['customs_status'] = @custom_offer_item!customs_status!
            data['best_before_date'] = @custom_offer_item!best_before_date!
            data['item_tags'] = @custom_offer_item!item_tags!

        # Only save the quantity if:
        # - the custom offer item is manually added, or
        # - we choose to override it.
        if @is_manual! or @override_quantity!
            data['quantity'] = @custom_offer_item!quantity!

        # Only save the selected price and currency if:
        # - the custom offer item is manually added, or
        # - we choose to override it.
        if @is_manual! or @override_price!
            data['price_per_case'] = @custom_offer_item!price_per_case!
            data['currency'] = @custom_offer_item!currency!

        api.call-and-then 'offer.create_or_update_custom_offer_item', data, do
            success: (resp) ~>
                @saving false
                if @creating!
                    app.notifier.notify "Successfully created new custom offer item for #{resp.name}.", 'success'
                else
                    app.notifier.notify "Successfully updated custom offer item for #{resp.name}.", 'success'
                app.$m.common.observable.broadcast 'custom_offer_item_updated', 'A Custom Offer Item has been updated.'
                app.$m.common.observable.broadcast 'offer_updated'
                @onsaved @

            failure: (resp) ~>
                @error_messages!push resp.message

    set_bottle: (bottle_artkey) ~>
        bottle = @current_selected_simple_product.bottles.find((bottle) -> bottle.artkey == +bottle_artkey)
        @bottle!artkey bottle.artkey,
        @bottle!alcohol_percentage bottle.alcohol_percentage
        @bottle!volume bottle.volume
        @bottle!refill_status bottle.refill_status

    set_focus: (vnode) ~>
        # Focus the product input element and reset the focus request.
        if @focus_product_input! then
            $ vnode.dom .focus!
            @focus_product_input false

    show_rate: ~>
        if @custom_offer_item!currency! != app.$s.currencies.default
            m 'small' " (rate: #{app.$s.currencies.exchange_rates[@custom_offer_item!currency!].portal_rate})"

    view: ~>
        m '.c-edit-custom-offer-item',
            # Show the custom offer item form.
            m '.fieldset.largest',
                m '.field-group',
                    m AutocompleteInput,
                        label: if @known_product! then 'Product (known)' else 'Product'
                        placeholder: "Select a product..."
                        on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_products(filter_text)
                        suggestion_as_label: (simple_product) -> simple_product.name
                        on_selected: (simple_product) ~>
                            if simple_product then
                                @after_update_product(simple_product)
                        default_text: @bottle!product!name! || ''
                        required: true
                        on_new_search_text: (text) ~>
                            @current_search_text = text
                            @after_update_product(null)
                        search_bar_controller: (sbc) ~> @search_bar_controller = sbc
                        disabled: @is_stock_item_from_pricelist!

                    inputs.select @bottle!product!category, @product_categories!, do
                        label: 'Category',
                        required: true
                        empty_option: true
                        disabled: @known_product! or @is_stock_item_from_pricelist!

                    # Specs dropdown field.
                    m '' {hidden: @new_spec!} a do
                        m '.field' a do
                            m 'label' 'Specs'
                            m '.control',
                                m 'select' do
                                    required: true
                                    onchange: (ev) ~>
                                        @set_bottle ev.target.value
                                    disabled: @is_stock_item_from_pricelist!
                                , a do
                                    if @bottle!product!
                                        # Render an option for each of the product's bottles. Pre-select
                                        # the 'set' bottle. This is better than selecting a disabled empty
                                        # value (which was the previous solution), because after selecting
                                        # another product, that disabled option cannot be selected again.
                                        (@bottle!product!bottles!
                                        |> sort-by (obj) -> +(obj.volume!)
                                        |> map (bottle) ~>
                                            m 'option' do
                                                value: bottle.artkey!
                                                selected: bottle.artkey! == @bottle!artkey!
                                            , bottle.to_specs!)

                                icon-button 'plus', do
                                    class: 'btn-default form-control'
                                    tabindex: -1
                                    onclick: @create_new_specs
                                    disabled: @is_stock_item_from_pricelist!

                    inputs.number @bottle!volume, do
                        classNames: {hidden: not @new_spec!}
                        label: 'Size in cl'
                        min: 1
                        step: '0.1'
                        required: true
                        disabled: @is_stock_item_from_pricelist!

                    inputs.number @bottle!alcohol_percentage, do
                        classNames: {hidden: not @new_spec!}
                        label: 'Alcohol %'
                        min: 0
                        max: 100
                        step: '0.1'
                        required: true
                        disabled: @is_stock_item_from_pricelist!

                    m '.field' {className: classes({hidden: not @new_spec!})},
                        m 'label' 'Refill'
                        inputs.refill_status @bottle!refill_status, do
                            required: true
                            disabled: @is_stock_item_from_pricelist!

                    inputs.gift_box_type @custom_offer_item!gift_box_type, {
                        disabled: @is_stock_item_from_pricelist!
                        label: 'Gift box type',
                    }

                    inputs.date @custom_offer_item!best_before_date, {
                        disabled: @is_stock_item_from_pricelist!
                        label: 'Best before date'
                    }

                m '.field-group' a do
                    inputs.number @custom_offer_item!number_of_bottles, do
                        label: 'Bottles per case'
                        min: 1
                        required: true
                        disabled: @is_stock_item_from_pricelist!

                    # Price inputs.
                    m '.field' a do
                        m 'label' 'Sales price' if @override_price! then @show_rate!
                        if @is_manual!
                            # Render form fields for setting the price and
                            # currency for a custom product.
                            m '.control' a do
                                # Price input.
                                m MoneyInput, do
                                    value: @custom_offer_item!price_per_case!
                                    show_currency_edit: true
                                    on_value: (price) ~> @custom_offer_item!price_per_case price
                                    on_value_currency: (currency) ~> @custom_offer_item!currency currency
                                    currency: @custom_offer_item!currency!
                                    required: true

                        else
                            m '.control',
                                m MoneyInput, do
                                    disabled: !@override_price!
                                    currency: @custom_offer_item!currency!
                                    show_currency_edit: true
                                    value: @custom_offer_item!price_per_case!
                                    on_value: (price) ~> @custom_offer_item!price_per_case price
                                    on_value_currency: (currency) ~> @custom_offer_item!currency currency
                                    required: true

                                icon-button if @override_price! then 'ban-circle' else 'edit', {
                                    class: 'btn-default'
                                    onclick: ~>
                                        @override_price(!@override_price())
                                    title:
                                        if @override_price! then 'Click to follow the offer item\'s price.'
                                        else 'Click to override the price of the original offer item.'
                                }

                    # Price inputs.
                    m '.field' a do
                        m 'label' 'Purchase price'
                        # Render form fields for setting the price and
                        # currency for a custom product.
                        m '.control' a do
                            # Price input.
                            m MoneyInput, do
                                value: @custom_offer_item!purchase_price_per_case!
                                currency: @custom_offer_item!purchase_currency!
                                on_value: (price) ~> @custom_offer_item!purchase_price_per_case price
                                show_currency_edit: true
                                on_value_currency: (currency) ~>
                                    @custom_offer_item!purchase_currency currency
                                required: @is_manual!

                    inputs.number @custom_offer_item!delivery_period, do
                        label: 'Delivery period (weeks)'
                        min: 1
                        required: @is_manual!
                        disabled: @is_stock_item_from_pricelist!

                    if @is_manual!
                        inputs.number @custom_offer_item!quantity, {
                            label: 'Quantity (cases)'
                            min: 1
                            required: true
                        }
                    else
                        m '.field',
                            m 'label' 'Offer item quantity'
                            m '.control',
                                if @override_quantity!
                                    inputs.number @custom_offer_item!quantity
                                else
                                    inputs.number @custom_offer_item!offer_item_quantity, {
                                        disabled: true
                                    }

                                icon-button if @override_quantity! then 'ban-circle' else 'edit', do
                                    class: 'btn-default'
                                    onclick: ~>
                                        @override_quantity(!@override_quantity())
                                , do
                                    title:
                                        if @override_quantity! then 'Click to follow the offer item\'s quantity.'
                                        else 'Click to override the quantity of the original offer item.'


                    inputs.number @custom_offer_item!minimum_quantity, {
                        label: 'MOQ (cs)',
                        min: 1
                    }

                    inputs.number @custom_offer_item!maximum_quantity, {
                        label: 'Max Q (cs)',
                        min: 1
                    }

                # To render the item-tags-editor correctly, the item tags must be loaded in the data
                # model. Somehow, using `$m.common.observable.subscribe 'item_tags_loaded'` only works for
                # the add form, not for the edit forms. Directly checking the data model always works.
                if app.$m.data.item_tag.tags!length then [
                    item-tags-editor @custom_offer_item, do
                        editable_country_of_origin: false
                        only_tags: true
                        disabled: @is_stock_item_from_pricelist!
                ]

                m '.field-group no-fill' a do
                    m RelationDropDown, {
                        label: 'TBO supplier'
                        selected_relation_artkey: @custom_offer_item!supplier!artkey!
                        required: @is_manual!
                        disabled: @is_stock_item_from_pricelist!
                        get_all_for_drop_down_response$: RelationDropDownData.relations().pipe(
                            mergeAll(),
                            filter((relation) -> relation.is_supplier),
                            toArray(),
                        )
                        onchange: (supplier_artkey) ~> @custom_offer_item!supplier!artkey supplier_artkey
                    }


                    inputs.customs_status @custom_offer_item!customs_status, {
                        label: 'Customs status'
                        required: true
                        disabled: @is_stock_item_from_pricelist!
                    }

                    inputs.tax_label @custom_offer_item!tax_label, {
                        disabled: @is_stock_item_from_pricelist!,
                        label: 'Tax Label',
                    }


                    inputs.text @custom_offer_item!remark, {
                        label: 'Remark (internal)'
                        placeholder: 'Remark'
                    }

                    inputs.checkbox @custom_offer_item!is_special, {
                        checked: @always_special!,
                        disabled: @always_special!,
                        label: 'Special offer'
                    }


                if @error_messages!length > 0
                    m '.error-messages.alert.alert-danger.animated.fadeInUp',
                        m 'ul' @error_messages!map (message) -> m 'li' message

                text-button "#{if @is_editing! then 'Update' else 'Add'} custom offer item", do
                    class: 'btn-success btn-submit',
                    disabled: @saving!,
                    onclick: @save

            # Show the market panel.
            if @is_editing!
                m '.panel-body', [
                    m '.columns is-multiline', [
                        m '.column.is-6',
                            # Only show the offered items panel for non-tbo custom offer items.
                            m OfferItems,
                                offer_item_artkey: @custom_offer_item!offer_item_artkey!
                        m '.column.is-6',
                            m OfferHistory,
                                bottle_artkey: @custom_offer_item!bottle_artkey!
                                customs_status: @custom_offer_item!customs_status!
                                supplier_artkey: @supplier_artkey!
                        m '.column.is-12',
                            m BottleMarket, @bottle_market_data!
                            m BottleStock, @match_data!
                        m '.column.is-6',
                            m BottlePurchaseOrders, @match_data!
                        m '.column.is-6',
                            m BottleSalesOrders, @match_data!
                        m '.column.is-12',
                            if @supplier_artkey() then
                                m BottleSalesOrders,
                                    bottle_artkey: @custom_offer_item!bottle_artkey!
                                    current_client_artkey: @supplier_artkey!
                            else
                                m 'p' 'No relation selected to show sales orders for.'
                    ]
                ]
