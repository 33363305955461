m = require 'mithril'
{map, compact} = require 'prelude-ls'
{a, match-term-in, maybe-map, prepend} = require 'utils.ls'
api = require 'api.ls'

{icon-button, button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'


module.exports = class PortalTipsList
    (vnode) ->
        @entities = new Collection do
            api_function_name: 'portal.tip.get_tips'
            filter_function: @is-match
            query_limit: 25

        @search_input_ctrl = new SearchInput.controller do
            collection: @entities
            placeholder: 'Search for portal tip...'

    oncreate: ~>
        @entities.init!

    is-match: (tip, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            tip.title
            tip.content
        ]

    onremove: ~>
        @search_input_ctrl.onremove!

    mark_deleted: (artkey) ~>
        api.call 'portal.tip.mark_deleted' {artkey: artkey}, (resp) ~>
            if resp.success
                app.notifier.notify 'Successfully deleted tip', 'success'
                @entities.soft_delete(artkey)
            else
                app.$m.common.generic_error_handler!


    view: -> m '.c-portal-tips view' a do
        m '.btn-toolbar' a do
            button-with-icon 'Create Portal Tip', 'plus', do
                class: 'btn-default'
                onclick: ~> m.route.set '/portal/portal-tips/create'


        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @entities
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: 'portal_tips'
                onclick: (record, e) -->
                    m.route.set "/portal/portal-tips/#{record.artkey}/edit"
            columns:
                do
                    width: 3
                    name: 'Title'
                    field: 'title'
                    sort: true
                do
                    width: 10
                    name: 'Message'
                    field: 'content'
                    sort: true
                    ellipsis: true
                do
                    width: 2
                    name: 'Author'
                    field: 'creator.profile.name'
                    sort: true
                do
                    width: 1
                    header: ''
                    name: 'Actions'
                    function: (record) ~>
                        m '.btn-toolbar.no-click' m '.btn-group',
                            icon-button 'pencil', do
                                class: 'btn-default no-click'
                                onclick: ~> m.route.set "/portal/portal-tips/#{record.artkey}/edit"
                            icon-button 'remove', do
                                class: 'btn-danger no-click'
                                onclick: ~> @mark_deleted(record.artkey)
