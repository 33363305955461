import m from 'mithril'
import {api, config} from '@bitstillery/common/app'

export default class ExactAuth {
    async oninit() {
        const {result} = await api.post('exact.authentication.get_auth_url', {frontend_host: config.frontend_host}) as any
        if (result.url) {
            window.location = result.url
        }
    }

    view(_vn:m.Vnode<any>) {
        return <div className="view">
            <h2>One moment please</h2>
            <p>Please wait, redirecting you to Exact...</p>
        </div>
    }
}
