m = require 'mithril'

api = require 'api.ls'
{button-with-icon} = require '@/components/buttons.ls'
{icon} = require '@/components/icon.ls'
{query_and_set_lead} = require './domain.ls'
utils = require 'utils.ls'
{Lead} = require './models.ls'

inputs = require '@/components/inputs'
{Button, FieldVatId} = require '@bitstillery/common/components'
{conditional, invalid_fields, invalid_fields_format, password, required, validation, vat_id} = require '@bitstillery/common/lib/validation'
{european_union_countries} = require '@bitstillery/common/lib/countries'
# The and validator can't be imported direclty in livescript
validators = require '@bitstillery/common/lib/validation'
{Spinner} = require '@bitstillery/common/components'

{CountriesSelect, DropDownOption, DropDownWithSelect, empty_option} = require '@/components/html_components'
{UserDropDown} = require '@/components/users'
_app = require('@/app')


field = (label, input) ->
    inputs.field label, input, label_width=4, input_width=8


module.exports = class LeadEdit
    ->
        @loading = window.prop true
        @lead = window.prop new Lead
        @artkey = m.route.param 'artkey'

        query_and_set_lead(@artkey, @lead, @loading)

        this.$v = {
            country_code: validation([this.lead, 'country_code'], required()),
            lead_name: validation([this.lead, 'name'], required()),
            manager_artkey: validation([this.lead, 'manager.artkey'], required()),
            vat_id: validation([this.lead, 'vat_id'], conditional((~>
                country_code = this.lead!.country_code!
                return european_union_countries.includes(country_code)
            ), validators.and([required(), vat_id([this.lead, 'country_code'])]))),
        }

    submit: ~>
        lead_fields = [
            'artkey',
            'name',
            'company_type',
            'operates_online',
            'street_address',
            'zip_code',
            'city',
            'country_code',
            'vat_id',
            'emailaddress',
            'telephone_number',
            'url',
            'linkedin',
            'facebook',
            'instagram',
            'twitter',
        ]
        data = {}
        for field in lead_fields
            data[field] = @lead![field]!

        data['manager_artkey'] = @lead!manager!artkey!

        lead_contact_fields = [
            'first_name',
            'family_name',
            'position',
            'emailaddress',
            'telephone_number',
        ]
        data['contact_person'] = {}
        for field in lead_contact_fields
            data['contact_person'][field] = @lead!contact_persons![0][field]!

        lead_warehouse_fields = [
            'name',
            'excise_id',
            'excise_location_id',
            'street_address',
            'zip_code',
            'city',
            'country_code',
        ]
        data['warehouse'] = {}
        for field in lead_warehouse_fields
            data['warehouse'][field] = @lead!warehouses![0][field]!

        api.call-and-then 'crm.leads.edit', data, do
            success: (resp) ~>
                _app.notifier.notify 'Successfully updated lead.', 'success'
                m.route.set "/crm/leads/#{@lead!artkey!}"

    view: ->
        if @loading! then
            return m Spinner

        invalid = invalid_fields(this.$v)

        return m 'form.c-lead-edit view',
            m '.btn-toolbar',
                button-with-icon 'Back', 'arrow-left', do
                    onclick: ~> m.route.set "/crm/leads/#{@artkey}"
                    class: 'btn-default'
            m '.fieldset-group',
                m '.fieldset',
                    m '.fieldset-label' "General information"
                    inputs.text @lead!name, {
                        label: 'Lead name',
                        placeholder: 'Lead name'
                        validation: this.$v.lead_name
                    }

                    m UserDropDown, {
                        label: 'Manager',
                        selected_user_artkey: String(@lead!manager!artkey!)
                        onchange: (user_artkey) ~> @lead!manager!artkey(user_artkey)
                        validation: this.$v.manager_artkey
                    }

                    m CountriesSelect,
                        label: 'Country'
                        selected: @lead!country_code!
                        onchange: (value) ~> @lead!country_code value
                        validation: this.$v.country_code

                    m FieldVatId, {
                        country_code: @lead!country_code!,
                        disabled: !european_union_countries.includes(@lead!country_code!),
                        ref: [@lead, 'vat_id'],
                        validation: this.$v.vat_id,
                    }

                    m DropDownWithSelect,
                        label: 'Company type',
                        selected: @lead!company_type!
                        validation: this.$v.company_type,
                        empty_option: empty_option("Select Company type...")
                        onchange: (chosen) ~>
                            @lead!company_type chosen
                    ,
                        _app.$m.data.company_types.map((company) ->
                            m DropDownOption,
                                value: company
                                company
                            )

                m '.fieldset',
                    m '.fieldset-label' 'Address & media'
                    m '.field-group',
                        inputs.text @lead!city, {label: 'City'}
                    m '.field-group',
                        inputs.text @lead!street_address, {label: 'Address'}
                        inputs.text @lead!zip_code, {label: 'Zip code'}

                    inputs.text @lead!telephone_number, {label: 'Phone number', placeholder: '+31501234567'}
                    m '.field-group',
                        inputs.text @lead!url, {label: 'Website', placeholder: 'https://company.org'}
                        inputs.checkbox @lead!operates_online, {label: 'Operates a webshop'}

                    inputs.text @lead!emailaddress, {
                        label: 'Email address',
                    }

                    m '.field-group',
                        inputs.text @lead!linkedin, {
                            icon: 'fab fa-linkedin',
                            label: 'LinkedIn',
                            placeholder: 'linkedin.com/company/...',
                        }
                        inputs.text @lead!facebook, {
                            icon: 'fab fa-facebook',
                            label: 'Facebook',
                            placeholder: 'facebook.com/...'
                        }
                    m '.field-group',
                        inputs.text @lead!instagram, {
                            icon: 'fab fa-instagram',
                            label: 'Instagram',
                            placeholder: 'instagram.com/...'
                        }
                        inputs.text @lead!twitter, {
                            icon: 'fab fa-twitter',
                            label: 'Twitter',
                            placeholder: 'twitter.com/...'
                        }


            m '.fieldset-group',
                m '.fieldset',
                    let contact_person = @lead!contact_persons![0] then [
                        m '.fieldset-label' 'Contact info'
                        m '.field-group',
                            inputs.text contact_person.first_name, {label: 'First name'}
                            inputs.text contact_person.family_name, {label: 'Last name'}

                        inputs.text contact_person.position, {label: 'Position'}
                        inputs.text contact_person.emailaddress, {label: 'Email address'}
                        inputs.text contact_person.telephone_number, {label: 'Phone number'}
                    ]

                m '.fieldset',
                    let warehouse = @lead!warehouses![0] then [
                        m '.fieldset-label' 'Warehouse'
                        inputs.text warehouse.excise_id, {
                            label: 'Excise ID',
                            help: 'Unique identifier for this warehouse',
                            placeholder: 'e.g. NL00000123ABC',
                        }
                        inputs.text warehouse.excise_location_id, {label: 'Excise Location ID'}
                        inputs.text warehouse.name, {label: 'Name', placeholder: 'Warehouse name'}

                        m '.field-group',
                            m CountriesSelect,
                                label: 'Country',
                                placeholder: 'Select Warehouse country...'
                                selected: warehouse.country_code!
                                onchange: (value) ~> warehouse.country_code value

                            inputs.text warehouse.city, {label: 'City', placeholder: 'Warehouse city'}

                        m '.field-group',
                            inputs.text warehouse.street_address, {label: 'Address', placeholder: 'Warehouse address'}
                            inputs.text warehouse.zip_code, {label: 'Zip code', placeholder: 'Warehouse zip code'}
                    ]

            m '.c-submit',
                m Button, {
                    className: 'btn-submit',
                    icon: 'save',
                    disabled: invalid.length,
                    onclick: ~> this.submit()
                    text: 'Update Lead',
                    type: 'success',
                }

                invalid_fields_format(invalid)



