import {Observable, Subject} from 'rxjs'

import {Api, FactserverEmtpyRequest} from './api'

export interface GetContactPersonDropdownData {
    artkey: number
    name: string
    supplier_name: string
    portal_user_artkey: number
    is_deleted: boolean
}

export interface ContactPersonGetAllResponse {
    artkey: number
    name: string
    first_name: string
    family_name: string
    telephone_number: string
    mobile_telephone_number: string
    emailaddress: string
    salutation: string
    language: string
    has_portal_access: string
    should_receive_mails: string
    should_receive_purchase_enquiries: string
    position: string
    birthdate: string
}

export class ContactPersonApi {
    api = new Api()

    get_all_for_relation(relation_artkey: number): Observable<ContactPersonGetAllResponse[]> {
        return this.api.post_request('contact_persons.get_all', {
            supplier_artkey: relation_artkey,
        })
    }

    /** Subject used when data is not yet available. */
    drop_down_data$: Subject<GetContactPersonDropdownData[]> = new Subject<GetContactPersonDropdownData[]>()
    private result: GetContactPersonDropdownData[] = []

    get_contact_person_dropdown_data(): Subject<GetContactPersonDropdownData[]> {
        this.api
            .post_request<FactserverEmtpyRequest, GetContactPersonDropdownData[]>(
                'contact_persons.get_data_for_dropdown',
                {},
            )
            .subscribe({
                next: (response: GetContactPersonDropdownData[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
        return this.drop_down_data$
    }
}
