m = require 'mithril'
{stop-propagation} = require 'utils.ls'


export class Modal
    (vnode) ->
        {@persistent, @width} = vnode.attrs
        @close = vnode.attrs.onclose || ->

    overlay-click: ~>
        if not @persistent then @close!

    key-handler: (e) ~>
        if e.key-code == 27 then @close!

    c-modal-attrs: ~>
        attrs = {
            onmouseup: stop-propagation ->
        }
        if @width
            attrs['style'] = "width: #{@width}"
        return attrs

    oncreate: ->
        if not @persistent then
            document.body.add-event-listener 'keyup', @key-handler

    onremove: ->
        if not @persistent then
            document.body.removeEventListener 'keyup', @key-handler

    view: (vnode) ->
        m '',
            m '.c-modal-overlay'
            m '.l-modal-container' {onmouseup: stop-propagation @overlay-click},
                m '.c-modal' @c-modal-attrs!,
                    m '.c-modal-title',
                        if not @persistent then
                            m 'button' do
                                type: 'button'
                                class: 'close'
                                onclick: @close
                            ,
                            if vnode.attrs.onclose then m 'span' '×'

                        m 'h4' vnode.attrs.title

                    m '.c-modal-body', vnode.children

                    if footer = vnode.attrs.footer then
                        m '.c-modal-footer' footer
