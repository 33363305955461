import m from 'mithril'
import {
    $m as _$m,
    $s as _$s,
    $t as _$t,
    App,
    api as _api,
    logger as _logger,
    store as _store,
    events as _events,
    notifier as _notifier,
    telemetry as _telemetry,
    view as _view,
} from '@bitstillery/common/app'
import {current_account_slug, set_account_styles} from '@bitstillery/common/account/account'

import {models, ModelsDiscover} from '@/models/models'
import {persistent, volatile, session, StateDiscover} from '@/lib/state'
import i18n_tags from '@/locales/locales'
// eslint-disable-next-line import/order
import {DEFAULT_ROUTE, routes} from '@/routes'
import 'summernote/dist/summernote.js'

import {config as _config} from '@/lib/config'

// These are to be imported from common/app directly; but there
// here until livescript imports are properly fixed; e.g.
// rewritten to typescript and using ES-modules.
export const $t = _$t
export const api = _api
export const config = _config
export const events = _events
export const logger = _logger
export const notifier = _notifier
export const store = _store
export const telemetry = _telemetry
export const view = _view

// The real app-specific exports:
export const $m = Object.assign(_$m, models) as unknown as ModelsDiscover
export const $s = _$s as unknown as StateDiscover

store.load(persistent, volatile, session)

class AppDiscover extends App {

    init() {
        super.init(config, models, store, i18n_tags)
        const requested_account = /\?account=(.*)/.exec(window.location.toString())
        if (requested_account && requested_account.length === 2) {
            const url_params = new URLSearchParams(requested_account[0])
            const account = url_params.get('account')
            if (account) {
                $s.session.account.slug = account
                store.save()
            }
        }

        set_account_styles(current_account_slug())
        events.once('app.route', () => {
            const $app = document.querySelector('.app') as HTMLElement
            m.route($app, DEFAULT_ROUTE, routes)
        })

        if ($s.identity.token) {
            events.emit('identity.login')
        } else {
            events.emit('app.route')
        }
    }
}

export const app = new AppDiscover()
globalThis.app = app
app.init()

window.$ = window.jQuery = require('jquery')
window.m = require('mithril')

// Bootstrap components.
require('bootstrap-sass')
require('bootstrap-multiselect')
require('bootstrap-toggle')
