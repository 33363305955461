import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {DisplayMode, NotificationsTypeFilter} from './notifications'

import {PagedCollectionFetcher} from '@/components/collection/collection_table'
import {CheckBox} from '@/components/html_components'
import {FullOpenNotificationsResponse, Notification} from '@/factserver_api/notification_api'

interface NotificationsFilterProps {
    display_mode: DisplayMode
    switch_display_mode_to: (display_mode: DisplayMode) => void
    open_notifications_count: FullOpenNotificationsResponse | null
    notifications_fetcher: PagedCollectionFetcher<Notification>
}

enum NotificationsGlyphicons {
    SALES_ORDERS = 'glyphicon glyphicon-screenshot',
    PURCHASE_ORDERS = 'glyphicon glyphicon-shopping-cart',
    PRICE_LISTS = 'glyphicon glyphicon-globe',
    EMAIL_BATCHES = 'glyphicon glyphicon-envelope',
    PORTAL_USERS = 'glyphicon glyphicon-cog',
    SUPPLIERS = 'glyphicon glyphicon-briefcase',
    ITEMS = 'glyphicon glyphicon-home',
    OFFER_ITEMS = 'glyphicon glyphicon-piggy-bank',
    OFFERS = 'glyphicon glyphicon-piggy-bank',
    LEADS = 'glyphicon glyphicon-user',
    ITEM_MUTATIONS = 'glyphicon glyphicon-piggy-bank',
    CONTACT_PERSONS = 'glyphicon glyphicon-user',
}

export class NotificationsFilter extends MithrilTsxComponent<NotificationsFilterProps> {
    selected_notification_type_filters: string[] = []
    notifications_fetcher: PagedCollectionFetcher<Notification>

    constructor(vnode: m.Vnode<NotificationsFilterProps>) {
        super()

        this.notifications_fetcher = vnode.attrs.notifications_fetcher
    }

    open_notifications_for(
        notification_type: NotificationsTypeFilter,
        open_notifications: FullOpenNotificationsResponse | null,
    ): number {
        if (notification_type === NotificationsTypeFilter.SALES_ORDERS) {
            return open_notifications?.open_notifications_sales_orders || 0
        } else if (notification_type === NotificationsTypeFilter.PURCHASE_ORDERS) {
            return open_notifications?.open_notifications_purchase_orders || 0
        } else if (notification_type === NotificationsTypeFilter.EMAIL_BATCHES) {
            return open_notifications?.open_notifications_email_batches || 0
        } else if (notification_type === NotificationsTypeFilter.PRICE_LISTS) {
            return open_notifications?.open_notifications_price_lists || 0
        } else if (notification_type === NotificationsTypeFilter.SUPPLIERS) {
            return open_notifications?.open_notifications_suppliers || 0
        } else if (notification_type === NotificationsTypeFilter.PORTAL_USERS) {
            return open_notifications?.open_notifications_portal_users || 0
        } else if (notification_type === NotificationsTypeFilter.ITEMS) {
            return open_notifications?.open_notifications_items || 0
        } else if (notification_type === NotificationsTypeFilter.OFFERS) {
            return open_notifications?.open_notifications_offers || 0
        } else if (notification_type === NotificationsTypeFilter.LEADS) {
            return open_notifications?.open_notifications_leads || 0
        } else if (notification_type === NotificationsTypeFilter.CONTACT_PERSONS) {
            return open_notifications?.open_notifications_contact_persons || 0
        }
        return 0
    }

    toggle_notification_type_filter(notification_type: string): void {
        if (this.selected_notification_type_filters.includes(notification_type)) {
            this.selected_notification_type_filters = this.selected_notification_type_filters.filter(
                (notification_type_filter) => notification_type_filter !== notification_type,
            )
        } else {
            this.selected_notification_type_filters.push(notification_type)
        }
        this.notifications_fetcher.filters['notification_types'] = this.selected_notification_type_filters.join(',')
        this.notifications_fetcher.reset_and_query()
    }

    view(vnode: m.Vnode<NotificationsFilterProps>): m.Children {
        return (
            <span className="c-notifications-filter">
                <div className="options">
                    <div
                        className={classes('option', {
                            active: vnode.attrs.display_mode === DisplayMode.NOTIFICATIONS,
                        })}
                        onclick={() => vnode.attrs.switch_display_mode_to(DisplayMode.NOTIFICATIONS)}
                    >
                        <a>
                            <i className={'fa fa-inbox'} /> Notifications
                        </a>
                    </div>
                    <div
                        className={classes('option', {
                            active: vnode.attrs.display_mode === DisplayMode.SEEN_NOTIFICATIONS,
                        })}
                        onclick={() => vnode.attrs.switch_display_mode_to(DisplayMode.SEEN_NOTIFICATIONS)}
                    >
                        <a>
                            <i className="glyphicon glyphicon-eye-open" /> Seen notifications
                        </a>
                    </div>
                    <div
                        className={classes('option', {
                            active: vnode.attrs.display_mode === DisplayMode.BLOCKED_NOTIFICATIONS,
                        })}
                        onclick={() => vnode.attrs.switch_display_mode_to(DisplayMode.BLOCKED_NOTIFICATIONS)}
                    >
                        <a>
                            <i className="glyphicon glyphicon-volume-off" /> Blocked notifications
                        </a>
                    </div>
                    <div
                        className={classes('option', {
                            active: vnode.attrs.display_mode === DisplayMode.SNOOZED_NOTIFICATIONS,
                        })}
                        onclick={() => vnode.attrs.switch_display_mode_to(DisplayMode.SNOOZED_NOTIFICATIONS)}
                    >
                        <a>
                            <i className="glyphicon glyphicon-time" /> Snoozed notifications
                        </a>
                    </div>
                </div>
                <hr />
                {Object.keys(NotificationsTypeFilter).map((key) => {
                    const notification_key = key as keyof typeof NotificationsTypeFilter
                    const notification_type = NotificationsTypeFilter[key as keyof typeof NotificationsTypeFilter]
                    const glyphicon = NotificationsGlyphicons[key as keyof typeof NotificationsGlyphicons]
                    const count_for_type = this.open_notifications_for(notification_type, vnode.attrs.open_notifications_count)
                    return (
                        <div className="filter-option">
                            <CheckBox
                                checked={this.selected_notification_type_filters.includes(notification_key)}
                                disabled={this.notifications_fetcher.is_fetching}
                                onchange={() => this.toggle_notification_type_filter(notification_key)}
                                id={`${notification_type}-sales-order-notification-filter`}
                            >
                                <div>
                                    <i className={glyphicon} /> {notification_type}
                                </div>
                            </CheckBox>
                            {count_for_type > 0 && <span class="label label-info">
                                {count_for_type}
                            </span>}
                        </div>
                    )
                })}
            </span>
        )
    }
}
