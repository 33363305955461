m = require 'mithril'
{concat, join, or-list} = require 'prelude-ls'
{account-icon} = require 'accounts.ls'
{current_account_slug} = require '@bitstillery/common/account/account'
api = require 'api.ls'
{RelationDropDownData} = require '@/factserver_api/relation_api'
{button-with-icon} = require '@/components/buttons.ls'
{Collection} = require '@/components/collection/collection.ls'
{classes} = require '@bitstillery/common/lib/utils'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{continents, countries} = require '@bitstillery/common/lib/countries'
{icon-with-popover} = require '@/components/icon.ls'
inputs = require '@/components/inputs'
{languages} = require '@/components/languages.ls'
FilterSidebar = require 'offer/components/relation_selection_sidebar.ls'
{ProcessManageOffer} = require '@/offer/offers/processes'
{Offer} = require '@/models/offers'
{EmailProcessFlow, EmailBatch, EmailBatchType} = require './models.ls'
app = require('@/app')


module.exports = class RecipientSelection
    (vnode) ->
        @email_batch_artkey = m.route.param 'artkey'

        @offer = window.prop new Offer
        @offer_artkey = m.route.param 'offer'
        if @offer_artkey
            @query_offer @offer_artkey

        @recipient_list = []

        @relations = new Collection do
            api_function_name: 'pricelistbatch.get_suppliers'
            filter_function: @is_match
            sort_order: [
                {'name': 'name', 'direction': 'asc'}
            ]
            default_sort_by: 'name'
            default_sort_order: 'asc'
            additional_params: @additional_params

        # Initialize relation filters with their default values.
        if typeof @relations.filters == 'undefined'
            @relations.filters = {
                'sales_account': window.prop [current_account_slug!]
                'buyers': window.prop 'true'
                # Preselect 'Client' and 'Prospect' so 'Low Potential' is left out by default.
                'client_status': window.prop ['Client', 'Prospect']
                'company_type': window.prop []
                'currency': window.prop []
                'customs_status_visibility': window.prop []
                'language': window.prop []
                'portal_level': window.prop []
                'price_list_frequency': window.prop []
                'purchase_manager': window.prop []
                'relation_filter': window.prop 'all'
                'sales_manager': window.prop []
                'suppliers': window.prop ''
                'active_sales_promotions': window.prop []
                'active_vouchers_artkeys': window.prop []
                'operates_online': window.prop ''
                'should_receive_offer_mails': window.prop 'true'
                'should_receive_purchase_mails': window.prop ''
            }
            for continent_code in Object.keys continents
                @relations.filters["countries_per_continent_#{continent_code}"] = window.prop []

        @search_input_ctrl = new SearchInput.controller do
            collection: @relations
            placeholder: 'Search for relation, country, sales manager and status'
            autocomplete: true
            suggestions: []

        @email_batch = new EmailBatch

        api.call-and-then 'email.batch.get' {offer_email_batch_artkey: @email_batch_artkey, include_suppliers: true}, do
            success: (resp) ~>
                @email_batch.from_js resp.result
                for supplier in @email_batch.suppliers!
                    @recipient_list.push supplier.artkey

                # (Re-)query the relations now that we have the context of the email batch.
                @relations.query!
            failure: (resp) ->
                app.notifier.notify 'Unknown batch.', 'danger'
                m.route.set '/offer/email'



        app.$m.common.observable.subscribe 'suppliers_updated', @, @relations.requery
        RelationDropDownData.names().subscribe((names) ~> @search_input_ctrl.set_suggestions(names))

    additional_params: ~>
        # Join the separate country filters per continent into one parameter
        # for the factserver.
        all_selected_countries = concat [@relations.filters["countries_per_continent_#{code}"]! for code in Object.keys continents]

        return do
            offer_email_batch_artkey: @email_batch_artkey
            sales_account: @relations.filters['sales_account']!
            buyers: @relations.filters['buyers']!
            client_status: @relations.filters['client_status']!
            company_type: @relations.filters['company_type']!
            country: all_selected_countries
            currency: @relations.filters['currency']!
            customs_status_visibility: @relations.filters['customs_status_visibility']!
            language: @relations.filters['language']!
            portal_level: @relations.filters['portal_level']!
            price_list_frequency: @relations.filters['price_list_frequency']!
            purchase_manager: @relations.filters['purchase_manager']!
            relation_filter: @relations.filters['relation_filter']!
            sales_manager: @relations.filters['sales_manager']!
            suppliers: @relations.filters['suppliers']!
            active_sales_promotions: @relations.filters['active_sales_promotions']!
            active_vouchers_artkeys: @relations.filters['active_vouchers_artkeys']!
            operates_online: @relations.filters['operates_online']!
            should_receive_offer_mails: @relations.filters['should_receive_offer_mails']!
            should_receive_purchase_mails: @relations.filters['should_receive_purchase_mails']!

    get_process_flow: ~>
        if @offer_artkey
            return EmailProcessFlow.OFFERS
        else
            return EmailProcessFlow.MAILS

    is_match: (relation, term) ~>
        return or-list [
            relation.name.toLowerCase!indexOf(term) > -1,
            relation.sales_manager.profile.name.toLowerCase!indexOf(term) > -1,
            (relation.company_type || '').toLowerCase!indexOf(term) > -1,
            (relation.client_status || '').toLowerCase!indexOf(term) > -1,
            (countries[relation.country_code] || '').toLowerCase!indexOf(term) > -1,
        ]

    check_relation: (supplier_artkey) ~>
        if supplier_artkey not in @recipient_list
            @recipient_list.push supplier_artkey
        else
            index = @recipient_list.indexOf(supplier_artkey)
            if(index > -1)
                @recipient_list.splice(index, 1)
        m.redraw!

    save: ~>
        data = do
            offer_email_batch_artkey: @email_batch.artkey! or null
            suppliers: @recipient_list
        api.call 'pricelistbatch.set_suppliers' data, @handle_save

    handle_save: (result) ~>
        if not result.success
            app.notifier.notify result.message, 'danger'
        else
            app.notifier.notify 'Successfully updated recipient.', 'success'
            if @get_process_flow! == EmailProcessFlow.MAILS then
                m.route.set "/crm/email/#{@email_batch_artkey}"
            else
                m.route.set "/offer/offers/#{@offer_artkey}/email/#{@email_batch_artkey}/email-send"
    query_offer: (artkey) ~>
        data = {artkey}
        api.call-and-then 'offer.get_offer' data, do
            success: (resp) ~>
                @offer new Offer resp.result
            failure: ~>
                app.notifier.notify 'Unknown offer.', 'danger'
                m.route.set '/offer/offers'

    # Sorts the list of relations on basis of which relations are checked.
    sort_by_checked: ~>
        # Populate the collection with a 'checked' attribute.
        for item in @relations.items!
            item.checked = item.artkey in @recipient_list
        # Tell the collection to sort by this new 'checked' attribute.
        @relations.sort_by 'checked'
        # Reverse the sort direction of the collection.
        @relations.ascending !@relations.ascending!
        # Execute the collection's filter_items function, which actually
        # performs the sorting.
        @relations.filter_items!

    toggle_all_relations: (value) ~>
        if value?
            if value
                # Check all relations in the search result. Note that we don't
                # clear the @relation_list, so that any previously checked
                # relations remain checked.
                for relation in @relations.search_result!
                    if relation.artkey not in @recipient_list
                        @recipient_list.push relation.artkey

            else
                # Uncheck all relations in the search result. Relations
                # outside the search result keep being checked in the
                # background.
                for relation in @relations.search_result!
                    index = @recipient_list.indexOf(relation.artkey)
                    if index > -1
                        @recipient_list.splice(index, 1)

        else
            # Check if the 'select all'-checkbox must be checked. If there is
            # no search result, or if (at least) one relation in the search
            # result is not checked, the checkbox must be unchecked.
            if @relations.search_result!length == 0 then return false
            for relation in @relations.search_result!
                if relation.artkey not in @recipient_list
                    return false

            # Otherwise, the checkbox must be checked.
            return true

    view: -> m classes('.c-mail-recipient-selection', 'view', {process: @get_process_flow! == EmailProcessFlow.OFFERS}),
        # Offers flow: show process indicator, title and subtitle.
        if @get_process_flow! == EmailProcessFlow.OFFERS
            [
                m ProcessManageOffer, {
                    active: 'email_recipients',
                    context: {
                        offer_artkey: @offer_artkey
                        email_batch_artkey: @email_batch_artkey
                    }
                }
            ]
        # Mails flow: show back to recipients-link and title.
        else if @get_process_flow! == EmailProcessFlow.MAILS
            [
                m '.btn-toolbar',
                    m 'button.btn.btn-default' do
                        type: 'button'
                        onclick: ~> m.route.set "/crm/email/#{@email_batch_artkey}"
                    , (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to email recipients list'
            ]

        m '.filter-sidebar-wrapper step-content',
            m FilterSidebar, do
                relations: @relations

            m '.filter-result',
                m '.row.mb-1',
                    m '.col-lg-4' SearchInput.view @search_input_ctrl
                    m '.col-lg-8.btn-toolbar',
                        button-with-icon 'Save selection', 'save', do
                            class: 'btn-success'
                            onclick: ~> @save!

                m '.mail.recipients',
                    inputs.checkbox(@toggle_all_relations, {
                        label: 'All relations',
                        help: 'Select all relations in the filter result'
                    })

                    m CollectionTable, do
                        collection: @relations
                        options:
                            search_table_style: true
                            sticky_header: true
                            with_buttons: true
                            autoscale: true
                            unique_name: 'email_relations'
                        columns:
                            do
                                width: 2
                                name: 'Make recipient'
                                field: 'checked'
                                sort_function: @sort_by_checked
                                default_visible: true
                                function: (record) ~>
                                    m '',
                                        m 'input' {
                                            type: 'checkbox'
                                            id: record.artkey
                                            onclick: ~>
                                                @check_relation record.artkey
                                            checked: record.artkey in @recipient_list
                                        }
                                        m 'label' {
                                            for: record.artkey
                                        }
                            do
                                width: 8
                                name: 'Relation'
                                field: 'name'
                                sort: true
                                ellipsis: true
                                default_visible: true
                                function: (relation) -> [
                                    m 'span.mr-05' account-icon relation.sales_account
                                    relation.name
                                ]
                            do
                                width: 6
                                name: 'Location'
                                field: 'country_code'
                                sort: true
                                default_visible: true
                                function: (record) ~>
                                    m '',
                                        countries[record.country_code] + ', ' + record.city
                            do
                                width: 4
                                name: 'Languages'
                                field: 'contact_languages'
                                sort: true
                                default_visible: true
                                function: (record) ~>
                                    # Determine the set of languages of contacts.
                                    language_list = []
                                    for language in record.contact_languages
                                        if language
                                            language_list.push languages[language]
                                        else
                                            language_list.push '-'

                                    # Determine the text to display.
                                    if language_list
                                        join ', ' language_list.sort!
                                    else
                                        '-'
                            do
                                width: 4
                                name: 'Sales manager'
                                field: 'sales_manager.profile.name'
                                sort: true
                                default_visible: true
                            do
                                width: 2
                                name: 'Portal level'
                                field: 'portal_level'
                                sort: true
                                default_visible: true
                            do
                                width: 2
                                name: 'Emailed'
                                field: 'offer_already_emailed'
                                sort: true
                                function: (record) ~>
                                    if record.offer_already_emailed
                                        icon-with-popover do
                                            icon-id: 'glyphicon-envelope'
                                            title: 'Already emailed'
                                            content: "The offer in this email batch has already been emailed to #{record.name}."
                            do
                                width: 1
                                header: ''
                                name: 'Column selector'
                                value: ' '
