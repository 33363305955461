import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Modal} from 'components/modal/modal.ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, events} from '@bitstillery/common/app'

import {EditAssist} from '../components/form'
import ChangePassword from '../data/user_manage/change_password'
import OtpDialog from '../data/user_manage/otp_dialog'

import {$s} from '@/app'
import {ManualStatusLogRequest} from '@/factserver_api/user_management_api'
import {UserManagementApi} from '@/factserver_api/user_management_api'
import {DefaultButton} from '@/components/buttons'
import {DangerButton} from '@/components/buttons'
import {UserSchedule} from '@/data/user_manage/user_schedule'
import UserDetails from '@/data/user_manage/user_details'

export default class MyDiscoverProfile extends MithrilTsxComponent<any> {

    api: UserManagementApi
    edit_assist: EditAssist
    is_loading = false
    is_self = false

    data = proxy({
        dialog: {
            otp: false,
            sick: false,
        },
        tab: '',
    })

    update_user_request = {
        artkey: undefined,
        name: '',
        is_trader: false,
        is_purchaser: false,
        is_crm_manager: false,
        is_stock_manager: false,
        is_superuser: false,
        is_compensable: false,
        decimal_locale: 'en',

        is_deleted: false,
        profile: {
            name: '',
            emailaddress: '',
            address: '',
            telephone_number: '',
            mobile_telephone_number: '',
        },
        trading_countries: [],
        user_accounts: [],

        schedule_monday: null,
        schedule_tuesday: null,
        schedule_wednesday: null,
        schedule_thursday: null,
        schedule_friday: null,

        daily_travel_distance: 0,
    }

    constructor() {
        super()
        this.api = new UserManagementApi()
        this.edit_assist = new EditAssist(m.route)
        this.edit_assist.is_creating = false

        const pathname = m.parsePathname(m.route.get())
        const params = Object.fromEntries(new URLSearchParams(pathname.params))
        this.data.tab = params.tab ? params.tab : 'details'
    }

    oninit() {
        this.poll_user_artkey()
    }

    poll_user_artkey() {
        if (!$s.identity.user.artkey) {
            setTimeout(() => {
                this.poll_user_artkey()
            }, 100)
            return
        }
        this.fetch_user($s.identity.user.artkey)
    }

    check_disabled(allow_if_self = false): boolean {
        // Fields none super_user can edit on their own profile
        if (allow_if_self) {
            return false
        }

        return true
    }

    fetch_user(artkey: number): void {
        this.is_loading = true
        this.api.get_user(artkey).subscribe({
            next: (response) => {
                this.update_user_request = {
                    ...response,
                    trading_countries: response.trading_countries.map((entry) => entry.country_code),
                    user_accounts: response.user_accounts.map((entry) => entry.account.slug),
                }
                this.is_loading = false
                this.is_self = true
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                this.is_self = false
                m.redraw()
            },
        })
    }

    set_user_sick(): void {
        const set_sick_request: ManualStatusLogRequest = {
            user_artkey: this.update_user_request.artkey,
            status: 'SICK',
        }

        this.api.manual_schedule_status_log(set_sick_request).subscribe({
            next: () => {
                events.notifier.notify($t('notifications.sick_updated'), 'success')
                m.redraw()
            },
        })
    }

    set_tab(tabName) {
        this.data.tab = tabName
        m.route.set(`/my-discover/profile?tab=${tabName}`)
    }

    view(): m.Children {
        return (
            <div className="c-my-discover-profile view">
                <div className="c-tabs">
                    <ul className="nav nav-tabs">
                        <li className={classes('nav-link', {active: this.data.tab === 'details'})}>
                            <a onclick={() => this.set_tab('details')}>{$t('profile.tabs.details')}</a>
                        </li>
                        <li className={classes('nav-link', {active: this.data.tab === 'password'})}>
                            <a onclick={() => this.set_tab('password')}>{$t('profile.tabs.security')}</a>
                        </li>
                        <li className={classes('nav-link', {active: this.data.tab === 'schedule'})}>
                            <a onclick={() => this.set_tab('schedule')}>{$t('profile.tabs.schedule')}</a>
                        </li>
                    </ul>

                    <div className={classes('c-tab', {active: this.data.tab === 'details'})}>
                        <div className="btn-toolbar">
                            <DangerButton
                                title={$t('profile.actions.call_sick')}
                                icon_class={'glyphicon glyphicon-earphone'}
                                onclick={() => (this.data.dialog.sick = true)}
                            />
                            <DefaultButton
                                additional_class="btn-danger"
                                title={$t('profile.actions.reset_otp')}
                                icon_class={'glyphicon glyphicon-refresh'}
                                onclick={() => this.data.dialog.otp = true}
                            />
                        </div>

                        <UserDetails
                            admin_view={false}
                            api={this.api}
                            update_user_request={this.update_user_request}
                            edit_assist={this.edit_assist}
                            is_self={true}
                            check_disabled={this.check_disabled.bind(this)}
                            LIST_ROUTE='/my-discover/profile'
                        />
                    </div>

                    <div className={classes('c-tab', {active: this.data.tab === 'password'})}>
                        <ChangePassword />
                    </div>

                    <div className={classes('c-tab', {active: this.data.tab === 'schedule'})}>
                        {this.update_user_request.artkey && <UserSchedule
                            api={this.api}
                            edit_assist={this.edit_assist}
                            update_user_request={this.update_user_request}
                            check_disabled={this.check_disabled.bind(this)}
                        />}
                    </div>
                </div>

                {this.data.dialog.sick && (
                    <Modal title={$t('profile.actions.call_sick')} onclose={() => (this.data.dialog.sick = false)}>
                        {(() => {
                            if (this.is_self) {
                                return <p>{$t('profile.sick.description.self')}</p>
                            }

                            return <p>{$t('profile.sick.description.other')}</p>
                        })()}
                        <div className="btn-toolbar">
                            <DefaultButton
                                title={$t('profile.actions.cancel')}
                                onclick={() => (this.data.dialog.sick = false)}
                            />
                            <DangerButton
                                title={$t('profile.actions.call_sick')}
                                icon_class={'glyphicon glyphicon-earphone'}
                                onclick={() => {
                                    this.set_user_sick()
                                    this.data.dialog.sick = false
                                }}
                            />
                        </div>
                    </Modal>
                )}

                <OtpDialog
                    show={[this.data.dialog, 'otp']}
                    update_user_request={this.update_user_request}
                />
            </div>
        )
    }
}
