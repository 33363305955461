import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {accountIcon} from 'accounts.ls'
import {Amount} from '@bitstillery/common/components'

import {
    DateRange,
    DifferenceKeys,
    formatted_date_string_for_size,
    RelationType,
    StatisticsOptions,
} from '../statistics'

import {$s} from '@/app'
import {
    ProductStatisticsResponse,
    RelationStatisticsDataResponse,
    StatisticsDataResponse,
    StatisticsDateRangeResponse,
} from '@/market/statistics/statistics_api'

interface StatisticsTableAttrs {
    totals: StatisticsDateRangeResponse
    statistics_data: StatisticsDataResponse[]
    options: StatisticsOptions
    difference_keys: DifferenceKeys
}

export class StatisticsTable extends MithrilTsxComponent<StatisticsTableAttrs> {
    options: StatisticsOptions
    difference_keys: DifferenceKeys

    constructor(vn: m.Vnode<StatisticsTableAttrs>) {
        super()
        this.options = vn.attrs.options
        this.difference_keys = vn.attrs.difference_keys
    }

    _get_set_headers() {
        if (!this.options.relation_artkey) {
            return [
                <th>Rel. nr</th>,
                <th>Relation name</th>,
            ]
        }
        else {
            return [
                <th>Product name</th>,
                <th>Btl / cs</th>,
                <th>Size</th>,
                <th>Ref</th>,
                <th>Alc %</th>,
                <th>GB</th>,
                <th>Tax label</th>,
            ]
        }
    }

    _get_recurring_headers_for_type() {
        const headers = [
            <th className={'border-cell'}>Cases</th>,
            <th>Total value</th>,
        ]

        if (this.options.type === RelationType.SALES) {
            headers.push(<th>Margin</th>, <th>Margin %</th>)
        }
        return headers
    }

    _get_difference_headers_for_type() {
        if (this.options.date_ranges.length <= 1) {
            return []
        }

        const headers = [
            <th className={'border-cell'}>Value diff.</th>,
            <th>Value %</th>,
        ]

        if (this.options.type === RelationType.SALES) {
            headers.push(<th>Margin diff.</th>)
        }
        return headers
    }

    _get_row_columns(
        row: StatisticsDataResponse,
        recurring_headers_length: number,
        diff_headers_length: number,
    ) {
        let columns: JSX.Element[] = []

        if (this.options.relation_artkey) {
            this._add_row_product_columns(columns, row as ProductStatisticsResponse)
        } else {
            this._add_row_relation_columns(columns, row as RelationStatisticsDataResponse)
        }

        this.options.date_ranges.forEach((date_range: DateRange) => {
            this._get_row_date_range_columns(row, date_range, recurring_headers_length).map((column: JSX.Element) => { columns.push(column) })
        })

        this._get_row_diff_columns(row, diff_headers_length).map((column: JSX.Element) => { columns.push(column) })

        return columns
    }

    _add_row_product_columns(
        columns: JSX.Element[],
        row: ProductStatisticsResponse,
    ) {
        columns.push(
            <td>{row.product_name}</td>,
            <td>{row.number_of_bottles}</td>,
            <td>{row.volume}</td>,
            <td>{row.refill_status}</td>,
            <td>{row.alcohol_percentage}</td>,
            <td>{row.gift_box_type}</td>,
            <td>{row.tax_label}</td>,
        )
    }

    _add_row_relation_columns(
        columns: JSX.Element[],
        row: RelationStatisticsDataResponse,
    ) {
        columns.push(
            <td>{row.relation_nr}</td>,
            <td className={'ellipsis'}>
                {accountIcon({slug: row.relation_account_slug, name: row.relation_account_name})}
                {row.relation_name}
            </td>,
        )
    }

    _get_row_date_range_columns(
        row: StatisticsDateRangeResponse,
        date_range: DateRange,
        recurring_headers_length: number,
    ) {
        const date_data = row[`${date_range.date}_${date_range.to_date}`]
        if (!date_data) { return this._empty_data_block(recurring_headers_length) }

        const columns = [
            <td className={'border-cell'}>{date_data.number_of_cases}</td>,
            <td><Amount amount={date_data.total_value} currency={$s.currencies.default}/></td>,
        ]

        if (date_data.total_margin !== undefined) {
            const margin_percentage = Number(date_data.total_margin / date_data.total_value * 100).toFixed(1)

            columns.push(
                <td><Amount amount={Number(date_data.total_margin).toFixed(2)} currency={$s.currencies.default}/></td>,
                <td>{+margin_percentage && isFinite(+margin_percentage) ? `${margin_percentage}%` : '-'}</td>,
            )
        }

        return columns
    }

    _get_row_diff_columns(
        row: StatisticsDateRangeResponse,
        difference_header_length: number,
    ) {
        if (difference_header_length === 0) {
            return []
        }

        const columns: JSX.Element[] = []
        const left_date_data = row[this.difference_keys.left]
        const right_date_data = row[this.difference_keys.right]

        if (left_date_data && right_date_data) {
            const value_difference = Number((right_date_data.total_value - left_date_data.total_value) / left_date_data.total_value * 100).toFixed(1)
            columns.push(
                <td className={'border-cell'}>
                    <Amount amount={right_date_data.total_value - left_date_data.total_value} currency={$s.currencies.default}/>
                </td>,
                <td>{+value_difference && isFinite(+value_difference) ? `${value_difference}%` : '-'}</td>,
            )

            if (right_date_data.total_margin !== undefined && left_date_data.total_margin !== undefined) {
                columns.push(<td><Amount amount={right_date_data.total_margin - left_date_data.total_margin} currency={$s.currencies.default}/></td>)
            }
        } else {
            columns.push(<td className={'border-cell'}>-</td>, <td>-</td>)

            // Practically the same as the margin check, but we are missing either the left or right data here
            if (this.options.type === RelationType.SALES) {
                columns.push(<td>-</td>)
            }
        }

        return columns
    }

    _empty_data_block(recurring_headers_length: number) {
        const empty_block = Array(recurring_headers_length).fill(<td>-</td>)
        empty_block[0] = <td className={'border-cell'}>-</td>
        return empty_block
    }

    _get_totals_row(
        totals: StatisticsDateRangeResponse,
        set_headers: JSX.Element[],
        recurring_headers_length: number,
        diff_headers_length: number,
    ) {
        const columns: JSX.Element[] = []
        set_headers.forEach(() => { columns.push(<td />) })
        columns[0] = <td><b>Total</b></td>

        this.options.date_ranges.forEach((date_range: DateRange) => {
            this._get_row_date_range_columns(totals, date_range, recurring_headers_length).map((column: JSX.Element) => { columns.push(column) })
        })

        this._get_row_diff_columns(totals, diff_headers_length).map((column: JSX.Element) => { columns.push(column) })

        return columns
    }

    _date_range_header_row(set_length: number, recurring_length: number, diff_length: number) {
        const columns: JSX.Element[] = [
            <th colSpan={set_length}>{this.options.type} value per {this.options.relation_artkey ? 'product' : 'relation'}</th>,
        ]

        this.options.date_ranges.map((date_range: DateRange) => {
            columns.push(<th className={'border-cell'} colSpan={recurring_length}>{
                formatted_date_string_for_size(date_range, this.options.date_picker_size)
            }</th>)
        })

        if (diff_length) {
            columns.push(<th className={'border-cell'} colSpan={diff_length}>Difference</th>)
        }

        return columns
    }

    view(vn: m.Vnode<StatisticsTableAttrs>): m.Children {
        const set_headers = this._get_set_headers()
        const recurring_headers = this._get_recurring_headers_for_type()
        const difference_headers = this._get_difference_headers_for_type()

        return <div className={'c-panel'}>
            <div className={'panel-heading'}>
                <div className={'panel-title'}>{this.options.type}</div>
            </div>
            <table className={'panel-body stats-table'}>
                <thead>
                    <tr className={'panel_header_row'}>
                        {this._date_range_header_row(
                            set_headers.length, recurring_headers.length, difference_headers.length,
                        )}
                    </tr>
                    <tr className={'panel_header_row'}>
                        {set_headers}
                        {this.options.date_ranges.map(() => {return recurring_headers})}
                        {difference_headers}
                    </tr>
                </thead>
                <tbody>
                    {this._get_totals_row(vn.attrs.totals, set_headers, recurring_headers.length, difference_headers.length)}
                    {vn.attrs.statistics_data.map((data: StatisticsDataResponse) => {
                        return (<tr>{this._get_row_columns(data, recurring_headers.length, difference_headers.length)}</tr>)
                    })}
                </tbody>
            </table>
        </div>
    }
}
