m = require 'mithril'
{head} = require 'prelude-ls'
api = require 'api.ls'
{a}: utils = require 'utils.ls'
{Amount} = require '@bitstillery/common/components'
discover_link = require '@/market/components/discover_link'
inputs = require '@/components/inputs'
{item-tags-editor} = require '../components/item_tags_editor.ls'
{ AutocompleteInput } = require '@/components/collection/autocomplete_input'
{Button} = require '@bitstillery/common/components'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
{Item, ItemMutationReason} = require '@/models/stock'
{Bottle} = require '@/models/bottles'
app = require('@/app')

export class ItemForm
    (vnode) ->
        {
            @item,
            @base_item_json,
            @new_items,
            @reason,
            @update_type,
        } = vnode.attrs

    add_item: ~>
        @new_items!push window.prop new Item @base_item_json!

    remove_item: (index) ~>
        @new_items!pop(index)

    view: (vnode) -> m '.panel.panel-default' a do
        m '.panel-heading' m '.panel-title' 'Updated stock'
        m '.panel-body' a do
            m 'table.table' a do
                m 'thead.thead-default',
                    m 'tr' a do
                        m 'th' '#'
                        m 'th' 'Intake'
                        m 'th' 'Warehouse'
                        m 'th' 'Lot #'
                        m 'th' 'Product'
                        m 'th' 'Btl / cs'
                        m 'th.number' 'Size'
                        m 'th.number' 'Alc %'
                        m 'th' 'Ref'
                        m 'th' 'GB'
                        m 'th.price' 'Purchase / cs'
                        m 'th.price' 'Cost / cs'
                        m 'th.number' 'Stock'
                        if vnode.attrs.show_sales
                            m 'th.number' 'Sales'
                        if vnode.attrs.show_available
                            m 'th.number' 'Avail.'
                        m 'th' 'Customs'
                        m 'th'
                if @item? and @item!artkey!
                    m 'tbody.table-row',
                        m 'tr' a do
                            m 'td' m m.route.Link, {target: '_blank', \
                                href: "/purchase-orders/manage/#{@item!purchase_order_item!purchase_order!reference!}"},
                                @item!purchase_order_item!purchase_order!reference!
                            m 'td' utils.format-date(@item!entry_date!) || '-'
                            m 'td.ellipsis' m 'span' @item!purchase_order_item!purchase_order!target_warehouse!name!
                            m 'td' @item!lot!
                            m 'td' @item!bottle!product!name!
                            m 'td.number' @item!number_of_bottles_per_case!
                            m 'td.number' @item!bottle!display_volume!
                            m 'td.number' @item!bottle!display_alcohol_percentage!
                            m 'td' @item!bottle!refill_status!
                            m 'td' @item!gift_box_type!
                            m 'td.price' m Amount, do
                                amount: @item!was_bought_for!
                                currency: @item!purchase_order_item!purchase_order!was_bought_in!
                            m 'td.price' m Amount, do
                                amount: @item!was_bought_for_plus_costs
                                currency: @item!purchase_order_item!purchase_order!was_bought_in!
                            m 'td.number' @item!number_of_cases_in_stock!
                            if vnode.attrs.show_sales
                                m 'td.number' @item!number_of_cases_in_sales!
                            if vnode.attrs.show_available
                                m 'td.number' @item!number_of_cases_available!
                            m 'td' @item!customs_status!
                            m 'td'

                # Show a form for each new item. Provide the mutation
                # reason via the options, so the form can base its logic
                # on that reason (i.e., when correcting the number of
                # bottles per case, don't update the target price).
                @new_items!map (item, index) ~>
                    m NewItemForm, do
                        item: item
                        index: index
                        remove_item: @remove_item
                        fields: vnode.attrs.fields
                        reason: @reason
                        update_type: @update_type

            # Don't show the create item link if we are correcting the number
            # of bottles per case.
            if @reason! != ItemMutationReason.CORRECT_BOTTLES_PER_CASE
                m Button, {
                    icon: 'plus',
                    onclick: @add_item,
                    text: 'Create item',
                    type: 'info',
                }


class NewItemForm
    (vnode) ->
        @item = vnode.attrs.item
        @reason = vnode.attrs.reason
        @remove_item = vnode.attrs.remove_item
        @update_type = vnode.attrs.update_type

        @product = window.prop @item!bottle!product!
        @bottles = window.prop []
        @case = window.prop []

        @original_number_of_bottles_per_case = window.prop @item!number_of_bottles_per_case!
        @original_gift_box_type = window.prop @item!gift_box_type!
        @original_tax_label = window.prop @item!tax_label!
        @original_bottle_artkey = window.prop @item!bottle!artkey!
        @original_was_bought_for = window.prop @item!was_bought_for!
        @original_was_bought_for_plus_costs = window.prop @item!was_bought_for_plus_costs!

        @query_bottles @item!bottle!product!artkey!
        @product_management_api = new ProductManagementApi()


    after_update_product: (selected_product) ~>
        if !selected_product
            @item!bottle_artkey ''
            @item!bottle new Bottle
            @bottles []
        else
            @product!artkey selected_product.artkey
            @product!name selected_product.name
            @query_bottles @product!artkey!

    after_update_bottle: ~>
        @item!bottle_artkey @item!bottle!artkey!
        @get_case!

    get_case: ~>
        if not @item!bottle!artkey!
            return
        data = do
            bottle_artkey: @item!bottle!artkey!
            search_exact_case: true
            number_of_bottles: @item!number_of_bottles_per_case!
            gift_box_type: @item!gift_box_type!
            tax_label: @item!tax_label!

        api.call2 'product_management.get_cases_for_bottle' data, (resp) ~>
            @case if resp.result.length > 0 then head resp.result else null
            @update_prices!

    update_prices: ~>
        # Mind your head!

        # If we are correcting the number of bottles per case, always use the
        # original target price, was_bought_for and was_bought_for_plus_costs.
        if @reason! == ItemMutationReason.CORRECT_BOTTLES_PER_CASE
            @item!was_bought_for @original_was_bought_for!
            @item!was_bought_for_plus_costs @original_was_bought_for_plus_costs!

        else
            # Otherwise, adjust was_bought_for and was_bought_for_plus_costs.
            # This keeps the price per bottle the same. Also, for
            # was_bought_for_plus_costs, the costs per case must stay the same,
            # so just use @item!was_bought_for! + costs there.
            @item!was_bought_for (@original_was_bought_for! *
                                  @item!number_of_bottles_per_case! /
                                  @original_number_of_bottles_per_case!)
            @item!was_bought_for_plus_costs (@item!was_bought_for! +
                                             @original_was_bought_for_plus_costs! -
                                             @original_was_bought_for!)

    query_bottles: (product_artkey) ~>
        data = {product_artkey: product_artkey}
        api.call2 'product_management.get_product_and_bottles', data, (resp) ~>
            @bottles app.$m.bottles.create_bottles resp.product.bottles

    view: (vnode) -> m 'tbody.table-row',
        m 'tr' a do
            m 'td' m m.route.Link, {target: '_blank', \
                href: "/purchase-orders/manage/#{@item!purchase_order_item!purchase_order!reference!}"},
                @item!purchase_order_item!purchase_order!reference!
            m 'td' utils.format-date(@item!entry_date!) || '-'
            m 'td.ellipsis' m 'span' @item!purchase_order_item!purchase_order!target_warehouse!name!
            m 'td' if 'lot' in vnode.attrs.fields
                   then inputs.text(@item!lot, {required: true})
                   else @item!lot!
            if 'product' in vnode.attrs.fields
                m 'td.col-sm-2',
                    m AutocompleteInput,
                        placeholder: "Select a product"
                        default_text: @product!name!
                        on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_products(filter_text)
                        suggestion_as_label: (simple_product) -> simple_product.name
                        on_selected: (simple_product) ~> @after_update_product(simple_product)
            else
                m 'td' @item!bottle!product!name!
            m 'td' {style: "width: 90px"} if 'number_of_bottles_per_case' in vnode.attrs.fields
                then
                    inputs.number @item!number_of_bottles_per_case,
                        min: 1
                        required: true
                        disabled: not @item!bottle!artkey!
                        after_update: @get_case
                else @item!number_of_bottles_per_case!
            if 'product' in vnode.attrs.fields
                m 'td' {colspan: 3} inputs.specs @bottles, @item!bottle,
                    after_update: @after_update_bottle
                    required: true
            else [
                m 'td.number' @item!bottle!display_volume!
                m 'td.number' @item!bottle!display_alcohol_percentage!
                m 'td' @item!bottle!refill_status!
            ]
            m 'td' if 'gift_box_type' in vnode.attrs.fields
                then
                    inputs.gift_box_type @item!gift_box_type,
                        disabled: not @item!bottle!artkey!
                        after_update: @get_case
                else @item!gift_box_type!
            m 'td.price' m Amount,
                amount: @item!was_bought_for!
                currency: @item!purchase_order_item!purchase_order!was_bought_in!
            m 'td.price' m Amount,
                amount: @item!was_bought_for_plus_costs
                currency: @item!purchase_order_item!purchase_order!was_bought_in!
            m 'td' {style: "width: 100px"} if 'stock' in vnode.attrs.fields
                then
                    inputs.number @item!number_of_cases_in_stock,
                        required: true
                        disabled: not @item!bottle!artkey!
                else
                    @item!number_of_cases_in_stock!
            if vnode.attrs.show_available
                m 'td.number' '-'
            if vnode.attrs.show_sales
                m 'td.number' '-'
            m 'td' if 'customs_status' in vnode.attrs.fields
                then
                    inputs.customs_status @item!customs_status,
                        disabled: not @item!bottle!artkey!
                        required: true
                else
                    @item!customs_status!
            m 'td.clickable', do
                # Do not show the remove icon if we are correcting the number of
                # bottles per case.
                if @reason! != ItemMutationReason.CORRECT_BOTTLES_PER_CASE
                    m 'span.glyphicon.glyphicon-remove-sign' {onclick: @remove_item.bind(@, vnode.attrs.index)}

        # Only show the additional items field in an update product mutation.
        if @update_type != 'correct-bottles'
            m 'tr' m 'td' {colspan: '100%'} a do
                m '.panel.panel-default' m '.panel-body' a do
                    m 'form.flex-form',
                        m '.fieldset',
                            inputs.tax_label @item!tax_label, {
                                after_update: @get_case,
                                label: 'Tax Label'
                            }

                            item-tags-editor @item
