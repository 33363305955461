import {events} from '@bitstillery/common/app'

import _models from './_bottles.ls'

import {$m} from '@/app'

export const {Bottle, BottlesDataModel} = _models

export function bind() {
    events.on('identity.login', async() => {
        $m.bottles = new BottlesDataModel()
    })
}
