api = require 'api.ls'
app = require('@/app')
m = require 'mithril'

{icon} = require '@/components/icon.ls'
{ProductBottleType, ProductCategory} = require '@/models/products'
inputs = require '@/components/inputs'


field = (label, input) ->
    inputs.field label, input, label_width=2, input_width=4


module.exports = class ProductCategoryEdit
    ->
        @product_category = window.prop new ProductCategory
        @categories = window.prop {}

        @create = false
        @artkey = m.route.param 'artkey'

        if @artkey
            @query_product_category @artkey
        else  # we are creating a new one
            @create = true

        @query_parent_product_categories!

    query_parent_product_categories: ->
        api.call-and-then 'product_categories.get_product_categories' {} do
            success: (resp) ~>
                categoryChoices = {}

                for i in resp.result.filter((cat) -> !cat.parent)
                    categoryChoices[i.artkey] = i.name
                @categories categoryChoices

    query_product_category: (artkey) ~>
        api.call-and-then 'product_categories.get_product_category' {artkey: artkey}, do
            success: (resp) ~>
                @product_category new ProductCategory resp.result[0]

    save: (e) ~>
        e.preventDefault!

        data = do
            artkey: @product_category!artkey!
            parent_artkey: @product_category!parent.artkey!
            priority: @product_category!priority!
            name: @product_category!name!
            default_product_bottle_type: @product_category!default_product_bottle_type!
            nl_vat_rate_artkey: @product_category!nl_vat_rate!artkey!
            de_vat_rate_artkey: @product_category!de_vat_rate!artkey!
            be_vat_rate_artkey: @product_category!be_vat_rate!artkey!

        if @create
            data['cbs_code'] = @product_category!cbs_code!
            data['cbs_code_gte_200'] = @product_category!cbs_code_gte_200!
            data['cbs_code_gte_1000'] = @product_category!cbs_code_gte_1000!

        api.call-and-then 'product_categories.create_or_update_product_category' data, do
            success: (resp) ~>
                if @create then
                    app.notifier.notify "Successfully created new product category \"#{@product_category!name!}\".", 'success'
                    m.route.set "/data/product-categories/#{resp.artkey}/edit"
                else
                    app.notifier.notify 'Successfully updated product category.', 'success'
            failure: (resp) ~>
                app.notifier.notify resp.message, 'danger'

    delete: (artkey) ~>
        api.call-and-then 'product_categories.delete_product_category' {artkey: artkey}, do
            success: ~>
                app.notifier.notify "Successfully deleted product category \"#{@product_category!name!}\".", 'success'
                app.$m.common.observable.broadcast 'deleted_product_category'
                m.route.set '/data/product-categories'

    view: ->
        m '.c-product-category-edit view',
            m '.btn-toolbar',
                m 'button.btn.btn-default' {type: "button", onclick: ~> m.route.set '/data/product-categories'},
                    (m 'span.glyphicon.glyphicon-arrow-left'), ' Back to list'
                if not @create then m 'button.btn.btn-danger' {type: 'button', \
                    onclick: ~> @delete(@artkey)},
                    (m 'span.glyphicon.glyphicon-trash'), 'Delete'

            if @create
                m '.alert.alert-warning',
                    m 'strong' "Warning! Don't forget excise."
                    m 'p' 'After creating this product category, please make sure to also add it to the right excise category for every excise country.'

            m 'form.flex-form' {onsubmit: @save},
                m '.fieldset',
                    field 'Name', inputs.text @product_category!name, do
                        required: true
                        placeholder: 'Product Category name'
                        value: @product_category!name! or ''
                        oninput: (ev) ~> @product_category!name ev.target.value

                    field 'Parent Category',
                        inputs.select @product_category!.parent.artkey, @categories!,
                            required: false
                            empty_option: true

                    field 'Priority',
                        inputs.number @product_category!.priority

                    field 'Default product bottle type',
                        inputs.select @product_category!default_product_bottle_type, ProductBottleType,
                            required: true

                m '.fieldset',
                    m '.field-group',
                        field 'CBS Code', inputs.text @product_category!cbs_code, do
                            disabled: !@create
                            required: @create
                        field 'CBS Code >= 200ml', inputs.text @product_category!cbs_code_gte_200, do
                            disabled: !@create
                        field 'CBS Code >= 1000ml', inputs.text @product_category!cbs_code_gte_1000, do
                            disabled: !@create

                    m '.field-group',
                        field [
                            'VAT Percentage '
                            icon 'flag-icon-nl'
                        ], inputs.vat_rates @product_category!nl_vat_rate!artkey, 'NL', {required: true}
                        field [
                            'VAT Percentage '
                            icon 'flag-icon-de'
                        ], inputs.vat_rates @product_category!de_vat_rate!artkey, 'DE', {required: true}
                        field [
                            'VAT Percentage '
                            icon 'flag-icon-be'
                        ], inputs.vat_rates @product_category!be_vat_rate!artkey, 'BE', {required: true}

                m 'button.btn.btn-success btn-submit' if @create then 'Create Category' else 'Update Category',
