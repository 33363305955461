import {accountIcon} from 'accounts.ls'
import {deref} from '@bitstillery/common/utils.ls'
import {Amount} from '@bitstillery/common/components'

import {DefaultView} from './default'

import {$m} from '@/app'
import {Percent} from '@/components/html_components'
/**
 * Returns '-' for empty values; otherwise the actual value.
 *
 * @param prop
 */
export function format_prop(prop: any) {
    // @ts-ignore
    const value = deref(prop)
    return value ? value : '-'
}

export class DeliveryConditionsView extends DefaultView {
    view() {
        return (
            <tbody>
                {this.collection.search_result().map((relation, index) => (
                    <tr
                        onclick={(e) => this.onclick(e, relation.artkey)}
                        className={index % 2 === 0 ? 'even' : 'odd'}
                        key={relation.artkey}
                    >
                        <td className="col-sm-2 ellipsis">
                            <span className="mr-05">{accountIcon(relation.sales_account)}</span>
                            <span>{relation.name}</span>
                        </td>
                        <td className="col-sm-1 ellipsis">
                            <span>{$m.data.humanize_customs_visibility[relation.portal_customs_visibility]}</span>
                        </td>
                        <td className="col-sm-1 ellipsis">{relation.currency}</td>
                        <td className="col-sm-1 ellipsis">{format_prop(deref(relation.incoterm))}</td>
                        <td className="col-sm-1 ellipsis">
                            <Amount amount={relation.transport_costs_per_case} currency={relation.currency} />
                        </td>
                        <td className="col-sm-1 ellipsis">
                            <Amount amount={relation.minimum_order_amount} currency={relation.currency} />
                        </td>
                        <td className="col-sm-1 ellipsis">{relation.price_preference}</td>
                        <td className="col-sm-1 ellipsis">
                            {relation.price_markup_percentage && (
                                <Percent value={relation.price_markup_percentage / 100} />
                            )}
                            {!relation.price_markup_percentage && '-'}
                        </td>
                        <td className="col-sm-3">{format_prop(deref(relation.memo))}</td>
                        <td className="col-sm-1 ellipsis">{format_prop(deref(relation.portal_level))}</td>
                    </tr>
                ))}

                {this.footer()}
            </tbody>
        )
    }
}
