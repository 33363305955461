m = require 'mithril'
api = require 'api.ls'
{download_binary_file_from_base64_str, format-date} = require 'utils.ls'


export download_file: (file, type) ~>
    if type == 'attachment'
        data = do
            file_hash: file.file_hash
            file_name: file.file_name
        api.call2 'attachment.get_order_attachment_file', data, (resp) ~> @handle_download_file resp, file


export handle_download_file: (resp, product_file) ~>
    download_binary_file_from_base64_str resp.result,
        product_file.file_name


export download_selected_files: (file_selection, context, type) ~>
    if type == 'attachment'
        data = do
            selection: file_selection
        api.call2 'attachment.get_selected_attachment_file_batch', data, (resp) ~> @handle_file_batch(resp, context, type)


export download_all_files: (context, type) ~>
    if type == 'purchase-attachment'
        data = do
            purchase_order_artkey: context.artkey!
        api.call2 'attachment.get_purchase_order_attachment_file_batch', data, (resp) ~> @handle_file_batch(resp, context, type)
    else if type == 'relation-attachment'
        data = do
            supplier_artkey: context.artkey!
        api.call2 'attachment.get_relation_attachment_file_batch', data, (resp) ~> @handle_file_batch(resp, context, type)


export handle_file_batch: (resp, context, type) ~>
    if type in ['purchase-attachment', 'sales-attachment', 'attachment']
        date = format-date(context.date!)
        download_binary_file_from_base64_str resp.result,
            "#{context.reference!} - #{context.supplier!name!} - #{date} files.zip"
    else if type in ['relation-attachment']
        date = format-date(context.date!)
        download_binary_file_from_base64_str resp.result,
            "#{context.reference!} - #{context.name!} - #{date} files.zip"
