m = require 'mithril'
{abs, filter, join, sum} = require 'prelude-ls'
api = require 'api.ls'
{icon-with-popover} = require '@/components/icon.ls'
{Amount} = require '@bitstillery/common/components'
{button-with-icon} = require '@/components/buttons.ls'
inputs = require '@/components/inputs'
table = require '@/components/table.ls'
{a}: utils = require 'utils.ls'
{AccountSlug, current_account_slug} = require '@bitstillery/common/account/account'
{RevenueType} = require './models.ls'
{convert_from_currency_to_euro, convert_from_source_to_target, convert_from_euro_to_currency} = require '@/factserver_api/currencies'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

module.exports = class SalesOrderRevenue
    ->
        @sales_orders = window.prop []

        @start_date = window.prop ''
        @end_date = window.prop ''

        @from_date = window.prop ''
        @to_date = window.prop ''
        @week_date = window.prop  ''
        @month_date = window.prop ''

        @_week_input_value = window.prop ''
        @_month_input_value = window.prop ''

        @date_selection_value = window.prop 'month'

        @loading = window.prop false

        # Default to the current month!
        @month(new Date!)
        @week(new Date!)
        @date_selection @date_selection_value!
        @query!

    set_month_dates: ~>
        date = @month_date!
        @start_date new Date(date.getFullYear(), date.getMonth(), 1)
        @end_date new Date(date.getFullYear(), date.getMonth() + 1, 0)

    set_week_dates: ~>
        date = @week_date!
        @start_date new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
        @end_date new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6)

    week: (value) ~>
        # Setter and getter in one. When setter, value will be a Date object.
        # When getter, the html5 input expects: '2016-W36' format
        if value
            @_week_input_value value.getWeek!
            @week_date value
        else
            @_week_input_value!

    month: (value) ~>
        # Setter and getter in one. When setter, value will be a Date object.
        # When getter, the html5 input expects: '2016-09' format
        if value
            @_month_input_value value.getMonthInputFormat!
            @month_date value
        else
            @_month_input_value!

    from: (value) ~>
        if value
            @from_date value
        else
            @from_date!

    to: (value) ~>
        if value
            @to_date value
        else
            @to_date!

    date_selection: (value) ~>
        if value?
            @date_selection_value value
        else
            return @date_selection_value!

    set_start_end_date: ~>
        switch @date_selection_value!
        case 'range'
            @start_date @from_date!
            @end_date @to_date!
        case 'week'
            @set_week_dates!
        case 'month'
            @set_month_dates!

    query: (event) ~>
        if event?
            event.preventDefault!
        @loading true

        @set_start_end_date!

        data = do
            start_date: utils.format_date_html5(@start_date!)
            end_date: utils.format_date_html5(@end_date!)
        api.call('sales.reporting.revenue', data, @set_sales_orders)

    set_sales_orders: (resp) ~>
        @loading false
        if resp.success
            @sales_orders resp.result

    handle_export_result: (resp) ~>
        if resp.success
            utils.download_binary_file_from_base64_str resp.result,
              'Revenue from ' + utils.format-date(@start_date!) + ' to ' + utils.format-date(@end_date!) + '.xlsx'
        else
            app.$m.common.generic_error_handler!

    # Export the table of sales orders to an Excel file.
    export_revenue_to_excel: ~>
        @set_start_end_date!

        api.call 'sales.reporting.export_revenue_to_excel', do
            start_date: utils.format_date_html5(@start_date!)
            end_date: utils.format_date_html5(@end_date!)
        , @handle_export_result

    # Get the supplier name(s) of the other sales account(s).
    # Currently this function returns hardcoded results for MSI and A2BC only. If we ever introduce
    # a third sales account, only this function needs to be extended for this page.
    reseller_supplier_names: ~>
        if [AccountSlug.A2BC, AccountSlug.ETR].includes(current_account_slug!)
            [app.$m.data.msi_supplier_name, app.$m.data.msp_supplier_name]
        else if current_account_slug! == AccountSlug.MSI
            [app.$m.data.a2bc_supplier_name, app.$m.data.msp_supplier_name]
        else if current_account_slug! == AccountSlug.MSP
            [app.$m.data.a2bc_supplier_name, app.$m.data.msi_supplier_name]
        else
            []

    # Get a subset of the @sales_orders property for a certain RevenueType.
    sales_orders_for_type: (type) ~>
        if type == RevenueType.DIRECT_SALES
            @sales_orders!filter((so) -> !so.supplier.is_supplier_for_account_artkey)

        else if type == RevenueType.TO_RESELLERS
            @sales_orders!filter((so) -> so.supplier.is_supplier_for_account_artkey)
        else
            # Return all sales orders by default.
            @sales_orders!

    # Get the supplier name for a certain RevenueType.
    supplier_name: (type) ~>
        if type == RevenueType.TO_RESELLERS
            "To #{join ' / ' @reseller_supplier_names!}"
        else if app.$m.accounts.current_account!
            # Sane default, even if type == null.
            app.$m.accounts.current_account!name!

    turnover: (type) ~>
        sum [convert_from_currency_to_euro(
            +sales_order.turnover,
            sales_order.was_sold_in,
            sales_order.sold_against_rate
        ) for sales_order in @sales_orders_for_type type]

    additionals_other_total: (type) ~>
        sum [convert_from_currency_to_euro(
            +sales_order.additionals_other_total,
            sales_order.was_sold_in,
            sales_order.sold_against_rate
        ) for sales_order in @sales_orders_for_type type]

    additionals_turnover_total: (type) ~>
        sum [convert_from_currency_to_euro(
            +sales_order.additionals_turnover_total,
            sales_order.was_sold_in,
            sales_order.sold_against_rate
        ) for sales_order in @sales_orders_for_type type]

    credit_total: (type) ~>
        sum [convert_from_currency_to_euro(
            +sales_order.credit_total,
            sales_order.was_sold_in,
            sales_order.sold_against_rate
        ) for sales_order in @sales_orders_for_type type]

    euro_turnover: (type) ~>
        sum [+sales_order.euro_turnover for sales_order in @sales_orders_for_type type]

    gross_margin_euro: (type) ~>
        sum [+sales_order.margin for sales_order in @sales_orders_for_type type]

    gross_margin_percentage: (type) ~>
        ((@gross_margin_euro type) / (@euro_turnover type) * 100) || 0

    net_margin_euro: (type) ~>
        sum [+sales_order.net_margin for sales_order in @sales_orders_for_type type]

    net_margin_percentage: (type) ~>
        ((@net_margin_euro type) / (@euro_turnover type) * 100) || 0

    view: ->
        m '.c-sales-revenue view',
            m '.c-filter-group',
                button-with-icon 'Export to excel', 'fa-file-excel', do
                    class: 'btn-default'
                    onclick: @export_revenue_to_excel

            m '.c-filter-group',
                inputs.radio(@date_selection, [
                    {'value': 'range', 'description': 'Range'},
                    {'value': 'week', 'description': 'Week'},
                    {'value': 'month', 'description': 'Month'}
                ])

            m '.c-filter-group' {onsubmit: @query},

                if @date_selection! == 'range'
                    m '.filter-field' [
                        m 'label' 'From'
                        inputs.date(@from, {required: true})
                    ],
                    m '.filter-field' [
                        m 'label' 'To'
                        inputs.date(@to, {required: true})
                    ]

                else if @date_selection! == 'week'
                    m '.filter-field' [
                        m 'label' 'Select week'
                        inputs.date_week(@week, {required: true})
                    ]
                else if @date_selection! == 'month'
                    m '.filter-field' [
                        m 'label' 'Select month'
                        inputs.date_month(@month, {required: true})
                    ],

                m 'button.btn.btn-default',
                    onclick: @query,
                    type: 'submit'
                    disabled: @loading!
                , if @loading! then m Spinner else 'Search'

            m '.c-filter-group',
                for type in [RevenueType.DIRECT_SALES, RevenueType.TO_RESELLERS]
                    m 'dl.dl-horizontal' a do
                        m 'dt' 'Supplier'
                        m 'dd.number' @supplier_name(type)
                        m 'dt' 'Period'
                        m 'dd.number' utils.format-date(@start_date!), ' - ', utils.format-date(@end_date!)
                        m 'dt' 'Turnover'
                        m 'dd.number' '€', @turnover(type).format-money!
                        m 'dt' 'Additionals turnover'
                        m 'dd.number' '€', @additionals_turnover_total(type).format-money!
                        m 'dt' 'Additionals other'
                        m 'dd.number' '€', @additionals_other_total(type).format-money!
                        m 'dt' 'Total credited'
                        m 'dd.number' '€', @credit_total(type).format-money!
                        m 'dt' 'Nr. of sales orders'
                        m 'dd.number' @sales_orders_for_type(type).length
                        m 'dt' 'Gross Margin (€)'
                        m 'dd.number' '€', @gross_margin_euro(type).format-money!
                        m 'dt' 'Gross Margin (%)'
                        m 'dd.number' @gross_margin_percentage(type).toFixed(2) + '%'
                        m 'dt' 'Net Margin (€)'
                        m 'dd.number' '€', @net_margin_euro(type).format-money!
                        m 'dt' 'Net Margin (%)'
                        m 'dd.number' @net_margin_percentage(type).toFixed(2) + '%'

            table.table(@sales_orders, [
                do
                    width: 6
                    field: 'reference'
                    name: '#'
                    function: ((sales_order) ->
                        m m.route.Link, {href: "/sales-orders/manage/#{sales_order.artkey}"} sales_order.reference
                    )
                do
                    width: 6
                    field: 'invoice_number'
                    name: 'Invoice nr'
                do
                    width: 8
                    name: "Invoice date"
                    function: ((sales_order) ~> utils.format-date(sales_order.is_invoiced_on))
                do
                    width: 15
                    field: "supplier.name"
                    name: "Relation"
                do
                    width: 10
                    name: "Sales manager"
                    function: (sales_order) ~>
                        m '',
                            sales_order.supplier.sales_manager.profile.name + ' '
                            if sales_order.supplier.sales_manager.profile.name != sales_order.was_handled_by.profile.name
                            then
                                icon-with-popover do
                                    icon-id: 'user'
                                    title: 'Created by'
                                    content: sales_order.was_handled_by.profile.name
                do
                    width: 10
                    name: "Product total excl. excise"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.product_total_excl_excise
                            currency: sales_order.was_sold_in
                            rate: sales_order.sold_against_rate
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 10
                    name: "Additional turnover"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.additionals_turnover_total
                            currency: sales_order.was_sold_in
                            rate: sales_order.sold_against_rate
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 10
                    name: "Turnover"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.turnover
                            currency: sales_order.was_sold_in
                            rate: sales_order.sold_against_rate
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 10
                    name: "Additional other"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.additionals_other_total
                            currency: sales_order.was_sold_in
                            rate: sales_order.sold_against_rate
                            display_currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 10
                    field: ""
                    name: "Gross Margin (€)"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.margin
                            currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 8
                    name: "Gross Margin (%)"
                    classes: 'number'
                    function: ((sales_order) ~>
                        if sales_order.euro_turnover
                        then (sales_order.margin / abs(sales_order.euro_turnover) * 100).toFixed(2) + '%'
                        else '-'
                    )
                do
                    width: 9
                    field: ""
                    name: "Net Margin (€)"
                    classes: 'price'
                    function: ((sales_order) ~>
                        m(Amount, do
                            amount: sales_order.net_margin
                            currency: app.$s.currencies.default
                        )
                    )
                do
                    width: 8
                    name: "Net Margin (%)"
                    classes: 'number'
                    function: ((sales_order) ~>
                        if sales_order.euro_turnover
                        then (sales_order.net_margin / abs(sales_order.euro_turnover) * 100).toFixed(2) + '%'
                        else '-'
                    )
            ])
            if not @sales_orders!length
                m 'p' 'No results.'
