m = require 'mithril'
{compact, join, map, min, Obj, or-list} = require 'prelude-ls'
require! 'utils.ls': {
    map-obj, format-date, match-term-in, maybe-map, on-set, assoc,
    dissoc, update, pluralize,
}
api = require 'api.ls'
inputs = require '@/components/inputs'
{Amount} = require '@bitstillery/common/components'
{icon-button, button-with-icon, text-button} = require '@/components/buttons.ls'
{link} = require '@/components/link.ls'
{CaseInfoPanel} = require '@/components/market_info/case_info_panel'
{Collection} = require '@/components/collection/collection.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{Modal} = require '@/components/modal/modal.ls'
{AddToOrder} = require '@/purchase_orders/components/add_to_order'

{account-icon} = require 'accounts.ls'
{TboStatus} = require '@/sales_orders/models.ls'
{SearchBar, SearchBarControl} = require '@/components/collection/search_bar'
{ ProductManagementApi } = require '@/factserver_api/product_management_api'
{SupplierPriceListItem} = require '@/models/suppliers'
app = require('@/app')

/*
-------------------------------------------------------------------------------
TBO list.
-------------------------------------------------------------------------------

url: /tbo

List of all TBO items in all sales orders which are 'open'.
For each TBO item the market can be viewed to look for a look candidate.
Then with a "Add to order" button a POI can be attached to the TBO.
*/

const TBO_STATUS_SELECTION = [
    # Not yet approved is consciously omitted.
    # Purchasing does not need to be informed about these until sales approves them.
    [TboStatus.OPEN, 'Show open TBO items']
    [TboStatus.ORDERED, 'Show ordered TBO items']
    [TboStatus.CONFIRMED, 'Show confirmed TBO items']
    [TboStatus.IN_PROGRESS, 'Show all in progress TBO items']
    [TboStatus.ALL, 'Show all TBO items']
]


module.exports = class PurchaseOrderTBO
    ->
        @tbo-items = new Collection do
            api_function_name: 'sales.tbo.get_tbo_items'
            # TODO make a client side filter
            # filter_function: @is_match
            additional_params: @additional_params
            query_limit: 25
            sort_order: []
            default_sort_by: 'sales_order.date'
            default_sort_order: 'asc'
            filter_serverside: true
            dont-reuse: true

        @search_status = window.prop TboStatus.OPEN
        @selected = null
        @only_mine = \
            let prop = window.prop false
                on-set prop, (new-value) ~>
                    @tbo-items.requery!

        @search_bar_controller = null
        @product_management_api = new ProductManagementApi()

    submit_search: (text) ~>
        @tbo-items.update_search_term text
        @tbo-items.submit_search!

    oncreate: ~>
        @tbo-items.init!

        q = m.route.param 'q'
        if q
            @submit_search q.replace(/\+/g, ' ')
        show_only_mine = m.route.param 'only_mine'
        if show_only_mine
            @only_mine true
        status = m.route.param 'status'
        if status
            @search_status status

        app.$m.common.observable.subscribe 'product_names_updated', @, ~> @search_input_ctrl.set_suggestions app.$m.products.get_names!

    additional_params: ~>
        params = do
            only_mine: @only_mine!

        if @search_status! == TboStatus.ALL
            params['status'] = [TboStatus.OPEN, TboStatus.ORDERED, TboStatus.CONFIRMED]
        else if @search_status! == TboStatus.IN_PROGRESS
            params['status'] = [TboStatus.OPEN, TboStatus.ORDERED]
        else
            params['status'] = [@search_status!]
        return params

    update_url_state: ~>
        m.route.set("/purchase-orders/tbo", {
            status: @search_status!,
            only_mine: @only_mine!,
        })

    set_search_status: (search_status) ~>
        @search_status search_status
        @tbo-items.requery!
        @update_url_state!

    add-to-order: (tbo-item, spli, custom_offer_item) ->
        @selected = do
            tbo-item: tbo-item
            spli: spli
            custom_offer_item: custom_offer_item

    close-add-to-order: ~>
        @selected = null
        m.redraw!

    refresh-and-close-add-to-order: ~>
        @selected = null
        @tbo-items.requery!

    view: -> m '.c-purchase-tbo view',
        if @selected then
            modal-attrs =  do
                title: if @selected.custom_offer_item then 'Add custom TBO offer item to purchase order' else 'Add market item to purchase order'
                onclose: @close-add-to-order

            if @selected.spli
                supplier_artkey = @selected.spli.supplier_price_list!supplier!artkey!
            else if @selected.custom_offer_item
                supplier_artkey = @selected.custom_offer_item.supplier.artkey

            m Modal, modal-attrs,
                m AddToOrder, {
                    selected_spli: @selected.spli,
                    supplier_artkey: supplier_artkey,
                    on_added_item: @refresh-and-close-add-to-order,
                    oncancel: @close-add-to-order,
                    tbo_item: @selected.tbo-item,
                    custom_offer_item: @selected.custom_offer_item
                }

        m '.c-filter-group',
            m SearchBar,
                placeholder: "Search for products, specs, TBO supplier, tax label or other item tags..."
                on_submit: @submit_search
                default_search_text: @tbo-items.search_term!
                search_bar_controller: (search_bar_controller) ~> @search_bar_controller = search_bar_controller
                on_get_suggestions$: (filter_text) ~> @product_management_api.get_simple_product_names(filter_text)

            inputs.select @search_status, TBO_STATUS_SELECTION,
                {onchange: @set_search_status}

            button-with-icon "Only my sales TBO's" 'glyphicon-user',
                if @only_mine!
                    class: 'btn-danger'
                    onclick: ~>
                        @only_mine false
                        @update_url_state!
                else
                    class: 'btn-default'
                    onclick: ~>
                        @only_mine true
                        @update_url_state!

        @tbo-items.show_counter!

        m CollectionTable, do
            collection: @tbo-items
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: false
                autoscale: true
                unique_name: 'tbo_items_table'

            view_details: (record) ~>
                if not record.purchase_order.artkey then
                    market-data = do
                        bottle_artkey: record.bottle_artkey
                        case_artkey: record.case_artkey
                        exclude_case_artkey_from_market: true
                        current_supplier_artkey: record.supplier.artkey
                        customs_status: record.case_customs_status
                        up_is_up: true  # Will show stock that equals the bottle.
                        add_to_order: (spli) ~>
                            # Market structure has changed (on 2018-04-04).
                            # Fix spli for backwards compatibility.
                            spli.supplier_price_list.supplier = spli.supplier
                            @add-to-order record, new SupplierPriceListItem spli, null
                        add_custom_offer_item_to_purchase_order: (custom_offer_item) ~>
                            @add-to-order record, null, custom_offer_item

                    m CaseInfoPanel, market-data

            columns:
                do
                    width: 4
                    name: 'Sales Order'
                    field: 'sales_order.artkey'
                    transform: (artkey) ->
                        link "/sales-orders/manage/#{artkey}", 'S' + artkey
                    sort: true
                do
                    width: 4
                    name: 'Order Date'
                    field: 'sales_order.date'
                    transform: format-date
                    sort: true
                do
                    width: 4
                    name: 'Purchase Date'
                    field: 'purchase_order.date'
                    transform: format-date
                    sort: true
                do
                    width: 4
                    name: 'ETA'
                    field: 'purchase_order.expected_delivery_date'
                    transform: format-date
                    sort: true
                do
                    width: 10
                    name: 'Buyer'
                    field: 'sales_order.buyer.name'
                    sort: true
                    ellipsis: true
                do
                    width: 10
                    name: 'Sales manager'
                    field: 'sales_order.buyer.sales_manager.name'
                    sort: true
                    ellipsis: true
                do
                    width: 8
                    name: 'For account'
                    field: 'sales_order.account.name'
                    ellipsis: true
                    sort: true
                    function: (record) -> [
                        m 'span.mr-05' account-icon record.sales_order.account
                        record.sales_order.account.name
                    ]
                do
                    width: 12
                    name: 'Product'
                    sort: true
                    field: 'product_name'
                    ellipsis: true
                do
                    width: 5
                    name: 'Category'
                    sort: true
                    field: 'product_category'
                    ellipsis: true
                    transform: (category) ->
                        m 'span.text-capitalize' category
                do
                    width: 3
                    name: 'Btl / cs'
                    sort: true
                    field: 'case_number_of_bottles'
                    default_visible: false
                    classes: ['number']
                do
                    width: 4
                    name: 'Size'
                    sort: true
                    field: 'bottle_volume'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_volume).toFixed(1), 'cl'
                    classes: ['number']
                do
                    width: 4
                    name: 'Alc %'
                    sort: true
                    field: 'bottle_alcohol_percentage'
                    default_visible: false
                    function: (record) ->
                        m 'span' (+record.bottle_alcohol_percentage).toFixed(1), '%'
                    classes: ['number']
                do
                    width: 3
                    name: 'Ref'
                    sort: true
                    default_visible: false
                    field: 'bottle_refill_status'
                do
                    width: 10
                    name: 'Specs'
                    sort: false
                    function: (record) ->
                        specs = []
                        specs.push record.case_number_of_bottles
                        specs.push (+record.bottle_volume).toFixed(1)
                        specs.push (+record.bottle_alcohol_percentage).toFixed(1)
                        specs.push record.bottle_refill_status
                        join ' / ' specs
                do
                    width: 4
                    name: 'GB'
                    sort: true
                    field: 'case_gift_box_type'
                    default_visible: false
                    ellipsis: true
                do
                    width: 4
                    name: 'Tax Label'
                    sort: true
                    field: 'case_tax_label'
                    default_visible: false
                    ellipsis: true
                do
                    width: 5
                    header: 'BBD'
                    name: 'Best before date'
                    sort: true
                    field: 'case_best_before_date'
                    default_visible: false
                    transform: maybe-map format-date
                do
                    width: 10
                    name: 'Features'
                    function: (record) ->
                        features = []
                        if record.case_gift_box_type
                            features.push record.case_gift_box_type
                        if record.case_tax_label
                            features.push record.case_tax_label
                        if record.case_best_before_date
                            features.push "BBD: #{format-date record.case_best_before_date}"
                        if record.case_damages
                            features.push record.case_damages.replace(', ', ' / ')
                        if record.case_general_tags
                            features.push record.case_general_tags.replace(', ', ' / ')
                        if record.case_pack_size
                            features.push record.case_pack_size
                        if record.case_packaging
                            features.push record.case_packaging
                        join ' / ' features
                do
                    width: 3
                    header: 'Cus.'
                    name: 'Customs status'
                    sort: true
                    field: 'case_customs_status'
                do
                    width: 4
                    header: 'Qty.'
                    name: 'Quantity'
                    sort: true
                    field: 'number_of_cases'
                do
                    width: 10
                    name: 'Price / cs'
                    sort: true
                    field: 'price_per_case'
                    classes: ['price']
                    function: (record) ->
                        m Amount, do
                            amount: +record.price_per_case
                            excise: +record.excise_per_case
                            currency: record.currency
                            rate: +record.rate
                do
                    width: 10
                    name: 'Preferred supplier'
                    sort: true
                    field: 'supplier.name'
                    ellipsis: true
                do
                    width: 5
                    name: 'Status'
                    sort: true
                    field: 'status'
                do
                    width: 5
                    name: 'PO#'
                    field: 'purchase_order.artkey'
                    transform: maybe-map purchase-order-link
                do
                    width: 6
                    name: 'Delivery period'
                    sort: true
                    field: 'delivery_period'
                    classes: ['number']
                    transform: (delivery-period) ->
                        if delivery-period
                            delivery-period + ' ' + pluralize delivery-period, 'week', 'weeks'
                        else
                            '-'
                do
                    width: 2
                    header: ''
                    name: 'Actions'
                    function: (record) ->
                        m '' ' '


purchase-order-link = (artkey) ->
    m m.route.Link, do
        href: "/purchase-orders/manage/P#{artkey}"
    , "P#{artkey}"
