import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount, Country, Tippy} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {DateTime} from 'luxon'

import {UserDropDown} from '../components/users'

import {KeyFigures} from './key_figures'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {
    GetPurchaseOrdersCollectionViewResponseOutput,
    PurchaseOrderStatus,
} from '@/factserver_api/fact2server_api'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {DefaultButton} from '@/components/buttons'
import {drop_down_menus_for_enum, DropDownWithSelect, empty_option} from '@/components/html_components'

interface PurchaseOrdersURLParams {
    purchase_manager_artkey?: string
    search_terms?: string
    status?: string
}

export default class PurchaseOrderList extends MithrilTsxComponent<unknown> {
    last_view_date = localStorage.getItem('views.list_purchase_orders') || '1980-01-01 00:00'
    search_bar_controller: SearchBarControl | null = null
    purchase_order_fetcher = new PagedCollectionFetcherWithGET<GetPurchaseOrdersCollectionViewResponseOutput>(
        'discover/purchase-orders',
        'reference',
        undefined,
        50,
        false,
    )

    oncreate(): void {
        localStorage.setItem('views.list_purchase_orders', DateTime.now().toISO())

        const params = m.route.param() as PurchaseOrdersURLParams
        if (params.purchase_manager_artkey) {
            this.purchase_order_fetcher.filters['purchase_manager_artkey'] = +params.purchase_manager_artkey
        }
        if (params.search_terms) {
            this.search_bar_controller?.set_and_submit_search_text(params.search_terms, true)
        }
        if (params.status) {
            this.purchase_order_fetcher.filters['status'] = decodeURI(params.status)
        }
    }

    update_url_params() {
        let route = m.route.get()
        if (route.includes('?')) {
            route = route.split('?')[0]
        }

        const params: PurchaseOrdersURLParams = {
        }
        if (this.purchase_order_fetcher.search_terms) {
            params.search_terms = this.purchase_order_fetcher.search_terms.join(' ')
        }
        if (this.purchase_order_fetcher.filters['purchase_manager_artkey']) {
            params.purchase_manager_artkey = this.purchase_order_fetcher.filters['purchase_manager_artkey'] as string
        }
        if (this.purchase_order_fetcher.filters['status']) {
            params.status = encodeURI(this.purchase_order_fetcher.filters['status'] as string)
        }
        m.route.set(route, params)
    }

    on_status_selected(status: string) {
        this.purchase_order_fetcher.filters['status'] = status
        this.purchase_order_fetcher.reset_and_query()
        this.update_url_params()
    }

    on_purchase_manager_selected(purchase_manager_artkey?: string): void {
        this.purchase_order_fetcher.filters['purchase_manager_artkey'] = purchase_manager_artkey || ''
        this.purchase_order_fetcher.reset_and_query()
        this.update_url_params()
    }

    search_for_search_text = (search_text: string): void => {
        this.purchase_order_fetcher.set_search_terms(search_text)
    }

    view(_: m.Vnode<unknown>): m.Children {
        return <div className="c-purchase-order-list view">
            <div className="btn-toolbar">
                <DefaultButton
                    title={'Create purchase order'}
                    icon_class={'fas fa-plus'}
                    onclick={() => m.route.set('/purchase-orders/manage/create')}
                />
                <KeyFigures />
            </div>
            <div className="c-filter-group">
                <SearchBar
                    label={'Search'}
                    placeholder={'Search for relation, references...'}
                    on_submit={this.search_for_search_text}
                    default_search_text={this.purchase_order_fetcher.search_text()}
                    search_bar_controller={(controller: SearchBarControl) =>
                        (this.search_bar_controller = controller)
                    }
                />
                <DropDownWithSelect
                    label="Status"
                    selected={this.purchase_order_fetcher.filters['status'] as string}
                    onchange={(value: string) => this.on_status_selected(value)}
                    empty_option={empty_option('All active')}
                >
                    {drop_down_menus_for_enum(PurchaseOrderStatus)}
                </DropDownWithSelect>
                <UserDropDown
                    label={'Purchase Manager'}
                    onchange={(user_artkey: string) => this.on_purchase_manager_selected(user_artkey)}
                    local_storage_name={'purchase-order-list-manager'}
                />
            </div>

            <CollectionTable<GetPurchaseOrdersCollectionViewResponseOutput, void>
                on_row_click={(row: GetPurchaseOrdersCollectionViewResponseOutput) => m.route.set(`/purchase-orders/manage/${row.artkey}`)}
                collection_fetcher={this.purchase_order_fetcher}
            >
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => '#'}
                    sort_name={'reference'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => {
                        const children: m.Children = []
                        if (row.was_last_updated_on > this.last_view_date) {
                            children.push(<span className={'fas fa-circle'}/>)
                        }
                        children.push(<span>{row.reference}</span>)
                        return children
                    }}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => '##'}
                    sort_name={'supplier_reference'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => row.supplier_reference}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'W#'}
                    sort_name={'warehouse_reference'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => row.warehouse_reference}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Name'}
                    sort_name={'supplier_name'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) =>
                        [
                            <Country country_code={row.supplier_country_code}/>,
                            ' ',
                            row.supplier_name,
                        ]
                    }
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Created'}
                    sort_name={'created_on'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => format_iso_to_date(row.created_on)}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'ETA'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => format_iso_to_date(row.expected_delivery_date)}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Incoterm'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => {
                        return `${row.incoterm} - ${row.incoterm_location}`
                    }}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Warehouse'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => row.target_warehouse_name}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Status'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => {
                        if (row.warehouse_status) {
                            return `${row.status}: ${row.warehouse_status}`
                        }
                        return row.status
                    }}
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Totals'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) =>
                        [
                            <div>{`${row.number_of_cases} cases `}</div>,
                            <Amount amount={row.was_bought_for} currency={row.was_bought_in}/>,
                        ]
                    }
                />
                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => 'Purchase Manager'}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => row.purchase_manager_name}
                />

                <CollectionTableColumn<GetPurchaseOrdersCollectionViewResponseOutput>
                    header_title={() => ''}
                    data_field={(row: GetPurchaseOrdersCollectionViewResponseOutput) => {
                        const children: m.Children = []
                        if (row.has_attachments) {
                            children.push(<span className={'fas fa-paperclip'}></span>)
                        }
                        if (row.is_communicated_on) {
                            children.push(<Tippy content={`Communicated on ${format_iso_to_date(row.is_communicated_on)}`}>
                                <span className={'fas fa-broadcast-tower'}></span>
                            </Tippy>,
                            )
                        }
                        if (row.was_sent_to_financial_information_system_on) {
                            children.push(<Tippy content={`Sent to FIS on ${format_iso_to_date(row.was_sent_to_financial_information_system_on)}`}>
                                <span className={'fas fa-wallet'}></span>
                            </Tippy>,
                            )
                        }
                        return children
                    }}
                />
            </CollectionTable>
        </div>
    }
}
