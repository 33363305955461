import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldTextArea, Spinner} from '@bitstillery/common/components'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {CancelButton, DefaultButton, SaveButton} from '@/components/buttons'
import {TextInput} from '@/components/html_components'
import {SystemParameterApi} from '@/factserver_api/system_parameter_api'
import {LoggedInUserData, User} from '@/factserver_api/user_api'
import {SystemParameterResponse} from '@/factserver_api/fact2server_api'

interface SystemParameterRow extends SystemParameterResponse {
    is_editing: boolean
    updated_value: string | null
    updated_description: string | null
}

export default class SystemParameterList extends MithrilTsxComponent<unknown> {
    system_parameter_api = new SystemParameterApi()
    system_parameters_fetcher: PagedCollectionFetcherWithGET<SystemParameterRow>

    user: User | null = null
    is_updating = false

    constructor() {
        super()
        this.system_parameters_fetcher = new PagedCollectionFetcherWithGET('discover/data/system-parameters/collection-view', 'name')
    }

    oncreate(): void {
        this.system_parameters_fetcher.fetch_next()

        LoggedInUserData.user().subscribe({
            next: (value) => {
                this.user = value
            },
        })
    }

    update_system_parameter(row: SystemParameterRow): void {
        if (!row.updated_value || !row.updated_description) {
            return
        }
        this.is_updating = true
        this.system_parameter_api.update(row.name, row.updated_value, row.updated_description).subscribe({
            next: () => {
                row.value = row.updated_value || ''
                row.description = row.updated_description || ''
                this.is_updating = false
                row.is_editing = false
                m.redraw()
            },
            error: () => {
                this.is_updating = false
                m.redraw()
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-configuration-settings view">
                <CollectionTable<SystemParameterResponse, void> collection_fetcher={this.system_parameters_fetcher}>
                    <CollectionTableColumn<SystemParameterRow>
                        header_title={() => 'Name'}
                        sort_name={'name'}
                        td_class_name={'col-sm-2'}
                        data_field={(row: SystemParameterRow) => row.name}
                    />
                    <CollectionTableColumn<SystemParameterRow>
                        header_title={() => 'Value'}
                        td_class_name={'col-sm-2'}
                        data_field={(row: SystemParameterRow) => {

                            if (row.type === 'string' && row.is_editing) {
                                return <FieldTextArea
                                    ref={[row, 'updated_value']}
                                />
                            }
                            return <span>
                                {!row.is_editing && row.value}
                                {row.is_editing && (
                                    <TextInput
                                        value={row.updated_value}
                                        onchange
                                        oninput={(value: string) => (row.updated_value = value)}
                                    />
                                )}
                            </span>
                        }}
                    />
                    <CollectionTableColumn<SystemParameterRow>
                        header_title={() => 'Description'}
                        td_class_name={'col-sm-4'}
                        data_field={(row: SystemParameterRow) => (
                            <span>
                                {!row.is_editing && row.description}
                                {row.is_editing && (
                                    <TextInput
                                        value={row.updated_description}
                                        onchange
                                        oninput={(value: string) => (row.updated_description = value)}
                                    />
                                )}
                            </span>
                        )}
                    />
                    {this.user?.is_superuser && (
                        <CollectionTableColumn<SystemParameterRow>
                            header_title={() => ''}
                            td_class_name={'col-sm-1'}
                            data_field={(row: SystemParameterRow) => (
                                <span className={'pull-right'}>
                                    {this.is_updating && <Spinner />}
                                    {!this.is_updating && row.is_editing && (
                                        <span>
                                            <SaveButton
                                                icon_class={'glyphicon glyphicon-save'}
                                                onclick={() => this.update_system_parameter(row)}
                                            />
                                            <CancelButton onclick={() => (row.is_editing = false)} />
                                        </span>
                                    )}
                                    {!this.is_updating && !row.is_editing && (
                                        <span>
                                            <DefaultButton
                                                title={''}
                                                icon_class={'glyphicon glyphicon-pencil'}
                                                onclick={() => {
                                                    row.is_editing = true
                                                    row.updated_description = row.description
                                                    row.updated_value = row.value
                                                }}
                                            />
                                        </span>
                                    )}
                                </span>
                            )}
                        />
                    )}
                </CollectionTable>
            </div>
        )
    }
}
