import {events} from '@bitstillery/common/app'

import _models from './_suppliers.ls'

import {$m} from '@/app'

export const {
    Supplier,
    SupplierPriceList,
    SupplierPriceListItem,
    SupplierPriceListSourceLine,
    SupplierPriceListsDataModel,
} = _models

export function bind() {
    events.on('identity.login', async() => {
        $m.spls = new SupplierPriceListsDataModel()
    })
}
