# including jquery explicitly breaks the tests,
# it is included via the webpack config.
m = require 'mithril'
{
    id, empty, join, all, head, filter,
    Obj, obj-to-pairs, pairs-to-obj,
    flip, apply, map, any, split-at,
    compact, is-type, split-at, drop
    tail, first, group-by, sum, maximum,
    sort-with,
} = require 'prelude-ls'

{Gauge} = require 'gaugeJS'


# Utilizes the browser eventsystem document.addEventListener, -dispatchEvent and -removeEventListener.
# usefull in cases where you need communication between independent components
# registered events are automatically removed onremove with preserving any other onremove handler.
#
# It has as a premise that the declaring party (target == weare in our case), never goes out of scope. If it does
# many eventlisteners will remain in memory.
export eventsMixin = (target) ->

    target.broadcast = (type, payload) ->
        ev = new CustomEvent(type, {detail: payload, bubbles: true, cancelable: true })
        document.dispatchEvent ev

    target.subscribe = (type, that, callback) ->
        old_unremove = that.onremove
        that.onremove = ->
            document.removeEventListener(type, callback)
            if old_unremove
                old_unremove!
        document.addEventListener(type, callback)

    return target

# Return date in format: dd-mm-yyyy. This function is called very
# often, therefore it must be quick.  Don't use `date.toLocaleString`
# here, it is to slow! The same goes for frameworks like `moment.js`,
# it would be very nice if it had an option to pre-compile the
# formatting string.
export format-date = (date) ->
    if not date
        return ''
    if typeof date != Date
        date = new Date date
    d = date.getDate!
    d = if d < 10 then '0' + d else d
    # JavaScript starts counting months at 0. Rascals.
    m = date.getMonth! + 1
    m = if m < 10 then '0' + m else m
    y = date.getFullYear!
    return "#{d}-#{m}-#{y}"


# HTML5 representation of a date string (RFC-3339): yyyy-mm-dd
# This format is required for setting a date input field
# See: http://stackoverflow.com/questions/7372038/is-there-any-way-to-change-input-type-date-format/9519493#9519493
export format_date_html5 = (date) ->
    date = format-date date
    return '' if not date
    [day, month, year] = date.split '-'
    # Required so that Mithril doesn't make the browser explode when entering a year manually.
    year = '0'.repeat(4 - String(year).length) + year
    "#year-#month-#day"


export format-time = (date) ->
    if not date
        return ''
    if typeof date != Date
        date = new Date date
    m = date.getMinutes!
    m = if m < 10 then '0' + m else m
    h = date.getHours!
    h = if h < 10 then '0' + h else h
    return "#{h}:#{m}"


export format-date-time = (date) ->
    if not date
        return ''
    if typeof date != Date
        date = new Date date
    return "#{format-date date} #{format-time date}"


# Based on: https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript/149099#149099
Number.prototype.format-money = ->
    n = @
    c = 2
    d = ","
    t = "."
    s = if n < 0 then "-" else ""
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + ""
    j = if (j = i.length) > 3 then j % 3 else 0
    return s + (if j then i.substr(0, j) + t else "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (if c then d + Math.abs(n - i).toFixed(c).slice(2) else "")


String.prototype.capitalizeFirstLetter = ->
    return @.charAt(0).toUpperCase! + @.slice(1);


export capitalize = (str) ->
    return str.replace(/\b[a-z]/g, (.toUpperCase!))


String.prototype.replaceAll = (search, replacement) ->
    return @.replace(new RegExp(escapeRegExp(search), 'g'), replacement);


export escapeRegExp = (str) ->
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")


export url = (url) ->
    if url and not url.startsWith 'http://' and not url.startsWith 'https://'
        return 'http://' + url
    return url


export split_incoterm = (incoterm) ->
    if not incoterm
        return
    parts = incoterm.split(' - ')
    # If we split more with our -, join the rest on -
    if parts.length == 1
        do
            incoterm: 'EXW'
            location: parts[0]
    else
        do
            incoterm: parts[0]
            location: parts.slice(1).join('-')


String.prototype.startsWith = (searchString, position) ->
      position = position || 0
      return @.indexOf(searchString, position) === position


# Get the descendant of an object by string. E.g.:
# get_descendant_prop {'a':{'b': {'c': 'd': 3}}}, "a.b.c.d"
# returns 3
export get_descendant_prop = (obj, desc) ->
    arr = desc.split '.'
    while arr.length && (obj = obj[arr.shift!])
        continue
    obj


# Turn a base64 encoded string back into a binary array. For details see:
# http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export base64_str_to_byte_array = (base64_str) ->
    byte_chars = atob base64_str
    byte_numbers = [byte_chars.charCodeAt(index) for bc, index in byte_chars]
    new Uint8Array byte_numbers


# Make the browser download a binary file that is base64 encoded
export download_binary_file_from_base64_str = (base64_str, file_name) ->
    download_binary_file_from_base64_str_with_type base64_str, file_name, 'application/octet-stream'


export download_binary_file_from_base64_str_with_type = (base64_str, file_name, content_type) ->
    byte_array = base64_str_to_byte_array base64_str
    blob = new Blob [byte_array], {type: content_type}
    a = document.createElement 'a'
    url = window.URL.createObjectURL blob
    a.href = url
    a.download = file_name
    a.click!
    window.URL.revokeObjectURL url


export tooltip = (e) ->
    $(e).tooltip({container: 'body'})


export randomString = (length) ->
    text = ""
    possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for i from 0 to length
        text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text


# Displays a (multiline) string by splitting the string into lines, which are
# each converted to a <span>line<br></span> format.
export multiline = (str) ->
    for line in (str or '') / '\n'
        m 'span', [line, m 'br']


# Returns the ISO week of the date.
Date.prototype.getWeek = ->
  date = new Date(@.getTime!)
  date.setHours(0, 0, 0, 0)
  # Thursday in current week decides the year.
  date.setDate(date.getDate! + 3 - (date.getDay! + 6) % 7)
  # January 4 is always in week 1.
  week1 = new Date(date.getFullYear(), 0, 4)
  # Adjust to Thursday in week 1 and count number of weeks from date to week1.
  week_number = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
  leading_zero = if week_number < 10 then '0' else ''
  return "#{date.getFullYear!}-W#{leading_zero}#{week_number}"


Date.prototype.getMonthInputFormat = ->
  month = ("0" + (@.getMonth!+1)).slice(-2)
  return "#{@.getFullYear!}-#{month}"


export rounded_number_to_two = (num) ->
    return Math.round((+num + Number.EPSILON) * 100) / 100

export with-default = (default-value, value) -->
    if value != null and value != void
    then value
    else default-value


export percentage = (first_value, second_value) ->
    if +first_value == 0 and +second_value == 0
        '0.0%'  # This is actually undefined, but we default to 0 here.
    else
        format-percentage (+first_value / +second_value)


# Deprecated, use @bitstillery/common/ts_utils.ts:format_percentage.
export format-percentage = (value) ->
    (100 * value).toFixed(1) + '%'


export format-percentage-base100 = (value) ->
    "#{value}%"


# Wraps a property with a setter callback.
# Deprecated: use after-update.
export on-set = (prop-ref, callback) ->
    (new_value) ->
        if new_value != void
            prop-ref new_value
            callback new_value
        prop-ref!


# Wrap a property with a after update callback.
#
# example:
#     prop |> after-update (v) -> console.log v
export after-update = (callback, prop-ref) -->
    (new-value) ->
        if new-value != void
            old-value = prop-ref!
            prop-ref new-value
            if new-value != old-value
                callback new-value
        prop-ref!


# Higher order function that returns an event handler that will stop the
# propagation of the event and then call fn. By default the fn argument
# is an empty function, thus you can use this function as follows:
#
#     {onclick: stop-propagation!}
#
export stop-propagation = (fn = (->)) -> (e) ->
    e.stopPropagation!
    fn e


export prevent-default = (fn) -> (e) ->
    e.preventDefault!
    fn e


# Curried function that accepts a maybe_value and maps it through the
# given function (fn) if the maybe_value is truthy. If it is falsy then
# it just returns the falsy value.
export maybe-map = (fn, maybe_value) -->
    if maybe_value then fn maybe_value else maybe_value

# Tests if all elements in a list are equal.
# Returns false for an empty list.
export all-equal = (list) ->
    if empty list
        false
    else
        list |> all (== head list)

# Join a list of maybe values. Values that are falsy are filtered out,
# and then a join is performed with the given separator.
export join-maybes = (separator, list_of_maybes) -->
    list_of_maybes |> filter id |> join separator

# Generate a random UUID.
# http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript#2117523
export random-uuid = ->
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace /[xy]/g, (c) ->
        r = Math.random!*16 .|. 0  # bitwise-or with 0 truncates the float.
        v = if c == 'x' then r else r .&. 11
        v.toString 16

# Update a property with a function.
# The function takes the current value of the property and returns a new value.
export update = (prop, fn) -> prop (fn prop!)

export dec = (x) -> x - 1
export inc = (x) -> x + 1


# Make a shallow copy of obj.
export copy = Obj.map id


# Add key => value to an immutable object (map).
# Note that this only makes a shallow copy of the object.
export assoc = (key, value, obj) -->
    res = copy obj
    res[key] = value
    res


# Filter an immutable object. Each (key, value) entry in the object is passed
# to the filter function fn.
#
# Note that this only makes a shallow copy of the object.
#
# examples:
#    x = {a: 1, b: 2, c: 3}
#    x |> filter-obj (== 'a')             # {a: 1}
#    x |> filter-obj ((k,v) -> k == 'a')  # {a: 1}
#    x |> filter-obj ((k,v) -> v <= 2)    # {a: 2, b: 2}
#
export filter-obj = (fn, obj) -->
    obj |> obj-to-pairs
        |> filter (apply fn)  # apply unpacks [key, value] into the arguments of fn.
        |> pairs-to-obj

# Convert a value to a string.
export str = (value) -> '' + value

# Remove a key from an immutable object.
# Because javascript keys are always strings, the key is converted
# to a string when comparing it.
export unassoc = (key, obj) -->
    filter-obj (!= str key), obj


# Map the keys and values of an immutable object.
#
# The function fn takes [k,v] as the first argument,
# and should return a tuple (2 dim list) with [k',v'].
export map-obj = (fn, obj) -->
    obj |> obj-to-pairs
        |> map fn
        |> pairs-to-obj


# Check if term is part of at least one string in the list.
#
# example:
#     match-term-in 'word' ['xyzwordabc', 'test']  # true
#     match-term-in 'xyz' ['abc', 'def']           # false
export match-term-in = (term, list) -->
    list |> map (.indexOf term) |> any (>= 0)


# Partition a list into 'n'-sized portions.
# Don't put in large lists, this uses recursion and Livescript
# probably doesn't do tail call optimization...
export partitions = (n, list) -->
    if empty list then []
    else let [h, t] = split-at n, list
        [h] ++ partitions n, t


# Create a custom mithril tag. This higher order function
# returns a function that can be used a drop in replacement for 'm'.
# It creates a template for a mithril tag, with a fixed tag type
# and (optionally) with a set of initial attributes.
#
# example:
#     table = m-ext 'table', {class: 'test'}
#     table {id: 'some-id'} [child1, child2, ...]
#     # the result is:
#     m 'table' {class: 'test', id: 'some-id'} children...
export m-ext = (tag, init-attrs={}) ->
    (optional-attrs, ...children) ->
        if is-type \Object optional-attrs then
            final-attrs = merge-obj init-attrs, optional-attrs
            # Merge classes if both init and optional have one defined.
            if init-attrs['class'] and optional-attrs['class'] then
                final-attrs['class'] = "#{init-attrs.class} #{optional-attrs.class}"
            m tag, final-attrs, ...children
        else
            # optional-attrs is not an object, assume it's the first child.
            m tag, init-attrs, optional-attrs, ...children


# Join a list of classes together with spaces.
# Falsy values are filtered from the list.
#
# example:
#     join-classes ['test', '', 'class2']  # 'test class2'
#     join-classes 'class1'                # 'class1'
export join-classes = (classes) ->
    if is-type \Array classes
        classes |> compact |> join ' '
    else if classes
        classes
    else
        ''


# Make a prop out of an indirect prop.
# prop-ref is a function that returns a prop.
#
# example:
#     item-tags = prop []
#     first-tag = -> first item-tags!
#     title-of-first-tag = indirect-prop (-> first-tag!title)
export indirect-prop = (prop-ref) -> (value) ->
    if value? then prop-ref! value
    prop-ref!!


# Convert a value to string.
export to-string = (value) -> '' + value


# Convert a value to int.
export to-int = (value) -> +value


# Convert a value to bool.
export to-bool = (value) -> !!value


# Compare a and b.
# Returns:
#   -1 if a < b
#    1 if a > b
#    0 if a = b
export compare = (a, b) ->
    | a < b     => -1
    | a > b     =>  1
    | otherwise =>  0


# Returns a function that compares a and b on a
# sequence of properties.
#
# For example:
#     compare-on (.name), (.size)
#     # a function that compares two arguments (a and b),
#     # first on .name and then on .size.
export compare-on = (...props) ->
    (a, b) ->
        for fn in props
            result = compare (fn a), (fn b)
            if result != 0 then return result

        return 0


# If value is thruthy then call fn with value.
export if-let = (value, fn) -->
    if value then fn value


# example:
#    >>> pluralize 1, 'case', 'cases'
#    'case'
#    >>> pluralize 3, 'case', 'cases'
#    'cases'
export pluralize = (count, singular, plural) ->
    if Math.abs(count) == 1 then singular else plural


# Append a suffix to a list or string in a pipeline.
#
# This is identical to a section: (++ suffix)
#
# example:
#     >>> ["a", "b"] |> append ["c"]
#     ["a", "b", "c"]
#     >>> "foo" |> append "bar"
#     "foobar"
export append = (suffix, list) --> list ++ suffix

# Prepend a suffix to a list or string in a pipeline.
#
# This is identical to a section: (prefix ++)
#
# example:
#     >>> ["b", "c"] |> prepend ["a"]
#     ["a", "b", "c"]
#     >>> "bar" |> prepend "foo"
#     "foobar"
export prepend = (prefix, list) --> prefix ++ list


# If value is not a list, make it a list,
# otherwise return it untouched.
#
# example:
#     >>> to-list 123
#     [123]
#     >>> to-list [1, 2, 3]
#     [1, 2, 3]
export to-list = (value) ->
    if is-type \Array value
    then value
    else [value]


# Shallow merge two objects.
# If keys from a and b overlap, b wins.
#
# example:
#    >>> merge-obj {a: 1, b: 2}, {b: 3, c: 4}
#    {a: 1, b: 3, c: 4}
export merge-obj = (a, b) -->
    pairs-to-obj (obj-to-pairs a) ++ (obj-to-pairs b)


# Test if value is a list.
export is-list = (value) ->
    is-type \Array value


# Deprecated: I think it's better to replace with [] (or just a comma, which works in most cases as well). - Lars
export a = -> [.. for &]

# Map fn over a list.
# fn is called with each value and index.
#
# example:
#     >>> indexed-map (+) ['a', 'b', 'c']
#     ['a0', 'b1', 'c2']
export indexed-map = (fn, list) --> list.map fn


# Remove a range from a list.
#
# example:
#     >>> remove-range 2, 2, [1 to 5]
#     [1,2,5]
export remove-range = (start, count, list) -->
    [prefix, suffix] = split-at start, list
    prefix ++ drop count, suffix


export contains = (sub, str) -->
    (str.indexOf sub) >= 0


export clamp = (min, max, value) -->
    if value < min then min
    else if value > max then max
    else value


export if-null = (fn, value) -->
    if value? then value else fn!


export or-else = (fn, value) -->
    if value then value else fn!


export if-empty = (fn, list) -->
    if empty list then fn! else list


export invert = (b) -> not b


# Make a property with a 'watch' on it.
# The change-handler is called when the property value is changed.
#
# example:
#     >>> p = prop-with-watch 0, (new-value) ->
#                 console.log new-value
#     p 123
#     # '123' is logged to console
export prop-with-watch = (init-value, change-handler) ->
    let prop = window.prop init-value
        on-set prop, change-handler


# Compare two strings case insensitive.
export compare-str-i = (a, b) ->
    if is-type \String, a and is-type \String, b then
        a.to-lower-case! == b.to-lower-case!
    else
        false


export to-lower = (.to-lower-case!)
export to-upper = (.to-upper-case!)
export dup = (a) -> [a, a]


# Conditional classes: returns a list of all keys that have a
# truthy value.
#
# example:
#    >>> cond-classes {a: true, b: false, c: true}
#    ['a', 'c']
export cond-classes = (classes) ->
    classes
    |> Obj.filter id
    |> Obj.keys


# Like String.join but then for lists.
#
# example:
#     >>> intersperse 'X', [1, 2, 3]
#     [1, 'X', 2, 'X', 3]
export intersperse = (sep, list) -->
    if empty list then
        []
    else if empty tail list then
        list
    else
        [head list] ++ [sep] ++ intersperse sep, tail list


# Perform a side effect and pass through the value.
export eff = (fn) -> (value) -> fn value; value


# Removes the value from a mutable array.
# Assumes that the value won't occur more than once.
export remove-from-array = (array, value) ->
    idx = array.index-of value
    if idx >= 0 then array.splice idx, 1
    return array


# Removes the value from a mutable array in a prop.
# Assumes that the value won't occur more than once.
export remove-from-array-prop = (prop, value) ->
    idx = prop!index-of value
    if idx >= 0 then prop!splice idx, 1
    return prop


# Add a value to mutable array, if it isn't in yet.
export add-unique-to-array = (array, value) ~>
    if value not in array then
        array.push value
    return array


# Returns a function that will map all the keys of object pairs.
# Hint: before calling this function, transform your object with
# obj-to-pairs, like so:
#
# example:
#     >>> {'a': 1, 'b': 2} |> obj-to-pairs |> map-keys (+ 'c')
#     [['ac', 1], ['bc', 2]]
export map-keys = (fn) ->
    map ([k, v]) -> [(fn k), v]


# Returns a function that will map all the values of object pairs.
#
# Hint: before calling this function, transform your object with
# obj-to-pairs, like so:
#
# Note: fn gets both the value and the key, in that order.
#
# example:
#     >>> {'a': 1, 'b': 2} |> obj-to-pairs |> map-values (+ 1)
#     [['a', 2], ['b', 3]]
export map-values = (fn) ->
    map ([k, v]) -> [k, (fn v, k)]


# Return the second element on a list.
# Undefined behaviour if the list doesn't contain a second element.
#
# example:
#     >>> second ['USA', 'Netherlands']
#     'Netherlands'
export second = tail >> first


# Group a list of pairs on the key.
# Returns a object.
export group-by-key = (pairs) ->
    pairs
    |> group-by first        # group on key
    |> Obj.map (map second)  # ditch all the keys


export map-kv = (key, value) ->
    map (item) -> [(key item), (value item)]


export list-to-pairs = (key) -> map-kv key, id


export is-int = is-type 'Number'


export maximum-of = (prop, list) -->
    list |> map prop |> filter is-int |> maximum


export sum-of = (prop, list) -->
    list |> map prop |> filter is-int |> sum


export compare-versions = (a, b) -->
    [a-head, ...a-tail] = a
    [b-head, ...b-tail] = b

    if a-head? then
        if b-head? then
            if diff = a-head - b-head then
                return diff
            else
                return compare-versions a-tail, b-tail
        else
            return 1
    else if b-head? then
        return -1
    else
        return 0


export fmt-version = (.join '.')


# Combination of sort-by and sort-with
export sort-by-with = (prop, compare) ->
    sort-with (a, b) -> compare (prop a), (prop b)


# Delay execution of a function for {ms} milliseconds.
#
# example:
#     >>> delay 1000, -> console.log '1 second elapsed'
export delay = (ms, fn) -->
    set-timeout fn, ms


# Run a function as soon as possible after processing the current
# computation (event).
export asap = delay 0


export redirect-to = (url) ->
    oninit: -> m.route.set url
    view: ->

# Creates a string that can be used for unique dynamic id attributes.
# Copied from the Portal.
export unique-id = ->
    return Math.random().toString(36).substr(2, 16);


export gauge = (target-element, min-value, max-value, value, arc-angle) ->
    opts = do
        angle: arc-angle # The span of the gauge arc
        lineWidth: 0.15 # The line thickness
        radiusScale: 1 # Relative radius
        pointer: do
            length: 0.5 # Relative to gauge radius
            strokeWidth: 0.035 # The thickness
            color: '#000000' # Fill color
        limitMax: false # If false, max value increases automatically if value > maxValue
        limitMin: false # If true, the min value of the gauge will be fixed
        # colorStart: '#6FADCF' # Colors
        # colorStop: '#8FC0DA' # just experiment with them
        strokeColor: '#E0E0E0' # to see which ones work best for you
        generateGradient: true
        highDpiSupport: true # High resolution support
        percentColors: [[0.0, '#DD0000'], [0.4, '#DD0000'], [0.5, '#FFB50A'], [0.68, '#FFE603'], [0.78, '#a9d70b'], [0.82, '#4CBB17']]
        staticLabels: do
            font: "10px sans-serif",  # Specifies font
            labels: [100],  # Print labels at these values
            color: "#000000",  # Optional: Label text color
            fractionDigits: 0  # Optional: Numerical precision. 0=round off.

    target = document.getElementById(target-element) # your canvas element
    gauge = new Gauge(target).setOptions(opts) # create sexy gauge!
    gauge.maxValue = max-value # set max gauge value
    gauge.setMinValue(min-value) # Prefer setter over gauge.minValue = 0
    gauge.animationSpeed = 5 # set animation speed (32 is default value)
    gauge.set(value) # set actual value
