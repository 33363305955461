import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, FieldCheckbox, FieldText, FieldTextArea} from '@bitstillery/common/components'
import {conditional, invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {DateTime} from 'luxon'
import {api, notifier} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

import {$s} from '@/app'

export class UpsertPricelist extends MithrilTsxComponent<any> {
    $v = {} as any
    watchers = [] as any

    async context_update() {
        Object.assign(this.$v, {
            end_date: validation([$s.context.data, 'end_date'], conditional(() => $s.context.data.supplier_price_list_status !== 'always_active', required())),
            start_date: validation([$s.context.data, 'start_date'], required()),
            supplier_artkey: validation([$s.context.data, 'supplier_artkey'], required()),
        })
    }

    async oninit() {
        this.watchers.push(watch($s.context, 'id', this.context_update.bind(this)))
        this.context_update()
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async save(vnode) {
        const data = {
            always_active: $s.context.data.supplier_price_list_status === 'always_active',
            description: $s.context.data.supplier_price_list_description,
            end_date: DateTime.fromISO($s.context.data.end_date).toISO(),
            relation_artkey: $s.context.data.supplier_artkey,
            start_date: DateTime.fromISO($s.context.data.start_date).toISO(),
        }

        let res

        if ($s.context.id) {
            res = await api.put(`discover/supplier-price-lists/${$s.context.id}`, data)
        } else {
            res = await api.post('discover/supplier-price-lists', data, true)
        }

        const {response, result, status_code} = res

        if (!response.ok) {
            notifier.notify(`There was an issue saving the price list: ${result.detail || status_code}`, 'warning')
            return
        }

        vnode.attrs.collection.update_context()

        if ($s.context.id) {
            notifier.notify('Successfully updated pricelist info.', 'success')
        } else {
            notifier.notify('Successfully created a new pricelist.', 'success')
            Object.assign($s.context, {data: null, query: null, id: null, name: 'data_card'})
            m.route.set(`/market/pricelists/${result.artkey}`)
        }
    }

    view(vnode:m.Vnode<any>) {
        const context = $s.context.data as any
        const invalid = invalid_fields(this.$v)

        return <div className="fieldset">
            <FieldText
                disabled={$s.context.id}
                help="The supplier of this pricelist"
                label="Supplier"
                ref={[context, 'supplier_name']}
                submit={(e, suggestion) => {
                    if (suggestion) {
                        context.supplier_artkey = suggestion.artkey
                    } else {
                        context.supplier_artkey = null
                    }
                }}
                suggestions={async(filter_text) => {
                    const {result} = await api.get(`discover/relations?search_terms=${filter_text}&only_suppliers=true`) as any
                    return result
                }}
                validation={this.$v.supplier_artkey}
            />

            <div className="field-group">

                <FieldText
                    help="The date from which the pricelist is valid"
                    label="Start date"
                    ref={[$s.context.data, 'start_date']}
                    type="date"
                    validation={this.$v.start_date}
                />

                <FieldText
                    help="The data after which the pricelist is no longer valid"
                    label="End date"
                    ref={[$s.context.data, 'end_date']}
                    type="date"
                    validation={this.$v.end_date}
                />
            </div>

            <FieldCheckbox
                computed={() => {
                    return $s.context.data.supplier_price_list_status === 'always_active'
                }}
                onclick={() => {
                    if ($s.context.data.supplier_price_list_status === 'always_active') {
                        $s.context.data.supplier_price_list_status = 'active'
                    } else {
                        $s.context.data.supplier_price_list_status = 'always_active'
                    }
                }}
                help="If this is checked, the pricelist will always be active, even if the start or end date is in the past."
                label="Always active"
            />

            <FieldTextArea
                label="Description"
                ref={[$s.context.data, 'supplier_price_list_description']}
            />

            <ButtonGroup>
                <Button
                    disabled={invalid.length}
                    onclick={() => {
                        this.save(vnode)
                    }}
                    text={`${$s.context.id ? 'Update' : 'Create'} Pricelist`}
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="success"
                />
            </ButtonGroup>
        </div>
    }
}
