import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import utils from 'utils.ls'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, ButtonGroup, FieldSelect, FieldText, FieldTextArea, Spinner} from '@bitstillery/common/components'
import {invalid_fields, required, validation} from '@bitstillery/common/lib/validation'
import {DataCard} from '@bitstillery/common/components'
import {api, events, notifier} from '@bitstillery/common/app'

import {CreateOrEditPurchaseOrderComponent} from '../edit_component'

import {$m, $s} from '@/app'
import {MoneyInput} from '@/components/decimal_input'
import {CountriesSelect, empty_option} from '@/components/html_components'
import {SupplierPriceListItem} from '@/models/suppliers'
import {PurchaseOrder} from '@/models/purchase_orders'
import {GiftBoxTypeDropDown} from '@/components/case_inputs'
import {NumberInput} from '@/components/input_numbers'
import {GetRelationPurchaseOrderResponseOutput} from '@/factserver_api/fact2server_api'

const AddMode = {
    CUSTOM: 'custom',
    MARKET: 'market',
    PURCHASE: 'purchase',
}

export interface AddToOrderTboItem {
    sales_order: {
        artkey: number
        buyer: {
            name: string
        }
    }
    artkey: number
    case_artkey: number
    number_of_cases: number
}

interface SpliAddToOrder {
    bid_per_case: string
    cases: number
    country_of_origin: string
    number_of_bottles_per_case: number | null
    original_price_per_case: string
    remark: string
    selected_po_artkey: number | null
    suggested_price_per_case: string
    cases_per_pallet: number | null
    cases_per_pallet_layer: number | null
    gift_box_type: string
}

export class AddToOrder extends MithrilTsxComponent<any> {
    custom_offer_item: any
    empty_purchase_order: typeof PurchaseOrder
    purchase_order_item: any
    purchase_order: any
    selected_spli: any

    data = (() => {
        const $f: SpliAddToOrder = {
            bid_per_case: '',
            cases: 0,
            country_of_origin: '',
            number_of_bottles_per_case: null,
            original_price_per_case: '',
            remark: '',
            selected_po_artkey: null,
            suggested_price_per_case: '',
            cases_per_pallet: null,
            cases_per_pallet_layer: null,
            gift_box_type: '',
        }

        return proxy({
            $f,
            error_msg: '',
            exclude_purchase_order_artkeys: [] as number[],
            loading: true,
            mode: '',
            purchase_orders: [] as GetRelationPurchaseOrderResponseOutput[],
            purchase_order_items: [],
            new_purchase_order: false,
            tax_label: '',
        })
    })()

    $v = {
        cases: validation([this.data.$f, 'cases'], required()),
        country_of_origin: validation([this.data.$f, 'country_of_origin'], required()),
    }

    oninit(vnode:m.Vnode<any>) {
        this.sync_cases(vnode)
        this.selected_spli = window.prop(null)

        if (vnode.attrs.selected_spli) {
            if (typeof vnode.attrs.selected_spli.bottle === 'function') {
                this.selected_spli(vnode.attrs.selected_spli)
            } else {
                this.selected_spli(new SupplierPriceListItem(vnode.attrs.selected_spli))
            }
        }

        this.custom_offer_item = vnode.attrs.custom_offer_item
        this.purchase_order_item = vnode.attrs.purchase_order_item

        this.data.exclude_purchase_order_artkeys = vnode.attrs.exclude_purchase_order_artkeys || []

        if (this.selected_spli()) {
            this.data.mode = AddMode.MARKET
            if (this.selected_spli().price_per_case()) {
                this.data.$f.number_of_bottles_per_case = this.selected_spli().number_of_bottles_per_case()
                // No need to look at price per bottle; price per case is always filled if possible.
                this.data.$f.original_price_per_case = this.selected_spli().price_per_case()
            }
            if (!vnode.attrs.tbo_item) {
                this.data.$f.number_of_bottles_per_case = this.selected_spli().number_of_bottles_per_case()
                this.data.$f.remark = this.selected_spli().aux_info()
            }
            this.data.$f.gift_box_type = this.selected_spli().case_gift_box_type ? this.selected_spli().case_gift_box_type() : this.selected_spli().gift_box_type()
            this.data.$f.country_of_origin = this.selected_spli().bottle().product().default_country_code()
            this.data.$f.number_of_bottles_per_case = this.selected_spli().number_of_bottles_per_case()
            this.data.$f.cases_per_pallet = this.selected_spli().cases_per_pallet()
            this.data.$f.cases_per_pallet_layer = this.selected_spli().cases_per_pallet_layer()
        } else if (this.custom_offer_item) {
            this.data.mode = AddMode.CUSTOM
            this.data.$f.number_of_bottles_per_case = this.custom_offer_item.number_of_bottles

            if (this.custom_offer_item.purchase_price_per_case) {
                this.data.$f.original_price_per_case = this.custom_offer_item.purchase_price_per_case
            }
        } else if (this.purchase_order_item) {
            this.data.mode = AddMode.PURCHASE
            this.data.$f.number_of_bottles_per_case = this.purchase_order_item.case().number_of_bottles()
            this.data.$f.original_price_per_case = this.purchase_order_item.was_bought_for()

            this.data.$f.suggested_price_per_case = this.purchase_order_item.suggested_price_per_case()
            this.data.$f.country_of_origin = this.purchase_order_item.country_of_origin()
        }

        if (vnode.attrs.tbo_item) {
            if (this.data.mode === AddMode.MARKET) {
                this.data.tax_label = vnode.attrs.tbo_item.case_tax_label
                this.data.$f.number_of_bottles_per_case = vnode.attrs.tbo_item.case_number_of_bottles
            }

            this.data.$f.cases = vnode.attrs.tbo_item.number_of_cases
            this.data.$f.remark = this.poi_remark(vnode.attrs.tbo_item)
        }

        if (!this.data.$f.cases) {
            this.data.$f.cases = vnode.attrs.cases
        }

        this.empty_purchase_order = new PurchaseOrder()
        this.empty_purchase_order.was_handled_by_artkey($s.identity.artkey)
        this.empty_purchase_order.supplier_artkey(vnode.attrs.supplier_artkey)

        this.purchase_order = window.prop(this.empty_purchase_order)

        this.query_orders(vnode, vnode.attrs.supplier_artkey)
    }

    onupdate(vnode) {
        this.sync_cases(vnode)
    }

    async query_orders(vnode, supplier_artkey, selected_po_artkey: number | null = null) {
        // If no supplier is selected, we don't need no orders.
        if (!supplier_artkey) return

        this.data.loading = true
        this.data.new_purchase_order = false

        const filters = {
            purchase_order_statuses: ['Saved'],
        }

        const {response, result} = await api.get<GetRelationPurchaseOrderResponseOutput[]>(
            `discover/relations/${supplier_artkey}/purchase-orders/collection-view?filters=${JSON.stringify(filters)}&sort_by=was_last_updated_on&sort_ascending=True`)
        this.data.loading = false
        if (!response.ok) {
            return
        }

        this.data.purchase_orders = result.filter((i:GetRelationPurchaseOrderResponseOutput) => !this.data.exclude_purchase_order_artkeys.includes(i.artkey))
        if (this.data.purchase_orders.length > 0) {
            // select either the purchase order with artkey == selected_po_artkey, or the first from the api.
            let selected_po = this.data.purchase_orders.find((i:any) => i.artkey === selected_po_artkey)

            const previous_selected_po_artkey = $s.supplier_price_list.add_to_order.last_selected.artkey
            if (previous_selected_po_artkey && !selected_po) {
                selected_po = this.data.purchase_orders.find((i:any) => i.artkey === previous_selected_po_artkey) || selected_po
            }
            await this.select_purchase_order(
                vnode,
                selected_po ? selected_po.artkey : this.data.purchase_orders[0].artkey,
            )
        }
    }

    async select_purchase_order(vnode, po_artkey) {
        this.data.$f.selected_po_artkey = po_artkey

        if (po_artkey) {
            const po = this.data.purchase_orders.find((i:any) => i.artkey === po_artkey)
            this.purchase_order(po)
            $s.supplier_price_list.add_to_order.last_selected.artkey = po_artkey
        } else {
            this.purchase_order(window.prop(this.empty_purchase_order))
        }

        this.data.purchase_order_items = []
        if (!this.purchase_order()) {
            this.purchase_order(this.empty_purchase_order)
        } else if (vnode.attrs.tbo_item) {
            const {result} = await api.post('purchase.core.get_purchase_order_items', {
                case_artkey: vnode.attrs.tbo_item.case_artkey,
                purchase_order_artkey: +po_artkey,
            })
            this.data.purchase_order_items = result
            if (this.data.purchase_order_items.length) {

                // The selected PO already has a POI with the same case, so
                // we are going to update that POI instead of creating a
                // new POI. Determine new remark, number of cases and
                // bid_per_case values for that existing POI.
                const poi = this.data.purchase_order_items[0]
                if (poi.remark) {
                    this.data.$f.remark = poi.remark + '\n' + this.poi_remark(vnode.attrs.tbo_item)
                }
                this.data.$f.cases = poi.number_of_cases + vnode.attrs.tbo_item.number_of_cases
                this.data.$f.bid_per_case = poi.was_bought_for
                this.data.$f.suggested_price_per_case = poi.suggested_price_per_case
                this.data.$f.country_of_origin = poi.country_of_origin
            } else {
                // The selected PO has no POI with the same case. Restore
                // the remark, number of cases and bid_per_case values to
                // their original values. This is necessary if an earlier
                // selected PO did have such a POI, after which these
                // values had been updated.
                this.data.$f.remark = this.poi_remark(vnode.attrs.tbo_item)

                this.data.$f.cases = vnode.attrs.tbo_item.number_of_cases
                this.data.$f.bid_per_case = ''
            }
        }
    }

    /**
     * Used to keep the amount of cases in sync with an external component; e.g.
     * splitting POI from split_purchase_order_item.tsx
     */
    sync_cases(vnode) {
        if (vnode.attrs.cases && this.data.$f.cases !== vnode.attrs.cases) {
            this.data.$f.cases = vnode.attrs.cases
        }
    }

    // Generate a remark for the purchase order item based on a tbo item.
    poi_remark(tbo_item) {
        return `TBO item for S${tbo_item.sales_order.artkey} ${tbo_item.sales_order.buyer.name}`
    }

    async add_to_order(vnode:m.Vnode<any>, close_side_panel: boolean) {
        const params = {
            country_of_origin: this.data.$f.country_of_origin,
            purchase_order_artkey: this.data.$f.selected_po_artkey,
            remark: this.data.$f.remark,
        }
        const bid_per_case = this.data.$f.bid_per_case ? +this.data.$f.bid_per_case : +this.data.$f.original_price_per_case
        let result, success

        if (this.data.mode === AddMode.MARKET) {
            Object.assign(params, {
                bid_per_case,
                cases: +this.data.$f.cases,
                for_tbo_item_artkey: vnode.attrs.tbo_item?.artkey,
                number_of_bottles_per_case: +this.data.$f.number_of_bottles_per_case,
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
                supplier_price_list_item_artkey: this.selected_spli().artkey(),
                tax_label: this.data.tax_label,
                gift_box_type: this.data.$f.gift_box_type,
                cases_per_pallet: this.data.$f.cases_per_pallet,
                cases_per_pallet_layer: this.data.$f.cases_per_pallet_layer,
            });

            ({result, success} = await api.post('purchase.core.add_spli_to_purchase_order', params))

            if (success) {
                const po_url = `<a href="#!/purchase-orders/manage/${this.data.$f.selected_po_artkey}">${this.data.$f.selected_po_artkey}</a>`
                notifier.notify(`Added "${this.selected_spli().bottle().product().name()} (${this.selected_spli().bottle().to_specs()})" to purchase order ${po_url}.`, 'success')
            }
        } else if (this.data.mode === AddMode.CUSTOM) {
            Object.assign(params, {
                bid_per_case,
                cases: +this.data.$f.cases,
                custom_offer_item_artkey: this.custom_offer_item.artkey,
                for_tbo_item_artkey: vnode.attrs.tbo_item?.artkey,
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
            });
            ({result, success} = await api.post('purchase.core.add_custom_offer_item_to_purchase_order', params))
            if (success) {
                notifier.notify(`Added "${this.custom_offer_item.name} (${this.custom_offer_item.volume} /
                ${this.custom_offer_item.alcohol_percentage}  / ${this.custom_offer_item.refill_status})" to purchase order ${this.purchase_order().reference}.`, 'success')
            }
        } else if (this.data.mode === AddMode.PURCHASE && vnode.attrs.tbo_item) {
            Object.assign(params, {
                bid_per_case,
                cases_for_poi: +this.data.$f.cases,
                cases_for_tbo_item: vnode.attrs.tbo_item.number_of_cases,
                lower_number_of_cases_in_poi: vnode.attrs.lower_number_of_cases_in_poi,
                original_tbo_item_artkey: vnode.attrs.tbo_item.artkey,
            });

            ({result, success} = await api.post('purchase.core.split_tbo_to_new_poi', params))
        } else if (this.data.mode === AddMode.PURCHASE) {
            Object.assign(params, {
                cases_for_poi: +this.data.$f.cases,
                original_poi_artkey: this.purchase_order_item.artkey(),
                suggested_price_per_case: this.data.$f.suggested_price_per_case,
                was_bought_for: this.data.$f.bid_per_case ? +this.data.$f.bid_per_case : +this.data.$f.original_price_per_case,
            });
            ({success} = await api.post('purchase.core.split_poi_to_new_poi', params))
        }

        if (success) {
            this.data.error_msg = ''
            await vnode.attrs.on_added_item(this.purchase_order().artkey)

            if (this.data.mode !== AddMode.PURCHASE) {
                events.emit('spli:added_to_purchase_order', this.purchase_order().artkey, this.selected_spli().artkey())
            }
            if (close_side_panel && vnode.attrs.on_close_panel) {
                vnode.attrs.on_close_panel()
            }
        } else {
            if (result.message) {
                this.data.error_msg = result.message
            } else {
                this.data.error_msg = 'Failed adding item to order. Please try again later or contact us if the problem persists.'
            }
        }
    }

    is_editing(vnode) {
        return (vnode.attrs.tbo_item && this.data.purchase_order_items.length)
    }

    needed_cases(vnode) {
        if (this.is_editing(vnode)) {
            return this.data.purchase_order_items[0].number_of_cases + vnode.attrs.tbo_item.number_of_cases
        } else if (vnode.attrs.tbo_item) {
            return vnode.attrs.tbo_item.number_of_cases
        }
        return 1
    }

    view(vnode) {
        const invalid = invalid_fields(this.$v)

        if (this.data.loading) return <Spinner className="view-spinner" />

        return <div className="c-add-to-order">
            {this.data.error_msg && <div className="alert alert-danger alert-dismissible">
                <button
                    className="close"
                    data-dismiss="alert"
                    onclick={() => this.data.error_msg = ''}
                    type="button"
                >
                    <span>×</span>
                </button>
                <span>{this.data.error_msg}</span>
            </div>}

            <div className="field-group vertical context-well">
                <FieldSelect
                    label="Purchase Order"
                    onchange={(value) => {
                        this.select_purchase_order(vnode, value)
                    }}
                    ref={[this.data.$f, 'selected_po_artkey']}
                    options={this.data.purchase_orders.map((i) => [i.artkey, `${i.reference} - ${utils.formatDate(i.created_on)} - ${i.is_communicated_on ? 'Closed' : 'Open'} (${i.was_bought_in})`])}
                    placeholder="New Purchase Order"
                />
                {(() => {
                    if (this.is_editing(vnode)) {
                        return <div className="alert alert-warning">
                            A purchase order item already exists for this product, editing the record instead.'
                        </div>
                    }
                })()}
            </div>

            {!this.data.$f.selected_po_artkey ? <CreateOrEditPurchaseOrderComponent
                relation_artkey={vnode.attrs.supplier_artkey}
                is_used_as_inline_form={true}
                onsaved={(artkey) => {
                    this.query_orders(vnode, vnode.attrs.supplier_artkey, artkey)
                }}
            /> : (() => {
                return <div className="fieldset">
                    <div className="field-group">
                        <FieldText
                            disabled={(() => {
                                return !(this.selected_spli() && !vnode.attrs.tbo_item)
                            })()}
                            label="Bottles per case"
                            min={1}
                            onafterupdate={(number_of_bottles_per_case) => {
                                this.data.$f.number_of_bottles_per_case = number_of_bottles_per_case

                                if (this.selected_spli().price_per_case() && this.selected_spli().number_of_bottles_per_case()) {
                                    // This should avoid issues with rounding.
                                    const amount = String((this.selected_spli().price_per_case() / +this.selected_spli().number_of_bottles_per_case()) * number_of_bottles_per_case)
                                    this.data.$f.original_price_per_case = amount
                                    // Empty == bid the original price.
                                    this.data.$f.bid_per_case = ''
                                } else if (this.selected_spli().price_per_bottle()) {
                                    this.data.$f.original_price_per_case = String(this.selected_spli().price_per_bottle() * number_of_bottles_per_case)
                                    // Empty == bid the original price.
                                    this.data.$f.bid_per_case = ''
                                }

                            }}
                            ref={[this.data.$f, 'number_of_bottles_per_case']}
                            required={true}
                            type="number"
                        />
                        <CountriesSelect
                            label='Country of origin'
                            required={true}
                            selected={this.data.$f.country_of_origin}
                            onchange={(value: string) => this.data.$f.country_of_origin = value}
                        />
                    </div>
                    <GiftBoxTypeDropDown
                        label="Giftbox Type"
                        empty_option={empty_option('')}
                        selected={this.data.$f.gift_box_type}
                        onchange={(value: string) => (this.data.$f.gift_box_type = value)}
                    />
                    <div className="field-group">
                        <NumberInput
                            label='Cases per pallet'
                            value={this.data.$f.cases_per_pallet}
                            oninput={(value: number) => {
                                this.data.$f.cases_per_pallet = value
                            }}
                        />
                        <NumberInput
                            label='Cases per pallet layer'
                            value={this.data.$f.cases_per_pallet_layer}
                            oninput={(value: number) => {
                                this.data.$f.cases_per_pallet_layer = value
                            }}
                        />
                    </div>
                    <div className="field-group">
                        <MoneyInput
                            label='Original price per case'
                            value={this.data.$f.original_price_per_case}
                            on_value={(price) => this.data.$f.original_price_per_case = price}
                            currency={(() => {
                                if (this.selected_spli()) {
                                    return this.selected_spli().currency()
                                }
                                return this.purchase_order().was_bought_in

                            })()}
                            disabled={true}
                        />
                        <MoneyInput
                            label='Bid per case'
                            value={this.data.$f.bid_per_case}
                            on_value={(price) => this.data.$f.bid_per_case = price}
                            currency={this.purchase_order().was_bought_in}
                            placeholder={this.data.$f.original_price_per_case ? Number(this.data.$f.original_price_per_case).toFixed(2) : ''}
                            required={!this.data.$f.original_price_per_case}
                        />
                    </div>
                    <div className="field-group">
                        {!vnode.attrs.tbo_item && <MoneyInput
                            label='Suggested sales price'
                            value={this.data.$f.suggested_price_per_case}
                            on_value={(price) => this.data.$f.suggested_price_per_case = price}
                            currency={this.purchase_order().was_bought_in}
                            placeholder={''}
                        />}
                        <FieldText
                            disabled={this.data.mode === AddMode.PURCHASE}
                            label="Cases to purchase"
                            min={0}
                            ref={[this.data.$f, 'cases']}
                            required={true}
                            step={1}
                            type="number"
                            validation={this.$v.cases}
                        />
                    </div>

                    <FieldTextArea
                        label="Remark"
                        ref={[this.data.$f, 'remark']}
                    />

                    {(() => {
                        if (!vnode.attrs.tbo_item) return
                        if (this.data.purchase_order_items.length) {
                            return <div className="help">
                                {`To complete the sales TBO item, enter at least ${this.data.purchase_order_items[0].number_of_cases} + ${vnode.attrs.tbo_item.number_of_cases} (tbo) = ${this.needed_cases(vnode)} cases.`}
                            </div>
                        } else {
                            return <div className="help">
                                {`To complete the sales TBO item, enter at least ${this.needed_cases(vnode)} cases.`}
                            </div>
                        }

                    })()}
                    <ButtonGroup>
                        {vnode.attrs.mode === 'panel' && <Button
                            icon="stop"
                            onclick={vnode.attrs.oncancel}
                            text="Cancel"
                        />}

                        <Button
                            disabled={invalid.length}
                            icon="plus"
                            onclick={() => this.add_to_order(vnode, true)}
                            text={(() => {
                                if (vnode.attrs.tbo_item && this.data.$f.cases < this.needed_cases(vnode)) {
                                    if (this.is_editing(vnode)) return 'Split sales TBO item and update purchase order'
                                    return 'Split sales TBO item and add to purchase order'
                                }

                                if (this.is_editing(vnode)) {
                                    return 'Update Purchase Order'
                                } return 'Add to Purchase Order'
                            })()}
                            type="success"
                        />
                    </ButtonGroup>
                </div>
            })()}

            {this.data.mode === AddMode.MARKET && vnode.attrs.mode !== 'panel' && <DataCard
                model={{data: [
                    {label: 'Supplier', value: this.selected_spli().supplier_price_list().supplier().name()},
                    {label: 'Product', value: this.selected_spli().bottle().product().name()},
                    {label: 'Specs', value: this.selected_spli().bottle().to_specs()},
                    {label: 'Bottles per case', value: this.selected_spli().number_of_bottles_per_case()},
                    {label: 'Giftbox', value: this.selected_spli().case_gift_box_type ? this.selected_spli().case_gift_box_type() : this.selected_spli().gift_box_type()},
                    {label: 'Quantity cases', value: this.selected_spli().number_of_cases()},
                    {label: 'Quantity bottles', value: this.selected_spli().number_of_bottles()},
                    {label: 'Price per case', value: this.selected_spli().price_per_case()},
                    {label: 'Price per bottle', value: this.selected_spli().price_per_bottle()},
                    {label: 'Customs status', value: this.selected_spli().customs_status()},
                    {label: 'Aux info', value: this.selected_spli().aux_info()},
                    {label: 'Pricelist incoterm', value: this.selected_spli().incoterm()},
                    {label: 'Availability status', value: this.selected_spli().availability_status()},
                ]}}
            />}

            {this.data.mode === AddMode.CUSTOM && vnode.attrs.mode !== 'panel' && <DataCard
                model={{data: [
                    {label: 'Supplier', value: this.custom_offer_item.supplier.name},
                    {label: 'Product', value: this.custom_offer_item.name},
                    {label: 'Specs', value: `${this.custom_offer_item.volume} / ${this.custom_offer_item.alcohol_percentage}  / ${this.custom_offer_item.refill_status}`},
                    {label: 'Bottles per case', value: this.custom_offer_item.number_of_bottles},
                    {label: 'Giftbox', value: this.custom_offer_item.gift_box_type},
                    {label: 'Customs status', value: this.custom_offer_item.customs_status},
                    {label: 'Tax label', value: this.custom_offer_item.tax_label},
                    {label: 'Best before date', value: utils.formatDate(this.custom_offer_item.best_before_date)},
                    {label: 'Features', value: $m.data.item_tag.get_tag_names(this.custom_offer_item.item_tags || []).join(' / ')},
                    {label: 'Incoterm', value: this.custom_offer_item.incoterm},
                    {label: 'Quantity (cases)', value: this.custom_offer_item.quantity},
                ]}}
            />}

            {(this.data.mode === AddMode.PURCHASE && vnode.attrs.tbo_item && vnode.attrs.mode !== 'panel') && <DataCard
                model={{data: [
                    {label: 'Supplier', value: this.purchase_order_item.purchase_order().supplier().name()},
                    {label: 'Product', value: this.purchase_order_item.case().bottle().product().name()},
                    {label: 'Specs', value: `${this.purchase_order_item.case().bottle().volume()} / ${this.purchase_order_item.case().bottle().alcohol_percentage()}  / ${this.purchase_order_item.case().bottle().refill_status()}`},
                    {label: 'Bottles per case', value: this.purchase_order_item.case().number_of_bottles()},
                    {label: 'Customs status', value: this.purchase_order_item.case().number_of_bottles()},
                    {label: 'Gift box type', value: this.purchase_order_item.case().gift_box_type()},
                    {label: 'Tax label', value: this.purchase_order_item.case().tax_label()},
                    {label: 'Best before date', value: utils.formatDate(this.purchase_order_item.case().best_before_date())},
                    {label: 'Features', value: $m.data.item_tag.get_tag_names(this.purchase_order_item.case().item_tags() || []).join(' / ')},
                    {label: 'Incoterm', value: `${this.purchase_order_item.purchase_order().incoterm()} - ${this.purchase_order_item.purchase_order().incoterm_location()}`},
                    {label: 'Quantity (cases)', value: vnode.attrs.tbo_item.number_of_cases},
                ]}}
            />}
        </div>
    }
}
