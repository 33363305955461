import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as Charts,
    LineController,
    LineElement,
    LinearScale,
    PieController,
    PointElement,
    TimeScale} from 'chart.js'
import 'chartjs-adapter-luxon'

Charts.register(
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    LineController,
    LineElement,
    LinearScale,
    PieController,
    PointElement,
    TimeScale,
)

export let Chart = Charts
