m = require 'mithril'
api = require 'api.ls'
{button-with-icon, text-button} = require '@/components/buttons.ls'
inputs = require '@/components/inputs'
{Offer, OfferType, Priority} = require '@/models/offers'
{IncotermsDropDown} = require '@/components/incoterms'
{IncotermsDropDownData} = require '@/factserver_api/incoterms_api'
app = require('@/app')

export class CloneOffer
    (vnode) ->
        {@source_offer, @done, @cancel} = vnode.attrs

        @copy_products = window.prop true
        @copy_relations = window.prop false
        @published = window.prop false
        @saving = window.prop false

        # Initialize new offer with some initial values.
        @new_offer = window.prop new Offer
        @new_offer!title @source_offer!title
        @new_offer!remark @source_offer!remark
        @new_offer!incoterm @source_offer!incoterm
        @new_offer!incoterm_location @source_offer!incoterm_location
        @new_offer!offer_type @source_offer!offer_type

        @use_default_incoterm = window.prop @new_offer!incoterm! is null

    save: (e) ~>
        e.preventDefault!

        # Basic form validation.
        today = new Date!
        start_date = new Date(@new_offer!start_date!)
        end_date = new Date(@new_offer!end_date!)
        if end_date < today.setDate(today.getDate() - 1)
            app.notifier.notify 'Could not clone the offer; the end date may not be in the past.', 'danger'
            return

        if start_date > end_date
            app.notifier.notify 'Could not clone the offer; the start date may not be later than the end date.', 'danger'
            return

        if @use_default_incoterm!
            @new_offer!incoterm ''
            @new_offer!incoterm_location ''

        if @new_offer!incoterm! and not @new_offer!incoterm_location!
            app.notifier.notify 'Could not clone the offer; when entering an incoterm, also enter an incoterm location.', 'danger'
            return

        if @new_offer!incoterm_location! and not @new_offer!incoterm!
            app.notifier.notify 'Could not clone the offer; when entering an incoterm location, also enter an incoterm.', 'danger'
            return

        if not @saving!
            # Everything is OK so far; clone the offer.
            @saving true
            data = do
                source_offer_artkey: @source_offer!artkey
                title: @new_offer!title!
                remark: @new_offer!remark!
                start_date: @new_offer!start_date!
                end_date: @new_offer!end_date!
                incoterm: @new_offer!incoterm!
                incoterm_location: @new_offer!incoterm_location!
                offer_type: @new_offer!offer_type!
                priority: @new_offer!priority!
                published: @published!
                copy_products: @copy_products!
                copy_relations: @copy_relations!

            api.call-and-then 'offer.clone_offer', data, do
                success: (resp) ~>
                    app.$m.common.observable.broadcast 'offer_updated'
                    app.notifier.notify "Successfully cloned custom offer \"#{@source_offer!title}\" into \"#{resp.result.title}\".", 'success'
                    @done!
                failure: (resp) ~>
                    app.notifier.notify resp.message, 'danger'
                    @saving false

    view: -> m '.c-clone-offer',

        m 'form.flex-form' {onsubmit: @save},
            m '.fieldset.large',

                m '.field-readonly',
                    m '.key' 'Clone from:'
                    m '.value' @source_offer!title

                inputs.text @new_offer!title, {
                    label: 'Title',
                    required: true,
                    placeholder: 'Title'
                }

                inputs.text @new_offer!remark, {
                    label: 'Remark',
                    placeholder: 'Remark'
                }

                m '.field-group',
                    inputs.date @new_offer!start_date, {
                        label: 'Start date',
                        required: true
                    }

                    inputs.date @new_offer!end_date, {
                        label: 'End date',
                        required: true
                    }

                m '.field',
                    m 'label' 'Incoterm to use'
                    inputs.radio @use_default_incoterm, [
                        {value: true, description: 'Default'}
                        {value: false, description: 'Override'}
                    ]

                if not @use_default_incoterm!
                    m '.field',
                        m 'label' 'Incoterm'
                        m IncotermsDropDown,
                            selected_incoterm: @new_offer!incoterm!
                            get_all_for_drop_down_response$: IncotermsDropDownData.incoterms()
                            onchange: (incoterm) ~> @new_offer!incoterm incoterm

                if not @use_default_incoterm!
                    inputs.text @new_offer!incoterm_location, {
                        label: 'Incoterm location',
                        placeholder: 'Incoterm location',
                    }

                m '.field',
                    m 'label' 'Offer type'
                    inputs.radio @new_offer!offer_type, \
                        [{value: value, description: description} for value, description of OfferType.offer_type_options]

                m '.field',
                    m 'label' 'Priority'
                    inputs.radio @new_offer!priority, \
                        [{value: +value, description: description} for value, description of Priority.all]

                m 'hr'

                inputs.checkbox @copy_products, {
                    label: 'Copy products'
                }

                inputs.checkbox @copy_relations, {
                    label: 'Copy relations'
                }

                inputs.checkbox @published, {
                    label: 'Published',
                }

            m '.btn-toolbar',
                text-button 'Cancel', do
                    class: 'btn-default'
                    onclick: @cancel

                button-with-icon 'Clone offer', 'duplicate', do
                    class: 'btn-success'
                    disabled: @saving!
