import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {ProcessStepper} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

export class ProcessVouchers extends MithrilTsxComponent<unknown> {

    constructor(vn) {
        super()
        this.data = proxy({
            active: vn.attrs.active,
            current: 0,
        })
    }

    data = proxy({
        current: 0,
    })

    view(vnode:m.Vnode<any>): m.Children {
        return <ProcessStepper
            data={this.data}
            process={[
                {
                    completed: this.data.current > 0,
                    id: 'list',
                    path: '/offer/vouchers',
                    text: $t('processes.vouchers.list'),
                },
                {
                    completed: this.data.current > 1,
                    id: 'upsert',
                    path: vnode.attrs.context.artkey ? `/offer/vouchers/${vnode.attrs.context.artkey}/edit` : '/offer/vouchers/create',
                    text: vnode.attrs.context.artkey ? $t('processes.vouchers.edit') : $t('processes.vouchers.create'),
                },
                {
                    completed: this.data.current > 2,
                    disabled: !vnode.attrs.context.artkey,
                    id: 'relations',
                    path: `/offer/vouchers/${vnode.attrs.context.artkey}/relation-selection`,
                    text: $t('processes.vouchers.relations'),
                },
            ]}
            variant="horizontal"
        />
    }
}
