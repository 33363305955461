import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from './api'

export class SystemParameterApi {
    api = new Api()

    update(name: string, new_value: string, new_description: string): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request('discover/data/system-parameters', {
            name: name,
            new_value: new_value,
            new_description: new_description,
        })
    }
}
