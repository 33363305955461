{Case, Item} = require '@/models/stock'
{PurchaseOrderItem} = require '@/models/purchase_orders'
{Product} = require '@/models/products'

export class Bottle
    (artkey, volume, alcohol_percentage, refill_status, product_artkey, purchase_order_items, items, cases, excise_nl, product, cbs_code) ->
        @artkey = window.prop artkey
        @product = window.prop (if product then product else new Product)
        @product_artkey = window.prop product_artkey
        @volume = window.prop if volume? then (+volume).toFixed(1) else ''
        @alcohol_percentage = window.prop if alcohol_percentage? then (+alcohol_percentage).toFixed(1) else ''
        @refill_status = prop(refill_status or 'ref')
        @excise_nl = window.prop excise_nl
        @cbs_code = window.prop cbs_code

        @purchase_order_items = window.prop []
        if purchase_order_items
            @purchase_order_items [new PurchaseOrderItem purchase_order_item for purchase_order_item in purchase_order_items]

        @cases = window.prop []
        if cases
            @cases [new Case kase for kase in cases]

        @items = window.prop []
        if items
            @items [new Item item for item in items]

        @_refill_status = window.prop @refill_status!
        @_alcohol_percentage = window.prop @alcohol_percentage!
        @_volume = window.prop @volume!

    dirty: ->
        @_refill_status! != @refill_status! or \
        @_alcohol_percentage! != @alcohol_percentage! or \
        @_volume! != @volume!

    reset: ~>
        @alcohol_percentage @_alcohol_percentage!
        @refill_status @_refill_status!
        @volume @_volume!

    apply_update: ~>
        @_alcohol_percentage @alcohol_percentage!
        @_refill_status @refill_status!
        @_volume @volume!

    to_string: ~>
        # (!) weare.product_categories doesn't exist; assumption: this function call never happens
        ["#{@product!name!}", "#{@volume!} cl", "#{@alcohol_percentage!} %",\
        @refill_status!, weare.product_categories[@product!category!toLowerCase!]].join ', '

    to_specs: ~>
        [(+@volume!).toFixed(1) + 'cl', (+@alcohol_percentage!).toFixed(1) + '%', @refill_status!].join ' / '

    display_volume: ~>
        (+@volume!).toFixed(1) + 'cl'

    display_alcohol_percentage: ~>
        (+@alcohol_percentage!).toFixed(1) + '%'


export class BottlesDataModel
    ->

    create_bottle: (bottle, product) ~>
        new Bottle bottle.artkey, bottle.volume, bottle.alcohol_percentage, \
            bottle.refill_status, bottle.product_artkey, bottle.purchase_order_items, \
            bottle.items, bottle.cases, bottle.excise_nl, product, bottle.cbs_code

    create_bottles: (bottles, product) ~>
        [@create_bottle bottle, product for bottle in bottles]
