import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Observable} from 'rxjs'

import {DropDownOption, DropDownWithSearch, empty_option} from './html_components'
import {MaybeObservable} from './relation'

import {GetContactPersonDropdownData} from '@/factserver_api/contact_person_api'

interface ContactPersonDropDownAttrs {
    selected_contact_person_artkey: string
    onchange: (contact_person_artkey: string) => unknown
    disabled?: boolean
    /**
     * The data source for the drop down options. Fill eg with api.get_contact_person_dropdown_data()
     **/
    get_contact_person_dropdown_data$: Observable<GetContactPersonDropdownData[]>
}

/**
 * ContactPerson dropdown with search. Note that this implementation does not cache the contact persons.
 * This is not done because the contact persons drop down is not heavily used and the data changes
 * during the session.
 */
export class ContactPersonDropdown extends MithrilTsxComponent<ContactPersonDropDownAttrs> {
    contact_persons: GetContactPersonDropdownData[] = []

    oncreate(vnode: m.Vnode<ContactPersonDropDownAttrs>): void {
        vnode.attrs.get_contact_person_dropdown_data$.subscribe(
            (contact_persons) => (this.contact_persons = contact_persons),
        )
    }

    view(vnode: m.Vnode<ContactPersonDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_contact_person_dropdown_data$}>
                <DropDownWithSearch
                    onchange={vnode.attrs.onchange}
                    empty_option={empty_option('Select a contact person')}
                    disabled={vnode.attrs.disabled}
                    selected={vnode.attrs.selected_contact_person_artkey}
                >
                    {this.contact_persons?.map((contact_person) => (
                        <DropDownOption value={`${contact_person.artkey}`}>
                            {contact_person.name} ({contact_person.supplier_name})
                        </DropDownOption>
                    ))}
                </DropDownWithSearch>
            </MaybeObservable>
        )
    }
}
