import api from 'api.ls'
import {buttonWithIcon} from 'components/buttons.ls'
import {countries} from '@bitstillery/common/lib/countries'
import {current_account_slug} from '@bitstillery/common/account/account'
import {FixedTableHeader} from 'components/table/fixed-header.ls'
import m from 'mithril'
import {compareStrI, download_binary_file_from_base64_str, formatDate} from 'utils.ls'
import {Collection} from 'components/collection/collection.ls'

import {FilterSidebar} from './sidebar'
import {CommunicationView} from './communication'
import {DefaultView} from './default'
import {DeliveryConditionsView} from './delivery_conditions'

import {$m, $s} from '@/app'

export default class SupplierList {

    is_downloading_excel = false

    constructor() {
        this.collection = new Collection({
            additional_params: this.additional_params.bind(this),
            api_function_name: 'suppliers.get_all',
            default_sort_by: 'name',
            default_sort_order: 'asc',
            filter_function: this.is_match.bind(this),
            query_limit: 25,
        })

        this.fields = prop([])

        this.currentTab = prop('default')
        this.set_tab(this.currentTab())

        // Initialize filters. Define them as attributes of the collection;
        // this helps to preserve their values when navigating back to this page.
        this.sidebar_filters = {
            active_sales_promotions: [],
            buyers: '',
            client_status: [],
            company_type: [],
            country: [],
            currency: [],
            customs_status_visibility: [],
            operates_online: '',
            portal_level: [],
            price_list_frequency: [],
            purchase_manager: [],
            relation_filter: '',
            sales_account: [current_account_slug()],
            sales_manager: [],
            should_receive_offer_mails: '',
            should_receive_purchase_mails: '',
            suppliers: '',
            has_valid_signed_trademark_declaration: '',
            has_valid_lexis_nexis: '',
        }

        for (const [key, value] of Object.entries(this.sidebar_filters)) {
            if (!this.collection[key]) {
                this.collection[key] = prop(value)
            }
        }
    }

    additional_params() {
        const data = {
            user_artkey: $s.identity.artkey,
        }

        for (const key of Object.keys(this.sidebar_filters)) {
            data[key] = this.collection[key]()
        }

        return data
    }

    check_country(country_code, term) {
        const term_cc = this.get_country_code(term)
        const match_cc = term_cc ? term_cc : term
        return compareStrI(country_code, match_cc)
    }

    create_vat_id_report() {
        api.callAndThen(
            'vatid.relation_vat_id_report',
            {},
            {
                success: (resp) => {
                    download_binary_file_from_base64_str(
                        resp.result,
                        `VAT ID Relation Report ${formatDate(new Date())}.xlsx`,
                    )
                },
            },
        )
    }

    export_to_excel() {
        this.is_downloading_excel = true
        const data = Object.assign(
            {
                ascending: this.collection.ascending(),
                search_terms: this.collection.search_terms(),
                sort_by: this.collection.sort_by(),
                sort_order: this.collection.sort_order(),
            },
            this.collection.additional_params(),
        )

        api.call('suppliers.export_to_excel', data, (resp) => {
            this.is_downloading_excel = false
            if (resp.success) {
                download_binary_file_from_base64_str(resp.result, `Relations Export ${formatDate(new Date())}.xlsx`)
            } else {
                $m.common.generic_error_handler()
            }
        })
    }

    get_country_code(name) {
        for (const [country_code, country_name] of Object.entries(countries)) {
            if (compareStrI(country_name, name)) {
                return country_code
            }
        }

        return null
    }

    is_match(relation, term) {
        if (this.collection.relation_filter() === 'mine') {
            const me = $s.identity.artkey
            if (me !== relation.purchase_manager.artkey && me !== relation.sales_manager.artkey) {
                return false
            }
        }

        if (this.collection.buyers() === 'true' && !relation.is_buyer) return false
        if (this.collection.buyers() === 'false' && relation.is_buyer) return false
        if (this.collection.suppliers() === 'true' && !relation.is_supplier) return false
        if (this.collection.suppliers() === 'false' && relation.is_supplier) return false

        return [
            relation.name.toLowerCase().includes(term),
            relation.purchase_manager.profile.name.toLowerCase().includes(term),
            relation.sales_manager.profile.name.toLowerCase().includes(term),
            (relation.street_address || '').toLowerCase().includes(term),
            (relation.city || '').toLowerCase().includes(term),
            (relation.url || '').toLowerCase().includes(term),
            (relation.company_type || '').toLowerCase().includes(term),
            (relation.client_status || '').toLowerCase().includes(term),
            +relation.relation_nr === +term,
            this.check_country(relation.country_code, term),
            relation.contact_persons.filter((i) => {
                return i.emailaddress === null || i.emailaddress.toLowerCase().includes(term)
            }).length > 0,
            relation.contact_persons.filter((i) => {
                return i.name === null || i.name.toLowerCase().includes(term)
            }).length > 0,
        ].some((i) => i)
    }

    oncreate() {
        this.collection.init()
        $m.common.observable.subscribe('suppliers_updated', this, this.collection.requery)
    }

    set_tab(tabName) {
        if (tabName === 'default') {
            this.fields([
                {id: 'name', name: 'Name', sortable: true},
                {id: 'street_address', name: 'Address', sortable: true},
                {id: 'zip_code', name: 'Zip code', sortable: true},
                {id: 'city', name: 'City', sortable: true},
                {id: 'country_code', name: 'Country', sortable: true},
                {id: 'company_type', name: 'Company Type', sortable: true},
                {id: 'operates_online', name: 'Online', sortable: true},
                {id: 'client_status', name: 'Client Status', sortable: true},
                {id: 'is_verified', name: 'Verified', sortable: true},
                {id: 'purchase_manager.profile.name', name: 'Purchase manager', sortable: false},
                {id: 'sales_manager.profile.name', name: 'Sales manager', sortable: false},
                {id: 'relation_nr', name: 'Rel. nr', sortable: true},
                {id: 'contacts', name: 'Contacts', sortable: false},
            ])
        } else if (tabName === 'delivery-conditions') {
            this.fields([
                {id: 'name', name: 'Name', sortable: true},
                {id: 'portal_customs_visibility', name: 'Customs Status', sortable: true},
                {id: 'currency', name: 'Currency', sortable: true},
                {id: 'incoterm', name: 'Incoterm', sortable: true},
                {id: 'transport_costs_per_case', name: 'Transport costs / cs', sortable: true},
                {id: 'minimum_order_amount', name: 'Minimum order amount', sortable: true},
                {id: 'price_preference', name: 'Price Preference', sortable: true},
                {id: 'price_markup_percentage', name: 'Price markup', sortable: true},
                {id: 'memo', name: 'Memo', sortable: true},
                {id: 'portal_level', name: 'Portal level', sortable: true},
            ])
        }

        this.currentTab(tabName)
    }

    view() {
        return (
            <div className="c-crm-relations view filter-sidebar-wrapper">
                <FilterSidebar collection={this.collection} />
                <div className="filter-result">
                    <div className="btn-toolbar">
                        {buttonWithIcon('Create Relation', 'plus', {
                            class: 'btn-default',
                            onclick: () => m.route.set('/crm/relations/create'),
                        })}
                        {buttonWithIcon('Export to Excel', 'fa-file-excel', {
                            class: 'btn-default',
                            disabled: this.is_downloading_excel,
                            onclick: this.export_to_excel.bind(this),
                        })}
                        {buttonWithIcon('Generate VAT ID relation report', 'fa-check-double', {
                            class: 'btn-default',
                            onclick: this.create_vat_id_report.bind(this),
                        })}
                    </div>

                    <ul className="nav nav-tabs">
                        <li
                            onclick={() => this.set_tab('default')}
                            className={this.currentTab() === 'default' ? 'active' : ''}
                        >
                            <a>
                                <i className="fa fa-th-list" /> Default
                            </a>
                        </li>
                        <li
                            onclick={() => this.set_tab('delivery-conditions')}
                            className={this.currentTab() === 'delivery-conditions' ? 'active' : ''}
                        >
                            <a>
                                <i className="fa fa-money-bill-alt" /> Delivery Conditions
                            </a>
                        </li>
                        <li
                            onclick={() => this.set_tab('communication')}
                            className={this.currentTab() === 'communication' ? 'active' : ''}
                        >
                            <a>
                                <i className="fa fa-satellite-dish" /> Communication
                            </a>
                        </li>
                    </ul>

                    <FixedTableHeader>
                        <table className="c-table table search-table clickable">
                            {['default', 'delivery-conditions'].includes(this.currentTab()) && (
                                <thead className="thead-default">
                                    <tr>
                                        {this.fields().map((field) => {
                                            if (field.sortable) {
                                                return (
                                                    <th
                                                        className="sortable"
                                                        onclick={this.collection.sort.bind(this, field.id)}
                                                    >
                                                        {field.name} {this.collection.sort_icon(field.id)}
                                                    </th>
                                                )
                                            } else {
                                                return (
                                                    <th>
                                                        {field.name} {this.collection.sort_icon(field.id)}
                                                    </th>
                                                )
                                            }
                                        })}
                                    </tr>
                                </thead>
                            )}

                            {this.currentTab() === 'default' && <DefaultView collection={this.collection} />}
                            {this.currentTab() === 'delivery-conditions' && (
                                <DeliveryConditionsView collection={this.collection} />
                            )}
                            {this.currentTab() === 'communication' && (
                                <CommunicationView collection={this.collection} />
                            )}
                        </table>
                    </FixedTableHeader>
                </div>
            </div>
        )
    }
}
