m = require 'mithril'
{head, sort-by} = require 'prelude-ls'
api = require 'api.ls'
{a}: utils = require 'utils.ls'
{MoneyInput} = require '@/components/decimal_input'
{button-with-icon, icon-button} = require '@/components/buttons.ls'
AutoComplete = require '@/components/collection/autocomplete.ls'
{SalesOrderItem} = require '@/sales_orders/models.ls'
{Bottle} = require '@/models/bottles'
{Item} = require '@/models/stock'
{convert_from_currency_to_euro, convert_from_source_to_target, convert_from_euro_to_currency} = require '@/factserver_api/currencies'
app = require('@/app')
{Spinner} = require '@bitstillery/common/components'

export class AddItemToSalesOrderClassic
    (vnode) ->
        @sales_order = vnode.attrs.sales_order
        @sales_order_item_added = vnode.attrs.sales_order_item_added
        @sellable_products = window.prop []
        @is_collapsed = true
        @is_focused = @is_collapsed

        @init!

        @loading_products = window.prop false
        @loading = window.prop false

        @autocomplete_ctrl = new AutoComplete.controller do
            input_container_id: '#search_input_container'
            on_submit_suggestion: @on_submit_suggestion

        # Start loading the products immediately.
        @load_sellable_products!

    init: ~>
        @product = window.prop ''
        @product_name = window.prop ''

        @bottle = window.prop new Bottle
        @sellable_bottles = window.prop []

        @sales_order_item = window.prop new SalesOrderItem
        @sales_order_item!sales_order = @sales_order

    reset_search: ~>
        @init!
        @autocomplete_ctrl.oninput ''

    load_sellable_products: ~>
        if @sellable_products!length == 0
            @loading_products true
            api.call 'sales.items.get_sellable_products_with_bottles' {}, @set_sellable_products

    set_sellable_products: (resp) ~>
        if resp.success
            @sellable_products app.$m.products.create_products resp.result
            sellable_product_names = [product.name! for product in @sellable_products!]
            @autocomplete_ctrl.set_suggestions sellable_product_names
        else
            app.$m.common.generic_error_handler!
        @loading_products false
        m.redraw!

    select_product: (value, is_suggestion=false) ~>
        @product_name value
        @autocomplete_ctrl.oninput value, is_suggestion
        @product head @sellable_products!filter (.name! == value)
        @reset_bottle!
        @reset_item!
        m.redraw!

    on_submit_suggestion: (value) ~>>
        @select_product value, true

        const {result: bottles} = await app.api.get(
            'discover/products/'+@product!artkey!+'/sellable-bottles?relation_artkey='+@sales_order!supplier!artkey!
        )

        @sellable_bottles app.$m.bottles.create_bottles bottles, @product!
        m.redraw!

    set_bottle: (bottle_artkey) ~>>
        if bottle_artkey
            @bottle head @sellable_bottles!filter (.artkey! == +bottle_artkey)

            request_url = "discover/bottles/#{bottle_artkey}/sellable-items/#{@sales_order!supplier!artkey!}"
            if @sales_order!was_sold_in!
                request_url += "?sales_order_currency=#{@sales_order!was_sold_in!}"

            const {result: items} = await app.api.get(request_url)
            @bottle!items [new Item item for item in items]
            @bottle!items!sort((first, second) -> if first.stock_age! > second.stock_age! then -1 else 1)
            m.redraw!

        @reset_item!

    reset_bottle: ~>
        @bottle new Bottle
        @sellable_bottles []

    set_item: (item_artkey) ~>
        if item_artkey
            item = head @bottle!items!filter (.artkey! == +item_artkey)

            @sales_order_item!item item

            # Set the initial value of the price input.
            if @sales_order!supplier!name! == app.$m.data.a2bc_supplier_name
                # For A2BC BV, the sales prices always have a 2% margin over the
                # purchase price. Note that we hardcoded both the margin and the
                # supplier name here. Both references are removed if and when the
                # default-margin-per-supplier field is implemented.
                # For calculating the 2%, the sales price is the baseline, so we
                # divide by 0.98 - the purchase price is 2% below the sales price.
                # We also convert to the sales order's currency using the portal
                # exchange rate.
                @sales_order_item!price_per_case (convert_from_euro_to_currency(
                    +item.euro_was_bought_for! / 0.98,
                    @sales_order!was_sold_in!,
                    app.$s.currencies.exchange_rates[@sales_order!was_sold_in!].portal_rate,
                )).toFixed(2)
            else if item.relation_sales_price!
                @sales_order_item!price_per_case (+item.relation_sales_price!).toFixed(2)
            else if item.purchase_order_item!suggested_price_per_case!
                @sales_order_item!price_per_case item.purchase_order_item!suggested_price_per_case!
            else
                # TODO no target price known for this item
                @sales_order_item!price_per_case ''
        else
            @reset_item!

    reset_item: ~>
        @sales_order_item!item new Item

    add_to_order: ~>
        @loading true

        data = do
            sales_order_artkey: @sales_order_item!sales_order!artkey!
            item_artkey: @sales_order_item!item!artkey!
            number_of_cases: @sales_order_item!number_of_cases!
            price_per_case: @sales_order_item!price_per_case!

        api.call 'sales.items.add_item_to_sales_order', data, @handle_added_sales_order_item

    handle_added_sales_order_item: (resp) ~>
        @loading false
        @is_focused = true

        if resp.success
            app.notifier.notify 'The item was added successfully.', 'success'
            @sales_order_item!number_of_cases ''
            @sales_order_item!price_per_case ''
            @sales_order_item!item!artkey ''
            @product = window.prop ''
            @product_name = window.prop ''

            @bottle = window.prop new Bottle
            @sellable_bottles = window.prop []

            if @sales_order_item_added
                @sales_order_item_added!
        else
            app.notifier.notify resp.message, 'danger'

        document.getElementById("product_name_input").focus()

    onremove: ~>
        @autocomplete_ctrl.onremove!

    view: -> m '.mwrap',
        button-with-icon 'Add product to order (classic)', 'plus', do
            class: 'btn btn-default mb-2'
            'data-target': '#add_item_classic'
            'data-toggle': 'collapse'
            onclick: ~>
                @is_collapsed = !@is_collapsed
                return true

        m '.collapse#add_item_classic',
            if @loading_products! then m Spinner else

                m '.fieldset.largest',
                    m '.field-group',
                        m '.field',
                            m 'label' 'Product'
                            m '.control',
                                m 'input', do
                                    id: 'product_name_input'
                                    value: @product_name!
                                    type: 'text'
                                    onclick: @autocomplete_ctrl.onclick
                                    onkeydown: @autocomplete_ctrl.onkeydown
                                    oninput: (ev) ~> @select_product ev.target.value
                                    onupdate: (event) ~>
                                        if @is_collapsed != @is_focused
                                            @is_focused = @is_collapsed
                                            if !@is_collapsed
                                                event.dom.focus()
                                    autocomplete: 'off'

                                AutoComplete.view @autocomplete_ctrl

                                icon-button 'remove-sign', do
                                    class: 'btn-default'
                                    tabindex: -1
                                    onclick: @reset_search

                        m '.c-field-select.field',
                            m 'label' 'Specs'
                            m 'select', do
                                required: true
                                onchange: (ev) ~> @set_bottle +ev.target.value
                            , a do
                                m 'option' {selected: true, value: ''} '-'
                                if @sellable_bottles!
                                    (sort-by ((obj) ~> +(obj.volume!)), @sellable_bottles!).map (bottle, index) ~>
                                        m 'option' {value: bottle.artkey!, selected: @bottle!artkey! == bottle.artkey!} \
                                            bottle.to_specs!

                        m '.c-field-select.field',
                            m 'label' 'Item'
                            m 'select', do
                                required: true
                                onchange: (ev) ~> @set_item +ev.target.value
                            , a do
                                m 'option' {selected: true, value: ''} '-'
                                if @product! and @bottle!
                                    @bottle!items!map (item, index) ~>
                                        # Show the purchase price in the sales order's
                                        # currency using the current rate.
                                        was_bought_for = convert_from_euro_to_currency(
                                            +item.euro_was_bought_for!,
                                            @sales_order!was_sold_in!,
                                        )
                                        option_text = (if item.lot! then item.lot! else item.purchase_order_item!purchase_order!reference!)
                                        option_text += " - In: #{was_bought_for.format-money!} #{@sales_order!was_sold_in!}"

                                        if item.relation_sales_price! then
                                            if item.relation_sales_price! == item.relation_base_price!
                                                option_text += " - Target: "
                                            else
                                                option_text += " - Offer: "
                                            option_text += "#{(+item.relation_sales_price!).format-money!} #{@sales_order!was_sold_in!}"

                                        option_text += " - #{item.case!number_of_bottles!} btl/cs"
                                        option_text += " - #{item.number_of_cases_available!} available"
                                        option_text += " - #{item.case!customs_status!}"
                                        if item.case!gift_box_type!
                                            option_text += " - #{item.case!gift_box_type!}"
                                        if item.case!tax_label!
                                            option_text += " - #{item.case!tax_label!}"
                                        if item.case!no_eu_address!
                                            option_text += " - No EU address"

                                        m 'option' do
                                            value: item.artkey!
                                            selected: item.artkey! == @sales_order_item!item!artkey!
                                        , option_text

                        m '.c-field-number.field',
                            m 'label' 'Cases'
                            m 'input', do
                                value: @sales_order_item!number_of_cases!
                                oninput: (ev) ~> @sales_order_item!number_of_cases ev.target.value
                                type: 'number'
                                step: 1
                                min: 1
                                required: true

                        m MoneyInput,
                            label: if @sales_order!includes_excise! then 'Price per case (incl. excise)' else 'Price per case'
                            value: @sales_order_item!price_per_case!
                            on_value: (price) ~> @sales_order_item!price_per_case price
                            placeholder: 'Price per case'
                            required: true
                            currency: @sales_order!was_sold_in!

                        m '.field',
                            m 'label' m.trust '&nbsp;'
                            m 'button.btn.btn-success.btn-submit', do
                                onclick: @add_to_order
                            , if @loading! then m Spinner else 'Add product'
