m = require 'mithril'
{ compact, map } = require 'prelude-ls'

{Collection} = require '@/components/collection/collection.ls'
SearchInput = require '@/components/collection/search_input.ls'
{CollectionTable} = require '@/components/collection_table.ls'
{after-update, match-term-in, format-date} = require 'utils.ls'
inputs = require '@/components/inputs'

export class GraphProductTable
    (vnode) ->
        @api_name = vnode.attrs.api_name
        @on_update_graph_with_record_artkey_and_record = vnode.attrs.on_update_graph_with_record_artkey_and_record
        @artkeys_in_graph = vnode.attrs.artkeys_in_graph
        @graph_product_items = new Collection do
            api_function_name: @api_name
            query_limit: 25
            filter_function: @is_match
            default_sort_by: 'timestamp'
            default_sort_order: 'desc'
            dont-reuse: true
        @search_input_ctrl = new SearchInput.controller do
            collection: @graph_product_items
            placeholder: 'Search for product name...'

    is_match: (batch, term) ~>
        match-term-in term <| map (.toLowerCase!) <| compact <| [
            batch.product_name
        ]

    oncreate: ->
        @graph_product_items.requery!

    view: -> [
        m '.c-filter-group',
            SearchInput.view @search_input_ctrl

        m CollectionTable, do
            collection: @graph_product_items
            options:
                search_table_style: true
                sticky_header: true
                with_buttons: true
                autoscale: true
                unique_name: "table#{@api_name}"
            row_model: (record) ~>
                record with
                    is_in_graph: window.prop (record.artkey in @artkeys_in_graph!)
                        |> after-update @on_update_graph_with_record_artkey_and_record(record.artkey, record)
            columns:
                do
                    width: 0
                    name: 'Include in graph'
                    sort: false
                    function: (record) ~>
                        inputs.checkbox record.is_in_graph, {
                            disabled: false,
                        }

                do
                    width: 3
                    name: 'Name'
                    field: 'product_name'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Volume'
                    field: 'volume'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Alcohol'
                    field: 'alcohol_percentage'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Refill'
                    field: 'refill'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Gift Box'
                    field: 'gift_box_type'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Customs status'
                    field: 'customs_status'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Tax label'
                    field: 'tax_label'
                    sort: false
                    ellipsis: true
                do
                    width: 1
                    name: 'Bottles per case'
                    field: 'number_of_bottles_per_case'
                    sort: false
                    ellipsis: true
    ]
